import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    typeIcon: {
      // marginRight: "5px",
    },
  };
});

export default useStyles;
