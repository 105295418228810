import React from "react";
import { useHistory } from "react-router-dom";

import IllustratedErrorMessage from "../../organisms/IllustratedErrorMessage";
import PrimaryButton from "../../atoms/PrimaryButton";

import stop from "../../assets/stop.svg";

export default function Ops() {
  const { goBack } = useHistory();

  return (
    <IllustratedErrorMessage
      messages={["Du hast nicht die benötigten Rechte, um auf diese Seite zuzugreifen."]}
      illustration={stop}
      illustrationAltText="Ein Mann läuft gegen ein Stoppschild"
    >
      <PrimaryButton onClick={() => goBack()}>Zurück</PrimaryButton>
    </IllustratedErrorMessage>
  );
}
