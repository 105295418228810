import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    background: theme.palette.primary.main,
    boxShadow: theme.shadows[0],
    zIndex: theme.zIndex.drawer + 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    color: "#fff",
    marginRight: 20,
  },
  avatar: { height: "40px", width: "40px" },
  counter: {
    "&>span": {
      backgroundColor: theme.palette.primary.main,
      border: "solid #fff 1px",
      borderRadius: "18px",
      color: "#fff",
      fontSize: "12px",
      fontWeight: theme.typography.fontWeightMedium,
      left: "50%",
      lineHeight: "16px",
      marginLeft: "40px",
      minWidth: "20px",
      padding: "0 4px",
      position: "absolute",
      textAlign: "center",
      top: "8px",
      transition: "transform 800ms ease-out",
    },
  },
  inboundActivity: {
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(0.5),
    color: theme.palette.secondary.main,
    display: "flex",
    flexDirection: "row",
    flexGrow: 0,
    height: 32,
    justifyContent: "flex-start",
    marginLeft: 10,
    minWidth: 100,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    position: "relative",
    "&:before": {
      borderBottom: `16px solid transparent`,
      borderLeft: `16px solid ${theme.palette.background.paper}`,
      borderRight: `0px solid transparent`,
      borderTop: `16px solid ${theme.palette.background.paper}`,
      bottom: 0,
      content: '""',
      height: 0,
      left: 0,
      margin: "auto 0",
      position: "absolute",
      top: theme.spacing(2),
      width: 0,
    },
    "&>svg": {
      zIndex: 100,
    },
    "&>p": {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}));

export default useStyles;
