import React, { useState, Fragment, FC } from "react";
import { useHistory } from "react-router-dom";
import { GoogleLogin } from "react-google-login";

import { Link as RouterLink } from "react-router-dom";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import { Search } from "@material-ui/icons";

import AccountMenu from "../AccountMenu";
import { ExtensionDialogExtended } from "../ExtensionDialog";
import auth from "../../../services/authService";
import Interactions from "../Interactions";
import { useAuthorization } from "../../../providers/AuthorizationProvider";
import config from "../../../config";
import AsyncSearch from "../../molecules/AsyncSearch";
import { useGetAllowedTeamsQuery, Team } from "../../../generated/graphql";
import TeamsDialog from "../TeamsDialog/TeamsDialog";

import TaskCounter from "./TaskCounter";
import elli from "./elli.svg";
import useStyles from "./styles";
import "./animation.css";

const HomeLink = (props: any) => <RouterLink to="/" {...props} />;

interface IHeader {
  handleDrawerToggle: () => void;
  activeElli?: boolean;
}

const Header: FC<IHeader> = props => {
  const getAllowedTeams = useGetAllowedTeamsQuery();
  const { isAuthorized, me } = useAuthorization();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openExtensions, setOpenExtensions] = useState<boolean>(false);
  const [openTeams, setOpenTeams] = useState(false);
  const isMenuOpen = Boolean(anchorEl);

  function handleMenuClose() {
    setAnchorEl(null);
  }
  function handleProfileMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  const avatar = me?.picture || me?.avatar || undefined;
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {auth.isAuthenticated() && (
          <Fragment>
            <IconButton
              className={classes.menuButton}
              aria-label="Menu"
              onClick={props.handleDrawerToggle}
              data-testid="mainMenu"
            >
              <MenuIcon color="inherit" />
            </IconButton>
            <Interactions />
          </Fragment>
        )}
        <HomeLink>
          <img src={elli} alt="elli" className={`elli elli-${props.activeElli ? "big" : ""}`} />
          {auth.isAuthenticated() && <TaskCounter activeElli={Boolean(props.activeElli)} />}
        </HomeLink>
        <div className={classes.grow} />
        <Box display="flex" alignItems="center">
          {auth.isAuthenticated() && (
            <>
              {isAuthorized("search") && <GlobalSearch />}
              <IconButton color="inherit" onClick={handleProfileMenuOpen}>
                {avatar ? (
                  <Avatar data-testid="HeaderUserAvatar" className={classes.avatar} src={avatar} />
                ) : (
                  <AccountCircle color="inherit" />
                )}
              </IconButton>
              {/* we need the login here otherwise we wont have the gapi loaded correctly for logout */}
              <GoogleLogin
                clientId={config.clientId}
                redirectUri={config.frontendUrl}
                isSignedIn
                fetchBasicProfile
                hostedDomain="otheb.de"
                scope={config.scopes}
                onSuccess={response => auth.googleLoginSuccess(response, false)}
                onFailure={auth.googleLoginError}
                render={() => <></>}
                buttonText="Login"
                cookiePolicy={"single_host_origin"}
              />
            </>
          )}
        </Box>
      </Toolbar>

      <AccountMenu
        anchorEl={anchorEl}
        isMenuOpen={isMenuOpen}
        handleMenuClose={handleMenuClose}
        openExtensionsDialog={() => setOpenExtensions(true)}
        openTeamsDialog={() => setOpenTeams(true)}
        teamsError={getAllowedTeams.error}
        profile={me}
      />
      {me?.id && openExtensions && (
        <ExtensionDialogExtended
          open={openExtensions}
          close={() => setOpenExtensions(false)}
          personId={me.id}
        />
      )}
      {me?.user && getAllowedTeams.data?.getAllowedTeams && openTeams ? (
        <TeamsDialog
          open={openTeams}
          close={() => setOpenTeams(false)}
          // TODO!: Fix typing. Type 'User' is used in various places (wrongly) which prohibits to set team and defaultTeam to nun-nullable
          defaultTeam={me.user.defaultTeam as Team}
          teams={(me.user.teams ?? []) as Team[]}
          userId={me.user.id}
          //allTeams={getAllowedTeams.data.getAllowedTeams}
          //to read the team from server       
          allTeams={[
            //"onlineTeam",
            "caseManagement",
            "serviceManagement",
            "counselingTC",
            "counseling1stLevel",
            "clinicalAdministration",
            "lifeManagement",
            "counseling2ndLevel",
            "digitalServices"
          ] as Team[]}
        />
      ) : null}
    </AppBar>
  );
};

function GlobalSearch() {
  const { push } = useHistory();

  const [open, setOpen] = useState(false);

  return (
    <Box marginRight={3} display="flex">
      {!open && <Search style={{ cursor: "pointer" }} onClick={() => setOpen(true)}></Search>}
      {open && (
        <AsyncSearch.GlobalSearch
          onClick={(selection: any) => {
            const path = selection?.path;

            if (!!path) {
              push(path);
            }

            setOpen(false);
          }}
        />
      )}
    </Box>
  );
}
export default Header;
