import { useEffect } from "react";

import {
  useGetCaseManagerByPersonQuery,
  CaseManagerSubscriptionDocument,
  CaseManagerSubscriptionSubscription,
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

export const useCaseManagerSubscription = (personId: number) => {
  const { data, loading, subscribeToMore } = useGetCaseManagerByPersonQuery({
    variables: {
      personId,
    },
    fetchPolicy,
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore<CaseManagerSubscriptionSubscription>({
      document: CaseManagerSubscriptionDocument,
      variables: { personId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !prev) return prev;

        return {
          ...prev,
          ...{ getCaseManagerByPerson: subscriptionData.data.caseManagerSubscription },
        };
      },
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personId]);

  return {
    caseManagerLoading: loading,
    caseManager: data?.getCaseManagerByPerson?.user,
  };
};

export default useCaseManagerSubscription;
