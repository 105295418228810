import React, { Suspense, useState } from "react";

import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  split,
  from,
  HttpLink,
  fromPromise,
} from "@apollo/client";

import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { onError } from "@apollo/client/link/error";

import { MuiThemeProvider } from "@material-ui/core/styles";
import { getMainDefinition } from "@apollo/client/utilities";
import { Router, Route, Switch, Redirect, RouteProps } from "react-router-dom";

import history from "../services/history";

import dayjs from "dayjs";
import "dayjs/locale/de";
import config from "../config";
import "./App.css";
import introspectionResult from "../introspection-result";

import AuthorizationProvider, { useAuthorization } from "../providers/AuthorizationProvider";

import Account from "./pages/Account";
import AccountContract from "./pages/AccountContract";
import AccountBusinessUnits from "./pages/AccountBusinessUnits/AccountBusinessUnits";
import AccountDepartments from "./pages/AccountDepartments";
import Accounts from "./pages/Accounts";
import ActivitiesPage from "./pages/Activities";
import ActivitiesExternal from "./pages/ActivitiesExternal";
import ActivitiesFlags from "./pages/ActivitiesFlags";
import ActivitiesInternal from "./pages/ActivitiesInternal";
import ActivitiesServices from "./pages/ActivitiesServices";
import Activity from "./pages/Activity";
import Appointment from "./pages/Appointment";
import BusinessActivity from "./pages/BusinessActivity";
import BusinessEmailSendNew from "./pages/BusinessEmailSendNew";
import BusinessMasterData from "./pages/BusinessMasterData";
import BusinessMemoCreation from "./pages/BusinessMemoCreation";
import BusinessMemoUpdate from "./pages/BusinessMemoUpdate";
import BusinessObjectPage from "./pages/Business";
import BusinessContactPersonDetails from "./pages/BusinessContactPersonDetails";
import BusinessContacts from "./pages/BusinessContacts";
import CaseManagement from "./pages/CaseManagement";
import CaseManagementReview from "./pages/CaseManagementReview";
import ConversationReport from "./pages/ConversationReportCreation";
import ConversationReportUpdate from "./pages/ConversationReportUpdate";
import CounselingCreation from "./pages/CounselingCreation";
import CounselingMandate from "./pages/CounselingMandate";
import CounselingSetup from "./pages/CounselingSetup";
import Documents from "./pages/Documents";
import EmailInbox from "./pages/EmailInbox";
import EmailThread from "./pages/EmailThread";
import EmailThreadBusiness from "./pages/EmailThreadBusiness";
import EmailSendNew from "./pages/EmailSendNew";
import EmailSendWpoNew from "./pages/EmailSendWpoNew";
import InactivityLogout from "./pages/InactivityLogout";
import Inbox from "./pages/Inbox";
import InboxAdmin from "./pages/InboxAdmin";
import InboxEmployee from "./pages/InboxEmployee";
import InboxEmployeeSelection from "./pages/InboxEmployeeSelection";
import InboxPool from "./pages/InboxPool";
import MemoUpdate from "./pages/MemoUpdate";
import MyClients from "./pages/MyClients";
import Ops from "./pages/Ops";
import PersonContacts from "./pages/PersonContacts";
import PersonContactDetails from "./pages/PersonContactDetails";
import PersonDetails from "./pages/PersonDetails";
import PersonMemoCreation from "./pages/PersonMemoCreation";
import PersonOverview from "./pages/PersonOverview";
import PersonTopic from "./pages/PersonTopic";
import RequireLogin from "./pages/RequireLogin";
import ServiceCreation from "./pages/ServiceCreation";
import ServiceDetails from "./pages/ServiceDetails";
import ServiceResearchElement from "./pages/ServiceResearchElement";
import ServiceResearchLeadCreation from "./pages/ServiceResearchLeadCreation";
import ServiceResearchSuggestionList from "./pages/ServiceResearchSuggestionList";
import ServiceSetup from "./pages/ServiceSetup";
import Shift from "./pages/Shift";
import Sms from "./pages/Sms";
import Statistics from "./pages/Statistics";
import STCDetails from "./pages/STCDetails";
import Chat from "./pages/Chat";

import auth from "../services/authService.js";
import theme from "../themes/default";
import ScrollTop from "../components/atoms/ScrollTop";
import ChatListener from "../containers/ChatListener";

import Layout from "./templates/Layout";

import { Person, ServiceType, User } from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";
import ActivitiesFlagsCaseManager from "./pages/ActivitiesFlagCaseManager";
import InboxChats from "./pages/InboxChats";
import ClientComplaints from "./pages/ClientComplaints";
import QualtricsQuestionnaire from "./pages/QualtricsQuestionnaire";
import QualtricsQuestionnaireType from "./pages/QualtricsQuestionnaireType";

import PersonViewRestrictions from "./molecules/PersonViewRestrictions"

dayjs.locale("de");

const httpLink = new HttpLink({
  uri: config.backendUrl,
  credentials: "include",
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  console.log({ graphQLErrors, networkError });

  if (graphQLErrors) {
    for (const error of graphQLErrors) {
      console.error(error);
      if (error.message === "Access denied! You need to be authorized to perform this action!") {
        // @ts-ignore
        if (window.gapi && window.gapi.auth2) {
          return fromPromise(
            // @ts-ignore
            window.gapi.auth2
              .getAuthInstance()
              .currentUser.get()
              .reloadAuthResponse()
              .then((response: any) => {
                const oldHeaders = operation.getContext().headers;
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${response.id_token}`,
                  },
                });
                auth.refreshSuccess(response, null);
                return forward(operation);
              }),
          );
        }
      }
    }
  }
  if (networkError) {
    // @ts-ignore
    if (window.gapi && window.gapi.auth2) {
      return fromPromise(
        // @ts-ignore
        window.gapi.auth2
          .getAuthInstance()
          .currentUser.get()
          .reloadAuthResponse()
          .then((response: any) => {
            const oldHeaders = operation.getContext().headers;
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `Bearer ${response.id_token}`,
              },
            });
            auth.refreshSuccess(response, null);
            return forward(operation);
          }),
      );
    }
  }
});

const authLink = setContext(async (_, { headers }) => {
  const token = await auth.getIdToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const wsLink = new WebSocketLink({
  uri: config.backendWsUrl,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      const token = await auth.getIdToken();
      return {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      };
    },
  },
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definitionMode = getMainDefinition(query);

    return (
      definitionMode.kind === "OperationDefinition" && definitionMode.operation === "subscription"
    );
  },
  wsLink,
  from([authLink, errorLink, httpLink]),
);

export const client = new ApolloClient({
  link: from([link]),
  cache: new InMemoryCache({
    possibleTypes: introspectionResult.possibleTypes,
  }),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy,
    },
  },
});

const ProtectedRoute = ({ render, ...rest }: RouteProps) => {
  const { isAuthorized } = useAuthorization();
  return (
    <Route
      {...rest}
      render={props => {
        if (auth.isAuthenticated()) {
          // check only when doAuthorizedCheck is true
          if (!isAuthorized(props.match.path)) return <Redirect to="/not-allowed" />; // create page
          // Prevent chat listener to connect for not protected routes!
          return render ? (
            <ChatListener mayChat={isAuthorized("chatStart")}>{render(props)}</ChatListener>
          ) : (
            <Redirect to="/require-login" />
          );
        }
        localStorage.setItem("historyBack", `${props.location.pathname}${props.location.search}`);
        return <Redirect to="/require-login" />;
      }}
    />
  );
};

export const PersonContext = React.createContext<{
  filterUsers?: Map<number, User>;
  setUsers: (filterUsers: Map<number, User>) => void;
  person?: Pick<
    Person,
    | "id"
    | "mentalState"
    | "name"
    | "alias"
    | "salut"
    | "redFlag"
    | "vip"
    | "yellowFlag"
    | "avatarKeys"
    | "avatar"
  >;
  setPerson: (
    person?: Pick<
      Person,
      | "id"
      | "mentalState"
      | "name"
      | "alias"
      | "salut"
      | "redFlag"
      | "vip"
      | "yellowFlag"
      | "avatarKeys"
      | "avatar"
    >,
  ) => void;
}>({
  filterUsers: undefined,
  setUsers: (filterUsers?: Map<number, User>) => { },
  person: undefined,
  setPerson: (
    person?: Pick<
      Person,
      | "id"
      | "mentalState"
      | "name"
      | "alias"
      | "salut"
      | "redFlag"
      | "vip"
      | "yellowFlag"
      | "avatarKeys"
      | "avatar"
    >,
  ) => { },
});

export default function App() {
  const [clientState, setClientState] = useState<
    | Pick<
      Person,
      | "id"
      | "mentalState"
      | "name"
      | "alias"
      | "salut"
      | "redFlag"
      | "vip"
      | "yellowFlag"
      | "avatarKeys"
      | "avatar"
    >
    | undefined
  >(undefined);
  const [userState, setUserState] = useState<Map<number, User> | undefined>(undefined);
  const [animation, setAnimation] = useState(false);
  const { isAuthorized } = useAuthorization();
  return (
    <ApolloProvider client={client}>
      <MuiThemeProvider theme={theme}>
        <AuthorizationProvider>
          <PersonContext.Provider
            value={{
              filterUsers: userState,
              setUsers: filterUsers => setUserState(filterUsers),
              person: clientState,
              setPerson: person => setClientState(person),
            }}
          >
            <Suspense fallback="loading">
              <Router history={history}>
                <ScrollTop />
                <Switch>
                  <ProtectedRoute
                    exact
                    path={"/"}
                    render={() => (
                      <Layout activeElli={animation} big setAnimation={setAnimation}>
                        <Inbox />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={["/inbox/:filter(unassigned)"]}
                    render={() => (
                      <Layout activeElli={animation} big setAnimation={setAnimation}>
                        {isAuthorized("filterInbox") ? <InboxAdmin /> : <Inbox />}
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={["/email/:threadId", "/person/:id/email/:threadId"]}
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <EmailThread />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={["/business/:id/email/:threadId"]}
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <EmailThreadBusiness />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/emails"
                    render={() => (
                      <Layout activeElli={animation} big setAnimation={setAnimation}>
                        <EmailInbox />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/person/:personId/send-email"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <EmailSendNew />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/person/:personId/send-wpo-email"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <EmailSendWpoNew />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/pool"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <InboxPool />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/inbox/myClients"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation} big>
                        <MyClients />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/not-allowed"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <Ops />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={["/inbox/:filter(overdue|pool)"]}
                    render={() => (
                      <Layout activeElli={animation} big setAnimation={setAnimation}>
                        <InboxAdmin />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={["/inbox/:filter(selection)"]}
                    render={() => (
                      <Layout activeElli={animation} big setAnimation={setAnimation}>
                        <InboxEmployeeSelection />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={["/inbox/selection/:userId"]}
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <InboxEmployee />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/inbox/chats"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <InboxChats />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/activities"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <ActivitiesPage />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/activities/external"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <ActivitiesExternal />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/activities/flags"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <ActivitiesFlags />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/activities/flags/caseManager"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <ActivitiesFlagsCaseManager />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/activities/internal"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <ActivitiesInternal />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/activities/services"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <ActivitiesServices />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/appointment"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <Appointment />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/account"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <Accounts />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/account/:id"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <Account />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/account/:id/departments"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <AccountDepartments />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/account/:id/business-units"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <AccountBusinessUnits />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/account/:id/contract"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <AccountContract />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/person/:personId/chat/:chatId"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <Chat />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/person/:id"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <PersonOverview />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/person/:id/caseManagement"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <CaseManagement />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/person/:id/caseManagement/review"
                    render={() => (
                      <Layout activeElli={animation} big setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <CaseManagementReview />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/person/:id/contacts"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <PersonContacts />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/person/:id/contacts/:contactId"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <PersonContactDetails />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/person/:id/details"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <PersonDetails />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/person/:id/qualtrics-documentation"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <QualtricsQuestionnaire />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/person/:id/qualtrics-type"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <QualtricsQuestionnaireType />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/complaints"
                    exact
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <ClientComplaints />
                      </Layout>
                    )}
                  />
                  {/* <ProtectedRoute
                    exact
                    path="/person/:id/client/:clientId/"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <ClientComplaints />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  /> */}
                  <ProtectedRoute
                    exact
                    path={"/business/:id/details"}
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <BusinessMasterData />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={["/person/:id/documents", "/business/:id/documents"]}
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <Documents />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/person/:id/topics/:topicId"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <PersonTopic />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/person/:id/report"
                    exact
                    render={() => (
                      <Layout activeElli={animation} big setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <ConversationReport />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/person/:id/service"
                    exact
                    render={() => (
                      <Layout activeElli={animation} big setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <ServiceCreation />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/person/:id/internalConsult"
                    exact
                    render={() => (
                      <Layout activeElli={animation} big setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <CounselingCreation type={ServiceType.internalConsult} />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/internalConsult/:serviceId"
                    exact
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <STCDetails />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/externalConsult/:serviceId"
                    exact
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <STCDetails />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/person/:id/internalConsult/:serviceId/setup"
                    exact
                    render={() => (
                      <Layout activeElli={animation} big setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <CounselingSetup />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/person/:id/externalConsult/:serviceId/setup"
                    exact
                    render={() => (
                      <Layout activeElli={animation} big setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <CounselingSetup />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/person/:id/externalConsult"
                    exact
                    render={() => (
                      <Layout activeElli={animation} big setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <CounselingCreation type={ServiceType.externalConsult} />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/person/:id/service/:serviceId/setup"
                    exact
                    render={() => (
                      <Layout activeElli={animation} big setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <ServiceSetup />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/business/:businessId/memo"
                    exact
                    render={() => (
                      <Layout activeElli={animation} big setAnimation={setAnimation}>
                        <BusinessMemoCreation />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/business/:businessId/memo/:id"
                    exact
                    render={() => (
                      <Layout activeElli={animation} big setAnimation={setAnimation}>
                        <BusinessMemoUpdate />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/person/:personId/report/:reportId"
                    exact
                    render={() => (
                      <Layout activeElli={animation} big setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <ConversationReportUpdate />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/person/:personId/memo"
                    exact
                    render={() => (
                      <Layout activeElli={animation} big setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <PersonMemoCreation />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/person/:personId/memo/:reportId"
                    exact
                    render={() => (
                      <Layout activeElli={animation} big setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <MemoUpdate />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/person/:personId/topics/:topicId/activities/:id"
                    exact
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <Activity />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/person/:personId/activities/:id"
                    exact
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <Activity />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/person/:personId/sms"
                    exact
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <PersonViewRestrictions>
                          <Sms />
                        </PersonViewRestrictions>
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/service/:serviceId"
                    exact
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <ServiceDetails />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/service/:serviceId/research/suggestionList"
                    exact
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <ServiceResearchSuggestionList />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/service/:serviceId/research"
                    exact
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <ServiceResearchLeadCreation />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/service/:serviceId/research/:researchId"
                    exact
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <ServiceResearchElement />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/shift/:page"
                    exact
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <Shift />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/statistics"
                    exact
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <Statistics />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/business/:businessId/activities/:id"
                    exact
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <BusinessActivity />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/business/:id"
                    exact
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <BusinessObjectPage />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/business/:id/contacts"
                    exact
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <BusinessContacts />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/business/:id/contacts/:contactId"
                    exact
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <BusinessContactPersonDetails />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    path="/business/:id/contacts/:contactId/email"
                    exact
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <BusinessEmailSendNew />
                      </Layout>
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/mandate/:mandateId"
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <CounselingMandate />
                      </Layout>
                    )}
                  />
                  <Route
                    path="/require-login"
                    exact
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <RequireLogin />
                      </Layout>
                    )}
                  />
                  <Route
                    path="/session-end"
                    exact
                    render={() => (
                      <Layout activeElli={animation} setAnimation={setAnimation}>
                        <InactivityLogout />
                      </Layout>
                    )}
                  />
                  <Route path="*">
                    <Redirect to="/" />
                  </Route>
                </Switch>
              </Router>
            </Suspense>
          </PersonContext.Provider>
        </AuthorizationProvider>
      </MuiThemeProvider>
    </ApolloProvider>
  );
}
