import gql from "graphql-tag";

export const hotFlags = gql`
  query hotFlags {
    hotFlags {
      id
      vip
      account {
        id
        name
      }
      person {
        id
        name
        yellowFlag
        redFlag
      }
      clientAttributes {
        clientReviewAttribute {
          id
          name
        }
        id
        attributeValue
        start
      }
    }
  }
`;

export const mySTCClients = gql`
  query mySTCClients {
    mySTCClients {
      id
      person {
        id
        name
        lastActivity
        flag
        name
        mentalState
        vip
        avatarKeys
      }
    }
  }
`;

export const getSTCClients = gql`
  query getSTCClients($userId: Int!) {
    getSTCClients(userId: $userId) {
      id
      person {
        id
        name
        lastActivity
        flag
        name
        mentalState
        vip
        avatarKeys
      }
    }
  }
`;

export const myCMClients = gql`
  query myCMClients {
    myCMClients {
      id
      person {
        id
        name
        lastActivity
        flag
        name
        mentalState
        vip
        avatarKeys
      }
    }
  }
`;

export const getCMClients = gql`
  query getCMClients($userId: Int!) {
    getCMClients(userId: $userId) {
      id
      person {
        id
        name
        lastActivity
        flag
        name
        mentalState
        vip
        avatarKeys
      }
    }
  }
`;

export const breadCrumbInfoByClientId = gql`
  query breadCrumbInfoByClientId($id: Int!) {
    breadCrumbInfoByClientId(id: $id) {
      contractEnd
      language
    }
  }
`;

export const clientByUcmsId = gql`
  query clientByUcmsId($ucmsId: Int!) {
    clientByUcmsId(ucmsId: $ucmsId) {
      id
      account {
        id
        name
      }
      person {
        id
        firstName
        lastName
        alias
      }
    }
  }
`;

export const allClients = gql`
  query allClients {
    allClients {
      user {
        person {
          name
          alias
          avatarKeys
          avatar
        }
      }
      clients {
        id
        caseManager {
          id
          user {
            id
            name
            primeDN
            person {
              id
              name
              avatarKeys
              avatar
            }
          }
        }
        person {
          id
          flag
          lastActivity
          name
          mentalState
          vip
          avatarKeys
          avatar
        }
      }
      reviews {
        id
        lastUpdate
        caseManager {
          id
          user {
            id
            name
            primeDN
            person {
              id
              name
              avatarKeys
              avatar
            }
          }
        }
        caseManagementReviewAttributes {
          lastUpdate
          attributeValue
        }
        user {
          id
          name
          absentUntil
          person {
            id
            alias
            name
          }
        }
        client {
          id
          account {
            id
            name
          }
          topics {
            id
            name
          }
          person {
            id
            flag
            name
            mentalState
            vip
            avatarKeys
            avatar
          }
          topics {
            id
            name
          }
        }
      }
      total
    }
  }
`;


export const getClientComplaints = gql`
  query getClientComplaints($id: Int!) {
    getClientComplaints(id: $id) {
      id
      user {
        id
        person {
          id
          name
        }
      }
      title
      content
      dateOfComplaint
      createdAt
      updatedAt
    }
  }
`;