import DayjsUtils from "@date-io/dayjs";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Field, FieldProps } from "formik";
import { TextField } from "formik-material-ui";
import React, { useState } from "react";
import {
  Account,
  AccountTariff,
  AccountType,
  useUpdateAccountMutation,
} from "../../../generated/graphql";
import AccountReportingSelect from "../../molecules/AccountReportingSelect";
import AccountTariffSelect from "../../molecules/AccountTariffSelect";
import AccountTypeSelect from "../../molecules/AccountTypeSelect";
import DialogForm from "../DialogForm";

type TAccountContractDialog = {
  account: Account;
  open: boolean;
  toggle: () => void;
};

const linesEAP = {
  local: [
    {
      prefix: "0800",
      numbers: ["6614150", "8833500", "5577500", "4488500", "4299942", "7722500", "9944500"],
    },
    ,
    {
      prefix: "0431",
      numbers: ["6614150", "8833500", "5577500", "4488500", "4299942", "7722500", "9944500"],
    },
  ],
  international: [
    {
      prefix: "0431",
      numbers: ["88725301", "88725302", "6614150", "88725305", "88725300", "88725311"],
    },
  ],
};

const linesWPO = {
  local: [
    {
      prefix: "0800",
      numbers: [
        "7237177",
        "291381",
        "295365",
        "1802587",
        "802200",
        "110350",
        "1830753",
        "730931",
        "202421",
        "1821325",
        "017905",
      ],
    },
    {
      prefix: "+49",
      numbers: ["8920194053", "32221095114"],
    },
    {
      prefix: "+43",
      numbers: ["6703080045"],
    },
  ],
  international: [
    {
      prefix: "0800",
      numbers: [
        "7237177",
        "291381",
        "295365",
        "1802587",
        "802200",
        "110350",
        "1830753",
        "730931",
        "202421",
        "1821325",
        "017905",
      ],
    },
    {
      prefix: "+49",
      numbers: ["8920194053"],
    },
    {
      prefix: "+43",
      numbers: ["6703080045"],
    },
  ],
};
//lifeWorks', 'morneauShepell', 'chesnut'
const linesLMC = {
  local: [
    {
      prefix: "0800",
      numbers: ["281297", "1830926", "6614150", "802633", "1833164"],
    },
  ],
  international: [
    {
      prefix: "0800",
      numbers: ["66141500"],
    },
    {
      prefix: "0431",
      numbers: ["88725488"],
    },
  ],
};

const usageRateTypes = [
  {
    id: "abhängig",
    value: "abhängig",
  },
  {
    id: "unabhängig",
    value: "unabhängig",
  },
];

const AccountContractDialog = ({ account, open, toggle }: TAccountContractDialog) => {
  const [update] = useUpdateAccountMutation();

  const [currentAccountType, setCurrentAccountType] = useState(null);

  const getHelplinesByContractType = (type: AccountType) => {
    if (type === AccountType.eap) {
      return linesEAP;
    }
    if (
      type === AccountType.lifeWorks ||
      type === AccountType.morneauShepell ||
      type === AccountType.chesnut
    ) {
      return linesLMC;
    }
    if (type === AccountType.wpo) {
      return linesWPO;
    }
    return null;
  };

  const getHelplinePrefixesByContractType = (type: AccountType) => {
    if (type === AccountType.eap) {
      return linesEAP.local.map(line => line?.prefix);
    }
    if (
      type === AccountType.lifeWorks ||
      type === AccountType.morneauShepell ||
      type === AccountType.chesnut
    ) {
      return linesLMC.local.map(line => line.prefix);
    }
    if (type === AccountType.wpo) {
      return linesWPO.local.map(line => line.prefix);
    }
    return null;
  };

  const getInternationHelplinePrefixesByContractType = (type: AccountType) => {
    if (type === AccountType.eap) {
      return linesEAP.international.map(line => line.prefix);
    }
    if (
      type === AccountType.lifeWorks ||
      type === AccountType.morneauShepell ||
      type === AccountType.chesnut
    ) {
      return linesLMC.international.map(line => line.prefix);
    }
    if (type === AccountType.wpo) {
      return linesWPO.international.map(line => line.prefix);
    }
    return null;
  };

  const getHelplineNumbersByContractTypeAndPrefix = (type: AccountType, prefix: string) => {
    if (!prefix) {
      return [];
    }
    if (type === AccountType.eap) {
      const line = linesEAP.local.find(e => e?.prefix === prefix);
      return line == null ? [] : line.numbers;
    }
    if (
      type === AccountType.lifeWorks ||
      type === AccountType.morneauShepell ||
      type === AccountType.chesnut
    ) {
      const line = linesLMC.local.find(e => e.prefix === prefix);
      return line == null ? [] : line.numbers;
    }
    if (type === AccountType.wpo) {
      const line = linesWPO.local.find(e => e.prefix === prefix);
      return line == null ? [] : line.numbers;
    }
    return null;
  };

  const getInternationHelplineNumbersByContractTypeAndPrefix = (
    type: AccountType,
    prefix: string,
  ) => {
    if (!prefix) {
      return [];
    }
    if (type === AccountType.eap) {
      const line = linesEAP.international.find(e => e.prefix === prefix);
      return line == null ? [] : line.numbers;
    }
    if (
      type === AccountType.lifeWorks ||
      type === AccountType.morneauShepell ||
      type === AccountType.chesnut
    ) {
      const line = linesLMC.international.find(e => e.prefix === prefix);
      return line == null ? [] : line.numbers;
    }
    if (type === AccountType.wpo) {
      const line = linesWPO.international.find(e => e.prefix === prefix);
      return line == null ? [] : line.numbers;
    }
    return null;
  };

  const getPrefix = (number: string) => {
    if (number == null || number == "") {
      return "";
    }
    const parts = number.split(" ");
    return parts.length === 2 ? parts[0] : "";
  };

  const getNumber = (number: string) => {
    if (number == null || number == "") {
      return "";
    }
    const parts = number.split(" ");
    return parts.length === 2 ? parts[1] : "";
  };

  const calculateTheValueForPayload = (prefix: string, line: string) => {
    if (prefix === "-" || !prefix || !line) {
      return "";
    }
    return `${prefix} ${line}`;
  };

  return (
    <DialogForm
      open={open}
      initialValues={{
        type: account.type || AccountType.eap,
        lifeworksId: account.lifeworksId || "",
        tariff: account.tariff || AccountTariff.premium,
        contractStart: account.contractStart || null,
        contractEnd: account.contractEnd || null,
        pin: account.pin || "",
        chatRegistrationPin: account.chatRegistrationPin || "",
        externalRef: account.externalRef || "",

        helplinePrefix: getPrefix(account.helpline!),
        helpline: getNumber(account.helpline!),

        helpline2Prefix: getPrefix(account.helpline2!),
        helpline2: getNumber(account.helpline2!),

        helplineInternationalPrefix: getPrefix(account.helplineInternational!),
        helplineInternational: getNumber(account.helplineInternational!),
        usageRate: account.usageRate || "",
        reporting: account.reporting || "",
      }}
      onClose={toggle}
      title="Vertragsdaten bearbeiten"
      onSubmit={values => {
        const payload = {
          type: values.type,
          lifeworksId: values.lifeworksId,
          tariff: values.tariff,
          contractStart: values.contractStart,
          contractEnd: values.contractEnd,
          pin: values.pin,
          chatRegistrationPin: values.chatRegistrationPin,
          externalRef: values.externalRef,
          helpline: calculateTheValueForPayload(values.helplinePrefix, values.helpline),
          helpline2: calculateTheValueForPayload(values.helpline2Prefix, values.helpline2),
          helplineInternational: calculateTheValueForPayload(
            values.helplineInternationalPrefix,
            values.helplineInternational,
          ),
          usageRate: values.usageRate,
          reporting: values.reporting,
        };
        return update({
          variables: {
            id: account.id!,
            input: {
              ...payload,
              contractStart: values.contractStart || null,
              contractEnd: values.contractEnd || null,
              pin: values.pin || null,
              lifeworksId: values.type === AccountType.lifeWorks ? values.lifeworksId : null,
            },
          },
        }).then(() => {
          toggle();
        });
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AccountTypeSelect tabIndex={0} />
        </Grid>
        <Grid item xs={6}>
          <FormControl margin="normal" fullWidth>
            <Field
              name="externalRef"
              component={TextField}
              label="Accountnummer"
              type="string"
              render={({ field, form, form: { setFieldValue } }: FieldProps) => {
                if (currentAccountType == null || form.values.type !== currentAccountType) {
                  setCurrentAccountType(form.values.type);
                  setFieldValue("helpline", "");
                  setFieldValue("helpline2", "");
                  setFieldValue("helplineInternational", "");
                }

                if (form.values.type !== AccountType.lifeWorks && form.values.type !== AccountType.eap && form.values.type !== AccountType.assistance) {
                  return null;
                }

                return (
                  <TextField label="Accountnummer" variant="standard" field={field} form={form} />
                );
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl margin="normal" fullWidth>
            <Field
              name="lifeworksId"
              type="string"
              render={({ field, form }: FieldProps) => {
                if (form.values.type !== AccountType.lifeWorks) {
                  return null;
                }

                return (
                  <TextField
                    label="Lifeworks Accountkennung"
                    variant="standard"
                    field={field}
                    form={form}
                  />
                );
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AccountTariffSelect tabIndex={2} />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field
              name="usageRate"
              render={({ form: { setFieldValue }, form }: FieldProps) => {
                return (
                  <>
                    <InputLabel htmlFor="usageRateTypes">Nutzungsrate</InputLabel>
                    <Select
                      value={form.values.usageRate}
                      onChange={v => {
                        setFieldValue("usageRate", v.target.value);
                      }}
                    >
                      {usageRateTypes.map(usageRate => {
                        return (
                          <MenuItem key={usageRate.id} value={usageRate.value}>
                            {usageRate.value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </>
                );
              }}
            ></Field>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <MuiPickersUtilsProvider utils={DayjsUtils} locale="de">
          <Grid item xs={6}>
            <FormControl margin="normal" fullWidth>
              <Field
                name="contractStart"
                render={({ form: { setFieldValue, values } }: FieldProps) => (
                  <KeyboardDatePicker
                    name="contractStart"
                    format="DD.MM.YYYY"
                    onChange={v => setFieldValue("contractStart", v)}
                    label="Vetragsbeginn"
                    cancelLabel="Abbrechen"
                    okLabel="OK"
                    value={values.contractStart}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl margin="normal" fullWidth>
              <Field
                name="contractEnd"
                render={({ form: { setFieldValue, values } }: FieldProps) => (
                  <KeyboardDatePicker
                    name="contractEnd"
                    format="DD.MM.YYYY"
                    onChange={v => setFieldValue("contractEnd", v)}
                    label="Vetragsende"
                    cancelLabel="Abbrechen"
                    okLabel="OK"
                    value={values.contractEnd}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl margin="normal" fullWidth>
            <Field name="pin" component={TextField} label="Pin" type="number" />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl margin="normal" fullWidth>
            <Field name="chatRegistrationPin" component={TextField} label="App-Kennwort" />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field
              name="helplinePrefix"
              render={({ form: { setFieldValue }, form }: FieldProps) => {
                if (getHelplinesByContractType(form.values.type) == null) {
                  return null;
                }
                return (
                  <>
                    <InputLabel htmlFor="helplinePrefix">Helpline 1 Vorwahl</InputLabel>
                    <Select
                      value={form.values.helplinePrefix}
                      onChange={v => {
                        setFieldValue("helplinePrefix", v.target.value);
                        setFieldValue("helpline", '');
                      }}
                    >
                      <MenuItem key={-1} value={"-"}>
                        {"-"}
                      </MenuItem>
                      {getHelplinePrefixesByContractType(form.values.type)?.map(line => {
                        return (
                          <MenuItem key={line} value={line}>
                            {line}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </>
                );
              }}
            ></Field>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field
              name="helpline"
              render={({ form: { setFieldValue }, form }: FieldProps) => {
                if (getHelplinesByContractType(form.values.type) == null) {
                  return null;
                }
                return (
                  <>
                    <InputLabel htmlFor="helpline">Helpline 1 Telefonnummer</InputLabel>
                    <Select
                      value={form.values.helpline}
                      onChange={v => {
                        setFieldValue("helpline", v.target.value);
                      }}
                    >
                      {getHelplineNumbersByContractTypeAndPrefix(
                        form.values.type,
                        form.values.helplinePrefix,
                      )?.map(line => {
                        return (
                          <MenuItem key={line} value={line}>
                            {line}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </>
                );
              }}
            ></Field>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field
              name="helpline2Prefix"
              render={({ form: { setFieldValue }, form }: FieldProps) => {
                if (getHelplinesByContractType(form.values.type) == null) {
                  return null;
                }
                return (
                  <>
                    <InputLabel htmlFor="helpline2Prefix">Helpline 2 Vorwahl</InputLabel>
                    <Select
                      value={form.values.helpline2Prefix}
                      onChange={v => {
                        setFieldValue("helpline2Prefix", v.target.value);
                        setFieldValue("helpline2", '');
                      }}
                    >
                      <MenuItem key={-1} value={"-"}>
                        {"-"}
                      </MenuItem>
                      {getHelplinePrefixesByContractType(form.values.type)?.map(line => {
                        return (
                          <MenuItem key={line} value={line}>
                            {line}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </>
                );
              }}
            ></Field>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field
              name="helpline2"
              render={({ form: { setFieldValue }, form }: FieldProps) => {
                if (getHelplinesByContractType(form.values.type) == null) {
                  return null;
                }
                return (
                  <>
                    <InputLabel htmlFor="helpline2">Helpline 2 Telefonnummer</InputLabel>
                    <Select
                      value={form.values.helpline2}
                      onChange={v => {
                        setFieldValue("helpline2", v.target.value);
                      }}
                    >
                      {getHelplineNumbersByContractTypeAndPrefix(
                        form.values.type,
                        form.values.helpline2Prefix,
                      )?.map(line => {
                        return (
                          <MenuItem key={line} value={line}>
                            {line}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </>
                );
              }}
            ></Field>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field
              name="helplineInternationalPrefix"
              render={({ form: { setFieldValue }, form }: FieldProps) => {
                if (getHelplinesByContractType(form.values.type) == null) {
                  return null;
                }
                return (
                  <>
                    <InputLabel htmlFor="helplineInternationalPrefix">Ausland Vorwahl</InputLabel>
                    <Select
                      value={form.values.helplineInternationalPrefix}
                      onChange={v => {
                        setFieldValue("helplineInternationalPrefix", v.target.value);
                        setFieldValue("helplineInternational", '');
                      }}
                    >
                      <MenuItem key={-1} value={"-"}>
                        {"-"}
                      </MenuItem>
                      {getInternationHelplinePrefixesByContractType(form.values.type)?.map(line => {
                        return (
                          <MenuItem key={line} value={line}>
                            {line}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </>
                );
              }}
            ></Field>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field
              name="helplineInternational"
              render={({ form: { setFieldValue }, form }: FieldProps) => {
                if (getHelplinesByContractType(form.values.type) == null) {
                  return null;
                }
                return (
                  <>
                    <InputLabel htmlFor="helplineInternational">Ausland Telefonnummer</InputLabel>
                    <Select
                      value={form.values.helplineInternational}
                      onChange={v => {
                        setFieldValue("helplineInternational", v.target.value);
                      }}
                    >
                      {getInternationHelplineNumbersByContractTypeAndPrefix(
                        form.values.type,
                        form.values.helplineInternationalPrefix,
                      )?.map(line => {
                        return (
                          <MenuItem key={line} value={line}>
                            {line}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </>
                );
              }}
            ></Field>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AccountReportingSelect />
        </Grid>
      </Grid>
    </DialogForm>
  );
};
export default AccountContractDialog;
