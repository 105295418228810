import { createContext, useContext } from "react";
import { User } from "../../../generated/graphql";

type PaperShiftProvider = {
  setUsers: (users: Map<number, User>) => void;
  setActiveLegends: (value: number) => void;
  setActiveLegendsInitial: (value: number) => void;
  users: Map<number, User> | undefined;
  activeLegends: number[];
};

export const PaperShiftContext = createContext<PaperShiftProvider | undefined>(undefined);

export const usePaperShiftContext = () => {
  const context = useContext(PaperShiftContext);
  if (context === undefined) {
    throw new Error("PaperShiftContext must be used within a PaperShiftContext");
  }

  return context;
};
