import classnames from "classnames";
import dayjs from "dayjs";
import { useSnackbar } from "notistack/";
import React, { useState } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import WatchLater from "@material-ui/icons/WatchLater";

import { ChatClient } from "../../../containers/ChatListener/reducer";
import {
  useAssignTaskMutation,
  useCreateChatMutation,
  useSnoozeTaskMutation,
} from "../../../generated/graphql";
import useStyles from "../../assets/inboxStyles";
import DefaultDialog from "../../molecules/DefaultDialog";
import DefaultDialogContent from "../../molecules/DefaultDialogContent";
import AssignUserDialog from "../../organisms/AssignUserDialog";
import Check from "@material-ui/icons/CheckCircle";

export const InboxWrapper = ({
  children,
  isHeadline = false,
}: {
  children: any;
  isHeadline?: boolean;
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classnames({
        [classes.task]: !isHeadline,
      })}
    >
      {children}
    </Grid>
  );
};

export const ChatActions = ({
  chat,
  mayFinish = false,
}: {
  chat: ChatClient;
  mayFinish?: boolean;
}) => {
  //   const { isAuthorized, me } = useAuthorization();

  const [snoozeTask] = useSnoozeTaskMutation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState(dayjs());
  const [isOpen, setIsOpen] = useState(false);

  const finishChat = () => {};

  return (
    <div className={classes.actionWrapper}>
      <AssignChat client={chat} />
    </div>
  );
};

const AssignChat = ({ client }: { client: ChatClient }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const [createChat] = useCreateChatMutation({
    variables: {
      chatInput: {
        chatAlias: client.alias,
        chatUserId: client.user_id,
        account: client.account,
      },
    },
  });

  const handleClick = async (userId?: number) => {
    createChat();
    setOpen(false);
  };

  return (
    <Box color="primary" height={24} title="Assign Chat">
      <svg
        onClick={() => setOpen(true)}
        className={classes.actionItem}
        color="inherit"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 19.5C4 16.1625 10.6625 14.5 14 14.5C17.3375 14.5 24 16.1625 24 19.5V22H4V19.5Z"
          fill="currentColor"
        />
        <path
          d="M17.5355 10.5355C16.5979 11.4732 15.3261 12 14 12C12.6739 12 11.4021 11.4732 10.4645 10.5355C9.52678 9.59785 9 8.32608 9 7C9 5.67392 9.52678 4.40215 10.4645 3.46447C11.4021 2.52678 12.6739 2 14 2C15.3261 2 16.5979 2.52678 17.5355 3.46447C18.4732 4.40215 19 5.67392 19 7C19 8.32608 18.4732 9.59785 17.5355 10.5355Z"
          fill="currentColor"
        />
        <path d="M8 11.5L3.55556 16V13.3H0V9.7H3.55556V7L8 11.5Z" fill="currentColor" />
      </svg>
      <DefaultDialog open={open} onClose={() => setOpen(false)}>
        <DefaultDialogContent>
          <AssignUserDialog onSubmit={handleClick} headline="Assign Chat" />
        </DefaultDialogContent>
      </DefaultDialog>
    </Box>
  );
};
