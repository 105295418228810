import React from "react";
import DialogForm from "../DialogForm";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import GenderSelect from "../../molecules/GenderSelect";
import FormControl from "@material-ui/core/FormControl";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import {
  useUpdateOrCreateContactMutation,
  Person,
  Gender,
  AddressType,
  PhoneType,
} from "../../../generated/graphql";
import * as Yup from "yup";
import PhoneCountrySelect from "../../molecules/PhoneCountrySelect";

type TBusinessContactPersonDialog = {
  businessId: number;
  open: boolean;
  person?: Person;
  toggle: () => void;
};

const BusinessContactPersonDialog = ({
  businessId,
  open,
  toggle,
  person,
}: TBusinessContactPersonDialog) => {
  const [action] = useUpdateOrCreateContactMutation();
  return (
    <DialogForm
      open={open}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required("Pflichtangabe"),
        lastName: Yup.string().required("Pflichtangabe"),
      })}
      initialValues={{
        firstName: person?.firstName || "",
        lastName: person?.lastName || "",
        title: person?.title || "",
        gender: person?.gender || Gender.unknown,
        note: person?.note || "",
        addresses:
          person?.addresses && person?.addresses.length
            ? person.addresses
            : [
                {
                  street: "",
                  city: "",
                  zip: "",
                  type: AddressType.work,
                },
              ],
        phones:
          person?.phones && person.phones.length
            ? person.phones
            : [{ areaCode: "", number: "", type: PhoneType.work }],
        emails: person?.emails && person.emails.length ? person.emails : [{ email: "" }],
      }}
      onClose={toggle}
      title={`Ansprechpartner ${person ? "bearbeiten" : "anlegen"}`}
      onSubmit={values => {
        return action({
          variables: {
            id: businessId,
            personId: person?.id,
            input: {
              firstName: values.firstName,
              lastName: values.lastName,
              title: values.title,
              gender: values.gender,
              note: values.note,
              phones: values.phones.map((p: any) => ({
                id: p.id || null,
                areaCode: p.areaCode,
                type: p.type,
                number: p.number,
              })),
              emails: values.emails.map((e: any) => ({ email: e.email, id: e.id || null })),
              addresses: values.addresses.map((a: any) => ({
                id: a.id || null,
                street: a.street,
                zip: a.zip,
                city: a.city,
                type: a.type,
              })),
            },
          },
        }).then(() => {
          toggle();
        });
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field name="firstName" label="Vorname" component={TextField} autoFocus />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field name="lastName" label="Nachname" component={TextField} />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field name="title" label="Akademischer Titel" component={TextField} />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <GenderSelect />
        </Grid>
      </Grid>
      <Grid container spacing={2} component={Box} my={1}>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <Field
              name="note"
              label="Notiz"
              component={TextField}
              placeholder="Funktion/Position im Unternehmen"
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <Field name="addresses[0].street" label="Straße und Hausnummer" component={TextField} />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <Field name="addresses[0].zip" label="Postleitzahl" component={TextField} />
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth margin="normal">
            <Field name="addresses[0].city" label="Stadt" component={TextField} />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <PhoneCountrySelect name="phones[0].countryCode" />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <Field name="phones[0].areaCode" label="Vorwahl" component={TextField} />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <Field name="phones[0].number" label="Nummer" component={TextField} />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <Field name="emails[0].email" label="E-Mail-Adresse" component={TextField} />
          </FormControl>
        </Grid>
      </Grid>
    </DialogForm>
  );
};

export default BusinessContactPersonDialog;
