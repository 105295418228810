import React, { FC } from "react";
import classnames from "classnames";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import Grid from "@material-ui/core/Grid";

import useStyles from "../inboxStyles";
import Loading from "../../atoms/Loading";
import InboxLabelFilter from "../../molecules/InboxLabelFilter";
import List from "../../organisms/InboxLists";
import { useAppointmentsByDaySubscription } from "../../../hooks/useAppointmentsByDaySubscription";
import { useTasksSubscription } from "../../../hooks/useTasksSubscription";

const now = dayjs();
const currentDayStart = now.startOf("day");
const currentDayEnd = now.endOf("day");
const oneMonthAgo = now.subtract(1, "month");
const oneDayAgo = currentDayEnd.subtract(1, "day");

const InboxAdmin = () => {
  const classes = useStyles();

  const { filter } = useParams<{ filter: string }>();

  const showOverdue = filter === "overdue";
  const showUnassigned = filter === "unassigned";
  const showPool = filter === "pool";

  const showAppointments = showOverdue || showPool;

  const appointmentsFilter = {
    startsAt: showOverdue ? oneMonthAgo.toISOString() : currentDayStart.toISOString(),
    endsAt: showOverdue ? now.toISOString() : currentDayEnd.toISOString(),
  };

  const tasksFilter = {
    pool: showUnassigned,
    ...(showOverdue ? { duedate: oneDayAgo.toISOString() } : []),
    withSnoozed: true,
  };

  return (
    <Grid container className={classnames(classes.content, classes.contentBox)}>
      <InboxLabelFilter scope="admin" />
      <Grid container>
        <Loader
          appointmentsFilter={appointmentsFilter}
          tasksFilter={tasksFilter}
          showAppointments={showAppointments}
        />
      </Grid>
    </Grid>
  );
};

interface LoaderProps {
  appointmentsFilter: {
    startsAt: string;
    endsAt: string;
  };
  tasksFilter: {
    pool: boolean;
    duedate?: string;
    withSnoozed?: boolean;
  };
  showAppointments?: boolean;
}

const Loader: FC<LoaderProps> = ({ appointmentsFilter, tasksFilter, showAppointments }) => {
  const { appointments, loading: loadingApps } = useAppointmentsByDaySubscription(
    appointmentsFilter,
  );

  const { tasks, loading: loadingTasks } = useTasksSubscription(tasksFilter);

  if (loadingTasks || loadingApps) {
    return <Loading />;
  }

  const noDataLoaded = !tasks.length && !appointments.length;

  const tasksWithoutTeam = tasks.filter(e => e.team == null);

  return (
    <Grid item xs={12}>
      <Grid container justify="center">
        {showAppointments && !loadingTasks && (
          <List.AppointmentList isAdmin appointments={appointments} />
        )}
        <List.TaskList isAdmin tasks={tasksWithoutTeam} showPlaceholder={noDataLoaded} />
      </Grid>
    </Grid>
  );
};

export default InboxAdmin;
