import React, { useState } from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useMutation } from "@apollo/client";
import { Formik } from "formik";

import {
  Address,
  AddressType,
  CreateAddressesDocument,
  UpdateAddressesDocument,
  DeleteAddressDocument,
} from "../../../../generated/graphql";
import AddressItem from "../../../atoms/Address";
import { AddressTypeMap } from "../../../molecules/AddressTypeSelect";
import DataColumn from "../../../molecules/DataColumn";
import DataRow from "../../../molecules/DataRow";
import DefaultDialog from "../../../molecules/DefaultDialog";
import DialogContent from "../../../molecules/DefaultDialogContent";
import { isValidAddress } from "../../../../utils/getAddressDistance";

import { ContactAllowed } from "./components";
import ContactAddressesForm from "./ContactAddressesForm";

const newAddress = {
  city: "",
  contactAllowed: true,
  street: "",
  type: AddressType.private,
  zip: "",
};
type TContactAddresses = {
  addresses: Address[];
  personId: number;
  created: Address[];
  setCreated: React.Dispatch<React.SetStateAction<Address[]>>;
};
const ContactAddresses = ({ addresses, personId, setCreated, created }: TContactAddresses) => {
  const [open, setOpen] = useState(false);

  const [updatePersonAddresses] = useMutation(UpdateAddressesDocument);
  const [createPersonAddress] = useMutation(CreateAddressesDocument);
  const [deletePersonAddress] = useMutation(DeleteAddressDocument);

  const newElements: Address[] =
    addresses.length < 1 && created.length < 1 ? [newAddress] : created;

  const handleRemove = (id: number) => {
    deletePersonAddress({
      variables: {
        personId,
        id,
      },
    });
  };

  const handleUpdate = async (
    values: { create?: Address[]; update?: Address[] },
    autosave = true,
  ) => {
    // Persist newly created addresses
    setCreated(values.create || []);

    if (values.create && !autosave) {
      createPersonAddress({
        variables: {
          addressesInput: values.create.filter(isValidAddress),
          personId,
        },
      });
    }

    if (values.update) {
      updatePersonAddresses({
        variables: {
          personId,
          addressesInput: values.update.map(address => {
            return {
              id: address.id,
              city: address.city,
              contactAllowed: address.contactAllowed,
              street: address.street,
              type: address.type,
              zip: address.zip,
              skipUpdateGeocode: autosave,
            };
          }),
        },
      });
    }
  };

  return (
    <DataRow title={`Adresse${addresses.length > 1 ? "n" : ""}`} editTrigger={() => setOpen(true)}>
      <Addresses addresses={addresses} />
      <DefaultDialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <Formik
            enableReinitialize
            onSubmit={async (values, { setSubmitting }) => {
              await handleUpdate(values, false);
              setCreated([]);
              setSubmitting(false);
              setOpen(false);
            }}
            initialValues={{
              update: addresses,
              create: newElements,
            }}
          >
            {props => (
              <ContactAddressesForm
                cancel={() => setOpen(false)}
                {...props}
                create={() => {
                  props.values.create.push(newAddress);
                  props.validateForm();
                }}
                remove={handleRemove}
              />
            )}
          </Formik>
        </DialogContent>
      </DefaultDialog>
    </DataRow>
  );
};

const Addresses = ({ addresses }: { addresses: Address[] }) => {
  if (addresses.length < 1) {
    return (
      <DataColumn>
        <Typography variant="body2">-</Typography>
      </DataColumn>
    );
  }

  return (
    <>
      {addresses.map(address => {
        const contactAllowed = !!address.contactAllowed;
        const type = address.type;

        return (
          <DataColumn
            label={AddressTypeMap.get(type || AddressType.private)}
            key={address.id || "address"}
            xs={12}
          >
            <Box>{!!address ? <AddressItem address={address} /> : "-"}</Box>
            <ContactAllowed contactAllowed={contactAllowed}>
              {contactAllowed ? "Briefverkehr erlaubt" : "Kein Briefverkehr"}
            </ContactAllowed>
          </DataColumn>
        );
      })}
    </>
  );
};
export default ContactAddresses;
