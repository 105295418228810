import React from "react";

import { Box, Grid, Link, Typography } from "@material-ui/core";
import { Create } from "@material-ui/icons";

import ClickBox from "../../atoms/ClickBox";

export interface IItem {
  title: string;
  key: string;
  additionalInfo?: string;
  onClick: () => void;
}

interface IItemList {
  items: IItem[];
}

const ItemList = ({ items }: IItemList) => {
  return (
    <Box mb={3}>
      {items.map(item => {
        return (
          <ClickBox
            icon={
              <Box mr={2}>
                <Create color="primary" />
              </Box>
            }
            handleClick={item.onClick}
            key={item.key}
          >
            <Box minHeight={48} width="100%" alignItems="center" display="flex">
              <Grid container alignItems="center" spacing={2} justify="space-between">
                <Grid item xs={9}>
                  <Link variant="h4" color="primary">
                    {item.title}
                  </Link>
                </Grid>

                {item.additionalInfo !== undefined ? (
                  <Grid item xs={3}>
                    <Typography variant="body2">{item.additionalInfo}</Typography>
                  </Grid>
                ) : null}
              </Grid>
            </Box>
          </ClickBox>
        );
      })}
    </Box>
  );
};

export default ItemList;
