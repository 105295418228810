import React, { FC } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ChevronRight from "@material-ui/icons/ChevronRight";
import classnames from "classnames";

import useStyles from "./styles";

type TCard = {
  icon?: React.ReactNode;
  actionIcon?: React.ReactNode;
  onClick?: () => void;
  title?: React.ReactNode | string | null;
  topCard?: boolean;
  log?: React.ReactNode | string | null;
};
const Card: FC<TCard> = props => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classnames(classes.card)}>
      <div className={classes.header} onClick={props.onClick}>
        {props.icon}
        {props.title && (
          <Typography
            variant="h3"
            component={Box}
            flexGrow={1}
            className={classnames({
              [classes.clickable]: !!props.onClick,
            })}
          >
            {props.title}
          </Typography>
        )}
        {!!props.actionIcon && props.actionIcon}
        {/* {!!props.onClick && !props.actionIcon && <ChevronRight />} */}
        {!!props.onClick && !props.actionIcon && !!props.log && <>{props.log}</>}
        {/* TODO Shaahin - NB for log issue */}
      </div>
      {props.children}
    </Grid>
  );
};

export default Card;
