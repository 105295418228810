import React, { useEffect } from "react";
import classNames from "classnames";
import dayjs from "dayjs";

import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";

import { ObservableJob, JobStatus, useObservableJobQuery } from "../../../generated/graphql";

import drive from "../../assets/googleDrive.svg";

import useStyles from "./styles";

type TGeneratedDriveFile = {
  personId: number;
  job: Pick<ObservableJob, "id" | "status" | "link" | "error">;
  displayName: string;
};
const GeneratedDriveFile = ({ personId, displayName, job }: TGeneratedDriveFile) => {
  const { data, loading, startPolling, stopPolling, error: queryError } = useObservableJobQuery({
    variables: { id: job.id },
    fetchPolicy: "network-only",
  });

  const classes = useStyles();

  const observable = data?.observableJob || job;

  const issue = queryError || observable.error;

  useEffect(() => {
    if (observable.status === JobStatus.running && !issue) {
      startPolling(1000);
    } else {
      stopPolling();
    }
    return () => {
      stopPolling();
    };
  }, [observable, issue, startPolling, stopPolling]);

  const fileName = `Datenauskunft-ID${personId}-${displayName}-${dayjs().format("YYYYMMDD")}`;
  return (
    <div className={classes.borderArea}>
      <Grid container className={classes.documentArea}>
        <Grid item xs={8} className={classes.column}>
          <Box mr={2} display="flex">
            <img
              alt="Google Drive"
              src={drive}
              width={24}
              height={24}
              className={classNames({ [classes.incomleteFile]: loading || issue })}
            />
          </Box>
          {observable.status !== JobStatus.success ? (
            <Typography variant="body2" className={classes.onelineEllipsis}>
              {fileName}
            </Typography>
          ) : (
            <Link
              variant="h4"
              className={classes.onelineEllipsis}
              href={observable.link || ""}
              target="blank"
            >
              {fileName}
            </Link>
          )}
        </Grid>

        <Grid xs={4} item className={classes.column}>
          {loading ||
            (observable.status === JobStatus.running && (
              <>
                <Box mr={2} display="flex">
                  <CircularProgress size={24} />
                </Box>
                <Typography variant="body2">Datenauskunft wird generiert</Typography>
              </>
            ))}
        </Grid>
      </Grid>

      {issue ? (
        <Box mb={2} width="100%">
          <Alert severity="error">{issue}</Alert>
        </Box>
      ) : null}
    </div>
  );
};

export default GeneratedDriveFile;
