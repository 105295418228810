import React, { FC } from "react";

import Create from "@material-ui/icons/Create";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

import useStyles from "./styles";

const EditIcon: FC<SvgIconProps> = props => {
  const classes = useStyles();

  return <Create {...props} className={classes.createIcon} />;
};

export default EditIcon;
