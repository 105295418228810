import React, { useState } from "react";

import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import { useFinishChatMutation, PersonActivity } from "../../../generated/graphql";
import PrimaryButton from "../../atoms/PrimaryButton";
import Notification from "../../molecules/Notification";
import SecondaryButton from "../../atoms/SecondaryButton";
import InteractionCompletedDialog from "../../organisms/InteractionCompletedDialog";

import AssignChatModal from "./AssignChatModal";
import { Alert } from "@material-ui/lab";

type TChatInput = {
  activity: PersonActivity;
  onSubmit: (text: string) => void;
  lastMessageUid: number;
  token: string;
  error: string | null;
};

const ChatInput = ({ activity, error, onSubmit, lastMessageUid, token }: TChatInput) => {
  const chatId = activity!.chat!.id!;
  const chatUserId = activity!.chat!.chatUserId!;
  const { push } = useHistory();
  const [finishChat] = useFinishChatMutation({
    variables: {
      id: chatId,
      uid: lastMessageUid,
      chatUserId,
      token,
    },
  });
  const [text, setText] = useState<string | undefined>();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [reassignOpen, setReassignOpen] = useState(false);

  return (
    <Box
      position="fixed"
      bottom={0}
      left={0}
      width={1}
      bgcolor="#F9F3E9"
      borderTop="1px solid #bdbdbd"
    >
      <Box justifyContent="center" display="flex" mx={3} py={4}>
        <Grid container>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <Box paddingBottom={1}>
              <TextField
                placeholder="Deine Nachricht"
                inputProps={{
                  maxLength: 255,
                }}
                fullWidth
                value={text}
                onChange={event => {
                  setText(event.target.value);
                }}
              />
              <Typography
                variant="body2"
                color={text && text.length > 244 ? "error" : "textSecondary"}
              >
                {text && text.length > 254
                  ? "Du hast das Zeichenlimit erreicht"
                  : `Noch ${255 - (!!text ? text.length : 0)} Zeichen`}
              </Typography>
            </Box>

            {error !== null ? (
              <Box mb={2}>
                <Alert severity="error">{error}</Alert>
              </Box>
            ) : null}

            <Box display="flex" justifyContent="center">
              <SecondaryButton
                type="submit"
                onClick={async () => {
                  setOpen(true);
                }}
                tabIndex={-1}
              >
                Chat beenden
              </SecondaryButton>
              <Box margin={1} />
              <PrimaryButton onClick={() => setReassignOpen(true)}>Weiterleiten</PrimaryButton>
              <Box margin={1} />
              <PrimaryButton
                type="submit"
                disabled={!text}
                onClick={() => {
                  setText("");
                  if (!!text) {
                    onSubmit(text);
                  }
                }}
              >
                Nachricht abschicken
              </PrimaryButton>
            </Box>

            <AssignChatModal
              chatId={chatId}
              chatUserId={chatUserId}
              open={reassignOpen}
              onClose={() => setReassignOpen(false)}
            />

            <InteractionCompletedDialog
              activity={activity}
              open={open}
              handleClose={async () => {
                await finishChat();
              }}
              handleNotifyClose={async () => {
                await finishChat();
                enqueueSnackbar("", {
                  content: (key: string) => (
                    <Notification
                      id={key}
                      message={
                        <>
                          Ich erinnere dich in der Inbox <br />
                          an das Gesprächsprotokoll.
                        </>
                      }
                    />
                  ),
                });

                setOpen(false);
                push(`/person/${activity?.person?.id}`);
              }}
            />
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ChatInput;
