import { useEffect } from "react";
import {
  Client,
  useMyStcClientsQuery,
  MyStcClientsSubscriptionSubscription,
  MyStcClientsSubscriptionDocument,
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

export const useMySTCClientsSubscription = () => {
  const { data, loading, subscribeToMore } = useMyStcClientsQuery({ fetchPolicy });

  useEffect(() => {
    const unsubscribe = subscribeToMore<MyStcClientsSubscriptionSubscription>({
      document: MyStcClientsSubscriptionDocument,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !prev) return prev;
        return { mySTCClients: subscriptionData.data.mySTCClientsSubscription };
      },
    });

    return unsubscribe;
  });
  return { clients: (data?.mySTCClients || []) as Client[], loading };
};
