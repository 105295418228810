import React from "react";

import { FormikProps } from "formik";

import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { useGetAllTeamsQuery } from "../../../generated/graphql";
import getTeamName from "../../../utils/getTeamName";

type AssignTeamDialogProps = { disabled?: boolean; handleChange: (e: any) => void };

const AssignTeamDialog = (props: AssignTeamDialogProps) => {
  const getAllTeams = useGetAllTeamsQuery();

  const teams = getAllTeams.data?.getAllTeams ?? [];

  const disabled = props.disabled || getAllTeams.loading || Boolean(getAllTeams.error);

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel>Team</InputLabel>

      <Select
        onChange={(e) => props.handleChange(e.target.value)}
        defaultValue={-1}
        displayEmpty
        disabled={disabled}
      >
        <MenuItem key="" value={-1}>
          <Typography color={disabled ? "textSecondary" : "primary"} variant="body2">
            nicht ausgewählt
          </Typography>
        </MenuItem>

        {teams.map(team => {
          return (
            <MenuItem key={team} value={team}>
              <Typography color={disabled ? "textSecondary" : "primary"} variant="body2">
                {getTeamName(team)}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};


export default AssignTeamDialog;
