import React, { FC } from "react";

import Box from "@material-ui/core/Box";
import ChevronRight from "@material-ui/icons/ChevronRight";

import useStyles from "./styles";

type TClickableListItem = {
  onBackground?: boolean;
  onClick: () => void;
};

const ClickableListItem: FC<TClickableListItem> = ({ onBackground = true, children, onClick }) => {
  const classes = useStyles({ onBackground });

  return (
    <Box
      display="flex"
      color="primary.main"
      alignItems="center"
      paddingY={1}
      paddingLeft={2}
      justifyContent="space-between"
      flex={1}
      width={1}
      className={classes.clickable}
      onClick={onClick}
    >
      {children}
      <ChevronRight />
    </Box>
  );
};

export default ClickableListItem;
