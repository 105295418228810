import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  form: {
    width: "100%",
  },
  radioRoot: {
    marginRight: theme.spacing(5),
  },
  formControl: {
    marginTop: theme.spacing(3),
  },
  memoContainer: {
    background: '#e1e7e8',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
}));
