import { isBefore12Month, isYesterday, isBeforeYesterday } from "./dateHelper";
import dayjs from "dayjs";

export function streamFormat(time: dayjs.Dayjs) {
  let timeFormat = "H:mm [Uhr]";
  if (isYesterday(time)) {
    timeFormat = "[Gestern] H:mm [Uhr]";
  }
  if (isBeforeYesterday(time)) {
    timeFormat = "[am] D. MMMM [um] H:mm [Uhr]";
  }
  if (isBefore12Month(time)) {
    timeFormat = "[am] D.MM.YYYY [um] H:mm [Uhr]";
  }
  return timeFormat;
}
