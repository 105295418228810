import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    item: {
      cursor: "pointer",
      transition: theme.transitions.create("background-color", {
        duration: theme.transitions.duration.standard,
        easing: theme.transitions.easing.easeInOut,
      }),
      "&:hover": {
        backgroundColor: theme.palette.common.white,
      },      
    },
    itemHeader: {
      cursor: "pointer",
      
    },
  };
});

export default useStyles;
