import React from "react";
import dayjs from "dayjs";
import Typography from "@material-ui/core/Typography";

import { fullTypeMap } from "../../../utils/activityHelper";
import { PersonActivity } from "../../../generated/graphql";

import useStyles from "./styles";

type TActivityText = {
  activity: Pick<PersonActivity, "memo" | "activityType" | "start" | "end" | "id">;
  personName: string;
};

const ActivityText = ({ activity, personName }: TActivityText) => {
  const classes = useStyles();
  const type = activity?.activityType || "callIn";
  const start = activity?.start;
  const end = activity?.end;
  if (!start) return <></>;
  const activityStart = dayjs(start);

  let startFormat = "D. MMMM [von]";
  if (dayjs().year() < activityStart.year()) {
    startFormat = "D. MMMM YYYY [von]";
  }
  return (
    <>
      <Typography variant="h2" className={classes.dataText}>
        {fullTypeMap(type)} mit {personName}
      </Typography>
      {type !== "mail" && (
        <Typography variant="h2" className={classes.dataText}>
          am {dayjs(start).format(`${startFormat} H:mm`)}{" "}
          {end ? `bis ${dayjs(end).format(`H:mm`)}` : ""} Uhr
          {end && <> ({dayjs(end).diff(activityStart, "minute")} Minuten)</>}
        </Typography>
      )}
    </>
  );
};

export default ActivityText;
