import React from "react";

import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Grid from "@material-ui/core/Grid";

import { Checkbox } from "formik-material-ui";
import { Field } from "formik";

import RelationTypeSelect from "../../molecules/RelationTypeSelect";

type TRelationField = {
  allowEmergencyContact: boolean;
  namePrefix: string;
};
const RelationField = ({ allowEmergencyContact, namePrefix }: TRelationField) => {
  const name = namePrefix ? `${namePrefix}.` : "";

  return (
    <Box mb={3} width="100%">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RelationTypeSelect name={`${name}relationship`} />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-start">
            <FormControl margin="none">
              <FormControlLabel
                disabled={!allowEmergencyContact}
                control={
                  <Field
                    label={"Notfallkontakt"}
                    name={`${name}isEmergency`}
                    component={Checkbox}
                  />
                }
                label={"Notfallkontakt"}
              />
            </FormControl>
            <FormControl margin="none">
              <FormControlLabel
                control={
                  <Field
                    label={"Lebt im Haushalt des Klienten"}
                    name={`${name}isHousehold`}
                    component={Checkbox}
                  />
                }
                label={"Lebt im Haushalt des Klienten"}
              />
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default RelationField;
