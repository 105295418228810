import React, { Dispatch, SetStateAction, useState } from "react";
import dayjs from "dayjs";
import * as Yup from "yup";
import { Formik, Form, Field, FieldProps } from "formik";
import DayjsUtils from "@date-io/dayjs";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Button from "../../atoms/PrimaryButton";
import { StyledKeyboardDatePickerFitsGrid } from "../../atoms/StyledFormComponents";
import Autocomplete from "../../molecules/Autocomplete";
import {
  useAccountsQuery,
  Account,
  AccountStatisticsQueryVariables,
} from "../../../generated/graphql";
import { QueryLazyOptions } from "@apollo/client";
import { fetchPolicy } from "../../../utils/constants";

const validation = Yup.object().shape({
  accountId: Yup.number().required("Bitte ausfüllen"),
});

export default function StatisticsForm({
  query,
  setQuery,
  disabled = false,
}: {
  query: (options?: QueryLazyOptions<AccountStatisticsQueryVariables> | undefined) => void;
  setQuery: Dispatch<SetStateAction<string | undefined>>;
  disabled: boolean;
}) {
  const { data, loading } = useAccountsQuery({ fetchPolicy });
  const accounts = data?.accounts as Account[];
  const [accountId, setAccountId] = useState();
  return (
    <Formik
      initialValues={{
        accountId: "",
        start: dayjs().subtract(1, "year"),
        end: dayjs(),
      }}
      onSubmit={(values, { setSubmitting }) => {
        setQuery(undefined);

        query({
          variables: {
            accountId: parseInt(values.accountId, 10),
            start: values.start,
            end: values.end,
          },
        });
        setQuery("account");
        setSubmitting(false);
      }}
      validationSchema={validation}
    >
      {props => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl margin="normal" fullWidth>
                <Field
                  disabled={disabled}
                  name="accountId"
                  render={({ field, form: { setFieldValue, errors, touched } }: FieldProps) => {
                    return (
                      <Autocomplete
                        disabled={disabled}
                        hasError={!!errors[field.name] && !!touched[field.name]}
                        error={
                          !!errors[field.name] && !!touched[field.name]
                            ? errors[field.name]
                            : undefined
                        }
                        label="Account"
                        placeholder="Bitte ausfüllen"
                        handleChange={(o: any) => {
                          setFieldValue(field.name, o.value);
                          setAccountId(o);
                        }}
                        value={accountId}
                        loading={loading}
                        suggestions={
                          accounts
                            ? accounts.map((account: Account) => {
                                if (account) {
                                  return {
                                    value: account.id,
                                    label: account.name,
                                  };
                                }
                                return null;
                              })
                            : []
                        }
                      />
                    );
                  }}
                ></Field>
              </FormControl>
            </Grid>
            <MuiPickersUtilsProvider utils={DayjsUtils} locale="de">
              <Grid item xs={6}>
                <StyledKeyboardDatePickerFitsGrid
                  name="date"
                  format="DD.MM.YYYY"
                  value={props.values.start}
                  onChange={value => {
                    props.setFieldValue("start", value || dayjs());
                  }}
                  disableFuture
                  label="Ab Datum"
                  cancelLabel="Abbrechen"
                  okLabel="OK"
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={6}>
                <StyledKeyboardDatePickerFitsGrid
                  disableFuture
                  name="date"
                  format="DD.MM.YYYY"
                  value={props.values.end}
                  onChange={value => {
                    props.setFieldValue("end", value || dayjs());
                  }}
                  label="Bis Datum"
                  cancelLabel="Abbrechen"
                  okLabel="OK"
                  disabled={disabled}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" mt={4}>
                <Button type="submit" disabled={!props.isValid || props.isSubmitting || disabled}>
                  Statistik erstellen
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
