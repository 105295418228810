import React from "react";
import IconButton from "@material-ui/core/IconButton";

import unassignedAvatar from "../../assets/unassignedAvatar.svg";
import Avatar from "../Avatar";

import useStyles from "./styles";

interface UnassignedUserButtonProps {
  setOpen?: () => void;
}

const UnassignedUserButton = ({ setOpen }: UnassignedUserButtonProps) => {
  const classes = useStyles();

  return (
    <IconButton classes={{ root: classes.iconButton }} onClick={setOpen} disabled={!setOpen}>
      ​ <Avatar src={unassignedAvatar} widthfactor={5}></Avatar>
    </IconButton>
  );
};

export default UnassignedUserButton;
