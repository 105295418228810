import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme: Theme) => {
  return {
    avatar: {
      background: theme.palette.primary.main,
      color: "#fff",
    },
    bubble: isClient => ({
      position: "relative",
      "&:before": {
        borderBottom: `8px solid transparent`,
        borderLeft: isClient ? `0px solid transparent` : `16px solid #FFFFFF`,
        borderRight: isClient ? `16px solid #F2FCFF` : `0px solid transparent`,
        borderTop: `8px solid transparent`,
        bottom: 0,
        content: '""',
        height: 0,
        ...(isClient ? { left: -16 } : { right: -16 }),
        margin: "auto 0",
        position: "absolute" as "absolute",
        top: 0,
        width: 0,
      },
    }),
  };
});
