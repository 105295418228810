import React, { FC } from "react";

import * as Yup from "yup";
import DayjsUtils from "@date-io/dayjs";
import { Formik, Form } from "formik";
import Box from "@material-ui/core/Box";
import dayjs from "dayjs";

import Typography from "@material-ui/core/Typography";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { useUpdateAbsentMutation, User } from "../../../generated/graphql";

import DefaultDialog from "../../molecules/DefaultDialog";
import DefaultDialogContent from "../../molecules/DefaultDialogContent";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import { UserSelectFieldContainer } from "../../forms/UserSelectField";

const validationSchema = Yup.object().shape({
  userIds: Yup.number().min(0, "Bitte wähle einen Mitarbeiter aus"),
  absentUntil: Yup.date().min(
    new Date(
      dayjs()
        .startOf("day")
        .toISOString(),
    ),
    "Das Datum muss in der Zukunft liegen",
  ),
});

type TAbsenceDialog = {
  user?: User;
  open: boolean;
  onClose: () => void;
};

const AbsenceDialog: FC<TAbsenceDialog> = ({ user, open, onClose }) => {
  const [updateAbsent] = useUpdateAbsentMutation();

  let initialValues = { absentUntil: dayjs().endOf("day"), userIds: -1 };
  if (!!user) {
    initialValues = {
      absentUntil: user.absentUntil,
      userIds: user!.id!,
    };
  }

  return (
    <>
      <DefaultDialog open={open} onClose={onClose}>
        <DefaultDialogContent>
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={async ({ absentUntil, userIds }) => {
              await updateAbsent({
                variables: {
                  id: userIds,
                  absentUntil,
                },
              });
              onClose();
            }}
          >
            {({ setFieldValue, isValid, values: { absentUntil } }) => {
              return (
                <Form style={{ width: "100%" }}>
                  <Box mb={3} textAlign="center">
                    <Typography variant="h1">Abwesenheit eines Mitarbeiters eintragen</Typography>
                  </Box>

                  {!!user ? (
                    <Box mt={5} mb={3}>
                      <Typography variant="h3">{user.name}</Typography>
                    </Box>
                  ) : (
                    <UserSelectFieldContainer />
                  )}

                  <MuiPickersUtilsProvider utils={DayjsUtils}>
                    <DatePicker
                      margin="normal"
                      label="Abwesend bis"
                      disablePast
                      fullWidth
                      cancelLabel="Abbrechen"
                      okLabel="OK"
                      name="absentUntil"
                      format="DD.MM.YYYY"
                      value={absentUntil}
                      onChange={value => {
                        setFieldValue("absentUntil", value?.endOf("day"));
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <Box display="flex" justifyContent="center" mt={3}>
                    <SecondaryButton onClick={onClose}>Abbrechen</SecondaryButton>
                    <Box mx={1} />
                    <PrimaryButton type="submit" disabled={!isValid}>
                      OK
                    </PrimaryButton>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </DefaultDialogContent>
      </DefaultDialog>
    </>
  );
};

export default AbsenceDialog;
