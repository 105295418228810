import React, { FC } from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

import useStyles from "./styles";

const TaskIcon: FC<SvgIconProps> = ({ fill }) => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 24 24" classes={{ root: classes.taskIconRoot }} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.99988 3C1.89531 3 0.999878 3.89543 0.999878 5L0.999878 21C0.999878 22.1046 1.89531 23 2.99988 23L13.9999 23C15.1044 23 15.9999 22.1046 15.9999 21L15.9999 5C15.9999 3.89543 15.1044 3 13.9999 3L2.99988 3ZM3.99988 6C3.44759 6 2.99988 6.44772 2.99988 7C2.99988 7.55228 3.44759 8 3.99988 8L8.99988 8C9.55216 8 9.99988 7.55228 9.99988 7C9.99988 6.44772 9.55216 6 8.99988 6L3.99988 6ZM2.99988 19C2.99988 18.4477 3.44759 18 3.99988 18L12.9999 18C13.5522 18 13.9999 18.4477 13.9999 19C13.9999 19.5523 13.5522 20 12.9999 20L3.99988 20C3.44759 20 2.99988 19.5523 2.99988 19ZM3.99988 12C3.44759 12 2.99988 12.4477 2.99988 13C2.99988 13.5523 3.44759 14 3.99988 14L12.9999 14C13.5522 14 13.9999 13.5523 13.9999 13C13.9999 12.4477 13.5522 12 12.9999 12L3.99988 12Z"
        fill={!!fill ? fill : "#025879"}
      />
      <path
        d="M15.5563 17.3585C15.3424 17.7289 15.0154 18.021 14.6233 18.1919L10.5981 19.9463L10.1048 15.5832C10.0568 15.1581 10.1463 14.7289 10.3601 14.3585L17.65 1.732C18.2023 0.775413 19.4255 0.447664 20.3821 0.999949L22.1141 1.99995C23.0707 2.55223 23.3985 3.77541 22.8462 4.732L15.5563 17.3585Z"
        fill="white"
      />
      <path
        d="M14.361 17.4288L11.3414 18.6589L10.8969 15.4288L17.3347 4.27817L20.7988 6.27817L14.361 17.4288Z"
        fill={!!fill ? fill : "#025879"}
      />
      <path
        d="M21.228 5.53467L17.7639 3.53467L18.5515 2.17057C18.8276 1.69227 19.4392 1.5284 19.9175 1.80454L21.6496 2.80454C22.1279 3.08068 22.2917 3.69227 22.0156 4.17057L21.228 5.53467Z"
        fill={!!fill ? fill : "#025879"}
      />
    </SvgIcon>
  );
};

export default TaskIcon;
