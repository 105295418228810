import React from "react";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import ResearchStatusIcon from "../ResearchStatusIcon";
import { ServiceResearchObject } from "../../../generated/graphql";
import helper from "../../../utils/researchStatusHelper";

type TStatusRow = {
  researchObject: ServiceResearchObject;
  toggle: () => void;
};
const StatusRow = ({ researchObject, toggle }: TStatusRow) => {
  return (
    <Box display="flex">
      <ResearchStatusIcon status={researchObject.status} />
      <Box ml={2}>
        <Box>
          <Link onClick={toggle} color="primary" variant="h3" underline="always">
            {helper(researchObject.status)}
          </Link>
        </Box>
        {!!researchObject.statusChangeReason && (
          <Box mt={1}>
            <Typography variant="body2">{researchObject.statusChangeReason || ""}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default StatusRow;
