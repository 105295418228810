import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import {
  useBusinessObjectQuery,
  useMandatesQuery,
  useUpdateMemoMutation,
  useMemoQuery,
  MemoState,
  CounselingMandate,
  Person,
  Memo,
  BusinessObject,
} from "../../../generated/graphql";
import Loader from "../../atoms/Loading";
import BusinessMemoUpdateFormStep1 from "../../forms/BusinessMemoUpdateFormStep1";
import BusinessMemoWizard from "../../forms/BusinessMemoWizard";
import { fetchPolicy } from "../../../utils/constants";
import { memoMap } from "../../../utils/memoHelpers";
import useStyles from "./styles";

export default function BusinessMemoCreation() {
  const classes = useStyles();
  const { businessId, id } = useParams<{ id: string; businessId: string }>();
  const { data, refetch, loading } = useBusinessObjectQuery({
    variables: { id: parseInt(businessId, 10) },
    fetchPolicy,
  });
  const { data: mandatesData, loading: mLoading } = useMandatesQuery({
    variables: { businessId: parseInt(businessId, 10) },
    fetchPolicy,
  });
  const { data: memoData, loading: memoLoading } = useMemoQuery({
    variables: { id: parseInt(id, 10) },
    fetchPolicy,
  });
  const [step, setStep] = useState<number>();
  const [updateMemo] = useUpdateMemoMutation();

  const memo = memoData?.memo;
  const business = data?.businessObject;
  const fallBackPerson = business?.persons?.length ? (business?.persons[0] as Person) : null;
  const mandates = (mandatesData?.mandates || []) as CounselingMandate[];

  useEffect(() => {
    if (memo && memo.status) {
      if (memo.status === MemoState.finished) {
        setStep(2);
      } else {
        setStep(1);
      }
    }
  }, [memo]);

  const backAction = () => {
    if (!memo) return;
    updateMemo({
      variables: {
        memo: {
          id: memo.id,
          status: memoMap[step! - 1],
        },
      },
    }).then(() => {
      setStep(step! - 1);
    });
  };

  function renderForm() {
    if (business && memo && memo.status) {
      if (step! > 0) {
        return (
          <BusinessMemoWizard
            setStep={setStep}
            business={business as BusinessObject}
            memo={memo as Memo}
            step={step!}
            backAction={backAction}
            refetch={refetch}
          />
        );
      } else {
        return (
          <BusinessMemoUpdateFormStep1
            business={business as BusinessObject}
            mandates={mandates}
            memo={memo as Memo}
            customCallBack={() => {
              setStep(step! + 1);
              refetch();
            }}
          />
        );
      }
    }
    return <Loader />;
  }

  return (loading || mLoading || memoLoading) && !memo && !business ? (
    <Loader />
  ) : business && fallBackPerson ? (
    <Grid container className={classes.content}>
      <Grid item xs={2} xl={3} />
      <Grid item xs={8} xl={6}>
        {renderForm()}
      </Grid>
      <Grid item xs={2} xl={3} />
    </Grid>
  ) : (
    <div>Partner konnte nicht geladen werden</div>
  );
}
