import React from "react";

import { Link } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import MuLink from "@material-ui/core/Link";

import { Person } from "../../../generated/graphql";

type TPersonName = {
  person: Person;
  variant: "h4" | "h1";
};

export const PersonName = ({ person, variant }: TPersonName) => {
  if (person.temporary) {
    return (
      <Typography color="secondary" variant={variant}>
        „{person.emails && person.emails[0]?.email}”
      </Typography>
    );
  }

  return (
    <MuLink
      color="primary"
      component={Link}
      title={person.name!}
      to={`/person/${person.id}`}
      underline="always"
      variant={variant}
    >
      {person.name}
    </MuLink>
  );
};

export default PersonName;
