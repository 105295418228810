import React from "react";

import { Formik, Form, FormikProps, FormikValues, FormikActions } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import { Gender } from "../../../generated/graphql";

import { STEPS } from "../../organisms/PersonCreateDialog/reducer";
import { usePersonCreateContext } from "../../organisms/PersonCreateDialog/provider";

// FIXME: Unify
const birthDateValidation = Yup.date()
  .nullable()
  .when("ageGuessed", {
    is: false,
    then: Yup.date()
      .typeError("Bitte überprüfe das Format")
      .required("Bitte angeben"),
  });

const ageValidation = Yup.number().when("ageGuessed", {
  is: true,
  then: Yup.number()
    .min(0)
    .required("Bitte angeben"),
});

export const PersonValidation = Yup.object().shape({
  firstName: Yup.string().required("Bitte ausfüllen"),
  lastName: Yup.string().required("Bitte ausfüllen"),
});

export const initialAccountValues = {
  hrBusiness: "",
  informationSource: "",
  source: "",
}

export const LifeWorkAccountValidation = Yup.object().shape({  
});

export const GoogleAccountValidation = Yup.object().shape({
  hrBusiness: Yup.string().max(256, 'nicht mehr als 256 Zeichen'),  
  source: Yup.string().max(256, 'nicht mehr als 256 Zeichen'),
});

export const initialPersonValues = {
  firstName: "",
  lastName: "",
  gender: Gender.unknown,
  countryCode: "49",
};

export const ClientValidation = Yup.object().shape({
  firstName: Yup.string().required("Bitte ausfüllen"),
  lastName: Yup.string().required("Bitte ausfüllen"),
  account: Yup.object().shape({
    value: Yup.number()
      .min(0, "Bitte ausfüllen")
      .required("Bitte ausfüllen"),
  }),
  birthDate: birthDateValidation,
  initialAge: ageValidation,
});

export const initialClientValues = {
  firstName: "",
  lastName: "",
  countryCode: "49",
  account: { label: "Bitte auswählen", value: -1 },
  gender: Gender.unknown,
  ageGuessed: true,
  initialAge: "",
  birthDate: dayjs(),
};

export const BusinessValidation = Yup.object().shape({
  // name: Yup.string().required("Bitte ausfüllen"),
  contactPerson: Yup.string().required("Bitte ausfüllen"),
  // businessCategoryId: Yup.number()
  //   .min(0, "Bitte ausfüllen")
  //   .required("Bitte ausfüllen"),
});

export const initialBusinessValues = {
  name: "",
  contactPerson: "",
  businessCategoryId: -1,
  countryCode: "49",
};

type TCreateForm = {
  children: (props: FormikProps<FormikValues>) => React.ReactNode;
  validationSchema: typeof PersonValidation | typeof ClientValidation | typeof BusinessValidation | typeof GoogleAccountValidation | typeof LifeWorkAccountValidation;
  initialValues: {} | {} | {};
  onSubmit: (values: FormikValues, actios: FormikActions<FormikValues>) => void;
  title: string;
  hasBackButton?: boolean,
  confirmButtonText?: string,
};

const CreateForm = ({
  children,
  initialValues,
  onSubmit,
  title,
  validationSchema,
  hasBackButton = true,
  confirmButtonText = 'Anlegen',

}: TCreateForm) => {
  const { setStep } = usePersonCreateContext();

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {formikProps => {
        return (
          <Form style={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography align="center" variant="h1">
                  {title}
                </Typography>
              </Grid>
            </Grid>
            <Box>{children(formikProps)}</Box>
            <Box display="flex" justifyContent="center" mt={4}>
              {hasBackButton &&
                <SecondaryButton tabIndex={-1} onClick={() => setStep(STEPS.TYPE)}>
                  Zurück
                </SecondaryButton>
              }
              <Box mx={1} />
              <PrimaryButton disabled={!formikProps.isValid} type="submit">
                { confirmButtonText }
              </PrimaryButton>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateForm;
