import React, { useContext } from "react";

import { CurrentIndex } from "../../pages/Appointment/components/Day";
import { AbsentContext } from "../ShiftRowWrapper";

import { SlotTypes } from "./AppointmentTimeSlots";

interface IAppointmentSlot {
  allowMultipleAppointment: boolean;
  isFirstColumn: boolean;
  isLastColumn: boolean;
  height: number;
  width: number;
  index: number;
  startIndex: number;
  bandIndicator: 1 | 2 | 4;
  type: SlotTypes;
  onClick: () => void;
}

// the appointment color is set here
const AppointmentSlot = ({
  height,
  index,
  onClick,
  type,
  width,
  bandIndicator,
}: IAppointmentSlot) => {
  const { isAbsent } = useContext(AbsentContext);
  const { setIndex } = useContext(CurrentIndex);

  let fill;
  switch (type) {
    case "block":
      fill = "rgba(0, 0, 0, 0.0)";
      break;
    case "appointment":
      fill = isAbsent ? "#A97B63" : "white";
      break;
  }

  return (
    <rect
      className={isAbsent ? "spacing" : ""}
      fill={fill}
      height={height}
      onClick={onClick}
      onMouseEnter={() => setIndex(-1)}
      width={width - bandIndicator - 16}
      x={bandIndicator + 6}
      y={(height + 1) * index}
    />
  );
};

export default AppointmentSlot;
