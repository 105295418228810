import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  "@keyframes typing": {
    "0%": {
      opacity: "0",
    },
    "5%": {
      opacity: "1",
    },
    "95%": {
      opacity: "1",
    },
    "100%": {
      opacity: "0",
    },
  },
  "@keyframes blink": {
    "0%": {
      opacity: 0.0,
    },
    "20%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0.0,
    },
  },
  activeChatBubble: {
    position: "relative",
  },
  typing: {
    opacity: "0",
    borderRadius: "2px",
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    display: "flex",
    left: "26px",
    width: "20px",
    height: "16px",
    zIndex: 102,
    position: "absolute" as "absolute",
    textAlign: "center" as "center",
    top: "7px",
    animation: `$typing 6000ms ease-out`,
    animationIterationCount: "1",
    "&>svg": {
      position: "absolute" as "absolute",
      left: "3px",
      top: "9px",
      margin: "1px",
      height: "3px",
      width: "3px",
      fill: "white",
      display: "block",
      animationName: "$blink",
      animationDuration: "1s",
      animationIterationCount: "infinite",
      animationFillMode: "both",
      "&:nth-child(2)": {
        animationDelay: ".2s",
        left: "8px",
      },
      "&:nth-child(3)": {
        animationDelay: ".4s",
        left: "13px",
      },
    },
  },
  activity: {
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(0.5),
    color: theme.palette.secondary.main,
    cursor: "pointer",
    display: "flex",
    flexDirection: "row" as "row",
    flex: "1",
    height: 32,
    justifyContent: "flex-start",
    marginLeft: 16,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    position: "relative" as "relative",
    transition: "min-width 0.2s ease",
    "&:before": {
      borderBottom: `16px solid transparent`,
      borderLeft: `16px solid ${theme.palette.background.paper}`,
      borderRight: `0px solid transparent`,
      borderTop: `16px solid ${theme.palette.background.paper}`,
      bottom: 0,
      content: '""',
      height: 0,
      left: 0,
      margin: "auto 0",
      position: "absolute" as "absolute",
      top: theme.spacing(2),
      width: 0,
    },
    "&>svg": {
      fill: theme.palette.secondary.main,
      zIndex: 100,
      marginRight: 5,
    },
    "&>img": {
      fill: theme.palette.secondary.main,
      zIndex: 100,
      marginRight: 5,
    },
    "&>p": {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}));

export default useStyles;
