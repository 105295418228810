import React, { FC } from "react";

import { FormikValues, FormikActions } from "formik";
import { useSnackbar } from "notistack";
import {
  useUpdateMemoMutation,
  useUpdateClientMemoMutation,
  Topic,
} from "../../../generated/graphql";
import MemoReportFormStep1, { TMemoUpdateFormStep1 } from "../MemoReportFormStep1";
import Notification from "../../molecules/Notification";

const MemoUpdateFormStep1: FC<TMemoUpdateFormStep1> = ({ person, memo, customCallBack }) => {
  const [updateClientMemo] = useUpdateClientMemoMutation();
  const [updateMemo] = useUpdateMemoMutation();
  const { enqueueSnackbar } = useSnackbar();
  const client = person.client;

  const { contact, id, topic, personActivity } = memo;

  const onSubmit = async (
    values: FormikValues,
    { setSubmitting }: FormikActions<FormikValues>,
    topic: Topic,
  ) => {
    if (values.start.valueOf() > values.end.valueOf()) {
      enqueueSnackbar("", {
        content: (key: string) => (
          <Notification
            id={key}
            message={
              <>
                Das End-Datum liegt vor dem Start-Datum,
                <br />
                bitte Eingaben korrigieren.
              </>
            }
          />
        ),
      });
      setSubmitting(false);
      return;
    }
    const memo = {
      id,
      topic: { id: topic.id, name: topic.name },
      personActivity: {
        id: personActivity && personActivity.id,
        activityType: values.type,
        end: values.end,
        start: values.start,
      },
    };

    if (!!client?.id) {
      await updateClientMemo({
        variables: {
          clientId: client.id,
          memo,
        },
      });
    } else {
      await updateMemo({
        variables: {
          memo,
        },
      });
    }
    customCallBack();

    setSubmitting(false);
  };

  return (
    <MemoReportFormStep1
      defaultContact={contact}
      initialTopic={topic}
      onSubmit={onSubmit}
      person={person}
      personActivity={personActivity}
    />
  );
};

export default MemoUpdateFormStep1;
