import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  createIcon: {
    height: "0.8em",
    width: "0.8em",
  },
}));

export default useStyles;
