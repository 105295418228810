import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Phone from "@material-ui/icons/Phone";
import React, { useState } from "react";
import { Person, Phone as TypePhone, RelationshipType } from "../../../generated/graphql";
import translateRelationshipType from "../../../utils/translateRelationshipType";
import EmergencyContactIcon from "../../assets/EmergenyContactIcon";
import OutgoingCall from "../../organisms/OutgoingCall";
import useStyles from "./styles";

type TEmergencyContactCard = { person: Person };

const EmergencyContactCard = ({ person }: TEmergencyContactCard) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openDialog, setOpenDialog] = useState<string | undefined>();
  const [callPhone, setCallPhone] = useState<TypePhone | undefined>();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const emergencyContact =
    person.relations && person.relations.find(relation => relation && relation.isEmergency);

  const openCallDialog = (phone: TypePhone) => {
    setCallPhone(phone);
    setOpenDialog("callOut");
  };

  const renderRelationship = (relationship?: RelationshipType | null) => {
    if (!relationship || relationship === RelationshipType.unknown) {
      return null;
    }

    return `, ${translateRelationshipType(relationship)}`;
  };

  const closeDialog = () => {
    setOpenDialog(undefined);
    setCallPhone(undefined);
  };

  if (
    !emergencyContact ||
    !emergencyContact.passivePerson ||
    !emergencyContact.passivePerson.phones?.length
  ) {
    return null;
  }
  // TODO - assumption - since its emergency, we only need to show the first phone and ignore is contactAllowed
  const phones = emergencyContact.passivePerson.phones;
  return (
    <>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Box width="100%" display="flex" alignItems="center">
          <Box display="flex" className={classes.marginLeft} marginRight={2}>
            <EmergencyContactIcon />
          </Box>
          <Typography className={classes.buttonText} variant="body2">
            Notfallkontakt
          </Typography>
        </Box>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}               
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleClose} className={classes.phoneMenuItem}>
          <Box display="flex" alignItems="center" justifyContent="space-between" width={1}>
            <Box display="flex" className={classes.marginLeft} marginRight={2}>
              <EmergencyContactIcon />
            </Box>
            <Box mr={3}>
              <Typography variant="h4" color="textSecondary" gutterBottom>
                Notfallkontakt
              </Typography>
              <Typography variant="body2">
                {emergencyContact.passivePerson.name}
                {renderRelationship(emergencyContact.relationship)}
              </Typography>
            </Box>
            <Box marginLeft={4}>
              <IconButton
                onClick={() => {
                  openCallDialog(phones![0]!);
                }}
              >
                <Phone />
              </IconButton>
            </Box>
          </Box>
        </MenuItem>
      </Menu>

      {/* TODO - this component is customized to take passivePerson (of type person) */}
      {/* if in the future making a call from this button doesn't work, it might be  */}
      {/* a good starting point */}
      <OutgoingCall
        person={emergencyContact.passivePerson}
        phone={callPhone}
        open={openDialog === "callOut"}
        close={closeDialog}
      />
    </>
  );
};

export default EmergencyContactCard;
