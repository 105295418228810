import React, { FC, useContext } from "react";

import Typography from "@material-ui/core/Typography";
import { ActivitiesStreamContext } from "..";
import { PersonActivity } from "../../../../generated/graphql";
import useStyles from "../styles";
type TSmsBubble = {
  activity: PersonActivity;
  isLink?: boolean;
};

export const SmsBubble: FC<TSmsBubble> = ({ activity, isLink }) => {
  const { context } = useContext(ActivitiesStreamContext);
  const classes = useStyles();
  const topicName = activity?.topic?.name || "";
  let withPerson = "";
  if (context === "global") {
    // Due to refactoring: https://medium.com/@calebmer/when-to-use-graphql-non-null-fields-4059337f6fc8
    withPerson = ` an ${activity.person?.name || "Unbekannt (Fehler)"}`;
  }
  return (
    <>
      <Typography className={`${isLink ? classes.clickableText : ""}`} variant="body2">
        Ausgehende SMS{withPerson}
      </Typography>
      <Typography variant="h4">{activity.description}</Typography>
      <Typography variant="body2">{topicName}</Typography>
    </>
  );
};

export default SmsBubble;
