import { useEffect } from "react";

const useScrollTop = (reverse = false) => {
  useEffect(() => {
    try {
      window.scroll({
        top: reverse ? window.outerHeight : 0,
        left: 0,
        behavior: "auto",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useScrollTop;
