import React, { useEffect, useState, FC } from "react";

import makeStyles from "@material-ui/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { Select } from "formik-material-ui";
import { Field } from "formik";

import { Topic, useTopicsFromPersonQuery } from "../../../generated/graphql";

const useStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
  },
}));

interface TopicSelectContainerProps {
  onChangePerson: () => void;
  personId: number;
}

const TopicSelectContainer: FC<TopicSelectContainerProps> = ({ onChangePerson, personId }) => {
  const [topics, setTopics] = useState<Topic[] | null | undefined>(undefined);

  const { data } = useTopicsFromPersonQuery({
    variables: { personId },
  });

  useEffect(() => {
    onChangePerson();
    // Do no change dependencies cause of warnings, this results in infinite renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personId]);

  useEffect(() => {
    setTopics(data?.topicsFromPerson);
  }, [data]);

  return <TopicSelect topics={topics} />;
};

interface TopicSelectProps {
  topics?: Topic[] | null;
}

export const TopicSelect: FC<TopicSelectProps> = ({ topics }) => {
  const classes = useStyles();

  return (
    <FormControl
      className={classes.formControl}
      margin="normal"
      disabled={!topics || !topics.length}
    >
      <InputLabel style={{ position: "absolute" }} htmlFor="Thema">
        Thema
      </InputLabel>
      <Field name="topicId" label="Thema" component={Select} disabled={!topics || !topics.length}>
        {topics &&
          !!topics.length &&
          topics.map(topic => {
            return (
              <MenuItem key={`topic-${topic.id}`} value={topic.id || -1}>
                {topic.name}
              </MenuItem>
            );
          })}
      </Field>
    </FormControl>
  );
};

export default TopicSelectContainer;
