import React, { forwardRef } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { SnackbarContentProps } from "@material-ui/core/SnackbarContent";
import useStyles from "./styles";
import { useSnackbar } from "notistack";

const Notification = forwardRef((props: SnackbarContentProps, ref) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();
  const handleClick = () => {
    closeSnackbar(props.id);
  };
  return (
    <Paper ref={ref} classes={{ root: classes.root }} square elevation={0} onClick={handleClick}>
      <Typography variant="body2" className={classes.text}>
        {props.message}
      </Typography>
    </Paper>
  );
});

export default Notification;
