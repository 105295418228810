import { useEffect } from "react";

import { fetchPolicy } from "../utils/constants";
import {
  useServiceQuery,
  ServiceSubscriptionDocument,
  ServiceSubscriptionSubscription,
} from "../generated/graphql";

export const useServiceSubscription = (id: number) => {
  const { data, loading, subscribeToMore, refetch, error } = useServiceQuery({
    variables: { id },
    fetchPolicy,
  });

  useEffect(() => {
    const unsub = subscribeToMore<ServiceSubscriptionSubscription>({
      document: ServiceSubscriptionDocument,
      variables: { id },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !prev) return prev;
        return Object.assign({}, prev, {
          // @ts-ignore
          service: { ...subscriptionData.data.serviceSubscription },
        });
      },
    });
    return () => unsub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { service: data?.service, loading, refetch, error };
};
