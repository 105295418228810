import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.main,
    width: 40,
    height: 40,
    padding: 8,
    marginRight: theme.spacing(0.5),
  },
  active: {
    color: theme.palette.background.default,
    background: `${theme.palette.primary.main} !important`,
  },
}));

export default useStyles;
