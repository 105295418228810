import { useEffect, useRef } from "react";
import isEqual from "lodash/isEqual";

const useAutoSave = (values: Object, save: (params: any) => any, dirty = true) => {
  const timer = useRef<NodeJS.Timeout | null>(null);
  const lastValues = useRef<Object>({});

  useEffect(() => {
    if (values) {
      timer.current = setTimeout(() => {
        if (!isEqual(lastValues.current, values)) {
          lastValues.current = values;
          if (dirty) {
            save(values);
          }
        }
      }, 500);
    }
    return () => {
      if (timer.current) clearTimeout(timer.current);
      timer.current = null;
      lastValues.current = values;
    };
  }, [values, save, timer, dirty]);

  return timer;
};

export default useAutoSave;
