import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    dataText: {
      lineHeight: 1.4,
      fontWeight: theme.typography.fontWeightRegular,
      paddingBottom: theme.spacing(2),
    },
  };
});

export default useStyles;
