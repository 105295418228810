import React, { useContext, useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import config from "../../../config";

import {
  usePersonQuery,
  useCreateChatMutation,
  useGetActiveChatQuery,
  PersonQuery,
} from "../../../generated/graphql";
import { useAuthorization } from "../../../providers/AuthorizationProvider";
import { fetchPolicy } from "../../../utils/constants";
import { ChatContext } from "../../../containers/ChatListener";
import { ChatServerMessage } from "../../../containers/ChatListener/ChatMessageListener";
import Chat from "../../organisms/Chat";
import PrimaryButton from "../../atoms/PrimaryButton";

import doNotDisturb from "../../assets/doNotDisturb.svg";
import PersonHeader from "../../molecules/PersonHeader";
import ErrorFallback from "../../molecules/ErrorFallback/ErrorFallback";
import Loader from "../../atoms/Loading";

export default function ChatContainer() {
  const { chatId, personId } = useParams<{ chatId: string; personId: string }>();
  const personResponse = usePersonQuery({
    variables: {
      id: parseInt(personId),
    },
  });

  const { chats, dispatch, token } = useContext(ChatContext);

  if (personResponse.loading) {
    return <Loader />;
  }

  const person = personResponse.data?.person;

  if (personResponse.error || !person) {
    return <ErrorFallback error={new Error("Die Person konnte nicht geladen werden.")} />;
  }

  if (!person.client) {
    return <ErrorFallback error={new Error("Chats können nur mit Klient*innen geführt werden.")} />;
  }

  const chatUserId = person.client.chatUserId;

  if (!chatUserId) {
    return (
      <ErrorFallback
        error={new Error("Der/die Chatnutzer*in konnten nicht identifiziert werden.")}
      />
    );
  }

  const chat = chats.get(chatUserId);

  // Avoid showing the chat in two different tabs, since it is also technically not possible to have
  // two active websocket connections
  if (!!chat?.hide) {
    return (
      <DoNotDisturb
        person={person}
        title="Psst, nicht stören. In diesem Chat befindet sich bereits ein Mitarbeiter."
      />
    );
  }

  // Due to refactoring: https://medium.com/@calebmer/when-to-use-graphql-non-null-fields-4059337f6fc8
  if (!chat || !chat.activity || !chat.activity.person) {
    return <ChatHistoryContainer chatId={chatId} personId={personId} token={token} />;
  }

  return (
    <Chat
      key={`chat-${chatUserId}`}
      activity={chat.activity}
      onFetchMore={(uid: number) => {
        chat.connection.send(JSON.stringify({ t: "h", uid }));
      }}
      onSubmit={(text: string) => {
        if (chat.connection.readyState === WebSocket.CLOSED) {
          throw Error("Die Verbindung zum Chatserver wurde unterbrochen");
        }

        chat.connection.send(JSON.stringify({ t: "c", b: text, oid: 1 }));

        dispatch({
          type: "addChatMessage",
          id: chatUserId,
          isActiveChat: true,
          message: {
            isClient: false,
            text,
            time: dayjs(),
          },
        });
      }}
      history={chat.history}
      person={person}
      withInput
    />
  );
}

function ChatHistoryContainer(props: { chatId: string; personId: string; token?: string | null }) {
  const getActiveChatResult = useGetActiveChatQuery({
    variables: {
      chatUserId: parseInt(props.chatId, 10),
    },
    fetchPolicy,
  });
  const { me } = useAuthorization();
  const personResult = usePersonQuery({
    variables: {
      id: parseInt(props.personId),
    },
  });

  if (getActiveChatResult.loading || personResult.loading) {
    return null;
  }

  const person = personResult?.data?.person;

  if (!person) {
    return null;
  }

  const getActiveChat = getActiveChatResult.data?.getActiveChat;

  const team = getActiveChat?.team;

  const hasActiveChat = !!getActiveChat;
  const userIsInChat = !!getActiveChat?.user && getActiveChat?.user.person?.id !== me?.id;
  const notInMyGroup = !!team && !me?.user?.teams?.includes(team);

  const isBlocked = hasActiveChat && (userIsInChat || notInMyGroup);

  if (isBlocked) {
    return (
      <DoNotDisturb
        person={person}
        title={
          userIsInChat
            ? `Psst, nicht stören. In diesem Chat befindet sich bereits ${getActiveChat?.user?.name}.`
            : "Psst, nicht stören. Diesen Chat übernimmt ein anderes Team."
        }
      />
    );
  }

  return <ChatHistory chatId={props.chatId} personId={props.personId} token={props.token} />;
}

type TChatHistory = {
  chatId: string;
  personId: string;
  token?: string | null;
};

export const ChatHistory = ({ chatId, personId, token }: TChatHistory) => {
  const personResponse = usePersonQuery({ variables: { id: parseInt(personId) } });

  const [connection, setConnection] = useState<WebSocket | null>(null);

  const [status, setStatus] = useState<"loading" | "blocked" | "free">("loading");
  const [chatHistory, setChatHistory] = useState<any[]>([]);

  useEffect(() => {
    if (token) {
      setConnection(
        new WebSocket(`${config.chatWebSocket}/ochat/${chatId}?v=${config.chatVersion}`, token),
      );
    }
  }, [chatId, token]);

  useEffect(() => {
    if (connection) {
      connection.onmessage = message => {
        const messageData: ChatServerMessage = JSON.parse(message.data);

        // Currently we do not have a better indication for this
        if (messageData.b === "Ein Otheb-Mitarbeiter ist bereits in dem Chatraum") {
          setStatus("blocked");
          return;
        }

        switch (messageData.t) {
          case "h":
            setChatHistory(current => [
              ...messageData.messages.map((message: any) => {
                return {
                  isClient: !message.o,
                  text: message.b,
                  time: dayjs(message.d),
                  uid: message.uid,
                };
              }),
              ...current,
            ]);

            setStatus("free");
        }
      };
    }

    return () => {
      if (connection) {
        connection.close();
      }
    };
  }, [connection, setChatHistory]);

  const person = personResponse?.data?.person;

  if (status === "loading" || !person) {
    return null;
  }

  if (status === "blocked") {
    return (
      <DoNotDisturb
        person={person}
        title="Psst, nicht stören. In diesem Chat befindet sich bereits ein Mitarbeiter."
      />
    );
  }

  return (
    <Box>
      <Chat
        key={`chat-${chatId}`}
        onFetchMore={uid => {
          if (connection) {
            connection.send(JSON.stringify({ t: "h", uid }));
          }
        }}
        history={chatHistory}
        person={person}
      />
      {person.client?.chatAlias && person.client?.chatUserId && person.client?.account?.name && (
        <StartChat
          chatAlias={person.client.chatAlias}
          chatUserId={person.client.chatUserId}
          account={person.client.account.name}
        />
      )}
    </Box>
  );
};

type TStartChat = {
  chatAlias: string;
  chatUserId: number;
  account: string;
};

const StartChat = ({ chatAlias, chatUserId, account }: TStartChat) => {
  const [submitting, setSubmitting] = useState(false);
  const [createChat] = useCreateChatMutation({
    variables: {
      chatInput: {
        chatAlias,
        chatUserId,
        account,
      },
    },
  });

  return (
    <Box position="fixed" bottom={0} left={0} width={1} bgcolor="#F9F3E9">
      <Box textAlign="center" marginX={2} paddingY={2} borderTop="1px solid #bdbdbd">
        <PrimaryButton
          disabled={submitting}
          onClick={async () => {
            setSubmitting(true);

            try {
              await createChat();
            } catch (error) {
              console.error(error);
            }

            setSubmitting(false);
          }}
        >
          Chat starten
        </PrimaryButton>
      </Box>
    </Box>
  );
};

const DoNotDisturb = ({ person, title }: { person: PersonQuery["person"]; title: string }) => {
  return (
    <>
      <PersonHeader person={person} mapList={[{ name: "Chat", path: "" }]} />
      <Box display="flex" alignItems="center" mt={36} flexDirection="column">
        <Typography variant="h1">{title}</Typography>
        <Typography variant="h1">Du kannst dich einer andere Aufgabe widmen.</Typography>
        <Box mt={5}>
          <img src={doNotDisturb} alt={title} />
        </Box>
      </Box>
    </>
  );
};
