import { QueryLazyOptions } from "@apollo/client";
import DayjsUtils from "@date-io/dayjs";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import React, { Dispatch, SetStateAction, useState } from "react";
import * as Yup from "yup";
import {  
  AccountType,
  QueryMonthlyLifeWorksStatisticsArgs,
} from "../../../generated/graphql";
import Button from "../../atoms/PrimaryButton";
import { StyledKeyboardDatePickerFitsGrid } from "../../atoms/StyledFormComponents";
import AccountTypeSelect from "../../molecules/AccountTypeSelect";

const validation = Yup.object().shape({
  type: Yup.string().required("Bitte ausfüllen"),
});

export default function MonthlyStatisticForm({
  query,
  setQuery,
  disabled = false,
}: {
  query: (options?: QueryLazyOptions<QueryMonthlyLifeWorksStatisticsArgs> | undefined) => void;
  setQuery: Dispatch<SetStateAction<string | undefined>>;
  disabled: boolean;
}) {
  
  return (
    <Formik
      initialValues={{
        start: dayjs().startOf("month"),
        type: AccountType.lifeWorks,
      }}
      onSubmit={(values, { setSubmitting }) => {
        setQuery(undefined);
        query({
          variables: {
            start: values.start,
            type: values.type,
          },
        });

        setQuery("lifeworks");
        setSubmitting(false);
      }}
      validationSchema={validation}
    >
      {props => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography align="left" variant="h3">
                Monatsberichte
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <AccountTypeSelect tabIndex={0} disabled={disabled} forStatistics onlyLifeWorks onlyEAP />
            </Grid>
            <MuiPickersUtilsProvider utils={DayjsUtils} locale="de">
              <Grid item xs={6}>
                <StyledKeyboardDatePickerFitsGrid
                  name="date"
                  openTo="month"
                  views={["year", "month"]}
                  format="DD.MM.YYYY"
                  value={props.values.start}
                  onChange={value => {
                    props.setFieldValue("start", value || dayjs());
                  }}
                  disableFuture
                  label="Ab Datum"
                  cancelLabel="Abbrechen"
                  okLabel="OK"
                  disabled={disabled}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" mt={4}>
                <Button type="submit" disabled={!props.isValid || props.isSubmitting || disabled}>
                  Statistik erstellen
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
