import React from "react";

import { Business, AccountBalance } from "@material-ui/icons";

import personBasic from "../../assets/personBasic.svg";
import personUser from "../../assets/personUser.svg";
import personBusiness from "../../assets/personBusiness.svg";
import personClient from "../../assets/personClient.svg";

export enum PersonTypes {
  business = "business",
  businessContact = "businessContact",
  client = "client",
  user = "user",
  person = "person",
  account = "account",
}

type TPersonTypeIcon = {
  type: PersonTypes;
};

const PersonTypeIcon = ({ type }: TPersonTypeIcon) => {
  switch (type) {
    case PersonTypes.person:
      return <img src={personBasic} alt="personBasic" />;
    case PersonTypes.client:
      return <img src={personClient} alt="personClient" />;
    case PersonTypes.businessContact:
      return <img src={personBusiness} alt="personBusiness" />;
    case PersonTypes.user:
      return <img src={personUser} alt="personUser" />;
    case PersonTypes.business:
      return <Business color="primary" />;
    case PersonTypes.account:
      return <AccountBalance color="primary" />;
    default:
      return null;
  }
};

export default PersonTypeIcon;
