import React, { FC, ReactNode } from "react";

import Box from "@material-ui/core/Box";
import ChevronRight from "@material-ui/icons/ChevronRight";

import useStyles from "./styles";

type TClickBox = {
  icon?: ReactNode;
  hasIcon?: boolean;
  handleClick: () => void;
  borderWidth?: number;
  py?: number;
  px?: number;
  mb?: number;
  isLinkBox?: boolean;
};

const ClickBox: FC<TClickBox> = ({
  icon = <ChevronRight color="primary" />,
  hasIcon = true,
  handleClick,
  children,
  borderWidth = 1,
  py = 2,
  mb = 2,
  px = 3,
  isLinkBox = false,
}) => {
  const classes = useStyles();
  return (
    <Box
      mb={mb}
      width={1}
      display="flex"
      alignItems="center"
      border={borderWidth}
      borderColor="grey.400"
      px={px}
      py={py}
      className={isLinkBox ? classes.itemHeader : classes.item}
      onClick={handleClick}
    >
      {children}
      {/* <Box mr={-2} height="24px">
        {hasIcon && icon}
      </Box> */}
    </Box>
  );
};

export default ClickBox;
