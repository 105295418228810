import React from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { Select } from "formik-material-ui";
import { Field } from "formik";

type TAccountCoachingSelect = {
  tabIndex?: number;
};

const AccountCoachingSelect = ({ tabIndex = 0 }: TAccountCoachingSelect) => {
  return (
    <FormControl fullWidth margin="normal">
      <InputLabel htmlFor="coaching">Führungskräftecoaching</InputLabel>
      <Field name="coaching" label="Führungskräftecoaching" component={Select} tabIndex={tabIndex}>
        <MenuItem value="true">Ja</MenuItem>
        <MenuItem value="false">Nein</MenuItem>
      </Field>
    </FormControl>
  );
};
export default AccountCoachingSelect;
