import React, { FC } from "react";

import Loader from "../../atoms/Loading";
import List from "./List";

import { useMyCMClientsSubscription } from "../../../hooks/useMyCMClientsSubscription";

const CMClients: FC<{}> = () => {
  const { clients, loading } = useMyCMClientsSubscription();

  if (loading && !clients.length) {
    return <Loader />;
  }
  return (
    <List
      title="Meine Klienten"
      clients={clients}
      emptyMessage="Du betreust momentan keine Klienten im Case Management"
      isCaseManagement
    />
  );
};
export default CMClients;
