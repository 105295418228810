import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    tab: {
      alignItems: "center",
      "&>svg": {
        marginRight: theme.spacing(2),
        height: 24,
        width: 24,
      },
    },
  };
});

export default useStyles;
