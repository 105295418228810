import React, { Dispatch, SetStateAction, useState } from "react";
import dayjs from "dayjs";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import DayjsUtils from "@date-io/dayjs";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Button from "../../atoms/PrimaryButton";
import { StyledKeyboardDatePickerFitsGrid } from "../../atoms/StyledFormComponents";
import { BusinessObject, ComplaintsStatisticsForBusinessQueryVariables } from "../../../generated/graphql";
import { QueryLazyOptions } from "@apollo/client";
import AsyncSearch from "../../molecules/AsyncSearch";
import AccountTypeSelect from "../../molecules/AccountTypeSelect";
import { Typography } from "@material-ui/core";

const validation = Yup.object().shape({
  type: Yup.string().required("Bitte ausfüllen"),
});

export default function ComplaintStatisticFormForBusiness({
  query,
  setQuery,
  disabled = false,
}: {
  query: (options?: QueryLazyOptions<ComplaintsStatisticsForBusinessQueryVariables> | undefined) => void;
  setQuery: Dispatch<SetStateAction<string | undefined>>;
  disabled: boolean;
}) {
  const [businessObjectId, setBusinessObjectId] = useState(-1);  

  return (
    <Formik
      initialValues={{
        type: "adsd",
        start: dayjs().subtract(1, "year"),
        end: dayjs(),
      }}
      onSubmit={(values, { setSubmitting }) => {
        setQuery(undefined);
        query({
          variables: {
            businessId: businessObjectId,
            start: values.start,
            end: values.end,
          },
        });

        setQuery("complaintsForBusiness");
        setSubmitting(false);
      }}
      validationSchema={validation}
    >
      {props => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography align="left" variant="h3">
                Beschwerden von Unternehmen
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box marginTop={6}>
                    <AsyncSearch.BusinessObjectSearch
                      placeholder="Suche nach Name"
                      allowCreate={false}
                      onChange={(businessObject: BusinessObject) => {
                        setBusinessObjectId(businessObject.id!);                        
                        // setFieldValue("businessCategoryId", businessObject.category?.id);
                      }}
                      onReset={() => {
                        setBusinessObjectId(-1);
                      }}
                      onCreateOption={(businessObjectName: string) => {
                        // setFieldValue("name", businessObjectName);
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <MuiPickersUtilsProvider utils={DayjsUtils} locale="de">
              <Grid item xs={6}>
                <StyledKeyboardDatePickerFitsGrid
                  name="date"
                  format="DD.MM.YYYY"
                  value={props.values.start}
                  onChange={value => {
                    props.setFieldValue("start", value || dayjs());
                  }}
                  disableFuture
                  label="Ab Datum"
                  cancelLabel="Abbrechen"
                  okLabel="OK"
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={6}>
                <StyledKeyboardDatePickerFitsGrid
                  disableFuture
                  name="date"
                  format="DD.MM.YYYY"
                  value={props.values.end}
                  onChange={value => {
                    props.setFieldValue("end", value || dayjs());
                  }}
                  label="Bis Datum"
                  cancelLabel="Abbrechen"
                  okLabel="OK"
                  disabled={disabled}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" mt={4}>
                <Button
                  type="submit"
                  disabled={props.isSubmitting || businessObjectId == -1 || disabled}
                >
                  Statistik erstellen
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}