import React, { FC } from "react";

import { Formik } from "formik";
import dayjs from "dayjs";

import { useMutation } from "@apollo/client";

import {
  Appointment,
  DeleteAppointmentDocument,
  UpdateAppointmentDocument,
  User,
} from "../../../../generated/graphql";

import AppointmentDialogForm from "./form";
import validationSchema from "./validationSchema";
import { TAvatarUser } from "../../UserAvatar";

interface IEdit {
  allowEditUsers: boolean;
  appointment: Appointment;
  minEndsAt: dayjs.Dayjs;
  minStartsAt: dayjs.Dayjs;
  onClose: () => void;
  refetch: () => void;
  users: Array<User | TAvatarUser>;
}

const Edit: FC<IEdit> = ({
  allowEditUsers,
  appointment,
  minEndsAt,
  minStartsAt,
  onClose,
  refetch,
  users,
}) => {
  const [updateAppointment] = useMutation(UpdateAppointmentDocument);
  const [deleteAppointment] = useMutation(DeleteAppointmentDocument);

  const initialValues = {
    isAppointment: !!appointment.person,
    note: appointment.note || "",
    title: appointment.title || "",
    personId: (appointment.person && appointment.person.id) || -1,
    date: dayjs(appointment.date),
    from: dayjs(appointment.from),
    to: dayjs(appointment.to),
    // person: appointment.person,
    topicId: appointment.topic?.id || -1,
    serviceId: appointment.service?.id || -1,
    // @ts-ignore
    userIds:
      appointment.users &&
      appointment.users
        .filter(user => !user?.absentUntil || dayjs(appointment.date).isAfter(user.absentUntil))
        .map(user => user?.id),
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        let from = values.date.clone().hour(values.from.hour());
        from = from.minute(values.from.minute());

        let to = values.date.clone().hour(values.to.hour());
        to = to.minute(values.to.minute());

        const response = await updateAppointment({
          variables: {
            appointmentInput: {
              id: appointment.id,
              ...values,
              from,
              to,
            },
          },
        });

        setSubmitting(false);

        if (response.data.updateAppointment != null) {
          onClose();
        } else {
          setFieldError("from", "Ungültig");
          setFieldError("to", "Ungültig");
        }
      }}
    >
      {props => (
        <AppointmentDialogForm
          {...props}
          allowEditUsers={allowEditUsers}
          person={appointment.person}
          minStartsAt={minStartsAt}
          minEndsAt={minEndsAt}
          users={appointment.users || users}
          deleteAppointment={async () => {
            await deleteAppointment({
              variables: {
                id: String(appointment.id),
              },
            });
            refetch();
            onClose();
          }}
          edit
        />
      )}
    </Formik>
  );
};

export default Edit;
