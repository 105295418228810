import React from "react";
import { Box, Typography, Checkbox } from "@material-ui/core";
import pathOr from "ramda/src/pathOr";
import { PapershiftWorkingArea, usePapershiftWorkingAreasQuery } from "../../../generated/graphql";
import { getWorkingAreaName } from "../../../utils/translateWorkingAreaEnums";
import { fetchPolicy } from "../../../utils/constants";

type TWorkingAreasFilter = {
  headline: string;
  toggleWorkingArea: (workingArea: PapershiftWorkingArea, selected: boolean) => void;
  selectedIds: number[];
};

const WorkingAreasFilter = ({ headline, toggleWorkingArea, selectedIds }: TWorkingAreasFilter) => {
  const { data, loading, error } = usePapershiftWorkingAreasQuery({ fetchPolicy });
  if (loading) {
    return null;
  }

  if (error) {
    return <Typography>{error.message}</Typography>;
  }

  const workingAreas: PapershiftWorkingArea[] = pathOr([], ["papershiftWorkingAreas"], data || []);

  if (!workingAreas.length) {
    return null;
  }

  const workingAreasFilter = workingAreas.map(workingArea => {
    return (
      <WorkingAreaFilter
        key={`${workingArea.id}`}
        toggleWorkingArea={toggleWorkingArea}
        workingArea={workingArea}
        selected={selectedIds.indexOf(workingArea.id) > -1}
      />
    );
  });

  return (
    <Box marginTop={3.5}>
      <Typography variant="h4">{headline}</Typography>
      <Box marginTop={2}>{workingAreasFilter}</Box>
    </Box>
  );
};

type TWorkingAreaFilter = {
  toggleWorkingArea: (workingArea: PapershiftWorkingArea, selected: boolean) => void;
  selected: boolean;
  workingArea: PapershiftWorkingArea;
};

const WorkingAreaFilter = ({ toggleWorkingArea, selected, workingArea }: TWorkingAreaFilter) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      borderBottom={`3px solid ${workingArea.color ?? "#cccccc"}`}
      style={{ cursor: "pointer" }}
      key={`${workingArea.id}`}
      onClick={() => {
        toggleWorkingArea(workingArea, selected);
      }}
    >
      <Box marginLeft="-12px">
        <Checkbox checked={selected} />
      </Box>
      <Box paddingTop="4px">{getWorkingAreaName(workingArea.name)}</Box>
    </Box>
  );
};

export default WorkingAreasFilter;
