import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import dayjs from "dayjs";

import useStyles from "../inboxStyles";
import { Task, Appointment } from "../../../generated/graphql";
import Box from "@material-ui/core/Box";

type THeader = {
  isUnassigned: boolean;
  me: any;
  tasks: Task[];
  appointments: Appointment[];
};
const Header = ({ isUnassigned, me, tasks, appointments }: THeader) => {
  const classes = useStyles();

  if (isUnassigned && tasks.length < 1) {
    return (
      <Grid container className={classes.greeting}>
        <Grid item xs={3} />
        <Grid item xs={6}>
          <Box mt={6}>
            <Typography variant="h1" align="center">
              Hier gibt’s grad nichts zu tun!
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3} />
      </Grid>
    );
  }

  const count = tasks.length + appointments.length;

  const morningEnd = dayjs().hour(10);
  const afternoonEnd = dayjs().hour(17);
  const eveningEnd = dayjs().hour(2);

  let greeting = "Guten Abend";

  if (count < 1) {
    greeting = "Gut gemacht";
  } else if (dayjs().isBefore(eveningEnd)) {
    greeting = "Guten Abend";
  } else if (dayjs().isBefore(morningEnd)) {
    greeting = "Guten Morgen";
  } else if (dayjs().isBefore(afternoonEnd)) {
    greeting = "Hallo";
  }

  const name = me.firstName ? ", " + me.firstName : "";
  const headerFirstline = `${greeting}${name}!`;

  const headerSecondline =
    count > 0
      ? `Aktuell ${count > 1 ? "stehen" : "steht"} ${count === 1 ? "eine" : count} Aufgabe${
          count > 1 ? "n" : ""
        } für dich an. Schön, dass du da bist.`
      : `Du hast keine offenen Aufgaben. Gönn dir einen Kaffee.`;

  return !isUnassigned ? (
    <Grid container className={classes.greeting}>
      <Grid item xs={3} />
      <Grid item xs={6}>
        <Box mt={count > 0 ? 0 : 6}>
          <Typography variant="h1" align="center">
            {headerFirstline}
          </Typography>
          <Typography variant="h1" align="center">
            {headerSecondline}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={3} />
    </Grid>
  ) : (
    <></>
  );
};

export default Header;
