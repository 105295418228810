import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    checkboxWrapper: {
      marginRight: theme.spacing(5),
    },
  };
});

export default useStyles;
