const trimMarkdownParagraph = (
  children: { text: string }[],
  length: number,
  trimLength: number,
) => {
  return children.reduce(
    (carry, item) => {
      if (carry.length >= trimLength) {
        return carry;
      }

      if (carry.length + item.text.length < trimLength) {
        return { paragraphs: [...carry.paragraphs, item], length: carry.length + item.text.length };
      } else {
        return {
          paragraphs: [
            ...carry.paragraphs,
            { ...item, text: item.text.slice(0, trimLength - carry.length) + " ..." },
          ],
          length: carry.length + item.text.length,
        };
      }
    },
    { paragraphs: [] as { text: string }[], length },
  );
};

interface IParagraph {
  children: { text: string }[];
  type: "paragraph";
}

interface IParagraphs {
  paragraphs: IParagraph[];
  length: number;
}

export const trimMarkdown = (note: string, trimLength = 180) => {
  try {
    const parsed = JSON.parse(note);

    const reduced = parsed.reduce(
      (carry: IParagraphs, item: IParagraph) => {
        if (carry.length >= trimLength) {
          return carry;
        }

        const { length, paragraphs } = trimMarkdownParagraph(
          item.children,
          carry.length,
          trimLength,
        );

        return { paragraphs: [...carry.paragraphs, { ...item, children: paragraphs }], length };
      },
      { paragraphs: [], length: 0 },
    );

    return { note: JSON.stringify(reduced.paragraphs), length: reduced.length };
  } catch (error) {
    // Most likely no error, but input is rather a string which can (and need not be) parsed
    return { note, length: note?.length || 0 };
  }
};
