import { WorkingAreas } from "../generated/graphql";

export function getWorkingAreaName(workingArea?: WorkingAreas | null): string {
  switch (workingArea) {
    case WorkingAreas.caseManagement:
      return "Case Management";
    case WorkingAreas.lMRechercheassistenten:
      return "LM Rechercheassistenten";
    case WorkingAreas.lifeManagerExperten:
      return "Life Manager & Experten";
    case WorkingAreas.counseling:
      return "Counseling";
    case WorkingAreas.clinicalAdministration:
      return "Clinical Administration";
    case WorkingAreas.counseling1stLevel:
      return "Counseling 1st Level";
    case WorkingAreas.counseling2ndLevel:
      return "Counseling 2nd Level";
    case WorkingAreas.counselingTC:
      return "Counseling TC";
    case WorkingAreas.digitalServices:
      return "Digital Services";
    default:
      return "Andere";
  }
}
