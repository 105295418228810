import React from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { Select } from "formik-material-ui";
import { Field } from "formik";

import { EmailType } from "../../../generated/graphql";

type TEmailTypeSelect = { name: string };

const EmailTypeSelect = ({ name }: TEmailTypeSelect) => {
  const options = [];
  for (const [relationType, relationValue] of EmailTypeMap) {
    options.push(
      <MenuItem key={relationType} value={relationType}>
        {relationValue}
      </MenuItem>,
    );
  }

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel style={{ position: "absolute" }} htmlFor={name}>
        Bezeichung
      </InputLabel>
      <Field name={name} label="Bezeichung" component={Select}>
        {options}
      </Field>
    </FormControl>
  );
};

export const EmailTypeMap = new Map<EmailType, string>([
  [EmailType.private, "Privat"],
  [EmailType.mobile, "Arbeit"],
]);

export default EmailTypeSelect;
