import React, { Fragment, ReactText } from "react";
import Typography from "@material-ui/core/Typography";
import { Formik, FormikProps, FormikValues } from "formik";
import {
  useUpdateClientMemoMutation,
  Person,
  MemoState,
  Memo,
  useUpdateMemoMutation,
} from "../../../generated/graphql";
import Step1 from "./step1";
import FinalStep from "../ReportFinalStep";

import useStyles from "../reportStyles";

export type TMemoWizard = {
  backAction: () => void;
  person: Person;
  memo: Memo;
  step: number;
  clientId?: number;
  setStep: (step: number) => void;
  refetch: () => void;
};

const memoMap = [MemoState.created, MemoState.created, MemoState.finished];

const MemoWizard = ({
  clientId,
  person,
  backAction,
  memo,
  step,
  setStep,
  refetch,
}: TMemoWizard) => {
  const [updateClientMemo] = useUpdateClientMemoMutation();
  const [updateMemo] = useUpdateMemoMutation();
  const classes = useStyles();
  const headline = () => {
    return ["", `Fasse kurz das Gespräch zusammen.`][step];
  };

  const getInitialValues = () => {
    return {
      note: memo.note || "",
      contact: memo.contact || "",
      saveContact: false,
    };
  };

  const getSubmitValues = (values: {
    [key: string]: string | number | null | undefined | ReactText | boolean | any[];
  }) => {
    let submitValues = {};
    const validKeys = Object.keys(getInitialValues());
    validKeys.forEach(key => {
      if (values[key]) {
        submitValues = { ...submitValues, [key]: values[key] };
      }
    });
    if (values["status"]) {
      submitValues = { ...submitValues, status: values["status"] };
    }
    return submitValues;
  };

  const renderForm = (
    callback: (p: any) => any,
    formProps: FormikProps<FormikValues>,
    allowContactSave: boolean = false,
  ) => {
    switch (step) {
      case 1:
        return (
          <Step1
            {...formProps}
            person={person}
            saveInput={callback}
            backAction={backAction}
            allowContactSave={allowContactSave}
          />
        );
      case 2:
        // finished state
        return <FinalStep person={person} backAction={backAction} />;
      default:
        return <div />;
    }
  };

  function autoSave(values: { [key: string]: string | number | null | undefined | ReactText }) {
    if (clientId) {
      return updateClientMemo({
        variables: {
          clientId,
          memo: {
            id: memo.id,
            ...getSubmitValues(values),
          },
        },
      });
    } else {
      return updateMemo({
        variables: {
          memo: {
            id: memo.id,
            ...getSubmitValues(values),
          },
        },
      });
    }
  }

  const allowContactSave =
    !!memo.contact &&
    person.name !== memo.contact &&
    !person.relations?.find(relation => {
      return (
        relation?.passivePerson?.name === memo.contact ||
        relation?.passivePerson?.alias === memo.contact
      );
    });

  return (
    <Fragment>
      <Typography variant="h1" className={classes.headline}>
        {headline()}
      </Typography>

      <Formik
        initialValues={getInitialValues()}
        onSubmit={async (values, { setSubmitting }) => {
          const newStep = step + 1;
          setStep(newStep);

          if (clientId) {
            await updateClientMemo({
              variables: {
                clientId,
                memo: {
                  id: memo.id,
                  status: memoMap[newStep % 3],
                  ...getSubmitValues(values),
                  ...(allowContactSave
                    ? { saveContact: values.saveContact }
                    : { saveContact: false }),
                },
              },
            });
          } else {
            await updateMemo({
              variables: {
                memo: {
                  id: memo.id,
                  status: memoMap[newStep % 3],
                  ...getSubmitValues(values),
                },
              },
            });
          }

          setSubmitting(false);
        }}
      >
        {formProps => renderForm(autoSave, formProps, allowContactSave)}
      </Formik>
    </Fragment>
  );
};
export default MemoWizard;
