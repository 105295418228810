import {
    usePersonQuery
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

export const usePersonSubscriptionForCallDialog = (id?: number) => {
  const { data, loading, subscribeToMore, error, refetch } = usePersonQuery({
    skip: !id,
    // @ts-ignore
    variables: { id },
    fetchPolicy,
  });
  return { person: data?.person, loading, error, refetch };
};


export const usePersonSubscription = (id?: number) => {
  const { data, loading, subscribeToMore, error, refetch } = usePersonQuery({
    skip: !id,
    // @ts-ignore
    variables: { id },
    fetchPolicy,
  });

//   useEffect(() => {
//     // by typing here, we get rid of the @ts-ignore
//     if (id) {
//       const unsubscribe = subscribeToMore<PersonSubscriptionSubscription>({
//         document: PersonSubscriptionDocument,
//         // skip: !id,
//         // @ts-ignore
//         variables: { id },
//         updateQuery: (prev, { subscriptionData }) => {
//           if (!subscriptionData.data || !prev) return prev;

//           return { ...prev, ...{ person: subscriptionData.data.personSubscription } };
//         },
//       });

//       return unsubscribe;
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [id]);

  return { person: data?.person, loading, error, refetch };
};

export default usePersonSubscription;
