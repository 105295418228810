import dayjs from "dayjs";
import intersection from "lodash/intersection";

import { PapershiftShift, PapershiftWorkingArea } from "../../generated/graphql";

// Larger refactoring to be done in: #172600725
export const combineMultipleShifts = (state: PapershiftShift[], action: PapershiftShift) => {
  const index = state.findIndex(shift => {
    return shift.users?.some(user => {
      return action.users?.some(_ => _?.id === user?.id);
    });
  });

  const stateCopy = [...state];
  const actionCopy = { ...action };

  if (index === -1) {
    // @ts-ignore
    actionCopy.shifts = [action];
    stateCopy.push(actionCopy);
  } else {
    const current = stateCopy[index];

    // @ts-ignore
    current.shifts.push(action);

    stateCopy[index] = current;
  }

  return stateCopy;
};

export const getOverlapsMaskForOneGroup = (shifts: PapershiftShift[]) => {
  let res = shifts.map(shift => {
    const concatenated: any[] = [];
    // the user is in the selected workingArea
    // @ts-ignore
    shift.shifts.forEach(s => {
      const indexes = {
        start: dayjs(s.startsAt).hour() * 4 + Math.floor(dayjs(s.startsAt).minute() / 15),
        end: dayjs(s.endsAt).hour() * 4 + Math.floor(dayjs(s.endsAt).minute() / 15),
      };
      for (let index = indexes.start; index < indexes.end; index++) {
        concatenated.push(index);
      }
    });

    return {
      shift,
      validRanges: concatenated,
    };
  });

  return res;
};

// We could use this instead of 'validRange' for the logic in find appointment, to determine if we can
// add an appointment for the users. However, this is postponed in Bug ticket: #172600725

// `shifts` are the shifts of the team members
// we need to fix things here, the external user should be and'ed and the team users should be or'ed
export const getOverlapsMask = (
  internalShifts: PapershiftShift[],
  externalShifts: PapershiftShift[] = [],
) => {
  const internalValidRanges = getOverlapsMaskForOneGroup(internalShifts);
  let masterShift: any = [];
  let combinedShifts = [];
  if (externalShifts.length > 0) {
    const externalValidRanges = getOverlapsMaskForOneGroup(externalShifts);
    if (externalValidRanges.length !== externalShifts.length) {
      return [];
      // no common shift
    }

    if (externalValidRanges.length === 1) {
      masterShift = externalValidRanges[0].validRanges;
    } else {
      masterShift = getIntersect(externalValidRanges.map(e => e.validRanges));
    }

    if (masterShift.length === 0) {
      return [];
    }

    const finalShifts = [];

    for (const x of internalValidRanges) {
      const isValid = getIntersect([x.validRanges, masterShift]);
      if (isValid.length > 0) {
        finalShifts.push(x);
      }
    }
    combinedShifts = [...externalShifts.map(f => f), ...finalShifts.map(f => f.shift),];
    console.log(combinedShifts);
    return combinedShifts;
  }

  return internalShifts;

  //   const intersected = intersection(...validRanges);

  //   return [];
  // return [...combinedShifts];
};

export const getIntersect = (shifts: any): any => {
  if (shifts.length === 0) {
    return [];
  }
  if (shifts.length === 1) {
    return shifts[0];
  }
  if (shifts.length === 2) {
    const result = [];
    const first = shifts[0].length >= shifts[1].length ? shifts[0] : shifts[1];
    const second = shifts[0].length <= shifts[1].length ? shifts[0] : shifts[1];

    for (const x of first) {
      for (const y of second) {
        if (x === y) {
          result.push(x);
        }
      }
    }
    return Array.from(new Set(result));
  }
  return getIntersect([getIntersect(shifts.splice(0, 2)), ...shifts.splice(2, shifts.length)]);
};
