import React, { useReducer } from "react";
import DefaultDialog from "../../molecules/DefaultDialog";
import DefaultDialogContent from "../../molecules/DefaultDialogContent";
import AssignClientModal from "../AssignClientDialog";
import { TransferWpoClientContext } from "./provider";
import { initialState, reducer, STEPS } from "./reducer";

type TTransferWpoClientDialog = {
  onClose: (path?: string, error?: boolean) => void;
  open: boolean;
  businessObjectId: number;
};
const TransferWpoClientDialog = ({ onClose, open, businessObjectId }: TTransferWpoClientDialog) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const setStep = (payload: STEPS) => {
    dispatch({ type: "SET_STEP", payload });
  };
  const setClientId = (payload: number) => {
    dispatch({ type: "SET_CLIENT_ID", payload });
    dispatch({ type: "SET_BUSINESS_OBJECT_ID", payload: businessObjectId });
  };
  const setPersonId = (payload: number) => {
    dispatch({ type: "SET_PERSON_ID", payload });
    dispatch({ type: "SET_BUSINESS_OBJECT_ID", payload: businessObjectId });
  };
  const setBusinessObjectId = (payload: number) => {
    dispatch({ type: "SET_BUSINESS_OBJECT_ID", payload });
  };
  const setPersonName = (payload: string) => {
    dispatch({ type: "SET_PERSON_NAME", payload });
  };
  const setCreateTask = (payload: boolean) => {
    dispatch({ type: "SET_CREATE_TASK", payload });
  };
  const setAutomaticConfirmation = (payload: boolean) => {
    dispatch({ type: "SET_AUTOMATIC_CONFIRMATION", payload });
  };
  const setUcmsId = (payload: number) => {
    dispatch({ type: "SET_UCMS_ID", payload });
  };

  return (
    <DefaultDialog
      open={open}
      onClose={() => {
        setStep(STEPS.UCMS);
        setCreateTask(true);
        setAutomaticConfirmation(true);
        setClientId(-1);
        setPersonId(-1);
        setUcmsId(-1);
        setBusinessObjectId(-1);
        setPersonName("");
        onClose();
      }}
      maxWidth="md"
    >
      <DefaultDialogContent>
        <TransferWpoClientContext.Provider
          value={{
            ...state,
            setStep,
            setClientId,
            setCreateTask,
            setBusinessObjectId,
            setPersonName,
            setPersonId,
            setUcmsId,
            setAutomaticConfirmation,
            onClose: (path, error) => {
              setStep(STEPS.INIT);
              onClose(path, error);
            },
          }}
        >
          <AssignClientModal />
        </TransferWpoClientContext.Provider>
      </DefaultDialogContent>
    </DefaultDialog>
  );
};

export default TransferWpoClientDialog;
