import React from "react";

import ShiftRow from "../../../../organisms/ShiftRow";
import { PapershiftWorkingArea } from "../../../../../generated/graphql";

interface IWorkingAreaPadding {
  workingArea: PapershiftWorkingArea;
}

const WorkingAreaPadding = ({ workingArea }: IWorkingAreaPadding) => {
  return <ShiftRow backgroundImage border="borderBottom" width={8} workingArea={workingArea} />;
};
export default WorkingAreaPadding;
