import React, { useState, FC } from "react";
import * as Yup from "yup";

import Typography from "@material-ui/core/Typography";
import { TextField, Checkbox } from "formik-material-ui";
import StarRate from "@material-ui/icons/StarRate";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";

import { Formik, Field, FormikProps, FormikValues } from "formik";

import { ClientType, Person, useUpdateClientMutation } from "../../../generated/graphql";
import DataColumn from "../../molecules/DataColumn";
import DataRow from "../../molecules/DataRow";
import DefaultDialog from "../../molecules/DefaultDialog";
import DialogContent from "../../molecules/DefaultDialogContent";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import ClientTypeSelect, { translatClientType } from "../../fields/ClientTypeSelect";
import useStyles from "../../forms/formStyles";
import AccountSelect from "../../fields/AccountSelect";
import DepartmentSelect from "../../fields/DepartmentSelect";
import BusinessUnitSelect from "../../fields/BusinessUnitSelect";
import Markup from "../../atoms/Markup";

const Account: FC<{ person: Person; memo: string }> = ({ person, memo }) => {
  const [open, setOpen] = useState(false);
  const [updateClientMutation] = useUpdateClientMutation();

  const client = person.client;

  if (!client) {
    return null;
  }

  const clientType = client.clientType || ClientType.unknown;

  // Code smell & quick fix:
  // The `validationPattern` is used only for the account "Itzehoer Versicherung Assistance".
  // That's why the validation pattern in the error message can be hardcoded here.
  const assistanceIdValidation = client.account?.validationPattern
    ? Yup.string().matches(
      new RegExp(client.account.validationPattern),
      `Versicherungsnummer ist nicht gültig. Bitte folgendes Format einhalten: XXXXXXXX-70-X`,
    )
    : Yup.string();

  return (
    <>
      <DataRow title="Account" editTrigger={() => setOpen(true)}>
        <DataColumn label="Account">
          <Typography variant="body2">{person.client?.account?.name || "-"}</Typography>
        </DataColumn>

        <DataColumn label="Status">
          <Typography variant="body2">{translatClientType(clientType)}</Typography>
        </DataColumn>

        <DataColumn label="Standort">
          <Typography variant="body2">
            {person.client?.department?.name || "Hauptstandort"}
          </Typography>
        </DataColumn>

        <DataColumn label="Beruf">
          <Typography variant="body2">{person.client?.job || "-"}</Typography>
        </DataColumn>

        {client.account?.businessUnits && client.account.businessUnits.length > 0 ? (
          <DataColumn label="Business-Unit">
            <Typography variant="body2">{client.businessUnit?.name ?? "-"}</Typography>
          </DataColumn>
        ) : null}

        <DataColumn label="Eintrittsjahr">
          <Typography variant="body2">{person.client?.yearOfEntry || "-"}</Typography>
        </DataColumn>

        <DataColumn label="Job Band">
          <Typography variant="body2">{person.client?.jobBand || "-"}</Typography>
        </DataColumn>

        <DataColumn label="VIP">
          {!!person.vip ? <StarRate htmlColor="#5AA6E2"></StarRate> : "-"}
        </DataColumn>

        {client?.account?.isAssistance ? (
          <DataColumn label="Versicherungsnummer">
            <Typography variant="body2">{client?.assistanceId || "-"}</Typography>
          </DataColumn>
        ) : null}
      </DataRow>

      <DefaultDialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <Formik
            enableReinitialize
            validationSchema={Yup.object().shape({
              yearOfEntry: Yup.number()
                .typeError("Muss eine Zahl sein")
                .integer("Nur Jahreszahlen erlaubt")
                .min(1900, "Kann nicht weiter in der Vergangenheit liegen")
                .max(new Date().getFullYear(), "Kann nicht in der Zukunft liegen"),
              assistanceId: assistanceIdValidation,
            })}
            onSubmit={async ({ account, ...values }, { setSubmitting }) => {
              await updateClientMutation({
                variables: {
                  clientInput: {
                    ...values,
                    yearOfEntry: typeof values.yearOfEntry !== "string" ? values.yearOfEntry : null,
                    businessUnitId:
                      typeof values.businessUnitId !== "string" ? values.businessUnitId : null,
                  },
                },
              });

              setSubmitting(false);
              setOpen(false);
            }}
            initialValues={{
              accountId: client.account?.id ?? null,
              account: client.account,
              id: client.id,
              departmentId: client.departmentId || undefined,
              job: client.job,
              vip: client.vip,
              jobBand: client.jobBand || "",
              clientType,
              yearOfEntry: client.yearOfEntry ?? "",
              businessUnitId: client.businessUnit?.id ?? "",
              assistanceId: client.assistanceId ?? "",
            }}
          >
            {props => (
              <AccountForm
                {...props}
                cancel={() => setOpen(false)}
                withAssistanceId={!!client?.account?.isAssistance}
                memo={memo}
              />
            )}
          </Formik>
        </DialogContent>
      </DefaultDialog>
    </>
  );
};

interface AccountFormProps {
  cancel: () => void;
  withAssistanceId: boolean;
  memo: string;
}

const AccountForm: FC<AccountFormProps & FormikProps<FormikValues>> = props => {
  const {
    withAssistanceId,
    cancel,
    handleSubmit,
    errors,
    touched,
    setTouched,
    setFieldValue,
    values,
    isSubmitting,
    memo,
  } = props;

  const disableSubmit =
    isSubmitting || (Object.keys(touched).length > 0 && Object.keys(errors).length > 0);

  const classes = useStyles();
  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1" align="center" style={{ marginBottom: "8px" }}>
            Account
          </Typography>
        </Grid>
        {memo !== "" && (
          <Grid item xs={12}>
            <Box className={classes.memoContainer}>
              <Markup value={memo} />
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AccountSelect
            formError={!!errors.account && !!touched.account}
            onBlur={() => {
              setTouched({ ...touched, account: true });
            }}
            onChange={value => {
              if (value) {
                setFieldValue("accountId", value.value);
                setFieldValue("account", value);
                setFieldValue("businessUnitId", "");
              }
            }}
            value={values.account || { name: "Kein Account", value: null }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <Field name="job" label="Beruf" component={TextField} />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          {!!values.accountId && <DepartmentSelect accountId={values.accountId} />}
        </Grid>

        <Grid item xs={12}>
          <BusinessUnitSelect accountId={values.accountId} />
        </Grid>

        <Grid item xs={12}>
          <ClientTypeSelect />
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <Field name="yearOfEntry" label="Eintrittsjahr" type="number" component={TextField} />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <Field name="jobBand" label="Job Band" component={TextField} />
          </FormControl>
        </Grid>
        {withAssistanceId ? (
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <Field
                name="assistanceId"
                label="Versicherungsnummer"
                type="string"
                component={TextField}
              />
            </FormControl>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <FormControl margin="normal" fullWidth>
            <FormControlLabel
              control={
                <Field
                  label="VIP"
                  name="vip"
                  value={!!props.values.vip}
                  component={Checkbox}
                  type="checkbox"
                />
              }
              label="VIP"
            />
          </FormControl>
        </Grid>
      </Grid>
      <Box marginTop={4} display="flex" justifyContent="center" flexDirection="row-reverse">
        <PrimaryButton type="submit" disabled={disableSubmit}>
          OK
        </PrimaryButton>
        <Box mx={1}></Box>
        <SecondaryButton onClick={cancel}>Abbrechen</SecondaryButton>
      </Box>
    </form>
  );
};
export default Account;
