import React, { FC } from "react";

import useStyles from "./styles";

const Main: FC = ({ children }) => {
  const classes = useStyles();

  return <main className={classes.content}>{children}</main>;
};

export default Main;
