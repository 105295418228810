/* eslint-disable no-control-regex */
export default function validURL(url: string, allowEmpty?: boolean) {
  if (allowEmpty && (!url || url === "")) {
    return true;
  }

  const pattern = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&=]*)/gi;

  return !!pattern.test(url);
}
