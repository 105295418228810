import React, { useState } from "react";
import dayjs from "dayjs";
import DayjsUtils from "@date-io/dayjs";
import { useMutation } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import { orderBy } from "lodash";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";

import {
  getTaskPath,
  getTaskTitle,
  taskIsStarted,
  taskIsPending,
  taskIsSnoozed,
  isReportTask,
  isServiceTask,
} from "../../../utils/taskHelpers";
import {
  Task,
  AssignTaskDocument,
  ConversationReport,
  PersonActivity,
  TaskType,
} from "../../../generated/graphql";
import useScrollTop from "../../../hooks/useScrollTop";
import Avatar from "../../atoms/Avatar";
import { UserAvatarFromPerson } from "../UserAvatar";

import { InboxWrapper as TaskWrapper, TaskActions } from "./components";
import useStyles from "../../assets/inboxStyles";
import unassignedAvatar from "../../assets/unassignedAvatar.svg";
import { InboxComponent } from "../InboxComponent";
import InboxGroup, { InboxHeadline } from "../InboxGroup";
import CallDialog from "../../organisms/CallDialog";

import man from "../../assets/man.svg";

type TTaskItem = {
  isAdmin?: boolean;
  isInbox?: boolean;
  task: Task;
  teamMode?: boolean;  
  hideActions?: boolean;  
  assignPerson?: (activityId?: number) => void;
};
const TaskItem = ({ isAdmin, task, hideActions, teamMode, assignPerson, isInbox }: TTaskItem) => {
  const { author, dueDate, id, person, personActivity, report, taskType, creator } = task;
  const [assignSelf] = useMutation(AssignTaskDocument, {
    variables: { taskId: id!, start: true },
  });

  const { push } = useHistory();
  const { pathname: locationPath } = useLocation();

  if (report) {
    return (
      <CMTaskItem
        isAdmin={isAdmin}
        report={report}
        task={task}
        hideActions={hideActions}
        isInbox={isInbox}
      />
    );
  }

  const avatar = author?.picture || author?.avatar || (author?.id ? "" : unassignedAvatar);
  const assigneeA = creator?.picture || creator?.avatar || (creator?.id ? "" : undefined);

  const { pathname, state } = getTaskPath(task);

  let activity: PersonActivity | null | undefined;
  if (isReportTask(task)) {
    activity = personActivity;
  }
  let serviceName: string | null | undefined;
  if (isServiceTask(task)) {
    serviceName = personActivity?.service?.serviceMeta?.name;
  }
  const assign = () => {
    assignSelf().then(() => {
      push({
        pathname: pathname === "" && locationPath === "/pool" ? "/" : pathname,
        state: { activity, ...(state ? state : {}) },
      });
    });
  };

  if (taskType === TaskType.custom) {
    return (            
        <InboxComponent
        dates={{
          dueDate: dueDate,
          start: task.created!,
        }}
        title={{
          name: task.title!,  
          team: task.team,        
          onClick: !task.startedAt ? assign : null,
        }}
        context={{
          person: person,
          topic: task.topic,
          service: task.service,
        }}
        leading={
          isAdmin && (
            <Avatar widthfactor={5} src={avatar}>
              {(author && author.avatarKeys) || "NN"}
            </Avatar>
          )
        }
        assigneeAvatar={
          !isAdmin &&
          !!creator && (
            <Avatar src={assigneeA} widthfactor={5}>
              {creator?.avatarKeys || "NN"}
            </Avatar>
          )
        }
        overdue={!!dueDate && dayjs().isAfter(dayjs(dueDate))}
        note={task.note}
        actions={!hideActions && <TaskActions task={task} mayFinish={isInbox} />}
      />      
    );
  }

  // CM Reminder tasks are not connected with a personActivity yet (Do so?)
  if (!person || !person.id) {
    return <></>;
  }

  const title = taskType ? getTaskTitle(task, serviceName) : "";
  // console.log(author, pathname, task)  
  return (
    <InboxComponent
      title={{
        name: title,
        team: task.team,
        link: author ? { pathname, state: { activity } } : undefined,
        onClick: task.person?.temporary
          ? () => (assignPerson ? assignPerson(task?.personActivity?.id) : undefined)
          : author
          ? undefined
          : assign,
      }}
      dates={{
        dueDate: dueDate,
        snoozedDate: task?.snoozeUntil,
        start: task.personActivity?.start || task.created,
        end: task.personActivity?.end,
      }}
      overdue={!!dueDate && dayjs().isAfter(dayjs(dueDate))}
      leading={
        isAdmin &&
        (!!author ? (
          <UserAvatarFromPerson person={author} />
        ) : (
          <Avatar src={unassignedAvatar} widthfactor={5} />
        ))
      }
      context={{
        person,
      }}
      assigneeAvatar={
        !isAdmin &&
        !!creator && (
          <Avatar src={assigneeA} widthfactor={5}>
            {creator?.avatarKeys || "NN"}
          </Avatar>
        )
      }
      note={task.note}
      actions={!hideActions && <TaskActions task={task} mayFinish={isInbox} />}
    />
  );
};
type TCMTaskItem = {
  isAdmin?: boolean;
  isInbox?: boolean;
  report: ConversationReport;
  task: Task;
  hideActions?: boolean;
};
const CMTaskItem = ({ isAdmin, report, task, hideActions, isInbox }: TCMTaskItem) => {
  const { id, taskType, author, person, creator } = task;
  const [assignSelf] = useMutation(AssignTaskDocument, {
    variables: { taskId: id || 0, start: true },
  });

  const { push } = useHistory();

  const { pathname, state } = getTaskPath(task);
  const assigneeA = creator?.picture || creator?.avatar || (creator?.id ? "" : undefined);
  const personActivity = report?.personActivity;

  const assign = () => {
    assignSelf().then(() => {
      push({ pathname, state });
    });
  };

  const title = taskType ? getTaskTitle(task) : "";

  return (
    <InboxComponent
      title={{
        name: title,
        team: task.team,
        link: { pathname, state },
        onClick: author ? undefined : assign,
      }}
      dates={{
        start: personActivity?.start,
        end: personActivity?.end,
        snoozedDate: task?.snoozeUntil,
      }}
      leading={
        isAdmin &&
        (!!author ? (
          <UserAvatarFromPerson person={author} />
        ) : (
          <Avatar src={unassignedAvatar} widthfactor={5} />
        ))
      }
      context={{
        person,
      }}
      assigneeAvatar={
        !isAdmin &&
        !!creator && (
          <Avatar src={assigneeA} widthfactor={5}>
            {creator?.avatarKeys || "NN"}
          </Avatar>
        )
      }
      note={task.note}
      actions={!hideActions && <TaskActions task={task} mayFinish={isInbox} />}
    />
  );
};

type TTaskKeys = "In Bearbeitung" | "Offen" | "Zurückgestellt" | "free";

type TAllTasks = {
  "In Bearbeitung"?: Task[];
  Offen?: Task[];
  Zurückgestellt?: Task[];
  free?: never;
};

type TFreeTasks = {
  "In Bearbeitung"?: never;
  Offen?: never;
  Zurückgestellt?: never;
  free?: Task[];
};

type TTaskList = {
  isAdmin?: boolean;
  tasks: Task[];
  showPlaceholder?: boolean;
  hideHeadline?: boolean;
  hideActions?: boolean;
  teamMode?: boolean;
  isInbox?: boolean;
};
const TaskList = ({
  isAdmin,
  tasks,
  hideHeadline,
  hideActions,
  teamMode, 
  showPlaceholder,
  isInbox,
}: TTaskList) => {
  const classes = useStyles();
  useScrollTop();

  const [activityId, setActivityId] = useState<number | undefined>();

  if (showPlaceholder) {
    return (
      <Grid container justify="center">
        <img className={classes.man} src={man} alt="Inbox ist abgearbeitet" />
      </Grid>
    );
  }

  const pendingTasks = tasks.filter(taskIsPending).filter(task => !taskIsSnoozed(task));
  const inProgressTasks = tasks.filter(taskIsStarted).filter(task => !taskIsSnoozed(task));
  const snoozedTasks = tasks.filter(taskIsSnoozed);

  const allTasks: TAllTasks | TFreeTasks = !hideHeadline
    ? {
        "In Bearbeitung": inProgressTasks.length > 0 ? inProgressTasks : undefined,
        Offen: pendingTasks.length > 0 ? pendingTasks : undefined,
        Zurückgestellt: snoozedTasks.length > 0 ? snoozedTasks : undefined,
      }
    : { free: tasks };

  const taskCounts = {
    "In Bearbeitung": inProgressTasks.length,
    Offen: pendingTasks.length,
    Zurückgestellt: snoozedTasks.length,
    free: tasks.length,
  };

  const assignPerson = (activityId: number | undefined) => {
    setActivityId(activityId);
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <>
          {Object.keys(allTasks)
            // @ts-ignore
            .map((taskKey: TTaskKeys) => {
              const tasks = allTasks[taskKey];
              const count = taskCounts[taskKey];
              if (!tasks || !count) {
                return null;
              }

              return (
                <InboxGroup key={taskKey}>
                  {!hideHeadline && (
                    <TaskWrapper isHeadline>
                      <InboxHeadline>
                        {taskKey} ({count})
                      </InboxHeadline>
                    </TaskWrapper>
                  )}

                  {orderBy(tasks, ["created"], "asc").map((task: Task) => (
                    <TaskItem
                      isInbox={isInbox}
                      isAdmin={isAdmin}
                      key={`${task.id}-${task.snoozeUntil}`}
                      task={task}
                      teamMode={teamMode}
                      hideActions={hideActions}
                      assignPerson={assignPerson}
                    />
                  ))}
                </InboxGroup>
              );
            })}
        </>
      </MuiPickersUtilsProvider>
      <CallDialog
        activityId={activityId}
        open={!!activityId}
        handleClose={() => {
          setActivityId(undefined);
        }}
        pushTarget={personId => `/person/${personId}/report`}
      />
    </>
  );
};

export default TaskList;
