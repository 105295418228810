import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  dialogRoot: {
    padding: `${theme.spacing(6, 11)} !important`,
  },
  minimize: {
    marginRight: theme.spacing(-1.5),
  },
}));
