import React, { useState, FC } from "react";
import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Create from "@material-ui/icons/Create";
import DefaultDialog from "../DefaultDialog";
import PersonNoteForm from "../../forms/PersonNoteForm";
import validationSchema from "../../forms/PersonNoteForm/validation";
import DefaultDialogContent from "../DefaultDialogContent";

import useStyles from "./styles";
import { Person, UpdatePersonDocument } from "../../../generated/graphql";

import postit from "../../assets/postit.svg";
import postitEmpty from "../../assets/emptyPostit.svg";

const PersonNote: FC<{ person: Person }> = ({ person }) => {
  const [update] = useMutation(UpdatePersonDocument);
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleSubmit = (values: object) => {
    const id = person.id;
    if (!id) return Promise.reject();
    return update({ variables: { person: { id, ...values } } });
  };

  const hasNote = person.note && person.note.length > 0;

  return (
    <>
      {hasNote ? (
        <Box position="relative">
          <Box position="absolute" right={8} top={-4}>
            <img src={postit} alt="note" />
          </Box>
          <Box
            position="absolute"
            width="204px"
            right={22}
            display="flex"
            alignItems="flex-end"
            flexDirection="column"
          >
            <IconButton size="small" onClick={() => setOpen(true)}>
              <Create color="primary" fontSize="small" />
            </IconButton>
            <Typography variant="body2" className={classes.light}>
              {person.note || ""}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box position="relative">
          <Box position="absolute" right={8} top={-4}>
            <img src={postitEmpty} alt="note" />
          </Box>
          <Box
            position="absolute"
            width="204px"
            right={22}
            display="flex"
            alignItems="flex-end"
            flexDirection="column"
          >
            <IconButton size="small" onClick={() => setOpen(true)}>
              <Create color="primary" fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      )}
      <DefaultDialog open={open} onClose={hasNote ? undefined : () => setOpen(false)}>
        <DefaultDialogContent>
          <Box mb={3} display="flex" justifyContent="center">
            <Typography variant="h1">
              Hinterlege eine Notiz{person.name ? ` zu ${person.name}` : ""}
            </Typography>
          </Box>
          <Formik
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values).then(() => {
                setSubmitting(false);
                setOpen(!open);
              });
            }}
            initialValues={{ note: person.note || "" }}
            validationSchema={validationSchema}
          >
            {props => <PersonNoteForm {...props} cancel={() => setOpen(false)}></PersonNoteForm>}
          </Formik>
        </DefaultDialogContent>
      </DefaultDialog>
    </>
  );
};

export default PersonNote;
