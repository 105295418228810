import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import Typography from "@material-ui/core/Typography";

import { Formik } from "formik";

import {
  CreateEmailDocument,
  DeleteEmailDocument,
  Email,
  EmailType,
  UpdateEmailsDocument,
} from "../../../../generated/graphql";
import DataColumn from "../../../molecules/DataColumn";
import DataRow from "../../../molecules/DataRow";
import DefaultDialog from "../../../molecules/DefaultDialog";
import DialogContent from "../../../molecules/DefaultDialogContent";
import { EmailTypeMap } from "../../../molecules/EmailTypeSelect";

import { ContactAllowed } from "./components";
import ContactEmailsForm from "./ContactEmailsForm";

const newEmail = {
  email: "",
  contactAllowed: true,
  type: EmailType.private,
};

type TContactEmails = {
  emails: Email[];
  personId: number;
  created: Email[];
  setCreated: React.Dispatch<React.SetStateAction<Email[]>>;
};
const ContactEmails = ({ emails, personId, created, setCreated }: TContactEmails) => {
  const [open, setOpen] = useState(false);

  // TODO use hooks from generated
  const [createEmail] = useMutation(CreateEmailDocument);
  const [updateEmails] = useMutation(UpdateEmailsDocument);
  const [deleteEmail] = useMutation(DeleteEmailDocument);

  const newElements: Email[] = emails.length < 1 && created.length < 1 ? [newEmail] : created;

  const handleRemove = (id: number) => {
    deleteEmail({
      variables: {
        personId,
        id,
      },
    });
  };

  const handleSubmit = async (values: { create?: Email[]; update?: Email[] }, autosave = true) => {
    // Persist newly created emails
    setCreated(values.create || []);

    if (values.create && !autosave) {
      createEmail({
        variables: {
          emailsInput: values.create.filter(email => !!email.email),
          personId,
        },
      });
    }

    if (values.update) {
      updateEmails({
        variables: {
          personId,
          emailsInput: values.update
            .filter(email => !!email.email)
            .map(email => {
              return {
                id: email.id,
                type: email.type,
                email: email.email,
                contactAllowed: email.contactAllowed,
              };
            }),
        },
      });
    }
  };

  return (
    <DataRow
      title={`E-Mail-Adresse${emails.length > 1 ? "n" : ""}`}
      editTrigger={() => setOpen(true)}
    >
      <Emails emails={emails} />
      <DefaultDialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <Formik
            enableReinitialize
            onSubmit={async (values, { setSubmitting }) => {
              await handleSubmit(values, false);
              setCreated([]);
              setSubmitting(false);
              setOpen(false);
            }}
            initialValues={{
              update: emails,
              create: newElements,
            }}
          >
            {props => (
              <ContactEmailsForm
                cancel={() => setOpen(false)}
                {...props}
                create={() => {
                  props.values.create.push(newEmail);
                  props.validateForm();
                }}
                remove={handleRemove}
              />
            )}
          </Formik>
        </DialogContent>
      </DefaultDialog>
    </DataRow>
  );
};

const Emails = ({ emails }: { emails: Email[] }) => {
  if (emails.length < 1) {
    return (
      <DataColumn>
        <Typography variant="body2">-</Typography>
      </DataColumn>
    );
  }

  return (
    <>
      {emails.map(email => {
        const type = email.type;
        const contactAllowed = email.contactAllowed;

        return (
          <DataColumn
            label={EmailTypeMap.get(type || EmailType.private)}
            key={`email-list-${email.id}` || "email"}
            xs={12}
          >
            <Typography variant="body2">{email.email || "-"}</Typography>

            <ContactAllowed contactAllowed={!!contactAllowed}>
              {contactAllowed ? "Kontakt erlaubt" : "Kontakt nicht erlaubt"}
            </ContactAllowed>
          </DataColumn>
        );
      })}
    </>
  );
};
export default ContactEmails;
