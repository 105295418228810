import React, { useState, SetStateAction, Dispatch } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import placeholderIcon from "../../../assets/placeholderIcon.svg";
import {
  Service,
  ServiceType,
  useBusinessObjectsQuery,
  BusinessObject,
  ServiceResearchObject,
} from "../../../../generated/graphql";
import { fetchPolicy } from "../../../../utils/constants";
import Loader from "../../../atoms/Loading";
import ServiceExtendedInfo from "../../../molecules/ServiceExtendedInfo";
import { CustomError } from "../../../molecules/ErrorFallback/ErrorFallback";
import ResearchList from "../ResearchList";
import ResearchListNoBusiness from "../ResearchListNoBusiness";
import useStyles from "./styles";

const Research = ({
  service,
  serviceResearchObjects,
}: {
  service: Service;
  serviceResearchObjects: ServiceResearchObject[];
}) => {
  const serviceId = service.id!;
  const classes = useStyles();
  const { state } = useLocation();

  const { data: businessData, loading: businessLoading, error } = useBusinessObjectsQuery({
    variables: {
      serviceId: service.id,
      unique: true,
      withAddress: true,
    },
    fetchPolicy,
  });

  const [showResearchList, setShowResearchList] = useState(false);

  if (businessLoading) {
    return <Loader />;
  }

  if (error) {
    throw new CustomError("Die Recherche-Elemente konnten nicht geladen werden", error);
  }

  const businessObjects = (businessData?.businessObjects || []) as BusinessObject[];

  // @ts-ignore
  const hideTeaserPage = state?.hideTeaserPage;

  return (
    <Grid container>
      {!hideTeaserPage && <ServiceExtendedInfo service={service} />}
      {service.serviceMeta?.type === ServiceType.researchBlank ? (
        <ResearchListNoBusiness
          key={`researchList-${serviceId}`}
          items={serviceResearchObjects}
          service={service}
          serviceId={serviceId}
        />
      ) : !serviceResearchObjects.length &&
        !showResearchList &&
        Boolean(businessObjects.length) &&
        !hideTeaserPage ? (
        <TeaserPage
          businessObjectsCount={businessObjects.length}
          gridClassName={classes.teaserPageGridClassName}
          placeholderIconClassName={classes.placeholderIcon}
          serviceId={serviceId}
          showEmptyList={setShowResearchList}
        />
      ) : (
        <ResearchList
          key={`researchList-${serviceId}`}
          items={serviceResearchObjects}
          service={service}
          serviceId={serviceId}
        />
      )}
    </Grid>
  );
};

type TTeaserPage = {
  businessObjectsCount: number;
  gridClassName: string;
  placeholderIconClassName: string;
  serviceId: number;
  showEmptyList: Dispatch<SetStateAction<boolean>>;
};
const TeaserPage = (props: TTeaserPage) => {
  const { push } = useHistory();

  const suggestionText =
    props.businessObjectsCount > 1 ? "Kontaktvorschläge übernehmen" : "Kontaktvorschlag übernehmen";

  return (
    <>
      <Grid item xs={12} className={props.gridClassName}>
        <img
          className={props.placeholderIconClassName}
          src={placeholderIcon}
          alt="Keine Recherchetasks vorhanden"
        />
      </Grid>
      <Grid item xs={12} className={props.gridClassName}>
        <Typography variant="body2">
          Es sind noch keine Einträge vorhanden, jetzt {props.businessObjectsCount}{" "}
          <Link
            variant="h4"
            underline="always"
            color="primary"
            onClick={() =>
              push(`/service/${props.serviceId}/research/suggestionList`, {
                hideTeaserPage: true,
              })
            }
          >
            {suggestionText}
          </Link>
        </Typography>
        <Typography variant="body2">
          oder{" "}
          <Link
            variant="h4"
            underline="always"
            color="primary"
            onClick={() => props.showEmptyList(true)}
          >
            ohne Vorschläge beginnen.
          </Link>
        </Typography>
      </Grid>
    </>
  );
};
export default Research;
