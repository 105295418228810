import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  addHourButton: {
    position: "absolute",
    right: 0,
  },
  clearIcon: {
    fontSize: "1rem",
  },
  hourEntry: {
    "&:first-child": {
      paddingTop: 10,
    },
    "&:last-child": {
      paddingBottom: 10,
    },
  },
  removeHourButton: {
    color: "#bdbdbd",
    marginRight: theme.spacing(1),
  },
}));
