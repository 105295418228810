import React from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { Hour } from "../../../../generated/graphql";
import PrimaryButton from "../../../atoms/PrimaryButton";
import Dialog from "../../../molecules/DefaultDialog";
import DialogContent from "../../../molecules/DefaultDialogContent";

import DaysTimeSelector from "../../../molecules/DaysTimeSelector/DaysTimeSelector";
import IHour from "../../../../interfaces/IHour";

interface HoursDialogProps {
  formUntouched: boolean;
  hours?: IHour[];
  open: boolean;
  setFieldValue: (hours: Hour[]) => void;
  toggle: (open: boolean) => void;
}

export default function HoursDialog({
  formUntouched,
  hours,
  open,
  setFieldValue,
  toggle,
}: HoursDialogProps) {
  if (!hours) {
    return <></>;
  }

  const methods = {
    identifier: "uuid",
    create: (hour: Hour) => {
      hours.push(hour);
      setFieldValue(hours);
    },
    delete: (uuid: string) => {
      const deleteIndex = hours.findIndex(hour => hour != null && hour.uuid === uuid);
      if (deleteIndex > -1) {
        hours.splice(deleteIndex, 1);
        setFieldValue(hours);
      }
    },
    deleteDayHours: (day: number) => {
      const newHours = hours.filter(hour => hour.day !== day);
      setFieldValue(newHours);
    },
    update: (hour: Hour, uuid: string) => {
      const updateIndex = hours.findIndex(hour => hour != null && hour.uuid === uuid);
      if (updateIndex > -1) {
        hours.splice(updateIndex, 1, hour);
        setFieldValue(hours);
      }
    },
  };

  return (
    <Dialog open={open} key="dialog">
      <DialogContent>
        <Box mb={3} display="flex" justifyContent="center">
          <Typography variant="h1">Öffnungszeiten</Typography>
        </Box>
        <DaysTimeSelector
          formHours={hours}
          initial={!!hours && formUntouched}
          methods={methods}
        ></DaysTimeSelector>

        <Box mt={5}>
          <PrimaryButton type="submit" onClick={() => toggle(!open)}>
            OK
          </PrimaryButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
