import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    // height: 600,
  },
  closeButton: {
    color: theme.palette.primary.dark,
    right: theme.spacing(1),
    position: "absolute" as "absolute",
    top: theme.spacing(1),
  },
  elliDesk: {
    width: theme.spacing(20),
  },

  clientSelectorContainer: {
    width: '98%',
    padding: '4px',
    position: 'fixed',
    bottom: '20px',
    left: '1%',
  },

  clientSelector: {
    backgroundColor: '#fff',
    width: '100%',
    height: theme.spacing(9),
    borderRadius: '4px',
    padding: '4px',
    display: 'grid',
    gridTemplateColumns: '1fr repeat(3, auto) 1fr',
    gridColumnGap: theme.spacing(4),
    alignItems: 'center',
    justifyItems: 'center',
    gridTemplateRows: 'auto',
    "& > div:nth-child(1)": {
      justifySelf: 'left'
    }
  },

  previewOverlay: {
    scale: '.97',
    pointerEvents: 'none',
    opacity: 0.8
  },

  personPreviewContainer: {
    backgroundColor: theme.palette.background.default,
    "& > header": {
      position: 'static'
    }
  },

  disabledLink: {
    pointerEvents: 'none',
    "& > a": {
      textDecoration: 'none',
      color: '#aaa'
    }
  },

  enabledLink: {
    pointerEvents: 'auto',
    "& > a": {
      textDecoration: 'underline',
      color: 'auto'
    }
  },

  headline: {
    textAlign: "center",
    marginBottom: theme.spacing(4),
  },
  subHeadline: {
    fontWeight: theme.typography.fontWeightRegular,
    margin: theme.spacing(4, 0, 2),
    textAlign: "center",
  },

}));

export default useStyles;
