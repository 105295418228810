import React from "react";
import DialogForm from "../DialogForm";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Field } from "formik";
import { TextField, Checkbox } from "formik-material-ui";
import {
  useCreateDepartmentMutation,
  useUpdateDepartmentMutation,
  Department,
} from "../../../generated/graphql";

type TDepartmentDialog = {
  disabled: boolean;
  defaultChecked: boolean;
  accountId: number;
  open: boolean;
  department?: Department;
  toggle: () => void;
};
const DepartmentDialog = ({
  open,
  toggle,
  department,
  accountId,
  disabled = false,
  defaultChecked = false,
}: TDepartmentDialog) => {
  const [createDepartment] = useCreateDepartmentMutation();
  const [updateDepartment] = useUpdateDepartmentMutation();
  return (
    <DialogForm
      open={open}
      initialValues={{
        name: department?.name || "",
        main: department?.main || defaultChecked,
      }}
      onClose={toggle}
      title={`Standort ${department ? "bearbeiten" : "anlegen"}`}
      onSubmit={values => {
        const action = department
          ? updateDepartment({
              variables: {
                id: department.id!,
                input: {
                  ...values,
                },
              },
            }).then(() => {
              toggle();
            })
          : createDepartment({ variables: { accountId, input: { ...values } } }).then(() => {
              toggle();
            });

        return action;
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl margin="normal" fullWidth>
            <Field name="name" component={TextField} label="Name" />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl margin="normal" fullWidth>
            <FormControlLabel
              control={<Field name="main" component={Checkbox} label="Hauptstandort" />}
              label="Hauptstandort"
              disabled={disabled || defaultChecked}
            ></FormControlLabel>
          </FormControl>
        </Grid>
      </Grid>
    </DialogForm>
  );
};
export default DepartmentDialog;
