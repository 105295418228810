// @ts-nocheck
import React, { useState, useEffect, useMemo, useCallback, useRef, FC } from "react";
import isHotkey from "is-hotkey";
import escapeHtml from "escape-html";
import has from "lodash/has";
import { Editable, withReact, useSlate, Slate } from "slate-react";
import { Editor, createEditor, Node, Transforms, Text } from "slate";
import { jsx } from "slate-hyperscript";
import { withHistory } from "slate-history";
import classNames from "classnames";
import dayjs from "dayjs";

import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Create from "@material-ui/icons/Create";
import FormatBold from "@material-ui/icons/FormatBold";
import FormatItalic from "@material-ui/icons/FormatItalic";
import FormatUnderlined from "@material-ui/icons/FormatUnderlined";
import FormatListBulleted from "@material-ui/icons/FormatListBulleted";
import EditorButton from "../../atoms/EditorButton";
import { useStyles } from "./styles";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
};

const LIST_TYPES = ["bulleted-list"];

const ELEMENT_TAGS = {
  A: el => ({ type: "paragraph" }),
  BLOCKQUOTE: () => ({ type: "quote" }),
  H1: () => ({ type: "paragraph" }),
  H2: () => ({ type: "paragraph" }),
  H3: () => ({ type: "paragraph" }),
  H4: () => ({ type: "paragraph" }),
  H5: () => ({ type: "paragraph" }),
  H6: () => ({ type: "paragraph" }),
  IMG: el => ({ type: "paragraph" }),
  LI: () => ({ type: "list-item" }),
  OL: () => ({ type: "bulleted-list" }),
  P: () => ({ type: "paragraph" }),
  PRE: () => ({ type: "paragraph" }),
  UL: () => ({ type: "bulleted-list" }),
};

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
};

export const deserialize = el => {
  if (el.nodeType === 3) {
    return el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  } else if (el.nodeName === "BR") {
    return "\n";
  }

  const { nodeName } = el;
  let parent = el;

  if (nodeName === "PRE" && el.childNodes[0] && el.childNodes[0].nodeName === "CODE") {
    parent = el.childNodes[0];
  }

  const children = Array.from(parent.childNodes)
    .map(deserialize)
    .flat();

  if (el.nodeName === "BODY") {
    return jsx("fragment", {}, children);
  }

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el);
    return jsx("element", attrs, children);
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el);
    return children.map(child => jsx("text", attrs, child));
  }

  return children;
};

export interface IRTEFieldProps {
  autoFocus?: boolean;
  autoSave?: (value: string, date: string) => void;
  handleChange: (newValue: string) => void;
  initiallyReadOnly?: boolean;
  label?: string;
  lastUpdate?: string | null;
  padded?: boolean;
  small?: boolean;
  tabIndex?: number;
  value: string | null | undefined;
}

const nonFCProps = [
  "autoFocus",
  "autoSave",
  "handleChange",
  "initiallyReadOnly",
  "label",
  "lastUpdate",
  "padded",
  "small",
  "tabIndex",
  "value",
];

const SlateRTEField = ({
  error,
  initiallyReadOnly,
  label,
  padded = true,
  small,
  value,
  ...props
}: IRTEFieldProps & FormControlProps) => {
  const classes = useStyles(padded);
  const [readOnly, toggleReadOnly] = useState(initiallyReadOnly || false);
  const fcprops = { ...props };
  // removes DOM Attributes in React 16 warning
  nonFCProps.forEach(p => {
    if (has(fcprops, p)) delete fcprops[`${p}`];
  });
  return (
    <FormControl
      {...fcprops}
      className={classNames(classes.field, { [classes.smallField]: small, "Mui-error": !!error })}
    >
      <InputLabel
        shrink
        classes={{
          root: small ? classes.customLabelRootSmall : classes.customLabelRoot,
        }}
        onClick={initiallyReadOnly ? () => toggleReadOnly(!readOnly) : () => undefined}
      >
        <Typography variant="body2" gutterBottom>
          {label}
        </Typography>
      </InputLabel>
      {initiallyReadOnly && (
        <Create
          className={classNames(classes.createIcon, {
            [classes.enabled]: !readOnly,
          })}
          onClick={() => toggleReadOnly(!readOnly)}
        />
      )}
      <Box
        marginTop={3}
        borderTop="1px solid #bdbdbd"
        color={readOnly ? "text.primary" : "primary.main"}
        className={classes.editor}
      >
        {!readOnly ? <SlateEditor {...props} value={value} /> : <ReadOnlyEditor value={value} />}
      </Box>
    </FormControl>
  );
};

const SlateEditor: FC<IRTEFieldProps & FormControlProps> = props => {
  if (props.autoSave) {
    return <EditableEditorSubscription {...props} />;
  }
  return <EditableEditor {...props} />;
};

export const useRTEAutoSave = (autoSave: (value: string, date: string) => void) => {
  const timer = useRef<NodeJS.Timeout | null>(null);
  const currentValue = useRef(initialFormValue);
  const lastDate = useRef(dayjs());
  const lastValue = useRef(initialFormValue);

  useEffect(() => {
    timer.current = setTimeout(() => {
      if (currentValue.current !== lastValue.current) {
        const now = dayjs();
        lastValue.current = currentValue.current;
        autoSave(currentValue.current, now.toISOString());
      }
    }, 500);

    return () => {
      if (timer.current) clearTimeout(timer.current);
      timer.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue.current, timer]);

  return (value: string, date: dayjs.Dayjs) => {
    lastDate.current = date;
    currentValue.current = value;
  };
};

const EditableEditorSubscription: FC<IRTEFieldProps & FormControlProps> = ({
  autoFocus,
  autoSave,
  lastUpdate,
  tabIndex,
  value,
}) => {
  let val;
  try {
    if (!value) {
      val = initialValue;
    } else {
      val = JSON.parse(value);
    }
  } catch (error) {
    // do nothing

    val = initialValue;
  }

  const lastType = useRef(dayjs());
  const [content, setContent] = useState(val || initalValue);
  useEffect(() => {
    if (
      !lastUpdate ||
      lastType.current.isBefore(
        dayjs(lastUpdate)
          // TODO: fix me! Comment in locally
          // .add(4, "hour")
          .subtract(600, "millisecond"),
      )
    ) {
      try {
        const subscriptionVal = JSON.parse(value);
        setContent(subscriptionVal);
      } catch (error) {
        console.log("in effect", error, value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, autoFocus]);
  const save = useRTEAutoSave(autoSave);
  const classes = useStyles();

  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(() => withRichText(withHistory(withReact(createEditor()))), []);

  const onChange = value => {
    setContent(value);
    const now = dayjs();
    lastType.current = now;
    save(JSON.stringify(value), now.toISOString());
    // handleChange();
  };
  return (
    <Slate editor={editor} value={content} onChange={value => onChange(value)}>
      <Editable
        tabIndex={tabIndex}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        spellCheck
        autoFocus={autoFocus}
        onKeyDown={event => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
              event.preventDefault();
              const mark = HOTKEYS[hotkey];
              editor.exec({
                type: "format_text",
                properties: { [mark]: true },
              });
            }
          }
        }}
      />
      <div className={classes.toolbar}>
        <MarkButton format="bold" icon="format_bold" />
        <MarkButton format="italic" icon="format_italic" />
        <MarkButton format="underline" icon="format_underlined" />
        <BlockButton format="bulleted-list" icon="format_list_bulleted" />
      </div>
    </Slate>
  );
};

const EditableEditor: FC<IRTEFieldProps & FormControlProps> = ({
  tabIndex,
  value,
  handleChange,
  autoFocus,
}) => {
  let val = initialValue;
  try {
    val = JSON.parse(value);
  } catch (error) {
    console.log(error);
  }
  const classes = useStyles();
  const [content, setContent] = useState(val);
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(() => withRichText(withHistory(withReact(createEditor()))), []);
  const onChange = value => {
    setContent(value);
    handleChange(JSON.stringify(value));
  };
  return (
    <Slate editor={editor} value={content} onChange={onChange}>
      <Editable
        tabIndex={tabIndex}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        spellCheck
        autoFocus={autoFocus}
        onKeyDown={event => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
              event.preventDefault();
              const mark = HOTKEYS[hotkey];
              editor.exec({
                type: "format_text",
                properties: { [mark]: true },
              });
            }
          }
        }}
      />
      <div className={classes.toolbar}>
        <MarkButton format="bold" icon="format_bold" />
        <MarkButton format="italic" icon="format_italic" />
        <MarkButton format="underline" icon="format_underlined" />
        <BlockButton format="bulleted-list" icon="format_list_bulleted" />
      </div>
    </Slate>
  );
};

export const ReadOnlyEditor = ({ value }) => {
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(() => withRichText(withHistory(withReact(createEditor()))), []);
  const classes = useStyles(true);
  let content = initialValue;
  try {
    content = JSON.parse(value);
  } catch (error) {
    console.log(error);
  }

  return (
    <Slate editor={editor} value={content}>
      <Editable
        readOnly
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        className={classes.readOnly}
      />
    </Slate>
  );
};

const withRichText = editor => {
  const { exec } = editor;

  editor.exec = command => {
    if (command.type === "format_block") {
      const { format } = command;
      const isActive = isBlockActive(editor, format);
      const isList = LIST_TYPES.includes(format);

      for (const f of LIST_TYPES) {
        Editor.unwrapNodes(editor, { match: n => n.type === f, split: true });
      }

      Editor.setNodes(editor, {
        type: isActive ? "paragraph" : isList ? "list-item" : format,
      });

      if (!isActive && isList) {
        Editor.wrapNodes(editor, { type: format, children: [] });
      }
    }
    if (command.type === "insert_data") {
      const { data } = command;
      const html = data.getData("text/html");

      if (html) {
        const parsed = new DOMParser().parseFromString(html, "text/html");
        const fragment = deserialize(parsed.body);
        Editor.insertFragment(editor, fragment);
        return;
      }
    } else {
      exec(command);
    }
  };

  return editor;
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? "paragraph" : isList ? "list-item" : format,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === format,
  });

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>;
    case "list-item":
      return <li {...attributes}>{children}</li>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const renderIcon = icon => {
  switch (icon) {
    case "format_bold":
      return <FormatBold />;
    case "format_underlined":
      return <FormatUnderlined />;
    case "format_italic":
      return <FormatItalic />;
    case "format_list_bulleted":
      return <FormatListBulleted />;
    default:
      return <span />;
  }
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <EditorButton
      toggled={isBlockActive(editor, format)}
      toggleAction={event => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {renderIcon(icon)}
    </EditorButton>
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <EditorButton
      toggled={isMarkActive(editor, format)}
      toggleAction={event => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {renderIcon(icon)}
    </EditorButton>
  );
};

export const serialize = nodes => {
  let content = initialValue;
  try {
    content = JSON.parse(nodes);
  } catch (error) {
    console.log(nodes);
  }
  return content
    .map(n => Node.string(n))
    .join(" ")
    .trim();
};

export const serializeHtml = node => {
  return `<body>${node.map(n => serializeNode(n)).join("")}</body>`;
};

const serializeNode = node => {
  if (Text.isText(node)) {
    return `${node.bold ? "<strong>" : ""}${node.italic ? "<em>" : ""}${
      node.underline ? "<u>" : ""
    }${escapeHtml(node.text)}${node.underline ? "</u>" : ""}${node.italic ? "</em>" : ""}${
      node.bold ? "</strong>" : ""
    }`;
  }

  const children = node?.children?.map(n => serializeNode(n)).join("");

  switch (node.type) {
    case "bulleted-list":
      return `<ul>${children}</ul>`;
    case "list-item":
      return `<li>${children}</li>`;
    case "quote":
      return `<blockquote><p>${children}</p></blockquote>`;
    case "paragraph":
      return `<p>${children}</p>`;
    case "link":
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    default:
      return children;
  }
};

export const initialValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

export const initialFormValue = JSON.stringify(initialValue);

export default SlateRTEField;
