import React from "react";
import { GoogleLogin } from "react-google-login";

import IllustratedErrorMessage from "../../organisms/IllustratedErrorMessage";
import PrimaryButton from "../../atoms/PrimaryButton";
import auth from "../../../services/authService";
import config from "../../../config";

import loggedOut from "../../assets/loggedOut.svg";

export default function InactivityPage() {
  return (
    <IllustratedErrorMessage
      messages={["Tür zu, es zieht!", "Bitte melde dich erneut an, um fortzufahren."]}
      illustration={loggedOut}
      illustrationAltText="Ein Mann zieht eine Tür zu"
    >
      <GoogleLogin
        clientId={config.clientId}
        redirectUri={config.frontendUrl}
        isSignedIn
        fetchBasicProfile
        hostedDomain="otheb.de"
        scope={config.scopes}
        onSuccess={auth.googleLoginSuccess}
        onFailure={auth.googleLoginError}
        render={renderProps => (
          <PrimaryButton onClick={renderProps.onClick}>Anmelden</PrimaryButton>
        )}
        buttonText="Login"
        cookiePolicy={"single_host_origin"}
      />
    </IllustratedErrorMessage>
  );
}
