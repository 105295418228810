import React, { FC } from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

type TDataColumn = {
  label?: string;
  xs?: 12 | 4 | 3;
};
const DataColumn: FC<TDataColumn> = ({ label, xs = 4, children }) => {
  return (
    <Grid item xs={xs}>
      {label && (
        <Typography variant="h4" color="textSecondary" gutterBottom>
          {label}
        </Typography>
      )}
      <Box marginBottom={2}>{children}</Box>
    </Grid>
  );
};

export default DataColumn;
