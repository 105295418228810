import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  dayRow: {
    padding: theme.spacing(0.5, 2.5),
    position: "relative",
    "&:before": {
      background: theme.palette.grey[400],
      top: 0,
      content: '""',
      height: 1,
      left: 0,
      position: "absolute",
      width: "100%",
    },
    "&:last-child": {
      "&:after": {
        background: theme.palette.grey[400],
        bottom: 0,
        content: '""',
        height: 1,
        left: 0,
        position: "absolute",
        width: "100%",
      },
    },
  },
  icon: { marginRight: theme.spacing(1) },
  leftSpacing: { marginLeft: theme.spacing(2) },
}));
