import React, { useState, FC } from "react";
import { useParams } from "react-router-dom";
import orderBy from "lodash/orderBy";

import { Department, useBreadCrumbInfoByAccountIdQuery } from "../../../generated/graphql";

import { useAccountSubscription } from "../../../hooks/useAccountSubscription";
import { useDepartmentsSubscription } from "../../../hooks/useDepartmentsSubscription";

import Loader from "../../atoms/Loading";
import PersonLayout from "../../templates/PersonLayout";
import DepartmentDialog from "../../forms/DepartmentDialog";
import ClickableItemList from "../../organisms/ClickableItemList";
import AccountStatusBadge from "../../molecules/AccountStatusBadge";

const AccountDepartments: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState<Department | undefined>();
  const toggle = () => {
    if (open) {
      setCurrent(undefined);
    }
    setOpen(!open);
  };
  const { account, loading } = useAccountSubscription(parseInt(id, 10), !id);
  const { departments, loading: loadDepartments } = useDepartmentsSubscription(parseInt(id, 10));
  const accountId = account?.id;
  const { data: breadcrumbData } = useBreadCrumbInfoByAccountIdQuery({
    skip: !accountId,
    variables: {
      id: accountId!,
    },
  });
  const mapList = [
    {
      name: "Accounts",
      path: "/account",
    },
    {
      name: account?.name || "",
      path: `/account/${account?.id}`,
      chip: (
        <AccountStatusBadge
          contractEnd={breadcrumbData?.breadCrumbInfoByAccountId?.contractEnd}
          lang={breadcrumbData?.breadCrumbInfoByAccountId?.language || ""}
        />
      ),
    },
    {
      name: "Standorte",
      path: ``,
    },
  ];
  return !account || loading || loadDepartments ? (
    <Loader />
  ) : (
    <PersonLayout
      counselor
      personIds={account.business?.persons?.map(p => p!.id!) || []}
      mapList={mapList}
      contractExpirationDate={account.contractEnd}      
      pathId={account.business?.id}
    >
      <ClickableItemList
        title="Standorte"
        btnLabel="Standort anlegen"
        onClick={toggle}
        countLabel="Anzahl Standorte"
        count={departments?.length || 0}
        items={orderBy(departments, ["main", "name"], ["desc", "asc"]).map(department => ({
          key: `department-${department.id}`,
          title: department.name,
          additionalInfo: department.main ? "Hauptstandort" : undefined,
          onClick: () => {
            setCurrent(department);
            setOpen(true);
          },
        }))}
      />

      <DepartmentDialog
        defaultChecked={!departments?.length}
        disabled={!!departments?.find(d => d.main) && !current?.main}
        toggle={toggle}
        open={open}
        department={current}
        accountId={parseInt(id, 10)}
      />
    </PersonLayout>
  );
};
export default AccountDepartments;
