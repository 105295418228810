import { useEffect } from "react";
import dayjs from "dayjs";

import {
  usePapershiftShiftsQuery,
  PapershiftShiftsSubscriptionSubscription,
  PapershiftShiftsSubscriptionDocument,
  PapershiftShiftsDocument,
  PapershiftShiftsQueryVariables,
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

export const usePapershiftShiftsSubscription = (variables: PapershiftShiftsQueryVariables) => {
  const { data, loading, error, client, subscribeToMore } = usePapershiftShiftsQuery({
    variables,
    fetchPolicy,
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore<PapershiftShiftsSubscriptionSubscription>({
      document: PapershiftShiftsSubscriptionDocument,
      variables,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) {
          return prev;
        }

        return {
          papershiftShifts: subscriptionData.data.papershiftShiftsSubscription,
        };
      },
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables.startsAt, variables.endsAt]);
  return { data, loading, client, error };
};

export const usePagedPapershiftShiftsSubscription = (page: number) => {
  const startsAt = dayjs().startOf("day");
  const endsAt = dayjs().endOf("day");
  const variables = {
    startsAt: startsAt
      .add(page - 1, "day")
      // for local testing!
      .add(1, "hour")
      .toISOString(),
    endsAt: endsAt
      .add(page - 1, "day")
      // for local testing!
      .add(1, "hour")
      .toISOString(),
  };

  const { data, loading, error, client } = usePapershiftShiftsSubscription(variables);

  // Preload next and previous pages
  if (page > 1) {
    client.query({
      query: PapershiftShiftsDocument,
      variables: {
        startsAt: startsAt
          .clone()
          .subtract(1, "day")
          .toISOString(),
        endsAt: endsAt
          .clone()
          .subtract(1, "day")
          .toISOString(),
      },
    });
  }

  if (page < 28) {
    client.query({
      query: PapershiftShiftsDocument,
      variables: {
        startsAt: startsAt
          .clone()
          .add(1, "day")
          .toISOString(),
        endsAt: endsAt
          .clone()
          .add(1, "day")
          .toISOString(),
      },
    });
  }

  return { data, loading, error };
};
