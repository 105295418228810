import React, { FC } from "react";
import { Field, FormikProps, FormikValues } from "formik";
import { TextField } from "formik-material-ui";

import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextInput from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";

import { Person } from "../../../generated/graphql";

import useStyles from "../formStyles";

interface FormProps {
  cancel: () => void;
  contacts: Person[];
}

const Form: FC<FormProps & FormikProps<FormikValues>> = ({
  cancel,
  contacts,
  handleSubmit,
  setFieldValue,
  values,
  isSubmitting,
}) => {
  const classes = useStyles();
  const setContact = (value: string | Person | null) => {
    if (value) {
      if (typeof value === "string") {
        const contactPerson = contacts.find(c => c.name === value);
        if (contactPerson) {
          setFieldValue("contactPerson", { ...contactPerson });
        } else {
          setFieldValue("contactPerson", { name: value });
        }
      } else {
        setFieldValue("contactPerson", {
          ...value,
          name: `${value.firstName} ${value.lastName}`,
        });
      }
    }
  };
  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <Field name="businessObject.name" label="Name" component={TextField} />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            tabIndex={4}
            value={values.contactPerson}
            options={contacts.filter((c: Person) => !c.temporary)}
            freeSolo
            noOptionsText="Neuen Ansprechpartner hinterlegen"
            getOptionLabel={(option: any) =>
              option.name ||
              (option.firstName &&
              option.firstName.length &&
              option.lastName &&
              option.lastName.length
                ? `${option.firstName} ${option.lastName}`
                : "")
            }
            onChange={(event: any, value: any) => {
              setContact(value);
            }}
            onBlur={(event: any) => setContact(event.target.value)}
            renderInput={params => (
              <FormControl fullWidth margin="normal">
                <TextInput label="Ansprechpartner" name="" {...params} disabled={isSubmitting} />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <Field
              name="businessObject.address.street"
              label="Straße und Hausnummer"
              component={TextField}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <Field name="businessObject.address.zip" label="Postleitzahl" component={TextField} />
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth margin="normal">
            <Field name="businessObject.address.city" label="Stadt" component={TextField} />
          </FormControl>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center" mt={4}>
        <SecondaryButton onClick={cancel}>Abbrechen</SecondaryButton>
        <Box mx={1} />
        <PrimaryButton type="submit">OK</PrimaryButton>
      </Box>
    </form>
  );
};

export default Form;
