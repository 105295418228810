import React from "react";
import { Field } from "formik";
import { Select } from "formik-material-ui";

import { InputLabel, MenuItem } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

import { useBusinessUnitsSubscription } from "../../../hooks/useBusinessUnitsSubscription";

type BusinessUnitSelectProps = {
  accountId: number;
};

const BusinessUnitSelect = ({ accountId }: BusinessUnitSelectProps) => {
  const { businessUnits, error } = useBusinessUnitsSubscription(accountId);

  const options = [{ id: "", name: "-" }, ...businessUnits];

  if (error) {
    return <>Business-Units konnten nicht geladen werden.</>;
  }

  if (businessUnits.length === 0) {
    return null;
  }

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel htmlFor="clientType">Business-Unit</InputLabel>
      <Field name="businessUnitId" label="Business-Unit" component={Select}>
        {options.map(businessUnit => (
          <MenuItem value={businessUnit.id} key={`business-unit-${businessUnit.id}`}>
            {businessUnit.name}
          </MenuItem>
        ))}
      </Field>
    </FormControl>
  );
};

export default BusinessUnitSelect;
