import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import Loader from "../../atoms/Loading";
import ServiceWizard from "../../forms/ServiceWizard";

import {
  useUpdateServiceMutation,
  ServiceWizardStatus,
  ServiceStatus,
  ServiceType,
} from "../../../generated/graphql";
import { usePersonSubscription } from "../../../hooks/usePersonSubscription";
import { useServiceSubscription } from "../../../hooks/useServiceSubscription";

export default function ServiceSetup() {
  const { id, serviceId } = useParams<{ id: string; serviceId: string }>();
  const { push, goBack } = useHistory();

  const createdServiceName: string | undefined | null = localStorage.getItem("createdServiceName");
  const reportId: string | undefined | null = localStorage.getItem("activeReport");
  const { person, loading: loadingPerson, refetch: refetchPerson } = usePersonSubscription(
    parseInt(id, 10),
  );
  const { service, loading, refetch: refetchService } = useServiceSubscription(
    parseInt(serviceId, 10),
  );

  const [updateService, { data }] = useUpdateServiceMutation();

  const serviceStep = service?.wizardStep || 0;
  const updateStep = data?.updateService?.wizardStep || serviceStep;
  const [step, setStateStep] = useState<number>(!!reportId ? 0 : updateStep);

  if (!person || !service) return <Loader />;

  const setStep = (wizardStep: number) => {
    const attributes: any[] = service?.serviceMeta?.serviceMetaAttributes || [];
    const serviceData = {
      wizardStep,
      wizardStatus: service!.wizardStatus!,
      status: service!.status!,
    };
    if (service.status === ServiceStatus.started) {
      if (wizardStep >= attributes.length) {
        serviceData.wizardStatus = ServiceWizardStatus.complete;
        serviceData.status =
          service!.serviceMeta!.type! !== ServiceType.internalConsult
            ? ServiceStatus.research
            : ServiceStatus.started;
      }
    }

    updateService({
      variables: {
        id: service!.id!,
        service: {
          ...serviceData,
        },
      },
    }).then(() => {
      refetchService();
      setStateStep(wizardStep);
    });
  };
  const update = () => {
    refetchPerson();
    refetchService();
  };
  const stepToUse =
    step === 0 && service?.wizardStep && service!.wizardStep! > step && !reportId
      ? service!.wizardStep!
      : step;

  const handleReportBack = () => {
    localStorage.removeItem("activeReport");
    localStorage.removeItem("createdServiceID");
    localStorage.removeItem("createdServiceName");
    push(`/person/${person!.id!}/report/${reportId}`);
  };
  const handleBack = () => setStep(stepToUse - 1);

  const backAction = () => {
    if (!!reportId && stepToUse === 0) {
      return handleReportBack();
    } else if (stepToUse === 0) {
      // todo go back to creation
      if (service!.wizardStatus! === ServiceWizardStatus.incomplete || createdServiceName) {
        const name = service?.serviceMeta?.name || "";
        localStorage.setItem("createdServiceID", serviceId);
        localStorage.setItem("createdServiceName", name);
        return push(`/person/${person!.id!}/service`);
      }
      return goBack();
    }
    return handleBack();
  };
  return (
    <ServiceWizard
      reportId={reportId}
      update={update}
      person={person!}
      service={service!}
      step={stepToUse}
      setStep={setStep}
      backAction={backAction}
      isReloading={loadingPerson || loading}
    />
  );
}
