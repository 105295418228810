import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    content: {
      marginTop: theme.spacing(20),
    },
    backButton: {
      left: theme.spacing(2),
      top: theme.spacing(10),
    },
  };
});

export default useStyles;
