import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    card: {
      "&>h3": {
        marginBottom: theme.spacing(3),
      },
    },
    header: {
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      display: "flex",
      paddingBottom: theme.spacing(0.5),
      marginBottom: theme.spacing(2),

      "&>h2": {
        flexGrow: 1,
      },
    },
    clickable: {
      color: theme.palette.primary.main,
      cursor: "pointer",      
      textDecoration: "underline",
      "&>h2": {
        color: theme.palette.primary.main,
        "&:hover": {
          color: theme.palette.primary.light,
        },
      },
      "&:hover": {
        color: theme.palette.primary.light,
      },
    },    
  };
});

export default useStyles;
