import React from "react";

import classnames from "classnames";
import SvgIcon from "@material-ui/core/SvgIcon";

import useStyles from "./styles";

type TEmptyIcon = {
  className?: string;
};

const EmptyIcon = ({ className }: TEmptyIcon) => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 48 68" fill="none" className={classnames(className, classes.icon)}>
      <path
        d="M2.49684 41.4872C2.03212 41.4646 1.02566 43.4416 1.02566 43.4416C1.02566 43.4416 5.0714 45.9159 5.80699 43.4416C6.54258 40.9674 4.33582 42.2809 3.60023 42.2809C2.86464 42.2809 2.96156 41.5098 2.49684 41.4872Z"
        stroke="#025879"
        fill="none"
      />
      <path
        d="M14.6341 34.1059C14.6341 34.1059 10.9561 40.749 5.80702 42.5943"
        stroke="#025879"
        fill="none"
      />
      <path
        d="M10.4832 15.8436C10.851 13.2602 12.9002 6.05734 15.0019 6.05734C17.5125 6.05734 17.3663 3.33322 19.6781 3.42743C21.99 3.52165 24.226 3.42743 23.0933 1.62864C21.9607 -0.17016 26.7188 2.22872 28.2425 4.58111C28.2425 4.58111 29.7896 2.92512 30.7908 3.42743C31.792 3.92975 31.2654 5.72871 31.9204 6.05734C32.5754 6.38598 34.1009 5.0882 34.1009 5.0882C34.1009 5.0882 35.2306 12.3313 36.4653 12.3313C39.6844 12.3313 36.7806 17.5044 36.4653 20.9841"
        stroke="#025879"
        fill="none"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8964 28.9391C24.5645 29.7867 31.4836 28.7545 33.848 25.1958C33.848 25.1958 35.4505 26.5138 36.4488 23.2977C37.4471 20.0816 34.5048 20.8198 34.5048 20.8198C33.3618 15.3721 28.6102 10.4861 25.6679 11.9624C24.8331 12.3812 23.6524 10.6508 23.0779 10.4861C20.5034 9.748 20.7419 11.9624 19.7832 11.9624C18.8245 11.9624 20.057 10.0784 17.9442 10.4861C15.8314 10.8938 12.0955 16.8919 12.0955 16.8919C12.0955 16.8919 9.49472 14.6552 9.8527 18.3458C10.2107 22.0364 12.0955 21.2983 12.0955 21.2983C13.1629 25.727 17.2282 28.0915 20.8964 28.9391Z"
        stroke="#025879"
        fill="none"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.6102 18.6053C28.204 18.6053 27.8747 18.2748 27.8747 17.8672C27.8747 17.4595 28.204 17.1291 28.6102 17.1291C29.0165 17.1291 29.3458 17.4595 29.3458 17.8672C29.3458 18.2748 29.0165 18.6053 28.6102 18.6053Z"
        fill="#025879"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0476 17.129C18.6413 17.129 18.312 16.7986 18.312 16.3909C18.312 15.9833 18.6413 15.6528 19.0476 15.6528C19.4539 15.6528 19.7832 15.9833 19.7832 16.3909C19.7832 16.7986 19.4539 17.129 19.0476 17.129Z"
        fill="#025879"
      />
      <path
        d="M28.6103 23.6669C28.1374 26.0394 23.8552 27.9374 20.3086 26.5139C16.762 25.0904 16.0527 22.7178 15.8163 20.8198"
        stroke="#025879"
        fill="none"
        strokeLinecap="round"
      />
      <path
        d="M31.1585 33.3676C35.2306 36.9264 40.6161 36.6891 41.5619 36.6891C42.5076 36.6891 42.7441 33.3676 43.6898 33.3676C44.6356 33.3676 43.6898 35.147 44.3992 35.147C45.1085 35.147 47 35.7401 47 36.9264C47 38.1127 42.7704 39.8525 41.5619 36.6891"
        stroke="#025879"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.4512 64.4991C28.4628 65.9422 24.627 67.4945 24.1113 66.2115C23.5957 64.9285 24.7147 63.9053 25.9563 63.6222C27.198 63.3391 28.4396 63.056 28.4512 64.4991Z"
        stroke="#025879"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5521 64.6851C14.0205 65.5387 15.9346 67.5302 17.3701 67.4412C18.8056 67.3521 19.0536 66.3207 18.6105 65.4494C18.1673 64.5781 15.0836 63.8315 14.5521 64.6851Z"
        stroke="#025879"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.8746 47.1021C27.8746 47.1021 25.7467 62.049 25.0374 62.7607C24.328 63.4725 23.1458 48.7629 22.2001 48.7629C21.2543 48.7629 18.4171 64.1843 17.9442 63.7097C17.4713 63.2352 16.2891 49.4746 17.4713 47.1021C18.6535 44.7296 27.8746 47.1021 27.8746 47.1021Z"
        fill="#555555"
        stroke="#025879"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.1301 30.0511C28.2859 30.0315 28.4441 30.049 28.5919 30.1019L32.317 31.4369C32.792 31.6071 33.0657 32.1044 32.9555 32.5967L32.2958 35.5443C32.1632 36.1364 31.5383 36.4773 30.9611 36.2902C29.6577 35.8677 27.6418 35.3138 27.6315 35.9263C27.5415 41.278 28.1732 46.1816 29.0359 47.3935C30.2181 49.0543 15.3224 48.1052 15.3224 46.6817C15.3224 45.6744 16.7897 40.6787 17.3333 35.9263C17.3707 35.5989 15.6576 36.8755 14.6815 37.6271C14.2983 37.9221 13.7601 37.9085 13.4196 37.5651C12.5797 36.718 11.1888 35.1235 11.4486 34.081C11.7639 32.8154 15.5932 30.4649 16.6797 29.8198C16.8664 29.709 17.0819 29.6669 17.2973 29.6939L19.9078 30.0214C19.9078 30.0214 21.4698 31.7348 22.8885 31.7348C24.3071 31.7348 25.4248 30.3904 25.4248 30.3904L28.1301 30.0511Z"
        fill="#A6C4CE"
        stroke="#025879"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default EmptyIcon;
