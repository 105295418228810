import React, { useState, FC } from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import DataRow from "../../molecules/DataRow";
import DataColumn from "../../molecules/DataColumn";
import BusinessContactData from "../../molecules/BusinessContactData";
import BusinessEditDialog from "../../forms/BusinessEditDialog";

import { BusinessObject } from "../../../generated/graphql";

const BusinessDetails: FC<{ business: BusinessObject }> = ({ business }) => {
  console.log(business);
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  return (
    <Grid container>
      <DataRow title="Allgemeine Informationen" editTrigger={toggle}>
        <DataColumn label="Name" xs={4}>
          <Typography variant="body2" key={`name${business.id}`}>
            {business?.name || "-"}
          </Typography>
        </DataColumn>
        <DataColumn label="Kategorie" xs={4}>
          <Typography variant="body2" key={`category${business.id}`}>
            {business?.category?.name || "-"}
          </Typography>
        </DataColumn>
      </DataRow>
      <BusinessContactData business={business} />
      <BusinessEditDialog business={business} open={open} toggle={toggle} />
    </Grid>
  );
};

export default BusinessDetails;
