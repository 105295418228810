import { useEffect } from "react";
import {
  useServiceResearchObjectQuery,
  ServiceResearchObjectSubscriptionSubscription,
  ServiceResearchObjectSubscriptionDocument,
  ServiceResearchObject,
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

export const useServiceResearchObjectSubscription = (id: number, serviceId: number) => {
  const { loading, data, subscribeToMore } = useServiceResearchObjectQuery({
    variables: { id, serviceId },
    fetchPolicy,
  });

  useEffect(() => {
    const subscription = subscribeToMore<ServiceResearchObjectSubscriptionSubscription>({
      document: ServiceResearchObjectSubscriptionDocument,
      // @ts-ignore
      variables: {
        serviceId: serviceId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !prev) return prev;

        const updatedEntry = ((subscriptionData.data?.serviceResearchObjectSubscription ||
          []) as ServiceResearchObject[]).find(
          (researchEl: ServiceResearchObject) =>
            researchEl.id! === prev?.serviceResearchObject?.id!,
        );

        if (updatedEntry) {
          return Object.assign({}, prev, {
            serviceResearchObject: { ...updatedEntry },
          });
        }
        return prev;
      },
    });

    return subscription;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, serviceId]);

  return { loading, researchObject: data?.serviceResearchObject };
};
