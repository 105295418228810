import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ResetIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon
      viewBox="0 0 115 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.8511 15.4467C56.9049 15.4467 53.1855 12.3092 50.5215 12.3092C49.5395 12.3092 48.6275 16.8043 48.0664 17.0154C47.5052 17.2265 44.6249 13.6285 44.2162 14.8776C43.6387 16.6429 43 18.7636 43 22.9171C43 31.3641 48.6658 35 55.9861 35C63.3064 35 69 28.648 69 21.7185C69 17.204 66.6982 12.3092 62.9512 11.0231C62.2488 10.782 64.094 12.4794 63.7794 13.8779C63.6381 14.5063 58.9841 10.8649 58.378 11.2633C57.3546 11.9363 60.7282 15.4467 59.8511 15.4467Z"
        stroke="#025879"
        fill="none"
      />
      <path
        d="M47.5245 29.549C51 31.9998 57.5 31.9998 60.5786 26.5129"
        stroke="#025879"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M47.5 23C48.3284 23 49 22.3284 49 21.5C49 20.6716 48.3284 20 47.5 20C46.6716 20 46 20.6716 46 21.5C46 22.3284 46.6716 23 47.5 23Z"
        fill="#025879"
      />
      <path
        d="M56.5 21C57.3284 21 58 20.3284 58 19.5C58 18.6716 57.3284 18 56.5 18C55.6716 18 55 18.6716 55 19.5C55 20.3284 55.6716 21 56.5 21Z"
        fill="#025879"
      />
      <path
        d="M44.8734 15C44.1212 14.2353 41.7696 14.9037 41.1121 14.2353C40.3598 13.4706 43.5665 12.2538 45.5941 10.7664C43.2649 10.0467 42.4587 9.32709 43.1753 8.60744C44.2503 7.52797 47.7623 8.03121 49.3748 6.68187C50.9872 5.33254 52.4545 3.74983 55.6794 2.94022C58.9044 2.13062 63.3314 1.59089 63.3314 2.40049C63.3314 3.21009 61.1814 4.55544 61.1814 5.36704C61.1814 6.17863 69.5126 8.06771 68.9751 9.14718C68.6167 9.86682 66.6894 11.1765 62.928 11.1765"
        stroke="#00577A"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.4943 91.6384C52.1585 92.376 45 91.9986 45 90.3827C45 88.7667 52.7135 87.2128 54.2158 88.4435C55.718 89.6742 54.8301 90.9008 53.4943 91.6384Z"
        stroke="#025879"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.2881 91.3631C64.2959 92.505 70 92.0759 70 90.3607C70 88.6455 65.0089 87.5016 62.8698 88.2164C60.7308 88.9311 60.2803 90.2212 62.2881 91.3631Z"
        stroke="#025879"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.9585 85.5941C52.7606 86.1217 54.1072 85.5941 55.6606 85.5941C56.5247 85.5941 55.6606 76.5404 55.6606 73.7008C55.6606 70.8611 57.3077 64.7542 58.5694 64.7542C59.8311 64.7542 61 70.6604 61 73.5C61 76.3396 60.2011 85.1552 60.4204 85.5941C60.832 86.4179 63.0648 85.8579 63.8581 85.0665C64.6514 84.2752 67 77.3541 67 72C67 68.4306 66.145 63.764 64.4351 58H51.9585C51.0271 61.7534 49 67.1163 49 72C49 75.1206 51.4844 85.2823 51.9585 85.5941Z"
        fill="#F2680A"
        stroke="#025879"
      />
      <path
        d="M69.0273 44.3396C70 48.0002 74 52.0002 75.5 51.5002C77 51.0002 78 36.5002 78 35.0002C78 33.5002 84.7603 31.3741 86.5 34.5002C88.2397 37.6263 79.5543 37.4896 78 35.5002"
        stroke="#025879"
        fill="none"
      />
      <path
        d="M48.5 43.9998C46 48.4998 44.2799 54.0485 42 51.9998C40.9186 51.028 39.7519 45.1946 38.5 34.4998C33.8333 31.4998 30.8333 30.9998 29.5 32.9998C27.5 35.9998 35.5 36.9998 38.5 34.9998"
        stroke="#025879"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.2663 61.9999C54.2594 61.1701 57.2446 60.9319 59.222 61.2855C61.1994 61.6391 63.3359 61.6391 65.6315 61.2855L63.9634 44.5538L67 50.4999C69.2186 50.7521 73 45.9022 73 44.4999C73 43.0976 68.3485 35.7954 66.5 34.9999C64.6515 34.2044 61.1187 38.0325 58.247 38.0325C55.3752 38.0325 51.5 35.4999 49.5 35.4999C47.5 35.4999 44.5393 42.3785 44 44.4999C43.4607 46.6213 48.1911 49.4999 49 49.4999C49.5393 49.4999 50.3926 47.7628 51.5598 44.2886L50.2663 61.9999Z"
        fill="#D2E1E6"
        stroke="#025879"
        strokeLinejoin="round"
      />
      <path
        d="M58 41.7901C56.3989 42.2584 55 51.1499 55 52.3199C55 53.4899 56.8 56.9998 58 56.9998C59.2 56.9998 61 52.3199 61 50.5649C61 48.81 59.6011 41.3218 58 41.7901Z"
        fill="#025879"
      />
      <path
        d="M58 39C57.2008 39 56.5 39 56.5 39.9231C56.5 40.8462 57.2008 41.7692 58 41.7692C58.7992 41.7692 59.5 40.3846 59.5 39.9231C59.5 39.4615 58.7992 39 58 39Z"
        fill="#025879"
      />
    </SvgIcon>
  );
};
export default ResetIcon;
