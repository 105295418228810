import React, { useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import WizardNavigation from "../../molecules/WizardNavigation";
import ActivityText from "../../molecules/ActivityText";
import { Topic, Person, PersonActivity } from "../../../generated/graphql";
import useStyles from "./styles";
import illu from "../../assets/reportIllustration.svg";

export const ActivityHeader = ({
  topic,
  person,
  activity,
}: {
  topic?: Topic;
  person: Person;
  activity: Pick<PersonActivity, "memo" | "activityType" | "start" | "end" | "id">;
}) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Box mb={2} width="fit-content">
        <img src={illu} alt="reportillu" height="100" />
      </Box>
      {activity && (
        <ActivityText activity={activity} personName={activity?.memo?.contact || person.name!} />
      )}
      {topic && (
        <Typography variant="h2" className={classes.dataText}>
          zum Thema{" "}
          <Link
            component={RouterLink}
            to={`/person/${person.id}/topics/${topic.id}`}
            underline="always"
          >
            {topic.name}
          </Link>
        </Typography>
      )}
    </Box>
  );
};

export const Pagination = ({ list, name }: { list: any[]; name: string }) => {
  const { push } = useHistory();
  const { id, topicId, personId } = useParams<{ id: string; topicId: string; personId: string }>();
  const { state } = useLocation();
  // @ts-ignore
  const goBackPath = useRef(state?.goBackPath! || undefined);
  const currentPosition = list.findIndex((r: any) => r.id === parseInt(id, 10));

  const prev: any | undefined = currentPosition > 0 ? list[currentPosition - 1] : undefined;
  const next: any | undefined =
    currentPosition + 1 !== list.length ? list[currentPosition + 1] : undefined;

  const handleClick = (item?: any) => {
    if (!item) return;
    push({
      pathname: `/person/${personId}${topicId ? `/topics/${topicId}` : ""}/activities/${item.id}`,
      state,
    });
  };
  return (
    <WizardNavigation>
      <SecondaryButton onClick={() => handleClick(prev)} disabled={!prev}>
        Vorheriges
      </SecondaryButton>
      {!!goBackPath.current && (
        <PrimaryButton onClick={() => push(goBackPath.current)}>{name} schließen</PrimaryButton>
      )}
      <SecondaryButton onClick={() => handleClick(next)} disabled={!next}>
        Nächstes
      </SecondaryButton>
    </WizardNavigation>
  );
};
