import React, { FC, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Phone as TypePhone, Person } from "../../../generated/graphql";

import useStyles from "./styles";
import TertiaryButton from "../../atoms/TertiaryButton";
import { Phone } from "@material-ui/icons";
import OutgoingCall from "../../organisms/OutgoingCall";
import translatePhoneType from "../../../utils/translatePhoneType";

type TCallBox = {
  icon?: React.ReactNode;
  actionIcon?: React.ReactNode;
  onClick?: () => void;
  title?: React.ReactNode | string | null;
  topCard?: boolean;
  log?: React.ReactNode | string | null;
  contact: Person;
};
const CallBox: FC<TCallBox> = props => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState<string | undefined>();
  const [callPhone, setCallPhone] = useState<TypePhone | undefined>();
  //const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [phoneAnchorEl, setPhoneAnchorEl] = useState<null | HTMLElement>(null);

  const closeDialog = () => {
    setOpenDialog(undefined);
    setCallPhone(undefined);
  };

  const openCallDialog = (phone: TypePhone) => {
    setCallPhone(phone);
    setOpenDialog("callOut");
    setPhoneAnchorEl(null);
  };

  const contact = props.contact;
  const phones = props.contact.phones || [];  
  return (
    <Grid      
      container
      spacing={2}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {phones.map(phone => {
        if (!phone) {
          return null;
        }
        return (
          <Grid xs={12}>
            <Box display="flex" alignItems="center" justifyContent="space-between" width={1} mb={1}>
              <Box mr={3}>
                <Typography variant="h4" color="textSecondary" gutterBottom>
                  Telefonnumer
                  {phone?.type && translatePhoneType(phone?.type)}
                </Typography>
                <Typography variant="body2">{phone?.readableNumber}</Typography>
              </Box>
              <Box>
                <TertiaryButton
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    openCallDialog(phone);
                  }}
                >
                  <Phone className={classes.phoneMenuItemIcon} />
                  Anrufen
                </TertiaryButton>
              </Box>
            </Box>
          </Grid>
        );
      })}

      <OutgoingCall
        person={contact}
        phone={callPhone}
        open={openDialog === "callOut"}
        close={closeDialog}
      />
    </Grid>
  );
};

export default CallBox;
