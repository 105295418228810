import React from "react";

import { path } from "ramda";
import { Transition } from "react-transition-group";
import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import dayjs from "dayjs";

import { Appointment, Person } from "../../../generated/graphql";
import { Bubble } from "./BubbleTypes";
import { isToday, isYesterday, isTomorrow } from "../../../utils/dateHelper";
import { UserAvatarFromPerson } from "../UserAvatar";

import { usePersonSubscription } from "../../../hooks/usePersonSubscription";
import { useMeQuery } from "../../../generated/graphql";
import { restrictedAccess } from "../../molecules/PersonViewRestrictions";

import useStyles from "./styles";
import "./styles.css";

const ActivityLogType = ["conversationReport", "person", "topic", "other"];

export const isActivityLogType = (activityType: string): boolean =>
  ActivityLogType.includes(activityType);

export const isServiceLogType = (activityType: string): boolean => {
  return activityType.indexOf("service") !== -1;
};
type TAppointmentItem = {
  delay: number;
  appointment: Appointment;
  personId?: number | undefined;
  withClient?: boolean;
};

const AppointmentItem = ({ appointment, delay, personId, withClient }: TAppointmentItem) => {
  const classes = useStyles();

  const timeTag = (date: string) => {
    const d = dayjs(date);
    if (isToday(d)) return d.format("H:mm");
    if (isTomorrow(d)) return `Morgen ${d.format("H:mm")}`;
    if (isYesterday(d)) return "Gestern";

    return d.format("D. MMM");
  };
  const person: Person | undefined = path(["users", 0, "person"], appointment);

  const { person: clientPerson } = usePersonSubscription(personId);
  const { data } = useMeQuery({ fetchPolicy: "cache-first" });

  if (restrictedAccess(data?.me?.user?.id, clientPerson?.client?.account?.id)) {
    return null;
  }

  return (
    <Transition timeout={150 * (delay + 1)} in appear>
      {state => (
        <ListItem
          alignItems="center"
          className={`activityItem activityItem-${state} ${classes.activityItem}`}
        >
          <Box className={classes.timeTag}>
            <div className={classes.grow} />
            {timeTag(appointment.from)}
          </Box>
          <ListItemAvatar>
            <div className={classes.avatar}>
              {person && <UserAvatarFromPerson person={person} />}
            </div>
          </ListItemAvatar>
          <Bubble
            appointment={appointment}
            withClient={withClient}
            personId={personId || path(["personId"], appointment)}
          />
        </ListItem>
      )}
    </Transition>
  );
};

export default AppointmentItem;
