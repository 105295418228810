import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  container: {
    position: "absolute" as "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
});

export default useStyles;
