import React, { FC } from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { Select } from "formik-material-ui";
import { Field } from "formik";

import { PhoneType } from "../../../generated/graphql";

const PhoneTypeSelect: FC<{ name: string }> = ({ name }) => {
  const options = [];
  for (const [relationType, relationValue] of PhoneTypeMap) {
    options.push(
      <MenuItem key={relationType} value={relationType}>
        {relationValue}
      </MenuItem>,
    );
  }

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel style={{ position: "absolute" }} htmlFor={name}>
        Bezeichung
      </InputLabel>
      <Field name={name} label="Bezeichung" component={Select}>
        {options}
      </Field>
    </FormControl>
  );
};

export const PhoneTypeMap = new Map<PhoneType, string>([
  [PhoneType.private, "Privat"],
  [PhoneType.mobile, "Mobil"],
  [PhoneType.work, "Arbeit"],
  [PhoneType.mobilework, "Arbeit mobil"],
  [PhoneType.fax, "Fax"],
]);

export default PhoneTypeSelect;
