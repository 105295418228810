import { useEffect } from "react";
import {
  useBusinessObjectQuery,
  BusinessObjectSubscriptionDocument,
  BusinessObjectSubscriptionSubscription,
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

export const useBusinessSubscription = (id: number) => {
  const { data, loading, subscribeToMore, error } = useBusinessObjectQuery({
    variables: { id },
    fetchPolicy,
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore<BusinessObjectSubscriptionSubscription>({
      document: BusinessObjectSubscriptionDocument,
      variables: { id },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !prev) return prev;
        return {
          ...prev,
          ...{ businessObject: subscriptionData.data.businessObjectSubscription },
        };
      },
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { businessObject: data?.businessObject, loading, error };
};

export default useBusinessSubscription;
