import React, { useEffect } from "react";

import Box from "@material-ui/core/Box";

import Checkbox from "@material-ui/core/Checkbox";
import {
  useMeQuery,
  usePapershiftWorkingAreasQuery,
  WorkingAreas,
} from "../../../../../generated/graphql";
import { fetchPolicy } from "../../../../../utils/constants";
import { getWorkingAreaName } from "../../../../../utils/translateWorkingAreaEnums";

import { FormControlLabel } from "@material-ui/core";
import { usePaperShiftContext } from "../../provider";
import { LegendWrapper } from "../styles";

const Legend = () => {
  const { data: me } = useMeQuery({ fetchPolicy: "cache-first" });
  const { data } = usePapershiftWorkingAreasQuery({ fetchPolicy });
  const { setActiveLegends, activeLegends } = usePaperShiftContext();

  const workingAreas = data?.papershiftWorkingAreas ? [...data?.papershiftWorkingAreas] : [];

  workingAreas.sort((a, b) => a?.name?.localeCompare(b?.name ?? "") ?? 1);
  const legendEntries = workingAreas
    .filter(
      wA =>
        wA?.name &&
        ![WorkingAreas.clinicalAdministration, WorkingAreas.lMRechercheassistenten].includes(
          wA?.name,
        ),
    )
    .map(workingArea => {
      if (!workingArea) return null;
      return (
        <Box
          key={`LegendEntry:${workingArea.id}`}
          marginX={2}
          borderBottom={`3px solid ${workingArea.color ?? "#cccccc"}`}
        >
          <FormControlLabel
            onChange={() => {
              setActiveLegends(workingArea.id);
            }}
            // checked={activeLegends.includes(workingArea.id) ? true : false}
            control={<Checkbox color="secondary" />}
            label={getWorkingAreaName(workingArea.name)}
          />
        </Box>
      );
    });

  return <LegendWrapper>{legendEntries}</LegendWrapper>;
};

interface TLegendColor {
  color: string;
}

const LegendColor = ({ color }: TLegendColor) => {
  return (
    <Box marginRight={1}>
      <svg width="6" height="6" viewBox="0 0 100 6">
        <rect x="0" y="0" width="100%" height="6" fill={color} />
      </svg>
    </Box>
  );
};

export default Legend;
