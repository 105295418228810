import React, { FC } from "react";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import Loader from "../../atoms/Loading";
import SubHeader from "../../organisms/SubHeader";
import BreadCrumb from "../../molecules/Breadcrumb";
import List from "../../organisms/InboxLists";
import useStyles from "../inboxStyles";
import { useCurrentTasksSubscription } from "../../../hooks/useCurrentTasksSubscription";

const Inbox: FC = () => {
  const classes = useStyles();

  const { tasks, loading } = useCurrentTasksSubscription(
    { pool: true },
    t => !!t && (!t.author || !t.author.id),
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <SubHeader>
        <BreadCrumb
          mapList={[
            { name: "Inbox", path: "/" },
            { name: "Offene Aufgaben", path: "" },
          ]}
        />
      </SubHeader>
      <Box paddingTop={14}>
        <Grid container className={classes.content}>
          <Grid container>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <Box mb={3}>
                <Typography variant="h1" align="center">
                  {tasks.length > 0
                    ? `Danke, dass du eine Aufgabe übernimmst.`
                    : "Es gibt keine nicht zugewiesenen Aufgaben. Gönn dir einen Tee."}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
          <List.TaskList tasks={tasks} teamMode={true} hideHeadline />
        </Grid>
      </Box>
    </>
  );
};

export default Inbox;
