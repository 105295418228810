import { RelationshipType } from "../generated/graphql";

export default function translateRelationshipType(relationship: RelationshipType) {
  switch (relationship) {
    case RelationshipType.unknown:
      return "Unbekannt";
    case RelationshipType.wife:
      return "Ehefrau";
    case RelationshipType.husband:
      return "Ehemann";
    case RelationshipType.partner:
      return "Partner";
    case RelationshipType.expartner:
      return "Ex-Partner";
    case RelationshipType.friend:
      return "Freund";
    case RelationshipType.mother:
      return "Mutter";
    case RelationshipType.father:
      return "Vater";
    case RelationshipType.daughter:
      return "Tochter";
    case RelationshipType.son:
      return "Sohn";
    case RelationshipType.grandmother:
      return "Großmutter";
    case RelationshipType.grandfather:
      return "Großvater";
    case RelationshipType.aunt:
      return "Tante";
    case RelationshipType.uncle:
      return "Onkel";
    case RelationshipType.other:
      return "Sonstiges";
    default:
      return "Unbekannt";
  }
}
