import React from "react";

import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";

type TAppointmentButton = {
  onClick: () => void;
};

const AppointmentButton = ({ onClick }: TAppointmentButton) => {
  return (
    <IconButton onClick={onClick}>
      <Box width="24px" height="24px">
        <svg
          width="18"
          height="20"
          viewBox="0 0 18 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 0V2H2C0.89 2 0 2.9 0 4V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H16C16.5304 20 17.0391 19.7893 17.4142 19.4142C17.7893 19.0391 18 18.5304 18 18V4C18 3.46957 17.7893 2.96086 17.4142 2.58579C17.0391 2.21071 16.5304 2 16 2H15V0H13V2H5V0H3ZM2 7H16V18H2V7ZM9.19 8C8.32 8 7.62 8.2 7.08 8.59C6.56 9 6.3 9.57 6.31 10.36L6.32 10.39H8.25C8.26 10.09 8.35 9.86 8.53 9.7C8.71 9.55 8.93 9.47 9.19 9.47C9.5 9.47 9.76 9.57 9.94 9.75C10.12 9.94 10.2 10.2 10.2 10.5C10.2 10.82 10.13 11.09 9.97 11.32C9.83 11.55 9.62 11.75 9.36 11.91C8.85 12.25 8.5 12.55 8.31 12.82C8.11 13.08 8 13.5 8 14H10C10 13.69 10.04 13.44 10.13 13.26C10.22 13.08 10.39 12.9 10.64 12.74C11.09 12.5 11.46 12.21 11.75 11.81C12.04 11.41 12.19 11 12.19 10.5C12.19 9.74 11.92 9.13 11.38 8.68C10.85 8.23 10.12 8 9.19 8ZM8 15V17H10V15H8Z"
            fill="#025879"
          />
        </svg>
      </Box>
    </IconButton>
  );
};

export default AppointmentButton;
