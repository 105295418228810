import React from "react";
import { GoogleLogin } from "react-google-login";

import PrimaryButton from "../../atoms/PrimaryButton";
import auth from "../../../services/authService";
import config from "../../../config";
import IllustratedErrorMessage from "../../organisms/IllustratedErrorMessage";

import requireLogin from "../../assets/requireLogin.svg";

const FallbackPage = () => {
  return (
    <IllustratedErrorMessage
      messages={["Willkommen", "Bitte melde dich an, um zu starten."]}
      illustration={requireLogin}
      illustrationAltText="Elli geht entspannt"
    >
      <GoogleLogin
        clientId={config.clientId}
        redirectUri={config.frontendUrl}
        isSignedIn
        fetchBasicProfile
        hostedDomain="otheb.de"
        scope={config.scopes}
        onSuccess={auth.googleLoginSuccess}
        onFailure={auth.googleLoginError}
        render={renderProps => (
          <PrimaryButton onClick={renderProps.onClick}>Anmelden</PrimaryButton>
        )}
        buttonText="Login"
        cookiePolicy={"single_host_origin"}
      />
    </IllustratedErrorMessage>
  );
};

export default FallbackPage;
