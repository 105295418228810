import React, { FC } from "react";

import Chip, { ChipProps } from "@material-ui/core/Chip";

import useStyles from "./styles";

const SmallOutlinedChip: FC<ChipProps> = ({ className, ...props }) => {
  const classes = useStyles();

  return (
    <Chip
      {...props}
      className={`${className || ""} ${classes.smallOutlinedChip} outlined`}
      size="small"
      variant="outlined"
    />
  );
};

export default SmallOutlinedChip;
