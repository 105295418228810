import React, { FC, lazy, Suspense, useReducer, useState } from "react";

import { useHistory } from "react-router-dom";

import { Box } from "@material-ui/core";
import { User } from "../../../generated/graphql";
import { useAuthorization } from "../../../providers/AuthorizationProvider";
import AbsenceButton from "../../atoms/AbsenceButton";
import AppointmentButton from "../../atoms/AppointmentButton";
import Loader from "../../atoms/Loading";
import AbsenceDialog from "../../organisms/AbsenceDialog";
import { PaperShiftContext } from "./provider";
import { initialState, reducer } from "./reducer";
import ShiftHeader from "./ShiftHeader";
import EmergencyContact from "./ShiftHeader/components/EmergencyContact";
import Legend from "./ShiftHeader/components/Legend";
import Nightshift from "./ShiftHeader/components/Nightshift";
import useStyles from "./styles";
import PADContact from "./ShiftHeader/components/PADContact";
const ShiftCalendar = lazy(() => import("./ShiftCalendar"));

const Shift: FC = () => {
  const { push } = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [open, setOpen] = useState(false);
  const { isAuthorized } = useAuthorization();
  const classes = useStyles();

  const setUsers = (payload: Map<number, User>) => {
    dispatch({ type: "SET_USERS", payload });
  };

  const setActiveLegends = (payload: number) => {
    dispatch({ type: "SET_ACTIVE_LEGENDS", payload });
  };

  const setActiveLegendsInitial = (payload: number) => {
    dispatch({ type: "SET_ACTIVE_LEGENDS_INITIAL", payload });
  };

  return (
    <>
      <Box display={"flex"} width={1} borderBottom={"1px solid #bdbdbd"} padding="8px 0">
        <Box className={classes.leading} display={"flex"}>
          <EmergencyContact />
          <PADContact />
          <Nightshift />
        </Box>
        <Box className="content" display="flex" flex={1}>
          <ShiftHeader />
        </Box>
        <Box className={classes.actions}>
          <>
            {isAuthorized("addAbsence") && (
              <>
                <AbsenceButton onClick={() => setOpen(true)} />
                <AbsenceDialog open={open} onClose={() => setOpen(false)} />
              </>
            )}
            <AppointmentButton onClick={() => push(`/appointment`)} />
          </>
        </Box>
      </Box>
      <PaperShiftContext.Provider
        value={{
          ...state,
          setUsers: setUsers,
          setActiveLegends: setActiveLegends,
          setActiveLegendsInitial: setActiveLegendsInitial,
        }}
      >
        <Suspense fallback={<Loader />}>
          <Legend />
          <ShiftCalendar />
        </Suspense>
      </PaperShiftContext.Provider>
    </>
  );
};
export default Shift;
