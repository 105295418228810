import { useEffect, useCallback } from "react";
import {
  Appointment,
  useMyAppointmentsQuery,
  MyAppointmentsSubscriptionDocument,
  MyAppointmentsSubscriptionSubscription,
  MyAppointmentsQueryVariables,
} from "../generated/graphql";

import { fetchPolicy } from "../utils/constants";

export const useMyAppointmentsSubscription = (variables?: MyAppointmentsQueryVariables) => {
  const { data, subscribeToMore, loading, error } = useMyAppointmentsQuery({
    variables: variables || {},
    fetchPolicy,
  });

  const appointmentSubscription = useCallback(
    () =>
      subscribeToMore<MyAppointmentsSubscriptionSubscription>({
        document: MyAppointmentsSubscriptionDocument,
        variables: variables || {},
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data || !prev) return prev;
          return Object.assign({}, prev, {
            myAppointments: subscriptionData?.data?.myAppointmentsSubscription,
          });
        },
      }),
    [subscribeToMore, variables],
  );

  useEffect(() => {
    const unsubscribe = appointmentSubscription();
    return unsubscribe;
  }, [appointmentSubscription]);

  return { appointments: (data?.myAppointments || []) as Appointment[], loading, error };
};
