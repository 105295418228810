import React, { useState } from "react";

import { useMutation } from "@apollo/client";
import { Formik, FormikActions } from "formik";
import * as Yup from "yup";
import { pathOr } from "ramda";

import {
  Service,
  Person,
  CreateServiceAddressDocument,
  DeleteServiceAddressDocument,
  UpdateServiceAddressDocument,
  Address,
  ServiceAddress,
  Maybe,
} from "../../../../generated/graphql";
import AddressRadiusForm from "../../AddressRadiusForm";
import useStyles from "../styles";
import AddressList from "./AddressList";

const AddressValidation = Yup.object().shape({
  city: Yup.string().required("Bitte ausfüllen"),
  street: Yup.string(),
  zip: Yup.string()
    .min(5, "Bitte geben Sie eine 5-stellige Postleitzahl ein")
    .max(5, "Bitte geben Sie eine 5-stellige Postleitzahl ein"),
});

type TAddressSelector = {
  backAction: () => void;
  service: Service;
  person: Person;
  serviceMetaAttribute: any;
  setStep: (step: number) => void;
  step: number;
  update: () => void;
  isReloading: boolean;
};

interface ICreateAddressFormProps {
  city: string;
  street: string;
  zip: string;
  radius: number;
}

export const AddressSelector = ({
  backAction,
  service,
  person: { addresses },
  serviceMetaAttribute,
  setStep,
  step,
  update,
  isReloading,
}: TAddressSelector) => {
  const classes = useStyles();

  const [showForm, setShowForm] = useState<boolean>(addresses ? addresses.length === 0 : true);

  const [createServiceAddress] = useMutation(CreateServiceAddressDocument);
  const [deleteServiceAddress] = useMutation(DeleteServiceAddressDocument);
  const [updateServiceAddress] = useMutation(UpdateServiceAddressDocument);

  const createWithAddress = (address: Address, radius: number) => {
    const { id, city, street, zip } = address;

    createServiceAddress({
      variables: {
        serviceAddress: {
          serviceMetaAttributeId: serviceMetaAttribute.id,
          serviceId: service.id,
          address: {
            id,
            city,
            street,
            zip,
          },
          radius,
        },
      },
    }).then(update);
  };

  const context = JSON.parse(serviceMetaAttribute.contextData);
  const next = () => {
    setStep(step + 1);
  };

  const connectedAddresses = !!service.serviceAddresses
    ? service.serviceAddresses.filter(
        (item: any) => item.serviceMetaAttributeId === serviceMetaAttribute.id,
      )
    : [];

  const initialValues: ICreateAddressFormProps = {
    city: "",
    street: "",
    zip: "",
    radius: context && context.default ? context.default : 5,
  };

  const onSubmit = async (
    values: ICreateAddressFormProps,
    { setSubmitting }: FormikActions<ICreateAddressFormProps>,
  ) => {
    if (!serviceMetaAttribute.multiple) {
      const currentServiceAddress = pathOr(null, ["serviceAddresses", 0], service) as Maybe<
        ServiceAddress
      >;

      if (!!currentServiceAddress) {
        deleteServiceAddress({
          variables: { id: currentServiceAddress.id },
        });
      }
    }

    await createServiceAddress({
      variables: {
        serviceAddress: {
          serviceMetaAttributeId: serviceMetaAttribute.id,
          serviceId: service.id,
          radius: values.radius,
          address: {
            street: values.street,
            city: values.city,
            zip: values.zip,
          },
        },
      },
    });

    setSubmitting(false);
    try {
      update();
    } catch (error) {
      console.log(error);
    }
    setShowForm(false);
  };

  return !showForm && addresses && addresses.length > 0 ? (
    <AddressList
      addresses={addresses}
      updateAction={updateServiceAddress}
      openForm={setShowForm}
      connected={connectedAddresses}
      deleteServiceAddress={deleteServiceAddress}
      createWithAddress={createWithAddress}
      classes={classes}
      next={next}
      update={update}
      backAction={backAction}
      isReloading={isReloading}
    />
  ) : (
    <Formik validationSchema={AddressValidation} initialValues={initialValues} onSubmit={onSubmit}>
      {props => (
        <AddressRadiusForm
          {...props}
          cancel={setShowForm}
          backAction={backAction}
          showCancel={!!addresses ? addresses.length > 0 : false}
        />
      )}
    </Formik>
  );
};

export default AddressSelector;
