import React, { FC } from "react";
import Loader from "../../atoms/Loading";
import List from "./List";

import { useMySTCClientsSubscription } from "../../../hooks/useMySTCClientsSubscription";

const StcClients: FC<{}> = () => {
  const { clients, loading } = useMySTCClientsSubscription();

  if (loading && !clients.length) {
    return <Loader />;
  }
  return (
    <List
      title="Klienten in interner Beratung"
      clients={clients}
      emptyMessage="Du hast momentan keine Klienten in einer internen Beratung"
    />
  );
};

export default StcClients;
