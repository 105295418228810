import React from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { Select } from "formik-material-ui";
import { Field } from "formik";

import { AddressType } from "../../../generated/graphql";

type TAddressTypeSelect = {
  name: string;
  tabIndex?: number;
};

const AddressTypeSelect = ({ name, tabIndex = 0 }: TAddressTypeSelect) => {
  const options = [];
  for (const [relationType, relationValue] of AddressTypeMap) {
    options.push(
      <MenuItem key={relationType} value={relationType}>
        {relationValue}
      </MenuItem>,
    );
  }

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel style={{ position: "absolute" }} htmlFor={name}>
        Bezeichung
      </InputLabel>
      <Field name={name} label="Bezeichung" component={Select} tabIndex={tabIndex}>
        {options}
      </Field>
    </FormControl>
  );
};

export default AddressTypeSelect;

export const AddressTypeMap = new Map<AddressType, string>([
  [AddressType.private, "Privat"],
  [AddressType.work, "Arbeit"],
]);
