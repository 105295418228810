import dayjs from "dayjs";
import React from "react";
import {
  CaseManagementReview,
  Maybe,
  Topic,
  useAccountQuery,
  useGetCaseManagementReviewsByUserIdQuery,
} from "../../../generated/graphql";
import { useGetCaseManagementReviewsByUserIdSubscription } from "../../../hooks/useGetCaseManagementReviewsSubscriptionByUserId";
import Loader from "../../atoms/Loading";

import Box from "@material-ui/core/Box";
import MuLink from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import ActivityItem from "../../molecules/ActivitiesItem";
import useStyles from "./styles";
import InboxGroup, { InboxHeadline } from "../InboxGroup";
import { InboxWrapper } from "./components";

const sortByLastActivity = (
  a: Maybe<Pick<CaseManagementReview, "lastUpdate">>,
  b: Maybe<Pick<CaseManagementReview, "lastUpdate">>,
) => {
  if (dayjs(a?.lastUpdate).isAfter(b?.lastUpdate)) {
    return -1;
  }
  if (dayjs(b?.lastUpdate).isAfter(a?.lastUpdate)) {
    return 1;
  }

  return 0;
};

type TClientList = { userId: string };
function ClientList({ userId }: TClientList) {
  const classes = useStyles();

  const { data, loading, subscribeToMore } = useGetCaseManagementReviewsByUserIdQuery({
    variables: {
      includeValues: ["whiteFlag", "yellowFlag", "redFlag", "unflagged"],
      clientReviewAttributeId: 10,
      userId: parseInt(userId, 10),
    },
  });

  const {
    getCaseManagementReviews,
    loading: loadingCaseManagement,
  } = useGetCaseManagementReviewsByUserIdSubscription({
    includeValues: ["whiteFlag", "yellowFlag", "redFlag", "unflagged"],
    clientReviewAttributeId: 10,
    userId: parseInt(userId, 10),
  });

  if (loadingCaseManagement) {
    return (
      <div style={{ position: "relative", minHeight: "200px" }}>
        <Loader />
      </div>
    );
  }

  if (getCaseManagementReviews?.length === 0) {
    return null;
  }
  
  const clients = getCaseManagementReviews
    ?.slice()
    .sort(sortByLastActivity)
    .map(caseManagementReview => {
      const caseManagementReviewAttributes = caseManagementReview?.caseManagementReviewAttributes;
      if (!caseManagementReviewAttributes?.length || caseManagementReviewAttributes.length > 1) {
        return caseManagementReview;
      }
      const flag = caseManagementReviewAttributes[0];
      return {
        avatar: {
          src:
            caseManagementReview?.user?.person?.picture ||
            caseManagementReview?.user?.person?.avatar,
          keys: caseManagementReview?.user?.person?.avatarKeys,
        },
        user: caseManagementReview?.user,
        account: caseManagementReview?.client?.account,
        person: caseManagementReview?.client?.person,
        topics: caseManagementReview?.client?.topics,
        info: {
          lastFlagUpdate: flag?.lastUpdate,
          lastReview: caseManagementReview?.lastUpdate,
        },
      };
    });

  if (clients?.length === 0) {
    return null;
  }

  return (
    <InboxGroup>
      <InboxWrapper>
        <InboxHeadline>Meine Klienten ({clients?.length})</InboxHeadline>
      </InboxWrapper>
      <Box className={classes.group}>
        {clients?.map((review: any) => {
          const title = review.topics.map((topic: Topic) => {
            return (
              <MuLink
                component={Link}
                to={`/person/${review.person.id}/topics/${topic.id}`}
                color="primary"
                underline="always"
                key={`topic-${topic.id}`}
                variant="h4"
              >
                {topic.name}
              </MuLink>
            );
          });
          return (
            <ActivityItem
              key={`review-${review.person.id}`}
              account={review.account}
              avatar={review.avatar}
              user={review.user}
              person={review.person}
              title={<Box className={classes.csv}>{title}</Box>}
              info={
                <Box display="flex">
                  <Box flex={1}>
                    <Typography variant="body2">
                      Aktuelle Flagge seit: {dayjs(review.info.lastFlagUpdate).format("DD.MM.YYYY")}
                    </Typography>
                  </Box>
                  <Box mx={1.5} />
                  <Box flex={1}>
                    <Typography variant="body2">
                      Letzte Review: {dayjs(review.info.lastReview).format("DD.MM.YYYY")}
                    </Typography>
                  </Box>
                </Box>
              }
            />
          );
        })}
      </Box>
    </InboxGroup>
  );
}

export default ClientList;
