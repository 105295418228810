import { Task, TaskType, ServiceType, Person, ServiceStatus } from "../generated/graphql";
import { path, pathOr } from "ramda";

export const taskIsSnoozed = (task: Task) => !!task.snoozeUntil;
export const taskIsStarted = (task: Task): boolean => task.startedAt !== null;
export const taskIsPending = (task: Task): boolean => task.startedAt === null;
export const taskIsDone = (task: Task): boolean => task.finishedAt !== null;
export const taskIsFinalized = (task?: Task): boolean => !task || taskIsDone(task);

export const isMine = (task: Task, me?: Person | null) => {
  if (!me || !task.author) return false;
  return me.id === task.author.id;
};

export const isWpoTask = (task: Task): boolean =>
  !!task.taskType && [TaskType.wpoVerweisung].includes(task.taskType);

export const isCMTask = (task: Task): boolean =>
  !!task.taskType && [TaskType.cmCheck, TaskType.ucms].includes(task.taskType);

export const isInternalConsultTask = (task: Task): boolean => {
  return !!task.taskType && [TaskType.planInternalConsult].includes(task.taskType);
};

export const isExternalConsultTask = (task: Task): boolean => {
  return (
    !!task.taskType &&
    [TaskType.externalConsultResearch, TaskType.externalFinish].includes(task.taskType)
  );
};

export const isServiceTask = (task: Task): boolean => {
  return (
    !!task.taskType &&
    [TaskType.createService, TaskType.researchService, TaskType.feedbackService].includes(
      task.taskType,
    )
  );
};

export const mapServiceStatusToTaskType = (
  status?: ServiceStatus | null,
  type?: ServiceType | null,
) => {
  if (!status || !type) return TaskType.noTask;
  switch (status) {
    case ServiceStatus.started:
      if (type === ServiceType.internalConsult) {
        return TaskType.planInternalConsult;
      }
      return TaskType.createService;
    case ServiceStatus.research:
      if (type !== ServiceType.externalConsult) return TaskType.researchService;
      return TaskType.externalConsultResearch;
    case ServiceStatus.ready:
      if (type !== ServiceType.externalConsult) {
        return TaskType.feedbackService;
      }
      return TaskType.noTask;
    case ServiceStatus.counseling:
    case ServiceStatus.finished:
    default:
      return TaskType.noTask;
  }
};

export const isReportTask = (task: Task): boolean => {
  return !!task.taskType && [TaskType.createConversationReport].includes(task.taskType);
};

export const allowManualFinish = (task: Task): boolean =>
  !!task.taskType &&
  [
    TaskType.cmCheck,
    TaskType.cmReminder,
    TaskType.custom,
    TaskType.externalFinish,
    TaskType.planInternalConsult,
    TaskType.ucms,
  ].includes(task.taskType);

interface PathState {
  pathname: string;
  state?: any;
}

// TODO: Refactor me!
export const getTaskPath = (task: Task): PathState => {
  const { personActivity, report, person } = task;

  if (task.taskType === TaskType.emailConversation) {
    return {
      pathname: task.path || "/",
    };
  }

  if (task.taskType === TaskType.wpoVerweisung) {
    return {
      pathname: task.path || "/",
    };
  }

  if (isCMTask(task) && !!report) {
    if (!!person?.id && !!report?.personActivity?.id) {
      return {
        pathname: `/person/${person.id}/activities/${report?.personActivity.id}`,
        state: { goBackPath: "/" },
      };
    }
    return { pathname: "" };
  }

  if (isWpoTask(task) && person) {
    if(!personActivity) {
     return {
       pathname: `/person/${task?.person?.id}`,
       state: { activity: personActivity },
     };      
    }
   return {
     pathname: `/person/${person.id}`,
     state: { activity: personActivity },
   };
 }

  if (!personActivity) {
    return { pathname: task.path ?? "" };
  }  

  if (isReportTask(task) && person) {
    if (taskIsPending(task)) {
      if (person?.business) {
        return {
          pathname: `/business/${person?.business.id}/memo`,
          state: { activity: personActivity },
        };
      } else {
        return {
          pathname: `/person/${person.id}/report`,
          state: { activity: personActivity },
        };
      }
    }
    const cid = personActivity.conversationReport?.id;
    const mid = personActivity.memo?.id;
    if (cid || mid) {
      return {
        pathname: `/${person?.business ? "business" : "person"}/${
          person?.business ? person?.business.id : person.id
        }/${cid ? `report/${cid}` : `memo/${mid}`}`,
        state: { activity: personActivity },
      };
    } else {
      if (person?.business) {
        return {
          pathname: `/business/${person?.business.id}/memo`,
          state: { activity: personActivity },
        };
      } else {
        return {
          pathname: `/person/${person.id}/report`,
          state: { activity: personActivity },
        };
      }
    }
  }
  if (isServiceTask(task) && person) {
    const sid = path(["service", "id"], personActivity);
    if (task.taskType === TaskType.createService) {
      const serviceType = pathOr(
        ServiceType.research,
        ["service", "serviceMeta", "type"],
        personActivity,
      );
      const serviceTarget =
        serviceType === ServiceType.research || serviceType === ServiceType.researchBlank
          ? "service"
          : serviceType;
      return { pathname: `/person/${person.id}/${serviceTarget}/${sid}/setup` };
    }
    return { pathname: `/service/${sid}` };
  }

  if (isInternalConsultTask(task)) {
    const sid = path(["service", "id"], personActivity);

    return { pathname: `/internalConsult/${sid}` };
  }

  if (isExternalConsultTask(task)) {
    const sid = path(["service", "id"], personActivity);

    return { pathname: `/service/${sid}` };
  }
  return { pathname: task.path ?? "" };
};

export const getTaskTitle = (task: Task, context?: string | null): string => {
  const serviceName = getServiceTaskName(task);
  switch (task.taskType) {
    case TaskType.ucms:
      return taskIsStarted(task) && !taskIsDone(task)
        ? "Stelle die Dokumentation des Gesprächs in UCMS fertig"
        : "Dokumentiere das Gespräch in UCMS";
    case TaskType.createConversationReport:
      return taskIsStarted(task) && !taskIsDone(task)
        ? "Stelle die Dokumentation des Gesprächs fertig"
        : "Dokumentiere das Gespräch";
    case TaskType.createService:
      const type = task.personActivity?.service?.serviceMeta?.type;

      if (type === ServiceType.externalConsult) {
        return "Schließe die Erstellung der externen Beratung ab";
      }

      const name = context || "Service";
      return `Schließe die Erstellung des Service ${name} ab`;
    case TaskType.emailConversation:
      return "E-Mail bearbeiten";
    case TaskType.planInternalConsult:
      return "Interne strukturierte Beratung planen";
    case TaskType.externalConsultResearch:
      return "F2F Recherche durchführen";
    case TaskType.externalFinish:
      return "Termin für Abschlussgespräch planen";
    case TaskType.researchService:
      return `Service ${serviceName.length ? `${serviceName} ` : ""}recherchieren`;
    case TaskType.feedbackService:
      return `Service ${serviceName.length ? `${serviceName} ` : ""}rückmelden`;
    case TaskType.cmCheck:
      return "Gesprächsprotokoll überprüfen";
    case TaskType.cmReminder:
      return task.title || "Erinnerung: CM Review";
    case TaskType.wpoVerweisung:
      return task.title || "WPO-Klienten kontaktieren";    
    case TaskType.custom:
      return task.title || "...";
    default:
      return "";
  }
};

export const getServiceTaskName = (task: Task) => {
  return task.personActivity?.service?.serviceMeta?.name || "";
};

const TaskHelper = {
  getServiceTaskName,
  getTaskPath,
  isMine,
  isReportTask,
  isServiceTask,
  taskIsDone,
  taskIsStarted,
};

export default TaskHelper;
