import React, { useState } from "react";

import Grid from "@material-ui/core/Grid";

import GlobalActivityStream from "../../organisms/GlobalActivityStream";
import AppointmentsStream from "../../organisms/AppointmentsStream";
import ActivitiesTemplate from "../../templates/Activities";

import CalendarTodayRounded from "@material-ui/icons/CalendarTodayRounded";
import Adjust from "@material-ui/icons/Adjust";
import HotTopics from "./topics";
import CalendarNavigator from "./calendarNavigator";
import DatePickerNavigator from "./datepickerNavigator";
import useLocalStorage, { LocalStorage } from "../../../utils/localStorageHelper";
import { Box, IconButton } from "@material-ui/core";

const ActivitiesPage = () => {
  
  const { getItem, setItem } = useLocalStorage(
    LocalStorage.settings,    
    'ACTIVITIES'
  );
  const [currentSetting, setCurrentSetting] = useState(getItem('VIEW_MODE'));
  if(!currentSetting) {
    setCurrentSetting('DATE_PICKER_VIEW');
  }

  const handleViewMode = (mode: string) => {
    setItem('VIEW_MODE', mode);
    setCurrentSetting(mode)
  }

  return (
    <ActivitiesTemplate>        
      <Grid container>
        <Grid item xs={1}></Grid>
        <Grid item xs={6}>
          <AppointmentsStream />
          <GlobalActivityStream />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={3} container direction="column">
          <HotTopics />
          {/* <Box mt={8}>
          <IconButton title="Datumsauswahlansicht" onClick={() => handleViewMode('DATE_PICKER_VIEW')} style={{
            opacity: currentSetting === 'DATE_PICKER_VIEW' ? 1 :  0.4
          }}>
            <Adjust />
          </IconButton>
          <IconButton title="Kalenderansicht" onClick={() => handleViewMode('CALENDAR_VIEW')} style={{
            opacity: currentSetting === 'CALENDAR_VIEW' ? 1 :  0.4
          }}>
            <CalendarTodayRounded />
          </IconButton>          
          </Box>
          { currentSetting === 'CALENDAR_VIEW' ?
            <CalendarNavigator /> :
            <DatePickerNavigator />
          } */}
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </ActivitiesTemplate>
  );
};

export default ActivitiesPage;
