import { makeStyles } from "@material-ui/styles";
import { createStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    counter: (props: any) => {
      return {
        animation: `pulse 500ms ease-out`,
        animationIterationCount: 1,
        backgroundColor:
          props.color === "secondary" ? theme.palette.secondary.main : theme.palette.primary.main,
        border: "solid #fff 1px",
        borderRadius: "18px",
        color: "#fff",
        fontSize: "12px",
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: "16px",
        minWidth: "20px",
        padding: "0 4px",
        position: props.top || props.right ? "absolute" : "relative",
        right: `${props.right}px`,
        textAlign: "center",
        top: `${props.top}px`,
        zIndex: 1,
      };
    },
    "@keyframes pulse": {
      "0%": {
        transform: "scale(1)",
      },
      "10%": {
        transform: "scale(0.8)",
      },
      "40%": {
        transform: "scale(1.15)",
      },
      "60%": {
        transform: "scale(0.90)",
      },
      "90%": {
        transform: "scale(1.05)",
      },
      "100%": {
        transform: "scale(1)",
      },
    },
  });
});

export default useStyles;
