import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";

import { pathOr } from "ramda";
import { MutationFunctionOptions } from "@apollo/client";
import { ExecutionResult } from "graphql";

import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";

import PrimaryButton from "../../../../atoms/PrimaryButton";
import { radiusDefaults } from "../../../../../utils/constants";
import { addressString } from "../../../../../utils/addressHelper";
import { Address, ServiceAddress, Maybe } from "../../../../../generated/graphql";
import WizardNavigation from "../../../../molecules/WizardNavigation";
import SecondaryButton from "../../../../atoms/SecondaryButton";

type TAddressListItem = {
  address: Address;
  update: () => void;
  connectedItem: ServiceAddress | null | undefined;
  defaultRadius: number;
  handleChange: (radius: number) => void | Promise<void>;
  updateAction: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<ExecutionResult<any>>;
  disabled: boolean;
  isReloading: boolean;
  classes: any;
};

const AddressListItem = ({
  address,
  update,
  connectedItem,
  defaultRadius,
  handleChange,
  updateAction,
  disabled,
  isReloading,
  classes,
}: TAddressListItem) => {
  const [submitting, setSubmitting] = useState(false);
  const [radius, setRadius] = useState(
    !!connectedItem ? (connectedItem.radius as number) : defaultRadius,
  );

  const handleRadiusChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const newRadius = (event.target as HTMLInputElement).value;

    setRadius(parseInt(newRadius, 10));

    if (!!connectedItem && !!connectedItem.id) {
      const response = await updateAction({
        variables: {
          serviceAddress: {
            id: connectedItem.id,
            radius: parseInt(newRadius, 10),
          },
        },
      });

      setRadius(response.data.updateServiceAddress.radius);
      update();
    }
  };

  const handleSelect = async () => {
    if (isReloading || submitting) {
      return;
    }
    setSubmitting(true);
    await handleChange(radius || defaultRadius);
    setSubmitting(false);
  };

  let connectedRadius: number = radius;

  if (!!connectedItem) {
    connectedRadius = pathOr(radius, ["radius"], connectedItem);
  }

  return (
    <Box mb={2}>
      <Box mb={0.5}>
        <FormControlLabel
          disabled={isReloading || submitting || (disabled && !connectedItem)}
          control={
            <Checkbox
              checked={!!connectedItem}
              onChange={handleSelect}
              disabled={!radius || isReloading || submitting}
            />
          }
          label={addressString(address)}
        />
      </Box>
      <FormControl
        component="fieldset"
        classes={{ root: classes.formGroup }}
        disabled={!connectedItem}
      >
        <RadioGroup
          aria-label="radius"
          name="radius"
          value={connectedRadius}
          onChange={handleRadiusChange}
          className={classes.formGroup}
        >
          {radiusDefaults.map(value => {
            if (value === 10000) {
              return (
                <FormControlLabel
                  tabIndex={4}
                  key="noRadius"
                  value={10000}
                  control={<Radio />}
                  label="Ohne Einschränkung"
                  labelPlacement="top"
                />
              );
            }
            return (
              <FormControlLabel
                key={value}
                value={value}
                control={<Radio />}
                label={`${value}km`}
                labelPlacement="top"
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

type TAddressList = {
  classes: any;
  connected: Maybe<ServiceAddress>[];
  createWithAddress: (address: Address, radius: number) => void;
  deleteServiceAddress: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<ExecutionResult<any>>;
  next: () => void;
  openForm: Dispatch<SetStateAction<boolean>>;
  addresses: Address[];
  updateAction: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<ExecutionResult<any>>;
  update: () => void;
  backAction: () => void;
  isReloading: boolean;
};

export const AddressList = ({
  backAction,
  classes,
  connected,
  createWithAddress,
  deleteServiceAddress,
  next,
  openForm,
  addresses,
  updateAction,
  update,
  isReloading,
}: TAddressList) => {
  const disabled = connected.length > 0;
  return (
    <>
      <Box display="flex" justifyContent="center">
        <div>
          {addresses.map(address => {
            const selected = connected.find((item: any) => item.addressId === address.id);

            const handleChange = (radius: number) => {
              if (selected) {
                return deleteServiceAddress({
                  variables: { id: selected.id },
                }).then(update);
              }
              return createWithAddress(address, radius);
            };

            return (
              <AddressListItem
                key={`${address.id}`}
                address={address}
                updateAction={updateAction}
                connectedItem={selected}
                disabled={disabled}
                handleChange={handleChange}
                defaultRadius={5}
                classes={classes}
                update={update}
                isReloading={isReloading}
              />
            );
          })}
          <Link onClick={() => openForm(true)} underline="always" variant="h4" color="primary">
            weitere Adresse hinzufügen
          </Link>
        </div>
      </Box>
      <WizardNavigation>
        <SecondaryButton onClick={() => backAction()}>Zurück</SecondaryButton>
        <PrimaryButton disabled={connected.length === 0} onClick={next}>
          Weiter
        </PrimaryButton>
      </WizardNavigation>
    </>
  );
};

export default AddressList;
