import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import elli from "./elli.svg";

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      backgroundColor: theme.palette.primary.main,
      cursor: "pointer" as "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: 68,
      maxWidth: 440,
      minHeight: 64,
      minWidth: 264,
      padding: theme.spacing(2, 3),
      "&:before": {
        borderBottom: "16px solid transparent",
        borderLeft: `16px solid ${theme.palette.primary.main}`,
        borderRight: "0px solid transparent",
        borderTop: "16px solid transparent",
        bottom: 0,
        content: '""',
        height: 0,
        margin: "auto 0",
        position: "absolute" as "absolute",
        right: 54,
        top: 0,
        width: 0,
      },
      "&:after": {
        background: `url(${elli}) no-repeat`,
        backgroundSize: "contain" as "contain",
        bottom: 0,
        content: '""',
        height: 50,
        margin: "auto 0",
        position: "absolute" as "absolute",
        right: 0,
        top: 0,
        width: 50,
      },
    },
    text: {
      color: theme.palette.common.white,
    },
  };
});

export default useStyles;
