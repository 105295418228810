import React, { useState } from "react";

import * as Yup from "yup";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { Form, Formik, FormikActions } from "formik";

import DefaultDialog from "../../molecules/DefaultDialog";
import DefaultDialogContent from "../../molecules/DefaultDialogContent";
import SecondaryButton from "../../atoms/SecondaryButton";
import PrimaryButton from "../../atoms/PrimaryButton";

type TDialogForm = {
  // When adding e.g. a document, fields might be prefilled.
  isInitialValid?: boolean;
  initialValues: { [key: string]: any };
  open: boolean;
  onClose: () => void;
  onSubmit: (
    values: any,
    actions: FormikActions<{
      [key: string]: any;
    }>,
  ) => Promise<void>;
  title: string;
  validationSchema?: Yup.ObjectSchema<{ [key: string]: any }>;
};

/**
 *  Generic Form for Dialogs, has Cancel/OK buttons, customize if needed or treat other dialogs as
 *  custom cases.
 */
const DialogForm: React.FC<TDialogForm> = ({
  children,
  initialValues,
  isInitialValid = true,
  open,
  onClose,
  onSubmit,
  title,
  validationSchema,
}) => {
  const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);

  const handleOnClose = () => {
    setHasSubmitError(false);
    onClose();
  };

  return (
    <DefaultDialog open={open} onClose={handleOnClose}>
      <DefaultDialogContent>
        <Box mb={5} textAlign="center">
          <Typography variant="h1">{title}</Typography>
        </Box>
        <Formik
          isInitialValid={isInitialValid}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            setHasSubmitError(false);

            try {
              await onSubmit(values, actions);
            } catch (error) {
              setHasSubmitError(true);
              console.error(error);
            }

            actions.setSubmitting(false);
          }}
        >
          {formProps => {
            return (
              <Form style={{ width: "100%" }}>
                {typeof children === "function" ? children(formProps) : children}

                <Box display="flex" justifyContent="center" mt={5}>
                  <SecondaryButton onClick={handleOnClose}>Abbrechen</SecondaryButton>
                  <Box mx={1} />
                  <PrimaryButton type="submit" disabled={!formProps.isValid}>
                    OK
                  </PrimaryButton>
                </Box>

                {hasSubmitError ? (
                  <Box mt={2}>
                    <Alert severity="error">Es gab einen Fehler beim Speichern</Alert>
                  </Box>
                ) : null}
              </Form>
            );
          }}
        </Formik>
      </DefaultDialogContent>
    </DefaultDialog>
  );
};

export default DialogForm;
