import React from "react";

import pathOr from "ramda/src/pathOr";

import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import { Person, useCancelCallMutation, PersonActivityQuery } from "../../../generated/graphql";
import { ActivityInteractionType } from "../Interactions/Bubble";
import Flag from "../../atoms/Flag";

type TCounterpart = {
  handleClose: () => void;
  personActivity: PersonActivityQuery["personActivity"];
  showCancel: boolean;
};

const Counterpart = ({ handleClose, personActivity, showCancel }: TCounterpart) => {
  switch (personActivity.activityType) {
    case ActivityInteractionType.mail:
      return <CounterpartMail person={personActivity.person} />;
    case ActivityInteractionType.call:
    case ActivityInteractionType.callIn:
    case ActivityInteractionType.callOut:
      return (
        <CounterpartCall
          call={personActivity.call}
          handleClose={handleClose}
          person={personActivity.person}
          showCancel={showCancel}
        />
      );
    default:
      return <></>;
  }
};

type TCounterpartCall = {
  call: PersonActivityQuery["personActivity"]["call"];
  handleClose: () => void;
  person?: Person | null;
  showCancel: boolean;
};

const CounterpartCall = ({ call, handleClose, person, showCancel }: TCounterpartCall) => {
  if (!call) {
    throw Error();
  }

  let counterPart = call.readableNumber;

  if (!person?.temporary) {
    let name = person?.name || "Unbekannt (Fehler)";
    if (call.account?.name) {
      name = `${name}, ${call.account?.name}`;
    }
    counterPart = `${name} (${counterPart})`;
  }

  return (
    <Box display="flex" justifyContent="space-between">
      { (person?.redFlag || person?.yellowFlag || person?.flag)
      &&
      <Box display="flex" mb={2}>
        <Box mr={1}>
          <Box
            height={40}
            width={40}
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={-1}
          >            
            <Flag
              red={person?.redFlag ?? false}
              yellow={person?.yellowFlag ?? false}  
              white={person?.flag === 'whiteFlag' ?? false}                         
            />
          </Box>
        </Box>
      </Box>
      }

      <Typography variant="h4">
        {call.status === "finished"
          ? `Du hast dein Telefonat mit
          ${counterPart} beendet.`
          : `Du sprichst mit ${counterPart}`}
      </Typography>
      {showCancel && <CancelCall callId={call.id} handleClose={handleClose} />}
    </Box>
  );
};

type TCancelCall = {
  callId: number;
  handleClose: () => void;
};

const CancelCall = ({ callId, handleClose }: TCancelCall) => {
  const [cancelCall] = useCancelCallMutation({
    variables: {
      callId,
    },
  });

  return (
    <Link
      onClick={async () => {
        await cancelCall();
        handleClose();
      }}
      style={{ cursor: "pointer" }}
      underline="always"
      variant="h4"
    >
      Ungültig
    </Link>
  );
};

type TCounterpartMail = {
  person?: Person | null;
};

const CounterpartMail = ({ person }: TCounterpartMail) => {
  const name = person?.temporary
    ? pathOr("Unbekannt", ["emails", 0, "email"], person)
    : person?.name || "Unbekannt (Fehler)";

  return <Typography variant="h4">E-Mail von: {name}</Typography>;
};

export default Counterpart;
