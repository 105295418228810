import React from "react";

import { FormikValues, Form } from "formik";

import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import SlateRTE from "../../molecules/SlateRTE";
import WizardNavigation from "../../molecules/WizardNavigation";
import useAutoSave from "../../../hooks/useAutoSave";

export type TStepRiskInfo = {
  backAction: () => void;
  saveInput: (params: any) => any;
  setFieldValue: (field: string, value: any) => void;
  values: FormikValues;
};

const StepRiskInfo = ({ backAction, saveInput, setFieldValue, values }: TStepRiskInfo) => {
  useAutoSave(values, saveInput);

  return (
    <Form
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <SlateRTE
        value={values.riskInfo}
        label="Risikoeinschätzung"
        handleChange={value => setFieldValue("riskInfo", value)}
        tabIndex={1}
      />
      <WizardNavigation>
        <SecondaryButton onClick={backAction}>Zurück</SecondaryButton>
        <PrimaryButton type="submit">Weiter</PrimaryButton>
      </WizardNavigation>
    </Form>
  );
};

export default StepRiskInfo;
