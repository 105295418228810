import { useGetOneGMailThreadQuery } from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

const useGetGMailThreadSubscription = (threadId?: string) => {
  const { data } = useGetOneGMailThreadQuery({
    skip: !threadId,
    variables: {
      // @ts-ignore
      threadId,
    },
    fetchPolicy,
    pollInterval: 10000,
  });

  return { getOneGMailThread: data?.getOneGMailThread };
};

export default useGetGMailThreadSubscription;
