import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Dayjs } from "dayjs";
import {
  Hour,
  Service,
  CreateHourDocument,
  DeleteHourDocument,
} from "../../../../../generated/graphql";

import { Box, FormControlLabel, Checkbox } from "@material-ui/core";

import { weekDays } from "../../../../../utils/constants";
import HourEntry from "../HourEntry";
import useStyles from "./styles";

interface DayEntryProps {
  day: number;
  defaultClose: Dayjs;
  defaultOpen: Dayjs;
  hours: Hour[];
  isReloading: boolean;
  service: Service;
  update: () => void;
}

export default function DayEntry({
  day,
  defaultClose,
  defaultOpen,
  hours,
  isReloading,
  service,
  update,
}: DayEntryProps) {
  const classes = useStyles();
  const [submitting, setSubmitting] = useState(false);
  const [createHour] = useMutation(CreateHourDocument);
  const [deleteHour] = useMutation(DeleteHourDocument);

  const handleCheck = async () => {
    if (isReloading || submitting) {
      return;
    }
    setSubmitting(true);
    if (!!hours.length) {
      const deleteHours = hours.map(hour => (hour.id ? _deleteHour(hour.id) : null));
      await Promise.all(deleteHours).then(update);
    } else {
      await createDefaultHour();
    }
    setSubmitting(false);
  };

  const _deleteHour = (id: number) => {
    return deleteHour({ variables: { id } });
  };

  const createDefaultHour = async () => {
    return createHour({
      variables: {
        hour: {
          day,
          open: defaultOpen,
          close: defaultClose,
          serviceId: service.id,
        },
      },
    }).then(update);
  };

  const renderDayHours = () => {
    return hours.map((hour: Hour, idx: number) => (
      <HourEntry
        createHour={createHour}
        day={day}
        deleteHour={_deleteHour}
        defaultClose={defaultClose}
        defaultOpen={defaultOpen}
        hour={hour}
        key={`hour-${hour.id}`}
        serviceId={service.id}
        showAddIcon={idx === hours.length - 1}
        showRemoveIcon={idx !== 0}
        update={update}
      />
    ));
  };

  return (
    <Box display="flex" className={classes.dayRow} width={600}>
      <Box display="flex" width={200}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleCheck}
              checked={!!hours.length}
              disabled={submitting || isReloading}
            />
          }
          label={weekDays[day]}
        />
      </Box>
      <Box display="flex" alignItems="flex-end" flex={1} flexDirection="column" minHeight={48}>
        {renderDayHours()}
      </Box>
    </Box>
  );
}
