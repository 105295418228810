import React, { FC } from "react";

import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import MuLink from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { CaseManagementReview, Maybe, Topic, useAllClientsQuery } from "../../../generated/graphql";
import { useGetCaseManagementReviewsSubscription } from "../../../hooks/useGetCaseManagementReviewsSubscription";
import empty from "../../assets/empty.svg";
import Loading from "../../atoms/Loading";
import ActivityItem from "../../molecules/ActivitiesItem";

import { withStyles } from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import useStyles from "./styles";
import { allClients } from "../../../queries/client";

const sortByLastActivity = (
  a: Maybe<Pick<CaseManagementReview, "lastUpdate">>,
  b: Maybe<Pick<CaseManagementReview, "lastUpdate">>,
) => {
  if (dayjs(a?.lastUpdate).isAfter(b?.lastUpdate)) {
    return -1;
  }
  if (dayjs(b?.lastUpdate).isAfter(a?.lastUpdate)) {
    return 1;
  }

  return 0;
};

const Accordion = withStyles({
  root: {
    background: "transparent",
    borderBottom: `1px solid #BDBDBD`,
    "&$expanded": {
      marginTop: "auto",
      marginBottom: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const ActivitiesFlagsCaseManager: FC = () => {
  const classes = useStyles();
  const { data, loading } = useAllClientsQuery();

  if (loading) {
    return <Loading />;
  }

  const init = new Map<string, any[]>([["betreute Klienten", []]]);

  if (!data?.allClients) {
    return <></>;
  }

  // const reviewGroups = data.allClients[0].reviews
  //   ?.slice()
  // //   ?.sort(sortByLastActivity)
  //   ?.reduce((carry, caseManagementReview) => {
  //     const caseManager = caseManagementReview?.user;
  //     const caseManagementReviewAttributes = caseManagementReview?.;

  //     if (!caseManagementReviewAttributes?.length || caseManagementReviewAttributes.length > 1) {
  //       console.error({ error: caseManagementReview });
  //       return carry;
  //     }

  //     const user = caseManager?.user;
  //     const flag = caseManagementReviewAttributes[0];
  //     const payload = {
  //       avatar: {
  //         src:
  //           caseManagementReview?.caseManager?.user?.person?.picture ||
  //           caseManagementReview?.caseManager?.user?.person?.avatar,
  //         keys: caseManagementReview?.caseManager?.user?.person?.avatarKeys,
  //       },
  //       user: caseManagementReview?.caseManager?.user,
  //       account: caseManagementReview?.client?.account,
  //       person: caseManagementReview?.client?.person,
  //       topics: caseManagementReview?.client?.topics,
  //       info: {
  //         lastFlagUpdate: flag?.lastUpdate,
  //         lastReview: caseManagementReview?.lastUpdate,
  //       },
  //     };
  //     if (user?.person) {
  //       if (carry.has(user.person.name)) {
  //         carry.get(user.person.name)?.push(payload);
  //       } else {
  //         carry.set(user.person.name, [payload]);
  //       }
  //     } else {
  //       carry.get("betreute Klienten")?.push(payload);
  //     }

  //     return carry;
  //   }, init);

  const groups = data?.allClients.map(client => {
    //   if (!reviews || reviews!.length < 1) {
    //     return null;
    //   }

    let title = client?.user?.person?.name;

    return (
      <Accordion key={`${title}`}>
        <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
          <Typography variant="h4">
            {title} ({client?.total})
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.group} flex={1} flexDirection={"column"}>
            {client?.reviews &&
              client?.reviews.map(review => {
                const title = review?.client?.topics?.map((topic: any) => {
                  return (
                    <MuLink
                      component={Link}
                      to={`/person/${review?.client?.person?.id}/topics/${topic.id}`}
                      color="primary"
                      underline="always"
                      key={`topic-${topic.id}`}
                      variant="h4"
                    >
                      {topic.name}
                    </MuLink>
                  );
                });
                return (
                  <ActivityItem
                    key={`review-${review?.client?.person?.id || "meh"}`}
                    account={review?.client?.account}
                    avatar={{
                      keys: review?.caseManager?.user?.person?.avatarKeys || null,
                      src: review?.caseManager?.user?.person?.avatar || null,
                    }}
                    user={review?.caseManager?.user}
                    person={review?.client?.person}
                    title={<Box className={classes.csv}>{title}</Box>}
                    info={
                      <Box display="flex">
                        <Box flex={1}>
                          <Typography variant="body2">
                            Aktuelle Flagge seit: {dayjs(review?.lastUpdate).format("DD.MM.YYYY")}
                          </Typography>
                        </Box>
                        <Box mx={1.5} />
                        {review?.caseManagementReviewAttributes &&
                        review?.caseManagementReviewAttributes.length > 0 ? (
                          <Box flex={1}>
                            <Typography variant="body2">
                              Letzte Review:{" "}
                              {dayjs(review.caseManagementReviewAttributes[0]?.lastUpdate).format(
                                "DD.MM.YYYY",
                              )}
                            </Typography>
                          </Box>
                        ) : (
                          <></>
                        )}
                      </Box>
                    }
                  />
                );
              })}
            {client?.clients &&
              client?.clients.map(client => {
                return (
                  <ActivityItem
                    key={`${title}-clients-${client?.id}`}
                    title={<></>}
                    user={client?.caseManager?.user}
                    avatar={{
                      keys: client?.caseManager?.user?.person?.avatarKeys || null,
                      src: client?.caseManager?.user?.person?.avatar || null,
                    }}
                    person={client?.person!}
                    info={
                      <Box display="flex">
                        <Box flex={1}>
                          <Typography variant="body2">
                            Letzte Aktivität:{" "}
                            {dayjs(client?.person!.lastActivity!).format("DD.MM.YYYY")}
                          </Typography>
                        </Box>
                      </Box>
                    }
                  />
                );
              })}
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  });

  return (
    <>
      <Grid container className={`${classes.container}`}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.headerContainer}
        >
          <Grid item xs={4}>
            <Chip
              key={"Case Manager"}
              label={"Case Manager"}
              color={"default"}
              classes={{ root: classes.chip, label: classes.chipLabel }}
            />

            <MuLink
              className={`${classes.filterLabel}`}
              component={Link}
              to={`/activities/flags`}
              color="primary"
              underline="always"
              key={`flags`}
              variant="h3"
              style={{ marginLeft: "16px" }}
            >
              Flaggen
            </MuLink>
          </Grid>
          <Grid xs={4} container alignItems="center" justifyContent="center">
            <h3>Case Manager Übersicht</h3>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </Grid>
      {!data?.allClients || data?.allClients.length === 0 ? (
        <Box mt={10} textAlign="center">
          <img src={empty} alt="Es gibt derzeit keine geflaggten Klienten" />
          <Box my={2} />
          <Typography variant="body2">Es gibt derzeit keine geflaggten Klienten</Typography>
        </Box>
      ) : (
        groups
      )}
    </>
  );
};

export default ActivitiesFlagsCaseManager;
