import React, { FC } from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { Select } from "formik-material-ui";
import { Field } from "formik";

const PhoneCountrySelect: FC<{ name: string }> = ({ name }) => {
  const options = [];
  for (const [relationType, relationValue] of PhoneCountryMap) {
    options.push(
      <MenuItem key={relationType} value={relationType}>
        {relationValue}
      </MenuItem>,
    );
  }

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel
        style={{
          position: "absolute",
        }}
        htmlFor={name}
      >
        Land
      </InputLabel>
      <Field name={name} label="land" component={Select}>
        {options}
      </Field>
    </FormControl>
  );
};

export const PhoneCountryMap = new Map<string, string>([
  ["49", "Deutschland"],
  ["43", "Österreich"],
  ["41", "Schweiz"],
  ["32", "Belgien"],
  ["45", "Dänemark"],
  ["33", "Frankreich"],
  ["44", "Großbritannien"],
  ["39", "Italien"],
  ["352", "Luxemburg"],
  ["31", "Niederlande"],
  ["34", "Spanien"],
  ["1", "USA/Kanada"],
]);

export default PhoneCountrySelect;
