export const SET_STEP = "SET_STEP";
export const SET_TYPE = "SET_TYPE";

export enum STEPS {
  TYPE,
  DETAILS,
}

export enum TYPES {
  UNDEFINED,
  CLIENT,
  PERSON,
  BUSINESS,
}

export type Action =
  | { type: typeof SET_TYPE; payload: TYPES }
  | { type: typeof SET_STEP; payload: STEPS };
export type State = {
  step: STEPS;
  type: TYPES;
};

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case SET_TYPE:
      return { ...state, type: action.payload, step: STEPS.DETAILS };
    case SET_STEP:
      return { ...state, step: action.payload };
    default:
      return { ...state };
  }
};

export const initialState: State = {
  step: STEPS.TYPE,
  type: TYPES.UNDEFINED,
};
