import Maybe from "graphql/tsutils/Maybe";

export interface LocalStorageHelper {
  setItem: (key: string, value: string) => void;
  getItem: (key: string) => Maybe<string>;
  removeItem: (key: string) => void;
}

export enum LocalStorage {
  caseManagementReview,
  researchCall,
  session,
  settings,
}

export default function useLocalStorage(pre: LocalStorage, id?: string): LocalStorageHelper {
  let prefix = `${pre}`;

  if (id) {
    prefix = `${prefix}:${id}`;
  }

  return {
    setItem: (key: string, value: string) => localStorage.setItem(`${prefix}:${key}`, value),
    getItem: (key: string) => localStorage.getItem(`${prefix}:${key}`),
    removeItem: (key: string) => localStorage.removeItem(`${prefix}:${key}`),
  };
}
