import React, { FC } from "react";

import dayjs from "dayjs";
import Box from "@material-ui/core/Box";

import Markup from "../../atoms/Markup";
import { Person } from "../../../generated/graphql";
import { useCaseManagementReviewSubscription } from "../../../hooks/useCaseManagementReviewSubscription";
import CaseManagementRisk from "../../molecules/CaseManagementRisk";
import Card from "../../molecules/Card";

const CaseManagementContainer: FC<{ person: Person }> = ({ person }) => {
  const review = useCaseManagementReviewSubscription(person.id!);

  // as long as we do not treat errors explicitly, let's simplify loading/error/data state
  if (!review) {
    return null;
  }

  const flag = review?.caseManagementReviewAttributes?.flag;
  const riskAssessment = review?.caseManagementReviewAttributes?.riskAssessment;

  const risk = {
    flag: flag?.attributeValue,
    riskAssessment: riskAssessment?.attributeValue,
  };

  let lastUpdate, user;
  if (!!riskAssessment && !!flag) {
    user = dayjs(riskAssessment.lastUpdate).isAfter(flag.lastUpdate)
      ? riskAssessment.user
      : flag.user;
    lastUpdate = dayjs(riskAssessment.lastUpdate).isAfter(flag.lastUpdate)
      ? riskAssessment.lastUpdate
      : flag.lastUpdate;
  }

  const strategy = review?.caseManagementReviewAttributes?.strategy?.attributeValue;

  return (
    <>
      {!!user && !!lastUpdate && (
        <Box mt={2} mb={4}>
          <Card title="Risiko">
            <CaseManagementRisk risk={risk} user={user} lastUpdate={lastUpdate} />
          </Card>
        </Box>
      )}
      {strategy && (
        <Card title="Strategie für die Gesprächsführung">
          <Box mb={4}>
            <Markup value={strategy}></Markup>
          </Box>
        </Card>
      )}
    </>
  );
};
export default CaseManagementContainer;
