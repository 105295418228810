import React from "react";

import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import dayjs from "dayjs";

import {
  useUpdateCaseManagementReviewAttributeMutation,
  ReviewAttributeType,
} from "../../../generated/graphql";
import SlateRTE, { initialFormValue } from "../../molecules/SlateRTE";
import { useGetCaseManagementReviewAttributeSubscription } from "../../../hooks/useGetCaseManagementReviewAttributeSubscription";

type TCaseManagementReviewAttribute = {
  caseManagementReviewId: number;
  clientReviewAttributeId: number;
  label: string;
  type: ReviewAttributeType;
  tabIndex?: number;
};
const CaseManagementReviewAttribute = ({
  caseManagementReviewId,
  clientReviewAttributeId,
  label,
  type,
  tabIndex = 0,
}: TCaseManagementReviewAttribute) => {
  const {
    caseManagementReviewAttribute,
    loading,
  } = useGetCaseManagementReviewAttributeSubscription({
    caseManagementReviewId,
    clientReviewAttributeId,
  });

  const [updateCaseManagementReviewAttribute] = useUpdateCaseManagementReviewAttributeMutation();

  if (loading && !caseManagementReviewAttribute) {
    return null;
  }
  const attributeValue = caseManagementReviewAttribute?.attributeValue || initialFormValue;
  if (type === ReviewAttributeType.checkbox) {
    // Currently this is not flexible!
    return (
      <Box display="flex" justifyContent="center" width="100%">
        <RadioGroup
          aria-label="flag"
          name="flag"
          value={attributeValue || "unflagged"}
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
          onChange={attributeValue => {
            updateCaseManagementReviewAttribute({
              variables: {
                caseManagementReviewAttributeInput: {
                  caseManagementReviewId,
                  clientReviewAttributeId,
                  attributeValue: attributeValue.target.value,
                  date: dayjs().toISOString(),
                },
              },
            });
          }}
        >
          {[
            { label: "Entflaggt", value: "unflagged" },
            { label: "weiße Flagge", value: "whiteFlag" },
            { label: "gelbe Flagge", value: "yellowFlag" },
            { label: "rote Flagge", value: "redFlag" },
          ].map(option => (
            <FormControlLabel
              style={{ marginRight: 0 }}
              key={option.value}
              value={option.value}
              control={<Radio inputProps={{ tabIndex }} />}
              label={option.label}
              labelPlacement="end"
            />
          ))}
        </RadioGroup>
      </Box>
    );
  }

  return (
    <SlateRTE
      tabIndex={tabIndex}
      label={label}
      autoFocus={tabIndex === 1}
      // @ts-ignore
      autoSave={(value: string, date: string) => {
        updateCaseManagementReviewAttribute({
          variables: {
            caseManagementReviewAttributeInput: {
              caseManagementReviewId,
              clientReviewAttributeId,
              attributeValue: value,
              date,
            },
          },
        });
      }}
      lastUpdate={caseManagementReviewAttribute?.lastUpdate}
      value={attributeValue}
    />
  );
};

export default CaseManagementReviewAttribute;
