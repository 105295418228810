import React, { useState } from "react";
import dayjs from "dayjs";
import { useLocation } from 'react-router-dom';
import Box from "@material-ui/core/Box";
import ActivitiesList from "../ActivitiesList";
import Loading from "../../atoms/Loading";
import { TAKE } from "../../../utils/constants";
import { useAllActivitiesSubscription } from "../../../hooks/useAllActivitiesSubscription";

type TGlobalActivityStream = {
  filter?: {
    skip?: number;
    take?: number;
  };
};

const GlobalActivityStream = ({ filter }: TGlobalActivityStream) => {
  const { activities, fetchMore, loading } = useAllActivitiesSubscription(filter);

  const [hasMore, setHasMore] = useState(true);
  const { search } = useLocation();
  const date = new URLSearchParams(search).get("date"); 
  
  return !activities.length && loading ? (
    <Loading />
  ) : (
    <Box display="flex" justifyContent="flex-end" marginTop={1}>
      <ActivitiesList
        past
        withClient
        hasMore={hasMore}
        activities={activities}
        onLoadMore={allActivities => {
          return fetchMore({
            variables: {
              skip: allActivities.length,
              take: TAKE,
              date: date ?? dayjs(Date()).format('YYYY-MM-DD'),                      
              ...filter,
            },
            updateQuery: (prev, { fetchMoreResult }: { fetchMoreResult?: any }) => {
              // if (!fetchMoreResult || !fetchMoreResult?.allActivities.length) {
              //   setHasMore(false);
              //   return prev;
              // }

              return Object.assign({}, prev, {
                allActivities: [
                  ...allActivities,
                  ...(fetchMoreResult ? fetchMoreResult.allActivities : []),
                ],
              });
            },
          });
        }}
      />
    </Box>
  );
};

export default GlobalActivityStream;
