import React, { useRef } from "react";

import { pathOr } from "ramda";

import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import { Address } from "../../../generated/graphql";
import uuid from "../../../utils/uuid";
import { isValidAddress } from "../../../utils/getAddressDistance";

type TAddressItem = {
  address: Address;
};

const AddressItem = ({ address }: TAddressItem) => {
  const key = useRef(uuid());
  const street: string = pathOr("", ["street"], address);
  const city: string = pathOr("", ["city"], address);
  const zip: string = pathOr("", ["zip"], address);
  const lat: string = pathOr("", ["lat"], address);
  const lon: string = pathOr("", ["lon"], address);
  const mapsUrl = `http://maps.google.com/maps?q=loc:${lat},${lon}`;
  // const mapsUrl = `https://maps.google.com/?ll=${lat},${lon}`;
  return (
    <Typography key={key.current} variant="h4">
      {isValidAddress(address) ? (
        <Link href={mapsUrl} target="_blanck" color="primary" underline="always">
          {street.length > 0 ? (
            <>
              {street}
              <br />
            </>
          ) : (
            ""
          )}
          {zip.length
            ? `${zip}${city.length ? ` ${city}` : ""}`
            : `${city.length > 0 ? city : "-"}`}
        </Link>
      ) : (
        <>
          {street.length > 0 ? (
            <>
              {street}
              <br />
            </>
          ) : (
            ""
          )}
          {zip.length
            ? `${zip}${city.length ? ` ${city}` : ""}`
            : `${city.length > 0 ? city : "-"}`}
        </>
      )}
    </Typography>
  );
};

export default AddressItem;
