import React, { FormEvent, ReactText, useState } from "react";
import Box from "@material-ui/core/Box";
import { Formik } from "formik";
import {
  Service,
  Person,
  useCreateServiceAttributeMutation,
  useUpdateServiceAttributeMutation,
} from "../../../../generated/graphql";
import useAutoSave from "../../../../hooks/useAutoSave";
import PrimaryButton from "../../../atoms/PrimaryButton";
import SlateRTE, { initialFormValue } from "../../../molecules/SlateRTE";
import useStyles from "../styles";
import WizardNavigation from "../../../molecules/WizardNavigation";
import SecondaryButton from "../../../atoms/SecondaryButton";

interface Props {
  backAction: () => void;
  service: Service;
  person: Person;
  serviceMetaAttribute: any;
  setStep: (step: number) => void;
  step: number;
  update: () => void;
}

const Form = ({
  classes,
  autoSave,
  handleSubmit,
  handleChange,
  label,
  placeholder,
  value,
  values,
}: any) => {
  const timer = useAutoSave(values, autoSave);
  const submit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    handleSubmit(event);
  };
  return (
    <form onSubmit={submit} onBlur={submit} className={classes.form}>
      <SlateRTE
        handleChange={handleChange}
        label={label}
        placeholder={placeholder || undefined}
        tabIndex={1}
        value={value || initialFormValue}
      />
    </form>
  );
};

export default function TextInput({
  serviceMetaAttribute,
  service,
  setStep,
  step,
  update,
  backAction,
}: Props) {
  const classes = useStyles();
  const [isSaveValue, setIsSaveValue] = useState(true);
  const [creating, setCreating] = useState(false);
  const [createServiceAttribute, { loading }] = useCreateServiceAttributeMutation();
  const [updateServiceAttribute] = useUpdateServiceAttributeMutation();
  const next = () => {
    setStep(step + 1);
  };
  let attributes = service?.serviceAttributes || [];
  const attribute = attributes.find(
    attr => attr?.serviceMetaAttributeId === serviceMetaAttribute.id,
  );
  const [currentValue, setCurrentValue] = useState(attribute?.value || "");
  const handleSave = (
    values: {
      [key: string]: string | number | null | undefined | ReactText;
    },
    callback?: () => void,
  ) => {
    if (!!attribute) {
      return updateServiceAttribute({
        variables: {
          serviceAttribute: {
            value: values.value ? String(values.value) : "",
            id: attribute.id,
            serviceId: service.id,
          },
        },
      }).then(({ data }) => {
        setIsSaveValue(data?.updateServiceAttribute?.value === currentValue);
        try {
          update();
        } catch (error) {
          console.log(error);
        }
      });
    } else if (!loading && !creating) {
      setCreating(true);
      return createServiceAttribute({
        variables: {
          serviceAttribute: {
            serviceMetaAttributeId: serviceMetaAttribute.id,
            value: values.value ? String(values.value) : "",
            serviceId: service.id,
          },
        },
      }).then(({ data }) => {
        setCreating(false);
        setIsSaveValue(data?.createServiceAttribute?.value === currentValue);
        try {
          update();
        } catch (error) {
          console.log(error);
        }
      });
    }
  };
  return (
    <>
      <Box display="flex" justifyContent="center">
        <Formik
          initialValues={{
            value: !!attribute ? attribute.value : "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleSave(values, () => setSubmitting(false));
          }}
        >
          {({ handleSubmit, setFieldValue, values }) => {
            return (
              <Form
                classes={classes}
                autoSave={handleSave}
                handleSubmit={handleSubmit}
                handleChange={(val: any) => {
                  setIsSaveValue(attribute?.value === val);
                  setFieldValue("value", val);
                  setCurrentValue(val);
                }}
                label={serviceMetaAttribute.label}
                placeholder={serviceMetaAttribute.placeholder || undefined}
                value={values.value || ""}
                values={values}
              />
            );
          }}
        </Formik>
      </Box>
      <WizardNavigation>
        <SecondaryButton onClick={() => backAction()} tabIndex={3}>
          Zurück
        </SecondaryButton>
        <PrimaryButton
          disabled={
            !isSaveValue ||
            (serviceMetaAttribute.required &&
              (!attribute || (!!attribute.value && attribute.value.length <= 2)))
          }
          onClick={next}
          tabIndex={2}
        >
          Weiter
        </PrimaryButton>
      </WizardNavigation>
    </>
  );
}
