import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { useDebounce } from "use-debounce";

import {
  useBusinessObjectQuery,
  useMandatesQuery,
  CounselingMandate,
  Person,
  PersonActivity,
} from "../../../generated/graphql";
import Loader from "../../atoms/Loading";
import BusinessMemoCreateForm from "../../forms/BusinessMemoCreateForm";
import { fetchPolicy } from "../../../utils/constants";
import useStyles from "./styles";

export default function BusinessMemoCreation() {
  const classes = useStyles();
  const { businessId } = useParams<{ businessId: string }>();
  const { state }: { state: { activity: PersonActivity } } = useLocation();
  const { data, refetch } = useBusinessObjectQuery({
    variables: { id: parseInt(businessId, 10) },
    fetchPolicy,
  });
  const { data: mandatesData, loading: mLoading } = useMandatesQuery({
    variables: { businessId: parseInt(businessId, 10) },
    fetchPolicy,
  });

  const activity = state?.activity;
  const business = data?.businessObject;
  const fallBackPerson = business?.persons?.length ? (business?.persons[0] as Person) : null;
  const mandates = (mandatesData?.mandates || []) as CounselingMandate[];
  const [loading, setLoading] = useState(true);

  const [businessDebounce] = useDebounce(business, 500);
  useEffect(() => {
    if (businessDebounce) {
      setLoading(!!business);
    }
  }, [businessDebounce, business]);

  return mLoading && loading && !business ? (
    <Loader />
  ) : business && fallBackPerson ? (
    <Grid container className={classes.content}>
      <Grid item xs={2} xl={3} />
      <Grid item xs={8} xl={6}>
        <BusinessMemoCreateForm
          business={business}
          activity={activity}
          refetch={refetch}
          mandates={mandates}
          fallBackPerson={fallBackPerson}
        />
      </Grid>
      <Grid item xs={2} xl={3} />
    </Grid>
  ) : (
    <div>Partner konnte nicht geladen werden</div>
  );
}
