import { Hour, Maybe } from "../generated/graphql";
import dayjs from "dayjs";

export function formatGroupedHours(
  groupedHours: {
    days: any[];
    close: any;
    open: any;
  }[],
) {
  return groupedHours.map(collected => {
    collected.days.sort((a, b) => {
      return a - b;
    });

    if (collected.days.length < 2) {
      const open = dayjs(collected.open).format("H:mm");
      const close = dayjs(collected.close).format("H:mm");
      const day = dayjs()
        .day(collected.days[0] + 1)
        .format("dd.");

      return `${day} ${open} - ${close} Uhr`;
    }
    const isConsecutive = collected.days.reduce((carry, item, index) => {
      if (index >= collected.days.length - 1) {
        return carry;
      }

      return carry && collected.days[index + 1] - item === 1;
    }, true);

    const open = dayjs(collected.open).format("H:mm");
    const close = dayjs(collected.close).format("H:mm");

    if (isConsecutive) {
      return (
        [
          dayjs()
            .day(collected.days[0] + 1)
            .format("dd."),
          dayjs()
            .day(collected.days[collected.days.length - 1] + 1)
            .format("dd."),
        ].join(collected.days.length > 2 ? " - " : ", ") + ` ${open} - ${close} Uhr`
      );
    } else {
      return (
        collected.days
          .map(day =>
            dayjs()
              .day(day + 1)
              .format("dd."),
          )
          .join(", ") + ` ${open} - ${close} Uhr`
      );
    }
  });
}

export function groupHours(hours: Maybe<Hour>[]) {
  const init: { days: any[]; close: any; open: any }[] = [];

  return hours.reduce((carry, hour) => {
    if (!hour) {
      return carry;
    }

    const index = carry.findIndex((item: Hour) => {
      return (
        dayjs(item.close).isSame(dayjs(hour.close)) && dayjs(item.open).isSame(dayjs(hour.open))
      );
    });

    if (index > -1) {
      carry[index].days.push(hour.day);
    } else {
      carry.push({
        days: [hour.day],
        open: hour.open,
        close: hour.close,
      });
    }

    return carry;
  }, init);
}
