import React, { FC } from "react";

import { useParams } from "react-router-dom";

import useBusinessSubscription from "../../../hooks/useBusinessSubscription";
import Email from "../../templates/Email";

const EmailThread: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { businessObject } = useBusinessSubscription(parseInt(id, 10));

  return <Email context={{ businessObject }} />;  
};

export default EmailThread;
