import React from "react";

import * as Yup from "yup";
import { Form, Formik, FormikProps, Field } from "formik";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

import SecondaryButton from "../../../atoms/SecondaryButton";
import AsyncSearch from "../../../molecules/AsyncSearch";

import {
  Account,
  Gender,
  useUpdatePersonAgeAndGenderMutation,
} from "../../../../generated/graphql";
import PrimaryButton from "../../../atoms/PrimaryButton";

import GenderSelect from "../../../molecules/GenderSelect";

import { usePersonCreateContext } from "../provider";
import { STEPS } from "../reducer";

const validationSchema = Yup.object().shape({
  initialAge: Yup.number()
    .min(0)
    .required("Bitte angeben"),
  account: Yup.object().required("Bitte angeben"),
});

interface FormValues {
  account?: Account;
  gender: Gender;
  initialAge: string;
}

const initialValues = {
  account: undefined,
  gender: Gender.unknown,
  initialAge: "",
};

type TAccountAssign = {
  personId?: number | null;
};

const AccountAssign = (props: TAccountAssign) => {
  const { name, setAccount, setStep } = usePersonCreateContext();
  const [updatePersonAgeAndGender] = useUpdatePersonAgeAndGenderMutation();

  const onSubmit = async (values: FormValues) => {
    if (props.personId) {
      await updatePersonAgeAndGender({
        variables: {
          id: props.personId,
          initialAge: parseInt(values.initialAge, 10),
          gender: values.gender,
        },
      });
    }

    if (values.account) {
      setAccount(values.account);
    }
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {(formikProps: FormikProps<FormValues>) => {
        return (
          <Form style={{ width: "100%" }}>
            <Box width={1} flex={1}>
              <Box mb={8}>
                <Typography variant="h1" align="center">
                  {name} anlegen
                  <br />
                </Typography>
              </Box>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <AsyncSearch.AccountSearch
                  onClick={(account: Account) => {
                    formikProps.setFieldValue("account", account);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  <Field
                    type="number"
                    name="initialAge"
                    id="initialAge"
                    label="Alter (ggf. geschätzt)"
                    component={TextField}
                    inputProps={{
                      min: 0,
                      onChange: (event: any) => {
                        formikProps.setFieldValue("initialAge", event.target.value);
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <GenderSelect />
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="center" mt={17}>
              <SecondaryButton
                onClick={() => {
                  setStep(STEPS.TYPE);
                }}
              >
                Zurück
              </SecondaryButton>
              <Box mx={1} />
              <PrimaryButton disabled={!formikProps.isValid} type="submit">
                Weiter
              </PrimaryButton>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AccountAssign;
