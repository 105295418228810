import React, { FC, useState } from "react";

import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { groupBy } from "ramda";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import MuLink from "@material-ui/core/Link";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import ActivitiesTemplate from "../../templates/Activities";
import Loader from "../../atoms/Loading";
import ActivityItem from "../../molecules/ActivitiesItem";

import { ServiceStatus, AllServicesQuery } from "../../../generated/graphql";
import { useAllServicesSubscription } from "../../../hooks/useAllServicesSubscription";

import { sortByDueDate, sortByLastUpdate } from "../../../utils/serviceHelper";
import empty from "../../assets/empty.svg";
import useStyles from "./styles";

const ActivitesServices: FC = () => {
  return (
    <ActivitiesTemplate>
      <ActivitesServicesContainer />
      <Finished />
    </ActivitiesTemplate>
  );
};

export default ActivitesServices;

const ActivitesServicesContainer = () => {
  const variables = {
    statusNot: [ServiceStatus.finished, ServiceStatus.counseling],
  };
  const { data, loading, error } = useAllServicesSubscription(variables);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    console.error(error);
  }

  if (!data?.services) {
    return null;
  }

  return <Active services={data.services} />;
};

const Active = ({ services }: { services: AllServicesQuery["services"] }) => {
  // TODO UGLY QUICKFIX add visibility groups to service meta in future
  const lmServices = services.filter(service => {
    const serviceMetaId = service?.serviceMeta?.id;
    if (!serviceMetaId) {
      return false;
    }
    // 1 internal, 2 external, 26 paartherapie, 27 psycho
    return ![1, 2, 26, 27].includes(serviceMetaId);
  });

  if (lmServices.length === 0) {
    return (
      <Box mt={10} textAlign="center">
        <img src={empty} alt="Es gibt derzeit keine Services" />
        <Box my={2} />
        <Typography variant="body2">Es gibt derzeit keine Services</Typography>
      </Box>
    );
  }

  const grouped = groupBy(service => service.status || "undefined", lmServices);
  // TODO!: Unify ServiceStatus translations somehwere
  return (
    <>
      {grouped[ServiceStatus.research] ? (
        <ServicesByStatus services={grouped[ServiceStatus.research]} title="In Recherche" />
      ) : null}
      {grouped[ServiceStatus.ready] ? (
        <ServicesByStatus services={grouped[ServiceStatus.ready]} title="Bereit zur Rückmeldung" />
      ) : null}
    </>
  );
};

const ServicesByStatus = ({
  services,
  title,
}: {
  services: AllServicesQuery["services"];
  title: string;
}) => {
  const classes = useStyles();

  return (
    <Box pb={2}>
      <Box mt={3} mb={2}>
        <Typography variant="h3">{title}</Typography>
      </Box>
      <Box className={classes.group}>
        <RenderServices services={services} />
      </Box>
    </Box>
  );
};

const Finished = () => {
  const classes = useStyles();
  const [expand, setExpand] = useState(false);
  const variables = {
    statusNot: [
      ServiceStatus.started,
      ServiceStatus.research,
      ServiceStatus.ready,
      ServiceStatus.counseling,
    ],
  };

  const { data, error } = useAllServicesSubscription(variables);

  if (error) {
    console.error(error);
  }

  if (!data?.services) {
    return null;
  }

  return (
    <Box mt={3}>
      <Typography variant="h3">Fertig</Typography>
      <Box className={classes.group} mt={2}>
        <Box
          py={3}
          textAlign="center"
          onClick={() => setExpand(!expand)}
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.expand}
        >
          {expand ? (
            <KeyboardArrowUpIcon color="primary" />
          ) : (
            <KeyboardArrowDownIcon color="primary" />
          )}
          <Box mx={1}></Box>
          <Typography color="primary" variant="h4">
            Abgeschlossene Services
          </Typography>
        </Box>
        {expand && <RenderServices services={data.services} />}
      </Box>
    </Box>
  );
};

const RenderServices = ({ services }: { services: AllServicesQuery["services"] }) => {
  // Duedate wins, with second priority sort by lastActivity
  const serviceList = [...services.sort(sortByLastUpdate).sort(sortByDueDate)];

  return (
    <>
      {serviceList.map(service => {
        const person = service?.client?.person;
        const topic = service?.topic?.name;
        const name = service?.serviceMeta?.name;
        const tasks = service.personActivity?.tasks
        const sortedTasks = tasks?.map(e => e).sort((a: any, b: any) => new Date(a.created) >= new Date(b.created) ? -1 : 1)       
        const avatar = sortedTasks ? {
          src:
            sortedTasks.length ? (sortedTasks[0]?.user?.person?.picture ||
            sortedTasks[0]?.user?.person?.avatar): "",

          keys: sortedTasks[0]?.user?.person?.avatarKeys,
        }: {
          src: "",
          key: ""
        };
        // Do not hide empty names (might be reject reason). Could be fixed after database clean up
        if (!person || topic === null || topic === undefined || !name) {
          return null;
        }

        return (
          <ActivityItem
            key={String(service!.id)}
            person={person}
            account={service?.client?.account}
            avatar={avatar}
            title={
              <MuLink
                component={Link}
                to={`/service/${service!.id}`}
                color="primary"
                underline="always"
                variant="h4"
              >
                {`${topic}, ${name}`}
              </MuLink>
            }
            info={
              <Box display="flex">
                <Box flex={1}>
                  {service?.duedate && (
                    <Typography variant="body2">
                      Fälligkeit: {dayjs(service?.duedate).format("DD.MM.YYYY")}
                    </Typography>
                  )}
                </Box>
                <Box mx={1.5} />
                <Box flex={1}>
                  <Typography variant="body2">
                    Letzte Aktivität: {dayjs(service?.lastActivity).format("DD.MM.YYYY")}
                  </Typography>
                </Box>
              </Box>
            }
          />
        );
      })}
    </>
  );
};
