import React from "react";
import DialogForm from "../DialogForm";
import Grid from "@material-ui/core/Grid";
import SlateRTE, { initialFormValue } from "../../molecules/SlateRTE";
import { Field, FieldProps } from "formik";
import { Service, useUpdateServiceMutation } from "../../../generated/graphql";

type TServiceExtendedInfoDialog = {
  service: Service;
  open: boolean;
  toggle: () => void;
};

const ServiceExtendedInfoDialog = ({ service, open, toggle }: TServiceExtendedInfoDialog) => {
  const [update] = useUpdateServiceMutation();
  return (
    <DialogForm
      open={open}
      initialValues={{
        extendedInfo: service.extendedInfo || initialFormValue,
      }}
      onClose={toggle}
      title="Allgemeine Informationen bearbeiten"
      onSubmit={values => {
        return update({
          variables: {
            id: service.id!,
            service: {
              extendedInfo: values.extendedInfo,
            },
          },
        }).then(() => {
          toggle();
        });
      }}
    >
      <Grid container spacing={2}>
        <Field
          name="extendeInfo"
          render={({ form: { setFieldValue, values } }: FieldProps) => (
            <SlateRTE
              value={values.extendedInfo}
              handleChange={input => setFieldValue("extendedInfo", input)}
              label="Informationen"
              tabIndex={1}
            />
          )}
        />
      </Grid>
    </DialogForm>
  );
};

export default ServiceExtendedInfoDialog;
