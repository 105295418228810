import React from "react";
import classnames from "classnames";
import { useHistory, useParams } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

import Loader from "../../atoms/Loading";
import { useAuthorization } from "../../../providers/AuthorizationProvider";
import Header from "./Header";
import Pool from "./pool";
import List from "../../organisms/InboxLists";
import useStyles from "../inboxStyles";
import InboxLabelFilter from "../../molecules/InboxLabelFilter";
import { useCurrentTasksSubscription } from "../../../hooks/useCurrentTasksSubscription";
import { useCurrentTaskCountSubscription } from "../../../hooks/useCurrentTaskCountSubscription";
import { useMyAppointmentsSubscription } from "../../../hooks/useMyAppointmentsSubscription";
import PrimaryButton from "../../atoms/PrimaryButton";
import IllustratedErrorMessage from "../../organisms/IllustratedErrorMessage";

import errorIllustration from "../../assets/errorIllustration.svg";

const Inbox = () => {
  const classes = useStyles();

  const { filter } = useParams<{ filter: string }>();
  const { goBack } = useHistory();

  const {
    appointments,
    loading: appointmentsLoading,
    error: appointmentsError,
  } = useMyAppointmentsSubscription();

  const { tasks: pool, error: poolError, loading: poolLoading } = useCurrentTaskCountSubscription({
    pool: true,
  });

  const unassigned = filter === "unassigned";

  const { me } = useAuthorization();

  const { tasks, loading: tasksLoading, error: tasksError } = useCurrentTasksSubscription({
    pool: false,
    unassigned,
  });

  if (appointmentsLoading || poolLoading || tasksLoading) {
    return <Loader />;
  }

  if (appointmentsError || poolError || tasksError || !me) {
    console.error({ appointmentsError, poolError, tasksError });

    return (
      <IllustratedErrorMessage
        messages={["Die Inbox konnte nicht geladen werden."]}
        illustration={errorIllustration}
        illustrationAltText="Elli rutscht auf einer Banane aus"
      >
        <PrimaryButton onClick={goBack}>Zurück</PrimaryButton>
      </IllustratedErrorMessage>
    );
  }
  return (
    <Grid container className={classnames(classes.content, classes.contentBox)}>
      <InboxLabelFilter scope="inbox" />
      <Header me={me} tasks={tasks} appointments={appointments} isUnassigned={unassigned} />
      <Grid container>
        <Grid item xs={12}>
          {filter !== "unassigned" && <List.AppointmentList appointments={appointments} />}
          <List.TaskList
            tasks={tasks}
            showPlaceholder={!tasks.length && !appointments.length}
            isInbox
          />
          <Pool tasks={pool} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Inbox;
