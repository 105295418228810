import React, { FormEvent } from "react";
import { Formik, FormikProps, FormikValues, Field } from "formik";
import { useMutation } from "@apollo/client";
import { ExecutionResult } from "graphql";
import useAutoSave from "../../../hooks/useAutoSave";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import useStyles from "../formStyles";

import { UpdateServiceDocument, ServiceStatus, Service } from "../../../generated/graphql";

export default function StatusForm({
  service,
  hidePlaning = false,
}: {
  service: Service;
  hidePlaning?: boolean;
}) {
  const [update] = useMutation(UpdateServiceDocument);
  const submit = (values: any) => {
    return update({
      variables: {
        service: {
          status: values.status,
        },
        id: service.id,
      },
    });
  };
  return (
    <Formik
      initialValues={{
        status: service.status || ServiceStatus.counseling,
      }}
      onSubmit={(values, { setSubmitting }) => {
        submit(values).then(() => {
          setSubmitting(false);
        });
      }}
    >
      {(props: FormikProps<FormikValues>) => (
        <FormComp {...props} handleSave={submit} hidePlaning={hidePlaning} />
      )}
    </Formik>
  );
}

function FormComp({
  values,
  setFieldValue,
  handleSave,
  handleSubmit,
  hidePlaning,
}: {
  handleSave: (values: any) => Promise<ExecutionResult<any>>;
  hidePlaning: boolean;
} & FormikProps<FormikValues>) {
  const classes = useStyles();
  const timer = useAutoSave(values, handleSave);

  const submit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    handleSubmit(event);
  };

  return (
    <form onSubmit={submit}>
      <Field>
        {({ field }: any) => (
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="status"
              name="status"
              value={values.status}
              onChange={(e: any, value: string) => {
                setFieldValue("status", value);
              }}
              row
              tabIndex={1}
            >
              {!hidePlaning && (
                <FormControlLabel
                  value={ServiceStatus.started}
                  control={<Radio />}
                  label="In Planung"
                  classes={{ root: classes.radioRoot }}
                />
              )}
              <FormControlLabel
                value={ServiceStatus.counseling}
                control={<Radio />}
                label="In Beratung"
                classes={{ root: classes.radioRoot }}
              />
              <FormControlLabel
                value={ServiceStatus.finished}
                control={<Radio />}
                label="Abgeschlossen"
                classes={{ root: classes.radioRoot }}
              />
            </RadioGroup>
          </FormControl>
        )}
      </Field>
    </form>
  );
}
