import React from "react";

import Markdown from "react-markdown";
import { ReadOnlyEditor } from "../../molecules/SlateRTE";
import useStyles from "./styles";

type TMarkup = { value: string };

const Markup = ({ value }: TMarkup) => {
  const classes = useStyles();
  try {
    JSON.parse(value);
    return <ReadOnlyEditor value={value} />;
  } catch (err) {
    return <Markdown source={value} className={classes.root} />;
  }
};

export default Markup;
