import React from "react";

/**
 * Used in:
 *  - Person: No topic
 *  - Case Management: Not reviewed
 */
const GirlWithPencil = () => {
  return (
    <svg
      width="121"
      height="160"
      viewBox="0 0 121 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M116.498 100.158C112.831 103.806 121.999 103.291 119.249 107.025C116.498 110.76 110.997 109.76 108.098 108.026C105.199 106.291 103.435 103.057 105.183 100.16C106.932 97.2625 120.166 96.51 116.498 100.158Z"
        stroke="#025879"
      />
      <path
        d="M72.5393 45.1888C71.5266 45.1888 70.7056 44.3726 70.7056 43.3659C70.7056 42.3591 71.5266 41.543 72.5393 41.543C73.5521 41.543 74.373 42.3591 74.373 43.3659C74.373 44.3726 73.5521 45.1888 72.5393 45.1888Z"
        fill="#025879"
      />
      <path
        d="M59.846 39.783C58.8332 39.783 58.0123 38.9669 58.0123 37.9601C58.0123 36.9534 58.8332 36.1372 59.846 36.1372C60.8587 36.1372 61.6797 36.9534 61.6797 37.9601C61.6797 38.9669 60.8587 39.783 59.846 39.783Z"
        fill="#025879"
      />
      <path
        d="M70.1394 115.482C73.6248 124.862 75.0293 130.871 74.3527 133.511C71.8107 143.427 67.8075 156.817 68.912 157.85C71.2055 159.994 82.5137 158.994 82.5137 156.047C82.5137 153.099 77.9503 151.916 75.6186 152.441C74.0641 152.791 71.8286 153.698 68.912 155.163"
        stroke="#025879"
      />
      <path
        d="M51.6621 111.13C57.2693 124.907 58.4073 132.791 55.0762 134.781C50.0795 137.765 31.2851 138.175 30.1871 140.859C29.0891 143.543 34.193 151.987 36.4919 151.29C38.7909 150.593 38.0875 144.929 35.2846 139.166"
        stroke="#025879"
      />
      <path
        d="M56.2523 72.6241C47.1664 77.0951 33.397 76.3384 29.2969 73.8248C26.0051 71.8067 20.9358 66.1229 14.0889 56.7734C7.06696 55.3534 2.93407 53.6885 1.69029 51.7788C-0.175392 48.9142 2.83873 43.644 5.17007 43.6023C7.50142 43.5606 9.86234 48.7347 11.6108 48.7035C13.3594 48.6722 15.8244 45.2794 17.0215 46.9968C17.8196 48.1417 16.8421 51.4006 14.0889 56.7734"
        stroke="#025879"
      />
      <path d="M76.1666 67.7075C84.9322 86.3372 94.6045 97.455 105.184 101.061" stroke="#025879" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.786 57.7891C56.105 63.5841 73.3987 65.065 83.2437 53.2819C93.0887 41.4988 63.2162 29.7801 63.2162 19.9287C57.6118 25.5949 53.8381 28.8143 51.895 29.587C48.9803 30.746 53.0609 21.474 50.1462 23.2125C47.2316 24.951 42.309 32.8064 41.4022 39.4384C40.4955 46.0704 41.467 51.9942 48.786 57.7891Z"
        stroke="#025879"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.0236 67.7064C97.0236 65.3884 87.9558 61.3964 95.21 48.7762C102.464 36.1561 98.4486 22.1653 93.3965 15.423C88.6683 9.11294 81.881 3.23743 76.1676 1.90144C73.5837 1.29723 71.6364 4.24482 68.9134 4.60575C64.6386 2.20192 61.9182 1 60.7524 1C59.0036 1 51.6846 9.11294 43.5235 13.6201C35.3625 18.1273 32.6422 32.5503 35.3625 43.3676C38.0829 54.1848 29.0151 57.5974 29.0151 60.4949C29.0151 63.3924 38.9896 69.5093 59.8456 71.3122C80.7016 73.115 97.0236 70.0244 97.0236 67.7064Z"
        stroke="#025879"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.4091 63.1997L76.8498 64.1011C75.6408 71.3127 76.5476 76.7213 79.5702 80.327C79.5702 99.5577 83.1973 113.079 90.4515 120.892C93.0368 123.676 72.6252 120.821 62.3413 119.99C52.0575 119.159 33.3244 122.695 36.9515 118.027C42.4377 110.967 46.6693 97.1985 49.6464 76.7213C55.0871 71.3127 52.579 65.3833 51.7803 63.1997L57.8074 64.1011C60.2255 68.3079 62.9459 70.4112 65.9685 70.4112C68.9911 70.4112 70.8046 68.0074 71.4091 63.1997Z"
        fill="#A6C4CE"
        stroke="#025879"
        strokeLinejoin="round"
      />
      <path
        d="M74.3535 51.3509C68.3083 54.9566 62.8676 55.8581 58.0315 54.0552C53.1953 52.2523 50.2375 48.6895 49.158 43.3667"
        stroke="#025879"
        strokeLinecap="round"
      />
      <path
        d="M86.244 30.7505C90.9075 38.2839 89.5473 50.2602 80.4795 56.5703"
        stroke="#025879"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.7949 82.134C72.5407 84.3824 62.5661 83.481 49.8712 79.4297L48.0576 87.9934C58.6367 93.1015 69.2158 93.8527 79.7949 90.2469V82.134Z"
        fill="#CCCCCC"
        stroke="#025879"
      />
      <path
        d="M7.2499 52.7264C7.13894 53.1381 7.39156 53.573 7.79788 53.6699L10.2824 54.3318C10.683 54.4498 11.1206 54.1987 11.2315 53.787L11.7589 51.8515L7.77727 50.791L7.2499 52.7264ZM15.2032 39.052L13.7458 36.5426L11.2215 37.9914L8.07884 49.6721L12.0605 50.7327L15.2032 39.052Z"
        fill="#242D31"
      />
    </svg>
  );
};

export default GirlWithPencil;
