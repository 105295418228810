import {
  UserFromPersonSubscriptionDocument,
  UserFromPersonSubscriptionSubscription,
  UserSubscriptionDocument,
  UserSubscriptionSubscription,
  useUserFromPersonQuery,
  useUserQuery,
} from "../generated/graphql";
import { useEffect } from "react";
import { FetchPolicy } from "@apollo/client";

export const useUserSubscription = (id: number) => {
  const { data, loading, subscribeToMore } = useUserQuery({
    variables: {
      id,
    },
  });

  useEffect(() => {
    if (id) {
      const unsubscribe = subscribeToMore<UserSubscriptionSubscription>({
        document: UserSubscriptionDocument,
        variables: { id },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data || !prev) return prev;

          return {
            ...prev,
            ...{ user: subscriptionData.data.userSubscription },
          };
        },
      });

      return unsubscribe;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { user: data?.user, loading };
};

export const useUserFromPersonSubscription = (personId: number, fetchPolicy?: FetchPolicy) => {
  const { data, loading, subscribeToMore } = useUserFromPersonQuery({
    variables: {
      personId,
    },
    fetchPolicy,
  });

  useEffect(() => {
    if (personId) {
      const unsubscribe = subscribeToMore<UserFromPersonSubscriptionSubscription>({
        document: UserFromPersonSubscriptionDocument,
        variables: { personId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data || !prev) return prev;

          return {
            ...prev,
            ...{ userFromPerson: subscriptionData.data.userFromPersonSubscription },
          };
        },
      });

      return unsubscribe;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personId]);

  return { user: data?.userFromPerson, loading };
};
