import React from "react";

import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { orderBy } from "lodash";

import {
  useReportActivityQuery,
  useBusinessInteractionsQuery,
  useGetOneThreadByActivityQuery,
  GMailMessage,
  useBreadCrumbInfoByAccountIdQuery,
} from "../../../generated/graphql";
import { fetchPolicy } from "../../../utils/constants";
import Loader from "../../atoms/Loading";
import BusinessHeader from "../../molecules/BusinessHeader";
import MemoDetails from "../../organisms/MemoDetails";
import ActivityInfo from "../../molecules/ActivityInfo";

import EmailThreadMessages from "../../organisms/EmailThreadMessages";

import { ActivityHeader, Pagination } from "./components";
import AccountStatusBadge from "../../molecules/AccountStatusBadge";

export default function Activity() {
  const { id, businessId } = useParams<{ id: string; businessId: string }>();
  const activitiesQuery = useBusinessInteractionsQuery({
    variables: {
      businessId: parseInt(businessId, 10),
    },
    fetchPolicy,
  });  
  const { data, loading } = useReportActivityQuery({
    variables: {
      id: parseInt(id, 10),
      loadReportData: true,
    },
    fetchPolicy,
  });  
  // TODO - breadcrumb - we don't have the accountId or any Ids
  const accountId = parseInt(id, 10);
  const { data: breadcrumbData } = useBreadCrumbInfoByAccountIdQuery({
    skip: !accountId,
    variables: {
      id: accountId!,
    },
  });
  if (loading) return <Loader />;
  const activity = data?.personActivity;
  const person = activity?.person;
  const topic = activity?.topic;
  if (!activity || !person) return <></>;
  const name = "Gesprächsnotiz";
  const mainPath = activity.memo?.business?.accountId
    ? [
        { path: "/account", name: "Accounts" },
        {
          path: `/account/${activity.memo?.business?.accountId}`,
          name: activity.memo?.business?.name,
          chip: (
            <AccountStatusBadge
              contractEnd={breadcrumbData?.breadCrumbInfoByAccountId?.contractEnd}
              lang={breadcrumbData?.breadCrumbInfoByAccountId?.language || ""}
            />
          ),
        },
      ]
    : [{ path: `/business/${activity.memo?.business?.id}`, name: activity.memo?.business?.name }];
  // TODO: this is weird, probably an unnoticed UI bug
  const mapList = [
    ...mainPath,
    { path: `/business/${activity.memo?.business?.id}`, name: activity.memo?.business?.name },
    { path: "", name },
  ];
  const list = (activitiesQuery?.data?.businessInteractions || []).filter(p => !!p?.doneAt);
  return (
    <>
      <BusinessHeader mapList={mapList} contractExpirationDate={breadcrumbData?.breadCrumbInfoByAccountId?.contractEnd} />
      <Box mt={20} mb={11}>
        <Grid container>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <ActivityHeader
              person={person}
              activity={activity}
              topic={topic || undefined}
            ></ActivityHeader>
            {!!activity.memo && <MemoDetails memo={activity.memo} />}
            {// Due to refactoring: https://medium.com/@calebmer/when-to-use-graphql-non-null-fields-4059337f6fc8
            activity.activityType && (
              <ActivityHistory
                activityType={activity.activityType}
                personActivityId={activity.id}
              />
            )}
            {activity && <ActivityInfo activity={activity} />}
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Box>
      <Pagination list={list} name={name} />
    </>
  );
}

type TActivityHistory = {
  activityType: string;
  personActivityId: number;
};

const ActivityHistory = ({ activityType, personActivityId }: TActivityHistory) => {  
  switch (activityType) {
    case "mail":
    case "wpoVerweisung":
      return <ActivityHistoryMail personActivityId={personActivityId} />;
    default:
      return null;
  }
};

type TActivityHistoryMail = {
  personActivityId: number;
};

const ActivityHistoryMail = ({ personActivityId }: TActivityHistoryMail) => {
  const { data } = useGetOneThreadByActivityQuery({
    variables: {
      personActivityId,
    },
  });

  return (
    <>
      <Box mt={5}>
        <Typography variant="h3">E-Mail</Typography>
        <Box borderTop={1} borderColor="grey.400"></Box>
      </Box>
      <EmailThreadMessages
        emails={orderBy((data?.getOneThreadByActivity || []) as GMailMessage[], "date")}
      />
    </>
  );
};
