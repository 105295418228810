import React from "react";

import { useLocation } from "react-router-dom";
import { Grid, Chip } from "@material-ui/core";
import MuLink from "@material-ui/core/Link";
import { Link } from "react-router-dom";

import { useAuthorization } from "../../../providers/AuthorizationProvider";

import useStyles from "./styles";

type TFilterLabel = {
  title: string;
  path: string;
  rule: string;
};

type TLabelFilter = {
  filterLabels: TFilterLabel[];
};

const LabelFilter = ({ filterLabels }: TLabelFilter) => {
  const classes = useStyles();
  const { isAuthorized } = useAuthorization();

  const { pathname } = useLocation();

  return (
    <Grid container className={`${classes.container}`}>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ position: "relative" }}
        >
          {filterLabels
            .filter(label => {
              return isAuthorized(label.rule);
            })
            .map(label => {
              if (pathname === label.path) {
                return (
                  <Chip
                    key={label.title}
                    label={label.title}
                    color={"default"}
                    classes={{ root: classes.chip, label: classes.chipLabel }}
                  />
                );
              }

              return (
                <MuLink
                  className={`${classes.filterLabel}`}
                  color="primary"
                  component={Link}
                  key={label.title}
                  title={label.title}
                  to={label.path}
                  underline="always"
                  variant="h3"
                >
                  {label.title}
                </MuLink>
              );
            })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LabelFilter;
