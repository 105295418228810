import React, { FC } from "react";
import { Link as RouterLink } from "react-router-dom";

import { Theme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/styles/withStyles/withStyles";
import Link from "@material-ui/core/Link/Link";
import { CalendarToday } from "@material-ui/icons";
import Assignment from "@material-ui/icons/Assignment";
import { Person as PersonIcon } from "@material-ui/icons";

import dayjs from "dayjs";

import { Appointment, Person, Maybe, User } from "../../../../generated/graphql";
import { getUserName } from "../../../../utils/userNameHelper";
import TopicOrServiceLink from "../../../atoms/TopicOrServiceLink";
import SecondaryButton from "../../../atoms/SecondaryButton";
import Markup from "../../../atoms/Markup";

export const UserAlias = withStyles((_: Theme) => ({
  root: {
    fontStyle: "italic",
  },
}))(Typography);

type TAppointmentDialogView = {
  appointment: Appointment;
  setToEditable: () => void;
};
const AppointmentDialogView: FC<TAppointmentDialogView> = ({ appointment, setToEditable }) => {
  const clientName = (person: Person) => {
    const name = person.name || "";
    return (
      <Link
        component={RouterLink}
        to={`/person/${person.id}`}
        underline="always"
        color="primary"
        variant="h4"
      >
        {name}
      </Link>
    );
  };

  return (
    <Box width="100%">
      <Box display="flex">
        <Box width="40px" flexShrink={0} textAlign="right">
          <CalendarToday color="primary" fontSize="small" />
        </Box>
        <Box marginX={2}>
          <Typography variant="h4">
            {dayjs(appointment.date).format("dd, D.M.YYYY")}{" "}
            {dayjs(appointment.from).format("H:mm")}
            {" - "}
            {dayjs(appointment.to).format("H:mm")}
            {" Uhr "}
          </Typography>
          <Box padding="4px 0">{!!appointment.person && clientName(appointment.person)}</Box>
          <Typography variant="body2">{appointment.title}</Typography>
          {!!appointment.person && (
            <TopicOrServiceLink
              person={appointment.person}
              topic={appointment.topic}
              service={appointment.service}
            />
          )}
        </Box>
      </Box>

      <Box display="flex" marginTop={2}>
        <Box width="40px" flexShrink={0} textAlign="right">
          <PersonIcon color="primary" fontSize="small" />
        </Box>
        <AppointmentDialogUsers users={appointment.users} />
      </Box>

      {!!appointment.note && appointment.note.trim().length > 1 && (
        <Box>
          <Box display="flex" marginTop={2}>
            <Box width="40px" flexShrink={0} textAlign="right">
              <Assignment color="primary" fontSize="small" />
            </Box>

            <Box marginX={2}>
              <Markup value={appointment.note.trim()} />
            </Box>
          </Box>
        </Box>
      )}

      <Box marginTop={3} textAlign="center">
        <SecondaryButton onClick={setToEditable}>Termin bearbeiten</SecondaryButton>
      </Box>
    </Box>
  );
};

const AppointmentDialogUsers: FC<{ users?: Maybe<User>[] | null }> = ({ users }) => {
  return (
    <Box marginX={2}>
      <Typography variant="body2" component="span">
        {users?.map(user => getUserName(user?.person) || "").join(", ")}
      </Typography>
    </Box>
  );
};
export default AppointmentDialogView;
