import React from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { Select } from "formik-material-ui";
import { Field } from "formik";

type TAccountSessionsSelect = { tabIndex?: number };

const AccountSTCSessionsSelect = ({ tabIndex = 0 }: TAccountSessionsSelect) => {
  return (
    <FormControl fullWidth margin="normal">
      <InputLabel htmlFor="nFreeSTCs">Interne Beratung</InputLabel>
      <Field name="nFreeSTCs" label="Interne Beratung" component={Select} tabIndex={tabIndex}>
        <MenuItem value="0">keine Sitzungen</MenuItem>
        <MenuItem value="1">eine Sitzung</MenuItem>
        <MenuItem value="2">zwei Sitzungen</MenuItem>
        <MenuItem value="3">drei Sitzungen</MenuItem>
        <MenuItem value="4">vier Sitzungen</MenuItem>
        <MenuItem value="5">fünf Sitzungen</MenuItem>
        <MenuItem value="6">sechs Sitzungen</MenuItem>
        <MenuItem value="7">sieben Sitzungen</MenuItem>
        <MenuItem value="8">acht Sitzungen</MenuItem>
        <MenuItem value="9">neun Sitzungen</MenuItem>
        {[...Array(90).keys()].map(number => {
          return (
            <MenuItem key={`session${number}`} value={number + 10}>
              {number + 10} Sitzungen
            </MenuItem>
          );
        })}
      </Field>
    </FormControl>
  );
};

export default AccountSTCSessionsSelect;
