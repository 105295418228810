import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => {
  return {
    arrowForward: {
      height: theme.spacing(2),
      width: theme.spacing(2),
    },
    table: {
      marginBottom: theme.spacing(8),
      tableLayout: "fixed",
    },
    tableCellAddNewItem: {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      color: theme.palette.common.black,
      "&:last-child": {
        padding: "0 10px 0 0",
      },
    },
    tableHeading: {
      alignItems: "center",
      display: "flex",
      marginBottom: theme.spacing(1),
      "&>svg": {
        marginRight: theme.spacing(1),
      },
    },
    iconLinkName: {
      display: "inline-flex",
      marginLeft: theme.spacing(1),
    },
    linkNameWrapper: {
      alignItems: "center",
      display: "flex",
    },
  };
});
