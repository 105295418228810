import { useEffect } from "react";
import {
  useAccountQuery,
  AccountSubscriptionDocument,
  AccountSubscriptionSubscription,
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

export const useAccountSubscription = (id: number, skip: boolean = false) => {
  const { data, loading, error, subscribeToMore } = useAccountQuery({
    variables: { id },
    skip,
    fetchPolicy,
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore<AccountSubscriptionSubscription>({
      document: AccountSubscriptionDocument,
      variables: { id },
      // updateQuery: (prev, { subscriptionData }) => {
      //   if (!subscriptionData.data || !prev) return prev;
      //   return {
      //     ...prev,
      //     ...{ businessObject: subscriptionData.data.accountSubscription },
      //   };
      // },
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { account: data?.account, loading, error };
};
