import React, { createContext, FC } from "react";

import dayjs from "dayjs";

import { PapershiftWorkingArea, PapershiftShift, Appointment } from "../../../generated/graphql";
import ShiftRow from "../ShiftRow";
import ShiftWrapper from "../ShiftWrapper";
import { FilterState } from "../../pages/Appointment/reducer";
import { TAvatarUser } from "../UserAvatar";

export const AbsentContext = createContext<{
  isAbsent?: boolean;
  day?: dayjs.Dayjs;
}>({
  isAbsent: false,
  day: undefined,
});

type ShiftRowWrapperProps = {
  appointments: Appointment[];
  appointmentsByUsers: Appointment[];
  day: dayjs.Dayjs;
  filter?: FilterState;
  isFirstColumn?: boolean;
  isLastColumn?: boolean;
  refetch: () => void;
  shift: PapershiftShift;
  user: TAvatarUser;
  workingArea: PapershiftWorkingArea;
  validRange?: {
    startsAt?: dayjs.Dayjs;
    endsAt?: dayjs.Dayjs;
  };
};

export const ShiftRowWrapper: FC<ShiftRowWrapperProps> = ({
  appointments,
  appointmentsByUsers,
  day,
  filter,
  isFirstColumn = false,
  isLastColumn = false,
  refetch,
  shift,
  user,
  validRange,
  workingArea,
}: ShiftRowWrapperProps) => {
//   console.log({shift, user, workingArea});
  return (
    <AbsentContext.Provider
      value={{ isAbsent: user?.absentUntil && dayjs(user.absentUntil).isAfter(day), day }}
    >
      <ShiftRow
        backgroundImage
        border="borderBottom"
        padded
        user={user}
        width={56}
        workingArea={workingArea}
      >
        {/* this is each column content */}
        <ShiftWrapper
          appointments={appointments}
          filter={filter}
          invalidTimes={appointmentsByUsers}
          isFirstColumn={isFirstColumn}
          isLastColumn={isLastColumn}
          refetch={refetch}
          shift={shift}
          user={user}
          validRange={validRange}
        />
      </ShiftRow>
    </AbsentContext.Provider>
  );
};

export default ShiftRowWrapper;
