import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  addLinkButton: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

export default useStyles;
