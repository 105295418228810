import React, { useState } from "react";
import dayjs from "dayjs";
import { useMutation } from "@apollo/client";
import { Link as RouterLink } from "react-router-dom";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import EditIcon from "../../atoms/EditIcon";
import Chip from "@material-ui/core/Chip";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import {
  CounselingMandate,
  CreateCounselingUserDocument,
  ServiceAttribute,
  ServiceStatus,
  ServiceType,
  Service,
  Team,
} from "../../../generated/graphql";

import DefaultDialog from "../../molecules/DefaultDialog";
import DefaultDialogContent from "../../molecules/DefaultDialogContent";
import Card from "../../molecules/Card";
import ServiceMetaIcon from "../../atoms/ServiceMetaIcon";
import Markup from "../../atoms/Markup";
import Avatar from "../../atoms/Avatar";
import STCForm from "../../forms/STCForm";
import ServiceStatusForm from "../../forms/ServiceStatusForm";
import useStyles from "../boxListStyles";
import AssignUserDialog from "../AssignUserDialog";
import UserDialog from "../UserDialog";
import { statusLabel } from "../../../utils/serviceHelper";
import UnassignedUserButton from "../../atoms/UnassignedUserButton";
import CaseManagementOverviewDialog from "../CaseManagementDialog";
import CaseManagementAssignmentDialog from "../CaseManagementAssignmentDialog";

type TSTCDashboard = {
  service: Service;
  mandate?: CounselingMandate;
  counselorView?: boolean;
  refNumber: number;
};
const STCDashboard = ({ service, mandate, counselorView, refNumber }: TSTCDashboard) => {
  dayjs.extend(LocalizedFormat);
  const [open, setOpen] = useState(false);
  const [openAssign, setOpenAssign] = useState(false);
  const classes = useStyles();
  const [createCounselingUser] = useMutation(CreateCounselingUserDocument);

  const status = service.status || ServiceStatus.research;
  const label = statusLabel(status);
  const serviceName = service.serviceMeta?.name || "Interne strukturierte Beratung";
  const serviceType = service.serviceMeta?.type || ServiceType.internalConsult;

  const attributes = [...(service.serviceAttributes || [])] as ServiceAttribute[];

  const sortFnc = (a: any, b: any) => (a.order || 0) - (b.order || 0);

  const handleClick = async (userId?: number) => {
    // We do not allow this currently (needed for resetting caseManager only so far)
    if (userId !== undefined) {
      await createCounselingUser({
        variables: { input: { userId, serviceId: service.id! } },
      });
    }
    setOpenAssign(false);
  };

  return (
    <>
      <Card
        icon={<ServiceMetaIcon name={serviceType} />}
        actionIcon={counselorView ? <></> : <EditIcon />}
        onClick={counselorView ? undefined : () => setOpen(true)}
        title={
          <Box display="flex" alignItems="center" flexGrow={1}>
            <Box ml={2}>{!mandate ? serviceName : "Beratungsmandat"}</Box>
            {counselorView && !!mandate?.service?.client?.person?.name && (
              <Box ml={2}>
                <Link
                  color="primary"
                  underline="always"
                  component={RouterLink}
                  to={`/externalConsult/${mandate?.service?.id}`}
                  variant="h3"
                >
                  {mandate?.service?.client?.person?.name}
                </Link>
              </Box>
            )}
            {!counselorView && !!service.counselingMandate && (
              <Box ml={2}>
                <Link
                  color="primary"
                  underline="always"
                  component={RouterLink}
                  to={`/mandate/${service.counselingMandate?.id}`}
                  variant="h3"
                >
                  {service.counselingMandate?.counselor?.name !== "Unbekannt"
                    ? service.counselingMandate?.counselor?.name
                    : service.counselingMandate?.business?.name}
                </Link>
              </Box>
            )}
            <Box ml={2}>
              <Chip
                label={label}
                color={status !== ServiceStatus.finished ? "primary" : "default"}
                classes={{ root: classes.chip, label: classes.chipLabel }}
              />
            </Box>
          </Box>
        }
      >
        <Grid container>
          <Grid item xs={3}>
            <Typography variant="h4" color="textSecondary" gutterBottom>
              Angelegt
            </Typography>
            <Typography variant="body2">
              {service.createdAt && dayjs(service.createdAt).format("L")}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h4" color="textSecondary" gutterBottom>
              Zuletzt aktualisiert
            </Typography>
            <Typography variant="body2">
              {service.lastUpdate && dayjs(service.lastUpdate).format("L")}
            </Typography>
          </Grid>
          {refNumber > 400000000 && (
            <Grid item xs={3}>
              <Typography variant="h4" color="textSecondary" gutterBottom>
                Referenznummer
              </Typography>
              <Typography variant="body2">{refNumber}</Typography>
            </Grid>
          )}
          {serviceType === ServiceType.internalConsult && (
            <Grid item xs={3}>
              <Typography variant="h4" color="textSecondary" gutterBottom>
                Zuständiger Berater
              </Typography>
              {!service?.counselingUser ? (
                <>
                <IconButton                  
                  classes={{ root: classes.iconButton }}
                  onClick={() => setOpenAssign(true)}
                >
                </IconButton>                                                    
                <CaseManagementAssignmentDialog caseManager={service?.counselingUser?.user} clientId={service.client?.id!} 
                counselingUser={null} 
                serviceId={service.id}
                 />
              </>
              ) : (
                <>
                  <IconButton
                    classes={{ root: classes.iconButton }}
                    onClick={() => setOpenAssign(true)}
                  >
                  </IconButton>                                                    
                  <CaseManagementAssignmentDialog caseManager={service?.counselingUser?.user} clientId={service.client?.id!} 
                  counselingUser={service.counselingUser.user!} 
                  serviceId={service.id}
                   />
                </>
              )}
            </Grid>
          )}
          {!!mandate?.service?.client?.account?.freeSessions && (
            <Grid item xs={3}>
              <Typography variant="h4" color="textSecondary" gutterBottom>
                Sitzungen
              </Typography>
              <Typography variant="body2">
                {`${mandate?.sessionsCompleted} von ${
                  mandate?.sessionsAppointed || mandate?.service?.client?.account?.freeSessions
                }`}
              </Typography>
            </Grid>
          )}
        </Grid>
        {attributes.sort(sortFnc).map((attr: ServiceAttribute) => {
          const title = attr.serviceMetaAttribute?.label || "";
          const value = attr.value || "";
          return (
            <Box mt={5} key={`internalConsult-${title}`}>
              <Typography variant="h4" color="textSecondary" gutterBottom>
                {title}
              </Typography>
              {attr.serviceMetaAttribute?.type === "text" ? (
                <Markup value={value}></Markup>
              ) : (
                <Typography variant="body2">{value}</Typography>
              )}
            </Box>
          );
        })}
      </Card>
      <DefaultDialog open={open} onClose={() => setOpen(false)}>
        <DefaultDialogContent>
          <Box display="flex" justifyContent="center">
            <Typography variant="h1">{serviceName} bearbeiten</Typography>
          </Box>
          <Box mt={3}>
            <ServiceStatusForm
              service={service}
              hidePlaning={service.serviceMeta?.type === ServiceType.externalConsult}
            ></ServiceStatusForm>
          </Box>
          <STCForm
            serviceAttributes={attributes}
            onClose={() => setOpen(false)}
            mandate={mandate}
          />
        </DefaultDialogContent>
      </DefaultDialog>
    </>
  );
};

export default STCDashboard;
