import React, { FC } from "react";

import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";

export const Name: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Typography variant="h4" className={classes.name} color="primary">
      {children}
    </Typography>
  );
};

export const NameDisabled: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Typography variant="h4" className={classes.nameDisabled}>
      {children}
    </Typography>
  );
};
