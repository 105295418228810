import Loading from "../../atoms/Loading";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import {
  Button,
  createStyles,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  ClientComplaint, useGetClientComplaintsQuery,
  // useCreateComplaintMutation,
  // useDeleteComplaintMutation,
  // useGetClientComplaintsQuery,
  // useUpdateComplaintMutation,
} from "../../../generated/graphql";
import DayjsUtils from "@date-io/dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import WizardNavigation from "../../molecules/WizardNavigation";
import SecondaryButton from "../../atoms/SecondaryButton";
import { useHistory, useParams } from "react-router";
import AsyncSearch from "../../molecules/AsyncSearch";
import SlateRTE, { initialFormValue } from "../../molecules/SlateRTE";
import PrimaryButton from "../../atoms/PrimaryButton";
import { Field, Formik } from "formik";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

dayjs.extend(LocalizedFormat);

export default function ClientComplaints() {
  const { clientId } = useParams<{ clientId: string }>();
  const { push } = useHistory();
  const { goBack } = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const [currentComplaint, setCurrentComplaint] = useState(null);

  const [personId, setPersonId] = useState('-1');

  const onChangePerson = (pId?: number) => {
    setPersonId(pId!.toString());
  }

  const { data: complaintsData, loading, refetch } = useGetClientComplaintsQuery({
    variables: {
      id: parseInt(personId, 10),
    },
  });


  if (loading) {
    return <Loading />;
  }

  const complaints = complaintsData?.getClientComplaints ?? [];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box marginTop={6}>            
            <AsyncSearch.PersonSearch placeholder={"Der Name des zu suchenden Kunden"} onClick={onChangePerson} autoFocus={false} />
          </Box>
        </Grid>
      </Grid>
      {personId !== '-1' && complaints.length > 0 && 
      <Box marginTop={6}>
        <ComplaintDialog
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          reloadComplaints={() => refetch()}
          clientId={clientId}
          complaint={currentComplaint}
        />
        <Grid container spacing={2}>
          <Table
            //key={`table-${itemsByStatus[0].status}`}
            //className={classes.table}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Titel</StyledTableCell>
                <StyledTableCell>Datum der Beschwerde</StyledTableCell>
                <StyledTableCell>Registriert durch</StyledTableCell>
                <StyledTableCell>Registrierungsdatum</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {complaints.map((item: any, idx) => {
                const onRowClick = () => {
                  if(personId !== '-1') {
                  push(`/person/${personId}`);
                  }
                  //setCurrentComplaint(item);
                  //setIsOpen(true);
                };
                return renderTableRow(item!, idx, onRowClick);
              })}
            </TableBody>
          </Table>
        </Grid>
      </Box>
      }
      {personId !== '-1' && complaints.length == 0 &&       
        <Box marginTop={6} display="flex" justifyContent="center">
        Keine Beschwerden
        </Box>
    }
    </>
  );
}

export const StyledKeyboardDatePicker = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
}))(KeyboardDatePicker);

const ComplaintDialog = (props: any) => {
  // const [createComplaint] = useCreateComplaintMutation();
  // const [updateComplaint] = useUpdateComplaintMutation();
  // const [deleteComplaint] = useDeleteComplaintMutation();
  const [complaintDate, setComplaintDate] = useState(dayjs());
  const [content, setContent] = useState(initialFormValue);
  const [title, setTitle] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const reloadComplaints = props.reloadComplaints;

  const complaint = props?.complaint;

  useEffect(() => {
    setIsEditMode(false);
    setTitle('');
    setContent(initialFormValue);
    setComplaintDate(dayjs());
    if (complaint) {
      setIsEditMode(true);
      setTitle(complaint.title);
      setContent(complaint.content);
      setComplaintDate(complaint.dateOfComplaint);
    }
  }, [complaint]);

  return (
    <Dialog fullWidth open={props.isOpen} onClose={() => props.onClose()}>
      <DialogContent>
        <Box mb={3} display="flex" justifyContent="center">
          <Typography variant="h1">
            {isEditMode ? "Beschwerde bearbeiten" : "Beschwerde einreichen"}
          </Typography>
        </Box>
        <Box m={4} minHeight={400}>
          <Formik
            initialValues={{
              title: title,
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              if (isEditMode) {
                // await updateComplaint({
                //   variables: {
                //     id: +complaint.id,
                //     complaintInput: {
                //       clientId: +props.clientId,
                //       content,
                //       title: values.title,
                //       dateOfComplaint: complaintDate,
                //     },
                //   },
                // });
              } else {
                // await createComplaint({
                //   variables: {
                //     complaintInput: {
                //       clientId: +props.clientId,
                //       content,
                //       title: values.title,
                //       dateOfComplaint: complaintDate,
                //     },
                //   },
                // });
              }
              reloadComplaints();
              setSubmitting(false);
              props.onClose();
            }}
          >
            {({ handleSubmit, handleChange, values }) => (
              <form onSubmit={handleSubmit}>
                <FormControl>
                  <Field
                    value={values.title}
                    name="title"
                    component={TextField}
                    onChange={handleChange("title")}
                    type="text"
                    label={"Title"}
                    tabIndex={1}
                  />
                </FormControl>
                <Box width="50%" padding="16px 0">
                  <MuiPickersUtilsProvider utils={DayjsUtils} locale="de">
                    <StyledKeyboardDatePicker
                      minDate={dayjs()}
                      name="dueDate"
                      format="DD.MM.YYYY"
                      value={complaintDate} //duedate
                      onChange={value => {
                        setComplaintDate(value || dayjs());
                      }}
                      label="Datum der Beschwerde"
                      cancelLabel="Abbrechen"
                      okLabel="OK"
                    />
                  </MuiPickersUtilsProvider>
                </Box>
                <SlateRTE
                  value={content}
                  label="Content"
                  handleChange={value => setContent(value)}
                  tabIndex={1}
                />
                <Box display="flex" justifyContent="space-between" mt={4}>
                  <Box mx={1}>
                    {isEditMode && (
                      <Box display="flex" justifyContent="right" mt={4}>
                        <SecondaryButton
                          onClick={async () => {
                            if (isEditMode) {
                              // await deleteComplaint({
                              //   variables: {
                              //     id: +complaint.id,
                              //   },
                              // });
                              // reloadComplaints();
                              props.onClose();
                            }
                          }}
                        >
                          Löschen
                        </SecondaryButton>
                      </Box>
                    )}
                  </Box>
                  <Box mx={1}>
                    <Box display="flex" justifyContent="right" mt={4}>
                      <PrimaryButton type="submit">
                        {isEditMode ? "Bearbeiten" : "Einreichen"}
                      </PrimaryButton>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const renderTableRow = (item: ClientComplaint, idx: number, onRowClick: any) => {
  const handleRowClick = () => {
    onRowClick();
  };
  return (
    <StyledTableRow key={`researchObject-${item.id}`} onClick={handleRowClick}>
      <StyledTableCell component="th" scope="row">
        {item.title}
      </StyledTableCell>
      <StyledTableCell>{dayjs(item.dateOfComplaint).format("L")}</StyledTableCell>
      <StyledTableCell>{item.user?.person?.name}</StyledTableCell>
      <StyledTableCell>{dayjs(item.updatedAt).format("L")}</StyledTableCell>
    </StyledTableRow>
  );
};

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:hover": {
        background: theme.palette.common.white,
        cursor: "pointer",
      },
    },
  }),
)(TableRow);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:last-child": {
        padding: theme.spacing(0, 2, 0, 0),
      },
    },
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: 14,
      letterSpacing: 0.7,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(3)}px`,
      "&:first-child": {
        width: "50%",
      },
      "&:nth-child(0n+2)": {
        width: "20%",
      },
      "&:nth-child(0n+3)": {
        width: "15%",
      },
    },
    body: {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      color: theme.palette.common.black,
      overflowWrap: "break-word",
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
  }),
)(TableCell);
