import React from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { Select } from "formik-material-ui";
import { Field } from "formik";

import { AccountUsageScope } from "../../../generated/graphql";
import { translateUsageScope } from "../../../utils/accountHelper";

type TAccountUsageScopeSelect = { tabIndex?: number };

const AccountUsageScopeSelect = ({ tabIndex = 0 }: TAccountUsageScopeSelect) => {
  return (
    <FormControl fullWidth margin="normal">
      <InputLabel htmlFor="usageScope">Zugangsberechtigung</InputLabel>
      <Field name="usageScope" label="Zugangsberechtig" component={Select} tabIndex={tabIndex}>
        <MenuItem value={AccountUsageScope.employeeOnly}>
          {translateUsageScope(AccountUsageScope.employeeOnly)}
        </MenuItem>
        <MenuItem value={AccountUsageScope.employeeAndFamily}>
          {translateUsageScope(AccountUsageScope.employeeAndFamily)}
        </MenuItem>
      </Field>
    </FormControl>
  );
};
export default AccountUsageScopeSelect;
