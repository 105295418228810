import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: theme.spacing(1.75),
    padding: theme.spacing(0, 0.5),
    fontWeight: theme.typography.fontWeightMedium,
    height: theme.spacing(4),
    letterSpacing: "0.05em",
    lineHeight: `${theme.spacing(3)}px`,
    minWidth: theme.spacing(8),
    "&.tiny": {
      height: theme.spacing(3),
      lineHeight: "23px",
    },
    "&.red": {
      backgroundColor: "red",
    },
    "&.yellow": {
      backgroundColor: "#F3AA18",
    },
    "&.white": {
      backgroundColor: "#ffffff",
      color: "#025879",
    },
  },
  icon: {
    fontSize: 16,
    marginRight: theme.spacing(0.5),
  },
}));

export default useStyles;
