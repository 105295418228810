import React from "react";
import dayjs from "dayjs";
import Box from "@material-ui/core/Box";
import orderBy from "lodash/orderBy";
import Typography from "@material-ui/core/Typography";
import ActivityItem from "../../molecules/ActivitiesItem";

import { Client } from "../../../generated/graphql";
import useStyles from "./styles";

import emptyAlternative from "../../assets/emptyAlternative.svg";
import empty from "../../assets/empty.svg";
type TList = {
  clients: Client[];
  emptyMessage: string;
  isCaseManagement?: boolean;
  title: string;
};
const List = ({ clients, emptyMessage, isCaseManagement = false, title }: TList) => {
  const classes = useStyles();
  return clients.length > 0 ? (
    <Box pb={2}>
      <Box mt={3} mb={1}>
        <Typography variant="h3">{title}</Typography>
      </Box>
      <Box className={classes.group}>
        {orderBy(clients, ["person.lastActivity"], ["desc"]).map((client: Client) => (
          <ActivityItem
            key={`${title}-clients-${client.id}`}
            title={<></>}
            person={client.person!}
            info={
              <Box display="flex">
                <Box flex={1}>
                  <Typography variant="body2">
                    Letzte Aktivität: {dayjs(client.person!.lastActivity!).format("DD.MM.YYYY")}
                  </Typography>
                </Box>
              </Box>
            }
          />
        ))}
      </Box>
    </Box>
  ) : (
    <Box mt={10} textAlign="center">
      <img src={isCaseManagement ? emptyAlternative : empty} alt={emptyMessage} />
      <Box my={2} />
      <Typography variant="body2">{emptyMessage}</Typography>
    </Box>
  );
};

export default List;
