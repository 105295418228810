import React, { FC } from "react";

import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import SvgIcon from "@material-ui/core/SvgIcon";

const BackButton: FC<IconButtonProps> = ({ className, ...props }) => {
  return (
    <IconButton {...props} className={`${className}`}>
      <SvgIcon color="primary">
        <path
          fillRule="evenodd"
          d="M10.3496 4.75C10.4047 4.7499 10.4573 4.77248 10.4952 4.81241L11.6312 6.01091C11.7041 6.08787 11.7043 6.20836 11.6316 6.28557L7.47689 10.7H19.95C20.0605 10.7 20.15 10.7895 20.15 10.9V12.6C20.15 12.7105 20.0605 12.8 19.95 12.8H7.47632L11.6238 17.2146C11.6961 17.2916 11.6961 17.4116 11.6236 17.4886L10.4956 18.6871C10.4578 18.7272 10.4051 18.75 10.35 18.75C10.2949 18.75 10.2422 18.7272 10.2044 18.6871L3.80436 11.8871C3.73188 11.8101 3.73188 11.6899 3.80436 11.6129L10.2044 4.81293C10.2421 4.77286 10.2946 4.7501 10.3496 4.75Z"
          clipRule="evenodd"
        />
      </SvgIcon>
    </IconButton>
  );
};

export default BackButton;
