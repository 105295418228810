import React, { FC } from "react";

import Button, { ButtonProps } from "@material-ui/core/Button";

import useStyles from "./styles";

const PrimaryButton: FC<ButtonProps> = ({ className, ...props }) => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      variant="contained"
      color="secondary"
      className={`${className} ${classes.button}`}
    />
  );
};

export default PrimaryButton;
