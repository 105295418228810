import React, { FC } from "react";

import IconButton from "@material-ui/core/IconButton";

import useStyles from "./styles";

type TEditorButton = {
  toggled: boolean;
  tabIndex?: number;
  toggleAction: (event: any) => void;
};

const EditorButton: FC<TEditorButton> = ({ children, toggled, toggleAction, tabIndex }) => {
  const classes = useStyles();

  return (
    <IconButton
      className={`${classes.button} ${toggled && classes.active}`}
      onMouseDown={toggleAction}
      tabIndex={tabIndex}
    >
      {children}
    </IconButton>
  );
};

export default EditorButton;
