import React, { ReactNode } from "react";
import { useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import PersonHeader from "../../../molecules/PersonHeader";
import ServiceMetaIcon from "../../../atoms/ServiceMetaIcon";
import { PersonQuery, ServiceMeta } from "../../../../generated/graphql";

import useStyles from "./styles";
import { Hidden } from "@material-ui/core";
import { NamePathMap } from "../../../molecules/Breadcrumb";

interface IHeader {
  backPath?: string;
  button?: ReactNode;
  headline?: string;
  mapPathList?: NamePathMap[];
  person: PersonQuery["person"];
  serviceId?: number | null;
  topicId?: number | null;
  serviceMeta: ServiceMeta;
  contractExpirationDate?: string;
  language?: string;
}

const Header = ({
  backPath,
  person,
  serviceId,
  serviceMeta,
  topicId,
  headline,
  button,
  mapPathList,
  contractExpirationDate,
  language,
}: IHeader) => {
  const { push } = useHistory();

  const classes = useStyles();
  return (
    <>
      <PersonHeader
        backPath={backPath}
        topicId={topicId}
        serviceId={serviceId}
        isClickable
        person={person}
        onClick={() => push(`/person/${person.id}`)}
        mapList={mapPathList}
        contractExpirationDate={contractExpirationDate}
        language={language}
      />

      <Grid className={classes.container} container alignItems="center">
        <Grid item xl={1} implementation="css" lgDown component={Hidden} />
        <Grid item xs={12} xl={10} className={classes.content}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box mr={2}>
              <ServiceMetaIcon name={serviceMeta?.iconName || ""} />
            </Box>
            <Typography variant="h1" align="center">
              {headline || `Service ${serviceMeta.name}`}
            </Typography>
          </Box>
          {button}
        </Grid>
        <Grid item xl={1} implementation="css" lgDown component={Hidden} />
      </Grid>
    </>
  );
};

export default Header;
