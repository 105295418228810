import React from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { Select } from "formik-material-ui";
import { Field } from "formik";

import { AccountReporting } from "../../../generated/graphql";
import { translateReporting } from "../../../utils/accountHelper";

type TAccountReportingSelect = { tabIndex?: number };

const AccountReportingSelect = ({ tabIndex = 0 }: TAccountReportingSelect) => {
  return (
    <FormControl fullWidth margin="normal">
      <InputLabel htmlFor="reporting">Reporting</InputLabel>
      <Field name="reporting" label="Reporting" component={Select} tabIndex={tabIndex}>
        <MenuItem value={AccountReporting.basic}>
          {translateReporting(AccountReporting.basic)}
        </MenuItem>
        <MenuItem value={AccountReporting.premium}>
          {translateReporting(AccountReporting.premium)}
        </MenuItem>
      </Field>
    </FormControl>
  );
};

export default AccountReportingSelect;
