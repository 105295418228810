import React, { useState } from "react";
import { useParams } from "react-router-dom";

import {
  RelationshipType,
  Gender,
  AddressType,
  PhoneType,
  useUpdateContactPersonMutation,
  Relationship,
  PersonQuery,
  useBreadCrumbInfoByPersonIdQuery,
} from "../../../generated/graphql";

import Loader from "../../atoms/Loading";
import PersonLayout from "../../templates/PersonLayout";
import DataRow from "../../molecules/DataRow";
import DataColumn from "../../molecules/DataColumn";
import AddressItem from "../../atoms/Address";
import DefaultDialog from "../../molecules/DefaultDialog";
import DefaultDialogContent from "../../molecules/DefaultDialogContent";
import translateRelationshipType from "../../../utils/translateRelationshipType";
import ContactPersonForm, { TContactPerson } from "../../forms/ContactPersonForm";
import { isValidAddress } from "../../../utils/getAddressDistance";
import usePersonSubscription from "../../../hooks/usePersonSubscription";

export default function PersonContactDetails() {
  const { id, contactId } = useParams<{ id: string; contactId: string }>();

  const { person, loading, error } = usePersonSubscription(parseInt(id, 10));
  const personId = person?.id;
  const { data: breadcrumbData } = useBreadCrumbInfoByPersonIdQuery({
    skip: !personId,
    variables: {
      id: personId!,
    },
  });

  if (loading) {
    return <Loader></Loader>;
  }

  if (!!error || !person || !person.relations) {
    return <></>;
  }

  const relation = person.relations.find(relation => {
    return (
      relation && relation.passivePerson && relation.passivePerson.id === parseInt(contactId, 10)
    );
  });

  if (!relation) {
    return <></>;
  }

  return (
    <PersonContactDetailsView
      contactId={parseInt(contactId, 10)}
      person={person}
      relation={relation}
      contractExpirationDate={breadcrumbData?.breadCrumbInfoByPersonId?.contractEnd}
      language={breadcrumbData?.breadCrumbInfoByPersonId?.language || ""}
    />
  );
}
type TPersonContactDetailsView = {
  contactId: number;
  person: PersonQuery["person"];
  relation: Relationship;
  language: string;
  contractExpirationDate: string;
};
const PersonContactDetailsView = ({
  contactId,
  person,
  relation,
  contractExpirationDate,
  language,
}: TPersonContactDetailsView) => {
  const [open, setOpen] = useState(false);

  const [updateContactPerson] = useUpdateContactPersonMutation();

  const handleSubmit = (values: TContactPerson, autoSave = true) => {
    // This should not be a case at all, however, @ts-ignore somehow is not sufficient below
    if (!person.id) {
      return;
    }

    updateContactPerson({
      variables: {
        contactPersonUpdateInput: {
          person: {
            id: contactId,
            alias: values.alias,
            firstName: values.firstName,
            lastName: values.lastName,
            title: values.title,
            gender: values.gender,
            addresses: values.addresses
              // in case a contact person has no address yet, only send onSubmit
              .filter(address => !autoSave || !!address.id)
              .map(address => {
                return {
                  id: address.id,
                  zip: address.zip,
                  city: address.city,
                  street: address.street,
                  type: address.type,
                  skipUpdateGeocode: autoSave,
                };
              }),
            phones: values.phones
              // in case a contact person has no phone yet, only send onSubmit
              .filter(phone => !autoSave || !!phone.id)
              .map(phone => {
                return {
                  id: phone.id,
                  areaCode: phone.areaCode,
                  number: phone.number,
                  type: phone.type,
                  countryCode: phone.countryCode,
                };
              }),
          },
          activePersonId: person.id,
          relation: values.relation,
        },
      },
    });
  };

  const passivePerson = relation.passivePerson;

  if (!passivePerson) {
    return <></>;
  }

  const address = passivePerson.addresses && passivePerson.addresses[0];
  const phone = passivePerson.phones && passivePerson.phones[0];

  return (
    <PersonLayout
      contactId={contactId}
      person={person}
      contractExpirationDate={contractExpirationDate}
      language={language}
      mapList={[
        {
          name: "Adressbuch",
          path: `/person/${person.id}/contacts/`,
        },
        {
          name: passivePerson.name,
          path: "",
        },
      ]}
    >
      <DataRow title="Persönliche Daten" editTrigger={() => setOpen(true)}>
        {passivePerson.title && (
          <DataColumn label="Akademischer Titel" xs={3}>
            {passivePerson.title}
          </DataColumn>
        )}
        {passivePerson.alias && (
          <DataColumn label="Alias" xs={3}>
            {passivePerson.alias}
          </DataColumn>
        )}
        {address && isValidAddress(address) && (
          <DataColumn label="Adresse" xs={3}>
            <AddressItem address={address}></AddressItem>
          </DataColumn>
        )}
        {phone?.readableNumber && phone?.readableNumber !== "+49  " && (
          <DataColumn label="Telefonnummer" xs={3}>
            {phone.readableNumber}
          </DataColumn>
        )}
        <DataColumn label="Beziehung" xs={3}>
          {translateRelationshipType(relation.relationship || RelationshipType.unknown)}
        </DataColumn>
        {relation.isHousehold && (
          <DataColumn label="Haushalt" xs={3}>
            Lebt im Haushalt des Klienten
          </DataColumn>
        )}
      </DataRow>
      <DefaultDialog open={open} onClose={() => setOpen(false)}>
        <DefaultDialogContent>
          <ContactPersonForm
            cancel={() => setOpen(false)}
            allowEmergencyContact={
              !(
                person.relations &&
                person.relations.some(relation => relation && relation.isEmergency)
              ) || !!relation.isEmergency
            }
            submit={values => {
              handleSubmit(values, false);

              setOpen(false);
            }}
            initialValues={{
              alias: passivePerson.alias || "",
              firstName: passivePerson.firstName || "",
              lastName: passivePerson.lastName || "",
              title: passivePerson.title || "",
              gender: passivePerson.gender || Gender.unknown,
              addresses: [
                Object.assign(
                  {
                    id: undefined,
                    type: AddressType.private,
                    zip: "",
                    city: "",
                    street: "",
                  },
                  address || {},
                ),
              ],
              phones: [
                Object.assign(
                  {
                    id: undefined,
                    type: PhoneType.private,
                    number: "",
                    areaCode: "",
                    countryCode: "49",
                  },
                  phone || {},
                ),
              ],
              relation: {
                id: relation.id,
                relationship: relation.relationship || RelationshipType.unknown,
                isEmergency: relation.isEmergency || false,
                isHousehold: relation.isHousehold || false,
              },
            }}
          />
        </DefaultDialogContent>
      </DefaultDialog>
    </PersonLayout>
  );
};
