import React from "react";

import { useParams, useHistory } from "react-router-dom";
import { FormikProps, Form, Field } from "formik";
import { TextField } from "formik-material-ui";

import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";

import SlateRTE, { initialFormValue } from "../../molecules/SlateRTE";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import WizardNavigation from "../../molecules/WizardNavigation";
import { ServiceResearchObject } from "../../../generated/graphql";

import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
  },
}));

export default function ReasearchObjectForm({
  isSubmitting,
  setFieldValue,
  values,
}: FormikProps<ServiceResearchObject>) {
  const classes = useStyles();
  const { serviceId } = useParams<{ serviceId: string }>();
  const { push } = useHistory();
  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <FormControl className={classes.formControl} margin="normal">
            <Field name="descriptor" component={TextField} label="Bezeichnung" tabIndex={1} />
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={12}>
          <SlateRTE
            label="Inhalt"
            small
            initiallyReadOnly={false}
            handleChange={value => setFieldValue("info", value)}
            placeholder={""}
            tabIndex={2}
            value={initialFormValue}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <SlateRTE
            label="Meine Notizen"
            small
            tabIndex={3}
            initiallyReadOnly={false}
            handleChange={value => setFieldValue("note", value)}
            placeholder={""}
            value={initialFormValue}
          />
        </Grid>
      </Grid>
      <WizardNavigation>
        <SecondaryButton tabIndex={5} onClick={() => push(`/service/${serviceId}`)}>
          Abbrechen
        </SecondaryButton>
        <PrimaryButton tabIndex={4} type="submit" disabled={isSubmitting}>
          Anlegen
        </PrimaryButton>
      </WizardNavigation>
    </Form>
  );
}
