import React from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import ArrowForward from "@material-ui/icons/ArrowForwardIos";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Appointment } from "../../../generated/graphql";
import CalendarToday from "@material-ui/icons/CalendarToday";
import useStyles from "./styles";
import "./styles.css";

type TBubble = {
  appointment: Appointment;
  personId: number | undefined;
  withClient?: boolean;
};
export const Bubble = ({ appointment, personId, withClient }: TBubble) => {
  const classes = useStyles();
  const { push } = useHistory();

  // will be changed in future
  const clickable = !!withClient;

  const onClick = () => {
    if (clickable) return push(`/person/${personId}`);
    return;
  };

  const clientName = appointment.person?.name || "";
  const title = appointment.title || "";
  const users = appointment.users || [];
  return (
    <ListItemText
      disableTypography
      className={classnames(classes.listItemRedesign, {
        [classes.clickable]: clickable,
      })}
      onClick={onClick}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        paddingX={2}
        className={classnames(classes.typeIcon)}
      >
        <CalendarToday color="primary"></CalendarToday>
      </Box>
      <Box paddingY={1} flexGrow={1}>
        <Typography variant="h4">{withClient ? clientName : title}</Typography>
        <Typography variant="body2">
          {withClient
            ? title
            : users.map((user: any, key: number) => (
                <span key={`${title}=app=${user.id}-${appointment.id}`}>
                  {user.person.name}
                  {key + 1 < users.length ? ", " : ""}
                </span>
              ))}
        </Typography>
      </Box>
      {clickable && (
        <Box display="flex" justifyContent="center" alignItems="center" paddingRight={2}>
          <ArrowForward color="primary" fontSize="small"></ArrowForward>
        </Box>
      )}
    </ListItemText>
  );
};
