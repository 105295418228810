import GlobalSearch from "./Global";
import PersonSearch from "./Person";
import ClientSearch from "./Client";
import AccountSearch from "./Account";
import BusinessObjectSearch from "./BusinessObject";
import PersonWithClientSearch from "./PersonWithClient";
import ClientForCallDialogSearch from "./ClientForCallDialog"



const Search = {
  PersonSearch,
  BusinessObjectSearch,
  GlobalSearch,
  AccountSearch,
  PersonWithClientSearch,
  ClientSearch,
  ClientForCallDialogSearch
};

export default Search;
