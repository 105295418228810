import React from "react";

import { useHistory } from "react-router";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import AsyncSearch from "../../../molecules/AsyncSearch";
import {
  useCreateBusinessObjectMutation,
  useAssignBusinessContactMutation,
  BusinessObject,
} from "../../../../generated/graphql";

import { usePersonCreateContext } from "../provider";

type TBusinessAssign = {
  handleClose: () => void;
};

const BusinessAssign = ({ handleClose }: TBusinessAssign) => {
  const { push } = useHistory();
  const { name, personActivity, pushTarget } = usePersonCreateContext();
  const [assignBusinessContact] = useAssignBusinessContactMutation();
  const [createBusinessObject] = useCreateBusinessObjectMutation();

  const contactPersonId = personActivity?.person?.id;

  if (!name || !contactPersonId) {
    return null;
  }

  return (
    <Box>
      <Typography variant="h1" align="center">
        Zu welche Unternehmen gehört {name}?
      </Typography>
      <Box mt={4} mb={16}>
        <AsyncSearch.BusinessObjectSearch
          placeholder="Suche nach Name"
          onChange={async (businessObject: BusinessObject) => {
            assignBusinessContact({
              variables: {
                businessObjectId: businessObject.id!,
                personId: contactPersonId,
              },
            });

            if (businessObject) {
              if (pushTarget) {
                push(pushTarget(contactPersonId));
              } else {
                push(`/business/${businessObject.id}/contacts/${contactPersonId}`);
              }
            }

            handleClose();
          }}
          onCreateOption={async (businessObjectName: string) => {
            if (!businessObjectName) {
              return;
            }
            const [lastName, ...firstName] = name.split(" ").reverse();

            const { data } = await createBusinessObject({
              variables: {
                businessObjectInput: {
                  name: businessObjectName,
                },
                contactPersonInput: {
                  id: contactPersonId,
                  firstName: firstName.reverse().join(" "),
                  lastName,
                },
              },
            });

            if (data?.createBusinessObject?.id) {
              if (pushTarget) {
                push(pushTarget(contactPersonId));
              } else {
                push(`/business/${data.createBusinessObject.id}/contacts/${contactPersonId}`);
              }
            }

            handleClose();
          }}
        />
      </Box>
    </Box>
  );
};

export default BusinessAssign;
