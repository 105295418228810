import React, { useState } from "react";

import Typography from "@material-ui/core/Typography";

import { useMutation } from "@apollo/client";
import { Formik } from "formik";

import {
  Phone,
  CreatePhoneDocument,
  UpdatePhonesDocument,
  DeletePhoneDocument,
  PhoneType,
} from "../../../../generated/graphql";

import DataColumn from "../../../molecules/DataColumn";
import DataRow from "../../../molecules/DataRow";
import DefaultDialog from "../../../molecules/DefaultDialog";
import DialogContent from "../../../molecules/DefaultDialogContent";
import { PhoneTypeMap } from "../../../molecules/PhoneTypeSelect";

import { ContactAllowed } from "./components";
import ContactPhonesForm from "./ContactPhonesForm";

const newPhone: Phone = {
  areaCode: "",
  number: "",
  contactAllowed: true,
  messagingAllowed: true,
  type: PhoneType.private,
  countryCode: "49",
};

type TContactPhones = {
  personId: number;
  phones: Phone[];
  created: Phone[];
  setCreated: React.Dispatch<React.SetStateAction<Phone[]>>;
};
const ContactPhones = ({ personId, phones, created, setCreated }: TContactPhones) => {
  const [open, setOpen] = useState(false);

  const [createPhone] = useMutation(CreatePhoneDocument);
  const [updatePhones] = useMutation(UpdatePhonesDocument);
  const [deletePhone] = useMutation(DeletePhoneDocument);

  const newElements = phones.length < 1 && created.length < 1 ? [newPhone] : created;

  const handleRemove = (id: number) => {
    deletePhone({
      variables: {
        id,
      },
    });
  };

  const handleSubmit = async (values: { create?: Phone[]; update?: Phone[] }, autosave = true) => {
    // Persist newly created phones
    setCreated(values.create || []);

    if (values.create && !autosave) {
      createPhone({
        variables: {
          phonesInput: values.create.filter(phone => !!phone.number),
          personId,
        },
      });
    }

    if (values.update) {
      updatePhones({
        variables: {
          phonesInput: values.update.map(phone => {
            return {
              id: phone.id,
              areaCode: phone.areaCode,
              countryCode: phone.countryCode,
              number: phone.number,
              contactAllowed: phone.contactAllowed,
              messagingAllowed: phone.messagingAllowed,
              type: phone.type,
            };
          }),
          personId,
        },
      });
    }
  };

  return (
    <DataRow
      title={`Telefonnummer${phones.length > 1 ? "n" : ""}`}
      editTrigger={() => setOpen(true)}
    >
      <Phones phones={phones} />
      <DefaultDialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <Formik
            enableReinitialize
            onSubmit={async (values, { setSubmitting }) => {
              await handleSubmit(values, false);
              setCreated([]);
              setSubmitting(false);
              setOpen(false);
            }}
            initialValues={{
              update: phones,
              create: newElements,
            }}
          >
            {props => (
              <ContactPhonesForm
                cancel={() => setOpen(false)}
                {...props}
                create={() => {
                  props.values.create.push(newPhone);
                  props.validateForm();
                }}
                remove={handleRemove}
              />
            )}
          </Formik>
        </DialogContent>
      </DefaultDialog>
    </DataRow>
  );
};

const Phones = ({ phones }: { phones: Phone[] }) => {
  if (phones.length < 1) {
    return (
      <DataColumn>
        <Typography variant="body2">-</Typography>
      </DataColumn>
    );
  }

  return (
    <>
      {phones.map(phone => {
        const type = phone.type;
        const contactAllowed = !!phone.contactAllowed;
        const messagingAllowed = !!phone.messagingAllowed;
        const readableNumber = phone.readableNumber || "-";

        return (
          <DataColumn
            label={PhoneTypeMap.get(type || PhoneType.private)}
            key={phone.id || "phone"}
            xs={12}
          >
            <Typography variant="body2">{readableNumber}</Typography>

            <ContactAllowed contactAllowed={contactAllowed}>
              {contactAllowed ? "Kontakt erlaubt" : "Kontakt nicht erlaubt"}
            </ContactAllowed>

            <ContactAllowed contactAllowed={messagingAllowed}>
              {messagingAllowed ? "Nachrichten erlaubt" : "Keine Nachrichten"}
            </ContactAllowed>
          </DataColumn>
        );
      })}
    </>
  );
};

export default ContactPhones;
