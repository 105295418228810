import React from "react";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import Icon from "../../assets/ResetServiceIcon";
import { Person, ServiceType, useDeleteServiceMutation } from "../../../generated/graphql";
import useStyles from "./styles";

type TServiceReset = {
  name: string;
  id: string;
  person: Person;
  type: ServiceType;
};
const ServiceReset = ({ name, id, person, type }: TServiceReset) => {
  const { push } = useHistory();
  const [deleteService] = useDeleteServiceMutation();

  const classes = useStyles();
  const target = type === ServiceType.research ? "/service" : `/${type}`;

  const restart = () =>
    deleteService({ variables: { id: parseInt(id, 10) } }).then(() => {
      localStorage.removeItem("createdServiceID");
      localStorage.removeItem("createdServiceName");
      localStorage.removeItem("createdConsultingId");

      push(`/person/${person.id}/service`);
    });

  const cancel = () =>
    deleteService({ variables: { id: parseInt(id, 10) } }).then(() => {
      localStorage.removeItem("createdServiceID");
      localStorage.removeItem("createdServiceName");
      localStorage.removeItem("createdConsultingId");
      push(`/person/${person.id}`);
    });

  return (
    <Box>
      <Box display="flex" justifyContent="center" mb={3}>
        <Icon className={classes.icon} />
      </Box>
      <Typography variant="h1" align="center">
        Du hast bereits den Service „{name}” in Bearbeitung und hast nun drei Optionen:
      </Typography>
      <Box mt={7} display="flex" justifyContent="center" flexDirection="column">
        <Typography variant="h1" align="center" gutterBottom>
          Fortsetzen
        </Typography>
        <Typography variant="h1" align="center" gutterBottom className={classes.normalWeight}>
          Du gelangst vor zum nächsten Schritt, dein Service bleibt erhalten.
        </Typography>
        <Box mt={2} display="flex" justifyContent="center">
          <PrimaryButton onClick={() => push(`/person/${person.id}${target}/${id}/setup`)}>
            Fortsetzen
          </PrimaryButton>
        </Box>
      </Box>
      <Box mt={7}>
        <Typography variant="h1" align="center" gutterBottom>
          Neustarten
        </Typography>
        <Typography variant="h1" align="center" gutterBottom className={classes.normalWeight}>
          Du beginnst einen neuen Service, alle Daten werden gelöscht.
        </Typography>
        <Box mt={2} display="flex" justifyContent="center">
          <SecondaryButton onClick={restart}>Neu starten</SecondaryButton>
        </Box>
      </Box>
      <Box mt={7}>
        <Typography variant="h1" align="center" gutterBottom>
          Abbrechen
        </Typography>
        <Typography variant="h1" align="center" gutterBottom className={classes.normalWeight}>
          Du brichst die Erstellung des Service „{name}” ab, alle Daten werden gelöscht.
        </Typography>
        <Box mt={2} display="flex" justifyContent="center">
          <SecondaryButton onClick={cancel}>Abbrechen</SecondaryButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ServiceReset;
