import React from "react";

import { Theme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Phone from "@material-ui/icons/Phone";
import Room from "@material-ui/icons/Room";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/styles/withStyles/withStyles";

import { User, PapershiftWorkingArea } from "../../../generated/graphql";
import { getWorkingAreaName } from "../../../utils/translateWorkingAreaEnums";
import { getUserName } from "../../../utils/userNameHelper";
import Avatar from "../../atoms/Avatar";
import getTeamName from "../../../utils/getTeamName";

export const UserAlias = withStyles((_: Theme) => ({
  root: {
    fontStyle: "italic",
  },
}))(Typography);

export const SmallPhone = withStyles((theme: Theme) => ({
  root: {
    color: "#78909C",
    fontSize: "16px",
    marginRight: theme.spacing(0.5),
  },
}))(Phone);

export const SmallRoom = withStyles((theme: Theme) => ({
  root: {
    color: "#78909C",
    fontSize: "16px",
    marginRight: theme.spacing(0.5),
  },
}))(Room);

interface IUserDetails {
  user: User;
  workingArea?: PapershiftWorkingArea;
}

export const UserDetails = ({ user, workingArea }: IUserDetails) => {
  // N.B basically the workingArea value being passed, is not correct
  // also, it seems we don't really need to pass it as we need to
  // display the user work area, and we have the user here
  const teams = user.teams?.map(getTeamName).join(", ");
  let userWorkingArea = null;
  if (user.papershiftWorkingAreas) {
    userWorkingArea = user.papershiftWorkingAreas[0]?.name;
  }
  return (
    <Box display="flex" alignItems="center" marginBottom={3} width="100%">
      <Avatar widthfactor={5} src={user.person?.picture || user.person?.avatar || ""}>
        {user.person?.avatarKeys}
      </Avatar>
      <Box marginX={2}>
        <Box>
          <Typography variant="h4">{getUserName(user!.person)}</Typography>
        </Box>
        {teams && <Typography variant="body2">{teams}</Typography>}
        {(user.papershiftWorkingAreas || user.primeDN) && (
          <Box display="flex" alignItems="center" marginTop={1}>
            {userWorkingArea && (
              <Box mr={2}>
                <SmallRoom /> {getWorkingAreaName(userWorkingArea)}
              </Box>
            )}
            {user.primeDN && (
              <>
                <SmallPhone /> {user.primeDN}
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UserDetails;
