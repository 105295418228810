import React, { FC } from "react";
import { FormikProps, FormikValues } from "formik";
import { useHistory } from "react-router-dom";
import DayjsUtils from "@date-io/dayjs";
import dayjs, { Dayjs } from "dayjs";

import { TimePicker, DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import PrimaryButton from "../../atoms/PrimaryButton";
import PersonTextualSelect from "../../molecules/ContactPersonTextualSelect";
import MandateSelect from "../../molecules/MandateSelect";
import WizardNavigation from "../../molecules/WizardNavigation";
import { Person, BusinessObject, CounselingMandate } from "../../../generated/graphql";
import SecondaryButton from "../../atoms/SecondaryButton";
import useStyles from "../reportStyles";

export interface IForm1Props {
  business: BusinessObject;
  defaultContact?: string;
  defaultMandate?: string;
  mandates: CounselingMandate[];
  setContact?: (person?: Person | { name: string; id?: never }) => void;
  setMandate?: (person?: Person) => void;
}

const BusinessMemoForm: FC<FormikProps<FormikValues> & IForm1Props> = ({
  business,
  defaultContact,
  defaultMandate,
  mandates,
  setContact,
  setMandate,
  handleSubmit,
  isSubmitting,
  values,
  setFieldValue,
}) => {
  const { goBack } = useHistory();
  const handleTypeChange = (event: any) => {
    setFieldValue("type", event.target.value);
  };
  const setEndDate = (start: Dayjs, end: Dayjs): Dayjs => {
    const startM = dayjs(start);
    const endM = dayjs(end);

    const newEndDate = endM
      .year(startM.year())
      .month(startM.month())
      .date(startM.date());
    setFieldValue("end", newEndDate);
    return newEndDate;
  };
  const handleStartChange = (value: MaterialUiPickersDate) => {
    if (value) {
      const startM = dayjs(value);
      setFieldValue("start", startM);
      const endDate = setEndDate(startM, values.end);
      const startYear = startM.year();
      if (startYear !== dayjs().year()) {
        setFieldValue("end", endDate.year(startYear));
      }
      if (endDate.isBefore(startM)) {
        setFieldValue("end", endDate.add(1, "day"));
      }
    }
  };
  const handleEndChange = (value: MaterialUiPickersDate) => {
    if (value) {
      value = setEndDate(values.start, value);
      if (value.isBefore(values.start)) {
        setFieldValue("end", value.add(1, "day"));
      } else {
        setFieldValue("end", value);
      }
    }
  };
  const classes = useStyles();
  return (
    <form onSubmit={handleSubmit} className={classes.content}>
      <Typography variant="h2" className={classes.dataText} gutterBottom>
        <Select
          value={values.type}
          classes={{ select: classes.select }}
          inputProps={{ className: classes.input }}
          IconComponent="span"
          onChange={handleTypeChange}
          tabIndex={0}
        >
          <MenuItem value="callIn">Eingehendes Telefonat</MenuItem>
          <MenuItem value="callOut">Ausgehendes Telefonat</MenuItem>
          <MenuItem value="mail">E-Mail</MenuItem>
        </Select>{" "}
        mit{" "}
        <PersonTextualSelect
          business={business}
          setContact={setContact}
          defaultContact={defaultContact}
        />
      </Typography>
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <Typography variant="h2" className={classes.dataText} gutterBottom>
          am{" "}
          <DatePicker
            tabIndex={2}
            format="Do MMM"
            className={classes.datePicker}
            value={values.start}
            onChange={handleStartChange}
            disableFuture
            key="startDate"
            InputProps={{ inputProps: { className: classes.timeinput } }}
          />{" "}
          von{" "}
          <TimePicker
            className={classes.timePicker}
            value={values.start}
            onChange={handleStartChange}
            ampm={false}
            InputProps={{ inputProps: { className: classes.timeinput } }}
            key="startTime"
            tabIndex={3}
          />
          {" bis "}
          <TimePicker
            value={values.end}
            className={classes.timePicker}
            onChange={handleEndChange}
            ampm={false}
            InputProps={{ inputProps: { className: classes.timeinput } }}
            key="endTime"
            tabIndex={4}
          />{" "}
          Uhr
        </Typography>
      </MuiPickersUtilsProvider>
      {!business.accountId && mandates.length > 0 && (
        <Typography variant="h2" className={classes.dataText} gutterBottom>
          <MandateSelect
            setMandate={setMandate}
            mandates={mandates}
            defaultMandate={defaultMandate}
          />
        </Typography>
      )}
      <WizardNavigation>
        <SecondaryButton onClick={() => goBack()} tabIndex={7}>
          Abbrechen
        </SecondaryButton>
        <PrimaryButton type="submit" disabled={isSubmitting} tabIndex={6}>
          Weiter
        </PrimaryButton>
      </WizardNavigation>
    </form>
  );
};
export default BusinessMemoForm;
