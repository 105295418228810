import React, { FC } from "react";
import { useHistory, useLocation } from "react-router-dom";
import dayjs from "dayjs";

import Box from "@material-ui/core/Box";

import SubHeader from "../../organisms/SubHeader";
import Breadcrumb from "../../molecules/Breadcrumb";
import DatePickerNavigator from "../../pages/Activities/datepickerNavigator";

const ActivitiesTemplate: FC = ({ children }) => {
  const {
    push,
    location: { pathname },
  } = useHistory();
  const { search } = useLocation();
  const date = new URLSearchParams(search).get("date") ?? Date();
  const sameDay = dayjs(date).isSame(new Date(), "date");
  const isInFeature = dayjs(date).isAfter(dayjs(new Date()).subtract(1, "day"), "date");
  const TitleToDisplay = `Ellis Tag (${dayjs(date).format("D. MMMM YYYY")})`;
  const nextDayUrl = `activities?date=${dayjs(date)
    .add(1, "day")
    .format("YYYY-MM-DD")}`;
  const previousDayUrl = `activities?date=${dayjs(date)
    .subtract(1, "day")
    .format("YYYY-MM-DD")}`;

  let name = TitleToDisplay;
  let isMainActivitiesPage = true;

  if (pathname.indexOf("internal") !== -1) {
    name = "Interne strukturierte Beratungen";
    isMainActivitiesPage = false;
  }
  if (pathname.indexOf("external") !== -1) {
    name = "Externe Beratungsmandate";
    isMainActivitiesPage = false;
  }
  if (pathname.indexOf("services") !== -1) {
    name = "Alle LM Services";
    isMainActivitiesPage = false;
  }
  if (pathname.indexOf("flags") !== -1) {
    name = "Alle geflaggten Klienten";
    isMainActivitiesPage = false;
  }

  return (
    <>
      <SubHeader>
        <Box display={"flex"} width={"100%"}>
        <Box
            mt={2}
            style={{ width: "33%", color: "black" }}            
          >
            {/* <Box style={{
              cursor: "pointer",
              display: 'inline-block'
            }} onClick={() => {
              push(`activities?date=${previousDayUrl}`);
            }}>Zurück</Box> */}
          </Box>
          <Box style={{ width: "33%" }}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <DatePickerNavigator />
           </Box>
            {/* <Breadcrumb mapList={[{ name, path: "" }]} /> */}
          </Box>
          <Box
            mt={2}
            style={{ width: "33%", color: "black", direction: "rtl" }}            
          >
            {/* {!isInFeature && <Box style={{
              cursor: !isInFeature ? "pointer" : "default",
              display: 'inline-block'
            }} onClick={() => {
              if (!isInFeature) {
                push(`activities?date=${nextDayUrl}`);
              }
            }}>Nächste</Box>} */}
          </Box>
        </Box>
      </SubHeader>
      <Box paddingTop={13}>{children}</Box>
    </>
  );
};

export default ActivitiesTemplate;
