import React, { MutableRefObject, useState, useEffect } from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";

type TWriteAnswerInput = {
  onAbort: () => void;
  onSubmit: (message: string) => Promise<any>;
  signature?: string;
};

const WriteAnswerInput = ({
  onAbort,
  onSubmit,
  signature,
  endRef,
}: TWriteAnswerInput & { endRef: MutableRefObject<any> }) => {
  const [message, setMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    endRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signatureLength = signature ? signature.length + 1 : 0;
  const messageLength = message ? message.length : 0;
  return (
    <Box>
      <Box>
        <Box borderBottom="solid #BDBDBD 1px" mb={1}>
          <Typography variant="body2">Nachricht</Typography>
        </Box>
        <TextField
          minRows={3}
          multiline
          inputProps={{
            maxLength: 160,
          }}
          fullWidth
          value={message}
          onChange={event => {
            setMessage(event.target.value);
          }}
        />
      </Box>
      <Typography
        variant="body2"
        color={message && signatureLength + messageLength > 160 ? "error" : "textSecondary"}
      >
        {message && signatureLength + message.length > 159
          ? "Du hast das Zeichenlimit erreicht"
          : `Noch ${160 - (messageLength + signatureLength)} Zeichen`}
      </Typography>
      <Box mt={1}>
        <Typography variant="body2" color="secondary" style={{ fontStyle: "italic" }}>
          Die Signatur wird automatisch hinzugefügt.
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" mt={4}>
        <SecondaryButton onClick={onAbort}>Abbrechen</SecondaryButton>
        <Box mx={1} />
        <PrimaryButton
          disabled={submitting || message.trim().length < 2}
          onClick={async () => {
            setSubmitting(true);
            await onSubmit(message);
            setSubmitting(false);
            onAbort();
          }}
        >
          Absenden
        </PrimaryButton>
      </Box>
    </Box>
  );
};

export default WriteAnswerInput;
