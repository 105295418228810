import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";

import TypeIcon from "../../atoms/TypeIcon";
import useStyles from "./styles";

type TTab = { active?: boolean; type: string; target: string };
const Tab: FC<TTab> = ({ active, children, type, target }) => {
  const classes = useStyles();

  return (
    <Box display="flex" color="primary" mr={6} className={classes.tab}>
      <TypeIcon type={type} active={!!active} />
      <Link component={RouterLink} to={target} underline="always" color="primary" variant="body1">
        {children}
      </Link>
    </Box>
  );
};

export default Tab;
