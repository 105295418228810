import React from 'react';
import { useParams } from "react-router-dom";
import { usePersonSubscription } from "../../../hooks/usePersonSubscription";
import IllustratedErrorMessage from "../../organisms/IllustratedErrorMessage";
import errorIllustration from "../../assets/errorIllustration.svg";
import { useMeQuery } from "../../../generated/graphql";

interface PersonViewRestrictionsProps {
  children: React.ReactNode;
}

export const restrictedAccess = (
  meUserId: number | undefined,
  accountId: number | undefined | null) => {

  return meUserId === 181 && accountId === 17 ? true : false;
}

const PersonViewRestrictions: React.FC<PersonViewRestrictionsProps> = ({ children }) => {
  // Sometimes the param is the route is named :id and sometimes :personId
  const params = useParams<{ id?: string; personId?: string }>();
  const personId = params.personId || params.id;

  const { person } = usePersonSubscription(personId ? parseInt(personId, 10) : 0);
  const { data } = useMeQuery({ fetchPolicy: "cache-first" });

  if (restrictedAccess(data?.me?.user?.id, person?.client?.account?.id)) {
    return (
      <IllustratedErrorMessage
        messages={["Du hast keine Zugriffsberechtigung für Klienten des Accounts \"Sparkasse Südholstein\"."]}
        illustration={errorIllustration}
        illustrationAltText="Elli rutscht auf einer Banane aus"
      >
      </IllustratedErrorMessage>
    );
  }

  return <>{children}</>;
};

export default PersonViewRestrictions;