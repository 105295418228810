import React, { useEffect, Fragment } from "react";
import dayjs from "dayjs";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TimeChip from "../../atoms/TimeChip";
import ActivityItem from "./item";
import { path } from "ramda";
import classnames from "classnames";
import "./styles.css";
import useStyles from "./styles";
import { PersonActivity } from "../../../generated/graphql";
import { useAuthorization } from "../../../providers/AuthorizationProvider";
import {
  getMonth,
  getYear,
  isNextMonth,
  isNextYear,
  isUniqueDayInCurrentMonth,
  getDay,
  isToday,
} from "../../../utils/dateHelper";

import { TAKE } from "../../../utils/constants";

// TODO move to providers
export const ActivitiesStreamContext = React.createContext<{
  context?: "global" | "business" | "person";
}>({
  context: undefined,
});

function ActivitiesList({
  activities,
  hasMore,
  onLoadMore,
  past,
  personId,
  topicId,
  withClient,
}: {
  hasMore: boolean;
  activities: PersonActivity[];
  onLoadMore: (arg0: PersonActivity[]) => void;
  personId?: number;
  past?: boolean;
  topicId?: number;
  withClient?: boolean;
}) {
  let iterDay: dayjs.Dayjs;
  let iterMonth: dayjs.Dayjs;
  let iterYear: dayjs.Dayjs;

  const classes = useStyles();
  const { me } = useAuthorization();

  useEffect(() => {
    const handleOnScroll = () => {
      const scrollTop =
        (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      const scrollHeight =
        (document.documentElement && document.documentElement.scrollHeight) ||
        document.body.scrollHeight;
      const clientHeight = document.documentElement.clientHeight || window.innerHeight;
      const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
      if (scrolledToBottom) {
        onLoadMore(activities);
      }
    };
    if (hasMore) window.addEventListener("scroll", handleOnScroll);
    return () => window.removeEventListener("scroll", handleOnScroll);
  }, [activities, hasMore, onLoadMore]);  
  return (
    // Once we need specific contexts for business/global also, adjust here!
    <ActivitiesStreamContext.Provider value={{ context: !!personId ? "person" : "global" }}>
      <List disablePadding className={classnames(classes.root, { [classes.past]: past })}>
        {activities.map((activity: PersonActivity, index: number) => {
          let showTimeChip = false;
          if (!isToday(dayjs(activity.lastUpdate))) {
            showTimeChip = isUniqueDayInCurrentMonth(iterDay, dayjs(activity.lastUpdate));
            showTimeChip = showTimeChip || isNextMonth(iterMonth, dayjs(activity.lastUpdate));
            showTimeChip = showTimeChip || isNextYear(iterYear, dayjs(activity.lastUpdate));
          }

          iterDay = getDay(iterDay, dayjs(activity.lastUpdate));
          iterMonth = getMonth(iterMonth, dayjs(activity.lastUpdate));
          iterYear = getYear(iterYear, dayjs(activity.lastUpdate));

          return (
            // Updating a service should update the corresponding activity bubble.
            <Fragment key={`fragment-${activity.id}-${activity.lastUpdate}`}>
              {showTimeChip && (
                <ListItem alignItems="center">
                  <TimeChip date={activity.lastUpdate} />
                </ListItem>
              )}
              <ActivityItem
                topicId={topicId}
                withClient={withClient}
                key={`${activity.id}`}
                meData={me}
                delay={index % TAKE}
                activity={activity}
                personId={personId || path(["personId"], activity)}
              />
            </Fragment>
          );
        })}
      </List>
    </ActivitiesStreamContext.Provider>
  );
}

export default ActivitiesList;
