import React, { useState } from "react";

import dayjs from "dayjs";
import Box from "@material-ui/core/Box";
import MuLink from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import { User, PapershiftWorkingArea } from "../../../generated/graphql";

import absenceIcon from "../../assets/absenceIcon.svg";
import UserDetails from "../../molecules/UserDetails";
import DefaultDialog from "../../molecules/DefaultDialog";
import DialogContent from "../../molecules/DefaultDialogContent";
import { useAuthorization } from "../../../providers/AuthorizationProvider";

import AbsenceDialog from "../AbsenceDialog";

interface IUserDialog {
  open: boolean;
  onClose: () => void;
  user: User;
  workingArea?: PapershiftWorkingArea;
}

export const UserDialog = ({ open, onClose, user, workingArea }: IUserDialog) => {
  const isCurrentlyAbsent = user.absentUntil && dayjs(user.absentUntil).isAfter(dayjs());
  const [absenceOpen, setAbsenceOpen] = useState(false);
  const { isAuthorized } = useAuthorization();

  let absentUntil = (
    <Box display="flex" alignItems="center">
      <Box width="40px" textAlign="right">
        <img src={absenceIcon} alt="Mitarbeiter als abwesend eintragen" />
      </Box>
      <Box mx={2}>
        <Typography color="inherit" variant="h4">
          Abwesend bis: {dayjs(user.absentUntil).format("DD.MM.YYYY")}
        </Typography>
      </Box>
    </Box>
  );

  if (isAuthorized("addAbsence")) {
    absentUntil = (
      <MuLink
        color="primary"
        underline="always"
        style={{ cursor: "pointer", display: "inline-block" }}
        onClick={() => setAbsenceOpen(true)}
        variant="h4"
      >
        {absentUntil}
      </MuLink>
    );
  }

  return (
    <DefaultDialog open={open} backdrop onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <UserDetails user={user} workingArea={workingArea} />
        {isCurrentlyAbsent && (
          <Box width={1}>
            {absentUntil}
            <AbsenceDialog user={user} open={absenceOpen} onClose={() => setAbsenceOpen(false)} />
          </Box>
        )}
      </DialogContent>
    </DefaultDialog>
  );
};

export default UserDialog;
