import React, { useState, useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import {
  usePersonQuery,
  useMemoQuery,
  useBusinessObjectByPersonQuery,
  MemoState,
  Memo,
  useUpdateClientMemoMutation,
  Person,
  PersonQueryVariables,
  useUpdateMemoMutation,
} from "../../../generated/graphql";
import Loader from "../../atoms/Loading";
import MemoUpdateFormStep1 from "../../forms/MemoUpdateFormStep1";
import MemoWizard from "../../forms/MemoWizard";
import { memoMap } from "../../../utils/memoHelpers";
import useStyles from "./styles";
import { fetchPolicy } from "../../../utils/constants";

export default function MemoUpdate() {
  const classes = useStyles();
  const { personId, reportId } = useParams<{ personId: string; reportId: string }>();
  const personQuery = usePersonQuery({
    variables: { id: parseInt(personId, 10) },
    fetchPolicy,
  });

  const memoQuery = useMemoQuery({
    variables: { id: parseInt(reportId, 10) },
    fetchPolicy,
  });

  const businessQuery = useBusinessObjectByPersonQuery({
    variables: { personId: parseInt(personId, 10) },
    fetchPolicy,
  });

  const person = personQuery.data?.person;
  const refetch = personQuery.refetch;
  const client = person?.client;
  const memo = memoQuery.data?.memo;

  const [step, setStep] = useState(1);

  useEffect(() => {
    if (memo && memo.status) {
      if (memo.status === MemoState.finished) {
        setStep(2);
      } else {
        setStep(1);
      }
    }
  }, [memo]);

  if (!personQuery.loading && person && !client && businessQuery.data?.businessObjectByPerson?.id) {
    return (
      <Redirect
        to={`/business/${businessQuery.data?.businessObjectByPerson.id}/memo/${reportId}`}
      />
    );
  }

  function renderForm() {
    if (person && memo) {
      if (step! > 0) {
        if (client) {
          return (
            <UpdateClientMemo
              person={person}
              memo={memo}
              refetch={refetch}
              step={step}
              setStep={setStep}
            />
          );
        } else {
          return (
            <UpdatePersonMemo
              person={person}
              memo={memo}
              refetch={refetch}
              step={step}
              setStep={setStep}
            />
          );
        }
      } else {
        return (
          // MemoUpdateFormStep1 renders MemoReportFormStep1 (might be even further unified)
          <MemoUpdateFormStep1
            person={person}
            memo={memo}
            customCallBack={() => {
              setStep(step! + 1);
              personQuery.refetch();
            }}
          />
        );
      }
    }
    return <Loader />;
  }

  return !person || !memo || step === undefined ? (
    <Loader />
  ) : (
    <Grid container className={classes.content}>
      <Grid item xs={2} xl={3} />
      <Grid item xs={8} xl={6}>
        {renderForm()}
      </Grid>
      <Grid item xs={2} xl={3} />
    </Grid>
  );
}

type TUpdateClientMemo = {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  memo: Memo;
  person: Person;
  refetch(variables?: PersonQueryVariables | undefined): Promise<any>;
};

const UpdateClientMemo = ({ memo, person, refetch, step, setStep }: TUpdateClientMemo) => {
  const client = person.client;

  const [updateClientMemo] = useUpdateClientMemoMutation();

  const backAction = async () => {
    await updateClientMemo({
      variables: {
        clientId: client!.id!,
        memo: {
          id: memo.id,
          status: memoMap[step - 1],
        },
      },
    });
    setStep(step - 1);
  };

  return (
    <MemoWizard
      setStep={setStep}
      person={person}
      clientId={client!.id!}
      memo={memo}
      step={step}
      backAction={backAction}
      refetch={refetch}
    />
  );
};

type TUpdatePersonMemo = {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  memo: Memo;
  person: Person;
  refetch(variables?: PersonQueryVariables | undefined): Promise<any>;
};

const UpdatePersonMemo = ({ step, setStep, memo, person, refetch }: TUpdatePersonMemo) => {
  const [updateMemo] = useUpdateMemoMutation();

  const backAction = async () => {
    await updateMemo({
      variables: {
        memo: {
          id: memo.id,
          status: memoMap[step - 1],
        },
      },
    });
    setStep(step - 1);
  };

  return (
    <MemoWizard
      setStep={setStep}
      person={person}
      memo={memo}
      step={step}
      backAction={backAction}
      refetch={refetch}
    />
  );
};
