import React from "react";

import { FormikProps, FormikValues } from "formik";

import AddressField from "../../../forms/AddressField";
import { Address } from "../../../../generated/graphql";

import { ContactFormWrapper, ContactFormElementWrapper, ContactFormProps } from "./components";

const ContactAddressesForm = ({
  cancel,
  create,
  remove,
  handleSubmit,
  validateForm,
  values,
}: ContactFormProps & FormikProps<FormikValues>) => {
  const isPlural = values.create.length + values.update.length > 1;

  return (
    <ContactFormWrapper
      cancel={cancel}
      onCreate={create}
      onCreateTitle="Weitere Adresse anlegen"
      onSubmit={handleSubmit}
      title={`Adresse${isPlural ? "n" : ""}`}
    >
      {values.update.map((address: Address, index: number) => {
        return (
          <ContactAddressForm
            key={address.id || "address"}
            address={address}
            index={index}
            method="update"
            // @ts-ignore
            deleteAddress={() => remove(address.id)}
          />
        );
      })}
      {values.create.map((address: Address, index: number) => {
        return (
          <ContactAddressForm
            key={`newAddress-${index}`}
            address={address}
            index={index}
            method="create"
            deleteAddress={
              index > 0 || values.update.length > 0
                ? () => {
                    values.create.splice(index, 1);
                    validateForm();
                  }
                : undefined
            }
          />
        );
      })}
    </ContactFormWrapper>
  );
};

type TContactAddressForm = {
  address: Address;
  deleteAddress?: () => void;
  index: number;
  method: string;
};
const ContactAddressForm = ({ address, deleteAddress, index, method }: TContactAddressForm) => {
  return (
    <ContactFormElementWrapper
      onDelete={deleteAddress}
      contactPermissions={[
        {
          label: "Briefverkehr erlaubt",
          name: `${method}[${index}].contactAllowed`,
          value: !!address.contactAllowed,
        },
      ]}
    >
      <AddressField namePrefix={`${method}[${index}]`} />
    </ContactFormElementWrapper>
  );
};

export default ContactAddressesForm;
