import React from "react";

import { Formik, Field } from "formik";
import { TextField } from "formik-material-ui";
import { useMutation } from "@apollo/client";

import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import {
  Service,
  Person,
  CreateServiceAttributeDocument,
  DeleteServiceAttributeDocument,
  UpdateServiceAttributeDocument,
  ServiceAttribute,
} from "../../../../generated/graphql";

import useStyles from "../styles";
import PrimaryButton from "../../../atoms/PrimaryButton";
import WizardNavigation from "../../../molecules/WizardNavigation";
import SecondaryButton from "../../../atoms/SecondaryButton";

interface Props {
  backAction: () => void;
  service: Service;
  person: Person;
  serviceMetaAttribute: any;
  setStep: (step: number) => void;
  step: number;
  update: () => void;
}

export default function ValueSelector({
  serviceMetaAttribute,
  service,
  setStep,
  step,
  update,
  backAction,
}: Props) {
  const classes = useStyles();
  const [createServiceAttribute] = useMutation(CreateServiceAttributeDocument);
  const [deleteServiceAttribute] = useMutation(DeleteServiceAttributeDocument);
  const [updateServiceAttribute] = useMutation(UpdateServiceAttributeDocument);
  const next = () => {
    setStep(step + 1);
  };
  const defaults = serviceMetaAttribute?.defaults || [];
  let attributes = (service.serviceAttributes as ServiceAttribute[]) || [];
  attributes = attributes.filter(attr => attr.serviceMetaAttributeId === serviceMetaAttribute.id);
  const individualAttr = attributes.find(attr => {
    return !defaults.includes(attr.value);
  });
  return (
    <>
      <Box display="flex" justifyContent="center">
        <div>
          {defaults.map((value: any) => {
            const selected = attributes.find((item: any) => item.value === value);
            const handleChange = () => {
              if (selected) {
                return deleteServiceAttribute({
                  variables: { id: selected.id },
                }).then(update);
              }
              return createServiceAttribute({
                variables: {
                  serviceAttribute: {
                    serviceMetaAttributeId: serviceMetaAttribute.id,
                    value,
                    serviceId: service.id,
                  },
                },
              }).then(update);
            };
            return (
              <Box
                mb={2}
                key={`${value}-${serviceMetaAttribute.name}-values-${serviceMetaAttribute.id}`}
                style={{ marginBottom: 8 }}
              >
                <FormControlLabel
                  control={<Checkbox checked={!!selected} onChange={handleChange} />}
                  label={value}
                />
              </Box>
            );
          })}
          <Formik
            key={serviceMetaAttribute.id}
            initialValues={{
              value: !!individualAttr ? individualAttr.value : "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              if (!!individualAttr) {
                updateServiceAttribute({
                  variables: {
                    serviceAttribute: {
                      value: values.value,
                      id: individualAttr.id,
                      serviceId: service.id,
                    },
                  },
                }).then(() => {
                  setSubmitting(false);
                  update();
                });
              } else {
                createServiceAttribute({
                  variables: {
                    serviceAttribute: {
                      serviceMetaAttributeId: serviceMetaAttribute.id,
                      value: values.value,
                      serviceId: service.id,
                    },
                  },
                }).then(() => {
                  setSubmitting(false);
                  update();
                });
              }
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit} onBlur={handleSubmit}>
                <FormControl
                  className={classes.formControl}
                  margin="normal"
                  style={{ marginTop: 8 }}
                >
                  <Field
                    name="value"
                    component={TextField}
                    label="Sonstige"
                    key={`${serviceMetaAttribute.name}-else-${serviceMetaAttribute.id}`}
                  />
                </FormControl>
              </form>
            )}
          </Formik>
        </div>
      </Box>
      <WizardNavigation>
        <SecondaryButton onClick={() => backAction()} tabIndex={defaults.length + 2}>
          Zurück
        </SecondaryButton>
        <PrimaryButton
          onClick={next}
          disabled={serviceMetaAttribute.required && attributes.length === 0}
          tabIndex={defaults.length + 2}
        >
          Weiter
        </PrimaryButton>
      </WizardNavigation>
    </>
  );
}
