import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  ClientAttribute,
  Maybe,
  useBreadCrumbInfoByPersonIdQuery,
  useCreateCaseManagementReviewMutation,
  useCreateCaseManagerMutation,
  User,
  useUpdateCaseManagerMutation,
  Team,
} from "../../../generated/graphql";
import { useCaseManagementReviewSubscription } from "../../../hooks/useCaseManagementReviewSubscription";
import { useCaseManagerSubscription } from "../../../hooks/useCaseManagerSubscription";
import { usePersonSubscription } from "../../../hooks/usePersonSubscription";
import { useRiskHistorySubscription } from "../../../hooks/useRiskHistorySubscription";
import CaseManagementIcon from "../../assets/caseManagement.svg";
import GirlWithPencil from "../../assets/GirlWithPencil";
import Avatar from "../../atoms/Avatar";
import DataColumn from "../../atoms/Column";
import CustomIconButton from "../../atoms/CustomIconButton";
import Loader from "../../atoms/Loading";
import Markup from "../../atoms/Markup";
import SecondaryButton from "../../atoms/SecondaryButton";
import UnassignedUserButton from "../../atoms/UnassignedUserButton";
import AccountStatusBadge from "../../molecules/AccountStatusBadge";
import Card from "../../molecules/Card";
import CaseManagementRisk from "../../molecules/CaseManagementRisk";
import DefaultDialog from "../../molecules/DefaultDialog";
import DefaultDialogContent from "../../molecules/DefaultDialogContent";
import AssignUserDialog from "../../organisms/AssignUserDialog";
import PersonLayout from "../../templates/PersonLayout";

export default function CaseManagement() {
  const { id } = useParams<{ id: string }>();
  const personId = parseInt(id, 10);

  const data = useRiskHistorySubscription(personId);
  const { person } = usePersonSubscription(personId);
  const review = useCaseManagementReviewSubscription(personId);
  const { caseManager, caseManagerLoading } = useCaseManagerSubscription(personId);

  const { data: breadcrumbData } = useBreadCrumbInfoByPersonIdQuery({
    skip: !personId,
    variables: {
      id: personId!,
    },
  });

  // as long as we do not treat errors explicitly, let's simplify loading/error/data state
  if (!person || !review || !person?.client || caseManagerLoading) {
    return <Loader />;
  }

  const flag = review?.caseManagementReviewAttributes?.flag;
  const riskAssessment = review?.caseManagementReviewAttributes?.riskAssessment;

  const risk = {
    flag: flag?.attributeValue,
    riskAssessment: riskAssessment?.attributeValue,
  };

  let lastUpdate, user;
  if (!!riskAssessment && !!flag) {
    user = dayjs(riskAssessment.lastUpdate).isAfter(flag.lastUpdate)
      ? riskAssessment.user
      : flag.user;
    lastUpdate = dayjs(riskAssessment.lastUpdate).isAfter(flag.lastUpdate)
      ? riskAssessment.lastUpdate
      : flag.lastUpdate;
  }

  const strategy = review?.caseManagementReviewAttributes?.strategy?.attributeValue;

  return (
    <PersonLayout
      contractExpirationDate={breadcrumbData?.breadCrumbInfoByPersonId?.contractEnd}
      language={breadcrumbData?.breadCrumbInfoByPersonId?.language || ""}
      person={person}
      mapList={[
        {
          name: "Case Management",
          path: "",
        },
      ]}
    >
      <CaseManagementOverview
        caseManager={caseManager}
        clientId={person.client!.id!}
        personId={person.id!}
        updatedAt={review.lastUpdate?.updatedAt}
      />
      {!review.lastUpdate?.updatedAt ? (
        <CaseManagementTeaser />
      ) : (
        <>
          <Box mb={4}>
            <Card title="Risiko">
              <CaseManagementRisk risk={risk} user={user} lastUpdate={lastUpdate} />
            </Card>
          </Box>
          <CaseManagementStrategy strategy={strategy} />
          <CaseManagementHistory history={data?.riskHistory} />
        </>
      )}
    </PersonLayout>
  );
}

function CaseManagementTeaser() {
  return (
    <Box textAlign="center" mt={10}>
      <GirlWithPencil />
      <Typography variant="body2">
        Es sind noch keine Daten vom Case Management hinterlegt
      </Typography>
    </Box>
  );
}

function CaseManagementOverview({
  caseManager,
  clientId,
  personId,
  updatedAt,
}: {
  caseManager?: User | null;
  clientId: number;
  personId: number;
  updatedAt?: dayjs.Dayjs;
}) {
  const [open, setOpen] = useState(false);
  const { push } = useHistory();
  const [createCaseManagementReview] = useCreateCaseManagementReviewMutation({
    variables: {
      clientId,
    },
  });
  const [createCaseManager] = useCreateCaseManagerMutation();
  const [updateCaseManager] = useUpdateCaseManagerMutation();

  const avatar = !caseManager ? (
    <UnassignedUserButton setOpen={() => setOpen(true)} />
  ) : (
    <CustomIconButton onClick={() => setOpen(true)}>
      <Avatar src={caseManager.person?.picture || caseManager.person?.avatar || ""} widthfactor={5}>
        {caseManager.person?.avatarKeys}
      </Avatar>
    </CustomIconButton>
  );

  return (
    <Box display="flex">
      <Card
        icon={
          <Box display="flex" alignItems="center">
            <img src={CaseManagementIcon} alt="CaseManagementIcon" />
            <Box width={16} />
            <Typography variant="h3" color="primary">
              Case Management
            </Typography>
          </Box>
        }
        actionIcon={
          <Box display="flex" alignItems="center">
            <Typography variant="h3" color="textSecondary">
              Case Manager
            </Typography>
            <Box width={16} />
            {avatar}
          </Box>
        }
      >
        <Grid container>
          <DataColumn label="Letzte Änderung">{updatedAt?.format("DD.MM.YYYY") || "-"}</DataColumn>
          <Box display="flex" flex={1} justifyContent="flex-end" alignItems="baseline">
            <SecondaryButton
              style={{ minWidth: 200 }}
              onClick={async () => {
                await createCaseManagementReview();
                push(`/person/${personId}/caseManagement/review`);
              }}
            >
              Review durchführen
            </SecondaryButton>
          </Box>
        </Grid>
      </Card>
      <DefaultDialog open={open} onClose={() => setOpen(false)}>
        <DefaultDialogContent>
          <AssignUserDialog
            allowReset
            onClose={() => setOpen(false)}
            user={caseManager}
            onSubmit={async userId => {
              // 'caseManager === undefined' means, no caseManager has been set before
              // 'caseManager === null' means, caseManager has been removed
              if (caseManager === undefined) {
                if (userId) {
                  await createCaseManager({
                    variables: {
                      clientId,
                      userId,
                    },
                  });
                }
              } else {
                await updateCaseManager({
                  variables: {
                    clientId,
                    userId,
                  },
                });
              }

              setOpen(false);
            }}
            headline={!!caseManager ? "Case Manager wechseln" : "Case Manager zuweisen"}
            teams={[Team.caseManagement]}
          />
        </DefaultDialogContent>
      </DefaultDialog>
    </Box>
  );
}

function CaseManagementStrategy({ strategy }: { strategy?: string }) {
  if (!strategy) {
    return null;
  }

  return (
    <Card title="Strategie für die Gesprächsführung">
      <Box mb={4}>
        <Markup value={strategy}></Markup>
      </Box>
    </Card>
  );
}

function CaseManagementHistory({ history }: { history?: Maybe<ClientAttribute>[] | null }) {
  if (!history) {
    return null;
  }

  // @ts-ignore
  const riskHistory = history.map(convertDate).sort(sortByDate);

  // @ts-ignore
  const risks = riskHistory.filter(attribute => attribute.clientReviewAttribute?.id === 9);
  // @ts-ignore
  const flags = riskHistory.filter(attribute => attribute.clientReviewAttribute?.id === 10);

  if (risks.length < 1 && flags.length < 1) {
    return null;
  }

  const merged = constructHistory(risks, flags);

  return (
    <Card title="Risiko-Verlauf">
      {merged.reverse().map((item, index) => {
        const flag = item.flag;
        const riskAssessment = item.risk;

        let lastUpdate, user;
        if (!!riskAssessment && !!flag) {
          user = dayjs(riskAssessment.lastUpdate).isAfter(flag.lastUpdate)
            ? riskAssessment.user
            : flag.user;
          lastUpdate = dayjs(riskAssessment.lastUpdate).isAfter(flag.lastUpdate)
            ? riskAssessment.lastUpdate
            : flag.lastUpdate;
        }

        if (!user || !lastUpdate) {
          return null;
        }
        return (
          <CaseManagementRisk
            key={String(lastUpdate)}
            risk={{
              flag:
                index + 1 === merged.length && flag?.attributeValue === "unflagged"
                  ? null
                  : (flag?.attributeValue as "redFlag" | "yellowFlag" | "whiteFlag" | "unflagged"),
              riskAssessment: riskAssessment?.attributeValue || "",
            }}
            user={user}
            lastUpdate={lastUpdate}
          />
        );
      })}
    </Card>
  );
}

type Merged = {
  risk: ClientAttribute | undefined;
  flag: ClientAttribute | undefined;
};

const convertDate = (attribute: ClientAttribute): ClientAttribute => {
  return {
    ...attribute,
    start: dayjs(attribute.start),
  };
};

const sortByDate = (a: ClientAttribute, b: ClientAttribute) => {
  if (a.start.isAfter(b.start)) {
    return -1;
  } else if (b.start.isAfter(a.start)) {
    return 1;
  } else {
    return 0;
  }
};

const constructHistory = (risks: ClientAttribute[], flags: ClientAttribute[]) => {
  const risk = risks.pop();
  const flag = flags.pop();

  return iterate([{ risk, flag }], risks, flags);
};

const merge = (merged: Merged[], flag: ClientAttribute, risk: ClientAttribute) => {
  return [
    ...merged,
    {
      risk,
      flag,
    },
  ];
};

const iterate = (
  merged: Merged[],
  risks: ClientAttribute[],
  flags: ClientAttribute[],
): Merged[] => {
  if (risks.length < 1 && flags.length < 1) {
    return merged;
  }

  if (risks.length < 1 && flags.length > 0) {
    const flag = flags.pop();
    // @ts-ignore: flag.length > 1 has been checked before!
    return iterate(merge(merged, flag, merged[merged.length - 1].risk), risks, flags);
  }
  if (flags.length < 1 && risks.length > 0) {
    const risk = risks.pop();
    // @ts-ignore: risk.length > 1 has been checked before!
    return iterate(merge(merged, merged[merged.length - 1].flag, risk), risks, flags);
  }

  const lastRisk = risks[risks.length - 1].start;
  const lastFlag = flags[flags.length - 1].start;
  const difference = dayjs(lastRisk).diff(dayjs(lastFlag), "second");

  if (difference < 1 && difference > -1) {
    const risk = risks.pop();
    const flag = flags.pop();
    // @ts-ignore
    return iterate(merge(merged, flag, risk), risks, flags);
  } else if (lastRisk.isBefore(lastFlag)) {
    const risk = risks.pop();
    // @ts-ignore: risk.length > 1 has been checked before!
    return iterate(merge(merged, merged[merged.length - 1].flag, risk), risks, flags);
  } else if (lastFlag.isBefore(lastRisk)) {
    const flag = flags.pop();
    // @ts-ignore: flag.length > 1 has been checked before!
    return iterate(merge(merged, flag, merged[merged.length - 1].risk), risks, flags);
  }

  return merged;
};
