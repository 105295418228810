import React, { useRef, useEffect, useState, useContext } from "react";

import { CellMeasurer, CellMeasurerCache, AutoSizer, List } from "react-virtualized";
import Box from "@material-ui/core/Box";

import { PersonActivity, PersonQuery } from "../../../generated/graphql";
import { Message } from "../../../containers/ChatListener/reducer";
import PersonHeader from "../../molecules/PersonHeader";
import ChatMessage from "../../molecules/ChatMesssage";

import ChatInput from "./ChatInput";
import { ChatContext } from "../../../containers/ChatListener";

type TChat = {
  activity?: PersonActivity;
  history: Message[];
  onFetchMore?: (uid: number) => void;
  onSubmit?: (text: string) => void;
  withInput?: boolean;
  person: PersonQuery["person"];
};

const Chat = ({ activity, withInput, history, onFetchMore, onSubmit, person }: TChat) => {
  const { token } = useContext(ChatContext);

  const [submitError, setSubmitError] = useState(null);
  const isInitialized = useRef(false);
  const bottomMessageUid = useRef<null | number>(null);
  const lastMessageUid = useRef<null | number>(null);

  const cache = new CellMeasurerCache({
    defaultHeight: 80,
    fixedWidth: true,
  });

  useEffect(() => {
    isInitialized.current = false;
  }, [history.length]);

  const newestMessageUid = history.reduce<number | undefined>(
    (uid, message) => message.uid || uid,
    undefined,
  );

  const scrollIndex = bottomMessageUid.current
    ? history.findIndex(message => message.uid === bottomMessageUid.current)
    : history.length;

  return (
    <>
      <PersonHeader person={person} mapList={[{ name: "Chat", path: "" }]} />
      <Box position="fixed" top={128} bottom={withInput ? 163 : 65} right={0} left={0}>
        <AutoSizer>
          {({ width, height }) => {
            return (
              <List
                key={history.length}
                containerStyle={{ padding: "16px 0", width: "66.6666%", margin: "0 auto" }}
                deferredMeasurementCache={cache}
                rowHeight={cache.rowHeight}
                rowCount={history.length}
                height={height}
                width={width}
                scrollToIndex={scrollIndex}
                onRowsRendered={onRowRenderedProps => {
                  if (isInitialized.current) {
                    const uid = history[0].uid;

                    if (onRowRenderedProps.startIndex === 0) {
                      bottomMessageUid.current = history[onRowRenderedProps.stopIndex].uid;

                      if (lastMessageUid.current !== uid && onFetchMore) {
                        lastMessageUid.current = uid;

                        onFetchMore(uid);
                      }
                    }
                  } else {
                    isInitialized.current = true;
                  }
                }}
                rowRenderer={rowRendererProps => {
                  const message = history[rowRendererProps.index];

                  return (
                    <CellMeasurer cache={cache} {...rowRendererProps} key={message.uid}>
                      <div style={rowRendererProps.style}>
                        <ChatMessage message={message} avatarKeys={person.avatarKeys} />
                      </div>
                    </CellMeasurer>
                  );
                }}
              />
            );
          }}
        </AutoSizer>
        {token && !!onSubmit && !!activity && newestMessageUid && (
          <ChatInput
            activity={activity}
            onSubmit={text => {
              setSubmitError(null);

              try {
                onSubmit(text);
                bottomMessageUid.current = null;
              } catch (error) {
                console.error(error);
                setSubmitError(error.message);
              }
            }}
            lastMessageUid={newestMessageUid}
            token={token}
            error={submitError}
          />
        )}
      </Box>
    </>
  );
};

export default Chat;
