import React, { FC } from "react";
import classnames from "classnames";
import dayjs from "dayjs";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { isToday, isYesterday } from "../../../utils/dateHelper";

import useStyles from "../../assets/inboxStyles";

export const InboxWrapper: FC<{
  isHeadline?: boolean;
}> = ({ children, isHeadline = false }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classnames({
        [classes.task]: !isHeadline,
      })}
    >
      {children}
    </Grid>
  );
};

export const TimeText: FC<{
  start: string;
  end?: string;
  text?: string;
  bold?: boolean;
  overdue?: boolean;
  time?: boolean;
}> = ({ start, end, text, bold, overdue, time = true }) => {
  const classes = useStyles();
  const activityStart = dayjs(start);

  let startFormat = "dd. DD.MM.";
  if (isToday(activityStart)) {
    startFormat = "[Heute]";
  } else if (isYesterday(activityStart)) {
    startFormat = "[Gestern]";
  }
  if (time) {
    startFormat += " H:mm";
  }

  return (
    <Typography
      variant={bold ? "h3" : "body2"}
      className={classnames({
        [classes.overdue]: overdue,
      })}
    >
      {text && `${text}: `}
      {dayjs(start).format(`${startFormat}`)}
      {end ? `–${dayjs(end).format(time ? "H:mm" : "")}` : ""} {time && "Uhr"}
    </Typography>
  );
};
