import React from "react";

import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";

import { FormikProps, FormikValues, Field } from "formik";
import { TextField } from "formik-material-ui";

import { Email } from "../../../../generated/graphql";

import { ContactFormWrapper, ContactFormElementWrapper, ContactFormProps } from "./components";
import EmailTypeSelect from "../../../molecules/EmailTypeSelect";

type TContactEmailsForm = ContactFormProps & FormikProps<FormikValues>;
const ContactEmailsForm = ({
  cancel,
  create,
  remove,
  handleSubmit,
  validateForm,
  values,
}: TContactEmailsForm) => {
  const isPlural = values.create.length + values.update.length > 1;

  return (
    <ContactFormWrapper
      cancel={cancel}
      onCreate={create}
      onCreateTitle="Weitere E-Mail-Adresse anlegen"
      onSubmit={handleSubmit}
      title={`E-Mail-Adresse${isPlural ? "n" : ""}`}
    >
      {values.update.map((email: Email, index: number) => {
        return (
          <ContactEmailForm
            key={email.id || "email"}
            email={email}
            index={index}
            method="update"
            // @ts-ignore
            deleteEmail={() => remove(email.id)}
          />
        );
      })}
      {values.create.map((email: Email, index: number) => {
        return (
          <ContactEmailForm
            key={`newEmail-${index}`}
            email={email}
            index={index}
            method="create"
            deleteEmail={
              index > 0 || values.update.length > 0
                ? () => {
                    values.create.splice(index, 1);
                    validateForm();
                  }
                : undefined
            }
          />
        );
      })}
    </ContactFormWrapper>
  );
};

type TContactEmailForm = {
  email: Email;
  deleteEmail?: () => void;
  index: number;
  method: string;
};
const ContactEmailForm = ({ email, deleteEmail, index, method }: TContactEmailForm) => {
  return (
    <ContactFormElementWrapper
      onDelete={deleteEmail}
      contactPermissions={[
        {
          label: "Kontakt erlaubt",
          name: `${method}[${index}].contactAllowed`,
          value: !!email.contactAllowed,
        },
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <EmailTypeSelect name={`${method}[${index}].type`} />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field
              name={`${method}[${index}].email`}
              label="E-Mail-Adresse"
              component={TextField}
            />
          </FormControl>
        </Grid>
      </Grid>
    </ContactFormElementWrapper>
  );
};
export default ContactEmailsForm;
