import React from "react";
import FormControl from "@material-ui/core/FormControl";
import { Field, FieldProps } from "formik";

import { useBusinessCategoriesQuery, BusinessCategory, Maybe } from "../../../generated/graphql";

import Autocomplete from "../../molecules/Autocomplete";
import { fetchPolicy } from "../../../utils/constants";

type TBusinessCategorySelect = {
  businessCategory: any;
  setBusinessCategory: (p: any) => void;
};

const BusinessCategorySelect = ({
  businessCategory,
  setBusinessCategory,
}: TBusinessCategorySelect) => {
  const { data } = useBusinessCategoriesQuery({ fetchPolicy });
  const businessCategories = data?.businessCategories || [];

  return (
    <FormControl margin="normal" fullWidth>
      <Field
        name="businessCategoryId"
        render={({ field, form: { setFieldValue, setTouched, errors, touched } }: FieldProps) => {
          return (
            <Autocomplete
              hasError={!!errors[field.name] && !!touched[field.name]}
              error={!!errors[field.name] && !!touched[field.name] ? errors[field.name] : undefined}
              onBlur={() => {
                setTouched({ ...touched, [field.name]: true });
              }}
              label="Kategorie"
              placeholder="Bitte ausfüllen"
              handleChange={(o: any) => {
                setFieldValue(field.name, o.value);
                setBusinessCategory(o);
              }}
              value={businessCategory}
              suggestions={
                businessCategories
                  ? [
                      { value: "", label: "-" },
                      ...businessCategories.map((industry: Maybe<BusinessCategory>) => {
                        if (industry) {
                          return {
                            value: industry.id,
                            label: industry.name,
                          };
                        }
                        return null;
                      }),
                    ]
                  : [{ value: "", label: "-" }]
              }
            />
          );
        }}
      />
    </FormControl>
  );
};
export default BusinessCategorySelect;
