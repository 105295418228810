import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  active: {
    cursor: "pointer",
  },
  addLinkButton: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1),
  },
  justifyContent: {
    alignItems: "center",
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
  },
  phoneMenuItem: {
    height: "auto",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& > .MuiTouchRipple-root": {
      display: "none",
    },
  },
  phoneMenuItemIcon: {
    marginRight: theme.spacing(1),
  },
  disabled: {
    color: theme.palette.grey[500],
  },
  centered: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(1),
      marginRight: theme.spacing(1),
  }
}));

export default useStyles;
