import React from "react";

import Email from "@material-ui/icons/Email";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import Gavel from "@material-ui/icons/Gavel";
import LocalOffer from "@material-ui/icons/LocalOffer";
import AccountBox from "@material-ui/icons/AccountBox";
import Assignment from "@material-ui/icons/Assignment";
import Business from "@material-ui/icons/Business";
import Phone from "@material-ui/icons/Phone";
import Chat from "@material-ui/icons/Chat";
import Sms from "@material-ui/icons/Sms";
import ChatBubble from "@material-ui/icons/ChatBubble";
import PhoneCallback from "@material-ui/icons/PhoneCallback";
import PhoneInTalk from "@material-ui/icons/PhoneInTalk";
import RoomService from "@material-ui/icons/RoomService";
import SvgIcon from "@material-ui/core/SvgIcon";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";

import useStyles from "./styles";
import mailActive from "./img/mail_active.svg";
import callInComming from "./img/callInComming.svg";

const typeIconMap: { [index: string]: Function } = {
  sms: (classes: string) => <Sms className={classes} />,
  callIn: (classes: string, active?: boolean) => {
    if (active) {
      return <PhoneInTalk className={classes} />;
    }
    return <PhoneCallback className={classes} />;
  },
  callOut: (classes: string, active?: boolean) => {
    if (active) {
      return <PhoneInTalk className={classes} />;
    }
    return <Phone className={classes} />;
  },
  call: (classes: string, active?: boolean) => {
    if (active) {
      return (
        <SvgIcon className={classes}>
          <path d="M19 16.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.02.25l-2.2 2.2a15.097 15.097 0 0 1-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02A11.36 11.36 0 0 1 7.5 5a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1 17 17 0 0 0 17 17 1 1 0 0 0 1-1v-3.5a1 1 0 0 0-1-1zM13 7l5-5v3h4v4h-4v3l-5-5z" />
        </SvgIcon>
      );
    }
    return <img src={callInComming} className={classes} alt="incoming" />;
  },
  chat: (classes: string, active?: boolean) => {
    if (active) {
      // ChatBubble visually appears to be top aligned, acccount for it be pushing it down
      return <Chat className={classes} style={{ marginTop: "2px" }} />;
    }
    // ChatBubble visually appears to be top aligned, acccount for it be pushing it down
    return <ChatBubble className={classes} style={{ marginTop: "2px" }} />;
  },
  driveDocument: (classes: string, active?: boolean) => {
    return <InsertDriveFile className={classes} />;
  },
  mail: (classes: string, active?: boolean) => {
    if (active) {
      return <img className={classes} src={mailActive} alt="mailActive" />;
    }
    return <Email className={classes} />;
  },
  locations: (classes: string) => <Business className={classes} color="primary" />,
  insertDriveFile: (classes: string) => <InsertDriveFile className={classes} color="primary" />,
  gavel: (classes: string) => <Gavel className={classes} color="primary" />,
  permContactCalendar: (classes: string) => <AccountBox className={classes} color="primary" />,
  assignment: (classes: string, active?: boolean) => (
    <Assignment className={classes} color={!active ? "primary" : "secondary"} />
  ),
  packages: (classes: string) => <RoomService className={classes} color="primary" />,
  laptop: (classes: string) => <LaptopMacIcon className={classes} color="primary" />,
  other: (classes: string) => <LocalOffer className={classes} />,
};

const TypeIcon = (props: { type: string; active?: boolean }) => {
  const { type, active } = props;
  const classes = useStyles();
  if (typeIconMap[type]) {
    return typeIconMap[type](classes.typeIcon, active);
  }

  return typeIconMap["other"](classes.typeIcon, active);
};

export default TypeIcon;
