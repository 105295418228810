import React, { FC } from "react";

import { Select } from "formik-material-ui";
import { Field } from "formik";
import dayjs from "dayjs";

import Chip from "@material-ui/core/Chip/Chip";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";

import { User, useUsersQuery } from "../../../generated/graphql";
import { getUserName } from "../../../utils/userNameHelper";

import useStyles from "./styles";
import sortBy from "lodash/sortBy";
import { fetchPolicy } from "../../../utils/constants";

export const UserSelectFieldContainer: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const { data } = useUsersQuery({ fetchPolicy });

  const userArray = data?.users?.map(user => {
    return [user!.id, user!];
  });

  if (!userArray) {
    return null;
  }

  // @ts-ignore
  const users = new Map<number, User>(userArray);

  return (
    <UserSelectField
      disabled={disabled}
      title="Mitarbeiter zuweisen"
      users={users}
      multiple={false}
    />
  );
};

export default function UserSelectField({
  day,
  disabled,
  users,
  multiple = true,
  title,
}: {
  day?: dayjs.Dayjs;
  disabled?: boolean;
  users?: Map<number, User> | undefined;
  multiple?: boolean;
  title: string;
}) {
  const classes = useStyles();

  if (!users) {
    return null;
  }

  const sortedMap: Map<number, User> = new Map(
    sortBy([...users], (entry: any) => getUserName(entry[1]?.person)),
  );

  const options = [
    <MenuItem key={-1} value={-1} disabled={disabled}>
      nicht ausgewählt
    </MenuItem>,
  ];
  for (const [index, user] of sortedMap) {
    if (user.person && (!day || !user.absentUntil || day.isAfter(user.absentUntil))) {
      options.push(
        <MenuItem key={index} value={index}>
          {getUserName(user.person)}
        </MenuItem>,
      );
    }
  }

  return (
    <FormControl margin="normal" fullWidth disabled={!!disabled}>
      <InputLabel style={{ position: "absolute" }} htmlFor={title} shrink={true}>
        {title}
      </InputLabel>
      <Field
        disabled={!!disabled}
        defaultValue="-1"
        displayEmpty
        name="userIds"
        label={title}
        as="select"
        component={Select}
        multiple={multiple}
        renderValue={(selected: any) => {
          if (!multiple) {
            return (
              <Typography color={!disabled ? "primary" : "textSecondary"} variant="body2">
                {getUserName(users?.get(selected)?.person) || "nicht ausgewählt"}
              </Typography>
            );
          }
          if (!selected.length) {
            return (
              <Typography color={!disabled ? "primary" : "textSecondary"} variant="body2">
                nicht ausgewählt
              </Typography>
            );
          }

          return (
            <div className={classes.chips}>
              {selected.map((value: any) => {
                return (
                  <Chip
                    key={value}
                    label={getUserName(users.get(value)?.person)}
                    className={classes.chip}
                  />
                );
              })}
            </div>
          );
        }}
      >
        {options}
      </Field>
    </FormControl>
  );
}
