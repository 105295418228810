import Typography from "@material-ui/core/Typography";
import React, { FC, useContext } from "react";
import { ActivitiesStreamContext } from "..";
import { PersonActivity } from "../../../../generated/graphql";
import useStyles from "../styles";

type TPersonBubble = {
  activity: PersonActivity;
  isLink?: boolean;
};

export const PersonBubble: FC<TPersonBubble> = ({ activity, isLink }) => {
  const { context } = useContext(ActivitiesStreamContext);
  // Due to refactoring: https://medium.com/@calebmer/when-to-use-graphql-non-null-fields-4059337f6fc8
  let name = activity?.person?.name;
  const classes = useStyles();
  if (!name) {
    name = "Unbekannt (Fehler)";
  }

  return (
    <>
      <Typography variant="body2" className={`${isLink ? classes.clickableText : ""}`}>
        Systemmeldung
      </Typography>
      <Typography variant="h4">{activity.description}</Typography>
      {activity.activityType !== "person" && context !== "person" && (
        <Typography variant="body2">{name}</Typography>
      )}
    </>
  );
};

export default PersonBubble;
