import React, { useState } from "react";

import dayjs from "dayjs";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import MuLink from "@material-ui/core/Link";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import ActivitiesTemplate from "../../templates/Activities";
import GirlWithPencil from "../../assets/GirlWithPencil";
import { ServiceType, ServiceStatus, ServicesByTypeQuery } from "../../../generated/graphql";
import ActivityItem from "../../molecules/ActivitiesItem";
import useServicesByTypeSubscription from "../../../hooks/useServicesByTypeSubscription";

import useStyles from "./styles";
import { sortByLastActivity } from "./helper";

const ActivitiesInternal = () => {
  return (
    <ActivitiesTemplate>
      <ActiveSTCs />
      <FinishedSTCs />
    </ActivitiesTemplate>
  );
};

const ActiveSTCs = () => {
  const classes = useStyles();

  const { data, loading } = useServicesByTypeSubscription({
    type: ServiceType.internalConsult,
    statusNot: [ServiceStatus.finished],
  });

  if (loading) {
    return null;
  }

  const servicesByType = [...data?.servicesByType];

  if (!servicesByType || servicesByType.length < 1) {
    return (
      <Box mt={10} textAlign="center">
        <GirlWithPencil />
        <Box my={2} />
        <Typography variant="body2">Es gibt derzeit keine offenen Beratungsmandate</Typography>
      </Box>
    );
  }

  servicesByType.sort(sortByLastActivity);

  return (
    <Box className={classes.group}>
      <RenderSTCs servicesByType={servicesByType} />
    </Box>
  );
};

const FinishedSTCs = () => {
  const classes = useStyles();
  const [expand, setExpand] = useState(false);

  const { data, loading } = useServicesByTypeSubscription({
    type: ServiceType.internalConsult,
    status: [ServiceStatus.finished],
  });

  if (loading) {
    return null;
  }

  const servicesByType = [...data?.servicesByType];

  if (!servicesByType || servicesByType.length < 1) {
    // show nothing
    return null;
  }

  servicesByType.sort(sortByLastActivity);

  return (
    <>
      <Box mt={5}>
        <Typography variant="h3">Fertig</Typography>
        <Box className={classes.group} mt={2}>
          <Box
            height={72}
            textAlign="center"
            onClick={() => setExpand(!expand)}
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={classes.expand}
          >
            {expand ? (
              <KeyboardArrowUpIcon color="primary" />
            ) : (
              <KeyboardArrowDownIcon color="primary" />
            )}
            <Box mx={1}></Box>
            <Typography color="primary" variant="h4">
              Abgeschlossene interne Beratungen
            </Typography>
          </Box>
          {expand && <RenderSTCs servicesByType={servicesByType} />}
        </Box>
      </Box>
    </>
  );
};

const RenderSTCs = ({
  servicesByType,
}: {
  servicesByType: ServicesByTypeQuery["servicesByType"];
}) => {
  const classes = useStyles();

  const services = servicesByType?.map(service => {
    const person = service?.client?.person;
    const topic = service?.topic;
    const counselingUser = service?.counselingUser;

    if (!person) {
      return null;
    }

    return (
      <ActivityItem
        avatar={{
          src: counselingUser?.user?.person?.picture || counselingUser?.user?.person?.avatar,
          keys: counselingUser?.user?.person?.avatarKeys,
        }}
        user={counselingUser?.user}
        key={`service-${service!.id}`}
        person={person}
        title={
          <Box className={classes.csv}>
            <MuLink
              component={Link}
              to={`/internalConsult/${service!.id}`}
              color="primary"
              underline="always"
              variant="h4"
            >
              {topic?.name}, interne strukturierte Beratung
            </MuLink>
          </Box>
        }
        info={
          <Box display="flex">
            <Box flex={1}>
              <Typography variant="body2">
                Beratung angelegt: {service?.created && dayjs(service.created).format("DD.MM.YYYY")}
              </Typography>
            </Box>
            <Box mx={1} />
            <Box flex={1}>
              <Typography variant="body2">
                Letzte Aktivität:{" "}
                {service?.lastActivity && dayjs(service.lastActivity).format("DD.MM.YYYY")}
              </Typography>
            </Box>
          </Box>
        }
      />
    );
  });

  return <>{services}</>;
};

export default ActivitiesInternal;
