import Typography from "@material-ui/core/Typography";
import dayjs from "dayjs";
import pathOr from "ramda/src/pathOr";
import React, { FC, useContext } from "react";
import removeMd from "remove-markdown";
import { ActivitiesStreamContext } from "..";
import { MemoType, PersonActivity, TaskType } from "../../../../generated/graphql";
import { trimMarkdown } from "../../../../utils/markdown";
import Markup from "../../../atoms/Markup";
import useStyles from "../styles";




const isBusinessMemo = (activity: PersonActivity) => {
  const memo = activity.memo;

  if (!memo) {
    return false;
  }

  return memo.type === MemoType.business && !!memo.business;
};

const getStatus = (
  activity: PersonActivity,
): "active" | "missed" | "inProgress" | "finished" | "failed" => {
  if (activity.isActive) {
    return "active";
  }

  if (!activity.author) {
    return "missed";
  }

  const task = activity.tasks?.find(task => task?.taskType === TaskType.createConversationReport);
  if (!!task && !task?.finishedAt) {
    return "inProgress";
  }

  if (activity.activityType === "callOut" && !activity.tasks?.length) {
    return "failed";
  }

  return "finished";
};

export const getTargetAndState = ({
  activity,
  userId,
  pathname,
  personId,
  topicId,
}: {
  activity: PersonActivity;
  userId?: number | null;
  pathname: string;
  personId?: number | null;
  topicId?: number | null;
}) => {
  const status = getStatus(activity);
  let state: any, target: string | undefined;
  switch (status) {
    case "active":
      if (activity.activityType === "mail") {
        target = activity?.path || "";
      }
      break;
    case "inProgress":
      if (userId === activity.author?.id) {
        if (!!activity.memo) {
          target = `/person/${personId}/memo/${activity.memo.id}`;
        } else if (!!activity.conversationReport) {
          target = `/person/${personId}/report/${activity.conversationReport.id}`;
        } else {
          if (activity?.person?.businessIds?.length) {
            target = `/business/${activity.person.businessIds[0]}/memo`;
          }
          else {
            target = `/person/${personId}/report`;
          }
        }
        state = { activity };
      }
      break;
    case "finished":
      if (isBusinessMemo(activity)) {
        target = `/business/${activity.memo!.business?.id}/activities/${activity.id}`;
      } else {
        target = `/person/${personId}/${topicId ? `topics/${topicId}/` : ""}activities/${activity.id
          }`;
      }
      state = { activity, goBackPath: pathname };
      break;
  }

  return { state, target };
};

const getDuration = (activity: PersonActivity) => {
  if (!activity.author) {
    return "";
  }

  const start = dayjs(activity.start);
  const hasEnd = !!activity.end;
  const end = hasEnd ? dayjs(activity.end) : dayjs();

  const diff = end.diff(start, "minute");

  return `(${!hasEnd ? "seit " : ""}${diff} min.)`;
};

const getHeadline = (activity: PersonActivity, context?: "person" | "global" | "business") => {
  let contact;
  if (context !== "person") {
    // Due to refactoring: https://medium.com/@calebmer/when-to-use-graphql-non-null-fields-4059337f6fc8
    contact =
      activity.memo?.contact ||
      activity.person?.name ||
      activity.memo?.person?.name ||
      "Unbekannt (Fehler)";
  }


  switch (activity.activityType) {
    case "callIn":
      return `Eingehendes Telefonat ${getDuration(activity)}${contact ? ` mit ${contact}` : ""}`;
    case "callOut":
      return `Ausgehendes Telefonat ${getDuration(activity)}${contact ? ` mit ${contact}` : ""}`;
    case "chat":
      return `Chat ${getDuration(activity)}${contact ? ` mit ${contact}` : ""}`;
    case "wpoVerweisung":
      return `${activity.isActive ? "Offene" : "Abgeschlossene"} E-Mail Konversation mit WPO`;
    case "mail":
      const name = activity?.person?.temporary
        ? pathOr("Unbekannt", ["person", "emails", 0, "email"], activity)
        : contact;
      return `${activity.isActive ? "Offene" : "Abgeschlossene"} E-Mail Konversation ${contact ? ` mit ${name}` : ""
        }`;
  }
};

const Title: FC<{ activity: PersonActivity }> = ({ activity }) => {
  const stauts = getStatus(activity);
  switch (stauts) {
    case "active":
      return <Typography variant="h4">{activity.description}</Typography>;
    case "missed":
      return <Typography variant="h4">Entgangener Anruf</Typography>;
    case "failed":
      return <Typography variant="h4">Erfolgloser Anrufversuch</Typography>;
    case "inProgress":
      return <Typography variant="h4">Gesprächsprotokoll / Notiz ist noch in Arbeit ...</Typography>;
    case "finished":
      return activity.conversationReport ? (
        <ReportSummary activity={activity} />
      ) : (
        <MemoNote activity={activity} />
      );
    default:
      return <></>;
  }
};

type TInteractionBubble = {
  activity: PersonActivity;
  isLink?: boolean;
};

export const InteractionBubble: FC<TInteractionBubble> = ({ activity, isLink }) => {
  const { context } = useContext(ActivitiesStreamContext);
  const classes = useStyles();
  return (
    <>
      <Typography variant="body2" className={`${isLink ? classes.clickableText : ""}`}>
        {getHeadline(activity, context)}
      </Typography>
      <Title activity={activity} />
      {activity.topic && <Typography variant="body2">{activity.topic?.name}</Typography>}
    </>
  );
};

export default InteractionBubble;

const ReportSummary: FC<{ activity: PersonActivity }> = ({ activity }) => {
  const classes = useStyles();

  const note = removeMd(activity.conversationReport?.issue || "Es wurde kein Anliegen angegeben", {
    stripListLeaders: false,
  });

  return note.trim().length > 2 ? (
    <div className={classes.reportSummary}>
      <Markup value={trimMarkdown(note).note} />
    </div>
  ) : (
    <Typography variant="h4">Es wurde kein Anliegen angegeben</Typography>
  );
};

const MemoNote: FC<{ activity: PersonActivity }> = ({ activity }) => {
  const classes = useStyles();

  const fixDescr = (desc?: string | null) => {
    if (!desc) {
      return desc;
    }
    return desc.replace("#", "Nr.");
  };

  const fallback =
    (activity.activityType === "mail" || activity.activityType === "wpoVerweisung")
      ? fixDescr(activity.description)
      : "Es wurde kein Anliegen angegeben";
  const note = removeMd(activity.memo?.note || fallback, {
    stripListLeaders: false,
  });

  return note.trim().length > 2 ? (
    <div className={classes.reportSummary}>
      <Markup value={trimMarkdown(note).note} />
    </div>
  ) : (
    <Typography variant="h4">Es wurde kein Anliegen angegeben</Typography>
  );
};
