import React, { useState, FC, memo } from "react";

import Box from "@material-ui/core/Box";
import ActivitiesList from "../ActivitiesList";
import Loading from "../../atoms/Loading";

import { PersonActivitiesDocument } from "../../../generated/graphql";

import { TAKE } from "../../../utils/constants";
import { usePersonActivitiesSubscription } from "../../../hooks/usePersonActivitiesSubscription";
type TActivityStream = {
  contactId?: number;
  personId: number;
  topicId?: number;
  serviceId?: number;
};

// Without memoization, the personActivitySubscription might be unsubscribed and resubscribed after
// a new subscription result is received! If you notice problems here, to care that subscriptions
// are working! (e.g. do a new incoming call and assign to a person, check the subscription for
// the calling as well as another user!)
const ActivityStream: FC<TActivityStream> = memo(({ contactId, personId, topicId, serviceId }) => {
  const [hasMore, setHasMore] = useState(true);

  const { activities, loading, fetchMore } = usePersonActivitiesSubscription({
    personId,
    contactId,
    topicId,
    serviceId,
  });

  return !activities.length && loading ? (
    <Loading />
  ) : (
    <Box display="flex" justifyContent="flex-end">
      <ActivitiesList
        hasMore={hasMore}
        past
        activities={activities}
        personId={personId}
        topicId={topicId}
        onLoadMore={personActivities => {
          return fetchMore({
            query: PersonActivitiesDocument,
            variables: {
              personId,
              topicId,
              contactId,
              serviceId,
              skip: personActivities.length,
              take: TAKE,
            },
            updateQuery: (prev, { fetchMoreResult }: { fetchMoreResult?: any }) => {
              if (!fetchMoreResult || !fetchMoreResult?.personActivities.length) {
                setHasMore(false);
                return prev;
              }

              return Object.assign({}, prev, {
                personActivities: [
                  ...personActivities,
                  ...(fetchMoreResult ? fetchMoreResult.personActivities : []),
                ],
              });
            },
          });
        }}
      />
    </Box>
  );
});

export default ActivityStream;
