import React from "react";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";

import { Person } from "../../../../generated/graphql";
import PrimaryButton from "../../../atoms/PrimaryButton";
import WizardNavigation from "../../../molecules/WizardNavigation";
import SecondaryButton from "../../../atoms/SecondaryButton";

interface Props {
  backAction: () => void;
  person: Person;
}

export default function FinalizeService({ person, backAction }: Props) {
  const { push } = useHistory();
  return (
    <Box display="flex" justifyContent="center" mt={8}>
      <Box mx={1}>
        <PrimaryButton
          onClick={() => {
            push(`/person/${person.id}/service`);
            localStorage.removeItem("createdServiceID");
            localStorage.removeItem("createdServiceName");
            localStorage.removeItem("createdConsultingId");
          }}
        >
          Weiteren Service anlegen
        </PrimaryButton>
      </Box>
      <Box mx={1}>
        <PrimaryButton
          onClick={() => {
            push(`/person/${person.id}`);
            localStorage.removeItem("createdServiceID");
            localStorage.removeItem("createdServiceName");
            localStorage.removeItem("createdConsultingId");
          }}
        >
          Zu {person.name}
        </PrimaryButton>
      </Box>
      <WizardNavigation>
        <SecondaryButton onClick={() => backAction()}>Zurück</SecondaryButton>
      </WizardNavigation>
    </Box>
  );
}
