import getDistance from "geolib/es/getDistance";
import { Address, Maybe } from "../generated/graphql";
import { pathOr } from "ramda";

export const getAddressDistance = (
  address_1: Maybe<Address> | undefined,
  address_2: Maybe<Address>,
) => {
  let distance;
  if (address_1 && address_1.lat && address_1.lon && address_2 && address_2.lat && address_2.lon) {
    distance = getDistance(
      { lat: address_1.lat, lon: address_1.lon },
      {
        lat: address_2.lat,
        lon: address_2.lon,
      },
      100,
    );
  }
  return distance;
};

export const isValidAddress = (address: Maybe<Address>) =>
  !!address && (!!address.city || !!address.zip);

export const buildAddressString = (address: Maybe<Address>) => {
  if (!address || !isValidAddress(address)) return "";

  let out = "";
  const used = [];
  const street: string | boolean = pathOr(false, ["street"], address);
  const zip: string | boolean = pathOr(false, ["zip"], address);
  const city: string | boolean = pathOr(false, ["city"], address);

  if (street) {
    out = `${street}`;
    used.push(street);
  }
  if (zip) {
    out += `${street ? ", " : ""}${zip}`;
    used.push(zip);
  }
  if (city) {
    out +=
      used.length === 2
        ? ` ${city}`
        : `${used.length === 1 ? `${street ? ", " : " "}` : ""}${city}`;
  }

  return out;
};
