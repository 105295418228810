import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type EntireFieldWrapper<T> = T;
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type Query = {
  __typename?: "Query";
  persons: EntireFieldWrapper<Array<Person>>;
  clientSearch: EntireFieldWrapper<PersonSearchPayload>;
  personSearch: EntireFieldWrapper<PersonSearchPayload>;
  personSearchWithClient: EntireFieldWrapper<PersonSearchPayload>;
  personsAdvanced: EntireFieldWrapper<Array<Person>>;
  personByChatAlias?: EntireFieldWrapper<Maybe<Person>>;
  personsByBusiness: EntireFieldWrapper<Array<Person>>;
  breadCrumbInfoByPersonId?: EntireFieldWrapper<Maybe<BreadCrumbStatusDetail>>;
  person: EntireFieldWrapper<Person>;
  getMemoForClientByPersonId?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  accounts?: EntireFieldWrapper<Maybe<Array<Maybe<Account>>>>;
  account?: EntireFieldWrapper<Maybe<Account>>;
  accountStatistics?: EntireFieldWrapper<Maybe<Statistic>>;
  complaintsStatistics?: EntireFieldWrapper<Maybe<Statistic>>;
  monthlyLifeWorksStatistics?: EntireFieldWrapper<Maybe<Statistic>>;
  complaintsStatisticsForBusiness?: EntireFieldWrapper<Maybe<Statistic>>;
  complaintsStatisticsByType?: EntireFieldWrapper<Maybe<Statistic>>;
  accountStatisticsByType?: EntireFieldWrapper<Maybe<Statistic>>;
  breadCrumbInfoByAccountId?: EntireFieldWrapper<Maybe<BreadCrumbStatusDetail>>;
  appointmentsByDay?: EntireFieldWrapper<Maybe<Array<Maybe<Appointment>>>>;
  appointmentsByUsers?: EntireFieldWrapper<Maybe<Array<Maybe<Appointment>>>>;
  myAppointments?: EntireFieldWrapper<Maybe<Array<Maybe<Appointment>>>>;
  personAppointments?: EntireFieldWrapper<Maybe<Array<Maybe<Appointment>>>>;
  businessCategories?: EntireFieldWrapper<Maybe<Array<Maybe<BusinessCategory>>>>;
  businessObjects: EntireFieldWrapper<Array<BusinessObject>>;
  businessObject?: EntireFieldWrapper<Maybe<BusinessObject>>;
  businessObjectByPerson?: EntireFieldWrapper<Maybe<BusinessObject>>;
  getBusinessUnitsByAccount: EntireFieldWrapper<Array<BusinessUnit>>;
  calls?: EntireFieldWrapper<Maybe<Array<Call>>>;
  getCaseManagementReviews?: EntireFieldWrapper<Maybe<Array<Maybe<CaseManagementReview>>>>;
  getCaseManagementReviewsByUserId?: EntireFieldWrapper<Maybe<Array<Maybe<CaseManagementReview>>>>;
  getCaseManagementReview?: EntireFieldWrapper<Maybe<CaseManagementReview>>;
  getCaseManagerByPerson?: EntireFieldWrapper<Maybe<CaseManager>>;
  getCaseManagerByClient?: EntireFieldWrapper<Maybe<CaseManager>>;
  getClientReviewTemplate?: EntireFieldWrapper<Maybe<ClientReviewTemplate>>;
  getCaseManagementReviewAttribute?: EntireFieldWrapper<Maybe<CaseManagementReviewAttribute>>;
  getToken?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  activeChats: EntireFieldWrapper<Array<Chat>>;
  getChatByActivity?: EntireFieldWrapper<Maybe<Chat>>;
  getActiveChat?: EntireFieldWrapper<Maybe<Chat>>;
  lastFinishedChat?: EntireFieldWrapper<Maybe<Array<Maybe<Chat>>>>;
  clientAttribute?: EntireFieldWrapper<Maybe<ClientAttribute>>;
  riskHistory?: EntireFieldWrapper<Maybe<Array<Maybe<ClientAttribute>>>>;
  client?: EntireFieldWrapper<Maybe<Client>>;
  hotFlags?: EntireFieldWrapper<Maybe<Array<Maybe<Client>>>>;
  allClients?: EntireFieldWrapper<Maybe<Array<Maybe<CaseMangerByUserViewModel>>>>;
  getClientComplaints?: EntireFieldWrapper<Maybe<Array<Maybe<ClientComplaint>>>>;
  myCMClients?: EntireFieldWrapper<Maybe<Array<Maybe<Client>>>>;
  getCMClients?: EntireFieldWrapper<Maybe<Array<Maybe<Client>>>>;
  mySTCClients?: EntireFieldWrapper<Maybe<Array<Maybe<Client>>>>;
  getSTCClients?: EntireFieldWrapper<Maybe<Array<Maybe<Client>>>>;
  breadCrumbInfoByClientId?: EntireFieldWrapper<Maybe<BreadCrumbStatusDetail>>;
  clientByUcmsId?: EntireFieldWrapper<Maybe<Client>>;
  conversationReport?: EntireFieldWrapper<Maybe<ConversationReport>>;
  conversationReports?: EntireFieldWrapper<Maybe<Array<Maybe<ConversationReport>>>>;
  allMandates?: EntireFieldWrapper<Maybe<Array<Maybe<CounselingMandate>>>>;
  mandates?: EntireFieldWrapper<Maybe<Array<Maybe<CounselingMandate>>>>;
  mandate?: EntireFieldWrapper<Maybe<CounselingMandate>>;
  mandateByService?: EntireFieldWrapper<Maybe<CounselingMandate>>;
  departments: EntireFieldWrapper<Array<Department>>;
  getDriveMetaData?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  getDriveDocuments: EntireFieldWrapper<Array<DriveDocument>>;
  getGMailThreads: EntireFieldWrapper<Array<GMailMessage>>;
  getOneThreadByActivity: EntireFieldWrapper<Array<GMailMessage>>;
  getOneGMailThread: EntireFieldWrapper<GMailThread>;
  industries?: EntireFieldWrapper<Maybe<Array<Maybe<Industry>>>>;
  memo?: EntireFieldWrapper<Maybe<Memo>>;
  memos?: EntireFieldWrapper<Maybe<Array<Maybe<Memo>>>>;
  me?: EntireFieldWrapper<Maybe<Person>>;
  getAttachmentData?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  observableJob?: EntireFieldWrapper<Maybe<ObservableJob>>;
  papershiftShifts?: EntireFieldWrapper<Maybe<Array<Maybe<PapershiftShift>>>>;
  papershiftWorkingAreas?: EntireFieldWrapper<Maybe<Array<Maybe<PapershiftWorkingArea>>>>;
  updatePapershift?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  personActivity: EntireFieldWrapper<PersonActivity>;
  personInteractions?: EntireFieldWrapper<Maybe<Array<Maybe<PersonActivity>>>>;
  businessInteractions?: EntireFieldWrapper<Maybe<Array<Maybe<PersonActivity>>>>;
  personActivities: EntireFieldWrapper<Array<PersonActivity>>;
  businessActivities?: EntireFieldWrapper<Maybe<Array<Maybe<PersonActivity>>>>;
  activeActivities: EntireFieldWrapper<Array<PersonActivity>>;
  allActivities: EntireFieldWrapper<Array<PersonActivity>>;
  serviceCategories: EntireFieldWrapper<Array<ServiceCategory>>;
  registeredServicesByAccountId: EntireFieldWrapper<Array<ServiceCategory>>;
  registeredServicesByPersonId: EntireFieldWrapper<Array<ServiceCategory>>;
  serviceMetas: EntireFieldWrapper<Array<ServiceMeta>>;
  serviceResearchObjectPagination: EntireFieldWrapper<ServiceResearchObject>;
  serviceResearchObject: EntireFieldWrapper<ServiceResearchObject>;
  serviceResearchObjects: EntireFieldWrapper<Array<ServiceResearchObject>>;
  service: EntireFieldWrapper<Service>;
  services: EntireFieldWrapper<Array<Service>>;
  servicesByType?: EntireFieldWrapper<Maybe<Array<Maybe<Service>>>>;
  statisticGroups: EntireFieldWrapper<Array<StatisticGroup>>;
  statisticItemsByReport?: EntireFieldWrapper<Maybe<Array<Maybe<StatisticItem>>>>;
  tasks: EntireFieldWrapper<Array<Task>>;
  currentTasks: EntireFieldWrapper<Array<Task>>;
  getCMReminderTask?: EntireFieldWrapper<Maybe<Task>>;
  getAllowedTeams: EntireFieldWrapper<Array<Team>>;
  getAllTeams: EntireFieldWrapper<Array<Team>>;
  topics: EntireFieldWrapper<Array<Topic>>;
  topicsFromPerson: EntireFieldWrapper<Array<Topic>>;
  topic: EntireFieldWrapper<Topic>;
  usersWithTasks?: EntireFieldWrapper<Maybe<Array<Maybe<UserWithTasksAndAppointments>>>>;
  users?: EntireFieldWrapper<Maybe<Array<Maybe<User>>>>;
  user?: EntireFieldWrapper<Maybe<User>>;
  userFromPerson?: EntireFieldWrapper<Maybe<User>>;
  availableLanguages?: EntireFieldWrapper<Maybe<Array<Maybe<LanguageViewModel>>>>;
  logAUserChange?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  getLatestUserChangeByType?: EntireFieldWrapper<Maybe<UserChangesLog>>;
};

export type QueryPersonsArgs = {
  lastName?: InputMaybe<Scalars["String"]>;
};

export type QueryClientSearchArgs = {
  name: Scalars["String"];
};

export type QueryPersonSearchArgs = {
  name: Scalars["String"];
};

export type QueryPersonSearchWithClientArgs = {
  name: Scalars["String"];
};

export type QueryPersonsAdvancedArgs = {
  accountId?: InputMaybe<Scalars["Float"]>;
  name: Scalars["String"];
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
};

export type QueryPersonByChatAliasArgs = {
  chatAlias: Scalars["String"];
};

export type QueryPersonsByBusinessArgs = {
  businessId: Scalars["Int"];
};

export type QueryBreadCrumbInfoByPersonIdArgs = {
  id: Scalars["Int"];
};

export type QueryPersonArgs = {
  id: Scalars["Int"];
};

export type QueryGetMemoForClientByPersonIdArgs = {
  id: Scalars["Int"];
};

export type QueryAccountsArgs = {
  acquisition?: InputMaybe<Scalars["Boolean"]>;
  latest?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type QueryAccountArgs = {
  id: Scalars["Int"];
};

export type QueryAccountStatisticsArgs = {
  end: Scalars["DateTime"];
  start: Scalars["DateTime"];
  accountId: Scalars["Int"];
};

export type QueryComplaintsStatisticsArgs = {
  start: Scalars["DateTime"];
};

export type QueryMonthlyLifeWorksStatisticsArgs = {
  type?: InputMaybe<AccountType>;
  start: Scalars["DateTime"];
};

export type QueryComplaintsStatisticsForBusinessArgs = {
  end: Scalars["DateTime"];
  start: Scalars["DateTime"];
  businessId?: InputMaybe<Scalars["Int"]>;
};

export type QueryComplaintsStatisticsByTypeArgs = {
  end: Scalars["DateTime"];
  start: Scalars["DateTime"];
  type: Scalars["String"];
};

export type QueryAccountStatisticsByTypeArgs = {
  end: Scalars["DateTime"];
  start: Scalars["DateTime"];
  type: Scalars["String"];
};

export type QueryBreadCrumbInfoByAccountIdArgs = {
  id: Scalars["Int"];
};

export type QueryAppointmentsByDayArgs = {
  endsAt: Scalars["String"];
  startsAt: Scalars["String"];
};

export type QueryAppointmentsByUsersArgs = {
  endsAt: Scalars["String"];
  startsAt: Scalars["String"];
  userIds: Array<Scalars["Int"]>;
};

export type QueryMyAppointmentsArgs = {
  userId?: InputMaybe<Scalars["Int"]>;
};

export type QueryPersonAppointmentsArgs = {
  personId: Scalars["Int"];
};

export type QueryBusinessObjectsArgs = {
  withAddress?: InputMaybe<Scalars["Boolean"]>;
  unique?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  serviceId?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
};

export type QueryBusinessObjectArgs = {
  id: Scalars["Int"];
};

export type QueryBusinessObjectByPersonArgs = {
  personId: Scalars["Int"];
};

export type QueryGetBusinessUnitsByAccountArgs = {
  accountId: Scalars["Int"];
};

export type QueryGetCaseManagementReviewsArgs = {
  includeValues?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryGetCaseManagementReviewsByUserIdArgs = {
  userId: Scalars["Int"];
  includeValues?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryGetCaseManagementReviewArgs = {
  personId?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetCaseManagerByPersonArgs = {
  personId?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetCaseManagerByClientArgs = {
  clientId?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetClientReviewTemplateArgs = {
  templateId?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetCaseManagementReviewAttributeArgs = {
  clientReviewAttributeId?: InputMaybe<Scalars["Int"]>;
  caseManagementReviewId?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetChatByActivityArgs = {
  activityId?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetActiveChatArgs = {
  chatUserId: Scalars["Int"];
};

export type QueryLastFinishedChatArgs = {
  chats?: InputMaybe<Array<InputMaybe<LastFinishedChatInput>>>;
};

export type QueryClientAttributeArgs = {
  id: Scalars["Int"];
};

export type QueryRiskHistoryArgs = {
  personId?: InputMaybe<Scalars["Int"]>;
};

export type QueryClientArgs = {
  id: Scalars["Int"];
};

export type QueryGetClientComplaintsArgs = {
  id: Scalars["Int"];
};

export type QueryGetCmClientsArgs = {
  userId: Scalars["Int"];
};

export type QueryGetStcClientsArgs = {
  userId: Scalars["Int"];
};

export type QueryBreadCrumbInfoByClientIdArgs = {
  id: Scalars["Int"];
};

export type QueryClientByUcmsIdArgs = {
  ucmsId?: InputMaybe<Scalars["Int"]>;
};

export type QueryConversationReportArgs = {
  id: Scalars["Int"];
};

export type QueryConversationReportsArgs = {
  topicId?: InputMaybe<Scalars["Int"]>;
  personId: Scalars["Int"];
};

export type QueryAllMandatesArgs = {
  active?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryMandatesArgs = {
  businessId: Scalars["Int"];
};

export type QueryMandateArgs = {
  id: Scalars["Int"];
};

export type QueryMandateByServiceArgs = {
  serviceId: Scalars["Int"];
};

export type QueryDepartmentsArgs = {
  accountId: Scalars["Int"];
};

export type QueryGetDriveMetaDataArgs = {
  fileId: Scalars["String"];
};

export type QueryGetDriveDocumentsArgs = {
  personIds: Array<Scalars["Int"]>;
};

export type QueryGetOneThreadByActivityArgs = {
  personActivityId: Scalars["Int"];
};

export type QueryGetOneGMailThreadArgs = {
  threadId: Scalars["String"];
};

export type QueryMemoArgs = {
  id: Scalars["Int"];
};

export type QueryMemosArgs = {
  topicId?: InputMaybe<Scalars["Int"]>;
  personId: Scalars["Int"];
};

export type QueryGetAttachmentDataArgs = {
  messageId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
};

export type QueryObservableJobArgs = {
  id: Scalars["Int"];
};

export type QueryPapershiftShiftsArgs = {
  endsAt: Scalars["String"];
  startsAt: Scalars["String"];
};

export type QueryPersonActivityArgs = {
  loadReportData?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["Int"];
};

export type QueryPersonInteractionsArgs = {
  topicId?: InputMaybe<Scalars["Int"]>;
  personId: Scalars["Int"];
};

export type QueryBusinessInteractionsArgs = {
  businessId?: InputMaybe<Scalars["Int"]>;
};

export type QueryPersonActivitiesArgs = {
  activityType?: InputMaybe<Scalars["String"]>;
  serviceId?: InputMaybe<Scalars["Int"]>;
  topicId?: InputMaybe<Scalars["Int"]>;
  contactId?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  personId: Scalars["Int"];
};

export type QueryBusinessActivitiesArgs = {
  personIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
};

export type QueryAllActivitiesArgs = {
  date?: InputMaybe<Scalars["DateTime"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
};

export type QueryRegisteredServicesByAccountIdArgs = {
  id: Scalars["Int"];
};

export type QueryRegisteredServicesByPersonIdArgs = {
  id: Scalars["Int"];
};

export type QueryServiceMetasArgs = {
  serviceCategoryId: Scalars["Int"];
};

export type QueryServiceResearchObjectPaginationArgs = {
  id: Scalars["ID"];
};

export type QueryServiceResearchObjectArgs = {
  serviceId: Scalars["Int"];
  id: Scalars["Int"];
};

export type QueryServiceResearchObjectsArgs = {
  serviceId: Scalars["Int"];
};

export type QueryServiceArgs = {
  id: Scalars["Int"];
};

export type QueryServicesArgs = {
  status?: InputMaybe<Array<InputMaybe<ServiceStatus>>>;
  statusNot?: InputMaybe<Array<InputMaybe<ServiceStatus>>>;
  topicId?: InputMaybe<Scalars["Int"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type QueryServicesByTypeArgs = {
  status?: InputMaybe<Array<InputMaybe<ServiceStatus>>>;
  statusNot?: InputMaybe<Array<InputMaybe<ServiceStatus>>>;
  type?: InputMaybe<ServiceType>;
};

export type QueryStatisticGroupsArgs = {
  clientId: Scalars["Int"];
  type: StatisticGroupTypes;
};

export type QueryStatisticItemsByReportArgs = {
  reportId: Scalars["Int"];
};

export type QueryTasksArgs = {
  withSnoozed?: InputMaybe<Scalars["Boolean"]>;
  duedate?: InputMaybe<Scalars["String"]>;
  pool?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryCurrentTasksArgs = {
  withSnoozed?: InputMaybe<Scalars["Boolean"]>;
  userId?: InputMaybe<Scalars["Int"]>;
  unassigned?: InputMaybe<Scalars["Boolean"]>;
  pool?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetCmReminderTaskArgs = {
  personId: Scalars["Int"];
};

export type QueryTopicsArgs = {
  clientId: Scalars["Int"];
};

export type QueryTopicsFromPersonArgs = {
  personId: Scalars["Int"];
};

export type QueryTopicArgs = {
  id: Scalars["Int"];
};

export type QueryUsersWithTasksArgs = {
  teams?: InputMaybe<Array<Team>>;
};

export type QueryUsersArgs = {
  teams?: InputMaybe<Array<Team>>;
};

export type QueryUserArgs = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type QueryUserFromPersonArgs = {
  personId?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetLatestUserChangeByTypeArgs = {
  type?: InputMaybe<Scalars["String"]>;
  targetType?: InputMaybe<Scalars["String"]>;
  targetId?: InputMaybe<Scalars["Int"]>;
};

/** The person model */
export type Person = {
  __typename?: "Person";
  id: EntireFieldWrapper<Scalars["Int"]>;
  avatar?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  picture?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  firstName?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  lastName?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  alias?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  title?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  abnormalities?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  name: EntireFieldWrapper<Scalars["String"]>;
  salut?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  gender?: EntireFieldWrapper<Maybe<Gender>>;
  familyStatus?: EntireFieldWrapper<Maybe<FamilyStatus>>;
  initialAge?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  note?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  ageGuessed?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  mentalState?: EntireFieldWrapper<Maybe<MentalState>>;
  temporary?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  birthDate?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  extras?: EntireFieldWrapper<Maybe<PersonExtraFieldType>>;
  age?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  avatarKeys?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  gMailMessages?: EntireFieldWrapper<Maybe<Array<Maybe<GMailMessage>>>>;
  addresses?: EntireFieldWrapper<Maybe<Array<Address>>>;
  phones?: EntireFieldWrapper<Maybe<Array<Maybe<Phone>>>>;
  driveDocuments?: EntireFieldWrapper<Maybe<Array<Maybe<DriveDocument>>>>;
  emails?: EntireFieldWrapper<Maybe<Array<Maybe<Email>>>>;
  tasks?: EntireFieldWrapper<Maybe<Array<Maybe<Task>>>>;
  client?: EntireFieldWrapper<Maybe<Client>>;
  user?: EntireFieldWrapper<Maybe<User>>;
  complaints?: EntireFieldWrapper<Maybe<Array<Maybe<ClientComplaint>>>>;
  activities?: EntireFieldWrapper<Maybe<Array<Maybe<PersonActivity>>>>;
  refActivities?: EntireFieldWrapper<Maybe<Array<Maybe<PersonActivity>>>>;
  relations?: EntireFieldWrapper<Maybe<Array<Maybe<Relationship>>>>;
  appointments?: EntireFieldWrapper<Maybe<Array<Maybe<Appointment>>>>;
  memos?: EntireFieldWrapper<Maybe<Array<Maybe<Memo>>>>;
  counselingMandates?: EntireFieldWrapper<Maybe<Array<Maybe<CounselingMandate>>>>;
  businesses?: EntireFieldWrapper<Maybe<Array<Maybe<BusinessObject>>>>;
  lastActivity?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  business?: EntireFieldWrapper<Maybe<BusinessObject>>;
  needsAccount?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  role?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  relation?: EntireFieldWrapper<Maybe<Relationship>>;
  businessIds?: EntireFieldWrapper<Maybe<Array<Maybe<Scalars["Int"]>>>>;
  redFlag?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  yellowFlag?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  flag?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  vip?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  papershiftWorkingAreas?: EntireFieldWrapper<Maybe<Array<Maybe<PapershiftWorkingArea>>>>;
};

/** The person model */
export type PersonRelationArgs = {
  passivePersonId: Scalars["Int"];
};

export enum Gender {
  female = "female",
  male = "male",
  divers = "divers",
  unknown = "unknown",
}

export enum FamilyStatus {
  unknown = "unknown",
  single = "single",
  married = "married",
  relationship = "relationship",
  other = "other",
  widowed = "widowed",
}

export enum MentalState {
  euphoric = "euphoric",
  happy = "happy",
  neutral = "neutral",
  depressed = "depressed",
  critical = "critical",
}

export type PersonExtraFieldType = {
  __typename?: "PersonExtraFieldType";
  informationSource?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  hrBusiness?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
};

export type GMailMessage = {
  __typename?: "GMailMessage";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  messageId?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  threadId: EntireFieldWrapper<Scalars["String"]>;
  historyId?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  incoming: EntireFieldWrapper<Scalars["Boolean"]>;
  date?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  classification?: EntireFieldWrapper<Maybe<MailClassificationType>>;
  payload?: EntireFieldWrapper<Maybe<Payload>>;
  person?: EntireFieldWrapper<Maybe<Person>>;
  businessObject?: EntireFieldWrapper<Maybe<BusinessObject>>;
  user?: EntireFieldWrapper<Maybe<User>>;
  gMailInbox?: EntireFieldWrapper<Maybe<GMailInbox>>;
  personActivity?: EntireFieldWrapper<Maybe<PersonActivity>>;
  attachments: EntireFieldWrapper<Array<Attachment>>;
  body?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
};

export enum MailClassificationType {
  service = "service",
  counseling = "counseling",
  support = "support",
}

export type Payload = {
  __typename?: "Payload";
  body?: EntireFieldWrapper<Maybe<Body>>;
  filename?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  headers?: EntireFieldWrapper<Maybe<Array<Maybe<Header>>>>;
  mimeType?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  partId?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  parts?: EntireFieldWrapper<Maybe<Array<Maybe<Payload>>>>;
  from?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  to?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  subject?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  returnPath?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
};

export type Body = {
  __typename?: "Body";
  attachmentId?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  data?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  size?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
};

export type Header = {
  __typename?: "Header";
  name?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  value?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
};

export type BusinessObject = {
  __typename?: "BusinessObject";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  name?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  url?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  info?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  memo?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  email?: EntireFieldWrapper<Maybe<Email>>;
  address?: EntireFieldWrapper<Maybe<Address>>;
  serviceResearchObjects?: EntireFieldWrapper<Maybe<ServiceResearchObject>>;
  businessObjectAttributes?: EntireFieldWrapper<Maybe<Array<Maybe<BusinessObjectAttribute>>>>;
  hours?: EntireFieldWrapper<Maybe<Array<Maybe<Hour>>>>;
  phones?: EntireFieldWrapper<Maybe<Array<Maybe<Phone>>>>;
  category?: EntireFieldWrapper<Maybe<BusinessCategory>>;
  account?: EntireFieldWrapper<Maybe<Account>>;
  accountId?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  persons?: EntireFieldWrapper<Maybe<Array<Maybe<Person>>>>;
  gMailMessages?: EntireFieldWrapper<Maybe<Array<Maybe<GMailMessage>>>>;
  categoryName?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
};

export type Email = {
  __typename?: "Email";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  email?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  type?: EntireFieldWrapper<Maybe<EmailType>>;
  contactAllowed?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  business?: EntireFieldWrapper<Maybe<BusinessObject>>;
};

export enum EmailType {
  private = "private",
  mobile = "mobile",
}

export type Address = {
  __typename?: "Address";
  skipUpdateGeocode?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  type?: EntireFieldWrapper<Maybe<AddressType>>;
  street?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  city?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  zip?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  businessObject?: EntireFieldWrapper<Maybe<BusinessObject>>;
  lat?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  lon?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  contactAllowed?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
};

export enum AddressType {
  private = "private",
  work = "work",
}

export type ServiceResearchObject = {
  __typename?: "ServiceResearchObject";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  businessObjectId?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  businessObject?: EntireFieldWrapper<Maybe<BusinessObject>>;
  service?: EntireFieldWrapper<Maybe<Service>>;
  descriptor?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  info?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  note?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  status: EntireFieldWrapper<ServiceResearchObjectStatus>;
  statusChangeReason?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  contactPerson?: EntireFieldWrapper<Maybe<Person>>;
  nextResearchObject?: EntireFieldWrapper<Maybe<ServiceResearchObject>>;
  previousResearchObject?: EntireFieldWrapper<Maybe<ServiceResearchObject>>;
};

export type Service = {
  __typename?: "Service";
  createdAt?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  id: EntireFieldWrapper<Scalars["Int"]>;
  topic?: EntireFieldWrapper<Maybe<Topic>>;
  status?: EntireFieldWrapper<Maybe<ServiceStatus>>;
  wizardStatus?: EntireFieldWrapper<Maybe<ServiceWizardStatus>>;
  wizardStep?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  extendedInfo?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  clientId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  client?: EntireFieldWrapper<Maybe<Client>>;
  serviceMeta?: EntireFieldWrapper<Maybe<ServiceMeta>>;
  serviceMetaId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  serviceClients?: EntireFieldWrapper<Maybe<Array<Maybe<ServiceClient>>>>;
  serviceAttributes?: EntireFieldWrapper<Maybe<Array<Maybe<ServiceAttribute>>>>;
  serviceAddresses?: EntireFieldWrapper<Maybe<Array<Maybe<ServiceAddress>>>>;
  hours?: EntireFieldWrapper<Maybe<Array<Maybe<Hour>>>>;
  personActivity?: EntireFieldWrapper<Maybe<PersonActivity>>;
  serviceResearchObjects?: EntireFieldWrapper<Maybe<Array<Maybe<ServiceResearchObject>>>>;
  counselingMandate?: EntireFieldWrapper<Maybe<CounselingMandate>>;
  appointments?: EntireFieldWrapper<Maybe<Array<Maybe<Appointment>>>>;
  counselingUser?: EntireFieldWrapper<Maybe<CounselingUser>>;
  tasks?: EntireFieldWrapper<Maybe<Array<Maybe<Task>>>>;
  openCount?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  limitedCount?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  suitableCount?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  unsuitableCount?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  created?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  lastUpdate?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  lastActivity?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  duedate?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  problem?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
};

export type Topic = {
  __typename?: "Topic";
  summary?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  services?: EntireFieldWrapper<Maybe<Array<Maybe<Service>>>>;
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  name?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  client?: EntireFieldWrapper<Maybe<Client>>;
  clientId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  appointments?: EntireFieldWrapper<Maybe<Array<Maybe<Appointment>>>>;
  conversationReports?: EntireFieldWrapper<Maybe<Array<Maybe<ConversationReport>>>>;
  memos?: EntireFieldWrapper<Maybe<Array<Maybe<Memo>>>>;
  lastInteraction?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  firstInteraction?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
};

export type Client = {
  __typename?: "Client";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  chatAlias?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  job?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  language?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  hrBusiness?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  source?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  healthInsuranceCompany?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  assistanceId?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  ucmsId?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  clientType?: EntireFieldWrapper<Maybe<ClientType>>;
  person?: EntireFieldWrapper<Maybe<Person>>;
  contactLanguageId?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  contactLanguage?: EntireFieldWrapper<Maybe<Language>>;
  caseManager?: EntireFieldWrapper<Maybe<CaseManager>>;
  topics?: EntireFieldWrapper<Maybe<Array<Maybe<Topic>>>>;
  account?: EntireFieldWrapper<Maybe<Account>>;
  accountId?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  department?: EntireFieldWrapper<Maybe<Department>>;
  departmentId?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  clientAttributes?: EntireFieldWrapper<Maybe<Array<Maybe<ClientAttribute>>>>;
  serviceClients?: EntireFieldWrapper<Maybe<Array<Maybe<ServiceClient>>>>;
  vip?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  chatUserId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  sourceOfInformation?: EntireFieldWrapper<Maybe<SourceOfInformation>>;
  yearOfEntry?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  businessUnit?: EntireFieldWrapper<Maybe<BusinessUnit>>;
  jobBand?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  reviewRequired?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
};

export enum ClientType {
  unknown = "unknown",
  employee = "employee",
  executive = "executive",
  relative = "relative",
  other = "other",
}

export type Language = {
  __typename?: "Language";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  name?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  code?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  sortOrder?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  isActive?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  isDefault?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
};

export type CaseManager = {
  __typename?: "CaseManager";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  user?: EntireFieldWrapper<Maybe<User>>;
  client?: EntireFieldWrapper<Maybe<Client>>;
};

export type User = {
  __typename?: "User";
  id: EntireFieldWrapper<Scalars["Int"]>;
  remoteId?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  papershiftId?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  rolePath?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  name?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  absentUntil?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  active?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  person?: EntireFieldWrapper<Maybe<Person>>;
  tasks?: EntireFieldWrapper<Maybe<Array<Maybe<Task>>>>;
  delegatedTasks?: EntireFieldWrapper<Maybe<Array<Maybe<Task>>>>;
  caseManagers?: EntireFieldWrapper<Maybe<Array<Maybe<CaseManager>>>>;
  clientComplaints?: EntireFieldWrapper<Maybe<Array<Maybe<CaseManager>>>>;
  papershiftShifts?: EntireFieldWrapper<Maybe<Array<Maybe<PapershiftShift>>>>;
  appointments?: EntireFieldWrapper<Maybe<Array<Maybe<Appointment>>>>;
  driveDocuments?: EntireFieldWrapper<Maybe<Array<Maybe<DriveDocument>>>>;
  counselingUser?: EntireFieldWrapper<Maybe<Array<Maybe<CounselingUser>>>>;
  gMailMessages?: EntireFieldWrapper<Maybe<Array<Maybe<GMailMessage>>>>;
  hotDesks?: EntireFieldWrapper<Maybe<Array<Maybe<HotDesk>>>>;
  teams?: EntireFieldWrapper<Maybe<Array<Maybe<Team>>>>;
  defaultTeam?: EntireFieldWrapper<Maybe<Team>>;
  primeDN?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  papershiftWorkingAreas?: EntireFieldWrapper<Maybe<Array<Maybe<PapershiftWorkingArea>>>>;
};

export type Task = {
  __typename?: "Task";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  created?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  startedAt?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  finishedAt?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  snoozeUntil?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  dueDate?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  taskType?: EntireFieldWrapper<Maybe<TaskType>>;
  title?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  path?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  note?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  author?: EntireFieldWrapper<Maybe<Person>>;
  creator?: EntireFieldWrapper<Maybe<Person>>;
  userPapershiftWorkingAreas?: EntireFieldWrapper<Maybe<Array<Maybe<PapershiftWorkingArea>>>>;
  user?: EntireFieldWrapper<Maybe<User>>;
  userId?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  assignee?: EntireFieldWrapper<Maybe<User>>;
  personActivity?: EntireFieldWrapper<Maybe<PersonActivity>>;
  person?: EntireFieldWrapper<Maybe<Person>>;
  personId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  group?: EntireFieldWrapper<Maybe<PapershiftWorkingArea>>;
  team?: EntireFieldWrapper<Maybe<Team>>;
  report?: EntireFieldWrapper<Maybe<ConversationReport>>;
  service?: EntireFieldWrapper<Maybe<Service>>;
  topic?: EntireFieldWrapper<Maybe<Topic>>;
};

export enum TaskType {
  appointment = "appointment",
  cmCheck = "cmCheck",
  cmReminder = "cmReminder",
  createConversationReport = "createConversationReport",
  createService = "createService",
  custom = "custom",
  emailConversation = "emailConversation",
  externalConsultResearch = "externalConsultResearch",
  externalFinish = "externalFinish",
  feedbackService = "feedbackService",
  noTask = "noTask",
  planInternalConsult = "planInternalConsult",
  researchService = "researchService",
  ucms = "ucms",
  wpoVerweisung = "wpoVerweisung",
}

export type PapershiftWorkingArea = {
  __typename?: "PapershiftWorkingArea";
  id: EntireFieldWrapper<Scalars["Int"]>;
  name?: EntireFieldWrapper<Maybe<WorkingAreas>>;
  status?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  papershiftColor?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  locationId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  users?: EntireFieldWrapper<Maybe<Array<Maybe<User>>>>;
  tasks?: EntireFieldWrapper<Maybe<Array<Maybe<Task>>>>;
  gMailInboxes?: EntireFieldWrapper<Maybe<Array<Maybe<GMailInbox>>>>;
  color?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
};

export enum WorkingAreas {
  caseManagement = "caseManagement",
  lMRechercheassistenten = "lMRechercheassistenten",
  lifeManagerExperten = "lifeManagerExperten",
  counseling = "counseling",
  counseling1stLevel = "counseling1stLevel",
  counseling2ndLevel = "counseling2ndLevel",
  counselingTC = "counselingTC",
  clinicalAdministration = "clinicalAdministration",
  counselingWeekendNightshift = "counselingWeekendNightshift",
  onlineTeam = "onlineTeam",
  digitalServices = "digitalServices",
}

export type GMailInbox = {
  __typename?: "GMailInbox";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  email?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  historyId?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  papershiftWorkingAreas?: EntireFieldWrapper<Maybe<Array<Maybe<PapershiftWorkingArea>>>>;
  gMailMessages?: EntireFieldWrapper<Maybe<Array<Maybe<GMailMessage>>>>;
};

export type PersonActivity = {
  __typename?: "PersonActivity";
  id: EntireFieldWrapper<Scalars["Int"]>;
  description?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  start?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  end?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  lastUpdate?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  activityType?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  persistanceLogType?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  isActive?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  isPublic?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  person: EntireFieldWrapper<Person>;
  personId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  refPerson?: EntireFieldWrapper<Maybe<Person>>;
  refPersonId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  topic?: EntireFieldWrapper<Maybe<Topic>>;
  topicId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  matches?: EntireFieldWrapper<Maybe<Array<Maybe<Person>>>>;
  suggestions?: EntireFieldWrapper<Maybe<Array<Maybe<Person>>>>;
  conversationReport?: EntireFieldWrapper<Maybe<ConversationReport>>;
  memo?: EntireFieldWrapper<Maybe<Memo>>;
  author?: EntireFieldWrapper<Maybe<Person>>;
  user?: EntireFieldWrapper<Maybe<User>>;
  tasks?: EntireFieldWrapper<Maybe<Array<Maybe<Task>>>>;
  call?: EntireFieldWrapper<Maybe<Call>>;
  chat?: EntireFieldWrapper<Maybe<Chat>>;
  driveDocument?: EntireFieldWrapper<Maybe<DriveDocument>>;
  serviceResearchObject?: EntireFieldWrapper<Maybe<ServiceResearchObject>>;
  service?: EntireFieldWrapper<Maybe<Service>>;
  gMailMessages?: EntireFieldWrapper<Maybe<Array<Maybe<GMailMessage>>>>;
  tempPerson?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  subtitle?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  path?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  doneAt?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
};

export type ConversationReport = {
  __typename?: "ConversationReport";
  id: EntireFieldWrapper<Scalars["Int"]>;
  clientId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  client?: EntireFieldWrapper<Maybe<Client>>;
  topic?: EntireFieldWrapper<Maybe<Topic>>;
  issue?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  agreement?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  mentalState?: EntireFieldWrapper<Maybe<MentalState>>;
  reportType?: EntireFieldWrapper<Maybe<ReportType>>;
  mentalDescription?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  summary?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  cmCheckMessage?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  risk?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  riskInfo?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  lifeworksService?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  lifeworksServiceIssue?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  lifeworksServiceSubIssue?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  status?: EntireFieldWrapper<Maybe<ReportState>>;
  finalizedAt?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  personActivity?: EntireFieldWrapper<Maybe<PersonActivity>>;
  clientAttributes?: EntireFieldWrapper<Maybe<Array<Maybe<ClientAttribute>>>>;
  conversationReportReviewAttributes?: EntireFieldWrapper<
    Maybe<Array<Maybe<ConversationReportReviewAttribute>>>
  >;
  activeClientReviewAttributeGroup?: EntireFieldWrapper<Maybe<ClientReviewAttributeGroup>>;
  activeClientReviewTemplate?: EntireFieldWrapper<Maybe<ClientReviewTemplate>>;
  connectedServices?: EntireFieldWrapper<Maybe<Array<Maybe<Service>>>>;
  statistics?: EntireFieldWrapper<Maybe<Array<Maybe<StatisticItem>>>>;
  services?: EntireFieldWrapper<Maybe<Array<Maybe<Service>>>>;
  doneAt?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  author?: EntireFieldWrapper<Maybe<Person>>;
};

export enum ReportType {
  counseling = "counseling",
  lm = "lm",
  both = "both",
}

export enum ReportState {
  created = "created",
  issued = "issued",
  workStats = "workStats",
  privateStats = "privateStats",
  agreed = "agreed",
  mentalized = "mentalized",
  summarized = "summarized",
  counselingOrLm = "counselingOrLm",
  risk = "risk",
  riskInfo = "riskInfo",
  cmnotified = "cmnotified",
  reportDone = "reportDone",
  startReview = "startReview",
  inReview = "inReview",
  inServiceReview = "inServiceReview",
  finished = "finished",
  serviceSelected = "serviceSelected",
}

export type ClientAttribute = {
  __typename?: "ClientAttribute";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  attributeValue?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  lastUpdate?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  author?: EntireFieldWrapper<Maybe<Person>>;
  client?: EntireFieldWrapper<Maybe<Client>>;
  clientReviewAttribute?: EntireFieldWrapper<Maybe<ClientReviewAttribute>>;
  clientReviewAttributeId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  conversationReport?: EntireFieldWrapper<Maybe<ConversationReport>>;
  user?: EntireFieldWrapper<Maybe<User>>;
  start?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
};

export type ClientReviewAttribute = {
  __typename?: "ClientReviewAttribute";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  name?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  label?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  type?: EntireFieldWrapper<Maybe<ReviewAttributeType>>;
  placeholder?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  isActive?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  clientReviewAttributeGroup?: EntireFieldWrapper<Maybe<ClientReviewAttributeGroup>>;
  clientAttributes?: EntireFieldWrapper<Maybe<Array<Maybe<ClientAttribute>>>>;
  conversationReportReviewAttributes?: EntireFieldWrapper<
    Maybe<Array<Maybe<ConversationReportReviewAttribute>>>
  >;
  caseManagementReviewAttributes?: EntireFieldWrapper<
    Maybe<Array<Maybe<CaseManagementReviewAttribute>>>
  >;
};

export enum ReviewAttributeType {
  text = "text",
  checkbox = "checkbox",
}

export type ClientReviewAttributeGroup = {
  __typename?: "ClientReviewAttributeGroup";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  pageOrder?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  headingNew?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  headingUpdate?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  clientReviewTemplate?: EntireFieldWrapper<Maybe<ClientReviewTemplate>>;
  clientReviewAttributes?: EntireFieldWrapper<Maybe<Array<Maybe<ClientReviewAttribute>>>>;
};

export type ClientReviewTemplate = {
  __typename?: "ClientReviewTemplate";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  name?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  sortIdx?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  isDefault?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  clientReviewAttributeGroups?: EntireFieldWrapper<Maybe<Array<Maybe<ClientReviewAttributeGroup>>>>;
};

export type ConversationReportReviewAttribute = {
  __typename?: "ConversationReportReviewAttribute";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  attributeValue?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  author?: EntireFieldWrapper<Maybe<Person>>;
  client?: EntireFieldWrapper<Maybe<Client>>;
  clientReviewAttribute?: EntireFieldWrapper<Maybe<ClientReviewAttribute>>;
  clientReviewAttributeId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  conversationReport?: EntireFieldWrapper<Maybe<ConversationReport>>;
};

export type CaseManagementReviewAttribute = {
  __typename?: "CaseManagementReviewAttribute";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  attributeValue?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  lastUpdate?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  user?: EntireFieldWrapper<Maybe<User>>;
  caseManagementReview?: EntireFieldWrapper<Maybe<CaseManagementReview>>;
  clientReviewAttribute?: EntireFieldWrapper<Maybe<ClientReviewAttribute>>;
};

export type CaseManagementReview = {
  __typename?: "CaseManagementReview";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  user?: EntireFieldWrapper<Maybe<User>>;
  client?: EntireFieldWrapper<Maybe<Client>>;
  caseManagementReviewAttributes?: EntireFieldWrapper<
    Maybe<Array<Maybe<CaseManagementReviewAttribute>>>
  >;
  caseManager?: EntireFieldWrapper<Maybe<CaseManager>>;
  lastUpdate?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
};

export type CaseManagementReviewCaseManagementReviewAttributesArgs = {
  clientReviewAttributeId?: InputMaybe<Scalars["Int"]>;
};

export type StatisticItem = {
  __typename?: "StatisticItem";
  id: EntireFieldWrapper<Scalars["Int"]>;
  name: EntireFieldWrapper<Scalars["String"]>;
  group?: EntireFieldWrapper<Maybe<StatisticGroup>>;
};

export type StatisticGroup = {
  __typename?: "StatisticGroup";
  id: EntireFieldWrapper<Scalars["Int"]>;
  name: EntireFieldWrapper<Scalars["String"]>;
  type: EntireFieldWrapper<StatisticGroupTypes>;
  items: EntireFieldWrapper<Array<StatisticItem>>;
};

export enum StatisticGroupTypes {
  work = "work",
  private = "private",
}

export type Memo = {
  __typename?: "Memo";
  id: EntireFieldWrapper<Scalars["Int"]>;
  person?: EntireFieldWrapper<Maybe<Person>>;
  topic?: EntireFieldWrapper<Maybe<Topic>>;
  contact?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  note?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  status?: EntireFieldWrapper<Maybe<MemoState>>;
  finalizedAt?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  type?: EntireFieldWrapper<Maybe<MemoType>>;
  personActivity?: EntireFieldWrapper<Maybe<PersonActivity>>;
  business?: EntireFieldWrapper<Maybe<BusinessObject>>;
  doneAt?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  author?: EntireFieldWrapper<Maybe<Person>>;
};

export enum MemoState {
  created = "created",
  finished = "finished",
}

export enum MemoType {
  client = "client",
  business = "business",
  person = "person",
}

export type Call = {
  __typename?: "Call";
  id: EntireFieldWrapper<Scalars["Int"]>;
  countryCode?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  OIGCurrentGlobalCallID?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  areaCode?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  number?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  callType?: EntireFieldWrapper<Maybe<CallType>>;
  account?: EntireFieldWrapper<Maybe<Account>>;
  accountId?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  status?: EntireFieldWrapper<Maybe<InteractionStatus>>;
  personActivity?: EntireFieldWrapper<Maybe<PersonActivity>>;
  personActivityId?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  readableNumber?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  person?: EntireFieldWrapper<Maybe<Person>>;
};

export enum CallType {
  client = "client",
  business = "business",
}

export type Account = {
  __typename?: "Account";
  updatedAt?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  tariff?: EntireFieldWrapper<Maybe<AccountTariff>>;
  reporting?: EntireFieldWrapper<Maybe<AccountReporting>>;
  coaching?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  allowFamily?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  contractStart?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  contractEnd?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  usageScope?: EntireFieldWrapper<Maybe<AccountUsageScope>>;
  name?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  helpline?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  helpline2?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  helplineInternational?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  remarks?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  nFreeSTCs?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  serviceTimes?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  usageRate?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  freeSessions?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  lifeworksId?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  chatRegistrationPin?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  externalRef?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  type?: EntireFieldWrapper<Maybe<AccountType>>;
  pin?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  employeesCount?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  executivesCount?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  isAssistance?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  validationPattern?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  business?: EntireFieldWrapper<Maybe<BusinessObject>>;
  accountServiceModules?: EntireFieldWrapper<Maybe<Array<Maybe<ServiceMeta>>>>;
  parent?: EntireFieldWrapper<Maybe<Account>>;
  industry?: EntireFieldWrapper<Maybe<Industry>>;
  departments?: EntireFieldWrapper<Maybe<Array<Maybe<Department>>>>;
  businessUnits?: EntireFieldWrapper<Maybe<Array<Maybe<BusinessUnit>>>>;
  serviceRegion: EntireFieldWrapper<ServiceRegion>;
};

export enum AccountTariff {
  light = "light",
  basic = "basic",
  premium = "premium",
}

export enum AccountReporting {
  basic = "basic",
  premium = "premium",
}

export enum AccountUsageScope {
  employeeOnly = "employeeOnly",
  employeeAndFamily = "employeeAndFamily",
}

export enum AccountType {
  eap = "eap",
  international = "international",
  wpo = "wpo",
  chesnut = "chesnut",
  morneauShepell = "morneauShepell",
  lifeWorks = "lifeWorks",
  assistance = "assistance",
  test = "test",
}

export type ServiceMeta = {
  __typename?: "ServiceMeta";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  name?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  iconName?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  type?: EntireFieldWrapper<Maybe<ServiceType>>;
  description?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  researchHint?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  count?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  subscribed?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  serviceCategory?: EntireFieldWrapper<Maybe<ServiceCategory>>;
  serviceCategoryId?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  serviceMetaAttributes?: EntireFieldWrapper<Maybe<Array<Maybe<ServiceMetaAttribute>>>>;
  businessCategories?: EntireFieldWrapper<Maybe<Array<Maybe<BusinessCategory>>>>;
};

export enum ServiceType {
  research = "research",
  researchBlank = "researchBlank",
  internalConsult = "internalConsult",
  externalConsult = "externalConsult",
}

export type ServiceCategory = {
  __typename?: "ServiceCategory";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  name?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  serviceMetas?: EntireFieldWrapper<Maybe<Array<Maybe<ServiceMeta>>>>;
};

export type ServiceMetaAttribute = {
  __typename?: "ServiceMetaAttribute";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  name?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  label?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  type?: EntireFieldWrapper<Maybe<ServiceMetaAttributeType>>;
  placeholder?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  headline?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  required?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  multiple?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  defaults?: EntireFieldWrapper<Maybe<Array<Maybe<Scalars["String"]>>>>;
  contextData?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  sortIdx?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  serviceMeta?: EntireFieldWrapper<Maybe<ServiceMeta>>;
  serviceMetaId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  serviceAttributes?: EntireFieldWrapper<Maybe<Array<Maybe<ServiceAttribute>>>>;
};

export enum ServiceMetaAttributeType {
  text = "text",
  checkbox = "checkbox",
  select = "select",
  currency = "currency",
  number = "number",
  selectMulti = "selectMulti",
  person = "person",
  hours = "hours",
  address = "address",
  date = "date",
}

export type ServiceAttribute = {
  __typename?: "ServiceAttribute";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  value?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  service?: EntireFieldWrapper<Maybe<Service>>;
  serviceId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  serviceMetaAttribute?: EntireFieldWrapper<Maybe<ServiceMetaAttribute>>;
  serviceMetaAttributeId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  order?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
};

export type BusinessCategory = {
  __typename?: "BusinessCategory";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  name?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  businesses?: EntireFieldWrapper<Maybe<Array<Maybe<BusinessObject>>>>;
  serviceMetas?: EntireFieldWrapper<Maybe<Array<Maybe<ServiceMeta>>>>;
};

export type Industry = {
  __typename?: "Industry";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  name?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  accounts?: EntireFieldWrapper<Maybe<Array<Maybe<Account>>>>;
};

export type Department = {
  __typename?: "Department";
  id: EntireFieldWrapper<Scalars["Int"]>;
  name: EntireFieldWrapper<Scalars["String"]>;
  main: EntireFieldWrapper<Scalars["Boolean"]>;
  account?: EntireFieldWrapper<Maybe<Account>>;
  accountId?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
};

export type BusinessUnit = {
  __typename?: "BusinessUnit";
  id: EntireFieldWrapper<Scalars["Int"]>;
  name: EntireFieldWrapper<Scalars["String"]>;
};

export enum ServiceRegion {
  austria = "austria",
  germany = "germany",
}

export enum InteractionStatus {
  pending = "pending",
  active = "active",
  finished = "finished",
  cancelled = "cancelled",
}

export type Chat = {
  __typename?: "Chat";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  status?: EntireFieldWrapper<Maybe<InteractionStatus>>;
  chatUserId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  finishedAt?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  personActivity?: EntireFieldWrapper<Maybe<PersonActivity>>;
  team?: EntireFieldWrapper<Maybe<Team>>;
  user?: EntireFieldWrapper<Maybe<User>>;
};

export enum Team {
  administrators = "administrators",
  softwareDevelopment = "softwareDevelopment",
  accountManagement = "accountManagement",
  caseManagement = "caseManagement",
  clinicalAdministration = "clinicalAdministration",
  counseling1stLevel = "counseling1stLevel",
  counseling2ndLevel = "counseling2ndLevel",
  counselingTC = "counselingTC",
  onlineTeam = "onlineTeam",
  lifeManagement = "lifeManagement",
  serviceManagement = "serviceManagement",
  digitalServices = "digitalServices",
}

export type DriveDocument = {
  __typename?: "DriveDocument";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  title: EntireFieldWrapper<Scalars["String"]>;
  fileId: EntireFieldWrapper<Scalars["String"]>;
  link: EntireFieldWrapper<Scalars["String"]>;
  person?: EntireFieldWrapper<Maybe<Person>>;
  user?: EntireFieldWrapper<Maybe<User>>;
  personActivity?: EntireFieldWrapper<Maybe<PersonActivity>>;
  created?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
};

export type PapershiftShift = {
  __typename?: "PapershiftShift";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  startsAt?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  endsAt?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  createdAt?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  updatedAt?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  locationId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  papershiftWorkingArea?: EntireFieldWrapper<Maybe<PapershiftWorkingArea>>;
  users?: EntireFieldWrapper<Maybe<Array<Maybe<User>>>>;
};

export type Appointment = {
  __typename?: "Appointment";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  note?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  title?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  date?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  from?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  to?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  person?: EntireFieldWrapper<Maybe<Person>>;
  personId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  task?: EntireFieldWrapper<Maybe<Task>>;
  users?: EntireFieldWrapper<Maybe<Array<Maybe<User>>>>;
  topic?: EntireFieldWrapper<Maybe<Topic>>;
  topicId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  service?: EntireFieldWrapper<Maybe<Service>>;
  serviceId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  deletedAt?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  topicName?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  serviceName?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
};

export type CounselingUser = {
  __typename?: "CounselingUser";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  user?: EntireFieldWrapper<Maybe<User>>;
  service?: EntireFieldWrapper<Maybe<Service>>;
  personActivity?: EntireFieldWrapper<Maybe<PersonActivity>>;
};

export type HotDesk = {
  __typename?: "HotDesk";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  user?: EntireFieldWrapper<Maybe<User>>;
  extension?: EntireFieldWrapper<Maybe<Extension>>;
};

export type Extension = {
  __typename?: "Extension";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  primeDN?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  hotDesks?: EntireFieldWrapper<Maybe<Array<Maybe<HotDesk>>>>;
};

export type ServiceClient = {
  __typename?: "ServiceClient";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  service?: EntireFieldWrapper<Maybe<Service>>;
  serviceId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  client?: EntireFieldWrapper<Maybe<Client>>;
  clientId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  serviceMetaAttribute?: EntireFieldWrapper<Maybe<ServiceMetaAttribute>>;
  serviceMetaAttributeId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
};

export enum SourceOfInformation {
  previousClient = "previousClient",
  coWorker = "coWorker",
  familyMember = "familyMember",
  hr = "hr",
  informationBooth = "informationBooth",
  internalCommunication = "internalCommunication",
  eapMobileApp = "eapMobileApp",
  orientation = "orientation",
  peerSupportOrWellnessBureau = "peerSupportOrWellnessBureau",
  promotionalLiterature = "promotionalLiterature",
  supervisor = "supervisor",
  traumaDebriefing = "traumaDebriefing",
  unionRepresentative = "unionRepresentative",
  webIntake = "webIntake",
  websiteViewing = "websiteViewing",
  wellnessSeminar = "wellnessSeminar",
  healthServices = "healthServices",
  DECLINED = "DECLINED",
}

export enum ServiceStatus {
  research = "research",
  ready = "ready",
  finished = "finished",
  started = "started",
  counseling = "counseling",
}

export enum ServiceWizardStatus {
  incomplete = "incomplete",
  complete = "complete",
}

export type ServiceAddress = {
  __typename?: "ServiceAddress";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  radius?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  service?: EntireFieldWrapper<Maybe<Service>>;
  serviceId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  address?: EntireFieldWrapper<Maybe<Address>>;
  addressId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  serviceMetaAttribute?: EntireFieldWrapper<Maybe<ServiceMetaAttribute>>;
  serviceMetaAttributeId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
};

export type Hour = {
  __typename?: "Hour";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  day?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  open?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  close?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  service?: EntireFieldWrapper<Maybe<Service>>;
  serviceId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  business?: EntireFieldWrapper<Maybe<BusinessObject>>;
  businessId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
};

export type CounselingMandate = {
  __typename?: "CounselingMandate";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  sessionsAppointed?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  sessionsCompleted?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  counselor?: EntireFieldWrapper<Maybe<Person>>;
  service?: EntireFieldWrapper<Maybe<Service>>;
  personActivity?: EntireFieldWrapper<Maybe<PersonActivity>>;
  business?: EntireFieldWrapper<Maybe<BusinessObject>>;
  referenceNumber?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  lastActivity?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
};

export enum ServiceResearchObjectStatus {
  open = "open",
  suitable = "suitable",
  limited = "limited",
  unsuitable = "unsuitable",
}

export type BusinessObjectAttribute = {
  __typename?: "BusinessObjectAttribute";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  businessObject?: EntireFieldWrapper<Maybe<BusinessObject>>;
  key?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  value?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
};

export type Phone = {
  __typename?: "Phone";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  countryCode?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  type?: EntireFieldWrapper<Maybe<PhoneType>>;
  areaCode?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  number?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  contactAllowed?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  messagingAllowed?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  readableNumber?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  personId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
};

export enum PhoneType {
  private = "private",
  mobile = "mobile",
  work = "work",
  mobilework = "mobilework",
  fax = "fax",
}

export type Attachment = {
  __typename?: "Attachment";
  attachmentId?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  filename?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  mimeType?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
};

export type ClientComplaint = {
  __typename?: "ClientComplaint";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  user?: EntireFieldWrapper<Maybe<User>>;
  person?: EntireFieldWrapper<Maybe<Person>>;
  personId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  title?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  content?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  dateOfComplaint?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  createdAt?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  updatedAt?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
};

export type Relationship = {
  __typename?: "Relationship";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  relationship?: EntireFieldWrapper<Maybe<RelationshipType>>;
  activePerson?: EntireFieldWrapper<Maybe<Person>>;
  passivePerson?: EntireFieldWrapper<Maybe<Person>>;
  isEmergency?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  isHousehold?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
};

export enum RelationshipType {
  unknown = "unknown",
  wife = "wife",
  husband = "husband",
  partner = "partner",
  expartner = "expartner",
  friend = "friend",
  mother = "mother",
  father = "father",
  daughter = "daughter",
  son = "son",
  grandmother = "grandmother",
  grandfather = "grandfather",
  aunt = "aunt",
  uncle = "uncle",
  other = "other",
}

export type PersonSearchPayload = {
  __typename?: "PersonSearchPayload";
  persons?: EntireFieldWrapper<Maybe<Array<Maybe<Person>>>>;
  businesses?: EntireFieldWrapper<Maybe<Array<Maybe<BusinessObject>>>>;
  accounts?: EntireFieldWrapper<Maybe<Array<Maybe<Account>>>>;
};

export type BreadCrumbStatusDetail = {
  __typename?: "BreadCrumbStatusDetail";
  contractEnd?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  language?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
};

export type Statistic = {
  __typename?: "Statistic";
  fileId?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
};

export type LastFinishedChatInput = {
  chatUserId?: InputMaybe<Scalars["Float"]>;
  latest?: InputMaybe<Scalars["String"]>;
};

export type CaseMangerByUserViewModel = {
  __typename?: "CaseMangerByUserViewModel";
  user?: EntireFieldWrapper<Maybe<User>>;
  clients?: EntireFieldWrapper<Maybe<Array<Maybe<Client>>>>;
  reviews?: EntireFieldWrapper<Maybe<Array<Maybe<CaseManagementReview>>>>;
  total?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
};

export type GMailThread = {
  __typename?: "GMailThread";
  threadId: EntireFieldWrapper<Scalars["String"]>;
  topic?: EntireFieldWrapper<Maybe<Topic>>;
  personActivity?: EntireFieldWrapper<Maybe<PersonActivity>>;
  emails: EntireFieldWrapper<Array<GMailMessage>>;
};

export type ObservableJob = {
  __typename?: "ObservableJob";
  id: EntireFieldWrapper<Scalars["Int"]>;
  status: EntireFieldWrapper<JobStatus>;
  error?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  link?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
};

export enum JobStatus {
  running = "running",
  success = "success",
  error = "error",
}

export type UserWithTasksAndAppointments = {
  __typename?: "UserWithTasksAndAppointments";
  userId?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  personId?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  firstName?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  lastName?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  alias?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  title?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  tasks?: EntireFieldWrapper<Maybe<Array<Maybe<Task>>>>;
  appointments?: EntireFieldWrapper<Maybe<Array<Maybe<Appointment>>>>;
  todayAppointments?: EntireFieldWrapper<Maybe<Array<Maybe<Appointment>>>>;
};

export type LanguageViewModel = {
  __typename?: "LanguageViewModel";
  id?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  name?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  code?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  language?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  sortOrder?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  isActive?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  isDefault?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
};

export type UserChangesLog = {
  __typename?: "UserChangesLog";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  ipAddress?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  targetType?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  targetId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  type?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  userRole?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  userId?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  payload?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  isActive?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  createdAt?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  updatedAt?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  user?: EntireFieldWrapper<Maybe<User>>;
};

export type Mutation = {
  __typename?: "Mutation";
  updatePersonExtras?: EntireFieldWrapper<Maybe<Person>>;
  addPerson?: EntireFieldWrapper<Maybe<Person>>;
  setAnonymousPerson?: EntireFieldWrapper<Maybe<Person>>;
  updatePersonAgeAndGender?: EntireFieldWrapper<Maybe<Person>>;
  updatePerson?: EntireFieldWrapper<Maybe<Person>>;
  createAccount?: EntireFieldWrapper<Maybe<Account>>;
  updateAccount?: EntireFieldWrapper<Maybe<Account>>;
  createAddresses?: EntireFieldWrapper<Maybe<Array<Maybe<Address>>>>;
  updateAddresses?: EntireFieldWrapper<Maybe<Array<Maybe<Address>>>>;
  deleteAddress?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  deleteAppointment?: EntireFieldWrapper<Maybe<Appointment>>;
  updateAppointment?: EntireFieldWrapper<Maybe<Appointment>>;
  createAppointment?: EntireFieldWrapper<Maybe<Appointment>>;
  createBusinessObject?: EntireFieldWrapper<Maybe<BusinessObject>>;
  updateBusinessObject?: EntireFieldWrapper<Maybe<BusinessObject>>;
  assignBusinessContact?: EntireFieldWrapper<Maybe<BusinessObject>>;
  updateOrCreateContact?: EntireFieldWrapper<Maybe<BusinessObject>>;
  createBusinessUnit: EntireFieldWrapper<BusinessUnit>;
  editBusinessUnit: EntireFieldWrapper<BusinessUnit>;
  createCall?: EntireFieldWrapper<Maybe<Call>>;
  triggerResearchCall?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  makeClientCall?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  updateCall?: EntireFieldWrapper<Maybe<Call>>;
  cancelCall?: EntireFieldWrapper<Maybe<Call>>;
  createCaseManagementReview?: EntireFieldWrapper<Maybe<CaseManagementReview>>;
  createCaseManager?: EntireFieldWrapper<Maybe<CaseManager>>;
  updateCaseManagerByUserId?: EntireFieldWrapper<Maybe<CaseManager>>;
  updateCaseManager?: EntireFieldWrapper<Maybe<CaseManager>>;
  updateCaseManagementReviewAttribute?: EntireFieldWrapper<Maybe<CaseManagementReviewAttribute>>;
  createChat?: EntireFieldWrapper<Maybe<Chat>>;
  reassignChat?: EntireFieldWrapper<Maybe<Chat>>;
  updateChat?: EntireFieldWrapper<Maybe<Chat>>;
  finishChat?: EntireFieldWrapper<Maybe<Chat>>;
  addClient?: EntireFieldWrapper<Maybe<Client>>;
  updateClient?: EntireFieldWrapper<Maybe<Client>>;
  updateComplaint?: EntireFieldWrapper<Maybe<ClientComplaint>>;
  deleteComplaint?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  createContactPerson?: EntireFieldWrapper<Maybe<Person>>;
  updateContactPerson?: EntireFieldWrapper<Maybe<Person>>;
  createConversationReport?: EntireFieldWrapper<Maybe<ConversationReport>>;
  updateConversationReport?: EntireFieldWrapper<Maybe<ConversationReport>>;
  updateMandate?: EntireFieldWrapper<Maybe<CounselingMandate>>;
  demoCreateMandate?: EntireFieldWrapper<Maybe<CounselingMandate>>;
  createMandate?: EntireFieldWrapper<Maybe<CounselingMandate>>;
  createCounselingUser?: EntireFieldWrapper<Maybe<CounselingUser>>;
  createDepartment?: EntireFieldWrapper<Maybe<Department>>;
  updateDepartment?: EntireFieldWrapper<Maybe<Department>>;
  createEmail?: EntireFieldWrapper<Maybe<Array<Maybe<Email>>>>;
  updateEmails?: EntireFieldWrapper<Maybe<Array<Maybe<Email>>>>;
  deleteEmail?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  createDriveDocument?: EntireFieldWrapper<Maybe<DriveDocument>>;
  createDriveComplaintDocument?: EntireFieldWrapper<Maybe<DriveDocument>>;
  finishThread?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  dangerouslyDeleteMails?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  createGMail?: EntireFieldWrapper<Maybe<GMailMessage>>;
  createBusinessHours?: EntireFieldWrapper<Maybe<Hour>>;
  createHour?: EntireFieldWrapper<Maybe<Hour>>;
  updateHour?: EntireFieldWrapper<Maybe<Hour>>;
  deleteHour?: EntireFieldWrapper<Maybe<Hour>>;
  createClientMemo?: EntireFieldWrapper<Maybe<Memo>>;
  createPersonMemo?: EntireFieldWrapper<Maybe<Memo>>;
  createBusinessMemo?: EntireFieldWrapper<Maybe<Memo>>;
  updateClientMemo?: EntireFieldWrapper<Maybe<Memo>>;
  updateMemo?: EntireFieldWrapper<Maybe<Memo>>;
  cancelGMailMessage?: EntireFieldWrapper<Maybe<GMailMessage>>;
  assignGMailMessage?: EntireFieldWrapper<Maybe<Task>>;
  createGDPRJob?: EntireFieldWrapper<Maybe<ObservableJob>>;
  assignPerson?: EntireFieldWrapper<Maybe<PersonActivity>>;
  deleteActivity?: EntireFieldWrapper<Maybe<PersonActivity>>;
  smsNotification?: EntireFieldWrapper<Maybe<PersonActivity>>;
  createPhone?: EntireFieldWrapper<Maybe<Phone>>;
  updatePhones?: EntireFieldWrapper<Maybe<Array<Maybe<Phone>>>>;
  deletePhone?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  createServiceAddress?: EntireFieldWrapper<Maybe<ServiceAddress>>;
  deleteServiceAddress?: EntireFieldWrapper<Maybe<ServiceAddress>>;
  updateServiceAddress?: EntireFieldWrapper<Maybe<ServiceAddress>>;
  createServiceAttribute?: EntireFieldWrapper<Maybe<ServiceAttribute>>;
  updateServiceAttribute?: EntireFieldWrapper<Maybe<ServiceAttribute>>;
  deleteServiceAttribute?: EntireFieldWrapper<Maybe<ServiceAttribute>>;
  createServiceClient?: EntireFieldWrapper<Maybe<ServiceClient>>;
  deleteServiceClient?: EntireFieldWrapper<Maybe<ServiceClient>>;
  createServiceResearchObject?: EntireFieldWrapper<Maybe<ServiceResearchObject>>;
  createServiceResearchObjects?: EntireFieldWrapper<Maybe<Array<Maybe<ServiceResearchObject>>>>;
  createPlainResearchObject?: EntireFieldWrapper<Maybe<ServiceResearchObject>>;
  updatePlainResearchObject?: EntireFieldWrapper<Maybe<ServiceResearchObject>>;
  updateServiceResearchObject?: EntireFieldWrapper<Maybe<ServiceResearchObject>>;
  createCounselingService?: EntireFieldWrapper<Maybe<Service>>;
  createService?: EntireFieldWrapper<Maybe<Service>>;
  updateService?: EntireFieldWrapper<Maybe<Service>>;
  deleteService?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  toggleReportStatistic?: EntireFieldWrapper<Maybe<ConversationReport>>;
  snoozeTask?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  finishTask?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  assignTaskToTeam?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  assignTask?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  unassignTask?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  refreshTasks?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
  finishWpoAssignmentProcess?: EntireFieldWrapper<Maybe<Task>>;
  createCMReminderTask?: EntireFieldWrapper<Maybe<Task>>;
  createCustomTask?: EntireFieldWrapper<Maybe<Task>>;
  updateAbsent?: EntireFieldWrapper<Maybe<User>>;
  updateExtension?: EntireFieldWrapper<Maybe<User>>;
  updateTeams: EntireFieldWrapper<Array<Team>>;
};

export type MutationUpdatePersonExtrasArgs = {
  personExtraInput: PersonExtraInput;
  personId?: InputMaybe<Scalars["Int"]>;
};

export type MutationAddPersonArgs = {
  person: PersonInput;
};

export type MutationSetAnonymousPersonArgs = {
  personActivityId?: InputMaybe<Scalars["Int"]>;
  personId?: InputMaybe<Scalars["Int"]>;
};

export type MutationUpdatePersonAgeAndGenderArgs = {
  gender: Gender;
  initialAge: Scalars["Int"];
  id: Scalars["Int"];
};

export type MutationUpdatePersonArgs = {
  assistanceId?: InputMaybe<Scalars["String"]>;
  accountId?: InputMaybe<Scalars["Int"]>;
  person: PersonUpdateInput;
};

export type MutationCreateAccountArgs = {
  accountInput: AccountInsertInput;
};

export type MutationUpdateAccountArgs = {
  input?: InputMaybe<AccountUpdateInput>;
  id: Scalars["Int"];
};

export type MutationCreateAddressesArgs = {
  personId: Scalars["ID"];
  addressesInput: Array<AddressInput>;
};

export type MutationUpdateAddressesArgs = {
  personId: Scalars["ID"];
  addressesInput: Array<AddressInput>;
};

export type MutationDeleteAddressArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteAppointmentArgs = {
  id: Scalars["String"];
};

export type MutationUpdateAppointmentArgs = {
  appointmentInput: UpdateAppointmentInput;
};

export type MutationCreateAppointmentArgs = {
  appointmentInput: AppointmentInput;
};

export type MutationCreateBusinessObjectArgs = {
  contactPersonInput: PersonInput;
  businessObjectInput: BusinessObjectInput;
};

export type MutationUpdateBusinessObjectArgs = {
  input?: InputMaybe<BusinessObjectUpdateInput>;
  id: Scalars["Int"];
};

export type MutationAssignBusinessContactArgs = {
  personId: Scalars["Int"];
  businessObjectId: Scalars["Int"];
};

export type MutationUpdateOrCreateContactArgs = {
  personId?: InputMaybe<Scalars["Int"]>;
  input: PersonInputStrict;
  id: Scalars["Int"];
};

export type MutationCreateBusinessUnitArgs = {
  input?: InputMaybe<CreateBusinessUnitInput>;
};

export type MutationEditBusinessUnitArgs = {
  input?: InputMaybe<EditBusinessUnitInput>;
};

export type MutationCreateCallArgs = {
  random?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationTriggerResearchCallArgs = {
  phoneId?: InputMaybe<Scalars["Int"]>;
  primeDN: Scalars["Int"];
  serviceResearchObjectId?: InputMaybe<Scalars["Float"]>;
};

export type MutationMakeClientCallArgs = {
  primeDN?: InputMaybe<Scalars["Int"]>;
  phoneId?: InputMaybe<Scalars["Int"]>;
  personId?: InputMaybe<Scalars["Int"]>;
};

export type MutationUpdateCallArgs = {
  call: CallUpdateInput;
};

export type MutationCancelCallArgs = {
  callId: Scalars["Int"];
};

export type MutationCreateCaseManagementReviewArgs = {
  clientId?: InputMaybe<Scalars["Int"]>;
};

export type MutationCreateCaseManagerArgs = {
  userId?: InputMaybe<Scalars["Int"]>;
  clientId?: InputMaybe<Scalars["Int"]>;
};

export type MutationUpdateCaseManagerByUserIdArgs = {
  oldCaseManagerId?: InputMaybe<Scalars["Int"]>;
  newCaseManagerId?: InputMaybe<Scalars["Int"]>;
  serviceId: Scalars["Int"];
};

export type MutationUpdateCaseManagerArgs = {
  userId?: InputMaybe<Scalars["Int"]>;
  clientId: Scalars["Int"];
};

export type MutationUpdateCaseManagementReviewAttributeArgs = {
  caseManagementReviewAttributeInput?: InputMaybe<CaseManagementReviewAttributeInput>;
};

export type MutationCreateChatArgs = {
  chatInput?: InputMaybe<ChatInsertInput>;
};

export type MutationReassignChatArgs = {
  team?: InputMaybe<Team>;
  userId?: InputMaybe<Scalars["Int"]>;
  chatId: Scalars["Int"];
};

export type MutationUpdateChatArgs = {
  chatInput?: InputMaybe<ChatUpdateInput>;
};

export type MutationFinishChatArgs = {
  token?: InputMaybe<Scalars["String"]>;
  uid?: InputMaybe<Scalars["Int"]>;
  chatUserId?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

export type MutationAddClientArgs = {
  client: ClientInsertInput;
};

export type MutationUpdateClientArgs = {
  clientInput: ClientUpdateInput;
};

export type MutationUpdateComplaintArgs = {
  complaintInput: ClinetComplaintInput;
  id?: InputMaybe<Scalars["Int"]>;
};

export type MutationDeleteComplaintArgs = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type MutationCreateContactPersonArgs = {
  contactPersonInput?: InputMaybe<ContactPersonInput>;
};

export type MutationUpdateContactPersonArgs = {
  contactPersonUpdateInput?: InputMaybe<ContactPersonUpdateInput>;
};

export type MutationCreateConversationReportArgs = {
  report: ConversationReportInsertInput;
};

export type MutationUpdateConversationReportArgs = {
  report: ConversationReportUpdateInput;
};

export type MutationUpdateMandateArgs = {
  input?: InputMaybe<UpdateCounselingMandateInput>;
  id?: InputMaybe<Scalars["Int"]>;
};

export type MutationDemoCreateMandateArgs = {
  id: Scalars["Int"];
};

export type MutationCreateMandateArgs = {
  mandate: CounselingMandateInput;
};

export type MutationCreateCounselingUserArgs = {
  input: CounselingUserInput;
};

export type MutationCreateDepartmentArgs = {
  input: DepartmentInput;
  accountId: Scalars["Int"];
};

export type MutationUpdateDepartmentArgs = {
  input: DepartmentInput;
  id: Scalars["Int"];
};

export type MutationCreateEmailArgs = {
  personId: Scalars["ID"];
  emailsInput?: InputMaybe<Array<InputMaybe<EmailInput>>>;
};

export type MutationUpdateEmailsArgs = {
  personId: Scalars["ID"];
  emailsInput?: InputMaybe<Array<InputMaybe<EmailInput>>>;
};

export type MutationDeleteEmailArgs = {
  id: Scalars["ID"];
};

export type MutationCreateDriveDocumentArgs = {
  createDocumentInput?: InputMaybe<CreateDocumentInput>;
};

export type MutationCreateDriveComplaintDocumentArgs = {
  createDocumentInput?: InputMaybe<CreateDocumentInput>;
};

export type MutationFinishThreadArgs = {
  threadId: Scalars["String"];
};

export type MutationCreateGMailArgs = {
  classification: Scalars["String"];
  businessObjectId?: InputMaybe<Scalars["Int"]>;
  topicId?: InputMaybe<Scalars["Int"]>;
  personId?: InputMaybe<Scalars["Int"]>;
  threadId?: InputMaybe<Scalars["String"]>;
  message: Scalars["String"];
  sender: Scalars["String"];
  subject: Scalars["String"];
};

export type MutationCreateBusinessHoursArgs = {
  hour: BusinessHoursBaseInput;
};

export type MutationCreateHourArgs = {
  hour: HourBaseInput;
};

export type MutationUpdateHourArgs = {
  hour: HourUpdate;
};

export type MutationDeleteHourArgs = {
  id: Scalars["Float"];
};

export type MutationCreateClientMemoArgs = {
  clientId: Scalars["Int"];
  memo: MemoInsertClientInput;
};

export type MutationCreatePersonMemoArgs = {
  personId: Scalars["Int"];
  memo: MemoInsertInput;
};

export type MutationCreateBusinessMemoArgs = {
  businessId: Scalars["Int"];
  memo: MemoInsertInput;
};

export type MutationUpdateClientMemoArgs = {
  clientId: Scalars["Int"];
  memo: MemoUpdateInput;
};

export type MutationUpdateMemoArgs = {
  memo: MemoUpdateInput;
};

export type MutationCancelGMailMessageArgs = {
  threadId: Scalars["String"];
};

export type MutationAssignGMailMessageArgs = {
  team?: InputMaybe<Team>;
  userId?: InputMaybe<Scalars["Int"]>;
  threadId?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateGdprJobArgs = {
  personId: Scalars["Int"];
};

export type MutationAssignPersonArgs = {
  personId: Scalars["Int"];
  id: Scalars["Int"];
};

export type MutationDeleteActivityArgs = {
  id: Scalars["Int"];
};

export type MutationSmsNotificationArgs = {
  input?: InputMaybe<SmsInput>;
};

export type MutationCreatePhoneArgs = {
  personId: Scalars["ID"];
  phonesInput?: InputMaybe<Array<InputMaybe<PhoneInput>>>;
};

export type MutationUpdatePhonesArgs = {
  personId: Scalars["ID"];
  phonesInput?: InputMaybe<Array<InputMaybe<PhoneInput>>>;
};

export type MutationDeletePhoneArgs = {
  id: Scalars["ID"];
};

export type MutationCreateServiceAddressArgs = {
  serviceAddress: ServiceAddressCreateInput;
};

export type MutationDeleteServiceAddressArgs = {
  id: Scalars["Float"];
};

export type MutationUpdateServiceAddressArgs = {
  serviceAddress: ServiceAddressUpdateInput;
};

export type MutationCreateServiceAttributeArgs = {
  serviceAttribute: ServiceAttributeInput;
};

export type MutationUpdateServiceAttributeArgs = {
  serviceAttribute: ServiceAttributeUpdate;
};

export type MutationDeleteServiceAttributeArgs = {
  id: Scalars["Float"];
};

export type MutationCreateServiceClientArgs = {
  serviceClient: ServiceClientInput;
};

export type MutationDeleteServiceClientArgs = {
  id: Scalars["Float"];
};

export type MutationCreateServiceResearchObjectArgs = {
  serviceId: Scalars["ID"];
  serviceResearchObjectInput: ServiceResearchObjectInput;
};

export type MutationCreateServiceResearchObjectsArgs = {
  serviceId: Scalars["Int"];
  businessObjectIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type MutationCreatePlainResearchObjectArgs = {
  serviceId: Scalars["Int"];
  input: PlainSroInput;
};

export type MutationUpdatePlainResearchObjectArgs = {
  id: Scalars["Int"];
  input: PlainSroInput;
};

export type MutationUpdateServiceResearchObjectArgs = {
  id: Scalars["ID"];
  serviceResearchObjectInput: ServiceResearchObjectInput;
};

export type MutationCreateCounselingServiceArgs = {
  service: CounselingServiceInput;
};

export type MutationCreateServiceArgs = {
  service: ServiceInput;
};

export type MutationUpdateServiceArgs = {
  service?: InputMaybe<ServiceUpdateInput>;
  id: Scalars["Float"];
};

export type MutationDeleteServiceArgs = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type MutationToggleReportStatisticArgs = {
  itemId: Scalars["Int"];
  reportId: Scalars["Int"];
};

export type MutationSnoozeTaskArgs = {
  snoozeUntil?: InputMaybe<Scalars["DateTime"]>;
  taskId?: InputMaybe<Scalars["Int"]>;
};

export type MutationFinishTaskArgs = {
  revert?: InputMaybe<Scalars["Boolean"]>;
  taskId?: InputMaybe<Scalars["Int"]>;
};

export type MutationAssignTaskToTeamArgs = {
  start?: InputMaybe<Scalars["Boolean"]>;
  targetTeam?: InputMaybe<Team>;
  taskId?: InputMaybe<Scalars["Int"]>;
};

export type MutationAssignTaskArgs = {
  start?: InputMaybe<Scalars["Boolean"]>;
  userId?: InputMaybe<Scalars["Int"]>;
  taskId?: InputMaybe<Scalars["Int"]>;
};

export type MutationUnassignTaskArgs = {
  taskId?: InputMaybe<Scalars["Int"]>;
};

export type MutationFinishWpoAssignmentProcessArgs = {
  shouldSendWpoEmail?: InputMaybe<Scalars["Boolean"]>;
  shouldCreateTask?: InputMaybe<Scalars["Boolean"]>;
  personId?: InputMaybe<Scalars["Int"]>;
  messageId: Scalars["String"];
  ucmsId: Scalars["Int"];
};

export type MutationCreateCmReminderTaskArgs = {
  taskId?: InputMaybe<Scalars["Int"]>;
  snoozeUntil: Scalars["DateTime"];
  personId: Scalars["Int"];
};

export type MutationCreateCustomTaskArgs = {
  taskInput?: InputMaybe<TaskInput>;
};

export type MutationUpdateAbsentArgs = {
  absentUntil?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

export type MutationUpdateExtensionArgs = {
  extension?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

export type MutationUpdateTeamsArgs = {
  teams: Array<Team>;
};

export type PersonExtraInput = {
  informationSource?: InputMaybe<SourceOfInformation>;
  hrBusiness?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
};

export type PersonInput = {
  id?: InputMaybe<Scalars["Int"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  alias?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  abnormalities?: InputMaybe<Scalars["String"]>;
  birthDate?: InputMaybe<Scalars["DateTime"]>;
  note?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<Gender>;
  familyStatus?: InputMaybe<FamilyStatus>;
  log?: InputMaybe<Scalars["Boolean"]>;
  initialAge?: InputMaybe<Scalars["Int"]>;
  ageGuessed?: InputMaybe<Scalars["Boolean"]>;
  temporary?: InputMaybe<Scalars["Boolean"]>;
  phones?: InputMaybe<Array<InputMaybe<PhoneInput>>>;
  addresses?: InputMaybe<Array<InputMaybe<AddressInput>>>;
};

export type PhoneInput = {
  id?: InputMaybe<Scalars["Int"]>;
  countryCode?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<PhoneType>;
  areaCode?: InputMaybe<Scalars["String"]>;
  number?: InputMaybe<Scalars["String"]>;
  contactAllowed?: InputMaybe<Scalars["Boolean"]>;
  messagingAllowed?: InputMaybe<Scalars["Boolean"]>;
};

export type AddressInput = {
  id?: InputMaybe<Scalars["ID"]>;
  street?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  zip?: InputMaybe<Scalars["String"]>;
  person?: InputMaybe<PersonInput>;
  type?: InputMaybe<AddressType>;
  contactAllowed?: InputMaybe<Scalars["Boolean"]>;
  skipUpdateGeocode?: InputMaybe<Scalars["Boolean"]>;
};

export type PersonUpdateInput = {
  id?: InputMaybe<Scalars["Int"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  alias?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  abnormalities?: InputMaybe<Scalars["String"]>;
  birthDate?: InputMaybe<Scalars["DateTime"]>;
  note?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<Gender>;
  familyStatus?: InputMaybe<FamilyStatus>;
  log?: InputMaybe<Scalars["Boolean"]>;
  initialAge?: InputMaybe<Scalars["Int"]>;
  ageGuessed?: InputMaybe<Scalars["Boolean"]>;
  temporary?: InputMaybe<Scalars["Boolean"]>;
  phones?: InputMaybe<Array<InputMaybe<PhoneInput>>>;
  addresses?: InputMaybe<Array<InputMaybe<AddressInput>>>;
};

export type AccountInsertInput = {
  type?: InputMaybe<AccountType>;
  name?: InputMaybe<Scalars["String"]>;
  tariff?: InputMaybe<AccountTariff>;
  parentId?: InputMaybe<Scalars["Float"]>;
  industryId?: InputMaybe<Scalars["Float"]>;
  helpline?: InputMaybe<Scalars["String"]>;
  helpline2?: InputMaybe<Scalars["String"]>;
  helplineInternational?: InputMaybe<Scalars["String"]>;
  remarks?: InputMaybe<Scalars["String"]>;
  nFreeSTCs?: InputMaybe<Scalars["Float"]>;
  serviceTimes?: InputMaybe<Scalars["String"]>;
  usageRate?: InputMaybe<Scalars["String"]>;
  employeesCount?: InputMaybe<Scalars["Float"]>;
  executivesCount?: InputMaybe<Scalars["Float"]>;
  freeSessions?: InputMaybe<Scalars["Float"]>;
  lifeworksId?: InputMaybe<Scalars["String"]>;
  coaching?: InputMaybe<Scalars["Boolean"]>;
  accountServiceModuleIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pin?: InputMaybe<Scalars["Float"]>;
  chatRegistrationPin?: InputMaybe<Scalars["String"]>;
  contractStart?: InputMaybe<Scalars["DateTime"]>;
  contractEnd?: InputMaybe<Scalars["DateTime"]>;
  externalRef?: InputMaybe<Scalars["String"]>;
  memo?: InputMaybe<Scalars["String"]>;
  reporting?: InputMaybe<AccountReporting>;
  usageScope?: InputMaybe<AccountUsageScope>;
  serviceRegion?: InputMaybe<ServiceRegion>;
};

export type AccountUpdateInput = {
  type?: InputMaybe<AccountType>;
  name?: InputMaybe<Scalars["String"]>;
  tariff?: InputMaybe<AccountTariff>;
  parentId?: InputMaybe<Scalars["Float"]>;
  industryId?: InputMaybe<Scalars["Float"]>;
  helpline?: InputMaybe<Scalars["String"]>;
  helpline2?: InputMaybe<Scalars["String"]>;
  helplineInternational?: InputMaybe<Scalars["String"]>;
  remarks?: InputMaybe<Scalars["String"]>;
  nFreeSTCs?: InputMaybe<Scalars["Float"]>;
  serviceTimes?: InputMaybe<Scalars["String"]>;
  usageRate?: InputMaybe<Scalars["String"]>;
  employeesCount?: InputMaybe<Scalars["Float"]>;
  executivesCount?: InputMaybe<Scalars["Float"]>;
  freeSessions?: InputMaybe<Scalars["Float"]>;
  lifeworksId?: InputMaybe<Scalars["String"]>;
  coaching?: InputMaybe<Scalars["Boolean"]>;
  accountServiceModuleIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pin?: InputMaybe<Scalars["Float"]>;
  chatRegistrationPin?: InputMaybe<Scalars["String"]>;
  contractStart?: InputMaybe<Scalars["DateTime"]>;
  contractEnd?: InputMaybe<Scalars["DateTime"]>;
  externalRef?: InputMaybe<Scalars["String"]>;
  memo?: InputMaybe<Scalars["String"]>;
  reporting?: InputMaybe<AccountReporting>;
  usageScope?: InputMaybe<AccountUsageScope>;
  serviceRegion?: InputMaybe<ServiceRegion>;
};

export type UpdateAppointmentInput = {
  personId?: InputMaybe<Scalars["Float"]>;
  topicId?: InputMaybe<Scalars["Float"]>;
  serviceId?: InputMaybe<Scalars["Float"]>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  isAppointment?: InputMaybe<Scalars["Boolean"]>;
  note?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  date?: InputMaybe<Scalars["DateTime"]>;
  from?: InputMaybe<Scalars["DateTime"]>;
  to?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

export type AppointmentInput = {
  personId?: InputMaybe<Scalars["Float"]>;
  topicId?: InputMaybe<Scalars["Float"]>;
  serviceId?: InputMaybe<Scalars["Float"]>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  isAppointment?: InputMaybe<Scalars["Boolean"]>;
  note?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  date?: InputMaybe<Scalars["DateTime"]>;
  from?: InputMaybe<Scalars["DateTime"]>;
  to?: InputMaybe<Scalars["DateTime"]>;
};

export type BusinessObjectInput = {
  id?: InputMaybe<Scalars["ID"]>;
  account?: InputMaybe<Scalars["String"]>;
  businessCategoryId?: InputMaybe<Scalars["Float"]>;
  category?: InputMaybe<BusinessCategoryInput>;
  name?: InputMaybe<Scalars["String"]>;
  address?: InputMaybe<AddressInput>;
  businessObjectAttributes?: InputMaybe<Array<InputMaybe<BusinessObjectAttributesInput>>>;
  phones?: InputMaybe<Array<InputMaybe<PhoneInput>>>;
  info?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<EmailInput>;
  hours?: InputMaybe<Array<InputMaybe<BusinessHoursBaseInput>>>;
};

export type BusinessCategoryInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type BusinessObjectAttributesInput = {
  id?: InputMaybe<Scalars["ID"]>;
  key?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

export type EmailInput = {
  id?: InputMaybe<Scalars["ID"]>;
  email?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<EmailType>;
  contactAllowed?: InputMaybe<Scalars["Boolean"]>;
};

export type BusinessHoursBaseInput = {
  id?: InputMaybe<Scalars["Int"]>;
  day?: InputMaybe<Scalars["Float"]>;
  open?: InputMaybe<Scalars["DateTime"]>;
  close?: InputMaybe<Scalars["DateTime"]>;
  businessObjectId?: InputMaybe<Scalars["Int"]>;
};

export type BusinessObjectUpdateInput = {
  name?: InputMaybe<Scalars["String"]>;
  businessCategoryId?: InputMaybe<Scalars["Float"]>;
  phones?: InputMaybe<Array<InputMaybe<PhoneInput>>>;
  info?: InputMaybe<Scalars["String"]>;
  memo?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<EmailInput>;
  address?: InputMaybe<AddressInput>;
};

export type PersonInputStrict = {
  id?: InputMaybe<Scalars["Int"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  alias?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  abnormalities?: InputMaybe<Scalars["String"]>;
  birthDate?: InputMaybe<Scalars["DateTime"]>;
  note?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<Gender>;
  familyStatus?: InputMaybe<FamilyStatus>;
  log?: InputMaybe<Scalars["Boolean"]>;
  initialAge?: InputMaybe<Scalars["Int"]>;
  ageGuessed?: InputMaybe<Scalars["Boolean"]>;
  temporary?: InputMaybe<Scalars["Boolean"]>;
  phones: Array<PhoneInput>;
  addresses: Array<AddressInput>;
  emails: Array<EmailInput>;
};

export type CreateBusinessUnitInput = {
  name?: InputMaybe<Scalars["String"]>;
  accountId?: InputMaybe<Scalars["Int"]>;
};

export type EditBusinessUnitInput = {
  id?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type CallUpdateInput = {
  id?: InputMaybe<Scalars["ID"]>;
  accountId?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<InteractionStatus>;
};

export type CaseManagementReviewAttributeInput = {
  caseManagementReviewId?: InputMaybe<Scalars["Int"]>;
  clientReviewAttributeId?: InputMaybe<Scalars["Int"]>;
  attributeValue?: InputMaybe<Scalars["String"]>;
  date?: InputMaybe<Scalars["String"]>;
};

export type ChatInsertInput = {
  id?: InputMaybe<Scalars["ID"]>;
  chatUserId?: InputMaybe<Scalars["Int"]>;
  chatAlias?: InputMaybe<Scalars["String"]>;
  account?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<InteractionStatus>;
};

export type ChatUpdateInput = {
  id?: InputMaybe<Scalars["ID"]>;
  chatUserId?: InputMaybe<Scalars["Int"]>;
  chatAlias?: InputMaybe<Scalars["String"]>;
  account?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<InteractionStatus>;
};

export type ClientInsertInput = {
  id?: InputMaybe<Scalars["Int"]>;
  accountId?: InputMaybe<Scalars["Int"]>;
  clientType?: InputMaybe<ClientType>;
  chatAlias?: InputMaybe<Scalars["String"]>;
  departmentId?: InputMaybe<Scalars["Float"]>;
  language?: InputMaybe<Scalars["String"]>;
  healthInsuranceCompany?: InputMaybe<Scalars["String"]>;
  hrBusiness?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
  job?: InputMaybe<Scalars["String"]>;
  jobBand?: InputMaybe<Scalars["String"]>;
  person?: InputMaybe<PersonInput>;
  vip?: InputMaybe<Scalars["Boolean"]>;
  assistanceId?: InputMaybe<Scalars["String"]>;
  sourceOfInformation?: InputMaybe<SourceOfInformation>;
  yearOfEntry?: InputMaybe<Scalars["Int"]>;
  businessUnitId?: InputMaybe<Scalars["Int"]>;
  ucmsId?: InputMaybe<Scalars["Float"]>;
};

export type ClientUpdateInput = {
  id?: InputMaybe<Scalars["Int"]>;
  accountId?: InputMaybe<Scalars["Int"]>;
  clientType?: InputMaybe<ClientType>;
  chatAlias?: InputMaybe<Scalars["String"]>;
  departmentId?: InputMaybe<Scalars["Float"]>;
  language?: InputMaybe<Scalars["String"]>;
  healthInsuranceCompany?: InputMaybe<Scalars["String"]>;
  hrBusiness?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
  job?: InputMaybe<Scalars["String"]>;
  jobBand?: InputMaybe<Scalars["String"]>;
  person?: InputMaybe<PersonInput>;
  vip?: InputMaybe<Scalars["Boolean"]>;
  assistanceId?: InputMaybe<Scalars["String"]>;
  sourceOfInformation?: InputMaybe<SourceOfInformation>;
  yearOfEntry?: InputMaybe<Scalars["Int"]>;
  businessUnitId?: InputMaybe<Scalars["Int"]>;
  contactLanguageId?: InputMaybe<Scalars["Int"]>;
};

export type ClinetComplaintInput = {
  clientId?: InputMaybe<Scalars["Int"]>;
  dateOfComplaint?: InputMaybe<Scalars["DateTime"]>;
  title?: InputMaybe<Scalars["String"]>;
  content?: InputMaybe<Scalars["String"]>;
};

export type ContactPersonInput = {
  activePersonId: Scalars["Float"];
  relation: RelationshipInput;
  person: PersonInput;
};

export type RelationshipInput = {
  id?: InputMaybe<Scalars["Int"]>;
  relationship?: InputMaybe<RelationshipType>;
  isEmergency?: InputMaybe<Scalars["Boolean"]>;
  isHousehold?: InputMaybe<Scalars["Boolean"]>;
};

export type ContactPersonUpdateInput = {
  activePersonId: Scalars["Float"];
  relation: RelationshipInput;
  person: PersonInput;
};

export type ConversationReportInsertInput = {
  id?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<ReportState>;
  clientId?: InputMaybe<Scalars["Int"]>;
  topic?: InputMaybe<TopicInput>;
  mentalDescription?: InputMaybe<Scalars["String"]>;
  personActivity: PersonActivityInput;
};

export type TopicInput = {
  id?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type PersonActivityInput = {
  id?: InputMaybe<Scalars["Int"]>;
  start?: InputMaybe<Scalars["DateTime"]>;
  end?: InputMaybe<Scalars["DateTime"]>;
  activityType?: InputMaybe<Scalars["String"]>;
  refPersonId?: InputMaybe<Scalars["Float"]>;
};

export type ConversationReportUpdateInput = {
  id: Scalars["Int"];
  status?: InputMaybe<ReportState>;
  clientId?: InputMaybe<Scalars["Int"]>;
  topic?: InputMaybe<TopicInput>;
  mentalDescription?: InputMaybe<Scalars["String"]>;
  personActivity?: InputMaybe<PersonActivityInput>;
  feedback?: InputMaybe<Scalars["String"]>;
  summary?: InputMaybe<Scalars["String"]>;
  issue?: InputMaybe<Scalars["String"]>;
  agreement?: InputMaybe<Scalars["String"]>;
  cmCheckMessage?: InputMaybe<Scalars["String"]>;
  lifeworksService?: InputMaybe<Scalars["String"]>;
  lifeworksServiceIssue?: InputMaybe<Scalars["String"]>;
  lifeworksServiceSubIssue?: InputMaybe<Scalars["String"]>;
  risk?: InputMaybe<Scalars["Boolean"]>;
  riskInfo?: InputMaybe<Scalars["String"]>;
  mentalState?: InputMaybe<MentalState>;
  reportType?: InputMaybe<ReportType>;
  activeClientReviewAttributeGroupId?: InputMaybe<Scalars["Int"]>;
  activeClientReviewTemplateId?: InputMaybe<Scalars["Int"]>;
  conversationReportReviewAttributes?: InputMaybe<
    Array<InputMaybe<ConversationReportReviewAttributeBaseInput>>
  >;
  connectedServices?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type ConversationReportReviewAttributeBaseInput = {
  id?: InputMaybe<Scalars["Int"]>;
  attributeValue?: InputMaybe<Scalars["String"]>;
};

export type UpdateCounselingMandateInput = {
  sessionsAppointed?: InputMaybe<Scalars["Float"]>;
  sessionsCompleted?: InputMaybe<Scalars["Float"]>;
};

export type CounselingMandateInput = {
  serviceId: Scalars["Float"];
  counselorId?: InputMaybe<Scalars["Float"]>;
};

export type CounselingUserInput = {
  serviceId: Scalars["Float"];
  userId: Scalars["Float"];
};

export type DepartmentInput = {
  name: Scalars["String"];
  main?: InputMaybe<Scalars["Boolean"]>;
};

export type CreateDocumentInput = {
  personId?: InputMaybe<Scalars["Float"]>;
  fileId?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  link?: InputMaybe<Scalars["String"]>;
};

export type HourBaseInput = {
  id?: InputMaybe<Scalars["Int"]>;
  day?: InputMaybe<Scalars["Float"]>;
  open?: InputMaybe<Scalars["DateTime"]>;
  close?: InputMaybe<Scalars["DateTime"]>;
  serviceId?: InputMaybe<Scalars["Int"]>;
  businessId?: InputMaybe<Scalars["Int"]>;
};

export type HourUpdate = {
  id?: InputMaybe<Scalars["Int"]>;
  day?: InputMaybe<Scalars["Float"]>;
  open?: InputMaybe<Scalars["DateTime"]>;
  close?: InputMaybe<Scalars["DateTime"]>;
  serviceId?: InputMaybe<Scalars["Int"]>;
  businessId?: InputMaybe<Scalars["Int"]>;
};

export type MemoInsertClientInput = {
  id?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["String"]>;
  clientId?: InputMaybe<Scalars["Int"]>;
  topic?: InputMaybe<TopicInput>;
  personId?: InputMaybe<Scalars["Float"]>;
  personActivity: PersonActivityInput;
  note?: InputMaybe<Scalars["String"]>;
  saveContact?: InputMaybe<Scalars["Boolean"]>;
  contact?: InputMaybe<Scalars["String"]>;
};

export type MemoInsertInput = {
  id?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["String"]>;
  clientId?: InputMaybe<Scalars["Int"]>;
  topic?: InputMaybe<TopicInput>;
  personId?: InputMaybe<Scalars["Float"]>;
  personActivity: PersonActivityInput;
  note?: InputMaybe<Scalars["String"]>;
  saveContact?: InputMaybe<Scalars["Boolean"]>;
  contact?: InputMaybe<Scalars["String"]>;
};

export type MemoUpdateInput = {
  id: Scalars["Int"];
  status?: InputMaybe<Scalars["String"]>;
  clientId?: InputMaybe<Scalars["Int"]>;
  topic?: InputMaybe<TopicInput>;
  personId?: InputMaybe<Scalars["Float"]>;
  personActivity?: InputMaybe<PersonActivityInput>;
  note?: InputMaybe<Scalars["String"]>;
  saveContact?: InputMaybe<Scalars["Boolean"]>;
  contact?: InputMaybe<Scalars["String"]>;
};

export type SmsInput = {
  body?: InputMaybe<Scalars["String"]>;
  phoneId?: InputMaybe<Scalars["Float"]>;
  personId?: InputMaybe<Scalars["Float"]>;
  topicId?: InputMaybe<Scalars["Float"]>;
  serviceId?: InputMaybe<Scalars["Float"]>;
};

export type ServiceAddressCreateInput = {
  id?: InputMaybe<Scalars["Int"]>;
  serviceId?: InputMaybe<Scalars["Int"]>;
  serviceMetaAttributeId?: InputMaybe<Scalars["Int"]>;
  radius?: InputMaybe<Scalars["Int"]>;
  address?: InputMaybe<AddressInput>;
};

export type ServiceAddressUpdateInput = {
  id?: InputMaybe<Scalars["Int"]>;
  serviceId?: InputMaybe<Scalars["Int"]>;
  serviceMetaAttributeId?: InputMaybe<Scalars["Int"]>;
  radius?: InputMaybe<Scalars["Int"]>;
  address?: InputMaybe<AddressInput>;
};

export type ServiceAttributeInput = {
  id?: InputMaybe<Scalars["Int"]>;
  value?: InputMaybe<Scalars["String"]>;
  serviceId?: InputMaybe<Scalars["Int"]>;
  serviceMetaAttributeId?: InputMaybe<Scalars["Int"]>;
};

export type ServiceAttributeUpdate = {
  id?: InputMaybe<Scalars["Int"]>;
  value?: InputMaybe<Scalars["String"]>;
  serviceId?: InputMaybe<Scalars["Int"]>;
};

export type ServiceClientInput = {
  id?: InputMaybe<Scalars["Int"]>;
  relationship?: InputMaybe<RelationshipType>;
  serviceId?: InputMaybe<Scalars["Int"]>;
  serviceMetaAttributeId?: InputMaybe<Scalars["Int"]>;
  client?: InputMaybe<ServiceClientClientInsertInput>;
};

export type ServiceClientClientInsertInput = {
  id?: InputMaybe<Scalars["Int"]>;
  accountId?: InputMaybe<Scalars["Int"]>;
  clientType?: InputMaybe<ClientType>;
  chatAlias?: InputMaybe<Scalars["String"]>;
  departmentId?: InputMaybe<Scalars["Float"]>;
  language?: InputMaybe<Scalars["String"]>;
  healthInsuranceCompany?: InputMaybe<Scalars["String"]>;
  hrBusiness?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
  job?: InputMaybe<Scalars["String"]>;
  jobBand?: InputMaybe<Scalars["String"]>;
  person?: InputMaybe<PersonInput>;
  vip?: InputMaybe<Scalars["Boolean"]>;
  assistanceId?: InputMaybe<Scalars["String"]>;
  sourceOfInformation?: InputMaybe<SourceOfInformation>;
  yearOfEntry?: InputMaybe<Scalars["Int"]>;
  businessUnitId?: InputMaybe<Scalars["Int"]>;
};

export type ServiceResearchObjectInput = {
  id?: InputMaybe<Scalars["ID"]>;
  businessObject?: InputMaybe<BusinessObjectInput>;
  note?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  statusChangeReason?: InputMaybe<Scalars["String"]>;
  info?: InputMaybe<Scalars["String"]>;
  descriptor?: InputMaybe<Scalars["String"]>;
  contactPerson?: InputMaybe<PersonInput>;
};

export type PlainSroInput = {
  id?: InputMaybe<Scalars["ID"]>;
  businessObject?: InputMaybe<BusinessObjectInput>;
  note?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  statusChangeReason?: InputMaybe<Scalars["String"]>;
  info?: InputMaybe<Scalars["String"]>;
  descriptor?: InputMaybe<Scalars["String"]>;
  contactPerson?: InputMaybe<PersonInput>;
};

export type CounselingServiceInput = {
  id?: InputMaybe<Scalars["Int"]>;
  clientId?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<ServiceType>;
  topic?: InputMaybe<TopicInput>;
};

export type ServiceInput = {
  id?: InputMaybe<Scalars["Int"]>;
  clientId?: InputMaybe<Scalars["Int"]>;
  serviceMetaId?: InputMaybe<Scalars["Int"]>;
  topic?: InputMaybe<TopicInput>;
};

export type ServiceUpdateInput = {
  wizardStep?: InputMaybe<Scalars["Int"]>;
  wizardStatus?: InputMaybe<ServiceWizardStatus>;
  status?: InputMaybe<ServiceStatus>;
  extendedInfo?: InputMaybe<Scalars["String"]>;
};

export type TaskInput = {
  id?: InputMaybe<Scalars["Int"]>;
  title?: InputMaybe<Scalars["String"]>;
  team?: InputMaybe<Team>;
  path?: InputMaybe<Scalars["String"]>;
  note?: InputMaybe<Scalars["String"]>;
  topicId?: InputMaybe<Scalars["Float"]>;
  serviceId?: InputMaybe<Scalars["Float"]>;
  userId?: InputMaybe<Scalars["Float"]>;
  personId?: InputMaybe<Scalars["Float"]>;
  dueDate?: InputMaybe<Scalars["String"]>;
  snoozeUntil?: InputMaybe<Scalars["String"]>;
};

export type Subscription = {
  __typename?: "Subscription";
  personsByBusinessSubscription: EntireFieldWrapper<Array<Person>>;
  personSubscription: EntireFieldWrapper<Person>;
  accountSubscription?: EntireFieldWrapper<Maybe<Account>>;
  personAppointmentsSubscription?: EntireFieldWrapper<Maybe<Array<Maybe<Appointment>>>>;
  myAppointmentsSubscription?: EntireFieldWrapper<Maybe<Array<Maybe<Appointment>>>>;
  appointmentSubscription?: EntireFieldWrapper<Maybe<Appointment>>;
  appointmentDailySubscription?: EntireFieldWrapper<Maybe<Array<Maybe<Appointment>>>>;
  businessObjectSubscription?: EntireFieldWrapper<Maybe<BusinessObject>>;
  businessUnitsSubscription: EntireFieldWrapper<Array<BusinessUnit>>;
  pendingCallsSubscription?: EntireFieldWrapper<Maybe<Array<Maybe<Call>>>>;
  caseManagementReviewsSubscription?: EntireFieldWrapper<Maybe<Array<Maybe<CaseManagementReview>>>>;
  caseManagementReviewSubscription?: EntireFieldWrapper<Maybe<CaseManagementReview>>;
  caseManagerSubscription?: EntireFieldWrapper<Maybe<CaseManager>>;
  caseManagementReviewAttributeSubscription?: EntireFieldWrapper<
    Maybe<CaseManagementReviewAttribute>
  >;
  activeChatsSubscription: EntireFieldWrapper<Array<Chat>>;
  lastFinishedChatSubscription?: EntireFieldWrapper<Maybe<Array<Maybe<Chat>>>>;
  riskHistorySubscription?: EntireFieldWrapper<Maybe<Array<Maybe<ClientAttribute>>>>;
  myCMClientsSubscription?: EntireFieldWrapper<Maybe<Array<Maybe<Client>>>>;
  getCMClientsSubscription?: EntireFieldWrapper<Maybe<Array<Maybe<Client>>>>;
  mySTCClientsSubscription?: EntireFieldWrapper<Maybe<Array<Maybe<Client>>>>;
  getSTCClientsSubscription?: EntireFieldWrapper<Maybe<Array<Maybe<Client>>>>;
  allMandatesSubscription?: EntireFieldWrapper<Maybe<Array<Maybe<CounselingMandate>>>>;
  departmentsSubscription: EntireFieldWrapper<Array<Department>>;
  getDriveDocumentsSubscription: EntireFieldWrapper<Array<DriveDocument>>;
  papershiftShiftsSubscription?: EntireFieldWrapper<Maybe<Array<Maybe<PapershiftShift>>>>;
  allActivitiesSubscription?: EntireFieldWrapper<Maybe<PersonActivity>>;
  personActivitiesSubscription?: EntireFieldWrapper<Maybe<PersonActivity>>;
  activeActivitiesSubscription: EntireFieldWrapper<Array<PersonActivity>>;
  pushActivitySubscription?: EntireFieldWrapper<Maybe<PushActivity>>;
  serviceResearchObjectSubscription?: EntireFieldWrapper<
    Maybe<Array<Maybe<ServiceResearchObject>>>
  >;
  servicesSubscription?: EntireFieldWrapper<Maybe<Array<Maybe<Service>>>>;
  serviceSubscription?: EntireFieldWrapper<Maybe<Service>>;
  servicesByTypeSubscription?: EntireFieldWrapper<Maybe<Array<Maybe<Service>>>>;
  statisticItemsByReportSubscription?: EntireFieldWrapper<Maybe<Array<Maybe<StatisticItem>>>>;
  taskSubscription?: EntireFieldWrapper<Maybe<Task>>;
  currentTasksSubscription?: EntireFieldWrapper<Maybe<Array<Maybe<Task>>>>;
  userFromPersonSubscription?: EntireFieldWrapper<Maybe<User>>;
  userSubscription?: EntireFieldWrapper<Maybe<User>>;
};

export type SubscriptionPersonsByBusinessSubscriptionArgs = {
  businessId: Scalars["Int"];
};

export type SubscriptionPersonSubscriptionArgs = {
  id: Scalars["Int"];
};

export type SubscriptionAccountSubscriptionArgs = {
  id: Scalars["Int"];
};

export type SubscriptionPersonAppointmentsSubscriptionArgs = {
  personId: Scalars["Int"];
};

export type SubscriptionMyAppointmentsSubscriptionArgs = {
  userId?: InputMaybe<Scalars["Int"]>;
};

export type SubscriptionAppointmentSubscriptionArgs = {
  endsAt: Scalars["String"];
  startsAt: Scalars["String"];
  userIds: Array<Scalars["Int"]>;
};

export type SubscriptionAppointmentDailySubscriptionArgs = {
  endsAt: Scalars["String"];
  startsAt: Scalars["String"];
};

export type SubscriptionBusinessObjectSubscriptionArgs = {
  id: Scalars["Int"];
};

export type SubscriptionBusinessUnitsSubscriptionArgs = {
  accountId: Scalars["Int"];
};

export type SubscriptionCaseManagementReviewsSubscriptionArgs = {
  includeValues?: InputMaybe<Array<Scalars["String"]>>;
};

export type SubscriptionCaseManagementReviewSubscriptionArgs = {
  personId?: InputMaybe<Scalars["Int"]>;
};

export type SubscriptionCaseManagerSubscriptionArgs = {
  personId?: InputMaybe<Scalars["Int"]>;
};

export type SubscriptionCaseManagementReviewAttributeSubscriptionArgs = {
  clientReviewAttributeId?: InputMaybe<Scalars["Int"]>;
  caseManagementReviewId?: InputMaybe<Scalars["Int"]>;
};

export type SubscriptionLastFinishedChatSubscriptionArgs = {
  chats?: InputMaybe<Array<InputMaybe<LastFinishedChatInput>>>;
};

export type SubscriptionRiskHistorySubscriptionArgs = {
  personId?: InputMaybe<Scalars["Int"]>;
};

export type SubscriptionGetCmClientsSubscriptionArgs = {
  userId: Scalars["Int"];
};

export type SubscriptionGetStcClientsSubscriptionArgs = {
  userId: Scalars["Int"];
};

export type SubscriptionAllMandatesSubscriptionArgs = {
  active?: InputMaybe<Scalars["Boolean"]>;
};

export type SubscriptionDepartmentsSubscriptionArgs = {
  accountId: Scalars["Int"];
};

export type SubscriptionGetDriveDocumentsSubscriptionArgs = {
  personIds: Array<Scalars["Int"]>;
};

export type SubscriptionPapershiftShiftsSubscriptionArgs = {
  endsAt: Scalars["String"];
  startsAt: Scalars["String"];
};

export type SubscriptionAllActivitiesSubscriptionArgs = {
  personIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type SubscriptionPersonActivitiesSubscriptionArgs = {
  topicId?: InputMaybe<Scalars["Int"]>;
  personId: Scalars["Int"];
};

export type SubscriptionServiceResearchObjectSubscriptionArgs = {
  serviceId: Scalars["Int"];
};

export type SubscriptionServicesSubscriptionArgs = {
  statusNot?: InputMaybe<Array<InputMaybe<ServiceStatus>>>;
};

export type SubscriptionServiceSubscriptionArgs = {
  id: Scalars["Int"];
};

export type SubscriptionServicesByTypeSubscriptionArgs = {
  status?: InputMaybe<Array<InputMaybe<ServiceStatus>>>;
  statusNot?: InputMaybe<Array<InputMaybe<ServiceStatus>>>;
  type?: InputMaybe<ServiceType>;
};

export type SubscriptionStatisticItemsByReportSubscriptionArgs = {
  reportId: Scalars["Int"];
};

export type SubscriptionCurrentTasksSubscriptionArgs = {
  withSnoozed?: InputMaybe<Scalars["Boolean"]>;
  userId?: InputMaybe<Scalars["Int"]>;
  unassigned?: InputMaybe<Scalars["Boolean"]>;
  pool?: InputMaybe<Scalars["Boolean"]>;
};

export type SubscriptionUserFromPersonSubscriptionArgs = {
  personId: Scalars["Int"];
};

export type SubscriptionUserSubscriptionArgs = {
  id: Scalars["Int"];
};

export type PushActivity = {
  __typename?: "PushActivity";
  status?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  callType?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  personActivity?: EntireFieldWrapper<Maybe<PersonActivity>>;
};

export type AccountBaseInput = {
  type?: InputMaybe<AccountType>;
  name?: InputMaybe<Scalars["String"]>;
  tariff?: InputMaybe<AccountTariff>;
  parentId?: InputMaybe<Scalars["Float"]>;
  industryId?: InputMaybe<Scalars["Float"]>;
  helpline?: InputMaybe<Scalars["String"]>;
  helpline2?: InputMaybe<Scalars["String"]>;
  helplineInternational?: InputMaybe<Scalars["String"]>;
  remarks?: InputMaybe<Scalars["String"]>;
  nFreeSTCs?: InputMaybe<Scalars["Float"]>;
  serviceTimes?: InputMaybe<Scalars["String"]>;
  usageRate?: InputMaybe<Scalars["String"]>;
  employeesCount?: InputMaybe<Scalars["Float"]>;
  executivesCount?: InputMaybe<Scalars["Float"]>;
  freeSessions?: InputMaybe<Scalars["Float"]>;
  lifeworksId?: InputMaybe<Scalars["String"]>;
  coaching?: InputMaybe<Scalars["Boolean"]>;
  accountServiceModuleIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pin?: InputMaybe<Scalars["Float"]>;
  chatRegistrationPin?: InputMaybe<Scalars["String"]>;
  contractStart?: InputMaybe<Scalars["DateTime"]>;
  contractEnd?: InputMaybe<Scalars["DateTime"]>;
  externalRef?: InputMaybe<Scalars["String"]>;
  memo?: InputMaybe<Scalars["String"]>;
  reporting?: InputMaybe<AccountReporting>;
  usageScope?: InputMaybe<AccountUsageScope>;
  serviceRegion?: InputMaybe<ServiceRegion>;
};

export type CallBaseInput = {
  id?: InputMaybe<Scalars["ID"]>;
  accountId?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<InteractionStatus>;
};

export type CallInsertInput = {
  id?: InputMaybe<Scalars["ID"]>;
  accountId?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<InteractionStatus>;
};

export type CaseMangerViewModel = {
  __typename?: "CaseMangerViewModel";
  caseId?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  userId?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  userName?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  userAlias?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  clientId?: EntireFieldWrapper<Maybe<Scalars["Float"]>>;
  clientName?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  clientAlias?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  clientAccountName?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  flag?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  flagupdatedAt?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  reviewupdatedAt?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  topics?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
};

export type ChatBaseInput = {
  id?: InputMaybe<Scalars["ID"]>;
  chatUserId?: InputMaybe<Scalars["Int"]>;
  chatAlias?: InputMaybe<Scalars["String"]>;
  account?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<InteractionStatus>;
};

export type ClientBaseInput = {
  id?: InputMaybe<Scalars["Int"]>;
  accountId?: InputMaybe<Scalars["Int"]>;
  clientType?: InputMaybe<ClientType>;
  chatAlias?: InputMaybe<Scalars["String"]>;
  departmentId?: InputMaybe<Scalars["Float"]>;
  language?: InputMaybe<Scalars["String"]>;
  healthInsuranceCompany?: InputMaybe<Scalars["String"]>;
  hrBusiness?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
  job?: InputMaybe<Scalars["String"]>;
  jobBand?: InputMaybe<Scalars["String"]>;
  person?: InputMaybe<PersonInput>;
  vip?: InputMaybe<Scalars["Boolean"]>;
  assistanceId?: InputMaybe<Scalars["String"]>;
  sourceOfInformation?: InputMaybe<SourceOfInformation>;
  yearOfEntry?: InputMaybe<Scalars["Int"]>;
  businessUnitId?: InputMaybe<Scalars["Int"]>;
};

export type ConversationReportInput = {
  id?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<ReportState>;
  clientId?: InputMaybe<Scalars["Int"]>;
  topic?: InputMaybe<TopicInput>;
  mentalDescription?: InputMaybe<Scalars["String"]>;
  personActivity?: InputMaybe<PersonActivityInput>;
};

export type EmailUpdateInput = {
  id?: InputMaybe<Scalars["ID"]>;
  email?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<EmailType>;
  contactAllowed?: InputMaybe<Scalars["Boolean"]>;
};

export type MemoInput = {
  id?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["String"]>;
  clientId?: InputMaybe<Scalars["Int"]>;
  topic?: InputMaybe<TopicInput>;
  personId?: InputMaybe<Scalars["Float"]>;
  personActivity?: InputMaybe<PersonActivityInput>;
  note?: InputMaybe<Scalars["String"]>;
  saveContact?: InputMaybe<Scalars["Boolean"]>;
  contact?: InputMaybe<Scalars["String"]>;
};

export type PhoneUpdateInput = {
  id?: InputMaybe<Scalars["Int"]>;
  countryCode?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<PhoneType>;
  areaCode?: InputMaybe<Scalars["String"]>;
  number?: InputMaybe<Scalars["String"]>;
  contactAllowed?: InputMaybe<Scalars["Boolean"]>;
  messagingAllowed?: InputMaybe<Scalars["Boolean"]>;
};

export type ProtocolCreationLog = {
  __typename?: "ProtocolCreationLog";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  payload?: EntireFieldWrapper<Maybe<Scalars["String"]>>;
  createdAt?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
  updatedAt?: EntireFieldWrapper<Maybe<Scalars["DateTime"]>>;
};

export type RelationshipUpdateInput = {
  id?: InputMaybe<Scalars["Int"]>;
  relationship?: InputMaybe<RelationshipType>;
  isEmergency?: InputMaybe<Scalars["Boolean"]>;
  isHousehold?: InputMaybe<Scalars["Boolean"]>;
};

export type ServiceAddressInput = {
  id?: InputMaybe<Scalars["Int"]>;
  serviceId?: InputMaybe<Scalars["Int"]>;
  serviceMetaAttributeId?: InputMaybe<Scalars["Int"]>;
  radius?: InputMaybe<Scalars["Int"]>;
  address?: InputMaybe<AddressInput>;
};

export type ServiceTeamAssignment = {
  __typename?: "ServiceTeamAssignment";
  id?: EntireFieldWrapper<Maybe<Scalars["Int"]>>;
  serviceMeta?: EntireFieldWrapper<Maybe<ServiceMeta>>;
  taskType?: EntireFieldWrapper<Maybe<ServiceStatus>>;
  targetTeam?: EntireFieldWrapper<Maybe<Team>>;
  isActive?: EntireFieldWrapper<Maybe<Scalars["Boolean"]>>;
};

export type CreateAccountMutationVariables = Exact<{
  accountInput: AccountInsertInput;
}>;

export type CreateAccountMutation = { __typename?: "Mutation" } & {
  createAccount?: Maybe<
    { __typename: "Account" } & Pick<
      Account,
      | "id"
      | "chatRegistrationPin"
      | "coaching"
      | "contractEnd"
      | "contractStart"
      | "employeesCount"
      | "executivesCount"
      | "externalRef"
      | "lifeworksId"
      | "helpline"
      | "helpline2"
      | "helplineInternational"
      | "remarks"
      | "nFreeSTCs"
      | "serviceTimes"
      | "usageRate"
      | "freeSessions"
      | "isAssistance"
      | "name"
      | "pin"
      | "reporting"
      | "tariff"
      | "type"
      | "updatedAt"
      | "usageScope"
      | "validationPattern"
      | "serviceRegion"
    > & { industry?: Maybe<{ __typename?: "Industry" } & Pick<Industry, "id" | "name">> }
  >;
};

export type UpdateAccountMutationVariables = Exact<{
  id: Scalars["Int"];
  input?: InputMaybe<AccountUpdateInput>;
}>;

export type UpdateAccountMutation = { __typename?: "Mutation" } & {
  updateAccount?: Maybe<
    { __typename: "Account" } & Pick<
      Account,
      | "id"
      | "chatRegistrationPin"
      | "coaching"
      | "contractEnd"
      | "contractStart"
      | "employeesCount"
      | "executivesCount"
      | "externalRef"
      | "lifeworksId"
      | "helpline"
      | "helpline2"
      | "helplineInternational"
      | "remarks"
      | "nFreeSTCs"
      | "serviceTimes"
      | "usageRate"
      | "freeSessions"
      | "isAssistance"
      | "name"
      | "pin"
      | "reporting"
      | "tariff"
      | "type"
      | "updatedAt"
      | "usageScope"
      | "validationPattern"
      | "serviceRegion"
    > & {
        accountServiceModules?: Maybe<
          Array<
            Maybe<
              { __typename?: "ServiceMeta" } & Pick<
                ServiceMeta,
                "id" | "name" | "serviceCategoryId"
              >
            >
          >
        >;
        parent?: Maybe<{ __typename?: "Account" } & Pick<Account, "id" | "name">>;
        business?: Maybe<
          { __typename: "BusinessObject" } & Pick<
            BusinessObject,
            "id" | "name" | "url" | "info" | "accountId"
          > & {
              address?: Maybe<
                { __typename: "Address" } & Pick<
                  Address,
                  "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
                >
              >;
              email?: Maybe<
                { __typename: "Email" } & Pick<Email, "id" | "type" | "contactAllowed" | "email">
              >;
              persons?: Maybe<
                Array<
                  Maybe<
                    { __typename: "Person" } & Pick<
                      Person,
                      | "id"
                      | "salut"
                      | "name"
                      | "alias"
                      | "age"
                      | "ageGuessed"
                      | "firstName"
                      | "lastName"
                      | "temporary"
                      | "birthDate"
                    >
                  >
                >
              >;
              phones?: Maybe<
                Array<
                  Maybe<
                    { __typename: "Phone" } & Pick<
                      Phone,
                      | "id"
                      | "type"
                      | "contactAllowed"
                      | "messagingAllowed"
                      | "countryCode"
                      | "areaCode"
                      | "number"
                      | "readableNumber"
                    >
                  >
                >
              >;
            }
        >;
        industry?: Maybe<{ __typename?: "Industry" } & Pick<Industry, "id" | "name">>;
      }
  >;
};

export type CreateAddressesMutationVariables = Exact<{
  personId: Scalars["ID"];
  addressesInput: Array<AddressInput> | AddressInput;
}>;

export type CreateAddressesMutation = { __typename?: "Mutation" } & {
  createAddresses?: Maybe<
    Array<
      Maybe<
        { __typename: "Address" } & Pick<
          Address,
          "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
        >
      >
    >
  >;
};

export type DeleteAddressMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteAddressMutation = { __typename?: "Mutation" } & Pick<Mutation, "deleteAddress">;

export type UpdateAddressesMutationVariables = Exact<{
  personId: Scalars["ID"];
  addressesInput: Array<AddressInput> | AddressInput;
}>;

export type UpdateAddressesMutation = { __typename?: "Mutation" } & {
  updateAddresses?: Maybe<
    Array<
      Maybe<
        { __typename: "Address" } & Pick<
          Address,
          "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
        >
      >
    >
  >;
};

export type CreateAppointmentMutationVariables = Exact<{
  appointmentInput: AppointmentInput;
}>;

export type CreateAppointmentMutation = { __typename?: "Mutation" } & {
  createAppointment?: Maybe<
    { __typename: "Appointment" } & Pick<
      Appointment,
      "id" | "title" | "note" | "date" | "from" | "to" | "deletedAt"
    > & {
        users?: Maybe<
          Array<
            Maybe<
              { __typename?: "User" } & Pick<User, "id"> & {
                  person?: Maybe<
                    { __typename: "Person" } & Pick<
                      Person,
                      | "avatarKeys"
                      | "picture"
                      | "id"
                      | "salut"
                      | "name"
                      | "alias"
                      | "age"
                      | "ageGuessed"
                      | "firstName"
                      | "lastName"
                      | "temporary"
                      | "birthDate"
                    >
                  >;
                }
            >
          >
        >;
        person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
        topic?: Maybe<{ __typename: "Topic" } & Pick<Topic, "id" | "name">>;
        service?: Maybe<
          { __typename: "Service" } & Pick<Service, "id"> & {
              serviceMeta?: Maybe<
                { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">
              >;
            }
        >;
      }
  >;
};

export type UpdateAppointmentMutationVariables = Exact<{
  appointmentInput: UpdateAppointmentInput;
}>;

export type UpdateAppointmentMutation = { __typename?: "Mutation" } & {
  updateAppointment?: Maybe<
    { __typename: "Appointment" } & Pick<
      Appointment,
      "id" | "title" | "note" | "date" | "from" | "to" | "deletedAt"
    > & {
        users?: Maybe<
          Array<
            Maybe<
              { __typename?: "User" } & Pick<User, "id"> & {
                  person?: Maybe<
                    { __typename: "Person" } & Pick<
                      Person,
                      | "avatarKeys"
                      | "picture"
                      | "id"
                      | "salut"
                      | "name"
                      | "alias"
                      | "age"
                      | "ageGuessed"
                      | "firstName"
                      | "lastName"
                      | "temporary"
                      | "birthDate"
                    >
                  >;
                }
            >
          >
        >;
        person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
        topic?: Maybe<{ __typename: "Topic" } & Pick<Topic, "id" | "name">>;
        service?: Maybe<
          { __typename: "Service" } & Pick<Service, "id"> & {
              serviceMeta?: Maybe<
                { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">
              >;
            }
        >;
      }
  >;
};

export type DeleteAppointmentMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteAppointmentMutation = { __typename?: "Mutation" } & {
  deleteAppointment?: Maybe<
    { __typename: "Appointment" } & Pick<
      Appointment,
      "id" | "title" | "note" | "date" | "from" | "to" | "deletedAt"
    > & {
        users?: Maybe<
          Array<
            Maybe<
              { __typename?: "User" } & Pick<User, "id"> & {
                  person?: Maybe<
                    { __typename: "Person" } & Pick<
                      Person,
                      | "avatarKeys"
                      | "picture"
                      | "id"
                      | "salut"
                      | "name"
                      | "alias"
                      | "age"
                      | "ageGuessed"
                      | "firstName"
                      | "lastName"
                      | "temporary"
                      | "birthDate"
                    >
                  >;
                }
            >
          >
        >;
        person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
        topic?: Maybe<{ __typename: "Topic" } & Pick<Topic, "id" | "name">>;
        service?: Maybe<
          { __typename: "Service" } & Pick<Service, "id"> & {
              serviceMeta?: Maybe<
                { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">
              >;
            }
        >;
      }
  >;
};

export type UpdateBusinessObjectMutationVariables = Exact<{
  id: Scalars["Int"];
  input?: InputMaybe<BusinessObjectUpdateInput>;
}>;

export type UpdateBusinessObjectMutation = { __typename?: "Mutation" } & {
  updateBusinessObject?: Maybe<
    { __typename: "BusinessObject" } & Pick<
      BusinessObject,
      "id" | "name" | "url" | "info" | "accountId"
    > & {
        category?: Maybe<
          { __typename?: "BusinessCategory" } & Pick<BusinessCategory, "id" | "name">
        >;
        address?: Maybe<
          { __typename: "Address" } & Pick<
            Address,
            "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
          >
        >;
        email?: Maybe<
          { __typename: "Email" } & Pick<Email, "id" | "type" | "contactAllowed" | "email">
        >;
        persons?: Maybe<
          Array<
            Maybe<
              { __typename: "Person" } & Pick<
                Person,
                | "id"
                | "salut"
                | "name"
                | "alias"
                | "age"
                | "ageGuessed"
                | "firstName"
                | "lastName"
                | "temporary"
                | "birthDate"
              >
            >
          >
        >;
        phones?: Maybe<
          Array<
            Maybe<
              { __typename: "Phone" } & Pick<
                Phone,
                | "id"
                | "type"
                | "contactAllowed"
                | "messagingAllowed"
                | "countryCode"
                | "areaCode"
                | "number"
                | "readableNumber"
              >
            >
          >
        >;
        hours?: Maybe<
          Array<Maybe<{ __typename?: "Hour" } & Pick<Hour, "id" | "open" | "close" | "day">>>
        >;
      }
  >;
};

export type UpdateOrCreateContactMutationVariables = Exact<{
  id: Scalars["Int"];
  personId?: InputMaybe<Scalars["Int"]>;
  input: PersonInputStrict;
}>;

export type UpdateOrCreateContactMutation = { __typename?: "Mutation" } & {
  updateOrCreateContact?: Maybe<
    { __typename: "BusinessObject" } & Pick<
      BusinessObject,
      "id" | "name" | "url" | "info" | "accountId"
    > & {
        persons?: Maybe<
          Array<
            Maybe<
              { __typename: "Person" } & Pick<
                Person,
                | "id"
                | "salut"
                | "name"
                | "alias"
                | "age"
                | "ageGuessed"
                | "firstName"
                | "lastName"
                | "temporary"
                | "birthDate"
              >
            >
          >
        >;
      }
  >;
};

export type AssignBusinessContactMutationVariables = Exact<{
  businessObjectId: Scalars["Int"];
  personId: Scalars["Int"];
}>;

export type AssignBusinessContactMutation = { __typename?: "Mutation" } & {
  assignBusinessContact?: Maybe<
    { __typename: "BusinessObject" } & Pick<
      BusinessObject,
      "id" | "name" | "url" | "info" | "accountId"
    > & {
        persons?: Maybe<
          Array<
            Maybe<
              { __typename: "Person" } & Pick<
                Person,
                | "id"
                | "salut"
                | "name"
                | "alias"
                | "age"
                | "ageGuessed"
                | "firstName"
                | "lastName"
                | "temporary"
                | "birthDate"
              >
            >
          >
        >;
      }
  >;
};

export type CreateBusinessObjectMutationVariables = Exact<{
  businessObjectInput: BusinessObjectInput;
  contactPersonInput: PersonInput;
}>;

export type CreateBusinessObjectMutation = { __typename?: "Mutation" } & {
  createBusinessObject?: Maybe<
    { __typename: "BusinessObject" } & Pick<
      BusinessObject,
      "id" | "name" | "url" | "info" | "accountId"
    >
  >;
};

export type CreateBusinessUnitMutationVariables = Exact<{
  input?: InputMaybe<CreateBusinessUnitInput>;
}>;

export type CreateBusinessUnitMutation = { __typename?: "Mutation" } & {
  createBusinessUnit: { __typename?: "BusinessUnit" } & Pick<BusinessUnit, "id" | "name">;
};

export type EditBusinessUnitMutationVariables = Exact<{
  input?: InputMaybe<EditBusinessUnitInput>;
}>;

export type EditBusinessUnitMutation = { __typename?: "Mutation" } & {
  editBusinessUnit: { __typename?: "BusinessUnit" } & Pick<BusinessUnit, "id" | "name">;
};

export type MakeClientCallMutationVariables = Exact<{
  personId: Scalars["Int"];
  phoneId: Scalars["Int"];
  primeDN: Scalars["Int"];
}>;

export type MakeClientCallMutation = { __typename?: "Mutation" } & Pick<Mutation, "makeClientCall">;

export type CreateCallMutationVariables = Exact<{
  random: Scalars["Boolean"];
}>;

export type CreateCallMutation = { __typename?: "Mutation" } & {
  createCall?: Maybe<
    { __typename?: "Call" } & Pick<Call, "id" | "readableNumber" | "accountId" | "status">
  >;
};

export type UpdateCallMutationVariables = Exact<{
  call: CallUpdateInput;
}>;

export type UpdateCallMutation = { __typename?: "Mutation" } & {
  updateCall?: Maybe<
    { __typename?: "Call" } & Pick<
      Call,
      "id" | "readableNumber" | "accountId" | "status" | "personActivityId"
    >
  >;
};

export type TriggerResearchCallMutationVariables = Exact<{
  serviceResearchObjectId: Scalars["Float"];
  primeDN: Scalars["Int"];
  phoneId: Scalars["Int"];
}>;

export type TriggerResearchCallMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "triggerResearchCall"
>;

export type CancelCallMutationVariables = Exact<{
  callId: Scalars["Int"];
}>;

export type CancelCallMutation = { __typename?: "Mutation" } & {
  cancelCall?: Maybe<{ __typename?: "Call" } & Pick<Call, "id" | "status">>;
};

export type CreateCaseManagementReviewMutationVariables = Exact<{
  clientId: Scalars["Int"];
}>;

export type CreateCaseManagementReviewMutation = { __typename?: "Mutation" } & {
  createCaseManagementReview?: Maybe<
    { __typename?: "CaseManagementReview" } & Pick<CaseManagementReview, "id"> & {
        caseManagementReviewAttributes?: Maybe<
          Array<
            Maybe<
              { __typename?: "CaseManagementReviewAttribute" } & Pick<
                CaseManagementReviewAttribute,
                "id" | "attributeValue"
              > & {
                  clientReviewAttribute?: Maybe<
                    { __typename?: "ClientReviewAttribute" } & Pick<
                      ClientReviewAttribute,
                      "id" | "name" | "label"
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type UpdateCaseManagementReviewAttributeMutationVariables = Exact<{
  caseManagementReviewAttributeInput: CaseManagementReviewAttributeInput;
}>;

export type UpdateCaseManagementReviewAttributeMutation = { __typename?: "Mutation" } & {
  updateCaseManagementReviewAttribute?: Maybe<
    { __typename?: "CaseManagementReviewAttribute" } & Pick<
      CaseManagementReviewAttribute,
      "id" | "attributeValue" | "lastUpdate"
    >
  >;
};

export type CreateCaseManagerMutationVariables = Exact<{
  clientId: Scalars["Int"];
  userId: Scalars["Int"];
}>;

export type CreateCaseManagerMutation = { __typename?: "Mutation" } & {
  createCaseManager?: Maybe<
    { __typename?: "CaseManager" } & Pick<CaseManager, "id"> & {
        user?: Maybe<
          { __typename?: "User" } & Pick<User, "id"> & {
              papershiftWorkingAreas?: Maybe<
                Array<
                  Maybe<
                    { __typename: "PapershiftWorkingArea" } & Pick<
                      PapershiftWorkingArea,
                      "id" | "name" | "color"
                    >
                  >
                >
              >;
              person?: Maybe<
                { __typename?: "Person" } & Pick<
                  Person,
                  "id" | "name" | "alias" | "avatar" | "avatarKeys" | "picture"
                >
              >;
            }
        >;
      }
  >;
};

export type UpdateCaseManagerMutationVariables = Exact<{
  clientId: Scalars["Int"];
  userId?: InputMaybe<Scalars["Int"]>;
}>;

export type UpdateCaseManagerMutation = { __typename?: "Mutation" } & {
  updateCaseManager?: Maybe<
    { __typename?: "CaseManager" } & Pick<CaseManager, "id"> & {
        user?: Maybe<
          { __typename?: "User" } & Pick<User, "id"> & {
              papershiftWorkingAreas?: Maybe<
                Array<
                  Maybe<
                    { __typename: "PapershiftWorkingArea" } & Pick<
                      PapershiftWorkingArea,
                      "id" | "name" | "color"
                    >
                  >
                >
              >;
              person?: Maybe<
                { __typename?: "Person" } & Pick<
                  Person,
                  "id" | "alias" | "name" | "avatar" | "avatarKeys" | "picture"
                >
              >;
            }
        >;
      }
  >;
};

export type UpdateCaseManagerByUserIdMutationVariables = Exact<{
  serviceId: Scalars["Int"];
  newCaseManagerId?: InputMaybe<Scalars["Int"]>;
  oldCaseManagerId?: InputMaybe<Scalars["Int"]>;
}>;

export type UpdateCaseManagerByUserIdMutation = { __typename?: "Mutation" } & {
  updateCaseManagerByUserId?: Maybe<
    { __typename?: "CaseManager" } & Pick<CaseManager, "id"> & {
        user?: Maybe<
          { __typename?: "User" } & Pick<User, "id"> & {
              papershiftWorkingAreas?: Maybe<
                Array<
                  Maybe<
                    { __typename: "PapershiftWorkingArea" } & Pick<
                      PapershiftWorkingArea,
                      "id" | "name" | "color"
                    >
                  >
                >
              >;
              person?: Maybe<
                { __typename?: "Person" } & Pick<
                  Person,
                  "id" | "alias" | "name" | "avatar" | "avatarKeys" | "picture"
                >
              >;
            }
        >;
      }
  >;
};

export type CreateChatMutationVariables = Exact<{
  chatInput: ChatInsertInput;
}>;

export type CreateChatMutation = { __typename?: "Mutation" } & {
  createChat?: Maybe<
    { __typename?: "Chat" } & Pick<Chat, "id" | "chatUserId"> & {
        personActivity?: Maybe<
          { __typename?: "PersonActivity" } & Pick<PersonActivity, "id"> & {
              person: { __typename?: "Person" } & Pick<Person, "id">;
            }
        >;
      }
  >;
};

export type ReassignChatMutationVariables = Exact<{
  chatId: Scalars["Int"];
  userId?: InputMaybe<Scalars["Int"]>;
  team?: InputMaybe<Team>;
}>;

export type ReassignChatMutation = { __typename?: "Mutation" } & {
  reassignChat?: Maybe<
    { __typename?: "Chat" } & Pick<Chat, "id" | "team"> & {
        personActivity?: Maybe<
          { __typename?: "PersonActivity" } & Pick<PersonActivity, "id"> & {
              user?: Maybe<{ __typename?: "User" } & Pick<User, "id">>;
            }
        >;
      }
  >;
};

export type FinishChatMutationVariables = Exact<{
  id: Scalars["Int"];
  uid: Scalars["Int"];
  chatUserId: Scalars["Int"];
  token: Scalars["String"];
}>;

export type FinishChatMutation = { __typename?: "Mutation" } & {
  finishChat?: Maybe<
    { __typename?: "Chat" } & Pick<Chat, "id" | "chatUserId"> & {
        personActivity?: Maybe<
          { __typename?: "PersonActivity" } & Pick<PersonActivity, "id"> & {
              person: { __typename?: "Person" } & Pick<Person, "id">;
            }
        >;
      }
  >;
};

export type AddClientMutationVariables = Exact<{
  client: ClientInsertInput;
}>;

export type AddClientMutation = { __typename?: "Mutation" } & {
  addClient?: Maybe<
    { __typename?: "Client" } & Pick<Client, "id" | "clientType"> & {
        account?: Maybe<{ __typename?: "Account" } & Pick<Account, "id" | "name" | "type">>;
        person?: Maybe<
          { __typename?: "Person" } & Pick<
            Person,
            "id" | "alias" | "firstName" | "lastName" | "gender"
          > & {
              phones?: Maybe<
                Array<Maybe<{ __typename?: "Phone" } & Pick<Phone, "areaCode" | "number">>>
              >;
            }
        >;
      }
  >;
};

export type UpdateClientMutationVariables = Exact<{
  clientInput: ClientUpdateInput;
}>;

export type UpdateClientMutation = { __typename?: "Mutation" } & {
  updateClient?: Maybe<
    { __typename?: "Client" } & Pick<
      Client,
      | "id"
      | "departmentId"
      | "clientType"
      | "assistanceId"
      | "job"
      | "vip"
      | "jobBand"
      | "language"
      | "contactLanguageId"
      | "healthInsuranceCompany"
      | "accountId"
      | "sourceOfInformation"
      | "hrBusiness"
      | "source"
      | "yearOfEntry"
    > & {
        contactLanguage?: Maybe<
          { __typename?: "Language" } & Pick<Language, "id" | "code" | "name">
        >;
        businessUnit?: Maybe<{ __typename?: "BusinessUnit" } & Pick<BusinessUnit, "id" | "name">>;
      }
  >;
};

export type CreateContactPersonMutationVariables = Exact<{
  contactPersonInput: ContactPersonInput;
}>;

export type CreateContactPersonMutation = { __typename?: "Mutation" } & {
  createContactPerson?: Maybe<
    { __typename: "Person" } & Pick<
      Person,
      | "id"
      | "abnormalities"
      | "age"
      | "ageGuessed"
      | "alias"
      | "avatar"
      | "avatarKeys"
      | "picture"
      | "birthDate"
      | "familyStatus"
      | "firstName"
      | "gender"
      | "lastName"
      | "mentalState"
      | "name"
      | "note"
      | "redFlag"
      | "salut"
      | "title"
      | "vip"
      | "yellowFlag"
      | "temporary"
    > & {
        extras?: Maybe<
          { __typename?: "PersonExtraFieldType" } & Pick<
            PersonExtraFieldType,
            "informationSource" | "hrBusiness"
          >
        >;
      }
  >;
};

export type UpdateContactPersonMutationVariables = Exact<{
  contactPersonUpdateInput: ContactPersonUpdateInput;
}>;

export type UpdateContactPersonMutation = { __typename?: "Mutation" } & {
  updateContactPerson?: Maybe<
    { __typename: "Person" } & Pick<
      Person,
      | "id"
      | "abnormalities"
      | "age"
      | "ageGuessed"
      | "alias"
      | "avatar"
      | "avatarKeys"
      | "picture"
      | "birthDate"
      | "familyStatus"
      | "firstName"
      | "gender"
      | "lastName"
      | "mentalState"
      | "name"
      | "note"
      | "redFlag"
      | "salut"
      | "title"
      | "vip"
      | "yellowFlag"
      | "temporary"
    > & {
        extras?: Maybe<
          { __typename?: "PersonExtraFieldType" } & Pick<
            PersonExtraFieldType,
            "informationSource" | "hrBusiness"
          >
        >;
      }
  >;
};

export type CreateConversationReportMutationVariables = Exact<{
  report: ConversationReportInsertInput;
}>;

export type CreateConversationReportMutation = { __typename?: "Mutation" } & {
  createConversationReport?: Maybe<
    { __typename: "ConversationReport" } & Pick<
      ConversationReport,
      | "id"
      | "agreement"
      | "issue"
      | "mentalDescription"
      | "mentalState"
      | "reportType"
      | "status"
      | "summary"
      | "risk"
      | "riskInfo"
      | "cmCheckMessage"
      | "doneAt"
      | "lifeworksService"
      | "lifeworksServiceIssue"
      | "lifeworksServiceSubIssue"
    > & {
        topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
        services?: Maybe<
          Array<
            Maybe<
              { __typename?: "Service" } & Pick<Service, "id"> & {
                  serviceMeta?: Maybe<
                    { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">
                  >;
                }
            >
          >
        >;
        connectedServices?: Maybe<Array<Maybe<{ __typename?: "Service" } & Pick<Service, "id">>>>;
        client?: Maybe<
          { __typename?: "Client" } & Pick<Client, "id"> & {
              person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
            }
        >;
        activeClientReviewTemplate?: Maybe<
          { __typename?: "ClientReviewTemplate" } & Pick<
            ClientReviewTemplate,
            "id" | "isDefault" | "name" | "sortIdx"
          >
        >;
        activeClientReviewAttributeGroup?: Maybe<
          { __typename?: "ClientReviewAttributeGroup" } & Pick<
            ClientReviewAttributeGroup,
            "id" | "headingNew" | "headingUpdate" | "pageOrder"
          >
        >;
        conversationReportReviewAttributes?: Maybe<
          Array<
            Maybe<
              { __typename?: "ConversationReportReviewAttribute" } & Pick<
                ConversationReportReviewAttribute,
                "id" | "attributeValue"
              > & {
                  clientReviewAttribute?: Maybe<
                    { __typename?: "ClientReviewAttribute" } & Pick<
                      ClientReviewAttribute,
                      "id" | "label" | "name" | "placeholder" | "type"
                    > & {
                        clientReviewAttributeGroup?: Maybe<
                          { __typename?: "ClientReviewAttributeGroup" } & Pick<
                            ClientReviewAttributeGroup,
                            "id" | "headingNew" | "headingUpdate" | "pageOrder"
                          > & {
                              clientReviewTemplate?: Maybe<
                                { __typename?: "ClientReviewTemplate" } & Pick<
                                  ClientReviewTemplate,
                                  "id" | "isDefault" | "name" | "sortIdx"
                                >
                              >;
                            }
                        >;
                      }
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type UpdateConversationReportMutationVariables = Exact<{
  report: ConversationReportUpdateInput;
}>;

export type UpdateConversationReportMutation = { __typename?: "Mutation" } & {
  updateConversationReport?: Maybe<
    { __typename: "ConversationReport" } & Pick<
      ConversationReport,
      | "id"
      | "agreement"
      | "issue"
      | "mentalDescription"
      | "mentalState"
      | "reportType"
      | "status"
      | "summary"
      | "risk"
      | "riskInfo"
      | "cmCheckMessage"
      | "doneAt"
      | "lifeworksService"
      | "lifeworksServiceIssue"
      | "lifeworksServiceSubIssue"
    > & {
        topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
        services?: Maybe<
          Array<
            Maybe<
              { __typename?: "Service" } & Pick<Service, "id"> & {
                  serviceMeta?: Maybe<
                    { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">
                  >;
                }
            >
          >
        >;
        connectedServices?: Maybe<Array<Maybe<{ __typename?: "Service" } & Pick<Service, "id">>>>;
        client?: Maybe<
          { __typename?: "Client" } & Pick<Client, "id"> & {
              person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
            }
        >;
        activeClientReviewTemplate?: Maybe<
          { __typename?: "ClientReviewTemplate" } & Pick<
            ClientReviewTemplate,
            "id" | "isDefault" | "name" | "sortIdx"
          >
        >;
        activeClientReviewAttributeGroup?: Maybe<
          { __typename?: "ClientReviewAttributeGroup" } & Pick<
            ClientReviewAttributeGroup,
            "id" | "headingNew" | "headingUpdate" | "pageOrder"
          >
        >;
        conversationReportReviewAttributes?: Maybe<
          Array<
            Maybe<
              { __typename?: "ConversationReportReviewAttribute" } & Pick<
                ConversationReportReviewAttribute,
                "id" | "attributeValue"
              > & {
                  clientReviewAttribute?: Maybe<
                    { __typename?: "ClientReviewAttribute" } & Pick<
                      ClientReviewAttribute,
                      "id" | "label" | "name" | "placeholder" | "type"
                    > & {
                        clientReviewAttributeGroup?: Maybe<
                          { __typename?: "ClientReviewAttributeGroup" } & Pick<
                            ClientReviewAttributeGroup,
                            "id" | "headingNew" | "headingUpdate" | "pageOrder"
                          > & {
                              clientReviewTemplate?: Maybe<
                                { __typename?: "ClientReviewTemplate" } & Pick<
                                  ClientReviewTemplate,
                                  "id" | "isDefault" | "name" | "sortIdx"
                                >
                              >;
                            }
                        >;
                      }
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type DemoCreateMandateMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DemoCreateMandateMutation = { __typename?: "Mutation" } & {
  demoCreateMandate?: Maybe<{ __typename?: "CounselingMandate" } & Pick<CounselingMandate, "id">>;
};

export type CreateMandateMutationVariables = Exact<{
  mandate: CounselingMandateInput;
}>;

export type CreateMandateMutation = { __typename?: "Mutation" } & {
  createMandate?: Maybe<{ __typename?: "CounselingMandate" } & Pick<CounselingMandate, "id">>;
};

export type UpdateMandateMutationVariables = Exact<{
  id: Scalars["Int"];
  input: UpdateCounselingMandateInput;
}>;

export type UpdateMandateMutation = { __typename?: "Mutation" } & {
  updateMandate?: Maybe<
    { __typename?: "CounselingMandate" } & Pick<
      CounselingMandate,
      "id" | "sessionsAppointed" | "sessionsCompleted"
    >
  >;
};

export type CreateCounselingUserMutationVariables = Exact<{
  input: CounselingUserInput;
}>;

export type CreateCounselingUserMutation = { __typename?: "Mutation" } & {
  createCounselingUser?: Maybe<{ __typename?: "CounselingUser" } & Pick<CounselingUser, "id">>;
};

export type CreateDepartmentMutationVariables = Exact<{
  accountId: Scalars["Int"];
  input: DepartmentInput;
}>;

export type CreateDepartmentMutation = { __typename?: "Mutation" } & {
  createDepartment?: Maybe<
    { __typename?: "Department" } & Pick<Department, "id" | "name" | "main" | "accountId">
  >;
};

export type UpdateDepartmentMutationVariables = Exact<{
  id: Scalars["Int"];
  input: DepartmentInput;
}>;

export type UpdateDepartmentMutation = { __typename?: "Mutation" } & {
  updateDepartment?: Maybe<
    { __typename?: "Department" } & Pick<Department, "id" | "name" | "main" | "accountId">
  >;
};

export type CreateDriveDocumentMutationVariables = Exact<{
  createDocumentInput: CreateDocumentInput;
}>;

export type CreateDriveDocumentMutation = { __typename?: "Mutation" } & {
  createDriveDocument?: Maybe<
    { __typename?: "DriveDocument" } & Pick<DriveDocument, "id" | "title" | "link" | "created"> & {
        user?: Maybe<{ __typename?: "User" } & Pick<User, "id" | "name">>;
      }
  >;
};

export type CreateDriveComplaintDocumentMutationVariables = Exact<{
  createDocumentInput: CreateDocumentInput;
}>;

export type CreateDriveComplaintDocumentMutation = { __typename?: "Mutation" } & {
  createDriveComplaintDocument?: Maybe<
    { __typename?: "DriveDocument" } & Pick<DriveDocument, "id" | "title" | "link" | "created"> & {
        user?: Maybe<{ __typename?: "User" } & Pick<User, "id" | "name">>;
      }
  >;
};

export type EmailFragment = { __typename: "Email" } & Pick<
  Email,
  "id" | "type" | "contactAllowed" | "email"
>;

export type CreateEmailMutationVariables = Exact<{
  personId: Scalars["ID"];
  emailsInput: Array<EmailInput> | EmailInput;
}>;

export type CreateEmailMutation = { __typename?: "Mutation" } & {
  createEmail?: Maybe<
    Array<Maybe<{ __typename: "Email" } & Pick<Email, "id" | "type" | "contactAllowed" | "email">>>
  >;
};

export type DeleteEmailMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteEmailMutation = { __typename?: "Mutation" } & Pick<Mutation, "deleteEmail">;

export type UpdateEmailsMutationVariables = Exact<{
  personId: Scalars["ID"];
  emailsInput: Array<EmailInput> | EmailInput;
}>;

export type UpdateEmailsMutation = { __typename?: "Mutation" } & {
  updateEmails?: Maybe<
    Array<Maybe<{ __typename: "Email" } & Pick<Email, "id" | "type" | "contactAllowed" | "email">>>
  >;
};

export type AssignGMailMessageMutationVariables = Exact<{
  threadId: Scalars["String"];
  userId?: InputMaybe<Scalars["Int"]>;
  team?: InputMaybe<Team>;
}>;

export type AssignGMailMessageMutation = { __typename?: "Mutation" } & {
  assignGMailMessage?: Maybe<
    { __typename?: "Task" } & Pick<Task, "id" | "team"> & {
        user?: Maybe<{ __typename?: "User" } & Pick<User, "id" | "name">>;
      }
  >;
};

export type CancelGMailMessageMutationVariables = Exact<{
  threadId: Scalars["String"];
}>;

export type CancelGMailMessageMutation = { __typename?: "Mutation" } & {
  cancelGMailMessage?: Maybe<{ __typename?: "GMailMessage" } & Pick<GMailMessage, "threadId">>;
};

export type CreateGMailMutationVariables = Exact<{
  message: Scalars["String"];
  threadId?: InputMaybe<Scalars["String"]>;
  classification: Scalars["String"];
  subject: Scalars["String"];
  sender: Scalars["String"];
  personId?: InputMaybe<Scalars["Int"]>;
  businessObjectId?: InputMaybe<Scalars["Int"]>;
  topicId?: InputMaybe<Scalars["Int"]>;
}>;

export type CreateGMailMutation = { __typename?: "Mutation" } & {
  createGMail?: Maybe<
    { __typename?: "GMailMessage" } & Pick<
      GMailMessage,
      "id" | "incoming" | "historyId" | "messageId" | "threadId" | "body" | "date"
    > & {
        attachments: Array<
          { __typename?: "Attachment" } & Pick<Attachment, "attachmentId" | "filename" | "mimeType">
        >;
        payload?: Maybe<
          { __typename?: "Payload" } & Pick<Payload, "from" | "to" | "subject" | "returnPath">
        >;
        gMailInbox?: Maybe<{ __typename?: "GMailInbox" } & Pick<GMailInbox, "id" | "email">>;
        person?: Maybe<
          { __typename?: "Person" } & Pick<
            Person,
            "id" | "name" | "avatar" | "avatarKeys" | "mentalState" | "temporary"
          > & { emails?: Maybe<Array<Maybe<{ __typename?: "Email" } & Pick<Email, "email">>>> }
        >;
        user?: Maybe<
          { __typename?: "User" } & Pick<User, "id"> & {
              person?: Maybe<
                { __typename?: "Person" } & Pick<
                  Person,
                  "id" | "name" | "avatar" | "avatarKeys" | "picture" | "mentalState" | "temporary"
                > & {
                    emails?: Maybe<Array<Maybe<{ __typename?: "Email" } & Pick<Email, "email">>>>;
                  }
              >;
            }
        >;
      }
  >;
};

export type FinishThreadMutationVariables = Exact<{
  threadId: Scalars["String"];
}>;

export type FinishThreadMutation = { __typename?: "Mutation" } & Pick<Mutation, "finishThread">;

export type CreateBusinessHoursMutationVariables = Exact<{
  hour: BusinessHoursBaseInput;
}>;

export type CreateBusinessHoursMutation = { __typename?: "Mutation" } & {
  createBusinessHours?: Maybe<
    { __typename?: "Hour" } & Pick<Hour, "id" | "open" | "close" | "day" | "businessId">
  >;
};

export type CreateHourMutationVariables = Exact<{
  hour: HourBaseInput;
}>;

export type CreateHourMutation = { __typename?: "Mutation" } & {
  createHour?: Maybe<
    { __typename?: "Hour" } & Pick<Hour, "id" | "open" | "close" | "day" | "serviceId">
  >;
};

export type UpdateHourMutationVariables = Exact<{
  hour: HourUpdate;
}>;

export type UpdateHourMutation = { __typename?: "Mutation" } & {
  updateHour?: Maybe<
    { __typename?: "Hour" } & Pick<Hour, "id" | "open" | "close" | "day" | "serviceId">
  >;
};

export type DeleteHourMutationVariables = Exact<{
  id: Scalars["Float"];
}>;

export type DeleteHourMutation = { __typename?: "Mutation" } & {
  deleteHour?: Maybe<{ __typename?: "Hour" } & Pick<Hour, "id">>;
};

export type CreateClientMemoMutationVariables = Exact<{
  memo: MemoInsertClientInput;
  clientId: Scalars["Int"];
}>;

export type CreateClientMemoMutation = { __typename?: "Mutation" } & {
  createClientMemo?: Maybe<
    { __typename: "Memo" } & Pick<
      Memo,
      "id" | "note" | "contact" | "status" | "type" | "doneAt"
    > & {
        topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
        person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
        business?: Maybe<
          { __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name" | "accountId">
        >;
      }
  >;
};

export type UpdateClientMemoMutationVariables = Exact<{
  memo: MemoUpdateInput;
  clientId: Scalars["Int"];
}>;

export type UpdateClientMemoMutation = { __typename?: "Mutation" } & {
  updateClientMemo?: Maybe<
    { __typename: "Memo" } & Pick<
      Memo,
      "id" | "note" | "contact" | "status" | "type" | "doneAt"
    > & {
        topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
        person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
        business?: Maybe<
          { __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name" | "accountId">
        >;
      }
  >;
};

export type CreateBusinessMemoMutationVariables = Exact<{
  memo: MemoInsertInput;
  businessId: Scalars["Int"];
}>;

export type CreateBusinessMemoMutation = { __typename?: "Mutation" } & {
  createBusinessMemo?: Maybe<
    { __typename: "Memo" } & Pick<
      Memo,
      "id" | "note" | "contact" | "status" | "type" | "doneAt"
    > & {
        topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
        person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
        business?: Maybe<
          { __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name" | "accountId">
        >;
      }
  >;
};

export type CreatePersonMemoMutationVariables = Exact<{
  memo: MemoInsertInput;
  personId: Scalars["Int"];
}>;

export type CreatePersonMemoMutation = { __typename?: "Mutation" } & {
  createPersonMemo?: Maybe<
    { __typename: "Memo" } & Pick<
      Memo,
      "id" | "note" | "contact" | "status" | "type" | "doneAt"
    > & {
        topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
        person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
        business?: Maybe<
          { __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name" | "accountId">
        >;
      }
  >;
};

export type UpdateMemoMutationVariables = Exact<{
  memo: MemoUpdateInput;
}>;

export type UpdateMemoMutation = { __typename?: "Mutation" } & {
  updateMemo?: Maybe<
    { __typename: "Memo" } & Pick<
      Memo,
      "id" | "note" | "contact" | "status" | "type" | "doneAt"
    > & {
        topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
        person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
        business?: Maybe<
          { __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name" | "accountId">
        >;
      }
  >;
};

export type CreateGdprJobMutationVariables = Exact<{
  personId: Scalars["Int"];
}>;

export type CreateGdprJobMutation = { __typename?: "Mutation" } & {
  createGDPRJob?: Maybe<
    { __typename?: "ObservableJob" } & Pick<ObservableJob, "id" | "status" | "link" | "error">
  >;
};

export type UpdatePersonAgeAndGenderMutationVariables = Exact<{
  id: Scalars["Int"];
  initialAge: Scalars["Int"];
  gender: Gender;
}>;

export type UpdatePersonAgeAndGenderMutation = { __typename?: "Mutation" } & {
  updatePersonAgeAndGender?: Maybe<
    { __typename: "Person" } & Pick<
      Person,
      | "id"
      | "abnormalities"
      | "age"
      | "ageGuessed"
      | "alias"
      | "avatar"
      | "avatarKeys"
      | "picture"
      | "birthDate"
      | "familyStatus"
      | "firstName"
      | "gender"
      | "lastName"
      | "mentalState"
      | "name"
      | "note"
      | "redFlag"
      | "salut"
      | "title"
      | "vip"
      | "yellowFlag"
      | "temporary"
    > & {
        extras?: Maybe<
          { __typename?: "PersonExtraFieldType" } & Pick<
            PersonExtraFieldType,
            "informationSource" | "hrBusiness"
          >
        >;
      }
  >;
};

export type UpdatePersonMutationVariables = Exact<{
  person: PersonUpdateInput;
  accountId?: InputMaybe<Scalars["Int"]>;
  assistanceId?: InputMaybe<Scalars["String"]>;
}>;

export type UpdatePersonMutation = { __typename?: "Mutation" } & {
  updatePerson?: Maybe<
    { __typename: "Person" } & Pick<
      Person,
      | "id"
      | "abnormalities"
      | "age"
      | "ageGuessed"
      | "alias"
      | "avatar"
      | "avatarKeys"
      | "picture"
      | "birthDate"
      | "familyStatus"
      | "firstName"
      | "gender"
      | "lastName"
      | "mentalState"
      | "name"
      | "note"
      | "redFlag"
      | "salut"
      | "title"
      | "vip"
      | "yellowFlag"
      | "temporary"
    > & {
        extras?: Maybe<
          { __typename?: "PersonExtraFieldType" } & Pick<
            PersonExtraFieldType,
            "informationSource" | "hrBusiness"
          >
        >;
      }
  >;
};

export type AddPersonMutationVariables = Exact<{
  person: PersonInput;
}>;

export type AddPersonMutation = { __typename?: "Mutation" } & {
  addPerson?: Maybe<
    { __typename: "Person" } & Pick<
      Person,
      | "id"
      | "abnormalities"
      | "age"
      | "ageGuessed"
      | "alias"
      | "avatar"
      | "avatarKeys"
      | "picture"
      | "birthDate"
      | "familyStatus"
      | "firstName"
      | "gender"
      | "lastName"
      | "mentalState"
      | "name"
      | "note"
      | "redFlag"
      | "salut"
      | "title"
      | "vip"
      | "yellowFlag"
      | "temporary"
    > & {
        extras?: Maybe<
          { __typename?: "PersonExtraFieldType" } & Pick<
            PersonExtraFieldType,
            "informationSource" | "hrBusiness"
          >
        >;
      }
  >;
};

export type SetAnonymousPersonMutationVariables = Exact<{
  personId: Scalars["Int"];
  personActivityId: Scalars["Int"];
}>;

export type SetAnonymousPersonMutation = { __typename?: "Mutation" } & {
  setAnonymousPerson?: Maybe<
    { __typename: "Person" } & Pick<
      Person,
      | "id"
      | "abnormalities"
      | "age"
      | "ageGuessed"
      | "alias"
      | "avatar"
      | "avatarKeys"
      | "picture"
      | "birthDate"
      | "familyStatus"
      | "firstName"
      | "gender"
      | "lastName"
      | "mentalState"
      | "name"
      | "note"
      | "redFlag"
      | "salut"
      | "title"
      | "vip"
      | "yellowFlag"
      | "temporary"
    > & {
        extras?: Maybe<
          { __typename?: "PersonExtraFieldType" } & Pick<
            PersonExtraFieldType,
            "informationSource" | "hrBusiness"
          >
        >;
      }
  >;
};

export type UpdatePersonExtrasMutationVariables = Exact<{
  personId: Scalars["Int"];
  personExtraInput: PersonExtraInput;
}>;

export type UpdatePersonExtrasMutation = { __typename?: "Mutation" } & {
  updatePersonExtras?: Maybe<
    { __typename: "Person" } & Pick<
      Person,
      | "id"
      | "abnormalities"
      | "age"
      | "ageGuessed"
      | "alias"
      | "avatar"
      | "avatarKeys"
      | "picture"
      | "birthDate"
      | "familyStatus"
      | "firstName"
      | "gender"
      | "lastName"
      | "mentalState"
      | "name"
      | "note"
      | "redFlag"
      | "salut"
      | "title"
      | "vip"
      | "yellowFlag"
      | "temporary"
    > & {
        extras?: Maybe<
          { __typename?: "PersonExtraFieldType" } & Pick<
            PersonExtraFieldType,
            "informationSource" | "hrBusiness"
          >
        >;
      }
  >;
};

export type AssignPersonMutationVariables = Exact<{
  id: Scalars["Int"];
  personId: Scalars["Int"];
}>;

export type AssignPersonMutation = { __typename?: "Mutation" } & {
  assignPerson?: Maybe<
    { __typename?: "PersonActivity" } & Pick<PersonActivity, "id"> & {
        person: { __typename?: "Person" } & Pick<Person, "id" | "needsAccount">;
      }
  >;
};

export type DeleteActivityMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteActivityMutation = { __typename?: "Mutation" } & {
  deleteActivity?: Maybe<{ __typename?: "PersonActivity" } & Pick<PersonActivity, "id">>;
};

export type SmsNotificationMutationVariables = Exact<{
  input: SmsInput;
}>;

export type SmsNotificationMutation = { __typename?: "Mutation" } & {
  smsNotification?: Maybe<
    { __typename?: "PersonActivity" } & Pick<PersonActivity, "id" | "description">
  >;
};

export type PhoneFragment = { __typename: "Phone" } & Pick<
  Phone,
  | "id"
  | "type"
  | "contactAllowed"
  | "messagingAllowed"
  | "countryCode"
  | "areaCode"
  | "number"
  | "readableNumber"
>;

export type CreatePhoneMutationVariables = Exact<{
  personId: Scalars["ID"];
  phonesInput: Array<PhoneInput> | PhoneInput;
}>;

export type CreatePhoneMutation = { __typename?: "Mutation" } & {
  createPhone?: Maybe<
    { __typename: "Phone" } & Pick<
      Phone,
      | "id"
      | "type"
      | "contactAllowed"
      | "messagingAllowed"
      | "countryCode"
      | "areaCode"
      | "number"
      | "readableNumber"
    >
  >;
};

export type DeletePhoneMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeletePhoneMutation = { __typename?: "Mutation" } & Pick<Mutation, "deletePhone">;

export type UpdatePhonesMutationVariables = Exact<{
  personId: Scalars["ID"];
  phonesInput: Array<PhoneInput> | PhoneInput;
}>;

export type UpdatePhonesMutation = { __typename?: "Mutation" } & {
  updatePhones?: Maybe<
    Array<
      Maybe<
        { __typename: "Phone" } & Pick<
          Phone,
          | "id"
          | "type"
          | "contactAllowed"
          | "messagingAllowed"
          | "countryCode"
          | "areaCode"
          | "number"
          | "readableNumber"
        >
      >
    >
  >;
};

export type CreateCounselingServiceMutationVariables = Exact<{
  service: CounselingServiceInput;
}>;

export type CreateCounselingServiceMutation = { __typename?: "Mutation" } & {
  createCounselingService?: Maybe<
    { __typename?: "Service" } & Pick<Service, "id" | "status" | "wizardStatus" | "wizardStep"> & {
        client?: Maybe<
          { __typename?: "Client" } & Pick<Client, "id"> & {
              person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
            }
        >;
        serviceMeta?: Maybe<
          { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name" | "type">
        >;
        topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
      }
  >;
};

export type CreateServiceMutationVariables = Exact<{
  service: ServiceInput;
}>;

export type CreateServiceMutation = { __typename?: "Mutation" } & {
  createService?: Maybe<
    { __typename?: "Service" } & Pick<Service, "id" | "status" | "wizardStatus" | "wizardStep"> & {
        client?: Maybe<
          { __typename?: "Client" } & Pick<Client, "id"> & {
              person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
            }
        >;
        serviceMeta?: Maybe<
          { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name" | "type">
        >;
        topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
      }
  >;
};

export type UpdateServiceMutationVariables = Exact<{
  id: Scalars["Float"];
  service: ServiceUpdateInput;
}>;

export type UpdateServiceMutation = { __typename?: "Mutation" } & {
  updateService?: Maybe<
    { __typename?: "Service" } & Pick<
      Service,
      "id" | "wizardStatus" | "wizardStep" | "status" | "extendedInfo"
    >
  >;
};

export type DeleteServiceMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteServiceMutation = { __typename?: "Mutation" } & Pick<Mutation, "deleteService">;

export type CreateServiceAddressMutationVariables = Exact<{
  serviceAddress: ServiceAddressCreateInput;
}>;

export type CreateServiceAddressMutation = { __typename?: "Mutation" } & {
  createServiceAddress?: Maybe<
    { __typename?: "ServiceAddress" } & Pick<
      ServiceAddress,
      "id" | "serviceMetaAttributeId" | "radius"
    > & {
        address?: Maybe<{ __typename?: "Address" } & Pick<Address, "id">>;
        service?: Maybe<{ __typename?: "Service" } & Pick<Service, "id">>;
      }
  >;
};

export type UpdateServiceAddressMutationVariables = Exact<{
  serviceAddress: ServiceAddressUpdateInput;
}>;

export type UpdateServiceAddressMutation = { __typename?: "Mutation" } & {
  updateServiceAddress?: Maybe<
    { __typename?: "ServiceAddress" } & Pick<
      ServiceAddress,
      "id" | "serviceMetaAttributeId" | "radius"
    > & {
        address?: Maybe<{ __typename?: "Address" } & Pick<Address, "id">>;
        service?: Maybe<{ __typename?: "Service" } & Pick<Service, "id">>;
      }
  >;
};

export type DeleteServiceAddressMutationVariables = Exact<{
  id: Scalars["Float"];
}>;

export type DeleteServiceAddressMutation = { __typename?: "Mutation" } & {
  deleteServiceAddress?: Maybe<{ __typename?: "ServiceAddress" } & Pick<ServiceAddress, "id">>;
};

export type CreateServiceAttributeMutationVariables = Exact<{
  serviceAttribute: ServiceAttributeInput;
}>;

export type CreateServiceAttributeMutation = { __typename?: "Mutation" } & {
  createServiceAttribute?: Maybe<
    { __typename?: "ServiceAttribute" } & Pick<
      ServiceAttribute,
      "id" | "serviceMetaAttributeId" | "serviceId" | "value"
    >
  >;
};

export type UpdateServiceAttributeMutationVariables = Exact<{
  serviceAttribute: ServiceAttributeUpdate;
}>;

export type UpdateServiceAttributeMutation = { __typename?: "Mutation" } & {
  updateServiceAttribute?: Maybe<
    { __typename?: "ServiceAttribute" } & Pick<ServiceAttribute, "id" | "serviceId" | "value">
  >;
};

export type DeleteServiceAttributeMutationVariables = Exact<{
  id: Scalars["Float"];
}>;

export type DeleteServiceAttributeMutation = { __typename?: "Mutation" } & {
  deleteServiceAttribute?: Maybe<
    { __typename?: "ServiceAttribute" } & Pick<ServiceAttribute, "id">
  >;
};

export type CreateServiceClientMutationVariables = Exact<{
  serviceClient: ServiceClientInput;
}>;

export type CreateServiceClientMutation = { __typename?: "Mutation" } & {
  createServiceClient?: Maybe<
    { __typename?: "ServiceClient" } & Pick<ServiceClient, "id" | "serviceMetaAttributeId"> & {
        client?: Maybe<
          { __typename?: "Client" } & Pick<Client, "id"> & {
              person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
            }
        >;
        service?: Maybe<{ __typename?: "Service" } & Pick<Service, "id">>;
        serviceMetaAttribute?: Maybe<
          { __typename?: "ServiceMetaAttribute" } & Pick<ServiceMetaAttribute, "id">
        >;
      }
  >;
};

export type DeleteServiceClientMutationVariables = Exact<{
  id: Scalars["Float"];
}>;

export type DeleteServiceClientMutation = { __typename?: "Mutation" } & {
  deleteServiceClient?: Maybe<{ __typename?: "ServiceClient" } & Pick<ServiceClient, "id">>;
};

export type CreateServiceResearchObjectMutationVariables = Exact<{
  serviceResearchObjectInput: ServiceResearchObjectInput;
  serviceId: Scalars["ID"];
}>;

export type CreateServiceResearchObjectMutation = { __typename?: "Mutation" } & {
  createServiceResearchObject?: Maybe<
    { __typename: "ServiceResearchObject" } & Pick<
      ServiceResearchObject,
      "id" | "descriptor" | "info" | "note" | "status" | "statusChangeReason"
    > & {
        contactPerson?: Maybe<
          { __typename: "Person" } & Pick<
            Person,
            | "id"
            | "salut"
            | "name"
            | "alias"
            | "age"
            | "ageGuessed"
            | "firstName"
            | "lastName"
            | "temporary"
            | "birthDate"
          >
        >;
        businessObject?: Maybe<
          { __typename: "BusinessObject" } & Pick<
            BusinessObject,
            "id" | "name" | "url" | "info" | "accountId"
          > & {
              address?: Maybe<
                { __typename: "Address" } & Pick<
                  Address,
                  "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
                >
              >;
              email?: Maybe<
                { __typename: "Email" } & Pick<Email, "id" | "type" | "contactAllowed" | "email">
              >;
              phones?: Maybe<
                Array<
                  Maybe<
                    { __typename: "Phone" } & Pick<
                      Phone,
                      | "id"
                      | "type"
                      | "contactAllowed"
                      | "messagingAllowed"
                      | "countryCode"
                      | "areaCode"
                      | "number"
                      | "readableNumber"
                    >
                  >
                >
              >;
              hours?: Maybe<
                Array<Maybe<{ __typename?: "Hour" } & Pick<Hour, "id" | "open" | "close" | "day">>>
              >;
            }
        >;
      }
  >;
};

export type CreatePlainResearchObjectMutationVariables = Exact<{
  input: PlainSroInput;
  serviceId: Scalars["Int"];
}>;

export type CreatePlainResearchObjectMutation = { __typename?: "Mutation" } & {
  createPlainResearchObject?: Maybe<
    { __typename: "ServiceResearchObject" } & Pick<
      ServiceResearchObject,
      "id" | "descriptor" | "info" | "note" | "status" | "statusChangeReason"
    > & {
        contactPerson?: Maybe<
          { __typename: "Person" } & Pick<
            Person,
            | "id"
            | "salut"
            | "name"
            | "alias"
            | "age"
            | "ageGuessed"
            | "firstName"
            | "lastName"
            | "temporary"
            | "birthDate"
          >
        >;
        businessObject?: Maybe<
          { __typename: "BusinessObject" } & Pick<
            BusinessObject,
            "id" | "name" | "url" | "info" | "accountId"
          > & {
              address?: Maybe<
                { __typename: "Address" } & Pick<
                  Address,
                  "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
                >
              >;
              email?: Maybe<
                { __typename: "Email" } & Pick<Email, "id" | "type" | "contactAllowed" | "email">
              >;
              phones?: Maybe<
                Array<
                  Maybe<
                    { __typename: "Phone" } & Pick<
                      Phone,
                      | "id"
                      | "type"
                      | "contactAllowed"
                      | "messagingAllowed"
                      | "countryCode"
                      | "areaCode"
                      | "number"
                      | "readableNumber"
                    >
                  >
                >
              >;
              hours?: Maybe<
                Array<Maybe<{ __typename?: "Hour" } & Pick<Hour, "id" | "open" | "close" | "day">>>
              >;
            }
        >;
      }
  >;
};

export type UpdatePlainResearchObjectMutationVariables = Exact<{
  input: PlainSroInput;
  id: Scalars["Int"];
}>;

export type UpdatePlainResearchObjectMutation = { __typename?: "Mutation" } & {
  updatePlainResearchObject?: Maybe<
    { __typename: "ServiceResearchObject" } & Pick<
      ServiceResearchObject,
      "id" | "descriptor" | "info" | "note" | "status" | "statusChangeReason"
    > & {
        contactPerson?: Maybe<
          { __typename: "Person" } & Pick<
            Person,
            | "id"
            | "salut"
            | "name"
            | "alias"
            | "age"
            | "ageGuessed"
            | "firstName"
            | "lastName"
            | "temporary"
            | "birthDate"
          >
        >;
        businessObject?: Maybe<
          { __typename: "BusinessObject" } & Pick<
            BusinessObject,
            "id" | "name" | "url" | "info" | "accountId"
          > & {
              address?: Maybe<
                { __typename: "Address" } & Pick<
                  Address,
                  "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
                >
              >;
              email?: Maybe<
                { __typename: "Email" } & Pick<Email, "id" | "type" | "contactAllowed" | "email">
              >;
              phones?: Maybe<
                Array<
                  Maybe<
                    { __typename: "Phone" } & Pick<
                      Phone,
                      | "id"
                      | "type"
                      | "contactAllowed"
                      | "messagingAllowed"
                      | "countryCode"
                      | "areaCode"
                      | "number"
                      | "readableNumber"
                    >
                  >
                >
              >;
              hours?: Maybe<
                Array<Maybe<{ __typename?: "Hour" } & Pick<Hour, "id" | "open" | "close" | "day">>>
              >;
            }
        >;
      }
  >;
};

export type CreateServiceResearchObjectsMutationVariables = Exact<{
  businessObjectIds: Array<InputMaybe<Scalars["Int"]>> | InputMaybe<Scalars["Int"]>;
  serviceId: Scalars["Int"];
}>;

export type CreateServiceResearchObjectsMutation = { __typename?: "Mutation" } & {
  createServiceResearchObjects?: Maybe<
    Array<
      Maybe<
        { __typename: "ServiceResearchObject" } & Pick<
          ServiceResearchObject,
          "id" | "descriptor" | "info" | "note" | "status" | "statusChangeReason"
        > & {
            contactPerson?: Maybe<
              { __typename: "Person" } & Pick<
                Person,
                | "id"
                | "salut"
                | "name"
                | "alias"
                | "age"
                | "ageGuessed"
                | "firstName"
                | "lastName"
                | "temporary"
                | "birthDate"
              >
            >;
            businessObject?: Maybe<
              { __typename: "BusinessObject" } & Pick<
                BusinessObject,
                "id" | "name" | "url" | "info" | "accountId"
              > & {
                  address?: Maybe<
                    { __typename: "Address" } & Pick<
                      Address,
                      "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
                    >
                  >;
                  email?: Maybe<
                    { __typename: "Email" } & Pick<
                      Email,
                      "id" | "type" | "contactAllowed" | "email"
                    >
                  >;
                  phones?: Maybe<
                    Array<
                      Maybe<
                        { __typename: "Phone" } & Pick<
                          Phone,
                          | "id"
                          | "type"
                          | "contactAllowed"
                          | "messagingAllowed"
                          | "countryCode"
                          | "areaCode"
                          | "number"
                          | "readableNumber"
                        >
                      >
                    >
                  >;
                  hours?: Maybe<
                    Array<
                      Maybe<{ __typename?: "Hour" } & Pick<Hour, "id" | "open" | "close" | "day">>
                    >
                  >;
                  persons?: Maybe<
                    Array<
                      Maybe<
                        { __typename: "Person" } & Pick<
                          Person,
                          | "id"
                          | "salut"
                          | "name"
                          | "alias"
                          | "age"
                          | "ageGuessed"
                          | "firstName"
                          | "lastName"
                          | "temporary"
                          | "birthDate"
                        >
                      >
                    >
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type UpdateServiceResearchObjectMutationVariables = Exact<{
  serviceResearchObjectInput: ServiceResearchObjectInput;
  id: Scalars["ID"];
}>;

export type UpdateServiceResearchObjectMutation = { __typename?: "Mutation" } & {
  updateServiceResearchObject?: Maybe<
    { __typename: "ServiceResearchObject" } & Pick<
      ServiceResearchObject,
      "id" | "descriptor" | "info" | "note" | "status" | "statusChangeReason"
    > & {
        contactPerson?: Maybe<
          { __typename: "Person" } & Pick<
            Person,
            | "id"
            | "salut"
            | "name"
            | "alias"
            | "age"
            | "ageGuessed"
            | "firstName"
            | "lastName"
            | "temporary"
            | "birthDate"
          >
        >;
        businessObject?: Maybe<
          { __typename: "BusinessObject" } & Pick<
            BusinessObject,
            "id" | "name" | "url" | "info" | "accountId"
          > & {
              address?: Maybe<
                { __typename: "Address" } & Pick<
                  Address,
                  "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
                >
              >;
              email?: Maybe<
                { __typename: "Email" } & Pick<Email, "id" | "type" | "contactAllowed" | "email">
              >;
              phones?: Maybe<
                Array<
                  Maybe<
                    { __typename: "Phone" } & Pick<
                      Phone,
                      | "id"
                      | "type"
                      | "contactAllowed"
                      | "messagingAllowed"
                      | "countryCode"
                      | "areaCode"
                      | "number"
                      | "readableNumber"
                    >
                  >
                >
              >;
              hours?: Maybe<
                Array<Maybe<{ __typename?: "Hour" } & Pick<Hour, "id" | "open" | "close" | "day">>>
              >;
              persons?: Maybe<
                Array<
                  Maybe<
                    { __typename: "Person" } & Pick<
                      Person,
                      | "id"
                      | "salut"
                      | "name"
                      | "alias"
                      | "age"
                      | "ageGuessed"
                      | "firstName"
                      | "lastName"
                      | "temporary"
                      | "birthDate"
                    >
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type ToggleReportStatisticMutationVariables = Exact<{
  reportId: Scalars["Int"];
  itemId: Scalars["Int"];
}>;

export type ToggleReportStatisticMutation = { __typename?: "Mutation" } & {
  toggleReportStatistic?: Maybe<
    { __typename?: "ConversationReport" } & Pick<ConversationReport, "id">
  >;
};

export type SnoozeTaskMutationVariables = Exact<{
  taskId: Scalars["Int"];
  snoozeUntil: Scalars["DateTime"];
}>;

export type SnoozeTaskMutation = { __typename?: "Mutation" } & Pick<Mutation, "snoozeTask">;

export type AssignTaskMutationVariables = Exact<{
  taskId: Scalars["Int"];
  userId?: InputMaybe<Scalars["Int"]>;
  start?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AssignTaskMutation = { __typename?: "Mutation" } & Pick<Mutation, "assignTask">;

export type FinishTaskMutationVariables = Exact<{
  taskId: Scalars["Int"];
  revert?: InputMaybe<Scalars["Boolean"]>;
}>;

export type FinishTaskMutation = { __typename?: "Mutation" } & Pick<Mutation, "finishTask">;

export type UnassignTaskMutationVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type UnassignTaskMutation = { __typename?: "Mutation" } & Pick<Mutation, "unassignTask">;

export type CreateCustomTaskMutationVariables = Exact<{
  taskInput?: InputMaybe<TaskInput>;
}>;

export type CreateCustomTaskMutation = { __typename?: "Mutation" } & {
  createCustomTask?: Maybe<{ __typename?: "Task" } & Pick<Task, "id" | "title">>;
};

export type AssignTaskToTeamMutationVariables = Exact<{
  taskId: Scalars["Int"];
  targetTeam?: InputMaybe<Team>;
  start?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AssignTaskToTeamMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "assignTaskToTeam"
>;

export type CreateCmReminderTaskMutationVariables = Exact<{
  personId: Scalars["Int"];
  snoozeUntil: Scalars["DateTime"];
  taskId?: InputMaybe<Scalars["Int"]>;
}>;

export type CreateCmReminderTaskMutation = { __typename?: "Mutation" } & {
  createCMReminderTask?: Maybe<
    { __typename?: "Task" } & Pick<Task, "id" | "title" | "snoozeUntil">
  >;
};

export type FinishWpoAssignmentProcessMutationVariables = Exact<{
  ucmsId: Scalars["Int"];
  messageId: Scalars["String"];
  personId: Scalars["Int"];
  shouldCreateTask: Scalars["Boolean"];
  shouldSendWpoEmail: Scalars["Boolean"];
}>;

export type FinishWpoAssignmentProcessMutation = { __typename?: "Mutation" } & {
  finishWpoAssignmentProcess?: Maybe<
    { __typename?: "Task" } & Pick<Task, "id" | "title" | "snoozeUntil">
  >;
};

export type UpdateTeamsMutationVariables = Exact<{
  teams: Array<Team> | Team;
}>;

export type UpdateTeamsMutation = { __typename?: "Mutation" } & Pick<Mutation, "updateTeams">;

export type UpdateAbsentMutationVariables = Exact<{
  id: Scalars["Int"];
  absentUntil: Scalars["DateTime"];
}>;

export type UpdateAbsentMutation = { __typename?: "Mutation" } & {
  updateAbsent?: Maybe<{ __typename?: "User" } & Pick<User, "id">>;
};

export type UpdateExtensionMutationVariables = Exact<{
  id: Scalars["Int"];
  extension: Scalars["String"];
}>;

export type UpdateExtensionMutation = { __typename?: "Mutation" } & {
  updateExtension?: Maybe<{ __typename?: "User" } & Pick<User, "id">>;
};

export type AccountFragment = { __typename: "Account" } & Pick<
  Account,
  | "id"
  | "chatRegistrationPin"
  | "coaching"
  | "contractEnd"
  | "contractStart"
  | "employeesCount"
  | "executivesCount"
  | "externalRef"
  | "lifeworksId"
  | "helpline"
  | "helpline2"
  | "helplineInternational"
  | "remarks"
  | "nFreeSTCs"
  | "serviceTimes"
  | "usageRate"
  | "freeSessions"
  | "isAssistance"
  | "name"
  | "pin"
  | "reporting"
  | "tariff"
  | "type"
  | "updatedAt"
  | "usageScope"
  | "validationPattern"
  | "serviceRegion"
> & { industry?: Maybe<{ __typename?: "Industry" } & Pick<Industry, "id" | "name">> };

export type AccountMinimalFragment = { __typename: "Account" } & Pick<
  Account,
  "id" | "name" | "isAssistance" | "validationPattern" | "tariff" | "contractEnd"
>;

export type AccountsQueryVariables = Exact<{
  name?: InputMaybe<Scalars["String"]>;
}>;

export type AccountsQuery = { __typename?: "Query" } & {
  accounts?: Maybe<
    Array<
      Maybe<
        { __typename: "Account" } & Pick<
          Account,
          "id" | "name" | "isAssistance" | "validationPattern" | "tariff" | "contractEnd"
        >
      >
    >
  >;
};

export type AccountQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type AccountQuery = { __typename?: "Query" } & {
  account?: Maybe<
    { __typename: "Account" } & Pick<
      Account,
      | "id"
      | "chatRegistrationPin"
      | "coaching"
      | "contractEnd"
      | "contractStart"
      | "employeesCount"
      | "executivesCount"
      | "externalRef"
      | "lifeworksId"
      | "helpline"
      | "helpline2"
      | "helplineInternational"
      | "remarks"
      | "nFreeSTCs"
      | "serviceTimes"
      | "usageRate"
      | "freeSessions"
      | "isAssistance"
      | "name"
      | "pin"
      | "reporting"
      | "tariff"
      | "type"
      | "updatedAt"
      | "usageScope"
      | "validationPattern"
      | "serviceRegion"
    > & {
        accountServiceModules?: Maybe<
          Array<
            Maybe<
              { __typename?: "ServiceMeta" } & Pick<
                ServiceMeta,
                "id" | "name" | "serviceCategoryId"
              >
            >
          >
        >;
        parent?: Maybe<{ __typename?: "Account" } & Pick<Account, "id" | "name" | "serviceRegion">>;
        business?: Maybe<
          { __typename: "BusinessObject" } & Pick<
            BusinessObject,
            "memo" | "id" | "name" | "url" | "info" | "accountId"
          > & {
              address?: Maybe<
                { __typename: "Address" } & Pick<
                  Address,
                  "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
                >
              >;
              email?: Maybe<
                { __typename: "Email" } & Pick<Email, "id" | "type" | "contactAllowed" | "email">
              >;
              persons?: Maybe<
                Array<
                  Maybe<
                    { __typename: "Person" } & Pick<
                      Person,
                      | "id"
                      | "salut"
                      | "name"
                      | "alias"
                      | "age"
                      | "ageGuessed"
                      | "firstName"
                      | "lastName"
                      | "temporary"
                      | "birthDate"
                    >
                  >
                >
              >;
              phones?: Maybe<
                Array<
                  Maybe<
                    { __typename: "Phone" } & Pick<
                      Phone,
                      | "id"
                      | "type"
                      | "contactAllowed"
                      | "messagingAllowed"
                      | "countryCode"
                      | "areaCode"
                      | "number"
                      | "readableNumber"
                    >
                  >
                >
              >;
            }
        >;
        industry?: Maybe<{ __typename?: "Industry" } & Pick<Industry, "id" | "name">>;
      }
  >;
};

export type AccountStatisticsQueryVariables = Exact<{
  accountId: Scalars["Int"];
  start: Scalars["DateTime"];
  end: Scalars["DateTime"];
}>;

export type AccountStatisticsQuery = { __typename?: "Query" } & {
  accountStatistics?: Maybe<{ __typename?: "Statistic" } & Pick<Statistic, "fileId">>;
};

export type AccountStatisticsByTypeQueryVariables = Exact<{
  type: Scalars["String"];
  start: Scalars["DateTime"];
  end: Scalars["DateTime"];
}>;

export type AccountStatisticsByTypeQuery = { __typename?: "Query" } & {
  accountStatisticsByType?: Maybe<{ __typename?: "Statistic" } & Pick<Statistic, "fileId">>;
};

export type ComplaintsStatisticsByTypeQueryVariables = Exact<{
  type: Scalars["String"];
  start: Scalars["DateTime"];
  end: Scalars["DateTime"];
}>;

export type ComplaintsStatisticsByTypeQuery = { __typename?: "Query" } & {
  complaintsStatisticsByType?: Maybe<{ __typename?: "Statistic" } & Pick<Statistic, "fileId">>;
};

export type ComplaintsStatisticsForBusinessQueryVariables = Exact<{
  businessId: Scalars["Int"];
  start: Scalars["DateTime"];
  end: Scalars["DateTime"];
}>;

export type ComplaintsStatisticsForBusinessQuery = { __typename?: "Query" } & {
  complaintsStatisticsForBusiness?: Maybe<{ __typename?: "Statistic" } & Pick<Statistic, "fileId">>;
};

export type MonthlyLifeWorksStatisticsQueryVariables = Exact<{
  start: Scalars["DateTime"];
  type?: InputMaybe<AccountType>;
}>;

export type MonthlyLifeWorksStatisticsQuery = { __typename?: "Query" } & {
  monthlyLifeWorksStatistics?: Maybe<{ __typename?: "Statistic" } & Pick<Statistic, "fileId">>;
};

export type BreadCrumbInfoByAccountIdQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type BreadCrumbInfoByAccountIdQuery = { __typename?: "Query" } & {
  breadCrumbInfoByAccountId?: Maybe<
    { __typename?: "BreadCrumbStatusDetail" } & Pick<
      BreadCrumbStatusDetail,
      "contractEnd" | "language"
    >
  >;
};

export type AddressFragment = { __typename: "Address" } & Pick<
  Address,
  "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
>;

export type AppointmentFragment = { __typename: "Appointment" } & Pick<
  Appointment,
  "id" | "title" | "note" | "date" | "from" | "to" | "deletedAt"
> & {
    topic?: Maybe<{ __typename: "Topic" } & Pick<Topic, "id" | "name">>;
    service?: Maybe<
      { __typename: "Service" } & Pick<Service, "id"> & {
          serviceMeta?: Maybe<{ __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">>;
        }
    >;
  };

export type AppointmentsByDayQueryVariables = Exact<{
  startsAt: Scalars["String"];
  endsAt: Scalars["String"];
}>;

export type AppointmentsByDayQuery = { __typename?: "Query" } & {
  appointmentsByDay?: Maybe<
    Array<
      Maybe<
        { __typename: "Appointment" } & Pick<
          Appointment,
          "id" | "title" | "note" | "date" | "from" | "to" | "deletedAt"
        > & {
            users?: Maybe<
              Array<
                Maybe<
                  { __typename: "User" } & Pick<
                    User,
                    "id" | "active" | "name" | "absentUntil" | "primeDN"
                  > & {
                      person?: Maybe<
                        { __typename: "Person" } & Pick<
                          Person,
                          | "alias"
                          | "avatar"
                          | "avatarKeys"
                          | "picture"
                          | "mentalState"
                          | "id"
                          | "salut"
                          | "name"
                          | "age"
                          | "ageGuessed"
                          | "firstName"
                          | "lastName"
                          | "temporary"
                          | "birthDate"
                        >
                      >;
                      hotDesks?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: "HotDesk" } & {
                              extension?: Maybe<
                                { __typename?: "Extension" } & Pick<Extension, "primeDN">
                              >;
                            }
                          >
                        >
                      >;
                    }
                >
              >
            >;
            person?: Maybe<
              { __typename: "Person" } & Pick<
                Person,
                | "avatarKeys"
                | "mentalState"
                | "id"
                | "salut"
                | "name"
                | "alias"
                | "age"
                | "ageGuessed"
                | "firstName"
                | "lastName"
                | "temporary"
                | "birthDate"
              >
            >;
            topic?: Maybe<{ __typename: "Topic" } & Pick<Topic, "id" | "name">>;
            service?: Maybe<
              { __typename: "Service" } & Pick<Service, "id"> & {
                  serviceMeta?: Maybe<
                    { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type AppointmentsByUsersQueryVariables = Exact<{
  userIds: Array<Scalars["Int"]> | Scalars["Int"];
  startsAt: Scalars["String"];
  endsAt: Scalars["String"];
}>;

export type AppointmentsByUsersQuery = { __typename?: "Query" } & {
  appointmentsByUsers?: Maybe<
    Array<
      Maybe<
        { __typename: "Appointment" } & Pick<
          Appointment,
          "id" | "title" | "note" | "date" | "from" | "to" | "deletedAt"
        > & {
            users?: Maybe<
              Array<
                Maybe<
                  { __typename: "User" } & Pick<
                    User,
                    "id" | "active" | "name" | "absentUntil" | "primeDN"
                  > & {
                      person?: Maybe<
                        { __typename: "Person" } & Pick<
                          Person,
                          | "alias"
                          | "avatar"
                          | "avatarKeys"
                          | "picture"
                          | "mentalState"
                          | "id"
                          | "salut"
                          | "name"
                          | "age"
                          | "ageGuessed"
                          | "firstName"
                          | "lastName"
                          | "temporary"
                          | "birthDate"
                        >
                      >;
                      hotDesks?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: "HotDesk" } & {
                              extension?: Maybe<
                                { __typename?: "Extension" } & Pick<Extension, "primeDN">
                              >;
                            }
                          >
                        >
                      >;
                    }
                >
              >
            >;
            person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name" | "age">>;
            topic?: Maybe<{ __typename: "Topic" } & Pick<Topic, "id" | "name">>;
            service?: Maybe<
              { __typename: "Service" } & Pick<Service, "id"> & {
                  serviceMeta?: Maybe<
                    { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type MyAppointmentsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars["Int"]>;
}>;

export type MyAppointmentsQuery = { __typename?: "Query" } & {
  myAppointments?: Maybe<
    Array<
      Maybe<
        { __typename: "Appointment" } & Pick<
          Appointment,
          "id" | "title" | "note" | "date" | "from" | "to" | "deletedAt"
        > & {
            users?: Maybe<
              Array<
                Maybe<
                  { __typename: "User" } & Pick<
                    User,
                    "id" | "active" | "name" | "absentUntil" | "primeDN"
                  > & {
                      person?: Maybe<
                        { __typename: "Person" } & Pick<
                          Person,
                          | "alias"
                          | "avatar"
                          | "avatarKeys"
                          | "picture"
                          | "mentalState"
                          | "id"
                          | "salut"
                          | "name"
                          | "age"
                          | "ageGuessed"
                          | "firstName"
                          | "lastName"
                          | "temporary"
                          | "birthDate"
                        >
                      >;
                      hotDesks?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: "HotDesk" } & {
                              extension?: Maybe<
                                { __typename?: "Extension" } & Pick<Extension, "primeDN">
                              >;
                            }
                          >
                        >
                      >;
                    }
                >
              >
            >;
            task?: Maybe<{ __typename?: "Task" } & Pick<Task, "id" | "finishedAt" | "startedAt">>;
            person?: Maybe<
              { __typename: "Person" } & Pick<
                Person,
                | "avatarKeys"
                | "mentalState"
                | "id"
                | "salut"
                | "name"
                | "alias"
                | "age"
                | "ageGuessed"
                | "firstName"
                | "lastName"
                | "temporary"
                | "birthDate"
              >
            >;
            topic?: Maybe<{ __typename: "Topic" } & Pick<Topic, "id" | "name">>;
            service?: Maybe<
              { __typename: "Service" } & Pick<Service, "id"> & {
                  serviceMeta?: Maybe<
                    { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type PersonAppointmentsQueryVariables = Exact<{
  personId: Scalars["Int"];
}>;

export type PersonAppointmentsQuery = { __typename?: "Query" } & {
  personAppointments?: Maybe<
    Array<
      Maybe<
        { __typename: "Appointment" } & Pick<
          Appointment,
          "id" | "title" | "note" | "date" | "from" | "to" | "deletedAt"
        > & {
            users?: Maybe<
              Array<
                Maybe<
                  { __typename: "User" } & Pick<
                    User,
                    "id" | "active" | "name" | "absentUntil" | "primeDN"
                  > & {
                      person?: Maybe<
                        { __typename: "Person" } & Pick<
                          Person,
                          | "avatar"
                          | "avatarKeys"
                          | "alias"
                          | "id"
                          | "salut"
                          | "name"
                          | "age"
                          | "ageGuessed"
                          | "firstName"
                          | "lastName"
                          | "temporary"
                          | "birthDate"
                        >
                      >;
                      hotDesks?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: "HotDesk" } & {
                              extension?: Maybe<
                                { __typename?: "Extension" } & Pick<Extension, "primeDN">
                              >;
                            }
                          >
                        >
                      >;
                    }
                >
              >
            >;
            person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
            topic?: Maybe<{ __typename: "Topic" } & Pick<Topic, "id" | "name">>;
            service?: Maybe<
              { __typename: "Service" } & Pick<Service, "id"> & {
                  serviceMeta?: Maybe<
                    { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type BusinessCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type BusinessCategoriesQuery = { __typename?: "Query" } & {
  businessCategories?: Maybe<
    Array<Maybe<{ __typename?: "BusinessCategory" } & Pick<BusinessCategory, "id" | "name">>>
  >;
};

export type BusinessObjectDataFragment = { __typename: "BusinessObject" } & Pick<
  BusinessObject,
  "id" | "name" | "url" | "info" | "accountId"
>;

export type BusinessObjectQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type BusinessObjectQuery = { __typename?: "Query" } & {
  businessObject?: Maybe<
    { __typename: "BusinessObject" } & Pick<
      BusinessObject,
      "memo" | "id" | "name" | "url" | "info" | "accountId"
    > & {
        category?: Maybe<
          { __typename?: "BusinessCategory" } & Pick<BusinessCategory, "id" | "name">
        >;
        address?: Maybe<
          { __typename: "Address" } & Pick<
            Address,
            "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
          >
        >;
        email?: Maybe<
          { __typename: "Email" } & Pick<Email, "id" | "type" | "contactAllowed" | "email">
        >;
        persons?: Maybe<
          Array<
            Maybe<
              { __typename: "Person" } & Pick<
                Person,
                | "id"
                | "name"
                | "alias"
                | "firstName"
                | "lastName"
                | "temporary"
                | "gender"
                | "title"
                | "note"
                | "salut"
                | "avatarKeys"
              >
            >
          >
        >;
        phones?: Maybe<
          Array<
            Maybe<
              { __typename: "Phone" } & Pick<
                Phone,
                | "id"
                | "type"
                | "contactAllowed"
                | "messagingAllowed"
                | "countryCode"
                | "areaCode"
                | "number"
                | "readableNumber"
              >
            >
          >
        >;
        hours?: Maybe<
          Array<Maybe<{ __typename?: "Hour" } & Pick<Hour, "id" | "open" | "close" | "day">>>
        >;
      }
  >;
};

export type BusinessObjectByPersonQueryVariables = Exact<{
  personId: Scalars["Int"];
}>;

export type BusinessObjectByPersonQuery = { __typename?: "Query" } & {
  businessObjectByPerson?: Maybe<
    { __typename: "BusinessObject" } & Pick<
      BusinessObject,
      "id" | "name" | "url" | "info" | "accountId"
    > & {
        address?: Maybe<
          { __typename: "Address" } & Pick<
            Address,
            "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
          >
        >;
        email?: Maybe<
          { __typename: "Email" } & Pick<Email, "id" | "type" | "contactAllowed" | "email">
        >;
        persons?: Maybe<
          Array<
            Maybe<
              { __typename: "Person" } & Pick<
                Person,
                | "id"
                | "salut"
                | "name"
                | "alias"
                | "age"
                | "ageGuessed"
                | "firstName"
                | "lastName"
                | "temporary"
                | "birthDate"
              >
            >
          >
        >;
        phones?: Maybe<
          Array<
            Maybe<
              { __typename: "Phone" } & Pick<
                Phone,
                | "id"
                | "type"
                | "contactAllowed"
                | "messagingAllowed"
                | "countryCode"
                | "areaCode"
                | "number"
                | "readableNumber"
              >
            >
          >
        >;
        hours?: Maybe<
          Array<Maybe<{ __typename?: "Hour" } & Pick<Hour, "id" | "open" | "close" | "day">>>
        >;
      }
  >;
};

export type BusinessObjectsQueryVariables = Exact<{
  serviceId?: InputMaybe<Scalars["Int"]>;
  unique?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  withAddress?: InputMaybe<Scalars["Boolean"]>;
}>;

export type BusinessObjectsQuery = { __typename?: "Query" } & {
  businessObjects: Array<
    { __typename: "BusinessObject" } & Pick<
      BusinessObject,
      "id" | "name" | "url" | "info" | "accountId"
    > & {
        address?: Maybe<
          { __typename: "Address" } & Pick<
            Address,
            "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
          >
        >;
        email?: Maybe<
          { __typename: "Email" } & Pick<Email, "id" | "type" | "contactAllowed" | "email">
        >;
        persons?: Maybe<
          Array<
            Maybe<
              { __typename: "Person" } & Pick<
                Person,
                | "id"
                | "salut"
                | "name"
                | "alias"
                | "age"
                | "ageGuessed"
                | "firstName"
                | "lastName"
                | "temporary"
                | "birthDate"
              >
            >
          >
        >;
        phones?: Maybe<
          Array<
            Maybe<
              { __typename: "Phone" } & Pick<
                Phone,
                | "id"
                | "type"
                | "contactAllowed"
                | "messagingAllowed"
                | "countryCode"
                | "areaCode"
                | "number"
                | "readableNumber"
              >
            >
          >
        >;
        hours?: Maybe<
          Array<Maybe<{ __typename?: "Hour" } & Pick<Hour, "id" | "open" | "close" | "day">>>
        >;
      }
  >;
};

export type GetBusinessUnitsByAccountQueryVariables = Exact<{
  accountId: Scalars["Int"];
}>;

export type GetBusinessUnitsByAccountQuery = { __typename?: "Query" } & {
  getBusinessUnitsByAccount: Array<
    { __typename?: "BusinessUnit" } & Pick<BusinessUnit, "id" | "name">
  >;
};

export type CallsQueryVariables = Exact<{ [key: string]: never }>;

export type CallsQuery = { __typename?: "Query" } & {
  calls?: Maybe<
    Array<
      { __typename?: "Call" } & Pick<
        Call,
        | "id"
        | "readableNumber"
        | "areaCode"
        | "countryCode"
        | "number"
        | "status"
        | "accountId"
        | "callType"
        | "personActivityId"
      > & { person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">> }
    >
  >;
};

export type GetCaseManagementReviewsQueryVariables = Exact<{
  includeValues?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  clientReviewAttributeId?: InputMaybe<Scalars["Int"]>;
}>;

export type GetCaseManagementReviewsQuery = { __typename?: "Query" } & {
  getCaseManagementReviews?: Maybe<
    Array<
      Maybe<
        { __typename?: "CaseManagementReview" } & Pick<
          CaseManagementReview,
          "id" | "lastUpdate"
        > & {
            caseManagementReviewAttributes?: Maybe<
              Array<
                Maybe<
                  { __typename?: "CaseManagementReviewAttribute" } & Pick<
                    CaseManagementReviewAttribute,
                    "id" | "attributeValue" | "lastUpdate"
                  >
                >
              >
            >;
            caseManager?: Maybe<
              { __typename?: "CaseManager" } & {
                user?: Maybe<
                  { __typename: "User" } & Pick<
                    User,
                    "id" | "active" | "name" | "absentUntil" | "primeDN"
                  > & {
                      papershiftWorkingAreas?: Maybe<
                        Array<
                          Maybe<
                            { __typename: "PapershiftWorkingArea" } & Pick<
                              PapershiftWorkingArea,
                              "id" | "name" | "color"
                            >
                          >
                        >
                      >;
                      person?: Maybe<
                        { __typename?: "Person" } & Pick<
                          Person,
                          "id" | "alias" | "name" | "avatar" | "avatarKeys" | "picture"
                        >
                      >;
                      hotDesks?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: "HotDesk" } & {
                              extension?: Maybe<
                                { __typename?: "Extension" } & Pick<Extension, "primeDN">
                              >;
                            }
                          >
                        >
                      >;
                    }
                >;
              }
            >;
            client?: Maybe<
              { __typename?: "Client" } & Pick<Client, "id"> & {
                  account?: Maybe<{ __typename?: "Account" } & Pick<Account, "id" | "name">>;
                  person?: Maybe<
                    { __typename?: "Person" } & Pick<
                      Person,
                      "id" | "flag" | "name" | "mentalState" | "vip" | "avatarKeys"
                    >
                  >;
                  topics?: Maybe<
                    Array<Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>>
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type GetCaseManagementReviewsByUserIdQueryVariables = Exact<{
  includeValues?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  clientReviewAttributeId?: InputMaybe<Scalars["Int"]>;
  userId: Scalars["Int"];
}>;

export type GetCaseManagementReviewsByUserIdQuery = { __typename?: "Query" } & {
  getCaseManagementReviewsByUserId?: Maybe<
    Array<
      Maybe<
        { __typename?: "CaseManagementReview" } & Pick<
          CaseManagementReview,
          "id" | "lastUpdate"
        > & {
            caseManagementReviewAttributes?: Maybe<
              Array<
                Maybe<
                  { __typename?: "CaseManagementReviewAttribute" } & Pick<
                    CaseManagementReviewAttribute,
                    "id" | "attributeValue" | "lastUpdate"
                  >
                >
              >
            >;
            user?: Maybe<
              { __typename: "User" } & Pick<
                User,
                "id" | "active" | "name" | "absentUntil" | "primeDN"
              > & {
                  papershiftWorkingAreas?: Maybe<
                    Array<
                      Maybe<
                        { __typename: "PapershiftWorkingArea" } & Pick<
                          PapershiftWorkingArea,
                          "id" | "name" | "color"
                        >
                      >
                    >
                  >;
                  person?: Maybe<
                    { __typename?: "Person" } & Pick<
                      Person,
                      "id" | "alias" | "name" | "avatar" | "avatarKeys" | "picture"
                    >
                  >;
                  hotDesks?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: "HotDesk" } & {
                          extension?: Maybe<
                            { __typename?: "Extension" } & Pick<Extension, "primeDN">
                          >;
                        }
                      >
                    >
                  >;
                }
            >;
            client?: Maybe<
              { __typename?: "Client" } & Pick<Client, "id"> & {
                  account?: Maybe<{ __typename?: "Account" } & Pick<Account, "id" | "name">>;
                  person?: Maybe<
                    { __typename?: "Person" } & Pick<
                      Person,
                      "id" | "flag" | "name" | "mentalState" | "vip" | "avatarKeys"
                    >
                  >;
                  topics?: Maybe<
                    Array<Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>>
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type GetCaseManagementReviewQueryVariables = Exact<{
  personId: Scalars["Int"];
}>;

export type GetCaseManagementReviewQuery = { __typename?: "Query" } & {
  getCaseManagementReview?: Maybe<
    { __typename?: "CaseManagementReview" } & Pick<CaseManagementReview, "id">
  >;
};

export type GetCaseManagementReviewDetailsQueryVariables = Exact<{
  personId: Scalars["Int"];
}>;

export type GetCaseManagementReviewDetailsQuery = { __typename?: "Query" } & {
  getCaseManagementReview?: Maybe<
    { __typename?: "CaseManagementReview" } & Pick<CaseManagementReview, "id"> & {
        caseManagementReviewAttributes?: Maybe<
          Array<
            Maybe<
              { __typename?: "CaseManagementReviewAttribute" } & Pick<
                CaseManagementReviewAttribute,
                "id" | "attributeValue" | "lastUpdate"
              > & {
                  user?: Maybe<
                    { __typename: "User" } & Pick<
                      User,
                      "id" | "active" | "name" | "absentUntil" | "primeDN"
                    > & {
                        person?: Maybe<
                          { __typename?: "Person" } & Pick<
                            Person,
                            "id" | "alias" | "name" | "avatar" | "avatarKeys" | "picture"
                          >
                        >;
                        hotDesks?: Maybe<
                          Array<
                            Maybe<
                              { __typename?: "HotDesk" } & {
                                extension?: Maybe<
                                  { __typename?: "Extension" } & Pick<Extension, "primeDN">
                                >;
                              }
                            >
                          >
                        >;
                      }
                  >;
                  clientReviewAttribute?: Maybe<
                    { __typename?: "ClientReviewAttribute" } & Pick<
                      ClientReviewAttribute,
                      "id" | "name"
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type GetClientReviewTemplateQueryVariables = Exact<{
  templateId: Scalars["Int"];
}>;

export type GetClientReviewTemplateQuery = { __typename?: "Query" } & {
  getClientReviewTemplate?: Maybe<
    { __typename?: "ClientReviewTemplate" } & Pick<ClientReviewTemplate, "id"> & {
        clientReviewAttributeGroups?: Maybe<
          Array<
            Maybe<
              { __typename?: "ClientReviewAttributeGroup" } & Pick<
                ClientReviewAttributeGroup,
                "id" | "headingUpdate" | "pageOrder"
              > & {
                  clientReviewAttributes?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: "ClientReviewAttribute" } & Pick<
                          ClientReviewAttribute,
                          "id" | "name" | "label" | "type"
                        >
                      >
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type GetCaseManagementReviewAttributeQueryVariables = Exact<{
  caseManagementReviewId: Scalars["Int"];
  clientReviewAttributeId: Scalars["Int"];
}>;

export type GetCaseManagementReviewAttributeQuery = { __typename?: "Query" } & {
  getCaseManagementReviewAttribute?: Maybe<
    { __typename?: "CaseManagementReviewAttribute" } & Pick<
      CaseManagementReviewAttribute,
      "id" | "attributeValue" | "lastUpdate"
    >
  >;
};

export type GetCaseManagerByPersonQueryVariables = Exact<{
  personId: Scalars["Int"];
}>;

export type GetCaseManagerByPersonQuery = { __typename?: "Query" } & {
  getCaseManagerByPerson?: Maybe<
    { __typename?: "CaseManager" } & Pick<CaseManager, "id"> & {
        user?: Maybe<
          { __typename: "User" } & Pick<
            User,
            "id" | "active" | "name" | "absentUntil" | "primeDN"
          > & {
              papershiftWorkingAreas?: Maybe<
                Array<
                  Maybe<
                    { __typename: "PapershiftWorkingArea" } & Pick<
                      PapershiftWorkingArea,
                      "id" | "name" | "color"
                    >
                  >
                >
              >;
              person?: Maybe<
                { __typename?: "Person" } & Pick<
                  Person,
                  "id" | "name" | "alias" | "avatar" | "avatarKeys" | "picture"
                >
              >;
              hotDesks?: Maybe<
                Array<
                  Maybe<
                    { __typename?: "HotDesk" } & {
                      extension?: Maybe<{ __typename?: "Extension" } & Pick<Extension, "primeDN">>;
                    }
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type GetCaseManagerByClientQueryVariables = Exact<{
  clientId: Scalars["Int"];
}>;

export type GetCaseManagerByClientQuery = { __typename?: "Query" } & {
  getCaseManagerByClient?: Maybe<
    { __typename?: "CaseManager" } & Pick<CaseManager, "id"> & {
        user?: Maybe<
          { __typename: "User" } & Pick<
            User,
            "id" | "active" | "name" | "absentUntil" | "primeDN"
          > & {
              papershiftWorkingAreas?: Maybe<
                Array<
                  Maybe<
                    { __typename: "PapershiftWorkingArea" } & Pick<
                      PapershiftWorkingArea,
                      "id" | "name" | "color"
                    >
                  >
                >
              >;
              person?: Maybe<
                { __typename?: "Person" } & Pick<
                  Person,
                  "id" | "name" | "avatar" | "alias" | "avatarKeys" | "picture"
                >
              >;
              hotDesks?: Maybe<
                Array<
                  Maybe<
                    { __typename?: "HotDesk" } & {
                      extension?: Maybe<{ __typename?: "Extension" } & Pick<Extension, "primeDN">>;
                    }
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type GetActiveChatQueryVariables = Exact<{
  chatUserId: Scalars["Int"];
}>;

export type GetActiveChatQuery = { __typename?: "Query" } & {
  getActiveChat?: Maybe<
    { __typename?: "Chat" } & Pick<Chat, "id" | "chatUserId" | "status" | "team"> & {
        user?: Maybe<
          { __typename?: "User" } & Pick<User, "id" | "name"> & {
              person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id">>;
            }
        >;
      }
  >;
};

export type ActiveChatsQueryVariables = Exact<{ [key: string]: never }>;

export type ActiveChatsQuery = { __typename?: "Query" } & {
  activeChats: Array<
    { __typename?: "Chat" } & Pick<Chat, "id" | "chatUserId" | "status" | "team"> & {
        user?: Maybe<{ __typename?: "User" } & Pick<User, "id">>;
      }
  >;
};

export type GetDetailedActiveChatsQueryVariables = Exact<{ [key: string]: never }>;

export type GetDetailedActiveChatsQuery = { __typename?: "Query" } & {
  activeChats: Array<
    { __typename?: "Chat" } & Pick<Chat, "id" | "chatUserId" | "status" | "team"> & {
        user?: Maybe<
          { __typename?: "User" } & Pick<User, "id"> & {
              person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "avatar">>;
            }
        >;
        personActivity?: Maybe<
          { __typename?: "PersonActivity" } & Pick<
            PersonActivity,
            "id" | "activityType" | "lastUpdate"
          > & { person: { __typename?: "Person" } & Pick<Person, "id" | "alias" | "name"> }
        >;
      }
  >;
};

export type GetTokenQueryVariables = Exact<{ [key: string]: never }>;

export type GetTokenQuery = { __typename?: "Query" } & Pick<Query, "getToken">;

export type GetChatByActivityQueryVariables = Exact<{
  activityId: Scalars["Int"];
}>;

export type GetChatByActivityQuery = { __typename?: "Query" } & {
  getChatByActivity?: Maybe<{ __typename?: "Chat" } & Pick<Chat, "id" | "chatUserId">>;
};

export type LastFinishedChatQueryVariables = Exact<{
  chats: Array<LastFinishedChatInput> | LastFinishedChatInput;
}>;

export type LastFinishedChatQuery = { __typename?: "Query" } & {
  lastFinishedChat?: Maybe<
    Array<
      Maybe<
        { __typename?: "Chat" } & Pick<Chat, "id" | "chatUserId" | "status" | "team"> & {
            user?: Maybe<{ __typename?: "User" } & Pick<User, "id">>;
          }
      >
    >
  >;
};

export type HotFlagsQueryVariables = Exact<{ [key: string]: never }>;

export type HotFlagsQuery = { __typename?: "Query" } & {
  hotFlags?: Maybe<
    Array<
      Maybe<
        { __typename?: "Client" } & Pick<Client, "id" | "vip"> & {
            account?: Maybe<{ __typename?: "Account" } & Pick<Account, "id" | "name">>;
            person?: Maybe<
              { __typename?: "Person" } & Pick<Person, "id" | "name" | "yellowFlag" | "redFlag">
            >;
            clientAttributes?: Maybe<
              Array<
                Maybe<
                  { __typename?: "ClientAttribute" } & Pick<
                    ClientAttribute,
                    "id" | "attributeValue" | "start"
                  > & {
                      clientReviewAttribute?: Maybe<
                        { __typename?: "ClientReviewAttribute" } & Pick<
                          ClientReviewAttribute,
                          "id" | "name"
                        >
                      >;
                    }
                >
              >
            >;
          }
      >
    >
  >;
};

export type MyStcClientsQueryVariables = Exact<{ [key: string]: never }>;

export type MyStcClientsQuery = { __typename?: "Query" } & {
  mySTCClients?: Maybe<
    Array<
      Maybe<
        { __typename?: "Client" } & Pick<Client, "id"> & {
            person?: Maybe<
              { __typename?: "Person" } & Pick<
                Person,
                "id" | "name" | "lastActivity" | "flag" | "mentalState" | "vip" | "avatarKeys"
              >
            >;
          }
      >
    >
  >;
};

export type GetStcClientsQueryVariables = Exact<{
  userId: Scalars["Int"];
}>;

export type GetStcClientsQuery = { __typename?: "Query" } & {
  getSTCClients?: Maybe<
    Array<
      Maybe<
        { __typename?: "Client" } & Pick<Client, "id"> & {
            person?: Maybe<
              { __typename?: "Person" } & Pick<
                Person,
                "id" | "name" | "lastActivity" | "flag" | "mentalState" | "vip" | "avatarKeys"
              >
            >;
          }
      >
    >
  >;
};

export type MyCmClientsQueryVariables = Exact<{ [key: string]: never }>;

export type MyCmClientsQuery = { __typename?: "Query" } & {
  myCMClients?: Maybe<
    Array<
      Maybe<
        { __typename?: "Client" } & Pick<Client, "id"> & {
            person?: Maybe<
              { __typename?: "Person" } & Pick<
                Person,
                "id" | "name" | "lastActivity" | "flag" | "mentalState" | "vip" | "avatarKeys"
              >
            >;
          }
      >
    >
  >;
};

export type GetCmClientsQueryVariables = Exact<{
  userId: Scalars["Int"];
}>;

export type GetCmClientsQuery = { __typename?: "Query" } & {
  getCMClients?: Maybe<
    Array<
      Maybe<
        { __typename?: "Client" } & Pick<Client, "id"> & {
            person?: Maybe<
              { __typename?: "Person" } & Pick<
                Person,
                "id" | "name" | "lastActivity" | "flag" | "mentalState" | "vip" | "avatarKeys"
              >
            >;
          }
      >
    >
  >;
};

export type BreadCrumbInfoByClientIdQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type BreadCrumbInfoByClientIdQuery = { __typename?: "Query" } & {
  breadCrumbInfoByClientId?: Maybe<
    { __typename?: "BreadCrumbStatusDetail" } & Pick<
      BreadCrumbStatusDetail,
      "contractEnd" | "language"
    >
  >;
};

export type ClientByUcmsIdQueryVariables = Exact<{
  ucmsId: Scalars["Int"];
}>;

export type ClientByUcmsIdQuery = { __typename?: "Query" } & {
  clientByUcmsId?: Maybe<
    { __typename?: "Client" } & Pick<Client, "id"> & {
        account?: Maybe<{ __typename?: "Account" } & Pick<Account, "id" | "name">>;
        person?: Maybe<
          { __typename?: "Person" } & Pick<Person, "id" | "firstName" | "lastName" | "alias">
        >;
      }
  >;
};

export type AllClientsQueryVariables = Exact<{ [key: string]: never }>;

export type AllClientsQuery = { __typename?: "Query" } & {
  allClients?: Maybe<
    Array<
      Maybe<
        { __typename?: "CaseMangerByUserViewModel" } & Pick<CaseMangerByUserViewModel, "total"> & {
            user?: Maybe<
              { __typename?: "User" } & {
                person?: Maybe<
                  { __typename?: "Person" } & Pick<
                    Person,
                    "name" | "alias" | "avatarKeys" | "avatar"
                  >
                >;
              }
            >;
            clients?: Maybe<
              Array<
                Maybe<
                  { __typename?: "Client" } & Pick<Client, "id"> & {
                      caseManager?: Maybe<
                        { __typename?: "CaseManager" } & Pick<CaseManager, "id"> & {
                            user?: Maybe<
                              { __typename?: "User" } & Pick<User, "id" | "name" | "primeDN"> & {
                                  person?: Maybe<
                                    { __typename?: "Person" } & Pick<
                                      Person,
                                      "id" | "name" | "avatarKeys" | "avatar"
                                    >
                                  >;
                                }
                            >;
                          }
                      >;
                      person?: Maybe<
                        { __typename?: "Person" } & Pick<
                          Person,
                          | "id"
                          | "flag"
                          | "lastActivity"
                          | "name"
                          | "mentalState"
                          | "vip"
                          | "avatarKeys"
                          | "avatar"
                        >
                      >;
                    }
                >
              >
            >;
            reviews?: Maybe<
              Array<
                Maybe<
                  { __typename?: "CaseManagementReview" } & Pick<
                    CaseManagementReview,
                    "id" | "lastUpdate"
                  > & {
                      caseManager?: Maybe<
                        { __typename?: "CaseManager" } & Pick<CaseManager, "id"> & {
                            user?: Maybe<
                              { __typename?: "User" } & Pick<User, "id" | "name" | "primeDN"> & {
                                  person?: Maybe<
                                    { __typename?: "Person" } & Pick<
                                      Person,
                                      "id" | "name" | "avatarKeys" | "avatar"
                                    >
                                  >;
                                }
                            >;
                          }
                      >;
                      caseManagementReviewAttributes?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: "CaseManagementReviewAttribute" } & Pick<
                              CaseManagementReviewAttribute,
                              "lastUpdate" | "attributeValue"
                            >
                          >
                        >
                      >;
                      user?: Maybe<
                        { __typename?: "User" } & Pick<User, "id" | "name" | "absentUntil"> & {
                            person?: Maybe<
                              { __typename?: "Person" } & Pick<Person, "id" | "alias" | "name">
                            >;
                          }
                      >;
                      client?: Maybe<
                        { __typename?: "Client" } & Pick<Client, "id"> & {
                            account?: Maybe<
                              { __typename?: "Account" } & Pick<Account, "id" | "name">
                            >;
                            topics?: Maybe<
                              Array<Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>>
                            >;
                            person?: Maybe<
                              { __typename?: "Person" } & Pick<
                                Person,
                                | "id"
                                | "flag"
                                | "name"
                                | "mentalState"
                                | "vip"
                                | "avatarKeys"
                                | "avatar"
                              >
                            >;
                          }
                      >;
                    }
                >
              >
            >;
          }
      >
    >
  >;
};

export type GetClientComplaintsQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetClientComplaintsQuery = { __typename?: "Query" } & {
  getClientComplaints?: Maybe<
    Array<
      Maybe<
        { __typename?: "ClientComplaint" } & Pick<
          ClientComplaint,
          "id" | "title" | "content" | "dateOfComplaint" | "createdAt" | "updatedAt"
        > & {
            user?: Maybe<
              { __typename?: "User" } & Pick<User, "id"> & {
                  person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
                }
            >;
          }
      >
    >
  >;
};

export type RiskHistoryQueryVariables = Exact<{
  personId: Scalars["Int"];
}>;

export type RiskHistoryQuery = { __typename?: "Query" } & {
  riskHistory?: Maybe<
    Array<
      Maybe<
        { __typename?: "ClientAttribute" } & Pick<
          ClientAttribute,
          "id" | "attributeValue" | "start" | "lastUpdate"
        > & {
            clientReviewAttribute?: Maybe<
              { __typename?: "ClientReviewAttribute" } & Pick<ClientReviewAttribute, "id">
            >;
            user?: Maybe<
              { __typename?: "User" } & Pick<User, "id"> & {
                  person?: Maybe<
                    { __typename?: "Person" } & Pick<
                      Person,
                      "id" | "name" | "alias" | "avatar" | "avatarKeys" | "picture"
                    >
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type AvailableLanguagesQueryVariables = Exact<{ [key: string]: never }>;

export type AvailableLanguagesQuery = { __typename?: "Query" } & {
  availableLanguages?: Maybe<
    Array<
      Maybe<{ __typename?: "LanguageViewModel" } & Pick<LanguageViewModel, "id" | "name" | "code">>
    >
  >;
};

export type BaseReportFragment = { __typename: "ConversationReport" } & Pick<
  ConversationReport,
  | "id"
  | "agreement"
  | "issue"
  | "mentalDescription"
  | "mentalState"
  | "reportType"
  | "status"
  | "summary"
  | "risk"
  | "riskInfo"
  | "cmCheckMessage"
  | "doneAt"
  | "lifeworksService"
  | "lifeworksServiceIssue"
  | "lifeworksServiceSubIssue"
>;

export type ReadReportFragment = { __typename: "ConversationReport" } & Pick<
  ConversationReport,
  | "id"
  | "agreement"
  | "issue"
  | "mentalDescription"
  | "mentalState"
  | "reportType"
  | "status"
  | "summary"
  | "risk"
  | "riskInfo"
  | "cmCheckMessage"
  | "doneAt"
  | "lifeworksService"
  | "lifeworksServiceIssue"
  | "lifeworksServiceSubIssue"
> & {
    author?: Maybe<
      { __typename: "Person" } & Pick<
        Person,
        "id" | "alias" | "name" | "avatar" | "avatarKeys" | "picture"
      >
    >;
    statistics?: Maybe<
      Array<
        Maybe<
          { __typename?: "StatisticItem" } & Pick<StatisticItem, "id" | "name"> & {
              group?: Maybe<
                { __typename?: "StatisticGroup" } & Pick<StatisticGroup, "id" | "name" | "type">
              >;
            }
        >
      >
    >;
    conversationReportReviewAttributes?: Maybe<
      Array<
        Maybe<
          { __typename?: "ConversationReportReviewAttribute" } & Pick<
            ConversationReportReviewAttribute,
            "id" | "attributeValue" | "clientReviewAttributeId"
          > & {
              clientReviewAttribute?: Maybe<
                { __typename?: "ClientReviewAttribute" } & Pick<
                  ClientReviewAttribute,
                  "id" | "label" | "name" | "type"
                >
              >;
            }
        >
      >
    >;
    clientAttributes?: Maybe<
      Array<
        Maybe<
          { __typename?: "ClientAttribute" } & Pick<
            ClientAttribute,
            "id" | "clientReviewAttributeId"
          >
        >
      >
    >;
    connectedServices?: Maybe<Array<Maybe<{ __typename?: "Service" } & Pick<Service, "id">>>>;
  };

export type ConversationReportQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type ConversationReportQuery = { __typename?: "Query" } & {
  conversationReport?: Maybe<
    { __typename: "ConversationReport" } & Pick<
      ConversationReport,
      | "id"
      | "agreement"
      | "issue"
      | "mentalDescription"
      | "mentalState"
      | "reportType"
      | "status"
      | "summary"
      | "risk"
      | "riskInfo"
      | "cmCheckMessage"
      | "doneAt"
      | "lifeworksService"
      | "lifeworksServiceIssue"
      | "lifeworksServiceSubIssue"
    > & {
        author?: Maybe<
          { __typename: "Person" } & Pick<
            Person,
            "id" | "alias" | "name" | "avatar" | "avatarKeys" | "picture"
          >
        >;
        personActivity?: Maybe<
          { __typename?: "PersonActivity" } & Pick<
            PersonActivity,
            "id" | "activityType" | "start" | "end"
          >
        >;
        client?: Maybe<
          { __typename: "Client" } & Pick<Client, "id" | "chatAlias"> & {
              person?: Maybe<
                { __typename: "Person" } & Pick<
                  Person,
                  | "id"
                  | "abnormalities"
                  | "age"
                  | "ageGuessed"
                  | "alias"
                  | "avatar"
                  | "avatarKeys"
                  | "picture"
                  | "birthDate"
                  | "familyStatus"
                  | "firstName"
                  | "gender"
                  | "lastName"
                  | "mentalState"
                  | "name"
                  | "note"
                  | "redFlag"
                  | "salut"
                  | "title"
                  | "vip"
                  | "yellowFlag"
                  | "temporary"
                > & {
                    extras?: Maybe<
                      { __typename?: "PersonExtraFieldType" } & Pick<
                        PersonExtraFieldType,
                        "informationSource" | "hrBusiness"
                      >
                    >;
                  }
              >;
            }
        >;
        topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
        services?: Maybe<
          Array<
            Maybe<
              { __typename?: "Service" } & Pick<Service, "id"> & {
                  serviceMeta?: Maybe<
                    { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">
                  >;
                }
            >
          >
        >;
        connectedServices?: Maybe<Array<Maybe<{ __typename?: "Service" } & Pick<Service, "id">>>>;
        activeClientReviewTemplate?: Maybe<
          { __typename?: "ClientReviewTemplate" } & Pick<
            ClientReviewTemplate,
            "id" | "isDefault" | "name" | "sortIdx"
          >
        >;
        activeClientReviewAttributeGroup?: Maybe<
          { __typename?: "ClientReviewAttributeGroup" } & Pick<
            ClientReviewAttributeGroup,
            "id" | "headingNew" | "headingUpdate" | "pageOrder"
          >
        >;
        conversationReportReviewAttributes?: Maybe<
          Array<
            Maybe<
              { __typename?: "ConversationReportReviewAttribute" } & Pick<
                ConversationReportReviewAttribute,
                "id" | "attributeValue"
              > & {
                  clientReviewAttribute?: Maybe<
                    { __typename?: "ClientReviewAttribute" } & Pick<
                      ClientReviewAttribute,
                      "id" | "label" | "name" | "placeholder" | "type"
                    > & {
                        clientReviewAttributeGroup?: Maybe<
                          { __typename?: "ClientReviewAttributeGroup" } & Pick<
                            ClientReviewAttributeGroup,
                            "id" | "headingNew" | "headingUpdate" | "pageOrder"
                          > & {
                              clientReviewTemplate?: Maybe<
                                { __typename?: "ClientReviewTemplate" } & Pick<
                                  ClientReviewTemplate,
                                  "id" | "isDefault" | "name" | "sortIdx"
                                >
                              >;
                            }
                        >;
                      }
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type ReadReportQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type ReadReportQuery = { __typename?: "Query" } & {
  conversationReport?: Maybe<
    { __typename: "ConversationReport" } & Pick<
      ConversationReport,
      | "id"
      | "agreement"
      | "issue"
      | "mentalDescription"
      | "mentalState"
      | "reportType"
      | "status"
      | "summary"
      | "risk"
      | "riskInfo"
      | "cmCheckMessage"
      | "doneAt"
      | "lifeworksService"
      | "lifeworksServiceIssue"
      | "lifeworksServiceSubIssue"
    > & {
        author?: Maybe<
          { __typename: "Person" } & Pick<
            Person,
            "id" | "alias" | "name" | "avatar" | "avatarKeys" | "picture"
          >
        >;
        personActivity?: Maybe<
          { __typename?: "PersonActivity" } & Pick<
            PersonActivity,
            "id" | "activityType" | "start" | "end"
          >
        >;
        topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
        conversationReportReviewAttributes?: Maybe<
          Array<
            Maybe<
              { __typename?: "ConversationReportReviewAttribute" } & Pick<
                ConversationReportReviewAttribute,
                "id" | "attributeValue" | "clientReviewAttributeId"
              > & {
                  clientReviewAttribute?: Maybe<
                    { __typename?: "ClientReviewAttribute" } & Pick<
                      ClientReviewAttribute,
                      "id" | "label" | "name" | "type"
                    >
                  >;
                }
            >
          >
        >;
        clientAttributes?: Maybe<
          Array<
            Maybe<
              { __typename?: "ClientAttribute" } & Pick<
                ClientAttribute,
                "id" | "clientReviewAttributeId"
              >
            >
          >
        >;
      }
  >;
};

export type SimpleReportsQueryVariables = Exact<{
  personId: Scalars["Int"];
  topicId?: InputMaybe<Scalars["Int"]>;
}>;

export type SimpleReportsQuery = { __typename?: "Query" } & {
  conversationReports?: Maybe<
    Array<Maybe<{ __typename?: "ConversationReport" } & Pick<ConversationReport, "id">>>
  >;
};

export type MandatesQueryVariables = Exact<{
  businessId: Scalars["Int"];
}>;

export type MandatesQuery = { __typename?: "Query" } & {
  mandates?: Maybe<
    Array<
      Maybe<
        { __typename?: "CounselingMandate" } & Pick<
          CounselingMandate,
          "id" | "sessionsAppointed" | "sessionsCompleted" | "referenceNumber"
        > & {
            counselor?: Maybe<
              { __typename: "Person" } & Pick<
                Person,
                | "id"
                | "salut"
                | "name"
                | "alias"
                | "age"
                | "ageGuessed"
                | "firstName"
                | "lastName"
                | "temporary"
                | "birthDate"
              >
            >;
            service?: Maybe<
              { __typename: "Service" } & Pick<
                Service,
                | "problem"
                | "id"
                | "duedate"
                | "wizardStatus"
                | "wizardStep"
                | "extendedInfo"
                | "status"
                | "createdAt"
                | "lastUpdate"
              > & {
                  client?: Maybe<
                    { __typename?: "Client" } & Pick<Client, "id"> & {
                        person?: Maybe<
                          { __typename: "Person" } & Pick<
                            Person,
                            | "id"
                            | "salut"
                            | "name"
                            | "alias"
                            | "age"
                            | "ageGuessed"
                            | "firstName"
                            | "lastName"
                            | "temporary"
                            | "birthDate"
                          >
                        >;
                        account?: Maybe<
                          { __typename?: "Account" } & Pick<Account, "id" | "freeSessions">
                        >;
                      }
                  >;
                  topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
                }
            >;
          }
      >
    >
  >;
};

export type MandateQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type MandateQuery = { __typename?: "Query" } & {
  mandate?: Maybe<
    { __typename?: "CounselingMandate" } & Pick<
      CounselingMandate,
      "id" | "sessionsAppointed" | "sessionsCompleted" | "referenceNumber"
    > & {
        counselor?: Maybe<
          { __typename: "Person" } & Pick<
            Person,
            | "id"
            | "salut"
            | "name"
            | "alias"
            | "age"
            | "ageGuessed"
            | "firstName"
            | "lastName"
            | "temporary"
            | "birthDate"
          >
        >;
        business?: Maybe<{ __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name">>;
        service?: Maybe<
          { __typename: "Service" } & Pick<
            Service,
            | "problem"
            | "id"
            | "duedate"
            | "wizardStatus"
            | "wizardStep"
            | "extendedInfo"
            | "status"
            | "createdAt"
            | "lastUpdate"
          > & {
              serviceMeta?: Maybe<
                { __typename: "ServiceMeta" } & Pick<
                  ServiceMeta,
                  "id" | "description" | "iconName" | "name" | "researchHint" | "type"
                > & {
                    serviceMetaAttributes?: Maybe<
                      Array<
                        Maybe<
                          { __typename: "ServiceMetaAttribute" } & Pick<
                            ServiceMetaAttribute,
                            | "id"
                            | "headline"
                            | "name"
                            | "type"
                            | "label"
                            | "placeholder"
                            | "defaults"
                            | "multiple"
                            | "required"
                            | "sortIdx"
                            | "contextData"
                          >
                        >
                      >
                    >;
                  }
              >;
              serviceAttributes?: Maybe<
                Array<
                  Maybe<
                    { __typename: "ServiceAttribute" } & Pick<
                      ServiceAttribute,
                      "id" | "value" | "order" | "serviceMetaAttributeId"
                    > & {
                        serviceMetaAttribute?: Maybe<
                          { __typename: "ServiceMetaAttribute" } & Pick<
                            ServiceMetaAttribute,
                            "id" | "name" | "type" | "label"
                          >
                        >;
                      }
                  >
                >
              >;
              client?: Maybe<
                { __typename?: "Client" } & Pick<Client, "id"> & {
                    person?: Maybe<
                      { __typename: "Person" } & Pick<
                        Person,
                        | "id"
                        | "salut"
                        | "name"
                        | "alias"
                        | "age"
                        | "ageGuessed"
                        | "firstName"
                        | "lastName"
                        | "temporary"
                        | "birthDate"
                      >
                    >;
                    account?: Maybe<
                      { __typename?: "Account" } & Pick<Account, "id" | "freeSessions">
                    >;
                  }
              >;
              topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
            }
        >;
      }
  >;
};

export type MandateByServiceQueryVariables = Exact<{
  serviceId: Scalars["Int"];
}>;

export type MandateByServiceQuery = { __typename?: "Query" } & {
  mandateByService?: Maybe<
    { __typename?: "CounselingMandate" } & Pick<
      CounselingMandate,
      "id" | "sessionsAppointed" | "sessionsCompleted" | "referenceNumber"
    > & {
        counselor?: Maybe<
          { __typename: "Person" } & Pick<
            Person,
            | "id"
            | "salut"
            | "name"
            | "alias"
            | "age"
            | "ageGuessed"
            | "firstName"
            | "lastName"
            | "temporary"
            | "birthDate"
          >
        >;
        business?: Maybe<{ __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name">>;
        service?: Maybe<
          { __typename: "Service" } & Pick<
            Service,
            | "problem"
            | "id"
            | "duedate"
            | "wizardStatus"
            | "wizardStep"
            | "extendedInfo"
            | "status"
            | "createdAt"
            | "lastUpdate"
          > & {
              serviceMeta?: Maybe<
                { __typename: "ServiceMeta" } & Pick<
                  ServiceMeta,
                  "id" | "description" | "iconName" | "name" | "researchHint" | "type"
                > & {
                    serviceMetaAttributes?: Maybe<
                      Array<
                        Maybe<
                          { __typename: "ServiceMetaAttribute" } & Pick<
                            ServiceMetaAttribute,
                            | "id"
                            | "headline"
                            | "name"
                            | "type"
                            | "label"
                            | "placeholder"
                            | "defaults"
                            | "multiple"
                            | "required"
                            | "sortIdx"
                            | "contextData"
                          >
                        >
                      >
                    >;
                  }
              >;
              serviceAttributes?: Maybe<
                Array<
                  Maybe<
                    { __typename: "ServiceAttribute" } & Pick<
                      ServiceAttribute,
                      "id" | "value" | "order" | "serviceMetaAttributeId"
                    > & {
                        serviceMetaAttribute?: Maybe<
                          { __typename: "ServiceMetaAttribute" } & Pick<
                            ServiceMetaAttribute,
                            "id" | "name" | "type" | "label"
                          >
                        >;
                      }
                  >
                >
              >;
              client?: Maybe<
                { __typename?: "Client" } & Pick<Client, "id"> & {
                    person?: Maybe<
                      { __typename: "Person" } & Pick<
                        Person,
                        | "id"
                        | "salut"
                        | "name"
                        | "alias"
                        | "age"
                        | "ageGuessed"
                        | "firstName"
                        | "lastName"
                        | "temporary"
                        | "birthDate"
                      >
                    >;
                    account?: Maybe<
                      { __typename?: "Account" } & Pick<Account, "id" | "freeSessions">
                    >;
                  }
              >;
              topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
            }
        >;
      }
  >;
};

export type AllMandatesQueryVariables = Exact<{
  active: Scalars["Boolean"];
}>;

export type AllMandatesQuery = { __typename?: "Query" } & {
  allMandates?: Maybe<
    Array<
      Maybe<
        { __typename?: "CounselingMandate" } & Pick<
          CounselingMandate,
          "id" | "sessionsAppointed" | "sessionsCompleted" | "referenceNumber" | "lastActivity"
        > & {
            counselor?: Maybe<
              { __typename?: "Person" } & Pick<
                Person,
                "id" | "name" | "picture" | "avatar" | "avatarKeys"
              > & {
                  businesses?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name"> & {
                            address?: Maybe<
                              { __typename?: "Address" } & Pick<
                                Address,
                                "id" | "type" | "street" | "city" | "zip"
                              >
                            >;
                          }
                      >
                    >
                  >;
                }
            >;
            service?: Maybe<
              { __typename?: "Service" } & Pick<Service, "id"> & {
                  topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
                  client?: Maybe<
                    { __typename?: "Client" } & Pick<Client, "id"> & {
                        account?: Maybe<{ __typename?: "Account" } & Pick<Account, "id" | "name">>;
                        person?: Maybe<
                          { __typename?: "Person" } & Pick<
                            Person,
                            "id" | "flag" | "name" | "mentalState" | "avatarKeys"
                          >
                        >;
                      }
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type DepartmentsQueryVariables = Exact<{
  accountId: Scalars["Int"];
}>;

export type DepartmentsQuery = { __typename?: "Query" } & {
  departments: Array<
    { __typename?: "Department" } & Pick<Department, "id" | "accountId" | "name" | "main">
  >;
};

export type GetDriveMetaDataQueryVariables = Exact<{
  fileId: Scalars["String"];
}>;

export type GetDriveMetaDataQuery = { __typename?: "Query" } & Pick<Query, "getDriveMetaData">;

export type GetDriveDocumentsQueryVariables = Exact<{
  personIds: Array<Scalars["Int"]> | Scalars["Int"];
}>;

export type GetDriveDocumentsQuery = { __typename?: "Query" } & {
  getDriveDocuments: Array<
    { __typename?: "DriveDocument" } & Pick<DriveDocument, "id" | "title" | "link" | "created"> & {
        user?: Maybe<{ __typename?: "User" } & Pick<User, "id" | "name">>;
      }
  >;
};

export type GMailMessageFragment = { __typename: "GMailMessage" } & Pick<
  GMailMessage,
  "id" | "incoming" | "historyId" | "messageId" | "threadId" | "body" | "date"
> & {
    attachments: Array<
      { __typename?: "Attachment" } & Pick<Attachment, "attachmentId" | "filename" | "mimeType">
    >;
    payload?: Maybe<
      { __typename?: "Payload" } & Pick<Payload, "from" | "to" | "subject" | "returnPath">
    >;
  };

export type GetGMailThreadsQueryVariables = Exact<{ [key: string]: never }>;

export type GetGMailThreadsQuery = { __typename?: "Query" } & {
  getGMailThreads: Array<
    { __typename?: "GMailMessage" } & Pick<
      GMailMessage,
      "id" | "incoming" | "historyId" | "messageId" | "threadId" | "body" | "date"
    > & {
        attachments: Array<
          { __typename?: "Attachment" } & Pick<Attachment, "attachmentId" | "filename" | "mimeType">
        >;
        payload?: Maybe<
          { __typename?: "Payload" } & Pick<Payload, "from" | "to" | "subject" | "returnPath">
        >;
        personActivity?: Maybe<
          { __typename?: "PersonActivity" } & Pick<
            PersonActivity,
            "id" | "activityType" | "start" | "end"
          > & {
              user?: Maybe<
                { __typename?: "User" } & Pick<User, "id"> & {
                    person?: Maybe<
                      { __typename?: "Person" } & Pick<
                        Person,
                        "id" | "avatar" | "avatarKeys" | "picture"
                      >
                    >;
                  }
              >;
            }
        >;
        gMailInbox?: Maybe<{ __typename?: "GMailInbox" } & Pick<GMailInbox, "id" | "email">>;
        user?: Maybe<
          { __typename?: "User" } & Pick<User, "id"> & {
              person?: Maybe<
                { __typename?: "Person" } & Pick<
                  Person,
                  "id" | "name" | "avatar" | "avatarKeys" | "picture" | "mentalState" | "temporary"
                > & {
                    emails?: Maybe<Array<Maybe<{ __typename?: "Email" } & Pick<Email, "email">>>>;
                  }
              >;
            }
        >;
        businessObject?: Maybe<
          { __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name"> & {
              email?: Maybe<{ __typename?: "Email" } & Pick<Email, "id" | "email">>;
            }
        >;
        person?: Maybe<
          { __typename?: "Person" } & Pick<
            Person,
            "id" | "name" | "avatar" | "avatarKeys" | "mentalState" | "temporary"
          > & { emails?: Maybe<Array<Maybe<{ __typename?: "Email" } & Pick<Email, "email">>>> }
        >;
      }
  >;
};

export type GetOneGMailThreadQueryVariables = Exact<{
  threadId: Scalars["String"];
}>;

export type GetOneGMailThreadQuery = { __typename?: "Query" } & {
  getOneGMailThread: { __typename?: "GMailThread" } & {
    topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
    personActivity?: Maybe<
      { __typename: "PersonActivity" } & Pick<
        PersonActivity,
        "id" | "activityType" | "start" | "end"
      > & {
          user?: Maybe<
            { __typename?: "User" } & Pick<User, "id"> & {
                person?: Maybe<
                  { __typename?: "Person" } & Pick<
                    Person,
                    "id" | "avatar" | "avatarKeys" | "picture"
                  >
                >;
              }
          >;
        }
    >;
    emails: Array<
      { __typename?: "GMailMessage" } & Pick<
        GMailMessage,
        "id" | "incoming" | "historyId" | "messageId" | "threadId" | "body" | "date"
      > & {
          attachments: Array<
            { __typename?: "Attachment" } & Pick<
              Attachment,
              "attachmentId" | "filename" | "mimeType"
            >
          >;
          payload?: Maybe<
            { __typename?: "Payload" } & Pick<Payload, "from" | "to" | "subject" | "returnPath">
          >;
          gMailInbox?: Maybe<{ __typename?: "GMailInbox" } & Pick<GMailInbox, "id" | "email">>;
          user?: Maybe<
            { __typename?: "User" } & Pick<User, "id"> & {
                person?: Maybe<
                  { __typename?: "Person" } & Pick<
                    Person,
                    | "id"
                    | "name"
                    | "avatar"
                    | "avatarKeys"
                    | "picture"
                    | "mentalState"
                    | "temporary"
                  > & {
                      emails?: Maybe<Array<Maybe<{ __typename?: "Email" } & Pick<Email, "email">>>>;
                    }
                >;
              }
          >;
          businessObject?: Maybe<
            { __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name"> & {
                email?: Maybe<{ __typename?: "Email" } & Pick<Email, "id" | "email">>;
              }
          >;
          person?: Maybe<
            { __typename?: "Person" } & Pick<
              Person,
              "id" | "name" | "avatar" | "avatarKeys" | "mentalState" | "temporary"
            > & { emails?: Maybe<Array<Maybe<{ __typename?: "Email" } & Pick<Email, "email">>>> }
          >;
          personActivity?: Maybe<
            { __typename?: "PersonActivity" } & Pick<
              PersonActivity,
              "id" | "activityType" | "start" | "end"
            > & {
                user?: Maybe<
                  { __typename?: "User" } & Pick<User, "id"> & {
                      person?: Maybe<
                        { __typename?: "Person" } & Pick<
                          Person,
                          "id" | "avatar" | "avatarKeys" | "picture"
                        >
                      >;
                    }
                >;
              }
          >;
        }
    >;
  };
};

export type GetOneThreadByActivityQueryVariables = Exact<{
  personActivityId: Scalars["Int"];
}>;

export type GetOneThreadByActivityQuery = { __typename?: "Query" } & {
  getOneThreadByActivity: Array<
    { __typename?: "GMailMessage" } & Pick<
      GMailMessage,
      "id" | "incoming" | "historyId" | "messageId" | "threadId" | "body" | "date"
    > & {
        attachments: Array<
          { __typename?: "Attachment" } & Pick<Attachment, "attachmentId" | "filename" | "mimeType">
        >;
        payload?: Maybe<
          { __typename?: "Payload" } & Pick<Payload, "from" | "to" | "subject" | "returnPath">
        >;
        user?: Maybe<
          { __typename?: "User" } & Pick<User, "id"> & {
              person?: Maybe<
                { __typename?: "Person" } & Pick<
                  Person,
                  "id" | "name" | "avatar" | "avatarKeys" | "picture" | "mentalState" | "temporary"
                > & {
                    emails?: Maybe<Array<Maybe<{ __typename?: "Email" } & Pick<Email, "email">>>>;
                  }
              >;
            }
        >;
        businessObject?: Maybe<
          { __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name"> & {
              email?: Maybe<{ __typename?: "Email" } & Pick<Email, "id" | "email">>;
            }
        >;
        person?: Maybe<
          { __typename?: "Person" } & Pick<
            Person,
            "id" | "name" | "avatar" | "avatarKeys" | "mentalState" | "temporary"
          >
        >;
        personActivity?: Maybe<
          { __typename?: "PersonActivity" } & Pick<
            PersonActivity,
            "id" | "activityType" | "start" | "end"
          >
        >;
      }
  >;
};

export type GetAttachmentDataQueryVariables = Exact<{
  id: Scalars["String"];
  messageId: Scalars["String"];
}>;

export type GetAttachmentDataQuery = { __typename?: "Query" } & Pick<Query, "getAttachmentData">;

export type IndustriesQueryVariables = Exact<{ [key: string]: never }>;

export type IndustriesQuery = { __typename?: "Query" } & {
  industries?: Maybe<Array<Maybe<{ __typename?: "Industry" } & Pick<Industry, "id" | "name">>>>;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = { __typename?: "Query" } & {
  me?: Maybe<
    { __typename?: "Person" } & Pick<
      Person,
      "id" | "avatar" | "avatarKeys" | "picture" | "firstName" | "name" | "age" | "role"
    > & {
        addresses?: Maybe<
          Array<{ __typename?: "Address" } & Pick<Address, "id" | "street" | "city" | "zip">>
        >;
        papershiftWorkingAreas?: Maybe<
          Array<
            Maybe<
              { __typename?: "PapershiftWorkingArea" } & Pick<PapershiftWorkingArea, "id" | "name">
            >
          >
        >;
        user?: Maybe<
          { __typename: "User" } & Pick<
            User,
            "defaultTeam" | "teams" | "id" | "active" | "name" | "absentUntil" | "primeDN"
          > & {
              person?: Maybe<
                { __typename: "Person" } & Pick<
                  Person,
                  | "id"
                  | "abnormalities"
                  | "age"
                  | "ageGuessed"
                  | "alias"
                  | "avatar"
                  | "avatarKeys"
                  | "picture"
                  | "birthDate"
                  | "familyStatus"
                  | "firstName"
                  | "gender"
                  | "lastName"
                  | "mentalState"
                  | "name"
                  | "note"
                  | "redFlag"
                  | "salut"
                  | "title"
                  | "vip"
                  | "yellowFlag"
                  | "temporary"
                > & {
                    extras?: Maybe<
                      { __typename?: "PersonExtraFieldType" } & Pick<
                        PersonExtraFieldType,
                        "informationSource" | "hrBusiness"
                      >
                    >;
                  }
              >;
              papershiftWorkingAreas?: Maybe<
                Array<
                  Maybe<
                    { __typename: "PapershiftWorkingArea" } & Pick<
                      PapershiftWorkingArea,
                      "id" | "name" | "color"
                    >
                  >
                >
              >;
              hotDesks?: Maybe<
                Array<
                  Maybe<
                    { __typename?: "HotDesk" } & {
                      extension?: Maybe<{ __typename?: "Extension" } & Pick<Extension, "primeDN">>;
                    }
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type MemoFragment = { __typename: "Memo" } & Pick<
  Memo,
  "id" | "note" | "contact" | "status" | "type" | "doneAt"
> & {
    topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
    person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
    business?: Maybe<
      { __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name" | "accountId">
    >;
  };

export type MemoQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type MemoQuery = { __typename?: "Query" } & {
  memo?: Maybe<
    { __typename: "Memo" } & Pick<
      Memo,
      "id" | "note" | "contact" | "status" | "type" | "doneAt"
    > & {
        personActivity?: Maybe<
          { __typename?: "PersonActivity" } & Pick<
            PersonActivity,
            "id" | "activityType" | "start" | "end"
          > & { person: { __typename?: "Person" } & Pick<Person, "id" | "name"> }
        >;
        topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
        person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
        business?: Maybe<
          { __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name" | "accountId">
        >;
      }
  >;
};

export type ObservableJobQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type ObservableJobQuery = { __typename?: "Query" } & {
  observableJob?: Maybe<
    { __typename?: "ObservableJob" } & Pick<ObservableJob, "status" | "id" | "link" | "error">
  >;
};

export type PapershiftShiftFragment = { __typename: "PapershiftShift" } & Pick<
  PapershiftShift,
  "id" | "startsAt" | "endsAt"
>;

export type PapershiftWorkingAreaFragment = { __typename: "PapershiftWorkingArea" } & Pick<
  PapershiftWorkingArea,
  "id" | "name" | "color"
>;

export type PapershiftShiftsQueryVariables = Exact<{
  startsAt: Scalars["String"];
  endsAt: Scalars["String"];
}>;

export type PapershiftShiftsQuery = { __typename?: "Query" } & {
  papershiftShifts?: Maybe<
    Array<
      Maybe<
        { __typename: "PapershiftShift" } & Pick<PapershiftShift, "id" | "startsAt" | "endsAt"> & {
            papershiftWorkingArea?: Maybe<
              { __typename: "PapershiftWorkingArea" } & Pick<
                PapershiftWorkingArea,
                "color" | "id" | "name"
              >
            >;
            users?: Maybe<
              Array<
                Maybe<
                  { __typename: "User" } & Pick<
                    User,
                    "id" | "active" | "name" | "absentUntil" | "primeDN"
                  > & {
                      papershiftWorkingAreas?: Maybe<
                        Array<
                          Maybe<
                            { __typename: "PapershiftWorkingArea" } & Pick<
                              PapershiftWorkingArea,
                              "id" | "name" | "color"
                            >
                          >
                        >
                      >;
                      person?: Maybe<
                        { __typename?: "Person" } & Pick<
                          Person,
                          "id" | "alias" | "name" | "avatar" | "avatarKeys" | "picture"
                        >
                      >;
                      hotDesks?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: "HotDesk" } & {
                              extension?: Maybe<
                                { __typename?: "Extension" } & Pick<Extension, "primeDN">
                              >;
                            }
                          >
                        >
                      >;
                    }
                >
              >
            >;
          }
      >
    >
  >;
};

export type PapershiftWorkingAreasQueryVariables = Exact<{ [key: string]: never }>;

export type PapershiftWorkingAreasQuery = { __typename?: "Query" } & {
  papershiftWorkingAreas?: Maybe<
    Array<
      Maybe<
        { __typename: "PapershiftWorkingArea" } & Pick<
          PapershiftWorkingArea,
          "id" | "name" | "color"
        >
      >
    >
  >;
};

export type PersonFragment = { __typename: "Person" } & Pick<
  Person,
  | "id"
  | "abnormalities"
  | "age"
  | "ageGuessed"
  | "alias"
  | "avatar"
  | "avatarKeys"
  | "picture"
  | "birthDate"
  | "familyStatus"
  | "firstName"
  | "gender"
  | "lastName"
  | "mentalState"
  | "name"
  | "note"
  | "redFlag"
  | "salut"
  | "title"
  | "vip"
  | "yellowFlag"
  | "temporary"
> & {
    extras?: Maybe<
      { __typename?: "PersonExtraFieldType" } & Pick<
        PersonExtraFieldType,
        "informationSource" | "hrBusiness"
      >
    >;
  };

export type UserPersonFragment = { __typename: "Person" } & Pick<
  Person,
  "id" | "alias" | "name" | "avatar" | "avatarKeys" | "picture"
>;

export type BasePersonFragment = { __typename: "Person" } & Pick<
  Person,
  | "id"
  | "salut"
  | "name"
  | "alias"
  | "age"
  | "ageGuessed"
  | "firstName"
  | "lastName"
  | "temporary"
  | "birthDate"
>;

export type BusinessPersonFragment = { __typename: "Person" } & Pick<
  Person,
  | "id"
  | "name"
  | "alias"
  | "firstName"
  | "lastName"
  | "temporary"
  | "gender"
  | "title"
  | "note"
  | "salut"
  | "avatarKeys"
>;

export type ClientPersonFragment = { __typename: "Person" } & Pick<
  Person,
  | "id"
  | "name"
  | "alias"
  | "ageGuessed"
  | "age"
  | "salut"
  | "avatar"
  | "avatarKeys"
  | "mentalState"
  | "yellowFlag"
  | "redFlag"
  | "vip"
>;

export type CounselorPersonFragment = { __typename: "Person" } & Pick<
  Person,
  "id" | "name" | "salut" | "avatarKeys" | "picture" | "title" | "firstName" | "lastName" | "gender"
>;

export type PersonByChatAliasQueryVariables = Exact<{
  chatAlias: Scalars["String"];
}>;

export type PersonByChatAliasQuery = { __typename?: "Query" } & {
  personByChatAlias?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
};

export type ReportPersonQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type ReportPersonQuery = { __typename?: "Query" } & {
  person: { __typename: "Person" } & Pick<
    Person,
    | "id"
    | "name"
    | "alias"
    | "ageGuessed"
    | "age"
    | "salut"
    | "avatar"
    | "avatarKeys"
    | "mentalState"
    | "yellowFlag"
    | "redFlag"
    | "vip"
  > & {
      client?: Maybe<
        { __typename?: "Client" } & Pick<Client, "id" | "reviewRequired"> & {
            topics?: Maybe<Array<Maybe<{ __typename?: "Topic" } & Pick<Topic, "name" | "id">>>>;
            account?: Maybe<{ __typename?: "Account" } & Pick<Account, "id" | "type">>;
          }
      >;
    };
};

export type PersonQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type PersonQuery = { __typename?: "Query" } & {
  person: { __typename: "Person" } & Pick<
    Person,
    | "id"
    | "abnormalities"
    | "age"
    | "ageGuessed"
    | "alias"
    | "avatar"
    | "avatarKeys"
    | "picture"
    | "birthDate"
    | "familyStatus"
    | "firstName"
    | "gender"
    | "lastName"
    | "mentalState"
    | "name"
    | "note"
    | "redFlag"
    | "salut"
    | "title"
    | "vip"
    | "yellowFlag"
    | "temporary"
  > & {
      emails?: Maybe<
        Array<
          Maybe<{ __typename: "Email" } & Pick<Email, "id" | "type" | "contactAllowed" | "email">>
        >
      >;
      addresses?: Maybe<
        Array<
          { __typename: "Address" } & Pick<
            Address,
            "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
          >
        >
      >;
      phones?: Maybe<
        Array<
          Maybe<
            { __typename: "Phone" } & Pick<
              Phone,
              | "id"
              | "type"
              | "contactAllowed"
              | "messagingAllowed"
              | "countryCode"
              | "areaCode"
              | "number"
              | "readableNumber"
            >
          >
        >
      >;
      relations?: Maybe<
        Array<
          Maybe<
            { __typename?: "Relationship" } & Pick<
              Relationship,
              "id" | "relationship" | "isEmergency" | "isHousehold"
            > & {
                passivePerson?: Maybe<
                  { __typename: "Person" } & Pick<
                    Person,
                    | "id"
                    | "abnormalities"
                    | "age"
                    | "ageGuessed"
                    | "alias"
                    | "avatar"
                    | "avatarKeys"
                    | "picture"
                    | "birthDate"
                    | "familyStatus"
                    | "firstName"
                    | "gender"
                    | "lastName"
                    | "mentalState"
                    | "name"
                    | "note"
                    | "redFlag"
                    | "salut"
                    | "title"
                    | "vip"
                    | "yellowFlag"
                    | "temporary"
                  > & {
                      client?: Maybe<{ __typename?: "Client" } & Pick<Client, "id">>;
                      addresses?: Maybe<
                        Array<
                          { __typename: "Address" } & Pick<
                            Address,
                            | "id"
                            | "street"
                            | "city"
                            | "zip"
                            | "lat"
                            | "lon"
                            | "type"
                            | "contactAllowed"
                          >
                        >
                      >;
                      phones?: Maybe<
                        Array<
                          Maybe<
                            { __typename: "Phone" } & Pick<
                              Phone,
                              | "id"
                              | "type"
                              | "contactAllowed"
                              | "messagingAllowed"
                              | "countryCode"
                              | "areaCode"
                              | "number"
                              | "readableNumber"
                            >
                          >
                        >
                      >;
                      extras?: Maybe<
                        { __typename?: "PersonExtraFieldType" } & Pick<
                          PersonExtraFieldType,
                          "informationSource" | "hrBusiness"
                        >
                      >;
                    }
                >;
              }
          >
        >
      >;
      client?: Maybe<
        { __typename?: "Client" } & Pick<
          Client,
          | "id"
          | "clientType"
          | "departmentId"
          | "ucmsId"
          | "job"
          | "jobBand"
          | "language"
          | "chatUserId"
          | "chatAlias"
          | "vip"
          | "healthInsuranceCompany"
          | "sourceOfInformation"
          | "hrBusiness"
          | "source"
          | "assistanceId"
          | "yearOfEntry"
        > & {
            department?: Maybe<
              { __typename?: "Department" } & Pick<Department, "name" | "id" | "main">
            >;
            contactLanguage?: Maybe<
              { __typename?: "Language" } & Pick<Language, "id" | "code" | "name">
            >;
            businessUnit?: Maybe<
              { __typename?: "BusinessUnit" } & Pick<BusinessUnit, "id" | "name">
            >;
            account?: Maybe<
              { __typename?: "Account" } & Pick<
                Account,
                | "id"
                | "name"
                | "isAssistance"
                | "tariff"
                | "type"
                | "validationPattern"
                | "freeSessions"
                | "contractEnd"
              > & {
                  businessUnits?: Maybe<
                    Array<
                      Maybe<{ __typename?: "BusinessUnit" } & Pick<BusinessUnit, "id" | "name">>
                    >
                  >;
                }
            >;
            topics?: Maybe<Array<Maybe<{ __typename?: "Topic" } & Pick<Topic, "name" | "id">>>>;
          }
      >;
      extras?: Maybe<
        { __typename?: "PersonExtraFieldType" } & Pick<
          PersonExtraFieldType,
          "informationSource" | "hrBusiness"
        >
      >;
    };
};

export type PersonsAdvancedQueryVariables = Exact<{
  accountId?: InputMaybe<Scalars["Float"]>;
  name: Scalars["String"];
}>;

export type PersonsAdvancedQuery = { __typename?: "Query" } & {
  personsAdvanced: Array<
    { __typename?: "Person" } & Pick<Person, "id" | "name" | "age" | "ageGuessed">
  >;
};

export type PersonSearchQueryVariables = Exact<{
  name: Scalars["String"];
}>;

export type PersonSearchQuery = { __typename?: "Query" } & {
  personSearch: { __typename?: "PersonSearchPayload" } & {
    accounts?: Maybe<Array<Maybe<{ __typename?: "Account" } & Pick<Account, "id" | "name">>>>;
    businesses?: Maybe<
      Array<Maybe<{ __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name">>>
    >;
    persons?: Maybe<
      Array<
        Maybe<
          { __typename?: "Person" } & Pick<Person, "id" | "name" | "age" | "ageGuessed"> & {
              businesses?: Maybe<
                Array<
                  Maybe<{ __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name">>
                >
              >;
              client?: Maybe<
                { __typename?: "Client" } & Pick<Client, "id"> & {
                    account?: Maybe<
                      { __typename?: "Account" } & Pick<Account, "id" | "name" | "contractEnd">
                    >;
                  }
              >;
            }
        >
      >
    >;
  };
};

export type ClientSearchQueryVariables = Exact<{
  name: Scalars["String"];
}>;

export type ClientSearchQuery = { __typename?: "Query" } & {
  clientSearch: { __typename?: "PersonSearchPayload" } & {
    accounts?: Maybe<Array<Maybe<{ __typename?: "Account" } & Pick<Account, "id" | "name">>>>;
    businesses?: Maybe<
      Array<Maybe<{ __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name">>>
    >;
    persons?: Maybe<
      Array<
        Maybe<
          { __typename?: "Person" } & Pick<Person, "id" | "name" | "age" | "ageGuessed"> & {
              businesses?: Maybe<
                Array<
                  Maybe<{ __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name">>
                >
              >;
              client?: Maybe<
                { __typename?: "Client" } & Pick<Client, "id"> & {
                    account?: Maybe<
                      { __typename?: "Account" } & Pick<Account, "id" | "name" | "contractEnd">
                    >;
                  }
              >;
            }
        >
      >
    >;
  };
};

export type PersonSearchWithClientQueryVariables = Exact<{
  name: Scalars["String"];
}>;

export type PersonSearchWithClientQuery = { __typename?: "Query" } & {
  personSearchWithClient: { __typename?: "PersonSearchPayload" } & {
    accounts?: Maybe<Array<Maybe<{ __typename?: "Account" } & Pick<Account, "id" | "name">>>>;
    businesses?: Maybe<
      Array<Maybe<{ __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name">>>
    >;
    persons?: Maybe<
      Array<
        Maybe<
          { __typename?: "Person" } & Pick<Person, "id" | "name" | "age" | "ageGuessed"> & {
              businesses?: Maybe<
                Array<
                  Maybe<{ __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name">>
                >
              >;
              client?: Maybe<
                { __typename?: "Client" } & Pick<Client, "id"> & {
                    account?: Maybe<
                      { __typename?: "Account" } & Pick<Account, "id" | "name" | "contractEnd">
                    >;
                  }
              >;
            }
        >
      >
    >;
  };
};

export type PersonsByBusinessQueryVariables = Exact<{
  businessId: Scalars["Int"];
}>;

export type PersonsByBusinessQuery = { __typename?: "Query" } & {
  personsByBusiness: Array<
    { __typename: "Person" } & Pick<
      Person,
      | "id"
      | "name"
      | "alias"
      | "firstName"
      | "lastName"
      | "temporary"
      | "gender"
      | "title"
      | "note"
      | "salut"
      | "avatarKeys"
    > & {
        emails?: Maybe<
          Array<
            Maybe<{ __typename: "Email" } & Pick<Email, "id" | "type" | "contactAllowed" | "email">>
          >
        >;
        addresses?: Maybe<
          Array<
            { __typename: "Address" } & Pick<
              Address,
              "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
            >
          >
        >;
        phones?: Maybe<
          Array<
            Maybe<
              { __typename: "Phone" } & Pick<
                Phone,
                | "id"
                | "type"
                | "contactAllowed"
                | "messagingAllowed"
                | "countryCode"
                | "areaCode"
                | "number"
                | "readableNumber"
              >
            >
          >
        >;
      }
  >;
};

export type BreadCrumbInfoByPersonIdQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type BreadCrumbInfoByPersonIdQuery = { __typename?: "Query" } & {
  breadCrumbInfoByPersonId?: Maybe<
    { __typename?: "BreadCrumbStatusDetail" } & Pick<
      BreadCrumbStatusDetail,
      "contractEnd" | "language"
    >
  >;
};

export type GetMemoForClientByPersonIdQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetMemoForClientByPersonIdQuery = { __typename?: "Query" } & Pick<
  Query,
  "getMemoForClientByPersonId"
>;

export type ConversationReportFragment = { __typename: "ConversationReport" } & Pick<
  ConversationReport,
  "id" | "finalizedAt" | "mentalState" | "issue"
>;

export type ActivityFragment = { __typename: "PersonActivity" } & Pick<
  PersonActivity,
  | "id"
  | "activityType"
  | "description"
  | "end"
  | "isActive"
  | "path"
  | "start"
  | "subtitle"
  | "lastUpdate"
  | "tempPerson"
>;

export type AuthorFragment = { __typename: "Person" } & Pick<
  Person,
  "id" | "alias" | "name" | "avatarKeys" | "avatar" | "picture"
>;

export type TaskFragment = { __typename: "Task" } & Pick<
  Task,
  "id" | "taskType" | "startedAt" | "finishedAt" | "userId"
>;

export type PersonActivitiesQueryVariables = Exact<{
  personId: Scalars["Int"];
  contactId?: InputMaybe<Scalars["Int"]>;
  topicId?: InputMaybe<Scalars["Int"]>;
  serviceId?: InputMaybe<Scalars["Int"]>;
  activityType?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
}>;

export type PersonActivitiesQuery = { __typename?: "Query" } & {
  personActivities: Array<
    { __typename: "PersonActivity" } & Pick<
      PersonActivity,
      | "id"
      | "activityType"
      | "description"
      | "end"
      | "isActive"
      | "path"
      | "start"
      | "subtitle"
      | "lastUpdate"
      | "tempPerson"
    > & {
        author?: Maybe<
          { __typename: "Person" } & Pick<
            Person,
            "id" | "alias" | "name" | "avatarKeys" | "avatar" | "picture"
          >
        >;
        conversationReport?: Maybe<
          { __typename: "ConversationReport" } & Pick<
            ConversationReport,
            "id" | "finalizedAt" | "mentalState" | "issue"
          >
        >;
        topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
        tasks?: Maybe<
          Array<
            Maybe<
              { __typename: "Task" } & Pick<
                Task,
                "id" | "taskType" | "startedAt" | "finishedAt" | "userId"
              >
            >
          >
        >;
        memo?: Maybe<
          { __typename: "Memo" } & Pick<
            Memo,
            "id" | "note" | "contact" | "status" | "type" | "doneAt"
          > & {
              topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
              person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
              business?: Maybe<
                { __typename?: "BusinessObject" } & Pick<
                  BusinessObject,
                  "id" | "name" | "accountId"
                >
              >;
            }
        >;
        service?: Maybe<
          { __typename: "Service" } & Pick<
            Service,
            | "id"
            | "duedate"
            | "wizardStatus"
            | "wizardStep"
            | "extendedInfo"
            | "status"
            | "createdAt"
            | "lastUpdate"
          > & {
              serviceMeta?: Maybe<
                { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name" | "type">
              >;
            }
        >;
      }
  >;
};

export type BusinessActivitiesQueryVariables = Exact<{
  personIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>> | InputMaybe<Scalars["Int"]>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
}>;

export type BusinessActivitiesQuery = { __typename?: "Query" } & {
  businessActivities?: Maybe<
    Array<
      Maybe<
        { __typename: "PersonActivity" } & Pick<
          PersonActivity,
          | "personId"
          | "id"
          | "activityType"
          | "description"
          | "end"
          | "isActive"
          | "path"
          | "start"
          | "subtitle"
          | "lastUpdate"
          | "tempPerson"
        > & {
            author?: Maybe<
              { __typename: "Person" } & Pick<
                Person,
                "id" | "alias" | "name" | "avatarKeys" | "avatar" | "picture"
              >
            >;
            memo?: Maybe<
              { __typename: "Memo" } & Pick<
                Memo,
                "id" | "note" | "contact" | "status" | "type" | "doneAt"
              > & {
                  topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
                  person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
                  business?: Maybe<
                    { __typename?: "BusinessObject" } & Pick<
                      BusinessObject,
                      "id" | "name" | "accountId"
                    >
                  >;
                }
            >;
            person: { __typename?: "Person" } & Pick<
              Person,
              "businessIds" | "name" | "id" | "yellowFlag" | "redFlag" | "temporary"
            > & {
                emails?: Maybe<
                  Array<Maybe<{ __typename?: "Email" } & Pick<Email, "id" | "email">>>
                >;
                client?: Maybe<
                  { __typename?: "Client" } & Pick<Client, "id" | "vip"> & {
                      account?: Maybe<{ __typename?: "Account" } & Pick<Account, "id" | "name">>;
                    }
                >;
              };
            serviceResearchObject?: Maybe<
              { __typename: "ServiceResearchObject" } & Pick<
                ServiceResearchObject,
                "id" | "descriptor" | "info" | "note" | "status" | "statusChangeReason"
              > & {
                  contactPerson?: Maybe<
                    { __typename: "Person" } & Pick<
                      Person,
                      | "id"
                      | "salut"
                      | "name"
                      | "alias"
                      | "age"
                      | "ageGuessed"
                      | "firstName"
                      | "lastName"
                      | "temporary"
                      | "birthDate"
                    >
                  >;
                  businessObject?: Maybe<
                    { __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name">
                  >;
                  service?: Maybe<
                    { __typename?: "Service" } & Pick<Service, "id"> & {
                        serviceMeta?: Maybe<
                          { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name" | "type">
                        >;
                      }
                  >;
                }
            >;
            conversationReport?: Maybe<
              { __typename: "ConversationReport" } & Pick<
                ConversationReport,
                "id" | "finalizedAt" | "mentalState" | "issue"
              >
            >;
            topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
            tasks?: Maybe<
              Array<
                Maybe<
                  { __typename: "Task" } & Pick<
                    Task,
                    "id" | "taskType" | "startedAt" | "finishedAt" | "userId"
                  >
                >
              >
            >;
            service?: Maybe<
              { __typename: "Service" } & Pick<
                Service,
                | "id"
                | "duedate"
                | "wizardStatus"
                | "wizardStep"
                | "extendedInfo"
                | "status"
                | "createdAt"
                | "lastUpdate"
              > & {
                  serviceMeta?: Maybe<
                    { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name" | "type">
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type ActiveActivitiesQueryVariables = Exact<{ [key: string]: never }>;

export type ActiveActivitiesQuery = { __typename?: "Query" } & {
  activeActivities: Array<
    { __typename?: "PersonActivity" } & Pick<
      PersonActivity,
      "id" | "isActive" | "activityType" | "start"
    > & {
        author?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
        chat?: Maybe<{ __typename?: "Chat" } & Pick<Chat, "id" | "chatUserId">>;
        call?: Maybe<{ __typename?: "Call" } & Pick<Call, "id" | "accountId" | "status">>;
        person: { __typename: "Person" } & Pick<
          Person,
          | "temporary"
          | "id"
          | "name"
          | "alias"
          | "ageGuessed"
          | "age"
          | "salut"
          | "avatar"
          | "avatarKeys"
          | "mentalState"
          | "yellowFlag"
          | "redFlag"
          | "vip"
        > & {
            emails?: Maybe<Array<Maybe<{ __typename?: "Email" } & Pick<Email, "id" | "email">>>>;
            phones?: Maybe<
              Array<
                Maybe<
                  { __typename: "Phone" } & Pick<
                    Phone,
                    | "id"
                    | "type"
                    | "contactAllowed"
                    | "messagingAllowed"
                    | "countryCode"
                    | "areaCode"
                    | "number"
                    | "readableNumber"
                  >
                >
              >
            >;
          };
        service?: Maybe<
          { __typename: "Service" } & Pick<
            Service,
            | "id"
            | "duedate"
            | "wizardStatus"
            | "wizardStep"
            | "extendedInfo"
            | "status"
            | "createdAt"
            | "lastUpdate"
          > & {
              serviceMeta?: Maybe<
                { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name" | "type">
              >;
            }
        >;
        serviceResearchObject?: Maybe<
          { __typename: "ServiceResearchObject" } & Pick<
            ServiceResearchObject,
            "id" | "descriptor" | "info" | "note" | "status" | "statusChangeReason"
          > & {
              contactPerson?: Maybe<
                { __typename: "Person" } & Pick<
                  Person,
                  | "id"
                  | "salut"
                  | "name"
                  | "alias"
                  | "age"
                  | "ageGuessed"
                  | "firstName"
                  | "lastName"
                  | "temporary"
                  | "birthDate"
                >
              >;
              businessObject?: Maybe<
                { __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name">
              >;
            }
        >;
      }
  >;
};

export type PersonActivityQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type PersonActivityQuery = { __typename?: "Query" } & {
  personActivity: { __typename: "PersonActivity" } & Pick<
    PersonActivity,
    | "id"
    | "activityType"
    | "description"
    | "end"
    | "isActive"
    | "path"
    | "start"
    | "subtitle"
    | "lastUpdate"
    | "tempPerson"
  > & {
      person: { __typename: "Person" } & Pick<
        Person,
        | "flag"
        | "yellowFlag"
        | "redFlag"
        | "temporary"
        | "id"
        | "salut"
        | "name"
        | "alias"
        | "age"
        | "ageGuessed"
        | "firstName"
        | "lastName"
        | "birthDate"
      > & {
          emails?: Maybe<Array<Maybe<{ __typename?: "Email" } & Pick<Email, "id" | "email">>>>;
          business?: Maybe<{ __typename?: "BusinessObject" } & Pick<BusinessObject, "id">>;
        };
      conversationReport?: Maybe<
        { __typename?: "ConversationReport" } & Pick<ConversationReport, "id">
      >;
      memo?: Maybe<
        { __typename: "Memo" } & Pick<
          Memo,
          "id" | "note" | "contact" | "status" | "type" | "doneAt"
        > & {
            topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
            person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
            business?: Maybe<
              { __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name" | "accountId">
            >;
          }
      >;
      call?: Maybe<
        { __typename?: "Call" } & Pick<
          Call,
          "id" | "accountId" | "status" | "readableNumber" | "countryCode" | "areaCode" | "number"
        > & { account?: Maybe<{ __typename?: "Account" } & Pick<Account, "id" | "name">> }
      >;
      matches?: Maybe<
        Array<
          Maybe<
            { __typename: "Person" } & Pick<
              Person,
              | "id"
              | "name"
              | "alias"
              | "ageGuessed"
              | "age"
              | "salut"
              | "avatar"
              | "avatarKeys"
              | "mentalState"
              | "yellowFlag"
              | "redFlag"
              | "vip"
            > & {
                phones?: Maybe<
                  Array<Maybe<{ __typename?: "Phone" } & Pick<Phone, "id" | "readableNumber">>>
                >;
              }
          >
        >
      >;
      suggestions?: Maybe<
        Array<
          Maybe<
            { __typename: "Person" } & Pick<
              Person,
              | "id"
              | "name"
              | "alias"
              | "ageGuessed"
              | "age"
              | "salut"
              | "avatar"
              | "avatarKeys"
              | "mentalState"
              | "yellowFlag"
              | "redFlag"
              | "vip"
            > & {
                phones?: Maybe<
                  Array<Maybe<{ __typename?: "Phone" } & Pick<Phone, "id" | "readableNumber">>>
                >;
                activities?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: "PersonActivity" } & Pick<
                        PersonActivity,
                        "id" | "start" | "end" | "activityType"
                      >
                    >
                  >
                >;
              }
          >
        >
      >;
    };
};

export type PersonResearchActivityQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type PersonResearchActivityQuery = { __typename?: "Query" } & {
  personActivity: { __typename: "PersonActivity" } & Pick<
    PersonActivity,
    | "id"
    | "activityType"
    | "description"
    | "end"
    | "isActive"
    | "path"
    | "start"
    | "subtitle"
    | "lastUpdate"
    | "tempPerson"
  > & {
      person: { __typename: "Person" } & Pick<
        Person,
        | "id"
        | "salut"
        | "name"
        | "alias"
        | "age"
        | "ageGuessed"
        | "firstName"
        | "lastName"
        | "temporary"
        | "birthDate"
      >;
      call?: Maybe<
        { __typename?: "Call" } & Pick<Call, "id" | "accountId" | "status" | "readableNumber"> & {
            account?: Maybe<{ __typename?: "Account" } & Pick<Account, "id" | "name">>;
          }
      >;
      serviceResearchObject?: Maybe<
        { __typename: "ServiceResearchObject" } & Pick<
          ServiceResearchObject,
          "id" | "descriptor" | "info" | "note" | "status" | "statusChangeReason"
        > & {
            contactPerson?: Maybe<
              { __typename: "Person" } & Pick<
                Person,
                | "id"
                | "salut"
                | "name"
                | "alias"
                | "age"
                | "ageGuessed"
                | "firstName"
                | "lastName"
                | "temporary"
                | "birthDate"
              >
            >;
            businessObject?: Maybe<
              { __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name">
            >;
          }
      >;
    };
};

export type AllActivitiesQueryVariables = Exact<{
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  date?: InputMaybe<Scalars["DateTime"]>;
}>;

export type AllActivitiesQuery = { __typename?: "Query" } & {
  allActivities: Array<
    { __typename: "PersonActivity" } & Pick<
      PersonActivity,
      | "personId"
      | "id"
      | "activityType"
      | "description"
      | "end"
      | "isActive"
      | "path"
      | "start"
      | "subtitle"
      | "lastUpdate"
      | "tempPerson"
    > & {
        author?: Maybe<
          { __typename: "Person" } & Pick<
            Person,
            "id" | "alias" | "name" | "avatarKeys" | "avatar" | "picture"
          >
        >;
        memo?: Maybe<
          { __typename: "Memo" } & Pick<
            Memo,
            "id" | "note" | "contact" | "status" | "type" | "doneAt"
          > & {
              topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
              person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
              business?: Maybe<
                { __typename?: "BusinessObject" } & Pick<
                  BusinessObject,
                  "id" | "name" | "accountId"
                >
              >;
            }
        >;
        person: { __typename?: "Person" } & Pick<
          Person,
          "businessIds" | "name" | "id" | "yellowFlag" | "redFlag" | "temporary"
        > & {
            emails?: Maybe<Array<Maybe<{ __typename?: "Email" } & Pick<Email, "id" | "email">>>>;
            client?: Maybe<
              { __typename?: "Client" } & Pick<Client, "id" | "vip"> & {
                  account?: Maybe<{ __typename?: "Account" } & Pick<Account, "id" | "name">>;
                }
            >;
          };
        serviceResearchObject?: Maybe<
          { __typename: "ServiceResearchObject" } & Pick<
            ServiceResearchObject,
            "id" | "descriptor" | "info" | "note" | "status" | "statusChangeReason"
          > & {
              contactPerson?: Maybe<
                { __typename: "Person" } & Pick<
                  Person,
                  | "id"
                  | "salut"
                  | "name"
                  | "alias"
                  | "age"
                  | "ageGuessed"
                  | "firstName"
                  | "lastName"
                  | "temporary"
                  | "birthDate"
                >
              >;
              businessObject?: Maybe<
                { __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name">
              >;
              service?: Maybe<
                { __typename?: "Service" } & Pick<Service, "id"> & {
                    serviceMeta?: Maybe<
                      { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name" | "type">
                    >;
                  }
              >;
            }
        >;
        conversationReport?: Maybe<
          { __typename: "ConversationReport" } & Pick<
            ConversationReport,
            "id" | "finalizedAt" | "mentalState" | "issue"
          >
        >;
        topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
        tasks?: Maybe<
          Array<
            Maybe<
              { __typename: "Task" } & Pick<
                Task,
                "id" | "taskType" | "startedAt" | "finishedAt" | "userId"
              >
            >
          >
        >;
        service?: Maybe<
          { __typename: "Service" } & Pick<
            Service,
            | "id"
            | "duedate"
            | "wizardStatus"
            | "wizardStep"
            | "extendedInfo"
            | "status"
            | "createdAt"
            | "lastUpdate"
          > & {
              serviceMeta?: Maybe<
                { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name" | "type">
              >;
            }
        >;
      }
  >;
};

export type ReportActivityQueryVariables = Exact<{
  id: Scalars["Int"];
  loadReportData?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ReportActivityQuery = { __typename?: "Query" } & {
  personActivity: { __typename: "PersonActivity" } & Pick<
    PersonActivity,
    | "doneAt"
    | "isPublic"
    | "id"
    | "activityType"
    | "description"
    | "end"
    | "isActive"
    | "path"
    | "start"
    | "subtitle"
    | "lastUpdate"
    | "tempPerson"
  > & {
      author?: Maybe<
        { __typename: "Person" } & Pick<
          Person,
          "id" | "alias" | "name" | "avatarKeys" | "avatar" | "picture"
        >
      >;
      person: { __typename: "Person" } & Pick<
        Person,
        | "id"
        | "salut"
        | "name"
        | "alias"
        | "age"
        | "ageGuessed"
        | "firstName"
        | "lastName"
        | "temporary"
        | "birthDate"
      >;
      refPerson?: Maybe<
        { __typename: "Person" } & Pick<
          Person,
          | "id"
          | "salut"
          | "name"
          | "alias"
          | "age"
          | "ageGuessed"
          | "firstName"
          | "lastName"
          | "temporary"
          | "birthDate"
        >
      >;
      topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
      tasks?: Maybe<
        Array<
          Maybe<
            { __typename: "Task" } & Pick<
              Task,
              "id" | "taskType" | "startedAt" | "finishedAt" | "userId"
            >
          >
        >
      >;
      conversationReport?: Maybe<
        { __typename: "ConversationReport" } & Pick<
          ConversationReport,
          | "id"
          | "agreement"
          | "issue"
          | "mentalDescription"
          | "mentalState"
          | "reportType"
          | "status"
          | "summary"
          | "risk"
          | "riskInfo"
          | "cmCheckMessage"
          | "doneAt"
          | "lifeworksService"
          | "lifeworksServiceIssue"
          | "lifeworksServiceSubIssue"
        > & {
            author?: Maybe<
              { __typename: "Person" } & Pick<
                Person,
                "id" | "alias" | "name" | "avatar" | "avatarKeys" | "picture"
              >
            >;
            statistics?: Maybe<
              Array<
                Maybe<
                  { __typename?: "StatisticItem" } & Pick<StatisticItem, "id" | "name"> & {
                      group?: Maybe<
                        { __typename?: "StatisticGroup" } & Pick<
                          StatisticGroup,
                          "id" | "name" | "type"
                        >
                      >;
                    }
                >
              >
            >;
            conversationReportReviewAttributes?: Maybe<
              Array<
                Maybe<
                  { __typename?: "ConversationReportReviewAttribute" } & Pick<
                    ConversationReportReviewAttribute,
                    "id" | "attributeValue" | "clientReviewAttributeId"
                  > & {
                      clientReviewAttribute?: Maybe<
                        { __typename?: "ClientReviewAttribute" } & Pick<
                          ClientReviewAttribute,
                          "id" | "label" | "name" | "type"
                        >
                      >;
                    }
                >
              >
            >;
            clientAttributes?: Maybe<
              Array<
                Maybe<
                  { __typename?: "ClientAttribute" } & Pick<
                    ClientAttribute,
                    "id" | "clientReviewAttributeId"
                  >
                >
              >
            >;
            connectedServices?: Maybe<
              Array<Maybe<{ __typename?: "Service" } & Pick<Service, "id">>>
            >;
          }
      >;
      memo?: Maybe<
        { __typename: "Memo" } & Pick<
          Memo,
          "id" | "note" | "contact" | "status" | "type" | "doneAt"
        > & {
            topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
            person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
            business?: Maybe<
              { __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name" | "accountId">
            >;
          }
      >;
    };
};

export type PersonInteractionsQueryVariables = Exact<{
  personId: Scalars["Int"];
  topicId?: InputMaybe<Scalars["Int"]>;
}>;

export type PersonInteractionsQuery = { __typename?: "Query" } & {
  personInteractions?: Maybe<
    Array<Maybe<{ __typename?: "PersonActivity" } & Pick<PersonActivity, "id" | "doneAt">>>
  >;
};

export type BusinessInteractionsQueryVariables = Exact<{
  businessId: Scalars["Int"];
}>;

export type BusinessInteractionsQuery = { __typename?: "Query" } & {
  businessInteractions?: Maybe<
    Array<Maybe<{ __typename?: "PersonActivity" } & Pick<PersonActivity, "id" | "doneAt">>>
  >;
};

export type ServiceBaseFragment = { __typename: "Service" } & Pick<
  Service,
  | "id"
  | "duedate"
  | "wizardStatus"
  | "wizardStep"
  | "extendedInfo"
  | "status"
  | "createdAt"
  | "lastUpdate"
>;

export type ServiceAddressesFragment = { __typename: "ServiceAddress" } & Pick<
  ServiceAddress,
  "id" | "radius" | "serviceMetaAttributeId" | "addressId"
> & {
    address?: Maybe<
      { __typename: "Address" } & Pick<
        Address,
        "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
      >
    >;
  };

export type ServiceMetaFragment = { __typename: "ServiceMeta" } & Pick<
  ServiceMeta,
  "id" | "description" | "iconName" | "name" | "researchHint" | "type"
> & {
    serviceMetaAttributes?: Maybe<
      Array<
        Maybe<
          { __typename: "ServiceMetaAttribute" } & Pick<
            ServiceMetaAttribute,
            | "id"
            | "headline"
            | "name"
            | "type"
            | "label"
            | "placeholder"
            | "defaults"
            | "multiple"
            | "required"
            | "sortIdx"
            | "contextData"
          >
        >
      >
    >;
  };

export type ServiceMetaAttributesFragment = { __typename: "ServiceMetaAttribute" } & Pick<
  ServiceMetaAttribute,
  | "id"
  | "headline"
  | "name"
  | "type"
  | "label"
  | "placeholder"
  | "defaults"
  | "multiple"
  | "required"
  | "sortIdx"
  | "contextData"
>;

export type ServiceMetaAttributeFragment = { __typename: "ServiceMetaAttribute" } & Pick<
  ServiceMetaAttribute,
  "id" | "name" | "type" | "label"
>;

export type ServiceAttributesFragment = { __typename: "ServiceAttribute" } & Pick<
  ServiceAttribute,
  "id" | "value" | "order" | "serviceMetaAttributeId"
> & {
    serviceMetaAttribute?: Maybe<
      { __typename: "ServiceMetaAttribute" } & Pick<
        ServiceMetaAttribute,
        "id" | "name" | "type" | "label"
      >
    >;
  };

export type ClientFragment = { __typename: "Client" } & Pick<Client, "id" | "chatAlias"> & {
    person?: Maybe<
      { __typename: "Person" } & Pick<
        Person,
        | "id"
        | "abnormalities"
        | "age"
        | "ageGuessed"
        | "alias"
        | "avatar"
        | "avatarKeys"
        | "picture"
        | "birthDate"
        | "familyStatus"
        | "firstName"
        | "gender"
        | "lastName"
        | "mentalState"
        | "name"
        | "note"
        | "redFlag"
        | "salut"
        | "title"
        | "vip"
        | "yellowFlag"
        | "temporary"
      > & {
          extras?: Maybe<
            { __typename?: "PersonExtraFieldType" } & Pick<
              PersonExtraFieldType,
              "informationSource" | "hrBusiness"
            >
          >;
        }
    >;
  };

export type ServiceClientsFragment = { __typename: "ServiceClient" } & Pick<
  ServiceClient,
  "id" | "serviceMetaAttributeId" | "clientId"
> & {
    client?: Maybe<
      { __typename: "Client" } & Pick<Client, "id" | "chatAlias"> & {
          person?: Maybe<
            { __typename: "Person" } & Pick<
              Person,
              | "id"
              | "abnormalities"
              | "age"
              | "ageGuessed"
              | "alias"
              | "avatar"
              | "avatarKeys"
              | "picture"
              | "birthDate"
              | "familyStatus"
              | "firstName"
              | "gender"
              | "lastName"
              | "mentalState"
              | "name"
              | "note"
              | "redFlag"
              | "salut"
              | "title"
              | "vip"
              | "yellowFlag"
              | "temporary"
            > & {
                extras?: Maybe<
                  { __typename?: "PersonExtraFieldType" } & Pick<
                    PersonExtraFieldType,
                    "informationSource" | "hrBusiness"
                  >
                >;
              }
          >;
        }
    >;
  };

export type CounselingUserFragment = { __typename: "CounselingUser" } & Pick<
  CounselingUser,
  "id"
> & {
    user?: Maybe<
      { __typename: "User" } & Pick<User, "id" | "active" | "name" | "absentUntil" | "primeDN"> & {
          person?: Maybe<
            { __typename: "Person" } & Pick<
              Person,
              | "picture"
              | "id"
              | "abnormalities"
              | "age"
              | "ageGuessed"
              | "alias"
              | "avatar"
              | "avatarKeys"
              | "birthDate"
              | "familyStatus"
              | "firstName"
              | "gender"
              | "lastName"
              | "mentalState"
              | "name"
              | "note"
              | "redFlag"
              | "salut"
              | "title"
              | "vip"
              | "yellowFlag"
              | "temporary"
            > & {
                extras?: Maybe<
                  { __typename?: "PersonExtraFieldType" } & Pick<
                    PersonExtraFieldType,
                    "informationSource" | "hrBusiness"
                  >
                >;
              }
          >;
          papershiftWorkingAreas?: Maybe<
            Array<
              Maybe<
                { __typename: "PapershiftWorkingArea" } & Pick<
                  PapershiftWorkingArea,
                  "id" | "name" | "color"
                >
              >
            >
          >;
          hotDesks?: Maybe<
            Array<
              Maybe<
                { __typename?: "HotDesk" } & {
                  extension?: Maybe<{ __typename?: "Extension" } & Pick<Extension, "primeDN">>;
                }
              >
            >
          >;
        }
    >;
  };

export type ServiceQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type ServiceQuery = { __typename?: "Query" } & {
  service: { __typename: "Service" } & Pick<
    Service,
    | "id"
    | "duedate"
    | "wizardStatus"
    | "wizardStep"
    | "extendedInfo"
    | "status"
    | "createdAt"
    | "lastUpdate"
  > & {
      client?: Maybe<
        { __typename: "Client" } & Pick<Client, "id" | "chatAlias"> & {
            person?: Maybe<
              { __typename: "Person" } & Pick<
                Person,
                | "id"
                | "abnormalities"
                | "age"
                | "ageGuessed"
                | "alias"
                | "avatar"
                | "avatarKeys"
                | "picture"
                | "birthDate"
                | "familyStatus"
                | "firstName"
                | "gender"
                | "lastName"
                | "mentalState"
                | "name"
                | "note"
                | "redFlag"
                | "salut"
                | "title"
                | "vip"
                | "yellowFlag"
                | "temporary"
              > & {
                  extras?: Maybe<
                    { __typename?: "PersonExtraFieldType" } & Pick<
                      PersonExtraFieldType,
                      "informationSource" | "hrBusiness"
                    >
                  >;
                }
            >;
          }
      >;
      hours?: Maybe<
        Array<Maybe<{ __typename?: "Hour" } & Pick<Hour, "id" | "day" | "open" | "close">>>
      >;
      serviceAddresses?: Maybe<
        Array<
          Maybe<
            { __typename: "ServiceAddress" } & Pick<
              ServiceAddress,
              "id" | "radius" | "serviceMetaAttributeId" | "addressId"
            > & {
                address?: Maybe<
                  { __typename: "Address" } & Pick<
                    Address,
                    "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
                  >
                >;
              }
          >
        >
      >;
      serviceMeta?: Maybe<
        { __typename: "ServiceMeta" } & Pick<
          ServiceMeta,
          "id" | "description" | "iconName" | "name" | "researchHint" | "type"
        > & {
            serviceMetaAttributes?: Maybe<
              Array<
                Maybe<
                  { __typename: "ServiceMetaAttribute" } & Pick<
                    ServiceMetaAttribute,
                    | "id"
                    | "headline"
                    | "name"
                    | "type"
                    | "label"
                    | "placeholder"
                    | "defaults"
                    | "multiple"
                    | "required"
                    | "sortIdx"
                    | "contextData"
                  >
                >
              >
            >;
          }
      >;
      serviceAttributes?: Maybe<
        Array<
          Maybe<
            { __typename: "ServiceAttribute" } & Pick<
              ServiceAttribute,
              "id" | "value" | "order" | "serviceMetaAttributeId"
            > & {
                serviceMetaAttribute?: Maybe<
                  { __typename: "ServiceMetaAttribute" } & Pick<
                    ServiceMetaAttribute,
                    "id" | "name" | "type" | "label"
                  >
                >;
              }
          >
        >
      >;
      serviceClients?: Maybe<
        Array<
          Maybe<
            { __typename: "ServiceClient" } & Pick<
              ServiceClient,
              "id" | "serviceMetaAttributeId" | "clientId"
            > & {
                client?: Maybe<
                  { __typename: "Client" } & Pick<Client, "id" | "chatAlias"> & {
                      person?: Maybe<
                        { __typename: "Person" } & Pick<
                          Person,
                          | "id"
                          | "abnormalities"
                          | "age"
                          | "ageGuessed"
                          | "alias"
                          | "avatar"
                          | "avatarKeys"
                          | "picture"
                          | "birthDate"
                          | "familyStatus"
                          | "firstName"
                          | "gender"
                          | "lastName"
                          | "mentalState"
                          | "name"
                          | "note"
                          | "redFlag"
                          | "salut"
                          | "title"
                          | "vip"
                          | "yellowFlag"
                          | "temporary"
                        > & {
                            extras?: Maybe<
                              { __typename?: "PersonExtraFieldType" } & Pick<
                                PersonExtraFieldType,
                                "informationSource" | "hrBusiness"
                              >
                            >;
                          }
                      >;
                    }
                >;
              }
          >
        >
      >;
      counselingUser?: Maybe<
        { __typename: "CounselingUser" } & Pick<CounselingUser, "id"> & {
            user?: Maybe<
              { __typename: "User" } & Pick<
                User,
                "id" | "active" | "name" | "absentUntil" | "primeDN"
              > & {
                  person?: Maybe<
                    { __typename: "Person" } & Pick<
                      Person,
                      | "picture"
                      | "id"
                      | "abnormalities"
                      | "age"
                      | "ageGuessed"
                      | "alias"
                      | "avatar"
                      | "avatarKeys"
                      | "birthDate"
                      | "familyStatus"
                      | "firstName"
                      | "gender"
                      | "lastName"
                      | "mentalState"
                      | "name"
                      | "note"
                      | "redFlag"
                      | "salut"
                      | "title"
                      | "vip"
                      | "yellowFlag"
                      | "temporary"
                    > & {
                        extras?: Maybe<
                          { __typename?: "PersonExtraFieldType" } & Pick<
                            PersonExtraFieldType,
                            "informationSource" | "hrBusiness"
                          >
                        >;
                      }
                  >;
                  papershiftWorkingAreas?: Maybe<
                    Array<
                      Maybe<
                        { __typename: "PapershiftWorkingArea" } & Pick<
                          PapershiftWorkingArea,
                          "id" | "name" | "color"
                        >
                      >
                    >
                  >;
                  hotDesks?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: "HotDesk" } & {
                          extension?: Maybe<
                            { __typename?: "Extension" } & Pick<Extension, "primeDN">
                          >;
                        }
                      >
                    >
                  >;
                }
            >;
          }
      >;
      counselingMandate?: Maybe<
        { __typename?: "CounselingMandate" } & Pick<CounselingMandate, "id"> & {
            counselor?: Maybe<
              { __typename: "Person" } & Pick<
                Person,
                | "id"
                | "salut"
                | "name"
                | "alias"
                | "age"
                | "ageGuessed"
                | "firstName"
                | "lastName"
                | "temporary"
                | "birthDate"
              >
            >;
            business?: Maybe<
              { __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name">
            >;
          }
      >;
      topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "summary" | "name">>;
      tasks?: Maybe<
        Array<
          Maybe<
            { __typename?: "Task" } & Pick<Task, "id" | "taskType" | "finishedAt"> & {
                user?: Maybe<
                  { __typename: "User" } & Pick<
                    User,
                    "id" | "active" | "name" | "absentUntil" | "primeDN"
                  > & {
                      person?: Maybe<
                        { __typename: "Person" } & Pick<
                          Person,
                          "id" | "alias" | "name" | "avatar" | "avatarKeys" | "picture"
                        >
                      >;
                      hotDesks?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: "HotDesk" } & {
                              extension?: Maybe<
                                { __typename?: "Extension" } & Pick<Extension, "primeDN">
                              >;
                            }
                          >
                        >
                      >;
                    }
                >;
              }
          >
        >
      >;
    };
};

export type ServicesQueryVariables = Exact<{
  ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>> | InputMaybe<Scalars["Int"]>>;
  topicId?: InputMaybe<Scalars["Int"]>;
  statusNot?: InputMaybe<Array<InputMaybe<ServiceStatus>> | InputMaybe<ServiceStatus>>;
  status?: InputMaybe<Array<InputMaybe<ServiceStatus>> | InputMaybe<ServiceStatus>>;
}>;

export type ServicesQuery = { __typename?: "Query" } & {
  services: Array<
    { __typename: "Service" } & Pick<
      Service,
      | "problem"
      | "id"
      | "duedate"
      | "wizardStatus"
      | "wizardStep"
      | "extendedInfo"
      | "status"
      | "createdAt"
      | "lastUpdate"
    > & {
        serviceMeta?: Maybe<
          { __typename: "ServiceMeta" } & Pick<
            ServiceMeta,
            "id" | "description" | "iconName" | "name" | "researchHint" | "type"
          > & {
              serviceMetaAttributes?: Maybe<
                Array<
                  Maybe<
                    { __typename: "ServiceMetaAttribute" } & Pick<
                      ServiceMetaAttribute,
                      | "id"
                      | "headline"
                      | "name"
                      | "type"
                      | "label"
                      | "placeholder"
                      | "defaults"
                      | "multiple"
                      | "required"
                      | "sortIdx"
                      | "contextData"
                    >
                  >
                >
              >;
            }
        >;
        counselingUser?: Maybe<
          { __typename: "CounselingUser" } & Pick<CounselingUser, "id"> & {
              user?: Maybe<
                { __typename: "User" } & Pick<
                  User,
                  "id" | "active" | "name" | "absentUntil" | "primeDN"
                > & {
                    person?: Maybe<
                      { __typename: "Person" } & Pick<
                        Person,
                        | "picture"
                        | "id"
                        | "abnormalities"
                        | "age"
                        | "ageGuessed"
                        | "alias"
                        | "avatar"
                        | "avatarKeys"
                        | "birthDate"
                        | "familyStatus"
                        | "firstName"
                        | "gender"
                        | "lastName"
                        | "mentalState"
                        | "name"
                        | "note"
                        | "redFlag"
                        | "salut"
                        | "title"
                        | "vip"
                        | "yellowFlag"
                        | "temporary"
                      > & {
                          extras?: Maybe<
                            { __typename?: "PersonExtraFieldType" } & Pick<
                              PersonExtraFieldType,
                              "informationSource" | "hrBusiness"
                            >
                          >;
                        }
                    >;
                    papershiftWorkingAreas?: Maybe<
                      Array<
                        Maybe<
                          { __typename: "PapershiftWorkingArea" } & Pick<
                            PapershiftWorkingArea,
                            "id" | "name" | "color"
                          >
                        >
                      >
                    >;
                    hotDesks?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: "HotDesk" } & {
                            extension?: Maybe<
                              { __typename?: "Extension" } & Pick<Extension, "primeDN">
                            >;
                          }
                        >
                      >
                    >;
                  }
              >;
            }
        >;
        counselingMandate?: Maybe<
          { __typename?: "CounselingMandate" } & Pick<CounselingMandate, "id"> & {
              counselor?: Maybe<
                { __typename: "Person" } & Pick<
                  Person,
                  | "id"
                  | "salut"
                  | "name"
                  | "alias"
                  | "age"
                  | "ageGuessed"
                  | "firstName"
                  | "lastName"
                  | "temporary"
                  | "birthDate"
                >
              >;
              business?: Maybe<
                { __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name">
              >;
            }
        >;
      }
  >;
};

export type AllServicesQueryVariables = Exact<{
  statusNot?: InputMaybe<Array<InputMaybe<ServiceStatus>> | InputMaybe<ServiceStatus>>;
}>;

export type AllServicesQuery = { __typename?: "Query" } & {
  services: Array<
    { __typename?: "Service" } & Pick<Service, "id" | "duedate" | "lastActivity" | "status"> & {
        topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
        serviceMeta?: Maybe<{ __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">>;
        client?: Maybe<
          { __typename?: "Client" } & Pick<Client, "id"> & {
              account?: Maybe<{ __typename?: "Account" } & Pick<Account, "id" | "name">>;
              person?: Maybe<
                { __typename?: "Person" } & Pick<
                  Person,
                  "id" | "avatarKeys" | "flag" | "mentalState" | "name"
                >
              >;
            }
        >;
        personActivity?: Maybe<
          { __typename?: "PersonActivity" } & Pick<PersonActivity, "id"> & {
              tasks?: Maybe<
                Array<
                  Maybe<
                    { __typename?: "Task" } & Pick<
                      Task,
                      "created" | "startedAt" | "finishedAt" | "taskType"
                    > & {
                        user?: Maybe<
                          { __typename: "User" } & Pick<
                            User,
                            "id" | "active" | "name" | "absentUntil" | "primeDN"
                          > & {
                              person?: Maybe<
                                { __typename: "Person" } & Pick<
                                  Person,
                                  "id" | "alias" | "name" | "avatar" | "avatarKeys" | "picture"
                                >
                              >;
                              hotDesks?: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: "HotDesk" } & {
                                      extension?: Maybe<
                                        { __typename?: "Extension" } & Pick<Extension, "primeDN">
                                      >;
                                    }
                                  >
                                >
                              >;
                            }
                        >;
                      }
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type ServicesByTypeQueryVariables = Exact<{
  type: ServiceType;
  status?: InputMaybe<Array<ServiceStatus> | ServiceStatus>;
  statusNot?: InputMaybe<Array<ServiceStatus> | ServiceStatus>;
}>;

export type ServicesByTypeQuery = { __typename?: "Query" } & {
  servicesByType?: Maybe<
    Array<
      Maybe<
        { __typename?: "Service" } & Pick<
          Service,
          "id" | "created" | "duedate" | "lastActivity" | "status"
        > & {
            counselingUser?: Maybe<
              { __typename?: "CounselingUser" } & Pick<CounselingUser, "id"> & {
                  user?: Maybe<
                    { __typename?: "User" } & Pick<User, "id"> & {
                        papershiftWorkingAreas?: Maybe<
                          Array<
                            Maybe<
                              { __typename: "PapershiftWorkingArea" } & Pick<
                                PapershiftWorkingArea,
                                "id" | "name" | "color"
                              >
                            >
                          >
                        >;
                        person?: Maybe<
                          { __typename?: "Person" } & Pick<
                            Person,
                            "id" | "alias" | "avatar" | "avatarKeys" | "picture" | "name"
                          >
                        >;
                      }
                  >;
                }
            >;
            topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
            serviceMeta?: Maybe<{ __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">>;
            client?: Maybe<
              { __typename?: "Client" } & Pick<Client, "id"> & {
                  account?: Maybe<{ __typename?: "Account" } & Pick<Account, "id" | "name">>;
                  person?: Maybe<
                    { __typename?: "Person" } & Pick<
                      Person,
                      "id" | "avatarKeys" | "flag" | "mentalState" | "name"
                    >
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type ServiceCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type ServiceCategoriesQuery = { __typename?: "Query" } & {
  serviceCategories: Array<
    { __typename?: "ServiceCategory" } & Pick<ServiceCategory, "id" | "name"> & {
        serviceMetas?: Maybe<
          Array<
            Maybe<
              { __typename?: "ServiceMeta" } & Pick<
                ServiceMeta,
                "name" | "iconName" | "serviceCategoryId" | "id" | "subscribed"
              >
            >
          >
        >;
      }
  >;
};

export type RegisteredServicesByAccountIdQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type RegisteredServicesByAccountIdQuery = { __typename?: "Query" } & {
  registeredServicesByAccountId: Array<
    { __typename?: "ServiceCategory" } & Pick<ServiceCategory, "id" | "name"> & {
        serviceMetas?: Maybe<
          Array<
            Maybe<
              { __typename?: "ServiceMeta" } & Pick<
                ServiceMeta,
                "name" | "iconName" | "serviceCategoryId" | "id" | "subscribed"
              >
            >
          >
        >;
      }
  >;
};

export type RegisteredServicesByPersonIdQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type RegisteredServicesByPersonIdQuery = { __typename?: "Query" } & {
  registeredServicesByPersonId: Array<
    { __typename?: "ServiceCategory" } & Pick<ServiceCategory, "id" | "name"> & {
        serviceMetas?: Maybe<
          Array<
            Maybe<
              { __typename?: "ServiceMeta" } & Pick<
                ServiceMeta,
                "name" | "iconName" | "serviceCategoryId" | "id" | "subscribed"
              >
            >
          >
        >;
      }
  >;
};

export type ServiceMetasQueryVariables = Exact<{
  serviceCategoryId: Scalars["Int"];
}>;

export type ServiceMetasQuery = { __typename?: "Query" } & {
  serviceMetas: Array<{ __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">>;
};

export type ServiceResearchObjectFragment = { __typename: "ServiceResearchObject" } & Pick<
  ServiceResearchObject,
  "id" | "descriptor" | "info" | "note" | "status" | "statusChangeReason"
>;

export type ServiceResearchObjectsQueryVariables = Exact<{
  serviceId: Scalars["Int"];
}>;

export type ServiceResearchObjectsQuery = { __typename?: "Query" } & {
  serviceResearchObjects: Array<
    { __typename: "ServiceResearchObject" } & Pick<
      ServiceResearchObject,
      "id" | "descriptor" | "info" | "note" | "status" | "statusChangeReason"
    > & {
        contactPerson?: Maybe<
          { __typename: "Person" } & Pick<
            Person,
            | "id"
            | "name"
            | "alias"
            | "firstName"
            | "lastName"
            | "temporary"
            | "gender"
            | "title"
            | "note"
            | "salut"
            | "avatarKeys"
          >
        >;
        businessObject?: Maybe<
          { __typename: "BusinessObject" } & Pick<
            BusinessObject,
            "id" | "name" | "url" | "info" | "accountId"
          > & {
              address?: Maybe<
                { __typename: "Address" } & Pick<
                  Address,
                  "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
                >
              >;
              email?: Maybe<
                { __typename: "Email" } & Pick<Email, "id" | "type" | "contactAllowed" | "email">
              >;
              persons?: Maybe<
                Array<
                  Maybe<
                    { __typename: "Person" } & Pick<
                      Person,
                      | "id"
                      | "name"
                      | "alias"
                      | "firstName"
                      | "lastName"
                      | "temporary"
                      | "gender"
                      | "title"
                      | "note"
                      | "salut"
                      | "avatarKeys"
                    >
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type ServiceResearchObjectQueryVariables = Exact<{
  id: Scalars["Int"];
  serviceId: Scalars["Int"];
}>;

export type ServiceResearchObjectQuery = { __typename?: "Query" } & {
  serviceResearchObject: { __typename: "ServiceResearchObject" } & Pick<
    ServiceResearchObject,
    "id" | "descriptor" | "info" | "note" | "status" | "statusChangeReason"
  > & {
      contactPerson?: Maybe<
        { __typename: "Person" } & Pick<
          Person,
          | "id"
          | "name"
          | "alias"
          | "firstName"
          | "lastName"
          | "temporary"
          | "gender"
          | "title"
          | "note"
          | "salut"
          | "avatarKeys"
        >
      >;
      service?: Maybe<
        { __typename?: "Service" } & Pick<Service, "id"> & {
            serviceAddresses?: Maybe<
              Array<
                Maybe<
                  { __typename?: "ServiceAddress" } & Pick<ServiceAddress, "id" | "radius"> & {
                      address?: Maybe<
                        { __typename: "Address" } & Pick<
                          Address,
                          | "id"
                          | "street"
                          | "city"
                          | "zip"
                          | "lat"
                          | "lon"
                          | "type"
                          | "contactAllowed"
                        >
                      >;
                    }
                >
              >
            >;
          }
      >;
      businessObject?: Maybe<
        { __typename: "BusinessObject" } & Pick<
          BusinessObject,
          "id" | "name" | "url" | "info" | "accountId"
        > & {
            address?: Maybe<
              { __typename: "Address" } & Pick<
                Address,
                "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
              >
            >;
            email?: Maybe<
              { __typename: "Email" } & Pick<Email, "id" | "type" | "contactAllowed" | "email">
            >;
            phones?: Maybe<
              Array<
                Maybe<
                  { __typename: "Phone" } & Pick<
                    Phone,
                    | "id"
                    | "type"
                    | "contactAllowed"
                    | "messagingAllowed"
                    | "countryCode"
                    | "areaCode"
                    | "number"
                    | "readableNumber"
                  >
                >
              >
            >;
            hours?: Maybe<
              Array<Maybe<{ __typename?: "Hour" } & Pick<Hour, "id" | "open" | "close" | "day">>>
            >;
            persons?: Maybe<
              Array<
                Maybe<
                  { __typename: "Person" } & Pick<
                    Person,
                    | "id"
                    | "name"
                    | "alias"
                    | "firstName"
                    | "lastName"
                    | "temporary"
                    | "gender"
                    | "title"
                    | "note"
                    | "salut"
                    | "avatarKeys"
                  >
                >
              >
            >;
          }
      >;
    };
};

export type StatisticGroupsQueryVariables = Exact<{
  type: StatisticGroupTypes;
  clientId: Scalars["Int"];
}>;

export type StatisticGroupsQuery = { __typename?: "Query" } & {
  statisticGroups: Array<
    { __typename?: "StatisticGroup" } & Pick<StatisticGroup, "id" | "name"> & {
        items: Array<{ __typename?: "StatisticItem" } & Pick<StatisticItem, "id" | "name">>;
      }
  >;
};

export type StatisticItemsByReportQueryVariables = Exact<{
  reportId: Scalars["Int"];
}>;

export type StatisticItemsByReportQuery = { __typename?: "Query" } & {
  statisticItemsByReport?: Maybe<
    Array<Maybe<{ __typename?: "StatisticItem" } & Pick<StatisticItem, "id" | "name">>>
  >;
};

export type TaskExtendedFragment = { __typename: "Task" } & Pick<
  Task,
  | "id"
  | "dueDate"
  | "finishedAt"
  | "startedAt"
  | "taskType"
  | "snoozeUntil"
  | "path"
  | "title"
  | "created"
  | "note"
  | "team"
> & {
    person?: Maybe<
      { __typename?: "Person" } & Pick<
        Person,
        | "id"
        | "name"
        | "avatar"
        | "avatarKeys"
        | "mentalState"
        | "yellowFlag"
        | "redFlag"
        | "vip"
        | "temporary"
      >
    >;
    author?: Maybe<
      { __typename?: "Person" } & Pick<Person, "id" | "name" | "avatarKeys" | "avatar" | "picture">
    >;
    creator?: Maybe<
      { __typename?: "Person" } & Pick<Person, "id" | "name" | "avatarKeys" | "avatar" | "picture">
    >;
    report?: Maybe<
      { __typename?: "ConversationReport" } & Pick<ConversationReport, "id" | "mentalState"> & {
          personActivity?: Maybe<
            { __typename?: "PersonActivity" } & Pick<PersonActivity, "id" | "start" | "end">
          >;
        }
    >;
    topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
    service?: Maybe<
      { __typename?: "Service" } & Pick<Service, "id"> & {
          serviceMeta?: Maybe<{ __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">>;
        }
    >;
    personActivity?: Maybe<
      { __typename?: "PersonActivity" } & Pick<
        PersonActivity,
        "id" | "activityType" | "description" | "end" | "start"
      > & {
          conversationReport?: Maybe<
            { __typename?: "ConversationReport" } & Pick<ConversationReport, "id" | "mentalState">
          >;
          service?: Maybe<
            { __typename?: "Service" } & Pick<Service, "id" | "wizardStatus" | "status"> & {
                serviceMeta?: Maybe<
                  { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name" | "type">
                >;
              }
          >;
          call?: Maybe<{ __typename?: "Call" } & Pick<Call, "id" | "readableNumber">>;
          memo?: Maybe<
            { __typename?: "Memo" } & Pick<Memo, "id" | "note" | "contact" | "status"> & {
                topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
                person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
              }
          >;
        }
    >;
  };

export type TasksQueryVariables = Exact<{
  pool?: InputMaybe<Scalars["Boolean"]>;
  duedate?: InputMaybe<Scalars["String"]>;
  withSnoozed?: InputMaybe<Scalars["Boolean"]>;
}>;

export type TasksQuery = { __typename?: "Query" } & {
  tasks: Array<
    { __typename: "Task" } & Pick<
      Task,
      | "id"
      | "dueDate"
      | "finishedAt"
      | "startedAt"
      | "taskType"
      | "snoozeUntil"
      | "path"
      | "title"
      | "created"
      | "note"
      | "team"
    > & {
        person?: Maybe<
          { __typename?: "Person" } & Pick<
            Person,
            | "id"
            | "name"
            | "avatar"
            | "avatarKeys"
            | "mentalState"
            | "yellowFlag"
            | "redFlag"
            | "vip"
            | "temporary"
          >
        >;
        author?: Maybe<
          { __typename?: "Person" } & Pick<
            Person,
            "id" | "name" | "avatarKeys" | "avatar" | "picture"
          >
        >;
        creator?: Maybe<
          { __typename?: "Person" } & Pick<
            Person,
            "id" | "name" | "avatarKeys" | "avatar" | "picture"
          >
        >;
        report?: Maybe<
          { __typename?: "ConversationReport" } & Pick<ConversationReport, "id" | "mentalState"> & {
              personActivity?: Maybe<
                { __typename?: "PersonActivity" } & Pick<PersonActivity, "id" | "start" | "end">
              >;
            }
        >;
        topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
        service?: Maybe<
          { __typename?: "Service" } & Pick<Service, "id"> & {
              serviceMeta?: Maybe<
                { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">
              >;
            }
        >;
        personActivity?: Maybe<
          { __typename?: "PersonActivity" } & Pick<
            PersonActivity,
            "id" | "activityType" | "description" | "end" | "start"
          > & {
              conversationReport?: Maybe<
                { __typename?: "ConversationReport" } & Pick<
                  ConversationReport,
                  "id" | "mentalState"
                >
              >;
              service?: Maybe<
                { __typename?: "Service" } & Pick<Service, "id" | "wizardStatus" | "status"> & {
                    serviceMeta?: Maybe<
                      { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name" | "type">
                    >;
                  }
              >;
              call?: Maybe<{ __typename?: "Call" } & Pick<Call, "id" | "readableNumber">>;
              memo?: Maybe<
                { __typename?: "Memo" } & Pick<Memo, "id" | "note" | "contact" | "status"> & {
                    topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
                    person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
                  }
              >;
            }
        >;
      }
  >;
};

export type CurrentTasksQueryVariables = Exact<{
  pool?: InputMaybe<Scalars["Boolean"]>;
  userId?: InputMaybe<Scalars["Int"]>;
  withSnoozed?: InputMaybe<Scalars["Boolean"]>;
  unassigned?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CurrentTasksQuery = { __typename?: "Query" } & {
  currentTasks: Array<
    { __typename: "Task" } & Pick<
      Task,
      | "id"
      | "dueDate"
      | "finishedAt"
      | "startedAt"
      | "taskType"
      | "snoozeUntil"
      | "path"
      | "title"
      | "created"
      | "note"
      | "team"
    > & {
        person?: Maybe<
          { __typename?: "Person" } & Pick<
            Person,
            | "id"
            | "name"
            | "avatar"
            | "avatarKeys"
            | "mentalState"
            | "yellowFlag"
            | "redFlag"
            | "vip"
            | "temporary"
          >
        >;
        author?: Maybe<
          { __typename?: "Person" } & Pick<
            Person,
            "id" | "name" | "avatarKeys" | "avatar" | "picture"
          >
        >;
        creator?: Maybe<
          { __typename?: "Person" } & Pick<
            Person,
            "id" | "name" | "avatarKeys" | "avatar" | "picture"
          >
        >;
        report?: Maybe<
          { __typename?: "ConversationReport" } & Pick<ConversationReport, "id" | "mentalState"> & {
              personActivity?: Maybe<
                { __typename?: "PersonActivity" } & Pick<PersonActivity, "id" | "start" | "end">
              >;
            }
        >;
        topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
        service?: Maybe<
          { __typename?: "Service" } & Pick<Service, "id"> & {
              serviceMeta?: Maybe<
                { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">
              >;
            }
        >;
        personActivity?: Maybe<
          { __typename?: "PersonActivity" } & Pick<
            PersonActivity,
            "id" | "activityType" | "description" | "end" | "start"
          > & {
              conversationReport?: Maybe<
                { __typename?: "ConversationReport" } & Pick<
                  ConversationReport,
                  "id" | "mentalState"
                >
              >;
              service?: Maybe<
                { __typename?: "Service" } & Pick<Service, "id" | "wizardStatus" | "status"> & {
                    serviceMeta?: Maybe<
                      { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name" | "type">
                    >;
                  }
              >;
              call?: Maybe<{ __typename?: "Call" } & Pick<Call, "id" | "readableNumber">>;
              memo?: Maybe<
                { __typename?: "Memo" } & Pick<Memo, "id" | "note" | "contact" | "status"> & {
                    topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
                    person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
                  }
              >;
            }
        >;
      }
  >;
};

export type CurrentTaskCountQueryVariables = Exact<{
  pool?: InputMaybe<Scalars["Boolean"]>;
  userId?: InputMaybe<Scalars["Int"]>;
  withSnoozed?: InputMaybe<Scalars["Boolean"]>;
  unassigned?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CurrentTaskCountQuery = { __typename?: "Query" } & {
  currentTasks: Array<{ __typename?: "Task" } & Pick<Task, "id">>;
};

export type GetCmReminderTaskQueryVariables = Exact<{
  personId: Scalars["Int"];
}>;

export type GetCmReminderTaskQuery = { __typename?: "Query" } & {
  getCMReminderTask?: Maybe<
    { __typename: "Task" } & Pick<
      Task,
      | "id"
      | "dueDate"
      | "finishedAt"
      | "startedAt"
      | "taskType"
      | "snoozeUntil"
      | "path"
      | "title"
      | "created"
      | "note"
      | "team"
    > & {
        person?: Maybe<
          { __typename?: "Person" } & Pick<
            Person,
            | "id"
            | "name"
            | "avatar"
            | "avatarKeys"
            | "mentalState"
            | "yellowFlag"
            | "redFlag"
            | "vip"
            | "temporary"
          >
        >;
        author?: Maybe<
          { __typename?: "Person" } & Pick<
            Person,
            "id" | "name" | "avatarKeys" | "avatar" | "picture"
          >
        >;
        creator?: Maybe<
          { __typename?: "Person" } & Pick<
            Person,
            "id" | "name" | "avatarKeys" | "avatar" | "picture"
          >
        >;
        report?: Maybe<
          { __typename?: "ConversationReport" } & Pick<ConversationReport, "id" | "mentalState"> & {
              personActivity?: Maybe<
                { __typename?: "PersonActivity" } & Pick<PersonActivity, "id" | "start" | "end">
              >;
            }
        >;
        topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
        service?: Maybe<
          { __typename?: "Service" } & Pick<Service, "id"> & {
              serviceMeta?: Maybe<
                { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">
              >;
            }
        >;
        personActivity?: Maybe<
          { __typename?: "PersonActivity" } & Pick<
            PersonActivity,
            "id" | "activityType" | "description" | "end" | "start"
          > & {
              conversationReport?: Maybe<
                { __typename?: "ConversationReport" } & Pick<
                  ConversationReport,
                  "id" | "mentalState"
                >
              >;
              service?: Maybe<
                { __typename?: "Service" } & Pick<Service, "id" | "wizardStatus" | "status"> & {
                    serviceMeta?: Maybe<
                      { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name" | "type">
                    >;
                  }
              >;
              call?: Maybe<{ __typename?: "Call" } & Pick<Call, "id" | "readableNumber">>;
              memo?: Maybe<
                { __typename?: "Memo" } & Pick<Memo, "id" | "note" | "contact" | "status"> & {
                    topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
                    person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
                  }
              >;
            }
        >;
      }
  >;
};

export type GetAllowedTeamsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllowedTeamsQuery = { __typename?: "Query" } & Pick<Query, "getAllowedTeams">;

export type GetAllTeamsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllTeamsQuery = { __typename?: "Query" } & Pick<Query, "getAllTeams">;

export type TopicsFromPersonQueryVariables = Exact<{
  personId: Scalars["Int"];
}>;

export type TopicsFromPersonQuery = { __typename?: "Query" } & {
  topicsFromPerson: Array<
    { __typename?: "Topic" } & Pick<Topic, "id" | "name"> & {
        services?: Maybe<
          Array<
            Maybe<
              { __typename?: "Service" } & Pick<Service, "id"> & {
                  serviceMeta?: Maybe<
                    { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type TopicsQueryVariables = Exact<{
  clientId: Scalars["Int"];
}>;

export type TopicsQuery = { __typename?: "Query" } & {
  topics: Array<
    { __typename?: "Topic" } & Pick<Topic, "id" | "name" | "lastInteraction"> & {
        services?: Maybe<
          Array<
            Maybe<
              { __typename?: "Service" } & Pick<Service, "id" | "status"> & {
                  serviceMeta?: Maybe<
                    { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "iconName" | "name">
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type TopicQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type TopicQuery = { __typename?: "Query" } & {
  topic: { __typename?: "Topic" } & Pick<
    Topic,
    "id" | "name" | "lastInteraction" | "firstInteraction"
  > & {
      services?: Maybe<
        Array<
          Maybe<
            { __typename?: "Service" } & Pick<
              Service,
              | "id"
              | "status"
              | "duedate"
              | "problem"
              | "openCount"
              | "limitedCount"
              | "suitableCount"
              | "unsuitableCount"
            > & {
                serviceMeta?: Maybe<
                  { __typename?: "ServiceMeta" } & Pick<
                    ServiceMeta,
                    "id" | "iconName" | "name" | "type"
                  >
                >;
              }
          >
        >
      >;
    };
};

export type TopicCrumbQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type TopicCrumbQuery = { __typename?: "Query" } & {
  topic: { __typename?: "Topic" } & Pick<Topic, "id" | "name"> & {
      client?: Maybe<
        { __typename?: "Client" } & Pick<Client, "id"> & {
            person?: Maybe<
              { __typename: "Person" } & Pick<
                Person,
                | "id"
                | "name"
                | "alias"
                | "ageGuessed"
                | "age"
                | "salut"
                | "avatar"
                | "avatarKeys"
                | "mentalState"
                | "yellowFlag"
                | "redFlag"
                | "vip"
              >
            >;
          }
      >;
    };
};

export type BaseUserFragment = { __typename: "User" } & Pick<
  User,
  "id" | "active" | "name" | "absentUntil" | "primeDN"
>;

export type UserFragment = { __typename: "User" } & Pick<
  User,
  "id" | "active" | "name" | "absentUntil" | "primeDN"
> & {
    hotDesks?: Maybe<
      Array<
        Maybe<
          { __typename?: "HotDesk" } & {
            extension?: Maybe<{ __typename?: "Extension" } & Pick<Extension, "primeDN">>;
          }
        >
      >
    >;
  };

export type UsersQueryVariables = Exact<{
  teams?: InputMaybe<Array<Team> | Team>;
}>;

export type UsersQuery = { __typename?: "Query" } & {
  users?: Maybe<
    Array<
      Maybe<
        { __typename: "User" } & Pick<
          User,
          "id" | "active" | "name" | "absentUntil" | "primeDN"
        > & {
            person?: Maybe<
              { __typename: "Person" } & Pick<
                Person,
                | "id"
                | "abnormalities"
                | "age"
                | "ageGuessed"
                | "alias"
                | "avatar"
                | "avatarKeys"
                | "picture"
                | "birthDate"
                | "familyStatus"
                | "firstName"
                | "gender"
                | "lastName"
                | "mentalState"
                | "name"
                | "note"
                | "redFlag"
                | "salut"
                | "title"
                | "vip"
                | "yellowFlag"
                | "temporary"
              > & {
                  extras?: Maybe<
                    { __typename?: "PersonExtraFieldType" } & Pick<
                      PersonExtraFieldType,
                      "informationSource" | "hrBusiness"
                    >
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type UserQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type UserQuery = { __typename?: "Query" } & {
  user?: Maybe<
    { __typename: "User" } & Pick<
      User,
      "teams" | "id" | "active" | "name" | "absentUntil" | "primeDN"
    > & {
        person?: Maybe<
          { __typename: "Person" } & Pick<
            Person,
            | "id"
            | "abnormalities"
            | "age"
            | "ageGuessed"
            | "alias"
            | "avatar"
            | "avatarKeys"
            | "picture"
            | "birthDate"
            | "familyStatus"
            | "firstName"
            | "gender"
            | "lastName"
            | "mentalState"
            | "name"
            | "note"
            | "redFlag"
            | "salut"
            | "title"
            | "vip"
            | "yellowFlag"
            | "temporary"
          > & {
              extras?: Maybe<
                { __typename?: "PersonExtraFieldType" } & Pick<
                  PersonExtraFieldType,
                  "informationSource" | "hrBusiness"
                >
              >;
            }
        >;
        papershiftWorkingAreas?: Maybe<
          Array<
            Maybe<
              { __typename: "PapershiftWorkingArea" } & Pick<
                PapershiftWorkingArea,
                "id" | "name" | "color"
              >
            >
          >
        >;
        hotDesks?: Maybe<
          Array<
            Maybe<
              { __typename?: "HotDesk" } & {
                extension?: Maybe<{ __typename?: "Extension" } & Pick<Extension, "primeDN">>;
              }
            >
          >
        >;
      }
  >;
};

export type UserFromPersonQueryVariables = Exact<{
  personId: Scalars["Int"];
}>;

export type UserFromPersonQuery = { __typename?: "Query" } & {
  userFromPerson?: Maybe<
    { __typename: "User" } & Pick<
      User,
      "teams" | "id" | "active" | "name" | "absentUntil" | "primeDN"
    > & {
        person?: Maybe<
          { __typename: "Person" } & Pick<
            Person,
            | "id"
            | "abnormalities"
            | "age"
            | "ageGuessed"
            | "alias"
            | "avatar"
            | "avatarKeys"
            | "picture"
            | "birthDate"
            | "familyStatus"
            | "firstName"
            | "gender"
            | "lastName"
            | "mentalState"
            | "name"
            | "note"
            | "redFlag"
            | "salut"
            | "title"
            | "vip"
            | "yellowFlag"
            | "temporary"
          > & {
              extras?: Maybe<
                { __typename?: "PersonExtraFieldType" } & Pick<
                  PersonExtraFieldType,
                  "informationSource" | "hrBusiness"
                >
              >;
            }
        >;
        papershiftWorkingAreas?: Maybe<
          Array<
            Maybe<
              { __typename: "PapershiftWorkingArea" } & Pick<
                PapershiftWorkingArea,
                "id" | "name" | "color"
              >
            >
          >
        >;
        hotDesks?: Maybe<
          Array<
            Maybe<
              { __typename?: "HotDesk" } & {
                extension?: Maybe<{ __typename?: "Extension" } & Pick<Extension, "primeDN">>;
              }
            >
          >
        >;
      }
  >;
};

export type UsersWithTasksQueryVariables = Exact<{
  teams?: InputMaybe<Array<Team> | Team>;
}>;

export type UsersWithTasksQuery = { __typename?: "Query" } & {
  usersWithTasks?: Maybe<
    Array<
      Maybe<
        { __typename?: "UserWithTasksAndAppointments" } & Pick<
          UserWithTasksAndAppointments,
          "userId" | "personId" | "firstName" | "lastName" | "alias" | "title"
        > & {
            tasks?: Maybe<Array<Maybe<{ __typename?: "Task" } & Pick<Task, "id">>>>;
            appointments?: Maybe<
              Array<Maybe<{ __typename?: "Appointment" } & Pick<Appointment, "id">>>
            >;
            todayAppointments?: Maybe<
              Array<Maybe<{ __typename?: "Appointment" } & Pick<Appointment, "id">>>
            >;
          }
      >
    >
  >;
};

export type GetLatestUserChangeByTypeQueryVariables = Exact<{
  targetId?: InputMaybe<Scalars["Int"]>;
  targetType?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
}>;

export type GetLatestUserChangeByTypeQuery = { __typename?: "Query" } & {
  getLatestUserChangeByType?: Maybe<
    { __typename?: "UserChangesLog" } & Pick<UserChangesLog, "updatedAt"> & {
        user?: Maybe<
          { __typename?: "User" } & {
            person?: Maybe<
              { __typename?: "Person" } & Pick<Person, "firstName" | "lastName" | "alias">
            >;
          }
        >;
      }
  >;
};

export type AccountSubscriptionSubscriptionVariables = Exact<{
  id: Scalars["Int"];
}>;

export type AccountSubscriptionSubscription = { __typename?: "Subscription" } & {
  accountSubscription?: Maybe<
    { __typename: "Account" } & Pick<
      Account,
      | "id"
      | "chatRegistrationPin"
      | "coaching"
      | "contractEnd"
      | "contractStart"
      | "employeesCount"
      | "executivesCount"
      | "externalRef"
      | "lifeworksId"
      | "helpline"
      | "helpline2"
      | "helplineInternational"
      | "remarks"
      | "nFreeSTCs"
      | "serviceTimes"
      | "usageRate"
      | "freeSessions"
      | "isAssistance"
      | "name"
      | "pin"
      | "reporting"
      | "tariff"
      | "type"
      | "updatedAt"
      | "usageScope"
      | "validationPattern"
      | "serviceRegion"
    > & {
        accountServiceModules?: Maybe<
          Array<
            Maybe<
              { __typename?: "ServiceMeta" } & Pick<
                ServiceMeta,
                "id" | "name" | "serviceCategoryId"
              >
            >
          >
        >;
        parent?: Maybe<{ __typename?: "Account" } & Pick<Account, "id" | "name" | "serviceRegion">>;
        business?: Maybe<
          { __typename: "BusinessObject" } & Pick<
            BusinessObject,
            "memo" | "id" | "name" | "url" | "info" | "accountId"
          > & {
              address?: Maybe<
                { __typename: "Address" } & Pick<
                  Address,
                  "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
                >
              >;
              email?: Maybe<
                { __typename: "Email" } & Pick<Email, "id" | "type" | "contactAllowed" | "email">
              >;
              persons?: Maybe<
                Array<
                  Maybe<
                    { __typename: "Person" } & Pick<
                      Person,
                      | "id"
                      | "salut"
                      | "name"
                      | "alias"
                      | "age"
                      | "ageGuessed"
                      | "firstName"
                      | "lastName"
                      | "temporary"
                      | "birthDate"
                    >
                  >
                >
              >;
              phones?: Maybe<
                Array<
                  Maybe<
                    { __typename: "Phone" } & Pick<
                      Phone,
                      | "id"
                      | "type"
                      | "contactAllowed"
                      | "messagingAllowed"
                      | "countryCode"
                      | "areaCode"
                      | "number"
                      | "readableNumber"
                    >
                  >
                >
              >;
            }
        >;
        industry?: Maybe<{ __typename?: "Industry" } & Pick<Industry, "id" | "name">>;
      }
  >;
};

export type AppointmentSubscriptionSubscriptionVariables = Exact<{
  userIds: Array<Scalars["Int"]> | Scalars["Int"];
  startsAt: Scalars["String"];
  endsAt: Scalars["String"];
}>;

export type AppointmentSubscriptionSubscription = { __typename?: "Subscription" } & {
  appointmentSubscription?: Maybe<
    { __typename: "Appointment" } & Pick<
      Appointment,
      "id" | "title" | "note" | "date" | "from" | "to" | "deletedAt"
    > & {
        users?: Maybe<
          Array<
            Maybe<
              { __typename: "User" } & Pick<
                User,
                "id" | "active" | "name" | "absentUntil" | "primeDN"
              > & {
                  person?: Maybe<
                    { __typename: "Person" } & Pick<
                      Person,
                      | "avatar"
                      | "avatarKeys"
                      | "mentalState"
                      | "picture"
                      | "id"
                      | "salut"
                      | "name"
                      | "alias"
                      | "age"
                      | "ageGuessed"
                      | "firstName"
                      | "lastName"
                      | "temporary"
                      | "birthDate"
                    >
                  >;
                  hotDesks?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: "HotDesk" } & {
                          extension?: Maybe<
                            { __typename?: "Extension" } & Pick<Extension, "primeDN">
                          >;
                        }
                      >
                    >
                  >;
                }
            >
          >
        >;
        person?: Maybe<
          { __typename: "Person" } & Pick<
            Person,
            | "id"
            | "name"
            | "salut"
            | "alias"
            | "age"
            | "ageGuessed"
            | "firstName"
            | "lastName"
            | "temporary"
            | "birthDate"
          >
        >;
        topic?: Maybe<{ __typename: "Topic" } & Pick<Topic, "id" | "name">>;
        service?: Maybe<
          { __typename: "Service" } & Pick<Service, "id"> & {
              serviceMeta?: Maybe<
                { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">
              >;
            }
        >;
      }
  >;
};

export type AppointmentDailySubscriptionSubscriptionVariables = Exact<{
  startsAt: Scalars["String"];
  endsAt: Scalars["String"];
}>;

export type AppointmentDailySubscriptionSubscription = { __typename?: "Subscription" } & {
  appointmentDailySubscription?: Maybe<
    Array<
      Maybe<
        { __typename: "Appointment" } & Pick<
          Appointment,
          "id" | "title" | "note" | "date" | "from" | "to" | "deletedAt"
        > & {
            users?: Maybe<
              Array<
                Maybe<
                  { __typename: "User" } & Pick<
                    User,
                    "id" | "active" | "name" | "absentUntil" | "primeDN"
                  > & {
                      person?: Maybe<
                        { __typename: "Person" } & Pick<
                          Person,
                          | "avatar"
                          | "avatarKeys"
                          | "picture"
                          | "mentalState"
                          | "id"
                          | "salut"
                          | "name"
                          | "alias"
                          | "age"
                          | "ageGuessed"
                          | "firstName"
                          | "lastName"
                          | "temporary"
                          | "birthDate"
                        >
                      >;
                      hotDesks?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: "HotDesk" } & {
                              extension?: Maybe<
                                { __typename?: "Extension" } & Pick<Extension, "primeDN">
                              >;
                            }
                          >
                        >
                      >;
                    }
                >
              >
            >;
            person?: Maybe<
              { __typename: "Person" } & Pick<
                Person,
                | "avatarKeys"
                | "mentalState"
                | "id"
                | "salut"
                | "name"
                | "alias"
                | "age"
                | "ageGuessed"
                | "firstName"
                | "lastName"
                | "temporary"
                | "birthDate"
              >
            >;
            topic?: Maybe<{ __typename: "Topic" } & Pick<Topic, "id" | "name">>;
            service?: Maybe<
              { __typename: "Service" } & Pick<Service, "id"> & {
                  serviceMeta?: Maybe<
                    { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type MyAppointmentsSubscriptionSubscriptionVariables = Exact<{
  userId?: InputMaybe<Scalars["Int"]>;
}>;

export type MyAppointmentsSubscriptionSubscription = { __typename?: "Subscription" } & {
  myAppointmentsSubscription?: Maybe<
    Array<
      Maybe<
        { __typename: "Appointment" } & Pick<
          Appointment,
          "id" | "title" | "note" | "date" | "from" | "to" | "deletedAt"
        > & {
            users?: Maybe<
              Array<
                Maybe<
                  { __typename: "User" } & Pick<
                    User,
                    "id" | "active" | "name" | "absentUntil" | "primeDN"
                  > & {
                      person?: Maybe<
                        { __typename: "Person" } & Pick<
                          Person,
                          | "alias"
                          | "avatar"
                          | "avatarKeys"
                          | "picture"
                          | "mentalState"
                          | "id"
                          | "salut"
                          | "name"
                          | "age"
                          | "ageGuessed"
                          | "firstName"
                          | "lastName"
                          | "temporary"
                          | "birthDate"
                        >
                      >;
                      hotDesks?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: "HotDesk" } & {
                              extension?: Maybe<
                                { __typename?: "Extension" } & Pick<Extension, "primeDN">
                              >;
                            }
                          >
                        >
                      >;
                    }
                >
              >
            >;
            task?: Maybe<{ __typename?: "Task" } & Pick<Task, "id" | "finishedAt" | "startedAt">>;
            person?: Maybe<
              { __typename: "Person" } & Pick<
                Person,
                | "avatarKeys"
                | "mentalState"
                | "id"
                | "salut"
                | "name"
                | "alias"
                | "age"
                | "ageGuessed"
                | "firstName"
                | "lastName"
                | "temporary"
                | "birthDate"
              >
            >;
            topic?: Maybe<{ __typename: "Topic" } & Pick<Topic, "id" | "name">>;
            service?: Maybe<
              { __typename: "Service" } & Pick<Service, "id"> & {
                  serviceMeta?: Maybe<
                    { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type PersonAppointmentsSubscriptionSubscriptionVariables = Exact<{
  personId: Scalars["Int"];
}>;

export type PersonAppointmentsSubscriptionSubscription = { __typename?: "Subscription" } & {
  personAppointmentsSubscription?: Maybe<
    Array<
      Maybe<
        { __typename: "Appointment" } & Pick<
          Appointment,
          "id" | "title" | "note" | "date" | "from" | "to" | "deletedAt"
        > & {
            users?: Maybe<
              Array<
                Maybe<
                  { __typename: "User" } & Pick<
                    User,
                    "id" | "active" | "name" | "absentUntil" | "primeDN"
                  > & {
                      person?: Maybe<
                        { __typename: "Person" } & Pick<
                          Person,
                          | "alias"
                          | "avatar"
                          | "avatarKeys"
                          | "picture"
                          | "mentalState"
                          | "id"
                          | "salut"
                          | "name"
                          | "age"
                          | "ageGuessed"
                          | "firstName"
                          | "lastName"
                          | "temporary"
                          | "birthDate"
                        >
                      >;
                      hotDesks?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: "HotDesk" } & {
                              extension?: Maybe<
                                { __typename?: "Extension" } & Pick<Extension, "primeDN">
                              >;
                            }
                          >
                        >
                      >;
                    }
                >
              >
            >;
            person?: Maybe<
              { __typename: "Person" } & Pick<
                Person,
                | "id"
                | "name"
                | "salut"
                | "alias"
                | "age"
                | "ageGuessed"
                | "firstName"
                | "lastName"
                | "temporary"
                | "birthDate"
              >
            >;
            topic?: Maybe<{ __typename: "Topic" } & Pick<Topic, "id" | "name">>;
            service?: Maybe<
              { __typename: "Service" } & Pick<Service, "id"> & {
                  serviceMeta?: Maybe<
                    { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type BusinessObjectSubscriptionSubscriptionVariables = Exact<{
  id: Scalars["Int"];
}>;

export type BusinessObjectSubscriptionSubscription = { __typename?: "Subscription" } & {
  businessObjectSubscription?: Maybe<
    { __typename: "BusinessObject" } & Pick<
      BusinessObject,
      "id" | "name" | "url" | "info" | "accountId"
    > & {
        category?: Maybe<
          { __typename?: "BusinessCategory" } & Pick<BusinessCategory, "id" | "name">
        >;
        address?: Maybe<
          { __typename: "Address" } & Pick<
            Address,
            "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
          >
        >;
        email?: Maybe<
          { __typename: "Email" } & Pick<Email, "id" | "type" | "contactAllowed" | "email">
        >;
        persons?: Maybe<
          Array<
            Maybe<
              { __typename: "Person" } & Pick<
                Person,
                | "id"
                | "name"
                | "alias"
                | "firstName"
                | "lastName"
                | "temporary"
                | "gender"
                | "title"
                | "note"
                | "salut"
                | "avatarKeys"
              >
            >
          >
        >;
        phones?: Maybe<
          Array<
            Maybe<
              { __typename: "Phone" } & Pick<
                Phone,
                | "id"
                | "type"
                | "contactAllowed"
                | "messagingAllowed"
                | "countryCode"
                | "areaCode"
                | "number"
                | "readableNumber"
              >
            >
          >
        >;
        hours?: Maybe<
          Array<Maybe<{ __typename?: "Hour" } & Pick<Hour, "id" | "open" | "close" | "day">>>
        >;
      }
  >;
};

export type BusinessUnitsSubscriptionSubscriptionVariables = Exact<{
  accountId: Scalars["Int"];
}>;

export type BusinessUnitsSubscriptionSubscription = { __typename?: "Subscription" } & {
  businessUnitsSubscription: Array<
    { __typename?: "BusinessUnit" } & Pick<BusinessUnit, "id" | "name">
  >;
};

export type PendingCallsSubscriptionSubscriptionVariables = Exact<{ [key: string]: never }>;

export type PendingCallsSubscriptionSubscription = { __typename?: "Subscription" } & {
  pendingCallsSubscription?: Maybe<
    Array<
      Maybe<
        { __typename?: "Call" } & Pick<
          Call,
          "id" | "readableNumber" | "accountId" | "status" | "callType" | "personActivityId"
        > & {
            personActivity?: Maybe<
              { __typename?: "PersonActivity" } & Pick<
                PersonActivity,
                "id" | "activityType" | "isActive"
              >
            >;
            person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
          }
      >
    >
  >;
};

export type CaseManagementReviewSubscriptionSubscriptionVariables = Exact<{
  personId: Scalars["Int"];
}>;

export type CaseManagementReviewSubscriptionSubscription = { __typename?: "Subscription" } & {
  caseManagementReviewSubscription?: Maybe<
    { __typename?: "CaseManagementReview" } & Pick<CaseManagementReview, "id"> & {
        caseManagementReviewAttributes?: Maybe<
          Array<
            Maybe<
              { __typename?: "CaseManagementReviewAttribute" } & Pick<
                CaseManagementReviewAttribute,
                "id" | "attributeValue" | "lastUpdate"
              > & {
                  user?: Maybe<
                    { __typename: "User" } & Pick<
                      User,
                      "id" | "active" | "name" | "absentUntil" | "primeDN"
                    > & {
                        papershiftWorkingAreas?: Maybe<
                          Array<
                            Maybe<
                              { __typename: "PapershiftWorkingArea" } & Pick<
                                PapershiftWorkingArea,
                                "id" | "name" | "color"
                              >
                            >
                          >
                        >;
                        person?: Maybe<
                          { __typename?: "Person" } & Pick<
                            Person,
                            "id" | "alias" | "name" | "avatar" | "avatarKeys" | "picture"
                          >
                        >;
                        hotDesks?: Maybe<
                          Array<
                            Maybe<
                              { __typename?: "HotDesk" } & {
                                extension?: Maybe<
                                  { __typename?: "Extension" } & Pick<Extension, "primeDN">
                                >;
                              }
                            >
                          >
                        >;
                      }
                  >;
                  clientReviewAttribute?: Maybe<
                    { __typename?: "ClientReviewAttribute" } & Pick<
                      ClientReviewAttribute,
                      "id" | "name"
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type CaseManagementReviewsSubscriptionSubscriptionVariables = Exact<{
  includeValues?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  clientReviewAttributeId?: InputMaybe<Scalars["Int"]>;
}>;

export type CaseManagementReviewsSubscriptionSubscription = { __typename?: "Subscription" } & {
  caseManagementReviewsSubscription?: Maybe<
    Array<
      Maybe<
        { __typename?: "CaseManagementReview" } & Pick<
          CaseManagementReview,
          "id" | "lastUpdate"
        > & {
            caseManagementReviewAttributes?: Maybe<
              Array<
                Maybe<
                  { __typename?: "CaseManagementReviewAttribute" } & Pick<
                    CaseManagementReviewAttribute,
                    "id" | "attributeValue" | "lastUpdate"
                  >
                >
              >
            >;
            caseManager?: Maybe<
              { __typename?: "CaseManager" } & {
                user?: Maybe<
                  { __typename: "User" } & Pick<
                    User,
                    "id" | "active" | "name" | "absentUntil" | "primeDN"
                  > & {
                      papershiftWorkingAreas?: Maybe<
                        Array<
                          Maybe<
                            { __typename: "PapershiftWorkingArea" } & Pick<
                              PapershiftWorkingArea,
                              "id" | "name" | "color"
                            >
                          >
                        >
                      >;
                      person?: Maybe<
                        { __typename?: "Person" } & Pick<
                          Person,
                          "id" | "alias" | "name" | "avatar" | "avatarKeys" | "picture"
                        >
                      >;
                      hotDesks?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: "HotDesk" } & {
                              extension?: Maybe<
                                { __typename?: "Extension" } & Pick<Extension, "primeDN">
                              >;
                            }
                          >
                        >
                      >;
                    }
                >;
              }
            >;
            client?: Maybe<
              { __typename?: "Client" } & Pick<Client, "id"> & {
                  account?: Maybe<{ __typename?: "Account" } & Pick<Account, "id" | "name">>;
                  person?: Maybe<
                    { __typename?: "Person" } & Pick<
                      Person,
                      "id" | "flag" | "name" | "mentalState" | "vip" | "avatarKeys"
                    >
                  >;
                  topics?: Maybe<
                    Array<Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>>
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type CaseManagementReviewAttributeSubscriptionSubscriptionVariables = Exact<{
  caseManagementReviewId: Scalars["Int"];
  clientReviewAttributeId: Scalars["Int"];
}>;

export type CaseManagementReviewAttributeSubscriptionSubscription = {
  __typename?: "Subscription";
} & {
  caseManagementReviewAttributeSubscription?: Maybe<
    { __typename?: "CaseManagementReviewAttribute" } & Pick<
      CaseManagementReviewAttribute,
      "id" | "attributeValue" | "lastUpdate"
    >
  >;
};

export type CaseManagerSubscriptionSubscriptionVariables = Exact<{
  personId: Scalars["Int"];
}>;

export type CaseManagerSubscriptionSubscription = { __typename?: "Subscription" } & {
  caseManagerSubscription?: Maybe<
    { __typename?: "CaseManager" } & Pick<CaseManager, "id"> & {
        user?: Maybe<
          { __typename: "User" } & Pick<
            User,
            "id" | "active" | "name" | "absentUntil" | "primeDN"
          > & {
              papershiftWorkingAreas?: Maybe<
                Array<
                  Maybe<
                    { __typename: "PapershiftWorkingArea" } & Pick<
                      PapershiftWorkingArea,
                      "id" | "name" | "color"
                    >
                  >
                >
              >;
              person?: Maybe<
                { __typename?: "Person" } & Pick<
                  Person,
                  "id" | "alias" | "name" | "avatar" | "avatarKeys" | "picture"
                >
              >;
              hotDesks?: Maybe<
                Array<
                  Maybe<
                    { __typename?: "HotDesk" } & {
                      extension?: Maybe<{ __typename?: "Extension" } & Pick<Extension, "primeDN">>;
                    }
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type LastFinishedChatSubscriptionSubscriptionVariables = Exact<{
  chats: Array<LastFinishedChatInput> | LastFinishedChatInput;
}>;

export type LastFinishedChatSubscriptionSubscription = { __typename?: "Subscription" } & {
  lastFinishedChatSubscription?: Maybe<
    Array<
      Maybe<
        { __typename?: "Chat" } & Pick<Chat, "id" | "chatUserId" | "status" | "team"> & {
            user?: Maybe<{ __typename?: "User" } & Pick<User, "id">>;
          }
      >
    >
  >;
};

export type ActiveChatsSubscriptionSubscriptionVariables = Exact<{ [key: string]: never }>;

export type ActiveChatsSubscriptionSubscription = { __typename?: "Subscription" } & {
  activeChatsSubscription: Array<
    { __typename?: "Chat" } & Pick<Chat, "id" | "chatUserId" | "status" | "team"> & {
        user?: Maybe<{ __typename?: "User" } & Pick<User, "id">>;
      }
  >;
};

export type MyStcClientsSubscriptionSubscriptionVariables = Exact<{ [key: string]: never }>;

export type MyStcClientsSubscriptionSubscription = { __typename?: "Subscription" } & {
  mySTCClientsSubscription?: Maybe<
    Array<
      Maybe<
        { __typename?: "Client" } & Pick<Client, "id"> & {
            person?: Maybe<
              { __typename?: "Person" } & Pick<
                Person,
                "id" | "name" | "lastActivity" | "flag" | "mentalState" | "vip" | "avatarKeys"
              >
            >;
          }
      >
    >
  >;
};

export type GetStcClientsSubscriptionSubscriptionVariables = Exact<{
  userId: Scalars["Int"];
}>;

export type GetStcClientsSubscriptionSubscription = { __typename?: "Subscription" } & {
  getSTCClientsSubscription?: Maybe<
    Array<
      Maybe<
        { __typename?: "Client" } & Pick<Client, "id"> & {
            person?: Maybe<
              { __typename?: "Person" } & Pick<
                Person,
                "id" | "name" | "lastActivity" | "flag" | "mentalState" | "vip" | "avatarKeys"
              >
            >;
          }
      >
    >
  >;
};

export type MyCmClientsSubscriptionSubscriptionVariables = Exact<{ [key: string]: never }>;

export type MyCmClientsSubscriptionSubscription = { __typename?: "Subscription" } & {
  myCMClientsSubscription?: Maybe<
    Array<
      Maybe<
        { __typename?: "Client" } & Pick<Client, "id"> & {
            person?: Maybe<
              { __typename?: "Person" } & Pick<
                Person,
                "id" | "name" | "lastActivity" | "flag" | "mentalState" | "vip" | "avatarKeys"
              >
            >;
          }
      >
    >
  >;
};

export type GetCmClientsSubscriptionSubscriptionVariables = Exact<{
  userId: Scalars["Int"];
}>;

export type GetCmClientsSubscriptionSubscription = { __typename?: "Subscription" } & {
  getCMClientsSubscription?: Maybe<
    Array<
      Maybe<
        { __typename?: "Client" } & Pick<Client, "id"> & {
            person?: Maybe<
              { __typename?: "Person" } & Pick<
                Person,
                "id" | "name" | "lastActivity" | "flag" | "mentalState" | "vip" | "avatarKeys"
              >
            >;
          }
      >
    >
  >;
};

export type RiskHistorySubscriptionSubscriptionVariables = Exact<{
  personId: Scalars["Int"];
}>;

export type RiskHistorySubscriptionSubscription = { __typename?: "Subscription" } & {
  riskHistorySubscription?: Maybe<
    Array<
      Maybe<
        { __typename?: "ClientAttribute" } & Pick<
          ClientAttribute,
          "id" | "attributeValue" | "start" | "lastUpdate"
        > & {
            clientReviewAttribute?: Maybe<
              { __typename?: "ClientReviewAttribute" } & Pick<ClientReviewAttribute, "id">
            >;
            user?: Maybe<
              { __typename?: "User" } & Pick<User, "id"> & {
                  person?: Maybe<
                    { __typename?: "Person" } & Pick<
                      Person,
                      "id" | "alias" | "name" | "avatar" | "avatarKeys" | "picture"
                    >
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type AllMandatesSubscriptionSubscriptionVariables = Exact<{
  active: Scalars["Boolean"];
}>;

export type AllMandatesSubscriptionSubscription = { __typename?: "Subscription" } & {
  allMandatesSubscription?: Maybe<
    Array<
      Maybe<
        { __typename?: "CounselingMandate" } & Pick<
          CounselingMandate,
          "id" | "lastActivity" | "referenceNumber"
        > & {
            counselor?: Maybe<
              { __typename?: "Person" } & Pick<
                Person,
                "id" | "name" | "avatar" | "avatarKeys" | "picture"
              > & {
                  businesses?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name"> & {
                            address?: Maybe<
                              { __typename?: "Address" } & Pick<
                                Address,
                                "id" | "type" | "street" | "city" | "zip"
                              >
                            >;
                          }
                      >
                    >
                  >;
                }
            >;
            service?: Maybe<
              { __typename?: "Service" } & Pick<Service, "id"> & {
                  topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
                  client?: Maybe<
                    { __typename?: "Client" } & Pick<Client, "id"> & {
                        account?: Maybe<{ __typename?: "Account" } & Pick<Account, "id" | "name">>;
                        person?: Maybe<
                          { __typename?: "Person" } & Pick<
                            Person,
                            "id" | "flag" | "name" | "mentalState" | "avatarKeys"
                          >
                        >;
                      }
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type DepartmentsSubscriptionSubscriptionVariables = Exact<{
  accountId: Scalars["Int"];
}>;

export type DepartmentsSubscriptionSubscription = { __typename?: "Subscription" } & {
  departmentsSubscription: Array<
    { __typename?: "Department" } & Pick<Department, "id" | "accountId" | "name" | "main">
  >;
};

export type GetDriveDocumentsSubscriptionSubscriptionVariables = Exact<{
  personIds: Array<Scalars["Int"]> | Scalars["Int"];
}>;

export type GetDriveDocumentsSubscriptionSubscription = { __typename?: "Subscription" } & {
  getDriveDocumentsSubscription: Array<
    { __typename?: "DriveDocument" } & Pick<DriveDocument, "id" | "title" | "link" | "created"> & {
        user?: Maybe<{ __typename?: "User" } & Pick<User, "id" | "name">>;
      }
  >;
};

export type PapershiftShiftsSubscriptionSubscriptionVariables = Exact<{
  startsAt: Scalars["String"];
  endsAt: Scalars["String"];
}>;

export type PapershiftShiftsSubscriptionSubscription = { __typename?: "Subscription" } & {
  papershiftShiftsSubscription?: Maybe<
    Array<
      Maybe<
        { __typename: "PapershiftShift" } & Pick<PapershiftShift, "id" | "startsAt" | "endsAt"> & {
            papershiftWorkingArea?: Maybe<
              { __typename: "PapershiftWorkingArea" } & Pick<
                PapershiftWorkingArea,
                "color" | "id" | "name"
              >
            >;
            users?: Maybe<
              Array<
                Maybe<
                  { __typename: "User" } & Pick<
                    User,
                    "id" | "active" | "name" | "absentUntil" | "primeDN"
                  > & {
                      papershiftWorkingAreas?: Maybe<
                        Array<
                          Maybe<
                            { __typename: "PapershiftWorkingArea" } & Pick<
                              PapershiftWorkingArea,
                              "id" | "name" | "color"
                            >
                          >
                        >
                      >;
                      person?: Maybe<
                        { __typename?: "Person" } & Pick<
                          Person,
                          "id" | "alias" | "name" | "avatar" | "avatarKeys" | "picture"
                        >
                      >;
                      hotDesks?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: "HotDesk" } & {
                              extension?: Maybe<
                                { __typename?: "Extension" } & Pick<Extension, "primeDN">
                              >;
                            }
                          >
                        >
                      >;
                    }
                >
              >
            >;
          }
      >
    >
  >;
};

export type PersonSubscriptionSubscriptionVariables = Exact<{
  id: Scalars["Int"];
}>;

export type PersonSubscriptionSubscription = { __typename?: "Subscription" } & {
  personSubscription: { __typename: "Person" } & Pick<
    Person,
    | "id"
    | "abnormalities"
    | "age"
    | "ageGuessed"
    | "alias"
    | "avatar"
    | "avatarKeys"
    | "picture"
    | "birthDate"
    | "familyStatus"
    | "firstName"
    | "gender"
    | "lastName"
    | "mentalState"
    | "name"
    | "note"
    | "redFlag"
    | "salut"
    | "title"
    | "vip"
    | "yellowFlag"
    | "temporary"
  > & {
      emails?: Maybe<
        Array<
          Maybe<{ __typename: "Email" } & Pick<Email, "id" | "type" | "contactAllowed" | "email">>
        >
      >;
      addresses?: Maybe<
        Array<
          { __typename: "Address" } & Pick<
            Address,
            "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
          >
        >
      >;
      phones?: Maybe<
        Array<
          Maybe<
            { __typename: "Phone" } & Pick<
              Phone,
              | "id"
              | "type"
              | "contactAllowed"
              | "messagingAllowed"
              | "countryCode"
              | "areaCode"
              | "number"
              | "readableNumber"
            >
          >
        >
      >;
      relations?: Maybe<
        Array<
          Maybe<
            { __typename?: "Relationship" } & Pick<
              Relationship,
              "id" | "relationship" | "isEmergency" | "isHousehold"
            > & {
                passivePerson?: Maybe<
                  { __typename: "Person" } & Pick<
                    Person,
                    | "id"
                    | "abnormalities"
                    | "age"
                    | "ageGuessed"
                    | "alias"
                    | "avatar"
                    | "avatarKeys"
                    | "picture"
                    | "birthDate"
                    | "familyStatus"
                    | "firstName"
                    | "gender"
                    | "lastName"
                    | "mentalState"
                    | "name"
                    | "note"
                    | "redFlag"
                    | "salut"
                    | "title"
                    | "vip"
                    | "yellowFlag"
                    | "temporary"
                  > & {
                      client?: Maybe<{ __typename?: "Client" } & Pick<Client, "id">>;
                      addresses?: Maybe<
                        Array<
                          { __typename: "Address" } & Pick<
                            Address,
                            | "id"
                            | "street"
                            | "city"
                            | "zip"
                            | "lat"
                            | "lon"
                            | "type"
                            | "contactAllowed"
                          >
                        >
                      >;
                      phones?: Maybe<
                        Array<
                          Maybe<
                            { __typename: "Phone" } & Pick<
                              Phone,
                              | "id"
                              | "type"
                              | "contactAllowed"
                              | "messagingAllowed"
                              | "countryCode"
                              | "areaCode"
                              | "number"
                              | "readableNumber"
                            >
                          >
                        >
                      >;
                      extras?: Maybe<
                        { __typename?: "PersonExtraFieldType" } & Pick<
                          PersonExtraFieldType,
                          "informationSource" | "hrBusiness"
                        >
                      >;
                    }
                >;
              }
          >
        >
      >;
      client?: Maybe<
        { __typename?: "Client" } & Pick<
          Client,
          | "id"
          | "clientType"
          | "departmentId"
          | "job"
          | "language"
          | "chatUserId"
          | "chatAlias"
          | "vip"
          | "healthInsuranceCompany"
          | "sourceOfInformation"
          | "assistanceId"
          | "yearOfEntry"
        > & {
            department?: Maybe<
              { __typename?: "Department" } & Pick<Department, "name" | "id" | "main">
            >;
            businessUnit?: Maybe<
              { __typename?: "BusinessUnit" } & Pick<BusinessUnit, "id" | "name">
            >;
            account?: Maybe<
              { __typename?: "Account" } & Pick<
                Account,
                "id" | "name" | "isAssistance" | "tariff" | "type" | "validationPattern"
              > & {
                  businessUnits?: Maybe<
                    Array<
                      Maybe<{ __typename?: "BusinessUnit" } & Pick<BusinessUnit, "id" | "name">>
                    >
                  >;
                }
            >;
            topics?: Maybe<Array<Maybe<{ __typename?: "Topic" } & Pick<Topic, "name" | "id">>>>;
          }
      >;
      extras?: Maybe<
        { __typename?: "PersonExtraFieldType" } & Pick<
          PersonExtraFieldType,
          "informationSource" | "hrBusiness"
        >
      >;
    };
};

export type PersonsByBusinessSubscriptionSubscriptionVariables = Exact<{
  businessId: Scalars["Int"];
}>;

export type PersonsByBusinessSubscriptionSubscription = { __typename?: "Subscription" } & {
  personsByBusinessSubscription: Array<
    { __typename: "Person" } & Pick<
      Person,
      | "id"
      | "name"
      | "alias"
      | "firstName"
      | "lastName"
      | "temporary"
      | "gender"
      | "title"
      | "note"
      | "salut"
      | "avatarKeys"
    > & {
        emails?: Maybe<
          Array<
            Maybe<{ __typename: "Email" } & Pick<Email, "id" | "type" | "contactAllowed" | "email">>
          >
        >;
        addresses?: Maybe<
          Array<
            { __typename: "Address" } & Pick<
              Address,
              "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
            >
          >
        >;
        phones?: Maybe<
          Array<
            Maybe<
              { __typename: "Phone" } & Pick<
                Phone,
                | "id"
                | "type"
                | "contactAllowed"
                | "messagingAllowed"
                | "countryCode"
                | "areaCode"
                | "number"
                | "readableNumber"
              >
            >
          >
        >;
      }
  >;
};

export type PushActivitySubscriptionVariables = Exact<{ [key: string]: never }>;

export type PushActivitySubscription = { __typename?: "Subscription" } & {
  pushActivitySubscription?: Maybe<
    { __typename?: "PushActivity" } & Pick<PushActivity, "status" | "callType"> & {
        personActivity?: Maybe<{ __typename: "PersonActivity" } & Pick<PersonActivity, "id">>;
      }
  >;
};

export type PersonActivitiesSubscriptionSubscriptionVariables = Exact<{
  personId: Scalars["Int"];
  topicId?: InputMaybe<Scalars["Int"]>;
}>;

export type PersonActivitiesSubscriptionSubscription = { __typename?: "Subscription" } & {
  personActivitiesSubscription?: Maybe<
    { __typename: "PersonActivity" } & Pick<
      PersonActivity,
      | "id"
      | "activityType"
      | "description"
      | "end"
      | "isActive"
      | "path"
      | "start"
      | "subtitle"
      | "lastUpdate"
      | "tempPerson"
    > & {
        author?: Maybe<
          { __typename: "Person" } & Pick<
            Person,
            "id" | "alias" | "name" | "avatarKeys" | "avatar" | "picture"
          >
        >;
        conversationReport?: Maybe<
          { __typename: "ConversationReport" } & Pick<
            ConversationReport,
            "id" | "finalizedAt" | "mentalState" | "issue"
          >
        >;
        topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
        memo?: Maybe<
          { __typename: "Memo" } & Pick<
            Memo,
            "id" | "note" | "contact" | "status" | "type" | "doneAt"
          > & {
              topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
              person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
              business?: Maybe<
                { __typename?: "BusinessObject" } & Pick<
                  BusinessObject,
                  "id" | "name" | "accountId"
                >
              >;
            }
        >;
        service?: Maybe<
          { __typename: "Service" } & Pick<
            Service,
            | "id"
            | "duedate"
            | "wizardStatus"
            | "wizardStep"
            | "extendedInfo"
            | "status"
            | "createdAt"
            | "lastUpdate"
          > & {
              serviceMeta?: Maybe<
                { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name" | "type">
              >;
            }
        >;
        tasks?: Maybe<
          Array<
            Maybe<
              { __typename: "Task" } & Pick<
                Task,
                "id" | "taskType" | "startedAt" | "finishedAt" | "userId"
              >
            >
          >
        >;
      }
  >;
};

export type AllActivitiesSubscriptionSubscriptionVariables = Exact<{
  personIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>> | InputMaybe<Scalars["Int"]>>;
}>;

export type AllActivitiesSubscriptionSubscription = { __typename?: "Subscription" } & {
  allActivitiesSubscription?: Maybe<
    { __typename: "PersonActivity" } & Pick<
      PersonActivity,
      | "personId"
      | "id"
      | "activityType"
      | "description"
      | "end"
      | "isActive"
      | "path"
      | "start"
      | "subtitle"
      | "lastUpdate"
      | "tempPerson"
    > & {
        author?: Maybe<
          { __typename: "Person" } & Pick<
            Person,
            "id" | "alias" | "name" | "avatarKeys" | "avatar" | "picture"
          >
        >;
        memo?: Maybe<
          { __typename: "Memo" } & Pick<
            Memo,
            "id" | "note" | "contact" | "status" | "type" | "doneAt"
          > & {
              topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
              person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
              business?: Maybe<
                { __typename?: "BusinessObject" } & Pick<
                  BusinessObject,
                  "id" | "name" | "accountId"
                >
              >;
            }
        >;
        person: { __typename?: "Person" } & Pick<
          Person,
          "name" | "businessIds" | "id" | "yellowFlag" | "redFlag" | "temporary"
        > & {
            emails?: Maybe<Array<Maybe<{ __typename?: "Email" } & Pick<Email, "id" | "email">>>>;
            client?: Maybe<
              { __typename?: "Client" } & Pick<Client, "id" | "vip"> & {
                  account?: Maybe<{ __typename?: "Account" } & Pick<Account, "id" | "name">>;
                }
            >;
          };
        serviceResearchObject?: Maybe<
          { __typename: "ServiceResearchObject" } & Pick<
            ServiceResearchObject,
            "id" | "descriptor" | "info" | "note" | "status" | "statusChangeReason"
          > & {
              contactPerson?: Maybe<
                { __typename: "Person" } & Pick<
                  Person,
                  | "id"
                  | "salut"
                  | "name"
                  | "alias"
                  | "age"
                  | "ageGuessed"
                  | "firstName"
                  | "lastName"
                  | "temporary"
                  | "birthDate"
                >
              >;
              businessObject?: Maybe<
                { __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name">
              >;
              service?: Maybe<
                { __typename?: "Service" } & Pick<Service, "id"> & {
                    serviceMeta?: Maybe<
                      { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name" | "type">
                    >;
                  }
              >;
            }
        >;
        conversationReport?: Maybe<
          { __typename: "ConversationReport" } & Pick<
            ConversationReport,
            "id" | "finalizedAt" | "mentalState" | "issue"
          >
        >;
        topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
        tasks?: Maybe<
          Array<
            Maybe<
              { __typename: "Task" } & Pick<
                Task,
                "id" | "taskType" | "startedAt" | "finishedAt" | "userId"
              >
            >
          >
        >;
        service?: Maybe<
          { __typename: "Service" } & Pick<
            Service,
            | "id"
            | "duedate"
            | "wizardStatus"
            | "wizardStep"
            | "extendedInfo"
            | "status"
            | "createdAt"
            | "lastUpdate"
          > & {
              serviceMeta?: Maybe<
                { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name" | "type">
              >;
            }
        >;
      }
  >;
};

export type ActiveActivitiesSubscriptionSubscriptionVariables = Exact<{ [key: string]: never }>;

export type ActiveActivitiesSubscriptionSubscription = { __typename?: "Subscription" } & {
  activeActivitiesSubscription: Array<
    { __typename?: "PersonActivity" } & Pick<
      PersonActivity,
      "id" | "isActive" | "activityType" | "start"
    > & {
        author?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
        chat?: Maybe<{ __typename?: "Chat" } & Pick<Chat, "id" | "chatUserId">>;
        call?: Maybe<{ __typename?: "Call" } & Pick<Call, "id" | "accountId" | "status">>;
        person: { __typename: "Person" } & Pick<
          Person,
          | "temporary"
          | "id"
          | "name"
          | "alias"
          | "ageGuessed"
          | "age"
          | "salut"
          | "avatar"
          | "avatarKeys"
          | "mentalState"
          | "yellowFlag"
          | "redFlag"
          | "vip"
        > & {
            emails?: Maybe<Array<Maybe<{ __typename?: "Email" } & Pick<Email, "id" | "email">>>>;
            phones?: Maybe<
              Array<
                Maybe<
                  { __typename: "Phone" } & Pick<
                    Phone,
                    | "id"
                    | "type"
                    | "contactAllowed"
                    | "messagingAllowed"
                    | "countryCode"
                    | "areaCode"
                    | "number"
                    | "readableNumber"
                  >
                >
              >
            >;
          };
        service?: Maybe<
          { __typename: "Service" } & Pick<
            Service,
            | "id"
            | "duedate"
            | "wizardStatus"
            | "wizardStep"
            | "extendedInfo"
            | "status"
            | "createdAt"
            | "lastUpdate"
          > & {
              serviceMeta?: Maybe<
                { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name" | "type">
              >;
            }
        >;
        serviceResearchObject?: Maybe<
          { __typename: "ServiceResearchObject" } & Pick<
            ServiceResearchObject,
            "id" | "descriptor" | "info" | "note" | "status" | "statusChangeReason"
          > & {
              contactPerson?: Maybe<
                { __typename: "Person" } & Pick<
                  Person,
                  | "id"
                  | "salut"
                  | "name"
                  | "alias"
                  | "age"
                  | "ageGuessed"
                  | "firstName"
                  | "lastName"
                  | "temporary"
                  | "birthDate"
                >
              >;
              businessObject?: Maybe<
                { __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name">
              >;
            }
        >;
      }
  >;
};

export type ServiceSubscriptionSubscriptionVariables = Exact<{
  id: Scalars["Int"];
}>;

export type ServiceSubscriptionSubscription = { __typename?: "Subscription" } & {
  serviceSubscription?: Maybe<
    { __typename: "Service" } & Pick<
      Service,
      | "id"
      | "duedate"
      | "wizardStatus"
      | "wizardStep"
      | "extendedInfo"
      | "status"
      | "createdAt"
      | "lastUpdate"
    > & {
        client?: Maybe<
          { __typename: "Client" } & Pick<Client, "id" | "chatAlias"> & {
              person?: Maybe<
                { __typename: "Person" } & Pick<
                  Person,
                  | "id"
                  | "abnormalities"
                  | "age"
                  | "ageGuessed"
                  | "alias"
                  | "avatar"
                  | "avatarKeys"
                  | "picture"
                  | "birthDate"
                  | "familyStatus"
                  | "firstName"
                  | "gender"
                  | "lastName"
                  | "mentalState"
                  | "name"
                  | "note"
                  | "redFlag"
                  | "salut"
                  | "title"
                  | "vip"
                  | "yellowFlag"
                  | "temporary"
                > & {
                    extras?: Maybe<
                      { __typename?: "PersonExtraFieldType" } & Pick<
                        PersonExtraFieldType,
                        "informationSource" | "hrBusiness"
                      >
                    >;
                  }
              >;
            }
        >;
        hours?: Maybe<
          Array<Maybe<{ __typename?: "Hour" } & Pick<Hour, "id" | "day" | "open" | "close">>>
        >;
        serviceAddresses?: Maybe<
          Array<
            Maybe<
              { __typename: "ServiceAddress" } & Pick<
                ServiceAddress,
                "id" | "radius" | "serviceMetaAttributeId" | "addressId"
              > & {
                  address?: Maybe<
                    { __typename: "Address" } & Pick<
                      Address,
                      "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
                    >
                  >;
                }
            >
          >
        >;
        serviceMeta?: Maybe<
          { __typename: "ServiceMeta" } & Pick<
            ServiceMeta,
            "id" | "description" | "iconName" | "name" | "researchHint" | "type"
          > & {
              serviceMetaAttributes?: Maybe<
                Array<
                  Maybe<
                    { __typename: "ServiceMetaAttribute" } & Pick<
                      ServiceMetaAttribute,
                      | "id"
                      | "headline"
                      | "name"
                      | "type"
                      | "label"
                      | "placeholder"
                      | "defaults"
                      | "multiple"
                      | "required"
                      | "sortIdx"
                      | "contextData"
                    >
                  >
                >
              >;
            }
        >;
        serviceAttributes?: Maybe<
          Array<
            Maybe<
              { __typename: "ServiceAttribute" } & Pick<
                ServiceAttribute,
                "id" | "value" | "order" | "serviceMetaAttributeId"
              > & {
                  serviceMetaAttribute?: Maybe<
                    { __typename: "ServiceMetaAttribute" } & Pick<
                      ServiceMetaAttribute,
                      "id" | "name" | "type" | "label"
                    >
                  >;
                }
            >
          >
        >;
        serviceClients?: Maybe<
          Array<
            Maybe<
              { __typename: "ServiceClient" } & Pick<
                ServiceClient,
                "id" | "serviceMetaAttributeId" | "clientId"
              > & {
                  client?: Maybe<
                    { __typename: "Client" } & Pick<Client, "id" | "chatAlias"> & {
                        person?: Maybe<
                          { __typename: "Person" } & Pick<
                            Person,
                            | "id"
                            | "abnormalities"
                            | "age"
                            | "ageGuessed"
                            | "alias"
                            | "avatar"
                            | "avatarKeys"
                            | "picture"
                            | "birthDate"
                            | "familyStatus"
                            | "firstName"
                            | "gender"
                            | "lastName"
                            | "mentalState"
                            | "name"
                            | "note"
                            | "redFlag"
                            | "salut"
                            | "title"
                            | "vip"
                            | "yellowFlag"
                            | "temporary"
                          > & {
                              extras?: Maybe<
                                { __typename?: "PersonExtraFieldType" } & Pick<
                                  PersonExtraFieldType,
                                  "informationSource" | "hrBusiness"
                                >
                              >;
                            }
                        >;
                      }
                  >;
                }
            >
          >
        >;
        counselingUser?: Maybe<
          { __typename: "CounselingUser" } & Pick<CounselingUser, "id"> & {
              user?: Maybe<
                { __typename: "User" } & Pick<
                  User,
                  "id" | "active" | "name" | "absentUntil" | "primeDN"
                > & {
                    person?: Maybe<
                      { __typename: "Person" } & Pick<
                        Person,
                        | "picture"
                        | "id"
                        | "abnormalities"
                        | "age"
                        | "ageGuessed"
                        | "alias"
                        | "avatar"
                        | "avatarKeys"
                        | "birthDate"
                        | "familyStatus"
                        | "firstName"
                        | "gender"
                        | "lastName"
                        | "mentalState"
                        | "name"
                        | "note"
                        | "redFlag"
                        | "salut"
                        | "title"
                        | "vip"
                        | "yellowFlag"
                        | "temporary"
                      > & {
                          extras?: Maybe<
                            { __typename?: "PersonExtraFieldType" } & Pick<
                              PersonExtraFieldType,
                              "informationSource" | "hrBusiness"
                            >
                          >;
                        }
                    >;
                    papershiftWorkingAreas?: Maybe<
                      Array<
                        Maybe<
                          { __typename: "PapershiftWorkingArea" } & Pick<
                            PapershiftWorkingArea,
                            "id" | "name" | "color"
                          >
                        >
                      >
                    >;
                    hotDesks?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: "HotDesk" } & {
                            extension?: Maybe<
                              { __typename?: "Extension" } & Pick<Extension, "primeDN">
                            >;
                          }
                        >
                      >
                    >;
                  }
              >;
            }
        >;
        counselingMandate?: Maybe<
          { __typename?: "CounselingMandate" } & Pick<CounselingMandate, "id"> & {
              counselor?: Maybe<
                { __typename: "Person" } & Pick<
                  Person,
                  | "id"
                  | "salut"
                  | "name"
                  | "alias"
                  | "age"
                  | "ageGuessed"
                  | "firstName"
                  | "lastName"
                  | "temporary"
                  | "birthDate"
                >
              >;
              business?: Maybe<
                { __typename?: "BusinessObject" } & Pick<BusinessObject, "id" | "name">
              >;
            }
        >;
        topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "summary" | "name">>;
        tasks?: Maybe<
          Array<
            Maybe<
              { __typename?: "Task" } & Pick<Task, "id" | "taskType" | "finishedAt"> & {
                  user?: Maybe<
                    { __typename: "User" } & Pick<
                      User,
                      "id" | "active" | "name" | "absentUntil" | "primeDN"
                    > & {
                        person?: Maybe<
                          { __typename: "Person" } & Pick<
                            Person,
                            "id" | "alias" | "name" | "avatar" | "avatarKeys" | "picture"
                          >
                        >;
                        hotDesks?: Maybe<
                          Array<
                            Maybe<
                              { __typename?: "HotDesk" } & {
                                extension?: Maybe<
                                  { __typename?: "Extension" } & Pick<Extension, "primeDN">
                                >;
                              }
                            >
                          >
                        >;
                      }
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type ServicesSubscriptionSubscriptionVariables = Exact<{
  statusNot?: InputMaybe<Array<InputMaybe<ServiceStatus>> | InputMaybe<ServiceStatus>>;
}>;

export type ServicesSubscriptionSubscription = { __typename?: "Subscription" } & {
  servicesSubscription?: Maybe<
    Array<
      Maybe<
        { __typename?: "Service" } & Pick<Service, "id" | "duedate" | "lastActivity" | "status"> & {
            topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
            serviceMeta?: Maybe<{ __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">>;
            client?: Maybe<
              { __typename?: "Client" } & Pick<Client, "id"> & {
                  account?: Maybe<{ __typename?: "Account" } & Pick<Account, "id" | "name">>;
                  person?: Maybe<
                    { __typename?: "Person" } & Pick<
                      Person,
                      "id" | "avatarKeys" | "flag" | "mentalState" | "name"
                    >
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type ServicesByTypeSubscriptionSubscriptionVariables = Exact<{
  type: ServiceType;
  status?: InputMaybe<Array<ServiceStatus> | ServiceStatus>;
  statusNot?: InputMaybe<Array<ServiceStatus> | ServiceStatus>;
}>;

export type ServicesByTypeSubscriptionSubscription = { __typename?: "Subscription" } & {
  servicesByTypeSubscription?: Maybe<
    Array<
      Maybe<
        { __typename?: "Service" } & Pick<
          Service,
          "id" | "created" | "duedate" | "lastActivity" | "status"
        > & {
            counselingUser?: Maybe<
              { __typename?: "CounselingUser" } & Pick<CounselingUser, "id"> & {
                  user?: Maybe<
                    { __typename?: "User" } & Pick<User, "id"> & {
                        papershiftWorkingAreas?: Maybe<
                          Array<
                            Maybe<
                              { __typename: "PapershiftWorkingArea" } & Pick<
                                PapershiftWorkingArea,
                                "id" | "name" | "color"
                              >
                            >
                          >
                        >;
                        person?: Maybe<
                          { __typename?: "Person" } & Pick<
                            Person,
                            "id" | "alias" | "avatar" | "avatarKeys" | "picture" | "name"
                          >
                        >;
                      }
                  >;
                }
            >;
            topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
            serviceMeta?: Maybe<{ __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">>;
            client?: Maybe<
              { __typename?: "Client" } & Pick<Client, "id"> & {
                  account?: Maybe<{ __typename?: "Account" } & Pick<Account, "id" | "name">>;
                  person?: Maybe<
                    { __typename?: "Person" } & Pick<
                      Person,
                      "id" | "avatarKeys" | "flag" | "mentalState" | "name"
                    >
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type ServiceResearchObjectSubscriptionSubscriptionVariables = Exact<{
  serviceId: Scalars["Int"];
}>;

export type ServiceResearchObjectSubscriptionSubscription = { __typename?: "Subscription" } & {
  serviceResearchObjectSubscription?: Maybe<
    Array<
      Maybe<
        { __typename: "ServiceResearchObject" } & Pick<
          ServiceResearchObject,
          "id" | "descriptor" | "info" | "note" | "status" | "statusChangeReason"
        > & {
            service?: Maybe<
              { __typename?: "Service" } & Pick<Service, "id"> & {
                  serviceAddresses?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: "ServiceAddress" } & Pick<
                          ServiceAddress,
                          "id" | "radius"
                        > & {
                            address?: Maybe<
                              { __typename: "Address" } & Pick<
                                Address,
                                | "id"
                                | "street"
                                | "city"
                                | "zip"
                                | "lat"
                                | "lon"
                                | "type"
                                | "contactAllowed"
                              >
                            >;
                          }
                      >
                    >
                  >;
                }
            >;
            contactPerson?: Maybe<
              { __typename: "Person" } & Pick<
                Person,
                | "id"
                | "name"
                | "alias"
                | "firstName"
                | "lastName"
                | "temporary"
                | "gender"
                | "title"
                | "note"
                | "salut"
                | "avatarKeys"
              >
            >;
            businessObject?: Maybe<
              { __typename: "BusinessObject" } & Pick<
                BusinessObject,
                "id" | "name" | "url" | "info" | "accountId"
              > & {
                  address?: Maybe<
                    { __typename: "Address" } & Pick<
                      Address,
                      "id" | "street" | "city" | "zip" | "lat" | "lon" | "type" | "contactAllowed"
                    >
                  >;
                  persons?: Maybe<
                    Array<
                      Maybe<
                        { __typename: "Person" } & Pick<
                          Person,
                          | "id"
                          | "name"
                          | "alias"
                          | "firstName"
                          | "lastName"
                          | "temporary"
                          | "gender"
                          | "title"
                          | "note"
                          | "salut"
                          | "avatarKeys"
                        >
                      >
                    >
                  >;
                  email?: Maybe<
                    { __typename: "Email" } & Pick<
                      Email,
                      "id" | "type" | "contactAllowed" | "email"
                    >
                  >;
                  phones?: Maybe<
                    Array<
                      Maybe<
                        { __typename: "Phone" } & Pick<
                          Phone,
                          | "id"
                          | "type"
                          | "contactAllowed"
                          | "messagingAllowed"
                          | "countryCode"
                          | "areaCode"
                          | "number"
                          | "readableNumber"
                        >
                      >
                    >
                  >;
                  hours?: Maybe<
                    Array<
                      Maybe<{ __typename?: "Hour" } & Pick<Hour, "id" | "open" | "close" | "day">>
                    >
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type StatisticItemsByReportSubscriptionSubscriptionVariables = Exact<{
  reportId: Scalars["Int"];
}>;

export type StatisticItemsByReportSubscriptionSubscription = { __typename?: "Subscription" } & {
  statisticItemsByReportSubscription?: Maybe<
    Array<Maybe<{ __typename?: "StatisticItem" } & Pick<StatisticItem, "id" | "name">>>
  >;
};

export type TaskSubscriptionSubscriptionVariables = Exact<{ [key: string]: never }>;

export type TaskSubscriptionSubscription = { __typename?: "Subscription" } & {
  taskSubscription?: Maybe<
    { __typename: "Task" } & Pick<
      Task,
      | "id"
      | "dueDate"
      | "finishedAt"
      | "startedAt"
      | "taskType"
      | "snoozeUntil"
      | "path"
      | "title"
      | "created"
      | "note"
      | "team"
    > & {
        userPapershiftWorkingAreas?: Maybe<
          Array<Maybe<{ __typename?: "PapershiftWorkingArea" } & Pick<PapershiftWorkingArea, "id">>>
        >;
        person?: Maybe<
          { __typename?: "Person" } & Pick<
            Person,
            | "id"
            | "name"
            | "avatar"
            | "avatarKeys"
            | "mentalState"
            | "yellowFlag"
            | "redFlag"
            | "vip"
            | "temporary"
          >
        >;
        author?: Maybe<
          { __typename?: "Person" } & Pick<
            Person,
            "id" | "name" | "avatarKeys" | "avatar" | "picture"
          >
        >;
        creator?: Maybe<
          { __typename?: "Person" } & Pick<
            Person,
            "id" | "name" | "avatarKeys" | "avatar" | "picture"
          >
        >;
        report?: Maybe<
          { __typename?: "ConversationReport" } & Pick<ConversationReport, "id" | "mentalState"> & {
              personActivity?: Maybe<
                { __typename?: "PersonActivity" } & Pick<PersonActivity, "id" | "start" | "end">
              >;
            }
        >;
        topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
        service?: Maybe<
          { __typename?: "Service" } & Pick<Service, "id"> & {
              serviceMeta?: Maybe<
                { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">
              >;
            }
        >;
        personActivity?: Maybe<
          { __typename?: "PersonActivity" } & Pick<
            PersonActivity,
            "id" | "activityType" | "description" | "end" | "start"
          > & {
              conversationReport?: Maybe<
                { __typename?: "ConversationReport" } & Pick<
                  ConversationReport,
                  "id" | "mentalState"
                >
              >;
              service?: Maybe<
                { __typename?: "Service" } & Pick<Service, "id" | "wizardStatus" | "status"> & {
                    serviceMeta?: Maybe<
                      { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name" | "type">
                    >;
                  }
              >;
              call?: Maybe<{ __typename?: "Call" } & Pick<Call, "id" | "readableNumber">>;
              memo?: Maybe<
                { __typename?: "Memo" } & Pick<Memo, "id" | "note" | "contact" | "status"> & {
                    topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
                    person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
                  }
              >;
            }
        >;
      }
  >;
};

export type CurrentTasksSubscriptionSubscriptionVariables = Exact<{
  pool?: InputMaybe<Scalars["Boolean"]>;
  userId?: InputMaybe<Scalars["Int"]>;
  withSnoozed?: InputMaybe<Scalars["Boolean"]>;
  unassigned?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CurrentTasksSubscriptionSubscription = { __typename?: "Subscription" } & {
  currentTasksSubscription?: Maybe<
    Array<
      Maybe<
        { __typename?: "Task" } & Pick<
          Task,
          | "id"
          | "dueDate"
          | "finishedAt"
          | "startedAt"
          | "taskType"
          | "team"
          | "snoozeUntil"
          | "path"
          | "title"
          | "created"
          | "note"
        > & {
            person?: Maybe<
              { __typename?: "Person" } & Pick<
                Person,
                | "id"
                | "name"
                | "avatar"
                | "avatarKeys"
                | "mentalState"
                | "yellowFlag"
                | "redFlag"
                | "temporary"
                | "vip"
              >
            >;
            author?: Maybe<
              { __typename?: "Person" } & Pick<
                Person,
                "id" | "name" | "avatarKeys" | "avatar" | "picture"
              >
            >;
            creator?: Maybe<
              { __typename?: "Person" } & Pick<
                Person,
                "id" | "name" | "avatarKeys" | "avatar" | "picture"
              >
            >;
            topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
            service?: Maybe<
              { __typename?: "Service" } & Pick<Service, "id"> & {
                  serviceMeta?: Maybe<
                    { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name">
                  >;
                }
            >;
            report?: Maybe<
              { __typename?: "ConversationReport" } & Pick<
                ConversationReport,
                "id" | "mentalState"
              > & {
                  personActivity?: Maybe<
                    { __typename?: "PersonActivity" } & Pick<PersonActivity, "id" | "start" | "end">
                  >;
                }
            >;
            personActivity?: Maybe<
              { __typename?: "PersonActivity" } & Pick<
                PersonActivity,
                "id" | "activityType" | "description" | "end" | "start"
              > & {
                  conversationReport?: Maybe<
                    { __typename?: "ConversationReport" } & Pick<
                      ConversationReport,
                      "id" | "mentalState"
                    >
                  >;
                  service?: Maybe<
                    { __typename?: "Service" } & Pick<Service, "id" | "wizardStatus" | "status"> & {
                        serviceMeta?: Maybe<
                          { __typename?: "ServiceMeta" } & Pick<ServiceMeta, "id" | "name" | "type">
                        >;
                      }
                  >;
                  call?: Maybe<{ __typename?: "Call" } & Pick<Call, "id" | "readableNumber">>;
                  memo?: Maybe<
                    { __typename?: "Memo" } & Pick<Memo, "id" | "note" | "contact" | "status"> & {
                        topic?: Maybe<{ __typename?: "Topic" } & Pick<Topic, "id" | "name">>;
                        person?: Maybe<{ __typename?: "Person" } & Pick<Person, "id" | "name">>;
                      }
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type CurrentTaskCountSubscriptionSubscriptionVariables = Exact<{
  pool?: InputMaybe<Scalars["Boolean"]>;
  userId?: InputMaybe<Scalars["Int"]>;
  withSnoozed?: InputMaybe<Scalars["Boolean"]>;
  unassigned?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CurrentTaskCountSubscriptionSubscription = { __typename?: "Subscription" } & {
  currentTasksSubscription?: Maybe<Array<Maybe<{ __typename?: "Task" } & Pick<Task, "id">>>>;
};

export type UserSubscriptionSubscriptionVariables = Exact<{
  id: Scalars["Int"];
}>;

export type UserSubscriptionSubscription = { __typename?: "Subscription" } & {
  userSubscription?: Maybe<
    { __typename: "User" } & Pick<
      User,
      "teams" | "id" | "active" | "name" | "absentUntil" | "primeDN"
    > & {
        person?: Maybe<
          { __typename: "Person" } & Pick<
            Person,
            | "id"
            | "abnormalities"
            | "age"
            | "ageGuessed"
            | "alias"
            | "avatar"
            | "avatarKeys"
            | "picture"
            | "birthDate"
            | "familyStatus"
            | "firstName"
            | "gender"
            | "lastName"
            | "mentalState"
            | "name"
            | "note"
            | "redFlag"
            | "salut"
            | "title"
            | "vip"
            | "yellowFlag"
            | "temporary"
          > & {
              extras?: Maybe<
                { __typename?: "PersonExtraFieldType" } & Pick<
                  PersonExtraFieldType,
                  "informationSource" | "hrBusiness"
                >
              >;
            }
        >;
        papershiftWorkingAreas?: Maybe<
          Array<
            Maybe<
              { __typename: "PapershiftWorkingArea" } & Pick<
                PapershiftWorkingArea,
                "id" | "name" | "color"
              >
            >
          >
        >;
        hotDesks?: Maybe<
          Array<
            Maybe<
              { __typename?: "HotDesk" } & {
                extension?: Maybe<{ __typename?: "Extension" } & Pick<Extension, "primeDN">>;
              }
            >
          >
        >;
      }
  >;
};

export type UserFromPersonSubscriptionSubscriptionVariables = Exact<{
  personId: Scalars["Int"];
}>;

export type UserFromPersonSubscriptionSubscription = { __typename?: "Subscription" } & {
  userFromPersonSubscription?: Maybe<
    { __typename: "User" } & Pick<
      User,
      "teams" | "id" | "active" | "name" | "absentUntil" | "primeDN"
    > & {
        person?: Maybe<
          { __typename: "Person" } & Pick<
            Person,
            | "id"
            | "abnormalities"
            | "age"
            | "ageGuessed"
            | "alias"
            | "avatar"
            | "avatarKeys"
            | "picture"
            | "birthDate"
            | "familyStatus"
            | "firstName"
            | "gender"
            | "lastName"
            | "mentalState"
            | "name"
            | "note"
            | "redFlag"
            | "salut"
            | "title"
            | "vip"
            | "yellowFlag"
            | "temporary"
          > & {
              extras?: Maybe<
                { __typename?: "PersonExtraFieldType" } & Pick<
                  PersonExtraFieldType,
                  "informationSource" | "hrBusiness"
                >
              >;
            }
        >;
        papershiftWorkingAreas?: Maybe<
          Array<
            Maybe<
              { __typename: "PapershiftWorkingArea" } & Pick<
                PapershiftWorkingArea,
                "id" | "name" | "color"
              >
            >
          >
        >;
        hotDesks?: Maybe<
          Array<
            Maybe<
              { __typename?: "HotDesk" } & {
                extension?: Maybe<{ __typename?: "Extension" } & Pick<Extension, "primeDN">>;
              }
            >
          >
        >;
      }
  >;
};

export const EmailFragmentDoc = gql`
  fragment email on Email {
    __typename
    id
    type
    contactAllowed
    email
  }
`;
export const PhoneFragmentDoc = gql`
  fragment phone on Phone {
    __typename
    id
    type
    contactAllowed
    messagingAllowed
    countryCode
    areaCode
    number
    readableNumber
  }
`;
export const AccountFragmentDoc = gql`
  fragment account on Account {
    __typename
    id
    chatRegistrationPin
    coaching
    contractEnd
    contractStart
    employeesCount
    executivesCount
    externalRef
    lifeworksId
    helpline
    helpline2
    helplineInternational
    remarks
    nFreeSTCs
    serviceTimes
    usageRate
    freeSessions
    industry {
      id
      name
    }
    isAssistance
    name
    pin
    reporting
    tariff
    type
    updatedAt
    usageScope
    validationPattern
    serviceRegion
  }
`;
export const AccountMinimalFragmentDoc = gql`
  fragment accountMinimal on Account {
    __typename
    id
    name
    isAssistance
    validationPattern
    tariff
    contractEnd
  }
`;
export const AppointmentFragmentDoc = gql`
  fragment appointment on Appointment {
    __typename
    id
    title
    note
    date
    from
    to
    deletedAt
    topic {
      __typename
      id
      name
    }
    service {
      __typename
      id
      serviceMeta {
        id
        name
      }
    }
  }
`;
export const BusinessObjectDataFragmentDoc = gql`
  fragment businessObjectData on BusinessObject {
    __typename
    id
    name
    url
    info
    accountId
  }
`;
export const BaseReportFragmentDoc = gql`
  fragment baseReport on ConversationReport {
    __typename
    id
    agreement
    issue
    mentalDescription
    mentalState
    reportType
    status
    summary
    risk
    riskInfo
    cmCheckMessage
    doneAt
    lifeworksService
    lifeworksServiceIssue
    lifeworksServiceSubIssue
  }
`;
export const UserPersonFragmentDoc = gql`
  fragment userPerson on Person {
    __typename
    id
    alias
    name
    avatar
    avatarKeys
    picture
  }
`;
export const ReadReportFragmentDoc = gql`
  fragment readReport on ConversationReport {
    __typename
    ...baseReport
    author {
      ...userPerson
    }
    statistics {
      id
      name
      group {
        id
        name
        type
      }
    }
    conversationReportReviewAttributes {
      id
      attributeValue
      clientReviewAttributeId
      clientReviewAttribute {
        id
        label
        name
        type
      }
    }
    clientAttributes {
      id
      clientReviewAttributeId
    }
    connectedServices {
      id
    }
  }
  ${BaseReportFragmentDoc}
  ${UserPersonFragmentDoc}
`;
export const GMailMessageFragmentDoc = gql`
  fragment gMailMessage on GMailMessage {
    __typename
    id
    incoming
    historyId
    messageId
    threadId
    body
    date
    attachments {
      attachmentId
      filename
      mimeType
    }
    payload {
      from
      to
      subject
      returnPath
    }
  }
`;
export const MemoFragmentDoc = gql`
  fragment memo on Memo {
    __typename
    id
    note
    contact
    status
    type
    doneAt
    topic {
      id
      name
    }
    person {
      id
      name
    }
    business {
      id
      name
      accountId
    }
  }
`;
export const PapershiftShiftFragmentDoc = gql`
  fragment papershiftShift on PapershiftShift {
    __typename
    id
    startsAt
    endsAt
  }
`;
export const BasePersonFragmentDoc = gql`
  fragment basePerson on Person {
    __typename
    id
    salut
    name
    alias
    age
    ageGuessed
    firstName
    lastName
    temporary
    birthDate
  }
`;
export const BusinessPersonFragmentDoc = gql`
  fragment businessPerson on Person {
    __typename
    id
    name
    alias
    firstName
    lastName
    temporary
    gender
    title
    note
    salut
    avatarKeys
  }
`;
export const ClientPersonFragmentDoc = gql`
  fragment clientPerson on Person {
    __typename
    id
    name
    alias
    ageGuessed
    age
    salut
    avatar
    avatarKeys
    mentalState
    yellowFlag
    redFlag
    vip
  }
`;
export const CounselorPersonFragmentDoc = gql`
  fragment counselorPerson on Person {
    __typename
    id
    name
    salut
    avatarKeys
    picture
    title
    firstName
    lastName
    gender
  }
`;
export const ConversationReportFragmentDoc = gql`
  fragment conversationReport on ConversationReport {
    __typename
    id
    finalizedAt
    mentalState
    issue
  }
`;
export const ActivityFragmentDoc = gql`
  fragment activity on PersonActivity {
    __typename
    id
    activityType
    description
    end
    isActive
    path
    start
    subtitle
    lastUpdate
    tempPerson
  }
`;
export const AuthorFragmentDoc = gql`
  fragment author on Person {
    __typename
    id
    alias
    name
    avatarKeys
    avatar
    picture
  }
`;
export const TaskFragmentDoc = gql`
  fragment task on Task {
    __typename
    id
    taskType
    startedAt
    finishedAt
    userId
  }
`;
export const ServiceBaseFragmentDoc = gql`
  fragment serviceBase on Service {
    __typename
    id
    duedate
    wizardStatus
    wizardStep
    extendedInfo
    status
    createdAt
    lastUpdate
  }
`;
export const AddressFragmentDoc = gql`
  fragment address on Address {
    __typename
    id
    street
    city
    zip
    lat
    lon
    type
    contactAllowed
  }
`;
export const ServiceAddressesFragmentDoc = gql`
  fragment serviceAddresses on ServiceAddress {
    __typename
    id
    radius
    serviceMetaAttributeId
    addressId
    address {
      ...address
    }
  }
  ${AddressFragmentDoc}
`;
export const ServiceMetaAttributesFragmentDoc = gql`
  fragment serviceMetaAttributes on ServiceMetaAttribute {
    __typename
    id
    headline
    name
    type
    label
    placeholder
    defaults
    multiple
    required
    sortIdx
    contextData
  }
`;
export const ServiceMetaFragmentDoc = gql`
  fragment serviceMeta on ServiceMeta {
    __typename
    id
    description
    iconName
    name
    researchHint
    type
    serviceMetaAttributes {
      ...serviceMetaAttributes
    }
  }
  ${ServiceMetaAttributesFragmentDoc}
`;
export const ServiceMetaAttributeFragmentDoc = gql`
  fragment serviceMetaAttribute on ServiceMetaAttribute {
    __typename
    id
    name
    type
    label
  }
`;
export const ServiceAttributesFragmentDoc = gql`
  fragment serviceAttributes on ServiceAttribute {
    __typename
    id
    value
    order
    serviceMetaAttributeId
    serviceMetaAttribute {
      ...serviceMetaAttribute
    }
  }
  ${ServiceMetaAttributeFragmentDoc}
`;
export const PersonFragmentDoc = gql`
  fragment person on Person {
    __typename
    id
    abnormalities
    age
    ageGuessed
    alias
    avatar
    avatarKeys
    picture
    birthDate
    familyStatus
    firstName
    gender
    lastName
    mentalState
    name
    note
    redFlag
    salut
    title
    vip
    yellowFlag
    temporary
    extras {
      informationSource
      hrBusiness
    }
  }
`;
export const ClientFragmentDoc = gql`
  fragment client on Client {
    __typename
    id
    chatAlias
    person {
      ...person
    }
  }
  ${PersonFragmentDoc}
`;
export const ServiceClientsFragmentDoc = gql`
  fragment serviceClients on ServiceClient {
    __typename
    id
    serviceMetaAttributeId
    clientId
    client {
      ...client
    }
  }
  ${ClientFragmentDoc}
`;
export const UserFragmentDoc = gql`
  fragment user on User {
    __typename
    id
    active
    name
    absentUntil
    primeDN
    hotDesks {
      extension {
        primeDN
      }
    }
  }
`;
export const PapershiftWorkingAreaFragmentDoc = gql`
  fragment papershiftWorkingArea on PapershiftWorkingArea {
    __typename
    id
    name
    color
  }
`;
export const CounselingUserFragmentDoc = gql`
  fragment counselingUser on CounselingUser {
    __typename
    id
    user {
      ...user
      person {
        ...person
        picture
      }
      papershiftWorkingAreas {
        ...papershiftWorkingArea
      }
    }
  }
  ${UserFragmentDoc}
  ${PersonFragmentDoc}
  ${PapershiftWorkingAreaFragmentDoc}
`;
export const ServiceResearchObjectFragmentDoc = gql`
  fragment serviceResearchObject on ServiceResearchObject {
    __typename
    id
    descriptor
    info
    note
    status
    statusChangeReason
  }
`;
export const TaskExtendedFragmentDoc = gql`
  fragment taskExtended on Task {
    __typename
    id
    dueDate
    finishedAt
    startedAt
    taskType
    snoozeUntil
    path
    title
    created
    note
    team
    person {
      id
      name
      avatar
      avatarKeys
      mentalState
      yellowFlag
      redFlag
      vip
      temporary
    }
    author {
      id
      name
      avatarKeys
      avatar
      picture
    }
    creator {
      id
      name
      avatarKeys
      avatar
      picture
    }
    report {
      id
      mentalState
      personActivity {
        id
        start
        end
      }
    }
    topic {
      id
      name
    }
    service {
      id
      serviceMeta {
        id
        name
      }
    }
    personActivity {
      id
      activityType
      description
      end
      start
      conversationReport {
        id
        mentalState
      }
      service {
        id
        wizardStatus
        status
        serviceMeta {
          id
          name
          type
        }
      }
      call {
        id
        readableNumber
      }
      memo {
        id
        note
        contact
        status
        topic {
          id
          name
        }
        person {
          id
          name
        }
      }
    }
  }
`;
export const BaseUserFragmentDoc = gql`
  fragment baseUser on User {
    __typename
    id
    active
    name
    absentUntil
    primeDN
  }
`;
export const CreateAccountDocument = gql`
  mutation createAccount($accountInput: AccountInsertInput!) {
    createAccount(accountInput: $accountInput) {
      ...account
    }
  }
  ${AccountFragmentDoc}
`;
export type CreateAccountMutationFn = Apollo.MutationFunction<
  CreateAccountMutation,
  CreateAccountMutationVariables
>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      accountInput: // value for 'accountInput'
 *   },
 * });
 */
export function useCreateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(
    CreateAccountDocument,
    options,
  );
}
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<
  CreateAccountMutation,
  CreateAccountMutationVariables
>;
export const UpdateAccountDocument = gql`
  mutation updateAccount($id: Int!, $input: AccountUpdateInput) {
    updateAccount(id: $id, input: $input) {
      ...account
      accountServiceModules {
        id
        name
        serviceCategoryId
      }
      parent {
        id
        name
      }
      business {
        ...businessObjectData
        address {
          ...address
        }
        email {
          ...email
        }
        persons {
          ...basePerson
        }
        phones {
          ...phone
        }
      }
    }
  }
  ${AccountFragmentDoc}
  ${BusinessObjectDataFragmentDoc}
  ${AddressFragmentDoc}
  ${EmailFragmentDoc}
  ${BasePersonFragmentDoc}
  ${PhoneFragmentDoc}
`;
export type UpdateAccountMutationFn = Apollo.MutationFunction<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(
    UpdateAccountDocument,
    options,
  );
}
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;
export const CreateAddressesDocument = gql`
  mutation createAddresses($personId: ID!, $addressesInput: [AddressInput!]!) {
    createAddresses(personId: $personId, addressesInput: $addressesInput) {
      ...address
    }
  }
  ${AddressFragmentDoc}
`;
export type CreateAddressesMutationFn = Apollo.MutationFunction<
  CreateAddressesMutation,
  CreateAddressesMutationVariables
>;

/**
 * __useCreateAddressesMutation__
 *
 * To run a mutation, you first call `useCreateAddressesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAddressesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAddressesMutation, { data, loading, error }] = useCreateAddressesMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      addressesInput: // value for 'addressesInput'
 *   },
 * });
 */
export function useCreateAddressesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAddressesMutation,
    CreateAddressesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAddressesMutation, CreateAddressesMutationVariables>(
    CreateAddressesDocument,
    options,
  );
}
export type CreateAddressesMutationHookResult = ReturnType<typeof useCreateAddressesMutation>;
export type CreateAddressesMutationResult = Apollo.MutationResult<CreateAddressesMutation>;
export type CreateAddressesMutationOptions = Apollo.BaseMutationOptions<
  CreateAddressesMutation,
  CreateAddressesMutationVariables
>;
export const DeleteAddressDocument = gql`
  mutation deleteAddress($id: ID!) {
    deleteAddress(id: $id)
  }
`;
export type DeleteAddressMutationFn = Apollo.MutationFunction<
  DeleteAddressMutation,
  DeleteAddressMutationVariables
>;

/**
 * __useDeleteAddressMutation__
 *
 * To run a mutation, you first call `useDeleteAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAddressMutation, { data, loading, error }] = useDeleteAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAddressMutation, DeleteAddressMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAddressMutation, DeleteAddressMutationVariables>(
    DeleteAddressDocument,
    options,
  );
}
export type DeleteAddressMutationHookResult = ReturnType<typeof useDeleteAddressMutation>;
export type DeleteAddressMutationResult = Apollo.MutationResult<DeleteAddressMutation>;
export type DeleteAddressMutationOptions = Apollo.BaseMutationOptions<
  DeleteAddressMutation,
  DeleteAddressMutationVariables
>;
export const UpdateAddressesDocument = gql`
  mutation updateAddresses($personId: ID!, $addressesInput: [AddressInput!]!) {
    updateAddresses(personId: $personId, addressesInput: $addressesInput) {
      ...address
    }
  }
  ${AddressFragmentDoc}
`;
export type UpdateAddressesMutationFn = Apollo.MutationFunction<
  UpdateAddressesMutation,
  UpdateAddressesMutationVariables
>;

/**
 * __useUpdateAddressesMutation__
 *
 * To run a mutation, you first call `useUpdateAddressesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressesMutation, { data, loading, error }] = useUpdateAddressesMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      addressesInput: // value for 'addressesInput'
 *   },
 * });
 */
export function useUpdateAddressesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAddressesMutation,
    UpdateAddressesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAddressesMutation, UpdateAddressesMutationVariables>(
    UpdateAddressesDocument,
    options,
  );
}
export type UpdateAddressesMutationHookResult = ReturnType<typeof useUpdateAddressesMutation>;
export type UpdateAddressesMutationResult = Apollo.MutationResult<UpdateAddressesMutation>;
export type UpdateAddressesMutationOptions = Apollo.BaseMutationOptions<
  UpdateAddressesMutation,
  UpdateAddressesMutationVariables
>;
export const CreateAppointmentDocument = gql`
  mutation createAppointment($appointmentInput: AppointmentInput!) {
    createAppointment(appointmentInput: $appointmentInput) {
      ...appointment
      users {
        id
        person {
          ...basePerson
          avatarKeys
          picture
        }
      }
      person {
        id
        name
      }
    }
  }
  ${AppointmentFragmentDoc}
  ${BasePersonFragmentDoc}
`;
export type CreateAppointmentMutationFn = Apollo.MutationFunction<
  CreateAppointmentMutation,
  CreateAppointmentMutationVariables
>;

/**
 * __useCreateAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentMutation, { data, loading, error }] = useCreateAppointmentMutation({
 *   variables: {
 *      appointmentInput: // value for 'appointmentInput'
 *   },
 * });
 */
export function useCreateAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAppointmentMutation,
    CreateAppointmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAppointmentMutation, CreateAppointmentMutationVariables>(
    CreateAppointmentDocument,
    options,
  );
}
export type CreateAppointmentMutationHookResult = ReturnType<typeof useCreateAppointmentMutation>;
export type CreateAppointmentMutationResult = Apollo.MutationResult<CreateAppointmentMutation>;
export type CreateAppointmentMutationOptions = Apollo.BaseMutationOptions<
  CreateAppointmentMutation,
  CreateAppointmentMutationVariables
>;
export const UpdateAppointmentDocument = gql`
  mutation updateAppointment($appointmentInput: UpdateAppointmentInput!) {
    updateAppointment(appointmentInput: $appointmentInput) {
      ...appointment
      users {
        id
        person {
          ...basePerson
          avatarKeys
          picture
        }
      }
      person {
        id
        name
      }
    }
  }
  ${AppointmentFragmentDoc}
  ${BasePersonFragmentDoc}
`;
export type UpdateAppointmentMutationFn = Apollo.MutationFunction<
  UpdateAppointmentMutation,
  UpdateAppointmentMutationVariables
>;

/**
 * __useUpdateAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentMutation, { data, loading, error }] = useUpdateAppointmentMutation({
 *   variables: {
 *      appointmentInput: // value for 'appointmentInput'
 *   },
 * });
 */
export function useUpdateAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppointmentMutation,
    UpdateAppointmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>(
    UpdateAppointmentDocument,
    options,
  );
}
export type UpdateAppointmentMutationHookResult = ReturnType<typeof useUpdateAppointmentMutation>;
export type UpdateAppointmentMutationResult = Apollo.MutationResult<UpdateAppointmentMutation>;
export type UpdateAppointmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppointmentMutation,
  UpdateAppointmentMutationVariables
>;
export const DeleteAppointmentDocument = gql`
  mutation deleteAppointment($id: String!) {
    deleteAppointment(id: $id) {
      ...appointment
      users {
        id
        person {
          ...basePerson
          avatarKeys
          picture
        }
      }
      person {
        id
        name
      }
    }
  }
  ${AppointmentFragmentDoc}
  ${BasePersonFragmentDoc}
`;
export type DeleteAppointmentMutationFn = Apollo.MutationFunction<
  DeleteAppointmentMutation,
  DeleteAppointmentMutationVariables
>;

/**
 * __useDeleteAppointmentMutation__
 *
 * To run a mutation, you first call `useDeleteAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppointmentMutation, { data, loading, error }] = useDeleteAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAppointmentMutation,
    DeleteAppointmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAppointmentMutation, DeleteAppointmentMutationVariables>(
    DeleteAppointmentDocument,
    options,
  );
}
export type DeleteAppointmentMutationHookResult = ReturnType<typeof useDeleteAppointmentMutation>;
export type DeleteAppointmentMutationResult = Apollo.MutationResult<DeleteAppointmentMutation>;
export type DeleteAppointmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteAppointmentMutation,
  DeleteAppointmentMutationVariables
>;
export const UpdateBusinessObjectDocument = gql`
  mutation updateBusinessObject($id: Int!, $input: BusinessObjectUpdateInput) {
    updateBusinessObject(id: $id, input: $input) {
      ...businessObjectData
      category {
        id
        name
      }
      address {
        ...address
      }
      email {
        ...email
      }
      persons {
        ...basePerson
      }
      phones {
        ...phone
      }
      hours {
        id
        open
        close
        day
      }
    }
  }
  ${BusinessObjectDataFragmentDoc}
  ${AddressFragmentDoc}
  ${EmailFragmentDoc}
  ${BasePersonFragmentDoc}
  ${PhoneFragmentDoc}
`;
export type UpdateBusinessObjectMutationFn = Apollo.MutationFunction<
  UpdateBusinessObjectMutation,
  UpdateBusinessObjectMutationVariables
>;

/**
 * __useUpdateBusinessObjectMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessObjectMutation, { data, loading, error }] = useUpdateBusinessObjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBusinessObjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBusinessObjectMutation,
    UpdateBusinessObjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBusinessObjectMutation, UpdateBusinessObjectMutationVariables>(
    UpdateBusinessObjectDocument,
    options,
  );
}
export type UpdateBusinessObjectMutationHookResult = ReturnType<
  typeof useUpdateBusinessObjectMutation
>;
export type UpdateBusinessObjectMutationResult = Apollo.MutationResult<
  UpdateBusinessObjectMutation
>;
export type UpdateBusinessObjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateBusinessObjectMutation,
  UpdateBusinessObjectMutationVariables
>;
export const UpdateOrCreateContactDocument = gql`
  mutation updateOrCreateContact($id: Int!, $personId: Int, $input: PersonInputStrict!) {
    updateOrCreateContact(id: $id, personId: $personId, input: $input) {
      ...businessObjectData
      persons {
        ...basePerson
      }
    }
  }
  ${BusinessObjectDataFragmentDoc}
  ${BasePersonFragmentDoc}
`;
export type UpdateOrCreateContactMutationFn = Apollo.MutationFunction<
  UpdateOrCreateContactMutation,
  UpdateOrCreateContactMutationVariables
>;

/**
 * __useUpdateOrCreateContactMutation__
 *
 * To run a mutation, you first call `useUpdateOrCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrCreateContactMutation, { data, loading, error }] = useUpdateOrCreateContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      personId: // value for 'personId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrCreateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrCreateContactMutation,
    UpdateOrCreateContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrCreateContactMutation, UpdateOrCreateContactMutationVariables>(
    UpdateOrCreateContactDocument,
    options,
  );
}
export type UpdateOrCreateContactMutationHookResult = ReturnType<
  typeof useUpdateOrCreateContactMutation
>;
export type UpdateOrCreateContactMutationResult = Apollo.MutationResult<
  UpdateOrCreateContactMutation
>;
export type UpdateOrCreateContactMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrCreateContactMutation,
  UpdateOrCreateContactMutationVariables
>;
export const AssignBusinessContactDocument = gql`
  mutation assignBusinessContact($businessObjectId: Int!, $personId: Int!) {
    assignBusinessContact(businessObjectId: $businessObjectId, personId: $personId) {
      ...businessObjectData
      persons {
        ...basePerson
      }
    }
  }
  ${BusinessObjectDataFragmentDoc}
  ${BasePersonFragmentDoc}
`;
export type AssignBusinessContactMutationFn = Apollo.MutationFunction<
  AssignBusinessContactMutation,
  AssignBusinessContactMutationVariables
>;

/**
 * __useAssignBusinessContactMutation__
 *
 * To run a mutation, you first call `useAssignBusinessContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignBusinessContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignBusinessContactMutation, { data, loading, error }] = useAssignBusinessContactMutation({
 *   variables: {
 *      businessObjectId: // value for 'businessObjectId'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useAssignBusinessContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignBusinessContactMutation,
    AssignBusinessContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignBusinessContactMutation, AssignBusinessContactMutationVariables>(
    AssignBusinessContactDocument,
    options,
  );
}
export type AssignBusinessContactMutationHookResult = ReturnType<
  typeof useAssignBusinessContactMutation
>;
export type AssignBusinessContactMutationResult = Apollo.MutationResult<
  AssignBusinessContactMutation
>;
export type AssignBusinessContactMutationOptions = Apollo.BaseMutationOptions<
  AssignBusinessContactMutation,
  AssignBusinessContactMutationVariables
>;
export const CreateBusinessObjectDocument = gql`
  mutation createBusinessObject(
    $businessObjectInput: BusinessObjectInput!
    $contactPersonInput: PersonInput!
  ) {
    createBusinessObject(
      businessObjectInput: $businessObjectInput
      contactPersonInput: $contactPersonInput
    ) {
      ...businessObjectData
    }
  }
  ${BusinessObjectDataFragmentDoc}
`;
export type CreateBusinessObjectMutationFn = Apollo.MutationFunction<
  CreateBusinessObjectMutation,
  CreateBusinessObjectMutationVariables
>;

/**
 * __useCreateBusinessObjectMutation__
 *
 * To run a mutation, you first call `useCreateBusinessObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessObjectMutation, { data, loading, error }] = useCreateBusinessObjectMutation({
 *   variables: {
 *      businessObjectInput: // value for 'businessObjectInput'
 *      contactPersonInput: // value for 'contactPersonInput'
 *   },
 * });
 */
export function useCreateBusinessObjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBusinessObjectMutation,
    CreateBusinessObjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBusinessObjectMutation, CreateBusinessObjectMutationVariables>(
    CreateBusinessObjectDocument,
    options,
  );
}
export type CreateBusinessObjectMutationHookResult = ReturnType<
  typeof useCreateBusinessObjectMutation
>;
export type CreateBusinessObjectMutationResult = Apollo.MutationResult<
  CreateBusinessObjectMutation
>;
export type CreateBusinessObjectMutationOptions = Apollo.BaseMutationOptions<
  CreateBusinessObjectMutation,
  CreateBusinessObjectMutationVariables
>;
export const CreateBusinessUnitDocument = gql`
  mutation createBusinessUnit($input: CreateBusinessUnitInput) {
    createBusinessUnit(input: $input) {
      id
      name
    }
  }
`;
export type CreateBusinessUnitMutationFn = Apollo.MutationFunction<
  CreateBusinessUnitMutation,
  CreateBusinessUnitMutationVariables
>;

/**
 * __useCreateBusinessUnitMutation__
 *
 * To run a mutation, you first call `useCreateBusinessUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessUnitMutation, { data, loading, error }] = useCreateBusinessUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBusinessUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBusinessUnitMutation,
    CreateBusinessUnitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBusinessUnitMutation, CreateBusinessUnitMutationVariables>(
    CreateBusinessUnitDocument,
    options,
  );
}
export type CreateBusinessUnitMutationHookResult = ReturnType<typeof useCreateBusinessUnitMutation>;
export type CreateBusinessUnitMutationResult = Apollo.MutationResult<CreateBusinessUnitMutation>;
export type CreateBusinessUnitMutationOptions = Apollo.BaseMutationOptions<
  CreateBusinessUnitMutation,
  CreateBusinessUnitMutationVariables
>;
export const EditBusinessUnitDocument = gql`
  mutation editBusinessUnit($input: EditBusinessUnitInput) {
    editBusinessUnit(input: $input) {
      id
      name
    }
  }
`;
export type EditBusinessUnitMutationFn = Apollo.MutationFunction<
  EditBusinessUnitMutation,
  EditBusinessUnitMutationVariables
>;

/**
 * __useEditBusinessUnitMutation__
 *
 * To run a mutation, you first call `useEditBusinessUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditBusinessUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editBusinessUnitMutation, { data, loading, error }] = useEditBusinessUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditBusinessUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditBusinessUnitMutation,
    EditBusinessUnitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditBusinessUnitMutation, EditBusinessUnitMutationVariables>(
    EditBusinessUnitDocument,
    options,
  );
}
export type EditBusinessUnitMutationHookResult = ReturnType<typeof useEditBusinessUnitMutation>;
export type EditBusinessUnitMutationResult = Apollo.MutationResult<EditBusinessUnitMutation>;
export type EditBusinessUnitMutationOptions = Apollo.BaseMutationOptions<
  EditBusinessUnitMutation,
  EditBusinessUnitMutationVariables
>;
export const MakeClientCallDocument = gql`
  mutation makeClientCall($personId: Int!, $phoneId: Int!, $primeDN: Int!) {
    makeClientCall(personId: $personId, phoneId: $phoneId, primeDN: $primeDN)
  }
`;
export type MakeClientCallMutationFn = Apollo.MutationFunction<
  MakeClientCallMutation,
  MakeClientCallMutationVariables
>;

/**
 * __useMakeClientCallMutation__
 *
 * To run a mutation, you first call `useMakeClientCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeClientCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeClientCallMutation, { data, loading, error }] = useMakeClientCallMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      phoneId: // value for 'phoneId'
 *      primeDN: // value for 'primeDN'
 *   },
 * });
 */
export function useMakeClientCallMutation(
  baseOptions?: Apollo.MutationHookOptions<MakeClientCallMutation, MakeClientCallMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MakeClientCallMutation, MakeClientCallMutationVariables>(
    MakeClientCallDocument,
    options,
  );
}
export type MakeClientCallMutationHookResult = ReturnType<typeof useMakeClientCallMutation>;
export type MakeClientCallMutationResult = Apollo.MutationResult<MakeClientCallMutation>;
export type MakeClientCallMutationOptions = Apollo.BaseMutationOptions<
  MakeClientCallMutation,
  MakeClientCallMutationVariables
>;
export const CreateCallDocument = gql`
  mutation createCall($random: Boolean!) {
    createCall(random: $random) {
      id
      readableNumber
      accountId
      status
    }
  }
`;
export type CreateCallMutationFn = Apollo.MutationFunction<
  CreateCallMutation,
  CreateCallMutationVariables
>;

/**
 * __useCreateCallMutation__
 *
 * To run a mutation, you first call `useCreateCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCallMutation, { data, loading, error }] = useCreateCallMutation({
 *   variables: {
 *      random: // value for 'random'
 *   },
 * });
 */
export function useCreateCallMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCallMutation, CreateCallMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCallMutation, CreateCallMutationVariables>(
    CreateCallDocument,
    options,
  );
}
export type CreateCallMutationHookResult = ReturnType<typeof useCreateCallMutation>;
export type CreateCallMutationResult = Apollo.MutationResult<CreateCallMutation>;
export type CreateCallMutationOptions = Apollo.BaseMutationOptions<
  CreateCallMutation,
  CreateCallMutationVariables
>;
export const UpdateCallDocument = gql`
  mutation updateCall($call: CallUpdateInput!) {
    updateCall(call: $call) {
      id
      readableNumber
      accountId
      status
      personActivityId
    }
  }
`;
export type UpdateCallMutationFn = Apollo.MutationFunction<
  UpdateCallMutation,
  UpdateCallMutationVariables
>;

/**
 * __useUpdateCallMutation__
 *
 * To run a mutation, you first call `useUpdateCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCallMutation, { data, loading, error }] = useUpdateCallMutation({
 *   variables: {
 *      call: // value for 'call'
 *   },
 * });
 */
export function useUpdateCallMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCallMutation, UpdateCallMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCallMutation, UpdateCallMutationVariables>(
    UpdateCallDocument,
    options,
  );
}
export type UpdateCallMutationHookResult = ReturnType<typeof useUpdateCallMutation>;
export type UpdateCallMutationResult = Apollo.MutationResult<UpdateCallMutation>;
export type UpdateCallMutationOptions = Apollo.BaseMutationOptions<
  UpdateCallMutation,
  UpdateCallMutationVariables
>;
export const TriggerResearchCallDocument = gql`
  mutation triggerResearchCall($serviceResearchObjectId: Float!, $primeDN: Int!, $phoneId: Int!) {
    triggerResearchCall(
      serviceResearchObjectId: $serviceResearchObjectId
      primeDN: $primeDN
      phoneId: $phoneId
    )
  }
`;
export type TriggerResearchCallMutationFn = Apollo.MutationFunction<
  TriggerResearchCallMutation,
  TriggerResearchCallMutationVariables
>;

/**
 * __useTriggerResearchCallMutation__
 *
 * To run a mutation, you first call `useTriggerResearchCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerResearchCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerResearchCallMutation, { data, loading, error }] = useTriggerResearchCallMutation({
 *   variables: {
 *      serviceResearchObjectId: // value for 'serviceResearchObjectId'
 *      primeDN: // value for 'primeDN'
 *      phoneId: // value for 'phoneId'
 *   },
 * });
 */
export function useTriggerResearchCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TriggerResearchCallMutation,
    TriggerResearchCallMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TriggerResearchCallMutation, TriggerResearchCallMutationVariables>(
    TriggerResearchCallDocument,
    options,
  );
}
export type TriggerResearchCallMutationHookResult = ReturnType<
  typeof useTriggerResearchCallMutation
>;
export type TriggerResearchCallMutationResult = Apollo.MutationResult<TriggerResearchCallMutation>;
export type TriggerResearchCallMutationOptions = Apollo.BaseMutationOptions<
  TriggerResearchCallMutation,
  TriggerResearchCallMutationVariables
>;
export const CancelCallDocument = gql`
  mutation cancelCall($callId: Int!) {
    cancelCall(callId: $callId) {
      id
      status
    }
  }
`;
export type CancelCallMutationFn = Apollo.MutationFunction<
  CancelCallMutation,
  CancelCallMutationVariables
>;

/**
 * __useCancelCallMutation__
 *
 * To run a mutation, you first call `useCancelCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCallMutation, { data, loading, error }] = useCancelCallMutation({
 *   variables: {
 *      callId: // value for 'callId'
 *   },
 * });
 */
export function useCancelCallMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelCallMutation, CancelCallMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelCallMutation, CancelCallMutationVariables>(
    CancelCallDocument,
    options,
  );
}
export type CancelCallMutationHookResult = ReturnType<typeof useCancelCallMutation>;
export type CancelCallMutationResult = Apollo.MutationResult<CancelCallMutation>;
export type CancelCallMutationOptions = Apollo.BaseMutationOptions<
  CancelCallMutation,
  CancelCallMutationVariables
>;
export const CreateCaseManagementReviewDocument = gql`
  mutation createCaseManagementReview($clientId: Int!) {
    createCaseManagementReview(clientId: $clientId) {
      id
      caseManagementReviewAttributes {
        id
        attributeValue
        clientReviewAttribute {
          id
          name
          label
        }
      }
    }
  }
`;
export type CreateCaseManagementReviewMutationFn = Apollo.MutationFunction<
  CreateCaseManagementReviewMutation,
  CreateCaseManagementReviewMutationVariables
>;

/**
 * __useCreateCaseManagementReviewMutation__
 *
 * To run a mutation, you first call `useCreateCaseManagementReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCaseManagementReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCaseManagementReviewMutation, { data, loading, error }] = useCreateCaseManagementReviewMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useCreateCaseManagementReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCaseManagementReviewMutation,
    CreateCaseManagementReviewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCaseManagementReviewMutation,
    CreateCaseManagementReviewMutationVariables
  >(CreateCaseManagementReviewDocument, options);
}
export type CreateCaseManagementReviewMutationHookResult = ReturnType<
  typeof useCreateCaseManagementReviewMutation
>;
export type CreateCaseManagementReviewMutationResult = Apollo.MutationResult<
  CreateCaseManagementReviewMutation
>;
export type CreateCaseManagementReviewMutationOptions = Apollo.BaseMutationOptions<
  CreateCaseManagementReviewMutation,
  CreateCaseManagementReviewMutationVariables
>;
export const UpdateCaseManagementReviewAttributeDocument = gql`
  mutation updateCaseManagementReviewAttribute(
    $caseManagementReviewAttributeInput: CaseManagementReviewAttributeInput!
  ) {
    updateCaseManagementReviewAttribute(
      caseManagementReviewAttributeInput: $caseManagementReviewAttributeInput
    ) {
      id
      attributeValue
      lastUpdate
    }
  }
`;
export type UpdateCaseManagementReviewAttributeMutationFn = Apollo.MutationFunction<
  UpdateCaseManagementReviewAttributeMutation,
  UpdateCaseManagementReviewAttributeMutationVariables
>;

/**
 * __useUpdateCaseManagementReviewAttributeMutation__
 *
 * To run a mutation, you first call `useUpdateCaseManagementReviewAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCaseManagementReviewAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaseManagementReviewAttributeMutation, { data, loading, error }] = useUpdateCaseManagementReviewAttributeMutation({
 *   variables: {
 *      caseManagementReviewAttributeInput: // value for 'caseManagementReviewAttributeInput'
 *   },
 * });
 */
export function useUpdateCaseManagementReviewAttributeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCaseManagementReviewAttributeMutation,
    UpdateCaseManagementReviewAttributeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCaseManagementReviewAttributeMutation,
    UpdateCaseManagementReviewAttributeMutationVariables
  >(UpdateCaseManagementReviewAttributeDocument, options);
}
export type UpdateCaseManagementReviewAttributeMutationHookResult = ReturnType<
  typeof useUpdateCaseManagementReviewAttributeMutation
>;
export type UpdateCaseManagementReviewAttributeMutationResult = Apollo.MutationResult<
  UpdateCaseManagementReviewAttributeMutation
>;
export type UpdateCaseManagementReviewAttributeMutationOptions = Apollo.BaseMutationOptions<
  UpdateCaseManagementReviewAttributeMutation,
  UpdateCaseManagementReviewAttributeMutationVariables
>;
export const CreateCaseManagerDocument = gql`
  mutation createCaseManager($clientId: Int!, $userId: Int!) {
    createCaseManager(clientId: $clientId, userId: $userId) {
      id
      user {
        id
        papershiftWorkingAreas {
          ...papershiftWorkingArea
        }
        person {
          id
          name
          alias
          avatar
          avatarKeys
          picture
        }
      }
    }
  }
  ${PapershiftWorkingAreaFragmentDoc}
`;
export type CreateCaseManagerMutationFn = Apollo.MutationFunction<
  CreateCaseManagerMutation,
  CreateCaseManagerMutationVariables
>;

/**
 * __useCreateCaseManagerMutation__
 *
 * To run a mutation, you first call `useCreateCaseManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCaseManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCaseManagerMutation, { data, loading, error }] = useCreateCaseManagerMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateCaseManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCaseManagerMutation,
    CreateCaseManagerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCaseManagerMutation, CreateCaseManagerMutationVariables>(
    CreateCaseManagerDocument,
    options,
  );
}
export type CreateCaseManagerMutationHookResult = ReturnType<typeof useCreateCaseManagerMutation>;
export type CreateCaseManagerMutationResult = Apollo.MutationResult<CreateCaseManagerMutation>;
export type CreateCaseManagerMutationOptions = Apollo.BaseMutationOptions<
  CreateCaseManagerMutation,
  CreateCaseManagerMutationVariables
>;
export const UpdateCaseManagerDocument = gql`
  mutation updateCaseManager($clientId: Int!, $userId: Int) {
    updateCaseManager(clientId: $clientId, userId: $userId) {
      id
      user {
        id
        papershiftWorkingAreas {
          ...papershiftWorkingArea
        }
        person {
          id
          alias
          name
          avatar
          avatarKeys
          picture
        }
      }
    }
  }
  ${PapershiftWorkingAreaFragmentDoc}
`;
export type UpdateCaseManagerMutationFn = Apollo.MutationFunction<
  UpdateCaseManagerMutation,
  UpdateCaseManagerMutationVariables
>;

/**
 * __useUpdateCaseManagerMutation__
 *
 * To run a mutation, you first call `useUpdateCaseManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCaseManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaseManagerMutation, { data, loading, error }] = useUpdateCaseManagerMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateCaseManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCaseManagerMutation,
    UpdateCaseManagerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCaseManagerMutation, UpdateCaseManagerMutationVariables>(
    UpdateCaseManagerDocument,
    options,
  );
}
export type UpdateCaseManagerMutationHookResult = ReturnType<typeof useUpdateCaseManagerMutation>;
export type UpdateCaseManagerMutationResult = Apollo.MutationResult<UpdateCaseManagerMutation>;
export type UpdateCaseManagerMutationOptions = Apollo.BaseMutationOptions<
  UpdateCaseManagerMutation,
  UpdateCaseManagerMutationVariables
>;
export const UpdateCaseManagerByUserIdDocument = gql`
  mutation updateCaseManagerByUserId(
    $serviceId: Int!
    $newCaseManagerId: Int
    $oldCaseManagerId: Int
  ) {
    updateCaseManagerByUserId(
      serviceId: $serviceId
      newCaseManagerId: $newCaseManagerId
      oldCaseManagerId: $oldCaseManagerId
    ) {
      id
      user {
        id
        papershiftWorkingAreas {
          ...papershiftWorkingArea
        }
        person {
          id
          alias
          name
          avatar
          avatarKeys
          picture
        }
      }
    }
  }
  ${PapershiftWorkingAreaFragmentDoc}
`;
export type UpdateCaseManagerByUserIdMutationFn = Apollo.MutationFunction<
  UpdateCaseManagerByUserIdMutation,
  UpdateCaseManagerByUserIdMutationVariables
>;

/**
 * __useUpdateCaseManagerByUserIdMutation__
 *
 * To run a mutation, you first call `useUpdateCaseManagerByUserIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCaseManagerByUserIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaseManagerByUserIdMutation, { data, loading, error }] = useUpdateCaseManagerByUserIdMutation({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *      newCaseManagerId: // value for 'newCaseManagerId'
 *      oldCaseManagerId: // value for 'oldCaseManagerId'
 *   },
 * });
 */
export function useUpdateCaseManagerByUserIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCaseManagerByUserIdMutation,
    UpdateCaseManagerByUserIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCaseManagerByUserIdMutation,
    UpdateCaseManagerByUserIdMutationVariables
  >(UpdateCaseManagerByUserIdDocument, options);
}
export type UpdateCaseManagerByUserIdMutationHookResult = ReturnType<
  typeof useUpdateCaseManagerByUserIdMutation
>;
export type UpdateCaseManagerByUserIdMutationResult = Apollo.MutationResult<
  UpdateCaseManagerByUserIdMutation
>;
export type UpdateCaseManagerByUserIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateCaseManagerByUserIdMutation,
  UpdateCaseManagerByUserIdMutationVariables
>;
export const CreateChatDocument = gql`
  mutation createChat($chatInput: ChatInsertInput!) {
    createChat(chatInput: $chatInput) {
      id
      chatUserId
      personActivity {
        id
        person {
          id
        }
      }
    }
  }
`;
export type CreateChatMutationFn = Apollo.MutationFunction<
  CreateChatMutation,
  CreateChatMutationVariables
>;

/**
 * __useCreateChatMutation__
 *
 * To run a mutation, you first call `useCreateChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatMutation, { data, loading, error }] = useCreateChatMutation({
 *   variables: {
 *      chatInput: // value for 'chatInput'
 *   },
 * });
 */
export function useCreateChatMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateChatMutation, CreateChatMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateChatMutation, CreateChatMutationVariables>(
    CreateChatDocument,
    options,
  );
}
export type CreateChatMutationHookResult = ReturnType<typeof useCreateChatMutation>;
export type CreateChatMutationResult = Apollo.MutationResult<CreateChatMutation>;
export type CreateChatMutationOptions = Apollo.BaseMutationOptions<
  CreateChatMutation,
  CreateChatMutationVariables
>;
export const ReassignChatDocument = gql`
  mutation reassignChat($chatId: Int!, $userId: Int, $team: Team) {
    reassignChat(chatId: $chatId, userId: $userId, team: $team) {
      id
      personActivity {
        id
        user {
          id
        }
      }
      team
    }
  }
`;
export type ReassignChatMutationFn = Apollo.MutationFunction<
  ReassignChatMutation,
  ReassignChatMutationVariables
>;

/**
 * __useReassignChatMutation__
 *
 * To run a mutation, you first call `useReassignChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReassignChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reassignChatMutation, { data, loading, error }] = useReassignChatMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      userId: // value for 'userId'
 *      team: // value for 'team'
 *   },
 * });
 */
export function useReassignChatMutation(
  baseOptions?: Apollo.MutationHookOptions<ReassignChatMutation, ReassignChatMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReassignChatMutation, ReassignChatMutationVariables>(
    ReassignChatDocument,
    options,
  );
}
export type ReassignChatMutationHookResult = ReturnType<typeof useReassignChatMutation>;
export type ReassignChatMutationResult = Apollo.MutationResult<ReassignChatMutation>;
export type ReassignChatMutationOptions = Apollo.BaseMutationOptions<
  ReassignChatMutation,
  ReassignChatMutationVariables
>;
export const FinishChatDocument = gql`
  mutation finishChat($id: Int!, $uid: Int!, $chatUserId: Int!, $token: String!) {
    finishChat(id: $id, uid: $uid, chatUserId: $chatUserId, token: $token) {
      id
      chatUserId
      personActivity {
        id
        person {
          id
        }
      }
    }
  }
`;
export type FinishChatMutationFn = Apollo.MutationFunction<
  FinishChatMutation,
  FinishChatMutationVariables
>;

/**
 * __useFinishChatMutation__
 *
 * To run a mutation, you first call `useFinishChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishChatMutation, { data, loading, error }] = useFinishChatMutation({
 *   variables: {
 *      id: // value for 'id'
 *      uid: // value for 'uid'
 *      chatUserId: // value for 'chatUserId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useFinishChatMutation(
  baseOptions?: Apollo.MutationHookOptions<FinishChatMutation, FinishChatMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FinishChatMutation, FinishChatMutationVariables>(
    FinishChatDocument,
    options,
  );
}
export type FinishChatMutationHookResult = ReturnType<typeof useFinishChatMutation>;
export type FinishChatMutationResult = Apollo.MutationResult<FinishChatMutation>;
export type FinishChatMutationOptions = Apollo.BaseMutationOptions<
  FinishChatMutation,
  FinishChatMutationVariables
>;
export const AddClientDocument = gql`
  mutation addClient($client: ClientInsertInput!) {
    addClient(client: $client) {
      id
      clientType
      account {
        id
        name
        type
      }
      person {
        id
        alias
        firstName
        lastName
        gender
        phones {
          areaCode
          number
        }
      }
    }
  }
`;
export type AddClientMutationFn = Apollo.MutationFunction<
  AddClientMutation,
  AddClientMutationVariables
>;

/**
 * __useAddClientMutation__
 *
 * To run a mutation, you first call `useAddClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClientMutation, { data, loading, error }] = useAddClientMutation({
 *   variables: {
 *      client: // value for 'client'
 *   },
 * });
 */
export function useAddClientMutation(
  baseOptions?: Apollo.MutationHookOptions<AddClientMutation, AddClientMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddClientMutation, AddClientMutationVariables>(
    AddClientDocument,
    options,
  );
}
export type AddClientMutationHookResult = ReturnType<typeof useAddClientMutation>;
export type AddClientMutationResult = Apollo.MutationResult<AddClientMutation>;
export type AddClientMutationOptions = Apollo.BaseMutationOptions<
  AddClientMutation,
  AddClientMutationVariables
>;
export const UpdateClientDocument = gql`
  mutation updateClient($clientInput: ClientUpdateInput!) {
    updateClient(clientInput: $clientInput) {
      id
      departmentId
      clientType
      assistanceId
      job
      vip
      jobBand
      language
      contactLanguageId
      contactLanguage {
        id
        code
        name
      }
      healthInsuranceCompany
      accountId
      sourceOfInformation
      hrBusiness
      source
      yearOfEntry
      businessUnit {
        id
        name
      }
    }
  }
`;
export type UpdateClientMutationFn = Apollo.MutationFunction<
  UpdateClientMutation,
  UpdateClientMutationVariables
>;

/**
 * __useUpdateClientMutation__
 *
 * To run a mutation, you first call `useUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientMutation, { data, loading, error }] = useUpdateClientMutation({
 *   variables: {
 *      clientInput: // value for 'clientInput'
 *   },
 * });
 */
export function useUpdateClientMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateClientMutation, UpdateClientMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateClientMutation, UpdateClientMutationVariables>(
    UpdateClientDocument,
    options,
  );
}
export type UpdateClientMutationHookResult = ReturnType<typeof useUpdateClientMutation>;
export type UpdateClientMutationResult = Apollo.MutationResult<UpdateClientMutation>;
export type UpdateClientMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientMutation,
  UpdateClientMutationVariables
>;
export const CreateContactPersonDocument = gql`
  mutation createContactPerson($contactPersonInput: ContactPersonInput!) {
    createContactPerson(contactPersonInput: $contactPersonInput) {
      ...person
    }
  }
  ${PersonFragmentDoc}
`;
export type CreateContactPersonMutationFn = Apollo.MutationFunction<
  CreateContactPersonMutation,
  CreateContactPersonMutationVariables
>;

/**
 * __useCreateContactPersonMutation__
 *
 * To run a mutation, you first call `useCreateContactPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactPersonMutation, { data, loading, error }] = useCreateContactPersonMutation({
 *   variables: {
 *      contactPersonInput: // value for 'contactPersonInput'
 *   },
 * });
 */
export function useCreateContactPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContactPersonMutation,
    CreateContactPersonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateContactPersonMutation, CreateContactPersonMutationVariables>(
    CreateContactPersonDocument,
    options,
  );
}
export type CreateContactPersonMutationHookResult = ReturnType<
  typeof useCreateContactPersonMutation
>;
export type CreateContactPersonMutationResult = Apollo.MutationResult<CreateContactPersonMutation>;
export type CreateContactPersonMutationOptions = Apollo.BaseMutationOptions<
  CreateContactPersonMutation,
  CreateContactPersonMutationVariables
>;
export const UpdateContactPersonDocument = gql`
  mutation updateContactPerson($contactPersonUpdateInput: ContactPersonUpdateInput!) {
    updateContactPerson(contactPersonUpdateInput: $contactPersonUpdateInput) {
      ...person
    }
  }
  ${PersonFragmentDoc}
`;
export type UpdateContactPersonMutationFn = Apollo.MutationFunction<
  UpdateContactPersonMutation,
  UpdateContactPersonMutationVariables
>;

/**
 * __useUpdateContactPersonMutation__
 *
 * To run a mutation, you first call `useUpdateContactPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactPersonMutation, { data, loading, error }] = useUpdateContactPersonMutation({
 *   variables: {
 *      contactPersonUpdateInput: // value for 'contactPersonUpdateInput'
 *   },
 * });
 */
export function useUpdateContactPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContactPersonMutation,
    UpdateContactPersonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContactPersonMutation, UpdateContactPersonMutationVariables>(
    UpdateContactPersonDocument,
    options,
  );
}
export type UpdateContactPersonMutationHookResult = ReturnType<
  typeof useUpdateContactPersonMutation
>;
export type UpdateContactPersonMutationResult = Apollo.MutationResult<UpdateContactPersonMutation>;
export type UpdateContactPersonMutationOptions = Apollo.BaseMutationOptions<
  UpdateContactPersonMutation,
  UpdateContactPersonMutationVariables
>;
export const CreateConversationReportDocument = gql`
  mutation createConversationReport($report: ConversationReportInsertInput!) {
    createConversationReport(report: $report) {
      ...baseReport
      topic {
        id
        name
      }
      services {
        id
        serviceMeta {
          id
          name
        }
      }
      connectedServices {
        id
      }
      client {
        id
        person {
          id
          name
        }
      }
      activeClientReviewTemplate {
        id
        isDefault
        name
        sortIdx
      }
      activeClientReviewAttributeGroup {
        id
        headingNew
        headingUpdate
        pageOrder
      }
      conversationReportReviewAttributes {
        id
        attributeValue
        clientReviewAttribute {
          id
          label
          name
          placeholder
          type
          clientReviewAttributeGroup {
            id
            headingNew
            headingUpdate
            pageOrder
            clientReviewTemplate {
              id
              isDefault
              name
              sortIdx
            }
          }
        }
      }
    }
  }
  ${BaseReportFragmentDoc}
`;
export type CreateConversationReportMutationFn = Apollo.MutationFunction<
  CreateConversationReportMutation,
  CreateConversationReportMutationVariables
>;

/**
 * __useCreateConversationReportMutation__
 *
 * To run a mutation, you first call `useCreateConversationReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConversationReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConversationReportMutation, { data, loading, error }] = useCreateConversationReportMutation({
 *   variables: {
 *      report: // value for 'report'
 *   },
 * });
 */
export function useCreateConversationReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateConversationReportMutation,
    CreateConversationReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateConversationReportMutation,
    CreateConversationReportMutationVariables
  >(CreateConversationReportDocument, options);
}
export type CreateConversationReportMutationHookResult = ReturnType<
  typeof useCreateConversationReportMutation
>;
export type CreateConversationReportMutationResult = Apollo.MutationResult<
  CreateConversationReportMutation
>;
export type CreateConversationReportMutationOptions = Apollo.BaseMutationOptions<
  CreateConversationReportMutation,
  CreateConversationReportMutationVariables
>;
export const UpdateConversationReportDocument = gql`
  mutation updateConversationReport($report: ConversationReportUpdateInput!) {
    updateConversationReport(report: $report) {
      ...baseReport
      topic {
        id
        name
      }
      services {
        id
        serviceMeta {
          id
          name
        }
      }
      connectedServices {
        id
      }
      client {
        id
        person {
          id
          name
        }
      }
      activeClientReviewTemplate {
        id
        isDefault
        name
        sortIdx
      }
      activeClientReviewAttributeGroup {
        id
        headingNew
        headingUpdate
        pageOrder
      }
      conversationReportReviewAttributes {
        id
        attributeValue
        clientReviewAttribute {
          id
          label
          name
          placeholder
          type
          clientReviewAttributeGroup {
            id
            headingNew
            headingUpdate
            pageOrder
            clientReviewTemplate {
              id
              isDefault
              name
              sortIdx
            }
          }
        }
      }
    }
  }
  ${BaseReportFragmentDoc}
`;
export type UpdateConversationReportMutationFn = Apollo.MutationFunction<
  UpdateConversationReportMutation,
  UpdateConversationReportMutationVariables
>;

/**
 * __useUpdateConversationReportMutation__
 *
 * To run a mutation, you first call `useUpdateConversationReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConversationReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConversationReportMutation, { data, loading, error }] = useUpdateConversationReportMutation({
 *   variables: {
 *      report: // value for 'report'
 *   },
 * });
 */
export function useUpdateConversationReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateConversationReportMutation,
    UpdateConversationReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateConversationReportMutation,
    UpdateConversationReportMutationVariables
  >(UpdateConversationReportDocument, options);
}
export type UpdateConversationReportMutationHookResult = ReturnType<
  typeof useUpdateConversationReportMutation
>;
export type UpdateConversationReportMutationResult = Apollo.MutationResult<
  UpdateConversationReportMutation
>;
export type UpdateConversationReportMutationOptions = Apollo.BaseMutationOptions<
  UpdateConversationReportMutation,
  UpdateConversationReportMutationVariables
>;
export const DemoCreateMandateDocument = gql`
  mutation demoCreateMandate($id: Int!) {
    demoCreateMandate(id: $id) {
      id
    }
  }
`;
export type DemoCreateMandateMutationFn = Apollo.MutationFunction<
  DemoCreateMandateMutation,
  DemoCreateMandateMutationVariables
>;

/**
 * __useDemoCreateMandateMutation__
 *
 * To run a mutation, you first call `useDemoCreateMandateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDemoCreateMandateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [demoCreateMandateMutation, { data, loading, error }] = useDemoCreateMandateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDemoCreateMandateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DemoCreateMandateMutation,
    DemoCreateMandateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DemoCreateMandateMutation, DemoCreateMandateMutationVariables>(
    DemoCreateMandateDocument,
    options,
  );
}
export type DemoCreateMandateMutationHookResult = ReturnType<typeof useDemoCreateMandateMutation>;
export type DemoCreateMandateMutationResult = Apollo.MutationResult<DemoCreateMandateMutation>;
export type DemoCreateMandateMutationOptions = Apollo.BaseMutationOptions<
  DemoCreateMandateMutation,
  DemoCreateMandateMutationVariables
>;
export const CreateMandateDocument = gql`
  mutation createMandate($mandate: CounselingMandateInput!) {
    createMandate(mandate: $mandate) {
      id
    }
  }
`;
export type CreateMandateMutationFn = Apollo.MutationFunction<
  CreateMandateMutation,
  CreateMandateMutationVariables
>;

/**
 * __useCreateMandateMutation__
 *
 * To run a mutation, you first call `useCreateMandateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMandateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMandateMutation, { data, loading, error }] = useCreateMandateMutation({
 *   variables: {
 *      mandate: // value for 'mandate'
 *   },
 * });
 */
export function useCreateMandateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMandateMutation, CreateMandateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMandateMutation, CreateMandateMutationVariables>(
    CreateMandateDocument,
    options,
  );
}
export type CreateMandateMutationHookResult = ReturnType<typeof useCreateMandateMutation>;
export type CreateMandateMutationResult = Apollo.MutationResult<CreateMandateMutation>;
export type CreateMandateMutationOptions = Apollo.BaseMutationOptions<
  CreateMandateMutation,
  CreateMandateMutationVariables
>;
export const UpdateMandateDocument = gql`
  mutation updateMandate($id: Int!, $input: UpdateCounselingMandateInput!) {
    updateMandate(id: $id, input: $input) {
      id
      sessionsAppointed
      sessionsCompleted
    }
  }
`;
export type UpdateMandateMutationFn = Apollo.MutationFunction<
  UpdateMandateMutation,
  UpdateMandateMutationVariables
>;

/**
 * __useUpdateMandateMutation__
 *
 * To run a mutation, you first call `useUpdateMandateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMandateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMandateMutation, { data, loading, error }] = useUpdateMandateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMandateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMandateMutation, UpdateMandateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMandateMutation, UpdateMandateMutationVariables>(
    UpdateMandateDocument,
    options,
  );
}
export type UpdateMandateMutationHookResult = ReturnType<typeof useUpdateMandateMutation>;
export type UpdateMandateMutationResult = Apollo.MutationResult<UpdateMandateMutation>;
export type UpdateMandateMutationOptions = Apollo.BaseMutationOptions<
  UpdateMandateMutation,
  UpdateMandateMutationVariables
>;
export const CreateCounselingUserDocument = gql`
  mutation createCounselingUser($input: CounselingUserInput!) {
    createCounselingUser(input: $input) {
      id
    }
  }
`;
export type CreateCounselingUserMutationFn = Apollo.MutationFunction<
  CreateCounselingUserMutation,
  CreateCounselingUserMutationVariables
>;

/**
 * __useCreateCounselingUserMutation__
 *
 * To run a mutation, you first call `useCreateCounselingUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCounselingUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCounselingUserMutation, { data, loading, error }] = useCreateCounselingUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCounselingUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCounselingUserMutation,
    CreateCounselingUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCounselingUserMutation, CreateCounselingUserMutationVariables>(
    CreateCounselingUserDocument,
    options,
  );
}
export type CreateCounselingUserMutationHookResult = ReturnType<
  typeof useCreateCounselingUserMutation
>;
export type CreateCounselingUserMutationResult = Apollo.MutationResult<
  CreateCounselingUserMutation
>;
export type CreateCounselingUserMutationOptions = Apollo.BaseMutationOptions<
  CreateCounselingUserMutation,
  CreateCounselingUserMutationVariables
>;
export const CreateDepartmentDocument = gql`
  mutation createDepartment($accountId: Int!, $input: DepartmentInput!) {
    createDepartment(accountId: $accountId, input: $input) {
      id
      name
      main
      accountId
    }
  }
`;
export type CreateDepartmentMutationFn = Apollo.MutationFunction<
  CreateDepartmentMutation,
  CreateDepartmentMutationVariables
>;

/**
 * __useCreateDepartmentMutation__
 *
 * To run a mutation, you first call `useCreateDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDepartmentMutation, { data, loading, error }] = useCreateDepartmentMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDepartmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDepartmentMutation,
    CreateDepartmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDepartmentMutation, CreateDepartmentMutationVariables>(
    CreateDepartmentDocument,
    options,
  );
}
export type CreateDepartmentMutationHookResult = ReturnType<typeof useCreateDepartmentMutation>;
export type CreateDepartmentMutationResult = Apollo.MutationResult<CreateDepartmentMutation>;
export type CreateDepartmentMutationOptions = Apollo.BaseMutationOptions<
  CreateDepartmentMutation,
  CreateDepartmentMutationVariables
>;
export const UpdateDepartmentDocument = gql`
  mutation updateDepartment($id: Int!, $input: DepartmentInput!) {
    updateDepartment(id: $id, input: $input) {
      id
      name
      main
      accountId
    }
  }
`;
export type UpdateDepartmentMutationFn = Apollo.MutationFunction<
  UpdateDepartmentMutation,
  UpdateDepartmentMutationVariables
>;

/**
 * __useUpdateDepartmentMutation__
 *
 * To run a mutation, you first call `useUpdateDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDepartmentMutation, { data, loading, error }] = useUpdateDepartmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDepartmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDepartmentMutation,
    UpdateDepartmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>(
    UpdateDepartmentDocument,
    options,
  );
}
export type UpdateDepartmentMutationHookResult = ReturnType<typeof useUpdateDepartmentMutation>;
export type UpdateDepartmentMutationResult = Apollo.MutationResult<UpdateDepartmentMutation>;
export type UpdateDepartmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateDepartmentMutation,
  UpdateDepartmentMutationVariables
>;
export const CreateDriveDocumentDocument = gql`
  mutation createDriveDocument($createDocumentInput: CreateDocumentInput!) {
    createDriveDocument(createDocumentInput: $createDocumentInput) {
      id
      title
      link
      user {
        id
        name
      }
      created
    }
  }
`;
export type CreateDriveDocumentMutationFn = Apollo.MutationFunction<
  CreateDriveDocumentMutation,
  CreateDriveDocumentMutationVariables
>;

/**
 * __useCreateDriveDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDriveDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDriveDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDriveDocumentMutation, { data, loading, error }] = useCreateDriveDocumentMutation({
 *   variables: {
 *      createDocumentInput: // value for 'createDocumentInput'
 *   },
 * });
 */
export function useCreateDriveDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDriveDocumentMutation,
    CreateDriveDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDriveDocumentMutation, CreateDriveDocumentMutationVariables>(
    CreateDriveDocumentDocument,
    options,
  );
}
export type CreateDriveDocumentMutationHookResult = ReturnType<
  typeof useCreateDriveDocumentMutation
>;
export type CreateDriveDocumentMutationResult = Apollo.MutationResult<CreateDriveDocumentMutation>;
export type CreateDriveDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreateDriveDocumentMutation,
  CreateDriveDocumentMutationVariables
>;
export const CreateDriveComplaintDocumentDocument = gql`
  mutation createDriveComplaintDocument($createDocumentInput: CreateDocumentInput!) {
    createDriveComplaintDocument(createDocumentInput: $createDocumentInput) {
      id
      title
      link
      user {
        id
        name
      }
      created
    }
  }
`;
export type CreateDriveComplaintDocumentMutationFn = Apollo.MutationFunction<
  CreateDriveComplaintDocumentMutation,
  CreateDriveComplaintDocumentMutationVariables
>;

/**
 * __useCreateDriveComplaintDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDriveComplaintDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDriveComplaintDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDriveComplaintDocumentMutation, { data, loading, error }] = useCreateDriveComplaintDocumentMutation({
 *   variables: {
 *      createDocumentInput: // value for 'createDocumentInput'
 *   },
 * });
 */
export function useCreateDriveComplaintDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDriveComplaintDocumentMutation,
    CreateDriveComplaintDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDriveComplaintDocumentMutation,
    CreateDriveComplaintDocumentMutationVariables
  >(CreateDriveComplaintDocumentDocument, options);
}
export type CreateDriveComplaintDocumentMutationHookResult = ReturnType<
  typeof useCreateDriveComplaintDocumentMutation
>;
export type CreateDriveComplaintDocumentMutationResult = Apollo.MutationResult<
  CreateDriveComplaintDocumentMutation
>;
export type CreateDriveComplaintDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreateDriveComplaintDocumentMutation,
  CreateDriveComplaintDocumentMutationVariables
>;
export const CreateEmailDocument = gql`
  mutation createEmail($personId: ID!, $emailsInput: [EmailInput!]!) {
    createEmail(personId: $personId, emailsInput: $emailsInput) {
      ...email
    }
  }
  ${EmailFragmentDoc}
`;
export type CreateEmailMutationFn = Apollo.MutationFunction<
  CreateEmailMutation,
  CreateEmailMutationVariables
>;

/**
 * __useCreateEmailMutation__
 *
 * To run a mutation, you first call `useCreateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmailMutation, { data, loading, error }] = useCreateEmailMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      emailsInput: // value for 'emailsInput'
 *   },
 * });
 */
export function useCreateEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEmailMutation, CreateEmailMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEmailMutation, CreateEmailMutationVariables>(
    CreateEmailDocument,
    options,
  );
}
export type CreateEmailMutationHookResult = ReturnType<typeof useCreateEmailMutation>;
export type CreateEmailMutationResult = Apollo.MutationResult<CreateEmailMutation>;
export type CreateEmailMutationOptions = Apollo.BaseMutationOptions<
  CreateEmailMutation,
  CreateEmailMutationVariables
>;
export const DeleteEmailDocument = gql`
  mutation deleteEmail($id: ID!) {
    deleteEmail(id: $id)
  }
`;
export type DeleteEmailMutationFn = Apollo.MutationFunction<
  DeleteEmailMutation,
  DeleteEmailMutationVariables
>;

/**
 * __useDeleteEmailMutation__
 *
 * To run a mutation, you first call `useDeleteEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmailMutation, { data, loading, error }] = useDeleteEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteEmailMutation, DeleteEmailMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteEmailMutation, DeleteEmailMutationVariables>(
    DeleteEmailDocument,
    options,
  );
}
export type DeleteEmailMutationHookResult = ReturnType<typeof useDeleteEmailMutation>;
export type DeleteEmailMutationResult = Apollo.MutationResult<DeleteEmailMutation>;
export type DeleteEmailMutationOptions = Apollo.BaseMutationOptions<
  DeleteEmailMutation,
  DeleteEmailMutationVariables
>;
export const UpdateEmailsDocument = gql`
  mutation updateEmails($personId: ID!, $emailsInput: [EmailInput!]!) {
    updateEmails(personId: $personId, emailsInput: $emailsInput) {
      ...email
    }
  }
  ${EmailFragmentDoc}
`;
export type UpdateEmailsMutationFn = Apollo.MutationFunction<
  UpdateEmailsMutation,
  UpdateEmailsMutationVariables
>;

/**
 * __useUpdateEmailsMutation__
 *
 * To run a mutation, you first call `useUpdateEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailsMutation, { data, loading, error }] = useUpdateEmailsMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      emailsInput: // value for 'emailsInput'
 *   },
 * });
 */
export function useUpdateEmailsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEmailsMutation, UpdateEmailsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEmailsMutation, UpdateEmailsMutationVariables>(
    UpdateEmailsDocument,
    options,
  );
}
export type UpdateEmailsMutationHookResult = ReturnType<typeof useUpdateEmailsMutation>;
export type UpdateEmailsMutationResult = Apollo.MutationResult<UpdateEmailsMutation>;
export type UpdateEmailsMutationOptions = Apollo.BaseMutationOptions<
  UpdateEmailsMutation,
  UpdateEmailsMutationVariables
>;
export const AssignGMailMessageDocument = gql`
  mutation assignGMailMessage($threadId: String!, $userId: Int, $team: Team) {
    assignGMailMessage(threadId: $threadId, userId: $userId, team: $team) {
      id
      user {
        id
        name
      }
      team
    }
  }
`;
export type AssignGMailMessageMutationFn = Apollo.MutationFunction<
  AssignGMailMessageMutation,
  AssignGMailMessageMutationVariables
>;

/**
 * __useAssignGMailMessageMutation__
 *
 * To run a mutation, you first call `useAssignGMailMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignGMailMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignGMailMessageMutation, { data, loading, error }] = useAssignGMailMessageMutation({
 *   variables: {
 *      threadId: // value for 'threadId'
 *      userId: // value for 'userId'
 *      team: // value for 'team'
 *   },
 * });
 */
export function useAssignGMailMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignGMailMessageMutation,
    AssignGMailMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignGMailMessageMutation, AssignGMailMessageMutationVariables>(
    AssignGMailMessageDocument,
    options,
  );
}
export type AssignGMailMessageMutationHookResult = ReturnType<typeof useAssignGMailMessageMutation>;
export type AssignGMailMessageMutationResult = Apollo.MutationResult<AssignGMailMessageMutation>;
export type AssignGMailMessageMutationOptions = Apollo.BaseMutationOptions<
  AssignGMailMessageMutation,
  AssignGMailMessageMutationVariables
>;
export const CancelGMailMessageDocument = gql`
  mutation cancelGMailMessage($threadId: String!) {
    cancelGMailMessage(threadId: $threadId) {
      threadId
    }
  }
`;
export type CancelGMailMessageMutationFn = Apollo.MutationFunction<
  CancelGMailMessageMutation,
  CancelGMailMessageMutationVariables
>;

/**
 * __useCancelGMailMessageMutation__
 *
 * To run a mutation, you first call `useCancelGMailMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelGMailMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelGMailMessageMutation, { data, loading, error }] = useCancelGMailMessageMutation({
 *   variables: {
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useCancelGMailMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelGMailMessageMutation,
    CancelGMailMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelGMailMessageMutation, CancelGMailMessageMutationVariables>(
    CancelGMailMessageDocument,
    options,
  );
}
export type CancelGMailMessageMutationHookResult = ReturnType<typeof useCancelGMailMessageMutation>;
export type CancelGMailMessageMutationResult = Apollo.MutationResult<CancelGMailMessageMutation>;
export type CancelGMailMessageMutationOptions = Apollo.BaseMutationOptions<
  CancelGMailMessageMutation,
  CancelGMailMessageMutationVariables
>;
export const CreateGMailDocument = gql`
  mutation createGMail(
    $message: String!
    $threadId: String
    $classification: String!
    $subject: String!
    $sender: String!
    $personId: Int
    $businessObjectId: Int
    $topicId: Int
  ) {
    createGMail(
      message: $message
      threadId: $threadId
      subject: $subject
      sender: $sender
      personId: $personId
      businessObjectId: $businessObjectId
      topicId: $topicId
      classification: $classification
    ) {
      id
      incoming
      historyId
      messageId
      threadId
      body
      date
      attachments {
        attachmentId
        filename
        mimeType
      }
      payload {
        from
        to
        subject
        returnPath
      }
      gMailInbox {
        id
        email
      }
      person {
        id
        name
        avatar
        avatarKeys
        mentalState
        temporary
        emails {
          email
        }
      }
      user {
        id
        person {
          id
          name
          avatar
          avatarKeys
          picture
          mentalState
          temporary
          emails {
            email
          }
        }
      }
    }
  }
`;
export type CreateGMailMutationFn = Apollo.MutationFunction<
  CreateGMailMutation,
  CreateGMailMutationVariables
>;

/**
 * __useCreateGMailMutation__
 *
 * To run a mutation, you first call `useCreateGMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGMailMutation, { data, loading, error }] = useCreateGMailMutation({
 *   variables: {
 *      message: // value for 'message'
 *      threadId: // value for 'threadId'
 *      classification: // value for 'classification'
 *      subject: // value for 'subject'
 *      sender: // value for 'sender'
 *      personId: // value for 'personId'
 *      businessObjectId: // value for 'businessObjectId'
 *      topicId: // value for 'topicId'
 *   },
 * });
 */
export function useCreateGMailMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateGMailMutation, CreateGMailMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateGMailMutation, CreateGMailMutationVariables>(
    CreateGMailDocument,
    options,
  );
}
export type CreateGMailMutationHookResult = ReturnType<typeof useCreateGMailMutation>;
export type CreateGMailMutationResult = Apollo.MutationResult<CreateGMailMutation>;
export type CreateGMailMutationOptions = Apollo.BaseMutationOptions<
  CreateGMailMutation,
  CreateGMailMutationVariables
>;
export const FinishThreadDocument = gql`
  mutation finishThread($threadId: String!) {
    finishThread(threadId: $threadId)
  }
`;
export type FinishThreadMutationFn = Apollo.MutationFunction<
  FinishThreadMutation,
  FinishThreadMutationVariables
>;

/**
 * __useFinishThreadMutation__
 *
 * To run a mutation, you first call `useFinishThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishThreadMutation, { data, loading, error }] = useFinishThreadMutation({
 *   variables: {
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useFinishThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<FinishThreadMutation, FinishThreadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FinishThreadMutation, FinishThreadMutationVariables>(
    FinishThreadDocument,
    options,
  );
}
export type FinishThreadMutationHookResult = ReturnType<typeof useFinishThreadMutation>;
export type FinishThreadMutationResult = Apollo.MutationResult<FinishThreadMutation>;
export type FinishThreadMutationOptions = Apollo.BaseMutationOptions<
  FinishThreadMutation,
  FinishThreadMutationVariables
>;
export const CreateBusinessHoursDocument = gql`
  mutation createBusinessHours($hour: BusinessHoursBaseInput!) {
    createBusinessHours(hour: $hour) {
      id
      open
      close
      day
      businessId
    }
  }
`;
export type CreateBusinessHoursMutationFn = Apollo.MutationFunction<
  CreateBusinessHoursMutation,
  CreateBusinessHoursMutationVariables
>;

/**
 * __useCreateBusinessHoursMutation__
 *
 * To run a mutation, you first call `useCreateBusinessHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessHoursMutation, { data, loading, error }] = useCreateBusinessHoursMutation({
 *   variables: {
 *      hour: // value for 'hour'
 *   },
 * });
 */
export function useCreateBusinessHoursMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBusinessHoursMutation,
    CreateBusinessHoursMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBusinessHoursMutation, CreateBusinessHoursMutationVariables>(
    CreateBusinessHoursDocument,
    options,
  );
}
export type CreateBusinessHoursMutationHookResult = ReturnType<
  typeof useCreateBusinessHoursMutation
>;
export type CreateBusinessHoursMutationResult = Apollo.MutationResult<CreateBusinessHoursMutation>;
export type CreateBusinessHoursMutationOptions = Apollo.BaseMutationOptions<
  CreateBusinessHoursMutation,
  CreateBusinessHoursMutationVariables
>;
export const CreateHourDocument = gql`
  mutation createHour($hour: HourBaseInput!) {
    createHour(hour: $hour) {
      id
      open
      close
      day
      serviceId
    }
  }
`;
export type CreateHourMutationFn = Apollo.MutationFunction<
  CreateHourMutation,
  CreateHourMutationVariables
>;

/**
 * __useCreateHourMutation__
 *
 * To run a mutation, you first call `useCreateHourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHourMutation, { data, loading, error }] = useCreateHourMutation({
 *   variables: {
 *      hour: // value for 'hour'
 *   },
 * });
 */
export function useCreateHourMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateHourMutation, CreateHourMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateHourMutation, CreateHourMutationVariables>(
    CreateHourDocument,
    options,
  );
}
export type CreateHourMutationHookResult = ReturnType<typeof useCreateHourMutation>;
export type CreateHourMutationResult = Apollo.MutationResult<CreateHourMutation>;
export type CreateHourMutationOptions = Apollo.BaseMutationOptions<
  CreateHourMutation,
  CreateHourMutationVariables
>;
export const UpdateHourDocument = gql`
  mutation updateHour($hour: HourUpdate!) {
    updateHour(hour: $hour) {
      id
      open
      close
      day
      serviceId
    }
  }
`;
export type UpdateHourMutationFn = Apollo.MutationFunction<
  UpdateHourMutation,
  UpdateHourMutationVariables
>;

/**
 * __useUpdateHourMutation__
 *
 * To run a mutation, you first call `useUpdateHourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHourMutation, { data, loading, error }] = useUpdateHourMutation({
 *   variables: {
 *      hour: // value for 'hour'
 *   },
 * });
 */
export function useUpdateHourMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateHourMutation, UpdateHourMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateHourMutation, UpdateHourMutationVariables>(
    UpdateHourDocument,
    options,
  );
}
export type UpdateHourMutationHookResult = ReturnType<typeof useUpdateHourMutation>;
export type UpdateHourMutationResult = Apollo.MutationResult<UpdateHourMutation>;
export type UpdateHourMutationOptions = Apollo.BaseMutationOptions<
  UpdateHourMutation,
  UpdateHourMutationVariables
>;
export const DeleteHourDocument = gql`
  mutation deleteHour($id: Float!) {
    deleteHour(id: $id) {
      id
    }
  }
`;
export type DeleteHourMutationFn = Apollo.MutationFunction<
  DeleteHourMutation,
  DeleteHourMutationVariables
>;

/**
 * __useDeleteHourMutation__
 *
 * To run a mutation, you first call `useDeleteHourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHourMutation, { data, loading, error }] = useDeleteHourMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteHourMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteHourMutation, DeleteHourMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteHourMutation, DeleteHourMutationVariables>(
    DeleteHourDocument,
    options,
  );
}
export type DeleteHourMutationHookResult = ReturnType<typeof useDeleteHourMutation>;
export type DeleteHourMutationResult = Apollo.MutationResult<DeleteHourMutation>;
export type DeleteHourMutationOptions = Apollo.BaseMutationOptions<
  DeleteHourMutation,
  DeleteHourMutationVariables
>;
export const CreateClientMemoDocument = gql`
  mutation createClientMemo($memo: MemoInsertClientInput!, $clientId: Int!) {
    createClientMemo(memo: $memo, clientId: $clientId) {
      ...memo
    }
  }
  ${MemoFragmentDoc}
`;
export type CreateClientMemoMutationFn = Apollo.MutationFunction<
  CreateClientMemoMutation,
  CreateClientMemoMutationVariables
>;

/**
 * __useCreateClientMemoMutation__
 *
 * To run a mutation, you first call `useCreateClientMemoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientMemoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientMemoMutation, { data, loading, error }] = useCreateClientMemoMutation({
 *   variables: {
 *      memo: // value for 'memo'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useCreateClientMemoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientMemoMutation,
    CreateClientMemoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateClientMemoMutation, CreateClientMemoMutationVariables>(
    CreateClientMemoDocument,
    options,
  );
}
export type CreateClientMemoMutationHookResult = ReturnType<typeof useCreateClientMemoMutation>;
export type CreateClientMemoMutationResult = Apollo.MutationResult<CreateClientMemoMutation>;
export type CreateClientMemoMutationOptions = Apollo.BaseMutationOptions<
  CreateClientMemoMutation,
  CreateClientMemoMutationVariables
>;
export const UpdateClientMemoDocument = gql`
  mutation updateClientMemo($memo: MemoUpdateInput!, $clientId: Int!) {
    updateClientMemo(memo: $memo, clientId: $clientId) {
      ...memo
    }
  }
  ${MemoFragmentDoc}
`;
export type UpdateClientMemoMutationFn = Apollo.MutationFunction<
  UpdateClientMemoMutation,
  UpdateClientMemoMutationVariables
>;

/**
 * __useUpdateClientMemoMutation__
 *
 * To run a mutation, you first call `useUpdateClientMemoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientMemoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientMemoMutation, { data, loading, error }] = useUpdateClientMemoMutation({
 *   variables: {
 *      memo: // value for 'memo'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useUpdateClientMemoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientMemoMutation,
    UpdateClientMemoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateClientMemoMutation, UpdateClientMemoMutationVariables>(
    UpdateClientMemoDocument,
    options,
  );
}
export type UpdateClientMemoMutationHookResult = ReturnType<typeof useUpdateClientMemoMutation>;
export type UpdateClientMemoMutationResult = Apollo.MutationResult<UpdateClientMemoMutation>;
export type UpdateClientMemoMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientMemoMutation,
  UpdateClientMemoMutationVariables
>;
export const CreateBusinessMemoDocument = gql`
  mutation createBusinessMemo($memo: MemoInsertInput!, $businessId: Int!) {
    createBusinessMemo(memo: $memo, businessId: $businessId) {
      ...memo
    }
  }
  ${MemoFragmentDoc}
`;
export type CreateBusinessMemoMutationFn = Apollo.MutationFunction<
  CreateBusinessMemoMutation,
  CreateBusinessMemoMutationVariables
>;

/**
 * __useCreateBusinessMemoMutation__
 *
 * To run a mutation, you first call `useCreateBusinessMemoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessMemoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessMemoMutation, { data, loading, error }] = useCreateBusinessMemoMutation({
 *   variables: {
 *      memo: // value for 'memo'
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useCreateBusinessMemoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBusinessMemoMutation,
    CreateBusinessMemoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBusinessMemoMutation, CreateBusinessMemoMutationVariables>(
    CreateBusinessMemoDocument,
    options,
  );
}
export type CreateBusinessMemoMutationHookResult = ReturnType<typeof useCreateBusinessMemoMutation>;
export type CreateBusinessMemoMutationResult = Apollo.MutationResult<CreateBusinessMemoMutation>;
export type CreateBusinessMemoMutationOptions = Apollo.BaseMutationOptions<
  CreateBusinessMemoMutation,
  CreateBusinessMemoMutationVariables
>;
export const CreatePersonMemoDocument = gql`
  mutation createPersonMemo($memo: MemoInsertInput!, $personId: Int!) {
    createPersonMemo(memo: $memo, personId: $personId) {
      ...memo
    }
  }
  ${MemoFragmentDoc}
`;
export type CreatePersonMemoMutationFn = Apollo.MutationFunction<
  CreatePersonMemoMutation,
  CreatePersonMemoMutationVariables
>;

/**
 * __useCreatePersonMemoMutation__
 *
 * To run a mutation, you first call `useCreatePersonMemoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePersonMemoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPersonMemoMutation, { data, loading, error }] = useCreatePersonMemoMutation({
 *   variables: {
 *      memo: // value for 'memo'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useCreatePersonMemoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePersonMemoMutation,
    CreatePersonMemoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePersonMemoMutation, CreatePersonMemoMutationVariables>(
    CreatePersonMemoDocument,
    options,
  );
}
export type CreatePersonMemoMutationHookResult = ReturnType<typeof useCreatePersonMemoMutation>;
export type CreatePersonMemoMutationResult = Apollo.MutationResult<CreatePersonMemoMutation>;
export type CreatePersonMemoMutationOptions = Apollo.BaseMutationOptions<
  CreatePersonMemoMutation,
  CreatePersonMemoMutationVariables
>;
export const UpdateMemoDocument = gql`
  mutation updateMemo($memo: MemoUpdateInput!) {
    updateMemo(memo: $memo) {
      ...memo
    }
  }
  ${MemoFragmentDoc}
`;
export type UpdateMemoMutationFn = Apollo.MutationFunction<
  UpdateMemoMutation,
  UpdateMemoMutationVariables
>;

/**
 * __useUpdateMemoMutation__
 *
 * To run a mutation, you first call `useUpdateMemoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemoMutation, { data, loading, error }] = useUpdateMemoMutation({
 *   variables: {
 *      memo: // value for 'memo'
 *   },
 * });
 */
export function useUpdateMemoMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMemoMutation, UpdateMemoMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMemoMutation, UpdateMemoMutationVariables>(
    UpdateMemoDocument,
    options,
  );
}
export type UpdateMemoMutationHookResult = ReturnType<typeof useUpdateMemoMutation>;
export type UpdateMemoMutationResult = Apollo.MutationResult<UpdateMemoMutation>;
export type UpdateMemoMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemoMutation,
  UpdateMemoMutationVariables
>;
export const CreateGdprJobDocument = gql`
  mutation createGDPRJob($personId: Int!) {
    createGDPRJob(personId: $personId) {
      id
      status
      link
      error
    }
  }
`;
export type CreateGdprJobMutationFn = Apollo.MutationFunction<
  CreateGdprJobMutation,
  CreateGdprJobMutationVariables
>;

/**
 * __useCreateGdprJobMutation__
 *
 * To run a mutation, you first call `useCreateGdprJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGdprJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGdprJobMutation, { data, loading, error }] = useCreateGdprJobMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useCreateGdprJobMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateGdprJobMutation, CreateGdprJobMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateGdprJobMutation, CreateGdprJobMutationVariables>(
    CreateGdprJobDocument,
    options,
  );
}
export type CreateGdprJobMutationHookResult = ReturnType<typeof useCreateGdprJobMutation>;
export type CreateGdprJobMutationResult = Apollo.MutationResult<CreateGdprJobMutation>;
export type CreateGdprJobMutationOptions = Apollo.BaseMutationOptions<
  CreateGdprJobMutation,
  CreateGdprJobMutationVariables
>;
export const UpdatePersonAgeAndGenderDocument = gql`
  mutation UpdatePersonAgeAndGender($id: Int!, $initialAge: Int!, $gender: Gender!) {
    updatePersonAgeAndGender(id: $id, initialAge: $initialAge, gender: $gender) {
      ...person
    }
  }
  ${PersonFragmentDoc}
`;
export type UpdatePersonAgeAndGenderMutationFn = Apollo.MutationFunction<
  UpdatePersonAgeAndGenderMutation,
  UpdatePersonAgeAndGenderMutationVariables
>;

/**
 * __useUpdatePersonAgeAndGenderMutation__
 *
 * To run a mutation, you first call `useUpdatePersonAgeAndGenderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonAgeAndGenderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonAgeAndGenderMutation, { data, loading, error }] = useUpdatePersonAgeAndGenderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      initialAge: // value for 'initialAge'
 *      gender: // value for 'gender'
 *   },
 * });
 */
export function useUpdatePersonAgeAndGenderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePersonAgeAndGenderMutation,
    UpdatePersonAgeAndGenderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePersonAgeAndGenderMutation,
    UpdatePersonAgeAndGenderMutationVariables
  >(UpdatePersonAgeAndGenderDocument, options);
}
export type UpdatePersonAgeAndGenderMutationHookResult = ReturnType<
  typeof useUpdatePersonAgeAndGenderMutation
>;
export type UpdatePersonAgeAndGenderMutationResult = Apollo.MutationResult<
  UpdatePersonAgeAndGenderMutation
>;
export type UpdatePersonAgeAndGenderMutationOptions = Apollo.BaseMutationOptions<
  UpdatePersonAgeAndGenderMutation,
  UpdatePersonAgeAndGenderMutationVariables
>;
export const UpdatePersonDocument = gql`
  mutation updatePerson($person: PersonUpdateInput!, $accountId: Int, $assistanceId: String) {
    updatePerson(person: $person, accountId: $accountId, assistanceId: $assistanceId) {
      ...person
    }
  }
  ${PersonFragmentDoc}
`;
export type UpdatePersonMutationFn = Apollo.MutationFunction<
  UpdatePersonMutation,
  UpdatePersonMutationVariables
>;

/**
 * __useUpdatePersonMutation__
 *
 * To run a mutation, you first call `useUpdatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonMutation, { data, loading, error }] = useUpdatePersonMutation({
 *   variables: {
 *      person: // value for 'person'
 *      accountId: // value for 'accountId'
 *      assistanceId: // value for 'assistanceId'
 *   },
 * });
 */
export function useUpdatePersonMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePersonMutation, UpdatePersonMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePersonMutation, UpdatePersonMutationVariables>(
    UpdatePersonDocument,
    options,
  );
}
export type UpdatePersonMutationHookResult = ReturnType<typeof useUpdatePersonMutation>;
export type UpdatePersonMutationResult = Apollo.MutationResult<UpdatePersonMutation>;
export type UpdatePersonMutationOptions = Apollo.BaseMutationOptions<
  UpdatePersonMutation,
  UpdatePersonMutationVariables
>;
export const AddPersonDocument = gql`
  mutation addPerson($person: PersonInput!) {
    addPerson(person: $person) {
      ...person
    }
  }
  ${PersonFragmentDoc}
`;
export type AddPersonMutationFn = Apollo.MutationFunction<
  AddPersonMutation,
  AddPersonMutationVariables
>;

/**
 * __useAddPersonMutation__
 *
 * To run a mutation, you first call `useAddPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPersonMutation, { data, loading, error }] = useAddPersonMutation({
 *   variables: {
 *      person: // value for 'person'
 *   },
 * });
 */
export function useAddPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<AddPersonMutation, AddPersonMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddPersonMutation, AddPersonMutationVariables>(
    AddPersonDocument,
    options,
  );
}
export type AddPersonMutationHookResult = ReturnType<typeof useAddPersonMutation>;
export type AddPersonMutationResult = Apollo.MutationResult<AddPersonMutation>;
export type AddPersonMutationOptions = Apollo.BaseMutationOptions<
  AddPersonMutation,
  AddPersonMutationVariables
>;
export const SetAnonymousPersonDocument = gql`
  mutation setAnonymousPerson($personId: Int!, $personActivityId: Int!) {
    setAnonymousPerson(personId: $personId, personActivityId: $personActivityId) {
      ...person
    }
  }
  ${PersonFragmentDoc}
`;
export type SetAnonymousPersonMutationFn = Apollo.MutationFunction<
  SetAnonymousPersonMutation,
  SetAnonymousPersonMutationVariables
>;

/**
 * __useSetAnonymousPersonMutation__
 *
 * To run a mutation, you first call `useSetAnonymousPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAnonymousPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAnonymousPersonMutation, { data, loading, error }] = useSetAnonymousPersonMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      personActivityId: // value for 'personActivityId'
 *   },
 * });
 */
export function useSetAnonymousPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetAnonymousPersonMutation,
    SetAnonymousPersonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetAnonymousPersonMutation, SetAnonymousPersonMutationVariables>(
    SetAnonymousPersonDocument,
    options,
  );
}
export type SetAnonymousPersonMutationHookResult = ReturnType<typeof useSetAnonymousPersonMutation>;
export type SetAnonymousPersonMutationResult = Apollo.MutationResult<SetAnonymousPersonMutation>;
export type SetAnonymousPersonMutationOptions = Apollo.BaseMutationOptions<
  SetAnonymousPersonMutation,
  SetAnonymousPersonMutationVariables
>;
export const UpdatePersonExtrasDocument = gql`
  mutation updatePersonExtras($personId: Int!, $personExtraInput: PersonExtraInput!) {
    updatePersonExtras(personId: $personId, personExtraInput: $personExtraInput) {
      ...person
    }
  }
  ${PersonFragmentDoc}
`;
export type UpdatePersonExtrasMutationFn = Apollo.MutationFunction<
  UpdatePersonExtrasMutation,
  UpdatePersonExtrasMutationVariables
>;

/**
 * __useUpdatePersonExtrasMutation__
 *
 * To run a mutation, you first call `useUpdatePersonExtrasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonExtrasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonExtrasMutation, { data, loading, error }] = useUpdatePersonExtrasMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      personExtraInput: // value for 'personExtraInput'
 *   },
 * });
 */
export function useUpdatePersonExtrasMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePersonExtrasMutation,
    UpdatePersonExtrasMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePersonExtrasMutation, UpdatePersonExtrasMutationVariables>(
    UpdatePersonExtrasDocument,
    options,
  );
}
export type UpdatePersonExtrasMutationHookResult = ReturnType<typeof useUpdatePersonExtrasMutation>;
export type UpdatePersonExtrasMutationResult = Apollo.MutationResult<UpdatePersonExtrasMutation>;
export type UpdatePersonExtrasMutationOptions = Apollo.BaseMutationOptions<
  UpdatePersonExtrasMutation,
  UpdatePersonExtrasMutationVariables
>;
export const AssignPersonDocument = gql`
  mutation assignPerson($id: Int!, $personId: Int!) {
    assignPerson(id: $id, personId: $personId) {
      id
      person {
        id
        needsAccount
      }
    }
  }
`;
export type AssignPersonMutationFn = Apollo.MutationFunction<
  AssignPersonMutation,
  AssignPersonMutationVariables
>;

/**
 * __useAssignPersonMutation__
 *
 * To run a mutation, you first call `useAssignPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignPersonMutation, { data, loading, error }] = useAssignPersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useAssignPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<AssignPersonMutation, AssignPersonMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignPersonMutation, AssignPersonMutationVariables>(
    AssignPersonDocument,
    options,
  );
}
export type AssignPersonMutationHookResult = ReturnType<typeof useAssignPersonMutation>;
export type AssignPersonMutationResult = Apollo.MutationResult<AssignPersonMutation>;
export type AssignPersonMutationOptions = Apollo.BaseMutationOptions<
  AssignPersonMutation,
  AssignPersonMutationVariables
>;
export const DeleteActivityDocument = gql`
  mutation deleteActivity($id: Int!) {
    deleteActivity(id: $id) {
      id
    }
  }
`;
export type DeleteActivityMutationFn = Apollo.MutationFunction<
  DeleteActivityMutation,
  DeleteActivityMutationVariables
>;

/**
 * __useDeleteActivityMutation__
 *
 * To run a mutation, you first call `useDeleteActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActivityMutation, { data, loading, error }] = useDeleteActivityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteActivityMutation, DeleteActivityMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteActivityMutation, DeleteActivityMutationVariables>(
    DeleteActivityDocument,
    options,
  );
}
export type DeleteActivityMutationHookResult = ReturnType<typeof useDeleteActivityMutation>;
export type DeleteActivityMutationResult = Apollo.MutationResult<DeleteActivityMutation>;
export type DeleteActivityMutationOptions = Apollo.BaseMutationOptions<
  DeleteActivityMutation,
  DeleteActivityMutationVariables
>;
export const SmsNotificationDocument = gql`
  mutation smsNotification($input: SMSInput!) {
    smsNotification(input: $input) {
      id
      description
    }
  }
`;
export type SmsNotificationMutationFn = Apollo.MutationFunction<
  SmsNotificationMutation,
  SmsNotificationMutationVariables
>;

/**
 * __useSmsNotificationMutation__
 *
 * To run a mutation, you first call `useSmsNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSmsNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [smsNotificationMutation, { data, loading, error }] = useSmsNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSmsNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SmsNotificationMutation,
    SmsNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SmsNotificationMutation, SmsNotificationMutationVariables>(
    SmsNotificationDocument,
    options,
  );
}
export type SmsNotificationMutationHookResult = ReturnType<typeof useSmsNotificationMutation>;
export type SmsNotificationMutationResult = Apollo.MutationResult<SmsNotificationMutation>;
export type SmsNotificationMutationOptions = Apollo.BaseMutationOptions<
  SmsNotificationMutation,
  SmsNotificationMutationVariables
>;
export const CreatePhoneDocument = gql`
  mutation createPhone($personId: ID!, $phonesInput: [PhoneInput!]!) {
    createPhone(personId: $personId, phonesInput: $phonesInput) {
      ...phone
    }
  }
  ${PhoneFragmentDoc}
`;
export type CreatePhoneMutationFn = Apollo.MutationFunction<
  CreatePhoneMutation,
  CreatePhoneMutationVariables
>;

/**
 * __useCreatePhoneMutation__
 *
 * To run a mutation, you first call `useCreatePhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPhoneMutation, { data, loading, error }] = useCreatePhoneMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      phonesInput: // value for 'phonesInput'
 *   },
 * });
 */
export function useCreatePhoneMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePhoneMutation, CreatePhoneMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePhoneMutation, CreatePhoneMutationVariables>(
    CreatePhoneDocument,
    options,
  );
}
export type CreatePhoneMutationHookResult = ReturnType<typeof useCreatePhoneMutation>;
export type CreatePhoneMutationResult = Apollo.MutationResult<CreatePhoneMutation>;
export type CreatePhoneMutationOptions = Apollo.BaseMutationOptions<
  CreatePhoneMutation,
  CreatePhoneMutationVariables
>;
export const DeletePhoneDocument = gql`
  mutation deletePhone($id: ID!) {
    deletePhone(id: $id)
  }
`;
export type DeletePhoneMutationFn = Apollo.MutationFunction<
  DeletePhoneMutation,
  DeletePhoneMutationVariables
>;

/**
 * __useDeletePhoneMutation__
 *
 * To run a mutation, you first call `useDeletePhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePhoneMutation, { data, loading, error }] = useDeletePhoneMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePhoneMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePhoneMutation, DeletePhoneMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePhoneMutation, DeletePhoneMutationVariables>(
    DeletePhoneDocument,
    options,
  );
}
export type DeletePhoneMutationHookResult = ReturnType<typeof useDeletePhoneMutation>;
export type DeletePhoneMutationResult = Apollo.MutationResult<DeletePhoneMutation>;
export type DeletePhoneMutationOptions = Apollo.BaseMutationOptions<
  DeletePhoneMutation,
  DeletePhoneMutationVariables
>;
export const UpdatePhonesDocument = gql`
  mutation updatePhones($personId: ID!, $phonesInput: [PhoneInput!]!) {
    updatePhones(personId: $personId, phonesInput: $phonesInput) {
      ...phone
    }
  }
  ${PhoneFragmentDoc}
`;
export type UpdatePhonesMutationFn = Apollo.MutationFunction<
  UpdatePhonesMutation,
  UpdatePhonesMutationVariables
>;

/**
 * __useUpdatePhonesMutation__
 *
 * To run a mutation, you first call `useUpdatePhonesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhonesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhonesMutation, { data, loading, error }] = useUpdatePhonesMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      phonesInput: // value for 'phonesInput'
 *   },
 * });
 */
export function useUpdatePhonesMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePhonesMutation, UpdatePhonesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePhonesMutation, UpdatePhonesMutationVariables>(
    UpdatePhonesDocument,
    options,
  );
}
export type UpdatePhonesMutationHookResult = ReturnType<typeof useUpdatePhonesMutation>;
export type UpdatePhonesMutationResult = Apollo.MutationResult<UpdatePhonesMutation>;
export type UpdatePhonesMutationOptions = Apollo.BaseMutationOptions<
  UpdatePhonesMutation,
  UpdatePhonesMutationVariables
>;
export const CreateCounselingServiceDocument = gql`
  mutation createCounselingService($service: CounselingServiceInput!) {
    createCounselingService(service: $service) {
      id
      status
      wizardStatus
      wizardStep
      client {
        id
        person {
          id
          name
        }
      }
      serviceMeta {
        id
        name
        type
      }
      topic {
        id
        name
      }
    }
  }
`;
export type CreateCounselingServiceMutationFn = Apollo.MutationFunction<
  CreateCounselingServiceMutation,
  CreateCounselingServiceMutationVariables
>;

/**
 * __useCreateCounselingServiceMutation__
 *
 * To run a mutation, you first call `useCreateCounselingServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCounselingServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCounselingServiceMutation, { data, loading, error }] = useCreateCounselingServiceMutation({
 *   variables: {
 *      service: // value for 'service'
 *   },
 * });
 */
export function useCreateCounselingServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCounselingServiceMutation,
    CreateCounselingServiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCounselingServiceMutation,
    CreateCounselingServiceMutationVariables
  >(CreateCounselingServiceDocument, options);
}
export type CreateCounselingServiceMutationHookResult = ReturnType<
  typeof useCreateCounselingServiceMutation
>;
export type CreateCounselingServiceMutationResult = Apollo.MutationResult<
  CreateCounselingServiceMutation
>;
export type CreateCounselingServiceMutationOptions = Apollo.BaseMutationOptions<
  CreateCounselingServiceMutation,
  CreateCounselingServiceMutationVariables
>;
export const CreateServiceDocument = gql`
  mutation createService($service: ServiceInput!) {
    createService(service: $service) {
      id
      status
      wizardStatus
      wizardStep
      client {
        id
        person {
          id
          name
        }
      }
      serviceMeta {
        id
        name
        type
      }
      topic {
        id
        name
      }
    }
  }
`;
export type CreateServiceMutationFn = Apollo.MutationFunction<
  CreateServiceMutation,
  CreateServiceMutationVariables
>;

/**
 * __useCreateServiceMutation__
 *
 * To run a mutation, you first call `useCreateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceMutation, { data, loading, error }] = useCreateServiceMutation({
 *   variables: {
 *      service: // value for 'service'
 *   },
 * });
 */
export function useCreateServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateServiceMutation, CreateServiceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateServiceMutation, CreateServiceMutationVariables>(
    CreateServiceDocument,
    options,
  );
}
export type CreateServiceMutationHookResult = ReturnType<typeof useCreateServiceMutation>;
export type CreateServiceMutationResult = Apollo.MutationResult<CreateServiceMutation>;
export type CreateServiceMutationOptions = Apollo.BaseMutationOptions<
  CreateServiceMutation,
  CreateServiceMutationVariables
>;
export const UpdateServiceDocument = gql`
  mutation updateService($id: Float!, $service: ServiceUpdateInput!) {
    updateService(id: $id, service: $service) {
      id
      wizardStatus
      wizardStep
      status
      extendedInfo
    }
  }
`;
export type UpdateServiceMutationFn = Apollo.MutationFunction<
  UpdateServiceMutation,
  UpdateServiceMutationVariables
>;

/**
 * __useUpdateServiceMutation__
 *
 * To run a mutation, you first call `useUpdateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceMutation, { data, loading, error }] = useUpdateServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      service: // value for 'service'
 *   },
 * });
 */
export function useUpdateServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateServiceMutation, UpdateServiceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateServiceMutation, UpdateServiceMutationVariables>(
    UpdateServiceDocument,
    options,
  );
}
export type UpdateServiceMutationHookResult = ReturnType<typeof useUpdateServiceMutation>;
export type UpdateServiceMutationResult = Apollo.MutationResult<UpdateServiceMutation>;
export type UpdateServiceMutationOptions = Apollo.BaseMutationOptions<
  UpdateServiceMutation,
  UpdateServiceMutationVariables
>;
export const DeleteServiceDocument = gql`
  mutation deleteService($id: Int!) {
    deleteService(id: $id)
  }
`;
export type DeleteServiceMutationFn = Apollo.MutationFunction<
  DeleteServiceMutation,
  DeleteServiceMutationVariables
>;

/**
 * __useDeleteServiceMutation__
 *
 * To run a mutation, you first call `useDeleteServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceMutation, { data, loading, error }] = useDeleteServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteServiceMutation, DeleteServiceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteServiceMutation, DeleteServiceMutationVariables>(
    DeleteServiceDocument,
    options,
  );
}
export type DeleteServiceMutationHookResult = ReturnType<typeof useDeleteServiceMutation>;
export type DeleteServiceMutationResult = Apollo.MutationResult<DeleteServiceMutation>;
export type DeleteServiceMutationOptions = Apollo.BaseMutationOptions<
  DeleteServiceMutation,
  DeleteServiceMutationVariables
>;
export const CreateServiceAddressDocument = gql`
  mutation createServiceAddress($serviceAddress: ServiceAddressCreateInput!) {
    createServiceAddress(serviceAddress: $serviceAddress) {
      id
      serviceMetaAttributeId
      radius
      address {
        id
      }
      service {
        id
      }
    }
  }
`;
export type CreateServiceAddressMutationFn = Apollo.MutationFunction<
  CreateServiceAddressMutation,
  CreateServiceAddressMutationVariables
>;

/**
 * __useCreateServiceAddressMutation__
 *
 * To run a mutation, you first call `useCreateServiceAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceAddressMutation, { data, loading, error }] = useCreateServiceAddressMutation({
 *   variables: {
 *      serviceAddress: // value for 'serviceAddress'
 *   },
 * });
 */
export function useCreateServiceAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateServiceAddressMutation,
    CreateServiceAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateServiceAddressMutation, CreateServiceAddressMutationVariables>(
    CreateServiceAddressDocument,
    options,
  );
}
export type CreateServiceAddressMutationHookResult = ReturnType<
  typeof useCreateServiceAddressMutation
>;
export type CreateServiceAddressMutationResult = Apollo.MutationResult<
  CreateServiceAddressMutation
>;
export type CreateServiceAddressMutationOptions = Apollo.BaseMutationOptions<
  CreateServiceAddressMutation,
  CreateServiceAddressMutationVariables
>;
export const UpdateServiceAddressDocument = gql`
  mutation updateServiceAddress($serviceAddress: ServiceAddressUpdateInput!) {
    updateServiceAddress(serviceAddress: $serviceAddress) {
      id
      serviceMetaAttributeId
      radius
      address {
        id
      }
      service {
        id
      }
    }
  }
`;
export type UpdateServiceAddressMutationFn = Apollo.MutationFunction<
  UpdateServiceAddressMutation,
  UpdateServiceAddressMutationVariables
>;

/**
 * __useUpdateServiceAddressMutation__
 *
 * To run a mutation, you first call `useUpdateServiceAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceAddressMutation, { data, loading, error }] = useUpdateServiceAddressMutation({
 *   variables: {
 *      serviceAddress: // value for 'serviceAddress'
 *   },
 * });
 */
export function useUpdateServiceAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateServiceAddressMutation,
    UpdateServiceAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateServiceAddressMutation, UpdateServiceAddressMutationVariables>(
    UpdateServiceAddressDocument,
    options,
  );
}
export type UpdateServiceAddressMutationHookResult = ReturnType<
  typeof useUpdateServiceAddressMutation
>;
export type UpdateServiceAddressMutationResult = Apollo.MutationResult<
  UpdateServiceAddressMutation
>;
export type UpdateServiceAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateServiceAddressMutation,
  UpdateServiceAddressMutationVariables
>;
export const DeleteServiceAddressDocument = gql`
  mutation deleteServiceAddress($id: Float!) {
    deleteServiceAddress(id: $id) {
      id
    }
  }
`;
export type DeleteServiceAddressMutationFn = Apollo.MutationFunction<
  DeleteServiceAddressMutation,
  DeleteServiceAddressMutationVariables
>;

/**
 * __useDeleteServiceAddressMutation__
 *
 * To run a mutation, you first call `useDeleteServiceAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceAddressMutation, { data, loading, error }] = useDeleteServiceAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteServiceAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteServiceAddressMutation,
    DeleteServiceAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteServiceAddressMutation, DeleteServiceAddressMutationVariables>(
    DeleteServiceAddressDocument,
    options,
  );
}
export type DeleteServiceAddressMutationHookResult = ReturnType<
  typeof useDeleteServiceAddressMutation
>;
export type DeleteServiceAddressMutationResult = Apollo.MutationResult<
  DeleteServiceAddressMutation
>;
export type DeleteServiceAddressMutationOptions = Apollo.BaseMutationOptions<
  DeleteServiceAddressMutation,
  DeleteServiceAddressMutationVariables
>;
export const CreateServiceAttributeDocument = gql`
  mutation createServiceAttribute($serviceAttribute: ServiceAttributeInput!) {
    createServiceAttribute(serviceAttribute: $serviceAttribute) {
      id
      serviceMetaAttributeId
      serviceId
      value
    }
  }
`;
export type CreateServiceAttributeMutationFn = Apollo.MutationFunction<
  CreateServiceAttributeMutation,
  CreateServiceAttributeMutationVariables
>;

/**
 * __useCreateServiceAttributeMutation__
 *
 * To run a mutation, you first call `useCreateServiceAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceAttributeMutation, { data, loading, error }] = useCreateServiceAttributeMutation({
 *   variables: {
 *      serviceAttribute: // value for 'serviceAttribute'
 *   },
 * });
 */
export function useCreateServiceAttributeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateServiceAttributeMutation,
    CreateServiceAttributeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateServiceAttributeMutation,
    CreateServiceAttributeMutationVariables
  >(CreateServiceAttributeDocument, options);
}
export type CreateServiceAttributeMutationHookResult = ReturnType<
  typeof useCreateServiceAttributeMutation
>;
export type CreateServiceAttributeMutationResult = Apollo.MutationResult<
  CreateServiceAttributeMutation
>;
export type CreateServiceAttributeMutationOptions = Apollo.BaseMutationOptions<
  CreateServiceAttributeMutation,
  CreateServiceAttributeMutationVariables
>;
export const UpdateServiceAttributeDocument = gql`
  mutation updateServiceAttribute($serviceAttribute: ServiceAttributeUpdate!) {
    updateServiceAttribute(serviceAttribute: $serviceAttribute) {
      id
      serviceId
      value
    }
  }
`;
export type UpdateServiceAttributeMutationFn = Apollo.MutationFunction<
  UpdateServiceAttributeMutation,
  UpdateServiceAttributeMutationVariables
>;

/**
 * __useUpdateServiceAttributeMutation__
 *
 * To run a mutation, you first call `useUpdateServiceAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceAttributeMutation, { data, loading, error }] = useUpdateServiceAttributeMutation({
 *   variables: {
 *      serviceAttribute: // value for 'serviceAttribute'
 *   },
 * });
 */
export function useUpdateServiceAttributeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateServiceAttributeMutation,
    UpdateServiceAttributeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateServiceAttributeMutation,
    UpdateServiceAttributeMutationVariables
  >(UpdateServiceAttributeDocument, options);
}
export type UpdateServiceAttributeMutationHookResult = ReturnType<
  typeof useUpdateServiceAttributeMutation
>;
export type UpdateServiceAttributeMutationResult = Apollo.MutationResult<
  UpdateServiceAttributeMutation
>;
export type UpdateServiceAttributeMutationOptions = Apollo.BaseMutationOptions<
  UpdateServiceAttributeMutation,
  UpdateServiceAttributeMutationVariables
>;
export const DeleteServiceAttributeDocument = gql`
  mutation deleteServiceAttribute($id: Float!) {
    deleteServiceAttribute(id: $id) {
      id
    }
  }
`;
export type DeleteServiceAttributeMutationFn = Apollo.MutationFunction<
  DeleteServiceAttributeMutation,
  DeleteServiceAttributeMutationVariables
>;

/**
 * __useDeleteServiceAttributeMutation__
 *
 * To run a mutation, you first call `useDeleteServiceAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceAttributeMutation, { data, loading, error }] = useDeleteServiceAttributeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteServiceAttributeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteServiceAttributeMutation,
    DeleteServiceAttributeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteServiceAttributeMutation,
    DeleteServiceAttributeMutationVariables
  >(DeleteServiceAttributeDocument, options);
}
export type DeleteServiceAttributeMutationHookResult = ReturnType<
  typeof useDeleteServiceAttributeMutation
>;
export type DeleteServiceAttributeMutationResult = Apollo.MutationResult<
  DeleteServiceAttributeMutation
>;
export type DeleteServiceAttributeMutationOptions = Apollo.BaseMutationOptions<
  DeleteServiceAttributeMutation,
  DeleteServiceAttributeMutationVariables
>;
export const CreateServiceClientDocument = gql`
  mutation createServiceClient($serviceClient: ServiceClientInput!) {
    createServiceClient(serviceClient: $serviceClient) {
      id
      serviceMetaAttributeId
      client {
        id
        person {
          id
          name
        }
      }
      service {
        id
      }
      serviceMetaAttribute {
        id
      }
    }
  }
`;
export type CreateServiceClientMutationFn = Apollo.MutationFunction<
  CreateServiceClientMutation,
  CreateServiceClientMutationVariables
>;

/**
 * __useCreateServiceClientMutation__
 *
 * To run a mutation, you first call `useCreateServiceClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceClientMutation, { data, loading, error }] = useCreateServiceClientMutation({
 *   variables: {
 *      serviceClient: // value for 'serviceClient'
 *   },
 * });
 */
export function useCreateServiceClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateServiceClientMutation,
    CreateServiceClientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateServiceClientMutation, CreateServiceClientMutationVariables>(
    CreateServiceClientDocument,
    options,
  );
}
export type CreateServiceClientMutationHookResult = ReturnType<
  typeof useCreateServiceClientMutation
>;
export type CreateServiceClientMutationResult = Apollo.MutationResult<CreateServiceClientMutation>;
export type CreateServiceClientMutationOptions = Apollo.BaseMutationOptions<
  CreateServiceClientMutation,
  CreateServiceClientMutationVariables
>;
export const DeleteServiceClientDocument = gql`
  mutation deleteServiceClient($id: Float!) {
    deleteServiceClient(id: $id) {
      id
    }
  }
`;
export type DeleteServiceClientMutationFn = Apollo.MutationFunction<
  DeleteServiceClientMutation,
  DeleteServiceClientMutationVariables
>;

/**
 * __useDeleteServiceClientMutation__
 *
 * To run a mutation, you first call `useDeleteServiceClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceClientMutation, { data, loading, error }] = useDeleteServiceClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteServiceClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteServiceClientMutation,
    DeleteServiceClientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteServiceClientMutation, DeleteServiceClientMutationVariables>(
    DeleteServiceClientDocument,
    options,
  );
}
export type DeleteServiceClientMutationHookResult = ReturnType<
  typeof useDeleteServiceClientMutation
>;
export type DeleteServiceClientMutationResult = Apollo.MutationResult<DeleteServiceClientMutation>;
export type DeleteServiceClientMutationOptions = Apollo.BaseMutationOptions<
  DeleteServiceClientMutation,
  DeleteServiceClientMutationVariables
>;
export const CreateServiceResearchObjectDocument = gql`
  mutation createServiceResearchObject(
    $serviceResearchObjectInput: ServiceResearchObjectInput!
    $serviceId: ID!
  ) {
    createServiceResearchObject(
      serviceResearchObjectInput: $serviceResearchObjectInput
      serviceId: $serviceId
    ) {
      ...serviceResearchObject
      contactPerson {
        ...basePerson
      }
      businessObject {
        ...businessObjectData
        address {
          ...address
        }
        email {
          ...email
        }
        phones {
          ...phone
        }
        hours {
          id
          open
          close
          day
        }
      }
    }
  }
  ${ServiceResearchObjectFragmentDoc}
  ${BasePersonFragmentDoc}
  ${BusinessObjectDataFragmentDoc}
  ${AddressFragmentDoc}
  ${EmailFragmentDoc}
  ${PhoneFragmentDoc}
`;
export type CreateServiceResearchObjectMutationFn = Apollo.MutationFunction<
  CreateServiceResearchObjectMutation,
  CreateServiceResearchObjectMutationVariables
>;

/**
 * __useCreateServiceResearchObjectMutation__
 *
 * To run a mutation, you first call `useCreateServiceResearchObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceResearchObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceResearchObjectMutation, { data, loading, error }] = useCreateServiceResearchObjectMutation({
 *   variables: {
 *      serviceResearchObjectInput: // value for 'serviceResearchObjectInput'
 *      serviceId: // value for 'serviceId'
 *   },
 * });
 */
export function useCreateServiceResearchObjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateServiceResearchObjectMutation,
    CreateServiceResearchObjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateServiceResearchObjectMutation,
    CreateServiceResearchObjectMutationVariables
  >(CreateServiceResearchObjectDocument, options);
}
export type CreateServiceResearchObjectMutationHookResult = ReturnType<
  typeof useCreateServiceResearchObjectMutation
>;
export type CreateServiceResearchObjectMutationResult = Apollo.MutationResult<
  CreateServiceResearchObjectMutation
>;
export type CreateServiceResearchObjectMutationOptions = Apollo.BaseMutationOptions<
  CreateServiceResearchObjectMutation,
  CreateServiceResearchObjectMutationVariables
>;
export const CreatePlainResearchObjectDocument = gql`
  mutation createPlainResearchObject($input: PlainSROInput!, $serviceId: Int!) {
    createPlainResearchObject(input: $input, serviceId: $serviceId) {
      ...serviceResearchObject
      contactPerson {
        ...basePerson
      }
      businessObject {
        ...businessObjectData
        address {
          ...address
        }
        email {
          ...email
        }
        phones {
          ...phone
        }
        hours {
          id
          open
          close
          day
        }
      }
    }
  }
  ${ServiceResearchObjectFragmentDoc}
  ${BasePersonFragmentDoc}
  ${BusinessObjectDataFragmentDoc}
  ${AddressFragmentDoc}
  ${EmailFragmentDoc}
  ${PhoneFragmentDoc}
`;
export type CreatePlainResearchObjectMutationFn = Apollo.MutationFunction<
  CreatePlainResearchObjectMutation,
  CreatePlainResearchObjectMutationVariables
>;

/**
 * __useCreatePlainResearchObjectMutation__
 *
 * To run a mutation, you first call `useCreatePlainResearchObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlainResearchObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlainResearchObjectMutation, { data, loading, error }] = useCreatePlainResearchObjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *      serviceId: // value for 'serviceId'
 *   },
 * });
 */
export function useCreatePlainResearchObjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePlainResearchObjectMutation,
    CreatePlainResearchObjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePlainResearchObjectMutation,
    CreatePlainResearchObjectMutationVariables
  >(CreatePlainResearchObjectDocument, options);
}
export type CreatePlainResearchObjectMutationHookResult = ReturnType<
  typeof useCreatePlainResearchObjectMutation
>;
export type CreatePlainResearchObjectMutationResult = Apollo.MutationResult<
  CreatePlainResearchObjectMutation
>;
export type CreatePlainResearchObjectMutationOptions = Apollo.BaseMutationOptions<
  CreatePlainResearchObjectMutation,
  CreatePlainResearchObjectMutationVariables
>;
export const UpdatePlainResearchObjectDocument = gql`
  mutation updatePlainResearchObject($input: PlainSROInput!, $id: Int!) {
    updatePlainResearchObject(input: $input, id: $id) {
      ...serviceResearchObject
      contactPerson {
        ...basePerson
      }
      businessObject {
        ...businessObjectData
        address {
          ...address
        }
        email {
          ...email
        }
        phones {
          ...phone
        }
        hours {
          id
          open
          close
          day
        }
      }
    }
  }
  ${ServiceResearchObjectFragmentDoc}
  ${BasePersonFragmentDoc}
  ${BusinessObjectDataFragmentDoc}
  ${AddressFragmentDoc}
  ${EmailFragmentDoc}
  ${PhoneFragmentDoc}
`;
export type UpdatePlainResearchObjectMutationFn = Apollo.MutationFunction<
  UpdatePlainResearchObjectMutation,
  UpdatePlainResearchObjectMutationVariables
>;

/**
 * __useUpdatePlainResearchObjectMutation__
 *
 * To run a mutation, you first call `useUpdatePlainResearchObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlainResearchObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlainResearchObjectMutation, { data, loading, error }] = useUpdatePlainResearchObjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdatePlainResearchObjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePlainResearchObjectMutation,
    UpdatePlainResearchObjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePlainResearchObjectMutation,
    UpdatePlainResearchObjectMutationVariables
  >(UpdatePlainResearchObjectDocument, options);
}
export type UpdatePlainResearchObjectMutationHookResult = ReturnType<
  typeof useUpdatePlainResearchObjectMutation
>;
export type UpdatePlainResearchObjectMutationResult = Apollo.MutationResult<
  UpdatePlainResearchObjectMutation
>;
export type UpdatePlainResearchObjectMutationOptions = Apollo.BaseMutationOptions<
  UpdatePlainResearchObjectMutation,
  UpdatePlainResearchObjectMutationVariables
>;
export const CreateServiceResearchObjectsDocument = gql`
  mutation createServiceResearchObjects($businessObjectIds: [Int]!, $serviceId: Int!) {
    createServiceResearchObjects(businessObjectIds: $businessObjectIds, serviceId: $serviceId) {
      ...serviceResearchObject
      contactPerson {
        ...basePerson
      }
      businessObject {
        ...businessObjectData
        address {
          ...address
        }
        email {
          ...email
        }
        phones {
          ...phone
        }
        hours {
          id
          open
          close
          day
        }
        persons {
          ...basePerson
        }
      }
    }
  }
  ${ServiceResearchObjectFragmentDoc}
  ${BasePersonFragmentDoc}
  ${BusinessObjectDataFragmentDoc}
  ${AddressFragmentDoc}
  ${EmailFragmentDoc}
  ${PhoneFragmentDoc}
`;
export type CreateServiceResearchObjectsMutationFn = Apollo.MutationFunction<
  CreateServiceResearchObjectsMutation,
  CreateServiceResearchObjectsMutationVariables
>;

/**
 * __useCreateServiceResearchObjectsMutation__
 *
 * To run a mutation, you first call `useCreateServiceResearchObjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceResearchObjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceResearchObjectsMutation, { data, loading, error }] = useCreateServiceResearchObjectsMutation({
 *   variables: {
 *      businessObjectIds: // value for 'businessObjectIds'
 *      serviceId: // value for 'serviceId'
 *   },
 * });
 */
export function useCreateServiceResearchObjectsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateServiceResearchObjectsMutation,
    CreateServiceResearchObjectsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateServiceResearchObjectsMutation,
    CreateServiceResearchObjectsMutationVariables
  >(CreateServiceResearchObjectsDocument, options);
}
export type CreateServiceResearchObjectsMutationHookResult = ReturnType<
  typeof useCreateServiceResearchObjectsMutation
>;
export type CreateServiceResearchObjectsMutationResult = Apollo.MutationResult<
  CreateServiceResearchObjectsMutation
>;
export type CreateServiceResearchObjectsMutationOptions = Apollo.BaseMutationOptions<
  CreateServiceResearchObjectsMutation,
  CreateServiceResearchObjectsMutationVariables
>;
export const UpdateServiceResearchObjectDocument = gql`
  mutation updateServiceResearchObject(
    $serviceResearchObjectInput: ServiceResearchObjectInput!
    $id: ID!
  ) {
    updateServiceResearchObject(serviceResearchObjectInput: $serviceResearchObjectInput, id: $id) {
      ...serviceResearchObject
      contactPerson {
        ...basePerson
      }
      businessObject {
        ...businessObjectData
        address {
          ...address
        }
        email {
          ...email
        }
        phones {
          ...phone
        }
        hours {
          id
          open
          close
          day
        }
        persons {
          ...basePerson
        }
      }
    }
  }
  ${ServiceResearchObjectFragmentDoc}
  ${BasePersonFragmentDoc}
  ${BusinessObjectDataFragmentDoc}
  ${AddressFragmentDoc}
  ${EmailFragmentDoc}
  ${PhoneFragmentDoc}
`;
export type UpdateServiceResearchObjectMutationFn = Apollo.MutationFunction<
  UpdateServiceResearchObjectMutation,
  UpdateServiceResearchObjectMutationVariables
>;

/**
 * __useUpdateServiceResearchObjectMutation__
 *
 * To run a mutation, you first call `useUpdateServiceResearchObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceResearchObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceResearchObjectMutation, { data, loading, error }] = useUpdateServiceResearchObjectMutation({
 *   variables: {
 *      serviceResearchObjectInput: // value for 'serviceResearchObjectInput'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateServiceResearchObjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateServiceResearchObjectMutation,
    UpdateServiceResearchObjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateServiceResearchObjectMutation,
    UpdateServiceResearchObjectMutationVariables
  >(UpdateServiceResearchObjectDocument, options);
}
export type UpdateServiceResearchObjectMutationHookResult = ReturnType<
  typeof useUpdateServiceResearchObjectMutation
>;
export type UpdateServiceResearchObjectMutationResult = Apollo.MutationResult<
  UpdateServiceResearchObjectMutation
>;
export type UpdateServiceResearchObjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateServiceResearchObjectMutation,
  UpdateServiceResearchObjectMutationVariables
>;
export const ToggleReportStatisticDocument = gql`
  mutation toggleReportStatistic($reportId: Int!, $itemId: Int!) {
    toggleReportStatistic(reportId: $reportId, itemId: $itemId) {
      id
    }
  }
`;
export type ToggleReportStatisticMutationFn = Apollo.MutationFunction<
  ToggleReportStatisticMutation,
  ToggleReportStatisticMutationVariables
>;

/**
 * __useToggleReportStatisticMutation__
 *
 * To run a mutation, you first call `useToggleReportStatisticMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleReportStatisticMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleReportStatisticMutation, { data, loading, error }] = useToggleReportStatisticMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useToggleReportStatisticMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleReportStatisticMutation,
    ToggleReportStatisticMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ToggleReportStatisticMutation, ToggleReportStatisticMutationVariables>(
    ToggleReportStatisticDocument,
    options,
  );
}
export type ToggleReportStatisticMutationHookResult = ReturnType<
  typeof useToggleReportStatisticMutation
>;
export type ToggleReportStatisticMutationResult = Apollo.MutationResult<
  ToggleReportStatisticMutation
>;
export type ToggleReportStatisticMutationOptions = Apollo.BaseMutationOptions<
  ToggleReportStatisticMutation,
  ToggleReportStatisticMutationVariables
>;
export const SnoozeTaskDocument = gql`
  mutation snoozeTask($taskId: Int!, $snoozeUntil: DateTime!) {
    snoozeTask(taskId: $taskId, snoozeUntil: $snoozeUntil)
  }
`;
export type SnoozeTaskMutationFn = Apollo.MutationFunction<
  SnoozeTaskMutation,
  SnoozeTaskMutationVariables
>;

/**
 * __useSnoozeTaskMutation__
 *
 * To run a mutation, you first call `useSnoozeTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSnoozeTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [snoozeTaskMutation, { data, loading, error }] = useSnoozeTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      snoozeUntil: // value for 'snoozeUntil'
 *   },
 * });
 */
export function useSnoozeTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<SnoozeTaskMutation, SnoozeTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SnoozeTaskMutation, SnoozeTaskMutationVariables>(
    SnoozeTaskDocument,
    options,
  );
}
export type SnoozeTaskMutationHookResult = ReturnType<typeof useSnoozeTaskMutation>;
export type SnoozeTaskMutationResult = Apollo.MutationResult<SnoozeTaskMutation>;
export type SnoozeTaskMutationOptions = Apollo.BaseMutationOptions<
  SnoozeTaskMutation,
  SnoozeTaskMutationVariables
>;
export const AssignTaskDocument = gql`
  mutation assignTask($taskId: Int!, $userId: Int, $start: Boolean) {
    assignTask(taskId: $taskId, userId: $userId, start: $start)
  }
`;
export type AssignTaskMutationFn = Apollo.MutationFunction<
  AssignTaskMutation,
  AssignTaskMutationVariables
>;

/**
 * __useAssignTaskMutation__
 *
 * To run a mutation, you first call `useAssignTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTaskMutation, { data, loading, error }] = useAssignTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      userId: // value for 'userId'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useAssignTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<AssignTaskMutation, AssignTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignTaskMutation, AssignTaskMutationVariables>(
    AssignTaskDocument,
    options,
  );
}
export type AssignTaskMutationHookResult = ReturnType<typeof useAssignTaskMutation>;
export type AssignTaskMutationResult = Apollo.MutationResult<AssignTaskMutation>;
export type AssignTaskMutationOptions = Apollo.BaseMutationOptions<
  AssignTaskMutation,
  AssignTaskMutationVariables
>;
export const FinishTaskDocument = gql`
  mutation finishTask($taskId: Int!, $revert: Boolean) {
    finishTask(taskId: $taskId, revert: $revert)
  }
`;
export type FinishTaskMutationFn = Apollo.MutationFunction<
  FinishTaskMutation,
  FinishTaskMutationVariables
>;

/**
 * __useFinishTaskMutation__
 *
 * To run a mutation, you first call `useFinishTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishTaskMutation, { data, loading, error }] = useFinishTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      revert: // value for 'revert'
 *   },
 * });
 */
export function useFinishTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<FinishTaskMutation, FinishTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FinishTaskMutation, FinishTaskMutationVariables>(
    FinishTaskDocument,
    options,
  );
}
export type FinishTaskMutationHookResult = ReturnType<typeof useFinishTaskMutation>;
export type FinishTaskMutationResult = Apollo.MutationResult<FinishTaskMutation>;
export type FinishTaskMutationOptions = Apollo.BaseMutationOptions<
  FinishTaskMutation,
  FinishTaskMutationVariables
>;
export const UnassignTaskDocument = gql`
  mutation unassignTask($taskId: Int!) {
    unassignTask(taskId: $taskId)
  }
`;
export type UnassignTaskMutationFn = Apollo.MutationFunction<
  UnassignTaskMutation,
  UnassignTaskMutationVariables
>;

/**
 * __useUnassignTaskMutation__
 *
 * To run a mutation, you first call `useUnassignTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignTaskMutation, { data, loading, error }] = useUnassignTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useUnassignTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<UnassignTaskMutation, UnassignTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnassignTaskMutation, UnassignTaskMutationVariables>(
    UnassignTaskDocument,
    options,
  );
}
export type UnassignTaskMutationHookResult = ReturnType<typeof useUnassignTaskMutation>;
export type UnassignTaskMutationResult = Apollo.MutationResult<UnassignTaskMutation>;
export type UnassignTaskMutationOptions = Apollo.BaseMutationOptions<
  UnassignTaskMutation,
  UnassignTaskMutationVariables
>;
export const CreateCustomTaskDocument = gql`
  mutation createCustomTask($taskInput: TaskInput) {
    createCustomTask(taskInput: $taskInput) {
      id
      title
    }
  }
`;
export type CreateCustomTaskMutationFn = Apollo.MutationFunction<
  CreateCustomTaskMutation,
  CreateCustomTaskMutationVariables
>;

/**
 * __useCreateCustomTaskMutation__
 *
 * To run a mutation, you first call `useCreateCustomTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomTaskMutation, { data, loading, error }] = useCreateCustomTaskMutation({
 *   variables: {
 *      taskInput: // value for 'taskInput'
 *   },
 * });
 */
export function useCreateCustomTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCustomTaskMutation,
    CreateCustomTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCustomTaskMutation, CreateCustomTaskMutationVariables>(
    CreateCustomTaskDocument,
    options,
  );
}
export type CreateCustomTaskMutationHookResult = ReturnType<typeof useCreateCustomTaskMutation>;
export type CreateCustomTaskMutationResult = Apollo.MutationResult<CreateCustomTaskMutation>;
export type CreateCustomTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateCustomTaskMutation,
  CreateCustomTaskMutationVariables
>;
export const AssignTaskToTeamDocument = gql`
  mutation assignTaskToTeam($taskId: Int!, $targetTeam: Team, $start: Boolean) {
    assignTaskToTeam(taskId: $taskId, targetTeam: $targetTeam, start: $start)
  }
`;
export type AssignTaskToTeamMutationFn = Apollo.MutationFunction<
  AssignTaskToTeamMutation,
  AssignTaskToTeamMutationVariables
>;

/**
 * __useAssignTaskToTeamMutation__
 *
 * To run a mutation, you first call `useAssignTaskToTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTaskToTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTaskToTeamMutation, { data, loading, error }] = useAssignTaskToTeamMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      targetTeam: // value for 'targetTeam'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useAssignTaskToTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignTaskToTeamMutation,
    AssignTaskToTeamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignTaskToTeamMutation, AssignTaskToTeamMutationVariables>(
    AssignTaskToTeamDocument,
    options,
  );
}
export type AssignTaskToTeamMutationHookResult = ReturnType<typeof useAssignTaskToTeamMutation>;
export type AssignTaskToTeamMutationResult = Apollo.MutationResult<AssignTaskToTeamMutation>;
export type AssignTaskToTeamMutationOptions = Apollo.BaseMutationOptions<
  AssignTaskToTeamMutation,
  AssignTaskToTeamMutationVariables
>;
export const CreateCmReminderTaskDocument = gql`
  mutation createCMReminderTask($personId: Int!, $snoozeUntil: DateTime!, $taskId: Int) {
    createCMReminderTask(personId: $personId, snoozeUntil: $snoozeUntil, taskId: $taskId) {
      id
      title
      snoozeUntil
    }
  }
`;
export type CreateCmReminderTaskMutationFn = Apollo.MutationFunction<
  CreateCmReminderTaskMutation,
  CreateCmReminderTaskMutationVariables
>;

/**
 * __useCreateCmReminderTaskMutation__
 *
 * To run a mutation, you first call `useCreateCmReminderTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCmReminderTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCmReminderTaskMutation, { data, loading, error }] = useCreateCmReminderTaskMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      snoozeUntil: // value for 'snoozeUntil'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useCreateCmReminderTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCmReminderTaskMutation,
    CreateCmReminderTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCmReminderTaskMutation, CreateCmReminderTaskMutationVariables>(
    CreateCmReminderTaskDocument,
    options,
  );
}
export type CreateCmReminderTaskMutationHookResult = ReturnType<
  typeof useCreateCmReminderTaskMutation
>;
export type CreateCmReminderTaskMutationResult = Apollo.MutationResult<
  CreateCmReminderTaskMutation
>;
export type CreateCmReminderTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateCmReminderTaskMutation,
  CreateCmReminderTaskMutationVariables
>;
export const FinishWpoAssignmentProcessDocument = gql`
  mutation finishWpoAssignmentProcess(
    $ucmsId: Int!
    $messageId: String!
    $personId: Int!
    $shouldCreateTask: Boolean!
    $shouldSendWpoEmail: Boolean!
  ) {
    finishWpoAssignmentProcess(
      ucmsId: $ucmsId
      messageId: $messageId
      personId: $personId
      shouldCreateTask: $shouldCreateTask
      shouldSendWpoEmail: $shouldSendWpoEmail
    ) {
      id
      title
      snoozeUntil
    }
  }
`;
export type FinishWpoAssignmentProcessMutationFn = Apollo.MutationFunction<
  FinishWpoAssignmentProcessMutation,
  FinishWpoAssignmentProcessMutationVariables
>;

/**
 * __useFinishWpoAssignmentProcessMutation__
 *
 * To run a mutation, you first call `useFinishWpoAssignmentProcessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishWpoAssignmentProcessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishWpoAssignmentProcessMutation, { data, loading, error }] = useFinishWpoAssignmentProcessMutation({
 *   variables: {
 *      ucmsId: // value for 'ucmsId'
 *      messageId: // value for 'messageId'
 *      personId: // value for 'personId'
 *      shouldCreateTask: // value for 'shouldCreateTask'
 *      shouldSendWpoEmail: // value for 'shouldSendWpoEmail'
 *   },
 * });
 */
export function useFinishWpoAssignmentProcessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinishWpoAssignmentProcessMutation,
    FinishWpoAssignmentProcessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FinishWpoAssignmentProcessMutation,
    FinishWpoAssignmentProcessMutationVariables
  >(FinishWpoAssignmentProcessDocument, options);
}
export type FinishWpoAssignmentProcessMutationHookResult = ReturnType<
  typeof useFinishWpoAssignmentProcessMutation
>;
export type FinishWpoAssignmentProcessMutationResult = Apollo.MutationResult<
  FinishWpoAssignmentProcessMutation
>;
export type FinishWpoAssignmentProcessMutationOptions = Apollo.BaseMutationOptions<
  FinishWpoAssignmentProcessMutation,
  FinishWpoAssignmentProcessMutationVariables
>;
export const UpdateTeamsDocument = gql`
  mutation updateTeams($teams: [Team!]!) {
    updateTeams(teams: $teams)
  }
`;
export type UpdateTeamsMutationFn = Apollo.MutationFunction<
  UpdateTeamsMutation,
  UpdateTeamsMutationVariables
>;

/**
 * __useUpdateTeamsMutation__
 *
 * To run a mutation, you first call `useUpdateTeamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamsMutation, { data, loading, error }] = useUpdateTeamsMutation({
 *   variables: {
 *      teams: // value for 'teams'
 *   },
 * });
 */
export function useUpdateTeamsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTeamsMutation, UpdateTeamsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTeamsMutation, UpdateTeamsMutationVariables>(
    UpdateTeamsDocument,
    options,
  );
}
export type UpdateTeamsMutationHookResult = ReturnType<typeof useUpdateTeamsMutation>;
export type UpdateTeamsMutationResult = Apollo.MutationResult<UpdateTeamsMutation>;
export type UpdateTeamsMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeamsMutation,
  UpdateTeamsMutationVariables
>;
export const UpdateAbsentDocument = gql`
  mutation updateAbsent($id: Int!, $absentUntil: DateTime!) {
    updateAbsent(id: $id, absentUntil: $absentUntil) {
      id
    }
  }
`;
export type UpdateAbsentMutationFn = Apollo.MutationFunction<
  UpdateAbsentMutation,
  UpdateAbsentMutationVariables
>;

/**
 * __useUpdateAbsentMutation__
 *
 * To run a mutation, you first call `useUpdateAbsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAbsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAbsentMutation, { data, loading, error }] = useUpdateAbsentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      absentUntil: // value for 'absentUntil'
 *   },
 * });
 */
export function useUpdateAbsentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAbsentMutation, UpdateAbsentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAbsentMutation, UpdateAbsentMutationVariables>(
    UpdateAbsentDocument,
    options,
  );
}
export type UpdateAbsentMutationHookResult = ReturnType<typeof useUpdateAbsentMutation>;
export type UpdateAbsentMutationResult = Apollo.MutationResult<UpdateAbsentMutation>;
export type UpdateAbsentMutationOptions = Apollo.BaseMutationOptions<
  UpdateAbsentMutation,
  UpdateAbsentMutationVariables
>;
export const UpdateExtensionDocument = gql`
  mutation updateExtension($id: Int!, $extension: String!) {
    updateExtension(id: $id, extension: $extension) {
      id
    }
  }
`;
export type UpdateExtensionMutationFn = Apollo.MutationFunction<
  UpdateExtensionMutation,
  UpdateExtensionMutationVariables
>;

/**
 * __useUpdateExtensionMutation__
 *
 * To run a mutation, you first call `useUpdateExtensionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExtensionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExtensionMutation, { data, loading, error }] = useUpdateExtensionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      extension: // value for 'extension'
 *   },
 * });
 */
export function useUpdateExtensionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateExtensionMutation,
    UpdateExtensionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateExtensionMutation, UpdateExtensionMutationVariables>(
    UpdateExtensionDocument,
    options,
  );
}
export type UpdateExtensionMutationHookResult = ReturnType<typeof useUpdateExtensionMutation>;
export type UpdateExtensionMutationResult = Apollo.MutationResult<UpdateExtensionMutation>;
export type UpdateExtensionMutationOptions = Apollo.BaseMutationOptions<
  UpdateExtensionMutation,
  UpdateExtensionMutationVariables
>;
export const AccountsDocument = gql`
  query accounts($name: String) {
    accounts(name: $name) {
      ...accountMinimal
    }
  }
  ${AccountMinimalFragmentDoc}
`;

/**
 * __useAccountsQuery__
 *
 * To run a query within a React component, call `useAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<AccountsQuery, AccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
}
export function useAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountsQuery, AccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
}
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>;
export type AccountsLazyQueryHookResult = ReturnType<typeof useAccountsLazyQuery>;
export type AccountsQueryResult = Apollo.QueryResult<AccountsQuery, AccountsQueryVariables>;
export const AccountDocument = gql`
  query account($id: Int!) {
    account(id: $id) {
      ...account
      accountServiceModules {
        id
        name
        serviceCategoryId
      }
      parent {
        id
        name
        serviceRegion
      }
      business {
        ...businessObjectData
        memo
        address {
          ...address
        }
        email {
          ...email
        }
        persons {
          ...basePerson
        }
        phones {
          ...phone
        }
      }
    }
  }
  ${AccountFragmentDoc}
  ${BusinessObjectDataFragmentDoc}
  ${AddressFragmentDoc}
  ${EmailFragmentDoc}
  ${BasePersonFragmentDoc}
  ${PhoneFragmentDoc}
`;

/**
 * __useAccountQuery__
 *
 * To run a query within a React component, call `useAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountQuery(
  baseOptions: Apollo.QueryHookOptions<AccountQuery, AccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountQuery, AccountQueryVariables>(AccountDocument, options);
}
export function useAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountQuery, AccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountQuery, AccountQueryVariables>(AccountDocument, options);
}
export type AccountQueryHookResult = ReturnType<typeof useAccountQuery>;
export type AccountLazyQueryHookResult = ReturnType<typeof useAccountLazyQuery>;
export type AccountQueryResult = Apollo.QueryResult<AccountQuery, AccountQueryVariables>;
export const AccountStatisticsDocument = gql`
  query accountStatistics($accountId: Int!, $start: DateTime!, $end: DateTime!) {
    accountStatistics(accountId: $accountId, start: $start, end: $end) {
      fileId
    }
  }
`;

/**
 * __useAccountStatisticsQuery__
 *
 * To run a query within a React component, call `useAccountStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountStatisticsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useAccountStatisticsQuery(
  baseOptions: Apollo.QueryHookOptions<AccountStatisticsQuery, AccountStatisticsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountStatisticsQuery, AccountStatisticsQueryVariables>(
    AccountStatisticsDocument,
    options,
  );
}
export function useAccountStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountStatisticsQuery,
    AccountStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountStatisticsQuery, AccountStatisticsQueryVariables>(
    AccountStatisticsDocument,
    options,
  );
}
export type AccountStatisticsQueryHookResult = ReturnType<typeof useAccountStatisticsQuery>;
export type AccountStatisticsLazyQueryHookResult = ReturnType<typeof useAccountStatisticsLazyQuery>;
export type AccountStatisticsQueryResult = Apollo.QueryResult<
  AccountStatisticsQuery,
  AccountStatisticsQueryVariables
>;
export const AccountStatisticsByTypeDocument = gql`
  query accountStatisticsByType($type: String!, $start: DateTime!, $end: DateTime!) {
    accountStatisticsByType(type: $type, start: $start, end: $end) {
      fileId
    }
  }
`;

/**
 * __useAccountStatisticsByTypeQuery__
 *
 * To run a query within a React component, call `useAccountStatisticsByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountStatisticsByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountStatisticsByTypeQuery({
 *   variables: {
 *      type: // value for 'type'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useAccountStatisticsByTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccountStatisticsByTypeQuery,
    AccountStatisticsByTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountStatisticsByTypeQuery, AccountStatisticsByTypeQueryVariables>(
    AccountStatisticsByTypeDocument,
    options,
  );
}
export function useAccountStatisticsByTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountStatisticsByTypeQuery,
    AccountStatisticsByTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountStatisticsByTypeQuery, AccountStatisticsByTypeQueryVariables>(
    AccountStatisticsByTypeDocument,
    options,
  );
}
export type AccountStatisticsByTypeQueryHookResult = ReturnType<
  typeof useAccountStatisticsByTypeQuery
>;
export type AccountStatisticsByTypeLazyQueryHookResult = ReturnType<
  typeof useAccountStatisticsByTypeLazyQuery
>;
export type AccountStatisticsByTypeQueryResult = Apollo.QueryResult<
  AccountStatisticsByTypeQuery,
  AccountStatisticsByTypeQueryVariables
>;
export const ComplaintsStatisticsByTypeDocument = gql`
  query complaintsStatisticsByType($type: String!, $start: DateTime!, $end: DateTime!) {
    complaintsStatisticsByType(type: $type, start: $start, end: $end) {
      fileId
    }
  }
`;

/**
 * __useComplaintsStatisticsByTypeQuery__
 *
 * To run a query within a React component, call `useComplaintsStatisticsByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useComplaintsStatisticsByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComplaintsStatisticsByTypeQuery({
 *   variables: {
 *      type: // value for 'type'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useComplaintsStatisticsByTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    ComplaintsStatisticsByTypeQuery,
    ComplaintsStatisticsByTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ComplaintsStatisticsByTypeQuery, ComplaintsStatisticsByTypeQueryVariables>(
    ComplaintsStatisticsByTypeDocument,
    options,
  );
}
export function useComplaintsStatisticsByTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ComplaintsStatisticsByTypeQuery,
    ComplaintsStatisticsByTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ComplaintsStatisticsByTypeQuery,
    ComplaintsStatisticsByTypeQueryVariables
  >(ComplaintsStatisticsByTypeDocument, options);
}
export type ComplaintsStatisticsByTypeQueryHookResult = ReturnType<
  typeof useComplaintsStatisticsByTypeQuery
>;
export type ComplaintsStatisticsByTypeLazyQueryHookResult = ReturnType<
  typeof useComplaintsStatisticsByTypeLazyQuery
>;
export type ComplaintsStatisticsByTypeQueryResult = Apollo.QueryResult<
  ComplaintsStatisticsByTypeQuery,
  ComplaintsStatisticsByTypeQueryVariables
>;
export const ComplaintsStatisticsForBusinessDocument = gql`
  query complaintsStatisticsForBusiness($businessId: Int!, $start: DateTime!, $end: DateTime!) {
    complaintsStatisticsForBusiness(businessId: $businessId, start: $start, end: $end) {
      fileId
    }
  }
`;

/**
 * __useComplaintsStatisticsForBusinessQuery__
 *
 * To run a query within a React component, call `useComplaintsStatisticsForBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useComplaintsStatisticsForBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComplaintsStatisticsForBusinessQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useComplaintsStatisticsForBusinessQuery(
  baseOptions: Apollo.QueryHookOptions<
    ComplaintsStatisticsForBusinessQuery,
    ComplaintsStatisticsForBusinessQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ComplaintsStatisticsForBusinessQuery,
    ComplaintsStatisticsForBusinessQueryVariables
  >(ComplaintsStatisticsForBusinessDocument, options);
}
export function useComplaintsStatisticsForBusinessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ComplaintsStatisticsForBusinessQuery,
    ComplaintsStatisticsForBusinessQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ComplaintsStatisticsForBusinessQuery,
    ComplaintsStatisticsForBusinessQueryVariables
  >(ComplaintsStatisticsForBusinessDocument, options);
}
export type ComplaintsStatisticsForBusinessQueryHookResult = ReturnType<
  typeof useComplaintsStatisticsForBusinessQuery
>;
export type ComplaintsStatisticsForBusinessLazyQueryHookResult = ReturnType<
  typeof useComplaintsStatisticsForBusinessLazyQuery
>;
export type ComplaintsStatisticsForBusinessQueryResult = Apollo.QueryResult<
  ComplaintsStatisticsForBusinessQuery,
  ComplaintsStatisticsForBusinessQueryVariables
>;
export const MonthlyLifeWorksStatisticsDocument = gql`
  query monthlyLifeWorksStatistics($start: DateTime!, $type: AccountType) {
    monthlyLifeWorksStatistics(start: $start, type: $type) {
      fileId
    }
  }
`;

/**
 * __useMonthlyLifeWorksStatisticsQuery__
 *
 * To run a query within a React component, call `useMonthlyLifeWorksStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyLifeWorksStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyLifeWorksStatisticsQuery({
 *   variables: {
 *      start: // value for 'start'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useMonthlyLifeWorksStatisticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MonthlyLifeWorksStatisticsQuery,
    MonthlyLifeWorksStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MonthlyLifeWorksStatisticsQuery, MonthlyLifeWorksStatisticsQueryVariables>(
    MonthlyLifeWorksStatisticsDocument,
    options,
  );
}
export function useMonthlyLifeWorksStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MonthlyLifeWorksStatisticsQuery,
    MonthlyLifeWorksStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MonthlyLifeWorksStatisticsQuery,
    MonthlyLifeWorksStatisticsQueryVariables
  >(MonthlyLifeWorksStatisticsDocument, options);
}
export type MonthlyLifeWorksStatisticsQueryHookResult = ReturnType<
  typeof useMonthlyLifeWorksStatisticsQuery
>;
export type MonthlyLifeWorksStatisticsLazyQueryHookResult = ReturnType<
  typeof useMonthlyLifeWorksStatisticsLazyQuery
>;
export type MonthlyLifeWorksStatisticsQueryResult = Apollo.QueryResult<
  MonthlyLifeWorksStatisticsQuery,
  MonthlyLifeWorksStatisticsQueryVariables
>;
export const BreadCrumbInfoByAccountIdDocument = gql`
  query breadCrumbInfoByAccountId($id: Int!) {
    breadCrumbInfoByAccountId(id: $id) {
      contractEnd
      language
    }
  }
`;

/**
 * __useBreadCrumbInfoByAccountIdQuery__
 *
 * To run a query within a React component, call `useBreadCrumbInfoByAccountIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBreadCrumbInfoByAccountIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBreadCrumbInfoByAccountIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBreadCrumbInfoByAccountIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    BreadCrumbInfoByAccountIdQuery,
    BreadCrumbInfoByAccountIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BreadCrumbInfoByAccountIdQuery, BreadCrumbInfoByAccountIdQueryVariables>(
    BreadCrumbInfoByAccountIdDocument,
    options,
  );
}
export function useBreadCrumbInfoByAccountIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BreadCrumbInfoByAccountIdQuery,
    BreadCrumbInfoByAccountIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BreadCrumbInfoByAccountIdQuery,
    BreadCrumbInfoByAccountIdQueryVariables
  >(BreadCrumbInfoByAccountIdDocument, options);
}
export type BreadCrumbInfoByAccountIdQueryHookResult = ReturnType<
  typeof useBreadCrumbInfoByAccountIdQuery
>;
export type BreadCrumbInfoByAccountIdLazyQueryHookResult = ReturnType<
  typeof useBreadCrumbInfoByAccountIdLazyQuery
>;
export type BreadCrumbInfoByAccountIdQueryResult = Apollo.QueryResult<
  BreadCrumbInfoByAccountIdQuery,
  BreadCrumbInfoByAccountIdQueryVariables
>;
export const AppointmentsByDayDocument = gql`
  query appointmentsByDay($startsAt: String!, $endsAt: String!) {
    appointmentsByDay(startsAt: $startsAt, endsAt: $endsAt) {
      ...appointment
      users {
        ...user
        person {
          ...basePerson
          alias
          avatar
          avatarKeys
          picture
          mentalState
        }
      }
      person {
        ...basePerson
        avatarKeys
        mentalState
      }
    }
  }
  ${AppointmentFragmentDoc}
  ${UserFragmentDoc}
  ${BasePersonFragmentDoc}
`;

/**
 * __useAppointmentsByDayQuery__
 *
 * To run a query within a React component, call `useAppointmentsByDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsByDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsByDayQuery({
 *   variables: {
 *      startsAt: // value for 'startsAt'
 *      endsAt: // value for 'endsAt'
 *   },
 * });
 */
export function useAppointmentsByDayQuery(
  baseOptions: Apollo.QueryHookOptions<AppointmentsByDayQuery, AppointmentsByDayQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppointmentsByDayQuery, AppointmentsByDayQueryVariables>(
    AppointmentsByDayDocument,
    options,
  );
}
export function useAppointmentsByDayLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentsByDayQuery,
    AppointmentsByDayQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppointmentsByDayQuery, AppointmentsByDayQueryVariables>(
    AppointmentsByDayDocument,
    options,
  );
}
export type AppointmentsByDayQueryHookResult = ReturnType<typeof useAppointmentsByDayQuery>;
export type AppointmentsByDayLazyQueryHookResult = ReturnType<typeof useAppointmentsByDayLazyQuery>;
export type AppointmentsByDayQueryResult = Apollo.QueryResult<
  AppointmentsByDayQuery,
  AppointmentsByDayQueryVariables
>;
export const AppointmentsByUsersDocument = gql`
  query appointmentsByUsers($userIds: [Int!]!, $startsAt: String!, $endsAt: String!) {
    appointmentsByUsers(userIds: $userIds, startsAt: $startsAt, endsAt: $endsAt) {
      ...appointment
      users {
        ...user
        person {
          ...basePerson
          alias
          avatar
          avatarKeys
          picture
          mentalState
        }
      }
      person {
        id
        name
        age
      }
    }
  }
  ${AppointmentFragmentDoc}
  ${UserFragmentDoc}
  ${BasePersonFragmentDoc}
`;

/**
 * __useAppointmentsByUsersQuery__
 *
 * To run a query within a React component, call `useAppointmentsByUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsByUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsByUsersQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      startsAt: // value for 'startsAt'
 *      endsAt: // value for 'endsAt'
 *   },
 * });
 */
export function useAppointmentsByUsersQuery(
  baseOptions: Apollo.QueryHookOptions<AppointmentsByUsersQuery, AppointmentsByUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppointmentsByUsersQuery, AppointmentsByUsersQueryVariables>(
    AppointmentsByUsersDocument,
    options,
  );
}
export function useAppointmentsByUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentsByUsersQuery,
    AppointmentsByUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppointmentsByUsersQuery, AppointmentsByUsersQueryVariables>(
    AppointmentsByUsersDocument,
    options,
  );
}
export type AppointmentsByUsersQueryHookResult = ReturnType<typeof useAppointmentsByUsersQuery>;
export type AppointmentsByUsersLazyQueryHookResult = ReturnType<
  typeof useAppointmentsByUsersLazyQuery
>;
export type AppointmentsByUsersQueryResult = Apollo.QueryResult<
  AppointmentsByUsersQuery,
  AppointmentsByUsersQueryVariables
>;
export const MyAppointmentsDocument = gql`
  query myAppointments($userId: Int) {
    myAppointments(userId: $userId) {
      ...appointment
      users {
        ...user
        person {
          ...basePerson
          alias
          avatar
          avatarKeys
          picture
          mentalState
        }
      }
      task {
        id
        finishedAt
        startedAt
      }
      person {
        ...basePerson
        avatarKeys
        mentalState
      }
    }
  }
  ${AppointmentFragmentDoc}
  ${UserFragmentDoc}
  ${BasePersonFragmentDoc}
`;

/**
 * __useMyAppointmentsQuery__
 *
 * To run a query within a React component, call `useMyAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAppointmentsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMyAppointmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<MyAppointmentsQuery, MyAppointmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyAppointmentsQuery, MyAppointmentsQueryVariables>(
    MyAppointmentsDocument,
    options,
  );
}
export function useMyAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyAppointmentsQuery, MyAppointmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyAppointmentsQuery, MyAppointmentsQueryVariables>(
    MyAppointmentsDocument,
    options,
  );
}
export type MyAppointmentsQueryHookResult = ReturnType<typeof useMyAppointmentsQuery>;
export type MyAppointmentsLazyQueryHookResult = ReturnType<typeof useMyAppointmentsLazyQuery>;
export type MyAppointmentsQueryResult = Apollo.QueryResult<
  MyAppointmentsQuery,
  MyAppointmentsQueryVariables
>;
export const PersonAppointmentsDocument = gql`
  query personAppointments($personId: Int!) {
    personAppointments(personId: $personId) {
      ...appointment
      users {
        ...user
        person {
          ...basePerson
          avatar
          avatarKeys
          alias
        }
      }
      person {
        id
        name
      }
    }
  }
  ${AppointmentFragmentDoc}
  ${UserFragmentDoc}
  ${BasePersonFragmentDoc}
`;

/**
 * __usePersonAppointmentsQuery__
 *
 * To run a query within a React component, call `usePersonAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonAppointmentsQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function usePersonAppointmentsQuery(
  baseOptions: Apollo.QueryHookOptions<PersonAppointmentsQuery, PersonAppointmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PersonAppointmentsQuery, PersonAppointmentsQueryVariables>(
    PersonAppointmentsDocument,
    options,
  );
}
export function usePersonAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PersonAppointmentsQuery,
    PersonAppointmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PersonAppointmentsQuery, PersonAppointmentsQueryVariables>(
    PersonAppointmentsDocument,
    options,
  );
}
export type PersonAppointmentsQueryHookResult = ReturnType<typeof usePersonAppointmentsQuery>;
export type PersonAppointmentsLazyQueryHookResult = ReturnType<
  typeof usePersonAppointmentsLazyQuery
>;
export type PersonAppointmentsQueryResult = Apollo.QueryResult<
  PersonAppointmentsQuery,
  PersonAppointmentsQueryVariables
>;
export const BusinessCategoriesDocument = gql`
  query businessCategories {
    businessCategories {
      id
      name
    }
  }
`;

/**
 * __useBusinessCategoriesQuery__
 *
 * To run a query within a React component, call `useBusinessCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBusinessCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<BusinessCategoriesQuery, BusinessCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusinessCategoriesQuery, BusinessCategoriesQueryVariables>(
    BusinessCategoriesDocument,
    options,
  );
}
export function useBusinessCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BusinessCategoriesQuery,
    BusinessCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BusinessCategoriesQuery, BusinessCategoriesQueryVariables>(
    BusinessCategoriesDocument,
    options,
  );
}
export type BusinessCategoriesQueryHookResult = ReturnType<typeof useBusinessCategoriesQuery>;
export type BusinessCategoriesLazyQueryHookResult = ReturnType<
  typeof useBusinessCategoriesLazyQuery
>;
export type BusinessCategoriesQueryResult = Apollo.QueryResult<
  BusinessCategoriesQuery,
  BusinessCategoriesQueryVariables
>;
export const BusinessObjectDocument = gql`
  query businessObject($id: Int!) {
    businessObject(id: $id) {
      ...businessObjectData
      category {
        id
        name
      }
      address {
        ...address
      }
      email {
        ...email
      }
      persons {
        ...businessPerson
      }
      phones {
        ...phone
      }
      hours {
        id
        open
        close
        day
      }
      memo
    }
  }
  ${BusinessObjectDataFragmentDoc}
  ${AddressFragmentDoc}
  ${EmailFragmentDoc}
  ${BusinessPersonFragmentDoc}
  ${PhoneFragmentDoc}
`;

/**
 * __useBusinessObjectQuery__
 *
 * To run a query within a React component, call `useBusinessObjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessObjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessObjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBusinessObjectQuery(
  baseOptions: Apollo.QueryHookOptions<BusinessObjectQuery, BusinessObjectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusinessObjectQuery, BusinessObjectQueryVariables>(
    BusinessObjectDocument,
    options,
  );
}
export function useBusinessObjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BusinessObjectQuery, BusinessObjectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BusinessObjectQuery, BusinessObjectQueryVariables>(
    BusinessObjectDocument,
    options,
  );
}
export type BusinessObjectQueryHookResult = ReturnType<typeof useBusinessObjectQuery>;
export type BusinessObjectLazyQueryHookResult = ReturnType<typeof useBusinessObjectLazyQuery>;
export type BusinessObjectQueryResult = Apollo.QueryResult<
  BusinessObjectQuery,
  BusinessObjectQueryVariables
>;
export const BusinessObjectByPersonDocument = gql`
  query businessObjectByPerson($personId: Int!) {
    businessObjectByPerson(personId: $personId) {
      ...businessObjectData
      address {
        ...address
      }
      email {
        ...email
      }
      persons {
        ...basePerson
      }
      phones {
        ...phone
      }
      hours {
        id
        open
        close
        day
      }
    }
  }
  ${BusinessObjectDataFragmentDoc}
  ${AddressFragmentDoc}
  ${EmailFragmentDoc}
  ${BasePersonFragmentDoc}
  ${PhoneFragmentDoc}
`;

/**
 * __useBusinessObjectByPersonQuery__
 *
 * To run a query within a React component, call `useBusinessObjectByPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessObjectByPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessObjectByPersonQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useBusinessObjectByPersonQuery(
  baseOptions: Apollo.QueryHookOptions<
    BusinessObjectByPersonQuery,
    BusinessObjectByPersonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusinessObjectByPersonQuery, BusinessObjectByPersonQueryVariables>(
    BusinessObjectByPersonDocument,
    options,
  );
}
export function useBusinessObjectByPersonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BusinessObjectByPersonQuery,
    BusinessObjectByPersonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BusinessObjectByPersonQuery, BusinessObjectByPersonQueryVariables>(
    BusinessObjectByPersonDocument,
    options,
  );
}
export type BusinessObjectByPersonQueryHookResult = ReturnType<
  typeof useBusinessObjectByPersonQuery
>;
export type BusinessObjectByPersonLazyQueryHookResult = ReturnType<
  typeof useBusinessObjectByPersonLazyQuery
>;
export type BusinessObjectByPersonQueryResult = Apollo.QueryResult<
  BusinessObjectByPersonQuery,
  BusinessObjectByPersonQueryVariables
>;
export const BusinessObjectsDocument = gql`
  query businessObjects($serviceId: Int, $unique: Boolean, $name: String, $withAddress: Boolean) {
    businessObjects(
      serviceId: $serviceId
      unique: $unique
      name: $name
      withAddress: $withAddress
    ) {
      ...businessObjectData
      address {
        ...address
      }
      email {
        ...email
      }
      persons {
        ...basePerson
      }
      phones {
        ...phone
      }
      hours {
        id
        open
        close
        day
      }
    }
  }
  ${BusinessObjectDataFragmentDoc}
  ${AddressFragmentDoc}
  ${EmailFragmentDoc}
  ${BasePersonFragmentDoc}
  ${PhoneFragmentDoc}
`;

/**
 * __useBusinessObjectsQuery__
 *
 * To run a query within a React component, call `useBusinessObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessObjectsQuery({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *      unique: // value for 'unique'
 *      name: // value for 'name'
 *      withAddress: // value for 'withAddress'
 *   },
 * });
 */
export function useBusinessObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<BusinessObjectsQuery, BusinessObjectsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusinessObjectsQuery, BusinessObjectsQueryVariables>(
    BusinessObjectsDocument,
    options,
  );
}
export function useBusinessObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BusinessObjectsQuery, BusinessObjectsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BusinessObjectsQuery, BusinessObjectsQueryVariables>(
    BusinessObjectsDocument,
    options,
  );
}
export type BusinessObjectsQueryHookResult = ReturnType<typeof useBusinessObjectsQuery>;
export type BusinessObjectsLazyQueryHookResult = ReturnType<typeof useBusinessObjectsLazyQuery>;
export type BusinessObjectsQueryResult = Apollo.QueryResult<
  BusinessObjectsQuery,
  BusinessObjectsQueryVariables
>;
export const GetBusinessUnitsByAccountDocument = gql`
  query getBusinessUnitsByAccount($accountId: Int!) {
    getBusinessUnitsByAccount(accountId: $accountId) {
      id
      name
    }
  }
`;

/**
 * __useGetBusinessUnitsByAccountQuery__
 *
 * To run a query within a React component, call `useGetBusinessUnitsByAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessUnitsByAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessUnitsByAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetBusinessUnitsByAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBusinessUnitsByAccountQuery,
    GetBusinessUnitsByAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBusinessUnitsByAccountQuery, GetBusinessUnitsByAccountQueryVariables>(
    GetBusinessUnitsByAccountDocument,
    options,
  );
}
export function useGetBusinessUnitsByAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBusinessUnitsByAccountQuery,
    GetBusinessUnitsByAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBusinessUnitsByAccountQuery,
    GetBusinessUnitsByAccountQueryVariables
  >(GetBusinessUnitsByAccountDocument, options);
}
export type GetBusinessUnitsByAccountQueryHookResult = ReturnType<
  typeof useGetBusinessUnitsByAccountQuery
>;
export type GetBusinessUnitsByAccountLazyQueryHookResult = ReturnType<
  typeof useGetBusinessUnitsByAccountLazyQuery
>;
export type GetBusinessUnitsByAccountQueryResult = Apollo.QueryResult<
  GetBusinessUnitsByAccountQuery,
  GetBusinessUnitsByAccountQueryVariables
>;
export const CallsDocument = gql`
  query calls {
    calls {
      id
      readableNumber
      areaCode
      countryCode
      number
      status
      accountId
      callType
      personActivityId
      person {
        id
        name
      }
    }
  }
`;

/**
 * __useCallsQuery__
 *
 * To run a query within a React component, call `useCallsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCallsQuery(
  baseOptions?: Apollo.QueryHookOptions<CallsQuery, CallsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CallsQuery, CallsQueryVariables>(CallsDocument, options);
}
export function useCallsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CallsQuery, CallsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CallsQuery, CallsQueryVariables>(CallsDocument, options);
}
export type CallsQueryHookResult = ReturnType<typeof useCallsQuery>;
export type CallsLazyQueryHookResult = ReturnType<typeof useCallsLazyQuery>;
export type CallsQueryResult = Apollo.QueryResult<CallsQuery, CallsQueryVariables>;
export const GetCaseManagementReviewsDocument = gql`
  query getCaseManagementReviews($includeValues: [String!], $clientReviewAttributeId: Int) {
    getCaseManagementReviews(includeValues: $includeValues) {
      id
      lastUpdate
      caseManagementReviewAttributes(clientReviewAttributeId: $clientReviewAttributeId) {
        id
        attributeValue
        lastUpdate
      }
      caseManager {
        user {
          ...user
          papershiftWorkingAreas {
            ...papershiftWorkingArea
          }
          person {
            id
            alias
            name
            avatar
            avatarKeys
            picture
          }
        }
      }
      client {
        id
        account {
          id
          name
        }
        person {
          id
          flag
          name
          mentalState
          vip
          avatarKeys
        }
        topics {
          id
          name
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${PapershiftWorkingAreaFragmentDoc}
`;

/**
 * __useGetCaseManagementReviewsQuery__
 *
 * To run a query within a React component, call `useGetCaseManagementReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaseManagementReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaseManagementReviewsQuery({
 *   variables: {
 *      includeValues: // value for 'includeValues'
 *      clientReviewAttributeId: // value for 'clientReviewAttributeId'
 *   },
 * });
 */
export function useGetCaseManagementReviewsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCaseManagementReviewsQuery,
    GetCaseManagementReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCaseManagementReviewsQuery, GetCaseManagementReviewsQueryVariables>(
    GetCaseManagementReviewsDocument,
    options,
  );
}
export function useGetCaseManagementReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCaseManagementReviewsQuery,
    GetCaseManagementReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCaseManagementReviewsQuery, GetCaseManagementReviewsQueryVariables>(
    GetCaseManagementReviewsDocument,
    options,
  );
}
export type GetCaseManagementReviewsQueryHookResult = ReturnType<
  typeof useGetCaseManagementReviewsQuery
>;
export type GetCaseManagementReviewsLazyQueryHookResult = ReturnType<
  typeof useGetCaseManagementReviewsLazyQuery
>;
export type GetCaseManagementReviewsQueryResult = Apollo.QueryResult<
  GetCaseManagementReviewsQuery,
  GetCaseManagementReviewsQueryVariables
>;
export const GetCaseManagementReviewsByUserIdDocument = gql`
  query getCaseManagementReviewsByUserId(
    $includeValues: [String!]
    $clientReviewAttributeId: Int
    $userId: Int!
  ) {
    getCaseManagementReviewsByUserId(includeValues: $includeValues, userId: $userId) {
      id
      lastUpdate
      caseManagementReviewAttributes(clientReviewAttributeId: $clientReviewAttributeId) {
        id
        attributeValue
        lastUpdate
      }
      user {
        ...user
        papershiftWorkingAreas {
          ...papershiftWorkingArea
        }
        person {
          id
          alias
          name
          avatar
          avatarKeys
          picture
        }
      }
      client {
        id
        account {
          id
          name
        }
        person {
          id
          flag
          name
          mentalState
          vip
          avatarKeys
        }
        topics {
          id
          name
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${PapershiftWorkingAreaFragmentDoc}
`;

/**
 * __useGetCaseManagementReviewsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetCaseManagementReviewsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaseManagementReviewsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaseManagementReviewsByUserIdQuery({
 *   variables: {
 *      includeValues: // value for 'includeValues'
 *      clientReviewAttributeId: // value for 'clientReviewAttributeId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetCaseManagementReviewsByUserIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCaseManagementReviewsByUserIdQuery,
    GetCaseManagementReviewsByUserIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCaseManagementReviewsByUserIdQuery,
    GetCaseManagementReviewsByUserIdQueryVariables
  >(GetCaseManagementReviewsByUserIdDocument, options);
}
export function useGetCaseManagementReviewsByUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCaseManagementReviewsByUserIdQuery,
    GetCaseManagementReviewsByUserIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCaseManagementReviewsByUserIdQuery,
    GetCaseManagementReviewsByUserIdQueryVariables
  >(GetCaseManagementReviewsByUserIdDocument, options);
}
export type GetCaseManagementReviewsByUserIdQueryHookResult = ReturnType<
  typeof useGetCaseManagementReviewsByUserIdQuery
>;
export type GetCaseManagementReviewsByUserIdLazyQueryHookResult = ReturnType<
  typeof useGetCaseManagementReviewsByUserIdLazyQuery
>;
export type GetCaseManagementReviewsByUserIdQueryResult = Apollo.QueryResult<
  GetCaseManagementReviewsByUserIdQuery,
  GetCaseManagementReviewsByUserIdQueryVariables
>;
export const GetCaseManagementReviewDocument = gql`
  query getCaseManagementReview($personId: Int!) {
    getCaseManagementReview(personId: $personId) {
      id
    }
  }
`;

/**
 * __useGetCaseManagementReviewQuery__
 *
 * To run a query within a React component, call `useGetCaseManagementReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaseManagementReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaseManagementReviewQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetCaseManagementReviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCaseManagementReviewQuery,
    GetCaseManagementReviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCaseManagementReviewQuery, GetCaseManagementReviewQueryVariables>(
    GetCaseManagementReviewDocument,
    options,
  );
}
export function useGetCaseManagementReviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCaseManagementReviewQuery,
    GetCaseManagementReviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCaseManagementReviewQuery, GetCaseManagementReviewQueryVariables>(
    GetCaseManagementReviewDocument,
    options,
  );
}
export type GetCaseManagementReviewQueryHookResult = ReturnType<
  typeof useGetCaseManagementReviewQuery
>;
export type GetCaseManagementReviewLazyQueryHookResult = ReturnType<
  typeof useGetCaseManagementReviewLazyQuery
>;
export type GetCaseManagementReviewQueryResult = Apollo.QueryResult<
  GetCaseManagementReviewQuery,
  GetCaseManagementReviewQueryVariables
>;
export const GetCaseManagementReviewDetailsDocument = gql`
  query getCaseManagementReviewDetails($personId: Int!) {
    getCaseManagementReview(personId: $personId) {
      id
      caseManagementReviewAttributes {
        id
        attributeValue
        lastUpdate
        user {
          ...user
          person {
            id
            alias
            name
            avatar
            avatarKeys
            picture
          }
        }
        clientReviewAttribute {
          id
          name
        }
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useGetCaseManagementReviewDetailsQuery__
 *
 * To run a query within a React component, call `useGetCaseManagementReviewDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaseManagementReviewDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaseManagementReviewDetailsQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetCaseManagementReviewDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCaseManagementReviewDetailsQuery,
    GetCaseManagementReviewDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCaseManagementReviewDetailsQuery,
    GetCaseManagementReviewDetailsQueryVariables
  >(GetCaseManagementReviewDetailsDocument, options);
}
export function useGetCaseManagementReviewDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCaseManagementReviewDetailsQuery,
    GetCaseManagementReviewDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCaseManagementReviewDetailsQuery,
    GetCaseManagementReviewDetailsQueryVariables
  >(GetCaseManagementReviewDetailsDocument, options);
}
export type GetCaseManagementReviewDetailsQueryHookResult = ReturnType<
  typeof useGetCaseManagementReviewDetailsQuery
>;
export type GetCaseManagementReviewDetailsLazyQueryHookResult = ReturnType<
  typeof useGetCaseManagementReviewDetailsLazyQuery
>;
export type GetCaseManagementReviewDetailsQueryResult = Apollo.QueryResult<
  GetCaseManagementReviewDetailsQuery,
  GetCaseManagementReviewDetailsQueryVariables
>;
export const GetClientReviewTemplateDocument = gql`
  query getClientReviewTemplate($templateId: Int!) {
    getClientReviewTemplate(templateId: $templateId) {
      id
      clientReviewAttributeGroups {
        id
        headingUpdate
        pageOrder
        clientReviewAttributes {
          id
          name
          label
          type
        }
      }
    }
  }
`;

/**
 * __useGetClientReviewTemplateQuery__
 *
 * To run a query within a React component, call `useGetClientReviewTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientReviewTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientReviewTemplateQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useGetClientReviewTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientReviewTemplateQuery,
    GetClientReviewTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientReviewTemplateQuery, GetClientReviewTemplateQueryVariables>(
    GetClientReviewTemplateDocument,
    options,
  );
}
export function useGetClientReviewTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientReviewTemplateQuery,
    GetClientReviewTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientReviewTemplateQuery, GetClientReviewTemplateQueryVariables>(
    GetClientReviewTemplateDocument,
    options,
  );
}
export type GetClientReviewTemplateQueryHookResult = ReturnType<
  typeof useGetClientReviewTemplateQuery
>;
export type GetClientReviewTemplateLazyQueryHookResult = ReturnType<
  typeof useGetClientReviewTemplateLazyQuery
>;
export type GetClientReviewTemplateQueryResult = Apollo.QueryResult<
  GetClientReviewTemplateQuery,
  GetClientReviewTemplateQueryVariables
>;
export const GetCaseManagementReviewAttributeDocument = gql`
  query getCaseManagementReviewAttribute(
    $caseManagementReviewId: Int!
    $clientReviewAttributeId: Int!
  ) {
    getCaseManagementReviewAttribute(
      caseManagementReviewId: $caseManagementReviewId
      clientReviewAttributeId: $clientReviewAttributeId
    ) {
      id
      attributeValue
      lastUpdate
    }
  }
`;

/**
 * __useGetCaseManagementReviewAttributeQuery__
 *
 * To run a query within a React component, call `useGetCaseManagementReviewAttributeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaseManagementReviewAttributeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaseManagementReviewAttributeQuery({
 *   variables: {
 *      caseManagementReviewId: // value for 'caseManagementReviewId'
 *      clientReviewAttributeId: // value for 'clientReviewAttributeId'
 *   },
 * });
 */
export function useGetCaseManagementReviewAttributeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCaseManagementReviewAttributeQuery,
    GetCaseManagementReviewAttributeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCaseManagementReviewAttributeQuery,
    GetCaseManagementReviewAttributeQueryVariables
  >(GetCaseManagementReviewAttributeDocument, options);
}
export function useGetCaseManagementReviewAttributeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCaseManagementReviewAttributeQuery,
    GetCaseManagementReviewAttributeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCaseManagementReviewAttributeQuery,
    GetCaseManagementReviewAttributeQueryVariables
  >(GetCaseManagementReviewAttributeDocument, options);
}
export type GetCaseManagementReviewAttributeQueryHookResult = ReturnType<
  typeof useGetCaseManagementReviewAttributeQuery
>;
export type GetCaseManagementReviewAttributeLazyQueryHookResult = ReturnType<
  typeof useGetCaseManagementReviewAttributeLazyQuery
>;
export type GetCaseManagementReviewAttributeQueryResult = Apollo.QueryResult<
  GetCaseManagementReviewAttributeQuery,
  GetCaseManagementReviewAttributeQueryVariables
>;
export const GetCaseManagerByPersonDocument = gql`
  query getCaseManagerByPerson($personId: Int!) {
    getCaseManagerByPerson(personId: $personId) {
      id
      user {
        ...user
        papershiftWorkingAreas {
          ...papershiftWorkingArea
        }
        person {
          id
          name
          alias
          avatar
          avatarKeys
          picture
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${PapershiftWorkingAreaFragmentDoc}
`;

/**
 * __useGetCaseManagerByPersonQuery__
 *
 * To run a query within a React component, call `useGetCaseManagerByPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaseManagerByPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaseManagerByPersonQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetCaseManagerByPersonQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCaseManagerByPersonQuery,
    GetCaseManagerByPersonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCaseManagerByPersonQuery, GetCaseManagerByPersonQueryVariables>(
    GetCaseManagerByPersonDocument,
    options,
  );
}
export function useGetCaseManagerByPersonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCaseManagerByPersonQuery,
    GetCaseManagerByPersonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCaseManagerByPersonQuery, GetCaseManagerByPersonQueryVariables>(
    GetCaseManagerByPersonDocument,
    options,
  );
}
export type GetCaseManagerByPersonQueryHookResult = ReturnType<
  typeof useGetCaseManagerByPersonQuery
>;
export type GetCaseManagerByPersonLazyQueryHookResult = ReturnType<
  typeof useGetCaseManagerByPersonLazyQuery
>;
export type GetCaseManagerByPersonQueryResult = Apollo.QueryResult<
  GetCaseManagerByPersonQuery,
  GetCaseManagerByPersonQueryVariables
>;
export const GetCaseManagerByClientDocument = gql`
  query getCaseManagerByClient($clientId: Int!) {
    getCaseManagerByClient(clientId: $clientId) {
      id
      user {
        ...user
        papershiftWorkingAreas {
          ...papershiftWorkingArea
        }
        person {
          id
          name
          avatar
          alias
          avatarKeys
          picture
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${PapershiftWorkingAreaFragmentDoc}
`;

/**
 * __useGetCaseManagerByClientQuery__
 *
 * To run a query within a React component, call `useGetCaseManagerByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaseManagerByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaseManagerByClientQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetCaseManagerByClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCaseManagerByClientQuery,
    GetCaseManagerByClientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCaseManagerByClientQuery, GetCaseManagerByClientQueryVariables>(
    GetCaseManagerByClientDocument,
    options,
  );
}
export function useGetCaseManagerByClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCaseManagerByClientQuery,
    GetCaseManagerByClientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCaseManagerByClientQuery, GetCaseManagerByClientQueryVariables>(
    GetCaseManagerByClientDocument,
    options,
  );
}
export type GetCaseManagerByClientQueryHookResult = ReturnType<
  typeof useGetCaseManagerByClientQuery
>;
export type GetCaseManagerByClientLazyQueryHookResult = ReturnType<
  typeof useGetCaseManagerByClientLazyQuery
>;
export type GetCaseManagerByClientQueryResult = Apollo.QueryResult<
  GetCaseManagerByClientQuery,
  GetCaseManagerByClientQueryVariables
>;
export const GetActiveChatDocument = gql`
  query getActiveChat($chatUserId: Int!) {
    getActiveChat(chatUserId: $chatUserId) {
      id
      chatUserId
      status
      team
      user {
        id
        name
        person {
          id
        }
      }
    }
  }
`;

/**
 * __useGetActiveChatQuery__
 *
 * To run a query within a React component, call `useGetActiveChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveChatQuery({
 *   variables: {
 *      chatUserId: // value for 'chatUserId'
 *   },
 * });
 */
export function useGetActiveChatQuery(
  baseOptions: Apollo.QueryHookOptions<GetActiveChatQuery, GetActiveChatQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActiveChatQuery, GetActiveChatQueryVariables>(
    GetActiveChatDocument,
    options,
  );
}
export function useGetActiveChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetActiveChatQuery, GetActiveChatQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetActiveChatQuery, GetActiveChatQueryVariables>(
    GetActiveChatDocument,
    options,
  );
}
export type GetActiveChatQueryHookResult = ReturnType<typeof useGetActiveChatQuery>;
export type GetActiveChatLazyQueryHookResult = ReturnType<typeof useGetActiveChatLazyQuery>;
export type GetActiveChatQueryResult = Apollo.QueryResult<
  GetActiveChatQuery,
  GetActiveChatQueryVariables
>;
export const ActiveChatsDocument = gql`
  query activeChats {
    activeChats {
      id
      chatUserId
      status
      team
      user {
        id
      }
    }
  }
`;

/**
 * __useActiveChatsQuery__
 *
 * To run a query within a React component, call `useActiveChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveChatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveChatsQuery(
  baseOptions?: Apollo.QueryHookOptions<ActiveChatsQuery, ActiveChatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActiveChatsQuery, ActiveChatsQueryVariables>(ActiveChatsDocument, options);
}
export function useActiveChatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ActiveChatsQuery, ActiveChatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActiveChatsQuery, ActiveChatsQueryVariables>(
    ActiveChatsDocument,
    options,
  );
}
export type ActiveChatsQueryHookResult = ReturnType<typeof useActiveChatsQuery>;
export type ActiveChatsLazyQueryHookResult = ReturnType<typeof useActiveChatsLazyQuery>;
export type ActiveChatsQueryResult = Apollo.QueryResult<
  ActiveChatsQuery,
  ActiveChatsQueryVariables
>;
export const GetDetailedActiveChatsDocument = gql`
  query getDetailedActiveChats {
    activeChats {
      id
      chatUserId
      status
      team
      user {
        id
        person {
          id
          avatar
        }
      }
      personActivity {
        id
        activityType
        lastUpdate
        person {
          id
          alias
          name
        }
      }
    }
  }
`;

/**
 * __useGetDetailedActiveChatsQuery__
 *
 * To run a query within a React component, call `useGetDetailedActiveChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDetailedActiveChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDetailedActiveChatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDetailedActiveChatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDetailedActiveChatsQuery,
    GetDetailedActiveChatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDetailedActiveChatsQuery, GetDetailedActiveChatsQueryVariables>(
    GetDetailedActiveChatsDocument,
    options,
  );
}
export function useGetDetailedActiveChatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDetailedActiveChatsQuery,
    GetDetailedActiveChatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDetailedActiveChatsQuery, GetDetailedActiveChatsQueryVariables>(
    GetDetailedActiveChatsDocument,
    options,
  );
}
export type GetDetailedActiveChatsQueryHookResult = ReturnType<
  typeof useGetDetailedActiveChatsQuery
>;
export type GetDetailedActiveChatsLazyQueryHookResult = ReturnType<
  typeof useGetDetailedActiveChatsLazyQuery
>;
export type GetDetailedActiveChatsQueryResult = Apollo.QueryResult<
  GetDetailedActiveChatsQuery,
  GetDetailedActiveChatsQueryVariables
>;
export const GetTokenDocument = gql`
  query getToken {
    getToken
  }
`;

/**
 * __useGetTokenQuery__
 *
 * To run a query within a React component, call `useGetTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTokenQuery, GetTokenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTokenQuery, GetTokenQueryVariables>(GetTokenDocument, options);
}
export function useGetTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTokenQuery, GetTokenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTokenQuery, GetTokenQueryVariables>(GetTokenDocument, options);
}
export type GetTokenQueryHookResult = ReturnType<typeof useGetTokenQuery>;
export type GetTokenLazyQueryHookResult = ReturnType<typeof useGetTokenLazyQuery>;
export type GetTokenQueryResult = Apollo.QueryResult<GetTokenQuery, GetTokenQueryVariables>;
export const GetChatByActivityDocument = gql`
  query getChatByActivity($activityId: Int!) {
    getChatByActivity(activityId: $activityId) {
      id
      chatUserId
    }
  }
`;

/**
 * __useGetChatByActivityQuery__
 *
 * To run a query within a React component, call `useGetChatByActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatByActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatByActivityQuery({
 *   variables: {
 *      activityId: // value for 'activityId'
 *   },
 * });
 */
export function useGetChatByActivityQuery(
  baseOptions: Apollo.QueryHookOptions<GetChatByActivityQuery, GetChatByActivityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChatByActivityQuery, GetChatByActivityQueryVariables>(
    GetChatByActivityDocument,
    options,
  );
}
export function useGetChatByActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChatByActivityQuery,
    GetChatByActivityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChatByActivityQuery, GetChatByActivityQueryVariables>(
    GetChatByActivityDocument,
    options,
  );
}
export type GetChatByActivityQueryHookResult = ReturnType<typeof useGetChatByActivityQuery>;
export type GetChatByActivityLazyQueryHookResult = ReturnType<typeof useGetChatByActivityLazyQuery>;
export type GetChatByActivityQueryResult = Apollo.QueryResult<
  GetChatByActivityQuery,
  GetChatByActivityQueryVariables
>;
export const LastFinishedChatDocument = gql`
  query lastFinishedChat($chats: [LastFinishedChatInput!]!) {
    lastFinishedChat(chats: $chats) {
      id
      chatUserId
      status
      team
      user {
        id
      }
    }
  }
`;

/**
 * __useLastFinishedChatQuery__
 *
 * To run a query within a React component, call `useLastFinishedChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastFinishedChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastFinishedChatQuery({
 *   variables: {
 *      chats: // value for 'chats'
 *   },
 * });
 */
export function useLastFinishedChatQuery(
  baseOptions: Apollo.QueryHookOptions<LastFinishedChatQuery, LastFinishedChatQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LastFinishedChatQuery, LastFinishedChatQueryVariables>(
    LastFinishedChatDocument,
    options,
  );
}
export function useLastFinishedChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LastFinishedChatQuery, LastFinishedChatQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LastFinishedChatQuery, LastFinishedChatQueryVariables>(
    LastFinishedChatDocument,
    options,
  );
}
export type LastFinishedChatQueryHookResult = ReturnType<typeof useLastFinishedChatQuery>;
export type LastFinishedChatLazyQueryHookResult = ReturnType<typeof useLastFinishedChatLazyQuery>;
export type LastFinishedChatQueryResult = Apollo.QueryResult<
  LastFinishedChatQuery,
  LastFinishedChatQueryVariables
>;
export const HotFlagsDocument = gql`
  query hotFlags {
    hotFlags {
      id
      vip
      account {
        id
        name
      }
      person {
        id
        name
        yellowFlag
        redFlag
      }
      clientAttributes {
        clientReviewAttribute {
          id
          name
        }
        id
        attributeValue
        start
      }
    }
  }
`;

/**
 * __useHotFlagsQuery__
 *
 * To run a query within a React component, call `useHotFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHotFlagsQuery(
  baseOptions?: Apollo.QueryHookOptions<HotFlagsQuery, HotFlagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HotFlagsQuery, HotFlagsQueryVariables>(HotFlagsDocument, options);
}
export function useHotFlagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HotFlagsQuery, HotFlagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HotFlagsQuery, HotFlagsQueryVariables>(HotFlagsDocument, options);
}
export type HotFlagsQueryHookResult = ReturnType<typeof useHotFlagsQuery>;
export type HotFlagsLazyQueryHookResult = ReturnType<typeof useHotFlagsLazyQuery>;
export type HotFlagsQueryResult = Apollo.QueryResult<HotFlagsQuery, HotFlagsQueryVariables>;
export const MyStcClientsDocument = gql`
  query mySTCClients {
    mySTCClients {
      id
      person {
        id
        name
        lastActivity
        flag
        name
        mentalState
        vip
        avatarKeys
      }
    }
  }
`;

/**
 * __useMyStcClientsQuery__
 *
 * To run a query within a React component, call `useMyStcClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyStcClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyStcClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyStcClientsQuery(
  baseOptions?: Apollo.QueryHookOptions<MyStcClientsQuery, MyStcClientsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyStcClientsQuery, MyStcClientsQueryVariables>(
    MyStcClientsDocument,
    options,
  );
}
export function useMyStcClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyStcClientsQuery, MyStcClientsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyStcClientsQuery, MyStcClientsQueryVariables>(
    MyStcClientsDocument,
    options,
  );
}
export type MyStcClientsQueryHookResult = ReturnType<typeof useMyStcClientsQuery>;
export type MyStcClientsLazyQueryHookResult = ReturnType<typeof useMyStcClientsLazyQuery>;
export type MyStcClientsQueryResult = Apollo.QueryResult<
  MyStcClientsQuery,
  MyStcClientsQueryVariables
>;
export const GetStcClientsDocument = gql`
  query getSTCClients($userId: Int!) {
    getSTCClients(userId: $userId) {
      id
      person {
        id
        name
        lastActivity
        flag
        name
        mentalState
        vip
        avatarKeys
      }
    }
  }
`;

/**
 * __useGetStcClientsQuery__
 *
 * To run a query within a React component, call `useGetStcClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStcClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStcClientsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetStcClientsQuery(
  baseOptions: Apollo.QueryHookOptions<GetStcClientsQuery, GetStcClientsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStcClientsQuery, GetStcClientsQueryVariables>(
    GetStcClientsDocument,
    options,
  );
}
export function useGetStcClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStcClientsQuery, GetStcClientsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStcClientsQuery, GetStcClientsQueryVariables>(
    GetStcClientsDocument,
    options,
  );
}
export type GetStcClientsQueryHookResult = ReturnType<typeof useGetStcClientsQuery>;
export type GetStcClientsLazyQueryHookResult = ReturnType<typeof useGetStcClientsLazyQuery>;
export type GetStcClientsQueryResult = Apollo.QueryResult<
  GetStcClientsQuery,
  GetStcClientsQueryVariables
>;
export const MyCmClientsDocument = gql`
  query myCMClients {
    myCMClients {
      id
      person {
        id
        name
        lastActivity
        flag
        name
        mentalState
        vip
        avatarKeys
      }
    }
  }
`;

/**
 * __useMyCmClientsQuery__
 *
 * To run a query within a React component, call `useMyCmClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCmClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCmClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyCmClientsQuery(
  baseOptions?: Apollo.QueryHookOptions<MyCmClientsQuery, MyCmClientsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyCmClientsQuery, MyCmClientsQueryVariables>(MyCmClientsDocument, options);
}
export function useMyCmClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyCmClientsQuery, MyCmClientsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyCmClientsQuery, MyCmClientsQueryVariables>(
    MyCmClientsDocument,
    options,
  );
}
export type MyCmClientsQueryHookResult = ReturnType<typeof useMyCmClientsQuery>;
export type MyCmClientsLazyQueryHookResult = ReturnType<typeof useMyCmClientsLazyQuery>;
export type MyCmClientsQueryResult = Apollo.QueryResult<
  MyCmClientsQuery,
  MyCmClientsQueryVariables
>;
export const GetCmClientsDocument = gql`
  query getCMClients($userId: Int!) {
    getCMClients(userId: $userId) {
      id
      person {
        id
        name
        lastActivity
        flag
        name
        mentalState
        vip
        avatarKeys
      }
    }
  }
`;

/**
 * __useGetCmClientsQuery__
 *
 * To run a query within a React component, call `useGetCmClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCmClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCmClientsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetCmClientsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCmClientsQuery, GetCmClientsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCmClientsQuery, GetCmClientsQueryVariables>(
    GetCmClientsDocument,
    options,
  );
}
export function useGetCmClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCmClientsQuery, GetCmClientsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCmClientsQuery, GetCmClientsQueryVariables>(
    GetCmClientsDocument,
    options,
  );
}
export type GetCmClientsQueryHookResult = ReturnType<typeof useGetCmClientsQuery>;
export type GetCmClientsLazyQueryHookResult = ReturnType<typeof useGetCmClientsLazyQuery>;
export type GetCmClientsQueryResult = Apollo.QueryResult<
  GetCmClientsQuery,
  GetCmClientsQueryVariables
>;
export const BreadCrumbInfoByClientIdDocument = gql`
  query breadCrumbInfoByClientId($id: Int!) {
    breadCrumbInfoByClientId(id: $id) {
      contractEnd
      language
    }
  }
`;

/**
 * __useBreadCrumbInfoByClientIdQuery__
 *
 * To run a query within a React component, call `useBreadCrumbInfoByClientIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBreadCrumbInfoByClientIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBreadCrumbInfoByClientIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBreadCrumbInfoByClientIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    BreadCrumbInfoByClientIdQuery,
    BreadCrumbInfoByClientIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BreadCrumbInfoByClientIdQuery, BreadCrumbInfoByClientIdQueryVariables>(
    BreadCrumbInfoByClientIdDocument,
    options,
  );
}
export function useBreadCrumbInfoByClientIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BreadCrumbInfoByClientIdQuery,
    BreadCrumbInfoByClientIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BreadCrumbInfoByClientIdQuery, BreadCrumbInfoByClientIdQueryVariables>(
    BreadCrumbInfoByClientIdDocument,
    options,
  );
}
export type BreadCrumbInfoByClientIdQueryHookResult = ReturnType<
  typeof useBreadCrumbInfoByClientIdQuery
>;
export type BreadCrumbInfoByClientIdLazyQueryHookResult = ReturnType<
  typeof useBreadCrumbInfoByClientIdLazyQuery
>;
export type BreadCrumbInfoByClientIdQueryResult = Apollo.QueryResult<
  BreadCrumbInfoByClientIdQuery,
  BreadCrumbInfoByClientIdQueryVariables
>;
export const ClientByUcmsIdDocument = gql`
  query clientByUcmsId($ucmsId: Int!) {
    clientByUcmsId(ucmsId: $ucmsId) {
      id
      account {
        id
        name
      }
      person {
        id
        firstName
        lastName
        alias
      }
    }
  }
`;

/**
 * __useClientByUcmsIdQuery__
 *
 * To run a query within a React component, call `useClientByUcmsIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientByUcmsIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientByUcmsIdQuery({
 *   variables: {
 *      ucmsId: // value for 'ucmsId'
 *   },
 * });
 */
export function useClientByUcmsIdQuery(
  baseOptions: Apollo.QueryHookOptions<ClientByUcmsIdQuery, ClientByUcmsIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClientByUcmsIdQuery, ClientByUcmsIdQueryVariables>(
    ClientByUcmsIdDocument,
    options,
  );
}
export function useClientByUcmsIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClientByUcmsIdQuery, ClientByUcmsIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClientByUcmsIdQuery, ClientByUcmsIdQueryVariables>(
    ClientByUcmsIdDocument,
    options,
  );
}
export type ClientByUcmsIdQueryHookResult = ReturnType<typeof useClientByUcmsIdQuery>;
export type ClientByUcmsIdLazyQueryHookResult = ReturnType<typeof useClientByUcmsIdLazyQuery>;
export type ClientByUcmsIdQueryResult = Apollo.QueryResult<
  ClientByUcmsIdQuery,
  ClientByUcmsIdQueryVariables
>;
export const AllClientsDocument = gql`
  query allClients {
    allClients {
      user {
        person {
          name
          alias
          avatarKeys
          avatar
        }
      }
      clients {
        id
        caseManager {
          id
          user {
            id
            name
            primeDN
            person {
              id
              name
              avatarKeys
              avatar
            }
          }
        }
        person {
          id
          flag
          lastActivity
          name
          mentalState
          vip
          avatarKeys
          avatar
        }
      }
      reviews {
        id
        lastUpdate
        caseManager {
          id
          user {
            id
            name
            primeDN
            person {
              id
              name
              avatarKeys
              avatar
            }
          }
        }
        caseManagementReviewAttributes {
          lastUpdate
          attributeValue
        }
        user {
          id
          name
          absentUntil
          person {
            id
            alias
            name
          }
        }
        client {
          id
          account {
            id
            name
          }
          topics {
            id
            name
          }
          person {
            id
            flag
            name
            mentalState
            vip
            avatarKeys
            avatar
          }
          topics {
            id
            name
          }
        }
      }
      total
    }
  }
`;

/**
 * __useAllClientsQuery__
 *
 * To run a query within a React component, call `useAllClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllClientsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllClientsQuery, AllClientsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllClientsQuery, AllClientsQueryVariables>(AllClientsDocument, options);
}
export function useAllClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllClientsQuery, AllClientsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllClientsQuery, AllClientsQueryVariables>(
    AllClientsDocument,
    options,
  );
}
export type AllClientsQueryHookResult = ReturnType<typeof useAllClientsQuery>;
export type AllClientsLazyQueryHookResult = ReturnType<typeof useAllClientsLazyQuery>;
export type AllClientsQueryResult = Apollo.QueryResult<AllClientsQuery, AllClientsQueryVariables>;
export const GetClientComplaintsDocument = gql`
  query getClientComplaints($id: Int!) {
    getClientComplaints(id: $id) {
      id
      user {
        id
        person {
          id
          name
        }
      }
      title
      content
      dateOfComplaint
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetClientComplaintsQuery__
 *
 * To run a query within a React component, call `useGetClientComplaintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientComplaintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientComplaintsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientComplaintsQuery(
  baseOptions: Apollo.QueryHookOptions<GetClientComplaintsQuery, GetClientComplaintsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientComplaintsQuery, GetClientComplaintsQueryVariables>(
    GetClientComplaintsDocument,
    options,
  );
}
export function useGetClientComplaintsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientComplaintsQuery,
    GetClientComplaintsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientComplaintsQuery, GetClientComplaintsQueryVariables>(
    GetClientComplaintsDocument,
    options,
  );
}
export type GetClientComplaintsQueryHookResult = ReturnType<typeof useGetClientComplaintsQuery>;
export type GetClientComplaintsLazyQueryHookResult = ReturnType<
  typeof useGetClientComplaintsLazyQuery
>;
export type GetClientComplaintsQueryResult = Apollo.QueryResult<
  GetClientComplaintsQuery,
  GetClientComplaintsQueryVariables
>;
export const RiskHistoryDocument = gql`
  query riskHistory($personId: Int!) {
    riskHistory(personId: $personId) {
      id
      attributeValue
      start
      lastUpdate
      clientReviewAttribute {
        id
      }
      user {
        id
        person {
          id
          name
          alias
          avatar
          avatarKeys
          picture
        }
      }
    }
  }
`;

/**
 * __useRiskHistoryQuery__
 *
 * To run a query within a React component, call `useRiskHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRiskHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRiskHistoryQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useRiskHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<RiskHistoryQuery, RiskHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RiskHistoryQuery, RiskHistoryQueryVariables>(RiskHistoryDocument, options);
}
export function useRiskHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RiskHistoryQuery, RiskHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RiskHistoryQuery, RiskHistoryQueryVariables>(
    RiskHistoryDocument,
    options,
  );
}
export type RiskHistoryQueryHookResult = ReturnType<typeof useRiskHistoryQuery>;
export type RiskHistoryLazyQueryHookResult = ReturnType<typeof useRiskHistoryLazyQuery>;
export type RiskHistoryQueryResult = Apollo.QueryResult<
  RiskHistoryQuery,
  RiskHistoryQueryVariables
>;
export const AvailableLanguagesDocument = gql`
  query availableLanguages {
    availableLanguages {
      id
      name
      code
    }
  }
`;

/**
 * __useAvailableLanguagesQuery__
 *
 * To run a query within a React component, call `useAvailableLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableLanguagesQuery(
  baseOptions?: Apollo.QueryHookOptions<AvailableLanguagesQuery, AvailableLanguagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AvailableLanguagesQuery, AvailableLanguagesQueryVariables>(
    AvailableLanguagesDocument,
    options,
  );
}
export function useAvailableLanguagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableLanguagesQuery,
    AvailableLanguagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AvailableLanguagesQuery, AvailableLanguagesQueryVariables>(
    AvailableLanguagesDocument,
    options,
  );
}
export type AvailableLanguagesQueryHookResult = ReturnType<typeof useAvailableLanguagesQuery>;
export type AvailableLanguagesLazyQueryHookResult = ReturnType<
  typeof useAvailableLanguagesLazyQuery
>;
export type AvailableLanguagesQueryResult = Apollo.QueryResult<
  AvailableLanguagesQuery,
  AvailableLanguagesQueryVariables
>;
export const ConversationReportDocument = gql`
  query conversationReport($id: Int!) {
    conversationReport(id: $id) {
      ...baseReport
      author {
        ...userPerson
      }
      personActivity {
        id
        activityType
        start
        end
      }
      client {
        ...client
      }
      topic {
        id
        name
      }
      services {
        id
        serviceMeta {
          id
          name
        }
      }
      connectedServices {
        id
      }
      activeClientReviewTemplate {
        id
        isDefault
        name
        sortIdx
      }
      activeClientReviewAttributeGroup {
        id
        headingNew
        headingUpdate
        pageOrder
      }
      conversationReportReviewAttributes {
        id
        attributeValue
        clientReviewAttribute {
          id
          label
          name
          placeholder
          type
          clientReviewAttributeGroup {
            id
            headingNew
            headingUpdate
            pageOrder
            clientReviewTemplate {
              id
              isDefault
              name
              sortIdx
            }
          }
        }
      }
    }
  }
  ${BaseReportFragmentDoc}
  ${UserPersonFragmentDoc}
  ${ClientFragmentDoc}
`;

/**
 * __useConversationReportQuery__
 *
 * To run a query within a React component, call `useConversationReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConversationReportQuery(
  baseOptions: Apollo.QueryHookOptions<ConversationReportQuery, ConversationReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConversationReportQuery, ConversationReportQueryVariables>(
    ConversationReportDocument,
    options,
  );
}
export function useConversationReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConversationReportQuery,
    ConversationReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ConversationReportQuery, ConversationReportQueryVariables>(
    ConversationReportDocument,
    options,
  );
}
export type ConversationReportQueryHookResult = ReturnType<typeof useConversationReportQuery>;
export type ConversationReportLazyQueryHookResult = ReturnType<
  typeof useConversationReportLazyQuery
>;
export type ConversationReportQueryResult = Apollo.QueryResult<
  ConversationReportQuery,
  ConversationReportQueryVariables
>;
export const ReadReportDocument = gql`
  query readReport($id: Int!) {
    conversationReport(id: $id) {
      ...baseReport
      author {
        ...userPerson
      }
      personActivity {
        id
        activityType
        start
        end
      }
      topic {
        id
        name
      }
      conversationReportReviewAttributes {
        id
        attributeValue
        clientReviewAttributeId
        clientReviewAttribute {
          id
          label
          name
          type
        }
      }
      clientAttributes {
        id
        clientReviewAttributeId
      }
    }
  }
  ${BaseReportFragmentDoc}
  ${UserPersonFragmentDoc}
`;

/**
 * __useReadReportQuery__
 *
 * To run a query within a React component, call `useReadReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadReportQuery(
  baseOptions: Apollo.QueryHookOptions<ReadReportQuery, ReadReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReadReportQuery, ReadReportQueryVariables>(ReadReportDocument, options);
}
export function useReadReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReadReportQuery, ReadReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReadReportQuery, ReadReportQueryVariables>(
    ReadReportDocument,
    options,
  );
}
export type ReadReportQueryHookResult = ReturnType<typeof useReadReportQuery>;
export type ReadReportLazyQueryHookResult = ReturnType<typeof useReadReportLazyQuery>;
export type ReadReportQueryResult = Apollo.QueryResult<ReadReportQuery, ReadReportQueryVariables>;
export const SimpleReportsDocument = gql`
  query simpleReports($personId: Int!, $topicId: Int) {
    conversationReports(personId: $personId, topicId: $topicId) {
      id
    }
  }
`;

/**
 * __useSimpleReportsQuery__
 *
 * To run a query within a React component, call `useSimpleReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleReportsQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *      topicId: // value for 'topicId'
 *   },
 * });
 */
export function useSimpleReportsQuery(
  baseOptions: Apollo.QueryHookOptions<SimpleReportsQuery, SimpleReportsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SimpleReportsQuery, SimpleReportsQueryVariables>(
    SimpleReportsDocument,
    options,
  );
}
export function useSimpleReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SimpleReportsQuery, SimpleReportsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SimpleReportsQuery, SimpleReportsQueryVariables>(
    SimpleReportsDocument,
    options,
  );
}
export type SimpleReportsQueryHookResult = ReturnType<typeof useSimpleReportsQuery>;
export type SimpleReportsLazyQueryHookResult = ReturnType<typeof useSimpleReportsLazyQuery>;
export type SimpleReportsQueryResult = Apollo.QueryResult<
  SimpleReportsQuery,
  SimpleReportsQueryVariables
>;
export const MandatesDocument = gql`
  query mandates($businessId: Int!) {
    mandates(businessId: $businessId) {
      id
      sessionsAppointed
      sessionsCompleted
      counselor {
        ...basePerson
      }
      referenceNumber
      service {
        ...serviceBase
        problem
        client {
          id
          person {
            ...basePerson
          }
          account {
            id
            freeSessions
          }
        }
        topic {
          id
          name
        }
      }
    }
  }
  ${BasePersonFragmentDoc}
  ${ServiceBaseFragmentDoc}
`;

/**
 * __useMandatesQuery__
 *
 * To run a query within a React component, call `useMandatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMandatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMandatesQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useMandatesQuery(
  baseOptions: Apollo.QueryHookOptions<MandatesQuery, MandatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MandatesQuery, MandatesQueryVariables>(MandatesDocument, options);
}
export function useMandatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MandatesQuery, MandatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MandatesQuery, MandatesQueryVariables>(MandatesDocument, options);
}
export type MandatesQueryHookResult = ReturnType<typeof useMandatesQuery>;
export type MandatesLazyQueryHookResult = ReturnType<typeof useMandatesLazyQuery>;
export type MandatesQueryResult = Apollo.QueryResult<MandatesQuery, MandatesQueryVariables>;
export const MandateDocument = gql`
  query mandate($id: Int!) {
    mandate(id: $id) {
      id
      sessionsAppointed
      sessionsCompleted
      counselor {
        ...basePerson
      }
      business {
        id
        name
      }
      referenceNumber
      service {
        ...serviceBase
        serviceMeta {
          ...serviceMeta
        }
        serviceAttributes {
          ...serviceAttributes
        }
        problem
        client {
          id
          person {
            ...basePerson
          }
          account {
            id
            freeSessions
          }
        }
        topic {
          id
          name
        }
      }
    }
  }
  ${BasePersonFragmentDoc}
  ${ServiceBaseFragmentDoc}
  ${ServiceMetaFragmentDoc}
  ${ServiceAttributesFragmentDoc}
`;

/**
 * __useMandateQuery__
 *
 * To run a query within a React component, call `useMandateQuery` and pass it any options that fit your needs.
 * When your component renders, `useMandateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMandateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMandateQuery(
  baseOptions: Apollo.QueryHookOptions<MandateQuery, MandateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MandateQuery, MandateQueryVariables>(MandateDocument, options);
}
export function useMandateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MandateQuery, MandateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MandateQuery, MandateQueryVariables>(MandateDocument, options);
}
export type MandateQueryHookResult = ReturnType<typeof useMandateQuery>;
export type MandateLazyQueryHookResult = ReturnType<typeof useMandateLazyQuery>;
export type MandateQueryResult = Apollo.QueryResult<MandateQuery, MandateQueryVariables>;
export const MandateByServiceDocument = gql`
  query mandateByService($serviceId: Int!) {
    mandateByService(serviceId: $serviceId) {
      id
      sessionsAppointed
      sessionsCompleted
      counselor {
        ...basePerson
      }
      business {
        id
        name
      }
      referenceNumber
      service {
        ...serviceBase
        serviceMeta {
          ...serviceMeta
        }
        serviceAttributes {
          ...serviceAttributes
        }
        problem
        client {
          id
          person {
            ...basePerson
          }
          account {
            id
            freeSessions
          }
        }
        topic {
          id
          name
        }
      }
    }
  }
  ${BasePersonFragmentDoc}
  ${ServiceBaseFragmentDoc}
  ${ServiceMetaFragmentDoc}
  ${ServiceAttributesFragmentDoc}
`;

/**
 * __useMandateByServiceQuery__
 *
 * To run a query within a React component, call `useMandateByServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useMandateByServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMandateByServiceQuery({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *   },
 * });
 */
export function useMandateByServiceQuery(
  baseOptions: Apollo.QueryHookOptions<MandateByServiceQuery, MandateByServiceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MandateByServiceQuery, MandateByServiceQueryVariables>(
    MandateByServiceDocument,
    options,
  );
}
export function useMandateByServiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MandateByServiceQuery, MandateByServiceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MandateByServiceQuery, MandateByServiceQueryVariables>(
    MandateByServiceDocument,
    options,
  );
}
export type MandateByServiceQueryHookResult = ReturnType<typeof useMandateByServiceQuery>;
export type MandateByServiceLazyQueryHookResult = ReturnType<typeof useMandateByServiceLazyQuery>;
export type MandateByServiceQueryResult = Apollo.QueryResult<
  MandateByServiceQuery,
  MandateByServiceQueryVariables
>;
export const AllMandatesDocument = gql`
  query allMandates($active: Boolean!) {
    allMandates(active: $active) {
      id
      sessionsAppointed
      sessionsCompleted
      referenceNumber
      lastActivity
      counselor {
        id
        name
        picture
        avatar
        avatarKeys
        businesses {
          id
          name
          address {
            id
            type
            street
            city
            zip
          }
        }
      }
      service {
        id
        topic {
          id
          name
        }
        client {
          id
          account {
            id
            name
          }
          person {
            id
            flag
            name
            mentalState
            avatarKeys
          }
        }
      }
    }
  }
`;

/**
 * __useAllMandatesQuery__
 *
 * To run a query within a React component, call `useAllMandatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMandatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMandatesQuery({
 *   variables: {
 *      active: // value for 'active'
 *   },
 * });
 */
export function useAllMandatesQuery(
  baseOptions: Apollo.QueryHookOptions<AllMandatesQuery, AllMandatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllMandatesQuery, AllMandatesQueryVariables>(AllMandatesDocument, options);
}
export function useAllMandatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllMandatesQuery, AllMandatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllMandatesQuery, AllMandatesQueryVariables>(
    AllMandatesDocument,
    options,
  );
}
export type AllMandatesQueryHookResult = ReturnType<typeof useAllMandatesQuery>;
export type AllMandatesLazyQueryHookResult = ReturnType<typeof useAllMandatesLazyQuery>;
export type AllMandatesQueryResult = Apollo.QueryResult<
  AllMandatesQuery,
  AllMandatesQueryVariables
>;
export const DepartmentsDocument = gql`
  query departments($accountId: Int!) {
    departments(accountId: $accountId) {
      id
      accountId
      name
      main
    }
  }
`;

/**
 * __useDepartmentsQuery__
 *
 * To run a query within a React component, call `useDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useDepartmentsQuery(
  baseOptions: Apollo.QueryHookOptions<DepartmentsQuery, DepartmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DepartmentsQuery, DepartmentsQueryVariables>(DepartmentsDocument, options);
}
export function useDepartmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DepartmentsQuery, DepartmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DepartmentsQuery, DepartmentsQueryVariables>(
    DepartmentsDocument,
    options,
  );
}
export type DepartmentsQueryHookResult = ReturnType<typeof useDepartmentsQuery>;
export type DepartmentsLazyQueryHookResult = ReturnType<typeof useDepartmentsLazyQuery>;
export type DepartmentsQueryResult = Apollo.QueryResult<
  DepartmentsQuery,
  DepartmentsQueryVariables
>;
export const GetDriveMetaDataDocument = gql`
  query getDriveMetaData($fileId: String!) {
    getDriveMetaData(fileId: $fileId)
  }
`;

/**
 * __useGetDriveMetaDataQuery__
 *
 * To run a query within a React component, call `useGetDriveMetaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriveMetaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriveMetaDataQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useGetDriveMetaDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetDriveMetaDataQuery, GetDriveMetaDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDriveMetaDataQuery, GetDriveMetaDataQueryVariables>(
    GetDriveMetaDataDocument,
    options,
  );
}
export function useGetDriveMetaDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDriveMetaDataQuery, GetDriveMetaDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDriveMetaDataQuery, GetDriveMetaDataQueryVariables>(
    GetDriveMetaDataDocument,
    options,
  );
}
export type GetDriveMetaDataQueryHookResult = ReturnType<typeof useGetDriveMetaDataQuery>;
export type GetDriveMetaDataLazyQueryHookResult = ReturnType<typeof useGetDriveMetaDataLazyQuery>;
export type GetDriveMetaDataQueryResult = Apollo.QueryResult<
  GetDriveMetaDataQuery,
  GetDriveMetaDataQueryVariables
>;
export const GetDriveDocumentsDocument = gql`
  query getDriveDocuments($personIds: [Int!]!) {
    getDriveDocuments(personIds: $personIds) {
      id
      title
      link
      user {
        id
        name
      }
      created
    }
  }
`;

/**
 * __useGetDriveDocumentsQuery__
 *
 * To run a query within a React component, call `useGetDriveDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriveDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriveDocumentsQuery({
 *   variables: {
 *      personIds: // value for 'personIds'
 *   },
 * });
 */
export function useGetDriveDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<GetDriveDocumentsQuery, GetDriveDocumentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDriveDocumentsQuery, GetDriveDocumentsQueryVariables>(
    GetDriveDocumentsDocument,
    options,
  );
}
export function useGetDriveDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDriveDocumentsQuery,
    GetDriveDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDriveDocumentsQuery, GetDriveDocumentsQueryVariables>(
    GetDriveDocumentsDocument,
    options,
  );
}
export type GetDriveDocumentsQueryHookResult = ReturnType<typeof useGetDriveDocumentsQuery>;
export type GetDriveDocumentsLazyQueryHookResult = ReturnType<typeof useGetDriveDocumentsLazyQuery>;
export type GetDriveDocumentsQueryResult = Apollo.QueryResult<
  GetDriveDocumentsQuery,
  GetDriveDocumentsQueryVariables
>;
export const GetGMailThreadsDocument = gql`
  query getGMailThreads {
    getGMailThreads {
      id
      incoming
      historyId
      messageId
      threadId
      body
      date
      attachments {
        attachmentId
        filename
        mimeType
      }
      payload {
        from
        to
        subject
        returnPath
      }
      personActivity {
        id
        activityType
        start
        end
        user {
          id
          person {
            id
            avatar
            avatarKeys
            picture
          }
        }
      }
      gMailInbox {
        id
        email
      }
      user {
        id
        person {
          id
          name
          avatar
          avatarKeys
          picture
          mentalState
          temporary
          emails {
            email
          }
        }
      }
      businessObject {
        id
        name
        email {
          id
          email
        }
      }
      person {
        id
        name
        avatar
        avatarKeys
        mentalState
        temporary
        emails {
          email
        }
      }
    }
  }
`;

/**
 * __useGetGMailThreadsQuery__
 *
 * To run a query within a React component, call `useGetGMailThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGMailThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGMailThreadsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGMailThreadsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetGMailThreadsQuery, GetGMailThreadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGMailThreadsQuery, GetGMailThreadsQueryVariables>(
    GetGMailThreadsDocument,
    options,
  );
}
export function useGetGMailThreadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGMailThreadsQuery, GetGMailThreadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGMailThreadsQuery, GetGMailThreadsQueryVariables>(
    GetGMailThreadsDocument,
    options,
  );
}
export type GetGMailThreadsQueryHookResult = ReturnType<typeof useGetGMailThreadsQuery>;
export type GetGMailThreadsLazyQueryHookResult = ReturnType<typeof useGetGMailThreadsLazyQuery>;
export type GetGMailThreadsQueryResult = Apollo.QueryResult<
  GetGMailThreadsQuery,
  GetGMailThreadsQueryVariables
>;
export const GetOneGMailThreadDocument = gql`
  query getOneGMailThread($threadId: String!) {
    getOneGMailThread(threadId: $threadId) {
      topic {
        id
        name
      }
      personActivity {
        __typename
        id
        activityType
        start
        end
        user {
          id
          person {
            id
            avatar
            avatarKeys
            picture
          }
        }
      }
      emails {
        id
        incoming
        historyId
        messageId
        threadId
        body
        date
        attachments {
          attachmentId
          filename
          mimeType
        }
        payload {
          from
          to
          subject
          returnPath
        }
        gMailInbox {
          id
          email
        }
        user {
          id
          person {
            id
            name
            avatar
            avatarKeys
            picture
            mentalState
            temporary
            emails {
              email
            }
          }
        }
        businessObject {
          id
          name
          email {
            id
            email
          }
        }
        person {
          id
          name
          avatar
          avatarKeys
          mentalState
          temporary
          emails {
            email
          }
        }
        personActivity {
          id
          activityType
          start
          end
          user {
            id
            person {
              id
              avatar
              avatarKeys
              picture
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetOneGMailThreadQuery__
 *
 * To run a query within a React component, call `useGetOneGMailThreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneGMailThreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneGMailThreadQuery({
 *   variables: {
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useGetOneGMailThreadQuery(
  baseOptions: Apollo.QueryHookOptions<GetOneGMailThreadQuery, GetOneGMailThreadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOneGMailThreadQuery, GetOneGMailThreadQueryVariables>(
    GetOneGMailThreadDocument,
    options,
  );
}
export function useGetOneGMailThreadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOneGMailThreadQuery,
    GetOneGMailThreadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOneGMailThreadQuery, GetOneGMailThreadQueryVariables>(
    GetOneGMailThreadDocument,
    options,
  );
}
export type GetOneGMailThreadQueryHookResult = ReturnType<typeof useGetOneGMailThreadQuery>;
export type GetOneGMailThreadLazyQueryHookResult = ReturnType<typeof useGetOneGMailThreadLazyQuery>;
export type GetOneGMailThreadQueryResult = Apollo.QueryResult<
  GetOneGMailThreadQuery,
  GetOneGMailThreadQueryVariables
>;
export const GetOneThreadByActivityDocument = gql`
  query getOneThreadByActivity($personActivityId: Int!) {
    getOneThreadByActivity(personActivityId: $personActivityId) {
      id
      incoming
      historyId
      messageId
      threadId
      body
      date
      attachments {
        attachmentId
        filename
        mimeType
      }
      payload {
        from
        to
        subject
        returnPath
      }
      user {
        id
        person {
          id
          name
          avatar
          avatarKeys
          picture
          mentalState
          temporary
          emails {
            email
          }
        }
      }
      businessObject {
        id
        name
        email {
          id
          email
        }
      }
      person {
        id
        name
        avatar
        avatarKeys
        mentalState
        temporary
      }
      personActivity {
        id
        activityType
        start
        end
      }
    }
  }
`;

/**
 * __useGetOneThreadByActivityQuery__
 *
 * To run a query within a React component, call `useGetOneThreadByActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneThreadByActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneThreadByActivityQuery({
 *   variables: {
 *      personActivityId: // value for 'personActivityId'
 *   },
 * });
 */
export function useGetOneThreadByActivityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOneThreadByActivityQuery,
    GetOneThreadByActivityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOneThreadByActivityQuery, GetOneThreadByActivityQueryVariables>(
    GetOneThreadByActivityDocument,
    options,
  );
}
export function useGetOneThreadByActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOneThreadByActivityQuery,
    GetOneThreadByActivityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOneThreadByActivityQuery, GetOneThreadByActivityQueryVariables>(
    GetOneThreadByActivityDocument,
    options,
  );
}
export type GetOneThreadByActivityQueryHookResult = ReturnType<
  typeof useGetOneThreadByActivityQuery
>;
export type GetOneThreadByActivityLazyQueryHookResult = ReturnType<
  typeof useGetOneThreadByActivityLazyQuery
>;
export type GetOneThreadByActivityQueryResult = Apollo.QueryResult<
  GetOneThreadByActivityQuery,
  GetOneThreadByActivityQueryVariables
>;
export const GetAttachmentDataDocument = gql`
  query getAttachmentData($id: String!, $messageId: String!) {
    getAttachmentData(id: $id, messageId: $messageId)
  }
`;

/**
 * __useGetAttachmentDataQuery__
 *
 * To run a query within a React component, call `useGetAttachmentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttachmentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttachmentDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useGetAttachmentDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetAttachmentDataQuery, GetAttachmentDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAttachmentDataQuery, GetAttachmentDataQueryVariables>(
    GetAttachmentDataDocument,
    options,
  );
}
export function useGetAttachmentDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAttachmentDataQuery,
    GetAttachmentDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAttachmentDataQuery, GetAttachmentDataQueryVariables>(
    GetAttachmentDataDocument,
    options,
  );
}
export type GetAttachmentDataQueryHookResult = ReturnType<typeof useGetAttachmentDataQuery>;
export type GetAttachmentDataLazyQueryHookResult = ReturnType<typeof useGetAttachmentDataLazyQuery>;
export type GetAttachmentDataQueryResult = Apollo.QueryResult<
  GetAttachmentDataQuery,
  GetAttachmentDataQueryVariables
>;
export const IndustriesDocument = gql`
  query industries {
    industries {
      id
      name
    }
  }
`;

/**
 * __useIndustriesQuery__
 *
 * To run a query within a React component, call `useIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndustriesQuery(
  baseOptions?: Apollo.QueryHookOptions<IndustriesQuery, IndustriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IndustriesQuery, IndustriesQueryVariables>(IndustriesDocument, options);
}
export function useIndustriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IndustriesQuery, IndustriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IndustriesQuery, IndustriesQueryVariables>(
    IndustriesDocument,
    options,
  );
}
export type IndustriesQueryHookResult = ReturnType<typeof useIndustriesQuery>;
export type IndustriesLazyQueryHookResult = ReturnType<typeof useIndustriesLazyQuery>;
export type IndustriesQueryResult = Apollo.QueryResult<IndustriesQuery, IndustriesQueryVariables>;
export const MeDocument = gql`
  query Me {
    me {
      id
      avatar
      avatarKeys
      picture
      firstName
      name
      age
      role
      addresses {
        id
        street
        city
        zip
      }
      papershiftWorkingAreas {
        id
        name
      }
      user {
        ...user
        defaultTeam
        teams
        person {
          ...person
        }
        papershiftWorkingAreas {
          ...papershiftWorkingArea
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${PersonFragmentDoc}
  ${PapershiftWorkingAreaFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MemoDocument = gql`
  query memo($id: Int!) {
    memo(id: $id) {
      ...memo
      personActivity {
        id
        activityType
        start
        end
        person {
          id
          name
        }
      }
    }
  }
  ${MemoFragmentDoc}
`;

/**
 * __useMemoQuery__
 *
 * To run a query within a React component, call `useMemoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMemoQuery(baseOptions: Apollo.QueryHookOptions<MemoQuery, MemoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemoQuery, MemoQueryVariables>(MemoDocument, options);
}
export function useMemoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MemoQuery, MemoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemoQuery, MemoQueryVariables>(MemoDocument, options);
}
export type MemoQueryHookResult = ReturnType<typeof useMemoQuery>;
export type MemoLazyQueryHookResult = ReturnType<typeof useMemoLazyQuery>;
export type MemoQueryResult = Apollo.QueryResult<MemoQuery, MemoQueryVariables>;
export const ObservableJobDocument = gql`
  query observableJob($id: Int!) {
    observableJob(id: $id) {
      status
      id
      link
      error
    }
  }
`;

/**
 * __useObservableJobQuery__
 *
 * To run a query within a React component, call `useObservableJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useObservableJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObservableJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useObservableJobQuery(
  baseOptions: Apollo.QueryHookOptions<ObservableJobQuery, ObservableJobQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ObservableJobQuery, ObservableJobQueryVariables>(
    ObservableJobDocument,
    options,
  );
}
export function useObservableJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ObservableJobQuery, ObservableJobQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ObservableJobQuery, ObservableJobQueryVariables>(
    ObservableJobDocument,
    options,
  );
}
export type ObservableJobQueryHookResult = ReturnType<typeof useObservableJobQuery>;
export type ObservableJobLazyQueryHookResult = ReturnType<typeof useObservableJobLazyQuery>;
export type ObservableJobQueryResult = Apollo.QueryResult<
  ObservableJobQuery,
  ObservableJobQueryVariables
>;
export const PapershiftShiftsDocument = gql`
  query papershiftShifts($startsAt: String!, $endsAt: String!) {
    papershiftShifts(startsAt: $startsAt, endsAt: $endsAt) {
      ...papershiftShift
      papershiftWorkingArea {
        ...papershiftWorkingArea
        color
      }
      users {
        ...user
        papershiftWorkingAreas {
          ...papershiftWorkingArea
        }
        person {
          id
          alias
          name
          avatar
          avatarKeys
          picture
        }
      }
    }
  }
  ${PapershiftShiftFragmentDoc}
  ${PapershiftWorkingAreaFragmentDoc}
  ${UserFragmentDoc}
`;

/**
 * __usePapershiftShiftsQuery__
 *
 * To run a query within a React component, call `usePapershiftShiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePapershiftShiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePapershiftShiftsQuery({
 *   variables: {
 *      startsAt: // value for 'startsAt'
 *      endsAt: // value for 'endsAt'
 *   },
 * });
 */
export function usePapershiftShiftsQuery(
  baseOptions: Apollo.QueryHookOptions<PapershiftShiftsQuery, PapershiftShiftsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PapershiftShiftsQuery, PapershiftShiftsQueryVariables>(
    PapershiftShiftsDocument,
    options,
  );
}
export function usePapershiftShiftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PapershiftShiftsQuery, PapershiftShiftsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PapershiftShiftsQuery, PapershiftShiftsQueryVariables>(
    PapershiftShiftsDocument,
    options,
  );
}
export type PapershiftShiftsQueryHookResult = ReturnType<typeof usePapershiftShiftsQuery>;
export type PapershiftShiftsLazyQueryHookResult = ReturnType<typeof usePapershiftShiftsLazyQuery>;
export type PapershiftShiftsQueryResult = Apollo.QueryResult<
  PapershiftShiftsQuery,
  PapershiftShiftsQueryVariables
>;
export const PapershiftWorkingAreasDocument = gql`
  query papershiftWorkingAreas {
    papershiftWorkingAreas {
      ...papershiftWorkingArea
    }
  }
  ${PapershiftWorkingAreaFragmentDoc}
`;

/**
 * __usePapershiftWorkingAreasQuery__
 *
 * To run a query within a React component, call `usePapershiftWorkingAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `usePapershiftWorkingAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePapershiftWorkingAreasQuery({
 *   variables: {
 *   },
 * });
 */
export function usePapershiftWorkingAreasQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PapershiftWorkingAreasQuery,
    PapershiftWorkingAreasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PapershiftWorkingAreasQuery, PapershiftWorkingAreasQueryVariables>(
    PapershiftWorkingAreasDocument,
    options,
  );
}
export function usePapershiftWorkingAreasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PapershiftWorkingAreasQuery,
    PapershiftWorkingAreasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PapershiftWorkingAreasQuery, PapershiftWorkingAreasQueryVariables>(
    PapershiftWorkingAreasDocument,
    options,
  );
}
export type PapershiftWorkingAreasQueryHookResult = ReturnType<
  typeof usePapershiftWorkingAreasQuery
>;
export type PapershiftWorkingAreasLazyQueryHookResult = ReturnType<
  typeof usePapershiftWorkingAreasLazyQuery
>;
export type PapershiftWorkingAreasQueryResult = Apollo.QueryResult<
  PapershiftWorkingAreasQuery,
  PapershiftWorkingAreasQueryVariables
>;
export const PersonByChatAliasDocument = gql`
  query personByChatAlias($chatAlias: String!) {
    personByChatAlias(chatAlias: $chatAlias) {
      id
      name
    }
  }
`;

/**
 * __usePersonByChatAliasQuery__
 *
 * To run a query within a React component, call `usePersonByChatAliasQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonByChatAliasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonByChatAliasQuery({
 *   variables: {
 *      chatAlias: // value for 'chatAlias'
 *   },
 * });
 */
export function usePersonByChatAliasQuery(
  baseOptions: Apollo.QueryHookOptions<PersonByChatAliasQuery, PersonByChatAliasQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PersonByChatAliasQuery, PersonByChatAliasQueryVariables>(
    PersonByChatAliasDocument,
    options,
  );
}
export function usePersonByChatAliasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PersonByChatAliasQuery,
    PersonByChatAliasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PersonByChatAliasQuery, PersonByChatAliasQueryVariables>(
    PersonByChatAliasDocument,
    options,
  );
}
export type PersonByChatAliasQueryHookResult = ReturnType<typeof usePersonByChatAliasQuery>;
export type PersonByChatAliasLazyQueryHookResult = ReturnType<typeof usePersonByChatAliasLazyQuery>;
export type PersonByChatAliasQueryResult = Apollo.QueryResult<
  PersonByChatAliasQuery,
  PersonByChatAliasQueryVariables
>;
export const ReportPersonDocument = gql`
  query reportPerson($id: Int!) {
    person(id: $id) {
      ...clientPerson
      client {
        id
        reviewRequired
        topics {
          name
          id
        }
        account {
          id
          type
        }
      }
    }
  }
  ${ClientPersonFragmentDoc}
`;

/**
 * __useReportPersonQuery__
 *
 * To run a query within a React component, call `useReportPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportPersonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReportPersonQuery(
  baseOptions: Apollo.QueryHookOptions<ReportPersonQuery, ReportPersonQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReportPersonQuery, ReportPersonQueryVariables>(
    ReportPersonDocument,
    options,
  );
}
export function useReportPersonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReportPersonQuery, ReportPersonQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReportPersonQuery, ReportPersonQueryVariables>(
    ReportPersonDocument,
    options,
  );
}
export type ReportPersonQueryHookResult = ReturnType<typeof useReportPersonQuery>;
export type ReportPersonLazyQueryHookResult = ReturnType<typeof useReportPersonLazyQuery>;
export type ReportPersonQueryResult = Apollo.QueryResult<
  ReportPersonQuery,
  ReportPersonQueryVariables
>;
export const PersonDocument = gql`
  query Person($id: Int!) {
    person(id: $id) {
      ...person
      emails {
        ...email
      }
      addresses {
        ...address
      }
      phones {
        ...phone
      }
      relations {
        id
        relationship
        isEmergency
        isHousehold
        passivePerson {
          ...person
          client {
            id
          }
          addresses {
            ...address
          }
          phones {
            ...phone
          }
        }
      }
      client {
        id
        clientType
        departmentId
        ucmsId
        department {
          name
          id
          main
        }
        job
        jobBand
        language
        contactLanguage {
          id
          code
          name
        }
        chatUserId
        chatAlias
        vip
        healthInsuranceCompany
        sourceOfInformation
        hrBusiness
        source
        assistanceId
        yearOfEntry
        businessUnit {
          id
          name
        }
        account {
          id
          name
          isAssistance
          tariff
          type
          validationPattern
          freeSessions
          contractEnd
          businessUnits {
            id
            name
          }
        }
        topics {
          name
          id
        }
      }
    }
  }
  ${PersonFragmentDoc}
  ${EmailFragmentDoc}
  ${AddressFragmentDoc}
  ${PhoneFragmentDoc}
`;

/**
 * __usePersonQuery__
 *
 * To run a query within a React component, call `usePersonQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePersonQuery(
  baseOptions: Apollo.QueryHookOptions<PersonQuery, PersonQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PersonQuery, PersonQueryVariables>(PersonDocument, options);
}
export function usePersonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PersonQuery, PersonQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PersonQuery, PersonQueryVariables>(PersonDocument, options);
}
export type PersonQueryHookResult = ReturnType<typeof usePersonQuery>;
export type PersonLazyQueryHookResult = ReturnType<typeof usePersonLazyQuery>;
export type PersonQueryResult = Apollo.QueryResult<PersonQuery, PersonQueryVariables>;
export const PersonsAdvancedDocument = gql`
  query personsAdvanced($accountId: Float, $name: String!) {
    personsAdvanced(accountId: $accountId, name: $name) {
      id
      name
      age
      ageGuessed
    }
  }
`;

/**
 * __usePersonsAdvancedQuery__
 *
 * To run a query within a React component, call `usePersonsAdvancedQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonsAdvancedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonsAdvancedQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function usePersonsAdvancedQuery(
  baseOptions: Apollo.QueryHookOptions<PersonsAdvancedQuery, PersonsAdvancedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PersonsAdvancedQuery, PersonsAdvancedQueryVariables>(
    PersonsAdvancedDocument,
    options,
  );
}
export function usePersonsAdvancedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PersonsAdvancedQuery, PersonsAdvancedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PersonsAdvancedQuery, PersonsAdvancedQueryVariables>(
    PersonsAdvancedDocument,
    options,
  );
}
export type PersonsAdvancedQueryHookResult = ReturnType<typeof usePersonsAdvancedQuery>;
export type PersonsAdvancedLazyQueryHookResult = ReturnType<typeof usePersonsAdvancedLazyQuery>;
export type PersonsAdvancedQueryResult = Apollo.QueryResult<
  PersonsAdvancedQuery,
  PersonsAdvancedQueryVariables
>;
export const PersonSearchDocument = gql`
  query personSearch($name: String!) {
    personSearch(name: $name) {
      accounts {
        id
        name
      }
      businesses {
        id
        name
      }
      persons {
        id
        name
        age
        ageGuessed
        businesses {
          id
          name
        }
        client {
          id
          account {
            id
            name
            contractEnd
          }
        }
      }
    }
  }
`;

/**
 * __usePersonSearchQuery__
 *
 * To run a query within a React component, call `usePersonSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonSearchQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function usePersonSearchQuery(
  baseOptions: Apollo.QueryHookOptions<PersonSearchQuery, PersonSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PersonSearchQuery, PersonSearchQueryVariables>(
    PersonSearchDocument,
    options,
  );
}
export function usePersonSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PersonSearchQuery, PersonSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PersonSearchQuery, PersonSearchQueryVariables>(
    PersonSearchDocument,
    options,
  );
}
export type PersonSearchQueryHookResult = ReturnType<typeof usePersonSearchQuery>;
export type PersonSearchLazyQueryHookResult = ReturnType<typeof usePersonSearchLazyQuery>;
export type PersonSearchQueryResult = Apollo.QueryResult<
  PersonSearchQuery,
  PersonSearchQueryVariables
>;
export const ClientSearchDocument = gql`
  query clientSearch($name: String!) {
    clientSearch(name: $name) {
      accounts {
        id
        name
      }
      businesses {
        id
        name
      }
      persons {
        id
        name
        age
        ageGuessed
        businesses {
          id
          name
        }
        client {
          id
          account {
            id
            name
            contractEnd
          }
        }
      }
    }
  }
`;

/**
 * __useClientSearchQuery__
 *
 * To run a query within a React component, call `useClientSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientSearchQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useClientSearchQuery(
  baseOptions: Apollo.QueryHookOptions<ClientSearchQuery, ClientSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClientSearchQuery, ClientSearchQueryVariables>(
    ClientSearchDocument,
    options,
  );
}
export function useClientSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClientSearchQuery, ClientSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClientSearchQuery, ClientSearchQueryVariables>(
    ClientSearchDocument,
    options,
  );
}
export type ClientSearchQueryHookResult = ReturnType<typeof useClientSearchQuery>;
export type ClientSearchLazyQueryHookResult = ReturnType<typeof useClientSearchLazyQuery>;
export type ClientSearchQueryResult = Apollo.QueryResult<
  ClientSearchQuery,
  ClientSearchQueryVariables
>;
export const PersonSearchWithClientDocument = gql`
  query personSearchWithClient($name: String!) {
    personSearchWithClient(name: $name) {
      accounts {
        id
        name
      }
      businesses {
        id
        name
      }
      persons {
        id
        name
        age
        ageGuessed
        businesses {
          id
          name
        }
        client {
          id
          account {
            id
            name
            contractEnd
          }
        }
      }
    }
  }
`;

/**
 * __usePersonSearchWithClientQuery__
 *
 * To run a query within a React component, call `usePersonSearchWithClientQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonSearchWithClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonSearchWithClientQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function usePersonSearchWithClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    PersonSearchWithClientQuery,
    PersonSearchWithClientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PersonSearchWithClientQuery, PersonSearchWithClientQueryVariables>(
    PersonSearchWithClientDocument,
    options,
  );
}
export function usePersonSearchWithClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PersonSearchWithClientQuery,
    PersonSearchWithClientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PersonSearchWithClientQuery, PersonSearchWithClientQueryVariables>(
    PersonSearchWithClientDocument,
    options,
  );
}
export type PersonSearchWithClientQueryHookResult = ReturnType<
  typeof usePersonSearchWithClientQuery
>;
export type PersonSearchWithClientLazyQueryHookResult = ReturnType<
  typeof usePersonSearchWithClientLazyQuery
>;
export type PersonSearchWithClientQueryResult = Apollo.QueryResult<
  PersonSearchWithClientQuery,
  PersonSearchWithClientQueryVariables
>;
export const PersonsByBusinessDocument = gql`
  query personsByBusiness($businessId: Int!) {
    personsByBusiness(businessId: $businessId) {
      ...businessPerson
      emails {
        ...email
      }
      addresses {
        ...address
      }
      phones {
        ...phone
      }
    }
  }
  ${BusinessPersonFragmentDoc}
  ${EmailFragmentDoc}
  ${AddressFragmentDoc}
  ${PhoneFragmentDoc}
`;

/**
 * __usePersonsByBusinessQuery__
 *
 * To run a query within a React component, call `usePersonsByBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonsByBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonsByBusinessQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function usePersonsByBusinessQuery(
  baseOptions: Apollo.QueryHookOptions<PersonsByBusinessQuery, PersonsByBusinessQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PersonsByBusinessQuery, PersonsByBusinessQueryVariables>(
    PersonsByBusinessDocument,
    options,
  );
}
export function usePersonsByBusinessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PersonsByBusinessQuery,
    PersonsByBusinessQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PersonsByBusinessQuery, PersonsByBusinessQueryVariables>(
    PersonsByBusinessDocument,
    options,
  );
}
export type PersonsByBusinessQueryHookResult = ReturnType<typeof usePersonsByBusinessQuery>;
export type PersonsByBusinessLazyQueryHookResult = ReturnType<typeof usePersonsByBusinessLazyQuery>;
export type PersonsByBusinessQueryResult = Apollo.QueryResult<
  PersonsByBusinessQuery,
  PersonsByBusinessQueryVariables
>;
export const BreadCrumbInfoByPersonIdDocument = gql`
  query breadCrumbInfoByPersonId($id: Int!) {
    breadCrumbInfoByPersonId(id: $id) {
      contractEnd
      language
    }
  }
`;

/**
 * __useBreadCrumbInfoByPersonIdQuery__
 *
 * To run a query within a React component, call `useBreadCrumbInfoByPersonIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBreadCrumbInfoByPersonIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBreadCrumbInfoByPersonIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBreadCrumbInfoByPersonIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    BreadCrumbInfoByPersonIdQuery,
    BreadCrumbInfoByPersonIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BreadCrumbInfoByPersonIdQuery, BreadCrumbInfoByPersonIdQueryVariables>(
    BreadCrumbInfoByPersonIdDocument,
    options,
  );
}
export function useBreadCrumbInfoByPersonIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BreadCrumbInfoByPersonIdQuery,
    BreadCrumbInfoByPersonIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BreadCrumbInfoByPersonIdQuery, BreadCrumbInfoByPersonIdQueryVariables>(
    BreadCrumbInfoByPersonIdDocument,
    options,
  );
}
export type BreadCrumbInfoByPersonIdQueryHookResult = ReturnType<
  typeof useBreadCrumbInfoByPersonIdQuery
>;
export type BreadCrumbInfoByPersonIdLazyQueryHookResult = ReturnType<
  typeof useBreadCrumbInfoByPersonIdLazyQuery
>;
export type BreadCrumbInfoByPersonIdQueryResult = Apollo.QueryResult<
  BreadCrumbInfoByPersonIdQuery,
  BreadCrumbInfoByPersonIdQueryVariables
>;
export const GetMemoForClientByPersonIdDocument = gql`
  query getMemoForClientByPersonId($id: Int!) {
    getMemoForClientByPersonId(id: $id)
  }
`;

/**
 * __useGetMemoForClientByPersonIdQuery__
 *
 * To run a query within a React component, call `useGetMemoForClientByPersonIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemoForClientByPersonIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemoForClientByPersonIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMemoForClientByPersonIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemoForClientByPersonIdQuery,
    GetMemoForClientByPersonIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMemoForClientByPersonIdQuery, GetMemoForClientByPersonIdQueryVariables>(
    GetMemoForClientByPersonIdDocument,
    options,
  );
}
export function useGetMemoForClientByPersonIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemoForClientByPersonIdQuery,
    GetMemoForClientByPersonIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemoForClientByPersonIdQuery,
    GetMemoForClientByPersonIdQueryVariables
  >(GetMemoForClientByPersonIdDocument, options);
}
export type GetMemoForClientByPersonIdQueryHookResult = ReturnType<
  typeof useGetMemoForClientByPersonIdQuery
>;
export type GetMemoForClientByPersonIdLazyQueryHookResult = ReturnType<
  typeof useGetMemoForClientByPersonIdLazyQuery
>;
export type GetMemoForClientByPersonIdQueryResult = Apollo.QueryResult<
  GetMemoForClientByPersonIdQuery,
  GetMemoForClientByPersonIdQueryVariables
>;
export const PersonActivitiesDocument = gql`
  query PersonActivities(
    $personId: Int!
    $contactId: Int
    $topicId: Int
    $serviceId: Int
    $activityType: String
    $skip: Int
    $take: Int
  ) {
    personActivities(
      personId: $personId
      contactId: $contactId
      topicId: $topicId
      serviceId: $serviceId
      activityType: $activityType
      skip: $skip
      take: $take
    ) {
      ...activity
      author {
        ...author
      }
      conversationReport {
        ...conversationReport
      }
      topic {
        id
        name
      }
      tasks {
        ...task
      }
      memo {
        ...memo
      }
      service {
        ...serviceBase
        serviceMeta {
          id
          name
          type
        }
      }
    }
  }
  ${ActivityFragmentDoc}
  ${AuthorFragmentDoc}
  ${ConversationReportFragmentDoc}
  ${TaskFragmentDoc}
  ${MemoFragmentDoc}
  ${ServiceBaseFragmentDoc}
`;

/**
 * __usePersonActivitiesQuery__
 *
 * To run a query within a React component, call `usePersonActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonActivitiesQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *      contactId: // value for 'contactId'
 *      topicId: // value for 'topicId'
 *      serviceId: // value for 'serviceId'
 *      activityType: // value for 'activityType'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function usePersonActivitiesQuery(
  baseOptions: Apollo.QueryHookOptions<PersonActivitiesQuery, PersonActivitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PersonActivitiesQuery, PersonActivitiesQueryVariables>(
    PersonActivitiesDocument,
    options,
  );
}
export function usePersonActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PersonActivitiesQuery, PersonActivitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PersonActivitiesQuery, PersonActivitiesQueryVariables>(
    PersonActivitiesDocument,
    options,
  );
}
export type PersonActivitiesQueryHookResult = ReturnType<typeof usePersonActivitiesQuery>;
export type PersonActivitiesLazyQueryHookResult = ReturnType<typeof usePersonActivitiesLazyQuery>;
export type PersonActivitiesQueryResult = Apollo.QueryResult<
  PersonActivitiesQuery,
  PersonActivitiesQueryVariables
>;
export const BusinessActivitiesDocument = gql`
  query businessActivities($personIds: [Int], $skip: Int, $take: Int) {
    businessActivities(personIds: $personIds, skip: $skip, take: $take) {
      ...activity
      personId
      author {
        ...author
      }
      memo {
        ...memo
      }
      person {
        businessIds
        name
        id
        yellowFlag
        redFlag
        temporary
        emails {
          id
          email
        }
        client {
          id
          vip
          account {
            id
            name
          }
        }
      }
      serviceResearchObject {
        ...serviceResearchObject
        contactPerson {
          ...basePerson
        }
        businessObject {
          id
          name
        }
        service {
          id
          serviceMeta {
            id
            name
            type
          }
        }
      }
      conversationReport {
        ...conversationReport
      }
      topic {
        id
        name
      }
      tasks {
        ...task
      }
      service {
        ...serviceBase
        serviceMeta {
          id
          name
          type
        }
      }
    }
  }
  ${ActivityFragmentDoc}
  ${AuthorFragmentDoc}
  ${MemoFragmentDoc}
  ${ServiceResearchObjectFragmentDoc}
  ${BasePersonFragmentDoc}
  ${ConversationReportFragmentDoc}
  ${TaskFragmentDoc}
  ${ServiceBaseFragmentDoc}
`;

/**
 * __useBusinessActivitiesQuery__
 *
 * To run a query within a React component, call `useBusinessActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessActivitiesQuery({
 *   variables: {
 *      personIds: // value for 'personIds'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useBusinessActivitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<BusinessActivitiesQuery, BusinessActivitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusinessActivitiesQuery, BusinessActivitiesQueryVariables>(
    BusinessActivitiesDocument,
    options,
  );
}
export function useBusinessActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BusinessActivitiesQuery,
    BusinessActivitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BusinessActivitiesQuery, BusinessActivitiesQueryVariables>(
    BusinessActivitiesDocument,
    options,
  );
}
export type BusinessActivitiesQueryHookResult = ReturnType<typeof useBusinessActivitiesQuery>;
export type BusinessActivitiesLazyQueryHookResult = ReturnType<
  typeof useBusinessActivitiesLazyQuery
>;
export type BusinessActivitiesQueryResult = Apollo.QueryResult<
  BusinessActivitiesQuery,
  BusinessActivitiesQueryVariables
>;
export const ActiveActivitiesDocument = gql`
  query activeActivities {
    activeActivities {
      id
      isActive
      activityType
      start
      author {
        id
        name
      }
      chat {
        id
        chatUserId
      }
      call {
        id
        accountId
        status
      }
      person {
        ...clientPerson
        temporary
        emails {
          id
          email
        }
        phones {
          ...phone
        }
      }
      service {
        ...serviceBase
        serviceMeta {
          id
          name
          type
        }
      }
      serviceResearchObject {
        ...serviceResearchObject
        contactPerson {
          ...basePerson
        }
        businessObject {
          id
          name
        }
      }
    }
  }
  ${ClientPersonFragmentDoc}
  ${PhoneFragmentDoc}
  ${ServiceBaseFragmentDoc}
  ${ServiceResearchObjectFragmentDoc}
  ${BasePersonFragmentDoc}
`;

/**
 * __useActiveActivitiesQuery__
 *
 * To run a query within a React component, call `useActiveActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveActivitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveActivitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<ActiveActivitiesQuery, ActiveActivitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActiveActivitiesQuery, ActiveActivitiesQueryVariables>(
    ActiveActivitiesDocument,
    options,
  );
}
export function useActiveActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ActiveActivitiesQuery, ActiveActivitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActiveActivitiesQuery, ActiveActivitiesQueryVariables>(
    ActiveActivitiesDocument,
    options,
  );
}
export type ActiveActivitiesQueryHookResult = ReturnType<typeof useActiveActivitiesQuery>;
export type ActiveActivitiesLazyQueryHookResult = ReturnType<typeof useActiveActivitiesLazyQuery>;
export type ActiveActivitiesQueryResult = Apollo.QueryResult<
  ActiveActivitiesQuery,
  ActiveActivitiesQueryVariables
>;
export const PersonActivityDocument = gql`
  query PersonActivity($id: Int!) {
    personActivity(id: $id) {
      ...activity
      person {
        ...basePerson
        flag
        yellowFlag
        redFlag
        temporary
        emails {
          id
          email
        }
        business {
          id
        }
      }
      conversationReport {
        id
      }
      memo {
        ...memo
      }
      call {
        id
        accountId
        status
        readableNumber
        countryCode
        areaCode
        number
        account {
          id
          name
        }
      }
      matches {
        ...clientPerson
        phones {
          id
          readableNumber
        }
      }
      suggestions {
        ...clientPerson
        phones {
          id
          readableNumber
        }
        activities {
          id
          start
          end
          activityType
        }
      }
    }
  }
  ${ActivityFragmentDoc}
  ${BasePersonFragmentDoc}
  ${MemoFragmentDoc}
  ${ClientPersonFragmentDoc}
`;

/**
 * __usePersonActivityQuery__
 *
 * To run a query within a React component, call `usePersonActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePersonActivityQuery(
  baseOptions: Apollo.QueryHookOptions<PersonActivityQuery, PersonActivityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PersonActivityQuery, PersonActivityQueryVariables>(
    PersonActivityDocument,
    options,
  );
}
export function usePersonActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PersonActivityQuery, PersonActivityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PersonActivityQuery, PersonActivityQueryVariables>(
    PersonActivityDocument,
    options,
  );
}
export type PersonActivityQueryHookResult = ReturnType<typeof usePersonActivityQuery>;
export type PersonActivityLazyQueryHookResult = ReturnType<typeof usePersonActivityLazyQuery>;
export type PersonActivityQueryResult = Apollo.QueryResult<
  PersonActivityQuery,
  PersonActivityQueryVariables
>;
export const PersonResearchActivityDocument = gql`
  query personResearchActivity($id: Int!) {
    personActivity(id: $id) {
      ...activity
      person {
        ...basePerson
      }
      call {
        id
        accountId
        status
        readableNumber
        account {
          id
          name
        }
      }
      serviceResearchObject {
        ...serviceResearchObject
        contactPerson {
          ...basePerson
        }
        businessObject {
          id
          name
        }
      }
    }
  }
  ${ActivityFragmentDoc}
  ${BasePersonFragmentDoc}
  ${ServiceResearchObjectFragmentDoc}
`;

/**
 * __usePersonResearchActivityQuery__
 *
 * To run a query within a React component, call `usePersonResearchActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonResearchActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonResearchActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePersonResearchActivityQuery(
  baseOptions: Apollo.QueryHookOptions<
    PersonResearchActivityQuery,
    PersonResearchActivityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PersonResearchActivityQuery, PersonResearchActivityQueryVariables>(
    PersonResearchActivityDocument,
    options,
  );
}
export function usePersonResearchActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PersonResearchActivityQuery,
    PersonResearchActivityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PersonResearchActivityQuery, PersonResearchActivityQueryVariables>(
    PersonResearchActivityDocument,
    options,
  );
}
export type PersonResearchActivityQueryHookResult = ReturnType<
  typeof usePersonResearchActivityQuery
>;
export type PersonResearchActivityLazyQueryHookResult = ReturnType<
  typeof usePersonResearchActivityLazyQuery
>;
export type PersonResearchActivityQueryResult = Apollo.QueryResult<
  PersonResearchActivityQuery,
  PersonResearchActivityQueryVariables
>;
export const AllActivitiesDocument = gql`
  query allActivities($skip: Int, $take: Int, $date: DateTime) {
    allActivities(skip: $skip, take: $take, date: $date) {
      ...activity
      personId
      author {
        ...author
      }
      memo {
        ...memo
      }
      person {
        businessIds
        name
        id
        yellowFlag
        redFlag
        temporary
        emails {
          id
          email
        }
        client {
          id
          vip
          account {
            id
            name
          }
        }
      }
      serviceResearchObject {
        ...serviceResearchObject
        contactPerson {
          ...basePerson
        }
        businessObject {
          id
          name
        }
        service {
          id
          serviceMeta {
            id
            name
            type
          }
        }
      }
      conversationReport {
        ...conversationReport
      }
      topic {
        id
        name
      }
      tasks {
        ...task
      }
      service {
        ...serviceBase
        serviceMeta {
          id
          name
          type
        }
      }
    }
  }
  ${ActivityFragmentDoc}
  ${AuthorFragmentDoc}
  ${MemoFragmentDoc}
  ${ServiceResearchObjectFragmentDoc}
  ${BasePersonFragmentDoc}
  ${ConversationReportFragmentDoc}
  ${TaskFragmentDoc}
  ${ServiceBaseFragmentDoc}
`;

/**
 * __useAllActivitiesQuery__
 *
 * To run a query within a React component, call `useAllActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllActivitiesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useAllActivitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<AllActivitiesQuery, AllActivitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllActivitiesQuery, AllActivitiesQueryVariables>(
    AllActivitiesDocument,
    options,
  );
}
export function useAllActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllActivitiesQuery, AllActivitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllActivitiesQuery, AllActivitiesQueryVariables>(
    AllActivitiesDocument,
    options,
  );
}
export type AllActivitiesQueryHookResult = ReturnType<typeof useAllActivitiesQuery>;
export type AllActivitiesLazyQueryHookResult = ReturnType<typeof useAllActivitiesLazyQuery>;
export type AllActivitiesQueryResult = Apollo.QueryResult<
  AllActivitiesQuery,
  AllActivitiesQueryVariables
>;
export const ReportActivityDocument = gql`
  query reportActivity($id: Int!, $loadReportData: Boolean) {
    personActivity(id: $id, loadReportData: $loadReportData) {
      ...activity
      doneAt
      isPublic
      author {
        ...author
      }
      person {
        ...basePerson
      }
      refPerson {
        ...basePerson
      }
      topic {
        id
        name
      }
      tasks {
        ...task
      }
      conversationReport {
        ...readReport
      }
      memo {
        ...memo
      }
    }
  }
  ${ActivityFragmentDoc}
  ${AuthorFragmentDoc}
  ${BasePersonFragmentDoc}
  ${TaskFragmentDoc}
  ${ReadReportFragmentDoc}
  ${MemoFragmentDoc}
`;

/**
 * __useReportActivityQuery__
 *
 * To run a query within a React component, call `useReportActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *      loadReportData: // value for 'loadReportData'
 *   },
 * });
 */
export function useReportActivityQuery(
  baseOptions: Apollo.QueryHookOptions<ReportActivityQuery, ReportActivityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReportActivityQuery, ReportActivityQueryVariables>(
    ReportActivityDocument,
    options,
  );
}
export function useReportActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReportActivityQuery, ReportActivityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReportActivityQuery, ReportActivityQueryVariables>(
    ReportActivityDocument,
    options,
  );
}
export type ReportActivityQueryHookResult = ReturnType<typeof useReportActivityQuery>;
export type ReportActivityLazyQueryHookResult = ReturnType<typeof useReportActivityLazyQuery>;
export type ReportActivityQueryResult = Apollo.QueryResult<
  ReportActivityQuery,
  ReportActivityQueryVariables
>;
export const PersonInteractionsDocument = gql`
  query personInteractions($personId: Int!, $topicId: Int) {
    personInteractions(personId: $personId, topicId: $topicId) {
      id
      doneAt
    }
  }
`;

/**
 * __usePersonInteractionsQuery__
 *
 * To run a query within a React component, call `usePersonInteractionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonInteractionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonInteractionsQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *      topicId: // value for 'topicId'
 *   },
 * });
 */
export function usePersonInteractionsQuery(
  baseOptions: Apollo.QueryHookOptions<PersonInteractionsQuery, PersonInteractionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PersonInteractionsQuery, PersonInteractionsQueryVariables>(
    PersonInteractionsDocument,
    options,
  );
}
export function usePersonInteractionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PersonInteractionsQuery,
    PersonInteractionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PersonInteractionsQuery, PersonInteractionsQueryVariables>(
    PersonInteractionsDocument,
    options,
  );
}
export type PersonInteractionsQueryHookResult = ReturnType<typeof usePersonInteractionsQuery>;
export type PersonInteractionsLazyQueryHookResult = ReturnType<
  typeof usePersonInteractionsLazyQuery
>;
export type PersonInteractionsQueryResult = Apollo.QueryResult<
  PersonInteractionsQuery,
  PersonInteractionsQueryVariables
>;
export const BusinessInteractionsDocument = gql`
  query businessInteractions($businessId: Int!) {
    businessInteractions(businessId: $businessId) {
      id
      doneAt
    }
  }
`;

/**
 * __useBusinessInteractionsQuery__
 *
 * To run a query within a React component, call `useBusinessInteractionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessInteractionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessInteractionsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useBusinessInteractionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BusinessInteractionsQuery,
    BusinessInteractionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusinessInteractionsQuery, BusinessInteractionsQueryVariables>(
    BusinessInteractionsDocument,
    options,
  );
}
export function useBusinessInteractionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BusinessInteractionsQuery,
    BusinessInteractionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BusinessInteractionsQuery, BusinessInteractionsQueryVariables>(
    BusinessInteractionsDocument,
    options,
  );
}
export type BusinessInteractionsQueryHookResult = ReturnType<typeof useBusinessInteractionsQuery>;
export type BusinessInteractionsLazyQueryHookResult = ReturnType<
  typeof useBusinessInteractionsLazyQuery
>;
export type BusinessInteractionsQueryResult = Apollo.QueryResult<
  BusinessInteractionsQuery,
  BusinessInteractionsQueryVariables
>;
export const ServiceDocument = gql`
  query service($id: Int!) {
    service(id: $id) {
      ...serviceBase
      client {
        ...client
      }
      hours {
        id
        day
        open
        close
      }
      serviceAddresses {
        ...serviceAddresses
      }
      serviceMeta {
        ...serviceMeta
      }
      serviceAttributes {
        ...serviceAttributes
      }
      serviceClients {
        ...serviceClients
      }
      counselingUser {
        ...counselingUser
      }
      counselingMandate {
        id
        counselor {
          ...basePerson
        }
        business {
          id
          name
        }
      }
      topic {
        id
        summary
        name
      }
      tasks {
        id
        taskType
        finishedAt
        user {
          ...user
          person {
            ...userPerson
          }
        }
      }
    }
  }
  ${ServiceBaseFragmentDoc}
  ${ClientFragmentDoc}
  ${ServiceAddressesFragmentDoc}
  ${ServiceMetaFragmentDoc}
  ${ServiceAttributesFragmentDoc}
  ${ServiceClientsFragmentDoc}
  ${CounselingUserFragmentDoc}
  ${BasePersonFragmentDoc}
  ${UserFragmentDoc}
  ${UserPersonFragmentDoc}
`;

/**
 * __useServiceQuery__
 *
 * To run a query within a React component, call `useServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceQuery(
  baseOptions: Apollo.QueryHookOptions<ServiceQuery, ServiceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
}
export function useServiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ServiceQuery, ServiceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
}
export type ServiceQueryHookResult = ReturnType<typeof useServiceQuery>;
export type ServiceLazyQueryHookResult = ReturnType<typeof useServiceLazyQuery>;
export type ServiceQueryResult = Apollo.QueryResult<ServiceQuery, ServiceQueryVariables>;
export const ServicesDocument = gql`
  query services(
    $ids: [Int]
    $topicId: Int
    $statusNot: [ServiceStatus]
    $status: [ServiceStatus]
  ) {
    services(ids: $ids, topicId: $topicId, statusNot: $statusNot, status: $status) {
      ...serviceBase
      problem
      serviceMeta {
        ...serviceMeta
      }
      counselingUser {
        ...counselingUser
      }
      counselingMandate {
        id
        counselor {
          ...basePerson
        }
        business {
          id
          name
        }
      }
    }
  }
  ${ServiceBaseFragmentDoc}
  ${ServiceMetaFragmentDoc}
  ${CounselingUserFragmentDoc}
  ${BasePersonFragmentDoc}
`;

/**
 * __useServicesQuery__
 *
 * To run a query within a React component, call `useServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      topicId: // value for 'topicId'
 *      statusNot: // value for 'statusNot'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useServicesQuery(
  baseOptions?: Apollo.QueryHookOptions<ServicesQuery, ServicesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, options);
}
export function useServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ServicesQuery, ServicesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, options);
}
export type ServicesQueryHookResult = ReturnType<typeof useServicesQuery>;
export type ServicesLazyQueryHookResult = ReturnType<typeof useServicesLazyQuery>;
export type ServicesQueryResult = Apollo.QueryResult<ServicesQuery, ServicesQueryVariables>;
export const AllServicesDocument = gql`
  query allServices($statusNot: [ServiceStatus]) {
    services(statusNot: $statusNot) {
      id
      duedate
      lastActivity
      status
      topic {
        id
        name
      }
      serviceMeta {
        id
        name
      }
      client {
        id
        account {
          id
          name
        }
        person {
          id
          avatarKeys
          flag
          mentalState
          name
        }
      }
      personActivity {
        id
        tasks {
          created
          startedAt
          finishedAt
          taskType
          user {
            ...user
            person {
              ...userPerson
            }
          }
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${UserPersonFragmentDoc}
`;

/**
 * __useAllServicesQuery__
 *
 * To run a query within a React component, call `useAllServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllServicesQuery({
 *   variables: {
 *      statusNot: // value for 'statusNot'
 *   },
 * });
 */
export function useAllServicesQuery(
  baseOptions?: Apollo.QueryHookOptions<AllServicesQuery, AllServicesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllServicesQuery, AllServicesQueryVariables>(AllServicesDocument, options);
}
export function useAllServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllServicesQuery, AllServicesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllServicesQuery, AllServicesQueryVariables>(
    AllServicesDocument,
    options,
  );
}
export type AllServicesQueryHookResult = ReturnType<typeof useAllServicesQuery>;
export type AllServicesLazyQueryHookResult = ReturnType<typeof useAllServicesLazyQuery>;
export type AllServicesQueryResult = Apollo.QueryResult<
  AllServicesQuery,
  AllServicesQueryVariables
>;
export const ServicesByTypeDocument = gql`
  query servicesByType(
    $type: ServiceType!
    $status: [ServiceStatus!]
    $statusNot: [ServiceStatus!]
  ) {
    servicesByType(type: $type, status: $status, statusNot: $statusNot) {
      id
      created
      duedate
      lastActivity
      status
      counselingUser {
        id
        user {
          id
          papershiftWorkingAreas {
            ...papershiftWorkingArea
          }
          person {
            id
            alias
            avatar
            avatarKeys
            picture
            name
          }
        }
      }
      topic {
        id
        name
      }
      serviceMeta {
        id
        name
      }
      client {
        id
        account {
          id
          name
        }
        person {
          id
          avatarKeys
          flag
          mentalState
          name
        }
      }
    }
  }
  ${PapershiftWorkingAreaFragmentDoc}
`;

/**
 * __useServicesByTypeQuery__
 *
 * To run a query within a React component, call `useServicesByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicesByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicesByTypeQuery({
 *   variables: {
 *      type: // value for 'type'
 *      status: // value for 'status'
 *      statusNot: // value for 'statusNot'
 *   },
 * });
 */
export function useServicesByTypeQuery(
  baseOptions: Apollo.QueryHookOptions<ServicesByTypeQuery, ServicesByTypeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServicesByTypeQuery, ServicesByTypeQueryVariables>(
    ServicesByTypeDocument,
    options,
  );
}
export function useServicesByTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ServicesByTypeQuery, ServicesByTypeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServicesByTypeQuery, ServicesByTypeQueryVariables>(
    ServicesByTypeDocument,
    options,
  );
}
export type ServicesByTypeQueryHookResult = ReturnType<typeof useServicesByTypeQuery>;
export type ServicesByTypeLazyQueryHookResult = ReturnType<typeof useServicesByTypeLazyQuery>;
export type ServicesByTypeQueryResult = Apollo.QueryResult<
  ServicesByTypeQuery,
  ServicesByTypeQueryVariables
>;
export const ServiceCategoriesDocument = gql`
  query serviceCategories {
    serviceCategories {
      id
      name
      serviceMetas {
        name
        iconName
        serviceCategoryId
        id
        subscribed
      }
    }
  }
`;

/**
 * __useServiceCategoriesQuery__
 *
 * To run a query within a React component, call `useServiceCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useServiceCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<ServiceCategoriesQuery, ServiceCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServiceCategoriesQuery, ServiceCategoriesQueryVariables>(
    ServiceCategoriesDocument,
    options,
  );
}
export function useServiceCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServiceCategoriesQuery,
    ServiceCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServiceCategoriesQuery, ServiceCategoriesQueryVariables>(
    ServiceCategoriesDocument,
    options,
  );
}
export type ServiceCategoriesQueryHookResult = ReturnType<typeof useServiceCategoriesQuery>;
export type ServiceCategoriesLazyQueryHookResult = ReturnType<typeof useServiceCategoriesLazyQuery>;
export type ServiceCategoriesQueryResult = Apollo.QueryResult<
  ServiceCategoriesQuery,
  ServiceCategoriesQueryVariables
>;
export const RegisteredServicesByAccountIdDocument = gql`
  query registeredServicesByAccountId($id: Int!) {
    registeredServicesByAccountId(id: $id) {
      id
      name
      serviceMetas {
        name
        iconName
        serviceCategoryId
        id
        subscribed
      }
    }
  }
`;

/**
 * __useRegisteredServicesByAccountIdQuery__
 *
 * To run a query within a React component, call `useRegisteredServicesByAccountIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisteredServicesByAccountIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisteredServicesByAccountIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegisteredServicesByAccountIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegisteredServicesByAccountIdQuery,
    RegisteredServicesByAccountIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegisteredServicesByAccountIdQuery,
    RegisteredServicesByAccountIdQueryVariables
  >(RegisteredServicesByAccountIdDocument, options);
}
export function useRegisteredServicesByAccountIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegisteredServicesByAccountIdQuery,
    RegisteredServicesByAccountIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegisteredServicesByAccountIdQuery,
    RegisteredServicesByAccountIdQueryVariables
  >(RegisteredServicesByAccountIdDocument, options);
}
export type RegisteredServicesByAccountIdQueryHookResult = ReturnType<
  typeof useRegisteredServicesByAccountIdQuery
>;
export type RegisteredServicesByAccountIdLazyQueryHookResult = ReturnType<
  typeof useRegisteredServicesByAccountIdLazyQuery
>;
export type RegisteredServicesByAccountIdQueryResult = Apollo.QueryResult<
  RegisteredServicesByAccountIdQuery,
  RegisteredServicesByAccountIdQueryVariables
>;
export const RegisteredServicesByPersonIdDocument = gql`
  query registeredServicesByPersonId($id: Int!) {
    registeredServicesByPersonId(id: $id) {
      id
      name
      serviceMetas {
        name
        iconName
        serviceCategoryId
        id
        subscribed
      }
    }
  }
`;

/**
 * __useRegisteredServicesByPersonIdQuery__
 *
 * To run a query within a React component, call `useRegisteredServicesByPersonIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisteredServicesByPersonIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisteredServicesByPersonIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegisteredServicesByPersonIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegisteredServicesByPersonIdQuery,
    RegisteredServicesByPersonIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegisteredServicesByPersonIdQuery,
    RegisteredServicesByPersonIdQueryVariables
  >(RegisteredServicesByPersonIdDocument, options);
}
export function useRegisteredServicesByPersonIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegisteredServicesByPersonIdQuery,
    RegisteredServicesByPersonIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegisteredServicesByPersonIdQuery,
    RegisteredServicesByPersonIdQueryVariables
  >(RegisteredServicesByPersonIdDocument, options);
}
export type RegisteredServicesByPersonIdQueryHookResult = ReturnType<
  typeof useRegisteredServicesByPersonIdQuery
>;
export type RegisteredServicesByPersonIdLazyQueryHookResult = ReturnType<
  typeof useRegisteredServicesByPersonIdLazyQuery
>;
export type RegisteredServicesByPersonIdQueryResult = Apollo.QueryResult<
  RegisteredServicesByPersonIdQuery,
  RegisteredServicesByPersonIdQueryVariables
>;
export const ServiceMetasDocument = gql`
  query serviceMetas($serviceCategoryId: Int!) {
    serviceMetas(serviceCategoryId: $serviceCategoryId) {
      id
      name
    }
  }
`;

/**
 * __useServiceMetasQuery__
 *
 * To run a query within a React component, call `useServiceMetasQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceMetasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceMetasQuery({
 *   variables: {
 *      serviceCategoryId: // value for 'serviceCategoryId'
 *   },
 * });
 */
export function useServiceMetasQuery(
  baseOptions: Apollo.QueryHookOptions<ServiceMetasQuery, ServiceMetasQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServiceMetasQuery, ServiceMetasQueryVariables>(
    ServiceMetasDocument,
    options,
  );
}
export function useServiceMetasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ServiceMetasQuery, ServiceMetasQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServiceMetasQuery, ServiceMetasQueryVariables>(
    ServiceMetasDocument,
    options,
  );
}
export type ServiceMetasQueryHookResult = ReturnType<typeof useServiceMetasQuery>;
export type ServiceMetasLazyQueryHookResult = ReturnType<typeof useServiceMetasLazyQuery>;
export type ServiceMetasQueryResult = Apollo.QueryResult<
  ServiceMetasQuery,
  ServiceMetasQueryVariables
>;
export const ServiceResearchObjectsDocument = gql`
  query serviceResearchObjects($serviceId: Int!) {
    serviceResearchObjects(serviceId: $serviceId) {
      ...serviceResearchObject
      contactPerson {
        ...businessPerson
      }
      businessObject {
        ...businessObjectData
        address {
          ...address
        }
        email {
          ...email
        }
        persons {
          ...businessPerson
        }
      }
    }
  }
  ${ServiceResearchObjectFragmentDoc}
  ${BusinessPersonFragmentDoc}
  ${BusinessObjectDataFragmentDoc}
  ${AddressFragmentDoc}
  ${EmailFragmentDoc}
`;

/**
 * __useServiceResearchObjectsQuery__
 *
 * To run a query within a React component, call `useServiceResearchObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceResearchObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceResearchObjectsQuery({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *   },
 * });
 */
export function useServiceResearchObjectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ServiceResearchObjectsQuery,
    ServiceResearchObjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServiceResearchObjectsQuery, ServiceResearchObjectsQueryVariables>(
    ServiceResearchObjectsDocument,
    options,
  );
}
export function useServiceResearchObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServiceResearchObjectsQuery,
    ServiceResearchObjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServiceResearchObjectsQuery, ServiceResearchObjectsQueryVariables>(
    ServiceResearchObjectsDocument,
    options,
  );
}
export type ServiceResearchObjectsQueryHookResult = ReturnType<
  typeof useServiceResearchObjectsQuery
>;
export type ServiceResearchObjectsLazyQueryHookResult = ReturnType<
  typeof useServiceResearchObjectsLazyQuery
>;
export type ServiceResearchObjectsQueryResult = Apollo.QueryResult<
  ServiceResearchObjectsQuery,
  ServiceResearchObjectsQueryVariables
>;
export const ServiceResearchObjectDocument = gql`
  query serviceResearchObject($id: Int!, $serviceId: Int!) {
    serviceResearchObject(id: $id, serviceId: $serviceId) {
      ...serviceResearchObject
      contactPerson {
        ...businessPerson
      }
      service {
        id
        serviceAddresses {
          id
          radius
          address {
            ...address
          }
        }
      }
      businessObject {
        ...businessObjectData
        address {
          ...address
        }
        email {
          ...email
        }
        phones {
          ...phone
        }
        hours {
          id
          open
          close
          day
        }
        persons {
          ...businessPerson
        }
      }
    }
  }
  ${ServiceResearchObjectFragmentDoc}
  ${BusinessPersonFragmentDoc}
  ${AddressFragmentDoc}
  ${BusinessObjectDataFragmentDoc}
  ${EmailFragmentDoc}
  ${PhoneFragmentDoc}
`;

/**
 * __useServiceResearchObjectQuery__
 *
 * To run a query within a React component, call `useServiceResearchObjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceResearchObjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceResearchObjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *      serviceId: // value for 'serviceId'
 *   },
 * });
 */
export function useServiceResearchObjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    ServiceResearchObjectQuery,
    ServiceResearchObjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServiceResearchObjectQuery, ServiceResearchObjectQueryVariables>(
    ServiceResearchObjectDocument,
    options,
  );
}
export function useServiceResearchObjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServiceResearchObjectQuery,
    ServiceResearchObjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServiceResearchObjectQuery, ServiceResearchObjectQueryVariables>(
    ServiceResearchObjectDocument,
    options,
  );
}
export type ServiceResearchObjectQueryHookResult = ReturnType<typeof useServiceResearchObjectQuery>;
export type ServiceResearchObjectLazyQueryHookResult = ReturnType<
  typeof useServiceResearchObjectLazyQuery
>;
export type ServiceResearchObjectQueryResult = Apollo.QueryResult<
  ServiceResearchObjectQuery,
  ServiceResearchObjectQueryVariables
>;
export const StatisticGroupsDocument = gql`
  query statisticGroups($type: StatisticGroupTypes!, $clientId: Int!) {
    statisticGroups(type: $type, clientId: $clientId) {
      id
      name
      items {
        id
        name
      }
    }
  }
`;

/**
 * __useStatisticGroupsQuery__
 *
 * To run a query within a React component, call `useStatisticGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticGroupsQuery({
 *   variables: {
 *      type: // value for 'type'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useStatisticGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<StatisticGroupsQuery, StatisticGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StatisticGroupsQuery, StatisticGroupsQueryVariables>(
    StatisticGroupsDocument,
    options,
  );
}
export function useStatisticGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StatisticGroupsQuery, StatisticGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StatisticGroupsQuery, StatisticGroupsQueryVariables>(
    StatisticGroupsDocument,
    options,
  );
}
export type StatisticGroupsQueryHookResult = ReturnType<typeof useStatisticGroupsQuery>;
export type StatisticGroupsLazyQueryHookResult = ReturnType<typeof useStatisticGroupsLazyQuery>;
export type StatisticGroupsQueryResult = Apollo.QueryResult<
  StatisticGroupsQuery,
  StatisticGroupsQueryVariables
>;
export const StatisticItemsByReportDocument = gql`
  query statisticItemsByReport($reportId: Int!) {
    statisticItemsByReport(reportId: $reportId) {
      id
      name
    }
  }
`;

/**
 * __useStatisticItemsByReportQuery__
 *
 * To run a query within a React component, call `useStatisticItemsByReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticItemsByReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticItemsByReportQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useStatisticItemsByReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    StatisticItemsByReportQuery,
    StatisticItemsByReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StatisticItemsByReportQuery, StatisticItemsByReportQueryVariables>(
    StatisticItemsByReportDocument,
    options,
  );
}
export function useStatisticItemsByReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StatisticItemsByReportQuery,
    StatisticItemsByReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StatisticItemsByReportQuery, StatisticItemsByReportQueryVariables>(
    StatisticItemsByReportDocument,
    options,
  );
}
export type StatisticItemsByReportQueryHookResult = ReturnType<
  typeof useStatisticItemsByReportQuery
>;
export type StatisticItemsByReportLazyQueryHookResult = ReturnType<
  typeof useStatisticItemsByReportLazyQuery
>;
export type StatisticItemsByReportQueryResult = Apollo.QueryResult<
  StatisticItemsByReportQuery,
  StatisticItemsByReportQueryVariables
>;
export const TasksDocument = gql`
  query tasks($pool: Boolean, $duedate: String, $withSnoozed: Boolean) {
    tasks(pool: $pool, duedate: $duedate, withSnoozed: $withSnoozed) {
      ...taskExtended
    }
  }
  ${TaskExtendedFragmentDoc}
`;

/**
 * __useTasksQuery__
 *
 * To run a query within a React component, call `useTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksQuery({
 *   variables: {
 *      pool: // value for 'pool'
 *      duedate: // value for 'duedate'
 *      withSnoozed: // value for 'withSnoozed'
 *   },
 * });
 */
export function useTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<TasksQuery, TasksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
}
export function useTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TasksQuery, TasksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
}
export type TasksQueryHookResult = ReturnType<typeof useTasksQuery>;
export type TasksLazyQueryHookResult = ReturnType<typeof useTasksLazyQuery>;
export type TasksQueryResult = Apollo.QueryResult<TasksQuery, TasksQueryVariables>;
export const CurrentTasksDocument = gql`
  query currentTasks($pool: Boolean, $userId: Int, $withSnoozed: Boolean, $unassigned: Boolean) {
    currentTasks(pool: $pool, userId: $userId, withSnoozed: $withSnoozed, unassigned: $unassigned) {
      ...taskExtended
    }
  }
  ${TaskExtendedFragmentDoc}
`;

/**
 * __useCurrentTasksQuery__
 *
 * To run a query within a React component, call `useCurrentTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentTasksQuery({
 *   variables: {
 *      pool: // value for 'pool'
 *      userId: // value for 'userId'
 *      withSnoozed: // value for 'withSnoozed'
 *      unassigned: // value for 'unassigned'
 *   },
 * });
 */
export function useCurrentTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentTasksQuery, CurrentTasksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentTasksQuery, CurrentTasksQueryVariables>(
    CurrentTasksDocument,
    options,
  );
}
export function useCurrentTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentTasksQuery, CurrentTasksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentTasksQuery, CurrentTasksQueryVariables>(
    CurrentTasksDocument,
    options,
  );
}
export type CurrentTasksQueryHookResult = ReturnType<typeof useCurrentTasksQuery>;
export type CurrentTasksLazyQueryHookResult = ReturnType<typeof useCurrentTasksLazyQuery>;
export type CurrentTasksQueryResult = Apollo.QueryResult<
  CurrentTasksQuery,
  CurrentTasksQueryVariables
>;
export const CurrentTaskCountDocument = gql`
  query currentTaskCount(
    $pool: Boolean
    $userId: Int
    $withSnoozed: Boolean
    $unassigned: Boolean
  ) {
    currentTasks(pool: $pool, userId: $userId, withSnoozed: $withSnoozed, unassigned: $unassigned) {
      id
    }
  }
`;

/**
 * __useCurrentTaskCountQuery__
 *
 * To run a query within a React component, call `useCurrentTaskCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentTaskCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentTaskCountQuery({
 *   variables: {
 *      pool: // value for 'pool'
 *      userId: // value for 'userId'
 *      withSnoozed: // value for 'withSnoozed'
 *      unassigned: // value for 'unassigned'
 *   },
 * });
 */
export function useCurrentTaskCountQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentTaskCountQuery, CurrentTaskCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentTaskCountQuery, CurrentTaskCountQueryVariables>(
    CurrentTaskCountDocument,
    options,
  );
}
export function useCurrentTaskCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentTaskCountQuery, CurrentTaskCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentTaskCountQuery, CurrentTaskCountQueryVariables>(
    CurrentTaskCountDocument,
    options,
  );
}
export type CurrentTaskCountQueryHookResult = ReturnType<typeof useCurrentTaskCountQuery>;
export type CurrentTaskCountLazyQueryHookResult = ReturnType<typeof useCurrentTaskCountLazyQuery>;
export type CurrentTaskCountQueryResult = Apollo.QueryResult<
  CurrentTaskCountQuery,
  CurrentTaskCountQueryVariables
>;
export const GetCmReminderTaskDocument = gql`
  query getCMReminderTask($personId: Int!) {
    getCMReminderTask(personId: $personId) {
      ...taskExtended
    }
  }
  ${TaskExtendedFragmentDoc}
`;

/**
 * __useGetCmReminderTaskQuery__
 *
 * To run a query within a React component, call `useGetCmReminderTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCmReminderTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCmReminderTaskQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetCmReminderTaskQuery(
  baseOptions: Apollo.QueryHookOptions<GetCmReminderTaskQuery, GetCmReminderTaskQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCmReminderTaskQuery, GetCmReminderTaskQueryVariables>(
    GetCmReminderTaskDocument,
    options,
  );
}
export function useGetCmReminderTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCmReminderTaskQuery,
    GetCmReminderTaskQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCmReminderTaskQuery, GetCmReminderTaskQueryVariables>(
    GetCmReminderTaskDocument,
    options,
  );
}
export type GetCmReminderTaskQueryHookResult = ReturnType<typeof useGetCmReminderTaskQuery>;
export type GetCmReminderTaskLazyQueryHookResult = ReturnType<typeof useGetCmReminderTaskLazyQuery>;
export type GetCmReminderTaskQueryResult = Apollo.QueryResult<
  GetCmReminderTaskQuery,
  GetCmReminderTaskQueryVariables
>;
export const GetAllowedTeamsDocument = gql`
  query getAllowedTeams {
    getAllowedTeams
  }
`;

/**
 * __useGetAllowedTeamsQuery__
 *
 * To run a query within a React component, call `useGetAllowedTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllowedTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllowedTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllowedTeamsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllowedTeamsQuery, GetAllowedTeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllowedTeamsQuery, GetAllowedTeamsQueryVariables>(
    GetAllowedTeamsDocument,
    options,
  );
}
export function useGetAllowedTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllowedTeamsQuery, GetAllowedTeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllowedTeamsQuery, GetAllowedTeamsQueryVariables>(
    GetAllowedTeamsDocument,
    options,
  );
}
export type GetAllowedTeamsQueryHookResult = ReturnType<typeof useGetAllowedTeamsQuery>;
export type GetAllowedTeamsLazyQueryHookResult = ReturnType<typeof useGetAllowedTeamsLazyQuery>;
export type GetAllowedTeamsQueryResult = Apollo.QueryResult<
  GetAllowedTeamsQuery,
  GetAllowedTeamsQueryVariables
>;
export const GetAllTeamsDocument = gql`
  query getAllTeams {
    getAllTeams
  }
`;

/**
 * __useGetAllTeamsQuery__
 *
 * To run a query within a React component, call `useGetAllTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllTeamsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllTeamsQuery, GetAllTeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllTeamsQuery, GetAllTeamsQueryVariables>(GetAllTeamsDocument, options);
}
export function useGetAllTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllTeamsQuery, GetAllTeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllTeamsQuery, GetAllTeamsQueryVariables>(
    GetAllTeamsDocument,
    options,
  );
}
export type GetAllTeamsQueryHookResult = ReturnType<typeof useGetAllTeamsQuery>;
export type GetAllTeamsLazyQueryHookResult = ReturnType<typeof useGetAllTeamsLazyQuery>;
export type GetAllTeamsQueryResult = Apollo.QueryResult<
  GetAllTeamsQuery,
  GetAllTeamsQueryVariables
>;
export const TopicsFromPersonDocument = gql`
  query topicsFromPerson($personId: Int!) {
    topicsFromPerson(personId: $personId) {
      id
      name
      services {
        id
        serviceMeta {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useTopicsFromPersonQuery__
 *
 * To run a query within a React component, call `useTopicsFromPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicsFromPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicsFromPersonQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useTopicsFromPersonQuery(
  baseOptions: Apollo.QueryHookOptions<TopicsFromPersonQuery, TopicsFromPersonQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TopicsFromPersonQuery, TopicsFromPersonQueryVariables>(
    TopicsFromPersonDocument,
    options,
  );
}
export function useTopicsFromPersonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TopicsFromPersonQuery, TopicsFromPersonQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TopicsFromPersonQuery, TopicsFromPersonQueryVariables>(
    TopicsFromPersonDocument,
    options,
  );
}
export type TopicsFromPersonQueryHookResult = ReturnType<typeof useTopicsFromPersonQuery>;
export type TopicsFromPersonLazyQueryHookResult = ReturnType<typeof useTopicsFromPersonLazyQuery>;
export type TopicsFromPersonQueryResult = Apollo.QueryResult<
  TopicsFromPersonQuery,
  TopicsFromPersonQueryVariables
>;
export const TopicsDocument = gql`
  query topics($clientId: Int!) {
    topics(clientId: $clientId) {
      id
      services {
        id
        status
        serviceMeta {
          id
          iconName
          name
        }
      }
      name
      lastInteraction
    }
  }
`;

/**
 * __useTopicsQuery__
 *
 * To run a query within a React component, call `useTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicsQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useTopicsQuery(
  baseOptions: Apollo.QueryHookOptions<TopicsQuery, TopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TopicsQuery, TopicsQueryVariables>(TopicsDocument, options);
}
export function useTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TopicsQuery, TopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TopicsQuery, TopicsQueryVariables>(TopicsDocument, options);
}
export type TopicsQueryHookResult = ReturnType<typeof useTopicsQuery>;
export type TopicsLazyQueryHookResult = ReturnType<typeof useTopicsLazyQuery>;
export type TopicsQueryResult = Apollo.QueryResult<TopicsQuery, TopicsQueryVariables>;
export const TopicDocument = gql`
  query topic($id: Int!) {
    topic(id: $id) {
      id
      name
      lastInteraction
      firstInteraction
      services {
        id
        status
        duedate
        problem
        openCount
        limitedCount
        suitableCount
        unsuitableCount
        serviceMeta {
          id
          iconName
          name
          type
        }
      }
    }
  }
`;

/**
 * __useTopicQuery__
 *
 * To run a query within a React component, call `useTopicQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTopicQuery(
  baseOptions: Apollo.QueryHookOptions<TopicQuery, TopicQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TopicQuery, TopicQueryVariables>(TopicDocument, options);
}
export function useTopicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TopicQuery, TopicQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TopicQuery, TopicQueryVariables>(TopicDocument, options);
}
export type TopicQueryHookResult = ReturnType<typeof useTopicQuery>;
export type TopicLazyQueryHookResult = ReturnType<typeof useTopicLazyQuery>;
export type TopicQueryResult = Apollo.QueryResult<TopicQuery, TopicQueryVariables>;
export const TopicCrumbDocument = gql`
  query topicCrumb($id: Int!) {
    topic(id: $id) {
      id
      name
      client {
        id
        person {
          ...clientPerson
        }
      }
    }
  }
  ${ClientPersonFragmentDoc}
`;

/**
 * __useTopicCrumbQuery__
 *
 * To run a query within a React component, call `useTopicCrumbQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicCrumbQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicCrumbQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTopicCrumbQuery(
  baseOptions: Apollo.QueryHookOptions<TopicCrumbQuery, TopicCrumbQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TopicCrumbQuery, TopicCrumbQueryVariables>(TopicCrumbDocument, options);
}
export function useTopicCrumbLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TopicCrumbQuery, TopicCrumbQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TopicCrumbQuery, TopicCrumbQueryVariables>(
    TopicCrumbDocument,
    options,
  );
}
export type TopicCrumbQueryHookResult = ReturnType<typeof useTopicCrumbQuery>;
export type TopicCrumbLazyQueryHookResult = ReturnType<typeof useTopicCrumbLazyQuery>;
export type TopicCrumbQueryResult = Apollo.QueryResult<TopicCrumbQuery, TopicCrumbQueryVariables>;
export const UsersDocument = gql`
  query users($teams: [Team!]) {
    users(teams: $teams) {
      ...baseUser
      person {
        ...person
      }
    }
  }
  ${BaseUserFragmentDoc}
  ${PersonFragmentDoc}
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      teams: // value for 'teams'
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export function useUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UserDocument = gql`
  query user($id: Int!) {
    user(id: $id) {
      ...user
      person {
        ...person
      }
      teams
      papershiftWorkingAreas {
        ...papershiftWorkingArea
      }
    }
  }
  ${UserFragmentDoc}
  ${PersonFragmentDoc}
  ${PapershiftWorkingAreaFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UserFromPersonDocument = gql`
  query userFromPerson($personId: Int!) {
    userFromPerson(personId: $personId) {
      ...user
      person {
        ...person
      }
      teams
      papershiftWorkingAreas {
        ...papershiftWorkingArea
      }
    }
  }
  ${UserFragmentDoc}
  ${PersonFragmentDoc}
  ${PapershiftWorkingAreaFragmentDoc}
`;

/**
 * __useUserFromPersonQuery__
 *
 * To run a query within a React component, call `useUserFromPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFromPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFromPersonQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useUserFromPersonQuery(
  baseOptions: Apollo.QueryHookOptions<UserFromPersonQuery, UserFromPersonQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserFromPersonQuery, UserFromPersonQueryVariables>(
    UserFromPersonDocument,
    options,
  );
}
export function useUserFromPersonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserFromPersonQuery, UserFromPersonQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserFromPersonQuery, UserFromPersonQueryVariables>(
    UserFromPersonDocument,
    options,
  );
}
export type UserFromPersonQueryHookResult = ReturnType<typeof useUserFromPersonQuery>;
export type UserFromPersonLazyQueryHookResult = ReturnType<typeof useUserFromPersonLazyQuery>;
export type UserFromPersonQueryResult = Apollo.QueryResult<
  UserFromPersonQuery,
  UserFromPersonQueryVariables
>;
export const UsersWithTasksDocument = gql`
  query usersWithTasks($teams: [Team!]) {
    usersWithTasks(teams: $teams) {
      userId
      personId
      firstName
      lastName
      alias
      title
      tasks {
        id
      }
      appointments {
        id
      }
      todayAppointments {
        id
      }
    }
  }
`;

/**
 * __useUsersWithTasksQuery__
 *
 * To run a query within a React component, call `useUsersWithTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersWithTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersWithTasksQuery({
 *   variables: {
 *      teams: // value for 'teams'
 *   },
 * });
 */
export function useUsersWithTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<UsersWithTasksQuery, UsersWithTasksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersWithTasksQuery, UsersWithTasksQueryVariables>(
    UsersWithTasksDocument,
    options,
  );
}
export function useUsersWithTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersWithTasksQuery, UsersWithTasksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersWithTasksQuery, UsersWithTasksQueryVariables>(
    UsersWithTasksDocument,
    options,
  );
}
export type UsersWithTasksQueryHookResult = ReturnType<typeof useUsersWithTasksQuery>;
export type UsersWithTasksLazyQueryHookResult = ReturnType<typeof useUsersWithTasksLazyQuery>;
export type UsersWithTasksQueryResult = Apollo.QueryResult<
  UsersWithTasksQuery,
  UsersWithTasksQueryVariables
>;
export const GetLatestUserChangeByTypeDocument = gql`
  query getLatestUserChangeByType($targetId: Int, $targetType: String, $type: String) {
    getLatestUserChangeByType(targetId: $targetId, targetType: $targetType, type: $type) {
      updatedAt
      user {
        person {
          firstName
          lastName
          alias
        }
      }
    }
  }
`;

/**
 * __useGetLatestUserChangeByTypeQuery__
 *
 * To run a query within a React component, call `useGetLatestUserChangeByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestUserChangeByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestUserChangeByTypeQuery({
 *   variables: {
 *      targetId: // value for 'targetId'
 *      targetType: // value for 'targetType'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetLatestUserChangeByTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLatestUserChangeByTypeQuery,
    GetLatestUserChangeByTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLatestUserChangeByTypeQuery, GetLatestUserChangeByTypeQueryVariables>(
    GetLatestUserChangeByTypeDocument,
    options,
  );
}
export function useGetLatestUserChangeByTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestUserChangeByTypeQuery,
    GetLatestUserChangeByTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLatestUserChangeByTypeQuery,
    GetLatestUserChangeByTypeQueryVariables
  >(GetLatestUserChangeByTypeDocument, options);
}
export type GetLatestUserChangeByTypeQueryHookResult = ReturnType<
  typeof useGetLatestUserChangeByTypeQuery
>;
export type GetLatestUserChangeByTypeLazyQueryHookResult = ReturnType<
  typeof useGetLatestUserChangeByTypeLazyQuery
>;
export type GetLatestUserChangeByTypeQueryResult = Apollo.QueryResult<
  GetLatestUserChangeByTypeQuery,
  GetLatestUserChangeByTypeQueryVariables
>;
export const AccountSubscriptionDocument = gql`
  subscription accountSubscription($id: Int!) {
    accountSubscription(id: $id) {
      ...account
      accountServiceModules {
        id
        name
        serviceCategoryId
      }
      parent {
        id
        name
        serviceRegion
      }
      business {
        ...businessObjectData
        memo
        address {
          ...address
        }
        email {
          ...email
        }
        persons {
          ...basePerson
        }
        phones {
          ...phone
        }
      }
    }
  }
  ${AccountFragmentDoc}
  ${BusinessObjectDataFragmentDoc}
  ${AddressFragmentDoc}
  ${EmailFragmentDoc}
  ${BasePersonFragmentDoc}
  ${PhoneFragmentDoc}
`;

/**
 * __useAccountSubscriptionSubscription__
 *
 * To run a query within a React component, call `useAccountSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountSubscriptionSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    AccountSubscriptionSubscription,
    AccountSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    AccountSubscriptionSubscription,
    AccountSubscriptionSubscriptionVariables
  >(AccountSubscriptionDocument, options);
}
export type AccountSubscriptionSubscriptionHookResult = ReturnType<
  typeof useAccountSubscriptionSubscription
>;
export type AccountSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  AccountSubscriptionSubscription
>;
export const AppointmentSubscriptionDocument = gql`
  subscription appointmentSubscription($userIds: [Int!]!, $startsAt: String!, $endsAt: String!) {
    appointmentSubscription(userIds: $userIds, startsAt: $startsAt, endsAt: $endsAt) {
      ...appointment
      users {
        ...user
        person {
          ...basePerson
          avatar
          avatarKeys
          mentalState
          picture
        }
      }
      person {
        ...basePerson
        id
        name
      }
    }
  }
  ${AppointmentFragmentDoc}
  ${UserFragmentDoc}
  ${BasePersonFragmentDoc}
`;

/**
 * __useAppointmentSubscriptionSubscription__
 *
 * To run a query within a React component, call `useAppointmentSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentSubscriptionSubscription({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      startsAt: // value for 'startsAt'
 *      endsAt: // value for 'endsAt'
 *   },
 * });
 */
export function useAppointmentSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    AppointmentSubscriptionSubscription,
    AppointmentSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    AppointmentSubscriptionSubscription,
    AppointmentSubscriptionSubscriptionVariables
  >(AppointmentSubscriptionDocument, options);
}
export type AppointmentSubscriptionSubscriptionHookResult = ReturnType<
  typeof useAppointmentSubscriptionSubscription
>;
export type AppointmentSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  AppointmentSubscriptionSubscription
>;
export const AppointmentDailySubscriptionDocument = gql`
  subscription appointmentDailySubscription($startsAt: String!, $endsAt: String!) {
    appointmentDailySubscription(startsAt: $startsAt, endsAt: $endsAt) {
      ...appointment
      users {
        ...user
        person {
          ...basePerson
          avatar
          avatarKeys
          picture
          mentalState
        }
      }
      person {
        ...basePerson
        avatarKeys
        mentalState
      }
    }
  }
  ${AppointmentFragmentDoc}
  ${UserFragmentDoc}
  ${BasePersonFragmentDoc}
`;

/**
 * __useAppointmentDailySubscriptionSubscription__
 *
 * To run a query within a React component, call `useAppointmentDailySubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentDailySubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentDailySubscriptionSubscription({
 *   variables: {
 *      startsAt: // value for 'startsAt'
 *      endsAt: // value for 'endsAt'
 *   },
 * });
 */
export function useAppointmentDailySubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    AppointmentDailySubscriptionSubscription,
    AppointmentDailySubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    AppointmentDailySubscriptionSubscription,
    AppointmentDailySubscriptionSubscriptionVariables
  >(AppointmentDailySubscriptionDocument, options);
}
export type AppointmentDailySubscriptionSubscriptionHookResult = ReturnType<
  typeof useAppointmentDailySubscriptionSubscription
>;
export type AppointmentDailySubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  AppointmentDailySubscriptionSubscription
>;
export const MyAppointmentsSubscriptionDocument = gql`
  subscription myAppointmentsSubscription($userId: Int) {
    myAppointmentsSubscription(userId: $userId) {
      ...appointment
      users {
        ...user
        person {
          ...basePerson
          alias
          avatar
          avatarKeys
          picture
          mentalState
        }
      }
      task {
        id
        finishedAt
        startedAt
      }
      person {
        ...basePerson
        avatarKeys
        mentalState
      }
    }
  }
  ${AppointmentFragmentDoc}
  ${UserFragmentDoc}
  ${BasePersonFragmentDoc}
`;

/**
 * __useMyAppointmentsSubscriptionSubscription__
 *
 * To run a query within a React component, call `useMyAppointmentsSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMyAppointmentsSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAppointmentsSubscriptionSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMyAppointmentsSubscriptionSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    MyAppointmentsSubscriptionSubscription,
    MyAppointmentsSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    MyAppointmentsSubscriptionSubscription,
    MyAppointmentsSubscriptionSubscriptionVariables
  >(MyAppointmentsSubscriptionDocument, options);
}
export type MyAppointmentsSubscriptionSubscriptionHookResult = ReturnType<
  typeof useMyAppointmentsSubscriptionSubscription
>;
export type MyAppointmentsSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  MyAppointmentsSubscriptionSubscription
>;
export const PersonAppointmentsSubscriptionDocument = gql`
  subscription personAppointmentsSubscription($personId: Int!) {
    personAppointmentsSubscription(personId: $personId) {
      ...appointment
      users {
        ...user
        person {
          ...basePerson
          alias
          avatar
          avatarKeys
          picture
          mentalState
        }
      }
      person {
        ...basePerson
        id
        name
      }
    }
  }
  ${AppointmentFragmentDoc}
  ${UserFragmentDoc}
  ${BasePersonFragmentDoc}
`;

/**
 * __usePersonAppointmentsSubscriptionSubscription__
 *
 * To run a query within a React component, call `usePersonAppointmentsSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePersonAppointmentsSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonAppointmentsSubscriptionSubscription({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function usePersonAppointmentsSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    PersonAppointmentsSubscriptionSubscription,
    PersonAppointmentsSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    PersonAppointmentsSubscriptionSubscription,
    PersonAppointmentsSubscriptionSubscriptionVariables
  >(PersonAppointmentsSubscriptionDocument, options);
}
export type PersonAppointmentsSubscriptionSubscriptionHookResult = ReturnType<
  typeof usePersonAppointmentsSubscriptionSubscription
>;
export type PersonAppointmentsSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  PersonAppointmentsSubscriptionSubscription
>;
export const BusinessObjectSubscriptionDocument = gql`
  subscription businessObjectSubscription($id: Int!) {
    businessObjectSubscription(id: $id) {
      ...businessObjectData
      category {
        id
        name
      }
      address {
        ...address
      }
      email {
        ...email
      }
      persons {
        ...businessPerson
      }
      phones {
        ...phone
      }
      hours {
        id
        open
        close
        day
      }
    }
  }
  ${BusinessObjectDataFragmentDoc}
  ${AddressFragmentDoc}
  ${EmailFragmentDoc}
  ${BusinessPersonFragmentDoc}
  ${PhoneFragmentDoc}
`;

/**
 * __useBusinessObjectSubscriptionSubscription__
 *
 * To run a query within a React component, call `useBusinessObjectSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBusinessObjectSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessObjectSubscriptionSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBusinessObjectSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    BusinessObjectSubscriptionSubscription,
    BusinessObjectSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    BusinessObjectSubscriptionSubscription,
    BusinessObjectSubscriptionSubscriptionVariables
  >(BusinessObjectSubscriptionDocument, options);
}
export type BusinessObjectSubscriptionSubscriptionHookResult = ReturnType<
  typeof useBusinessObjectSubscriptionSubscription
>;
export type BusinessObjectSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  BusinessObjectSubscriptionSubscription
>;
export const BusinessUnitsSubscriptionDocument = gql`
  subscription businessUnitsSubscription($accountId: Int!) {
    businessUnitsSubscription(accountId: $accountId) {
      id
      name
    }
  }
`;

/**
 * __useBusinessUnitsSubscriptionSubscription__
 *
 * To run a query within a React component, call `useBusinessUnitsSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBusinessUnitsSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessUnitsSubscriptionSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useBusinessUnitsSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    BusinessUnitsSubscriptionSubscription,
    BusinessUnitsSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    BusinessUnitsSubscriptionSubscription,
    BusinessUnitsSubscriptionSubscriptionVariables
  >(BusinessUnitsSubscriptionDocument, options);
}
export type BusinessUnitsSubscriptionSubscriptionHookResult = ReturnType<
  typeof useBusinessUnitsSubscriptionSubscription
>;
export type BusinessUnitsSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  BusinessUnitsSubscriptionSubscription
>;
export const PendingCallsSubscriptionDocument = gql`
  subscription pendingCallsSubscription {
    pendingCallsSubscription {
      id
      readableNumber
      accountId
      status
      callType
      personActivityId
      personActivity {
        id
        activityType
        isActive
      }
      person {
        id
        name
      }
    }
  }
`;

/**
 * __usePendingCallsSubscriptionSubscription__
 *
 * To run a query within a React component, call `usePendingCallsSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePendingCallsSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingCallsSubscriptionSubscription({
 *   variables: {
 *   },
 * });
 */
export function usePendingCallsSubscriptionSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    PendingCallsSubscriptionSubscription,
    PendingCallsSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    PendingCallsSubscriptionSubscription,
    PendingCallsSubscriptionSubscriptionVariables
  >(PendingCallsSubscriptionDocument, options);
}
export type PendingCallsSubscriptionSubscriptionHookResult = ReturnType<
  typeof usePendingCallsSubscriptionSubscription
>;
export type PendingCallsSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  PendingCallsSubscriptionSubscription
>;
export const CaseManagementReviewSubscriptionDocument = gql`
  subscription caseManagementReviewSubscription($personId: Int!) {
    caseManagementReviewSubscription(personId: $personId) {
      id
      caseManagementReviewAttributes {
        id
        attributeValue
        lastUpdate
        user {
          ...user
          papershiftWorkingAreas {
            ...papershiftWorkingArea
          }
          person {
            id
            alias
            name
            avatar
            avatarKeys
            picture
          }
        }
        clientReviewAttribute {
          id
          name
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${PapershiftWorkingAreaFragmentDoc}
`;

/**
 * __useCaseManagementReviewSubscriptionSubscription__
 *
 * To run a query within a React component, call `useCaseManagementReviewSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCaseManagementReviewSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseManagementReviewSubscriptionSubscription({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useCaseManagementReviewSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    CaseManagementReviewSubscriptionSubscription,
    CaseManagementReviewSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CaseManagementReviewSubscriptionSubscription,
    CaseManagementReviewSubscriptionSubscriptionVariables
  >(CaseManagementReviewSubscriptionDocument, options);
}
export type CaseManagementReviewSubscriptionSubscriptionHookResult = ReturnType<
  typeof useCaseManagementReviewSubscriptionSubscription
>;
export type CaseManagementReviewSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  CaseManagementReviewSubscriptionSubscription
>;
export const CaseManagementReviewsSubscriptionDocument = gql`
  subscription caseManagementReviewsSubscription(
    $includeValues: [String!]
    $clientReviewAttributeId: Int
  ) {
    caseManagementReviewsSubscription(includeValues: $includeValues) {
      id
      lastUpdate
      caseManagementReviewAttributes(clientReviewAttributeId: $clientReviewAttributeId) {
        id
        attributeValue
        lastUpdate
      }
      caseManager {
        user {
          ...user
          papershiftWorkingAreas {
            ...papershiftWorkingArea
          }
          person {
            id
            alias
            name
            avatar
            avatarKeys
            picture
          }
        }
      }
      client {
        id
        account {
          id
          name
        }
        person {
          id
          flag
          name
          mentalState
          vip
          avatarKeys
        }
        topics {
          id
          name
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${PapershiftWorkingAreaFragmentDoc}
`;

/**
 * __useCaseManagementReviewsSubscriptionSubscription__
 *
 * To run a query within a React component, call `useCaseManagementReviewsSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCaseManagementReviewsSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseManagementReviewsSubscriptionSubscription({
 *   variables: {
 *      includeValues: // value for 'includeValues'
 *      clientReviewAttributeId: // value for 'clientReviewAttributeId'
 *   },
 * });
 */
export function useCaseManagementReviewsSubscriptionSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    CaseManagementReviewsSubscriptionSubscription,
    CaseManagementReviewsSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CaseManagementReviewsSubscriptionSubscription,
    CaseManagementReviewsSubscriptionSubscriptionVariables
  >(CaseManagementReviewsSubscriptionDocument, options);
}
export type CaseManagementReviewsSubscriptionSubscriptionHookResult = ReturnType<
  typeof useCaseManagementReviewsSubscriptionSubscription
>;
export type CaseManagementReviewsSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  CaseManagementReviewsSubscriptionSubscription
>;
export const CaseManagementReviewAttributeSubscriptionDocument = gql`
  subscription caseManagementReviewAttributeSubscription(
    $caseManagementReviewId: Int!
    $clientReviewAttributeId: Int!
  ) {
    caseManagementReviewAttributeSubscription(
      caseManagementReviewId: $caseManagementReviewId
      clientReviewAttributeId: $clientReviewAttributeId
    ) {
      id
      attributeValue
      lastUpdate
    }
  }
`;

/**
 * __useCaseManagementReviewAttributeSubscriptionSubscription__
 *
 * To run a query within a React component, call `useCaseManagementReviewAttributeSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCaseManagementReviewAttributeSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseManagementReviewAttributeSubscriptionSubscription({
 *   variables: {
 *      caseManagementReviewId: // value for 'caseManagementReviewId'
 *      clientReviewAttributeId: // value for 'clientReviewAttributeId'
 *   },
 * });
 */
export function useCaseManagementReviewAttributeSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    CaseManagementReviewAttributeSubscriptionSubscription,
    CaseManagementReviewAttributeSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CaseManagementReviewAttributeSubscriptionSubscription,
    CaseManagementReviewAttributeSubscriptionSubscriptionVariables
  >(CaseManagementReviewAttributeSubscriptionDocument, options);
}
export type CaseManagementReviewAttributeSubscriptionSubscriptionHookResult = ReturnType<
  typeof useCaseManagementReviewAttributeSubscriptionSubscription
>;
export type CaseManagementReviewAttributeSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  CaseManagementReviewAttributeSubscriptionSubscription
>;
export const CaseManagerSubscriptionDocument = gql`
  subscription caseManagerSubscription($personId: Int!) {
    caseManagerSubscription(personId: $personId) {
      id
      user {
        ...user
        papershiftWorkingAreas {
          ...papershiftWorkingArea
        }
        person {
          id
          alias
          name
          avatar
          avatarKeys
          picture
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${PapershiftWorkingAreaFragmentDoc}
`;

/**
 * __useCaseManagerSubscriptionSubscription__
 *
 * To run a query within a React component, call `useCaseManagerSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCaseManagerSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseManagerSubscriptionSubscription({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useCaseManagerSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    CaseManagerSubscriptionSubscription,
    CaseManagerSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CaseManagerSubscriptionSubscription,
    CaseManagerSubscriptionSubscriptionVariables
  >(CaseManagerSubscriptionDocument, options);
}
export type CaseManagerSubscriptionSubscriptionHookResult = ReturnType<
  typeof useCaseManagerSubscriptionSubscription
>;
export type CaseManagerSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  CaseManagerSubscriptionSubscription
>;
export const LastFinishedChatSubscriptionDocument = gql`
  subscription lastFinishedChatSubscription($chats: [LastFinishedChatInput!]!) {
    lastFinishedChatSubscription(chats: $chats) {
      id
      chatUserId
      status
      team
      user {
        id
      }
    }
  }
`;

/**
 * __useLastFinishedChatSubscriptionSubscription__
 *
 * To run a query within a React component, call `useLastFinishedChatSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLastFinishedChatSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastFinishedChatSubscriptionSubscription({
 *   variables: {
 *      chats: // value for 'chats'
 *   },
 * });
 */
export function useLastFinishedChatSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    LastFinishedChatSubscriptionSubscription,
    LastFinishedChatSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    LastFinishedChatSubscriptionSubscription,
    LastFinishedChatSubscriptionSubscriptionVariables
  >(LastFinishedChatSubscriptionDocument, options);
}
export type LastFinishedChatSubscriptionSubscriptionHookResult = ReturnType<
  typeof useLastFinishedChatSubscriptionSubscription
>;
export type LastFinishedChatSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  LastFinishedChatSubscriptionSubscription
>;
export const ActiveChatsSubscriptionDocument = gql`
  subscription activeChatsSubscription {
    activeChatsSubscription {
      id
      chatUserId
      status
      team
      user {
        id
      }
    }
  }
`;

/**
 * __useActiveChatsSubscriptionSubscription__
 *
 * To run a query within a React component, call `useActiveChatsSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useActiveChatsSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveChatsSubscriptionSubscription({
 *   variables: {
 *   },
 * });
 */
export function useActiveChatsSubscriptionSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    ActiveChatsSubscriptionSubscription,
    ActiveChatsSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ActiveChatsSubscriptionSubscription,
    ActiveChatsSubscriptionSubscriptionVariables
  >(ActiveChatsSubscriptionDocument, options);
}
export type ActiveChatsSubscriptionSubscriptionHookResult = ReturnType<
  typeof useActiveChatsSubscriptionSubscription
>;
export type ActiveChatsSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  ActiveChatsSubscriptionSubscription
>;
export const MyStcClientsSubscriptionDocument = gql`
  subscription mySTCClientsSubscription {
    mySTCClientsSubscription {
      id
      person {
        id
        name
        lastActivity
        flag
        name
        mentalState
        vip
        avatarKeys
      }
    }
  }
`;

/**
 * __useMyStcClientsSubscriptionSubscription__
 *
 * To run a query within a React component, call `useMyStcClientsSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMyStcClientsSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyStcClientsSubscriptionSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMyStcClientsSubscriptionSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    MyStcClientsSubscriptionSubscription,
    MyStcClientsSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    MyStcClientsSubscriptionSubscription,
    MyStcClientsSubscriptionSubscriptionVariables
  >(MyStcClientsSubscriptionDocument, options);
}
export type MyStcClientsSubscriptionSubscriptionHookResult = ReturnType<
  typeof useMyStcClientsSubscriptionSubscription
>;
export type MyStcClientsSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  MyStcClientsSubscriptionSubscription
>;
export const GetStcClientsSubscriptionDocument = gql`
  subscription getSTCClientsSubscription($userId: Int!) {
    getSTCClientsSubscription(userId: $userId) {
      id
      person {
        id
        name
        lastActivity
        flag
        name
        mentalState
        vip
        avatarKeys
      }
    }
  }
`;

/**
 * __useGetStcClientsSubscriptionSubscription__
 *
 * To run a query within a React component, call `useGetStcClientsSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetStcClientsSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStcClientsSubscriptionSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetStcClientsSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    GetStcClientsSubscriptionSubscription,
    GetStcClientsSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetStcClientsSubscriptionSubscription,
    GetStcClientsSubscriptionSubscriptionVariables
  >(GetStcClientsSubscriptionDocument, options);
}
export type GetStcClientsSubscriptionSubscriptionHookResult = ReturnType<
  typeof useGetStcClientsSubscriptionSubscription
>;
export type GetStcClientsSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  GetStcClientsSubscriptionSubscription
>;
export const MyCmClientsSubscriptionDocument = gql`
  subscription myCMClientsSubscription {
    myCMClientsSubscription {
      id
      person {
        id
        name
        lastActivity
        flag
        name
        mentalState
        vip
        avatarKeys
      }
    }
  }
`;

/**
 * __useMyCmClientsSubscriptionSubscription__
 *
 * To run a query within a React component, call `useMyCmClientsSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMyCmClientsSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCmClientsSubscriptionSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMyCmClientsSubscriptionSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    MyCmClientsSubscriptionSubscription,
    MyCmClientsSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    MyCmClientsSubscriptionSubscription,
    MyCmClientsSubscriptionSubscriptionVariables
  >(MyCmClientsSubscriptionDocument, options);
}
export type MyCmClientsSubscriptionSubscriptionHookResult = ReturnType<
  typeof useMyCmClientsSubscriptionSubscription
>;
export type MyCmClientsSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  MyCmClientsSubscriptionSubscription
>;
export const GetCmClientsSubscriptionDocument = gql`
  subscription getCMClientsSubscription($userId: Int!) {
    getCMClientsSubscription(userId: $userId) {
      id
      person {
        id
        name
        lastActivity
        flag
        name
        mentalState
        vip
        avatarKeys
      }
    }
  }
`;

/**
 * __useGetCmClientsSubscriptionSubscription__
 *
 * To run a query within a React component, call `useGetCmClientsSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetCmClientsSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCmClientsSubscriptionSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetCmClientsSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    GetCmClientsSubscriptionSubscription,
    GetCmClientsSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetCmClientsSubscriptionSubscription,
    GetCmClientsSubscriptionSubscriptionVariables
  >(GetCmClientsSubscriptionDocument, options);
}
export type GetCmClientsSubscriptionSubscriptionHookResult = ReturnType<
  typeof useGetCmClientsSubscriptionSubscription
>;
export type GetCmClientsSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  GetCmClientsSubscriptionSubscription
>;
export const RiskHistorySubscriptionDocument = gql`
  subscription riskHistorySubscription($personId: Int!) {
    riskHistorySubscription(personId: $personId) {
      id
      attributeValue
      start
      lastUpdate
      clientReviewAttribute {
        id
      }
      user {
        id
        person {
          id
          alias
          name
          avatar
          avatarKeys
          picture
        }
      }
    }
  }
`;

/**
 * __useRiskHistorySubscriptionSubscription__
 *
 * To run a query within a React component, call `useRiskHistorySubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRiskHistorySubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRiskHistorySubscriptionSubscription({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useRiskHistorySubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    RiskHistorySubscriptionSubscription,
    RiskHistorySubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    RiskHistorySubscriptionSubscription,
    RiskHistorySubscriptionSubscriptionVariables
  >(RiskHistorySubscriptionDocument, options);
}
export type RiskHistorySubscriptionSubscriptionHookResult = ReturnType<
  typeof useRiskHistorySubscriptionSubscription
>;
export type RiskHistorySubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  RiskHistorySubscriptionSubscription
>;
export const AllMandatesSubscriptionDocument = gql`
  subscription allMandatesSubscription($active: Boolean!) {
    allMandatesSubscription(active: $active) {
      id
      lastActivity
      referenceNumber
      counselor {
        id
        name
        avatar
        avatarKeys
        picture
        businesses {
          id
          name
          address {
            id
            type
            street
            city
            zip
          }
        }
      }
      service {
        id
        topic {
          id
          name
        }
        client {
          id
          account {
            id
            name
          }
          person {
            id
            flag
            name
            mentalState
            avatarKeys
          }
        }
      }
    }
  }
`;

/**
 * __useAllMandatesSubscriptionSubscription__
 *
 * To run a query within a React component, call `useAllMandatesSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAllMandatesSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMandatesSubscriptionSubscription({
 *   variables: {
 *      active: // value for 'active'
 *   },
 * });
 */
export function useAllMandatesSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    AllMandatesSubscriptionSubscription,
    AllMandatesSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    AllMandatesSubscriptionSubscription,
    AllMandatesSubscriptionSubscriptionVariables
  >(AllMandatesSubscriptionDocument, options);
}
export type AllMandatesSubscriptionSubscriptionHookResult = ReturnType<
  typeof useAllMandatesSubscriptionSubscription
>;
export type AllMandatesSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  AllMandatesSubscriptionSubscription
>;
export const DepartmentsSubscriptionDocument = gql`
  subscription departmentsSubscription($accountId: Int!) {
    departmentsSubscription(accountId: $accountId) {
      id
      accountId
      name
      main
    }
  }
`;

/**
 * __useDepartmentsSubscriptionSubscription__
 *
 * To run a query within a React component, call `useDepartmentsSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentsSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentsSubscriptionSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useDepartmentsSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    DepartmentsSubscriptionSubscription,
    DepartmentsSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    DepartmentsSubscriptionSubscription,
    DepartmentsSubscriptionSubscriptionVariables
  >(DepartmentsSubscriptionDocument, options);
}
export type DepartmentsSubscriptionSubscriptionHookResult = ReturnType<
  typeof useDepartmentsSubscriptionSubscription
>;
export type DepartmentsSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  DepartmentsSubscriptionSubscription
>;
export const GetDriveDocumentsSubscriptionDocument = gql`
  subscription getDriveDocumentsSubscription($personIds: [Int!]!) {
    getDriveDocumentsSubscription(personIds: $personIds) {
      id
      title
      link
      user {
        id
        name
      }
      created
    }
  }
`;

/**
 * __useGetDriveDocumentsSubscriptionSubscription__
 *
 * To run a query within a React component, call `useGetDriveDocumentsSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetDriveDocumentsSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriveDocumentsSubscriptionSubscription({
 *   variables: {
 *      personIds: // value for 'personIds'
 *   },
 * });
 */
export function useGetDriveDocumentsSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    GetDriveDocumentsSubscriptionSubscription,
    GetDriveDocumentsSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetDriveDocumentsSubscriptionSubscription,
    GetDriveDocumentsSubscriptionSubscriptionVariables
  >(GetDriveDocumentsSubscriptionDocument, options);
}
export type GetDriveDocumentsSubscriptionSubscriptionHookResult = ReturnType<
  typeof useGetDriveDocumentsSubscriptionSubscription
>;
export type GetDriveDocumentsSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  GetDriveDocumentsSubscriptionSubscription
>;
export const PapershiftShiftsSubscriptionDocument = gql`
  subscription papershiftShiftsSubscription($startsAt: String!, $endsAt: String!) {
    papershiftShiftsSubscription(startsAt: $startsAt, endsAt: $endsAt) {
      ...papershiftShift
      papershiftWorkingArea {
        ...papershiftWorkingArea
        color
      }
      users {
        ...user
        papershiftWorkingAreas {
          ...papershiftWorkingArea
        }
        person {
          id
          alias
          name
          avatar
          avatarKeys
          picture
        }
      }
    }
  }
  ${PapershiftShiftFragmentDoc}
  ${PapershiftWorkingAreaFragmentDoc}
  ${UserFragmentDoc}
`;

/**
 * __usePapershiftShiftsSubscriptionSubscription__
 *
 * To run a query within a React component, call `usePapershiftShiftsSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePapershiftShiftsSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePapershiftShiftsSubscriptionSubscription({
 *   variables: {
 *      startsAt: // value for 'startsAt'
 *      endsAt: // value for 'endsAt'
 *   },
 * });
 */
export function usePapershiftShiftsSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    PapershiftShiftsSubscriptionSubscription,
    PapershiftShiftsSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    PapershiftShiftsSubscriptionSubscription,
    PapershiftShiftsSubscriptionSubscriptionVariables
  >(PapershiftShiftsSubscriptionDocument, options);
}
export type PapershiftShiftsSubscriptionSubscriptionHookResult = ReturnType<
  typeof usePapershiftShiftsSubscriptionSubscription
>;
export type PapershiftShiftsSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  PapershiftShiftsSubscriptionSubscription
>;
export const PersonSubscriptionDocument = gql`
  subscription personSubscription($id: Int!) {
    personSubscription(id: $id) {
      ...person
      emails {
        ...email
      }
      addresses {
        ...address
      }
      phones {
        ...phone
      }
      relations {
        id
        relationship
        isEmergency
        isHousehold
        passivePerson {
          ...person
          client {
            id
          }
          addresses {
            ...address
          }
          phones {
            ...phone
          }
        }
      }
      client {
        id
        clientType
        departmentId
        department {
          name
          id
          main
        }
        job
        language
        chatUserId
        chatAlias
        vip
        healthInsuranceCompany
        sourceOfInformation
        assistanceId
        yearOfEntry
        businessUnit {
          id
          name
        }
        account {
          id
          name
          isAssistance
          tariff
          type
          validationPattern
          businessUnits {
            id
            name
          }
        }
        topics {
          name
          id
        }
      }
    }
  }
  ${PersonFragmentDoc}
  ${EmailFragmentDoc}
  ${AddressFragmentDoc}
  ${PhoneFragmentDoc}
`;

/**
 * __usePersonSubscriptionSubscription__
 *
 * To run a query within a React component, call `usePersonSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePersonSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonSubscriptionSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePersonSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    PersonSubscriptionSubscription,
    PersonSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    PersonSubscriptionSubscription,
    PersonSubscriptionSubscriptionVariables
  >(PersonSubscriptionDocument, options);
}
export type PersonSubscriptionSubscriptionHookResult = ReturnType<
  typeof usePersonSubscriptionSubscription
>;
export type PersonSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  PersonSubscriptionSubscription
>;
export const PersonsByBusinessSubscriptionDocument = gql`
  subscription personsByBusinessSubscription($businessId: Int!) {
    personsByBusinessSubscription(businessId: $businessId) {
      ...businessPerson
      emails {
        ...email
      }
      addresses {
        ...address
      }
      phones {
        ...phone
      }
    }
  }
  ${BusinessPersonFragmentDoc}
  ${EmailFragmentDoc}
  ${AddressFragmentDoc}
  ${PhoneFragmentDoc}
`;

/**
 * __usePersonsByBusinessSubscriptionSubscription__
 *
 * To run a query within a React component, call `usePersonsByBusinessSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePersonsByBusinessSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonsByBusinessSubscriptionSubscription({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function usePersonsByBusinessSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    PersonsByBusinessSubscriptionSubscription,
    PersonsByBusinessSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    PersonsByBusinessSubscriptionSubscription,
    PersonsByBusinessSubscriptionSubscriptionVariables
  >(PersonsByBusinessSubscriptionDocument, options);
}
export type PersonsByBusinessSubscriptionSubscriptionHookResult = ReturnType<
  typeof usePersonsByBusinessSubscriptionSubscription
>;
export type PersonsByBusinessSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  PersonsByBusinessSubscriptionSubscription
>;
export const PushActivityDocument = gql`
  subscription pushActivity {
    pushActivitySubscription {
      status
      callType
      personActivity {
        __typename
        id
      }
    }
  }
`;

/**
 * __usePushActivitySubscription__
 *
 * To run a query within a React component, call `usePushActivitySubscription` and pass it any options that fit your needs.
 * When your component renders, `usePushActivitySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePushActivitySubscription({
 *   variables: {
 *   },
 * });
 */
export function usePushActivitySubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    PushActivitySubscription,
    PushActivitySubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<PushActivitySubscription, PushActivitySubscriptionVariables>(
    PushActivityDocument,
    options,
  );
}
export type PushActivitySubscriptionHookResult = ReturnType<typeof usePushActivitySubscription>;
export type PushActivitySubscriptionResult = Apollo.SubscriptionResult<PushActivitySubscription>;
export const PersonActivitiesSubscriptionDocument = gql`
  subscription personActivitiesSubscription($personId: Int!, $topicId: Int) {
    personActivitiesSubscription(personId: $personId, topicId: $topicId) {
      ...activity
      author {
        ...author
      }
      conversationReport {
        ...conversationReport
      }
      topic {
        id
        name
      }
      memo {
        ...memo
      }
      service {
        ...serviceBase
        serviceMeta {
          id
          name
          type
        }
      }
      tasks {
        ...task
      }
    }
  }
  ${ActivityFragmentDoc}
  ${AuthorFragmentDoc}
  ${ConversationReportFragmentDoc}
  ${MemoFragmentDoc}
  ${ServiceBaseFragmentDoc}
  ${TaskFragmentDoc}
`;

/**
 * __usePersonActivitiesSubscriptionSubscription__
 *
 * To run a query within a React component, call `usePersonActivitiesSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePersonActivitiesSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonActivitiesSubscriptionSubscription({
 *   variables: {
 *      personId: // value for 'personId'
 *      topicId: // value for 'topicId'
 *   },
 * });
 */
export function usePersonActivitiesSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    PersonActivitiesSubscriptionSubscription,
    PersonActivitiesSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    PersonActivitiesSubscriptionSubscription,
    PersonActivitiesSubscriptionSubscriptionVariables
  >(PersonActivitiesSubscriptionDocument, options);
}
export type PersonActivitiesSubscriptionSubscriptionHookResult = ReturnType<
  typeof usePersonActivitiesSubscriptionSubscription
>;
export type PersonActivitiesSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  PersonActivitiesSubscriptionSubscription
>;
export const AllActivitiesSubscriptionDocument = gql`
  subscription allActivitiesSubscription($personIds: [Int]) {
    allActivitiesSubscription(personIds: $personIds) {
      ...activity
      personId
      author {
        ...author
      }
      memo {
        ...memo
      }
      person {
        name
        businessIds
        id
        yellowFlag
        redFlag
        temporary
        emails {
          id
          email
        }
        client {
          id
          vip
          account {
            id
            name
          }
        }
      }
      serviceResearchObject {
        ...serviceResearchObject
        contactPerson {
          ...basePerson
        }
        businessObject {
          id
          name
        }
        service {
          id
          serviceMeta {
            id
            name
            type
          }
        }
      }
      conversationReport {
        ...conversationReport
      }
      topic {
        id
        name
      }
      tasks {
        ...task
      }
      service {
        ...serviceBase
        serviceMeta {
          id
          name
          type
        }
      }
    }
  }
  ${ActivityFragmentDoc}
  ${AuthorFragmentDoc}
  ${MemoFragmentDoc}
  ${ServiceResearchObjectFragmentDoc}
  ${BasePersonFragmentDoc}
  ${ConversationReportFragmentDoc}
  ${TaskFragmentDoc}
  ${ServiceBaseFragmentDoc}
`;

/**
 * __useAllActivitiesSubscriptionSubscription__
 *
 * To run a query within a React component, call `useAllActivitiesSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAllActivitiesSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllActivitiesSubscriptionSubscription({
 *   variables: {
 *      personIds: // value for 'personIds'
 *   },
 * });
 */
export function useAllActivitiesSubscriptionSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    AllActivitiesSubscriptionSubscription,
    AllActivitiesSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    AllActivitiesSubscriptionSubscription,
    AllActivitiesSubscriptionSubscriptionVariables
  >(AllActivitiesSubscriptionDocument, options);
}
export type AllActivitiesSubscriptionSubscriptionHookResult = ReturnType<
  typeof useAllActivitiesSubscriptionSubscription
>;
export type AllActivitiesSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  AllActivitiesSubscriptionSubscription
>;
export const ActiveActivitiesSubscriptionDocument = gql`
  subscription activeActivitiesSubscription {
    activeActivitiesSubscription {
      id
      isActive
      activityType
      start
      author {
        id
        name
      }
      chat {
        id
        chatUserId
      }
      call {
        id
        accountId
        status
      }
      person {
        ...clientPerson
        temporary
        emails {
          id
          email
        }
        phones {
          ...phone
        }
      }
      service {
        ...serviceBase
        serviceMeta {
          id
          name
          type
        }
      }
      serviceResearchObject {
        ...serviceResearchObject
        contactPerson {
          ...basePerson
        }
        businessObject {
          id
          name
        }
      }
    }
  }
  ${ClientPersonFragmentDoc}
  ${PhoneFragmentDoc}
  ${ServiceBaseFragmentDoc}
  ${ServiceResearchObjectFragmentDoc}
  ${BasePersonFragmentDoc}
`;

/**
 * __useActiveActivitiesSubscriptionSubscription__
 *
 * To run a query within a React component, call `useActiveActivitiesSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useActiveActivitiesSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveActivitiesSubscriptionSubscription({
 *   variables: {
 *   },
 * });
 */
export function useActiveActivitiesSubscriptionSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    ActiveActivitiesSubscriptionSubscription,
    ActiveActivitiesSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ActiveActivitiesSubscriptionSubscription,
    ActiveActivitiesSubscriptionSubscriptionVariables
  >(ActiveActivitiesSubscriptionDocument, options);
}
export type ActiveActivitiesSubscriptionSubscriptionHookResult = ReturnType<
  typeof useActiveActivitiesSubscriptionSubscription
>;
export type ActiveActivitiesSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  ActiveActivitiesSubscriptionSubscription
>;
export const ServiceSubscriptionDocument = gql`
  subscription serviceSubscription($id: Int!) {
    serviceSubscription(id: $id) {
      ...serviceBase
      client {
        ...client
      }
      hours {
        id
        day
        open
        close
      }
      serviceAddresses {
        ...serviceAddresses
      }
      serviceMeta {
        ...serviceMeta
      }
      serviceAttributes {
        ...serviceAttributes
      }
      serviceClients {
        ...serviceClients
      }
      counselingUser {
        ...counselingUser
      }
      counselingMandate {
        id
        counselor {
          ...basePerson
        }
        business {
          id
          name
        }
      }
      topic {
        id
        summary
        name
      }
      tasks {
        id
        taskType
        finishedAt
        user {
          ...user
          person {
            ...userPerson
          }
        }
      }
    }
  }
  ${ServiceBaseFragmentDoc}
  ${ClientFragmentDoc}
  ${ServiceAddressesFragmentDoc}
  ${ServiceMetaFragmentDoc}
  ${ServiceAttributesFragmentDoc}
  ${ServiceClientsFragmentDoc}
  ${CounselingUserFragmentDoc}
  ${BasePersonFragmentDoc}
  ${UserFragmentDoc}
  ${UserPersonFragmentDoc}
`;

/**
 * __useServiceSubscriptionSubscription__
 *
 * To run a query within a React component, call `useServiceSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useServiceSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceSubscriptionSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ServiceSubscriptionSubscription,
    ServiceSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ServiceSubscriptionSubscription,
    ServiceSubscriptionSubscriptionVariables
  >(ServiceSubscriptionDocument, options);
}
export type ServiceSubscriptionSubscriptionHookResult = ReturnType<
  typeof useServiceSubscriptionSubscription
>;
export type ServiceSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  ServiceSubscriptionSubscription
>;
export const ServicesSubscriptionDocument = gql`
  subscription servicesSubscription($statusNot: [ServiceStatus]) {
    servicesSubscription(statusNot: $statusNot) {
      id
      duedate
      lastActivity
      status
      topic {
        id
        name
      }
      serviceMeta {
        id
        name
      }
      client {
        id
        account {
          id
          name
        }
        person {
          id
          avatarKeys
          flag
          mentalState
          name
        }
      }
    }
  }
`;

/**
 * __useServicesSubscriptionSubscription__
 *
 * To run a query within a React component, call `useServicesSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useServicesSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicesSubscriptionSubscription({
 *   variables: {
 *      statusNot: // value for 'statusNot'
 *   },
 * });
 */
export function useServicesSubscriptionSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    ServicesSubscriptionSubscription,
    ServicesSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ServicesSubscriptionSubscription,
    ServicesSubscriptionSubscriptionVariables
  >(ServicesSubscriptionDocument, options);
}
export type ServicesSubscriptionSubscriptionHookResult = ReturnType<
  typeof useServicesSubscriptionSubscription
>;
export type ServicesSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  ServicesSubscriptionSubscription
>;
export const ServicesByTypeSubscriptionDocument = gql`
  subscription servicesByTypeSubscription(
    $type: ServiceType!
    $status: [ServiceStatus!]
    $statusNot: [ServiceStatus!]
  ) {
    servicesByTypeSubscription(type: $type, status: $status, statusNot: $statusNot) {
      id
      created
      duedate
      lastActivity
      status
      counselingUser {
        id
        user {
          id
          papershiftWorkingAreas {
            ...papershiftWorkingArea
          }
          person {
            id
            alias
            avatar
            avatarKeys
            picture
            name
          }
        }
      }
      topic {
        id
        name
      }
      serviceMeta {
        id
        name
      }
      client {
        id
        account {
          id
          name
        }
        person {
          id
          avatarKeys
          flag
          mentalState
          name
        }
      }
    }
  }
  ${PapershiftWorkingAreaFragmentDoc}
`;

/**
 * __useServicesByTypeSubscriptionSubscription__
 *
 * To run a query within a React component, call `useServicesByTypeSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useServicesByTypeSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicesByTypeSubscriptionSubscription({
 *   variables: {
 *      type: // value for 'type'
 *      status: // value for 'status'
 *      statusNot: // value for 'statusNot'
 *   },
 * });
 */
export function useServicesByTypeSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ServicesByTypeSubscriptionSubscription,
    ServicesByTypeSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ServicesByTypeSubscriptionSubscription,
    ServicesByTypeSubscriptionSubscriptionVariables
  >(ServicesByTypeSubscriptionDocument, options);
}
export type ServicesByTypeSubscriptionSubscriptionHookResult = ReturnType<
  typeof useServicesByTypeSubscriptionSubscription
>;
export type ServicesByTypeSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  ServicesByTypeSubscriptionSubscription
>;
export const ServiceResearchObjectSubscriptionDocument = gql`
  subscription serviceResearchObjectSubscription($serviceId: Int!) {
    serviceResearchObjectSubscription(serviceId: $serviceId) {
      ...serviceResearchObject
      service {
        id
        serviceAddresses {
          id
          radius
          address {
            ...address
          }
        }
      }
      contactPerson {
        ...businessPerson
      }
      businessObject {
        ...businessObjectData
        address {
          ...address
        }
        persons {
          ...businessPerson
        }
        email {
          ...email
        }
        phones {
          ...phone
        }
        hours {
          id
          open
          close
          day
        }
      }
    }
  }
  ${ServiceResearchObjectFragmentDoc}
  ${AddressFragmentDoc}
  ${BusinessPersonFragmentDoc}
  ${BusinessObjectDataFragmentDoc}
  ${EmailFragmentDoc}
  ${PhoneFragmentDoc}
`;

/**
 * __useServiceResearchObjectSubscriptionSubscription__
 *
 * To run a query within a React component, call `useServiceResearchObjectSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useServiceResearchObjectSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceResearchObjectSubscriptionSubscription({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *   },
 * });
 */
export function useServiceResearchObjectSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ServiceResearchObjectSubscriptionSubscription,
    ServiceResearchObjectSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ServiceResearchObjectSubscriptionSubscription,
    ServiceResearchObjectSubscriptionSubscriptionVariables
  >(ServiceResearchObjectSubscriptionDocument, options);
}
export type ServiceResearchObjectSubscriptionSubscriptionHookResult = ReturnType<
  typeof useServiceResearchObjectSubscriptionSubscription
>;
export type ServiceResearchObjectSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  ServiceResearchObjectSubscriptionSubscription
>;
export const StatisticItemsByReportSubscriptionDocument = gql`
  subscription statisticItemsByReportSubscription($reportId: Int!) {
    statisticItemsByReportSubscription(reportId: $reportId) {
      id
      name
    }
  }
`;

/**
 * __useStatisticItemsByReportSubscriptionSubscription__
 *
 * To run a query within a React component, call `useStatisticItemsByReportSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useStatisticItemsByReportSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticItemsByReportSubscriptionSubscription({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useStatisticItemsByReportSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    StatisticItemsByReportSubscriptionSubscription,
    StatisticItemsByReportSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    StatisticItemsByReportSubscriptionSubscription,
    StatisticItemsByReportSubscriptionSubscriptionVariables
  >(StatisticItemsByReportSubscriptionDocument, options);
}
export type StatisticItemsByReportSubscriptionSubscriptionHookResult = ReturnType<
  typeof useStatisticItemsByReportSubscriptionSubscription
>;
export type StatisticItemsByReportSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  StatisticItemsByReportSubscriptionSubscription
>;
export const TaskSubscriptionDocument = gql`
  subscription taskSubscription {
    taskSubscription {
      ...taskExtended
      userPapershiftWorkingAreas {
        id
      }
    }
  }
  ${TaskExtendedFragmentDoc}
`;

/**
 * __useTaskSubscriptionSubscription__
 *
 * To run a query within a React component, call `useTaskSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTaskSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskSubscriptionSubscription({
 *   variables: {
 *   },
 * });
 */
export function useTaskSubscriptionSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    TaskSubscriptionSubscription,
    TaskSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    TaskSubscriptionSubscription,
    TaskSubscriptionSubscriptionVariables
  >(TaskSubscriptionDocument, options);
}
export type TaskSubscriptionSubscriptionHookResult = ReturnType<
  typeof useTaskSubscriptionSubscription
>;
export type TaskSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  TaskSubscriptionSubscription
>;
export const CurrentTasksSubscriptionDocument = gql`
  subscription currentTasksSubscription(
    $pool: Boolean
    $userId: Int
    $withSnoozed: Boolean
    $unassigned: Boolean
  ) {
    currentTasksSubscription(
      pool: $pool
      userId: $userId
      withSnoozed: $withSnoozed
      unassigned: $unassigned
    ) {
      id
      dueDate
      finishedAt
      startedAt
      taskType
      team
      snoozeUntil
      path
      title
      created
      note
      person {
        id
        name
        avatar
        avatarKeys
        mentalState
        yellowFlag
        redFlag
        temporary
        vip
      }
      author {
        id
        name
        avatarKeys
        avatar
        picture
      }
      creator {
        id
        name
        avatarKeys
        avatar
        picture
      }
      topic {
        id
        name
      }
      service {
        id
        serviceMeta {
          id
          name
        }
      }
      report {
        id
        mentalState
        personActivity {
          id
          start
          end
        }
      }
      personActivity {
        id
        activityType
        description
        end
        start
        conversationReport {
          id
          mentalState
        }
        service {
          id
          wizardStatus
          status
          serviceMeta {
            id
            name
            type
          }
        }
        call {
          id
          readableNumber
        }
        memo {
          id
          note
          contact
          status
          topic {
            id
            name
          }
          person {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useCurrentTasksSubscriptionSubscription__
 *
 * To run a query within a React component, call `useCurrentTasksSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCurrentTasksSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentTasksSubscriptionSubscription({
 *   variables: {
 *      pool: // value for 'pool'
 *      userId: // value for 'userId'
 *      withSnoozed: // value for 'withSnoozed'
 *      unassigned: // value for 'unassigned'
 *   },
 * });
 */
export function useCurrentTasksSubscriptionSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    CurrentTasksSubscriptionSubscription,
    CurrentTasksSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CurrentTasksSubscriptionSubscription,
    CurrentTasksSubscriptionSubscriptionVariables
  >(CurrentTasksSubscriptionDocument, options);
}
export type CurrentTasksSubscriptionSubscriptionHookResult = ReturnType<
  typeof useCurrentTasksSubscriptionSubscription
>;
export type CurrentTasksSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  CurrentTasksSubscriptionSubscription
>;
export const CurrentTaskCountSubscriptionDocument = gql`
  subscription currentTaskCountSubscription(
    $pool: Boolean
    $userId: Int
    $withSnoozed: Boolean
    $unassigned: Boolean
  ) {
    currentTasksSubscription(
      pool: $pool
      userId: $userId
      withSnoozed: $withSnoozed
      unassigned: $unassigned
    ) {
      id
    }
  }
`;

/**
 * __useCurrentTaskCountSubscriptionSubscription__
 *
 * To run a query within a React component, call `useCurrentTaskCountSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCurrentTaskCountSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentTaskCountSubscriptionSubscription({
 *   variables: {
 *      pool: // value for 'pool'
 *      userId: // value for 'userId'
 *      withSnoozed: // value for 'withSnoozed'
 *      unassigned: // value for 'unassigned'
 *   },
 * });
 */
export function useCurrentTaskCountSubscriptionSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    CurrentTaskCountSubscriptionSubscription,
    CurrentTaskCountSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CurrentTaskCountSubscriptionSubscription,
    CurrentTaskCountSubscriptionSubscriptionVariables
  >(CurrentTaskCountSubscriptionDocument, options);
}
export type CurrentTaskCountSubscriptionSubscriptionHookResult = ReturnType<
  typeof useCurrentTaskCountSubscriptionSubscription
>;
export type CurrentTaskCountSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  CurrentTaskCountSubscriptionSubscription
>;
export const UserSubscriptionDocument = gql`
  subscription userSubscription($id: Int!) {
    userSubscription(id: $id) {
      ...user
      person {
        ...person
      }
      papershiftWorkingAreas {
        ...papershiftWorkingArea
      }
      teams
    }
  }
  ${UserFragmentDoc}
  ${PersonFragmentDoc}
  ${PapershiftWorkingAreaFragmentDoc}
`;

/**
 * __useUserSubscriptionSubscription__
 *
 * To run a query within a React component, call `useUserSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscriptionSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    UserSubscriptionSubscription,
    UserSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    UserSubscriptionSubscription,
    UserSubscriptionSubscriptionVariables
  >(UserSubscriptionDocument, options);
}
export type UserSubscriptionSubscriptionHookResult = ReturnType<
  typeof useUserSubscriptionSubscription
>;
export type UserSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  UserSubscriptionSubscription
>;
export const UserFromPersonSubscriptionDocument = gql`
  subscription userFromPersonSubscription($personId: Int!) {
    userFromPersonSubscription(personId: $personId) {
      ...user
      person {
        ...person
      }
      papershiftWorkingAreas {
        ...papershiftWorkingArea
      }
      teams
    }
  }
  ${UserFragmentDoc}
  ${PersonFragmentDoc}
  ${PapershiftWorkingAreaFragmentDoc}
`;

/**
 * __useUserFromPersonSubscriptionSubscription__
 *
 * To run a query within a React component, call `useUserFromPersonSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserFromPersonSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFromPersonSubscriptionSubscription({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useUserFromPersonSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    UserFromPersonSubscriptionSubscription,
    UserFromPersonSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    UserFromPersonSubscriptionSubscription,
    UserFromPersonSubscriptionSubscriptionVariables
  >(UserFromPersonSubscriptionDocument, options);
}
export type UserFromPersonSubscriptionSubscriptionHookResult = ReturnType<
  typeof useUserFromPersonSubscriptionSubscription
>;
export type UserFromPersonSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  UserFromPersonSubscriptionSubscription
>;
