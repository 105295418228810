import React, { Fragment } from "react";

import { Link as RouterLink, useHistory } from "react-router-dom";
import { withStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowForward from "@material-ui/icons/ArrowForwardIos";
import { Add } from "@material-ui/icons";
import sortBy from "lodash/sortBy";

import Markup from "../../../atoms/Markup";
import {
  ServiceResearchObjectStatus,
  ServiceResearchObject,
  Service,
} from "../../../../generated/graphql";
import ResearchStatusIconLabel from "../../../molecules/ResearchStatusIconLabel";

import useStyles from "./styles";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:last-child": {
        padding: theme.spacing(0, 2, 0, 0),
      },
    },
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: 14,
      letterSpacing: 0.7,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(3)}px`,
      "&:first-child": {
        width: "45%",
      },
      "&:nth-child(0n+2)": {
        width: "50%",
      },
      "&:nth-child(0n+3)": {
        width: "5%",
      },
    },
    body: {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      color: theme.palette.common.black,
      overflowWrap: "break-word",
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:hover": {
        background: theme.palette.common.white,
        cursor: "pointer",
      },
    },
  }),
)(TableRow);

type TResearchListNoBusiness = {
  items: ServiceResearchObject[];
  service: Service;
  serviceId: number;
};
const ResearchListNoBusiness = (props: TResearchListNoBusiness) => {
  const classes = useStyles();
  const { push } = useHistory();

  // @ts-ignore
  const researchItems: ServiceResearchObject[] = sortBy(props.items, ["descriptor"]);

  const initialIds: number[] = [];

  const idList = Object.values(ServiceResearchObjectStatus).reduce((carry, status) => {
    const ids: number[] = researchItems
      .filter(_ => _.status && ServiceResearchObjectStatus[_.status] === status)
      .map(item => item.id) as number[];

    return [...carry, ...ids];
  }, initialIds);

  if (!researchItems.find(_ => _.id === 0)) {
    researchItems.unshift({
      id: 0,
      note: "",
      status: ServiceResearchObjectStatus.open,
      businessObject: {
        name: "Neues Element",
        url: `/service/${props.serviceId}/research`,
      },
    });
  }

  const renderTableByStatus = (
    itemsByStatus: ServiceResearchObject[],
    idList: number[],
    key: number,
  ) => {
    if (!itemsByStatus.length) {
      return <></>;
    }

    return (
      <div key={`research-item-list-${key}`}>
        {itemsByStatus[0].status && (
          <ResearchStatusIconLabel
            className={classes.tableHeading}
            status={itemsByStatus[0].status}
          />
        )}
        <Table
          key={`table-${itemsByStatus[0].status}`}
          className={classes.table}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Bezeichnung</StyledTableCell>
              <StyledTableCell>Meine Notizen</StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemsByStatus.map((item, idx) => {
              return renderTableRow(item, idx, idList);
            })}
          </TableBody>
        </Table>
      </div>
    );
  };

  const renderTableRow = (item: ServiceResearchObject, idx: number, idList: number[]) => {
    const isNewItemRow = item.status === ServiceResearchObjectStatus.open && idx === 0;

    const newItemUrl = `/service/${props.serviceId}/research`;
    const handleRowClick = () => {
      isNewItemRow
        ? push(newItemUrl)
        : push(`/service/${props.serviceId}/research/${item.id}`, idList);
    };
    return (
      <StyledTableRow key={`researchObject-${item.id}`} onClick={handleRowClick}>
        <StyledTableCell component="th" scope="row">
          <Link onClick={handleRowClick} underline="always" variant="h4">
            {isNewItemRow ? "Neues Recherche Element" : item.descriptor}
          </Link>
        </StyledTableCell>
        <StyledTableCell>
          <Markup value={item.note || ""} />
        </StyledTableCell>
        {isNewItemRow ? (
          <TableCell align="right" classes={{ body: classes.tableCellAddNewItem }}>
            <IconButton
              aria-label="Add new research item"
              color="primary"
              component={RouterLink}
              to={newItemUrl}
              size="small"
            >
              <Add />
            </IconButton>
          </TableCell>
        ) : (
          <StyledTableCell align="right">
            <ArrowForward color="primary" className={classes.arrowForward} />
          </StyledTableCell>
        )}
      </StyledTableRow>
    );
  };

  return (
    <>
      {Object.values(ServiceResearchObjectStatus).map((sbos, key) => {
        return (
          <Fragment key={`renderTableByStatus-${key}`}>
            {renderTableByStatus(
              researchItems.filter(_ => _.status && ServiceResearchObjectStatus[_.status] === sbos),
              idList,
              key,
            )}
          </Fragment>
        );
      })}
    </>
  );
};
export default ResearchListNoBusiness;
