import React from "react";

import { FormikValues, Form } from "formik";

import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import WizardNavigation from "../../molecules/WizardNavigation";

export type TStepCounselingOrLM = {
  backAction: () => void;
  saveInput: (params: any) => any;
  setFieldValue: (field: string, value: any) => void;
  values: FormikValues;
};

const StepCounselingOrLM = ({
  backAction,
  saveInput,
  setFieldValue,
  values,
}: TStepCounselingOrLM) => {
  return (
    <Form
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <RadioGroup
        aria-label="reportType"
        id="reportType"
        name="reportType"
        value={values.reportType}
        style={{
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
        onChange={attributeValue => {
          setFieldValue("reportType", attributeValue.target.value);
          saveInput({ ...values, reportType: attributeValue.target.value });
        }}
      >
        {[
          { label: "Counseling", value: "counseling" },
          { label: "Life Management", value: "lm" },
          { label: "Beides", value: "both" },
        ].map(option => (
          <FormControlLabel
            style={{ marginRight: 0 }}
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
            labelPlacement="end"
          />
        ))}
      </RadioGroup>
      <WizardNavigation>
        <SecondaryButton onClick={backAction}>Zurück</SecondaryButton>
        <PrimaryButton type="submit">Weiter</PrimaryButton>
      </WizardNavigation>
    </Form>
  );
};

export default StepCounselingOrLM;
