import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Markup from "../../atoms/Markup";
import ServiceExtendedInfoDialog from "../../forms/ServiceExtendedInfoDialog";
import DataRow from "../DataRow";
import { Service } from "../../../generated/graphql";

type TServiceExtendedInfo = {
  service: Service;
};

const ServiceExtendedInfo = ({ service }: TServiceExtendedInfo) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  return (
    <>
      <DataRow title="Informationen" editTrigger={toggle}>
        {service.extendedInfo ? (
          <Grid component={Box} mb={4} xs={12} item>
            <Markup value={service.extendedInfo} />
          </Grid>
        ) : (
          <Grid xs={12} item>
            <Box display="flex" my={4} justifyContent="center" alignItems="center">
              <Typography variant="body2">
                Allgemeine Informationen für den Service können hier dokumentiert werden.
              </Typography>
            </Box>
          </Grid>
        )}
      </DataRow>
      <ServiceExtendedInfoDialog service={service} open={open} toggle={toggle} />
    </>
  );
};
export default ServiceExtendedInfo;
