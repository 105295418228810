import React, { useContext } from "react";

import dayjs from "dayjs";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import blocked from "./blocked.svg";
import AppointmentSlot from "./AppointmentSlots";
import { AbsentContext } from "../ShiftRowWrapper";
import { TAvatarUser } from "../UserAvatar";

export type SlotTypes = "shift" | "block" | "appointment";
type TypeProps = { type: SlotTypes };

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    selectedSlot: {
      position: "absolute",
      overflow: "visible",
      left: "8px",
    },
    slot: (props: TypeProps) => {
      return {
        "&>rect:not(.spacing):hover": {
          fill: props.type === "block" ? "rgba(116, 156, 185, 0.3)" : "#b83800",
        },
        "&>rect:not(.spacing)": {},
        cursor: "pointer",
        position: "absolute",
        left: "8px",
      };
    },
  });
});

interface IAppointmentTimeSlots {
  isFirstColumn?: boolean;
  isLastColumn?: boolean;
  onClick: () => void;
  endsAt: dayjs.Dayjs;
  startsAt: dayjs.Dayjs;
  height: number;
  type: SlotTypes;
  user: TAvatarUser;
  width: number;
}

/**
 *  This component displays the appointment and block slots as separate svgs for a shift
 */
const AppointmentTimeSlots = ({
  isFirstColumn = false,
  isLastColumn = false,
  endsAt,
  height,
  onClick,
  startsAt,
  type,
  user,
  width,
}: IAppointmentTimeSlots) => {
  const { isAbsent } = useContext(AbsentContext);
  const classes = useStyles({ type });
  if (!startsAt.isValid() || !endsAt.isValid()) {
    return <></>;
  }

  // Determine index range of shifts
  const startsAtHourIndex = 4 * (startsAt.hour() - 6);
  const startsAtMinuteIndex = startsAt.minute() === 45 ? 3 : startsAt.minute() === 30 ? 2 : startsAt.minute() === 15 ? 1 : 0;
  const endsAtHourIndex = 4 * (endsAt.hour() - 6);
  const endsAtMinuteIndex = endsAt.minute() === 45 ? 3 : endsAt.minute() === 30 ? 2 : endsAt.minute() === 15 ? 1 : 0;

  const startIndex = startsAtHourIndex + startsAtMinuteIndex;
  const endIndex = endsAtHourIndex + endsAtMinuteIndex;

  // No need to display blocked time if absent
  if (isAbsent && type === "block") {
    return null;
  }

  // In case of adding an appointment with endTime < startTime, this prevents crashing the app
  if (endIndex <= startIndex) {
    return <></>;
  }

  // @ts-ignore
  const rects = [...Array(endIndex - startIndex).keys()].map(index => {
    return (
      <React.Fragment key={`${type}-${user?.id}-${index}`}>
        <AppointmentSlot
          allowMultipleAppointment
          isFirstColumn={isFirstColumn}
          isLastColumn={isLastColumn}
          startIndex={startIndex}
          type={type}
          height={height}
          width={width}
          index={index}
          onClick={onClick}
          bandIndicator={4}
        />
        {(startIndex + index) % 4 === 0 && (
          <rect
            className="spacing"
            x="0"
            y={height * index}
            width={width}
            height="1"
            fill="rgba(130, 130, 130, 0.3)"
          />
        )}
      </React.Fragment>
    );
  });

  return (
    <svg
      className={classes.slot}
      style={{
        top: `${height * startIndex}px`,
        backgroundImage: type === "block" ? `url(${blocked})` : undefined,
      }}
      width={width}
      height={height * (endIndex - startIndex)}
      viewBox={`0 0 ${width} ${height * (endIndex - startIndex)}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      {rects}
    </svg>
  );
};
export default AppointmentTimeSlots;
