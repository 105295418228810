import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    man: {
      marginTop: theme.spacing(8),
    },
    taskGroup: {
      marginTop: theme.spacing(3),
      "&:first-child": {
        marginTop: 0,
      },
      "&>div": {
        "&:last-child": {
          "& $taskContentColumn:before": {
            background: theme.palette.grey[400],
            bottom: 0,
            content: '""',
            height: 1,
            left: 0,
            position: "absolute",
            width: "100%",
          },
        },
      },
    },
    task: {
      minHeight: 72,
      "&:hover $actionWrapper": {
        display: "flex",
      },
      "&:hover $taskContentColumn": {
        background: "#fff",
      },
      "&:hover $taskContentColumn > $actionWrapper": {
        display: "flex",
      },
    },
    taskContentColumn: {
      alignItems: "center",
      display: "flex",
      position: "relative",
      padding: theme.spacing(2, 1),
      "&:after": {
        background: theme.palette.grey[400],
        bottom: 0,
        content: '""',
        height: 1,
        left: 0,
        position: "absolute",
        width: "100%",
      },

      "&>a": {
        alignItems: "center",
        display: "flex",
        overflow: "hidden",
        textAlign: "left",
      },
      "&:nth-child(1)": {
        paddingLeft: theme.spacing(3),
      },
      "&:nth-child(3)": {
        paddingRight: theme.spacing(3),
        justifyContent: "space-between",
      },
      "&:nth-child(4)": {},
    },
    taskIconRoot: {
      color: "transparent",
      height: 24,
      width: "auto",
    },
    onelineEllipsis: {
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap" as "nowrap",
    },
    overdue: {
      color: theme.palette.error.main,
    },
    taskTitle: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
    noLinkStyle: {
      textDecoration: "none",
    },
    dueDateChip: {
      marginLeft: theme.spacing(6),
    },
    taskLink: {
      fontWeight: theme.typography.fontWeightMedium,
      cursor: "pointer",
    },
    taskHeadline: {
      textAlign: "left",
    },
    author: {
      flexGrow: 0,
    },
    actionWrapper: {
      background: "#fff",
      height: "calc(100% - 2px)",
      display: "none",
      paddingRight: theme.spacing(2),
      position: "absolute",
      right: 0,
      justifyContent: "flex-end",
      alignItems: "center",
    },
    actionItem: {
      color: theme.palette.primary.main,
      cursor: "pointer",
      height: theme.spacing(3),
      marginLeft: theme.spacing(3),
      width: theme.spacing(3),
      "&:first-child": {
        marginLeft: theme.spacing(3),
      },
    },
    input: {
      display: "none!important",
    },
    appointmentNote: {
      maxHeight: theme.typography.fontSize * 1.43 * 4, // show 4 max lines (fontSize * lineHeight * 4)
      overflow: "hidden",
      textAlign: "left",
      width: "calc(100% - 100px)",
    },
    notificationLink: {
      color: theme.palette.common.white,
      "&:hover": {
        color: theme.palette.secondary.light,
      },
    },
  };
});

export default useStyles;
