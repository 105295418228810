import React, { useState, useEffect } from "react";
import { useParams, useLocation, Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { useDebounce } from "use-debounce";

import { usePersonQuery, PersonActivity } from "../../../generated/graphql";
import { ReportCreateForm } from "../../forms/ReportCreateForm";
import Loader from "../../atoms/Loading";
import { fetchPolicy } from "../../../utils/constants";
import useStyles from "./styles";

export default function ConversationReport() {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { state }: { state: { activity: PersonActivity } } = useLocation();  
  const { data, refetch } = usePersonQuery({
    variables: { id: parseInt(id, 10) },
    fetchPolicy,
  });
  const activity = state?.activity;
  const person = data?.person;

  const [loading, setLoading] = useState(true);

  const [personDebounce] = useDebounce(person, 500);
  useEffect(() => {
    if (personDebounce) {
      setLoading(!!person);
    }
  }, [personDebounce, person]);
  
  return loading && !person ? (
    <Loader />
  ) : person ? (
    !person.temporary ? (
      <Grid container className={classes.content}>
        <Grid item xs={2} xl={3} />
        <Grid item xs={8} xl={6}>
          <ReportCreateForm person={person} activity={activity} refetch={refetch} />
        </Grid>
        <Grid item xs={2} xl={3} />
      </Grid>
    ) : (
      <Redirect to={`/person/${person.id}`} />
    )
  ) : (
    <div>Person konnte nicht geladen werden</div>
  );
}
