import { createTheme } from "@material-ui/core";
import { Overrides as CoreOverrides } from "@material-ui/core/styles/overrides";
import { AutocompleteClassKey } from "@material-ui/lab";
import { CSSProperties } from "@material-ui/styles";

interface Overrides extends CoreOverrides {
  // Define additional lab components here as needed....
  MuiAutocomplete?:
    | Partial<Record<AutocompleteClassKey, CSSProperties | (() => CSSProperties)>>
    | undefined;
}

const overrides: Overrides = {
  MuiInput: {
    underline: {
      "&:before": {
        borderBottom: "1px solid #025879",
      },
    },
  },
  MuiFormLabel: {
    root: {
      color: "#000",
      fontSize: 14,
      letterSpacing: "0.7px",
      lineHeight: "24px",
    },
  },
  MuiFormControlLabel: {
    label: {
      fontSize: 14,
      letterSpacing: "0.7px",
    },
  },
  MuiInputBase: {
    root: {
      "&$disabled": {
        "&>input": {
          color: "rgba(0, 0, 0, 0.38)",
        },
        "& svg": {
          color: "rgba(0, 0, 0, 0.38)",
        },
      },
    },
    input: {
      color: "#025879",
      fontSize: 14,
      letterSpacing: "0.7px",
      height: "auto",
    },
  },
  MuiMenuItem: {
    root: {
      color: "#025879",
      fontSize: 14,
      height: 40,
    },
    gutters: {
      paddingRight: 24,
      paddingLeft: 24,
    },
  },
  MuiMenu: {
    paper: { borderRadius: 10 },
    list: { padding: "16px 0" },
  },

  MuiPaper: {
    root: {
      color: `#242D31`,
      "& > .MuiFormLabel-root": {
        color: "#242D31",
      },
    },
    elevation1: {
      boxShadow: "none",
    },
  },
  MuiExpansionPanel: {
    root: {
      borderColor: "rgba(0, 0, 0, 0.12)",
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderStyle: "solid",
      "&::before": {
        display: "none",
      },
      "&:last-child": {
        borderBottomWidth: 1,
      },
      "&.Mui-expanded": {
        margin: 0,
      },
    },
  },
  MuiInputLabel: {
    shrink: {
      transform: "translate(0, 1.5px)",
    },
  },
  MuiLink: {
    underlineAlways: {
      cursor: "pointer",
      "&:hover": { color: "#047BA9" }, // figma color link hover
    },
  },
  MuiAutocomplete: {
    paper: {
      color: "#242D31",
    },
    // list: { padding: "16px 0" },
    option: {
      fontSize: 14,
      letterSpacing: "0.7px",
      lineHeight: "24px",
    },
  },
  MuiExpansionPanelDetails: {
    root: {
      flexWrap: "wrap",
    },
  },
};

export default createTheme({
  palette: {
    primary: {
      main: "#025879", // figma color Header, Link
      light: "047BA9", // figma color link hover
      dark: "#242D31",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#f2680a", // figma color Primary Button
      light: "#ff9943",
      dark: "#b83800",
      contrastText: "#ffffff",
    },
    success: {
      main: "#10A70D",
    },
    error: {
      main: "#FF0606",
    },
    background: {
      default: "#F9F3E9", // figma color #background
      paper: "#ffffff", // figma color #white
    },
    common: {
      black: "#000",      
    },
    text: {
      primary: "#000",
      secondary: "#596165", // figma color label
    },
  },
  typography: {
    fontFamily: "Nunito",
    fontWeightLight: 300, // actually not used
    fontWeightRegular: 400,
    fontWeightMedium: 600, // semibold in figma who knows when we do a typo change again
    fontWeightBold: 700, // actually not used
    // figma 16/regular
    body1: {
      fontSize: 16,
      letterSpacing: "0.8px",
      lineHeight: "24px",
    },
    // figma 14/regular
    body2: {
      fontSize: 14,
      letterSpacing: "0.7px",
      lineHeight: "24px",
    },
    // figma 12/regular
    caption: {
      fontSize: 12,
      lineHeight: "24px",
      letterSpacing: "0.05em",
    },
    // figma 24/semibold
    h1: {
      fontSize: 24,
      fontWeight: 600,
      letterSpacing: "0.05em",
      lineHeight: "34px",
    },
    // figma 24/regular
    h2: {
      fontSize: 24,
      fontWeight: 400,
      letterSpacing: "0.8px",
      lineHeight: "34px",
    },
    // figma 16 semibold
    h3: {
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: "0.8px",
      lineHeight: "24px",
    },
    // used in ResearchElements as label
    // figma 14/semibold
    h4: {
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: "0.7px",
      lineHeight: "24px",
    },
  },
  overrides,
});
