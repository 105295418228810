import React, { ReactNode, useState } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import NoSsr from "@material-ui/core/NoSsr";

import {
  BusinessObject,
  BusinessObjectsQuery,
  BusinessObjectsDocument,
} from "../../../../generated/graphql";
import { PersonTypes } from "../../../atoms/PersonTypeIcon";

import useStyles, {
  Control,
  ClearIndicator,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  OptionValue,
} from "../components";
import useDebouncedSearch from "../hooks";

type TBusinessObjectSearch = {
  customClass?: string;
  error?: boolean;
  id?: string;
  label?: ReactNode;
  name?: string;
  onChange: (selectedBusinessObject: any) => void;
  onReset?: () => void;
  onCreateOption: (inputValue: string) => void;
  placeholder?: string;
  serviceId?: number;
  tabIndex?: number;
  value?: any;
  allowCreate?: boolean;
};

export const CustomComponents = {
  Control,
  DropdownIndicator: null,
  IndicatorSeparator: null,
  ClearIndicator,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const formatData = (name: string, data?: BusinessObjectsQuery) => {
  return (
    data?.businessObjects?.map((businessObject: BusinessObject) => {
      const label = `${businessObject.name}${
        businessObject.address?.city ? ` (${businessObject.address?.city})` : ""
      }`;
      return {
        value: businessObject.id,
        label,
      };
    }) || []
  );
};

export const formatBusiness = (business?: BusinessObject | null): OptionValue => {
  return {
    path: `/business/${business!.id}`,
    type: PersonTypes.business,
    label: `${business!.name}`,
    name: `${business!.name}`,
    value: business!.id,
  };
};

const BusinessObjectSearch = ({
  customClass,
  error,
  id,
  label,
  name,
  onChange,
  onCreateOption,
  placeholder,
  serviceId,
  tabIndex,
  value,
  allowCreate = true,
  onReset,
}: TBusinessObjectSearch) => {
  const [currentData, setCurrentData] = useState<any>();
  const loadOptions = useDebouncedSearch(
    undefined,
    300,
    {
      query: BusinessObjectsDocument,
      variables: { serviceId, unique: true, withAddress: false },
      fetchPolicy: "network-only", // I do not want to touch this yet, to avoid potential side effetcts
    },
    formatData,
    data => setCurrentData(data),
  );

  const classes = useStyles();

  return (
    <NoSsr>
      <AsyncCreatableSelect
        tabIndex={`${tabIndex || 0}`}
        autoFocus
        autoComplete="off"
        isClearable
        classes={classes}
        error={error}
        components={CustomComponents}
        createOptionPosition={"first"}
        formatCreateLabel={(inputValue: string) => allowCreate ? `“${inputValue}” neu anlegen` : ''}
        id={id}
        loadOptions={loadOptions}
        value={value}
        onChange={(selection: any) => {
          if (!selection && onReset) {
            onReset();
          }
          if (selection?.value && currentData?.businessObjects) {
            const bo = currentData?.businessObjects?.find(
              (b: BusinessObjectsQuery["businessObjects"][0]) => b.id === selection.value,
            );
            onChange(bo);
          }
          if (!selection) {
            onCreateOption("");
          }
        }}
        onCreateOption={onCreateOption}
        placeholder={placeholder}
        textFieldProps={{ label, className: customClass }}
      />
    </NoSsr>
  );
};

export default BusinessObjectSearch;
