import { createContext, useContext } from "react";

import { STEPS, TYPES } from "./reducer";

/**
 *  Provide current state and actions
 */
type PersonCreateProvider = {
  onClose: (path?: string, error?: boolean) => void;
  setStep: (step: STEPS) => void;
  setType: (type: TYPES) => void;
  step: STEPS;
  type: TYPES;
};

export const PersonCreateContext = createContext<PersonCreateProvider | undefined>(undefined);

export const usePersonCreateContext = () => {
  const context = useContext(PersonCreateContext);
  if (context === undefined) {
    throw new Error("usePersonCreateContext must be used within a PersonCreateContext");
  }
  return context;
};
