import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { GridSize } from "@material-ui/core/Grid";
import Markup from "../../atoms/Markup";

import useStyles from "./styles";

type TInfo = {
  title: string;
  value: string;
  markup?: boolean;
  xs?: GridSize;
  md?: GridSize;
  xl?: GridSize;
};

const Info = ({ title, value, xs, md, xl, markup }: TInfo) => {
  const classes = useStyles();

  return (
    <Grid item xs={xs || 4} md={md || 4} xl={xl || 2} className={classes.info}>
      <Typography variant="h4" className={classes.label} color="textSecondary">
        {title}
      </Typography>
      {markup ? <Markup value={value} /> : <Typography variant="body2">{value}</Typography>}
    </Grid>
  );
};

export default Info;
