import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => {
  return {
    root: {
      ...theme.typography.h4,
      height: 24,
      padding: theme.spacing(0, 2),
      color: theme.palette.common.white,
      transition: "opacity ease-in-out 200ms",
    },
    label: {
      padding: theme.spacing(0),
    },
    premium: {
      backgroundColor: "#887ADD",
      "&.clickable:hover, &:active, &:focus": {
        backgroundColor: "#887ADD",
        opacity: 0.8,
      },
    },
    basic: {
      backgroundColor: "#5AA6E2",
      "&.clickable:hover, &:active, &:focus": {
        backgroundColor: "#5AA6E2",
        opacity: 0.8,
      },
    },
    light: {
      backgroundColor: "#86D26B",
      "&.clickable:hover, &:active, &:focus": {
        backgroundColor: "#86D26B",
        opacity: 0.8,
      },
    },
    bordered: {
      border: `1px solid ${theme.palette.grey[400]}`,
    },
    h4: {
      color: "#596165",
    },
    mr12: {
      marginRight: "8px",
      width: "24px",
      height: "24px",
    },
    borderBottom: {
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(0),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
  };
});
