import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    leading: {
      display: "flex",
      flex: "0 0 550px",
    },
    actions: {
      display: "flex",
      flex: "0 0 550px",
      justifyContent: "flex-end",
    },
  };
});

export default useStyles;
