import React from "react";

import { Formik, FormikProps, Field, Form } from "formik";
import { Checkbox } from "formik-material-ui";

import { Checkbox as MuiCheckbox } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { createStyles, Theme, withStyles } from "@material-ui/core";
import { pathOr } from "ramda";

import {
  BusinessObject,
  Service,
  Address,
  Maybe,
  ServiceAddress,
} from "../../../generated/graphql";
import convertDistance from "geolib/es/convertDistance";
import PrimaryButton from "../../atoms/PrimaryButton";

import {
  getAddressDistance,
  isValidAddress,
  buildAddressString,
} from "../../../utils/getAddressDistance";

import useStyles from "./styles";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: 14,
      letterSpacing: 0.7,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(3)}px`,
      "&:first-child": {
        width: "30%",
      },
      "&:nth-child(0n+2)": {
        width: "40%",
      },
      "&:nth-child(0n+3)": {
        width: "30%",
      },
    },
    body: {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      color: theme.palette.common.black,
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
  }),
)(TableCell);

const StyledTableCellEmpty = withStyles((theme: Theme) =>
  createStyles({
    body: {
      borderBottom: 0,
    },
  }),
)(StyledTableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:hover": {
        background: theme.palette.common.white,
        cursor: "pointer",
      },
    },
  }),
)(TableRow);

const StyledFormControl = withStyles((theme: Theme) => ({
  root: {
    marginRight: 0,

    "& .MuiFormControlLabel-label": {
      color: "white",
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "white",
    },
  },
}))(FormControlLabel);

interface ISuggestionListForm {
  businessObjects: BusinessObject[];
  onSubmit: (values: BusinessObject[]) => void;
  service: Service;
}

const SuggestionListForm = ({ businessObjects, onSubmit, service }: ISuggestionListForm) => {
  const initialValues = businessObjects.reduce((acc: any, bo: BusinessObject) => {
    return { ...acc, [`${bo.id}`]: false };
  }, {});
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {props => <TableForm {...props} businessObjects={businessObjects} service={service} />}
    </Formik>
  );
};

interface TableFormProps {
  businessObjects: BusinessObject[];
  service: Service;
}

const TableForm = ({
  businessObjects,
  isSubmitting,
  submitForm,
  service,
  setFieldValue,
  setValues,
  values,
}: TableFormProps & FormikProps<BusinessObject[]>) => {
  const classes = useStyles();

  const getAddressString = (item: BusinessObject, distance: number | undefined) => {
    const businessAddress = pathOr(null, ["address"], item) as Maybe<Address>;

    if (!businessAddress || !isValidAddress(businessAddress)) {
      return "";
    }

    let addressStr = buildAddressString(businessAddress);

    if (distance) {
      addressStr += ` (${convertDistance(distance, "km").toFixed(1)} km)`;
    }
    return addressStr || "";
  };

  const renderTableRow = (item: BusinessObject) => {
    const currentServiceAddress = pathOr(null, ["serviceAddresses", 0], service) as Maybe<
      ServiceAddress
    >;

    const businessAddress = pathOr(null, ["address"], item) as Maybe<Address>;

    let distance;
    if (currentServiceAddress) {
      distance = getAddressDistance(currentServiceAddress.address, businessAddress);
    }

    return (
      <StyledTableRow
        key={`businessObject-${item.id}`}
        onClick={() => item.id && setFieldValue(String(item.id), !values[item.id])}
      >
        <StyledTableCell scope="row">{item.name}</StyledTableCell>
        <StyledTableCell>{item.address ? getAddressString(item, distance) : ""}</StyledTableCell>
        <StyledTableCell align="right">
          <Field
            component={Checkbox}
            name={String(item.id)}
            style={{ padding: 0 }}
            type="checkbox"
            value={item.id && !!values[item.id]}
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const allSelectedChecked =
    Boolean(Object.keys(values).length) &&
    Object.keys(values).filter((key: any) => !values[key]).length === 0;

  const handleChange = (ev: any) => {
    setValues({
      ...Object.keys(values).reduce((acc: any, bo) => ({ ...acc, [bo]: ev.target.checked }), {}),
    });
  };

  return (
    <Form>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Adresse</StyledTableCell>
            <StyledTableCell align="right">
              {Object.keys(values).length > 0 && (
                <StyledFormControl
                  control={
                    <MuiCheckbox
                      className={classes.selectAllCheckbox}
                      checked={allSelectedChecked}
                      onChange={handleChange}
                    />
                  }
                  label="Alle auswählen"
                  labelPlacement="start"
                />
              )}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!businessObjects.length && (
            <StyledTableRow>
              <StyledTableCellEmpty>Es wurden keine Vorschläge gefunden.</StyledTableCellEmpty>
            </StyledTableRow>
          )}
          {businessObjects.map(bo => renderTableRow(bo))}
        </TableBody>
      </Table>
      <Box padding={3} />
      <Grid item xs={12} style={{ textAlign: "right" }}>
        <PrimaryButton
          type="submit"
          disabled={isSubmitting}
          onClick={() => {
            submitForm();
          }}
        >
          Weiter
        </PrimaryButton>{" "}
      </Grid>
    </Form>
  );
};
export default SuggestionListForm;
