import { Field } from "formik";

import withStyles from "@material-ui/styles/withStyles/withStyles";
import { Theme } from "@material-ui/core";
import { TimePicker, KeyboardDatePicker } from "@material-ui/pickers";

export const StyledField = withStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(4, 1, 0),
    width: "100%",
  },
}))(Field);

export const StyledKeyboardDatePicker = withStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(4, 1, 0),
    width: "100%",
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
}))(KeyboardDatePicker);

export const StyledKeyboardDatePickerFitsGrid = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
}))(KeyboardDatePicker);

export const StyledKeyboardTimePicker = withStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(4, 1, 0),
    width: "100%",
  },
}))(TimePicker);
