import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Card from "../Card";
import AddressItem from "../../atoms/Address";
import TarifBadge from "../../molecules/TariffBadge";
import { Person, useGetLatestUserChangeByTypeQuery } from "../../../generated/graphql";
import useStyles from "./styles";
import dayjs from "dayjs";

const PersonalCard: FC<{ person: Person }> = ({ person }) => {
  const classes = useStyles();

  const { push } = useHistory();
  const { data: logData, loading, error } = useGetLatestUserChangeByTypeQuery({
    variables: {
      targetId: person.id,
      targetType: "PERSON",
      type: "PERSON_UPDATE",
    },
    skip: !person.id,
  });
  const columnsWidth = 3;

  const getLanguage = () => {
    if (person.client?.contactLanguage) {
      if (person.client.contactLanguage.code !== "de") {
        return (
          <Grid item xs={columnsWidth}>
            <Typography variant="h4" color="textSecondary" gutterBottom>
              Sprache
            </Typography>
            <Typography variant="body2">{person.client.contactLanguage.name}</Typography>
            <Typography variant="body2">{person.client.language || ""}</Typography>
          </Grid>
        );
      }
    }

    return <></>;
  };

  const constructLog = () => {
    if (!!logData?.getLatestUserChangeByType?.user?.person) {
      const date = dayjs(logData.getLatestUserChangeByType?.updatedAt);
      const person = logData.getLatestUserChangeByType.user.person;
      return (
        <div className={classes.logContainer}>
          <Typography
            variant="body2"
            color="textSecondary"
            gutterBottom
            className={classes.logText}
          >
            {person?.firstName} {person?.lastName}, {dayjs(date).format("DD.MM.YYYY HH:mm")}
          </Typography>
        </div>
      );
    }
    return <></>;
  };

  return (
    <Card
      title="Persönliche Daten"
      log={constructLog()}
      onClick={() => push(`/person/${person.id}/details`)}
    >
      <Grid container>
        {person.client && person.client.account && person.client.account.name && (
          <Grid item xs={columnsWidth}>
            <Typography variant="h4" color="textSecondary" gutterBottom>
              Account
            </Typography>
            <Box pr={1}>
              <TarifBadge account={person.client.account} fetch />
            </Box>
          </Grid>
        )}
        {person.addresses && person.addresses.length > 0 && person.addresses[0] && (
          <Grid item xs={columnsWidth}>
            <Typography variant="h4" color="textSecondary" gutterBottom>
              Adresse
            </Typography>
            <AddressItem address={person.addresses[0]} />
          </Grid>
        )}
        {person.abnormalities && (
          <Grid item xs={columnsWidth}>
            <Typography variant="h4" color="textSecondary" gutterBottom>
              Auffälligkeiten
            </Typography>
            <Typography variant="body2">{person.abnormalities}</Typography>
          </Grid>
        )}
        {getLanguage()}
      </Grid>
    </Card>
  );
};

export default PersonalCard;
