import React, { useState } from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { FieldArray, FormikProps } from "formik";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import getTeamName from "../../../utils/getTeamName";
import { ReactComponent as CheckMark } from "../../assets/checkmark.svg";
import DialogForm from "../../forms/DialogForm";
import { useUpdateTeamsMutation, GetAllowedTeamsQuery, Team } from "../../../generated/graphql";
import { client } from "../../App";

import useStyles from "./TeamsDialog.styles";
import { Typography } from "@material-ui/core";

interface ITeamsDialog {
  allTeams: GetAllowedTeamsQuery["getAllowedTeams"];
  userId: number;
  defaultTeam: Team;
  teams: Team[];
  open: boolean;
  close: () => void;
}

const TeamsDialog = (props: ITeamsDialog) => {
  const classes = useStyles();
  const [minimumTeamSelected, setMinimumTeamSelected] = useState('');
  const [updateTeams] = useUpdateTeamsMutation();
  const errorMessage = 'Es sollte mindestens ein Team ausgewählt werden.';
  console.log(props.teams)
  return (
    <DialogForm
      initialValues={{ teams: props.teams }}
      onSubmit={async values => {
        await updateTeams({
          variables: { teams: values.teams },
          update: (cache, { data }) => {
            if (data) {
              if (data?.updateTeams.length > 0) {
                setMinimumTeamSelected('');
                cache.modify({
                  id: cache.identify({ __typename: "User", id: props.userId }),
                  fields: {
                    teams() {
                      return data.updateTeams;
                    },
                  },
                });
                client.reFetchObservableQueries();
                props.close();
              } else {
                setMinimumTeamSelected(errorMessage);
              }
            }
          },
        });
      }}
      open={props.open}
      onClose={props.close}
      title="Teams auswählen"
    >
      {(
        formProps: FormikProps<{
          teams: Team[];

        }>,
      ) => {
        return (
          <Box>
            <FieldArray name="teams">
              {arrayHelpers => {
                return (
                  <Grid container>
                    {props.allTeams.map(team => {
                      return (
                        <Grid item xs={6} md={4} key={team}>
                          <FormControlLabel
                            className={classes.checkboxWrapper}
                            control={
                              // props.defaultTeam === team ? (
                              //   <Box
                              //     ml={1.75}
                              //     mr={1.5}
                              //     display="flex"
                              //     alignItems="center"
                              //     height={42}
                              //   >
                              //     <CheckMark />
                              //   </Box>
                              // ) : 
                              (
                                <Checkbox
                                  name="teams"
                                  checked={formProps.values.teams.includes(team)}
                                  onChange={event => {
                                    if (event.target.checked) {
                                      setMinimumTeamSelected('');
                                      arrayHelpers.push(team);
                                    } else {
                                      if (formProps.values.teams.length > 1) {
                                        setMinimumTeamSelected('');
                                        arrayHelpers.remove(formProps.values.teams.indexOf(team));
                                      } else {
                                        setMinimumTeamSelected(errorMessage);
                                      }
                                    }
                                  }}
                                />
                              )
                            }
                            label={getTeamName(team)}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                );
              }}
            </FieldArray>
            <Box textAlign="center">
              <Typography variant="h4" color="error">
                {minimumTeamSelected}
              </Typography>
            </Box>
          </Box>
        );
      }}
    </DialogForm>
  );
};

export default TeamsDialog;
