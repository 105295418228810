import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  callButton: {
    minWidth: 140,
    position: "absolute",
    right: 0,
    top: 12,
  },
  autoSelect: {
    marginTop: theme.spacing(2),
    "& .MuiFormControl-root": {},
    "& .MuiInput-underline.Mui-disabled:before": {
      border: "none",
    },
    "& label.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.87)",
    },
    "& input.Mui-disabled": {
      cursor: "text",
      color: `${theme.palette.primary.main}`,
    },
  },
  dialogRoot: {
    padding: `${theme.spacing(6, 11)} !important`,
  },
  question: {
    margin: theme.spacing(13, 0, 15),
    textAlign: "center",
  },
}));
