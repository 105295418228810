import React from "react";

const ContactIcon = () => {
  return (
    <svg width="19" height="39" viewBox="0 0 19 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.125" y="0.757202" width="18.4777" height="37.4857" rx="1" fill="#025879" />
      <rect x="1.28125" y="3.68579" width="16.168" height="31.0429" fill="white" />
      <ellipse cx="9.94001" cy="36.4858" rx="1.15486" ry="1.17143" fill="white" />
      <ellipse cx="6.18715" cy="2.22145" rx="0.288713" ry="0.292857" fill="white" />
      <rect x="7.63281" y="1.92859" width="3.46457" height="0.585714" rx="0.292857" fill="white" />
    </svg>
  );
};

export default ContactIcon;
