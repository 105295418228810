import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  icon: {
    width: "auto",
    height: "100%",
  },
}));

export default useStyles;
