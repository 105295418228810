import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "&>p": {
      margin: 0,
    },
    "&>strong,&>b": {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}));

export default useStyles;
