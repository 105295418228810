import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import AddIcon from "@material-ui/icons/Add";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Person } from "../../../generated/graphql";
import useContractStatus from "../../../hooks/useContractStatus";
import BusinessContactPersonDialog from "../../forms/BusinessContactPersonDialog";
import DocumentDialog from "../../organisms/DocumentDialog";
import SubHeader from "../../organisms/SubHeader";
import Breadcrumb, { NamePathMap } from "../Breadcrumb";
import useStyles from "./styles";

type TBusinessHeader = {
  person?: Person;
  contractExpirationDate?: string;
  pathId?: number | null;
  isClickable?: boolean;
  mapList?: NamePathMap[];
  onClick?: () => void;
  accountExpired?: boolean;
  language?: string;
};

function businessIdFromPath(path: string, fallback: string) {
  const split = path.split("/");
  const businessIndex = split.findIndex(v => v === "business");
  if (businessIndex !== -1 && businessIndex + 1 < split.length) {
    return split[businessIndex + 1];
  }
  return fallback;
}
// different actions for account than business
const BusinessHeader = ({
  mapList,
  pathId,
  person,
  contractExpirationDate,
  accountExpired,
  language,
}: TBusinessHeader) => {
  const classes = useStyles();
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const [openDialog, setOpenDialog] = useState<string | undefined>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const contractExpired = useContractStatus(contractExpirationDate);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const alternativeId =
    pathId?.toString() ||
    (mapList && mapList.length > 0 ? businessIdFromPath(mapList[0].path, id) : id);

  const linkTo = (pathname: string) => {
    push(pathname);
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const email = person?.emails?.length;

  return (
    <SubHeader
      isAccountDeactivated={!!accountExpired || contractExpired}            
      actions={
        <>
          <IconButton
            aria-label="Add"
            className={classes.addLinkButton}
            size="small"
            onClick={handleClick}
          >
            <AddIcon color="primary" />
          </IconButton>
          <Menu
            id="client-action-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => linkTo(`/business/${alternativeId}/memo`)}>
              Gesprächsprotokoll
            </MenuItem>
            {!person && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  setOpenDialog("contact");
                }}
              >
                Ansprechpartner
              </MenuItem>
            )}
            {!!person && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  setOpenDialog("document");
                }}
              >
                Dokument
              </MenuItem>
            )}
            {!!person && (
              <MenuItem
                disabled={!email}
                onClick={() => {
                  handleClose();
                  linkTo(`/business/${alternativeId}/contacts/${person.id}/email`);
                }}
              >
                E-Mail
              </MenuItem>
            )}
          </Menu>
        </>
      }
    >
      <Toolbar disableGutters>
        <Box display="flex" flexGrow="1" alignItems="center" justifyContent="center">
          <Breadcrumb mapList={mapList || []}></Breadcrumb>
        </Box>
      </Toolbar>
      <BusinessContactPersonDialog
        open={openDialog === "contact"}
        toggle={() => setOpenDialog(undefined)}
        businessId={parseInt(alternativeId, 10)}
      />
      {person !== undefined ? (
        <DocumentDialog
          open={openDialog === "document"}
          onClose={() => setOpenDialog(undefined)}
          person={person}
        />
      ) : null}
    </SubHeader>
  );
};

export default BusinessHeader;
