import React, { useState } from "react";

import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import pathOr from "ramda/src/pathOr";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Loader from "../../../../atoms/Loading";
import Avatar from "../../../../atoms/Avatar";
import UserDialog from "../../../../organisms/UserDialog";

import {
  usePapershiftShiftsQuery,
  PapershiftShift,
  PapershiftWorkingArea,
  User,
} from "../../../../../generated/graphql";

function useFetchData(page: number) {
  const startsAt = dayjs()
    .startOf("day")
    .hour(22);
  const endsAt = dayjs()
    .startOf("day")
    .hour(8);

  const { data, loading, error } = usePapershiftShiftsQuery({
    variables: {
      startsAt: startsAt.add(page - 1, "day").toISOString(),
      endsAt: endsAt.add(page, "day").toISOString(),
    },
  });

  return { data, loading, error };
}

const Nightshift = () => {
  const { page } = useParams<{ page: string }>();

  const { data, loading, error } = useFetchData(parseInt(page, 10));

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <></>;
  }

  const shifts: PapershiftShift[] | undefined = pathOr(undefined, ["papershiftShifts"], data || {});

  if (!shifts) {
    return <></>;
  }

  const nightshiftUsers = shifts.map(shift => {
    if (!shift.users) {
      return <></>;
    }

    const papershiftWorkingArea = shift.papershiftWorkingArea;

    if (!papershiftWorkingArea) {
      return <></>;
    }

    return shift.users.map(user => {
      if (!user) {
        return <></>;
      }

      return (
        <NightshiftUser
          key={`${user.id}`}
          user={user}
          papershiftWorkingArea={papershiftWorkingArea}
        />
      );
    });
  });

  return (
    <Box display="flex" alignItems="center">
      {nightshiftUsers}
      <Box width="12px" />
      <Typography color="textPrimary" variant="body2">Nachtschicht</Typography>
    </Box>
  );
};
type TNightshiftUser = {
  papershiftWorkingArea: PapershiftWorkingArea;
  user: User;
};
const NightshiftUser = ({ papershiftWorkingArea, user }: TNightshiftUser) => {
  const [userDialogOpen, setUserDialogOpen] = useState(false);

  return (
    <>
      <Avatar
        widthfactor={5}
        onClick={() => setUserDialogOpen(true)}
        style={{ cursor: "pointer" }}
        src={user.person?.picture || user.person?.avatar || ""}
      >
        {user.person?.avatarKeys}
      </Avatar>
      <UserDialog
        user={user}
        workingArea={papershiftWorkingArea}
        open={userDialogOpen}
        onClose={() => setUserDialogOpen(false)}
      />
    </>
  );
};
export default Nightshift;
