// import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Box from "@material-ui/core/Box";
import Chip, { ChipProps } from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classnames from "classnames";
import React, { useState } from "react";
import {
  Account,
  AccountTariff,
  AccountUsageScope,
  useRegisteredServicesByAccountIdQuery,
} from "../../../generated/graphql";
import { useAccountSubscription } from "../../../hooks/useAccountSubscription";
import { translateTariff, translateType, translateUsageScope } from "../../../utils/accountHelper";
import { fetchPolicy } from "../../../utils/constants";
import Loading from "../../atoms/Loading";
import AccountStatusBadge from "../AccountStatusBadge";
import DefaultDialog from "../DefaultDialog";
import DefaultDialogContent from "../DefaultDialogContent";
import check from "./check.svg";
import cross from "./cross.svg";
import useStyles from "./styles";

/**
 * TariffBadge should be able to show account information in a dialog later
 * for now it is only used in AccountCard with a passed Account
 * but it may contain a query later when account is undefined.
 */
type TTariffBadge = {
  account: Account;
  fetch?: boolean;
};
const TariffBadge = ({ account, fetch = false }: TTariffBadge) => {
  const { account: fetchedAccount } = useAccountSubscription(account.id!, !fetch);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const classes = useStyles();
  const {
    data: subscribedServices,
    loading: loadingServices,
  } = useRegisteredServicesByAccountIdQuery({
    variables: { id: account.id! },
    fetchPolicy,
  });

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };

  const displayServiceDetails = (serviceMetas: any) => {
    const selectedPile = serviceMetas.filter((sm: any) => sm.subscribed);
    const unselectedPile = [] as any[];//serviceMetas.filter((sm: any) => !sm.subscribed);
    return (
      <Box display="flex" flexDirection="column" width="100%">
        {selectedPile.length && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="start"
            width="100%"
            flexWrap="wrap"
          >
            {selectedPile.map((sm: any, index: number) => {
              return (
                <Box
                  key={index}
                  display="flex"
                  flexDirection="row"
                  marginRight="24px"
                  marginBottom="8px"
                >
                  <img src={check} className={classes.mr12} alt="check icon" />
                  <Typography variant="body2">{sm.name}</Typography>
                </Box>
              );
            })}
          </Box>
        )}
        {!!unselectedPile.length && (
          <Box
            marginTop="12px"
            display="flex"
            flexDirection="row"
            justifyContent="start"
            width="100%"
            flexWrap="wrap"
          >
            {unselectedPile.map((sm: any, index: number) => {
              return (
                <Box key={index} display="flex" flexDirection="row" marginRight="24px">
                  <img src={cross} className={classes.mr12} alt="cross icon" />
                  <Typography variant="body2">{sm.name}</Typography>
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    );
  };

  const Accordion = withStyles({
    root: {
      "&$expanded": {
        marginTop: "auto",
        marginBottom: "auto",
        "&> :first-child": {
          borderTop: `1px solid #BDBDBD`,
        },
      },
    },
    expanded: {},
  })(MuiAccordion);

  const fullAccount = fetchedAccount || account;

  return !!fullAccount && fullAccount.tariff ? (
    <>
      {fetch ? (
        <Typography variant="h4" className={classes.h4}>
          <Link onClick={toggle} color="primary" underline="always">
            {fullAccount.name}
          </Link>
        </Typography>
      ) : (
        <Badge account={fullAccount} clickable onClick={toggle} />
      )}

      <DefaultDialog open={open} onClose={toggle} maxWidth="md">
        <DefaultDialogContent>
          <Typography variant="h1">
            {fullAccount.name} {""}
            <AccountStatusBadge contractEnd={fullAccount?.contractEnd} isText={true} />
          </Typography>
          <Typography variant="h4">
            {!!fullAccount?.type ? translateType(fullAccount.type, true) : "-"}
          </Typography>
          <Box my={4}>
            <Grid
              spacing={2}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={4}>
                <Typography variant="h4" className={classes.h4}>
                  Helpline
                </Typography>
                <Typography variant="body2">{fullAccount?.helpline || "-"}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h4" className={classes.h4}>
                  Helpline 2
                </Typography>
                <Typography variant="body2">{fullAccount?.helpline2 || "-"}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h4" className={classes.h4}>
                  Telefonnr. Ausland
                </Typography>
                <Typography variant="body2">{fullAccount?.helplineInternational || "-"}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h4" className={classes.h4}>
                  App-Kennwort
                </Typography>
                <Typography variant="body2">{fullAccount?.chatRegistrationPin || "-"}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h4" className={classes.h4}>
                  Externe Beratungen
                </Typography>
                <Typography variant="body2">
                  {fullAccount?.freeSessions || "keine"} Sitzung
                  {fullAccount?.freeSessions && fullAccount?.freeSessions !== 1 ? "en" : ""}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h4" className={classes.h4}>
                  Interne Beratungen
                </Typography>
                <Typography variant="body2">
                  {fullAccount?.nFreeSTCs || "keine"} Sitzung
                  {fullAccount?.nFreeSTCs && fullAccount?.nFreeSTCs !== 1 ? "en" : ""}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h4" className={classes.h4}>
                  Führungskräftecoaching
                </Typography>
                <Typography variant="body2">
                  {!fullAccount.coaching ? "nicht " : ""}gebucht
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h4" className={classes.h4}>
                  Zugangsberechtigung
                </Typography>
                <Typography variant="body2">
                  {translateUsageScope(fullAccount?.usageScope || AccountUsageScope.employeeOnly)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" className={classes.h4}>
                  Besonderheiten/ Einschränkungen
                </Typography>
                <Typography variant="body2">{fullAccount?.remarks || "-"}</Typography>
              </Grid>
            </Grid>
          </Box>
          {loadingServices ? (
            <Loading />
          ) : (
            <Box className={classes.bordered} display="flex" flexDirection="column" width="100%">
              {subscribedServices?.registeredServicesByAccountId.map(service => {
                let text = "";
                const numberOfSelectedServices =
                  service?.serviceMetas?.filter((sm: any) => sm.subscribed).length || 0;
                if (
                  numberOfSelectedServices > 0 &&
                  numberOfSelectedServices < service?.serviceMetas!.length
                ) {
                  text = "eingeschränkt";
                }
                if (numberOfSelectedServices === service?.serviceMetas?.length) {
                  text = "alle";
                }
                if (numberOfSelectedServices === 0) {
                  text = "keine";
                }

                return (
                  <Accordion
                    expanded={expanded === service.name!}
                    onChange={handleChange(service.name!)}
                    square
                    key={`services-${service.id}-${service.name}`}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`${service.name!}-content`}
                      id={`${service.name!}-header`}
                    >
                      <Box display="flex" flexDirection="row" width="100%">
                        <Typography
                          variant="h4"
                          className={classes.h4}
                          component={Box}
                          width="45%"
                          flexShrink={0}
                        >
                          {service.name}
                        </Typography>

                        <Typography
                          variant="body2"
                          key={`${service.id}-${service?.serviceMetas?.map(m => m?.id).join("-")}`}
                        >
                          {text}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails className={classes.borderBottom}>
                      {displayServiceDetails(service!.serviceMetas!)}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          )}
        </DefaultDialogContent>
      </DefaultDialog>
    </>
  ) : (
    <></>
  );
};

type TBadge = { account: Account; info?: boolean } & ChipProps;
const Badge = ({ account, info, onClick, clickable }: TBadge) => {
  const classes = useStyles();
  return account.tariff ? (
    <Chip
      label={`${info ? `Tarif: ` : ""}${translateTariff(account.tariff)}`}
      clickable={clickable}
      onClick={onClick}
      classes={{ root: classes.root, label: classes.label }}
      className={classnames({
        [classes.premium]: account.tariff === AccountTariff.premium,
        [classes.basic]: account.tariff === AccountTariff.basic,
        [classes.light]: account.tariff === AccountTariff.light,
        clickable: clickable,
      })}
    />
  ) : (
    <></>
  );
};

export default TariffBadge;
