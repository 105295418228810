import * as Yup from "yup";

import isValidURL from "../../../utils/isValidURL";

const base = Yup.object().shape({
  businessObject: Yup.object().shape({
    name: Yup.string().required("Bitte einen Namen angeben."),
    address: Yup.object().shape({
      street: Yup.string(),
      zip: Yup.string(),
      city: Yup.string(),
    }),
  }),
});

const contact = Yup.object().shape({
  businessObject: Yup.object().shape({
    url: Yup.string().test("url", "Bitte überprüfe die angegebene URL.", value =>
      isValidURL(value, true),
    ),
    phones: Yup.array().of(Yup.string()),
    email: Yup.object().shape({
      email: Yup.string().email("Bitte überprüfe die angegebene E-Mail."),
    }),
  }),
});
const times = Yup.object().shape({});

const other = Yup.object().shape({
  businessObject: Yup.object().shape({
    info: Yup.string(),
  }),
});
const notes = Yup.object().shape({
  note: Yup.string(),
});

export const validations = [
  {
    name: "Stammdaten",
    schema: base,
  },
  {
    name: "Kontaktdaten",
    schema: contact,
  },
  {
    name: "Öffnungszeiten",
    schema: times,
  },
  {
    name: "Sonstiges",
    schema: other,
  },
  {
    name: "Meine Notizen",
    schema: notes,
  },
];

export default validations;
