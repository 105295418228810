import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(11),
  },
  headline: {
    textAlign: "center",
    marginBottom: theme.spacing(4),
  },
  subHeadline: {
    fontWeight: theme.typography.fontWeightRegular,
    margin: theme.spacing(4, 0, 2),
    textAlign: "center",
  },
  finishedHeadline: {
    fontWeight: theme.typography.fontWeightMedium,
    margin: theme.spacing(4, 0, 2),
    textAlign: "center",
  },
  dataText: {
    lineHeight: 1.4,
    paddingBottom: theme.spacing(2),
  },
  paddedText: {
    margin: theme.spacing(1.5, 0, 1),
  },
  button: {
    marginTop: theme.spacing(6),
  },
  withRightMargin: {
    marginRight: theme.spacing(2),
  },
  wideButton: {
    margin: theme.spacing(2.5),
    width: theme.spacing(22.5),
  },
  content: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    minHeight: "60vh",
    marginBottom: theme.spacing(11),
  },
  contentLeft: {
    minHeight: "60vh",
    marginBottom: theme.spacing(11),
  },
  input: {
    ...theme.typography.h1,
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontWeight: theme.typography.fontWeightRegular,
    padding: "6px 0 0",
  },
  timeinput: {
    ...theme.typography.h1,
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontWeight: theme.typography.fontWeightRegular,
    padding: 0,
  },
  checkboxInput: {
    alignSelf: "flex-start",
  },
  checkboxLabel: {
    fontSize: 14,
  },
  checkboxColorSecondaryChecked: {
    color: theme.palette.secondary.main,
  },
  checkboxColorSecondary: {
    color: theme.palette.text.secondary,
  },
  checkboxDisabled: {
    color: `${theme.palette.common.black} !important`,
  },
  select: {
    paddingRight: "0 !important",
  },
  datePicker: { width: theme.spacing(13) },
  timePicker: { width: theme.spacing(9) },
  list: {
    width: "100%",
    maxWidth: 900,
    "&>div": {
      "&:last-child": {
        "&:after": {
          background: theme.palette.grey[400],
          bottom: 0,
          content: '""',
          height: 1,
          left: 0,
          position: "absolute" as "absolute",
          width: "100%",
        },
      },
    },
  },
  listItem: {
    alignItems: "center",
    background: "transparent",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1.5, 1, 1.5, 2),
    position: "relative",
    transition: `background ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
    width: "100%",
    "&:before": {
      background: theme.palette.grey[400],
      top: 0,
      content: '""',
      height: 1,
      left: 0,
      position: "absolute",
      width: "100%",
    },
    "&:hover": {
      background: theme.palette.common.white,
    },
    "&>svg": {
      height: 16,
      width: 16,
    },
  },
}));

export default useStyles;
