import { useEffect } from "react";
import {
  useAllMandatesQuery,
  AllMandatesSubscriptionDocument,
  AllMandatesSubscriptionSubscription,
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

export const useAllMandatesSubscription = (active = true) => {
  const { data, loading, subscribeToMore } = useAllMandatesQuery({
    variables: {
      active,
    },
    fetchPolicy,
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore<AllMandatesSubscriptionSubscription>({
      document: AllMandatesSubscriptionDocument,
      variables: { active },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !prev) return prev;

        return {
          ...prev,
          ...{ allMandates: subscriptionData.data.allMandatesSubscription },
        };
      },
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return { mandates: data?.allMandates, loading };
};
