import React, { FC } from "react";

import dayjs from "dayjs";
import * as Yup from "yup";
import DayjsUtils from "@date-io/dayjs";
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";

import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Box from "@material-ui/core/Box";

import { useUpdatePersonMutation } from "../../../../generated/graphql";
import { StyledKeyboardDatePicker } from "../../../atoms/FormControls";
import SecondaryButton from "../../../atoms/SecondaryButton";
import PrimaryButton from "../../../atoms/PrimaryButton";

import { usePersonCreateContext } from "../provider";
import { STEPS } from "../reducer";

const AssistanceAccountAssign: FC = () => {
  const { account, personActivity, setAssistanceId, setStep } = usePersonCreateContext();
  const [updatePerson] = useUpdatePersonMutation();

  let assistanceIdValidation = Yup.string().required("Pflichtangabe");

  if (account?.validationPattern) {
    assistanceIdValidation = assistanceIdValidation.matches(
      new RegExp(account.validationPattern),
      "Ungültiges Format der Versicherungsnummer",
    );
  }

  const validation = Yup.object().shape({
    assistanceId: assistanceIdValidation,
    birthDate: Yup.date().required("Pflichtangabe"),
  });
  // Due to refactoring: https://medium.com/@calebmer/when-to-use-graphql-non-null-fields-4059337f6fc8
  let name = personActivity?.person?.name;

  if (!name) {
    name = "Unbekannt (Fehler)";
  }

  return (
    <Formik
      initialValues={{
        assistanceId: "",
        // Due to refactoring: https://medium.com/@calebmer/when-to-use-graphql-non-null-fields-4059337f6fc8
        birthDate: personActivity.person?.birthDate || dayjs(),
      }}
      onSubmit={async ({ assistanceId, birthDate }, { setSubmitting }) => {
        await updatePerson({
          variables: {
            person: { id: personActivity!.person!.id!, birthDate, ageGuessed: false },
          },
        });

        setAssistanceId(assistanceId);

        setSubmitting(false);
      }}
      validationSchema={validation}
    >
      {props => (
        <>
          <Box width={1} flex={1}>
            <Form {...props}>
              <Box mb={5}>
                <Typography variant="h1" align="center">
                  {name},<br /> {account?.name} anlegen?
                </Typography>
              </Box>
              <FormControl fullWidth margin="normal">
                <Field
                  component={TextField}
                  name="assistanceId"
                  label="Versicherungsnummer"
                  margin="normal"
                ></Field>
              </FormControl>
              <Box mt={2}>
                <MuiPickersUtilsProvider utils={DayjsUtils} locale="de">
                  <StyledKeyboardDatePicker
                    name="date"
                    disableFuture
                    format="DD.MM.YYYY"
                    value={props.values.birthDate}
                    onChange={value => {
                      props.setFieldValue("birthDate", value || dayjs());
                      if (props.errors) {
                        props.validateForm();
                      }
                    }}
                    label="Geburtsdatum"
                    cancelLabel="Abbrechen"
                    okLabel="OK"
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Form>
          </Box>
          <Box display="flex" justifyContent="center">
            <SecondaryButton
              onClick={() => {
                setStep(STEPS.ACCOUNT);
              }}
            >
              Zurück
            </SecondaryButton>
            <Box mx={1} />
            <PrimaryButton onClick={props.submitForm}>Anlegen</PrimaryButton>
          </Box>
        </>
      )}
    </Formik>
  );
};

export default AssistanceAccountAssign;
