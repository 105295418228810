import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  defaultText: {
    color: `${theme.palette.primary.dark}`,
  },
  red: {
    color: "red",
  },
  yellow: {
    color: "#F3AA18",
  },
  datePicker: { width: theme.spacing(13) },
  dataText: {
    lineHeight: 1.4,
    paddingBottom: theme.spacing(2),
  },
}));
