import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    csv: {
      "&>a": {
        fontWeight: theme.typography.fontWeightMedium,
        "&:not(:last-child):after": {
          content: '","',
        },
        paddingRight: theme.spacing(0.5),
      },
    },
    group: {
      "&>div": {
        borderTop: "solid #BDBDBD 1px",
        "&:last-child": {
          borderBottom: "solid #BDBDBD 1px",
        },
      },
    },
    container: {
      marginBottom: theme.spacing(4),
      paddingTop: theme.spacing(4),
    },
    chip: {
      backgroundColor: "white",
      margin: theme.spacing(0, 3),
    },
    chipLabel: {
      color: theme.palette.primary.main,
      fontSize: "16px",
    },
    filterLabel: {
      fontSize: "16px",
      margin: `${theme.spacing(0, 3)}  !important`,
    },
    headerContainer: {
      position: "relative",
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
  };
});

export default useStyles;
