import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme: Theme) => {
  return {
    avatar: isClient => ({
      color: "#fff",
      position: "absolute",
      top: 8,
      left: isClient ? "-64px" : "calc(100% + 24px)",
    }),
    bubble: isClient => ({
      position: "relative",
      "&:before": {
        borderBottom: `12px solid transparent`,
        borderLeft: isClient ? `0px solid transparent` : `12px solid #FFFFFF`,
        borderRight: isClient ? `12px solid #F2FCFF` : `0px solid transparent`,
        borderTop: `12px solid transparent`,
        content: '""',
        height: 0,
        ...(isClient ? { left: -12 } : { right: -12 }),
        margin: "auto 0",
        position: "absolute" as "absolute",
        top: 16,
        width: 0,
      },
    }),
  };
});
