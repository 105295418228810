import React, { useState } from "react";

import Box from "@material-ui/core/Box";
import { Theme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";

import {
  CreateGdprJobMutation,
  JobStatus,
  useCreateGdprJobMutation,
} from "../../../generated/graphql";

import DefaultDialog from "../../molecules/DefaultDialog";
import DefaultDialogContent from "../../molecules/DefaultDialogContent";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import GeneratedDriveFile from "../../molecules/GeneratedDriveFile";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 346,
  },
}));

type TGDPRDialog = {
  open: boolean;
  onClose: () => void;
  personId: number;
  displayName: string;
};
const GDPRDialog = ({ open, onClose, personId, displayName }: TGDPRDialog) => {
  const classes = useStyles();
  const [createGDPR] = useCreateGdprJobMutation();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [job, setJob] = useState<CreateGdprJobMutation["createGDPRJob"] | undefined>();
  const [error, setError] = useState(false);

  const start = async () => {
    setError(false);
    setSubmitting(true);
    try {
      const mutationResult = await createGDPR({ variables: { personId } });

      if (
        // errors ||
        !mutationResult.data ||
        mutationResult.data.createGDPRJob?.status === JobStatus.error
      ) {
        setError(true);
        return;
      }
      setJob(mutationResult.data.createGDPRJob);
    } catch (error) {
      console.error(error);
      setError(true);
    }
    setSubmitting(false);
  };

  const handleClose = () => {
    setJob(undefined);
    setError(false);
    setSubmitting(false);
    onClose();
  };

  return (
    <DefaultDialog open={open} onClose={handleClose}>
      <DefaultDialogContent className={classes.root}>
        <Box mb={3} textAlign="center">
          <Typography variant="h1">Datenauskunft</Typography>
        </Box>
        <Box flex={1}>
          <Typography variant="body1">
            Für eine Datenauskunft werden alle Daten des Klienten exportiert und in ein Google
            Dokument geschrieben.
          </Typography>
        </Box>
        {job ? (
          <GeneratedDriveFile personId={personId} job={job} displayName={displayName} />
        ) : null}
        <Box display="flex" justifyContent="center" mt={3}>
          <SecondaryButton onClick={handleClose}>Abbrechen</SecondaryButton>
          {!job ? (
            <>
              <Box mx={1} />
              <PrimaryButton onClick={start} disabled={submitting}>
                Datenauskunft exportieren
              </PrimaryButton>
            </>
          ) : null}
        </Box>
        {error ? (
          <Box mt={2} width="100%">
            <Alert severity="error">Es gab einen Fehler beim Speichern</Alert>
          </Box>
        ) : null}
      </DefaultDialogContent>
    </DefaultDialog>
  );
};

export default GDPRDialog;
