import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Markup from "../../atoms/Markup";
import { Memo } from "../../../generated/graphql";

const MemoDetails: FC<{ memo: Memo }> = ({ memo }) => {
  return (
    <Box mt={5} mb={5}>
      {!!memo.note && memo.note.trim().length > 2 && (
        <Box mt={5}>
          <Box mb={2}>
            <Typography variant="h4">Gesprächszusammenfassung</Typography>
          </Box>
          <Typography variant="body2">
            <Markup value={memo.note}></Markup>
          </Typography>
        </Box>
      )}
    </Box>
  );
};
export default MemoDetails;
