import { createContext, useContext } from "react";
import { STEPS } from "./reducer";

type TransferWpoClientProvider = {
  onClose: (path?: string, error?: boolean) => void;
  setStep: (step: STEPS) => void;
  step: STEPS;
  setClientId: (clientId: number) => void;
  clientId: number;
  setPersonId: (personId: number) => void;
  personId: number;
  setPersonName: (personName: string) => void;
  personName: string;
  automaticConfirmation: boolean;
  setAutomaticConfirmation: (automaticConfirmation: boolean) => void;
  createTask: boolean;
  setCreateTask: (createTask: boolean) => void;
  businessObjectId: number;
  setBusinessObjectId: (businessObjectId: number) => void;
  ucmsId: number;
  setUcmsId: (ucmsId: number) => void;
};

export const TransferWpoClientContext = createContext<TransferWpoClientProvider | undefined>(
  undefined,
);

export const useTransferWpoClientContext = () => {
  const context = useContext(TransferWpoClientContext);
  if (context === undefined) {
    throw new Error("useTransferWpoClientContext must be used within a TransferWpoClientContext");
  }

  return context;
};
