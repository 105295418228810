import React, { useState } from "react";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";

import DataColumn from "../../molecules/DataColumn";
import DataRow from "../../molecules/DataRow";
import AddressItem from "../../atoms/Address";
import Markup from "../../atoms/Markup";
import BusinessInfoDialog from "../../forms/BusinessInfoDialog";
import BusinessContactDialog from "../../forms/BusinessContactDialog";
import { BusinessObject } from "../../../generated/graphql";

type TBusinessContactData = { business: BusinessObject };
const BusinessContactData = ({ business }: TBusinessContactData) => {
  const [openInfo, setOpenInfo] = useState(false);
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const toggleInfo = () => setOpenInfo(!setOpenInfo);

  return (
    <>
      <Grid container component={Box} mt={3}>
        <DataRow title="Kontaktdaten" editTrigger={toggle}>
          <DataColumn xs={4} label="Adresse">
            {!!business.address ? (
              <AddressItem address={business.address} />
            ) : (
              <Typography variant="body2">-</Typography>
            )}
          </DataColumn>

          <DataColumn xs={4} label="Telefonnummer">
            <Typography variant="body2" key={`phone${business.id}`}>
              {business?.phones?.[0]?.readableNumber || "-"}
            </Typography>
          </DataColumn>

          <DataColumn xs={4} label="E-Mail-Adresse">
            <Typography variant="body2" key={`email${business.id}`}>
              {business?.email?.email || "-"}
            </Typography>
          </DataColumn>

          <DataColumn xs={4} label="Webseite">
            {!!business?.url ? (
              <Link
                href={business?.url || ""}
                target="_blanck"
                color="primary"
                underline="always"
                key={`site${business.id}`}
                variant="h4"
              >
                {business?.url || ""}
              </Link>
            ) : (
              <Typography variant="body2" key={`website${business.id}`}>
                -
              </Typography>
            )}
          </DataColumn>
        </DataRow>
      </Grid>

      <Grid container component={Box} mt={3}>
        <DataRow
          title="Sonstiges"
          editTrigger={() => {
            setOpenInfo(true);
          }}
        >
          <Grid item xs={12}>
            <Markup value={business?.info || "-"} key={`info${business.id}`} />
          </Grid>
          <DataColumn xs={12} label="Internes Memo">
            <Grid item xs={12}>
              <Markup value={business?.memo || "-"} key={`info${business.id}`} />
            </Grid>
          </DataColumn>
        </DataRow>
      </Grid>

      <BusinessContactDialog toggle={toggle} open={open} business={business} />

      <BusinessInfoDialog toggle={toggleInfo} open={openInfo} business={business} />
    </>
  );
};
export default BusinessContactData;
