import React from "react";

import classnames from "classnames";

import { Person } from "../../../generated/graphql";
import Avatar from "../Avatar";
import MentalIcon from "../MentalIcon";

import useStyles from "./styles";

type TClientAvatar = {
  customClass?: string;
  person?: Person;
  tiny?: boolean;
  red?: boolean;
  yellow?: boolean;
  white?: boolean;
};

const ClientAvatar = ({ person, customClass, tiny, red, yellow, white }: TClientAvatar) => {
  const classes = useStyles();

  return (
    <Avatar className={classnames(classes.avatar, customClass, { tiny, red, yellow, white })}>
      {person && person.mentalState && (
        <MentalIcon size="small" type={person.mentalState} className={classes.icon} />
      )}
      {person && person.avatarKeys}
    </Avatar>
  );
};

export default ClientAvatar;
