import React, { FC } from "react";
import { Field, FormikProps, FormikValues } from "formik";
import { TextField } from "formik-material-ui";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import useStyles from "../formStyles";

interface FormProps {
  cancel: () => void;
}

const Form: FC<FormProps & FormikProps<FormikValues>> = ({
  cancel,
  handleSubmit,
  setFieldValue,
  submitForm,
}) => {
  const classes = useStyles();

  const reset = () => {
    setFieldValue("note", "");
    setTimeout(submitForm, 0);
  };

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <FormControl className={classes.formControl} margin="normal" fullWidth>
        <Field name="note" component={TextField} label="Notiz" tabIndex={1} autoFocus />
      </FormControl>
      <Box display="flex" justifyContent="center" mt={4} flexDirection="row-reverse">
        <Box mx={1}>
          <PrimaryButton type="submit" tabIndex={0}>
            OK
          </PrimaryButton>
        </Box>
        <Box mx={1}>
          <SecondaryButton onClick={reset} tabIndex={0}>
            Notiz löschen
          </SecondaryButton>
        </Box>
        <Box mx={1}>
          <SecondaryButton onClick={cancel} tabIndex={0}>
            Abbrechen
          </SecondaryButton>
        </Box>
      </Box>
    </form>
  );
};

export default Form;
