import React from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { Select } from "formik-material-ui";
import { Field } from "formik";

import { AccountType } from "../../../generated/graphql";
import { translateType } from "../../../utils/accountHelper";

type TAccountTypeSelect = {
  tabIndex?: number;
  disabled?: boolean;
  forStatistics?: boolean;
  onlyLifeWorks?: boolean;
  onlyEAP?: boolean;  
};

const AccountTypeSelect = ({
  tabIndex = 0,
  disabled = false,
  forStatistics = false,
  onlyLifeWorks = false,
  onlyEAP = false,  
}: TAccountTypeSelect) => {
  return (
    <FormControl fullWidth margin="normal">
      <InputLabel htmlFor="type">Vertragstyp</InputLabel>
      <Field
        name="type"
        label="Vertragstyp"
        component={Select}
        tabIndex={tabIndex}
        disabled={disabled}    
      >
        {forStatistics && onlyLifeWorks && (
          <MenuItem value={AccountType.lifeWorks}>
            {translateType(AccountType.lifeWorks, true)}
          </MenuItem>
        )}
        {forStatistics && onlyEAP && (
          <MenuItem value={AccountType.eap}>
            {translateType(AccountType.eap, true)}
          </MenuItem>
        )}
        {forStatistics && !onlyLifeWorks && <MenuItem value="all">Alle</MenuItem>}
        {!onlyLifeWorks && (
          <MenuItem value={AccountType.test}>{translateType(AccountType.test)}</MenuItem>
        )}
        {!onlyLifeWorks && (
          <MenuItem value={forStatistics ? translateType(AccountType.eap) : AccountType.eap}>
            {translateType(AccountType.eap)}
          </MenuItem>
        )}
        {!onlyLifeWorks && (
          <MenuItem value={forStatistics ? translateType(AccountType.wpo, true) : AccountType.wpo}>
            {translateType(AccountType.wpo, true)}
          </MenuItem>
        )}
        {!onlyLifeWorks && (
          <MenuItem value={AccountType.lifeWorks}>
            {translateType(AccountType.lifeWorks, true)}
          </MenuItem>
        )}
        {!onlyLifeWorks && (
          <MenuItem value={AccountType.international}>
            {translateType(AccountType.international)}
          </MenuItem>
        )}
        {!forStatistics && !onlyLifeWorks ? (
          <MenuItem value={AccountType.assistance}>
            {translateType(AccountType.assistance)}
          </MenuItem>
        ) : null}
      </Field>
    </FormControl>
  );
};
export default AccountTypeSelect;
