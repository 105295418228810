import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useBreadCrumbInfoByAccountIdQuery } from "../../../generated/graphql";
import { useAccountSubscription } from "../../../hooks/useAccountSubscription";
import errorIllustration from "../../assets/errorIllustration.svg";
import Loader from "../../atoms/Loading";
import PrimaryButton from "../../atoms/PrimaryButton";
import AccountStatusBadge from "../../molecules/AccountStatusBadge";
import Tab from "../../molecules/Tab";
import AccountCard from "../../organisms/AccountCard";
import IllustratedErrorMessage from "../../organisms/IllustratedErrorMessage";
import PersonLayout from "../../templates/PersonLayout";

const Account = () => {
  const { id } = useParams<{ id: string }>();
  const { goBack } = useHistory();

  const { account, loading, error } = useAccountSubscription(parseInt(id, 10), !id);
  const accountId = account?.id;
  const { data: breadcrumbData } = useBreadCrumbInfoByAccountIdQuery({
    skip: !accountId,
    variables: {
      id: accountId!,
    },
  });

  if (loading) {
    return <Loader />;
  }

  if (error || !account) {
    console.error(error);

    return (
      <IllustratedErrorMessage
        messages={["Der Account konnte nicht geladen werden."]}
        illustration={errorIllustration}
        illustrationAltText="Elli rutscht auf einer Banane aus"
      >
        <PrimaryButton onClick={goBack}>Zurück</PrimaryButton>
      </IllustratedErrorMessage>
    );
  }

  const mapList = [
    {
      name: "Accounts",
      path: "/account",
    },
    {
      name: account.name || "",
      path: "",
      chip: (
        <AccountStatusBadge
          contractEnd={breadcrumbData?.breadCrumbInfoByAccountId?.contractEnd}
          lang={breadcrumbData?.breadCrumbInfoByAccountId?.language || ""}
        />
      ),
    },
  ];

  return (
    <PersonLayout
      mapList={mapList}
      counselor
      personIds={account.business?.persons?.map(p => p!.id!) || []}
      contractExpirationDate={breadcrumbData?.breadCrumbInfoByAccountId?.contractEnd}      
      pathId={account.business?.id}
    >
      <Box bgcolor="#EFE8DD" width={1} py={2} marginBottom={4}>
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex" ml={3}>
              <Tab
                type="permContactCalendar"
                target={`/business/${account.business!.id!}/contacts`}
              >
                Ansprechpartner
              </Tab>
              <Tab type="locations" target={`/account/${id}/departments`}>
                Standorte
              </Tab>
              <Tab type="laptop" target={`/account/${id}/business-units`}>
                Business Units
              </Tab>
              <Tab type="insertDriveFile" target={`/business/${account.business!.id}/documents`}>
                Dokumente
              </Tab>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <AccountCard account={account} />
    </PersonLayout>
  );
};

export default Account;
