import React, { useState, Fragment } from "react";

import { Formik, FormikValues, FormikActions } from "formik";
import dayjs from "dayjs";

import Typography from "@material-ui/core/Typography";

import {
  Memo,
  Person,
  Topic,
  PersonActivity,
  ConversationReport,
} from "../../../generated/graphql";
import ReportForm from "../ReportForm";
import useStyles from "../reportStyles";

export type TMemoUpdateFormStep1 = {
  memo: Memo;
  person: Person;
  customCallBack: () => void;
};

export type TReportUpdateFormStep1 = {
  person: Person;
  report: ConversationReport;
  customCallBack: () => void;
};

type TMemoReportFormStep1 = {
  defaultContact?: string | null | undefined;
  initialTopic?: Topic | null;
  isCreating?: boolean;
  onSubmit: (
    formikValues: FormikValues,
    formikActions: FormikActions<FormikValues>,
    topic: Topic,
  ) => Promise<void>;
  person: Person;
  personActivity?: PersonActivity | null;
};

const MemoReportFormStep1 = ({
  defaultContact,
  initialTopic,
  isCreating = false,
  onSubmit,
  person,
  personActivity,
}: TMemoReportFormStep1) => {
  const classes = useStyles();
  const [topic, setTopic] = useState<Topic>(initialTopic || { name: "", id: null });
  const initialValues = {
    start: personActivity?.start ? dayjs(personActivity?.start) : dayjs().subtract(3, "minute"),
    end: personActivity?.end ? dayjs(personActivity?.end) : dayjs(),
    type: personActivity?.activityType || "callIn",
  };

  return (
    <Fragment>
      <Typography variant="h1" className={classes.headline}>
        Erstelle eine neue Gesprächsnotiz zu {person.name!}.
      </Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, action) => onSubmit(values, action, topic)}
      >
        {formProps => {
          return (
            <ReportForm
              {...formProps}
              defaultContact={defaultContact}
              isCreating={isCreating}
              person={person}
              setTopic={setTopic}
              topic={topic}
            />
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default MemoReportFormStep1;
