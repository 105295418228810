import React, { useState, useEffect } from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import AccessTime from "@material-ui/icons/AccessTime";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import dayjs, { Dayjs } from "dayjs";
import DayjsUtils from "@date-io/dayjs";
import sortBy from "lodash/sortBy";
import Typography from "@material-ui/core/Typography";

import { weekDays } from "../../../utils/constants";
import { Hour } from "../../../generated/graphql";

import useStyles from "./styles";
import HourEntry from "../HourEntrySelector";
import TimeDialog from "../TimeDialog";
import uuidv4 from "../../../utils/uuid";
import IHour from "../../../interfaces/IHour";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

export type THourMethodsType = {
  identifier: any;
  create: (hour: IHour, params?: any) => void;
  update: (hour: IHour, hourKey: any) => void;
  delete: (hourKey: any) => void;
  deleteDayHours: (day: number) => void;
};

interface IDaysTimeSelectorProps {
  autoSave?: boolean;
  formHours: Hour[];
  initial: boolean;
  methods: THourMethodsType;
}

type TDayEntry = {
  autoSave?: boolean;
  day: number;
  defaultClose: Dayjs;
  defaultOpen: Dayjs;
  hours: Hour[];
  initiallyChecked: boolean;
  methods: THourMethodsType;
};

const DayEntry = ({
  autoSave,
  day,
  defaultClose,
  defaultOpen,
  hours,
  initiallyChecked,
  methods,
}: TDayEntry) => {
  const classes = useStyles();

  useEffect(() => {
    if (initiallyChecked) {
      methods.create(
        {
          day,
          open: defaultOpen,
          close: defaultClose,
          ...(methods.identifier === "uuid" ? { uuid: uuidv4() } : []),
        },
        // do not auto save initially checked days
        { save: !autoSave },
      );
    }
  }, [autoSave, defaultOpen, defaultClose, day, initiallyChecked, methods]);

  const handleCheck = () => {
    if (!!hours.length) {
      methods.deleteDayHours(day);
    } else {
      methods.create({
        day,
        open: defaultOpen,
        close: defaultClose,
        ...(methods.identifier === "uuid" ? { uuid: uuidv4() } : []),
      });
    }
  };

  const renderDayHours = () => {
    return hours.map((hour: IHour, idx: number) => {
      return (
        <HourEntry
          day={day}
          defaultClose={defaultClose}
          defaultOpen={defaultOpen}
          hour={hour}
          // @ts-ignore
          key={`hour-${hour[methods.identifier]}`}
          methods={methods}
          showAddIcon={idx === hours.length - 1}
          showRemoveIcon={idx !== 0}
        />
      );
    });
  };

  return (
    <Box display="flex" className={classes.dayRow} width={600}>
      <Box display="flex" width={200}>
        <FormControlLabel
          control={<Checkbox onChange={handleCheck} checked={!!hours.length || initiallyChecked} />}
          label={weekDays[day]}
        />
      </Box>
      <Box display="flex" alignItems="flex-end" flex={1} flexDirection="column">
        {renderDayHours()}
      </Box>
    </Box>
  );
};

const DaysTimeSelector = (props: IDaysTimeSelectorProps) => {
  const { autoSave, formHours, initial, methods } = props;
  const classes = useStyles();

  const hours: Hour[] = sortBy(formHours, ["day", "open"]);

  const defaultHour = hours.length
    ? {
        open: dayjs(hours[0].open),
        close: dayjs(hours[0].close),
      }
    : {
        open: dayjs()
          .startOf("day")
          .add(8, "hour"),
        close: dayjs()
          .startOf("day")
          .add(14, "hour"),
      };
  const [open, setOpen] = useState(defaultHour.open);
  const [close, setClose] = useState(defaultHour.close);
  const [showOpen, setShowOpen] = useState(false);
  const [showClose, setShowClose] = useState(false);

  const handleUpdateOpen = (date: MaterialUiPickersDate) => {
    const indicesToUpdate = hours.reduce((acc: number[], hour: Hour, idx: number) => {
      return dayjs(hour.open).isSame(open) && dayjs(hour.close).isSame(close) ? [...acc, idx] : acc;
    }, []);

    indicesToUpdate.forEach((indice: number) => {
      const hourToUpdate = hours[indice];
      methods.update(
        { ...hourToUpdate, open: date },
        // @ts-ignore
        hourToUpdate[`${methods.identifier}`],
      );
    });
  };

  const handleUpdateClose = (date: MaterialUiPickersDate) => {
    const indicesToUpdate = hours.reduce((acc: number[], hour: Hour, idx: number) => {
      return dayjs(hour.open).isSame(open) && dayjs(hour.close).isSame(close) ? [...acc, idx] : acc;
    }, []);

    indicesToUpdate.forEach((indice: number) => {
      const hourToUpdate = hours[indice];

      methods.update(
        { ...hourToUpdate, close: date },
        // @ts-ignore
        hourToUpdate[`${methods.identifier}`],
      );
    });
  };

  const renderDays = () => {
    const days = [];
    for (let index = 0; index < 7; index++) {
      const dayHours: Hour[] = hours.filter(h => h.day === index);
      // check all weekdays (index < 5) initially if no hours are set at all and form is untouched
      const initiallyChecked = initial && index < 5;

      days.push(
        <DayEntry
          autoSave={autoSave}
          day={index}
          key={`day-${index}`}
          hours={dayHours}
          initiallyChecked={initiallyChecked}
          defaultOpen={open}
          defaultClose={close}
          methods={methods}
        />,
      );
    }
    return days;
  };

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils} locale="de">
      <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
        <Box mb={5} display="flex" alignItems="center" width={600} justifyContent="center">
          <AccessTime color="primary" className={classes.icon} />{" "}
          <Typography variant="body2">Zeitraum</Typography>
          <Typography variant="body2" className={classes.leftSpacing}>
            <Link variant="h4" underline="always" color="primary" onClick={() => setShowOpen(true)}>
              {open.format("H:mm")}
            </Link>{" "}
            bis{" "}
            <Link
              variant="h4"
              underline="always"
              color="primary"
              onClick={() => setShowClose(true)}
            >
              {close.format("H:mm")}
            </Link>{" "}
            Uhr
          </Typography>
        </Box>
        {renderDays()}
      </Box>
      <TimeDialog
        handleClose={() => {
          setShowOpen(false);
          setShowClose(true);
        }}
        handleOpen={() => setShowOpen(true)}
        handleChange={setOpen}
        handleDone={handleUpdateOpen}
        label={"Von"}
        show={showOpen}
        value={open}
      />
      <TimeDialog
        handleClose={() => setShowClose(false)}
        handleOpen={() => setShowClose(true)}
        handleChange={setClose}
        handleDone={handleUpdateClose}
        label={"Bis"}
        show={showClose}
        value={close}
      />
    </MuiPickersUtilsProvider>
  );
};
export default DaysTimeSelector;
