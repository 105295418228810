import React, { FC } from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link/Link";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import FormControl from "@material-ui/core/FormControl/FormControl";

import { Checkbox } from "formik-material-ui";
import { Field } from "formik";

import PrimaryButton from "../../../atoms/PrimaryButton";
import SecondaryButton from "../../../atoms/SecondaryButton";

// eslint-ignore-next-line
export interface ContactFormProps {
  cancel: () => void;
  create: () => void;
  remove: (id: number) => void;
}

export const ContactAllowed: FC<{
  contactAllowed: boolean;
}> = ({ contactAllowed, children }) => {
  return (
    <Box color={contactAllowed ? "success.main" : "error.main"} paddingTop={1}>
      <Typography color="inherit" variant="body2">
        {children}
      </Typography>
    </Box>
  );
};
type TContactFormWrapper = {
  cancel: () => void;
  onCreate: () => void;
  onCreateTitle: string;
  onSubmit: () => void;
  title: string;
};
export const ContactFormWrapper: FC<TContactFormWrapper> = ({
  cancel,
  children,
  onCreate,
  onCreateTitle,
  onSubmit,
  title,
}) => {
  return (
    <>
      <Box marginBottom={1}>
        <Typography variant="h1">{title}</Typography>
      </Box>
      {children}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Link underline="always" onClick={onCreate} variant="h4">
            {onCreateTitle}
          </Link>
        </Grid>
      </Grid>
      <Box marginTop={4} display="flex" justifyContent="center" flexDirection="row-reverse">
        <PrimaryButton type="submit" onClick={onSubmit} tabIndex={0}>
          OK
        </PrimaryButton>
        <Box mx={1} />
        <SecondaryButton onClick={cancel} tabIndex={0}>
          Abbrechen
        </SecondaryButton>
      </Box>
    </>
  );
};

type TContactFormElementWrapper = {
  contactPermissions: {
    label: string;
    name: string;
    value: boolean;
  }[];
  onDelete?: () => void;
};
export const ContactFormElementWrapper: FC<TContactFormElementWrapper> = ({
  children,
  contactPermissions,
  onDelete,
}) => {
  return (
    <Box mb={4} pb={4} borderBottom="solid #bdbdbd 1px" width="100%">
      {/* <Grid container spacing={2} alignItems="center"> */}
      {children}
      {/* </Grid> */}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <Box display="flex">
            {contactPermissions.map(contactPermission => {
              return (
                <FormControl margin="none" fullWidth key={contactPermission.name}>
                  <FormControlLabel
                    control={
                      <Field
                        label={contactPermission.label}
                        name={contactPermission.name}
                        value={contactPermission.value}
                        component={Checkbox}
                        type="checkbox"
                      />
                    }
                    label={contactPermission.label}
                  />
                </FormControl>
              );
            })}
          </Box>
        </Grid>
        {onDelete && (
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Link underline="always" onClick={onDelete} variant="h4">
              Löschen
            </Link>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
