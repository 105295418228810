import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    iconLabel: {
      alignItems: "center",
      display: "flex",
      "&>svg": {
        marginRight: theme.spacing(1),
      },
    },
  };
});

export default useStyles;
