import { createContext, useContext } from "react";

import {
  Account,
  PersonActivityQueryVariables,
  PersonActivityQuery,
} from "../../../generated/graphql";

import { STEPS, TYPES } from "./reducer";

type ApolloQueryResult<T> = {
  data?: T;
  loading: boolean;
};

/**
 *  Provide current state and actions
 */
type PersonContextExternalState = {
  pushTarget?: (personId: number) => string;
  personActivity: PersonActivityQuery["personActivity"];
  refetch: (
    variables?: PersonActivityQueryVariables | undefined,
  ) => Promise<ApolloQueryResult<PersonActivityQuery>>;
};

type PersonContextInternalState = {
  account?: Account;
  assistanceId?: string;
  name?: string;
  step: STEPS;
  type: TYPES;
};

type PersonContextInternalActions = {
  setStep: (step: STEPS) => void;
  setType: (type: TYPES) => void;
  setAccount: (account: Account) => void;
  setName: (name: string) => void;
  setAssistanceId: (assistanceId: string) => void;
  resetAccount: () => void;
};

type PersonCreateProvider = PersonContextExternalState &
  PersonContextInternalState &
  PersonContextInternalActions;

export const PersonCreateContext = createContext<PersonCreateProvider | undefined>(undefined);

export const usePersonCreateContext = () => {
  const context = useContext(PersonCreateContext);
  if (context === undefined) {
    throw new Error("usePersonCreateContext must be used within a PersonCreateContext");
  }
  return context;
};
