import classnames from "classnames";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import sortBy from "lodash/sortBy";
import { Person, User, UserWithTasksAndAppointments, useUsersWithTasksQuery } from "../../../generated/graphql";
import { getUserName } from "../../../utils/userNameHelper";
import Loader from "../../atoms/Loading";
import InboxLabelFilter from "../../molecules/InboxLabelFilter";
import useStyles from "../inboxStyles";
import { CalendarToday, Event, Work } from "@material-ui/icons";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import orderBy from "lodash/orderBy";

const InboxEmployeeSelection: FC = () => {
  const { push } = useHistory();
  const classes = useStyles();
  const { data, loading } = useUsersWithTasksQuery();

  if (loading) {
    return <Loader />;
  }

  const users = data?.usersWithTasks as User[];

  if (!users || users.length < 1) {
    return <>Error</>;
  }

  return (
    <Grid container className={classnames(classes.content, classes.contentBox)}>
      <InboxLabelFilter scope="admin" />
      <Grid container>
        <Grid item xs={12}>
          <Box
            maxWidth={600}
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            marginX="auto"
          >
            <Box mb={5} width={1} textAlign="center">
              <Typography variant="h1">Wessen Inbox möchtest du dir ansehen?</Typography>
            </Box>
            <Box width={1}>
              <Box>
                {/* {sortBy(users, (user: User) => getUserName(user?.person)).map((user: User) => {
                  if (!user) {
                    return null;
                  }

                  return (
                    <UserListItem
                      key={String(user.id)}
                      user={user}
                      onSubmit={(userId?: number) => {
                        if (userId) {
                          push(`/inbox/selection/${userId}`);
                        }
                      }}
                    />
                  );
                })} */}

                {orderBy(users, 
                [
                  (user: UserWithTasksAndAppointments) => user.todayAppointments,
                  (user: UserWithTasksAndAppointments) => user.appointments,
                  (user: UserWithTasksAndAppointments) => user.tasks
                ], [
                  'desc',
                  'desc',
                  'desc'
                ])
                .map((user: any) => {
                      if (!user) {
                        return null;
                      }

                      return (
                        <UserWithTaskAndAppointmentListItem
                          key={String(user.userId)}
                          user={{
                            id: user.userId,
                            person: {
                              name: `${user.firstName} ${user.lastName}`,
                              alias: user.alias
                            } as Person,
                            tasks: user.tasks,
                            appointments: user.appointments,
                            caseManagers: user.todayAppointments
                          } as User}
                          onSubmit={(userId?: number) => {
                            if (userId) {
                              push(`/inbox/selection/${userId}`);
                            }
                          }}
                        />
                      );
                    })}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

const UserListItem = ({ onSubmit, user }: { onSubmit: (userId: number) => void; user: User }) => {
  const classes = useStyles();

  if (!user.person) {
    return null;
  }

  return (
    <Box
      display="flex"
      color="primary.main"
      alignItems="center"
      paddingY={1}
      paddingLeft={2}
      justifyContent="space-between"
      flex={1}
      width={1}
      className={classes.clickable}
      onClick={async () => {
        onSubmit(user.id);
      }}
    >
      <Box flex={1}>{getUserName(user!.person)}</Box>
      <Box paddingX={2}>{user!.tasks?.length}</Box>
    </Box>
  );
};

const UserWithTaskAndAppointmentListItem = ({ onSubmit, user }: { onSubmit: (userId: number) => void; user: User }) => {
  const classes = useStyles();

  if (!user.person) {
    return null;
  }

  return (
    <Box
      display="flex"
      color="primary.main"
      alignItems="center"
      paddingY={0}
      paddingLeft={2}
      justifyContent="space-between"
      flex={1}
      width={1}
      height={48}
      className={classes.clickable}
      onClick={async () => {
        onSubmit(user.id);
      }}
    >
      <Box flex={5}>{getUserName(user!.person)}</Box>


      <Box flex={2}>
        {(user!.caseManagers && user!.caseManagers?.length > 0) &&
          <List>
            <ListItem>
              <ListItemText primaryTypographyProps={{ variant: "caption" }} primary={user!.caseManagers?.length} />
              <ListItemIcon>
                <Event color="secondary" />
              </ListItemIcon>
            </ListItem>
          </List>
        }
      </Box>

      <Box flex={2}>
        {(user!.appointments && user!.appointments?.length > 0) &&
          <List>
            <ListItem>
              <ListItemText primaryTypographyProps={{ variant: "caption" }} primary={user!.appointments?.length} />
              <ListItemIcon>
                <CalendarToday color="primary" />
              </ListItemIcon>
            </ListItem>
          </List>
        }
      </Box>

      <Box flex={2}>
        {(user!.tasks && user!.tasks?.length > 0) &&
          <List>
            <ListItem>
              <ListItemText primaryTypographyProps={{ variant: "caption" }} primary={user!.tasks?.length} />
              <ListItemIcon>
                <Work color="primary" />
              </ListItemIcon>
            </ListItem>
          </List>
        }
      </Box>

    </Box>

  );
};

export default InboxEmployeeSelection;
