import React, { FC } from "react";

import { useParams } from "react-router-dom";

import usePersonSubscription from "../../../hooks/usePersonSubscription";
import Email from "../../templates/Email";

const EmailThread: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { person } = usePersonSubscription(parseInt(id, 10));

  return <Email context={{ person }} />;
};

export default EmailThread;
