import React from "react";

export const EmptyAlternativeSecond = () => {
  return (
    <svg
      width="220"
      height="116"
      viewBox="0 0 220 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M214.178 64.2038L217.318 65.1717L206.245 72.8517L195.721 100.774L192.581 99.8062L203.106 71.8838L214.178 64.2038Z"
        fill="#F2680A"
        stroke="#025879"
        strokeLinejoin="round"
      />
      <path
        d="M116.953 84.936C121.953 94.9358 132.453 105.936 140.451 106.938C147.451 102.438 157.501 88.7102 158.401 87.7895M158.401 87.7895C159.967 86.1861 154.536 80.117 156.103 78.5136C157.669 76.9102 159.222 81.5501 160.397 80.3475C161.571 79.1449 167.946 75.6028 170.949 78.5225C173.029 80.5469 168.958 89.9365 158.401 87.7895Z"
        stroke="#025879"
      />
      <path
        d="M100.449 52.4453C93.4651 43.9461 77.4412 41.7931 74.4425 42.9457C69.4412 44.8679 69.1345 56.9467 67.9111 56.9448M67.9111 56.9448C65.6868 56.9415 63.139 57.9475 61.9393 59.4477C60.7397 60.948 57.9374 64.4471 60.4228 67.447C62.9374 69.9471 66.4111 63.4471 67.9111 64.4471C72.4111 68.947 70.922 60.9454 67.9111 56.9448Z"
        stroke="#025879"
      />
      <path
        d="M59.9462 91.4434C59.9462 91.4434 42.6465 97.4002 37.4457 97.9435C33.0765 98.3999 19.0153 95.0299 14.9522 95.4544M14.9522 95.4544C13.2045 95.6369 1.45209 96.9427 2.45209 100.937C3.95209 102.937 7.95209 102.437 11.4521 100.437C13.9521 99.0085 14.9522 95.4544 14.9522 95.4544Z"
        stroke="#025879"
      />
      <path
        d="M56.9519 73.946C48.9492 71.4407 39.1972 69.2052 33.4492 71.4394C27.3418 73.8133 21.6799 90.4455 19.2119 90.2917M19.2119 90.2917C19.2119 90.2917 19.2593 93.8947 15.1049 92.0932C10.9504 90.2917 6.90309 86.7683 9.45043 85.9391C11.9978 85.1099 18.7842 86.0687 19.2119 90.2917Z"
        stroke="#025879"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.8341 94.3023C74.8341 94.3023 60.7046 100.833 57.2502 99.4162C52.7382 97.5657 52.3761 88.6841 52.0691 86.9544C51.5178 83.8488 62.4138 83.1828 62.0234 80.9834C61.633 78.7839 50.9945 80.9005 50.534 78.306C50.368 77.3709 49.0855 66.6037 51.2632 65.6872C55.1281 64.0605 67.5137 65.9262 71.2729 67.0728C77.15 68.8656 74.8341 94.3023 74.8341 94.3023Z"
        fill="#555555"
        stroke="#025879"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M125.444 82.9505C127.544 86.1002 130.381 88.5125 131.894 89.6766C132.417 90.0784 132.461 90.8727 131.965 91.3067L121.602 100.375C121.225 100.704 120.666 100.708 120.287 100.381C117.436 97.9293 106.212 88.4088 104.444 88.9505C92.4538 92.6237 75.4955 94.5735 73.4444 97.4453C70.6337 101.381 66.5104 64.826 69.6816 63.7937C71.9256 63.0632 83.8818 61.9803 94.8777 59.7895C95.8897 59.5879 87.2725 56.2501 85.6244 52.9017C85.4871 52.6227 85.5521 52.2955 85.7414 52.0488C89.4049 47.2747 92.9982 46.87 95.4434 43.2614C95.7437 42.8181 96.3224 42.633 96.7964 42.882C101.479 45.3417 105.817 48.6091 107.444 51.4453C109.17 54.4528 109.944 58.9453 109.944 58.9453C109.944 58.9453 106.944 63.7937 108.67 70.4327C112.444 75.9505 118.944 75.9505 118.944 75.9505C118.944 75.9505 123.884 80.6109 125.444 82.9505Z"
        fill="#A6C4CE"
        stroke="#025879"
        strokeLinejoin="round"
      />
      <path
        d="M114.634 37.2512C117.177 31.5942 126.801 15.8073 131.584 17.1902C137.299 18.8421 138.764 12.5256 143.964 14.2619C149.163 15.9982 154.315 17.2543 152.924 12.4017C151.534 7.54912 160.779 16.1574 162.695 22.5313C162.695 22.5313 167.309 19.768 169.256 21.5738C171.203 23.3795 168.817 27.1408 170.091 28.3222C171.365 29.5036 175.694 27.5439 175.694 27.5439C175.694 27.5439 173.483 44.8261 176.294 45.6386C183.62 47.7567 173.596 57.6581 170.582 65.3962"
        stroke="#025879"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.46 73.6326C137.432 78.0398 154.21 80.3299 162.066 73.7727C162.066 73.7727 164.921 77.8679 169.372 71.1766C173.823 64.4853 166.49 64.1964 166.49 64.1964C167.436 50.9564 159.617 36.5753 151.795 37.9762C149.577 38.3736 147.975 33.6182 146.748 32.8549C141.247 29.4337 140.337 34.6635 138.106 34.0188C135.876 33.3741 139.99 29.89 134.806 29.4025C129.621 28.915 116.961 40.1343 116.961 40.1343C116.961 40.1343 112.391 33.2648 110.781 41.9545C109.172 50.6442 114.045 50.222 114.045 50.222C113.596 61.0785 121.488 69.2254 129.46 73.6326Z"
        stroke="#025879"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M155.448 58.4163C154.119 58.0322 153.352 56.6455 153.736 55.3191C154.119 53.9927 155.507 53.2289 156.836 53.613C158.165 53.9972 158.931 55.3839 158.548 56.7102C158.164 58.0366 156.776 58.8005 155.448 58.4163Z"
        fill="#025879"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M132.78 46.6585C131.451 46.2744 130.684 44.8877 131.068 43.5613C131.451 42.2349 132.839 41.4711 134.168 41.8552C135.497 42.2394 136.263 43.626 135.88 44.9524C135.496 46.2788 134.108 47.0427 132.78 46.6585Z"
        fill="#025879"
      />
      <path
        d="M150.47 66.6195C146.283 72.4463 135.606 73.2472 128.959 67.4382C122.313 61.6292 122.605 56.7099 123.475 51.7782"
        stroke="#025879"
        strokeLinecap="round"
      />
      <path d="M188.215 106.125L185.986 75.125H207.912L205.684 106.125H188.215Z" stroke="#025879" />
      <mask id="path-15-inside-1" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M186.844 87.9453L188.157 106.945H205.849L207.162 87.9453H186.844Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M186.844 87.9453L188.157 106.945H205.849L207.162 87.9453H186.844Z"
        fill="#F2CF89"
      />
      <path
        d="M188.157 106.945L187.159 107.014L187.224 107.945H188.157V106.945ZM186.844 87.9453V86.9453H185.772L185.846 88.0143L186.844 87.9453ZM205.849 106.945V107.945H206.782L206.846 107.014L205.849 106.945ZM207.162 87.9453L208.159 88.0143L208.233 86.9453H207.162V87.9453ZM189.154 106.876L187.841 87.8764L185.846 88.0143L187.159 107.014L189.154 106.876ZM205.849 105.945H188.157V107.945H205.849V105.945ZM206.164 87.8764L204.851 106.876L206.846 107.014L208.159 88.0143L206.164 87.8764ZM207.162 86.9453H186.844V88.9453H207.162V86.9453Z"
        fill="#025879"
        mask="url(#path-15-inside-1)"
      />
    </svg>
  );
};

export default EmptyAlternativeSecond;
