import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: "auto",
    maxWidth: theme.spacing(3),
    maxHeight: theme.spacing(8),
    height: "100%",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    minHeight: theme.spacing(8),
  },
  avatar: {
    marginRight: theme.spacing(2),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  avatarLink: {
    textDecoration: "none",
  },
}));

export default useStyles;
