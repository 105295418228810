import React, { ReactText } from "react";
import Typography from "@material-ui/core/Typography";
import { Formik } from "formik";
import {
  useUpdateConversationReportMutation,
  Person,
  ConversationReport,
  ConversationReportReviewAttributeBaseInput,
  Service,
  ReportState,
} from "../../../generated/graphql";

import IReviewStep from "../../../interfaces/IReviewStep";
import IClientReviewFormAttribute from "../../../interfaces/IClientReviewFormAttribute";
import useStyles from "../reportStyles";
import GenericForm from "./genericForm";
import { pathOr } from "ramda";
import some from "lodash/some";

export interface IReviewWizardProps {
  activeReviewStep: IReviewStep;
  backAction: () => void;
  nextReviewStep: any | null;
  person: Person;
  report: ConversationReport;
  step: ReportState;
}

export default function ReviewWizard({
  activeReviewStep,
  backAction,
  nextReviewStep,
  person,
  report,
  step,
}: IReviewWizardProps) {
  const classes = useStyles();
  const [updateReport] = useUpdateConversationReportMutation();

  const getInitialValues = () => {
    return activeReviewStep.attrs.reduce((acc: any, attr: IClientReviewFormAttribute) => {
      acc[`${attr.name}`] = attr.value;
      return acc;
    }, {});
  };

  const getConversationReportReviewAttributes = (
    values: any,
  ): ConversationReportReviewAttributeBaseInput[] | [] => {
    // convert boolen values to match api validation
    const adjustValue = (inputValue: any) => {
      if (inputValue === true) {
        return "true";
      } else if (inputValue === false) {
        return null;
      }
      return inputValue;
    };
    return activeReviewStep.attrs.reduce((carry: any, attrToSave: IClientReviewFormAttribute) => {
      if (attrToSave.name && values.hasOwnProperty(attrToSave.name)) {
        return carry.concat({
          id: attrToSave.id,
          attributeValue: adjustValue(values[attrToSave.name]),
        });
      }
      return null;
    }, []);
  };
  function autoSave(values: { [key: string]: string | number | null | undefined | ReactText }) {
    return updateReport({
      variables: {
        report: {
          id: report.id,
          conversationReportReviewAttributes: getConversationReportReviewAttributes(values),
          activeClientReviewAttributeGroupId: activeReviewStep.groupId,
          activeClientReviewTemplateId: activeReviewStep.templateId,
        },
      },
    });
  }

  const isCreation = some(
    Object.values(getInitialValues()),
    (val: string | number | null | undefined | ReactText | boolean) => {
      if (!val || val === "true") return true;
      if (typeof val === "string") {
        return val.length <= 2;
      }
      return false;
    },
  );
  const services: Service[] = pathOr([], ["services"], report);
  const next = services.length ? ReportState.inServiceReview : ReportState.finished;

  return (
    <>
      <Typography variant="h1" className={classes.headline}>
        {isCreation ? activeReviewStep.headingNew : activeReviewStep.headingUpdate}
      </Typography>
      
      <Formik
        initialValues={getInitialValues()}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          return updateReport({
            variables: {
              report: {
                id: report.id,
                status: nextReviewStep ? step : next,
                conversationReportReviewAttributes: getConversationReportReviewAttributes(values),
                ...nextReviewStep,
              },
            },
          }).then(() => {
            setSubmitting(false);
          });
        }}
      >
        {formProps => (
          <GenericForm
            key={`reviewStep-${activeReviewStep.id}`}
            {...formProps}
            activeReviewStep={activeReviewStep}
            person={person}
            saveInput={autoSave}
            backAction={backAction}
          />
        )}
      </Formik>
    </>
  );
}
