export const fullTypeMap = (type: string) => {
  switch (type) {
    case "callIn":
      return "Eingehendes Telefonat";
    case "callOut":
      return "Ausgehendes Telefonat";
    case "chat":
      return "Chat";
    case "mail":
      return "E-Mail Konversation";
    case "wpoVerweisung":
      return "E-Mail Konversation mit WPO";
    default:
      return "Telefonat";
  }
};
