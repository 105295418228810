import React from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { Select } from "formik-material-ui";
import { Field } from "formik";

import { AccountTariff } from "../../../generated/graphql";
import { translateTariff } from "../../../utils/accountHelper";

type TAccountTariffSelect = { tabIndex?: number };

const AccountTariffSelect = ({ tabIndex = 0 }: TAccountTariffSelect) => {
  return (
    <FormControl fullWidth margin="normal">
      <InputLabel htmlFor="type">Tarif</InputLabel>
      <Field name="tariff" label="Tarif" component={Select} tabIndex={tabIndex}>
        <MenuItem value={AccountTariff.light}>{translateTariff(AccountTariff.light)}</MenuItem>
        <MenuItem value={AccountTariff.basic}>{translateTariff(AccountTariff.basic)}</MenuItem>
        <MenuItem value={AccountTariff.premium}>{translateTariff(AccountTariff.premium)}</MenuItem>
      </Field>
    </FormControl>
  );
};
export default AccountTariffSelect;
