import React, { Fragment } from "react";
import { FormikProps, FormikValues, Form } from "formik";

import Box from "@material-ui/core/Box";

import { Person } from "../../../generated/graphql";
import SlateRTE from "../../molecules/SlateRTE";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import WizardNavigation from "../../molecules/WizardNavigation";

import useStyles from "../reportStyles";
import useAutoSave from "../../../hooks/useAutoSave";

export interface IFormProps {
  person: Person;
  saveInput: (params: any) => any;
  backAction: () => void;
}

export default function StepCMCheck({
  backAction,
  saveInput,
  setFieldValue,
  values,
}: FormikProps<FormikValues> & IFormProps) {
  useAutoSave(values, saveInput);
  const classes = useStyles();

  return (
    <Form className={classes.content}>
      <Fragment>
        <Box maxWidth={900} width="100%">
          <SlateRTE
            value={values.cmCheckMessage}
            label="Begründung für die CM-Prüfung (bitte leer lassen wenn du keine Prüfung veranlassen möchtest)"
            handleChange={value => setFieldValue("cmCheckMessage", value)}
            tabIndex={3}
          />
        </Box>
        <WizardNavigation>
          <SecondaryButton onClick={() => backAction()} tabIndex={5}>
            Zurück
          </SecondaryButton>
          <PrimaryButton type="submit" tabIndex={4}>
            Weiter
          </PrimaryButton>
        </WizardNavigation>
      </Fragment>
    </Form>
  );
}
