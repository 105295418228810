import { useEffect } from "react";
import {
  useRiskHistoryQuery,
  RiskHistorySubscriptionSubscription,
  RiskHistorySubscriptionDocument,
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

export const useRiskHistorySubscription = (personId: number) => {
  const { data, subscribeToMore } = useRiskHistoryQuery({
    variables: {
      personId,
    },
    fetchPolicy,
  });

  useEffect(() => {
    // by typing here, we get rid of the @ts-ignore
    const unsubscribe = subscribeToMore<RiskHistorySubscriptionSubscription>({
      document: RiskHistorySubscriptionDocument,
      variables: { personId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !prev) return prev;

        return { ...prev, ...{ riskHistory: subscriptionData.data.riskHistorySubscription } };
      },
    });

    return unsubscribe;
  });

  return data;
};

export default useRiskHistorySubscription;
