import * as Yup from "yup";

import isValidURL from "../../../utils/isValidURL";

const ServiceResearchObjectValidation = Yup.object().shape({
  note: Yup.string(),
  businessObject: Yup.object().shape({
    name: Yup.string().required("Bitte einen Namen angeben"),
    info: Yup.string(),
    url: Yup.string().test("url", "Bitte überprüfe die angegebene URL.", value =>
      isValidURL(value, true),
    ),
    phones: Yup.array().of(Yup.string()),
    hours: Yup.array().of(Yup.string()),
    email: Yup.object().shape({
      email: Yup.string().email("Bitte überprüfe die angegebene E-Mail."),
    }),
    address: Yup.object().shape({
      street: Yup.string(),
      zip: Yup.string(),
      city: Yup.string(),
    }),
  }),
});

export default ServiceResearchObjectValidation;
