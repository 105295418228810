import React from "react";

import DialogForm from "../DialogForm";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";

import { BusinessObject, useUpdateBusinessObjectMutation } from "../../../generated/graphql";
import { initialValuesHelper } from "../../../utils/initialValueHelper";
import stripTypenames from "../../../utils/stripTypenames";
import PhoneCountrySelect from "../../molecules/PhoneCountrySelect";

type TBusinessContactDialog = {
  business: BusinessObject;
  open: boolean;
  toggle: () => void;
};

const BusinessContactDialog = ({ business, open, toggle }: TBusinessContactDialog) => {
  const [updateBusinessObject] = useUpdateBusinessObjectMutation();

  return (
    <DialogForm
      open={open}
      initialValues={{
        address: {
          street: business.address?.street ?? "",
          city: business.address?.city ?? "",
          zip: business.address?.zip ?? "",
        },
        phone: {
          areaCode: business.phones?.[0]?.areaCode ?? "",
          number: business.phones?.[0]?.number ?? "",
          countryCode: business.phones?.[0]?.countryCode ?? "49",
        },
        email: initialValuesHelper(business.email) || { email: "" },
        url: business.url || "",
      }}
      validationSchema={Yup.object().shape({
        address: Yup.object().shape({
          street: Yup.string().required("Pflichtangabe"),
          city: Yup.string().required("Pflichtangabe"),
          zip: Yup.string().required("Pflichtangabe"),
        }),
      })}
      onClose={toggle}
      title="Kontaktdaten bearbeiten"
      onSubmit={async values => {
        const {
          address: { city, zip, street },
          phone,
          email,
          url,
        } = values;

        await updateBusinessObject({
          variables: {
            id: business.id!,
            input: {
              address: { city, zip, street },
              email: email.email ? stripTypenames(email, "__typename") : null,
              phones: phone
                ? [
                    {
                      id: phone.id,
                      areaCode: phone.areaCode,
                      number: phone.number,
                      countryCode: phone.countryCode,
                    },
                  ]
                : [],
              url: stripTypenames(url, "__typename"),
            },
          },
        });

        toggle();
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <Field name="address.street" label="Straße und Hausnummer" component={TextField} />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <Field name="address.zip" label="Postleitzahl" component={TextField} />
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth margin="normal">
            <Field name="address.city" label="Stadt" component={TextField} />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <PhoneCountrySelect name="phone.countryCode" />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <Field name="phone.areaCode" label="Vorwahl" component={TextField} />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <Field name="phone.number" label="Nummer" component={TextField} />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <Field name="email.email" label="E-Mail-Adresse" component={TextField} />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <Field name="url" label="Webseite" component={TextField} />
          </FormControl>
        </Grid>
      </Grid>
    </DialogForm>
  );
};

export default BusinessContactDialog;
