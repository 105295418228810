import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  red: {
    color: "red",
  },
  yellow: {
    color: "#F3AA18",
  },
}));

export default useStyles;
