import React, { FC } from "react";
import Close from "@material-ui/icons/Close";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

import useStyles from "./styles";

const Down = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="down" ref={ref} {...props} />;
});

const Up = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

type TDefaultDialog = {
  backdrop?: boolean;
  open: boolean;
  onClose?: () => void;
  slideDirection?: string;
  primaryAction?: () => void;
  secondaryAction?: () => void;
  tertiaryAction?: () => void;
};

const DefaultDialog: FC<TDefaultDialog & DialogProps> = props => {
  const classes = useStyles();
  const dialogProps = { ...props };
  dialogProps.backdrop && delete dialogProps.backdrop;

  const close = (_event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (!props.backdrop && reason && reason === "backdropClick") {
      return;
    }
    return props.onClose !== undefined ? props.onClose() : () => undefined;
  };

  return (
    <Dialog    
      maxWidth="md"
      {...dialogProps}
      onClose={close}
      fullWidth
      open={props.open}
      aria-labelledby="form-dialog-title"
      TransitionComponent={props.slideDirection === "up" ? Up : Down}
    >
      {!props.backdrop && !!props.onClose && (
        <Close color="primary" onClick={props.onClose} className={classes.closeIcon} />
      )}
      {props.children}      
    </Dialog>
  );
};
export default DefaultDialog;
