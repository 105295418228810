import React, { useContext } from "react";

import dayjs from "dayjs";
import { createStyles, makeStyles } from "@material-ui/styles";

import { Appointment } from "../../../generated/graphql";

import ShiftSlot from "./ShiftSlot";
import { CurrentIndex } from "../../pages/Appointment/components/Day";
import { TAvatarUser } from "../UserAvatar";

const useStyles = makeStyles(() => {
  return createStyles({
    selectedSlot: {
      position: "absolute",
      overflow: "visible",
      left: "8px",
    },
    slot: {
      "&>rect:not(.spacing):hover": {
        fill: "#6699AF",
      },
      "&>rect:not(.spacing)": {
        cursor: "pointer",
      },
      position: "absolute",
      left: "8px",
    },
  });
});

interface IShiftTimeSlots {
  invalidTimes?: Appointment[];
  isFirstColumn?: boolean;
  isLastColumn?: boolean;
  onClick: (index: number) => void;
  endsAt: dayjs.Dayjs;
  startsAt: dayjs.Dayjs;
  height: number;
  user: TAvatarUser;
  width: number;
  color: string;
}

/**
 *  This component displays the shift time slots as separate svgs for a shift
 */
const ShiftTimeSlots = ({
  invalidTimes,
  isFirstColumn = false,
  isLastColumn = false,
  endsAt,
  height,
  onClick,
  startsAt,
  user,
  width,
  color,
}: IShiftTimeSlots) => {
  const classes = useStyles();
  const { selectedIndex } = useContext(CurrentIndex);  
  // Determine index range of shifts
  const startsAtHourIndex = 4 * (startsAt.hour() - 6);
  const startsAtMinuteIndex = startsAt.minute() === 30 ? 2 : startsAt.minute() === 15 ? 1 : 0;
  const endsAtHourIndex = 4 * (endsAt.hour() - 6);
  const endsAtMinuteIndex = endsAt.minute() === 30 ? 2 : endsAt.minute() === 15 ? 1 : 0;

  const startIndex = startsAtHourIndex + startsAtMinuteIndex;
  const endIndex = endsAtHourIndex + endsAtMinuteIndex;

  // @ts-ignore
  const rects = [...Array(endIndex - startIndex).keys()].map(index => {
    return (
      <React.Fragment key={`shift-${user?.id}-${index}`}>
        <ShiftSlot
          invalidTimes={invalidTimes}
          isFirstColumn={isFirstColumn}
          isLastColumn={isLastColumn}
          startIndex={startIndex}
          height={height}
          width={width}
          index={index}
          onClick={() => onClick(index)}
          color={color}
        />
        {true && (
          <rect
            className="spacing"
            x="0"
            y={height * index}
            width={width}
            height="1"
            fill="rgba(130, 130, 130, 0.3)"
          />
        )}
      </React.Fragment>
    );
  });

  return (
    <svg
      className={selectedIndex < 0 ? classes.slot : classes.selectedSlot}
      style={{
        top: `${height * startIndex}px`,
      }}
      width={width}
      height={height * (endIndex - startIndex)}
      viewBox={`0 0 ${width} ${height * (endIndex - startIndex)}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      {rects}
    </svg>
  );
};
export default ShiftTimeSlots;
