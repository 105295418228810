import React, { useState } from "react";
import classnames from "classnames";
import { Field, FormikProps, FormikValues } from "formik";
import { TextField } from "formik-material-ui";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";

import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import useCommonStyles from "../formStyles";
import { ServiceResearchObjectStatus } from "../../../generated/graphql";
import useStyles from "../formStyles";

interface FormProps {
  cancel: () => void;
  hasSubmitError: boolean;
}

const Form = ({
  cancel,
  isSubmitting,
  handleSubmit,
  setFieldValue,
  values,
  hasSubmitError,
}: FormProps & FormikProps<FormikValues>) => {
  const [changed, setChanged] = useState(false);
  const commonClasses = useCommonStyles();
  const classes = useStyles();

  const handleChange = (name: string, value: string) => {
    setFieldValue(name, value);
  };
  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field>
            {({ field }: any) => (
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="status"
                  name="status"
                  value={values.status}
                  onChange={(e: any, value: string) => {
                    handleChange("status", value);
                    setChanged(true);
                  }}
                  row
                  tabIndex={1}
                >
                  <FormControlLabel
                    value={ServiceResearchObjectStatus.open}
                    control={<Radio />}
                    label="Offen"
                    classes={{ root: commonClasses.radioRoot }}
                  />
                  <FormControlLabel
                    value={ServiceResearchObjectStatus.suitable}
                    control={<Radio />}
                    label="Geeignet"
                    classes={{ root: commonClasses.radioRoot }}
                  />
                  <FormControlLabel
                    value={ServiceResearchObjectStatus.limited}
                    control={<Radio />}
                    label="Bedingt geeignet"
                    classes={{ root: commonClasses.radioRoot }}
                  />
                  <FormControlLabel
                    value={ServiceResearchObjectStatus.unsuitable}
                    control={<Radio />}
                    label="Ungeeignet"
                    classes={{ root: commonClasses.radioRoot }}
                  />
                </RadioGroup>
              </FormControl>
            )}
          </Field>
        </Grid>
      </Grid>
      <Collapse in={changed}>
        <FormControl
          className={classnames(classes.formControl, commonClasses.formControl)}
          margin="normal"
          fullWidth
        >
          <Field name="statusChangeReason" component={TextField} label="Begründung" tabIndex={2} />
        </FormControl>
      </Collapse>
      <Box display="flex" justifyContent="center" mt={4}>
        <SecondaryButton onClick={cancel}>Abbrechen</SecondaryButton>
        <Box mx={1} />
        <PrimaryButton disabled={isSubmitting} type="submit">
          OK
        </PrimaryButton>
      </Box>
      {hasSubmitError ? (
        <Box mt={2}>
          <Alert severity="error">Es gab einen Fehler beim Speichern</Alert>
        </Box>
      ) : null}
    </form>
  );
};

export default Form;
