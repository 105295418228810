import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import MuLink from "@material-ui/core/Link";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { MailClassificationType, useCreateGMailMutation } from "../../../generated/graphql";
import useSessionStorage from "../../../hooks/useSessionStorage";
import { useAuthorization } from "../../../providers/AuthorizationProvider";
import { useEmailThreadContext } from "../../../providers/Email";
import { getOneGMailThread } from "../../../queries/gmail";
import errorIllustration from "../../assets/errorIllustration.svg";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import { EmailMessageContainer } from "../../molecules/EmailMessage";
import Notification from "../../molecules/Notification";
import SlateRTE, { initialFormValue, serializeHtml } from "../../molecules/SlateRTE";
import IllustratedErrorMessage from "../../organisms/IllustratedErrorMessage";
type TEmailDraft = {
  fromAddress: string;
  subject: string;
  classification: string;
  body: any;
  threadId: string;
};
type TEmailThreadAnswer = {
  endRef: React.MutableRefObject<any>;
  writeMode: boolean;
  setWriteMode: React.Dispatch<React.SetStateAction<boolean>>;
};

const EMAIL_DRAFT_PREFIX = "email_draft_thread_";

const EmailThreadAnswer: React.FC<TEmailThreadAnswer> = ({ endRef, writeMode, setWriteMode }) => {
  const { me } = useAuthorization();
  const { goBack } = useHistory();
  const { threadId } = useParams<{ threadId: string }>();
  const [draftEmail, setDraftEmail] = useSessionStorage(
    `${EMAIL_DRAFT_PREFIX}${threadId}`,
    {} as TEmailDraft,
  );

  useEffect(() => {
    if (draftEmail.body) {
      setWriteMode(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!me || !me.user) {
    return (
      <IllustratedErrorMessage
        messages={["Nutzer konnte nicht geladen werden"]}
        illustration={errorIllustration}
        illustrationAltText="Eine Banane wirft Elli um"
      >
        <PrimaryButton onClick={goBack}>Zurück</PrimaryButton>
      </IllustratedErrorMessage>
    );
  }

  return (
    <EmailMessageContainer
      dense={!writeMode}
      isClient={false}
      user={{
        id: me.user.id,
        papershiftWorkingAreas: me.papershiftWorkingAreas,
        person: {
          ...me,
        },
      }}
    >
      {!writeMode ? (
        <Box textAlign="center">
          <MuLink
            color="primary"
            onClick={() => {
              setWriteMode(true);
            }}
            title="Antworten"
            underline="always"
            variant="h4"
          >
            Antworten
          </MuLink>
        </Box>
      ) : (
        <EmailThreadAnswerWrite
          onAbort={() => {
            setWriteMode(false);
            setDraftEmail({} as TEmailDraft);
            sessionStorage.removeItem(`${EMAIL_DRAFT_PREFIX}${threadId}`);
          }}
          endRef={endRef}
          emailDraft={draftEmail}
          setEmailDraft={setDraftEmail}
        />
      )}
    </EmailMessageContainer>
  );
};

type TEmailThreadAnswerWrite = {
  endRef: React.MutableRefObject<any>;
  onAbort: () => void;
  emailDraft: TEmailDraft;
  setEmailDraft: (model: TEmailDraft) => void;
};

const EmailThreadAnswerWrite: React.FC<TEmailThreadAnswerWrite> = ({
  onAbort,
  endRef,
  emailDraft,
  setEmailDraft,
}) => {
  const {
    inbox,
    thread,
    context: { businessObject, person },
  } = useEmailThreadContext();

  const [createGMail] = useCreateGMailMutation();
  const { threadId } = useParams<{ threadId: string }>();
  const [message, setMessage] = useState(emailDraft.body || initialFormValue);
  const [classification, setClassification] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setEmailDraft({
      ...emailDraft,
      body: message,
    });
  }, [message]);

  // useEffect(() => {
  //   let timer = setTimeout(() => {
  //     endRef.current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "start",
  //     });
  //   }, 100);
  //   return () => clearTimeout(timer);
  // }, [message, endRef]);

  const businessObjectId = businessObject?.id;
  const personId = person?.id;
  const sender = inbox?.email;

  if (!thread?.threadId || !sender || (!businessObjectId && !personId) || !thread?.subject) {
    return null;
  }

  return (
    <Box>
      <SlateRTE
        autoFocus
        label="Nachricht"
        handleChange={setMessage}
        padded={false}
        value={message}
      />
      <Box display="flex" justifyContent="center" mt={1}>
        <FormControl fullWidth margin="normal" component="fieldset">
          <FormLabel component="legend">Diese Mail ist:</FormLabel>
          <RadioGroup
            row
            aria-label="classification"
            name="classification"
            value={classification}
            onChange={e => {
              setClassification(e.target.value);
              setEmailDraft({
                ...emailDraft,
                classification: e.target.value as MailClassificationType,
              });
            }}
          >
            <FormControlLabel
              value={MailClassificationType.counseling}
              control={<Radio />}
              label="Eine Beratung"
            />
            <FormControlLabel
              value={MailClassificationType.support}
              control={<Radio />}
              label="Beratungsunterstützend"
            />
            <FormControlLabel
              value={MailClassificationType.service}
              control={<Radio />}
              label="Serviceorientiert"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Typography variant="body2" style={{ fontStyle: "italic" }} color="secondary">
        Die Signatur wird automatisch hinzugefügt.
      </Typography>
      <Box display="flex" justifyContent="center" mt={4}>
        <SecondaryButton onClick={onAbort}>Abbrechen</SecondaryButton>
        <Box mx={1} />
        <PrimaryButton
          disabled={submitting || message.trim().length < 2 || !classification}
          onClick={async () => {
            setSubmitting(true);
            await createGMail({
              variables: {
                classification,
                message: serializeHtml(JSON.parse(message)),
                threadId: thread.threadId!,
                businessObjectId,
                personId,
                sender,
                subject: thread.subject!,
              },
              // For other users, due to polling new messages are received within seconds. For the
              // sending user, however, this delay is reduced by merging the mutation result in the
              // cache
              update: (proxy, { data }) => {
                if (!data) {
                  return;
                }
                // Read the data from our cache for this query.
                const cache: any = proxy.readQuery({
                  query: getOneGMailThread,
                  variables: {
                    threadId,
                  },
                });
                proxy.writeQuery({
                  query: getOneGMailThread,
                  variables: {
                    threadId,
                  },
                  data: {
                    getOneGMailThread: {
                      ...cache?.getOneGMailThread,
                      emails: [data?.createGMail, ...cache?.getOneGMailThread.emails],
                    },
                  },
                });
              },
            });

            setSubmitting(false);

            enqueueSnackbar("", {
              content: (key: string) => (
                <Notification id={key} message={<>E-Mail wurde abgeschickt</>} />
              ),
            });
            onAbort();
          }}
        >
          Absenden
        </PrimaryButton>
      </Box>
    </Box>
  );
};

export default EmailThreadAnswer;
