import React from "react";
import { useParams } from "react-router-dom";
import Loader from "../../atoms/Loading";
import PersonLayout from "../../templates/PersonLayout";

import STCDashboard from "../../organisms/STCDashboard";

import { useMandateQuery } from "../../../generated/graphql";
import { fetchPolicy } from "../../../utils/constants";
import useScrollTop from "../../../hooks/useScrollTop";

export default function CounselingMandate() {
  useScrollTop();
  const { mandateId } = useParams<{ mandateId: string }>();

  const { data, loading } = useMandateQuery({
    variables: { id: parseInt(mandateId, 10) },
    fetchPolicy,
  });

  if (loading) {
    return <Loader />;
  }

  const mandate = data?.mandate;

  if (!mandate) {
    return (
      <div>
        <div>Beratung wurde nicht gefunden.</div>
      </div>
    );
  }
  const counselor = mandate.counselor;

  if (!counselor || !counselor.id) {
    return (
      <div>
        <div>Person wurde nicht gefunden</div>
      </div>
    );
  }
  const business = mandate.business;
  const service = mandate.service;
  const clientName = service?.client?.person?.name || "";
  const clientSalut = service?.client?.person?.salut || "";
  if (!service || !service.id) {
    return (
      <div>
        <div>Beratung wurde nicht gefunden</div>
      </div>
    );
  }
  const name = business
    ? {
        name: business.name,
        path: `/business/${business.id}`,
      }
    : null;
  const namePathMap = [
    {
      name: `Beratungsmandat${
        !!clientName ? ` ${!!clientSalut ? `${clientSalut} ` : ""}${clientName}` : ""
      }`,
      path: "",
    },
  ];

  return (
    <PersonLayout
      counselor
      person={counselor}
      serviceId={service.id}
      mapList={name ? [name, ...namePathMap] : namePathMap}
    >
      <STCDashboard
        service={service}
        mandate={mandate}
        counselorView
        refNumber={400000000 + service.id}
      ></STCDashboard>
    </PersonLayout>
  );
}
