import React from "react";

import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { Theme } from "@material-ui/core";
import { useServiceCategoriesQuery, Account } from "../../../generated/graphql";
import { fetchPolicy } from "../../../utils/constants";
const useStyles = makeStyles((theme: Theme) => {
  return {
    list: {
      paddingInlineStart: "16px",
      marginBlockStart: 0,
    },
  };
});

type TAccountModuleList = { account: Account; small?: boolean };

const AccountModuleList = ({ account, small = false }: TAccountModuleList) => {
  const { data } = useServiceCategoriesQuery({ fetchPolicy });
  const classes = useStyles();
  const categories = data?.serviceCategories || [];
  const allModules = account?.accountServiceModules || [];
  return !!allModules.length ? (
    <ul className={classes.list}>
      {categories?.map(category => {
        const modules = allModules.filter(sm => sm && sm.serviceCategoryId! === category.id);
        return (
          !!modules.length && (
            <li key={`module-list-${category.id}`}>
              <Typography variant={small ? "body2" : "body1"} gutterBottom>
                {category.name}
              </Typography>
              {!!category?.serviceMetas && category?.serviceMetas.length !== modules.length && (
                <Typography variant="body2" gutterBottom>
                  {modules.reduce((acc, sm) => {
                    if (acc === "") return sm!.name!;
                    return `${acc}, ${sm!.name!}`;
                  }, "")}
                </Typography>
              )}
            </li>
          )
        );
      })}
    </ul>
  ) : (
    <Typography variant="body1" gutterBottom>
      Keine Service Module gebucht
    </Typography>
  );
};
export default AccountModuleList;
