import React, { FC, ReactNode } from "react";

import { Link } from "react-router-dom";
import dayjs from "dayjs";

import classnames from "classnames";
import Today from "@material-ui/icons/Today";
import WatchLater from "@material-ui/icons/WatchLater";
import Email from "@material-ui/icons/Email";
import { Grid, Box, Typography } from "@material-ui/core";
import MuLink from "@material-ui/core/Link";

import { Person, Topic, Service, BusinessObject } from "../../../generated/graphql";
import TaskIcon from "../../atoms/TaskIcon";
import ClientAvatar from "../../atoms/ClientAvatar";
import Markup from "../../atoms/Markup";
import TopicOrServiceLink from "../../atoms/TopicOrServiceLink";

import { InboxWrapper, TimeText } from "./components";
import useStyles from "../../assets/inboxStyles";

type TInboxComponent = {
  actions: ReactNode;
  dates: {
    dueDate?: string | null;
    snoozedDate?: string | null;
    end?: string;
    start?: string;
  };
  assigneeAvatar?: ReactNode;
  isAppointment?: boolean;
  isEmail?: boolean;
  leading?: ReactNode;
  note?: string | null;
  overdue?: boolean;
  context: {
    person?: Person | null;
    businessObject?: BusinessObject | null;
    service?: Service | null;
    topic?: Topic | null;
  };
  title: {
    name: string;
    team?: string | null;
    link?: any;
    onClick?: any;
  };
};

export const InboxComponent: FC<TInboxComponent> = ({
  actions,
  dates,
  assigneeAvatar,
  isAppointment = false,
  isEmail = false,
  leading,
  note,
  overdue = false,
  context,
  title,  
}) => {
  const classes = useStyles();
  const { businessObject, person, topic, service } = context;
  const accountName = person?.client?.account?.name;

  return (
    <InboxWrapper>
      <Grid item xs={12} md={4} className={classes.taskContentColumn}>
        {!!leading && <Box mr={3}>{leading}</Box>}
        {isAppointment && <Today color="primary" />}
        {isEmail && <Email color="primary" />}
        {!isAppointment && !isEmail && <TaskIcon />}
        <Box ml={2} display="flex" alignItems="start" flexDirection="column" overflow="hidden">
          {!!title.onClick || !!title.link ? (
            <MuLink
              color={"primary"}
              className={classnames(classes.taskLink, classes.onelineEllipsis)}
              underline="always"
              variant="h4"
              component={!title.onClick ? Link : Typography}
              onClick={title.onClick}
              to={title.link}
              title={title.name + (title.team  ? ` (${title.team}) ` : '')}
            >
              {title.name}              
            </MuLink>
          ) : (
            <Typography variant="body2">{title.name}</Typography>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} md={isEmail ? 2 : 2} className={classes.taskContentColumn}>
        <Box mr={2} color="grey.400" width="24px" height="24px">
          {!!dates.snoozedDate && <WatchLater color="inherit"></WatchLater>}
        </Box>
        {!!assigneeAvatar && <Box mr={2}>{assigneeAvatar}</Box>}
        <DateColumn
          dueDate={dates.dueDate}
          end={dates.end}
          isEmail={isEmail}
          overdue={overdue}
          snoozedDate={dates.snoozedDate}
          start={dates.start || dayjs().toISOString()}
        />
      </Grid>
      <Grid item xs={12} md={isEmail ? 3 : 3} className={classes.taskContentColumn}>
        {businessObject && businessObject.name && (
          <Box paddingX={2}>
            <Box>
              <MuLink
                to={`/business/${businessObject.id}`}
                component={Link}
                className={classnames([classes.onelineEllipsis])}
                title={businessObject.name}
                color="primary"
                underline="always"
                variant="h4"
              >
                {businessObject.name}
              </MuLink>
            </Box>
          </Box>
        )}
        {person && !person.temporary && (
          <Box display="flex" alignItems="center">
            <Link to={`/person/${person.id || ""}`} className={classes.noLinkStyle}>
              <ClientAvatar person={person} tiny />
            </Link>
            <Box paddingX={2}>
              <Box>
                <MuLink
                  to={`/person/${person.id || ""}`}
                  component={Link}
                  className={classnames([classes.onelineEllipsis])}
                  title={person?.name!}
                  color="primary"
                  underline="always"
                  variant="h4"
                >
                  {person?.name}
                  {!!accountName && `, ${accountName}`}
                </MuLink>
              </Box>
              <TopicOrServiceLink person={person} topic={topic} service={service} />
            </Box>
          </Box>
        )}

        {person && person.temporary && (
          <Typography color="secondary"> {person.emails && person.emails[0]?.email}</Typography>
        )}
      </Grid>
      <Grid item xs={12} md={isEmail ? 3 : 3} className={classes.taskContentColumn}>
        {!!note && <Markup value={note} />}
        {actions}
      </Grid>
    </InboxWrapper>
  );
};

type TDateColumn = {
  isEmail: boolean;
  dueDate?: string | null;
  snoozedDate?: string | null;
  overdue?: boolean;
  start?: string;
  end?: string;
};
const DateColumn: FC<TDateColumn> = ({
  isEmail,
  dueDate,
  snoozedDate,
  overdue = false,
  start = dayjs().toISOString(),
  end,
}) => {
  if (snoozedDate) {
    return <TimeText start={snoozedDate} text="Bis" overdue={overdue} time />;
  }
  if (dueDate) {
    return <TimeText start={dueDate} text="Fälligkeit" time={false} overdue={overdue} />;
  }
  if (isEmail) {
    return <TimeText start={start} text="" />;
  }

  return <TimeText start={start} end={end} text={!!end ? "" : "Erstellt"} overdue={overdue} />;
};

export default InboxComponent;
