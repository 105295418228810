import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { useParams } from "react-router-dom";
import { useBreadCrumbInfoByAccountIdQuery } from "../../../generated/graphql";
import { useBusinessSubscription } from "../../../hooks/useBusinessSubscription";
import Loader from "../../atoms/Loading";
import AccountStatusBadge from "../../molecules/AccountStatusBadge";
import BusinessHeader from "../../molecules/BusinessHeader";
import AccountDetails from "../../organisms/AccountDetails";
import BusinessDetails from "../../organisms/BusinessDetails";

export default function BusinessMasterData() {
  const { id } = useParams<{ id: string }>();

  const { businessObject, loading, error } = useBusinessSubscription(parseInt(id!));
  const accountId = businessObject?.accountId;
  const { data: breadcrumbData } = useBreadCrumbInfoByAccountIdQuery({
    skip: !accountId,
    variables: {
      id: accountId!,
    },
  });
  if (loading) return <Loader />;
  const mainPath = businessObject?.accountId
    ? [
        { path: "/account", name: "Accounts" },
        {
          path: `/account/${businessObject?.accountId}`,
          name: businessObject?.name,
          chip: (
            <AccountStatusBadge
              contractEnd={breadcrumbData?.breadCrumbInfoByAccountId?.contractEnd}
              lang={breadcrumbData?.breadCrumbInfoByAccountId?.language || ""}
            />
          ),
        },
      ]
    : [{ path: `/business/${businessObject?.id}`, name: businessObject?.name }];
  const mapList = [
    ...mainPath,
    {
      path: "",
      name: "Allgemeine Informationen",
    },
  ];
  if (!businessObject) {
    return <>Unternehmen konnte nicht geladen werden</>;
  }
  return (
    <>
      <BusinessHeader
        mapList={mapList}
        contractExpirationDate={breadcrumbData?.breadCrumbInfoByAccountId?.contractEnd}
      />
      <Box mt={20} mb={11}>
        <Grid container>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            {businessObject?.accountId ? (
              <AccountDetails id={businessObject.accountId} />
            ) : (
              <BusinessDetails business={businessObject} />
            )}{" "}
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Box>
    </>
  );
}
