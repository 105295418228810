import React, { FC, useContext } from "react";

import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import { useReassignChatMutation } from "../../../generated/graphql";
import { ChatContext } from "../../../containers/ChatListener";
import AssignModal from "../AssignDialog";

const AssignChatModal: FC<{
  chatUserId: number;
  chatId: number;
  open: boolean;
  onClose: () => void;
}> = ({ chatUserId, chatId, open, onClose }) => {
  const [reassignChat] = useReassignChatMutation();
  const { dispatch } = useContext(ChatContext);
  const { push } = useHistory();
  const { personId } = useParams<{ personId: string }>();

  if (!personId) {
    return null;
  }

  return (
    <AssignModal
      open={open}
      onClose={onClose}
      onSubmit={async values => {
        dispatch({
          type: "disconnectChat",
          id: chatUserId,
        });

        await reassignChat({
          variables: {
            chatId,
            ...(values.team !== -1 ? { team: values.team } : {}),
            ...(values.userIds !== -1 ? { userId: values.userIds } : {}),
          },
        });

        push(`/person/${personId}`);
      }}
    />
  );
};

export default AssignChatModal;
