import React, { useState, useEffect } from "react";
import { useParams, Redirect, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import {
  useReportPersonQuery,
  useConversationReportQuery,
  ReportState,
  useUpdateConversationReportMutation,
  ClientReviewAttributeGroup,
  ConversationReportReviewAttribute,
  Person,
  ConversationReport,
  AccountType,
} from "../../../generated/graphql";
import IReviewStep from "../../../interfaces/IReviewStep";
import Loader from "../../atoms/Loading";
import ReportUpdateFormStep1 from "../../forms/ReportUpdateFormStep1";
import ReportWizard from "../../forms/ReportWizard";
import ReviewWizard from "../../forms/ReviewWizard";
import IllustratedErrorMessage from "../../organisms/IllustratedErrorMessage";
import PrimaryButton from "../../atoms/PrimaryButton";

import { fetchPolicy } from "../../../utils/constants";
import { getPrevNext, notReportStates } from "../../../utils/reportHelper";
import useStyles from "./styles";

import errorIllustration from "../../assets/errorIllustration.svg";

const ConversationReportUpdate = () => {
  const classes = useStyles();
  const { personId, reportId } = useParams<{ personId: string; reportId: string }>();
  const { goBack } = useHistory();
  const personQuery = useReportPersonQuery({
    variables: { id: parseInt(personId, 10) },
    fetchPolicy,
  });

  const reportQuery = useConversationReportQuery({
    variables: { id: parseInt(reportId, 10) },
    fetchPolicy,
  });

  const [updateReport] = useUpdateConversationReportMutation();

  const person = personQuery.data?.person;
  const report = reportQuery.data?.conversationReport;
  let reviewSteps: IReviewStep[] = [];

  const [step, setStep] = useState(report?.status);

  useEffect(() => {
    if (report && report.status) {
      setStep(report.status);
    }
  }, [report]);

  // TODO: Check wether it is allowed to update report in the context of this person! Might lead to
  // hard to spot mistakes otherwise!
  if (report) {
    if (report.client?.person?.id !== parseInt(personId, 10)) {
      return <Redirect to="/not-allowed" />;
    }
  }

  if (
    !reportQuery.loading &&
    !personQuery.loading &&
    (reportQuery.error || personQuery.error || step === undefined || step === null)
  ) {
    return (
      <IllustratedErrorMessage
        messages={["Es ist ein Fehler beim Laden des Gesprächsprotokolls aufgetreten."]}
        illustration={errorIllustration}
        illustrationAltText="Elli rutscht auf einer Banane aus"
      >
        <PrimaryButton onClick={goBack}>Zurück</PrimaryButton>
      </IllustratedErrorMessage>
    );
  }

  if (!step || !person || !report) {
    return <Loader />;
  }

  if ([ReportState.inReview, ReportState.inServiceReview, ReportState.finished].includes(step)) {
    reviewSteps = report
      ? // @ts-ignore
        ((report.conversationReportReviewAttributes ||
          []) as ConversationReportReviewAttribute[]).reduce(
          (acc: IReviewStep[], attr: ConversationReportReviewAttribute) => {
            const curTemplateGroup: ClientReviewAttributeGroup | undefined | null =
              attr.clientReviewAttribute?.clientReviewAttributeGroup;

            if (!curTemplateGroup || !curTemplateGroup.clientReviewTemplate) {
              return acc;
            }
            const groupIdx = acc.findIndex((_: any) => _.id === curTemplateGroup.id);
            if (groupIdx === -1) {
              acc.push({
                ...curTemplateGroup,
                attrs: [],
                groupId: curTemplateGroup.id,
                templateId: curTemplateGroup.clientReviewTemplate.id,
                templateName: curTemplateGroup.clientReviewTemplate.name,
                templateSortIdx: curTemplateGroup.clientReviewTemplate.sortIdx,
              });
            }
            acc[groupIdx === -1 ? acc.length - 1 : groupIdx].attrs.push({
              ...attr.clientReviewAttribute,
              id: attr.id,
              value: attr.attributeValue || null,
            });
            return acc;
          },
          [],
        )
      : [];

    reviewSteps.sort((a: IReviewStep, b: IReviewStep) => {
      if (a.templateSortIdx && b.templateSortIdx) {
        if (a.pageOrder && b.pageOrder && a.templateSortIdx - b.templateSortIdx === 0) {
          return a.pageOrder - b.pageOrder;
        }
        return a.templateSortIdx - b.templateSortIdx;
      }
      return -1;
    });
  }

  const shouldRenderReportWizard = !notReportStates.includes(step);

  const curReviewStepIndex = () => {
    const activeGroupId = report?.activeClientReviewAttributeGroup?.id;
    const activeTemplateId = report?.activeClientReviewTemplate?.id;
    if (!activeGroupId || !activeTemplateId) {
      return 0;
    }
    return reviewSteps.findIndex(
      (_: any) => _.groupId === activeGroupId && _.templateId === activeTemplateId,
    );
  };

  // returns previous review step indicated by review template id and review group id
  const getPreviousReviewStep = () => {
    if (reviewSteps.length < 1 || curReviewStepIndex() < 1) {
      return null;
    }

    if (step === ReportState.finished || step === ReportState.inServiceReview) {
      return {
        activeClientReviewAttributeGroupId: reviewSteps[curReviewStepIndex()].groupId,
        activeClientReviewTemplateId: reviewSteps[curReviewStepIndex()].templateId,
      };
    }
    if (step === ReportState.inReview) {
      return {
        activeClientReviewAttributeGroupId: reviewSteps[curReviewStepIndex() - 1].groupId,
        activeClientReviewTemplateId: reviewSteps[curReviewStepIndex() - 1].templateId,
      };
    }
    return null;
  };

  // returns next review step indicated by review template id and review group id
  const getNextReviewStep = () => {
    if (
      step !== ReportState.inReview ||
      reviewSteps.length < 1 ||
      reviewSteps.length === curReviewStepIndex() + 1
    ) {
      return null;
    }
    return {
      activeClientReviewAttributeGroupId: reviewSteps[curReviewStepIndex() + 1].groupId,
      activeClientReviewTemplateId: reviewSteps[curReviewStepIndex() + 1].templateId,
    };
  };

  const services: any[] = report?.services || [];

  const getPreviousStatus = () => {
    const previousStatus = getPrevNext(step, {
      hasServices: services.length > 0,
      needsReview: !!report?.activeClientReviewAttributeGroup,
      hasRisk: !!report?.risk,
      doesReview: !!report?.activeClientReviewAttributeGroup,
      accountType: person?.client?.account?.type || AccountType.eap,
      next: false,
    });

    // we need this sonderlocke for the review wizard
    return step === ReportState.inReview && getPreviousReviewStep() ? step : previousStatus;
  };

  const backAction = () => {
    if (!report) {
      return;
    }

    updateReport({
      variables: {
        report: {
          id: report.id,
          status: getPreviousStatus(),
          ...getPreviousReviewStep(),
        },
      },
    });
  };

  function renderForm({
    person,
    report,
    step,
  }: {
    person: Person;
    report: ConversationReport;
    step: ReportState;
  }) {
    if (shouldRenderReportWizard) {
      return <ReportWizard person={person} report={report} step={step} backAction={backAction} />;
    } else if (
      step === ReportState.inReview &&
      report.conversationReportReviewAttributes &&
      report.conversationReportReviewAttributes.length
    ) {
      return (
        <ReviewWizard
          activeReviewStep={reviewSteps[curReviewStepIndex()]}
          nextReviewStep={getNextReviewStep()}
          backAction={backAction}
          person={person}
          report={report}
          step={step}
        />
      );
    } else {
      // ReportUpdateFormStep1 rednders MemoReportFormStep1 (might be even further unified)
      return (
        <ReportUpdateFormStep1
          person={person}
          report={report}
          customCallBack={() => {
            updateReport({
              variables: {
                report: {
                  id: report.id,
                  status: ReportState.issued,
                },
              },
            });
            personQuery.refetch();
          }}
        />
      );
    }
  }

  const gridMain = (size: string) => {
    if (![ReportState.workStats, ReportState.privateStats].includes(step)) {
      return size === "xs" ? 8 : 6;
    }
    return 12;
  };

  const isStatistic = [ReportState.workStats, ReportState.privateStats].includes(step);

  return (
    <Grid container className={classes.content}>
      {!isStatistic ? <Grid item xs={2} xl={3} /> : null}
      <Grid item xs={gridMain("xs")} xl={gridMain("lg")}>
        {renderForm({
          person,
          step,
          report: report as ConversationReport,
        })}
      </Grid>
      {!isStatistic ? <Grid item xs={2} xl={3} /> : null}
    </Grid>
  );
};

export default ConversationReportUpdate;
