import React from "react";
import classnames from "classnames";
import useStyles from "./styles";
import SvgIcon from "@material-ui/core/SvgIcon";

type TDivider = { className?: string };
const Divider = ({ className }: TDivider) => {
  const classes = useStyles();
  return (
    <SvgIcon viewBox="0 0 27 66" fill="none" className={classnames(className, classes.icon)}>
      <path d="M1 1L25.5 33L1 65" stroke="#BDBDBD" fill="none" />
    </SvgIcon>
  );
};
export default Divider;
