import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { useBreadCrumbInfoByAccountIdQuery } from "../../../generated/graphql";
import { useAccountSubscription } from "../../../hooks/useAccountSubscription";
import useContractStatus from "../../../hooks/useContractStatus";
import Loader from "../../atoms/Loading";
import AccountStatusBadge from "../../molecules/AccountStatusBadge";
import BusinessHeader from "../../molecules/BusinessHeader";
import AccountContractDetails from "../../organisms/AccountContractDetails";

const AccountContract: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { account, loading } = useAccountSubscription(parseInt(id, 10), !id);
  const accountId = account?.id;
  const { data: breadcrumbData } = useBreadCrumbInfoByAccountIdQuery({
    skip: !accountId,
    variables: {
      id: accountId!,
    },
  });
  const isAccountExpired = useContractStatus(account?.contractEnd);
  const mapList = [
    {
      name: "Accounts",
      path: "/account",
    },
    {
      name: account?.name || "",
      path: `/account/${account?.id}`,
      chip: (
        <AccountStatusBadge
          contractEnd={breadcrumbData?.breadCrumbInfoByAccountId?.contractEnd}
          lang={breadcrumbData?.breadCrumbInfoByAccountId?.language || ""}
        />
      ),
    },
    {
      name: "Vertragsdaten",
      path: "",
    },
  ];
  return !account || loading ? (
    <Loader />
  ) : (
    <>
      <BusinessHeader
        mapList={mapList}
        accountExpired={isAccountExpired}
        language={breadcrumbData?.breadCrumbInfoByAccountId?.language || ""}
      />
      <Box mt={20} mb={11}>
        <Grid container>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <AccountContractDetails account={account}></AccountContractDetails>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AccountContract;
