import React, { useState } from "react";

import { KeyboardDatePicker } from "@material-ui/pickers";
import { TextField } from "formik-material-ui";
import MUITextField from "@material-ui/core/TextField";
import { withStyles, Theme } from "@material-ui/core";

import useOutsideClick from "../../../hooks/useOutsideClick";

import { StyledField } from "./styles";

type TCustomField = {
  identifier: string;
  isEmpty: boolean;
  initialStatus: boolean;
  label: string;
  name: string;
  tabIndex: number;
};

export const CustomField = ({
  identifier,
  isEmpty,
  initialStatus,
  label,
  name,
  tabIndex,
}: TCustomField) => {
  const [isEditable, setEditable] = useState(initialStatus);
  const { ref } = useOutsideClick(setEditable);

  return (
    <div
      ref={ref}
      key={identifier}
      onKeyDown={e => {
        if (e.keyCode === 9) {
          setEditable(false);
        }
      }}
    >
      <StyledField
        disabled={!isEmpty && !initialStatus && !isEditable}
        onFocus={() => setEditable(true)}
        name={name}
        component={TextField}
        label={label}
        tabIndex={tabIndex}
      />
    </div>
  );
};

type TCustomAutocompleteTextField = {
  identifier: string;
  isEmpty: boolean;
  initialStatus: boolean;
  label: string;
  name: string;
  params: object;
  tabIndex?: number;
};

export const CustomAutocompleteTextField = ({
  identifier,
  isEmpty,
  initialStatus,
  label,
  name,
  params,
  tabIndex,
}: TCustomAutocompleteTextField) => {
  const [isEditable, setEditable] = useState(initialStatus);
  const { ref } = useOutsideClick(setEditable);
  return (
    <MUITextField
      ref={ref}
      key={identifier}
      name={name}
      {...params}
      label={label}
      fullWidth
      tabIndex={tabIndex}
      disabled={!isEmpty && !initialStatus && !isEditable}
    />
  );
};

export const StyledKeyboardDatePicker = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
}))(KeyboardDatePicker);
