import React from "react";
import classnames from "classnames";

import InboxLabelFilter from "../../molecules/InboxLabelFilter";
import Grid from "@material-ui/core/Grid";
import ClientList from "../../organisms/ClientList";
import { useAuthorization } from "../../../providers/AuthorizationProvider";
import useStyles from "../inboxStyles";

export default function MyClients() {
  const { isAuthorized } = useAuthorization();
  const classes = useStyles();
  return (
    <Grid container className={classnames(classes.content, classes.contentBox)}>
      <InboxLabelFilter />
      <Grid container>
        <Grid item xs={12}>
          {isAuthorized("cmpage") && <ClientList.CMClients />}
          <ClientList.StcClients />
        </Grid>
      </Grid>
    </Grid>
  );
}
