import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import dayjs from "dayjs";
import React, { FC } from "react";
import { useAccountsQuery } from "../../../generated/graphql";
import { fetchPolicy } from "../../../utils/constants";
import { MuiAutoCompleteWrapper } from "../../molecules/Autocomplete";

type AccountSelectProps = {
  formError?: boolean;
  onBlur: () => void;
  onChange: (value: any) => void;
  value: any;
};

export const getContractEnd = (date: any) => {
  const isContractExpired = dayjs(date).isBefore(dayjs());
  const alertDate = dayjs().add(3, "month");
  if (isContractExpired) {
    return "(Vertrag beendet)";
  }

  if (dayjs(date).isBefore(alertDate)) {
    return `(Vertragsende: ${dayjs(date).format("DD.MM.YY")})`;
  }

  return "";
};

const AccountSelect: FC<AccountSelectProps> = ({ formError, onBlur, onChange, value }) => {
  const { data, error } = useAccountsQuery({ fetchPolicy });

  const options = [
    { label: "Bitte auswählen", value: null },
    ...(data?.accounts?.map(account => {
      return {
        label: `${account?.name} ${getContractEnd(account?.contractEnd)}`,
        value: account?.id,
      };
    }) || []),
  ];

  if (error) {
    return <>Accounts konnten nicht geladen werden.</>;
  }

  return (
    <FormControl fullWidth margin="normal" error={formError}>
      <MuiAutoCompleteWrapper
        error={formError}
        label="Account"
        id="account"
        getOptionSelected={option => option.id === value.value}
        noOptionsText="Account wurde nicht gefunden"
        options={options || []}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
      />
      {formError && <FormHelperText error={formError}>Bitte ausfüllen</FormHelperText>}
    </FormControl>
  );
};

export default AccountSelect;
