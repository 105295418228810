import React, { FC } from "react";
import DOMPurify from "dompurify";
import { useSnackbar } from "notistack";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// @ts-ignore

import dayjs from "dayjs";

import Avatar from "../../atoms/Avatar";
import { streamFormat } from "../../../utils/timeFormats";
import {
  Attachment,
  Maybe,
  useGetAttachmentDataQuery,
  GetOneThreadByActivityQuery,
} from "../../../generated/graphql";
import TypeIcon from "../../atoms/TypeIcon";
import { DownloadTextLink } from "../../atoms/TextLink";
import Notification from "../../molecules/Notification";

import elliBlue from "../../assets/elliBlue.svg";
import useStyles from "./styles";
import UserAvatar, { TAvatarUser } from "../../organisms/UserAvatar";

export type TEmailMessage = {
  attachments?: Maybe<Attachment>[] | null;
  avatarKeys?: string | null;
  isClient: boolean;
  messageId?: string | null;
  subject: string;
  text: string;
  time: dayjs.Dayjs;
  user?: GetOneThreadByActivityQuery["getOneThreadByActivity"][0]["user"];
};

export const EmailMessage = ({
  attachments,
  avatarKeys,
  isClient,
  subject,
  messageId,
  text,
  time,
  user,
}: TEmailMessage) => {
  return (
    <EmailMessageContainer user={user} avatarKeys={avatarKeys} isClient={isClient}>
      <Box
        display="flex"
        justifyContent="space-between"
        width={1}
        pb={2}
        mb={3}
        borderBottom="solid #BDBDBD 1px"
      >
        <Typography variant="body2">
          {isClient ? "Eingehende E-Mail" : "Ausgehende E-Mail"}
        </Typography>
        <Typography variant="body2">{time.format(streamFormat(time))}</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="h4">{subject}</Typography>
      </Box>
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }} />
      {attachments?.map(attachment => {
        return attachment && messageId ? (
          <EmailAttachment
            key={`attachments-${attachment?.attachmentId}`}
            messageId={messageId}
            attachment={attachment}
          />
        ) : null;
      })}
    </EmailMessageContainer>
  );
};

export type TEmailAttachment = {
  attachment: Attachment;
  messageId: string;
};

export const EmailAttachment = ({ attachment, messageId }: TEmailAttachment) => {
  const { data, loading } = useGetAttachmentDataQuery({
    variables: { messageId, id: attachment.attachmentId! },
    skip: !attachment.attachmentId,
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleAttachmentClick = () => {
    enqueueSnackbar("", {
      content: (key: string) => (
        <Notification
          id={key}
          message={
            <>
              Bitte nicht vergessen das Dokument
              <br />
              in GoogleDrive zu speichern und mit dem <br />
              Klienten zu verknüpfen.
            </>
          }
        />
      ),
    });
  };

  return (
    <Box display="flex" alignItems="center" key={`attachments-${attachment?.attachmentId}`}>
      <TypeIcon type="insertDriveFile" />
      <Box mx={0.5} />
      <DownloadTextLink
        onClick={handleAttachmentClick}
        href={`data:${attachment.mimeType};base64,${data?.getAttachmentData}`}
        download={attachment?.filename || "Download"}
        disabled={loading}
      >
        {attachment?.filename}
      </DownloadTextLink>
    </Box>
  );
};

export type TEmailMessageContainer = {
  avatarKeys?: string | null;
  dense?: boolean;
  isClient: boolean;
  user?: TAvatarUser | null;
};

export const EmailMessageContainer: FC<TEmailMessageContainer> = ({
  avatarKeys,
  children,
  dense = false,
  isClient,
  user,
}) => {
  const classes = useStyles(isClient);

  let avatar;

  if (isClient) {
    avatar = (
      <Avatar className={classes.avatar} widthfactor={5}>
        {avatarKeys}
      </Avatar>
    );
  } else if (!!user) {
    avatar = (
      <div className={classes.avatar}>
        <UserAvatar user={user} />
      </div>
    );
  } else {
    avatar = <Avatar className={classes.avatar} src={elliBlue} widthfactor={5} />;
  }

  return (
    <Box
      className={classes.bubble}
      display="flex"
      flexDirection={isClient ? "row" : "row-reverse"}
      my={4}
      position="relative"
      width={1}
    >
      {avatar}

      <Box width={1} bgcolor={isClient ? "#F2FCFF" : "#FFFFFF"} p={dense ? 2 : 4}>
        {children}
      </Box>
    </Box>
  );
};

export default EmailMessage;
