import React from "react";

import Async from "react-select/async";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper/Paper";
import makeStyles from "@material-ui/styles/makeStyles/makeStyles";

import { PersonSearchDocument, PersonSearchQuery } from "../../../../generated/graphql";

import useStyles, { CustomComponents, inputComponent } from "../components";
import useDebouncedSearch, {
  computeLevenshteinDistance,
  sortByDistance,
  sortClientFirst,
} from "../hooks";
import { formatPerson } from "../Person";
import { formatBusiness } from "../BusinessObject";
import { formatAccount } from "../Account";
import { useMeQuery } from "../../../../generated/graphql";

const formatData = (name: string, data?: PersonSearchQuery) => {
  const results = data?.personSearch;

  if (!results) return [];

  const persons = results.persons?.map(formatPerson) || [];
  const businesses = results.businesses?.map(formatBusiness) || [];
  const accounts = results.accounts?.map(formatAccount) || [];

  return [...persons, ...businesses, ...accounts]
    .map(option => computeLevenshteinDistance(option, name))
    .sort(sortByDistance)
  // .sort(sortClientFirst);
};

type TGlobalSearch = { onClick: (selection: any) => void };

const GlobalSearch = (props: TGlobalSearch) => {
  const classes = useStyles();
  const { data: meData } = useMeQuery({ fetchPolicy: "cache-first" });

  const loadOptions = useDebouncedSearch(
    meData?.me?.user?.id,
    300,
    {
      query: PersonSearchDocument,
    },
    formatData,
  );

  return (
    <Box width={400}>
      <Async
        autoFocus
        isClearable
        classes={classes}
        components={{
          ...CustomComponents,
          Control,
          Menu,
          DropdownIndicator: null,
        }}
        placeholder="Suche"
        onChange={props.onClick}
        loadOptions={loadOptions}
      />
    </Box>
  );
};

export default GlobalSearch;

/**
 * Overwrites specific for this component
 */
function Menu(props: any) {
  return (
    <Paper
      elevation={0}
      square
      style={{ border: "none", borderTop: "solid #ddd 1px" }}
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const useInputClasses = makeStyles({
  root: (props: { menuIsOpen: boolean }) => ({
    background: "#fff",
    padding: "0 16px",
    color: "blue",
    borderRadius: 4,
    borderBottomLeftRadius: props.menuIsOpen ? 0 : 4,
    borderBottomRightRadius: props.menuIsOpen ? 0 : 4,
  }),
  disabled: {},
  underline: {
    "&:after": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    },
    "&:hover:not($disabled):before": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    },
  },
});

function Control(props: any) {
  const inputClasses = useInputClasses({ menuIsOpen: props.menuIsOpen });

  return (
    <TextField
      autoFocus
      fullWidth
      autoComplete="off"
      error={props.selectProps.error}
      InputProps={{
        classes: inputClasses,
        disableUnderline: true,
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
          autoComplete: "off",
        },
      }}
      InputLabelProps={{ shrink: true }}
      {...props.selectProps.textFieldProps}
    />
  );
}
