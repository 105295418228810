import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  disabled: {
    //filter: "grayscale(100%)",
    "&> div img,h3": {      
      opacity: .3
    }    
  },
  categoryButton: {
    background: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius * 2.5,
    display: "flex",
    flexDirection: "column",
    height: 200,
    justifyContent: "start",
    width: 280,
  },
  inFirstRow: {
    gridRow: 1,
    gridColumn: 1,
  },
  inSecondRow: {
    gridRow: 2,
    gridColumn: 1,
  },
  icon: {
    color: theme.palette.common.white,
    height: 72,
    width: 72,
  },
  categoryName: {
    lineHeight: "24px",
    maxWidth: theme.spacing(21),
  },
  categoryRoot: {
    display: "grid",
    gridColumnGap: theme.spacing(3),
    gridRowGap: theme.spacing(3),
    gridTemplateColumns: "1fr 1fr",
  },
  grid5: {
    gridTemplateColumns: "1fr 1fr 1fr",
  },
  list: {
    width: "100%",
    "&>div": {
      "&:last-child": {
        "&:before": {
          background: theme.palette.grey[400],
          bottom: 0,
          content: '""',
          height: 1,
          left: 0,
          position: "absolute" as "absolute",
          width: "100%",
        },
      },
    },
  },
  listItem: {
    alignItems: "center",
    background: "transparent",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1.5, 1, 1.5, 2),
    position: "relative",
    transition: `background ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
    color: theme.palette.primary.main,
    width: "100%",
    "&:after": {
      background: theme.palette.grey[400],
      top: 0,
      content: '""',
      height: 1,
      left: 0,
      position: "absolute",
      width: "100%",
    },
    "&:hover": {
      background: theme.palette.common.white,
    },
    "&>svg": {
      height: 16,
      width: 16,
    },
  },
  listItemDisabled: {
    color: theme.palette.grey[400],
  },
  listItemSelected: {
      background: '#f2f2f2',
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
    background: "#025879",
    padding: "14px 24px",
    color: theme.palette.common.white,
    position: 'relative',
    "&:before": {
      content: "''",
      position: "absolute",
      width: "20px",
      background: "#025879",
      height: "20px",
      right: "-10px",
      transform: "rotate(45deg)",
    },
  },
  elliContainer: {
    flex: "0 0 100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  smallElli: {
    height: "50px",
    width: "50px",
  },
  reminder: {
    height: "100px",
    width: "100%",
    maxWidth: "480px",
    position: "fixed",
    right: "-500px",
    bottom: "40px",
    display: "flex",
    flexDirection: "row",
    zIndex: 1101,
    transition: "right 0.5s ease-in-out",
  },
  showReminder: {
    right: "0px",
  },  
}));

export default useStyles;
