export const SET_STEP = "SET_STEP";
export const SET_CLIENT_ID = "SET_CLIENT_ID";
export const SET_PERSON_ID = "SET_PERSON_ID";
export const SET_UCMS_ID = "SET_UCMS_ID";
export const SET_BUSINESS_OBJECT_ID = "SET_BUSINESS_OBJECT_ID";
export const SET_PERSON_NAME = "SET_PERSON_NAME";
export const SET_CREATE_TASK = "SET_CREATE_TASK";
export const SET_AUTOMATIC_CONFIRMATION = "SET_AUTOMATIC_CONFIRMATION";
export enum STEPS {
  UCMS,
  INIT, // this is not the initial step, should later be refactored to have a better name
  DETAILS,
  ANONYMOUS,
  CONFIRM,
}

export type Action =
  | { type: typeof SET_STEP; payload: STEPS }
  | { type: string; payload: any }
  | { type: typeof SET_CLIENT_ID; payload: number }
  | { type: typeof SET_PERSON_ID; payload: number }
  | { type: typeof SET_BUSINESS_OBJECT_ID; payload: number }
  | { type: typeof SET_PERSON_NAME; payload: string }
  | { type: typeof SET_CREATE_TASK; payload: boolean }
  | { type: typeof SET_AUTOMATIC_CONFIRMATION; payload: number };
export type State = {
  step: STEPS;
  clientId: number;
  personId: number;
  businessObjectId: number;
  personName: string;
  automaticConfirmation: boolean;
  createTask: boolean;
  ucmsId: number;
};

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case SET_STEP:
      return { ...state, step: action.payload };
    case SET_CLIENT_ID:
      return { ...state, clientId: action.payload };
    case SET_UCMS_ID:
      return { ...state, ucmsId: action.payload };
    case SET_PERSON_ID:
      return { ...state, personId: action.payload };
    case SET_BUSINESS_OBJECT_ID:
      return { ...state, businessObjectId: action.payload };
    case SET_PERSON_NAME:
      return { ...state, personName: action.payload };
    case SET_AUTOMATIC_CONFIRMATION:
      return { ...state, automaticConfirmation: action.payload };
    case SET_CREATE_TASK:
      return { ...state, createTask: action.payload };
    default:
      return { ...state };
  }
};

export const initialState: State = {
  step: STEPS.UCMS,
  clientId: -1,
  ucmsId: -1,
  businessObjectId: -1,
  personId: -1,
  automaticConfirmation: true,
  createTask: true,
  personName: "",
};
