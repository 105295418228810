import React from "react";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { pathOr } from "ramda";
import { useQuery } from "@apollo/client";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import ClickBox from "../../atoms/ClickBox";
import Markup from "../../atoms/Markup";

import {
  MandatesDocument,
  CounselingMandate,
  ServiceStatus,
  ServiceType,
  BusinessObject,
} from "../../../generated/graphql";
import useStyles from "../boxListStyles";
import ServiceMetaIcon from "../../atoms/ServiceMetaIcon";
import emptyTopics from "../../assets/emptyTopics.svg";

interface ITopicList {
  name: string;
  business: BusinessObject;
}
interface IList {
  mandates: CounselingMandate[];
  subscribe?: () => () => void;
}
interface IItem {
  mandate: CounselingMandate;
}

const List = ({ mandates }: IList) => {
  return (
    <Box mt={2} width={1}>
      {mandates.map(mandate => (
        <Item mandate={mandate} key={`mandate-list-item-${mandate.id}`}></Item>
      ))}
    </Box>
  );
};

const Item = ({ mandate }: IItem) => {
  const classes = useStyles();
  const { push } = useHistory();
  dayjs.extend(LocalizedFormat);
  const client = mandate.service?.client?.person?.name;
  const status = mandate.service?.status || ServiceStatus.started;
  // TODO!: Unify ServiceStatus translations somehwere
  const statusLabel = {
    [ServiceStatus.research]: "in Recherche",
    [ServiceStatus.ready]: "Rückmeldungfertig",
    [ServiceStatus.finished]: "abgeschlossen",
    [ServiceStatus.started]: "in Planung",
    [ServiceStatus.counseling]: "in Beratung",
  }[status];
  return (
    <ClickBox handleClick={() => push(`/mandate/${mandate.id}`)}>
      <Box pr={2} width={0.3}>
        <Typography variant="h3" color="primary">
          {client}
        </Typography>
        <Box mt={1}>
          <Chip
            label={statusLabel}
            color={status !== ServiceStatus.finished ? "primary" : "default"}
            classes={{ root: classes.chip, label: classes.chipLabel }}
          />
        </Box>
      </Box>

      <Box alignSelf="flex-start" pr={2} flex={1}>
        <Typography variant="h4" color="textSecondary" gutterBottom>
          Problembeschreibung
        </Typography>
        <Markup value={mandate.service?.problem || "-"} />
      </Box>
      <Box alignSelf="flex-start" pr={2}>
        <Typography variant="h4" color="textSecondary" gutterBottom>
          Genehmigte Sitzungen
        </Typography>
        <Typography variant="body2">{mandate.service?.client?.account?.freeSessions}</Typography>
      </Box>
    </ClickBox>
  );
};

const TopicList = ({ business, name }: ITopicList) => {
  const { data, loading } = useQuery(MandatesDocument, {
    variables: { businessId: business.id },
  });

  const mandates: CounselingMandate[] = data
    ? (pathOr([], ["mandates"], data) as CounselingMandate[])
    : [];
  return (
    <Box mt={2}>
      {!loading && !!mandates.length && (
        <>
          <Box display="flex" alignItems="center">
            <Box mr={2}>
              <ServiceMetaIcon name={ServiceType.externalConsult} />
            </Box>
            <Typography variant="h3">Beratungsmandate</Typography>
          </Box>
          <List mandates={mandates}></List>
        </>
      )}
      {!loading && !mandates.length && (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box mt={2} mb={4}>
            <img src={emptyTopics} alt="no mandates" />
          </Box>
          <Typography variant="body2">
            {name.length
              ? `${name} hat noch keine Beratungsmandate`
              : "Noch keine Beratungsmandate"}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default TopicList;
