import React, { useState } from "react";
import { pathOr } from "ramda";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import Phone from "@material-ui/icons/Phone";

import Dialog from "../../../molecules/DefaultDialog";
import DialogContent from "../../../molecules/DefaultDialogContent";
import PrimaryButton from "../../../atoms/PrimaryButton";
import SecondaryButton from "../../../atoms/SecondaryButton";
import { Phone as PhoneType, BusinessObject } from "../../../../generated/graphql";
import useStyles from "../styles";
import { useAuthorization } from "../../../../providers/AuthorizationProvider";

type TCallStart = {
  action: (primeDN: number, phoneId: number) => Promise<any>;
  business: BusinessObject | any;
  loading: boolean;
};
const CallStart = ({ action, business, loading }: TCallStart) => {
  const classes = useStyles();
  const { me } = useAuthorization();

  const [open, setOpen] = useState(false);
  const [primeDN, setPrimeDN] = useState(me?.user?.primeDN || "");
  const handleAction = async () => {
    if (business.phones[0].id && primeDN) {
      await action(parseInt(primeDN, 10), business.phones[0]!.id);
      setOpen(false);
    }
  };
  const phone: PhoneType | undefined = pathOr(undefined, ["phones", 0], business);
  const number = phone?.number || "";
  const areaCode = phone?.areaCode || "";
  const countryCode = phone?.countryCode || "";
  return (
    <>
      <PrimaryButton
        disabled={!number.length || !areaCode.length}
        onClick={() => setOpen(true)}
        className={classes.callButton}
      >
        <Box marginRight={1} display="flex">
          <Phone fontSize="small" />
        </Box>
        Anrufen
      </PrimaryButton>
      <Dialog open={open} aria-labelledby="service-outgoing-call-start-dialog">
        <DialogContent>
          <Box width={1}>
            <Typography variant="body2">
              Nebenstelle{" "}
              {me?.user?.hotDesks?.length === 1 ? (
                me?.user?.primeDN
              ) : (
                <Select
                  value={primeDN}
                  native
                  onChange={e => {
                    if (typeof e.target.value === "string") setPrimeDN(e.target.value);
                  }}
                >
                  {me?.user?.hotDesks?.map(ht => (
                    <option value={ht!.extension!.primeDN || ""} key={ht!.extension!.primeDN || ""}>
                      {ht?.extension?.primeDN}
                    </option>
                  ))}
                </Select>
              )}
            </Typography>
          </Box>
          <Typography variant="h1" className={classes.question}>
            {business.name &&
              `${business.name}, ${
                phone && phone.readableNumber
                  ? phone.readableNumber
                  : `${countryCode} ${areaCode} ${number}`
              } anrufen`}
          </Typography>
          <Box alignItems="center" justifyContent="center" display="flex">
            <Box marginRight={3}>
              <SecondaryButton onClick={() => setOpen(false)} disabled={loading}>
                Abbrechen
              </SecondaryButton>
            </Box>
            <Box>
              <PrimaryButton onClick={handleAction} disabled={loading}>
                Jetzt anrufen
              </PrimaryButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CallStart;
