import React, { useReducer } from "react";

import DefaultDialog from "../../molecules/DefaultDialog";
import DefaultDialogContent from "../../molecules/DefaultDialogContent";
import PersonCreateForm from "../../forms/PersonCreateForm";

import { PersonCreateContext } from "./provider";
import { SET_STEP, SET_TYPE, STEPS, TYPES, initialState, reducer } from "./reducer";

type TPersonCreateDialog = { onClose: (path?: string, error?: boolean) => void; open: boolean };

const PersonCreateDialog = ({ onClose, open }: TPersonCreateDialog) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setStep = (payload: STEPS) => {
    dispatch({ type: SET_STEP, payload });
  };

  const setType = (payload: TYPES) => {
    dispatch({ type: SET_TYPE, payload });
  };

  return (
    <DefaultDialog
      open={open}
      onClose={() => {
        setStep(STEPS.TYPE);
        onClose();
      }}
      maxWidth="md"
    >
      <DefaultDialogContent>
        <PersonCreateContext.Provider
          value={{
            ...state,
            setStep,
            setType,
            onClose: (path, error) => {
              setStep(STEPS.TYPE);
              onClose(path, error);
            },
          }}
        >
          <PersonCreateForm />
        </PersonCreateContext.Provider>
      </DefaultDialogContent>
    </DefaultDialog>
  );
};

export default PersonCreateDialog;
