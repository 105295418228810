import dayjs from "dayjs";

import { Maybe, Service } from "../../../generated/graphql";

export const sortByLastActivity = (a: Maybe<Pick<Service, "lastActivity">>, b: Maybe<Pick<Service, "lastActivity">>) => {
  if (dayjs(a?.lastActivity).isAfter(b?.lastActivity)) {
    return -1;
  }

  if (dayjs(b?.lastActivity).isAfter(a?.lastActivity)) {
    return 1;
  }

  return 0;
};
