import { useEffect } from "react";
import {
  useStatisticItemsByReportQuery,
  StatisticItemsByReportSubscriptionSubscription,
  StatisticItemsByReportSubscriptionDocument,
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

export const useStatisticItemsByReportSubscription = (reportId: number) => {
  const { data, subscribeToMore } = useStatisticItemsByReportQuery({
    variables: { reportId: reportId },
    fetchPolicy,
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore<StatisticItemsByReportSubscriptionSubscription>({
      variables: { reportId: reportId },
      document: StatisticItemsByReportSubscriptionDocument,
      updateQuery: (prev, { subscriptionData }) => {
        if (!prev) {
          return prev;
        }
        return { statisticItemsByReport: subscriptionData.data.statisticItemsByReportSubscription };
      },
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId]);

  return data?.statisticItemsByReport || [];
};
