import React, { FC, useEffect, useState } from "react";

import dayjs from "dayjs";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CalendarToday from "@material-ui/icons/CalendarToday";
import useStyles from "./styles";
import { useHistory, useLocation } from "react-router";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import { IconButton } from "@material-ui/core";

const DatePickerNavigator: FC = () => {
  const location = useLocation();
  const classes = useStyles();
  const { search } = useLocation();  
  const selectedDate = new URLSearchParams(search).get("date") ?? Date();
  const [dateToShow, setDateToShow] = useState(selectedDate);
  const [isDatePickerActive, setIsDatePickerActive] = useState(false);

  const { push } = useHistory();
  

  const handleDateSelection = (e: any) => {
    const desiredDate = dayjs(e);
    const targetDate = dayjs(desiredDate).format("YYYY-MM-DD");    
    push(`activities?date=${targetDate}`);
  };

  useEffect(() => {
    setDateToShow(new URLSearchParams(search).get("date") ?? Date())
}, [location]);
  return (
    <Box mt={1}>
      <Box display={'flex'}>
        <MuiPickersUtilsProvider utils={DayjsUtils}>
          <Typography
            variant="h3"
            className={classes.defaultText}
            gutterBottom
            style={{
              paddingTop: '1px'
            }}
          >
            Ellis Tag 
            {/* ({" "}
            {dayjs(dateToShow).format("Do MMMM YYYY")}
            {" "}) */}
          </Typography>          
          <DatePicker
              cancelLabel="Abbrechen"
              className={classes.datePicker}
              disableFuture
              format="Do MMMM YYYY"              
              key="activitiesDate"
              onChange={handleDateSelection}
              tabIndex={2}              
              value={dateToShow}              
              style={{
                width: '150px'
              }}
              TextFieldComponent={() => null}
              open={isDatePickerActive}
              onOpen={() => setIsDatePickerActive(true)}
              onClose={() => setIsDatePickerActive(false)}              
            />
            <Box pb={2} ml={1}>
            <IconButton onClick={() => {
              setIsDatePickerActive(true)
            }} aria-label="delete" size="small">
              <CalendarToday fontSize="small" color="primary" />
            </IconButton>
            </Box>

        </MuiPickersUtilsProvider>
      </Box>
    </Box>
  );
};

type TCalendarSlot = {
  month: number;
  monthName: string;
  days: any[];
  currentDate: any;
};

const CalendarMonthView = ({ month, monthName, days, currentDate }: TCalendarSlot) => {
  const { push } = useHistory();
  return (
    <Box>
      <Box m={1}>
        <Typography variant="h4">{monthName}</Typography>
      </Box>
      <Box
        display={"flex"}
        flex={1}
        flexWrap={"wrap"}
        style={{
          textAlign: "center",
        }}
      >
        {days.map((d, index) => {
          const sameDay = currentDate.isSame(d.date, "date");
          const today = dayjs().isSame(d.date, "date");
          const isInFeature = d.date.isAfter(new Date(), "date");
          const dayTitle = dayjs(d.date).format("D");
          return (
            <Box
              style={{
                background: sameDay ? "burlywood" : "white",
                textAlign: "center",
                border: "1px solid #bdbdbd",
                margin: "2px",
                borderRadius: "5px",
                height: "51px",
                cursor: isInFeature ? "not-allowed" : "pointer",
              }}
              display={"flex"}
              flexDirection={"column"}
              width={"15%"}
              key={index}
              onClick={() => {
                if (!isInFeature) {
                  const targetDate = dayjs(d.date).format("YYYY-MM-DD");
                  push(`activities?date=${targetDate}`);
                }
              }}
            >
              <Box
                style={{
                  height: "21px",
                  background: today ? "rgba(253, 0, 0, 1.0)" : "rgba(253, 0, 0, 0.62)",
                  color: "white",
                }}
              >
                {d.title}
              </Box>
              <Box
                style={{
                  textDecoration: isInFeature ? "line-through" : today ? "underline" : "",
                  fontWeight: today ? "bold" : "normal",
                }}
              >
                {dayTitle}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default DatePickerNavigator;
