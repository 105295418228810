import React from "react";
import dayjs from "dayjs";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Avatar from "../../atoms/Avatar";
import { getUserName } from "../../../utils/userNameHelper";

import { ReportActivityQuery } from "../../../generated/graphql";
type TInfoBox = { activity: ReportActivityQuery["personActivity"] };
const InfoBox = ({ activity }: TInfoBox) => {
  const author = activity?.author;
  const avatar = author?.picture || author?.avatar || "";
  return (
    <>
      {author && (
        <Box display="flex" alignItems="center" borderTop={1} borderColor="grey.400" pt={5}>
          <Box>
            <Typography variant="body2">erstellt von</Typography>
          </Box>
          <Box ml={2} mr={1} color="text.primary">
            <Avatar src={avatar} widthfactor={5}>
              {author?.avatarKeys}
            </Avatar>
          </Box>
          <Box mr={2}>
            <Typography variant="h4">{getUserName(author)}</Typography>
          </Box>
          {activity?.doneAt && (
            <Box>
              <Typography>{dayjs(activity?.doneAt).format("D. MMMM YYYY, H:mm")}</Typography>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default InfoBox;
