import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const StyledSvgIcon = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 13,
      width: 14,
    },
  }),
)(SvgIcon);
