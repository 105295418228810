import React from "react";

import useStyles from "./styles";

type TCounter = {
  color?: "primary" | "secondary";
  value: number | string;
  id: string;
  top?: number;
  right?: number;
};

export const Counter = ({ color = "secondary", value, id, top, right }: TCounter) => {
  const classes = useStyles({ top, right, color });

  return (
    <span
      key={id}
      className={classes.counter}
      style={{
        position: top !== undefined || right !== undefined ? "absolute" : "relative",
        right: `${right}px`,
        top: `${top}px`,
      }}
    >
      {value}
    </span>
  );
};

export default Counter;
