import React, { useState } from "react";

import {
  Person,
  User,
  PapershiftWorkingArea,
  useUserQuery,
  Maybe,
} from "../../../generated/graphql";
import { fetchPolicy } from "../../../utils/constants";
import {
  useUserFromPersonSubscription,
  useUserSubscription,
} from "../../../hooks/useUserSubscription";

import Badge from "@material-ui/core/Badge";
import Avatar from "../../atoms/Avatar";

import UserDialog from "../UserDialog";
import unassignedAvatar from "../../assets/unassignedAvatar.svg";
import useStyles from "./styles";

type TUserAvatarFromPerson = {
  person: Person;
};

export const UserAvatarFromPerson = ({ person }: TUserAvatarFromPerson) => {
  const [open, setOpen] = useState(false);
  const { user } = useUserFromPersonSubscription(person.id!, "cache-first");

  const src = person.picture || person.avatar || "";
  const avatarKeys = person.avatarKeys;

  return (
    <>
      <Avatar src={src} onClick={() => setOpen(true)} widthfactor={5} style={{ cursor: "pointer" }}>
        {avatarKeys}
      </Avatar>
      {user && <UserDialog open={open} user={user} onClose={() => setOpen(false)} />}
    </>
  );
};

type TUserAvatarFromTask = {
  userId: number;
  badgeCount?: number;
};

export const UserAvatarFromTask = ({ userId, badgeCount = 1 }: TUserAvatarFromTask) => {
  const [open, setOpen] = useState(false);
  const { user } = useUserSubscription(userId);
  const person = user?.person;
  const { badge } = useStyles();

  const src = person?.picture || person?.avatar || "";
  const avatarKeys = person?.avatarKeys;

  if (badgeCount > 1) {
    return (
      <>
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          badgeContent={<div className={badge}>+{badgeCount - 1}</div>}
        >
          <Avatar
            src={src}
            onClick={() => setOpen(true)}
            widthfactor={5}
            style={{ cursor: "pointer" }}
          >
            {avatarKeys}
          </Avatar>
        </Badge>
        {user && <UserDialog open={open} user={user} onClose={() => setOpen(false)} />}
      </>
    );
  }

  return (
    <>
      <Avatar src={src} onClick={() => setOpen(true)} widthfactor={5} style={{ cursor: "pointer" }}>
        {avatarKeys}
      </Avatar>
      {user && <UserDialog open={open} user={user} onClose={() => setOpen(false)} />}
    </>
  );
};

type TUnassingedBadgeAvatar = {
  badgeCount?: number;
};

export const UnassingedBadgeAvatar = ({ badgeCount = 1 }: TUnassingedBadgeAvatar) => {
  const { badge } = useStyles();

  if (badgeCount > 1) {
    return (
      <>
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          badgeContent={<div className={badge}>+{badgeCount - 1}</div>}
        >
          <Avatar src={unassignedAvatar} widthfactor={5} style={{ cursor: "pointer" }} />
        </Badge>
      </>
    );
  }

  return <Avatar src={unassignedAvatar} widthfactor={5} style={{ cursor: "pointer" }} />;
};

export type TAvatarUser = Pick<User, "id" | "absentUntil" | "name"> & {
  person?: Maybe<
    {
      __typename?: "Person";
    } & Pick<Person, "id" | "avatar" | "avatarKeys" | "picture">
  >;
  papershiftWorkingAreas?: Array<Pick<PapershiftWorkingArea, "id" | "name"> | null> | null;
};

type TUserAvatar = {
  disabled?: boolean;
  user: TAvatarUser;
  workingArea?: PapershiftWorkingArea;
};

const UserAvatar = ({ disabled = false, user, workingArea }: TUserAvatar) => {
  const [open, setOpen] = useState(false);

  // We might have not loaded everything for this user for performance reasons.
  const { data } = useUserQuery({
    skip: !user || !user.id || !Object.keys(user).includes("papershiftWorkingAreas"),
    variables: {
      id: user.id,
    },
    fetchPolicy,
  });

  const src = user?.person?.picture || user?.person?.avatar || "";
  const avatarKeys = user?.person?.avatarKeys;

  if (!data?.user && !user) {
    return <></>;
  }

  const dialogUser = (data?.user || user) as User;

  return (
    <>
      <Avatar
        src={src}
        onClick={() => setOpen(true)}
        widthfactor={5}
        style={{ cursor: "pointer", opacity: disabled ? 0.2 : 1 }}
      >
        {avatarKeys}
      </Avatar>      
      <UserDialog
        open={open}
        user={dialogUser}
        workingArea={workingArea}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default UserAvatar;
