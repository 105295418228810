import React, { FC, useContext, useState } from "react";

import dayjs from "dayjs";
import { Form, FormikProps } from "formik";
import { TextField } from "formik-material-ui";

import DayjsUtils from "@date-io/dayjs";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Typography from "@material-ui/core/Typography";
import WatchLater from "@material-ui/icons/WatchLater";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { Maybe, Person, User } from "../../../../generated/graphql";
import PrimaryButton from "../../../atoms/PrimaryButton";
import SecondaryButton from "../../../atoms/SecondaryButton";
import SlateRTE, { initialFormValue } from "../../../molecules/SlateRTE";

import {
  StyledField,
  StyledKeyboardDatePicker,
  StyledKeyboardTimePicker,
} from "../../../atoms/StyledFormComponents";

import TopicServiceSelectField from "../../../forms/TopicServiceSelectField";
import UserSelectField from "../../../forms/UserSelectField";
import { PaperShiftContext, usePaperShiftContext } from "../../../pages/Shift/provider";
import { TAvatarUser } from "../../UserAvatar";
import {
  customHackyDateValidation,
  customHackyDateValidationForShiftBoundary,
} from "./validationSchema";

interface AppointmentDialogFormValues {
  note: string;
  title: string;
  personId: number;
  topicId: number;
  serviceId: number;
  date: dayjs.Dayjs;
  from: dayjs.Dayjs;
  to: dayjs.Dayjs;
}

interface AppointmentDialogFormProps {
  allowEditUsers: boolean;
  deleteAppointment?: () => void;
  edit?: boolean;
  person?: Person | null;
  minStartsAt: dayjs.Dayjs;
  minEndsAt: dayjs.Dayjs;
  users: Maybe<User | TAvatarUser>[];
}

const AppointmentDialogForm: FC<
  AppointmentDialogFormProps & FormikProps<AppointmentDialogFormValues>
> = ({
  allowEditUsers,
  deleteAppointment,
  edit = false,
  setFieldError,
  validateForm,
  submitForm,
  errors,
  person,
  setFieldValue,
  users,
  values,
  minEndsAt,
  minStartsAt,
}) => {
  const [submittedOnce, setSubmittedOnce] = useState(false);
  //   const contextUsers = useContext(UsersContext).users;
  const contextUsers = useContext(PaperShiftContext)?.users;
  let from = values.date.clone().hour(values.from.hour());
  from = from.minute(values.from.minute());

  let to = values.date.clone().hour(values.to.hour());
  to = to.minute(values.to.minute());

  return (
    <Form style={{ marginLeft: "-8px", marginRight: "-8px" }}>
      <Box margin="40px 8px 8px">
        {!allowEditUsers && (
          <Typography variant="body2">Für: {users.map(user => user!.name).join(", ")}</Typography>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between">
        <MuiPickersUtilsProvider utils={DayjsUtils} locale="de">
          <StyledKeyboardDatePicker
            name="date"
            format="DD.MM.YYYY"
            value={values.date}
            onChange={value => {
              setFieldValue("date", value || dayjs());
              if (errors) {
                validateForm();
              }
            }}
            label="Datum"
            cancelLabel="Abbrechen"
            okLabel="OK"
          />
          <StyledKeyboardTimePicker
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <WatchLater color="primary" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            minutesStep={15}
            format="H:mm"
            name="from"
            error={!!errors.from}
            helperText={errors.from}
            ampm={false}
            value={from}
            onChange={value => {
              setFieldValue("from", value || dayjs());
              if (value) {
                customHackyDateValidation(setFieldError, value, values.to);
                customHackyDateValidationForShiftBoundary(
                  setFieldError,
                  value,
                  minStartsAt,
                  minEndsAt,
                );
              }
            }}
            label="Von"
            cancelLabel="Abbrechen"
            okLabel="OK"
          />
          <StyledKeyboardTimePicker
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <WatchLater color="primary" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={!!errors.to}
            helperText={errors.to}
            minutesStep={15}
            format="H:mm"
            name="to"
            ampm={false}
            value={to}
            onChange={value => {
              setFieldValue("to", value || dayjs());
              if (value) {
                customHackyDateValidation(setFieldError, values.from, value);
                customHackyDateValidationForShiftBoundary(
                  setFieldError,
                  value,
                  minStartsAt,
                  minEndsAt,
                );
              }
            }}
            label="Bis"
            cancelLabel="Abbrechen"
            okLabel="OK"
          />
        </MuiPickersUtilsProvider>
      </Box>

      <TopicServiceSelectField
        errors={errors}
        onChangePerson={(personId?: number) => {
          setFieldValue("personId", personId || -1);
          setFieldValue("serviceId", -1);
          setFieldValue("topicId", -1);
        }}
        person={person}
        personId={values.personId}
        serviceId={values.serviceId}
        topicId={values.topicId}
        setFieldValue={setFieldValue}
        submittedOnce={submittedOnce}
      />
      <Box display="flex">
        <StyledField label="Titel" name="title" component={TextField} margin="normal" />
      </Box>
      <Box margin="32px 8px 0">
        {allowEditUsers && (
          <UserSelectField day={values.date} users={contextUsers} title="Teilnehmer" />
        )}
      </Box>
      <Box display="flex" paddingX="8px">
        <SlateRTE
          small
          handleChange={value => {
            setFieldValue("note", value);
          }}
          label={"Notizen"}
          placeholder={""}
          value={values.note || initialFormValue}
        />
      </Box>
      <Box marginTop="32px" textAlign="center">
        {edit && <SecondaryButton onClick={deleteAppointment}>Termin löschen</SecondaryButton>}
        <Box marginX={3} display="inline-block">
          <SecondaryButton
            onClick={async () => {
              setSubmittedOnce(true);

              setFieldValue("isAppointment", false);
              // Validation is done on submit. However, for the field 'isAppointment', the validation
              // is always one click behind otherwise
              await validateForm();

              submitForm();
            }}
          >
            Zeit sperren
          </SecondaryButton>
        </Box>
        <PrimaryButton
          onClick={async () => {
            setSubmittedOnce(true);

            setFieldValue("isAppointment", true);
            // Validation is done on submit. However, for the field 'isAppointment', the validation
            // is always one click behind otherwise
            await validateForm();

            submitForm();
          }}
        >
          {edit ? "OK" : "Termin anlegen"}
        </PrimaryButton>
      </Box>
    </Form>
  );
};
export default AppointmentDialogForm;
