import React from "react";
import Box from "@material-ui/core/Box";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import FormLabel from "@material-ui/core/FormLabel";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  useServiceCategoriesQuery,
  ServiceCategory,
  Account,
  ServiceMeta,
} from "../../../generated/graphql";
import { fetchPolicy } from "../../../utils/constants";

type TAccountModuleSelect = {
  account: Account;
  setModules: (sm: ServiceMeta) => void;
  modules: ServiceMeta[];
};

const AccountModuleSelect = ({ account, modules, setModules }: TAccountModuleSelect) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const { data } = useServiceCategoriesQuery({ fetchPolicy });
  const categories = (data?.serviceCategories || []) as ServiceCategory[];
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <FormLabel>
        <Typography variant="body2" gutterBottom>
          Life-Management Module
        </Typography>
      </FormLabel>
      {categories.map(category => {
        const categoryModules = modules.filter(sm => sm && sm.serviceCategoryId! === category.id);
        const text =
          categoryModules.length !== category.serviceMetas?.length
            ? categoryModules.map(sm => sm!.name).join(", ")
            : "alle";
        return (
          <ExpansionPanel
            expanded={expanded === category.name!}
            onChange={handleChange(category.name!)}
            square
            key={`modulepack-${category.id}-${text}`}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${category.name!}-content`}
              id={`${category.name!}-header`}
            >
              <Box display="flex" flexDirection="row" width="100%">
                <Typography variant="h4" component={Box} width="45%" flexShrink={0}>
                  {category.name}
                </Typography>

                <Typography
                  variant="body2"
                  key={`${category.id}-${categoryModules.map(m => m.id).join("-")}`}
                >
                  {expanded !== category.name! && text}
                </Typography>
              </Box>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {category.serviceMetas?.map(sm => {
                const toggle = () => setModules(sm!);
                const selected = !!modules.find(m => m!.id! === sm!.id!);
                return (
                  sm && (
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{ cursor: "pointer" }}
                      key={`${sm.id}-module-item`}
                      onClick={toggle}
                      mr={3}
                    >
                      <Box marginLeft="-12px">
                        <Checkbox checked={selected} />
                      </Box>
                      <Box paddingTop="4px">{sm.name}</Box>
                    </Box>
                  )
                );
              })}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </>
  );
};
export default AccountModuleSelect;
