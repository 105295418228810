import React from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { Formik, FormikProps, FormikValues, FormikActions } from "formik";
import * as Yup from "yup";

import { RelationshipType, AddressType, PhoneType, Gender } from "../../../generated/graphql";

import AddressField from "../../forms/AddressField";
import PersonBaseFields from "../../forms/PersonBaseField";
import PhoneField from "../../forms/PhoneField";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import RelationField from "../../forms/RelationField";

export type TContactPerson = {
  firstName: string;
  lastName: string;
  alias: string;
  gender: Gender;
  title: string;
  addresses: {
    id?: string | null;
    type: AddressType;
    zip: string;
    city: string;
    street: string;
  }[];
  phones: {
    id?: number | null;
    type: PhoneType;
    number: string;
    areaCode: string;
    countryCode: string;
  }[];
  relation: {
    id?: number | null;
    relationship: RelationshipType;
    isEmergency: boolean;
    isHousehold: boolean;
  };
};

type TContactPersonForm = {
  allowEmergencyContact: boolean;
  cancel: () => void;
  initialValues: TContactPerson;
  newEntry?: boolean;
  submit: (values: TContactPerson, formikActions: FormikActions<TContactPerson>) => void;
};
const ContactPersonForm = ({
  allowEmergencyContact,
  cancel,
  initialValues,
  newEntry,
  submit,
}: TContactPersonForm) => {
  return (
    <Formik
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required("Pflichtangabe"),
        lastName: Yup.string().required("Pflichtangabe"),
      })}
      initialValues={initialValues}
      onSubmit={submit}
    >
      {props => (
        <Form
          newEntry={newEntry}
          cancel={cancel}
          {...props}
          allowEmergencyContact={allowEmergencyContact}
        />
      )}
    </Formik>
  );
};

type TForm = {
  allowEmergencyContact: boolean;
  cancel: () => void;
  newEntry?: boolean;
};
const Form = ({
  allowEmergencyContact,
  cancel,
  handleSubmit,
  isSubmitting,
  newEntry,
}: TForm & FormikProps<FormikValues>) => {
  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <Box width="100%" mb={3}>
        <Typography align="center" variant="h1">
          Kontaktperson {newEntry ? "anlegen" : "bearbeiten"}
        </Typography>
      </Box>
      <PersonBaseFields />
      <AddressField namePrefix="addresses[0]" />
      <PhoneField namePrefix="phones[0]" />
      <RelationField namePrefix="relation" allowEmergencyContact={allowEmergencyContact} />
      <Box marginTop={4} display="flex" justifyContent="center" flexDirection="row-reverse">
        <PrimaryButton type="submit" disabled={isSubmitting} tabIndex={0}>
          OK
        </PrimaryButton>
        {cancel && (
          <>
            <Box mx={1} />
            <SecondaryButton onClick={cancel} tabIndex={0}>
              Abbrechen
            </SecondaryButton>
          </>
        )}
      </Box>
    </form>
  );
};
export default ContactPersonForm;
