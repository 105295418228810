import React, { useState, useContext, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import {
  PersonActivity,
  usePersonByChatAliasQuery,
  usePushActivitySubscription,
  useCreateChatMutation,
  Person,
  CallType,
} from "../../../generated/graphql";
import { useActiveActivitiesSubscription } from "../../../hooks/useActiveActivitiesSubscription";
import { useAuthorization } from "../../../providers/AuthorizationProvider";

import CallDialog from "../CallDialog";
import ResearchCallDialog from "../ResearchCallDialog";
import CallDialogCompleted from "../CallDialog/CallDialogCompleted";
import Notification from "../../molecules/Notification";
import { ChatContext } from "../../../containers/ChatListener";
import Counter from "../../atoms/Counter";
import { ChatClient } from "../../../containers/ChatListener/reducer";
import DefaultDialog from "../../molecules/DefaultDialog";
import ClickableListItem from "../../atoms/ClickableListItem";
import DefaultDialogContent from "../../molecules/DefaultDialogContent";

import ResearchCallBubble from "./ResearchCallBubble";
import ActiveChatBubble from "./ActiveChatBubble";
import ActiveHeaderBubble from "./ActiveHeaderBubble";

const isBlockingInteraction = (_activity: Partial<PersonActivity>) => {
  return false; // return activity?.activityType === "callIn" || activity?.activityType === "callOut";
};

const Interactions = () => {
  const { push } = useHistory();

  const [openResearchCall, setOpenResearchCall] = useState(false);
  const [currentActivityId, setCurrentActivityId] = useState<number | undefined>();

  const openModal = (next: boolean) => {
    if (!next) {
      setCurrentActivityId(undefined);
    }
    setOpenResearchCall(next);
  };

  const { activities } = useActiveActivitiesSubscription();

  return (
    <Box display="flex" alignItems="center">
      <CallModals
        openResearchCall={openResearchCall}
        setOpenResearchCall={openModal}
        activityId={currentActivityId}
      />
      {!activities.some(isBlockingInteraction) && <IncomingChats />}
      {activities.map((activity: any) => {
        if (activity.serviceResearchObject) {
          return (
            <ResearchCallBubble
              key={activity.id}
              activity={activity}
              clickHandler={(id: number) => {
                setOpenResearchCall(true);
                setCurrentActivityId(id);
              }}
            />
          );
        }

        if (activity.call) {
          return (
            <ActiveHeaderBubble
              key={activity.id}
              activity={activity}
              handleClick={(person?: Person | null) => {
                if (person) {
                  push(`/person/${person.id}`);
                }
              }}
            />
          );
        }

        return <ActiveChatBubble key={activity.id} activity={activity} />;
      })}
    </Box>
  );
};

type TCallModals = {
  activityId: number | undefined;
  setOpenResearchCall: (next: boolean) => void;
  openResearchCall: boolean;
};

const CallModals = ({ setOpenResearchCall, openResearchCall, activityId }: TCallModals) => {
  const { data } = usePushActivitySubscription(); // with the newest version of apollo client we get an error here https://github.com/apollographql/apollo-client/issues/7608
  const [openCallAcceptedDialog, setOpenCallAcceptedDialog] = useState(false);
  const [openCallCompletedDialog, setOpenCallCompletedDialog] = useState(false);
  const [currentActivityId, setCurrentActivityId] = useState<number | undefined>();

  const { enqueueSnackbar } = useSnackbar();

  const status = data?.pushActivitySubscription?.status;
  const callType = data?.pushActivitySubscription?.callType;
  const personActivityId = data?.pushActivitySubscription?.personActivity?.id;

  useEffect(() => {
    switch (status) {
      case "answered":
        setOpenCallAcceptedDialog(true);
        setCurrentActivityId(personActivityId);
        break;
      case "transferred":
        setOpenCallAcceptedDialog(true);
        setCurrentActivityId(personActivityId);
        break;
      case "finished":
        setCurrentActivityId(personActivityId);
        if (callType === CallType.client) {
          setOpenCallCompletedDialog(true);
        } else if (callType === CallType.business) {
          setOpenResearchCall(true);
        }
        break;
      case "cancelled":
        setOpenCallAcceptedDialog(false);
        setCurrentActivityId(undefined);
        break;
      case "close":
        setOpenCallAcceptedDialog(false);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, personActivityId, callType]); // do not add setOpenResearchCall. Will lead to never closing modal

  const id = activityId || currentActivityId;
  return (
    <>
      {openCallAcceptedDialog && (
        <CallDialog
          open={openCallAcceptedDialog}
          handleClose={() => setOpenCallAcceptedDialog(false)}
          activityId={currentActivityId}
        />
      )}

      {currentActivityId && !openCallAcceptedDialog && (
        <CallDialogCompleted
          open={openCallCompletedDialog}
          handleClose={() => {
            setOpenCallCompletedDialog(false);
          }}
          handleNotifyClose={() => {
            enqueueSnackbar("", {
              content: (key: string) => (
                <Notification
                  id={key}
                  message={
                    <>
                      Ich erinnere dich in der Inbox <br />
                      an das Gesprächsprotokoll.
                    </>
                  }
                />
              ),
            });

            setOpenCallCompletedDialog(false);
          }}
          activityId={currentActivityId}
        />
      )}
      {id !== undefined && (
        <ResearchCallDialog
          isOpen={openResearchCall}
          toggle={setOpenResearchCall}
          activityId={id}
        />
      )}
    </>
  );
};

// this component is responsible for chats list in header
function IncomingChats() {
  const [open, setOpen] = useState(false);
  const { obrowser, activeChats } = useContext(ChatContext);
  const { isAuthorized } = useAuthorization();

  const clients = [...(obrowser?.clients?.values() || [])];

  const incomingChats = clients.filter(
    client => activeChats.every(chat => chat.chatUserId !== client.user_id) && !!client.latest,
  );

  if (clients.length < 1) {
    return null;
  }

  return isAuthorized("chatStart") && incomingChats.length > 0 ? (
    <Box position="relative" width={48}>
      <Box display="flex">
        <IconButton style={{ color: "#fff" }} onClick={() => setOpen(true)}>
          <ChatBubbleOutlineIcon color="inherit" />
        </IconButton>
        <Counter id={String(incomingChats.length)} value={incomingChats.length} right={0} top={0} />
      </Box>
      <ChatModal incomingChats={incomingChats} open={open} onClose={() => setOpen(false)} />
    </Box>
  ) : null;
}

function ChatModal({
  incomingChats,
  open,
  onClose,
}: {
  incomingChats: ChatClient[];
  open: boolean;
  onClose: () => void;
}) {
  return (
    <DefaultDialog open={open} onClose={onClose}>
      <DefaultDialogContent>
        <Typography variant="h1">Wähle einen Chat aus</Typography>
        <Box marginTop={5} width="100%" overflow="scroll">
          {incomingChats.map(chat => {
            return (
              <Chat
                key={chat.user_id}
                alias={chat.alias}
                userId={chat.user_id}
                account={chat.account}
                onClose={onClose}
              />
            );
          })}
        </Box>
      </DefaultDialogContent>
    </DefaultDialog>
  );
}

function Chat({
  alias,
  userId,
  account,
  onClose,
}: {
  alias: string;
  userId: number;
  account: string;
  onClose: () => void;
}) {
  const [submitting, setSubmitting] = useState(false);

  const { push } = useHistory();
  const { data } = usePersonByChatAliasQuery({
    variables: {
      chatAlias: alias,
    },
  });

  const name = data?.personByChatAlias?.name || alias;

  const [createChat] = useCreateChatMutation({
    variables: {
      chatInput: {
        chatAlias: alias,
        chatUserId: userId,
        account,
      },
    },
  });

  return (
    <ClickableListItem
      onBackground={false}
      onClick={async () => {
        if (!submitting) {
          setSubmitting(true);
          try {
            const { data } = await createChat();

            if (!!data?.createChat) {
              setSubmitting(false);
              push(`/person/${data?.createChat?.personActivity?.person?.id}/chat/${userId}`);
              onClose();
            }
          } catch (error) {
            console.log({ error });
          } finally {
            setSubmitting(false);
          }
        }
      }}
    >
      <Box flex={1}>
        <Typography variant="body2">{name}</Typography>
      </Box>
    </ClickableListItem>
  );
}
export default Interactions;
