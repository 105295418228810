import React, { ReactNode, FC } from "react";

import pathOr from "ramda/src/pathOr";
import groupBy from "ramda/src/groupBy";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { PapershiftShift } from "../../../../generated/graphql";
import ShiftTimes from "../../../organisms/ShiftTimes";

import Day from "./Day";
import NoUserSelectedTeaser from "./NoUserSelectedTeaser";
import { FilterState } from "../reducer";
import { combineMultipleShifts, getOverlapsMask } from "../../../../containers/Shifts/utils";

dayjs.extend(isBetween);

interface ShiftResultsContainerProps {
  filter: FilterState;
  shifts: PapershiftShift[];
}

const ShiftResultsContainer: FC<ShiftResultsContainerProps> = ({ filter, shifts }) => {
  const dayFormat = "DD.MM.YYYY";
  const shiftsByDay = groupBy((shift: PapershiftShift) => {
    const startsAt = pathOr("", ["startsAt"], shift) as string;

    return dayjs(startsAt).format(dayFormat);
  })(shifts);

  let blocks = 1;
  // @ts-ignore
  const days = [...Array(28).keys()]
    .map(day => {
      const totalUserCount = filter.users.size + filter.externalUsers.size;
      //   if (blocks > totalUserCount * 10) {
      //     return undefined;
      //   }

      const currentDay = dayjs().add(day, "day");

      let shifts = shiftsByDay[currentDay.format(dayFormat)];

      if (!shifts) {
        return undefined;
      }

      let internalShifts = shifts
        // #172600725
        .reduce(combineMultipleShifts, [] as PapershiftShift[])
        .filter(shift => {
          // @ts-ignore
          const user = shift.users[0];

          return (
            // @ts-ignore
            !!filter.users.get(user.id)
          );
        })
        // Bug, to be addressed in: #172600725
        // @ts-ignore
        .filter(shift => shift.shifts.length === 1);

      let externalShifts = shifts
        // #172600725
        .reduce(combineMultipleShifts, [] as PapershiftShift[])
        .filter(shift => {
          // @ts-ignore
          const user = shift.users[0];

          return (
            // @ts-ignore
            !!filter.externalUsers.get(user?.id)
          );
        })
        // Bug, to be addressed in: #172600725
        // @ts-ignore
        .filter(shift => shift.shifts.length === 1);
      if (externalShifts.length !== Array.from(filter.externalUsers).length) {
        return undefined;
      }

      let validRange = null;
      if (filter.users.size > 0) {
        // this is the days with a shift in them
        validRange = internalShifts.reduce(
          (carry, shift) => {
            if (!carry.startsAt || !carry.endsAt) {
              return {
                startsAt: dayjs(shift.startsAt),
                endsAt: dayjs(shift.endsAt),
              };
            }

            return {
              startsAt: carry.startsAt.isAfter(dayjs(shift.startsAt))
                ? carry.startsAt
                : dayjs(shift.startsAt),
              endsAt: carry.endsAt.isBefore(dayjs(shift.endsAt))
                ? carry.endsAt
                : dayjs(shift.endsAt),
            };
          },
          {
            startsAt: undefined,
            endsAt: undefined,
          },
        );
      } else if (filter.externalUsers.size > 0) {
        validRange = externalShifts.reduce(
          (carry, shift) => {
            if (!carry.startsAt || !carry.endsAt) {
              return {
                startsAt: dayjs(shift.startsAt),
                endsAt: dayjs(shift.endsAt),
              };
            }

            return {
              startsAt: carry.startsAt.isAfter(dayjs(shift.startsAt))
                ? carry.startsAt
                : dayjs(shift.startsAt),
              endsAt: carry.endsAt.isBefore(dayjs(shift.endsAt))
                ? carry.endsAt
                : dayjs(shift.endsAt),
            };
          },
          {
            startsAt: undefined,
            endsAt: undefined,
          },
        );
      } else {
        validRange = {
          startsAt: undefined,
          endsAt: undefined,
        };
      }

      internalShifts = internalShifts.filter(
        i => i.papershiftWorkingArea?.id === filter.selectedWorkingAreaId,
      );      

      const intersection = getOverlapsMask(internalShifts, externalShifts);
      //   console.warn({ externalShifts, intersection });

      //   if (shifts.length < 1 || shifts.length !== filter.users.size || intersection.length < 1) {
      if (externalShifts.length > 0 && intersection.length === 0) {
        return undefined;
      }
      //   if (internalShifts.length < 1 || intersection.length < 1) {
      //     return undefined;
      //   }
      if (intersection.length < 1) {
        return undefined;
      }

      blocks = blocks + shifts.length;

      return (
        <Day
          key={day}
          shifts={intersection}
          day={currentDay}
          filter={filter}
          // @ts-ignore
          validRange={validRange}
        />
      );
    })
    .filter(_ => _ !== undefined);

  return <ShiftResults num={filter.users.size + filter.externalUsers.size} days={days} />;
};

interface ShiftResultsProps {
  num: number;
  days: ReactNode[];
}

const ShiftResults: FC<ShiftResultsProps> = ({ days, num }) => {
  if (num < 1) {
    return (
      <Box textAlign="center" paddingTop={12}>
        <NoUserSelectedTeaser />
        <Box mt={7} />
        <Typography variant="body2">
          Bitte wähle ein Team und/oder einen Mitarbeiter aus der Liste aus, für die Du einen Termin
          finden möchtest.
        </Typography>
      </Box>
    );
  }
  if (num > 0 && days.length < 1) {
    return (
      <Box textAlign="center" paddingTop={12}>
        <Typography variant="body2">
          Es wurden keine gemeinsamen Schichten für diese Mitarbeiter gefunden.
        </Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" overflow="auto" paddingBottom={2}>
      <Box paddingTop="32px">
        <ShiftTimes />
      </Box>
      {days}
    </Box>
  );
};

export default ShiftResultsContainer;
