import React from "react";

import LabelFilter from "../LabelFilter";

type TInboxLabelFilter = {
  scope?: "full" | "admin" | "inbox";
};

const InboxLabelFilter = ({ scope = "inbox" }: TInboxLabelFilter) => {
  const inbox = [
    { title: "Meine Aufgaben", path: "/", rule: "/" },
    { title: "E-Mails", path: "/emails", rule: "/emails" },
    { title: "Meine Klienten", path: "/inbox/myClients", rule: "/inbox/myClients" },
  ];

  const admin = [
    {
      title: "Alle Aufgaben",
      path: "/inbox/pool",
      rule: "/inbox/:filter(overdue|pool)",
    },
    {
      title: "Überfällige Aufgaben",
      path: "/inbox/overdue",
      rule: "/inbox/:filter(overdue|pool)",
    },
    {
      title: "Nicht zugewiesene Aufgaben",
      path: "/inbox/unassigned",
      rule: "/",
    },
    {
      title: "Inbox eines Mitarbeiters",
      path: "/inbox/selection",
      rule: "/inbox/:filter(selection)",
    },
    {
      title: "Chats",
      path: "/inbox/chats",
      rule: "/",
    },
  ];

  let filterLabels = [];

  switch (scope) {
    case "admin":
      filterLabels = admin;
      break;
    case "full":
      filterLabels = [...inbox, ...admin];
      break;
    case "inbox":
    default:
      filterLabels = inbox;
      break;
  }

  return <LabelFilter filterLabels={filterLabels} />;
};

export default InboxLabelFilter;
