import React, { useRef, useState } from "react";

import { useParams, useLocation, useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import { Typography, Box } from "@material-ui/core";

import WriteAnswer from "../../organisms/WriteAnswer";
import PersonHeader from "../../molecules/PersonHeader";
import Loader from "../../atoms/Loading";
import usePersonSubscription from "../../../hooks/usePersonSubscription";
import {
  useSmsNotificationMutation,
  PhoneType,
  useTopicsFromPersonQuery,
  Topic,
  Phone,
  Person,
  AccountType,
} from "../../../generated/graphql";
import TopicSelectOrCreate from "../../molecules/TopicSelectOrCreate";
import SmsIllustration from "../../assets/SmsIllustration";

const Sms = () => {
  const ref = useRef<any>(null);
  const { personId } = useParams<{ personId: string }>();
  const { state }: { state: { topicId: string; serviceId: number } } = useLocation();
  const { goBack } = useHistory();
  const { person } = usePersonSubscription(parseInt(personId, 10));
  const [smsNotification] = useSmsNotificationMutation();
  const { data } = useTopicsFromPersonQuery({
    variables: { personId: parseInt(personId, 10) },
  });

  const topics = data?.topicsFromPerson;
  const [topic, setTopic] = useState<Topic | undefined>(
    topics?.find(topic => topic.id === parseInt(state?.topicId || "-1")),
  );

  if (!person) {
    return <Loader />;
  }

  const phone = person.phones?.find(phone => {
    return (
      phone?.messagingAllowed &&
      (phone.type === PhoneType.mobile || phone.type === PhoneType.mobilework)
    );
  });

  if (!phone) {
    return null;
  }

  const signature =
    person.client?.account?.type !== AccountType.eap
      ? "MfG Ihre Mitarbeiterberatung"
      : "MfG Ihr OTHEB Team";

  const onSubmit = async (message: string) => {
    const body = message + (signature ? `\n${signature}` : "");

    return smsNotification({
      variables: {
        input: {
          body,
          phoneId: phone.id,
          personId: person.id,
          topicId: topic?.id,
          serviceId: state?.serviceId,
        },
      },
    });
  };

  return (
    <>
      <PersonHeader
        person={person}
        mapList={[
          {
            path: "/",
            name: "SMS",
          },
        ]}
      />
      <Grid container style={{ marginTop: "192px" }}>
        <Grid item xs={3}></Grid>
        <Grid
          item
          container
          xs={6}
          direction="column"
          alignItems="center"
          style={{ display: "flex" }}
        >
          <SmsHeader
            phone={phone}
            person={person}
            topics={topics}
            topic={topic}
            setTopic={topic => setTopic(topic)}
          />
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <WriteAnswer
            endRef={ref}
            onSubmit={onSubmit}
            onAbort={goBack}
            initialWriteMode
            signature={signature}
          />
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
      <div ref={ref} />
    </>
  );
};

export default Sms;

type TSmsHeader = {
  phone: Phone;
  person: Person;
  setTopic: (topic: Topic) => void;
  topic?: Topic;
  topics?: Topic[];
};

const SmsHeader = ({ person, phone, topic, topics, setTopic }: TSmsHeader) => {
  return (
    <>
      <SmsIllustration />
      <Box mt={4} py="3px">
        <Typography variant="h2">SMS an {person.name}</Typography>
      </Box>
      {topics && (
        <Box py="3px">
          <Typography variant="h2">
            Zum{" "}
            <TopicSelectOrCreate
              // @ts-ignore
              allowCreate={false}
              topics={topics}
              selectAction={setTopic}
              topic={topic}
              tabIndex={5}
            />
          </Typography>
        </Box>
      )}
      <Box my={1} />
      <Typography variant="body2">SMS an: {phone.readableNumber}</Typography>
    </>
  );
};
