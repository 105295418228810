import { useEffect } from "react";
import dayjs from "dayjs";

import {
  PersonActivity,
  AllActivitiesSubscriptionSubscription,
  AllActivitiesSubscriptionDocument,
  useBusinessActivitiesQuery,
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

export const useAllBusinessActivitiesSubscription = (variables: {
  personIds: number[];
  skip?: number;
  take?: number;
}) => {
  const { data, fetchMore, subscribeToMore, loading } = useBusinessActivitiesQuery({
    variables: { ...variables },
    fetchPolicy,
  });
  useEffect(
    () => {
      const unsubscribe = subscribeToMore<AllActivitiesSubscriptionSubscription>({
        document: AllActivitiesSubscriptionDocument,
        variables,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data || !prev) return prev;
          const newActivity = subscriptionData.data.allActivitiesSubscription;
          const activities = [...prev.businessActivities];
          if (newActivity) {
            const updateIndex = activities.findIndex(activity => activity!.id === newActivity.id);

            let index = updateIndex;

            if (updateIndex === -1) {
              index = activities.findIndex(activity =>
                dayjs(activity!.start).isBefore(newActivity.start),
              );
            }

            const replace = updateIndex > -1 ? 1 : 0;

            activities.splice(index, replace, newActivity);
          }
          return { ...prev, businessActivities: activities };
        },
      });
      return unsubscribe;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variables],
  );

  return { activities: (data?.businessActivities || []) as PersonActivity[], loading, fetchMore };
};
