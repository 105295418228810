import React from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import {
  ServiceType,
  useBreadCrumbInfoByClientIdQuery,
  useTriggerResearchCallMutation,
} from "../../../generated/graphql";
import Loader from "../../atoms/Loading";
import ServiceTemplate from "../../templates/Service";
import ResearchElement from "../../organisms/ResearchElement";
import PlainResearchElement from "../../organisms/PlainResearchElement";
import Pagination from "./Pagination";
import Call from "./Call";
import { useServiceSubscription } from "../../../hooks/useServiceSubscription";
import { useServiceResearchObjectSubscription } from "../../../hooks/useServiceResearchObjectSubscription";

function ServiceResearchElement() {
  const { serviceId, researchId } = useParams<{ serviceId: string; researchId: string }>();

  const [triggerCall, callMeta] = useTriggerResearchCallMutation();

  const callLoading = callMeta.loading;

  const startCall = (primeDN: number, phoneId: number) => {
    return triggerCall({
      variables: { serviceResearchObjectId: parseInt(researchId, 10), primeDN, phoneId },
    });
  };

  const { service, loading: serviceLoading, refetch } = useServiceSubscription(
    parseInt(serviceId, 10),
  );

  const { loading, researchObject } = useServiceResearchObjectSubscription(
    parseInt(researchId, 10),
    parseInt(serviceId, 10),
  );

  const clientId = service?.client?.id;
  const { data: breadcrumbData } = useBreadCrumbInfoByClientIdQuery({
    skip: !clientId,
    variables: {
      id: clientId!,
    },
  });

  if (serviceLoading || loading) {
    return <Loader />;
  }

  if (!service) {
    return (
      <div>
        <div>Service wurde nicht gefunden</div>
      </div>
    );
  }

  const person = service?.client?.person;

  if (!person || !person.id) {
    return (
      <div>
        <div>Person wurde nicht gefunden</div>
      </div>
    );
  }

  if (!researchObject) {
    return (
      <div>
        <div>Element wurde nicht gefunden</div>
      </div>
    );
  }
  const business = researchObject.businessObject;

  const headline = (business ? business?.name : researchObject.descriptor) || "";

  const namePathMap = [
    {
      // @ts-ignore
      name: service.topic.name,
      // @ts-ignore
      path: `/person/${person.id}/topics/${service.topic.id}`,
    },
    {
      // @ts-ignore
      name: service.serviceMeta.name,
      path: `/service/${service.id}`,
    },
    { name: headline, path: `/service/${serviceId}/research/${researchId}` },
  ];

  return (
    <>
      <ServiceTemplate
        mapPathList={namePathMap}
        person={person}
        service={service}
        refetchService={refetch}
        headline={headline}        
        contractExpirationDate={breadcrumbData?.breadCrumbInfoByClientId?.contractEnd}
        language={breadcrumbData?.breadCrumbInfoByClientId?.language || ""}
        button={
          service.serviceMeta?.type !== ServiceType.researchBlank && (
            <Call business={business} action={startCall} loading={callLoading} />
          )
        }
      >
        <Grid item xs={1} xl={1}></Grid>
        {service.serviceMeta?.type === ServiceType.researchBlank ? (
          <PlainResearchElement researchObject={researchObject} />
        ) : (
          <ResearchElement researchObject={researchObject} />
        )}
      </ServiceTemplate>
      <Pagination currentId={parseInt(researchId, 10)} service={service} />
    </>
  );
}

export default ServiceResearchElement;
