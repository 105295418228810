import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  subHeader: {
    top: "64px !important",
    backgroundColor: `${theme.palette.background.default} !important`,
  },
  subHeaderBorder: {
    alignSelf: "stretch",
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  actions: {
    textAlign: "right",
  },
  deactivated: {
    backgroundImage: `linear-gradient(110deg, ${theme.palette.background.default} 25%, #efe8dd 25%, #efe8dd 50%, ${theme.palette.background.default} 50%, ${theme.palette.background.default} 75%, #efe8dd 75%, #efe8dd 100%)`,
    backgroundSize: " 21.28px 58.48px",
  },
}));

export default useStyles;
