import React from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Maybe from "graphql/tsutils/Maybe";

import { User, PapershiftWorkingArea } from "../../../../generated/graphql";
import { getWorkingAreaName } from "../../../../utils/translateWorkingAreaEnums";
import WorkingAreasFilter from "../../../molecules/WorkingAreasFilter";

import { sortUsersByAlias } from "./helper";
import { FilterState } from "../reducer";

import { Chip, TextField } from "@material-ui/core";
import AutoComplete from "../../../molecules/Autocomplete";

/**
 *
 */
interface IFilter {
  filter: FilterState;
  toggleWorkingArea: (workingArea: PapershiftWorkingArea, selected: boolean) => void;
  addExternalUser: (user: User) => void;
  removeExternalUser: (user: User) => void;
  //   users: Map<number, User>;
}

const Filter = ({ filter, toggleWorkingArea, addExternalUser, removeExternalUser }: IFilter) => {
  return (
    <Box>
      <Box width={"max-content"}>
        <WorkingAreasFilter
          headline="Rollen"
          toggleWorkingArea={toggleWorkingArea}
          selectedIds={Array.from(filter.workingAreas).map(([id, wa]) => wa.id)}
        />
      </Box>
      <Box>
        <Box padding="24px 0 16px 0">
          <Typography variant="h4">Team</Typography>
        </Box>
        <UserFilter
          addExternalUser={addExternalUser}
          removeExternalUser={removeExternalUser}
          filter={filter}
        />
      </Box>
    </Box>
  );
};

export default Filter;

/**
 *
 */
interface IUserFilter {
  filter: FilterState;
  addExternalUser: (user: User) => void;
  removeExternalUser: (user: User) => void;
}

const UserFilter = ({ addExternalUser, filter, removeExternalUser }: IUserFilter) => {
  const { allUsers, externalUsers } = filter;  

  const sortedUsers = Array.from(allUsers)
    .map(([id, user]) => user)
    .sort(sortUsersByAlias);
  const sortedExternalUsers = Array.from(externalUsers)
    .map(([id, user]) => user)
    .sort(sortUsersByAlias);

  if (!!sortedUsers.length) {
    return (
      <>
        <AutoComplete
          hasError={false}
          error={undefined}
          label="Search"
          placeholder="Search Users"
          handleChange={(o: { label: string; value: User }) => {
            addExternalUser(o?.value);
          }}
          value={null}
          suggestions={
            sortedUsers
              ? sortedUsers.map((user: User) => {
                  if (user) {
                    return {
                      value: user,
                      label: user.person?.alias,
                    };
                  }
                  return null;
                })
              : []
          }
        />

        {sortedExternalUsers && (
          <Box display={"flex"} flexDirection={"column"} alignItems={"start"} gridGap={8} paddingY={1}>
            {sortedExternalUsers.map((user: User) => {
              return (
                <Chip
                  key={user.id}
                  label={user.person?.alias}
                  onClick={() => removeExternalUser(user)}
                  onDelete={() => removeExternalUser(user)}
                />
              );
            })}
          </Box>
        )}
      </>
    );
  }

  return <></>;
  //   const usersFilter = Array.from(users)
  //     .filter(([id, user]) => {
  //       return (
  //         user.papershiftWorkingAreas &&
  //         user.papershiftWorkingAreas.some((workingArea: Maybe<PapershiftWorkingArea>) => {
  //           // Should not be a case, but typescript needs it
  //           if (!workingArea || !workingArea.id) {
  //             return false;
  //           }

  //           // Show user if either no working area is selected or the user has a matching working area
  //           return !!filter.workingAreas.get(workingArea.id) || filter.workingAreas.size === 0;
  //         })
  //       );
  //     })
  //     .map(([id, user]) => user);
  //   usersFilter.sort(sortUsersByAlias);
  //   return (
  //     <>
  //       {usersFilter.map(user => {
  //         const id = user.id;

  //         let initials: string[] = [];
  //         if (user.papershiftWorkingAreas) {
  //           initials = user.papershiftWorkingAreas.map(workingArea => {
  //             if (!!workingArea && !!workingArea.name) {
  //               return getWorkingAreaName(workingArea.name)
  //                 .split(" ")
  //                 .map(_ => _[0])
  //                 .join("");
  //             }
  //             return "";
  //           });
  //         }

  //         if (user && id) {
  //           const checked = !!filter.users.get(id);
  //           return (
  //             <Box
  //               display="flex"
  //               alignItems="center"
  //               key={`${id}`}
  //               onClick={() => toggleUser(user, checked, id)}
  //               style={{ cursor: "pointer" }}
  //             >
  //               <Box marginLeft="-12px">
  //                 <Checkbox checked={checked} />{" "}
  //               </Box>
  //               <Box lineHeight="24px" paddingTop="4px">
  //                 {user.person!.name}
  //                 {user.person!.alias && user.person?.alias !== user.person?.name && (
  //                   <>
  //                     <br />({user.person!.alias})
  //                   </>
  //                 )}{" "}
  //                 [{initials.join(", ")}]
  //               </Box>
  //             </Box>
  //           );
  //         }

  //         return <></>;
  //       })}
  //     </>
  //   );
};
