import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PrimaryButton from "../../atoms/PrimaryButton";
import { useHistory } from "react-router-dom";
import { Task } from "../../../generated/graphql";

type TPool = { tasks: Task[] };

const Pool = ({ tasks }: TPool) => {
  const { push } = useHistory();

  const count = tasks.length;

  const countName = count > 1 ? count : "eine";
  const task = `Aufgabe${count === 1 ? "" : "n"}`;
  const invitationAttribute = count > 1 ? "eine" : "diese";

  return count > 0 ? (
    <Box display="flex" justifyContent="center" mt={10} mb={4} flexDirection="column">
      <Typography variant="h3" align="center">
        Es gibt derzeit {countName} nicht zugewiesene {task}. Möchtest du dir {invitationAttribute}{" "}
        auswählen und bearbeiten?
      </Typography>
      <Box mt={3} display="flex" justifyContent="center">
        <PrimaryButton onClick={() => push("/pool")}>Aufgabe auswählen</PrimaryButton>
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default Pool;
