import { usePersonActivityQuery } from "../generated/graphql";

const usePersonActivitySubscription = (id: number = -1) => {
  const { loading, data, refetch } = usePersonActivityQuery({
    variables: { id },
    skip: id === -1,
  });

  return { loading, personActivity: data?.personActivity, refetch };
};

export default usePersonActivitySubscription;
