import React from "react";

import { PersonActivity } from "../../../generated/graphql";

import Bubble, { ActivityInteractionType } from "./Bubble";

interface IResearchCallBubble {
  activity: PersonActivity;
  clickHandler: (id: number) => void;
}

const ResearchCallBubble = ({ activity, clickHandler }: IResearchCallBubble) => {
  const business = activity?.serviceResearchObject?.businessObject?.name;
  const contact = activity?.serviceResearchObject?.contactPerson?.name;
  // Due to refactoring: https://medium.com/@calebmer/when-to-use-graphql-non-null-fields-4059337f6fc8
  if (!activity.activityType || !(activity.activityType in ActivityInteractionType)) {
    console.log("Activity is currently not supporteds");
    return null;
  }

  return (
    <Bubble
      // @ts-ignore
      type={activity.activityType}
      onClick={() => clickHandler(activity.id)}
      text={business || contact || ""}
    />
  );
};

export default ResearchCallBubble;
