import { makeStyles } from "@material-ui/styles";
import { Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: (props: any) => {
      return {
        background: !props.hasAvatar ? theme.palette.primary.main : "transparant",
        cursor: props.clickable ? "pointer" : "normal",
        fontSize: theme.spacing(1.75),
        fontWeight: theme.typography.fontWeightMedium,
        height: theme.spacing(props.width),
        letterSpacing: "0.05em",
        lineHeight: theme.spacing(3),
        width: theme.spacing(props.width),
      };
    },
  }),
);

export default useStyles;
