import React, { useState, ReactNode, useEffect } from "react";

import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Theme, withStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";

import {
  Maybe,
  useGetCaseManagementReviewDetailsQuery,
  useGetClientReviewTemplateQuery,
  GetCaseManagementReviewDetailsQuery,
  ClientReviewAttributeGroup,
  useCreateCmReminderTaskMutation,
  useGetCmReminderTaskQuery,
} from "../../../generated/graphql";
import { fetchPolicy } from "../../../utils/constants";
import useLocalStorage, { LocalStorage } from "../../../utils/localStorageHelper";

import WizardNavigation from "../../molecules/WizardNavigation";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import CaseManagementRisk from "../../molecules/CaseManagementRisk";

import CaseManagementReviewAttribute from "./CaseManagementReviewAttribute";

export const StyledKeyboardDatePicker = withStyles((theme: Theme) => ({
  root: {
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
}))(KeyboardDatePicker);

interface ICaseManagementWizardProps {
  personId: number;
}

interface INavigator {
  previous?: () => void;
  next?: () => void;
  finalize?: () => void;
}

interface INavigatorComponent {
  component: ReactNode;
}

const CaseManagementWizard = ({ personId }: ICaseManagementWizardProps) => {
  const { data: reviewData } = useGetCaseManagementReviewDetailsQuery({
    variables: { personId },
  });
  const { data: templateData } = useGetClientReviewTemplateQuery({ variables: { templateId: 2 } });
  const { getItem, removeItem, setItem } = useLocalStorage(
    LocalStorage.caseManagementReview,
    String(personId),
  );
  const { push } = useHistory();
  const lastStep = getItem("step");
  const [step, setStep] = useState(parseInt(lastStep || "0"));
  const { goBack } = useHistory();
  const [createCMReminder] = useCreateCmReminderTaskMutation();
  const { data } = useGetCmReminderTaskQuery({
    variables: { personId },
    fetchPolicy,
  });

  const task = data?.getCMReminderTask;
  const foo = task?.snoozeUntil;
  const [snoozeUntil, setSnoozeUntil] = useState(foo);
  useEffect(() => {
    setSnoozeUntil(foo);
  }, [foo]);

  setItem("step", String(step));

  const reviewId = reviewData?.getCaseManagementReview?.id;
  const clientReviewAttributeGroups =
    templateData?.getClientReviewTemplate?.clientReviewAttributeGroups;

  if (!reviewId || !clientReviewAttributeGroups) {
    return null;
  }

  const attributes = reviewData?.getCaseManagementReview?.caseManagementReviewAttributes;

  const flag = attributes?.find(attribute => {
    return attribute?.clientReviewAttribute?.name === "flag";
  });

  const riskAssessment = attributes?.find(attribute => {
    return attribute?.clientReviewAttribute?.name === "riskAssessment";
  });

  const navigator: (INavigator & INavigatorComponent)[] = [
    {
      component: (
        <CaseManagementWizardStep
          key={`cmws-step-1-${reviewId}`}
          clientReviewAttributeGroups={clientReviewAttributeGroups}
          page={1}
          reviewId={reviewId}
        />
      ),
      previous: () => {
        removeItem("step");
        goBack();
      },
      next: () => {
        setStep(1);
      },
    },
    {
      component: (
        <CaseManagementWizardRisk
          key={`cmws-step-2-${reviewId}`}
          reviewData={reviewData}
          next={() => {
            setStep(2);
          }}
          finalize={() => {
            setStep(3);
          }}
        />
      ),
      previous: () => {
        setStep(0);
      },
    },
    {
      component: (
        <CaseManagementWizardStep
          key={`cmws-step-3-${reviewId}`}
          clientReviewAttributeGroups={clientReviewAttributeGroups}
          page={2}
          reviewId={reviewId}
        />
      ),
      previous: () => {
        if (!flag?.attributeValue || !riskAssessment?.attributeValue) {
          setStep(0);
        } else {
          setStep(1);
        }
      },
      next: () => {
        setStep(3);
      },
    },
    {
      component: (
        <FollowUp
          key={snoozeUntil}
          snoozeUntil={snoozeUntil}
          setSnoozeUntil={setSnoozeUntil}
          finalize={async () => {
            setItem("step", "0");

            push(`/person/${personId}/caseManagement/`);
          }}
        />
      ),
      previous: () => {
        if (snoozeUntil) {
          setSnoozeUntil(undefined);
        } else {
          setStep(1);
        }
      },
      finalize: !snoozeUntil
        ? undefined
        : async () => {
            if (snoozeUntil) {
              await createCMReminder({
                variables: {
                  taskId: task?.id,
                  personId,
                  snoozeUntil,
                },
              });
            }

            setItem("step", "0");

            push(`/person/${personId}/caseManagement/`);
          },
    },
  ];

  const hasNavigation =
    !!navigator[step].previous || !!navigator[step].next || !!navigator[step].finalize;

  return (
    <Box mt={22}>
      <Grid container>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Typography align="center">Review</Typography>

          {navigator[step].component || null}
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
      {hasNavigation && (
        <WizardNavigation>
          {navigator[step].previous && (
            <SecondaryButton onClick={navigator[step].previous}>Zurück</SecondaryButton>
          )}
          {navigator[step].next && (
            <PrimaryButton onClick={navigator[step].next}>Weiter</PrimaryButton>
          )}
          {navigator[step].finalize && (
            <PrimaryButton onClick={navigator[step].finalize}>Weiter</PrimaryButton>
          )}
        </WizardNavigation>
      )}
    </Box>
  );
};
type TCaseManagementWizardStep = {
  clientReviewAttributeGroups?: Maybe<ClientReviewAttributeGroup>[] | null;
  page: number;
  reviewId: number;
};
const CaseManagementWizardStep = ({
  clientReviewAttributeGroups,
  page,
  reviewId,
}: TCaseManagementWizardStep) => {
  const group = clientReviewAttributeGroups?.find(
    clientReviewAttributeGroup => clientReviewAttributeGroup?.pageOrder === page,
  );

  if (!group) {
    return null;
  }

  return (
    <Box key={reviewId} mb={10}>
      <Typography align="center" variant="h1">
        {group.headingUpdate}
      </Typography>
      {group.clientReviewAttributes?.map((clientReviewAttribute, index) => {
        return (
          <Box mt={5} key={`${clientReviewAttribute!.id}`}>
            <CaseManagementReviewAttribute
              tabIndex={index + 1}
              caseManagementReviewId={reviewId}
              clientReviewAttributeId={clientReviewAttribute!.id!}
              label={clientReviewAttribute!.label!}
              type={clientReviewAttribute!.type!}
            />
          </Box>
        );
      })}
    </Box>
  );
};

const CaseManagementWizardRisk = ({
  reviewData,
  next,
  finalize,
}: INavigator & { reviewData: GetCaseManagementReviewDetailsQuery | undefined }) => {
  const attributes = reviewData?.getCaseManagementReview?.caseManagementReviewAttributes;

  const flag = attributes?.find(attribute => {
    return attribute?.clientReviewAttribute?.name === "flag";
  });

  const riskAssessment = attributes?.find(attribute => {
    return attribute?.clientReviewAttribute?.name === "riskAssessment";
  });

  if (!flag?.attributeValue || !riskAssessment?.attributeValue) {
    next && next();
    return null;
  }

  const risk = {
    flag: flag.attributeValue as "redFlag" | "yellowFlag" | "whiteFlag" | "unflagged",
    riskAssessment: riskAssessment.attributeValue,
  };

  const user = dayjs(riskAssessment.lastUpdate).isAfter(flag.lastUpdate)
    ? riskAssessment.user
    : flag.user;
  const lastUpdate = dayjs(riskAssessment.lastUpdate).isAfter(flag.lastUpdate)
    ? riskAssessment.lastUpdate
    : flag.lastUpdate;

  if (!user || !lastUpdate) {
    return null;
  }

  return (
    <Box>
      <Typography align="center" variant="h1">
        Hat sich das Risiko geändert?
      </Typography>
      <Box height={48} />
      <CaseManagementRisk risk={risk} user={user} lastUpdate={lastUpdate} />
      <Box display="flex" justifyContent="center">
        <SecondaryButton onClick={next}>Ja, das Risiko hat sich geändert</SecondaryButton>
        <Box width={16} />
        <SecondaryButton onClick={finalize}>Nein, das Risiko bleibt so</SecondaryButton>
      </Box>
    </Box>
  );
};

type TFollowUp = {
  finalize: () => Promise<void>;
  snoozeUntil?: dayjs.Dayjs;
  setSnoozeUntil: (date: dayjs.Dayjs) => void;
};

const FollowUp = ({ finalize, snoozeUntil, setSnoozeUntil }: TFollowUp) => {
  const [toggle, setToggle] = useState(!!snoozeUntil);

  return (
    <Box>
      <Typography align="center" variant="h1">
        Möchtest du dir den Klienten später nochmal anschauen?
      </Typography>
      <Box display="flex" justifyContent="center" pt={4}>
        {toggle ? (
          <MuiPickersUtilsProvider utils={DayjsUtils}>
            <StyledKeyboardDatePicker
              label="Datum"
              cancelLabel="Abbrechen"
              value={snoozeUntil || dayjs()}
              tabIndex={2}
              format="Do MMM"
              onChange={value => {
                if (value) {
                  setSnoozeUntil(value.startOf("day"));
                }
              }}
              disablePast
              key="startDate"
            />
          </MuiPickersUtilsProvider>
        ) : (
          <>
            <SecondaryButton
              onClick={() => {
                finalize();
              }}
            >
              Nein
            </SecondaryButton>
            <Box mx={1} />
            <SecondaryButton
              onClick={() => {
                setSnoozeUntil(
                  dayjs()
                    .startOf("day")
                    .add(1, "week"),
                );
                setToggle(true);
              }}
            >
              Ja
            </SecondaryButton>
          </>
        )}
      </Box>
    </Box>
  );
};

export default CaseManagementWizard;
