import React from "react";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";

import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";

import {
  BusinessUnit,
  useCreateBusinessUnitMutation,
  useEditBusinessUnitMutation,
} from "../../../generated/graphql";

import DialogForm from "../DialogForm";

interface BusinessUnitDialogProps {
  businessUnit?: Pick<BusinessUnit, "id" | "name">;
  accountId: number;
  open: boolean;
  toggle: () => void;
}

const BusinessUnitDialog = ({ businessUnit, open, toggle, accountId }: BusinessUnitDialogProps) => {
  const [createBusinessUnit] = useCreateBusinessUnitMutation();
  const [updateBusinessUnit] = useEditBusinessUnitMutation();

  return (
    <DialogForm
      title={`Business-Unit ${businessUnit !== undefined ? "bearbeiten" : "anlegen"}`}
      initialValues={{
        name: businessUnit?.name || "",
      }}
      open={open}
      onClose={toggle}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Pflichtangabe"),
      })}
      onSubmit={values => {
        // create new business unit
        if (businessUnit === undefined) {
          return createBusinessUnit({
            variables: { input: { accountId, name: values.name } },
          }).then(() => {
            toggle();
          });
        }

        // update existing business unit
        return updateBusinessUnit({
          variables: {
            input: {
              id: businessUnit.id,
              name: values.name,
            },
          },
        }).then(() => {
          toggle();
        });
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl margin="normal" fullWidth>
            <Field name="name" component={TextField} label="Name" />
          </FormControl>
        </Grid>
      </Grid>
    </DialogForm>
  );
};
export default BusinessUnitDialog;
