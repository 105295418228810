import * as Yup from "yup";

export default Yup.object().shape({
  firstName: Yup.string().required("Bitte ausfüllen"),
  lastName: Yup.string().required("Bitte ausfüllen"),
  alias: Yup.string(),
  initialAge: Yup.number()
    .typeError("Bitte nur Zahlen verwenden")
    .min(0, "Bitte nur positive Zahlen verwenden")
    .max(120, "Zahl muss kleiner als 120 sein")
    .required("Pflichtangabe"),
  ageGuessed: Yup.boolean(),
  gender: Yup.string().required("Pflichtangabe"),
  clientType: Yup.string().required("Pflichtangabe"),
  areaCode: Yup.string()
    .matches(/^\d+$/, "Bitte nur Zahlen verwenden")
    .required("Pflichtangabe"),
  number: Yup.string()
    .matches(/^\d+$/, "Bitte nur Zahlen verwenden")
    .required("Pflichtangabe"),
  ucmsId: Yup.string(),
  account: Yup.object().shape({
    value: Yup.string().required("Pflichtangabe")
  })
});
