import React from "react";

import { useHistory } from "react-router";
import groupBy from "lodash/groupBy";
import { useSnackbar } from "notistack";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { GetGMailThreadsQuery } from "../../../generated/graphql";

import { InboxComponent } from "../../organisms/InboxComponent";
import UnassignedUserButton from "../../atoms/UnassignedUserButton";
import emptyAlternative from "../../assets/emptyAlternative.svg";
import Notification from "../../molecules/Notification";
import TextLink from "../../atoms/TextLink";
import TypeIcon from "../../atoms/TypeIcon";
import Counter from "../../atoms/Counter";
import InboxGroup, { InboxHeadline } from "../../organisms/InboxGroup";
import UserAvatar from "../../organisms/UserAvatar";
import EmptyAlternativeSecond from "../../assets/EmptyAlternativeSecond";

type TEmailInboxView = {
  threads: GetGMailThreadsQuery["getGMailThreads"][];
};

export const EmailInboxView = ({ threads }: TEmailInboxView) => {
  const groups = groupBy(threads, thread => {
    const email = thread[0];
    if (!email || email.incoming === undefined) {
      return "outgoing";
    }

    return email !== undefined && email.incoming ? "incoming" : "outgoing";
  });

  return (
    <Grid container>
      <Box mb={5} width={1}>
        <InboxGroup>
          <InboxHeadline>Neue E-Mails</InboxHeadline>
          <EmailThreadGroupView threads={groups.incoming} incoming />
        </InboxGroup>
      </Box>
      <Box mb={5} width={1}>
        <InboxGroup>
          <InboxHeadline>E-Mail Konversationen</InboxHeadline>
          <EmailThreadGroupView threads={groups.outgoing} />
        </InboxGroup>
      </Box>
    </Grid>
  );
};

type TEmailThreadGroupView = {
  incoming?: boolean;
  threads: GetGMailThreadsQuery["getGMailThreads"][];
};

const EmailThreadGroupView = ({ threads, incoming = false }: TEmailThreadGroupView) => {
  if (!threads) {
    const message = incoming
      ? "Hier ist grad nichts zu tun: Keine neuen E-Mails vorhanden"
      : "Keine aktiven E-Mail Konversationen vorhanden";
    return (
      <Box mt={5} width={1} textAlign="center">
        {incoming ? <EmptyAlternativeSecond /> : <img src={emptyAlternative} alt={message} />}
        <Box mt={4} />
        <Typography>{message}</Typography>
      </Box>
    );
  }

  return (
    <>
      {threads.map(thread => {
        if (thread === undefined) {
          return null;
        }
        const attachments = thread
          .map(email => {
            if (email === undefined) {
              return undefined;
            }
            return email.attachments;
          })
          .filter(_ => _ && _.length > 0)
          .reduce((carry, item) => {
            if (!item) {
              return carry;
            }
            if (!carry) {
              return [...item];
            }
            return [...carry, ...item];
          }, []);

        const email = thread[0];

        if (email === undefined) {
          return null;
        }

        return (
          <EmailInboxThreadView
            attachments={attachments}
            threadId={email.threadId}
            email={email}
            key={email.threadId || "..."}
            threadLength={thread.length}
          />
        );
      })}
    </>
  );
};

type TEmailInboxThreadView = {
  attachments?: any[] | null;
  threadId: any;
  threadLength: any;
  email: GetGMailThreadsQuery["getGMailThreads"][0];
};

const EmailInboxThreadView = ({
  attachments,
  email,
  threadId,
  threadLength,
}: TEmailInboxThreadView) => {
  const { gMailInbox, date, payload, person, businessObject, personActivity } = email;

  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const handleAttachmentClick = () => {
    enqueueSnackbar("", {
      content: (key: string) => (
        <Notification
          id={key}
          message={
            <>
              Anhänge können nur in der E-Mail <br />
              Ansicht heruntergelden werden.
            </>
          }
        />
      ),
    });
  };

  return (
    <InboxComponent
      leading={
        personActivity && personActivity.user ? (
          <UserAvatar user={personActivity.user} />
        ) : (
          <UnassignedUserButton />
        )
      }
      actions={
        <>
          <Grid item xs={6}>
            {gMailInbox?.email?.split("@")[0]}
          </Grid>
          {attachments && attachments[attachments.length - 1] && (
            <Grid item xs={6}>
              <Box display="flex" alignItems="center">
                <Box m={1}>
                  <TypeIcon type="insertDriveFile" />
                </Box>
                <TextLink onClick={handleAttachmentClick}>
                  {attachments[attachments.length - 1].filename}
                </TextLink>
                {attachments.length > 1 ? (
                  <Box m={1}>
                    <Counter
                      id={`${threadId}-counter-${attachments.length}`}
                      color="primary"
                      value={`+${attachments.length - 1}`}
                    />
                  </Box>
                ) : null}
              </Box>
            </Grid>
          )}
        </>
      }
      isEmail
      context={{
        businessObject,
        person,
      }}
      dates={{
        start: date || "",
      }}
      title={{
        name: `${payload?.subject || "Kein Betreff"} ${
          threadLength > 1 ? `(${threadLength})` : ""
        }`,
        onClick: () => {
          if (email.person && !email.person.temporary) {
            push(`/person/${email.person.id}/email/${threadId}`);
          } else if (email.businessObject) {
            push(`/business/${email.businessObject.id}/email/${threadId}`);
          } else {
            push(`/email/${threadId}`);
          }
        },
      }}
    />
  );
};

export default EmailInboxView;
