import React from "react";
import groupBy from "lodash/groupBy";
import map from "lodash/map";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DataColumn from "../../molecules/DataColumn";
import Markup from "../../atoms/Markup";
import { List } from "../ServiceList";
import {
  useServicesQuery,
  Person,
  ConversationReportReviewAttribute,
  ClientAttribute,
  StatisticItem,
  ReportActivityQuery,
} from "../../../generated/graphql";
import { trimMarkdown } from "../../../utils/markdown";

type IServiceList = { ids: number[] };

const ServiceList = ({ ids }: IServiceList) => {
  const { data, loading } = useServicesQuery({ variables: { ids } });
  const services = data?.services;

  return services && !loading ? (
    <Box mt={5}>
      <List services={services} />
    </Box>
  ) : (
    <></>
  );
};

type IReportDetails = {
  person: Person;
  report: ReportActivityQuery["personActivity"]["conversationReport"]; // TODO: get maybe out here
};

const ReportDetails = ({ person, report }: IReportDetails) => {
  if (!report) {
    return (
      <Typography variant="h4" color="error">
        Der Report konnte nicht geladen werden.
      </Typography>
    );
  }

  const reviewAttributes =
    (report.conversationReportReviewAttributes as ConversationReportReviewAttribute[]) || [];
  const statistics = (report.statistics || []) as StatisticItem[];
  const clientAttributes = (report.clientAttributes as ClientAttribute[]) || [];
  const connectedServices = (report.connectedServices || []).map(s => s!.id!);
  const workItems = statistics.filter(i => i?.group?.type === "work");
  const privateItems = statistics.filter(i => i?.group?.type === "private");

  return (
    <Box mt={5} mb={5}>
      <MarkupParagraph title={`Was war das Anliegen von ${person.name}?`} text={report.issue} />
      {!!workItems.length && (
        <Box mt={5}>
          <Box mb={2}>
            <Typography variant="h4">Arbeitsbezogene Gründe für die Kontaktaufnahme</Typography>
          </Box>
          <Grid container spacing={2}>
            {map(groupBy(workItems, "group.name"), (value: StatisticItem[], key: string) => (
              <DataColumn label={key} key={`stat-group-${key}`}>
                {value.map(item => (
                  <Typography variant="body2" key={item.name!}>
                    {item.name!}
                  </Typography>
                ))}
              </DataColumn>
            ))}
          </Grid>
        </Box>
      )}
      {!!privateItems.length && (
        <Box mt={!!workItems.length ? 1 : 5}>
          <Box mb={2}>
            <Typography variant="h4">Private Gründe für die Kontaktaufnahme</Typography>
          </Box>
          <Grid container spacing={2}>
            {map(groupBy(privateItems, "group.name"), (value: StatisticItem[], key: string) => (
              <DataColumn label={key} key={`stat-group-${key}`}>
                {value.map(item => (
                  <Typography variant="body2" key={item.name!}>
                    {item.name!}
                  </Typography>
                ))}
              </DataColumn>
            ))}
          </Grid>
        </Box>
      )}

      <MarkupParagraph title="Was habt ihr vereinbart?" text={report.agreement} />
      <MarkupParagraph
        title={`Wie war die psychische Verfassung von ${person.name}?`}
        text={report.mentalDescription}
      />
      <MarkupParagraph title="Gesprächsverlauf" text={report.summary} />
      <MarkupParagraph title="Risikoeinschätzung" text={report.riskInfo} />
      <MarkupParagraph title="CM Prüfung" text={report.cmCheckMessage} />

      <Box mt={5}>
        <Typography variant="h3">Informationen zum Klienten</Typography>
        <Box borderTop={1} borderColor="grey.400"></Box>
      </Box>

      {reviewAttributes
        .filter(
          (r: ConversationReportReviewAttribute) =>
            r.clientReviewAttribute &&
            r.clientReviewAttribute.type &&
            r.clientReviewAttribute.type !== "checkbox" &&
            r.attributeValue,
        )
        .map((reviewAttr: ConversationReportReviewAttribute) => {
          const changed =
            !!clientAttributes.length &&
            clientAttributes.findIndex(
              (ca: ClientAttribute) =>
                ca.clientReviewAttributeId === reviewAttr.clientReviewAttributeId,
            ) !== -1;

          const { length } = trimMarkdown(reviewAttr.attributeValue || "", 2);

          if (length < 2) {
            return null;
          }

          return (
            <Box key={`review-attr-val-map-${reviewAttr.id}`} mt={5} position="relative">
              <Box mb={2}>
                <Typography variant="h4">{reviewAttr.clientReviewAttribute?.label}</Typography>
              </Box>
              <Typography variant="body2">
                <Markup value={reviewAttr.attributeValue || ""} />
              </Typography>
              {changed && (
                <Box
                  bgcolor="secondary.main"
                  position="absolute"
                  top="0"
                  bottom="0"
                  left="-16px"
                  width="5px"
                ></Box>
              )}
            </Box>
          );
        })}
      {!reviewAttributes.filter(
        (r: ConversationReportReviewAttribute) =>
          r.clientReviewAttribute &&
          r.clientReviewAttribute.type &&
          r.clientReviewAttribute.type !== "checkbox" &&
          r.attributeValue,
      ).length && (
        <Box mt={5}>
          <Typography variant="h4">
            Es wurden keine Änderungen an den persönlichen Informationen vorgenommen
          </Typography>
        </Box>
      )}
      {!!connectedServices.length && (
        <>
          <Box mt={5}>
            <Typography variant="h3">Bearbeitete Services</Typography>
            <Box borderTop={1} borderColor="grey.400"></Box>
          </Box>
          <ServiceList ids={connectedServices}></ServiceList>
        </>
      )}
    </Box>
  );
};

type IMarkupParagraph = {
  text: string | any;
  title: string;
};

const MarkupParagraph = ({ text, title }: IMarkupParagraph) => {
  const { length } = trimMarkdown(text || "", 2);

  if (length < 2) {
    return null;
  }

  return (
    <Box mt={5}>
      <Box mb={2}>
        <Typography variant="h4">{title}</Typography>
      </Box>
      <Typography variant="body2">
        <Markup value={text || ""} />
      </Typography>
    </Box>
  );
};

export default ReportDetails;
