import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => {
  return {
    iconButton: {
      cursor: "pointer",
      padding: 0,
    },
  };
});

export default useStyles;
