import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    taskIconRoot: {
      color: "transparent",
      height: 24,
      width: "auto",
    },
  };
});

export default useStyles;
