import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import React from "react";
import { useParams } from "react-router-dom";
import {
  Service,
  useBreadCrumbInfoByPersonIdQuery,
  useTopicQuery,
} from "../../../generated/graphql";
import { usePersonSubscription } from "../../../hooks/usePersonSubscription";
import useScrollTop from "../../../hooks/useScrollTop";
import { fetchPolicy } from "../../../utils/constants";
import Card from "../../molecules/Card";
import ServiceList from "../../organisms/ServiceList";
import PersonLayout from "../../templates/PersonLayout";

export default function PersonOverview() {
  useScrollTop();
  dayjs.extend(LocalizedFormat);
  const { id, topicId } = useParams<{ id: string; topicId: string }>();

  const { person } = usePersonSubscription(parseInt(id, 10));

  const personId = person?.id;
  const { data: breadcrumbData } = useBreadCrumbInfoByPersonIdQuery({
    skip: !personId,
    variables: {
      id: personId!,
    },
  });
  const { data: topicData, loading } = useTopicQuery({
    variables: { id: parseInt(topicId, 10) },
    fetchPolicy,
  });
  const topic = topicData?.topic;
  if (!person) return <></>;
  const services: Service[] = (topic?.services as Service[]) || [];

  return (
    <PersonLayout
      person={person}
      topicId={parseInt(topicId, 10)}
      contractExpirationDate={breadcrumbData?.breadCrumbInfoByPersonId?.contractEnd}
      language={breadcrumbData?.breadCrumbInfoByPersonId?.language || ""}
      mapList={[
        {
          name: topic ? topic.name : "",
          path: `/person/${id}/topics/${topicId}`,
        },
      ]}
    >
      {!loading && topic && (
        <>
          <Card title="Themen Informationen">
            <Grid container>
              {topic.lastInteraction && (
                <Grid item xs={4}>
                  <Typography variant="h4" color="textSecondary" gutterBottom>
                    Letzte Beratung
                  </Typography>
                  <Typography variant="body2">
                    {topic.lastInteraction && dayjs(topic.lastInteraction).format("L")}
                  </Typography>
                </Grid>
              )}
              {topic.firstInteraction && (
                <Grid item xs={4}>
                  <Typography variant="h4" color="textSecondary" gutterBottom>
                    Erste Beratung
                  </Typography>
                  <Typography variant="body2">
                    {topic.firstInteraction && dayjs(topic.firstInteraction).format("L")}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Card>
          {topic.services && <ServiceList services={services} />}
        </>
      )}
    </PersonLayout>
  );
}
