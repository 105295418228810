import React, { FC } from "react";

import { BusinessObject, PersonQuery } from "../../../generated/graphql";

import EmailThreadProvider from "../../../providers/Email";

import EmailThread from "./EmailThread";

type TEmail = {
  context: { businessObject?: BusinessObject | null; person?: PersonQuery["person"] | null };
};

const Email: FC<TEmail> = ({ context }) => {
  return (
    <EmailThreadProvider context={context}>
      <EmailThread />
    </EmailThreadProvider>
  );
};

export default Email;
