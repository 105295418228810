import dayjs from "dayjs";
import config from "../../config";

import { PersonActivity } from "../../generated/graphql";

export type Message = { isClient: boolean; text: string; time: dayjs.Dayjs; uid: number };

export interface ChatConnection {
  hide: boolean;
  numNewMessages: number;
  lastTyping?: dayjs.Dayjs;
  activity?: PersonActivity;
  connection: WebSocket;
  history: Message[];
}

export interface ChatClient {
  user_id: number;
  alias: string;
  partner_id: number;
  account: string;
  latest: string | number;
  client_id: number;
  otheb: boolean;
}

export interface ChatOtheb {
  partner_id: string;
  user_id: undefined;
}

export type ChatUser = ChatClient | ChatOtheb;

export type ChatState = {
  obrowser: {
    connection?: WebSocket;
    clients?: Map<number, ChatClient>;
    memos?: Map<number, ChatClient>;
  };
  chats: Map<number, ChatConnection>;
};

export type Action =
  | {
      type: "connectGlobal";
      token: string;
    }
  | {
      type: "disconnectGlobal";
    }
  | {
      type: "setClients";
      clients: ChatUser[];
      memos: any[];
    }
  | {
      type: "connectChat";
      token: string;
      activity?: PersonActivity;
      id: number;
    }
  | {
      type: "disconnectChat";
      id: number;
    }
  | {
      type: "resetCounter";
      id: number;
    }
  | {
      type: "hideChat";
      id: number;
    }
  | {
      type: "setTyping";
      id: number;
    }
  | {
      type: "updateClient";
      id: number;
      latest: string;
    }
  | {
      type: "addChatMessageBulk";
      id: number;
      isActiveChat: boolean;
      messages: any[];
    }
  | {
      type: "addChatMessage";
      id: number;
      isActiveChat: boolean;
      message: any;
    };

export const obrowserInitial = {
  connection: undefined,
  clients: new Map(),
  memos: new Map(),
};

export const reducer = (state: ChatState, action: Action) => {
  let chat;
  switch (action.type) {
    case "updateClient":
      const activated = state.obrowser.clients?.get(action.id);

      if (activated) {
        state.obrowser.clients?.set(action.id, { ...activated, latest: action.latest });
      }

      return { ...state };
    case "setClients":
      // account for 'memo' AND 'client'
      const newIds = new Set([
        ...action.clients.map(client => client.user_id),
        ...action.memos.map(memo => memo.id),
      ]);

      const deleteIds = [...state.obrowser.clients?.keys()].reduce((carry, item) => {
        if (![...newIds.keys()].some(_ => _ === item)) {
          carry.push(item);
        }
        return carry;
      }, [] as number[]);

      deleteIds.forEach(id => {
        state.obrowser.clients?.delete(id);
      });

      // Add connected clients
      action.clients.forEach(client => {
        // @ts-ignore
        const existingClient = state.obrowser.clients?.get(client.user_id);

        // a 's'-type message might arrive before an 'u'-type message, with a newer timestamp. If
        // this is the case, ignore this client since we already updated it here
        // @ts-ignore
        if (!existingClient || dayjs(existingClient.latest).isBefore(dayjs(client.latest))) {
          // @ts-ignore
          state.obrowser.clients?.set(client.user_id, { ...client, otheb: false });
        }
      });

      // Add clients that are not connected anymore but wrote a message
      action.memos.forEach(memo => {
        state.obrowser.clients?.set(memo.id, { ...memo, user_id: memo.id, otheb: false });
      });
      return { ...state };
    case "disconnectGlobal":
      state.obrowser.connection?.close();
      return { ...state, obrowser: obrowserInitial };
    case "connectGlobal":
      return {
        ...state,
        obrowser: {
          ...state.obrowser,
          connection: new WebSocket(
            `${config.chatWebSocket}/obrowser?v=${config.chatVersion}`,
            action.token,
          ),
        },
      };
    case "connectChat":
      if (!action.id) {
        return state;
      }
      const connection = new WebSocket(
        `${config.chatWebSocket}/ochat/${action.id}?v=${config.chatVersion}`,
        action.token,
      );
      const numNewMessages = localStorage.getItem(connection.url);
      state.chats.set(action.id, {
        numNewMessages: !!numNewMessages ? parseInt(numNewMessages) : 0,
        hide: false,
        activity: action.activity,
        connection,
        history: [],
      });
      return { ...state };
    case "disconnectChat":
      chat = state.chats.get(action.id);
      chat?.connection.close();
      state.chats.delete(action.id);
      return { ...state };
    case "resetCounter":
      chat = state.chats.get(action.id);
      if (!!chat) {
        chat.numNewMessages = 0;
        localStorage.setItem(chat.connection.url, "0");
      }
      return { ...state };
    case "hideChat":
      chat = state.chats.get(action.id);
      if (!!chat) {
        chat.hide = true;
      }
      return { ...state };
    case "setTyping":
      chat = state.chats.get(action.id);
      const now = dayjs();
      if (!!chat && (!chat.lastTyping || now.diff(chat.lastTyping, "second") > 6)) {
        chat.lastTyping = now;
      }
      return { ...state };

    case "addChatMessageBulk":
      chat = state.chats.get(action.id);

      if (chat) {
        chat.numNewMessages = !action.isActiveChat ? chat.numNewMessages + 1 : chat.numNewMessages;
        localStorage.setItem(chat.connection.url, chat.numNewMessages.toFixed(0));
        chat.history = [...action.messages, ...chat.history];
        chat.lastTyping = undefined;
      }

      return { ...state };

    case "addChatMessage":
      chat = state.chats.get(action.id);

      if (chat) {
        chat.numNewMessages = !action.isActiveChat ? chat.numNewMessages + 1 : chat.numNewMessages;
        localStorage.setItem(chat.connection.url, chat.numNewMessages.toFixed(0));
        chat.history.push(action.message);
        chat.lastTyping = undefined;
      }

      return { ...state };
    default:
      return state;
  }
};
