/**  Within this file we define which role is allowed to what
 * if you add a new route to the app go here
 * https://docs.google.com/spreadsheets/d/1iZyCOGD8zwjORouBxiEWyNt9zybPTAyrEPx2-X9tB2M/edit#gid=0
 * and check who should use that path
 * and add the path to the groups who will be able to use it
 * if you have create a new action which should have a restricted access
 * give it a name and add it below too.
 *
 * Example
 * const common = ["/", "/foobar"];
 * const admin = ["/admin", "createSomethingSerious"];
 * etc.
 *
 */

const managementInbox = [
  "/inbox/:filter(overdue|pool)",
  "/inbox/:filter(selection)",
  "/inbox/selection/:userId",
  "/inbox/chats",
];
const cmPage = ["/person/:id/caseManagement", "/person/:id/caseManagement/review", "cmpage"];

const filterInbox = ["filterInbox"];
const assignTask = ["assignTask"];
const addAbsence = ["addAbsence"];
const listLMs = ["/activities/services"];
const listF2F = ["/activities/external"];
const listCMs = ["/activities/flags", "/activities/flags/caseManager"];
const listSTC = ["/activities/internal"];
const myClients = ["/inbox/myClients"];
const statistics = ["/statistics"];
const am = [
  "/account/:id",
  "/account",
  "createAccount",
  "/account/:id/contract",
  "/account/:id/departments",
  "/account/:id/business-units",
  "/complaints",
  "/statistics",
  "search",
];
// this "/person/:id/client/:clientId/complaints",
//should be added to the following
const clientPage = [
  "/person/:id",
  "/person/:id/contacts",
  "/person/:id/contacts/:contactId",
  "/person/:id/details",  
  "/person/:id/qualtrics-documentation",
  "/person/:id/qualtrics-type",
  "/person/:id/topics/:topicId",
  "doPersonActions",
  "createService",
  "createContact",
  "search",
];

const readReports = [
  "/person/:personId/activities/:id",
  "/person/:personId/topics/:topicId/activities/:id",
];

const createReports = [
  "/person/:id/report",
  "/person/:personId/memo",
  "/person/:personId/report/:reportId",
  "/person/:personId/memo/:reportId",
  "doPersonActions",
  "createIntake",
];

const createAndViewF2F = [
  "createAndViewF2F",
  "/externalConsult/:serviceId",
  "/person/:id/externalConsult/:serviceId/setup",
  "/person/:id/externalConsult",
  "/mandate/:mandateId",
];
const createAndViewSTC = [
  "createAndViewSTC",
  "/internalConsult/:serviceId",
  "/person/:id/internalConsult",
  "/person/:id/internalConsult/:serviceId/setup",
];
const serviceResearch = [
  "/service/:serviceId",
  "/service/:serviceId/research/suggestionList",
  "/service/:serviceId/research",
  "/service/:serviceId/research/:researchId",
];
const serviceCreate = [
  "/person/:id/service",
  "/person/:id/service/:serviceId/setup",
  "doPersonActions",
  "createService",
];

const seeBusinessDetails = [
  "/business/:id",
  "/business/:id/contacts",
  "/business/:id/contacts/:contactId",
  "/business/:id/contacts/:contactId/email",
  "/business/:id/details",
  "/business/:businessId/memo",
  "/business/:businessId/memo/:id",
  "/business/:businessId/activities/:id",
];

const chat = ["/person/:personId/chat/:chatId", "chat"];
const chatStart = ["chatStart"];
const chatHistory = [""];
const seeActivities = ["/activities"];
const inbox = ["/", "/pool", "/inbox/:filter(unassigned)"];
const session = ["/require-login", "/session-end", "/not-allowed"];
const appointment = ["/appointment", "/shift/:page"];
const base = [
  ...inbox,
  ...session,
  "/person/:id/documents",
  "/business/:id/documents",
  "/emails",
  "/email/:threadId",
  "/person/:id/email/:threadId",
  "/business/:id/email/:threadId",
  "/person/:personId/sms",
  "/person/:personId/send-email",
  "/person/:personId/send-wpo-email",
];
const createTask = ["createTask", "doPersonActions"];
const finishService = ["finishService"];
const deleteActivity = ["deleteActivity"];
const createGDPRFiles = ["createGDPRFiles"];

const rules = {
  "": [...base],
  "/Administratoren": [
    addAbsence,
    am,
    appointment,
    assignTask,
    base,
    chat,
    chatHistory,
    chatStart,
    clientPage,
    cmPage,
    createAndViewF2F,
    createAndViewSTC,
    createReports,
    createTask,
    createGDPRFiles,
    deleteActivity,
    filterInbox,
    finishService,
    listCMs,
    listF2F,
    listLMs,
    listSTC,
    managementInbox,
    myClients,
    readReports,
    seeActivities,
    seeBusinessDetails,
    serviceCreate,
    serviceResearch,
  ].flat(),
  "/Case Management": [
    // addAbsence,
    appointment,
    assignTask, // ?
    base,
    chat,
    chatHistory,
    chatStart,
    clientPage,
    cmPage,
    createAndViewF2F,
    createAndViewSTC,
    createReports,
    createTask,
    createGDPRFiles,
    finishService,
    filterInbox,
    listCMs,
    listF2F,
    listLMs,
    listSTC,
    managementInbox,
    myClients,
    readReports,
    seeActivities,
    seeBusinessDetails,
    serviceCreate,
    serviceResearch,
    statistics,
  ].flat(),
  "/Case Management/Assistenten": [
    appointment,
    base,
    chat,
    chatHistory,
    chatStart,
    clientPage,
    cmPage,
    createAndViewF2F,
    createAndViewSTC, // only view should be done here
    createReports,
    createTask,
    createGDPRFiles,
    finishService,
    filterInbox,
    listCMs,
    listF2F,
    listLMs,
    // listSTC, // remove?
    // myClients,
    readReports,
    seeActivities,
    seeBusinessDetails,
    serviceCreate,
    serviceResearch,
    statistics,
  ].flat(),
  "/Case Management/Clinical Administrator": [
    appointment,
    base,
    chat,
    chatHistory,
    chatStart,
    clientPage,
    cmPage,
    createAndViewF2F,
    createGDPRFiles,
    // createAndViewSTC,
    // createReports,
    createTask,
    finishService,
    filterInbox,
    // listCMs,
    listF2F,
    listLMs,
    // listSTC,
    // myClients,
    readReports,
    seeActivities,
    seeBusinessDetails,
    serviceCreate,
    serviceResearch,
  ].flat(),
  "/Service Management": [
    addAbsence,
    appointment,
    assignTask,
    base,
    chat,
    chatHistory,
    chatStart,
    clientPage,
    // cmPage,
    createAndViewF2F,
    createAndViewSTC,
    createReports,
    createTask,
    createGDPRFiles,
    deleteActivity,
    finishService,
    filterInbox,
    listCMs,
    listF2F,
    listLMs,
    listSTC,
    managementInbox,
    myClients,
    readReports,
    seeActivities,
    seeBusinessDetails,
    serviceCreate,
    serviceResearch,
    statistics,
  ].flat(),
  "/Service Management/Assistenten": [
    addAbsence,
    appointment,
    assignTask,
    base,
    chat,
    chatHistory,
    chatStart,
    clientPage,
    // cmPage,
    createAndViewF2F,
    // createAndViewSTC,
    createGDPRFiles,
    createReports,
    createTask,
    finishService,
    filterInbox,
    // listCMs,
    listF2F,
    listLMs,
    // listSTC,
    managementInbox,
    // myClients,
    readReports,
    seeActivities,
    seeBusinessDetails,
    serviceCreate,
    serviceResearch,
  ].flat(),
  "/Counseling": [
    // addAbsence,
    appointment,
    assignTask,
    base,
    chat,
    chatHistory,
    chatStart,
    clientPage,
    createAndViewSTC,
    createReports,
    createTask,
    filterInbox,
    finishService,
    // listCMs,
    // listF2F,
    listLMs,
    listSTC,
    managementInbox,
    myClients,
    readReports,
    seeActivities,
    seeBusinessDetails,
    serviceCreate,
    serviceResearch,
  ].flat(),
  "/Life Management/Assistenten": [
    appointment,
    base,
    chat,
    chatHistory,
    chatStart,
    clientPage,
    createTask,
    finishService,
    filterInbox,
    listLMs,
    readReports,
    serviceResearch,
  ].flat(),
  "/Life Management": [
    addAbsence,
    appointment,
    assignTask,
    base,
    chat,
    chatHistory,
    chatStart,
    clientPage,
    createReports,
    createTask,
    finishService,
    filterInbox,
    listLMs,
    managementInbox,
    readReports,
    seeActivities,
    serviceCreate,
    serviceResearch,
  ].flat(),
  "/Account Management": [
    am,
    appointment,
    base,
    createTask,
    seeBusinessDetails,
    statistics,
    ["/person/:id/documents"],
  ].flat(),
};

export default rules;
