import { AccountType, ReportState } from "../generated/graphql";

const fullStepOrder = [
  ReportState.created,
  ReportState.issued,
  ReportState.serviceSelected,
  ReportState.workStats,
  ReportState.privateStats,
  ReportState.agreed,
  ReportState.mentalized,
  ReportState.summarized,
  ReportState.counselingOrLm,
  ReportState.risk,
  ReportState.riskInfo,
  ReportState.cmnotified,
  ReportState.reportDone,
  ReportState.startReview,
  ReportState.inReview,
  ReportState.inServiceReview,
  ReportState.finished,
];

const filterSteps = (
  step: ReportState,
  options: {
    hasServices: boolean;
    needsReview: boolean;
    hasRisk: boolean;
    doesReview: boolean;
    accountType: AccountType;
    next: boolean;
  },
) => {
  const { hasServices, needsReview, hasRisk, doesReview, accountType, next } = options;

  const serviceCondition = hasServices ? true : step !== ReportState.inServiceReview;

  const prevCondition = next
    ? true
    : ![ReportState.startReview, ReportState.reportDone].includes(step);

  const needsReviewCondition = needsReview ? step !== ReportState.reportDone : true;

  const doesReviewCondition = doesReview
    ? true
    : ![ReportState.inReview, ReportState.startReview].includes(step);

  const hasRiskCondition = hasRisk ? true : step !== ReportState.riskInfo;

  if (accountType === AccountType.eap || accountType === AccountType.test) {
    return (
      step !== ReportState.serviceSelected &&
      serviceCondition &&
      needsReviewCondition &&
      doesReviewCondition &&
      hasRiskCondition &&
      prevCondition
    );
  }

  if (accountType === AccountType.wpo) {
    return (
      ![ReportState.serviceSelected, ReportState.workStats, ReportState.privateStats].includes(
        step,
      ) &&
      serviceCondition &&
      needsReviewCondition &&
      doesReviewCondition &&
      hasRiskCondition &&
      prevCondition
    );
  }

  return (
    serviceCondition &&
    needsReviewCondition &&
    doesReviewCondition &&
    hasRiskCondition &&
    prevCondition
  );
};

export const getPrevNext = (
  status: ReportState,
  options: {
    hasServices: boolean;
    needsReview: boolean;
    hasRisk: boolean;
    doesReview: boolean;
    accountType: AccountType;
    next: boolean;
  },
) => {
  const { hasServices, needsReview, hasRisk, doesReview, accountType, next } = options;

  if (next && status === ReportState.finished) {
    return ReportState.finished;
  } else if (!next && status === ReportState.created) {
    return ReportState.created;
  }

  const steps = fullStepOrder.filter(step =>
    filterSteps(step, { hasServices, needsReview, hasRisk, doesReview, accountType, next }),
  );

  let currentIndex = steps.findIndex(step => step === status);

  // this is fallback
  if (currentIndex === -1 || currentIndex === (next ? steps.length - 1 : 0)) {
    currentIndex = fullStepOrder.findIndex(step => step === status);
    if (currentIndex === -1) {
      // this case should never ever happen if fullStepOrder is configured correctly
      throw new Error("falsey step configuration");
    }
    return fullStepOrder[currentIndex + (next ? 1 : -1)];
  }

  return steps[currentIndex + (next ? 1 : -1)];
};

export const notReportStates = [ReportState.inReview, ReportState.created, ReportState.startReview];
