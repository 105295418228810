import React, { useState } from "react";
import classnames from "classnames";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import sortBy from "lodash/sortBy";
import ArrowForward from "@material-ui/icons/ArrowForwardIos";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import Box from "@material-ui/core/Box";
import { useLocation } from "react-router-dom";
import SecondaryButton from "../../atoms/SecondaryButton";
import WizardNavigation from "../../molecules/WizardNavigation";
import TopicSelectOrCreate from "../../molecules/TopicSelectOrCreate";
import PrimaryButton from "../../atoms/PrimaryButton";
import { useAuthorization } from "../../../providers/AuthorizationProvider";
import {
  Person,
  PersonActivity,
  Topic,
  ServiceCategory,
  CreateServiceDocument,
  ServiceType,
  useCreateCounselingServiceMutation,
} from "../../../generated/graphql";
import useStyles from "../reportStyles";
import useOwnStyles from "./styles";
import ServiceMetaIcon from "../../atoms/ServiceMetaIcon";
import elli from "../../organisms/Header/elli.svg";
interface Props {
  activity?: PersonActivity;
  person: Person;
  serviceCategories: ServiceCategory[];
  step: number;
  setStep: (step: number) => void;
}
interface ITSProps {
  person: Person;
  proceed: () => void;
  setTopic: (topic: Topic) => void;
  styles: any;
  topic: Topic;
  topics: Topic[];
}
interface ICSProps {
  categories: ServiceCategory[];
  person: Person;
  setCategory: (id: number) => void;
  styles: any;
  topic: Topic;
}
interface ISSProps {
  categoryId: number | null;
  categories: ServiceCategory[];
  save: (id: number) => void;
  styles: any;
  person: Person;
  isTryingToBookAnUnsubscribedService?: boolean;
  setIsTryingToBookAnUnsubscribedService: (status: boolean) => void;
  serviceId?: [number, React.Dispatch<React.SetStateAction<number>>];
}

const TopicSelect = ({ styles, topics, setTopic, topic, person, proceed }: ITSProps) => (
  <>
    <Box mb={11}>
      <Typography variant="h2">Neuen Service</Typography>
    </Box>
    <Typography variant="h2" className={styles.dataText}>
      Zum <TopicSelectOrCreate topics={topics} selectAction={setTopic} topic={topic} />
    </Typography>
    <Typography variant="h2" className={styles.dataText}>
      für
    </Typography>
    <Typography variant="h2" className={styles.dataText}>
      {person.name} anlegen
    </Typography>
  </>
);

const CategorySelect = ({ categories, setCategory, styles, person, topic }: ICSProps) => {
  const classes = useOwnStyles();
  const { push } = useHistory();
  const [createCounseling] = useCreateCounselingServiceMutation();
  const client = person?.client || null;
  const handleCounselingCreation = (type: ServiceType) => {
    createCounseling({
      variables: {
        service: {
          topic: {
            id: topic.id,
            name: topic.name,
          },
          clientId: client && client.id,
          type,
        },
      },
    }).then(
      ({ data }) => {
        push(`/person/${person.id}/${type}/${data?.createCounselingService?.id}/setup`);
      },
      err => console.log(err),
    );
  };

  const checkServiceCategoryAvailability = (category: ServiceCategory) => {
    const count = category.serviceMetas?.filter(meta => !meta?.subscribed).length;
    return count === category.serviceMetas?.length ? classes.disabled : "";
  };


  const { isAuthorized } = useAuthorization();
  const fractal3 = isAuthorized("createAndViewSTC") || isAuthorized("createAndViewF2F");
  const columnExtra = fractal3 ? 1 : 0;
  return (
    <>
      <Box mb={11}>
        <Typography variant="h1">Service Kategorie für {person.name}</Typography>
      </Box>
      <div
        className={classnames(classes.categoryRoot, {
          [classes.grid5]: fractal3,
        })}
      >
        {isAuthorized("createAndViewSTC") && (
          <ButtonBase
            className={classes.categoryButton}
            style={{ gridRow: 1, gridColumn: 3 }}
            onClick={() => handleCounselingCreation(ServiceType.internalConsult)}
          >
            <Box mt={5}>
              <ServiceMetaIcon name="internalConsult" big />
            </Box>
            <Box flex="1" display="flex" justifyContent="center" alignItems="center" pb={2}>
              <Typography variant="h3" className={classes.categoryName}>
                Interne Beratung
              </Typography>
            </Box>
          </ButtonBase>
        )}
        {isAuthorized("createAndViewF2F") && (
          <ButtonBase
            className={`${classes.categoryButton} ${person.client?.account?.freeSessions === 0 ? classes.disabled : ""}`}
            style={{ gridRow: isAuthorized("createAndViewSTC") ? 2 : 1, gridColumn: 3 }}
            onClick={() => handleCounselingCreation(ServiceType.externalConsult)}
          >
            <Box mt={5}>
              <ServiceMetaIcon name="externalConsult" big />
            </Box>
            <Box flex="1" display="flex" justifyContent="center" alignItems="center" pb={2}>
              <Typography variant="h3" className={classes.categoryName}>
                Externe Beratung
              </Typography>
            </Box>
          </ButtonBase>
        )}
        {sortBy(categories, ["id"]).map((category: any, index: number) => (
          <ButtonBase
            style={{
              gridRow: index < 2 ? 1 : 2,
              gridColumn: ((index % 2) + 1) % (2 + columnExtra),
            }}
            className={`${classes.categoryButton} ${checkServiceCategoryAvailability(category)}`}
            key={`${category.id}`}
            onClick={() => setCategory(category.id)}
          >
            <Box mt={5}>
              <ServiceMetaIcon name={category.name} big />
            </Box>
            <Box flex="1" display="flex" justifyContent="center" alignItems="center" pb={2}>
              <Typography variant="h3" className={classes.categoryName}>
                {category.name}
              </Typography>
            </Box>
          </ButtonBase>
        ))}
      </div>
    </>
  );
};

const ServiceSelect = ({
  categoryId,
  categories,
  save,
  styles,
  person,
  isTryingToBookAnUnsubscribedService,
  setIsTryingToBookAnUnsubscribedService,
  serviceId,
}: ISSProps) => {
  const category = categories.find(c => c.id === categoryId);
  const classes = useOwnStyles();
  return (
    <>
      {category && (
        <>
          <Typography variant="h1" className={styles.headline}>
            {category.name} Service für {person.name} anlegen
          </Typography>
          <div className={classes.list}>
            {!!category.serviceMetas &&
              !!category.serviceMetas.length &&
              category.serviceMetas.map((sm: any, index: number) => (
                <div
                  className={`${classes.listItem} ${!sm.subscribed ? classes.listItemDisabled : ""
                    } ${sm.id === serviceId![0] ? classes.listItemSelected : ""}`}
                  key={`${sm.id}`}
                  onClick={() => {
                    if (sm.subscribed) {
                      setIsTryingToBookAnUnsubscribedService(false);
                      serviceId![1](-1);
                      save(sm.id);
                    } else {
                      setIsTryingToBookAnUnsubscribedService(true);
                      serviceId![1](sm.id);
                    }
                  }}
                  tabIndex={index}
                >
                  {sm.name}
                  <ArrowForward color="primary"></ArrowForward>
                </div>
              ))}
          </div>
          {NotSubscribedReminder(!!isTryingToBookAnUnsubscribedService)}
        </>
      )}
    </>
  );
};

const NotSubscribedReminder = (show: boolean) => {
  const classes = useOwnStyles();
  return (
    <div className={`${classes.reminder} ${show ? classes.showReminder : ""}`}>
      <div className={classes.textContainer}>
        <Typography variant="body2">
          Dieser Service ist vom Account dieses Klienten nicht gebucht. Willst du dennoch
          fortfahren?
        </Typography>
      </div>
      <div className={classes.elliContainer}>
        <img src={elli} alt="elli" className={classes.smallElli} />
      </div>
    </div>
  );
};

/**
 *  Get the initial topic given the following hierarchy:
 *
 *  1 - topic from the current activity
 *  2 - context from page before
 *  3 - first topic of client
 *  4 - default empty
 *
 */
const initialTopic = ({
  activity,
  topics,
  topicId,
}: {
  activity?: PersonActivity;
  topics: Topic[];
  topicId?: number;
}) => {
  let topic;

  if (activity && activity.topic) {
    topic = activity.topic;
  } else if (topicId && topics && topics.length > 0) {
    topic = topics.find(topic => topic.id === topicId);
  } else if (topics && topics.length > 0) {
    topic = topics[0];
  }

  if (!!topic) {
    return { name: topic.name, id: topic.id };
  } else {
    return { name: "", id: null };
  }
};

export default function ServiceCreateForm(props: Props) {
  const classes = useStyles();
  const { push, goBack } = useHistory();
  const { state }: { state: { topicId: number } } = useLocation();
  const [categoryId, setCategoryId] = useState<number | null>(null);
  const { activity, person, serviceCategories, step, setStep } = props;
  const [isTryingToBookAnUnsubscribedService, setIsTryingToBookAnUnsubscribedService] = useState(
    false,
  );
  const lastService = useState(-1);

  const topics = (person?.client?.topics || []) as Topic[];

  const iniTopic = initialTopic({ activity, topics, topicId: state?.topicId });
  const [topic, setTopic] = useState<Topic>(iniTopic);
  const [creating, setCreating] = useState<boolean>(false);
  const client = props.person.client ? props.person.client : null;
  const setCategory = (id: number) => {
    setCategoryId(id);
    setStep(2);
  };

  const [createService] = useMutation(CreateServiceDocument);

  const handleCreate = (serviceMetaId: number) => {
    if (!creating) {
      setCreating(true);

      createService({
        variables: {
          service: {
            topic: {
              id: topic.id,
              name: topic.name,
            },
            clientId: client && client.id,
            serviceMetaId,
          },
        },
      }).then(
        ({ data }) => {
          setCreating(false);
          localStorage.setItem("createdServiceID", data.createService.id);
          localStorage.setItem("createdServiceName", data.createService.serviceMeta.name);
          push(`/person/${person.id}/service/${data.createService.id}/setup`);
        },
        err => console.log(err),
      );
    }
  };

  return (
    <>
      <div className={classes.content}>
        {step === 0 && (
          <TopicSelect
            styles={classes}
            topics={topics}
            topic={topic}
            setTopic={setTopic}
            person={person}
            proceed={() => setStep(1)}
          />
        )}
        {step === 1 && (
          <CategorySelect
            styles={classes}
            setCategory={setCategory}
            categories={serviceCategories}
            person={person}
            topic={topic}
          />
        )}
        {step === 2 && (
          <ServiceSelect
            person={person}
            categoryId={categoryId}
            categories={serviceCategories}
            styles={classes}
            save={handleCreate}
            setIsTryingToBookAnUnsubscribedService={setIsTryingToBookAnUnsubscribedService}
            isTryingToBookAnUnsubscribedService={isTryingToBookAnUnsubscribedService}
            serviceId={lastService}
          />
        )}
      </div>
      <WizardNavigation>
        {step === 0 && (
          <>
            <SecondaryButton onClick={() => goBack()}>Abbrechen</SecondaryButton>
            <PrimaryButton
              disabled={!topic.name || /^(\s|\t|\n|\r)+$/.test(topic.name)}
              onClick={() => {
                setIsTryingToBookAnUnsubscribedService(false)
                setStep(1)
              }}
            >
              Weiter
            </PrimaryButton>
          </>
        )}
        {/* especial case for when a service is not subscribed */}
        {step > 0 && <SecondaryButton onClick={() => {
          setIsTryingToBookAnUnsubscribedService(false)
          setStep(step - 1)
        }}>Zurück</SecondaryButton>}
        {isTryingToBookAnUnsubscribedService && (
          <PrimaryButton onClick={() => handleCreate(lastService[0])}>Weiter</PrimaryButton>
        )}
      </WizardNavigation>
    </>
  );
}
