import React, { useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import WizardNavigation from "../../molecules/WizardNavigation";
import ActivityText from "../../molecules/ActivityText";
import { Topic, Person, ReportActivityQuery } from "../../../generated/graphql";
import useStyles from "./styles";
import illu from "../../assets/reportIllustration.svg";

type TActivityHeader = {
  topic?: Topic;
  person: Person;
  activity: ReportActivityQuery["personActivity"];
};
export const ActivityHeader = ({ person, activity }: TActivityHeader) => {
  const classes = useStyles();
  const refPerson = activity?.refPerson;
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Box mb={2} width="fit-content">
        <img src={illu} alt="reportillu" height="100" />
      </Box>
      {activity && (
        <ActivityText activity={activity} personName={activity?.memo?.contact || person.name!} />
      )}
      {refPerson && (
        <Typography variant="h1" className={classes.dataText}>
          zum Beratungsmandat{" "}
          <Link component={RouterLink} to={`/person/${refPerson.id}`} underline="always">
            {refPerson.name}
          </Link>
        </Typography>
      )}
    </Box>
  );
};
type TPagination = { list: any[]; name: string };
export const Pagination = ({ list, name }: TPagination) => {
  const { push } = useHistory();
  const { id, businessId } = useParams<{ id: string; businessId: string }>();
  const { state } = useLocation();
  // @ts-ignore
  const goBackPath = useRef(state?.goBackPath! || undefined);
  const currentPosition = list.findIndex((r: any) => r.id === parseInt(id, 10));

  const prev: any | undefined = currentPosition > 0 ? list[currentPosition - 1] : undefined;
  const next: any | undefined =
    currentPosition + 1 !== list.length ? list[currentPosition + 1] : undefined;

  const handleClick = (item?: any) => {
    if (!item) return;
    push({
      pathname: `/business/${businessId}/activities/${item.id}`,
      state,
    });
  };
  return (
    <WizardNavigation>
      <SecondaryButton onClick={() => handleClick(prev)} disabled={!prev}>
        Vorheriges
      </SecondaryButton>
      {!!goBackPath.current && (
        <PrimaryButton onClick={() => push(goBackPath.current)}>{name} schließen</PrimaryButton>
      )}
      <SecondaryButton onClick={() => handleClick(next)} disabled={!next}>
        Nächstes
      </SecondaryButton>
    </WizardNavigation>
  );
};
