import React from "react";

import ItemList, { IItem } from "../../molecules/ClickableItemList/ItemList";
import ItemListHeader from "../../molecules/ClickableItemList/ItemListHeader";

interface ClickableItemListProps {
  title: string;
  btnLabel?: string;
  countLabel?: string;
  onClick?: () => void;
  count?: number;
  items: IItem[];
}

const ClickableItemList = ({
  title,
  btnLabel,
  countLabel,
  onClick,
  count,
  items,
}: ClickableItemListProps) => {
  return (
    <>
      <ItemListHeader
        title={title}
        btnLabel={btnLabel}
        onClick={onClick}
        countLabel={countLabel}
        count={count}
      />

      <ItemList items={items} />
    </>
  );
};

export default ClickableItemList;
