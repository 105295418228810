import React, { FC } from "react";
import { useLocation, useParams } from "react-router";
import {
  useBreadCrumbInfoByAccountIdQuery,
  useBreadCrumbInfoByPersonIdQuery,
} from "../../../generated/graphql";
import { useBusinessSubscription } from "../../../hooks/useBusinessSubscription";
import { usePersonSubscription } from "../../../hooks/usePersonSubscription";
import Loader from "../../atoms/Loading";
import AccountStatusBadge from "../../molecules/AccountStatusBadge";
import PersonLayout from "../../templates/PersonLayout";
import DocumentsList from "./DocumentsList";

export const Documents: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();
  const isBusiness = pathname.indexOf("/business") > -1;

  return isBusiness ? (
    <BusinessDocuments id={parseInt(id)} />
  ) : (
    <PersonDocuments id={parseInt(id)} />
  );
};

const BusinessDocuments: FC<{ id: number }> = ({ id }) => {
  const { businessObject, loading } = useBusinessSubscription(id);
  const accountId = businessObject?.accountId;
  const { data: breadcrumbData } = useBreadCrumbInfoByAccountIdQuery({
    skip: !accountId,
    variables: {
      id: accountId!,
    },
  });

  if (loading) {
    return <Loader />;
  }

  if (!businessObject) {
    return <>Unternehmen wurde nicht gefunden</>;
  }

  const persons = businessObject.persons || [];
  const personIds = persons.map(p => p!.id!);
  const name = businessObject.name || "";
  const mapList = businessObject.accountId
    ? [
        { name: "Accounts", path: "/account" },
        {
          name,
          path: `/account/${businessObject.accountId}`,
          chip: (
            <AccountStatusBadge
              contractEnd={breadcrumbData?.breadCrumbInfoByAccountId?.contractEnd}
              lang={breadcrumbData?.breadCrumbInfoByAccountId?.language || ""}
            />
          ),
        },
        { name: "Dokumente", path: "" },
      ]
    : [
        {
          name,
          path: `/business/${id}`,
        },
        {
          name: "Dokumente",
          path: "",
        },
      ];

  return (
    <PersonLayout
      counselor
      personIds={personIds}
      mapList={mapList}
      contractExpirationDate={breadcrumbData?.breadCrumbInfoByAccountId?.contractEnd}
      language={breadcrumbData?.breadCrumbInfoByAccountId?.language || ""}
    >
      <DocumentsList personIds={persons.map(person => person!.id)} />
    </PersonLayout>
  );
};

const PersonDocuments: FC<{ id: number }> = ({ id }) => {
  const { person, loading } = usePersonSubscription(id);
  const personId = person?.id;
  const { data: breadcrumbData } = useBreadCrumbInfoByPersonIdQuery({
    skip: !personId,
    variables: {
      id: personId!,
    },
  });

  if (loading) {
    return <Loader />;
  }

  if (!person) return <>Person wurde nicht gefunden</>;

  return (
    <PersonLayout
      person={person}
      mapList={[{ name: "Dokumente", path: "" }]}
      contractExpirationDate={breadcrumbData?.breadCrumbInfoByPersonId?.contractEnd}
      language={breadcrumbData?.breadCrumbInfoByPersonId?.language || ""}
    >
      <DocumentsList personIds={[person.id]} />
    </PersonLayout>
  );
};

export default Documents;
