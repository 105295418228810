import React, { useState, FC, useEffect } from "react";

import { withRouter, RouteComponentProps } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import CssBaseline from "@material-ui/core/CssBaseline";

import Header from "../../organisms/Header";
import Menu from "../../organisms/Menu";
import Main from "../../atoms/Main";

interface LayoutProps {
  big?: boolean;
  activeElli?: boolean;
  setAnimation: any;
}

const Layout: FC<LayoutProps & RouteComponentProps> = ({
  children,
  big,
  setAnimation,
  activeElli,
  history,
}) => {
  const [open, setOpen] = useState(false);
  function handleDrawerToggle() {
    setOpen(!open);
  }
  useEffect(() => {
    if (!/(\/service\/\d+\/setup)|(\/person\/\d+\/service$)/.test(history.location.pathname)) {
      localStorage.removeItem("createdServiceID");
      localStorage.removeItem("createdServiceName");
    }
    if (
      !/(\/(internal|external)Consult\/\d+\/setup)|(\/person\/\d+\/(internal|external)Consult$)/.test(
        history.location.pathname,
      )
    ) {
      localStorage.removeItem("createdConsultingId");
    }
    history.listen((location: { pathname: string }) => {
      if (!/(\/service\/\d+\/setup)|(\/person\/\d+\/service$)/.test(location.pathname)) {
        localStorage.removeItem("createdServiceID");
        localStorage.removeItem("createdServiceName");
      }
      if (
        !/(\/(internal|external)Consult\/\d+\/setup)|(\/person\/\d+\/(internal|external)Consult$)/.test(
          history.location.pathname,
        )
      ) {
        localStorage.removeItem("createdConsultingId");
      }
    });
  }, [history]);

  useEffect(() => {
    setAnimation(big);
  }, [big, setAnimation]);

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <CssBaseline />
      <Menu handleDrawerToggle={handleDrawerToggle} open={open} />
      <Header handleDrawerToggle={handleDrawerToggle} activeElli={activeElli} />
      <Main>{children}</Main>
    </SnackbarProvider>
  );
};
export default withRouter(Layout);
