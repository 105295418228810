import React, { FC } from "react";
import { FormikProps, FormikValues } from "formik";

import { Box } from "@material-ui/core";

import { Hour } from "../../../../generated/graphql";
import PrimaryButton from "../../../atoms/PrimaryButton";
import SecondaryButton from "../../../atoms/SecondaryButton";
import DaysTimeSelector from "../../../molecules/DaysTimeSelector/DaysTimeSelector";

interface HoursDialogProps {
  hours?: Hour[];
  autoSave: (values: any) => any;
  cancel: () => void;
}

const HoursForm: FC<HoursDialogProps & FormikProps<FormikValues>> = ({
  autoSave,
  cancel,
  handleSubmit,
  hours,
  setFieldTouched,
  setFieldValue,
  touched,
  values,
}) => {
  if (!hours) {
    return <></>;
  }

  const methods = {
    identifier: "id",
    create: (hour: Hour, { save = true } = {}) => {
      hours.push(hour);
      setFieldTouched("businessObject.hours", true);
      setFieldValue("businessObject.hours", hours);
      if (save) {
        autoSave({
          ...values,
          businessObject: { ...values.businessObject, hours },
        });
      }
    },
    delete: (id: number) => {
      const deleteIndex = hours.findIndex(hour => hour != null && hour.id === id);
      if (deleteIndex > -1) {
        hours.splice(deleteIndex, 1);
        setFieldTouched("businessObject.hours", true);
        setFieldValue("businessObject.hours", hours);
        autoSave({
          ...values,
          businessObject: { ...values.businessObject, hours },
        });
      }
    },
    deleteDayHours: (day: number) => {
      const newHours = hours.filter(hour => hour.day !== day);
      setFieldTouched("businessObject.hours", true);
      setFieldValue("businessObject.hours", newHours);
      autoSave({
        ...values,
        businessObject: { ...values.businessObject, hours: newHours },
      });
    },
    update: (hour: Hour, id: number) => {
      const updateIndex = hours.findIndex(hour => hour != null && hour.id === id);
      if (updateIndex > -1) {
        hours.splice(updateIndex, 1, hour);
        setFieldTouched("businessObject.hours", true);
        setFieldValue("businessObject.hours", hours);
        autoSave({
          ...values,
          businessObject: { ...values.businessObject, hours },
        });
      }
    },
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <DaysTimeSelector
        formHours={hours as Hour[]}
        initial={false}
        methods={methods}
        autoSave
      ></DaysTimeSelector>

      <Box mt={4} display="flex" justifyContent="center">
        <SecondaryButton onClick={cancel}>Abbrechen</SecondaryButton>
        <Box mx={1} />
        <PrimaryButton type="submit">OK</PrimaryButton>
      </Box>
    </form>
  );
};
export default HoursForm;
