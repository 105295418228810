import React from "react";

import { StyledSvgIcon } from "./styles";

const WebsiteLinkIcon = () => {
  return (
    <StyledSvgIcon color="primary" viewBox="0 0 14 13">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1H1V12H12V9H13V13H0V0H5V1Z"
        fill="#025879"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.57322 2.57322L7 0H8.03027H13.0303V5V6L10.4594 3.45481L5.88388 8.03033L5 7.14644L9.57322 2.57322Z"
        fill="#025879"
      />
    </StyledSvgIcon>
  );
};

export default WebsiteLinkIcon;
