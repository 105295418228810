import React, { FC } from "react";
import { useParams, useHistory } from "react-router-dom";

import {
  Service,
  useBusinessObjectsQuery,
  BusinessObject,
  useCreateServiceResearchObjectsMutation,
  useBreadCrumbInfoByClientIdQuery,
} from "../../../generated/graphql";
import { fetchPolicy } from "../../../utils/constants";
import { useServiceSubscription } from "../../../hooks/useServiceSubscription";

import Grid from "@material-ui/core/Grid";
import Loader from "../../atoms/Loading";
import ServiceTemplate from "../../templates/Service";
import SuggestionListForm from "./form";

export default function ServiceResearchSuggestionList() {
  const { serviceId } = useParams<{ serviceId: string }>();

  const { service, loading, refetch } = useServiceSubscription(parseInt(serviceId, 10));
  const clientId = service?.client?.id;
  const { data: breadcrumbData } = useBreadCrumbInfoByClientIdQuery({
    skip: !clientId,
    variables: {
      id: clientId!,
    },
  });

  if (loading) {
    return <Loader />;
  }

  if (!service) {
    return (
      <div>
        <div>Service wurde nicht gefunden</div>
      </div>
    );
  }

  const person = service.client?.person;

  if (!person || !person.id) {
    return (
      <div>
        <div>Person wurde nicht gefunden</div>
      </div>
    );
  }
  const namePathMap = [
    {
      // @ts-ignore
      name: service.topic.name,
      // @ts-ignore
      path: `/person/${person.id}/topics/${service.topic.id}`,
    },
    {
      // @ts-ignore
      name: service.serviceMeta.name,
      path: `/service/${service.id}`,
    },
    { name: "Vorschläge", path: `/service/${serviceId}` },
  ];
  return (
    <ServiceTemplate
      mapPathList={namePathMap}
      service={service}
      person={person}
      refetchService={refetch}
      contractExpirationDate={breadcrumbData?.breadCrumbInfoByClientId?.contractEnd}
      language={breadcrumbData?.breadCrumbInfoByClientId?.language || ""}
    >
      <Grid item xs={9} xl={7}>
        <SuggestionList service={service}></SuggestionList>
      </Grid>
    </ServiceTemplate>
  );
}

interface SuggestionListProps {
  service: Service;
}

const SuggestionList: FC<SuggestionListProps> = ({ service }) => {
  const { push } = useHistory();

  const { data, loading, refetch } = useBusinessObjectsQuery({
    variables: {
      serviceId: service.id,
      unique: true,
      withAddress: true,
    },
    fetchPolicy,
  });

  const [createServiceResearchObjects] = useCreateServiceResearchObjectsMutation();

  if (loading) {
    return <Loader />;
  }

  const businessObjects = (data?.businessObjects || []) as BusinessObject[];

  return (
    <SuggestionListForm
      businessObjects={businessObjects}
      service={service}
      onSubmit={async values => {
        const createIds = Object.keys(values)
          .filter((id: any) => values[id] === true)
          .map((id: any) => parseInt(id, 10));

        if (createIds.length) {
          await createServiceResearchObjects({
            variables: {
              businessObjectIds: createIds,
              serviceId: service.id!,
            },
          });
          await refetch();
        }

        push(`/service/${service.id}`, { refetch: Boolean(createIds.length) });
      }}
    />
  );
};
