import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import Chat from "@material-ui/icons/Chat";
import Phone from "@material-ui/icons/Phone";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Account,
  AccountType,
  PapershiftShiftsDocument,
  PersonActivityQuery,
  PersonQuery,
  Phone as TypePhone,
  PhoneType,
  usePersonQuery,
} from "../../../generated/graphql";
import useContractStatus from "../../../hooks/useContractStatus";
import { useAuthorization } from "../../../providers/AuthorizationProvider";
import { fetchPolicy } from "../../../utils/constants";
import translatePhoneType from "../../../utils/translatePhoneType";
import { client, PersonContext } from "../../App";
import BackButton from "../../atoms/BackButton";
import CalendarButton from "../../atoms/CalendarButton";
import Loader from "../../atoms/Loading";
import TertiaryButton from "../../atoms/TertiaryButton";
import GDPRDialog from "../../forms/GDPRDialog";
import ContactPersonDialog from "../../organisms/ContactPersonDialog";
import DocumentDialog from "../../organisms/DocumentDialog";
import OutgoingCall from "../../organisms/OutgoingCall";
import SubHeader from "../../organisms/SubHeader";
import TaskDialog from "../../organisms/TaskDialog";
import AccountStatusBadge from "../AccountStatusBadge";
import Breadcrumb, { NamePathMap } from "../Breadcrumb";
import useStyles from "./styles";
import Calendar from "@material-ui/icons/Event";
import ICallContentType, {
  CallContentTypes,
  NONE_CALL_CONTENT_TYPE,
} from "../../../interfaces/ICallContentType";
import ComplaintDialog from "../../organisms/ComplaintDialog";

type TPersonHeader = {
  backPath?: string;
  isClickable?: boolean;
  mapList?: NamePathMap[];
  person: PersonQuery["person"] | PersonActivityQuery["personActivity"]["person"];
  onClick?: () => void;
  topicId?: number | null;
  serviceId?: number | null;
  contractExpirationDate?: string;
  language?: string;
};

const PersonHeader = ({
  topicId,
  serviceId,
  backPath,
  person,
  mapList,
  contractExpirationDate,
  language,
}: TPersonHeader) => {
  const { isAuthorized } = useAuthorization();
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState<string | undefined>();
  const [callPhone, setCallPhone] = useState<TypePhone | undefined>();
  const { push } = useHistory();
  const { data, error, loading } = usePersonQuery({
    variables: { id: person?.id! },
    fetchPolicy,
  });
  const isAccountExpired = useContractStatus(contractExpirationDate);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [phoneAnchorEl, setPhoneAnchorEl] = useState<null | HTMLElement>(null);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    console.error(error);
    return <></>;
  }

  if (!data?.person) {
    console.error("Error: no data for person query");
    return <></>;
  }

  const closeDialog = () => {
    setOpenDialog(undefined);
    setCallPhone(undefined);
  };

  const openCallDialog = (phone: TypePhone) => {
    setCallPhone(phone);
    setOpenDialog("callOut");
    setPhoneAnchorEl(null);
  };

  const handlePhoneClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPhoneAnchorEl(event.currentTarget);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const linkTo = (pathname: string) => {
    if (topicId) {
      push({ pathname, state: { topicId, serviceId } });
    } else {
      push(pathname);
    }
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(null);
    setPhoneAnchorEl(null);
  };

  const phone = data.person.phones?.find(phone => {
    return (
      phone?.messagingAllowed &&
      (phone.type === PhoneType.mobile || phone.type === PhoneType.mobilework)
    );
  });

  const email = data.person.emails?.find(email => email?.contactAllowed);
  const phones = data.person.phones?.filter(p => p?.contactAllowed);
  const callContentType = {
    type: CallContentTypes.NONE_CALL_CONTENT_TYPE,
    hrBusiness: '',
    informationSource: ''
  } as ICallContentType;
  const account = data.person.client?.account;
  if (account) {
    if(account.type === AccountType.lifeWorks) {
      if(account.name?.toLowerCase().includes('google')) {
        callContentType.type = CallContentTypes.GOOGLE_CALL_CONTENT_TYPE;
        callContentType.hrBusiness = data.person.extras?.hrBusiness || '';
        callContentType.informationSource = data.person.extras?.informationSource || '';
      } else {
        callContentType.type = CallContentTypes.LIFEWORKS_CALL_CONTENT_TYPE;
        callContentType.informationSource = data.person.extras?.informationSource || '';
      }      
    }
    //lifeworks 48050
  }
  return (
    <SubHeader
      isAccountDeactivated={isAccountExpired}
      person={person}
      leading={
        !!backPath && (
          <BackButton
            onClick={() => {
              if (!!backPath) {
                push(backPath);
              }
            }}
          />
        )
      }
      actions={
        data.person.client ? (
          <>
            {!!data.person?.client?.chatUserId && (
              <IconButton
                aria-label="Chat"
                className={classes.addLinkButton}
                onClick={() => {
                  push(`/person/${data.person?.id}/chat/${data.person?.client?.chatUserId}`);
                }}
              >
                <Chat color="primary" />
              </IconButton>
            )}
            <PersonContext.Consumer>
              {context => {
                return isAccountExpired ? (
                  <div className={classes.centered}>
                    <Calendar color="disabled" />
                  </div>
                ) : (
                  <CalendarButton
                    className={`${classes.addLinkButton}`}
                    color="primary"
                    onMouseOver={() => {
                      const startsAt = dayjs().startOf("day");
                      const endsAt = dayjs().endOf("day");

                      client.query({
                        query: PapershiftShiftsDocument,
                        variables: {
                          startsAt: startsAt.toISOString(),
                          endsAt: endsAt.toISOString(),
                        },
                      });
                    }}
                    onClick={() => {
                      if (!context.person || context.person.id !== data.person?.id) {
                        context.setPerson(data.person);
                      }

                      push("/shift/1");
                    }}
                  />
                );
              }}
            </PersonContext.Consumer>
            {isAuthorized("doPersonActions") && (
              <>
                {!!phones?.length && (
                  <IconButton
                    aria-label="Call"
                    className={classes.addLinkButton}
                    onClick={handlePhoneClick}
                  >
                    <Phone color="primary" />
                  </IconButton>
                )}
                <IconButton
                  aria-label="Add"
                  className={classes.addLinkButton}
                  onClick={handleClick}
                >
                  <AddIcon color="primary" />
                </IconButton>
                <Menu
                  id="client-call-menu"
                  anchorEl={phoneAnchorEl}
                  keepMounted
                  open={Boolean(phoneAnchorEl)}
                  onClose={handleClose}
                >
                  {phones?.map(phone => (
                    <MenuItem key={phone?.readableNumber || ""} className={classes.phoneMenuItem}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        width={1}
                      >
                        <Box mr={3}>
                          <Typography variant="h4" color="textSecondary" gutterBottom>
                            Telefonnumer {phone?.type && translatePhoneType(phone?.type)}
                          </Typography>
                          <Typography variant="body2">{phone?.readableNumber}</Typography>
                        </Box>
                        <Box>
                          <TertiaryButton
                            onClick={() => (phone ? openCallDialog(phone) : undefined)}
                          >
                            <Phone className={classes.phoneMenuItemIcon} />
                            Anrufen
                          </TertiaryButton>
                        </Box>
                      </Box>
                    </MenuItem>
                  ))}
                </Menu>
                <Menu
                  id="client-action-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {isAuthorized("createIntake") && !data.person.temporary && (
                    <MenuItem onClick={() => linkTo(`/person/${data.person.id}/report`)}>
                      Gesprächsprotokoll
                    </MenuItem>
                  )}
                  {isAuthorized("createService") && (
                    <MenuItem
                      disabled={isAccountExpired}
                      onClick={() => linkTo(`/person/${data.person.id}/service`)}
                    >
                      Service
                    </MenuItem>
                  )}
                  {isAuthorized("createTask") && (
                    <MenuItem
                      disabled={isAccountExpired}
                      onClick={() => {
                        handleClose();
                        setOpenDialog("task");
                      }}
                    >
                      Aufgabe
                    </MenuItem>
                  )}
                  {isAuthorized("createContact") && (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setOpenDialog("contact");
                      }}
                    >
                      Kontaktperson
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setOpenDialog("document");
                    }}
                  >
                    Dokument
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setOpenDialog("complaint");
                    }}
                  >
                    Beschwerde
                  </MenuItem>
                  {data.person.client?.account?.type === AccountType.lifeWorks && (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        linkTo(`/person/${data.person.id}/qualtrics-type`);
                      }}
                    >
                      Qualtrics
                    </MenuItem>
                  )}
                  <MenuItem
                    disabled={!phone}
                    onClick={() => {
                      handleClose();
                      linkTo(`/person/${data.person.id}/sms`);
                    }}
                  >
                    SMS
                  </MenuItem>
                  <MenuItem
                    disabled={!email}
                    onClick={() => {
                      handleClose();
                      linkTo(`/person/${data.person.id}/send-email`);
                    }}
                  >
                    E-Mail
                  </MenuItem>
                  {data.person.client.ucmsId ? (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        linkTo(`/person/${data.person.id}/send-wpo-email`);
                      }}
                    >
                      E-Mail an WPO
                    </MenuItem>
                  ) : null}
                  {isAuthorized("createGDPRFiles") ? (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setOpenDialog("gdpr");
                      }}
                    >
                      Datenauskunft
                    </MenuItem>
                  ) : null}
                </Menu>
              </>
            )}
          </>
        ) : (
          <>
            {isAuthorized("doPersonActions") && (
              <>
                <IconButton
                  aria-label="Add"
                  className={classes.addLinkButton}
                  onClick={handleClick}
                >
                  <AddIcon color="primary" />
                </IconButton>
                <Menu
                  id="client-action-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setOpenDialog("document");
                    }}
                  >
                    Dokument
                  </MenuItem>
                  <MenuItem
                    disabled={!email}
                    onClick={() => {
                      handleClose();
                      linkTo(`/person/${data.person.id}/send-email`);
                    }}
                  >
                    E-Mail
                  </MenuItem>
                </Menu>
              </>
            )}
          </>
        )
      }
    >
      <Toolbar disableGutters>
        <div className={classes.justifyContent}>
          <Breadcrumb
            mapList={mapList || []}
            person={data.person}
            chipForPerson={
              <AccountStatusBadge contractEnd={contractExpirationDate} lang={language} />
            }
          ></Breadcrumb>
        </div>
      </Toolbar>
      <ContactPersonDialog
        open={openDialog === "contact"}
        onClose={closeDialog}
        person={data.person}
      />
      <DocumentDialog open={openDialog === "document"} onClose={closeDialog} person={data.person} />
      <ComplaintDialog
        open={openDialog === "complaint"}
        onClose={closeDialog}
        person={data.person}
      />
      <TaskDialog
        open={openDialog === "task"}
        onClose={closeDialog}
        person={data.person}
        topicId={topicId}
        serviceId={serviceId}
      />
      <GDPRDialog
        open={openDialog === "gdpr"}
        onClose={closeDialog}
        personId={data.person.id}
        displayName={data.person.name}
      />
      <OutgoingCall
        person={data.person}
        phone={callPhone}
        open={openDialog === "callOut"}
        close={closeDialog}
        callContentType={callContentType}
      />
    </SubHeader>
  );
};
export default PersonHeader;
