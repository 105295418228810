import React from "react";

import Typography from "@material-ui/core/Typography";

import { groupHours, formatGroupedHours } from "../../../utils/hoursHelper";
import { Hour, Maybe } from "../../../generated/graphql";

type TFormattedHours = { hours: Maybe<Hour>[] };

const FormattedHours = ({ hours }: TFormattedHours) => {
  const groupedHours = groupHours(hours);

  groupedHours.sort((a, b) => {
    const minA = Math.min.apply(Math, a.days);
    const minb = Math.min.apply(Math, b.days);

    if (minA > minb) {
      return 1;
    } else if (minA < minb) {
      return -1;
    } else {
      return 0;
    }
  });

  const formattedGroupedHours = formatGroupedHours(groupedHours);

  return (
    <>
      {formattedGroupedHours.map(group => (
        <Typography variant="body2" key={`hour-label-${group}`} gutterBottom>
          {group}
        </Typography>
      ))}
    </>
  );
};

export default FormattedHours;
