import React from "react";

const EmergencyContactIcon = () => {
  return (
    <svg width="19" height="39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.126007" y="0.756836" width="18.4777" height="37.4857" rx="1" fill="#025879" />
      <rect x="1.28087" y="3.68555" width="16.168" height="31.0429" fill="white" />
      <ellipse cx="9.94229" cy="36.4859" rx="1.15486" ry="1.17143" fill="white" />
      <ellipse cx="6.18901" cy="2.22157" rx="0.288713" ry="0.292857" fill="white" />
      <rect x="7.63258" y="1.92871" width="3.46457" height="0.585714" rx="0.292857" fill="white" />
      <path
        d="M4.07563 20.734C3.71993 20.734 3.38271 20.6871 3.06397 20.5934C2.74985 20.495 2.49347 20.3638 2.29484 20.1998C2.18859 20.1202 2.13547 20.0077 2.13547 19.8625C2.13547 19.7641 2.16318 19.6797 2.21862 19.6094C2.27867 19.5345 2.34796 19.497 2.42649 19.497C2.50964 19.497 2.60434 19.5298 2.71059 19.5954C2.90922 19.7406 3.1171 19.8461 3.33421 19.9117C3.55132 19.9773 3.79153 20.0101 4.05484 20.0101C4.39206 20.0101 4.65075 19.9515 4.8309 19.8343C5.01106 19.7125 5.10114 19.5368 5.10114 19.3072C5.10114 19.1291 5.01568 18.9933 4.84476 18.8995C4.67846 18.8011 4.39899 18.7074 4.00634 18.6184C3.59983 18.5294 3.26954 18.4263 3.01547 18.3091C2.7614 18.192 2.56969 18.0421 2.44035 17.8593C2.31101 17.6766 2.24633 17.4446 2.24633 17.1635C2.24633 16.873 2.32717 16.6129 2.48885 16.3833C2.65053 16.1537 2.87458 15.9757 3.16098 15.8491C3.452 15.7179 3.77767 15.6523 4.13799 15.6523C4.79857 15.6523 5.33673 15.8304 5.75248 16.1865C5.81253 16.2381 5.85411 16.2896 5.8772 16.3411C5.90492 16.388 5.91878 16.4489 5.91878 16.5239C5.91878 16.6223 5.88875 16.709 5.8287 16.7839C5.77327 16.8542 5.70629 16.8894 5.62776 16.8894C5.58156 16.8894 5.53768 16.8823 5.4961 16.8683C5.45915 16.8542 5.40833 16.8285 5.34366 16.791C5.15426 16.6504 4.96949 16.5473 4.78933 16.4817C4.61379 16.4114 4.39668 16.3763 4.13799 16.3763C3.82387 16.3763 3.57442 16.4419 3.38964 16.5731C3.20948 16.6996 3.11941 16.8777 3.11941 17.1073C3.11941 17.2994 3.20025 17.447 3.36193 17.5501C3.52361 17.6531 3.79846 17.7492 4.18649 17.8382C4.59762 17.9319 4.93022 18.0374 5.18429 18.1545C5.44298 18.267 5.6393 18.4122 5.77327 18.5903C5.91185 18.7683 5.98114 18.9909 5.98114 19.258C5.98114 19.5485 5.9003 19.8062 5.73862 20.0311C5.58156 20.2514 5.35752 20.4247 5.0665 20.5513C4.78009 20.6731 4.4498 20.734 4.07563 20.734Z"
        fill="#F2680A"
      />
      <path
        d="M9.32581 20.734C8.85001 20.734 8.43657 20.6309 8.08549 20.4247C7.73903 20.2186 7.47111 19.9234 7.28171 19.5391C7.09231 19.1549 6.99761 18.7051 6.99761 18.1897C6.99761 17.6742 7.09 17.2267 7.27478 16.8472C7.46418 16.463 7.73441 16.1678 8.08549 15.9616C8.43657 15.7554 8.85001 15.6523 9.32581 15.6523C9.80161 15.6523 10.2127 15.7554 10.5592 15.9616C10.9103 16.1678 11.1782 16.463 11.363 16.8472C11.5524 17.2267 11.6471 17.6742 11.6471 18.1897C11.6471 18.7051 11.5524 19.1549 11.363 19.5391C11.1736 19.9234 10.9033 20.2186 10.5523 20.4247C10.2058 20.6309 9.79699 20.734 9.32581 20.734ZM9.32581 20.0101C9.77389 20.0101 10.1227 19.8531 10.3721 19.5391C10.6262 19.2252 10.7532 18.7754 10.7532 18.1897C10.7532 17.6039 10.6262 17.1565 10.3721 16.8472C10.1227 16.5333 9.77389 16.3763 9.32581 16.3763C8.8731 16.3763 8.51972 16.5333 8.26565 16.8472C8.0162 17.1565 7.89147 17.6039 7.89147 18.1897C7.89147 18.7754 8.0162 19.2252 8.26565 19.5391C8.51972 19.8531 8.8731 20.0101 9.32581 20.0101Z"
        fill="#F2680A"
      />
      <path
        d="M14.5871 20.734C14.2314 20.734 13.8942 20.6871 13.5754 20.5934C13.2613 20.495 13.0049 20.3638 12.8063 20.1998C12.7 20.1202 12.6469 20.0077 12.6469 19.8625C12.6469 19.7641 12.6746 19.6797 12.7301 19.6094C12.7901 19.5345 12.8594 19.497 12.9379 19.497C13.0211 19.497 13.1158 19.5298 13.222 19.5954C13.4207 19.7406 13.6286 19.8461 13.8457 19.9117C14.0628 19.9773 14.303 20.0101 14.5663 20.0101C14.9035 20.0101 15.1622 19.9515 15.3424 19.8343C15.5225 19.7125 15.6126 19.5368 15.6126 19.3072C15.6126 19.1291 15.5271 18.9933 15.3562 18.8995C15.1899 18.8011 14.9104 18.7074 14.5178 18.6184C14.1113 18.5294 13.781 18.4263 13.5269 18.3091C13.2729 18.192 13.0812 18.0421 12.9518 17.8593C12.8225 17.6766 12.7578 17.4446 12.7578 17.1635C12.7578 16.873 12.8386 16.6129 13.0003 16.3833C13.162 16.1537 13.386 15.9757 13.6724 15.8491C13.9635 15.7179 14.2891 15.6523 14.6494 15.6523C15.31 15.6523 15.8482 15.8304 16.2639 16.1865C16.324 16.2381 16.3656 16.2896 16.3887 16.3411C16.4164 16.388 16.4302 16.4489 16.4302 16.5239C16.4302 16.6223 16.4002 16.709 16.3402 16.7839C16.2847 16.8542 16.2177 16.8894 16.1392 16.8894C16.093 16.8894 16.0491 16.8823 16.0076 16.8683C15.9706 16.8542 15.9198 16.8285 15.8551 16.791C15.6657 16.6504 15.4809 16.5473 15.3008 16.4817C15.1252 16.4114 14.9081 16.3763 14.6494 16.3763C14.3353 16.3763 14.0859 16.4419 13.9011 16.5731C13.7209 16.6996 13.6309 16.8777 13.6309 17.1073C13.6309 17.2994 13.7117 17.447 13.8734 17.5501C14.0351 17.6531 14.3099 17.7492 14.698 17.8382C15.1091 17.9319 15.4417 18.0374 15.6957 18.1545C15.9544 18.267 16.1508 18.4122 16.2847 18.5903C16.4233 18.7683 16.4926 18.9909 16.4926 19.258C16.4926 19.5485 16.4118 19.8062 16.2501 20.0311C16.093 20.2514 15.869 20.4247 15.578 20.5513C15.2915 20.6731 14.9613 20.734 14.5871 20.734Z"
        fill="#F2680A"
      />
    </svg>
  );
};

export default EmergencyContactIcon;
