import React, { useEffect, useState } from "react";

import dayjs from "dayjs";

import { User, Person, Appointment } from "../../../generated/graphql";
import DefaultDialog from "../../molecules/DefaultDialog";
import DialogContent from "../../molecules/DefaultDialogContent";

import AppointmentDialogEdit from "./Form/index";
import AppointmentDialogView from "./View";
import { TAvatarUser } from "../UserAvatar";

interface IAppointmentDialog {
  appointment?: Appointment;
  allowEditUsers?: boolean;
  currentTime: dayjs.Dayjs;
  minEndsAt: dayjs.Dayjs;
  minStartsAt: dayjs.Dayjs;
  onClose: () => void;
  open: boolean;
  person?: Person;
  refetch: () => void;
  users: Array<User | TAvatarUser>;
}

const AppointmentDialog = ({
  appointment,
  allowEditUsers = true,
  currentTime,
  minEndsAt,
  minStartsAt,
  onClose,
  open,
  person,
  refetch,
  users,
}: IAppointmentDialog) => {
  const [editable, setEditable] = useState(!appointment);

  useEffect(() => {
    setEditable(!appointment);
  }, [appointment]);

  return (
    <DefaultDialog
      open={open}
      onClose={!editable || !appointment ? onClose : undefined}
      backdrop={!editable}
      maxWidth={!editable ? "sm" : "md"}
      fullWidth
      PaperProps={{ square: true }}
    >
      <DialogContent>
        {!editable && (
          <AppointmentDialogView
            // @ts-ignore
            appointment={appointment}
            setToEditable={() => setEditable(true)}
          />
        )}
        {editable && (
          <AppointmentDialogEdit
            appointment={appointment}
            allowEditUsers={allowEditUsers}
            currentTime={currentTime}
            minEndsAt={minEndsAt}
            minStartsAt={minStartsAt}
            onClose={() => {
              setEditable(!appointment);
              onClose();
            }}
            person={person}
            refetch={refetch}
            users={users}
          />
        )}
      </DialogContent>
    </DefaultDialog>
  );
};
export default AppointmentDialog;
