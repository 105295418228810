import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  closeIcon: {
    cursor: "pointer",
    position: "absolute",
    top: 24,
    right: 24,
  },
}));

export default useStyles;
