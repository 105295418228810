import React, { FormEvent } from "react";
import { FormikProps, FormikValues } from "formik";
import useAutoSave from "../../../hooks/useAutoSave";
import { Person } from "../../../generated/graphql";
import PrimaryButton from "../../atoms/PrimaryButton";
import SlateRTE from "../../molecules/SlateRTE";
import useStyles from "../reportStyles";
import WizardNavigation from "../../molecules/WizardNavigation";
import Box from "@material-ui/core/Box";
import SecondaryButton from "../../atoms/SecondaryButton";

export interface IFormProps {
  person: Person;
  saveInput: (params: any) => any;
  backAction: () => void;
}

export default function StepSummary({
  backAction,
  handleSubmit,
  isSubmitting,
  saveInput,
  setFieldValue,
  values,
}: FormikProps<FormikValues> & IFormProps) {
  const timer = useAutoSave(values, saveInput);
  function handleChange(value: string) {
    setFieldValue("summary", value);
  }

  const submit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    handleSubmit(event);
  };

  const classes = useStyles();
  return (
    <form onSubmit={submit} className={classes.content}>
      <Box maxWidth={900} width="100%">
        <SlateRTE
          value={values.summary}
          label="Zusammenfassung"
          handleChange={handleChange}
          tabIndex={1}
        />
      </Box>
      <WizardNavigation>
        <SecondaryButton onClick={() => backAction()} tabIndex={3}>
          Zurück
        </SecondaryButton>
        <PrimaryButton type="submit" disabled={isSubmitting} tabIndex={2}>
          Weiter
        </PrimaryButton>
      </WizardNavigation>
    </form>
  );
}
