import React from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

type TNoContactPersonsTeaser = {
  title?: string | null;
};

const NoContactPersonsTeaser = ({ title }: TNoContactPersonsTeaser) => {
  return (
    <Box width="100%" textAlign="center">
      <svg
        width="239"
        height="292"
        viewBox="0 0 239 292"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M57.2097 63.0102C58.7407 52.2179 67.2711 22.1274 76.0202 22.1274C86.4719 22.1274 85.863 10.7472 95.487 11.1408C105.111 11.5344 114.419 11.1408 109.704 3.62617C104.989 -3.88842 124.797 6.13307 131.14 15.9603C131.14 15.9603 137.58 9.04232 141.748 11.1408C145.916 13.2392 143.724 20.7545 146.451 22.1274C149.177 23.5003 155.528 18.0787 155.528 18.0787C155.528 18.0787 160.23 48.3374 165.371 48.3374C178.771 48.3374 166.683 69.9482 165.371 84.4849"
          stroke="#025879"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99.9812 118.465C115.588 122.015 145.027 117.692 155.087 102.786C155.087 102.786 161.905 108.307 166.152 94.8363C170.4 81.366 157.881 84.4576 157.881 84.4576C153.018 61.6406 132.802 41.1759 120.283 47.359C116.731 49.1131 111.707 41.8656 109.263 41.1759C98.3092 38.0843 99.3238 47.359 95.2449 47.359C91.1659 47.359 96.41 39.4682 87.4205 41.1759C78.4311 42.8835 62.5362 68.0061 62.5362 68.0061C62.5362 68.0061 51.4704 58.6379 52.9936 74.0957C54.5167 89.5534 62.5362 86.4619 62.5362 86.4619C67.0773 105.011 84.3743 114.915 99.9812 118.465Z"
          stroke="#025879"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M136.423 80.0906C133.993 80.0906 132.023 78.1234 132.023 75.6967C132.023 73.27 133.993 71.3027 136.423 71.3027C138.854 71.3027 140.824 73.27 140.824 75.6967C140.824 78.1234 138.854 80.0906 136.423 80.0906Z"
          fill="#025879"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M92.4157 71.3035C89.9852 71.3035 88.0149 69.3363 88.0149 66.9096C88.0149 64.4829 89.9852 62.5156 92.4157 62.5156C94.8461 62.5156 96.8164 64.4829 96.8164 66.9096C96.8164 69.3363 94.8461 71.3035 92.4157 71.3035Z"
          fill="#025879"
        />
        <path
          d="M132.022 96.3674C127.798 108.249 110.166 114.813 96.1124 108.249C82.059 101.685 80.1505 93.2373 79.2136 84.4854"
          stroke="#025879"
          strokeLinecap="round"
        />
        <path
          d="M145.223 134.194C154.704 137.522 169.487 145.543 183.038 141.175C192.351 138.173 214.548 120.474 216.332 119.083C219.438 116.66 211.414 104.808 214.52 102.385C217.625 99.9625 219.234 108.41 221.563 106.593C223.893 104.776 231.676 101.939 234.819 105.955C237.962 109.972 232.991 123.42 216.332 119.083"
          stroke="#025879"
        />
        <path
          d="M67.3987 128.509C53.826 125.339 35.8806 125.089 28.1628 115.944C19.0484 105.144 14.8594 88.9676 14.1584 86.9348C12.8839 83.2391 25.202 77.9707 23.9275 74.275C22.653 70.5793 17.1578 76.6024 16.2019 73.8306C15.246 71.0589 10.4405 64.4433 5.92735 65.9949C1.41424 67.5465 0.494854 86.3501 14.1584 86.9348"
          stroke="#025879"
        />
        <path
          d="M119.764 220.697C119.764 220.697 123.221 241.249 123.221 250.44C123.221 258.161 110.019 277.481 110.019 284.661C110.019 287.75 132.554 293.742 136.006 289.575C138.05 287.107 132.189 281.727 127.073 280.183C123.678 279.158 110.019 284.661 110.019 284.661"
          stroke="#025879"
        />
        <path
          d="M82.1474 220.697C81.2568 227.036 78.607 242.749 79.2136 253.571C79.8581 265.069 86.3029 276.315 84.7036 280.356C84.7036 280.356 90.9126 285.585 85.4797 288.2C80.0468 290.815 70.4121 292.909 70.4121 288.2C70.4121 283.492 77.4151 278.773 84.7036 280.356"
          stroke="#025879"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M127.397 196.457C127.397 196.457 134.357 222.914 130.845 228.457C126.258 235.697 110.781 233.595 107.694 233.595C102.152 233.595 104.346 214.535 100.421 214.535C96.4956 214.535 96.8904 233.595 92.2603 233.595C90.5917 233.595 71.57 232.507 70.6531 228.457C69.0259 221.269 76.057 200.409 79.1952 194.256C84.1015 184.636 127.397 196.457 127.397 196.457Z"
          fill="#555555"
          stroke="#025879"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M131.102 123.582C131.163 123.575 131.224 123.573 131.285 123.577L154.33 125.01C154.987 125.051 155.426 125.704 155.215 126.328L147.22 149.959C147.041 150.489 146.47 150.774 145.94 150.591C142.118 149.274 128.109 144.666 128.053 147.886C127.669 169.923 130.366 190.114 134.048 195.104C139.095 201.943 75.5085 198.035 75.5085 192.173C75.5085 188.026 80.2022 167.455 82.5224 147.886C82.7218 146.204 64.9087 145.386 61.0971 145.23C60.6649 145.212 60.306 144.926 60.1938 144.508C59.5077 141.952 57.2101 132.817 57.2101 125.091C57.2101 116.225 81.3269 121.771 82.4798 122.041C82.5168 122.05 82.5413 122.054 82.5791 122.058L95.0827 123.571C95.0827 123.571 101.75 130.627 107.806 130.627C113.862 130.627 118.633 125.091 118.633 125.091L131.102 123.582Z"
          fill="#A6C4CE"
          stroke="#025879"
          strokeLinejoin="round"
        />
      </svg>
      <Box paddingTop={5}>
        <Typography>{title}</Typography>
      </Box>
    </Box>
  );
};

export default NoContactPersonsTeaser;
