import React from "react";
import DayjsUtils from "@date-io/dayjs";
import dayjs from "dayjs";
import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Box from "@material-ui/core/Box";

import PrimaryButton from "../../../atoms/PrimaryButton";
import WizardNavigation from "../../../molecules/WizardNavigation";
import SecondaryButton from "../../../atoms/SecondaryButton";
import {
  Service,
  Person,
  CreateServiceAttributeDocument,
  UpdateServiceAttributeDocument,
} from "../../../../generated/graphql";
import { addWorkinngDays } from "../../../../utils/dateHelper";

import useStyles from "../styles";

interface Props {
  backAction: () => void;
  service: Service;
  person: Person;
  serviceMetaAttribute: any;
  setStep: (step: number) => void;
  step: number;
  update: () => void;
}

export default function DateInput({
  serviceMetaAttribute,
  service,
  setStep,
  step,
  update,
  backAction,
}: Props) {
  const classes = useStyles();
  const [createServiceAttribute] = useMutation(CreateServiceAttributeDocument);
  const [updateServiceAttribute] = useMutation(UpdateServiceAttributeDocument);
  const next = () => {
    setStep(step + 1);
  };
  let attributes = service?.serviceAttributes || [];
  attributes = attributes.filter(attr => attr?.serviceMetaAttributeId === serviceMetaAttribute.id);
  const attribute = attributes.length > 0 ? attributes[0] : null;

  const initialValue =
    serviceMetaAttribute.name === "duedate" ? addWorkinngDays(dayjs(), 3) : dayjs();

  return (
    <>
      <Box display="flex" justifyContent="center">
        <div>
          <MuiPickersUtilsProvider utils={DayjsUtils} locale="de">
            <Formik
              initialValues={{
                value: !!attribute && attribute.value ? dayjs(attribute.value) : initialValue,
              }}
              onSubmit={(values, { setSubmitting }) => {
                if (!!attribute) {
                  updateServiceAttribute({
                    variables: {
                      serviceAttribute: {
                        value: dayjs(values.value),
                        id: attribute.id,
                        serviceId: service.id,
                      },
                    },
                  }).then(() => {
                    setSubmitting(false);
                    try {
                      update();
                    } catch (error) {
                      console.log(error);
                    }
                  });
                } else {
                  createServiceAttribute({
                    variables: {
                      serviceAttribute: {
                        serviceMetaAttributeId: serviceMetaAttribute.id,
                        value: dayjs(values.value),
                        serviceId: service.id,
                      },
                    },
                  }).then(() => {
                    setSubmitting(false);
                    try {
                      update();
                    } catch (error) {
                      console.log(error);
                    }
                  });
                }
              }}
            >
              {({ handleSubmit, values, setFieldValue, setSubmitting }) => (
                <form onSubmit={handleSubmit} className={classes.form}>
                  <KeyboardDatePicker
                    tabIndex={1}
                    className={classes.formControl}
                    format="DD.MM.YYYY"
                    onChange={date => setFieldValue("value", date)}
                    onAccept={date => {
                      setSubmitting(true);
                      if (!!attribute) {
                        updateServiceAttribute({
                          variables: {
                            serviceAttribute: {
                              value: date,
                              id: attribute.id,
                              serviceId: service.id,
                            },
                          },
                        }).then(() => {
                          setSubmitting(false);
                          update();
                        });
                      } else {
                        createServiceAttribute({
                          variables: {
                            serviceAttribute: {
                              serviceMetaAttributeId: serviceMetaAttribute.id,
                              value: date,
                              serviceId: service.id,
                            },
                          },
                        }).then(() => {
                          setSubmitting(false);
                          update();
                        });
                      }
                    }}
                    label={serviceMetaAttribute.label}
                    minDate={dayjs()}
                    value={values.value}
                  />
                </form>
              )}
            </Formik>
          </MuiPickersUtilsProvider>
        </div>
      </Box>
      <WizardNavigation>
        <SecondaryButton onClick={() => backAction()} tabIndex={3}>
          Zurück
        </SecondaryButton>
        <PrimaryButton
          tabIndex={2}
          disabled={serviceMetaAttribute.required && !attribute}
          onClick={next}
        >
          Weiter
        </PrimaryButton>
      </WizardNavigation>
    </>
  );
}
