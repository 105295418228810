import React, { useState, useRef } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import { Person, RelationshipType, Gender, Relationship } from "../../../generated/graphql";
import TextField from "@material-ui/core/TextField";
import translateRelationshipType from "../../../utils/translateRelationshipType";
import useStyles from "../textualSelectStyles";

type TPersonTextualSelect = {
  defaultContact?: string | null;
  person: Person;
  setContact?: (person?: Person | { name: string; id?: never }) => void;
  tabIndex?: number;
};
const PersonTextualSelect = ({
  defaultContact,
  person,
  setContact,
  tabIndex,
}: TPersonTextualSelect) => {
  const [createMode, setCreateMode] = useState(false);
  const [name, setName] = useState(person.name!);
  const anchor = useRef<HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const relations = (person.relations || []) as Relationship[];
  function toggleMenu() {
    setOpen(!open);
  }
  function handleClose(event: any) {
    if (anchor && anchor.current && anchor.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }

  function handleChange(event: any) {
    const {
      target: { value },
    } = event;
    setName(value);

    let existing;
    if (person.name! !== value) {
      existing = relations.find(rel => rel!.passivePerson!.name! === value)?.passivePerson;
    } else {
      existing = person;
    }

    const contact = existing || { name: value };

    setContact && setContact(contact);
  }

  function handleSelectRelation(rel: Relationship) {
    setName(rel.passivePerson!.name!);
    setContact && setContact(rel.passivePerson!);
    toggleMenu();
  }
  function selectClient() {
    setName(person!.name!);
    setContact && setContact(person);
    toggleMenu();
  }
  function selectNew() {
    setCreateMode(true);
    setName("");
    setContact && setContact({ name: "" });
    toggleMenu();
  }

  return setContact ? (
    <>
      <TextField
        {...tabIndex}
        className={classes.textField}
        data-testid="PersonTextualSelect"
        onClick={!createMode ? toggleMenu : () => undefined}
        onBlur={createMode ? () => setCreateMode(false) : () => undefined}
        onChange={handleChange}
        value={name}
        inputProps={{ className: classes.input }}
        inputRef={anchor}
      />
      <Popper open={open} anchorEl={anchor.current} transition>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <MenuItem key="new-person" onClick={selectNew}>
                    Neu
                  </MenuItem>
                  <MenuItem key="client" onClick={selectClient}>
                    {person!.name}, Klient
                    {person.gender === Gender.female && "in"}
                  </MenuItem>
                  {relations.map((rel: Relationship) => (
                    <MenuItem
                      key={`report-rel-${rel?.passivePerson?.name}-${rel?.id}`}
                      onClick={() => handleSelectRelation(rel)}
                    >
                      {rel!.passivePerson!.name!},{" "}
                      {translateRelationshipType(rel!.relationship || RelationshipType.unknown)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  ) : (
    <>{defaultContact || person.name}</>
  );
};

export default PersonTextualSelect;
