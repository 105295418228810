import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Client, Service, ServiceStatus, Topic, useTopicsQuery } from "../../../generated/graphql";
import { fetchPolicy } from "../../../utils/constants";
import GirlWithPencil from "../../assets/GirlWithPencil";
import ClickBox from "../../atoms/ClickBox";
import Loader from "../../atoms/Loading";
import useStyles from "../boxListStyles";


interface ITopicList {
  name: string;
  client: Pick<Client, "id">;
}
interface IList {
  topics: Topic[];
  subscribe?: () => () => void;
}
interface IItem {
  topic: Topic;
}

interface IServiceItem {
  service: Service;
  isLast: boolean;
}

const List = ({ topics }: IList) => {
  return (
    <Box mt={2} width={1}>
      {topics.map(topic => (
        <Item topic={topic} key={`topic-list-item-${topic.id}-${topic.name}`}></Item>
      ))}
    </Box>
  );
};

const ServiceItem = ({ service, isLast }: IServiceItem) => {
  const name = service.serviceMeta?.name;
  const classes = useStyles();
  if (!name) {
    return <></>;
  }
  const status = service.status || ServiceStatus.research;
  // TODO!: Unify ServiceStatus translations somehwere
  const statusLabel = {
    [ServiceStatus.research]: "Recherche",
    [ServiceStatus.ready]: "Rückmeldung",
    [ServiceStatus.finished]: "abgeschlossen",
    [ServiceStatus.started]: "in Planung",
    [ServiceStatus.counseling]: "in Beratung",
  }[status];
  return (
    <Box display="flex" mb={!isLast ? 1 : 0}>
      <Typography variant="body2">{name}</Typography>
      <Chip
        label={statusLabel}
        size="small"
        color={status !== ServiceStatus.finished ? "primary" : "default"}
        classes={{ root: classes.tinyChip }}
      ></Chip>
    </Box>
  );
};

const Item = ({ topic }: IItem) => {
  const { push } = useHistory();
  const classes = useStyles();
  const { pathname } = useLocation();
  dayjs.extend(LocalizedFormat);
  const services = (topic.services || []) as Service[];
  return (
    <ClickBox handleClick={() => push(`${pathname}/topics/${topic.id}`)}>
      <Box pr={2} width={0.3}>
        <Typography className={classes.clickable} variant="h3" color="primary">
          {topic.name}
        </Typography>
      </Box>

      <Box alignSelf="flex-start" pr={2} flex={1}>
        {topic.services && topic.services.length > 0 && (
          <>
            <Typography variant="h4" color="textSecondary" gutterBottom>
              Services
            </Typography>
            <Box>
              {services.map((service: Service, idx: number) =>
                service ? (
                  <ServiceItem
                    key={`topic-${topic.id}-service-${service.id}-item`}
                    service={service}
                    isLast={idx + 1 === services.length}
                  />
                ) : (
                  <></>
                ),
              )}
            </Box>
          </>
        )}
      </Box>
      <Box alignSelf="flex-start" pr={2}>
        <Typography variant="h4" color="textSecondary" gutterBottom>
          Letzte Beratung
        </Typography>
        <Typography variant="body2">
          {topic.lastInteraction && dayjs(topic.lastInteraction).format("L")}
        </Typography>
      </Box>
    </ClickBox>
  );
};

const TopicList = ({ client, name }: ITopicList) => {
  const { data, loading } = useTopicsQuery({
    variables: { clientId: client.id! },
    fetchPolicy,
  });

  const topics = data?.topics || [];
  return (
    <Box>
      <Typography variant="h3">Themen</Typography>
      {loading ? (
        <Box marginTop={6} position="relative">
          <Loader />
        </Box>
      ) : (
        <>
          {!!topics.length && <List topics={topics}></List>}
          {!topics.length && (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box mt={2} mb={4}>
                <GirlWithPencil />
              </Box>
              <Typography variant="body2">
                {name.length ? `${name} hat noch kein Thema` : "Noch kein Thema"}
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default TopicList;
