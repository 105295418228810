import React, { FC } from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { Select } from "formik-material-ui";
import { Field } from "formik";

import { TabIndex } from "../../../types";

import { ClientType } from "../../../generated/graphql";

const ClientTypeSelect: FC<TabIndex> = ({ tabIndex = 0 }) => {
  return (
    <FormControl fullWidth margin="normal">
      <InputLabel htmlFor="clientType">Status</InputLabel>
      <Field name="clientType" label="Status" component={Select} tabIndex={tabIndex}>
        <MenuItem value={ClientType.unknown}>{translatClientType(ClientType.unknown)}</MenuItem>
        <MenuItem value={ClientType.employee}>{translatClientType(ClientType.employee)}</MenuItem>
        <MenuItem value={ClientType.executive}>{translatClientType(ClientType.executive)}</MenuItem>
        <MenuItem value={ClientType.relative}>{translatClientType(ClientType.relative)}</MenuItem>
        <MenuItem value={ClientType.other}>{translatClientType(ClientType.other)}</MenuItem>
      </Field>
    </FormControl>
  );
};

export const translatClientType = (clientType: ClientType) => {
  switch (clientType) {
    case ClientType.employee:
      return "Angestellter";
    case ClientType.executive:
      return "Mitarbeiter mit Führungsposition";
    case ClientType.other:
      return "Sonstiges";
    case ClientType.relative:
      return "Angehörige";
    default:
      return "Unbekannt";
  }
};

export default ClientTypeSelect;
