import React, { FormEvent } from "react";
import { FormikProps, FormikValues, Field } from "formik";
import { FormControl, FormControlLabel } from "@material-ui/core";
import { Checkbox } from "formik-material-ui";
import useAutoSave from "../../../hooks/useAutoSave";
import { Person } from "../../../generated/graphql";
import SlateRTE, { initialFormValue } from "../../molecules/SlateRTE";
import PrimaryButton from "../../atoms/PrimaryButton";
import IReviewStep from "../../../interfaces/IReviewStep";
import IClientReviewFormAttribute from "../../../interfaces/IClientReviewFormAttribute";
import WizardNavigation from "../../molecules/WizardNavigation";
import Box from "@material-ui/core/Box";
import SecondaryButton from "../../atoms/SecondaryButton";
import useStyles from "../reportStyles";

export interface IFormProps {
  activeReviewStep: IReviewStep;
  backAction: () => void;
  person: Person;
  saveInput: (params: any) => any;
}

export default function GenericForm({
  activeReviewStep,
  backAction,
  handleSubmit,
  saveInput,
  setFieldValue,
  isSubmitting,
  values,
}: FormikProps<FormikValues> & IFormProps) {
  const timer = useAutoSave(values, saveInput);
  function handleChange(value: string, key: string) {
    setFieldValue(key, value);
  }

  const submit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    handleSubmit(event);
  };

  const classes = useStyles();
  return (
    <form onSubmit={submit} className={classes.content}>
      <Box maxWidth={900} width="100%">
        {Object.keys(values).map((key, index) => {
          const currentAttr: IClientReviewFormAttribute = activeReviewStep.attrs[index];
          if (!currentAttr) {
            return null;
          }

          if (currentAttr.type === "checkbox") {
            return (
              <FormControl key={key} margin="normal" className={classes.checkboxInput}>
                <FormControlLabel
                  control={
                    <Field
                      label={currentAttr.label || ""}
                      name={key}
                      value={!!values[key]}
                      component={Checkbox}
                      tabIndex={index + 1}
                      type="checkbox"
                    />
                  }
                  classes={{ label: classes.checkboxLabel }}
                  label={currentAttr.label || ""}
                />
              </FormControl>
            );
          }
          return (
            <SlateRTE
              handleChange={value => handleChange(value, key)}
              initiallyReadOnly
              key={key}
              label={currentAttr.label || ""}
              placeholder={currentAttr.placeholder || undefined}
              tabIndex={index + 1}
              value={values[key] || initialFormValue}
            />
          );
        })}
        <WizardNavigation>
          <SecondaryButton onClick={() => backAction()} tabIndex={Object.keys(values).length + 2}>
            Zurück
          </SecondaryButton>
          <PrimaryButton
            type="submit"
            disabled={isSubmitting}
            tabIndex={Object.keys(values).length + 1}
          >
            Weiter
          </PrimaryButton>
        </WizardNavigation>
      </Box>
    </form>
  );
}
