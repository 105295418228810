import React from "react";
import dayjs from "dayjs";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import Avatar from "../../atoms/Avatar";
import { streamFormat } from "../../../utils/timeFormats";

import elliBlue from "../../assets/elliBlue.svg";
import useStyles from "./styles";

type TMessage = {
  isClient: boolean;
  text: string;
  time: dayjs.Dayjs;
};
type TChatMessage = {
  message: TMessage;
  avatarKeys?: string | null;
};
const ChatMessage = ({ message: { isClient, text, time }, avatarKeys }: TChatMessage) => {
  const classes = useStyles(isClient);

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection={isClient ? "row" : "row-reverse"}
      margin={`8px ${isClient ? 20 : 0}% 8px ${isClient ? 0 : 20}%`}
    >
      {isClient ? (
        <Avatar className={classes.avatar} widthfactor={5}>
          {avatarKeys}
        </Avatar>
      ) : (
        <Avatar src={elliBlue} widthfactor={5} />
      )}

      <Box
        className={classes.bubble}
        minWidth={0.5}
        bgcolor={isClient ? "#F2FCFF" : "#FFFFFF"}
        padding={2}
        margin={`0px ${isClient ? 0 : 32}px 0 ${isClient ? 32 : 0}px`}
      >
        <Typography style={{ color: "#979797" }} variant="body2">
          {time.format(streamFormat(time))}
        </Typography>
        <Typography variant="body2">{text}</Typography>
      </Box>
    </Box>
  );
};

export default ChatMessage;
