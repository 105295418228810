import React from "react";
import { useParams } from "react-router-dom";
import { Box } from "@material-ui/core";

import Loader from "../../atoms/Loading";

import PersonDashboard from "../../organisms/PersonDashboard";
import { usePersonSubscription } from "../../../hooks/usePersonSubscription";

export default function PersonOverview() {
  const { id } = useParams<{ id: string }>();

  const { person, loading } = usePersonSubscription(parseInt(id, 10));

  if (loading) {
    return <Loader />;
  }

  if (!person) {    
    return (
      <Box display="flex" alignItems="center" justifyContent="center" marginTop={24} width="100%">
        <div>Person wurde nicht gefunden</div>
      </Box>
    );
  }

  return <PersonDashboard person={person} id={parseInt(id, 10)} />;
}
