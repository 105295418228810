import React, { FC } from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import History from "@material-ui/icons/History";
import HighlightOff from "@material-ui/icons/HighlightOff";
import { useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { ServiceResearchObjectStatus } from "../../../generated/graphql";

const ResearchStatusIcon: FC<{
  status?: ServiceResearchObjectStatus | null;
}> = ({ status }) => {
  const theme: Theme = useTheme();
  switch (status) {
    case ServiceResearchObjectStatus.open:
      return <History color="secondary" />;
    case ServiceResearchObjectStatus.suitable:
      return (
        <SvgIcon>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.90476 12C3.90476 7.52912 7.52912 3.90476 12 3.90476C12.4659 3.90476 12.9219 3.94402 13.365 4.01921L13.6837 2.14128C13.1357 2.04831 12.5731 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 10.7704 21.7776 9.59057 21.3701 8.49996L19.5858 9.1667C19.9149 10.0473 20.0952 11.0015 20.0952 12C20.0952 16.4709 16.4709 20.0952 12 20.0952C7.52912 20.0952 3.90476 16.4709 3.90476 12ZM12 16.2857L19.1429 5.91127L17.5545 4.85714L12 12.9981L9.24286 10.2844L7.71429 11.8082L12 16.2857Z"
            fill="#68BC7D"
          />
        </SvgIcon>
      );
    case ServiceResearchObjectStatus.limited:
      return (
        <SvgIcon>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.90476 12C3.90476 7.52912 7.52912 3.90476 12 3.90476C12.4659 3.90476 12.9219 3.94402 13.365 4.01921L13.6837 2.14128C13.1357 2.04831 12.5731 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 10.7704 21.7776 9.59057 21.3701 8.49996L19.5858 9.1667C19.9149 10.0473 20.0952 11.0015 20.0952 12C20.0952 16.4709 16.4709 20.0952 12 20.0952C7.52912 20.0952 3.90476 16.4709 3.90476 12ZM12 16.2857L19.1429 5.91127L17.5545 4.85714L12 12.9981L9.24286 10.2844L7.71429 11.8082L12 16.2857Z"
            fill={theme.palette.secondary.main}
          />
        </SvgIcon>
      );
    case ServiceResearchObjectStatus.unsuitable:
      return <HighlightOff />;
    default:
      return <></>;
  }
};

export default ResearchStatusIcon;
