import { useEffect } from "react";
import dayjs from "dayjs";

import {
  PersonActivity,
  usePersonActivitiesQuery,
  PersonActivitiesSubscriptionDocument,
  PersonActivitiesSubscriptionSubscription,
} from "../generated/graphql";

import { TAKE, fetchPolicy } from "../utils/constants";

export const usePersonActivitiesSubscription = (variables: {
  contactId?: number;
  personId: number;
  topicId?: number;
  serviceId?: number;
}) => {
  const { data, fetchMore, loading, subscribeToMore } = usePersonActivitiesQuery({
    variables: { ...variables, skip: 0, take: TAKE },
    fetchPolicy,
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore<PersonActivitiesSubscriptionSubscription>({
      document: PersonActivitiesSubscriptionDocument,
      variables: { ...variables },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !prev) return prev;
        const newActivity = subscriptionData.data.personActivitiesSubscription;
        const activities = [...prev.personActivities];
        if (newActivity) {
          const updateIndex = activities.findIndex(activity => activity.id === newActivity.id);

          let index = updateIndex;

          if (updateIndex === -1) {
            index = activities.findIndex(activity =>
              dayjs(activity.start).isBefore(newActivity.start),
            );
          }

          const replace = updateIndex > -1 ? 1 : 0;

          activities.splice(index, replace, newActivity);
        }

        return { ...prev, personActivities: activities };
      },
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables]);

  return { activities: (data?.personActivities || []) as PersonActivity[], loading, fetchMore };
};
