import React from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import dayjs from "dayjs";

import noData from "../../assets/noData.svg";
import ClickBox from "../../atoms/ClickBox";
import { useGetDriveDocumentsSubscription } from "../../../hooks/useDriveDocumentsSubscription";

type TDocumentsList = {
  personIds: number[];
};

export const DocumentsList = ({ personIds }: TDocumentsList) => {
  const { getDriveDocuments, loading } = useGetDriveDocumentsSubscription(personIds);

  if (loading) {
    return null;
  }

  if (!getDriveDocuments || getDriveDocuments.length < 1) {
    return (
      <Box textAlign="center">
        <img src={noData} alt="nix" />
        <Box mt={7} />
        <Typography variant="body2">Es wurden noch keine Dokumente hinterlegt.</Typography>
      </Box>
    );
  }

  return (
    <>
      {getDriveDocuments.map(document => {
        return (
          <ClickBox
            key={`document-${document!.id}`}
            handleClick={() => {
              window.open(document?.link);
            }}
          >
            <Box display="flex" flex={1} py={2} px={1} alignItems="center">
              <Illustration />
              <Box mx={1} />
              <Box>
                <Typography color="primary" variant="body2">
                  {document?.title}
                </Typography>
                <Box my={1} />
                <Typography style={{ color: "#979797" }} variant="body2">
                  {document?.user?.name}, {dayjs(document?.created || "").format("DD.MM.YYYY")}
                </Typography>
              </Box>
            </Box>
          </ClickBox>
        );
      })}
    </>
  );
};

const Illustration = () => {
  return (
    <svg width="43" height="54" viewBox="0 0 43 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.946246 2.34433C3.22437 1.99111 9.76166 1.05953 17.6028 0.672438C25.4523 0.284937 34.5686 0.445471 42.0212 2.25738C42.2602 2.31549 42.4164 2.54042 42.3821 2.79784C40.2607 18.7021 42.0469 46.1079 42.4316 51.5152C42.4503 51.7784 42.2614 52.0061 41.9891 52.0345C41.4172 52.0942 40.605 52.1764 39.5976 52.2717L16.1123 53.5C10.2678 53.4897 4.73357 53.1417 0.932957 52.1686C0.712015 52.1121 0.564186 51.9033 0.590739 51.6586C2.30027 35.9025 0.851092 8.13703 0.551126 2.85027C0.536186 2.58697 0.706531 2.3815 0.946246 2.34433Z"
        fill="white"
        stroke="#025879"
      />
      <path
        d="M6.01953 14.3879C13.8962 14.3879 15.6715 16.2763 20.7351 14.1133M36.1195 20.1922C29.3681 19.5267 11.1956 21.9227 6.01953 20.1922M6.01953 46.644C12.0395 47.3774 14.3806 46.3694 19.3973 47.0414"
        stroke="#025879"
        strokeLinecap="round"
      />
      <path
        d="M9.36523 26.8789C24.5559 26.8789 25.753 27.9056 32.4419 26.8789M9.36523 33.2017C17.2419 33.2017 27.0908 34.9429 36.1208 33.3681M33.4452 40.35C26.6938 39.6845 15.3852 40.9909 9.36523 40.35"
        stroke="#025879"
        strokeLinecap="round"
      />
      <path
        d="M28.7617 16.3629C29.9657 13.2604 28.762 9.31163 28.7617 5.08081C31.9722 4.23468 33.1762 5.08081 36.7884 5.08081C35.9856 9.59368 36.7884 12.4142 36.7884 16.3629C33.9847 17.3481 31.5711 16.3629 28.7617 16.3629Z"
        fill="#F2680A"
        fillOpacity="0.5"
      />
      <ellipse cx="6.35489" cy="26.5432" rx="1.00333" ry="1.008" fill="#025879" />
      <ellipse cx="6.35489" cy="33.2658" rx="1.00333" ry="1.008" fill="#025879" />
      <ellipse cx="6.35489" cy="39.9846" rx="1.00333" ry="1.008" fill="#025879" />
    </svg>
  );
};

export default DocumentsList;
