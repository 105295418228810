import { Formik, FormikActions } from "formik";
import { pathOr } from "ramda";
import { useMutation } from "@apollo/client";
import * as Yup from "yup";
import React, { useState } from "react";

import {
  Service,
  Person,
  CreateServiceClientDocument,
  DeleteServiceClientDocument,
  ClientType,
  Relationship,
  ServiceClient,
  Maybe,
  ServiceMetaAttribute,
  Client,
} from "../../../../generated/graphql";
import NestedClientForm from "../../NestedClientForm";
import PersonList from "./PersonList";

const PersonValidation = Yup.object().shape({
  firstName: Yup.string().required("Bitte ausfüllen"),
  lastName: Yup.string().required("Bitte ausfüllen"),
  initialAge: Yup.number()
    .max(120, "Das erlaubte Höchstalter beträgt 120 Jahre")
    .required("Bitte ausfüllen"),
});

interface Props {
  backAction: () => void;
  service: Service;
  person: Person;
  serviceMetaAttribute: ServiceMetaAttribute;
  setStep: (step: number) => void;
  step: number;
  update: () => void;
  isReloading: boolean;
}

interface CreatePersonFormProps {
  firstName: string;
  lastName: string;
  gender: string;
  initialAge?: number;
}

export default function PersonSelector(props: Props) {
  const { backAction, person, serviceMetaAttribute, service, setStep, step, update } = props;
  const relations: Relationship[] = pathOr([], ["relations"], person);
  const [showForm, setShowForm] = useState<boolean>(relations ? relations.length === 0 : true);
  const [createServiceClient] = useMutation(CreateServiceClientDocument);
  const [deleteServiceClient] = useMutation(DeleteServiceClientDocument);

  const createWithClient = async (client: Client) => {
    createServiceClient({
      variables: {
        serviceClient: {
          serviceMetaAttributeId: serviceMetaAttribute.id,
          serviceId: service.id,
          client: {
            id: client.id,
          },
        },
      },
    }).then(update);
  };

  const next = () => {
    setStep(step + 1);
  };

  const connectedServiceClient = !!service.serviceClients
    ? service.serviceClients.filter(
        (serviceClient: any) => serviceClient.serviceMetaAttributeId === serviceMetaAttribute.id,
      )
    : [];

  const onSubmit = async (
    values: CreatePersonFormProps,
    { setSubmitting }: FormikActions<CreatePersonFormProps>,
  ) => {
    if (!serviceMetaAttribute.multiple) {
      const currentServiceClient = pathOr(null, ["serviceClients", 0], service) as Maybe<
        ServiceClient
      >;

      if (!!currentServiceClient) {
        await deleteServiceClient({
          variables: { id: currentServiceClient.id },
        });
      }
    }

    await createServiceClient({
      variables: {
        serviceClient: {
          serviceMetaAttributeId: serviceMetaAttribute.id,
          serviceId: service.id,
          client: {
            person: {
              ...values,
            },
            clientType: ClientType.relative,
          },
        },
      },
    });

    setSubmitting(false);
    try {
      update();
    } catch (error) {
      console.log(error);
    }
    setShowForm(false);
  };

  const initialValues: CreatePersonFormProps = {
    firstName: "",
    lastName: "",
    gender: "unknown",
    initialAge: undefined,
  };

  const hasRelations = !!relations && relations.length > 0;

  return !showForm && hasRelations ? (
    <PersonList
      backAction={backAction}
      relations={relations}
      openForm={setShowForm}
      connected={connectedServiceClient}
      deleteSC={deleteServiceClient}
      createSC={createWithClient}
      callback={update}
      multiple={serviceMetaAttribute.multiple}
      required={serviceMetaAttribute.required}
      next={next}
      isReloading={props.isReloading}
    />
  ) : (
    <Formik validationSchema={PersonValidation} initialValues={initialValues} onSubmit={onSubmit}>
      {props => (
        <NestedClientForm
          {...props}
          cancel={setShowForm}
          showCancel={hasRelations}
          backAction={backAction}
        />
      )}
    </Formik>
  );
}
