import React, { FC } from "react";

import { FormikValues, FormikActions } from "formik";
import { useSnackbar } from "notistack";

import {
  useUpdateConversationReportMutation,
  Topic,
  ReportState,
} from "../../../generated/graphql";
import MemoReportFormStep1, { TReportUpdateFormStep1 } from "../MemoReportFormStep1";
import Notification from "../../molecules/Notification";

const ReportUpdateFormStep1: FC<TReportUpdateFormStep1> = ({ person, report, customCallBack }) => {
  const [updateReport] = useUpdateConversationReportMutation();
  const { enqueueSnackbar } = useSnackbar();
  const client = person.client;

  const { id, personActivity, topic } = report;

  const onSubmit = async (
    values: FormikValues,
    { setSubmitting }: FormikActions<FormikValues>,
    topic: Topic,
  ) => {
    if (values.start.valueOf() > values.end.valueOf()) {
      enqueueSnackbar("", {
        content: (key: string) => (
          <Notification
            id={key}
            message={
              <>
                Das End-Datum liegt vor dem Start-Datum,
                <br />
                bitte Eingaben korrigieren.
              </>
            }
          />
        ),
      });
      setSubmitting(false);
      return;
    }

    if (client && client.id) {
      await updateReport({
        variables: {
          report: {
            id,
            status: ReportState.created,
            clientId: client.id,
            topic: { id: topic.id, name: topic.name },
            personActivity: {
              id: personActivity && personActivity.id,
              activityType: values.type,
              end: values.end,
              start: values.start,
            },
          },
        },
      });

      customCallBack();
    }

    setSubmitting(false);
  };

  return (
    <MemoReportFormStep1
      initialTopic={topic}
      onSubmit={onSubmit}
      person={person}
      personActivity={personActivity}
    />
  );
};
export default ReportUpdateFormStep1;
