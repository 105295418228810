import Grid from "@material-ui/core/Grid";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useParams } from "react-router-dom";
import {
    ServiceResearchObject,
    useBreadCrumbInfoByClientIdQuery
} from "../../../generated/graphql";
import { useServiceSubscription } from "../../../hooks/useServiceSubscription";
import Loader from "../../atoms/Loading";
import ErrorFallback from "../../molecules/ErrorFallback/ErrorFallback";
import ServiceTemplate from "../../templates/Service";
import Research from "./Research";

export default function ServiceDetails() {
  const { serviceId } = useParams<{ serviceId: string }>();
  const { service, loading, refetch } = useServiceSubscription(parseInt(serviceId, 10));
  const clientId = service?.client?.id;
  const { data: breadcrumbData } = useBreadCrumbInfoByClientIdQuery({
    skip: !clientId,
    variables: {
      id: clientId!,
    },
  });

  if (loading) {
    return <Loader />;
  }

  if (!service) {
    return (
      <div>
        <div>Service wurde nicht gefunden</div>
      </div>
    );
  }

  const person = service?.client?.person;

  if (!person || !person.id) {
    return (
      <div>
        <div>Person wurde nicht gefunden</div>
      </div>
    );
  }
  const namePathMap = [
    {
      // @ts-ignore
      name: service.topic.name,
      // @ts-ignore
      path: `/person/${person.id}/topics/${service.topic.id}`,
    },
    {
      // @ts-ignore
      name: service.serviceMeta.name,
      path: `/service/${service.id}`,
    },
  ];
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ServiceTemplate
        service={service}
        person={person}
        refetchService={refetch}
        mapPathList={namePathMap}
        contractExpirationDate={breadcrumbData?.breadCrumbInfoByClientId?.contractEnd}
        language={breadcrumbData?.breadCrumbInfoByClientId?.language || ""}
      >
        {(serviceResearchObjects: ServiceResearchObject[]) => {
          return (
            <Grid item xs={9} xl={7}>
              <Research serviceResearchObjects={serviceResearchObjects} service={service} />
            </Grid>
          );
        }}
      </ServiceTemplate>
    </ErrorBoundary>
  );
}
