import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    smallOutlinedChip: {
      color: theme.palette.text.secondary,
      cursor: "inherit",
      fontSize: 12,
      fontWeight: theme.typography.fontWeightRegular,
      letterSpacing: "0.05em",
      lineHeight: 1,
      "&.outlined": {
        border: "1px solid #bdbdbd",
      },
    },
  };
});

export default useStyles;
