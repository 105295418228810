import React, { useState } from "react";
import { ExecutionResult } from "graphql";
import { pathOr } from "ramda";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useMutation } from "@apollo/client";
import AccessTime from "@material-ui/icons/AccessTime";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import dayjs from "dayjs";
import DayjsUtils from "@date-io/dayjs";
import sortBy from "lodash/sortBy";
import Typography from "@material-ui/core/Typography";

import PrimaryButton from "../../../atoms/PrimaryButton";
import { Service, Person, UpdateHourDocument, Hour } from "../../../../generated/graphql";

import useStyles from "../styles";
import WizardNavigation from "../../../molecules/WizardNavigation";
import SecondaryButton from "../../../atoms/SecondaryButton";

import DayEntry from "./DayEntry";
import TimeDialog from "./TimeDialog";

interface TimesSelectorProps {
  backAction: () => void;
  isReloading: boolean;
  person: Person;
  service: Service;
  serviceMetaAttribute: any;
  setStep: (step: number) => void;
  step: number;
  update: () => void;
}

export default function TimesSelector({
  backAction,
  isReloading,
  service,
  serviceMetaAttribute,
  setStep,
  step,
  update,
}: TimesSelectorProps) {
  const classes = useStyles();
  const next = () => {
    setStep(step + 1);
  };

  const hours: Hour[] = sortBy(pathOr([], ["hours"], service), ["day", "open"]);

  const defaultHour = hours.length
    ? {
        open: dayjs(hours[0].open),
        close: dayjs(hours[0].close),
      }
    : {
        open: dayjs()
          .startOf("day")
          .add(8, "hour"),
        close: dayjs()
          .startOf("day")
          .add(14, "hour"),
      };
  const [open, setOpen] = useState(defaultHour.open);
  const [close, setClose] = useState(defaultHour.close);
  const [showOpen, setShowOpen] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [updateHour] = useMutation(UpdateHourDocument);

  const handleUpdateOpen = (date: any) => {
    const hoursToUpdate = hours.filter(
      hour => dayjs(hour.open).isSame(open) && dayjs(hour.close).isSame(close),
    );
    const promises: Promise<ExecutionResult<any>>[] = [];
    hoursToUpdate.forEach(hour => {
      promises.push(
        updateHour({
          variables: {
            hour: {
              id: hour.id,
              open: date,
            },
          },
        }),
      );
    });
    Promise.all(promises).then(() => {
      setOpen(date);
      try {
        update();
      } catch (error) {
        console.log(error);
      }
    });
  };
  const handleUpdateClose = (date: any) => {
    const hoursToUpdate = hours.filter(
      hour => dayjs(hour.open).isSame(open) && dayjs(hour.close).isSame(close),
    );
    const promises: Promise<ExecutionResult<any>>[] = [];
    hoursToUpdate.forEach(hour => {
      promises.push(
        updateHour({
          variables: {
            hour: {
              id: hour.id,
              close: date,
            },
          },
        }),
      );
    });
    Promise.all(promises).then(() => {
      setClose(date);
      try {
        update();
      } catch (error) {
        console.log(error);
      }
    });
  };

  const renderDays = () => {
    const days = [];
    for (let index = 0; index < 7; index++) {
      const dayHours: Hour[] = hours.filter(h => h.day === index);

      days.push(
        <DayEntry
          isReloading={isReloading}
          update={update}
          day={index}
          key={`day${index}`}
          hours={dayHours}
          defaultOpen={open}
          defaultClose={close}
          service={service}
        />,
      );
    }
    return days;
  };

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils} locale="de">
      <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
        <Box mb={5} display="flex" alignItems="center" width={600} justifyContent="center">
          <AccessTime color="primary" className={classes.icon} />{" "}
          <Typography variant="body2">Zeitraum</Typography>
          <Typography variant="body2" className={classes.leftSpacing}>
            <Link onClick={() => setShowOpen(true)} variant="h4" underline="always" color="primary">
              {open.format("H:mm")}
            </Link>{" "}
            bis{" "}
            <Link
              onClick={() => setShowClose(true)}
              variant="h4"
              underline="always"
              color="primary"
            >
              {close.format("H:mm")}
            </Link>{" "}
            Uhr
          </Typography>
        </Box>
        {renderDays()}
      </Box>
      <TimeDialog
        handleClose={() => {
          setShowOpen(false);
          setShowClose(true);
        }}
        handleOpen={() => setShowOpen(true)}
        handleChange={setOpen}
        handleDone={handleUpdateOpen}
        label="Von"
        show={showOpen}
        value={open}
      />
      <TimeDialog
        handleClose={() => setShowClose(false)}
        handleOpen={() => setShowClose(true)}
        handleChange={setClose}
        handleDone={handleUpdateClose}
        label="Bis"
        show={showClose}
        value={close}
      />
      <WizardNavigation>
        <SecondaryButton onClick={() => backAction()}>Zurück</SecondaryButton>
        <PrimaryButton
          onClick={next}
          disabled={serviceMetaAttribute.required && hours.length === 0}
        >
          Weiter
        </PrimaryButton>
      </WizardNavigation>
    </MuiPickersUtilsProvider>
  );
}
