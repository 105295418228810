import { useEffect } from "react";
import {
  useActiveActivitiesQuery,
  ActiveActivitiesSubscriptionDocument,
  ActiveActivitiesSubscriptionSubscription,
  PersonActivity,
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

export const useActiveActivitiesSubscription = () => {
  const { data, loading, subscribeToMore } = useActiveActivitiesQuery({
    fetchPolicy,
  });
  useEffect(() => {
    const unsubscribe = subscribeToMore<ActiveActivitiesSubscriptionSubscription>({
      document: ActiveActivitiesSubscriptionDocument,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !prev) return prev;
        const activeActivities = subscriptionData.data.activeActivitiesSubscription;
        return { ...prev, activeActivities };
      },
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { activities: (data?.activeActivities || []) as PersonActivity[], loading };
};
