import React, { useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import Typography from "@material-ui/core/Typography";
import {
  Person,
  Topic,
  PersonActivity,
  useCreateConversationReportMutation,
  useCreateClientMemoMutation,
  ReportState,
} from "../../../generated/graphql";
import ReportForm from "../ReportForm";
import Notification from "../../molecules/Notification";
import useStyles from "../reportStyles";

export type TReportCreateForm = {
  person: Person;
  activity?: PersonActivity;
  refetch: () => void;
};

export const ReportCreateForm = ({ person, activity, refetch }: TReportCreateForm) => {
  const classes = useStyles();
  const { replace } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [createConversationReport] = useCreateConversationReportMutation();
  const [createClientMemo] = useCreateClientMemoMutation();
  const [contact, setContact] = useState<Person | { name: string; id?: never } | undefined>(person);
  const topics = person.client?.topics || [];
  // @ts-ignore
  const retTopic = topics && topics.length > 0 && topics[0];
  const iniTopic =
    activity && activity.topic
      ? { name: activity.topic.name, id: activity.topic.id }
      : retTopic || { name: "", id: null };
  const [topic, setTopic] = useState<Topic>(iniTopic);
  const client = person.client ? person.client : null;

  return (
    <Fragment>
      <Typography variant="h1" className={classes.headline}>
        Gesprächsprotokoll oder Gesprächsnotiz zu {person.name!} erstellen
      </Typography>
      <Formik
        validationSchema={{}}
        initialValues={{
          isReport: true,
          start: activity ? dayjs(activity.start) : dayjs().subtract(20, "minute"),
          end: !!activity?.end ? dayjs(activity.end) : dayjs(),
          type: activity ? activity.activityType : "callIn",
          // topic: { id: topic.id, name: topic.name },
        }}
        onSubmit={async (values, { setError, setSubmitting }) => {
          const personActivity = {
            id: activity?.id,
            activityType: values.type,
            end: values.end,
            start: values.start,
          };
          let pathname;

          if (values.start.valueOf() > values.end.valueOf()) {
            enqueueSnackbar("", {
              content: (key: string) => (
                <Notification
                  id={key}
                  message={
                    <>
                      Das End-Datum liegt vor dem Start-Datum,
                      <br />
                      bitte Eingaben korrigieren.
                    </>
                  }
                />
              ),
            });
            setSubmitting(false);
            return;
          }

          if (values.isReport) {
            const { data } = await createConversationReport({
              variables: {
                report: {
                  clientId: client && client.id,
                  topic: { id: topic.id, name: topic.name },
                  status: ReportState.issued,
                  personActivity,
                },
              },
            });

            if (data?.createConversationReport?.id) {
              pathname = `/person/${person.id}/report/${data.createConversationReport.id}`;
            } else {
              setError({});
            }
          } else {
            const { data } = await createClientMemo({
              variables: {
                clientId: client.id!,
                memo: {
                  personId: contact && contact.id,
                  topic: { id: topic.id, name: topic.name },
                  contact: contact ? contact.name! : person.name!,
                  personActivity,
                },
              },
            });

            if (data?.createClientMemo?.id) {
              pathname = `/person/${person.id}/memo/${data.createClientMemo.id}`;
            } else {
              setError({});
            }
          }

          setSubmitting(false);
          refetch();
          replace({
            pathname,
            state: { activity },
          });
        }}
      >
        {formProps => {
          return (
            <ReportForm
              {...formProps}
              isCreating
              person={person}
              topic={topic}
              setTopic={setTopic}
              setContact={setContact}
            />
          );
        }}
      </Formik>
    </Fragment>
  );
};
