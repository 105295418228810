import { useEffect } from "react";
import { fetchPolicy } from "../utils/constants";

import {
  AppointmentsByDayQueryVariables,
  useAppointmentsByDayQuery,
  Appointment,
  AppointmentDailySubscriptionDocument,
  AppointmentDailySubscriptionSubscription,
} from "../generated/graphql";

export const useAppointmentsByDaySubscription = (variables: AppointmentsByDayQueryVariables) => {
  const { data, loading, subscribeToMore } = useAppointmentsByDayQuery({ variables, fetchPolicy });

  useEffect(
    () => {
      const unsubscribe = subscribeToMore<AppointmentDailySubscriptionSubscription>({
        document: AppointmentDailySubscriptionDocument,
        variables: { ...variables },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data || !prev) return prev;
          return Object.assign({}, prev, {
            appointmentsByDay: subscriptionData?.data?.appointmentDailySubscription,
          });
        },
      });
      return unsubscribe;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variables],
  );

  return { appointments: (data?.appointmentsByDay || []) as Appointment[], loading };
};
