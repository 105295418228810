import React from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import SecondaryButton from "../../../atoms/SecondaryButton";
import PrimaryButton from "../../../atoms/PrimaryButton";
import emptyAlternative from "../../../assets/emptyAlternative.svg";

import { usePersonCreateContext } from "../provider";

type TAccountConfirm = {
  updatePerson: (values: {
    personId: number;
    accountId?: number | null;
    assistanceId?: string;
  }) => Promise<void>;
};

const AccountConfirm = ({ updatePerson }: TAccountConfirm) => {
  const { account, assistanceId, name, personActivity, resetAccount } = usePersonCreateContext();

  const submit = async () => {
    // Due to refactoring: https://medium.com/@calebmer/when-to-use-graphql-non-null-fields-4059337f6fc8
    const id = personActivity?.person?.id;

    if (id) {
      await updatePerson({ personId: id, accountId: account?.id, assistanceId });
    }
  };

  return (
    <>
      <Box width={1} flex={1} textAlign="center" display="flex" flexDirection="column" mb={5}>
        <Box mb={5}>
          <Typography variant="h1" align="center">
            {name}
            {account ? (
              <>
                ,<br /> {account?.name} anlegen?
              </>
            ) : (
              <> anlegen</>
            )}
          </Typography>
        </Box>
        <Box display="flex" flex={1} justifyContent="center" alignItems="center">
          <img src={emptyAlternative} alt="Klient anlegen" />
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <SecondaryButton onClick={resetAccount}>Zurück</SecondaryButton>
        <Box mx={1} />
        <PrimaryButton onClick={submit}>Anlegen</PrimaryButton>
      </Box>
    </>
  );
};
export default AccountConfirm;
