import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const MiminizeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M5 13H19" stroke="#025879" strokeWidth="2" />
      <rect x="0.5" y="0.5" width="23" height="23" stroke="#025879" fill="transparent" />
    </SvgIcon>
  );
};

export default MiminizeIcon;
