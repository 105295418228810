import { PhoneType } from "../generated/graphql";

export default function translatePhoneType(type: PhoneType) {
  switch (type) {
    case PhoneType.private:
      return "privat";
    case PhoneType.fax:
      return "fax";
    case PhoneType.mobile:
      return "mobil";
    case PhoneType.work:
    case PhoneType.mobilework:
      return "beruflich";
    default:
      return "privat";
  }
}
