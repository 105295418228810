import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import config from "../../../config";
import {
  MailClassificationType,
  Topic,
  useCreateGMailMutation,
  useTopicsFromPersonQuery,
} from "../../../generated/graphql";
import { useAccountSubscription } from "../../../hooks/useAccountSubscription";
import usePersonSubscription from "../../../hooks/usePersonSubscription";
import useSessionStorage from "../../../hooks/useSessionStorage";
import { useAuthorization } from "../../../providers/AuthorizationProvider";
import emailIllustration from "../../assets/emailIllustration.svg";
import errorIllustration from "../../assets/errorIllustration.svg";
import Loader from "../../atoms/Loading";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import { EmailMessageContainer } from "../../molecules/EmailMessage";
import PersonHeader from "../../molecules/PersonHeader";
import SlateRTE, { initialFormValue, serializeHtml } from "../../molecules/SlateRTE";
import TopicSelectOrCreate from "../../molecules/TopicSelectOrCreate";
import IllustratedErrorMessage from "../../organisms/IllustratedErrorMessage";

const EMAIL_DRAFT_PREFIX = "email_draft_person_";

interface EmailDraft {
  fromAddress: string;
  subject: string;
  classification: string;
  body: any;
  threadId: string;
}

const EmailSendWpoNew: FC = () => {
  const { me } = useAuthorization();
  const { personId } = useParams<{ personId: string }>();
  const { state }: { state: { topicId: string; serviceId: number } } = useLocation();
  const { goBack } = useHistory();
  const { person, loading: personLoading, error: personError } = usePersonSubscription(
    parseInt(personId, 10),
  );

  useEffect(() => {
    if (draftEmail) {
      setFromAddress(draftEmail.fromAddress || "");
      setSubject(draftEmail.subject || "");
      setBody(draftEmail.body || initialFormValue);
    }
  }, []);

  const [createGMail] = useCreateGMailMutation();
  const { data: topicsData, error: topicError, loading: topicLoading } = useTopicsFromPersonQuery({
    variables: { personId: parseInt(personId, 10) },
  });

  const topics = topicsData?.topicsFromPerson;
  const [topic, setTopic] = useState<Topic | undefined>(
    topics?.find(topic => topic.id === parseInt(state?.topicId || "-1")),
  );
  const fromAddresses = config.inboxes;
  const [fromAddress, setFromAddress] = useState("");
  const [subject, setSubject] = useState("");
  const [classification, setClassification] = useState("");
  const [body, setBody] = useState(initialFormValue);
  const [submitting, setSubmitting] = useState(false);
  const [draftEmail, setDraftEmail, clearValue] = useSessionStorage(
    `${EMAIL_DRAFT_PREFIX}${personId}`,
    {} as EmailDraft,
  );

  useEffect(() => {
    setDraftEmail({
      ...draftEmail,
      body,
    });
  }, [body]);

  useEffect(() => {
    if (fromAddresses.length) {
      setFromAddress(fromAddresses[0]);
    }
  }, [fromAddresses]);

  if (personLoading || topicLoading) {
    return <Loader />;
  }

  if (!me || !person || !me || !me.user || topicError || personError) {
    if (topicError || personError) {
      console.error(topicError || personError);
    }

    return (
      <IllustratedErrorMessage
        messages={["Fehler beim Laden der Daten"]}
        illustration={errorIllustration}
        illustrationAltText="Elli rutscht auf einer Banane aus"
      >
        <PrimaryButton onClick={goBack}>Zurück</PrimaryButton>
      </IllustratedErrorMessage>
    );
  }

  const user = {
    id: me.user.id,
    papershiftWorkingAreas: me.papershiftWorkingAreas,
    person: {
      ...me,
    },
  };

  //   const email = person.emails?.find(email => email?.contactAllowed);

  //   if (!email) {
  //     return null;
  //   }

  const onSubmit = async () => {
    setSubmitting(true);

    await createGMail({
      variables: {
        classification,
        sender: fromAddress,
        personId: config.wpoPersonId as number,
        message: serializeHtml(JSON.parse(body)),
        subject,
        topicId: topic?.id,
      },
    });
    setSubmitting(false);
    clearValue(`${EMAIL_DRAFT_PREFIX}${personId}`);
    goBack();
  };

  return (
    <>
      <PersonHeader
        person={person}
        contractExpirationDate={person.client?.account?.contractEnd}
        mapList={[
          {
            path: "/",
            name: "E-Mail",
          },
        ]}
      />
      <Grid container style={{ marginTop: "192px" }}>
        <Grid item xs={3}></Grid>

        <Grid
          item
          container
          xs={6}
          direction="column"
          alignItems="center"
          style={{ display: "flex" }}
        >
          <img src={emailIllustration} alt="" />
          <Box mt={4} py="3px">
            <Typography variant="h2">E-Mail an WPO</Typography>
          </Box>
          {topics && topics.length > 0 && (
            <Box py="3px">
              <Typography variant="h2">
                Zum{" "}
                <TopicSelectOrCreate
                  allowCreate={false}
                  topics={topics}
                  selectAction={topic => setTopic(topic)}
                  topic={topic}
                />
              </Typography>
            </Box>
          )}
          <Box my={1} />
          <Typography variant="body2">E-Mail: {config.inboxesWpo}</Typography>
        </Grid>

        <Grid item xs={3}></Grid>
      </Grid>

      <Grid container>
        <Grid item xs={3}></Grid>

        <Grid item xs={6}>
          <EmailMessageContainer isClient={false} user={user}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Absender</InputLabel>
              <Select value={fromAddress}>
                {fromAddresses.map((name, index) => (
                  <MenuItem
                    key={name}
                    value={name}
                    onClick={() => {
                      setFromAddress(name);
                      setDraftEmail({
                        ...draftEmail,
                        fromAddress: name,
                      });
                    }}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              inputProps={{
                maxLength: 256,
              }}
              fullWidth
              value={subject}
              onChange={event => {
                setSubject(event.target.value);
                setDraftEmail({
                  ...draftEmail,
                  subject: event.target.value,
                });
              }}
              label="Betreff"
              margin="normal"
            />
            <SlateRTE label="Nachricht" value={body} handleChange={setBody} />
            <Box mt={1}>
              <FormControl fullWidth margin="normal" component="fieldset">
                <FormLabel component="legend">Diese Mail ist:</FormLabel>
                <RadioGroup
                  row
                  aria-label="classification"
                  name="classification"
                  value={classification}
                  onChange={e => setClassification(e.target.value)}
                >
                  <FormControlLabel
                    value={MailClassificationType.counseling}
                    control={<Radio />}
                    label="Eine Beratung"
                  />
                  <FormControlLabel
                    value={MailClassificationType.support}
                    control={<Radio />}
                    label="Beratungsunterstützend"
                  />
                  <FormControlLabel
                    value={MailClassificationType.service}
                    control={<Radio />}
                    label="Serviceorientiert"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box mt={1}>
              <Typography variant="body2" style={{ fontStyle: "italic" }} color="secondary">
                Die Signatur wird automatisch hinzugefügt.
              </Typography>
            </Box>
          </EmailMessageContainer>

          <Box display="flex" justifyContent="center" mt={4}>
            <SecondaryButton
              onClick={() => {
                goBack();
                clearValue(`${EMAIL_DRAFT_PREFIX}${personId}`);
              }}
            >
              Abbrechen
            </SecondaryButton>
            <Box mx={1} />
            <PrimaryButton
              disabled={
                submitting ||
                !JSON.parse(body)[0]["children"][0]["text"].trim().length ||
                !fromAddress ||
                !subject ||
                !classification ||
                (!topic?.id && !!person.client)
              }
              onClick={onSubmit}
            >
              Absenden
            </PrimaryButton>
          </Box>
        </Grid>

        <Grid item xs={3}></Grid>
      </Grid>
    </>
  );
};

export default EmailSendWpoNew;
