import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Service } from "../../../../generated/graphql";
import SecondaryButton from "../../../atoms/SecondaryButton";
import PrimaryButton from "../../../atoms/PrimaryButton";
import WizardNavigation from "../../../molecules/WizardNavigation";

type TPagination = {
  currentId?: number | null;
  service: Service;
  isSubmitting?: boolean;
};
const Pagination = ({ isSubmitting, service, currentId }: TPagination) => {
  const { push } = useHistory();
  const { state } = useLocation();

  const index = !!state
    ? // @ts-ignore
      (state as number[]).findIndex((id: number) => id === currentId)
    : -1;
  // @ts-ignore
  const next = !state || index >= state.length - 1 ? undefined : state[index + 1];
  // @ts-ignore
  const previous = !state || index < 1 ? undefined : state[index - 1];

  return (
    <WizardNavigation>
      {previous && (
        <SecondaryButton
          disabled={isSubmitting}
          onClick={() => push(`/service/${service.id}/research/${previous}`, state)}
        >
          Vorheriges
        </SecondaryButton>
      )}
      <PrimaryButton onClick={() => push(`/service/${service.id}`)}>
        Rechercheelement schließen
      </PrimaryButton>
      {next && (
        <SecondaryButton
          disabled={isSubmitting}
          onClick={() => push(`/service/${service.id}/research/${next}`, state)}
        >
          Nächstes
        </SecondaryButton>
      )}
    </WizardNavigation>
  );
};
export default Pagination;
