import React, { FC } from "react";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";

import { Field } from "formik";
import { TextField } from "formik-material-ui";

import GenderSelect from "../../molecules/GenderSelect";

const PersonBaseFields: FC<{ withAlias?: boolean }> = ({ withAlias = true }) => {
  return (
    <Box mb={3} width="100%">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field name="firstName" label="Vorname" component={TextField} autoFocus />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field name="lastName" label="Nachname" component={TextField} />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field name="title" label="Akademischer Titel" component={TextField} />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <GenderSelect />
        </Grid>
      </Grid>
      {withAlias && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <Field name="alias" label="Alias" component={TextField} />
            </FormControl>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
export default PersonBaseFields;
