import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const drawerWidth = 280;
const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawerListItem: {
    paddingLeft: theme.spacing(3),
  },
}));

export default useStyles;
