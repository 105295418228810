import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { Alert, AlertTitle } from "@material-ui/lab";
import Loader from "../../atoms/Loading";
import StatisticsForm from "../../forms/StatisticsForm";
import StatisticsByTypeForm from "../../forms/StatisticsForm/StatisticByTypeForm";

import {
  useAccountStatisticsLazyQuery,
  useAccountStatisticsByTypeLazyQuery,
  useMonthlyLifeWorksStatisticsLazyQuery,
  useComplaintsStatisticsByTypeLazyQuery,
  useComplaintsStatisticsForBusinessLazyQuery,
} from "../../../generated/graphql";
import MonthlyStatisticForm from "../../forms/StatisticsForm/MonthlyStatisticForm";
import ComplaintStatisticForm from "../../forms/StatisticsForm/ComplaintStatisticForm";
import ComplaintStatisticFormForBusiness from "../../forms/StatisticsForm/ComplaintStatisticFormForBusiness";

export default function Statistics() {
  const [currentQuery, setCurrentQuery] = useState<string | undefined>();

  const [
    query,
    { data: accountData, loading: accountLoading, called: accountCalled, error: accountError },
  ] = useAccountStatisticsLazyQuery({
    fetchPolicy: "network-only",
  });

  const [
    typeQuery,
    { data: typeData, loading: typeLoading, called: typeCalled, error: typeError },
  ] = useAccountStatisticsByTypeLazyQuery({});

  const [
    lifeWorksQuery,
    {
      data: lifeWorksData,
      loading: lifeWorksLoading,
      called: lifeWorksCalled,
      error: lifeWorksError,
    },
  ] = useMonthlyLifeWorksStatisticsLazyQuery({});

  const [
    complaintsQuery,
    {
      data: complaintsData,
      loading: complaintsLoading,
      called: complaintsCalled,
      error: complaintsError,
    },
  ] = useComplaintsStatisticsByTypeLazyQuery({});

  const [
    complaintsForBusinessQuery,
    {
      data: complaintsForBusinessData,
      loading: complaintsForBusinessLoading,
      called: complaintsForBusinessCalled,
      error: complaintsForBusinessError,
    },
  ] = useComplaintsStatisticsForBusinessLazyQuery({});

  

  const error = accountError || typeError;
  const loading = accountLoading || typeLoading;
  const called = accountCalled || typeCalled;

  const fileIds: { [key: string]: string | undefined | null } = {
    account: accountData?.accountStatistics?.fileId,
    type: typeData?.accountStatisticsByType?.fileId,
    lifeworks: lifeWorksData?.monthlyLifeWorksStatistics?.fileId,
    complaints: complaintsData?.complaintsStatisticsByType?.fileId,
    complaintsForBusiness: complaintsForBusinessData?.complaintsStatisticsForBusiness?.fileId,
  };  

  const fileId = currentQuery ? fileIds[currentQuery] : undefined;

  return (
    <Box display="flex" justifyContent="center" mt={11} mb={5}>
      <Box width={1400}>
        <StatisticsByTypeForm query={typeQuery} setQuery={setCurrentQuery} disabled={loading} />
        <StatisticsForm query={query} setQuery={setCurrentQuery} disabled={loading} />
        <MonthlyStatisticForm query={lifeWorksQuery} setQuery={setCurrentQuery} disabled={lifeWorksLoading} />
        <ComplaintStatisticForm query={complaintsQuery} setQuery={setCurrentQuery} disabled={complaintsLoading} />
        <ComplaintStatisticFormForBusiness query={complaintsForBusinessQuery} setQuery={setCurrentQuery} disabled={complaintsLoading} />
        

        {called && loading && <Loader />}
        {lifeWorksCalled && lifeWorksLoading && <Loader />}
        {complaintsCalled && complaintsLoading && <Loader />}
        {complaintsForBusinessCalled && complaintsForBusinessLoading && <Loader />}
        

        {error !== undefined ? (
          <Box mx={"auto"} mt={5} width={700}>
            <Alert severity="error">
              <AlertTitle>Es ist ein Fehler aufgetreten</AlertTitle>
              {error.message}
            </Alert>
          </Box>
        ) : null}

        {lifeWorksError !== undefined ? (
          <Box mx={"auto"} mt={5} width={700}>
            <Alert severity="error">
              <AlertTitle>Es ist ein Fehler aufgetreten</AlertTitle>
              {lifeWorksError.message}
            </Alert>
          </Box>
        ) : null}

        {!!fileId && (
          <Box mt={5} textAlign="center">
            <Typography variant="body1">
              Google Spreadsheet wird generiert,{" "}
              <Link href={`https://docs.google.com/spreadsheets/d/${fileId}/edit`} target="blank">
                jetzt öffnen.
              </Link>
            </Typography>
          </Box>
        )}


      </Box>
    </Box>
  );
}
