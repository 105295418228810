import React from "react";
import { FormikProps, FormikValues } from "formik";
import { useSnackbar } from "notistack";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";

import PrimaryButton from "../../atoms/PrimaryButton";
import WizardNavigation from "../../molecules/WizardNavigation";
import Loader from "../../atoms/Loading";
import SecondaryButton from "../../atoms/SecondaryButton";
import Card from "../../molecules/Card";
import useStyles from "../reportStyles";

import {
  useStatisticGroupsQuery,
  useToggleReportStatisticMutation,
  StatisticGroupTypes,
  StatisticItem,
  ConversationReport,
} from "../../../generated/graphql";
import { useStatisticItemsByReportSubscription } from "../../../hooks/useStatisticItemsByReportSubscription";
import IllustratedErrorMessage from "../../organisms/IllustratedErrorMessage";

import errorIllustration from "../../assets/errorIllustration.svg";
import helperIllu1 from "../../assets/stupidHelper1.svg";
import helperIllu2 from "../../assets/stupidHelper2.svg";

export interface IFormProps {
  type: StatisticGroupTypes;
  report: ConversationReport;
  backAction: () => void;
}

function StatItem({
  item,
  selected,
  toggle,
}: {
  item: StatisticItem;
  selected: boolean;
  toggle?: () => void;
}) {
  const classes = useStyles();  
  return (
    <Box
      display="inline-block"
      width={"100%"}
      style={{ cursor: "pointer" }}
      key={`${item.id}-stat-item`}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={selected}            
            classes={{
              colorSecondary: classes.checkboxColorSecondary,
              checked: classes.checkboxColorSecondaryChecked,
            }}
            onChange={toggle}
          />
        }
        label={item.name}
      />
    </Box>
  );
}

function StupidLittleHelper({ type }: { type: StatisticGroupTypes }) {
  return (
    <>
      <Box maxWidth={280}>
        <Typography variant="h3">
          {type === StatisticGroupTypes.work
            ? "Bitte hilf dem verzweifelten Statistiker ein paar Daten zu sammeln!"
            : "Ohne ein paar Daten geht’s ihm wirklich schlecht..."}
        </Typography>
      </Box>
      <Box mt={2}>
        <img src={type === StatisticGroupTypes.work ? helperIllu1 : helperIllu2} alt="warum" />
      </Box>
    </>
  );
}

export default function StepStats({
  backAction,
  handleSubmit,
  isSubmitting,
  report,
  type,
}: FormikProps<FormikValues> & IFormProps) {
  const classes = useStyles();
  const connectedStats = useStatisticItemsByReportSubscription(report.id);
  const [toggle] = useToggleReportStatisticMutation();
  const { data, loading, error } = useStatisticGroupsQuery({
    variables: { type, clientId: report!.client!.id! },
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    const key = enqueueSnackbar("", {
      content: (key: string) => (
        <Box mb={6} p={3} style={{ background: "#F9F3E9CC" }} key={key}>
          <StupidLittleHelper type={type} />
        </Box>
      ),
    });

    return () => {
      closeSnackbar(key);
    };
  }, [type, enqueueSnackbar, closeSnackbar]);

  const groups = data?.statisticGroups;

  if (loading) {
    return <Loader />;
  }

  if (error || !groups) {
    return (
      <IllustratedErrorMessage
        messages={["Die Statistik-Optionen konnten nicht geladen werden."]}
        illustration={errorIllustration}
        illustrationAltText="Elli rutscht auf einer Banane aus"
      >
        <PrimaryButton onClick={backAction}>Zurück</PrimaryButton>
      </IllustratedErrorMessage>
    );
  }

  const GROUP_COLORS = ["#EFE8DD", "#F7E9D5", "#FBF5C3", "#F1EED6", "#EEF1E4", "#E8F3F1", "#E8F3F1"];

  return (
    <form onSubmit={handleSubmit} className={classes.contentLeft}>      
      <Box mt={3} width="100%" style={{ columns: type === StatisticGroupTypes.work ? 3 : 4, columnGap: 24}}>
        {groups.map((group, idx) => (
          <Box
            key={`stepbox-${group.id}`}
            px={2}
            pt={1}
            pb={1}
            mb={3}
            style={{ background: GROUP_COLORS[idx % GROUP_COLORS.length] }}
          >
            <Card title={group.name}>
              {group.items.map(item => (
                <StatItem
                  item={item}
                  key={`stat-item-${type}-${item.id}`}                  
                  selected={connectedStats.findIndex(i => i && i.id === item.id) > -1}
                  toggle={() => toggle({ variables: { reportId: report.id, itemId: item.id } })}
                />
              ))}
            </Card>
          </Box>
        ))}
      </Box>

      <WizardNavigation>
        <SecondaryButton onClick={backAction} tabIndex={0}>
          Zurück
        </SecondaryButton>
        <PrimaryButton
          type="submit"
          disabled={
            isSubmitting || (type === StatisticGroupTypes.private && !connectedStats.length)
          }
          tabIndex={0}
        >
          Weiter
        </PrimaryButton>
      </WizardNavigation>
    </form>
  );
}
