import React from "react";
import { components } from "react-select";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { Theme } from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import PersonTypeIcon, { PersonTypes } from "../../atoms/PersonTypeIcon";
import AccountStatusBadge from "../AccountStatusBadge";

export type OptionValue = {
  label: string;
  value?: number | null;
  type: PersonTypes;
  name: string;
  path: string;
  expirationDate?: string | null;
};

export default makeStyles((theme: Theme) => ({
  input: {
    color: `${theme.palette.primary.main} !important`,
    display: "flex",
    height: 32,
    padding: 0,
  },
  valueContainer: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexWrap: "wrap" as "wrap",
    overflow: "hidden",
    "&>div": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  option: {
    color: theme.palette.primary.main,
    height: "auto",
    minHeight: 40,
    textDecorationLine: "underline",
    "&:hover": {
      textDecorationLine: "underline",
    },
  },
  singleValue: {
    padding: "6px 0",
    height: "1.1875em",
    color: `${theme.palette.primary.main} !important`,
  },
  placeholder: {
    position: "absolute" as "absolute",
    margin: 2,
  },
  clearIndicator: {
    cursor: "pointer",
    padding: "0 !important",
  },
  paper: {
    border: "1px solid #bdbdbd",
    boxShadow: "none",
    left: 0,
    position: "absolute" as "absolute",
    overflow: "hidden",
    right: 0,
    zIndex: theme.zIndex.modal + 1,
  },
  searchIconWrapper: {
    cursor: "pointer" as "pointer",
    padding: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px ${theme.spacing(1)}px !important`,
  },
  searchIcon: {
    color: theme.palette.primary.main,
  },
}));

export const useInputClasses = makeStyles((theme: Theme) => ({
  disabled: {},
  underline: {
    "&:after": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    },
    "&:hover:not($disabled):before": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    },
  },
}));

export function NoOptionsMessage(props: any) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
      variant="body2"
    >
      Keinen Eintrag gefunden
    </Typography>
  );
}

export function inputComponent({ inputRef, ...props }: any) {
  return <div ref={inputRef} {...props} />;
}

export function Control(props: any) {
  const inputClasses = useInputClasses();
  return (
    <TextField
      autoFocus
      fullWidth
      autoComplete="off"
      error={props.selectProps.error}
      InputProps={{
        classes: inputClasses,
        disableUnderline: props.menuIsOpen,
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
          autoComplete: "off",
        },
      }}
      InputLabelProps={{ shrink: true }}
      {...props.selectProps.textFieldProps}
    />
  );
}

export function Option(props: any) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      className={props.selectProps.classes.option}
      style={{
        fontWeight: props.isSelected ? 600 : 400,
        backgroundColor: props.isFocused ? "#F8FAFB" : "inherit",
      }}
      {...props.innerProps}
    >
      <Box display="flex" alignItems="center" width="100%" marginRight={2}>
        <Box marginRight={2} height={24}>
          <PersonTypeIcon type={props.data.type} />
        </Box>

        <Typography        
          color="primary"
          variant="body2"
          style={{
            whiteSpace: "pre-wrap",
            pointerEvents: 'none',
          }}
        >
          {props.children}

          <AccountStatusBadge contractEnd={props.data.expirationDate} />
        </Typography>
      </Box>
    </MenuItem>
  );
}

export function Placeholder(props: any) {
  return (
    <Typography
      color="textSecondary"
      variant="body2"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

export function SingleValue(props: any) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
      variant="body2"
    >
      {props.children}
    </Typography>
  );
}

export function ValueContainer(props: any) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

export const DropdownIndicator = (props: any) => {
  return (
    // @ts-ignore
    <components.DropdownIndicator
      className={props.selectProps.classes.searchIconWrapper}
      {...props}
    >
      {props.selectProps.menuIsOpen ? (
        <CloseIcon className={props.selectProps.classes.searchIcon} />
      ) : (
        <Search className={props.selectProps.classes.searchIcon} />
      )}
    </components.DropdownIndicator>
  );
};

export const ClearIndicator = (props: any) => {
  return (
    // @ts-ignore
    <components.ClearIndicator className={props.selectProps.classes.clearIndicator} {...props}>
      <IconButton aria-label="delete" size="small">
        <CloseIcon fontSize="small" color="primary" />
      </IconButton>
    </components.ClearIndicator>
  );
};

export function Menu(props: any) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

export const CustomComponents = {
  Control,
  DropdownIndicator,
  IndicatorSeparator: null,
  ClearIndicator,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};
