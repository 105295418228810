import React, { FC } from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { Select } from "formik-material-ui";
import { Field } from "formik";

import { FamilyStatus } from "../../../generated/graphql";
import { TabIndex } from "../../../types";

const FamilyStatusSelect: FC<TabIndex> = ({ tabIndex = 0 }) => {
  return (
    <FormControl fullWidth margin="normal">
      <InputLabel htmlFor="familyStatus">Beziehungsstatus</InputLabel>
      <Field name="familyStatus" label="Beziehungsstatus" component={Select} tabIndex={tabIndex}>
        <MenuItem value={FamilyStatus.single}>
          {translateFamilyStatus(FamilyStatus.single)}
        </MenuItem>
        <MenuItem value={FamilyStatus.relationship}>
          {translateFamilyStatus(FamilyStatus.relationship)}
        </MenuItem>
        <MenuItem value={FamilyStatus.married}>
          {translateFamilyStatus(FamilyStatus.married)}
        </MenuItem>
        <MenuItem value={FamilyStatus.widowed}>
          {translateFamilyStatus(FamilyStatus.widowed)}
        </MenuItem>
        <MenuItem value={FamilyStatus.unknown}>
          {translateFamilyStatus(FamilyStatus.unknown)}
        </MenuItem>
        <MenuItem value={FamilyStatus.other}>{translateFamilyStatus(FamilyStatus.other)}</MenuItem>
      </Field>
    </FormControl>
  );
};

export const translateFamilyStatus = (familyStatus: FamilyStatus) => {
  switch (familyStatus) {
    case FamilyStatus.unknown:
      return "unbekannt";
    case FamilyStatus.widowed:
      return "verwitwet";
    case FamilyStatus.married:
      return "verheiratet";
    case FamilyStatus.other:
      return "Sonstiges";
    case FamilyStatus.relationship:
      return "in einer Beziehung";
    case FamilyStatus.single:
      return "Single";
    default:
      return "unbekannt";
  }
};

export default FamilyStatusSelect;
