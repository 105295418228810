import React, { ReactNode, FC } from "react";

import AppBar from "@material-ui/core/AppBar/AppBar";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Box from "@material-ui/core/Box";

import useStyles from "./styles";
import EmergencyContactCard from "../../molecules/EmergencyContactCard";
import { PersonActivityQuery, PersonQuery } from "../../../generated/graphql";

interface ISubHeader {
  leading?: ReactNode;
  children: ReactNode;
  actions?: ReactNode;
  isAccountDeactivated?: boolean;
  person?: PersonQuery["person"] | PersonActivityQuery["personActivity"]["person"];
}

const SubHeader: FC<ISubHeader> = props => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.subHeader} elevation={0}>
      <Toolbar className={props.isAccountDeactivated ? classes.deactivated : ""}>
        <Grid container className={classes.subHeaderBorder} alignItems="center">
          <Grid item xs={1}>
            {props.person && <EmergencyContactCard person={props.person} />}
            {props.leading && props.leading}
          </Grid>
          <Grid item xs={10}>
            {props.children}
          </Grid>
          <Grid item xs={1} className={classes.actions}>
            <Box paddingRight={1} display="flex" justifyContent="flex-end">
              {props.actions && props.actions}
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
export default SubHeader;
