import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    width: 32,
    height: 32,
    padding: 5,
    marginRight: theme.spacing(1),
  },
  active: {
    backgroundColor: theme.palette.common.white,
  },
}));

export default useStyles;
