import React from "react";

import { useHistory } from "react-router-dom";

import {
  Person,
  Gender,
  AddressType,
  PhoneType,
  RelationshipType,
  useCreateContactPersonMutation,
} from "../../../generated/graphql";
import DefaultDialog from "../../molecules/DefaultDialog";
import DefaultDialogContent from "../../molecules/DefaultDialogContent";
import ContactPersonForm from "../../forms/ContactPersonForm";

type TContactPersonDialog = { open: boolean; onClose: () => void; person: Person };

export const ContactPersonDialog = ({ open, onClose, person }: TContactPersonDialog) => {
  const { push } = useHistory();
  const [createContactPerson] = useCreateContactPersonMutation();

  const id = person.id;

  if (!id) {
    return null;
  }

  return (
    <DefaultDialog open={open} onClose={onClose}>
      <DefaultDialogContent>
        <ContactPersonForm
          cancel={onClose}
          newEntry
          allowEmergencyContact={
            !(
              person.relations &&
              person.relations.some(relation => relation && relation.isEmergency)
            )
          }
          submit={async values => {
            const contactPerson = await createContactPerson({
              variables: {
                contactPersonInput: {
                  person: {
                    alias: values.alias,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    title: values.title,
                    gender: values.gender,
                    addresses: values.addresses,
                    phones: values.phones,
                  },
                  activePersonId: id,
                  relation: values.relation,
                },
              },
            });

            onClose();

            if (contactPerson.data && contactPerson.data.createContactPerson) {
              push(`/person/${person.id}/contacts/${contactPerson.data.createContactPerson.id}`);
            }
          }}
          initialValues={{
            alias: "",
            firstName: "",
            lastName: "",
            title: "",
            gender: Gender.unknown,
            addresses: [
              {
                type: AddressType.private,
                zip: "",
                city: "",
                street: "",
              },
            ],
            phones: [
              {
                type: PhoneType.private,
                number: "",
                areaCode: "",
                countryCode: "49",
              },
            ],
            relation: {
              relationship: RelationshipType.unknown,
              isEmergency: false,
              isHousehold: false,
            },
          }}
        />
      </DefaultDialogContent>
    </DefaultDialog>
  );
};

export default ContactPersonDialog;
