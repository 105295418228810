import { useMutation } from "@apollo/client";
import Grid from "@material-ui/core/Grid";
import { Formik } from "formik";
import omit from "lodash/omit";
import { pathOr } from "ramda";
import React, { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
    CreateServiceResearchObjectDocument, Service, ServiceResearchObject, ServiceResearchObjectStatus, ServiceType, useBreadCrumbInfoByClientIdQuery, useCreatePlainResearchObjectMutation
} from "../../../generated/graphql";
import { useServiceSubscription } from "../../../hooks/useServiceSubscription";
import Loader from "../../atoms/Loading";
import ResearchObjectForm from "../../forms/ResearchObjectForm";
import ServiceTemplate from "../../templates/Service";
import ServiceResearchLeadCreationForm from "./form";



export default function ServiceResearchLeadCreation() {
  const { serviceId } = useParams<{ serviceId: string }>();

  const { service, loading, refetch } = useServiceSubscription(parseInt(serviceId, 10));
  const clientId = service?.client?.id;
  const { data: breadcrumbData } = useBreadCrumbInfoByClientIdQuery({
    skip: !clientId,
    variables: {
      id: clientId!,
    },
  });

  if (loading) {
    return <Loader />;
  }

  if (!service) {
    return (
      <div>
        <div>Service wurde nicht gefunden</div>
      </div>
    );
  }

  const person = service?.client?.person;

  if (!person || !person.id) {
    return (
      <div>
        <div>Person wurde nicht gefunden</div>
      </div>
    );
  }

  const namePathMap = [
    {
      // @ts-ignore
      name: service.topic.name,
      // @ts-ignore
      path: `/person/${person.id}/topics/${service.topic.id}`,
    },
    {
      // @ts-ignore
      name: service.serviceMeta.name,
      path: `/service/${service.id}`,
    },
    { name: "Neues Element", path: `/service/${serviceId}/research/` },
  ];

  return (
    <ServiceTemplate
      mapPathList={namePathMap}
      person={person}
      refetchService={refetch}
      service={service}
      contractExpirationDate={breadcrumbData?.breadCrumbInfoByClientId?.contractEnd}
      language={breadcrumbData?.breadCrumbInfoByClientId?.language || ""}
    >
      <Grid item xs={1} xl={1}></Grid>
      <Grid item xs={7} xl={6}>
        {service.serviceMeta?.type === ServiceType.researchBlank ? (
          <CreatePlain service={service} />
        ) : (
          <Create service={service} />
        )}
      </Grid>
    </ServiceTemplate>
  );
}

interface CreateProps {
  service: Service;
}

const CreatePlain: FC<CreateProps> = ({ service }) => {
  const { push } = useHistory();

  const [create] = useCreatePlainResearchObjectMutation();

  const initialValues = {
    descriptor: "",
    note: "",
    info: "",
    status: ServiceResearchObjectStatus.open,
  };

  async function submit(values: any) {
    await create({
      variables: {
        serviceId: service.id!,
        input: {
          descriptor: values.descriptor,
          info: values.info,
          note: values.note,
        },
      },
    });
    push(`/service/${service.id!}`);
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={Yup.object().shape({
        descriptor: Yup.string().required("Pflichtangabe"),
      })}
    >
      {props => <ResearchObjectForm {...props} />}
    </Formik>
  );
};

const Create: FC<CreateProps> = ({ service }) => {
  const { push } = useHistory();

  const newServiceResearchObject = {
    note: "",
    contactPerson: { name: "" },
    status: ServiceResearchObjectStatus.open,
    businessObject: {
      name: "",
      phones: [{ areaCode: "", number: "", countryCode: "49" }],
      info: "",
      url: "",
      email: {
        email: "",
      },
      hours: [],
      address: {
        street: "",
        zip: "",
        city: "",
      },
    },
  };

  const [createServiceResearchObject] = useMutation(CreateServiceResearchObjectDocument);

  const prepareValues = (values: ServiceResearchObject) => {
    const contactName: string = pathOr("", ["contactPerson", "name"], values);
    const copy = omit(values, ["businessObject.accountId", "contactPerson"]);
    if (copy.businessObject?.phones?.length) {
      const phones = copy.businessObject.phones.map(p => {
        return omit(p, "readableNumber");
      });
      copy.businessObject.phones = phones;
    }
    if (copy.businessObject?.address) {
      const address = omit(copy.businessObject.address, ["lat", "lon"]);
      copy.businessObject.address = address;
    }
    if (!contactName.length) {
      return copy;
    }
    const indexOfLastSpace = contactName.lastIndexOf(" ");
    const lastName =
      indexOfLastSpace === -1 ? contactName : contactName.substring(indexOfLastSpace).trim();
    const firstName = indexOfLastSpace === -1 ? null : contactName.substring(0, indexOfLastSpace);
    return { ...copy, contactPerson: { firstName, lastName } };
  };

  return (
    <ServiceResearchLeadCreationForm
      service={service}
      serviceResearchObject={newServiceResearchObject}
      onSubmit={async values => {
        const serviceResearchObjectInput = prepareValues(values);
        await createServiceResearchObject({
          variables: {
            serviceResearchObjectInput,
            serviceId: service.id,
          },
        });

        push(`/service/${service.id}`);
      }}
    />
  );
};
