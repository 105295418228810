import React, { FC } from "react";

import Box from "@material-ui/core/Box";
import useStyles from "./styles";

const WizardNavigation: FC<{}> = ({ children }) => {
  const classes = useStyles();

  return (
    <Box
      width="100%"
      position="fixed"
      bottom="0"
      right="0"
      left="0"
      zIndex="appBar"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={8 * 8}
      paddingX={3}
      bgcolor="background.default"
    >
      <Box
        width={1}
        height={1}
        borderTop={1}
        borderColor="grey.400"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box display="flex" justifyContent="center" alignItems="center" className={classes.wrapper}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default WizardNavigation;
