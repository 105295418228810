import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  logContainer: {
    
  },
  logText: {
      textDecoration: "none",
      margin: "0px",
  }
}));

export default useStyles;
