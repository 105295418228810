import React from "react";

import { FormikProps, FormikValues } from "formik";

import PhoneField from "../../../forms/PhoneField";
import { Phone } from "../../../../generated/graphql";

import { ContactFormWrapper, ContactFormElementWrapper, ContactFormProps } from "./components";

const ContactPhonesForm = ({
  cancel,
  create,
  remove,
  handleSubmit,
  validateForm,
  values,
}: ContactFormProps & FormikProps<FormikValues>) => {
  const isPlural = values.create.length + values.update.length > 1;

  return (
    <ContactFormWrapper
      cancel={cancel}
      onCreate={create}
      onCreateTitle="Weitere Telefonnummer anlegen"
      onSubmit={handleSubmit}
      title={`Telefonnummer${isPlural ? "n" : ""}`}
    >
      {values.update.map((phone: Phone, index: number) => {
        return (
          <ContactPhoneForm
            key={phone.id || "phone"}
            phone={phone}
            index={index}
            method="update"
            // @ts-ignore
            deletePhone={() => remove(phone.id)}
          />
        );
      })}
      {values.create.map((phone: Phone, index: number) => {
        return (
          <ContactPhoneForm
            key={`newPhone-${index}`}
            phone={phone}
            index={index}
            method="create"
            deletePhone={
              index > 0 || values.update.length > 0
                ? () => {
                    values.create.splice(index, 1);
                    validateForm();
                  }
                : undefined
            }
          />
        );
      })}
    </ContactFormWrapper>
  );
};
type TContactPhoneForm = {
  phone: Phone;
  deletePhone?: () => void;
  index: number;
  method: string;
};
const ContactPhoneForm = ({ phone, deletePhone, index, method }: TContactPhoneForm) => {
  return (
    <ContactFormElementWrapper
      onDelete={deletePhone}
      contactPermissions={[
        {
          label: "Kontakt erlaubt",
          name: `${method}[${index}].contactAllowed`,
          value: !!phone.contactAllowed,
        },
        {
          label: "Nachrichten erlaubt",
          name: `${method}[${index}].messagingAllowed`,
          value: !!phone.messagingAllowed,
        },
      ]}
    >
      <PhoneField namePrefix={`${method}[${index}]`} />
    </ContactFormElementWrapper>
  );
};

export default ContactPhonesForm;
