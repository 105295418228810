import { MenuItem, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { Phone } from "@material-ui/icons";
import orderBy from "lodash/orderBy";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useBreadCrumbInfoByAccountIdQuery } from "../../../generated/graphql";
import { useBusinessSubscription } from "../../../hooks/useBusinessSubscription";
import { usePersonsByBusinessSubscription } from "../../../hooks/usePersonsByBusinessSubscription";
import ClickBox from "../../atoms/ClickBox";
import Loader from "../../atoms/Loading";
import NoPersonsTeaser from "../../atoms/NoPersonsTeaser";
import TertiaryButton from "../../atoms/TertiaryButton";
import AccountStatusBadge from "../../molecules/AccountStatusBadge";
import CallBox from "../../molecules/CallBox";
import PersonLayout from "../../templates/PersonLayout";

export default function PersonContacts() {  
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();

  const { businessObject, loading } = useBusinessSubscription(parseInt(id!, 10));
  const { persons, loading: loadingPersons } = usePersonsByBusinessSubscription(parseInt(id!, 10));
  const accountId = businessObject?.accountId;
  const { data: breadcrumbData } = useBreadCrumbInfoByAccountIdQuery({
    skip: !accountId,
    variables: {
      id: accountId!,
    },
  });
  if (loading || loadingPersons) {
    return <Loader></Loader>;
  }

  if (!businessObject) return <>Unternehmen konnte nicht gefunden werden</>;

  const personIds = persons.map(p => p.id) as number[];
  const filteredSet = persons.filter(p => !p.temporary);
  const mapList = businessObject.accountId
    ? [
      { name: "Accounts", path: "/account" },
      {
        name: businessObject.name || "",
        path: `/account/${businessObject.accountId!}`,
        chip: (
          <AccountStatusBadge
            contractEnd={breadcrumbData?.breadCrumbInfoByAccountId?.contractEnd}
            lang={breadcrumbData?.breadCrumbInfoByAccountId?.language || ""}
          />
        ),
      },
      {
        name: "Ansprechpartner",
        path: "",
      },
    ]
    : [
      {
        name: businessObject.name || "",
        path: `/business/${id}`,
      },
      {
        name: "Mitarbeiter",
        path: "",
      },
    ];
    
console.log(filteredSet)
  return (
    <PersonLayout
      counselor
      personIds={personIds}
      mapList={mapList}
      contractExpirationDate={breadcrumbData?.breadCrumbInfoByAccountId?.contractEnd}
    >
      {!persons ||
        (filteredSet.length < 1 && (
          <NoPersonsTeaser
            title={`Es sind noch keine Mitarbeiter für ${businessObject.name ||
              `diese${businessObject.accountId ? "n Account" : "s Unternehmen"}`
              } angelegt.`}
          />
        ))}
      {filteredSet &&
        orderBy(filteredSet, ["lastName", "firstName", "name"], "asc").map(contact => {          
          return (
            <ClickBox
              handleClick={() => push(`/business/${id}/contacts/${contact.id!}`)}
              key={`business-contacts-${contact.id}` || "contact"}
            >
              <Box minHeight={48} width="100%" alignItems="center" display="flex">
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={5}>
                    <Link variant="h4" color="primary">
                      {contact.name || "-"}
                    </Link>
                  </Grid>
                  <Grid item xs={7}>
                    <CallBox contact={contact} />
                  </Grid>
                </Grid>
              </Box>
            </ClickBox>
          );
        })}
    </PersonLayout>
  );
}
