import React, { useState } from "react";

import * as Yup from "yup";
import { Formik } from "formik";
import { Form } from "formik";
import { TextField } from "formik-material-ui";
import { Field } from "formik";
import dayjs from "dayjs";

import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";

import {
  useUsersQuery,
  useCreateCustomTaskMutation,
  PersonFragment,
  Team,
} from "../../../generated/graphql";
import { fetchPolicy } from "../../../utils/constants";

import DefaultDialog from "../../molecules/DefaultDialog";
import DialogContent from "../../molecules/DefaultDialogContent";
import UserSelectField from "../../forms/UserSelectField";
import TopicServiceSelectField from "../../forms/TopicServiceSelectField";
import TeamSelect from "../../forms/TeamSelectField/TeamSelectField";
import SlateRTE, { initialFormValue } from "../../molecules/SlateRTE";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";

interface ITaskDialog {
  onClose: () => void;
  open: boolean;
  person?: PersonFragment;
  topicId?: number | null;
  serviceId?: number | null;
}

const TaskDialog = ({ onClose, open, topicId, serviceId, person }: ITaskDialog) => {
  const [submittedOnce, setSubmittedOnce] = useState(false);
  const { data, loading, error } = useUsersQuery({ fetchPolicy });
  const [createCustomTask] = useCreateCustomTaskMutation();

  if (loading) {
    return <></>;
  }

  if (error || !data || !data.users) {
    return <></>;
  }

  const users = data.users?.map(user => {
    return [user!.id, user];
  });

  const initialValues: {
    team: -1 | Team;
    note: string;
    title: string;
    personId: number;
    dueDate: null | dayjs.Dayjs;
    topicId: number;
    serviceId: number;
    userIds: number;
  } = {
    team: -1,
    note: "",
    title: "",
    personId: person?.id ?? -1,
    dueDate: null,
    topicId: topicId ?? -1,
    serviceId: serviceId ?? -1,
    userIds: -1,
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .min(1)
      .required("Bitte ausfüllen"),
  });

  return (
    <DefaultDialog
      open={open}
      onClose={() => {
        setSubmittedOnce(false);
        onClose();
      }}
      fullWidth
      PaperProps={{ square: true }}
    >
      <DialogContent>
        <Box width="100%">
          <Typography variant="h1" align="center">
            Erstelle jetzt eine neue Aufgabe
          </Typography>
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={async values => {
              setSubmittedOnce(false);
              let path;
              if (values.personId > 0) {
                path = `person/${values.personId}`;
                if (values.topicId > 0) {
                  path = `${path}/topic/${values.topicId}`;
                }
                if (values.serviceId > 0) {
                  path = `${path}/service/${values.serviceId}`;
                }
              }

              await createCustomTask({
                variables: {
                  taskInput: {
                    title: values.title,
                    note: values.note,
                    // @ts-ignore
                    dueDate: values.dueDate?.toString(),
                    ...(values.personId > 0 ? { personId: values.personId } : {}),
                    ...(values.topicId > 0 ? { topicId: values.topicId } : {}),
                    ...(values.serviceId > 0 ? { serviceId: values.serviceId } : {}),
                    ...(values.team !== -1 ? { team: values.team } : {}),
                    path,
                    userId: values.userIds,
                  },
                },
              });

              onClose();
            }}
          >
            {({ errors, setFieldValue, submitForm, values, handleChange }) => {
              return (
                <Form style={{ marginLeft: "-8px", marginRight: "-8px" }}>
                  <Box margin="0 8px 0">
                    <FormControl margin="normal" fullWidth>
                      <Field
                        name="title"
                        label="Titel"
                        component={TextField}
                        autoFocus
                        tabIndex={0}
                      ></Field>
                    </FormControl>
                  </Box>
                  <TopicServiceSelectField
                    hasDueDate
                    dueDate={values.dueDate}
                    errors={errors}
                    onChangePerson={(personId?: number) => {
                      setFieldValue("personId", personId || -1);
                      setFieldValue("serviceId", -1);
                      setFieldValue("topicId", -1);
                    }}
                    person={person}
                    personId={values.personId}
                    topicId={values.topicId}
                    serviceId={values.serviceId}
                    setFieldValue={setFieldValue}
                    submittedOnce={submittedOnce}
                  />
                  <Box margin="32px 8px 0" display="flex">
                    <Box flex={1}>
                      <TeamSelect handleChange={handleChange} disabled={values.userIds !== -1} />
                    </Box>
                    <Box mx={1} />
                    <Box flex={1}>
                      <UserSelectField
                        // @ts-ignore
                        users={new Map(users)}
                        multiple={false}
                        title="Mitarbeiter zuweisen"
                        disabled={values.team !== -1}
                      />
                    </Box>
                  </Box>
                  <Box display="flex" padding="16px 8px 0">
                    <SlateRTE
                      small
                      handleChange={value => {
                        setFieldValue("note", value);
                      }}
                      error={submittedOnce && !!errors.note}
                      label={"Notizen"}
                      placeholder={""}
                      value={values.note || initialFormValue}
                      tabIndex={1000}
                    />
                  </Box>
                  <Box
                    marginTop={4}
                    justifyContent="center"
                    display="flex"
                    flexDirection="row-reverse"
                  >
                    <PrimaryButton
                      tabIndex={0}
                      onClick={() => {
                        setSubmittedOnce(true);
                        submitForm();
                      }}
                    >
                      OK
                    </PrimaryButton>
                    <Box mx={1}></Box>
                    <SecondaryButton onClick={onClose} tabIndex={0}>
                      Abbrechen
                    </SecondaryButton>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </DialogContent>
    </DefaultDialog>
  );
};
export default TaskDialog;
