import React, { useState } from "react";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Formik } from "formik";
import * as Yup from "yup";

import DataColumn from "../../molecules/DataColumn";
import DataRow from "../../molecules/DataRow";
import Dialog from "../../molecules/DefaultDialog";
import DialogContent from "../../molecules/DefaultDialogContent";
import ElementDescriptorForm from "../../forms/ElementDescriptorForm";
import ElementNotesForm from "../../forms/ElementNotesForm";
import ElementInfoForm from "../../forms/ElementInfoForm";
import ElementStatusForm from "../../forms/ElementStatusForm";
import Markup from "../../atoms/Markup";
import {
  ServiceResearchObject,
  ServiceStatus,
  useUpdatePlainResearchObjectMutation,
} from "../../../generated/graphql";
import StatusRow from "../../molecules/StatusRow";

interface IResearchElement {
  researchObject: ServiceResearchObject;
}

const ResearchElement = ({ researchObject }: IResearchElement) => {
  const [update] = useUpdatePlainResearchObjectMutation();
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  async function handleSubmit(values: any) {
    await update({
      variables: {
        input: { ...values },
        id: researchObject.id!,
      },
    });
  }

  return (
    <Grid item xs={7} xl={6} key={`serviceResearchObject-${researchObject.id}`}>
      <Box marginBottom={11} width={1}>
        <Grid container>
          <DataColumn xs={12}>
            <StatusRow
              researchObject={researchObject}
              toggle={() => setCurrentModal("Status")}
              key={`status${researchObject.id}`}
            ></StatusRow>
          </DataColumn>
          <DataRow title="Bezeichnung" editTrigger={() => setCurrentModal("Bezeichnung")}>
            <DataColumn>
              <Typography key={`name${researchObject.id}`}>{researchObject.descriptor}</Typography>
            </DataColumn>
          </DataRow>
          <DataRow title="Inhalt" editTrigger={() => setCurrentModal("Inhalt")}>
            <DataColumn xs={12}>
              <Markup value={researchObject.info || ""} key={`info${researchObject.id}`} />
            </DataColumn>
          </DataRow>
          <DataRow title="Meine Notizen" editTrigger={() => setCurrentModal("Meine Notizen")}>
            <DataColumn xs={12}>
              <Markup value={researchObject.note || ""} key={`note${researchObject.id}`} />
            </DataColumn>
          </DataRow>
        </Grid>
      </Box>
      <Dialog open={!!currentModal} onClose={() => setCurrentModal(null)}>
        <DialogContent>
          <Box mb={3} display="flex" justifyContent="center">
            <Typography variant="h1">{currentModal}</Typography>
          </Box>
          <Formik
            onSubmit={async (values, { setSubmitting }) => {
              setHasSubmitError(false);
              setSubmitting(true);

              try {
                await handleSubmit(values);

                setCurrentModal(null);
              } catch (error) {
                console.error(error);
                setHasSubmitError(true);
              }

              setSubmitting(false);
            }}
            validationSchema={Yup.object().shape({
              descriptor: Yup.string().required("Pflichtangabe"),
            })}
            initialValues={{
              status: researchObject.status || ServiceStatus.research,
              statusChangeReason: researchObject.statusChangeReason || "",
              info: researchObject.info || "",
              note: researchObject.note || "",
              descriptor: researchObject.descriptor || "",
            }}
          >
            {props => {
              switch (currentModal) {
                case "Bezeichnung":
                  return <ElementDescriptorForm {...props} cancel={() => setCurrentModal(null)} />;

                case "Meine Notizen":
                  return <ElementNotesForm {...props} cancel={() => setCurrentModal(null)} />;

                case "Inhalt":
                  return (
                    <ElementInfoForm
                      {...props}
                      cancel={() => setCurrentModal(null)}
                      valueKey={["info"]}
                    />
                  );

                case "Status":
                  return (
                    <ElementStatusForm
                      {...props}
                      hasSubmitError={hasSubmitError}
                      cancel={() => {
                        setCurrentModal(null);
                        setHasSubmitError(false);
                      }}
                    />
                  );

                default:
                  return <div></div>;
              }
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default ResearchElement;
