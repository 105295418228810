import React, { ReactNode, useContext } from "react";

import Box from "@material-ui/core/Box";

import background from "./background.svg";
import backgroundSimple from "./background-2lines.svg";
import { PapershiftWorkingArea } from "../../../generated/graphql";
import { AbsentContext } from "../ShiftRowWrapper";
import UserAvatar, { TAvatarUser } from "../UserAvatar";

interface IShiftRow {
  backgroundImage?: boolean;
  simpleBackground?: boolean;
  border: "borderBottom" | "borderRight";
  children?: ReactNode;
  color?: string;
  padded?: boolean;
  user?: TAvatarUser;
  width: 1 | 8 | 56;
  workingArea?: PapershiftWorkingArea;
}

/**
 *  This component is used to display a row (either times, the seperator between two working areas,
 *  padding for working areas and shifts for a person) in the shift calendar as well as on the
 *  'find appointment'-screen.
 */
const ShiftRow = ({
  backgroundImage = false,
  simpleBackground = false,
  border,
  children,
  color,
  padded,
  width,
  user,
  workingArea,
}: IShiftRow) => {
  const { isAbsent } = useContext(AbsentContext);

  const style = {
    backgroundImage: backgroundImage
      ? simpleBackground
        ? `url(${backgroundSimple})`
        : `url(${background})`
      : undefined,
    borderBottom: border === "borderBottom" ? "solid rgba(130, 130, 130, 0.3) 1px" : undefined,
    borderRight: border === "borderRight" ? "solid rgba(130, 130, 130, 0.3) 1px" : undefined,
    padding: padded ? "0 8px" : undefined,
    position: "relative" as "relative",
  };

  //  overriding the fill color as we don't use the teams color anymore
  const workingAreaIndicator = ((workingArea && workingArea.color) || color) && (
    <svg width={`${width + (padded ? 16 : 0)}px`} height="5" xmlns="http://www.w3.org/2000/svg">
      <rect x="0" y="0" width={`${width + (padded ? 16 : 0)}px`} height="5" fill={"#CCCCCC"} />
    </svg>
  );

  // this is the avatar, border color beneath the avatar, and the whole column (795px)
  return (
    <Box
      width={`${width + (padded ? 16 : 0)}px`}
      sx={{
        // @ts-ignore
        "&:hover": {
          backgroundColor: "rgba(220, 211, 195, 0.3)",
        },
      }}
    >
      <Box height="60px" alignItems="center" justifyContent="center" display="flex">
        {user && <UserAvatar disabled={isAbsent} user={user} workingArea={workingArea} />}
      </Box>
      <Box height="15px" display="flex">
        {workingAreaIndicator}
      </Box>
      <Box height="720px" style={style}>
        {children}
      </Box>
    </Box>
  );
};
export default ShiftRow;
