import React, { FC, useState } from "react";

import dayjs from "dayjs";
import { pathOr } from "ramda";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import MuLink from "@material-ui/core/Link";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { AllMandatesQuery, CounselingMandate, Maybe } from "../../../generated/graphql";

import { useAllMandatesSubscription } from "../../../hooks/useAllMandatesSubscription";

import ActivitiesTemplate from "../../templates/Activities";
import ActivityItem from "../../molecules/ActivitiesItem";
import GirlWithPencil from "../../assets/GirlWithPencil";
import Loader from "../../atoms/Loading";

import useStyles from "./styles";

const sortByLastActivity = (
  a: Maybe<Pick<CounselingMandate, "lastActivity">>,
  b: Maybe<Pick<CounselingMandate, "lastActivity">>,
) => {
  if (dayjs(a?.lastActivity).isAfter(b?.lastActivity)) {
    return -1;
  }
  if (dayjs(b?.lastActivity).isAfter(a?.lastActivity)) {
    return 1;
  }

  return 0;
};

const ActivitiesExternal: FC = () => {
  const { mandates, loading } = useAllMandatesSubscription();

  return (
    <ActivitiesTemplate>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Active mandates={mandates} />
          <Finished />
        </>
      )}
    </ActivitiesTemplate>
  );
};

export default ActivitiesExternal;

const Active: FC<{ mandates?: AllMandatesQuery["allMandates"] }> = ({ mandates }) => {
  const classes = useStyles();

  if (!mandates) {
    return <GirlWithPencil />;
  }

  return (
    <Box className={classes.group}>
      <Mandates mandates={[...mandates].sort(sortByLastActivity)} />
    </Box>
  );
};

const Finished: FC = () => {
  const classes = useStyles();
  const [expand, setExpand] = useState(false);

  const { mandates } = useAllMandatesSubscription(false);

  if (!mandates) {
    return null;
  }

  return (
    <Box mt={5}>
      <Typography variant="h3">Fertig</Typography>
      <Box className={classes.group} mt={2}>
        <Box
          height={72}
          textAlign="center"
          onClick={() => setExpand(!expand)}
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.expand}
        >
          {expand ? (
            <KeyboardArrowUpIcon color="primary" />
          ) : (
            <KeyboardArrowDownIcon color="primary" />
          )}
          <Box mx={1}></Box>
          <Typography color="primary" variant="h4">
            Abgeschlossene externe Beratungen
          </Typography>
        </Box>
        {expand && <Mandates mandates={mandates.sort(sortByLastActivity)} />}
      </Box>
    </Box>
  );
};

const Mandates: FC<{ mandates?: AllMandatesQuery["allMandates"] }> = ({ mandates }) => {
  const classes = useStyles();

  if (!mandates) {
    return null;
  }

  return (
    <>
      {mandates.map(mandate => {
        const person = mandate?.service?.client?.person;
        const topic = mandate?.service?.topic;
        const counselor = mandate?.counselor;
        const referenceNumber = mandate?.referenceNumber;
        const businesses = counselor?.businesses;
        const city = !!businesses?.length ? businesses[0]?.address?.city : "";

        let name = counselor?.name;
        if ((!name || name === "Unbekannt") && !!businesses) {
          name = pathOr("Unbekannt", [0, "name"], businesses);
        }

        return (
          <ActivityItem
            key={`mandate-${mandate!.id}`}
            account={mandate?.service?.client?.account}
            person={person}
            title={
              <Box className={classes.csv}>
                <MuLink
                  component={Link}
                  to={`/externalConsult/${mandate!.service!.id}`}
                  color="primary"
                  underline="always"
                  variant="h4"
                >
                  {topic?.name}, Externe Beratung bei {name}
                </MuLink>
              </Box>
            }
            info={
              <Box display="flex">
                <Box flex={1}>
                  <Typography variant="body2">{city}</Typography>
                </Box>
                <Box flex={1}>
                  <Typography variant="body2">{referenceNumber}</Typography>
                </Box>
                <Box flex={2}>
                  <Typography variant="body2">
                    Letzte Aktivität: {dayjs(mandate?.lastActivity || "").format("DD.MM.YYYY")}
                  </Typography>
                </Box>
              </Box>
            }
          />
        );
      })}
    </>
  );
};
