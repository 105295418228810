import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import ActivitiesList from "../ActivitiesList";

import { useAllBusinessActivitiesSubscription } from "../../../hooks/useAllBusinessActivitiesSubscription";

import { TAKE } from "../../../utils/constants";
import Loader from "../../atoms/Loading";

type TBusinessActivityStream = {
  personIds: number[];
};

const BusinessActivityStream = ({ personIds }: TBusinessActivityStream) => {
  const [hasMore, setHasMore] = useState(true);

  const { activities, loading, fetchMore } = useAllBusinessActivitiesSubscription({
    personIds,
    skip: 0,
    take: TAKE,
  });
  return loading ? (
    <Loader></Loader>
  ) : (
    <Box display="flex" justifyContent="flex-end">
      <ActivitiesList
        hasMore={hasMore}
        past
        withClient
        activities={activities}
        onLoadMore={businessActivities => {
          return fetchMore({
            variables: {
              personIds,
              skip: businessActivities.length,
              take: TAKE,
            },
            updateQuery: (prev, { fetchMoreResult }: { fetchMoreResult?: any }) => {
              if (!fetchMoreResult?.businessActivities?.length) {
                setHasMore(false);
                return prev;
              }

              return {
                ...prev,
                businessActivities: [
                  ...businessActivities,
                  ...(fetchMoreResult ? fetchMoreResult.businessActivities : []),
                ],
              };
            },
          });
        }}
      />
    </Box>
  );
};
export default BusinessActivityStream;
