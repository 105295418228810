import React, { FC } from "react";
import { pathOr } from "ramda";

import { FormikProps, FormikValues } from "formik";
import SlateRTE, { initialFormValue } from "../../molecules/SlateRTE";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import useStyles from "../formStyles";

interface FormProps {
  cancel: () => void;
  valueKey?: string[];
}

const Form: FC<FormProps & FormikProps<FormikValues>> = ({
  cancel,
  handleSubmit,
  setFieldValue,
  values,
  valueKey = ["businessObject", "info"],
}) => {
  function handleChange(value: string) {
    setFieldValue(valueKey.join("."), value);
  }
  const classes = useStyles();
  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SlateRTE
            value={pathOr(initialFormValue, valueKey, values)}
            handleChange={handleChange}
            label="Sonstiges"
            tabIndex={1}
          />
        </Grid>
      </Grid>

      <Box display="flex" justifyContent="center" mt={4}>
        <SecondaryButton onClick={cancel}>Abbrechen</SecondaryButton>
        <Box mx={1} />
        <PrimaryButton type="submit">OK</PrimaryButton>
      </Box>
    </form>
  );
};

export default Form;
