import React, { FC } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import withStyles from "@material-ui/styles/withStyles/withStyles";
import dayjs from "dayjs";

import ShiftRow from "../ShiftRow";

export const TimesWrapper = withStyles((theme: Theme) => ({
  root: {
    position: "relative",
    "& svg": {
      marginRight: "1px",
    },
  },
}))(Box);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: () => {
      const custom = {
        height: 40,
      };

      return {
        fontSize: "12px",
        margin: theme.spacing(0, -1),
        padding: theme.spacing(0, 1),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...custom,
      };
    },
  }),
);

const ShiftTimes: FC = () => {
  const classes = useStyles();
  // @ts-ignore
  const times = [...Array(18).keys()].map(hour => {
    const formatted = dayjs()
      .hour(6 + hour)
      .format("H:00");

    return (
      <Box className={classes.root} key={`hour-${hour}`}>
        <span>{formatted}</span>
      </Box>
    );
  });

  return (
    <TimesWrapper>
      <ShiftRow backgroundImage simpleBackground border="borderBottom" width={56} color="#CCCCCC">
        {times}
      </ShiftRow>
    </TimesWrapper>
  );
};

export default ShiftTimes;
