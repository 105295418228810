import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => {
  return {
    wrapper: {
      "&>button": {
        margin: theme.spacing(0, 1),
      },
    },
  };
});
