import React from "react";
import dayjs from "dayjs";
import Box from "@material-ui/core/Box";
import AppointmentsList from "../AppointmentsList";
import Loading from "../../atoms/Loading";

import { useAppointmentsByDaySubscription } from "../../../hooks/useAppointmentsByDaySubscription";

const startsAt = dayjs();
const endsAt = dayjs().endOf("day");

const AppointmentsStream = () => {
  const { appointments, loading } = useAppointmentsByDaySubscription({
    startsAt: startsAt.toISOString(),
    endsAt: endsAt.toISOString(),
  });

  if (!appointments.length && loading) {
    return <Loading />;
  }
  if (appointments.length) {
    return (
      <Box display="flex" justifyContent="flex-end" marginTop={1}>
        <AppointmentsList withClient appointments={appointments} />
      </Box>
    );
  }
  return <></>;
};

export default AppointmentsStream;
