import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    ucmsForm: {
      display: "flex",
      alignItems: "end",
      gap: theme.spacing(2),
    },
  };
});

export default useStyles;
