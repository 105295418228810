import React, { useState, useRef } from "react";

import dayjs from "dayjs";
import { groupBy } from "ramda";

import { Appointment, User, PapershiftShift } from "../../../generated/graphql";

import AppointmentDialog from "../AppointmentDialog";
import ShiftTimeSlots from "../ShiftTimeSlots";
import AppointmentTimeSlots from "../ShiftTimeSlots/AppointmentTimeSlots";
import { FilterState } from "../../pages/Appointment/reducer";
import { PersonContext } from "../../App";
import { TAvatarUser } from "../UserAvatar";

const isValid = (
  invalidTimes: Appointment[],
  current: dayjs.Dayjs,
  validRange?: {
    startsAt?: dayjs.Dayjs;
    endsAt?: dayjs.Dayjs;
  },
) => {
  const isInRange =
    (validRange &&
      validRange.startsAt &&
      validRange.endsAt &&
      (current.isBetween(validRange.startsAt, validRange.endsAt) ||
        current.isSame(validRange.startsAt))) ||
    false;

  return (
    !invalidTimes.some(time => {
      return current.isSame(time.from) || current.isBetween(time.from, time.to);
    }) && isInRange
  );
};

interface IShiftWrapper {
  appointments?: Appointment[];
  invalidTimes?: Appointment[];
  filter?: FilterState;
  height?: number;
  isFirstColumn?: boolean;
  isLastColumn?: boolean;
  refetch: () => void;
  shift: PapershiftShift;
  user: TAvatarUser;
  width?: number;
  validRange?: {
    startsAt?: dayjs.Dayjs;
    endsAt?: dayjs.Dayjs;
  };
}

/**
 *  This component displays the svg for the shift, appointments within this shift. Also includes
 *  the dialogs and view shift/appointment details and for creating a new appointment,
 */
const ShiftWrapper = ({
  appointments,
  invalidTimes,
  isFirstColumn = false,
  isLastColumn = false,
  filter,
  height = 10,
  refetch,
  width = 56,
  shift,
  user,
  validRange,
}: IShiftWrapper) => {
  const [open, setOpen] = useState(false);
  const appointmentRef = useRef<Appointment | undefined>();

  const startsAt = dayjs(shift.startsAt);
  const endsAt = dayjs(shift.endsAt);
  const currentTime = useRef(startsAt);
  const allowMultiple = useRef(true);

  let users: Array<TAvatarUser | User> = [];
  if (filter) {
    filter.externalUsers.forEach(user => {
      users.push(user);
    });
    users.push(user);
  } else {
    users.push(user);
  }
  // @ts-ignore
  const shifts = shift.shifts.map((shift: any) => {
    return (
      <ShiftTimeSlots
        key={`shiftTimeSlots-shift-${shift.id}`}
        invalidTimes={invalidTimes}
        isFirstColumn={isFirstColumn}
        isLastColumn={isLastColumn}
        height={height}
        onClick={index => {
          allowMultiple.current = !!invalidTimes
            ? isValid(
                invalidTimes,
                dayjs(shift.startsAt)
                  .clone()
                  .add(15 * index, "minute"), // 15 here represents the time division (each hour is divided into 4 sections)
                // ADJUST HERE! #172600725
                validRange,
              )
            : true;
          currentTime.current = dayjs(shift.startsAt)
            .clone()
            .add(15 * index, "minute");
          setOpen(true);
        }}
        startsAt={dayjs(shift.startsAt)}
        endsAt={dayjs(shift.endsAt)}
        user={user}
        width={width}
        color={shift?.papershiftWorkingArea?.color}
      />
    );
  });
  return (
    <>
      {shifts}
      {/* appointments indicators */}
      <Appointments
        // somehow deleting a user from an appointment does not trigger a rerender of the
        // appointment in this user's shift
        appointments={appointments?.filter(a => a.users?.some(u => u?.id === user?.id))}
        height={height}
        onClick={(appointment: Appointment) => {
          appointmentRef.current = appointment;
          setOpen(true);
        }}
        user={user}
        width={width}
      />
      <PersonContext.Consumer>
        {({ person }) => {
          return (
            <AppointmentDialog
              appointment={appointmentRef.current}
              allowEditUsers={!filter}
              currentTime={currentTime.current}
              refetch={refetch}
              minEndsAt={endsAt}
              minStartsAt={startsAt}
              onClose={() => {
                setOpen(false);
                setTimeout(() => {
                  appointmentRef.current = undefined;
                }, 200);
              }}
              open={open}
              person={person}
              users={users}
            />
          );
        }}
      </PersonContext.Consumer>
    </>
  );
};

interface IAppointments {
  appointments?: Appointment[];
  height: number;
  onClick: (appointment: Appointment) => void;
  user: TAvatarUser;
  width: number;
}

const Appointments = ({ appointments, height, onClick, user, width }: IAppointments) => {
  const groupedAppointments = groupBy((appointment: Appointment) => {
    return !!appointment.person ? "appointment" : "block";
  })(appointments || []);

  return (
    <>
      {groupedAppointments["appointment"] &&
        groupedAppointments["appointment"]
          .filter(appointment => !appointment.deletedAt)
          .map(appointment => {
            return (
              <AppointmentTimeSlots
                key={`${appointment.id}`}
                height={height}
                onClick={() => onClick(appointment)}
                startsAt={dayjs(appointment.from)}
                endsAt={dayjs(appointment.to)}
                type={"appointment"}
                user={user}
                width={width}
              />
            );
          })}
      {groupedAppointments["block"] &&
        groupedAppointments["block"].map(block => {
          return (
            <AppointmentTimeSlots
              key={`${block.id}`}
              height={height}
              onClick={() => onClick(block)}
              startsAt={dayjs(block.from)}
              endsAt={dayjs(block.to)}
              type={"block"}
              user={user}
              width={width}
            />
          );
        })}
    </>
  );
};
export default ShiftWrapper;
