import { useEffect } from "react";
import dayjs from "dayjs";
import {
  useAllActivitiesQuery,
  AllActivitiesSubscriptionDocument,
  AllActivitiesSubscriptionSubscription,
  PersonActivity,
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";
import { useLocation } from "react-router";

type Filter = {
  skip?: number;
  take?: number;
};

export const useAllActivitiesSubscription = (filter?: Filter) => {
  const { search } = useLocation();
  const date = new URLSearchParams(search).get("date");
  const { data, loading, fetchMore, subscribeToMore } = useAllActivitiesQuery({
    variables: {
      ...filter,
      date: date ?? dayjs(Date()).format('YYYY-MM-DD'),
    },
    fetchPolicy,
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore<AllActivitiesSubscriptionSubscription>({
      document: AllActivitiesSubscriptionDocument,
      variables: { ...filter },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !prev) return prev;
        const newActivity = subscriptionData.data.allActivitiesSubscription;
        const activities = [...prev.allActivities].sort((a, b) => {
          return a.lastUpdate - b.lastUpdate ? -1 : 1;
        });

        if (newActivity) {
          const updateIndex = activities.findIndex(activity => activity.id! === newActivity.id!);

          let index = updateIndex;

          if (updateIndex === -1) {
            index = activities.findIndex(activity =>
              dayjs(activity.start).isBefore(newActivity.start),
            );
          }

          const replace = updateIndex > -1 ? 1 : 0;

          activities.splice(index, replace, newActivity);
        }
        return { ...prev, allActivities: activities };
      },
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return { activities: (data?.allActivities || []) as PersonActivity[], loading, fetchMore };
};
