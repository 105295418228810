import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@material-ui/core";

import Loader from "../../atoms/Loading";

import PersonDashboard from "../../organisms/PersonDashboard";
import { usePersonSubscriptionForCallDialog } from "../../../hooks/usePersonSubscription";

const PersonPreview: FC<{ id: string }> = ({ id }) => {  
  const { person, loading } = usePersonSubscriptionForCallDialog(parseInt(id, 10));

  if (loading) {
    return <Loader />;
  }

  if (!person) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" marginTop={24} width="100%">
        <div>Person wurde nicht gefunden</div>
      </Box>
    );
  }
  
  return <PersonDashboard person={person} id={parseInt(id, 10)} />;
}

export default PersonPreview;
