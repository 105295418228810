import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  info: {
    margin: `${theme.spacing(2)}px 0`,
    padding: `0 ${theme.spacing(2)}px 0 0`,
  },
  label: {
    margin: `0 0 ${theme.spacing(0.5)}px`,
  },
}));

export default useStyles;
