import React from "react";

import dayjs from "dayjs";
import DayjsUtils from "@date-io/dayjs";
import { Field, FormikValues, FormikErrors } from "formik";
import { TextField, Checkbox } from "formik-material-ui";

import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import withStyles from "@material-ui/styles/withStyles";
import { Theme } from "@material-ui/core/styles";

// TODO: Unify
const StyledKeyboardDatePicker = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
}))(KeyboardDatePicker);

const StyledFormControlLabel = withStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(0, 2),
  },
}))(FormControlLabel);

type IClientAge = {
  setFieldValue: (field: string, value: any) => void;
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  validateForm: (values?: any) => Promise<FormikErrors<FormikValues>> | any;
};

const ClientAge = (props: IClientAge) => {
  return (
    <>
      <Grid item xs={6}>
        <Box display="flex">
          <FormControl fullWidth margin="normal">
            <Field
              type="number"
              name="initialAge"
              disabled={!props.values.ageGuessed}
              label="Alter"
              component={TextField}
              values={props.values.initialAge}
              inputProps={{
                min: 0,
                onChange: (e: any) => {
                  const estBDay = dayjs().subtract(parseInt(e.target.value), "year");

                  if (estBDay.isValid()) {
                    props.setFieldValue("birthDate", null);
                  }
                },
              }}
              tabIndex={6}
            />
          </FormControl>
          <Box mt={4}>
            <FormControl fullWidth>
              <StyledFormControlLabel
                tabIndex={7}
                control={
                  <Field
                    label="Geschätzt"
                    name="ageGuessed"
                    component={Checkbox}
                    inputProps={{
                      onChange: (e: any) => {
                        props.setFieldValue("ageGuessed", e.target.value !== "checked");
                      },
                    }}
                  />
                }
                label="Geschätzt"
              />
            </FormControl>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={6}>
        <FormControl fullWidth margin="normal">
          <MuiPickersUtilsProvider utils={DayjsUtils} locale="de">
            <StyledKeyboardDatePicker
              name="birthDate"
              disableFuture
              helperText={props.errors.birthDate}
              disabled={props.values.ageGuessed}
              format="DD.MM.YYYY"
              value={props.values.ageGuessed ? null : props.values.birthDate}
              onChange={value => {
                props.setFieldValue("birthDate", value);

                if (value?.isValid()) {
                  props.setFieldValue("initialAge", dayjs().diff(value || dayjs(), "years"));
                }

                if (props.errors) {
                  props.validateForm();
                }
              }}
              label="Geburtsdatum"
              cancelLabel="Abbrechen"
              okLabel="OK"
            />
          </MuiPickersUtilsProvider>
        </FormControl>
      </Grid>
    </>
  );
};

export default ClientAge;
