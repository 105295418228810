import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import AppointmentsList from "../AppointmentsList";
import Loading from "../../atoms/Loading";

import { usePersonAppointmentsSubscription } from "../../../hooks/usePersonAppointmentsSubscription";

const PersonAppointmentStream: FC<{ personId: number }> = ({ personId }) => {
  const { appointments, loading } = usePersonAppointmentsSubscription({
    personId,
  });
  if (!appointments.length && loading) {
    return <Loading />;
  }
  if (appointments.length) {
    return (
      <Box display="flex" justifyContent="flex-end" marginTop={1}>
        <AppointmentsList appointments={appointments} />
      </Box>
    );
  }
  return <></>;
};

export default PersonAppointmentStream;
