import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Field } from "formik";
import { Select } from "formik-material-ui";
import React from "react";
import { useAvailableLanguagesQuery } from "../../../generated/graphql";

const LanguageSelect = ({ tabIndex = 0 }) => {
  const { data, loading, error } = useAvailableLanguagesQuery({
    variables: {},
  });

  const languages = data?.availableLanguages || [];

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel htmlFor="contactLanguageId">Sprache</InputLabel>
      <Field name="contactLanguageId" label="Sprache" component={Select}>
        {languages.map(language => (
          <MenuItem key={`language${language?.id}`} value={language?.id || ""}>
            {language?.name}
          </MenuItem>
        ))}
      </Field>
    </FormControl>
  );
};
export default LanguageSelect;
