import React from "react";

import Avatar, { AvatarProps } from "@material-ui/core/Avatar";

import useStyles from "./styles";

type TCustomAvatar = { widthfactor?: number };

const CustomAvatar = ({
  children,
  className,
  onClick,
  src,
  widthfactor,
  ...props
}: TCustomAvatar & AvatarProps) => {
  const classes = useStyles({
    clickable: !!onClick,
    hasAvatar: !!src,
    width: widthfactor || 4,
  });

  return (
    <Avatar className={`${classes.avatar} ${className}`} onClick={onClick} src={src} {...props}>
      {children}
    </Avatar>
  );
};

export default CustomAvatar;
