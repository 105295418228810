import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import { Checkbox, FormControlLabel, Link, Theme, Typography, withStyles } from "@material-ui/core";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import { useHistory, useParams } from "react-router";
import { KeyboardDatePicker } from "@material-ui/pickers";
import SecondaryButton from "../../atoms/SecondaryButton";
import PrimaryButton from "../../atoms/PrimaryButton";
import useStyles from "./styles";

dayjs.extend(LocalizedFormat);

export default function QualtricsQuestionnaireType() {
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const { push } = useHistory();
  const { goBack } = useHistory();

  const [isOpen, setIsOpen] = useState(false);

  const [personId, setPersonId] = useState("-1");

  const onChangePerson = (pId?: number) => {
    setPersonId(pId!.toString());
  };

  // if (loading) {
  //   return <Loading />;
  // }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box marginTop={24}>
            <Typography variant="h1" className={classes.subHeadline}>
              Ist dies der erste oder letzte Anruf für diesen Kunden?
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <div>
        <div className={classes.clientSelectorContainer}>
          <div className={classes.clientSelector}>
            <Box marginLeft={2}>
              <Link
                variant="h4"
                underline="always"
                color="primary"
                onClick={() => {
                  push(`/person/${id}`);
                }}
              >
                Zurück zum Kunden
              </Link>
            </Box>

            <SecondaryButton
              onClick={async () => {
                push(`/person/${id}/qualtrics-documentation?t=1`);
              }}
            >
              Erste Umfragen
            </SecondaryButton>

            <PrimaryButton
              onClick={async () => {
                push(`/person/${id}/qualtrics-documentation?t=2`);
              }}
            >
              Letzte Umfragen
            </PrimaryButton>
          </div>
        </div>
      </div>
    </>
  );
}

export const StyledKeyboardDatePicker = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
}))(KeyboardDatePicker);
