import React from "react";

import { path } from "ramda";

import { PersonActivity, Person } from "../../../generated/graphql";

import Bubble, { ActivityInteractionType } from "./Bubble";

type TActiveHeaderBubble = {
  activity: PersonActivity;
  handleClick: (person?: Person | null) => void;
};

const ActiveHeaderBubble = ({ activity, handleClick }: TActiveHeaderBubble) => {
  const readableNumber = path<string | undefined>(
    ["person", "phones", 0, "readableNumber"],
    activity,
  );

  const person = activity.person;

  // Due to refactoring: https://medium.com/@calebmer/when-to-use-graphql-non-null-fields-4059337f6fc8
  let text;
  if (person) {
    text = person.temporary ? readableNumber : person.name;
  }

  if (!text) {
    text = "Unbekannt";
  }

  if (!activity.activityType || !(activity.activityType in ActivityInteractionType)) {
    console.log("Activity is currently not supported");
    return null;
  }

  return (
    <Bubble
      // @ts-ignore
      type={activity.activityType}
      onClick={() => handleClick(person)}
      text={text || ""}
    />
  );
};

export default ActiveHeaderBubble;
