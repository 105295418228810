import React from "react";

import dayjs from "dayjs";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import { GetOneThreadByActivityQuery, Person } from "../../../generated/graphql";

import EmailMessage from "../../molecules/EmailMessage";

type TEmailThreadMessages = {
  emails?: GetOneThreadByActivityQuery["getOneThreadByActivity"];
  person?: Person | null;
};

const EmailThreadMessages = ({ emails }: TEmailThreadMessages) => {  
  if (!emails) {
    return null;
  }

  let personActivity = emails[0]?.personActivity;

  return (
    <Box width={1}>
      {emails.map(email => {
        let divider;
        if (personActivity?.id !== email?.personActivity?.id) {
          divider = (
            <Box display="flex" alignItems="center">
              <Box borderBottom="solid #C4C4C4 1px" height={1} flex={1} />
              <Box px={1}>
                <Typography variant="body2" color="textSecondary">
                  oberhalb bereits abgeschlossen am{" "}
                  {dayjs(personActivity!.end).format("DD. MMMM YYYY [um] H:mm")}
                </Typography>
              </Box>
              <Box borderBottom="solid #C4C4C4 1px" height={1} flex={1} />
            </Box>
          );
          personActivity = email?.personActivity;
        }

        return (
          <React.Fragment key={`email-${email?.id}-wrapper`}>
            {divider}
            <EmailMessage
              key={`email-${email?.id}`}
              messageId={email?.messageId}
              attachments={email?.attachments}
              avatarKeys={email?.person?.avatarKeys}
              // To correctly display messages sent from Gmail directly, do not use something like
              // '!user' to determine whether the message was sent by otheb or not
              isClient={!!email?.incoming}
              subject={email?.payload?.subject || ""}
              text={email?.body || ""}
              time={dayjs(email?.date || "")}
              user={email?.user}
            />
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default EmailThreadMessages;
