import { useEffect } from "react";

import dayjs from "dayjs";

import {
  useGetCaseManagementReviewDetailsQuery,
  CaseManagementReviewSubscriptionSubscription,
  CaseManagementReviewSubscriptionDocument,
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

export const useCaseManagementReviewSubscription = (personId: number) => {
  const { data, loading, subscribeToMore } = useGetCaseManagementReviewDetailsQuery({
    variables: {
      personId,
    },
    fetchPolicy,
  });

  useEffect(() => {
    // by typing here, we get rid of the @ts-ignore
    const unsubscribe = subscribeToMore<CaseManagementReviewSubscriptionSubscription>({
      document: CaseManagementReviewSubscriptionDocument,
      variables: { personId },
      // no updateQuery? seems to work though!?!
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personId]);

  if (loading) {
    return undefined;
  }

  const caseManagementReviewAttributes = data?.getCaseManagementReview?.caseManagementReviewAttributes?.reduce(
    (carry, caseManagementReviewAttribute) => {
      const key = caseManagementReviewAttribute?.clientReviewAttribute?.name;

      if (!!key) {
        carry[key] = {
          attributeValue: caseManagementReviewAttribute?.attributeValue,
          lastUpdate: caseManagementReviewAttribute?.lastUpdate,
          user: caseManagementReviewAttribute?.user,
        };
      }

      return carry;
    },
    {} as any,
  );

  const lastUpdate = data?.getCaseManagementReview?.caseManagementReviewAttributes?.reduce(
    (carry, caseManagementReviewAttribute) => {
      const lastUpdate = caseManagementReviewAttribute?.lastUpdate;

      if (!!lastUpdate) {
        if (!carry.updatedAt || dayjs(lastUpdate).isAfter(carry.updatedAt)) {
          return {
            updatedAt: dayjs(lastUpdate),
            updatedBy: caseManagementReviewAttribute?.user,
          };
        }
      }

      return carry;
    },
    { updatedAt: undefined, updatedBy: undefined } as { updatedAt?: dayjs.Dayjs; updatedBy: any },
  );

  return { caseManagementReviewAttributes, lastUpdate };
};
