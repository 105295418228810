/* eslint-disable no-useless-computed-key */

interface ILifeworksMapping {
  [service: string]: {
    label: string;

    issues: {
      [issue: string]: {
        label: string;

        subIssues: {
          [subissue: string]: {
            label: string;
          };
        };
      };
    };
  };
}

const mapping: ILifeworksMapping = {
  Counselling: {
    label: "Counselling",
    issues: {
      Addiction_Related_Issue: {
        label: "Addiction Related Issue",
        subIssues: {
          ["Addiction Other"]: { label: "Addiction Other" },
          ["Alcohol"]: { label: "Alcohol" },
          ["Drug"]: { label: "Drug" },
          ["Gambling"]: { label: "Gambling" },
          ["Smoking"]: { label: "Smoking" },
        },
      },
      Career: {
        label: "Career",
        subIssues: {
          ["Resiliency"]: { label: "Resiliency" },
          ["Retirement Planning"]: { label: "Retirement Planning" },
          ["Starting Out"]: { label: "Starting Out" },
          ["General"]: { label: "General" },
        },
      },
      Couple_Relationship: {
        label: "Couple Relationship",
        subIssues: {
          ["Communication/Conflict Resolution"]: { label: "Communication/Conflict Resolution" },
          ["Domestic Violence"]: { label: "Domestic Violence" },
          ["Family Planning"]: { label: "Family Planning" },
          ["Intimacy Issues"]: { label: "Intimacy Issues" },
          ["Relationship - General"]: { label: "Relationship - General" },
          ["Relationship Breakdown"]: { label: "Relationship Breakdown" },
          ["Separation/Divorce"]: { label: "Separation/Divorce" },
        },
      },
      Expat: {
        label: "Expat",
        subIssues: {
          ["Assignment Fatigue"]: { label: "Assignment Fatigue" },
          ["Conflict (Host National/Management)"]: { label: "Conflict (Host National/Management)" },
          ["Culture Shock/Adjustment"]: { label: "Culture Shock/Adjustment" },
          ["Family Member Left Behind"]: { label: "Family Member Left Behind" },
          ["Isolation"]: { label: "Isolation" },
          ["Language Barrier"]: { label: "Language Barrier" },
          ["Problem Obtaining Perscription Drugs"]: {
            label: "Problem Obtaining Perscription Drugs",
          },
          ["Spouse Unable to Work"]: { label: "Spouse Unable to Work" },
        },
      },
      Family: {
        label: "Family",
        subIssues: {
          ["Adolescent Behaviour"]: { label: "Adolescent Behaviour" },
          ["Blended Family"]: { label: "Blended Family" },
          ["Child Behaviour"]: { label: "Child Behaviour" },
          ["Communication"]: { label: "Communication" },
          ["Elder Related"]: { label: "Elder Related" },
          ["Extended Family Relations"]: { label: "Extended Family Relations" },
          ["Family Planning"]: { label: "Family Planning" },
          ["Parenting"]: { label: "Parenting" },
        },
      },
      Personal_Emotional_Issue: {
        label: "Personal Emotional Issue",
        subIssues: {
          ["Abuse - Child"]: { label: "Abuse - Child" },
          ["Abuse - Childhood"]: { label: "Abuse - Childhood" },
          ["Abuse - Domestic Violence"]: { label: "Abuse - Domestic Violence" },
          ["Abuse - Elder"]: { label: "Abuse - Elder" },
          ["Abuse - Emotional"]: { label: "Abuse - Emotional" },
          ["Abuse - Physical"]: { label: "Abuse - Physical" },
          ["Abuse - Sexual"]: { label: "Abuse - Sexual" },
          ["Abuse - Verbal"]: { label: "Abuse - Verbal" },
          ["Anger Issues"]: { label: "Anger Issues" },
          ["Anxiety"]: { label: "Anxiety" },
          ["Cross-Cultural Issues"]: { label: "Cross-Cultural Issues" },
          ["Depression"]: { label: "Depression" },
          ["Eating Disorder"]: { label: "Eating Disorder" },
          ["Grief"]: { label: "Grief" },
          ["Life Stages"]: { label: "Life Stages" },
          ["Mental Health Condition"]: { label: "Mental Health Condition" },
          ["Other"]: { label: "Other" },
          ["Post Trauma"]: { label: "Post Trauma" },
          ["Repatriation Issues"]: { label: "Repatriation Issues" },
          ["Self Esteem"]: { label: "Self Esteem" },
          ["Self Harm"]: { label: "Self Harm" },
          ["Sexuality"]: { label: "Sexuality" },
          ["Social Isolation"]: { label: "Social Isolation" },
          ["Stress - Financial"]: { label: "Stress - Financial" },
          ["Stress - Legal"]: { label: "Stress - Legal" },
          ["Stress - Medical"]: { label: "Stress - Medical" },
          ["Stress - Personal"]: { label: "Stress - Personal" },
          ["Suicidal Risk"]: { label: "Suicidal Risk" },
        },
      },
      Work_Related_Issue: {
        label: "Work Related Issue",
        subIssues: {
          ["Work Performance"]: { label: "Work Performance" },
          ["Work Relationships/Conflict"]: { label: "Work Relationships/Conflict" },
          ["Workplace Stress"]: { label: "Workplace Stress" },
          ["Workplace Violence/Harassment"]: { label: "Workplace Violence/Harassment" },
        },
      },
    },
  },
  Financial: {
    label: "Financial",
    issues: {
      Financial_Issue: {
        label: "Financial Issue",
        subIssues: {
          ["Bankrupcy"]: { label: "Bankrupcy" },
          ["Debt/Credit"]: { label: "Debt/Credit" },
          ["Divorce"]: { label: "Divorce" },
          ["Employment Transition"]: { label: "Employment Transition" },
          ["Estate"]: { label: "Estate" },
          ["Insurance"]: { label: "Insurance" },
          ["Investment Planning"]: { label: "Investment Planning" },
          ["Real Estate/Mortgages"]: { label: "Real Estate/Mortgages" },
          ["Retirement"]: { label: "Retirement" },
          ["Taxes"]: { label: "Taxes" },
        },
      },
    },
  },
  Legal: {
    label: "Legal",
    issues: {
      Legal_Issue: {
        label: "Legal issues",
        subIssues: {
          "Child Custody": { label: "Child Custody" },
          "Child Support": { label: "Child Support" },
          "Civil Litigation": { label: "Civil Litigation" },
          "Criminal Law": { label: "Criminal Law" },
          "Landlord-Tenant": { label: "Landlord-Tenant" },
          "Property Law": { label: "Property Law" },
          "Real Estate": { label: "Real Estate" },
          "Separation/Divorce": { label: "Separation/Divorce" },
          "Will/Estates": { label: "Will/Estates" },
        },
      },
    },
  },
  Management_Consultation: {
    label: "Management Consultation",
    issues: {
      Management_Consultation_Issue: {
        label: "Management Consultation Issue",
        subIssues: {
          ["Addiction Related"]: { label: "Addiction Related" },
          ["Individuals in Conflict"]: { label: "Individuals in Conflict" },
          ["Harm to others"]: { label: "Harm to others" },
          ["Manager/Employee in Conflict"]: { label: "Manager/Employee in Conflict" },
          ["Other Issue"]: { label: "Other Issue" },
          ["Performance Issues"]: { label: "Performance Issues" },
          ["Safety Sensitive Harm to Self"]: { label: "Safety Sensitive Harm to Self" },
          ["Trauma"]: { label: "Trauma" },
        },
      },
    },
  },
  Naturopathic_Services: {
    label: "Naturopathic Services",
    issues: {
      Naturopathic_Services_Issue: {
        label: "Naturopathic Services Issue",
        subIssues: {
          ["Disease State"]: { label: "Disease State" },
          ["General Healthy Eating"]: { label: "General Healthy Eating" },
          ["Medical Issue/Condition"]: { label: "Medical Issue/Condition" },
          ["Preventative Health"]: { label: "Preventative Health" },
          ["Sleeping Health"]: { label: "Sleeping Health" },
          ["Weight Management"]: { label: "Weight Management" },
        },
      },
    },
  },
  Nutrition: {
    label: "Nutrition",
    issues: {
      Nutrition_Issue: {
        label: "Nutrition Issue",
        subIssues: {
          ["Disease State"]: { label: "Disease State" },
          ["General Healthy Eating"]: { label: "General Healthy Eating" },
          ["Sleeping Health"]: { label: "Sleeping Health" },
          ["Medical Issue/Condition"]: { label: "Medical Issue/Condition" },
          ["Preventative Health"]: { label: "Preventative Health" },
          ["Weight Management"]: { label: "Weight Management" },
        },
      },
    },
  },
  Trauma: {
    label: "Trauma",
    issues: {
      Trauma_Issue: {
        label: "Trauma Issue",
        subIssues: {
          ["Accident - Fatal"]: { label: "Accident - Fatal" },
          ["Accident - Non-Fatal"]: { label: "Accident - Non-Fatal" },
          ["Death of Employee,Family Member"]: { label: "Death of Employee,Family Member" },
          ["Disaster Natural or Manmade"]: { label: "Disaster Natural or Manmade" },
          ["Downsizing/Restructuring/ Site Closure"]: {
            label: "Downsizing/Restructuring/ Site Closure",
          },
          ["Harassment - Workplace"]: { label: "Harassment - Workplace" },
          ["Homicide"]: { label: "Homicide" },
          ["Illness Diagnosed"]: { label: "Illness Diagnosed" },
          ["Inquest/Investigation/ Performance Issues"]: {
            label: "Inquest/Investigation/ Performance Issues",
          },
          ["Mental Health Crisis"]: { label: "Mental Health Crisis" },
          ["Murder"]: { label: "Murder" },
          ["Robbery"]: { label: "Robbery" },
          ["Robbery (Armed/Aggression)"]: { label: "Robbery (Armed/Aggression)" },
          ["Suicide"]: { label: "Suicide" },
          ["Termination"]: { label: "Termination" },
          ["Theft/Fraud"]: { label: "Theft/Fraud" },
          ["Violence - At Home"]: { label: "Violence - At Home" },
          ["Violence - At Work"]: { label: "Violence - At Work" },
          ["Violence - Bomb Threat"]: { label: "Violence - Bomb Threat" },
          ["Violence - Verbal Abuse/Threats"]: { label: "Violence - Verbal Abuse/Threats" },
          ["Workplace Trauma–Other"]: { label: "Workplace Trauma–Other" },
        },
      },
    },
  },
  Child_Youth_Care: {
    label: "Child Youth Care",
    issues: {
      Child_Youth_Care_Issue: {
        label: "Child Youth Care Issue",
        subIssues: {
          ["Adoption"]: { label: "Adoption" },
          ["Daycare"]: { label: "Daycare" },
          ["Expectant & New Parents"]: { label: "Expectant & New Parents" },
          ["Home Support Services"]: { label: "Home Support Services" },
          ["Parenting Resources & Information"]: { label: "Parenting Resources & Information" },
          ["Planning a Family"]: { label: "Planning a Family" },
          ["Special Needs"]: { label: "Special Needs" },
        },
      },
    },
  },
  Elder_Adult_Care: {
    label: "Elder Adult Care",
    issues: {
      Elder_Care_Adultcare_Issue: {
        label: "Elder Care Adultcare Issue",
        subIssues: {
          ["Community Programs"]: { label: "Community Programs" },
          ["Compassionate Care & Bereavement"]: { label: "Compassionate Care & Bereavement" },
          ["Eldercare Resources & Information"]: { label: "Eldercare Resources & Information" },
          ["Home Support Services"]: { label: "Home Support Services" },
          ["Residential Care Options"]: { label: "Residential Care Options" },
        },
      },
    },
  },
  Workplace_Referral_Program: {
    label: "Workplace Referral Program",
    issues: {
      Addiction_Related_Issue: {
        label: "Addiction Related Issue",
        subIssues: {
          ["Addiction Other"]: { label: "Addiction Other" },
          ["Alcohol"]: { label: "Alcohol" },
          ["Drug"]: { label: "Drug" },
          ["Gambling"]: { label: "Gambling" },
          ["Smoking"]: { label: "Smoking" },
        },
      },
      Personal_Emotional_Issue: {
        label: "Personal Emotional Issue",
        subIssues: {
          ["Abuse - Child"]: { label: "Abuse - Child" },
          ["Abuse - Childhood"]: { label: "Abuse - Childhood" },
          ["Abuse - Domestic Violence"]: { label: "Abuse - Domestic Violence" },
          ["Abuse - Elder"]: { label: "Abuse - Elder" },
          ["Abuse - Emotional"]: { label: "Abuse - Emotional" },
          ["Abuse - Physical"]: { label: "Abuse - Physical" },
          ["Abuse - Sexual"]: { label: "Abuse - Sexual" },
          ["Abuse - Verbal"]: { label: "Abuse - Verbal" },
          ["Anger Issues"]: { label: "Anger Issues" },
          ["Anxiety"]: { label: "Anxiety" },
          ["Cross-Cultural Issues"]: { label: "Cross-Cultural Issues" },
          ["Depression"]: { label: "Depression" },
          ["Eating Disorder"]: { label: "Eating Disorder" },
          ["Grief"]: { label: "Grief" },
          ["Life Stages"]: { label: "Life Stages" },
          ["Mental Health Condition"]: { label: "Mental Health Condition" },
          ["Other"]: { label: "Other" },
          ["Post Trauma"]: { label: "Post Trauma" },
          ["Repatriation Issues"]: { label: "Repatriation Issues" },
          ["Self Esteem"]: { label: "Self Esteem" },
          ["Self Harm"]: { label: "Self Harm" },
          ["Sexuality"]: { label: "Sexuality" },
          ["Social Isolation"]: { label: "Social Isolation" },
          ["Stress - Financial"]: { label: "Stress - Financial" },
          ["Stress - Legal"]: { label: "Stress - Legal" },
          ["Stress - Medical"]: { label: "Stress - Medical" },
          ["Stress - Personal"]: { label: "Stress - Personal" },
          ["Suicidal Risk"]: { label: "Suicidal Risk" },
        },
      },
      Work_Related_Issue: {
        label: "Work Related Issue",
        subIssues: {
          ["Work Performance"]: { label: "Work Performance" },
          ["Work Relationships/Conflict"]: { label: "Work Relationships/Conflict" },
          ["Workplace Stress"]: { label: "Workplace Stress" },
          ["Workplace Violence/Harassment"]: { label: "Workplace Violence/Harassment" },
        },
      },
    },
  },
};

export default mapping;
