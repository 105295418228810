import React, { FC } from "react";

import { Field, FormikProps, FormikValues } from "formik";
import { TextField } from "formik-material-ui";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";

import WizardNavigation from "../../molecules/WizardNavigation";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import GenderSelect from "../../molecules/GenderSelect";

import useStyles from "../formStyles";

interface FormProps {
  cancel: (show: boolean) => void;
  showCancel: boolean;
  backAction: () => void;
}

const Form: FC<FormProps & FormikProps<FormikValues>> = ({
  handleSubmit,
  isSubmitting,
  cancel,
  showCancel,
  backAction,
}) => {
  const classes = useStyles();
  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field name="firstName" component={TextField} label="Vorname" />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field name="lastName" component={TextField} label="Nachname" />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field component={TextField} label="Alter" name="initialAge" type="number" />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <GenderSelect />
        </Grid>
      </Grid>
      <WizardNavigation>
        {!showCancel && <SecondaryButton onClick={() => backAction()}>Zurück</SecondaryButton>}
        {showCancel && <SecondaryButton onClick={() => cancel(false)}>Zur Auswahl</SecondaryButton>}
        <PrimaryButton type="submit" disabled={isSubmitting}>
          Weiter
        </PrimaryButton>
      </WizardNavigation>
    </form>
  );
};
export default Form;
