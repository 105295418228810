import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const SuccessIllustration = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 98 120">
      <path
        d="M23.27 26.223c.623-4.39 4.093-16.63 7.651-16.63 4.252 0 4.004-4.628 7.918-4.468 3.915.16 7.701 0 5.783-3.057-1.918-3.056 6.139 1.02 8.72 5.017 0 0 2.619-2.814 4.314-1.96 1.695.853.804 3.91 1.913 4.469 1.109.558 3.692-1.647 3.692-1.647s1.913 12.307 4.003 12.307c5.451 0 .534 8.79 0 14.703"
        stroke="#025879"
        fill="none"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        fill="none"
        clipRule="evenodd"
        d="M40.667 48.779c6.348 1.444 18.322-.315 22.414-6.378 0 0 2.774 2.246 4.501-3.233 1.728-5.48-3.364-4.222-3.364-4.222-1.978-9.28-10.201-17.604-15.293-15.09-1.445.714-3.488-2.234-4.482-2.514-4.456-1.258-4.043 2.515-5.702 2.515-1.66 0 .474-3.21-3.183-2.515-3.656.694-10.122 10.913-10.122 10.913s-4.5-3.81-3.881 2.477c.62 6.287 3.881 5.03 3.881 5.03 1.848 7.545 8.883 11.573 15.231 13.017z"
        stroke="#025879"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.49 33.17c-.989 0-1.79-.8-1.79-1.787 0-.987.801-1.787 1.79-1.787s1.79.8 1.79 1.787c0 .987-.801 1.787-1.79 1.787zM37.59 29.596c-.989 0-1.79-.8-1.79-1.787 0-.987.802-1.788 1.79-1.788a1.789 1.789 0 110 3.575z"
        fill="#025879"
      />
      <path
        fill="none"
        d="M53.7 39.79c-1.718 4.834-8.89 7.503-14.606 4.834-5.717-2.67-6.493-6.107-6.874-9.666"
        stroke="#025879"
        strokeLinecap="round"
      />
      <path
        fill="none"
        d="M59.07 55.176c3.856 1.354 9.869 4.617 15.38 2.84 3.789-1.221 12.818-8.42 13.543-8.986 1.263-.985-2-5.806-.737-6.792 1.263-.985 1.918 2.45 2.865 1.712.948-.74 4.113-1.893 5.392-.26 1.278 1.634-.744 7.104-7.52 5.34M27.415 52.864c-5.521-1.29-12.82-1.39-15.96-5.11-3.707-4.394-5.41-10.973-5.696-11.8-.518-1.503 4.492-3.646 3.974-5.15-.519-1.503-2.754.947-3.143-.18-.388-1.128-2.343-3.819-4.179-3.188-1.836.632-2.21 8.28 3.348 8.518M48.714 90.362s1.406 8.36 1.406 12.098c0 3.14-5.37 10.999-5.37 13.919 0 1.256 9.166 3.694 10.57 1.999.832-1.004-1.552-3.192-3.633-3.821-1.38-.416-6.937 1.822-6.937 1.822M33.413 90.362c-.362 2.578-1.44 8.97-1.193 13.371.262 4.677 2.884 9.251 2.233 10.895 0 0 2.526 2.127.316 3.191-2.21 1.063-6.129 1.915-6.129 0 0-1.916 2.848-3.835 5.813-3.191"
        stroke="#025879"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.819 80.502s2.83 10.762 1.402 13.016c-1.866 2.945-8.16 2.09-9.416 2.09-2.255 0-1.362-7.753-2.959-7.753-1.596 0-1.436 7.753-3.32 7.753-.678 0-8.415-.442-8.788-2.09-.662-2.924 2.198-11.409 3.475-13.911 1.995-3.913 19.606.895 19.606.895z"
        fill="#555555"
        stroke="#025879"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.272 50.867c.06-.008.121-.01.182-.006l8.546.532a1 1 0 01.885 1.318l-2.81 8.305c-.179.53-.758.812-1.29.635-2.054-.682-6.679-2.085-6.7-.905-.156 8.963.941 17.176 2.439 19.206 2.053 2.781-23.811 1.192-23.811-1.192 0-1.688 1.91-10.055 2.853-18.014.075-.634-6.136-.966-8.284-1.062a.971.971 0 01-.907-.728c-.358-1.404-1.105-4.672-1.105-7.482 0-3.544 9.475-1.426 10.246-1.248a.958.958 0 00.107.018l5.052.612s2.712 2.87 5.175 2.87c2.463 0 4.404-2.252 4.404-2.252l5.018-.607z"
        fill="#A6C4CE"
        stroke="#025879"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default SuccessIllustration;
