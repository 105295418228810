import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { pathOr } from "ramda";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import ServiceMetaIcon from "../../atoms/ServiceMetaIcon";
import ClickBox from "../../atoms/ClickBox";
import ResearchStatusIcon from "../../molecules/ResearchStatusIcon";
import {
  Service,
  ServiceStatus,
  ServiceResearchObjectStatus,
  ServiceType,
} from "../../../generated/graphql";
import helper from "../../../utils/researchStatusHelper";
import useStyles from "../boxListStyles";
import SmallOutlinedChip from "../../atoms/SmallOutlinedChip";
import Markup from "../../atoms/Markup";
import { statusLabel, serviceTarget } from "../../../utils/serviceHelper";
import emptyService from "../../assets/emptyService.svg";

const Item: FC<{ service: Service; mb?: number }> = ({ service, mb = 2 }) => {
  const classes = useStyles();
  const { push } = useHistory();
  dayjs.extend(LocalizedFormat);
  const name: string = pathOr("", ["serviceMeta", "name"], service);
  const icon: string = pathOr("", ["serviceMeta", "iconName"], service);
  const type: ServiceType = pathOr(ServiceType.research, ["serviceMeta", "type"], service);
  const duedate: string | undefined = service.duedate || undefined;
  const problem: string | undefined = service.problem || undefined;
  const status: ServiceStatus = pathOr(ServiceStatus.research, ["status"], service);
  const open: number = pathOr(0, ["openCount"], service);
  const limited: number = pathOr(0, ["limitedCount"], service);
  const suitable: number = pathOr(0, ["suitableCount"], service);
  const unsuitable: number = pathOr(0, ["unsuitableCount"], service);
  const label = statusLabel(status);
  const target = serviceTarget(service);
  return (
    <ClickBox handleClick={target ? () => push(target) : () => undefined}>
      <Box pr={2} flex={1}>
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <ServiceMetaIcon name={icon} />
          </Box>
          <Box display="flex" justifyContent="center" flexDirection="column">
            <Box display="flex" alignItems="center">
              <Typography variant="h3" color="primary">
                {name}
              </Typography>
              <Box ml={2}>
                <Chip
                  label={label}
                  color={status !== ServiceStatus.finished ? "primary" : "default"}
                  classes={{ root: classes.chip, label: classes.chipLabel }}
                />
              </Box>
              <Box ml={2}>
                {duedate && <SmallOutlinedChip label={dayjs(duedate).format("L")} />}
              </Box>
            </Box>
            {type !== ServiceType.researchBlank &&
              type !== ServiceType.research &&
              problem &&
              problem.length > 2 && (
                <Box mt={2}>
                  <Markup value={problem} />
                </Box>
              )}
          </Box>
        </Box>
        {(type === ServiceType.research || type === ServiceType.researchBlank) && (
          <Box display="flex" alignItems="center" mt={3}>
            <Box display="flex" alignItems="center" mr={3}>
              <ResearchStatusIcon status={ServiceResearchObjectStatus.suitable} />
              <Box ml={1}>
                <Typography variant="body2">
                  {helper(ServiceResearchObjectStatus.suitable)}
                </Typography>
              </Box>
              <Box ml={1}>
                <Typography variant="body2">{suitable}</Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" mr={3}>
              <ResearchStatusIcon status={ServiceResearchObjectStatus.limited} />
              <Box ml={1}>
                <Typography variant="body2">
                  {helper(ServiceResearchObjectStatus.limited)}
                </Typography>
              </Box>
              <Box ml={1}>
                <Typography variant="body2">{limited}</Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" mr={3}>
              <ResearchStatusIcon status={ServiceResearchObjectStatus.open} />
              <Box ml={1}>
                <Typography variant="body2">{helper(ServiceResearchObjectStatus.open)}</Typography>
              </Box>
              <Box ml={1}>
                <Typography variant="body2">{open}</Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" mr={3}>
              <ResearchStatusIcon status={ServiceResearchObjectStatus.unsuitable} />
              <Box ml={1}>
                <Typography variant="body2">
                  {helper(ServiceResearchObjectStatus.unsuitable)}
                </Typography>
              </Box>
              <Box ml={1}>
                <Typography variant="body2">{unsuitable}</Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </ClickBox>
  );
};

export const List: FC<{ services: Service[] }> = ({ services }) => {
  return (
    <>
      {services.map(service => (
        <Item service={service} key={`service-quick-${service.id}`} />
      ))}
    </>
  );
};

const ServiceList: FC<{ services: Service[] }> = ({ services }) => {
  return (
    <Box mt={4} width={1}>
      {services.length > 0 ? (
        <List services={services} />
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box mt={2} mb={4}>
            <img src={emptyService} alt="no topics" />
          </Box>
          <Typography variant="body2">Für dieses Thema gibt es noch keinen Service.</Typography>
        </Box>
      )}
    </Box>
  );
};

export default ServiceList;
