import React, { FC } from "react";

import { PersonActivity } from "../../../generated/graphql";

import DefaultBubble from "./Bubbles/DefaultBubble";
import DocumentBubble from "./Bubbles/DocumentBubble";
import CMCheckBubble from "./Bubbles/CMCheckBubble";
import MandateBubble from "./Bubbles/MandateBubble";
import PersonBubble from "./Bubbles/PersonBubble";
import InteractionBubble from "./Bubbles/InteractionBubble";
import ServiceBubble from "./Bubbles/ServiceBubble";
import SmsBubble from "./Bubbles/SmsBubble";

type TBubbleType = {
  activity: PersonActivity;
  isLink?: boolean;
};

export const BubbleType: FC<TBubbleType> = ({ activity, isLink }) => {
  let topic;  

  switch (activity.activityType) {
    case "sms":
      return <SmsBubble isLink={isLink} activity={activity} />;
    case "service":
      return <ServiceBubble isLink={isLink} activity={activity} />;
    case "relationship":
    case "person":
      return <PersonBubble isLink={isLink} activity={activity} />;
    case "mandate":
      topic = activity.topic!;
      return <MandateBubble isLink={isLink} activity={activity} topic={topic} />;
    case "cmCheck":
      return <CMCheckBubble isLink={isLink} activity={activity} />;
    case "driveDocument":
      return <DocumentBubble isLink={isLink} activity={activity} />;
    case "callOut":
    case "callIn":
    case "chat":      
    case "mail":      
    case "wpoVerweisung":
      return <InteractionBubble isLink={isLink} activity={activity} />;
    default:
      return <DefaultBubble isLink={isLink} activity={activity} />;
  }
};

export default BubbleType;
