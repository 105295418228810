import React from "react";

import sortBy from "lodash/sortBy";
import { pathOr } from "ramda";
import { Redirect } from "react-router";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import { Service, Person, ServiceType } from "../../../generated/graphql";
import useStyles from "../reportStyles";

import PersonSelector from "./PersonSelector";
import ValueSelector from "./ValueSelector";
import TextInput from "./TextInput";
import DateInput from "./DateInput";
import AddressSelector from "./AddressSelector";
import TimesSelector from "./TimesSelector";
import NumberSelector from "./NumberSelector";
import Finalize from "./Finalize";

interface Props {
  backAction: () => void;
  service: Service;
  person: Person;
  reportId?: string | null;
  mainHeadline?: string;
  step: number;
  setStep: (step: number) => void;
  update: () => void;
  isReloading: boolean;
}

export default function ServiceWizard({
  service,
  person,
  step,
  setStep,
  update,
  reportId,
  backAction,
  mainHeadline,
  isReloading,
}: Props) {
  const classes = useStyles();
  const serviceName: string = pathOr("", ["serviceMeta", "name"], service);
  const headline = () => {
    const { serviceMeta } = service;
    return serviceMeta &&
      serviceMeta.serviceMetaAttributes &&
      step < serviceMeta.serviceMetaAttributes.length
      ? sortBy(serviceMeta.serviceMetaAttributes, ["sortIdx"])[
          step % serviceMeta.serviceMetaAttributes.length
        ].headline
      : `Geschafft, der Service ${serviceName} wurde erfolgreich angelegt.`;
  };
  const renderStep = () => {
    const { serviceMeta } = service;
    const length =
      serviceMeta && serviceMeta.serviceMetaAttributes
        ? serviceMeta.serviceMetaAttributes.length
        : 0;
    const type = serviceMeta && serviceMeta.type ? serviceMeta.type : ServiceType.research;
    const currentStep =
      serviceMeta && serviceMeta.serviceMetaAttributes
        ? sortBy(serviceMeta.serviceMetaAttributes, ["sortIdx"])[step]
        : null;
    if (step >= length && !!reportId) {
      localStorage.removeItem("activeReport");
      return <Redirect to={`/person/${person.id}/report/${reportId}`} />;
    }
    if (step >= length && type === ServiceType.internalConsult) {
      const topicPath = service.topic && service.topic.id ? `/topics/${service.topic.id}` : "";
      return <Redirect to={`/person/${person.id}${topicPath}`} />;
    }
    if (step >= length && type === ServiceType.externalConsult) {
      return <Redirect to={`/service/${service.id}`} />;
    }
    if (step >= length || currentStep === null) {
      return <Finalize person={person} backAction={backAction} />;
    }
    switch (currentStep.type) {
      case "person":
        return (
          <PersonSelector
            key={`person-input-step-${step}-${currentStep.name}`}
            update={update}
            person={person}
            service={service}
            serviceMetaAttribute={currentStep}
            setStep={setStep}
            step={step}
            backAction={backAction}
            isReloading={isReloading}
          />
        );
      case "number":
      case "currency":
        return (
          <NumberSelector
            key={`number-input-step-${step}-${currentStep.name}`}
            update={update}
            person={person}
            service={service}
            serviceMetaAttribute={currentStep}
            setStep={setStep}
            step={step}
            backAction={backAction}
          />
        );
      case "select":
      case "selectMulti":
        return (
          <ValueSelector
            key={`value-select-step-${step}-${currentStep.name}`}
            update={update}
            person={person}
            service={service}
            serviceMetaAttribute={currentStep}
            setStep={setStep}
            step={step}
            backAction={backAction}
          />
        );
      case "date":
        return (
          <DateInput
            key={`date-input-step-${step}-${currentStep.name}`}
            update={update}
            person={person}
            service={service}
            serviceMetaAttribute={currentStep}
            setStep={setStep}
            step={step}
            backAction={backAction}
          />
        );
      case "text":
        return (
          <TextInput
            key={`text-input-step-${step}-${currentStep.name}`}
            update={update}
            person={person}
            service={service}
            serviceMetaAttribute={currentStep}
            setStep={setStep}
            step={step}
            backAction={backAction}
          />
        );
      case "address":
        return (
          <AddressSelector
            key={`address-input-step-${step}-${currentStep.name}`}
            update={update}
            person={person}
            service={service}
            serviceMetaAttribute={currentStep}
            setStep={setStep}
            step={step}
            backAction={backAction}
            isReloading={isReloading}
          />
        );
      case "hours":
        return (
          <TimesSelector
            key={`times-input-step-${step}-${currentStep.name}`}
            update={update}
            person={person}
            service={service}
            serviceMetaAttribute={currentStep}
            setStep={setStep}
            step={step}
            backAction={backAction}
            isReloading={isReloading}
          />
        );
      default:
        return <>WIP</>;
    }
  };

  return (
    <Grid container className={classes.contentWrapper}>
      <Grid item xs={12}>
        <Box mb={2}>
          <Typography variant="body2" align="center">
            {Boolean(mainHeadline)
              ? mainHeadline
              : `Service ${serviceName} für ${person.name} erstellen`}
          </Typography>
        </Box>
        <Typography variant="h1" className={classes.headline}>
          {headline()}
        </Typography>
      </Grid>
      <Grid item xs={2} xl={3} />
      <Grid item xs={8} xl={6}>
        <Box display="flex" justifyContent="center" width="100%">
          <Box mt={4} maxWidth={900} width="100%">
            {renderStep()}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={2} xl={3} />
    </Grid>
  );
}
