import React from "react";
import DialogForm from "../DialogForm";
import Grid from "@material-ui/core/Grid";
import SlateRTE, { initialFormValue } from "../../molecules/SlateRTE";
import { Field, FieldProps } from "formik";
import { BusinessObject, useUpdateBusinessObjectMutation } from "../../../generated/graphql";

type TBusinessEditDialog = {
  business: BusinessObject;
  open: boolean;
  toggle: () => void;
};

const BusinessEditDialog = ({ business, open, toggle }: TBusinessEditDialog) => {
  const [update] = useUpdateBusinessObjectMutation();
  return (
    <DialogForm
      open={open}
      initialValues={{
        info: business.info || initialFormValue,
        memo: business.memo || initialFormValue,
      }}
      onClose={toggle}
      title="Sonstiges bearbeiten"
      onSubmit={values => {        
        return update({
          variables: {
            id: business.id!,
            input: {
              info: values.info,
              memo: values.memo,
            },
          },
        }).then(() => {
          toggle();
        });
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field
            name="info"
            render={({ form: { setFieldValue, values } }: FieldProps) => {
              return (
                <SlateRTE
                  value={values.info}
                  handleChange={input => setFieldValue("info", input)}
                  label="Sonstiges"
                  tabIndex={1}
                />
              );
            }}
          ></Field>
        </Grid>
        <Grid item xs={12}>
          <Field
            name="memo"
            render={({ form: { setFieldValue, values } }: FieldProps) => {
              return (
                <SlateRTE
                  value={values.memo}
                  handleChange={input => setFieldValue("memo", input)}
                  label="Internes Memo"
                  tabIndex={2}
                />
              );
            }}
          ></Field>
        </Grid>
      </Grid>
    </DialogForm>
  );
};

export default BusinessEditDialog;
