import React, { useState } from "react";

import { MutationFunctionOptions } from "@apollo/client";
import { ExecutionResult } from "graphql";

import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { Relationship, ServiceClient, Maybe, Client } from "../../../../../generated/graphql";
import PrimaryButton from "../../../../atoms/PrimaryButton";
import SecondaryButton from "../../../../atoms/SecondaryButton";
import WizardNavigation from "../../../../molecules/WizardNavigation";

interface PersonListProps {
  connected: Maybe<ServiceClient>[];
  multiple?: boolean | null | undefined;
  createSC: (client: Client) => Promise<void>;
  deleteSC: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<ExecutionResult<any>>;
  next: () => void;
  backAction: () => void;
  openForm: (arg0: boolean) => void;
  relations: Relationship[];
  required?: boolean | null | undefined;
  callback: () => void;
  isReloading: boolean;
}

export default function PersonList({
  backAction,
  callback,
  connected,
  createSC,
  deleteSC,
  isReloading,
  multiple,
  next,
  openForm,
  relations,
  required,
}: PersonListProps) {
  const disabled = !multiple ? connected.length > 0 : false;

  return (
    <>
      <Box display="flex" justifyContent="center">
        <div>
          {relations.map((relation: Relationship, index: number) => (
            <Relative
              tabIndex={index + 1}
              key={`relative-${relation.id}-service`}
              callback={callback}
              connected={connected}
              createSC={createSC}
              deleteSC={deleteSC}
              disabled={disabled}
              isReloading={isReloading}
              relation={relation}
            />
          ))}
          <Box marginTop={2}>
            <Link
              onClick={() => openForm(true)}
              underline="always"
              variant="h4"
              color="primary"
              tabIndex={relations.length + 1}
            >
              weitere Person anlegen
            </Link>
          </Box>
        </div>
      </Box>
      <WizardNavigation>
        <SecondaryButton onClick={() => backAction()} tabIndex={relations.length + 3}>
          Zurück
        </SecondaryButton>
        <PrimaryButton
          disabled={!!required && connected.length === 0}
          onClick={next}
          tabIndex={relations.length + 2}
        >
          Weiter
        </PrimaryButton>
      </WizardNavigation>
    </>
  );
}

function Relative({
  callback,
  connected,
  createSC,
  deleteSC,
  disabled,
  relation,
  isReloading,
  tabIndex,
}: {
  disabled: boolean;
  callback: () => void;
  connected: Maybe<ServiceClient>[];
  createSC: (client: Client) => Promise<void>;
  deleteSC: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<ExecutionResult<any>>;
  relation: Relationship;
  isReloading: boolean;
  tabIndex?: number;
}) {
  const [submitting, setSubmitting] = useState(false);
  const passivePerson = relation.passivePerson;

  if (passivePerson == null) {
    return <></>;
  }

  const client = passivePerson.client;

  if (client == null) {
    return <></>;
  }

  const selected = connected.find((item: any) => item.clientId === client.id);

  const handleChange = async () => {
    if (isReloading || submitting) {
      return;
    }
    setSubmitting(true);

    if (selected) {
      await deleteSC({ variables: { id: selected.id } });
      setSubmitting(false);
      return callback();
    }
    await createSC(client);
    setSubmitting(false);
  };

  return (
    <Box mb={2} key={`${relation.id}`} style={{ marginBottom: 8 }}>
      <FormControlLabel
        disabled={isReloading || submitting || (disabled && !selected)}
        control={<Checkbox checked={!!selected} onChange={handleChange} tabIndex={tabIndex} />}
        label={`${passivePerson.name}, ${passivePerson.age} Jahre`}
      />
    </Box>
  );
}
