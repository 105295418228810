import React, { useState, useContext } from "react";

import dayjs from "dayjs";
import Box from "@material-ui/core/Box";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";

import { PapershiftShift, User, Appointment } from "../../../../generated/graphql";
import { ShiftRowWrapper } from "../../../organisms/ShiftRowWrapper";
import { sortShiftsByUserAlias, sortByWorkingArea } from "./helper";
import { FilterState } from "../reducer";
import WorkingAreaPadding from "../../Shift/ShiftCalendar/components/WorkingAreaPadding";
import WorkingAreasSeparator from "../../Shift/ShiftCalendar/components/WorkingAreaSeparator";
import { PersonContext } from "../../../App";
import { useAppointmentSubscription } from "../../../../hooks/useAppointmentSubscription";

/**
 *
 */
interface IDay {
  filter: FilterState;
  day: dayjs.Dayjs;
  shifts: PapershiftShift[];
  validRange: {
    startsAt: dayjs.Dayjs;
    endsAt: dayjs.Dayjs;
  };
}

// TODO move to Providers
export const CurrentIndex = React.createContext({
  selectedIndex: -1,
  setIndex: (index: number) => {},
});

const Day = ({ day, filter, shifts, validRange }: IDay) => {
  const [index, setIndex] = useState(-1);
  const { setUsers } = useContext(PersonContext);

  const state = {
    selectedIndex: index,
    setIndex: (index: number) => {
      const shiftStart = dayjs()
        .startOf("day")
        .add(6, "hour");

      let time;
      if (index % 2 === 0) {
        time = shiftStart.add(index / 2, "hour");
      } else {
        time = shiftStart.add(Math.floor(index / 2), "hour").add(30, "minute");
      }

      // ADJUST HERE!
      if (validRange && validRange.startsAt && validRange.endsAt) {
        const timeIndex = time.hour() * 60 + time.minute();
        // ADJUST HERE!
        const startIndex = validRange.startsAt.hour() * 60 + validRange.startsAt.minute();
        // ADJUST HERE!
        const endIndex = validRange.endsAt.hour() * 60 + validRange.endsAt.minute();

        if (timeIndex >= startIndex && timeIndex < endIndex) {
          return setIndex(index);
        } else {
          return setIndex(-1);
        }
      } else {
        return setIndex(-1);
      }
    },
  };

  return (
    <Box textAlign="center">
      <Box height={32}>
        <Link
          component={RouterLink}
          underline="always"
          onClick={() => setUsers(filter.users)}
          to={`/shift/${day.diff(dayjs(), "day") + 2}`}
          variant="caption"
        >
          {day.format("dd. DD.MM.")}
        </Link>
      </Box>
      <CurrentIndex.Provider value={state}>
        <Box
          display="flex"
          onMouseEnter={() => {}}
          onMouseLeave={() => {
            setIndex(-1);
          }}
        >
          <ShiftRowWrapperContainer
            day={day}
            filter={filter}
            shifts={shifts}
            validRange={validRange}
          />
        </Box>
      </CurrentIndex.Provider>
    </Box>
  );
};

export default Day;

interface IShiftRowWrapperContainer {
  day: dayjs.Dayjs;
  filter?: FilterState;
  shifts: PapershiftShift[];
  validRange?: {
    startsAt?: dayjs.Dayjs;
    endsAt?: dayjs.Dayjs;
  };
}

const ShiftRowWrapperContainer = ({
  day,
  filter,
  shifts,
  validRange,
}: IShiftRowWrapperContainer) => {
//   shifts.sort(sortShiftsByUserAlias);
//   shifts.sort(sortByWorkingArea);

  let userIds: number[] = [];
  if (!!filter) {
    new Map([...filter.externalUsers, ...filter.users]).forEach(u => {
      // @ts-ignore
      userIds.push(u.id);
    });
  } else {
    // @ts-ignore
    userIds = shifts.map(shift => {
      // @ts-ignore
      const user: User = shift.users[0];

      return user.id;
    });
  }

  // This is a custom hook, alse used in <ShiftRowWrapperContainer>
  const { data, error, loading, refetch, variables } = useAppointmentSubscription({
    userIds: userIds,
    shift: shifts[0],
  });

  if (loading) {
    return <></>;
  }

  if (error || !data) {
    return <></>;
  }

  const appointmentsByUsers = data.appointmentsByUsers as Appointment[] | undefined;

  if (!appointmentsByUsers) {
    return <></>;
  }

  const users = shifts.map((shift, index) => {
    // Has been checked in the filter above!
    // @ts-ignore
    const user: User = shift.users[0];

    const appointments = appointmentsByUsers.filter(appointment => {
      if (!appointment.users) {
        return false;
      }

      return appointment.users.some(u => {
        if (!u || !u.id) {
          return false;
        }

        return u.id === user.id;
      });
    });

    if (!shift.papershiftWorkingArea) {
      return <></>;
    }

    return (
      <ShiftRowWrapper
        appointments={appointments}
        appointmentsByUsers={appointmentsByUsers}
        day={day}
        filter={filter}
        isFirstColumn={index === 0}
        isLastColumn={index === shifts.length - 1}
        key={`${shift.id}-${user.id}`}
        refetch={() => refetch(variables)}
        shift={shift}
        user={user}
        workingArea={shift.papershiftWorkingArea}
        validRange={validRange}
      />
    );
  });

  const firstWorkingArea = shifts[0].papershiftWorkingArea;
  const lastWorkingArea = shifts[shifts.length - 1].papershiftWorkingArea;

  return (
    <Box display="flex" padding={0}>
      {firstWorkingArea && <WorkingAreaPadding workingArea={firstWorkingArea} />}
      {users}
      {lastWorkingArea && <WorkingAreaPadding workingArea={lastWorkingArea} />}
      <WorkingAreasSeparator />
    </Box>
  );
};
