import dayjs from "dayjs";

const isToday = (date: dayjs.Dayjs) => date.isSame(dayjs(), "day");

const isTomorrow = (date: dayjs.Dayjs) => date.isSame(dayjs().add(1, "day"), "day");

const isYesterday = (date: dayjs.Dayjs) => date.isSame(dayjs().subtract(1, "day"), "day");

const isCurrentMonth = (date: dayjs.Dayjs) => date.isSame(dayjs(), "month");

const isUniqueDayInCurrentMonth = (day: dayjs.Dayjs, start: dayjs.Dayjs) =>
  day ? isCurrentMonth(start) && start.isBefore(day, "day") : true;

const isNextMonth = (month: dayjs.Dayjs, start: dayjs.Dayjs) =>
  month
    ? start.isAfter(dayjs().subtract(12, "month"), "month") && start.isBefore(month, "month")
    : true;

const isNextYear = (year: dayjs.Dayjs, start: dayjs.Dayjs) =>
  year ? start.isBefore(year, "year") : true;

const getDay = (day: dayjs.Dayjs, start: dayjs.Dayjs) =>
  !day || start.isBefore(day, "day") ? start.startOf("day") : day;

const getMonth = (month: dayjs.Dayjs, start: dayjs.Dayjs) =>
  !month || start.isBefore(month, "month") ? start.startOf("month") : month;

const getYear = (year: dayjs.Dayjs, start: dayjs.Dayjs) =>
  !year || start.isBefore(year, "year") ? start.startOf("year") : year;

const isBefore12Month = (start: dayjs.Dayjs) =>
  start.isBefore(dayjs().subtract(12, "month"), "month");

const isBeforeYesterday = (start: dayjs.Dayjs) => start.isBefore(dayjs().subtract(1, "day"), "day");

const addWorkinngDays = (date: dayjs.Dayjs, days: number) => {
  switch (date.day()) {
    case 3:
    case 4:
    case 5:
      return date.add(days + 2, "day");
    case 6:
      return date.add(days + 1, "day");
    default:
      return date.add(days, "day");
  }
};

export {
  addWorkinngDays,
  getDay,
  getMonth,
  getYear,
  isBefore12Month,
  isBeforeYesterday,
  isCurrentMonth,
  isNextMonth,
  isNextYear,
  isToday,
  isTomorrow,
  isUniqueDayInCurrentMonth,
  isYesterday,
};
