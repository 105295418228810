import React from "react";

import IconButton from "@material-ui/core/IconButton";

import absenceIcon from "../../assets/absenceIcon.svg";

type TAbsenceButton = { onClick: () => void };

const AbsenceButton = ({ onClick }: TAbsenceButton) => {
  return (
    <IconButton onClick={onClick}>
      <img src={absenceIcon} alt="Mitarbeiter als abwesend eintragen" />
    </IconButton>
  );
};

export default AbsenceButton;
