import React, { useState } from "react";

import dayjs from "dayjs";
import AccessTime from "@material-ui/icons/AccessTime";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";

import { Hour, Maybe } from "../../../../generated/graphql";

import HoursDialog from "./HoursDialog";

function formatGroupedHours(
  groupedHours: {
    days: any[];
    close: any;
    open: any;
  }[],
) {
  return groupedHours.map(collected => {
    collected.days.sort((a, b) => {
      return a - b;
    });

    if (collected.days.length < 2) {
      const open = dayjs(collected.open).format("H:mm");
      const close = dayjs(collected.close).format("H:mm");
      const day = dayjs()
        .day(collected.days[0] + 1)
        .format("dd.");

      return `${day} ${open} - ${close} Uhr`;
    }
    const isConsecutive = collected.days.reduce((carry, item, index) => {
      if (index >= collected.days.length - 1) {
        return carry;
      }

      return carry && collected.days[index + 1] - item === 1;
    }, true);

    const open = dayjs(collected.open).format("H:mm");
    const close = dayjs(collected.close).format("H:mm");

    if (isConsecutive) {
      return (
        [
          dayjs()
            .day(collected.days[0] + 1)
            .format("dd."),
          dayjs()
            .day(collected.days[collected.days.length - 1] + 1)
            .format("dd."),
        ].join(collected.days.length > 2 ? " - " : ", ") + ` ${open} - ${close} Uhr`
      );
    } else {
      return (
        collected.days
          .map(day =>
            dayjs()
              .day(day + 1)
              .format("dd."),
          )
          .join(", ") + ` ${open} - ${close} Uhr`
      );
    }
  });
}

function groupHours(hours: Maybe<Hour>[]) {
  const init: { days: any[]; close: any; open: any }[] = [];

  return hours.reduce((carry, hour) => {
    if (!hour) {
      return carry;
    }

    const index = carry.findIndex(item => {
      return (
        dayjs(item.close).isSame(dayjs(hour.close)) && dayjs(item.open).isSame(dayjs(hour.open))
      );
    });

    if (index > -1) {
      carry[index].days.push(hour.day);
    } else {
      carry.push({
        days: [hour.day],
        open: hour.open,
        close: hour.close,
      });
    }

    return carry;
  }, init);
}

function getFormattedHours(hours: Maybe<Hour>[], onClick: any) {
  const groupedHours = groupHours(hours);

  groupedHours.sort((a, b) => {
    const minA = Math.min.apply(Math, a.days);
    const minb = Math.min.apply(Math, b.days);

    if (minA > minb) {
      return 1;
    } else if (minA < minb) {
      return -1;
    } else {
      return 0;
    }
  });

  const formattedGroupedHours = formatGroupedHours(groupedHours);

  return formattedGroupedHours.map(group => {
    return (
      <Box key={`address-label-${group}`}>
        <Link onClick={onClick} variant="h4" underline="always">
          {group}
        </Link>
      </Box>
    );
  });
}

interface HoursFieldProps {
  formUntouched: boolean;
  hours: Hour[];
  setFieldValue: any;
  tabIndex?: number;
}

export default function HoursField({
  formUntouched,
  hours,
  setFieldValue,
  tabIndex = 0,
}: HoursFieldProps) {
  const [hoursDialogIsOpen, toggleHoursDialog] = useState(false);

  let hoursLabel = [
    <Link
      onClick={() => toggleHoursDialog(!hoursDialogIsOpen)}
      key="address-label"
      tabIndex={tabIndex}
      variant="h4"
      underline="always"
    >
      Öffnungszeiten
    </Link>,
  ];

  if (!!hours) {
    const formattedHours = getFormattedHours(hours, () => toggleHoursDialog(!hoursDialogIsOpen));

    if (formattedHours.length > 0) {
      hoursLabel = formattedHours;
    }
  }

  return (
    <>
      <Box marginRight={1} height={24}>
        <AccessTime color="primary" />
      </Box>{" "}
      <div>{hoursLabel}</div>
      <HoursDialog
        open={hoursDialogIsOpen}
        formUntouched={formUntouched}
        toggle={toggleHoursDialog}
        hours={hours}
        setFieldValue={setFieldValue}
      />
    </>
  );
}
