import { Person } from "../generated/graphql";
export function getUserName(person?: Person | null) {
  if (!person) {
    return "";
  }
  if (!person!.alias || person!.alias === person!.name) {
    return person!.name;
  }
  return `${person!.name} (${person!.alias})`;
}
