import React from "react";
import { Dayjs } from "dayjs";
import { Dialog, DialogTitle } from "@material-ui/core";
import { TimePicker } from "@material-ui/pickers";

interface TimeDialogProps {
  handleChange: (date: any) => void;
  handleClose: () => void;
  handleDone: (date: any) => void;
  handleOpen: () => void;
  label: string;
  show: boolean;
  value: Dayjs;
}

export default function TimeDialog({
  handleChange,
  handleClose,
  handleDone,
  handleOpen,
  label,
  show,
  value,
}: TimeDialogProps) {
  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle>{label}</DialogTitle>
      <TimePicker
        ampm={false}
        cancelLabel="Abbrechen"
        okLabel="OK"
        onAccept={date => date && handleDone(date)}
        onChange={date => date && handleChange(date)}
        onClose={handleClose}
        onOpen={handleOpen}
        open
        value={value}
      />
    </Dialog>
  );
}
