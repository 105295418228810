import reduce from "lodash/reduce";
import isArray from "lodash/isArray";

export function initialValuesHelper(object: any): object {
  return reduce(
    object,
    (acc, val, key) => {
      if (val === null || val === undefined) {
        (acc as any)[key] = "";
      } else if (val && typeof val === "object") {
        if (isArray(val)) {
          (acc as any)[key] = val.map(subVal => initialValuesHelper(subVal));
        } else {
          (acc as any)[key] = initialValuesHelper(val);
        }
      } else {
        if (["lon", "readableNumber", "lat"].includes(key)) {
          // not editable by formik so null will be ok
          (acc as any)[key] = undefined;
        } else {
          (acc as any)[key] = val || typeof val === "boolean" || typeof val === "number" ? val : "";
        }
      }

      return acc;
    },
    {},
  );
}
