import React from "react";

import classnames from "classnames";
import FlagIcon from "@material-ui/icons/Flag";

import useStyles from "./styles";

type TFlag = {
  red: boolean;
  yellow: boolean;
  white?: boolean;
  unflagged?: boolean;
};

const Flag = ({ red, yellow, white, unflagged }: TFlag) => {
  const classes = useStyles();

//   if (unflagged) {
//     return <Unflagged />;
//   }

  if (white) {
    return <WhiteFlag />;
  }

  return (
    <FlagIcon
      className={classnames({
        [classes.red]: red,
        [classes.yellow]: yellow,
      })}
    ></FlagIcon>
  );
};

export default Flag;

const Unflagged = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1213 8.12124L27.7071 6.70703L6.4939 27.9202L7.90811 29.3344L29.1213 8.12124ZM14.9999 11H21.9999L19.9999 13H14.9999V18L12.9999 20V19V13V11H14.9999ZM20.5999 19H19.6568L17.6568 21H20.5999L20.9999 23H25.9999H27.9999V21V15V13H25.9999H25.6568L23.6568 15H25.9999V21H22.6396L22.5611 20.6078L22.2396 19H20.5999ZM12.9999 25.6569L14.9999 23.6569V26V28H12.9999V26V25.6569Z"
        fill="#025879"
      />
    </svg>
  );
};

const WhiteFlag = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.4 13H27V23H20L19.6 21H14V28H12V11H21L21.4 13Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7604 15L19.3604 13H14V19H21.2396L21.6396 21H25V15H19.7604ZM20 23L19.6 21H14V28H12V11H21L21.4 13H27V23H20Z"
        fill="#596165"
      />
    </svg>
  );
};
