import React, { FC } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../atoms/Loading";
import PersonLayout from "../../templates/PersonLayout";

import STCDashboard from "../../organisms/STCDashboard";

import useScrollTop from "../../../hooks/useScrollTop";
import { useServiceSubscription } from "../../../hooks/useServiceSubscription";
import { useMandateByServiceQuery } from "../../../generated/graphql";
import { fetchPolicy } from "../../../utils/constants";
import ErrorFallback, { CustomError } from "../../molecules/ErrorFallback/ErrorFallback";

const STCDetails: FC = () => {
  useScrollTop();
  const { serviceId } = useParams<{ serviceId: string }>();

  const { service, loading, error } = useServiceSubscription(parseInt(serviceId, 10));

  const { data, error: mandatesError, loading: loadingMandate } = useMandateByServiceQuery({
    variables: { serviceId: parseInt(serviceId, 10) },
    fetchPolicy,
  });

  if (loading || loadingMandate) {
    return <Loader />;
  }

  if (error) {
    return <ErrorFallback error={new CustomError("Fehler beim Laden der Service Daten.", error)} />;
  }

  if (mandatesError) {
    return (
      <ErrorFallback
        error={new CustomError("Fehler beim Laden der Mandats Daten.", mandatesError)}
      />
    );
  }

  if (!service) {
    return (
      <div>
        <div>Beratung wurde nicht gefunden.</div>
      </div>
    );
  }
  const person = service?.client?.person;

  if (!person || !person.id) {
    return (
      <div>
        <div>Person wurde nicht gefunden</div>
      </div>
    );
  }
  const namePathMap = [
    {
      // @ts-ignore
      name: service.topic.name,
      // @ts-ignore
      path: `/person/${person.id}/topics/${service.topic.id}`,
    },
    {
      // @ts-ignore
      name: service?.serviceMeta?.name,
      // @ts-ignore
      path: `/${service?.serviceMeta?.type}/${service?.id}`,
    },
  ];
  return (
    <PersonLayout
      person={person}
      mapList={namePathMap}
      serviceId={parseInt(serviceId, 10)}
      topicId={service.topic?.id ?? undefined}
    >
      <STCDashboard
        service={service}
        refNumber={service.id! + 400000000}
        mandate={data?.mandateByService || undefined}
      />
    </PersonLayout>
  );
};

export default STCDetails;
