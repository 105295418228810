import React, { ReactNode, FC } from "react";

import { useMutation } from "@apollo/client";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import {
  Service,
  ServiceStatus,
  UpdateServiceDocument,
  CreateMandateDocument,
  ServiceType,
  useServiceResearchObjectsQuery,
  PersonQuery,
} from "../../../generated/graphql";
import Loading from "../../atoms/Loading";
import { NamePathMap } from "../../molecules/Breadcrumb";
import IllustratedErrorMessage from "../../organisms/IllustratedErrorMessage";
import PrimaryButton from "../../atoms/PrimaryButton";
import errorIllustration from "../../assets/errorIllustration.svg";
import ErrorFallback from "../../molecules/ErrorFallback/ErrorFallback";

import Header from "./Header";
import Requirements from "./Requirements";

interface TServiceTemplate {
  backPath?: string;
  button?: ReactNode;
  mapPathList?: NamePathMap[];
  headline?: string;
  person: PersonQuery["person"];
  refetchService: () => void;
  service: Service;
  contractExpirationDate?: string;
  language?: string;
}

const ServiceTemplate: FC<TServiceTemplate> = ({
  backPath,
  button,
  children,
  mapPathList,
  person,
  refetchService,
  service,
  headline,
  contractExpirationDate,
  language,
}) => {
  const { goBack } = useHistory();
  const serviceResearchObjectsQuery = useServiceResearchObjectsQuery({
    variables: { serviceId: service.id },
  });
  const [updateService] = useMutation(UpdateServiceDocument);
  const [createMandate] = useMutation(CreateMandateDocument);
  if (!service.serviceMeta || !service.id) {
    return <>Service Metadaten konnten nicht geladen werden</>;
  }

  const changeServiceStatus =
    service?.serviceMeta?.type === ServiceType.research ||
    service?.serviceMeta?.type === ServiceType.researchBlank
      ? () => {
          const status =
            service.status === ServiceStatus.research
              ? ServiceStatus.ready
              : ServiceStatus.finished;
          updateService({
            variables: { id: service.id, service: { status } },
          }).then(() => refetchService());
        }
      : () => {
          createMandate(
            // @ts-ignore
            {
              variables: { mandate: { serviceId: service.id } },
            },
          ).then(() => refetchService());
        };
  const revertServiceStatus = () => {
    const status =
      service.status === ServiceStatus.finished ? ServiceStatus.ready : ServiceStatus.research;
    updateService({ variables: { id: service.id, service: { status } } }).then(() =>
      refetchService(),
    );
  };

  if (serviceResearchObjectsQuery.loading) {
    return <Loading />;
  }

  const serviceResearchObjects = serviceResearchObjectsQuery.data?.serviceResearchObjects;

  if (serviceResearchObjectsQuery.error || serviceResearchObjects === undefined) {
    return (
      <>
        <Header
          backPath={backPath}
          button={button}
          mapPathList={mapPathList}
          person={person}
          topicId={service?.topic?.id}
          serviceId={service.id}
          headline={headline}
          serviceMeta={service.serviceMeta}
          contractExpirationDate={contractExpirationDate}
          language={language}
        />
        <IllustratedErrorMessage
          messages={["Die Recherche-Elemente konnten nicht geladen werden"]}
          illustration={errorIllustration}
          illustrationAltText="Elli rutscht auf einer Banane aus"
        >
          <PrimaryButton onClick={goBack}>Zurück</PrimaryButton>
        </IllustratedErrorMessage>
      </>
    );
  }

  return (
    <>
      <Header
        backPath={backPath}
        button={button}
        mapPathList={mapPathList}
        person={person}
        topicId={service?.topic?.id}
        serviceId={service.id}
        headline={headline}
        serviceMeta={service.serviceMeta}
        contractExpirationDate={contractExpirationDate}
        language={language}
      />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Grid container spacing={4}>
          <Grid item xs={3} xl={4}>
            <Requirements
              service={service}
              changeServiceStatus={changeServiceStatus}
              revertServiceStatus={revertServiceStatus}
              serviceResearchObjects={serviceResearchObjects}
            />
          </Grid>
          {typeof children === "function" ? children(serviceResearchObjects) : children}
        </Grid>
      </ErrorBoundary>
    </>
  );
};
export default ServiceTemplate;
