import { User } from "../../../generated/graphql";

export const SET_USERS = "SET_USERS";
export const SET_ACTIVE_LEGENDS = "SET_ACTIVE_LEGENDS";
export const SET_ACTIVE_LEGENDS_INITIAL = "SET_ACTIVE_LEGENDS_INITIAL";

export type Action =
  | { type: string; payload: any }
  | { type: typeof SET_USERS; payload: Map<number, User> }
  | { type: typeof SET_ACTIVE_LEGENDS; payload: number }
  | { type: typeof SET_ACTIVE_LEGENDS; payload: number };
export type State = {
  users: Map<number, User> | undefined;
  activeLegends: number[];
};

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case SET_USERS:
      return { ...state, users: action.payload };
    case SET_ACTIVE_LEGENDS:
      return {
        ...state,
        activeLegends: state.activeLegends.includes(action.payload)
          ? [...state.activeLegends.filter(e => action.payload !== e)]
          : [...state.activeLegends, action.payload],
      };
    case SET_ACTIVE_LEGENDS_INITIAL:
      return {
        ...state,
        activeLegends: [action.payload],
      };
    default:
      return { ...state };
  }
};

export const initialState: State = {
  users: undefined,
  activeLegends: [],
};
