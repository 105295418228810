import React, { FC } from "react";
import maxBy from "lodash/maxBy";

import { PersonActivity, TaskType } from "../../../generated/graphql";
import Avatar from "../../atoms/Avatar";
import unassignedAvatar from "../../assets/unassignedAvatar.svg";

import useStyles from "./styles";
import elli from "../../assets/elliBlue.svg";
import "./styles.css";
import { UnassingedBadgeAvatar, UserAvatarFromPerson, UserAvatarFromTask } from "../UserAvatar";
import { mapServiceStatusToTaskType } from "../../../utils/taskHelpers";

const ActivityLogType = ["conversationReport", "relationship", "person", "topic", "other"];

export const isActivityLogType = (activityType: string): boolean =>
  ActivityLogType.includes(activityType);

export const isMissedCall = (activity: PersonActivity) =>
  activity.activityType === "callIn" && !activity.author;

const getGetCurrentTask = (activity: PersonActivity) => {
  const tasks = activity.tasks || [];
  const count = tasks.length;
  const taskType = mapServiceStatusToTaskType(
    activity.service?.status,
    activity.service?.serviceMeta?.type,
  );

  if (taskType === TaskType.noTask) {
    return {
      currentTask: maxBy(tasks, "finishedAt"),
      count,
    };
  }

  let currentTask;

  // it is possible to have more than one matching task
  const currentTasks = tasks.filter(task => task?.taskType === taskType);

  if (currentTasks.length === 1) {
    currentTask = currentTasks[0];
  } else {
    currentTask = currentTasks.find(task => !task?.finishedAt);
  }

  return { currentTask, count };
};

const ActivityAvatar: FC<{ activity: PersonActivity }> = ({ activity }) => {
  const { avatar } = useStyles();

  // Due to refactoring: https://medium.com/@calebmer/when-to-use-graphql-non-null-fields-4059337f6fc8
  if (!activity.activityType) {
    return null;
  }

  if (isActivityLogType(activity.activityType) || isMissedCall(activity)) {
    return (
      <div className={avatar}>
        <Avatar src={elli} widthfactor={5} />
      </div>
    );
  }

  if (!activity.author) {
    return (
      <div className={avatar} style={{ background: "#F9F3E9" }}>
        <Avatar src={unassignedAvatar} widthfactor={5} />
      </div>
    );
  }
  if (!!activity.service) {
    const { currentTask, count } = getGetCurrentTask(activity);

    if (!currentTask) {
      return (
        <div className={avatar}>
          <UserAvatarFromPerson person={activity.author} />
        </div>
      );
    }

    return !currentTask.userId ? (
      <div className={avatar} style={{ background: "#F9F3E9" }}>
        <UnassingedBadgeAvatar badgeCount={count} />
      </div>
    ) : (
      <div className={avatar}>
        <UserAvatarFromTask userId={currentTask.userId} badgeCount={count} />
      </div>
    );
  }
  // Here we might need to fetch the user
  return (
    <div className={avatar}>
      <UserAvatarFromPerson person={activity.author} />
    </div>
  );
};

export default ActivityAvatar;
