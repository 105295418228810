import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  badge: {
    backgroundColor: theme.palette.primary.main,
    border: "solid #fff 1px",
    borderRadius: "18px",
    color: "#fff",
    fontSize: "12px",
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: "16px",
    minWidth: "20px",
    padding: "0 4px",
    textAlign: "center",
  },
}));

export default useStyles;
