import React, { useState, FC } from "react";

import Grid from "@material-ui/core/Grid";

import { Phone, Address, Email, Person } from "../../../../generated/graphql";

import ContactAddresses from "./ContactAddresses";
import ContactPhones from "./ContactPhones";
import ContactEmails from "./ContactEmails";

const Contact: FC<{ person: Person }> = ({ person }) => {
  // We need to persist newly created addresses, emails and phones, that are not save yet. Otherwise
  // they get overwritten due to autosave or another user editing a field.
  const [createdAddresses, setCreatedAddresses] = useState<Address[]>([]);
  const [createdPhones, setCreatedPhones] = useState<Phone[]>([]);
  const [createdEmails, setCreatedEmails] = useState<Email[]>([]);

  if (!person.id) return <></>;

  return (
    <Grid container spacing={2}>
      <Grid item xs={4} data-testid="ContactAddresses">
        <ContactAddresses
          addresses={(person.addresses as Address[]) || []}
          personId={person.id}
          created={createdAddresses}
          setCreated={setCreatedAddresses}
        />
      </Grid>
      <Grid item xs={4} data-testid="ContactPhones">
        <ContactPhones
          phones={(person.phones as Phone[]) || []}
          personId={person.id}
          created={createdPhones}
          setCreated={setCreatedPhones}
        />
      </Grid>
      <Grid item xs={4} data-testid="ContactEmails">
        <ContactEmails
          emails={(person.emails as Email[]) || []}
          personId={person.id}
          created={createdEmails}
          setCreated={setCreatedEmails}
        />
      </Grid>
    </Grid>
  );
};

export default Contact;
