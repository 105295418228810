import classnames from "classnames";
import React, { useContext } from "react";
import { ChatContext } from "../../../containers/ChatListener";
import InboxLabelFilter from "../../molecules/InboxLabelFilter";
import DayjsUtils from "@date-io/dayjs";

import Grid from "@material-ui/core/Grid";
import InboxGroup, { InboxHeadline } from "../../organisms/InboxGroup";
import { InboxWrapper as TaskWrapper } from "../../organisms/InboxLists/components";
import useStyles from "../inboxStyles";
import ChatItem from "./chatItem";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  Chat,
  useActiveChatsQuery,
  useGetDetailedActiveChatsQuery,
} from "../../../generated/graphql";
import InProgressChatItem from "./inProgressChatItem";

const InboxChats = () => {
  const classes = useStyles();
  const { obrowser, activeChats } = useContext(ChatContext);
  const unconnectedChats = [...(obrowser?.clients?.values() || [])];

  const { data: connectedChats } = useGetDetailedActiveChatsQuery();
  if (!connectedChats?.activeChats.length) {
    return null;
  }
  return (
    <Grid container className={classnames(classes.content, classes.contentBox)}>
      <InboxLabelFilter scope="admin" />
      {!!unconnectedChats.length ? (
        <MuiPickersUtilsProvider utils={DayjsUtils}>
          <InboxGroup key={"Nicht verbundene Chats"}>
            <TaskWrapper isHeadline>
              <InboxHeadline>Nicht verbundene Chats</InboxHeadline>
            </TaskWrapper>
            {unconnectedChats.map(c => {
              return <ChatItem key={c.latest} client={c} />;
            })}
          </InboxGroup>
        </MuiPickersUtilsProvider>
      ) : (
        <>No chats</>
      )}

      <br />
      <br />

      {!!connectedChats?.activeChats.length ? (
        <MuiPickersUtilsProvider utils={DayjsUtils}>
          <InboxGroup key={"Verbundene Chats"}>
            <TaskWrapper isHeadline>
              <InboxHeadline>Verbundene Chats</InboxHeadline>
            </TaskWrapper>
            {connectedChats?.activeChats.map(c => {
              return <InProgressChatItem key={c.id?.toString()} client={c as Chat} />;
            })}
          </InboxGroup>
        </MuiPickersUtilsProvider>
      ) : (
        <>No chats</>
      )}
    </Grid>
  );
};

export default InboxChats;
