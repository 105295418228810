import Maybe from "graphql/tsutils/Maybe";
import { User, PapershiftWorkingArea } from "../../../generated/graphql";

export type FilterState = {
  users: Map<number, User>;
  allUsers: Map<number, User>;
  workingAreas: Map<number, PapershiftWorkingArea>;
  externalUsers: Map<number, User>;
  selectedWorkingAreaId: number | void;
};

export type Action =
  | {
      type: "deselectWorkingArea";
      id: number;
    }
  | {
      type: "selectWorkingArea";
      workingArea: PapershiftWorkingArea;
      id: number;
    }
  | {
      type: "add";
      user: User;
      id: number;
    }
  | {
      type: "delete";
      id: number;
    }
  | {
      type: "updateAllUsers";
      users: Map<number, User>;
    }
  | {
      type: "addExternalUser";
      user: User;
      id: number;
    }
  | {
      type: "removeExternalUser";
      user: User;
      id: number;
    };

export const reducer = (state: FilterState, action: Action): FilterState => {
  switch (action.type) {
    case "updateAllUsers":
      return {
        ...state,
        allUsers: action.users,
      };
    case "selectWorkingArea":
      state.workingAreas.clear();
      state.users.clear();
      state.workingAreas.set(action.id, action.workingArea);
      state.selectedWorkingAreaId = action.id;

      //   state.users.forEach(user => {
      //     if (!user.papershiftWorkingAreas) {
      //       // @ts-ignore
      //       return state.users.delete(user.id);
      //     }

      //     if (state.workingAreas.size === 0) {
      //       return;
      //     }

      //     const isInWorkingArea = user.papershiftWorkingAreas.some(papershiftWorkingAreas => {
      //       if (!papershiftWorkingAreas || !papershiftWorkingAreas.id) {
      //         return false;
      //       }

      //       return !!state.workingAreas.get(papershiftWorkingAreas.id);
      //     });

      //     if (!isInWorkingArea) {
      //       // @ts-ignore
      //       return state.users.delete(user.id);
      //     }
      //   });

      const usersFilter = Array.from(state.allUsers)
        .filter(([id, user]) => {
          return (
            user.papershiftWorkingAreas &&
            user.papershiftWorkingAreas.some((workingArea: Maybe<PapershiftWorkingArea>) => {
              // Should not be a case, but typescript needs it
              if (!workingArea || !workingArea.id) {
                return false;
              }

              // Show user if either no working area is selected or the user has a matching working area
              return !!state.workingAreas.get(workingArea.id) || state.workingAreas.size === 0;
            })
          );
        })
        .map(([id, user]) => user);

      //   usersFilter.sort(sortUsersByAlias);

      usersFilter.forEach(user => {
        state.users.set(user.id, user);
      });

      return {
        users: state.users,
        allUsers: state.allUsers,
        workingAreas: state.workingAreas,
        externalUsers: state.externalUsers,
        selectedWorkingAreaId: state.selectedWorkingAreaId,
      };

    case "deselectWorkingArea":
      state.workingAreas.delete(action.id);

      state.users.forEach(user => {
        if (!user.papershiftWorkingAreas) {
          // @ts-ignore
          return state.users.delete(user.id);
        }

        if (state.workingAreas.size === 0) {
          return;
        }

        const isInWorkingArea = user.papershiftWorkingAreas.some(papershiftWorkingAreas => {
          if (!papershiftWorkingAreas || !papershiftWorkingAreas.id) {
            return false;
          }

          return !!state.workingAreas.get(papershiftWorkingAreas.id);
        });

        if (!isInWorkingArea) {
          // @ts-ignore
          return state.users.delete(user.id);
        }
      });

      state.workingAreas.clear();
      state.users.clear();
      state.selectedWorkingAreaId = undefined;
      return {
        users: state.users,
        allUsers: state.allUsers,
        workingAreas: state.workingAreas,
        externalUsers: state.externalUsers,
        selectedWorkingAreaId: state.selectedWorkingAreaId,
      };
    case "add":
      return {
        users: state.users.set(action.id, action.user),
        workingAreas: state.workingAreas,
        allUsers: state.allUsers,
        externalUsers: state.externalUsers,
        selectedWorkingAreaId: state.selectedWorkingAreaId,
      };
    case "addExternalUser":
      state.allUsers.delete(action.id);
      return {
        ...state,
        externalUsers: state.externalUsers.set(action.id, action.user),
      };
    case "removeExternalUser":
      state.allUsers.set(action.id, action.user);
      state.externalUsers.delete(action.id);
      return {
        ...state,
      };
    case "delete":
      state.users.delete(action.id);
      return {
        users: state.users,
        allUsers: state.allUsers,
        workingAreas: state.workingAreas,
        externalUsers: state.externalUsers,
        selectedWorkingAreaId: state.selectedWorkingAreaId,
      };
    default:
      console.log("unknown action type or wrong input: ", { action });
      return state;
  }
};
