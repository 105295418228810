import { useEffect } from "react";

import {
  GetCaseManagementReviewAttributeQueryVariables,
  useGetCaseManagementReviewAttributeQuery,
  CaseManagementReviewAttributeSubscriptionSubscription,
  CaseManagementReviewAttributeSubscriptionDocument,
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

export const useGetCaseManagementReviewAttributeSubscription = (
  variables: GetCaseManagementReviewAttributeQueryVariables,
) => {
  const { data, loading, subscribeToMore } = useGetCaseManagementReviewAttributeQuery({
    variables,
    fetchPolicy,
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore<CaseManagementReviewAttributeSubscriptionSubscription>({
      document: CaseManagementReviewAttributeSubscriptionDocument,
      variables: {
        ...variables,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          getCaseManagementReviewAttribute: {
            ...prev.getCaseManagementReviewAttribute,
            attributeValue:
              subscriptionData.data.caseManagementReviewAttributeSubscription?.attributeValue,
          },
        };
      },
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables]);

  return { caseManagementReviewAttribute: data?.getCaseManagementReviewAttribute, loading };
};
