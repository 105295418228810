import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme: Theme) => {
  return {
    input: {
      ...theme.typography.h2,
      color: theme.palette.primary.main,
      cursor: "pointer",
      padding: theme.spacing(1, 0, 0),
    },
    textField: {
      width: "fit-content",
    },
  };
});
