import React from "react";

import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { FormControl } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import PrimaryButton from "../../../atoms/PrimaryButton";
import Dialog from "../../../molecules/DefaultDialog";
import DialogContent from "../../../molecules/DefaultDialogContent";

interface AddressDialogProps {
  open: boolean;
  toggle: (open: boolean) => void;
}

export default function AddressDialog({ open, toggle }: AddressDialogProps) {
  return (
    <Dialog open={open} onClose={() => toggle(!open)}>
      <DialogContent>
        <Box mb={3} display="flex" justifyContent="center">
          <Typography variant="h1">Adresse</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <FormControl fullWidth margin="normal">
              <Field
                name="businessObject.address.street"
                component={TextField}
                label="Straße und Hausnummer"
              />
            </FormControl>
          </Grid>
          <Grid item md={8}>
            <FormControl fullWidth margin="normal">
              <Field name="businessObject.address.city" component={TextField} label="Stadt" />
            </FormControl>
          </Grid>
          <Grid item md={4}>
            <FormControl fullWidth margin="normal">
              <Field name="businessObject.address.zip" component={TextField} label="Postleitzahl" />
            </FormControl>
          </Grid>
        </Grid>
        <Box mt={3}>
          <PrimaryButton onClick={() => toggle(!open)}>OK</PrimaryButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
