import React, { useContext } from "react";

import dayjs from "dayjs";

import { CurrentIndex } from "../../pages/Appointment/components/Day";
import { Appointment } from "../../../generated/graphql";
import { AbsentContext } from "../ShiftRowWrapper";

const isValid = (invalidTimes: Appointment[], index: number) => {
  if (invalidTimes.length < 1) {
    return true;
  }

  const current = dayjs(invalidTimes[0].from)
    .startOf("day")
    .add(6, "hour")
    .add(30 * index, "minute");

  return !invalidTimes.some(time => {
    return current.isSame(time.from) || current.isBetween(time.from, time.to);
  });
};

interface IShiftSlot {
  invalidTimes?: Appointment[];
  isFirstColumn: boolean;
  isLastColumn: boolean;
  height: number;
  width: number;
  index: number;
  startIndex: number;
  onClick: () => void;
  color: string;
}

const ShiftSlot = ({
  invalidTimes,
  isFirstColumn,
  isLastColumn,
  height,
  index,
  startIndex,
  onClick,
  width,
  color,
}: IShiftSlot) => {
  const { isAbsent } = useContext(AbsentContext);
  const { selectedIndex, setIndex } = useContext(CurrentIndex);

  let fill = isAbsent ? "#E0D9CE" : "#CAD9E4";

  let valid = false;
  const bandIndicator = 4;
  if (!!invalidTimes) {
    if (invalidTimes.length) {
    }
    valid = isValid(invalidTimes, startIndex + index);
  }

  if (selectedIndex === startIndex + index && valid) {
    fill = "#6699AF";

    return (
      <>
        <rect
          fill={color}
          height={height}
          onClick={onClick}
          width={4 + 16 - (isFirstColumn ? 8 : 0) - (isLastColumn ? 8 : 0)}
          x={isFirstColumn ? "0" : "0"}
          y={height * index}
          style={{ cursor: isAbsent ? "not-allowed" : "pointer" }}
        />
        <rect
          onMouseLeave={() => setIndex(-1)}
          onMouseEnter={() => setIndex(startIndex + index)}
          fill={fill}
          height={height}
          onClick={onClick}
          width={width + 16 - (isFirstColumn ? 8 : 0) - (isLastColumn ? 8 : 0)}
          x={isFirstColumn ? "0" : bandIndicator}
          y={height * index}
          style={{ cursor: isAbsent ? "not-allowed" : "pointer" }}
        />
      </>
    );
  }

  return (
    <>
      <rect
        fill={color}
        height={height}
        onClick={onClick}
        width={bandIndicator}
        x={isFirstColumn ? "0" : "0"}
        y={height * index}
        style={{ cursor: isAbsent ? "not-allowed" : "pointer" }}
      />

      <rect
        onMouseEnter={() => {
          if (!isAbsent) {
            setIndex(valid ? startIndex + index : -1);
          }
        }}
        className={isAbsent ? "spacing" : ""}
        fill={fill}
        height={height}
        onClick={isAbsent ? undefined : onClick}
        width={width - bandIndicator}
        x={bandIndicator}
        y={height * index}
      />
    </>
  );
};
export default ShiftSlot;
