import Grid from "@material-ui/core/Grid";
import React, { memo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ServiceType, usePersonQuery, useRegisteredServicesByPersonIdQuery } from "../../../generated/graphql";
import { fetchPolicy } from "../../../utils/constants";
import Loader from "../../atoms/Loading";
import ServiceCreateForm from "../../forms/ServiceCreateForm";
import ServiceReset from "../../organisms/ServiceReset";
import useStyles from "./styles";

const ServiceCreation = memo(() => {
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation();
//   const scQuery = useServiceCategoriesQuery({ fetchPolicy });
  const scQuery = useRegisteredServicesByPersonIdQuery({
    variables: { id: +id },    
    fetchPolicy,
  });
  const { data, loading } = usePersonQuery({
    variables: { id: parseInt(id, 10) },
  });
  // @ts-ignore
  const activity = state?.activity! || null;
  const createdServiceName: string | undefined | null = localStorage.getItem("createdServiceName");
  const createdServiceID: string | undefined | null = localStorage.getItem("createdServiceID");
  const person = data ? data.person : null;
  return scQuery.loading && loading && !person ? (
    <Loader />
  ) : (
    person && (
      <Grid container className={classes.content}>
        {createdServiceName && createdServiceID ? (
          <>
            <Grid item xs={1} />
            <Grid item xs={10}>
              <ServiceReset
                name={createdServiceName}
                id={createdServiceID}
                person={person}
                type={ServiceType.research}
              />
            </Grid>
            <Grid item xs={1} />
          </>
        ) : (
          <>
            <Grid item xs={3} xl={3} />
            <Grid item xs={6} xl={6}>
              <ServiceCreateForm
                person={person}
                activity={activity}
                serviceCategories={scQuery.data ? scQuery.data.registeredServicesByPersonId : []}
                step={step}
                setStep={setStep}
              />
            </Grid>
            <Grid item xs={3} xl={3} />
          </>
        )}
      </Grid>
    )
  );
});

export default ServiceCreation;
