import React, { FC } from "react";

import dayjs from "dayjs";
import {
  isYesterday,
  isCurrentMonth,
  isBefore12Month,
  isBeforeYesterday,
} from "../../../utils/dateHelper";

import { StyledChip } from "./styles";

type TTimeChip = {
  date: Date;
};

const TimeChip: FC<TTimeChip> = ({ date }) => {
  const getFormattedDate = () => {
    const d = dayjs(date);
    return d.format("dddd, DD.MM.");

    if (isBefore12Month(d)) {
      return d.format("YYYY");
    }
    if (!isCurrentMonth(d)) {
      return d.format("MMMM");
    }
    if (isBeforeYesterday(d)) {
      return d.format("dddd, DD.MM.");
    }
    if (isYesterday(d)) {
      return "Gestern";
    }
    return undefined;
  };
  return <StyledChip label={getFormattedDate()} />;
};

export default TimeChip;
