import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { Phone } from "@material-ui/icons";
import orderBy from "lodash/orderBy";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { RelationshipType, useBreadCrumbInfoByPersonIdQuery } from "../../../generated/graphql";
import { usePersonSubscription } from "../../../hooks/usePersonSubscription";
import translateRelationshipType from "../../../utils/translateRelationshipType";
import EmergencyContactIcon from "../../assets/EmergenyContactIcon";
import ClickBox from "../../atoms/ClickBox";
import Loader from "../../atoms/Loading";
import NoPersonsTeaser from "../../atoms/NoPersonsTeaser";
import TertiaryButton from "../../atoms/TertiaryButton";
import PersonLayout from "../../templates/PersonLayout";

export default function PersonContacts() {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();

  const { person, loading, error } = usePersonSubscription(parseInt(id, 10));

  const personId = person?.id;
  const { data: breadcrumbData } = useBreadCrumbInfoByPersonIdQuery({
    skip: !personId,
    variables: {
      id: personId!,
    },
  });

  if (loading) {
    return <Loader></Loader>;
  }

  if (!person || !!error) {
    return <></>;
  }

  const relations = person.relations;

  return (
    <PersonLayout
      person={person}
      mapList={[
        {
          name: "Adressbuch",
          path: "",
        },
      ]}
      contractExpirationDate={breadcrumbData?.breadCrumbInfoByPersonId?.contractEnd}
      language={breadcrumbData?.breadCrumbInfoByPersonId?.language || ""}
    >
      {!relations ||
        (relations.length < 1 && (
          <NoPersonsTeaser
            title={`Es sind noch keine Adressen zu ${person.name || "diesem Klienten"} hinterlegt.`}
          />
        ))}
      {relations &&
        orderBy(relations, "passivePerson.name", "asc").map(relation => {
          if (!relation || !relation.passivePerson) return null;

          const target = `/person/${person.id}/contacts/${relation.passivePerson.id}`;

          return (
            <ClickBox handleClick={() => push(target)} key={relation.id || "relation"}>              
              <Box minHeight={48} width="100%" alignItems="center" display="flex">
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={3}>
                    <Link variant="h3" color="primary">
                      {relation.passivePerson.name || "-"}
                    </Link>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2">
                      {translateRelationshipType(relation.relationship || RelationshipType.unknown)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {relation.isEmergency && (
                      <Box display="flex" alignItems="center">
                        <EmergencyContactIcon />
                        <Box paddingX={2}>
                          <Typography variant="body2">Notfallkontakt</Typography>
                        </Box>
                        <TertiaryButton>
                          <Box marginRight={1} display="flex">
                            <Phone fontSize="small" />
                          </Box>
                          Anrufen
                        </TertiaryButton>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </ClickBox>
          );
        })}
    </PersonLayout>
  );
}
