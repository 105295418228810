import React, { useState, FC } from "react";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import SecondaryButton from "../../atoms/SecondaryButton";
import WizardNavigation from "../../molecules/WizardNavigation";
import TopicSelectOrCreate from "../../molecules/TopicSelectOrCreate";
import PrimaryButton from "../../atoms/PrimaryButton";
import ServiceMetaIcon from "../../atoms/ServiceMetaIcon";
import {
  Person,
  Topic,
  useCreateCounselingServiceMutation,
  ServiceType,
} from "../../../generated/graphql";
import useStyles from "../reportStyles";

interface Props {
  person: Person;
  type: ServiceType;
  topicId?: number;
}
interface ITSProps {
  person: Person;
  setTopic: (topic: Topic) => void;
  type: ServiceType;
  styles: any;
  topic: Topic;
  topics: Topic[];
}

const TopicSelect: FC<ITSProps> = ({ styles, topics, setTopic, topic, person, type }) => (
  <>
    <Box mb={2}>
      <ServiceMetaIcon name={type} big />
    </Box>
    <Typography variant="h2" className={styles.headline}>
      {type === ServiceType.internalConsult && "Neue interne strukturierte Beratung"}
      {type === ServiceType.externalConsult && "Neue externe Beratung"}
    </Typography>
    <Typography variant="h2" className={styles.dataText}>
      Zum <TopicSelectOrCreate topics={topics} selectAction={setTopic} topic={topic} tabIndex={1} />
    </Typography>
    <Typography variant="h2" className={styles.dataText}>
      für
    </Typography>
    <Typography variant="h2" className={styles.dataText}>
      {person.name} anlegen
    </Typography>
  </>
);

const ServiceCreateForm: FC<Props> = ({ person, topicId, type }: Props) => {
  const classes = useStyles();
  const { push, goBack } = useHistory();

  const topics = (person.client?.topics || []) as Topic[];
  const getTopic = () => {
    if (!topics || !topics.length) return { name: "", id: null };
    const topic = topics[0];
    let predefinedTopic;
    if (topicId) {
      predefinedTopic = topics.find(t => t.id === topicId);
    }
    return predefinedTopic || topic;
  };
  const iniTopic = getTopic();
  const [topic, setTopic] = useState<Topic>(iniTopic);
  const client = person?.client || null;

  const [createCounselingService] = useCreateCounselingServiceMutation();

  const handleCreate = () => {
    createCounselingService({
      variables: {
        service: {
          topic: {
            id: topic.id,
            name: topic.name,
          },
          clientId: client && client.id,
          type,
        },
      },
    }).then(
      ({ data }) => {
        if (data?.createCounselingService?.id) {
          push(`/person/${person.id}/${type}/${data?.createCounselingService?.id}/setup`);
        }
      },
      err => console.log(err),
    );
  };

  return (
    <>
      <div className={classes.content}>
        <TopicSelect
          styles={classes}
          topics={topics}
          topic={topic}
          setTopic={setTopic}
          person={person}
          type={type}
        />
      </div>
      <WizardNavigation>
        <SecondaryButton onClick={() => goBack()} tabIndex={3}>
          Abbrechen
        </SecondaryButton>
        <PrimaryButton
          disabled={!topic.name || /^(\s|\t|\n|\r)+$/.test(topic.name)}
          onClick={handleCreate}
          tabIndex={2}
        >
          Weiter
        </PrimaryButton>
      </WizardNavigation>
    </>
  );
};
export default ServiceCreateForm;
