import React, { useState } from "react";

import { pathOr } from "ramda";
import { FormikProps, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MUITextField from "@material-ui/core/TextField";

import {
  Service,
  ServiceResearchObject,
  Person,
  Address,
  BusinessObject,
} from "../../../generated/graphql";
import AsyncSearch from "../../molecules/AsyncSearch";
import SlateRTE, { initialFormValue } from "../../molecules/SlateRTE";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import stripTypenames from "../../../utils/stripTypenames";
import { useHistory, useParams } from "react-router-dom";
import WizardNavigation from "../../molecules/WizardNavigation";
import IHour from "../../../interfaces/IHour";

import HoursField from "./HoursField";
import AddressField from "./AddressField";
import useStyles from "./styles";
import PhoneCountrySelect from "../../molecules/PhoneCountrySelect";

interface AddFormProps {
  service: Service;
}

export default function ResearchObjectBusinessForm({
  touched,
  isSubmitting,
  submitForm,
  setFieldValue,
  setFieldTouched,
  values,
  errors,
}: AddFormProps & FormikProps<ServiceResearchObject>) {
  const { serviceId } = useParams<{ serviceId: string }>();
  const { push } = useHistory();
  const [contacts, setContacts] = useState<Person[]>([]);

  const businessObject: BusinessObject | undefined = pathOr(undefined, ["businessObject"], values);

  const hours: IHour[] = pathOr([], ["businessObject", "hours"], values);

  const address: Address | {} = pathOr({}, ["businessObject", "address"], values);
  const setContact = (value: string | Person | null) => {
    if (value) {
      if (typeof value === "string") {
        const contactPerson = contacts.find(c => c.name === value);
        if (contactPerson) {
          setFieldValue("contactPerson", { ...contactPerson });
        } else {
          setFieldValue("contactPerson", { name: value });
        }
      } else {
        setFieldValue("contactPerson", {
          ...value,
          name: `${value.firstName} ${value.lastName}`,
        });
      }
    }
  };

  const setBusiness = (value: BusinessObject) => {
    if (value) {
      const cleanObject = { ...stripTypenames(value, "__typename") }; //enforce copy
      const businessContacts =
        (value.persons as Person[]).filter((c: Person) => !c.temporary) || [];
      setContacts(businessContacts);
      delete cleanObject.persons;
      setFieldValue("businessObject", cleanObject);
      if (businessContacts.length) {
        setFieldValue("contactPerson", { ...businessContacts[0] });
      }
    }
  };

  const setBusinessName = (value: string | any) => {
    if (value) {
      setContacts([]);
      setFieldValue("businessObject.id", null);
      setFieldValue("contactPerson.name", "");
      setFieldValue("contactPerson.id", null);
      setFieldValue("businessObject.name", value);
      setFieldValue("businessObject.phones[0].id", null);
      setFieldValue("businessObject.email.id", null);
      setFieldValue("businessObject.address.id", null);
    }
  };
  const classes = useStyles();
  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          {businessObject &&
          !businessObject.id &&
          businessObject.name &&
          businessObject.name.length > 0 ? (
            <FormControl margin="normal" fullWidth>
              <Field
                name="businessObject.name"
                label="Name"
                component={TextField}
                autoFocus
                tabIndex={0}
              />
            </FormControl>
          ) : (
            <AsyncSearch.BusinessObjectSearch
              error={!!errors.businessObject}
              customClass={classes.autoSelect}
              serviceId={parseInt(serviceId, 10)}
              onCreateOption={(inputValue: string) => setBusinessName(inputValue)}
              onChange={value => setBusiness(value)}
              label="Name"
              placeholder=""
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            tabIndex={0}
            value={values.contactPerson}
            options={contacts.filter((c: Person) => !c.temporary)}
            freeSolo
            noOptionsText="Neuen Ansprechpartner hinterlegen"
            getOptionLabel={(option: any) =>
              option.name ||
              (option.firstName &&
              option.firstName.length &&
              option.lastName &&
              option.lastName.length
                ? `${option.firstName} ${option.lastName}`
                : "")
            }
            getOptionSelected={(option, value) => {
              if (value.id) {
                return option.id === value.id;
              }
              return false;
            }}
            onChange={(event: any, value: any) => {
              setContact(value);
            }}
            onBlur={(event: any) => setContact(event.target.value)}
            renderInput={params => (
              <FormControl fullWidth margin="normal">
                <MUITextField
                  style={{ width: "100%" }}
                  key={`contactPerson.name-${values.id}`}
                  label="Ansprechpartner"
                  name=""
                  {...params}
                />
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      <Box display="flex" alignItems="center" height={48} margin="16px 0 8px 0">
        <AddressField address={address} tabIndex={0} />
      </Box>
      <Box display="flex" margin="16px 0 8px 0">
        <HoursField
          hours={hours}
          tabIndex={0}
          setFieldValue={(hours: IHour[]) => {
            setFieldTouched("businessObject.hours", true);
            setFieldValue("businessObject.hours", hours);
          }}
          formUntouched={
            // only check all weekdays per default when form is left untouched
            //@ts-ignore
            !(touched.businessObject && touched.businessObject.hours)
          }
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6} lg={4}>
          <PhoneCountrySelect name="businessObject.phones[0].countryCode" />
        </Grid>
        <Grid item xs={6} lg={4}>
          <FormControl fullWidth margin="normal">
            <Field
              name="businessObject.phones[0].areaCode"
              label="Vorwahl"
              component={TextField}
              InputProps={{ inputProps: { tabIndex: 0 } }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} lg={4}>
          <FormControl fullWidth margin="normal">
            <Field
              label="Telefonnummer"
              name="businessObject.phones[0].number"
              component={TextField}
              InputProps={{ inputProps: { tabIndex: 0 } }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <FormControl fullWidth margin="normal">
            <Field
              name="businessObject.email.email"
              label="E-Mail-Adresse"
              component={TextField}
              InputProps={{ inputProps: { tabIndex: 0 } }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <FormControl fullWidth margin="normal">
            <Field
              name="businessObject.url"
              label="Webseite"
              component={TextField}
              InputProps={{ inputProps: { tabIndex: 0 } }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <SlateRTE
        small
        handleChange={value => setFieldValue("businessObject.info", value)}
        label={"Sonstiges"}
        placeholder={""}
        tabIndex={0}
        value={initialFormValue}
      />
      <SlateRTE
        small
        handleChange={value => setFieldValue("note", value)}
        label={"Meine Notizen"}
        placeholder={""}
        tabIndex={0}
        value={initialFormValue}
      />
      <Box padding={3} />
      <WizardNavigation>
        <SecondaryButton tabIndex={12} onClick={() => push(`/service/${serviceId}`)}>
          Abbrechen
        </SecondaryButton>
        <PrimaryButton
          tabIndex={11}
          type="submit"
          disabled={isSubmitting}
          onClick={() => {
            // remove hour identifier uuid used for unsaved hours
            setFieldValue(
              "businessObject.hours",
              hours.map(h => {
                delete h.uuid;
                return h;
              }),
            );
            submitForm();
          }}
        >
          Anlegen
        </PrimaryButton>
      </WizardNavigation>
    </Form>
  );
}
