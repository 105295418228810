import { InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { Field, FieldProps } from "formik";
import React, { useState } from "react";
import {
  Account,
  AccountReporting,
  AccountUsageScope,
  ServiceMeta,
  useUpdateAccountMutation,
} from "../../../generated/graphql";
import AccountCoachingSelect from "../../molecules/AccountCoachingSelect";
import AccountModuleSelect from "../../molecules/AccountModuleSelect";
import AccountSessionsSelect from "../../molecules/AccountSessionsSelect";
import AccountSTCSessionsSelect from "../../molecules/AccountSTCSessionsSelect";
import AccountUsageScopeSelect from "../../molecules/AccountUsageScopeSelect";
import DialogForm from "../DialogForm";

type TAccountEditDialog = {
  account: Account;
  open: boolean;
  toggle: () => void;
};

const serviceTimeTypes = [
  {
    id: "24_7",
    value: "24/7",
  },
  {
    id: "daily_6_22",
    value: "6:00 - 22:00",
  },
];

const AccountEditDialog = ({ account, open, toggle }: TAccountEditDialog) => {
  const [update] = useUpdateAccountMutation();
  const [modules, setModules] = useState((account.accountServiceModules || []) as ServiceMeta[]);
  const [usageScope, setUsageScope] = useState("");
  const checkModule = (sm: ServiceMeta) => {
    const exists = !!modules.find(m => m!.id! === sm.id!);
    if (exists) {
      setModules(modules.filter(m => m!.id !== sm.id!));
    } else {
      setModules([...modules, sm]);
    }
  };
  return (
    <DialogForm
      open={open}
      initialValues={{
        freeSessions: account.freeSessions || 0,
        nFreeSTCs: account.nFreeSTCs || 0,
        serviceTimes: account.serviceTimes || "",
        coaching: account.coaching?.toString() || "false",
        reporting: account.reporting || AccountReporting.basic,
        usageScope: account.usageScope || AccountUsageScope.employeeOnly,
        remarks: account.remarks || "",
      }}
      onClose={toggle}
      title="Vertragsmodule bearbeiten"
      //validationSchema={validationSchema}
      onSubmit={values => {        
        return update({
          variables: {
            id: account.id!,
            input: {
              freeSessions: parseInt(values.freeSessions, 10),
              nFreeSTCs: parseInt(values.nFreeSTCs, 10),
              coaching: values.coaching === "true",
              reporting: values.reporting,
              usageScope: values.usageScope,
              accountServiceModuleIds: modules.map(m => m && m.id!),
              serviceTimes: values.serviceTimes,
              remarks: values.remarks,
            },
          },
        }).then(() => {
          toggle();
        });
      }}
    >
      <Grid container spacing={2} component={Box} mt={1}>
        <Grid item xs={12}>
          <AccountModuleSelect account={account} modules={modules} setModules={checkModule} />
        </Grid>

        <Grid item xs={12}>
          <AccountSessionsSelect />
        </Grid>

        <Grid item xs={12}>
          <AccountSTCSessionsSelect />
        </Grid>

        <Grid item xs={12}>
          <AccountUsageScopeSelect />
        </Grid>

        <Grid item xs={12}>
          <AccountCoachingSelect />
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <Field
              name="serviceTimes"
              render={({ form: { setFieldValue }, form }: FieldProps) => {
                if (form.values.usageScope !== usageScope) {
                //   if (form.values.usageScope === AccountUsageScope.employeeAndFamily) {
                //     setFieldValue("coaching", false);
                //   }
                  setUsageScope(form.values.usageScope);
                }

                return (
                  <>
                    <InputLabel htmlFor="serviceTimesTypes">Service-Zeiten</InputLabel>
                    <Select
                      value={form.values.serviceTimes}
                      onChange={v => {
                        setFieldValue("serviceTimes", v.target.value);
                      }}
                    >
                      {serviceTimeTypes.map(serviceTime => {
                        return (
                          <MenuItem key={serviceTime.id} value={serviceTime.value}>
                            {serviceTime.value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </>
                );
              }}
            ></Field>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <Field
              component={TextField}
              label="Besonderheiten/ Einschränkungen"
              type="string"
              render={({ field, form, form: { setFieldValue } }: FieldProps) => {
                return (
                  <TextField
                    onChange={v => {
                      setFieldValue("remarks", v.target.value);
                    }}
                    value={form.values.remarks}
                    label="Besonderheiten/ Einschränkungen"
                    variant="standard"
                    multiline
                    minRows={2}
                    maxRows={2}
                  />
                );
              }}
            ></Field>
          </FormControl>
        </Grid>

        {/* <Grid item xs={12}>
          <AccountReportingSelect />
        </Grid> */}
      </Grid>
    </DialogForm>
  );
};

export default AccountEditDialog;
