import React, { FC } from "react";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import DataColumn from "../../molecules/DataColumn";
import DataRow from "../../molecules/DataRow";
import ContactPersonCard from "../../molecules/ContactPersonCard";
import AddressItem from "../../atoms/Address";
import TariffBadge from "../../molecules/TariffBadge";
import { Account } from "../../../generated/graphql";
import { translateType } from "../../../utils/accountHelper";

const hasContractStarted = (startDate: any, endDate: any) => {
  const today = dayjs();
  const hasStarted = dayjs(startDate).isAfter(today);
  //   const hasEnded = endDate ? dayjs(endDate).isBefore(today) : false;
  return hasStarted;
};

const AccountCard: FC<{ account: Account }> = ({ account }) => {
  const { push } = useHistory();
  const lastChange = account?.updatedAt;
  const isContractInFuture = hasContractStarted(account?.contractStart, account?.contractEnd);
  return (
    <Grid container>
      <DataRow
        defaultIcon
        title="Allgemeine Informationen"
        editTrigger={() => push(`/business/${account?.business?.id!}/details`)}
      >
        <DataColumn label="Account" xs={4}>
          <Typography variant="body2" key={`account${account.id}`}>
            {account?.name}
          </Typography>
        </DataColumn>
        <DataColumn label="Übergeordneter Account" xs={4}>
          <Typography variant="body2" key={`parent${account.id}`}>
            {account?.parent?.name || "-"}
          </Typography>
        </DataColumn>
        <DataColumn label="Ort" xs={4}>
          {!!account?.business?.address ? (
            <AddressItem address={account?.business?.address} />
          ) : (
            "-"
          )}
        </DataColumn>
        <DataColumn label="Telefon" xs={4}>
          <Typography variant="body2" key={`phone${account.id}`}>
            {!!account?.business?.phones?.length ? account.business.phones[0]?.readableNumber : "-"}
          </Typography>
        </DataColumn>
        <DataColumn label="E-Mail-Adresse" xs={4}>
          <Typography variant="body2" key={`email${account.id}`}>
            {account?.business?.email?.email || "-"}
          </Typography>
        </DataColumn>
        <DataColumn label="Webseite" xs={4}>
          <Typography variant="body2" key={`site${account.id}`}>
            {!!account?.business?.url ? (
              <Link
                href={account?.business?.url || ""}
                target="_blanck"
                color="primary"
                underline="always"
                key={`site${account?.business.id!}`}
                variant="h4"
              >
                {account?.business?.url || ""}
              </Link>
            ) : (
              <Typography variant="body2" key={`website${account?.business?.id!}`}>
                -
              </Typography>
            )}
          </Typography>
        </DataColumn>
      </DataRow>
      {!!account?.business?.persons?.filter(p => !!p && !p.temporary).length && (
        <ContactPersonCard
        person={account.business.persons.filter(p => !!p && !p.temporary)[0]!}
        business={account.business}
         />
      )}
      <DataRow
        defaultIcon
        title="Vertragsdaten"
        editTrigger={() => push(`/account/${account.id}/contract`)}
      >
        <DataColumn label="Letzte Änderung" xs={4}>
          <Typography variant="body2" key={`lastChange${account.id}`}>
            {dayjs(lastChange).format("DD.MM.YYYY")}
          </Typography>
        </DataColumn>
        {isContractInFuture ? (
          <DataColumn label="Vertragsbeginn" xs={4}>
            <Typography variant="body2" key={`contractStart${account.id}`}>
              {!!account?.contractStart ? dayjs(account?.contractStart).format("DD.MM.YYYY") : "-"}
            </Typography>
          </DataColumn>
        ) : (
          !!account?.contractEnd && (
            <DataColumn label="Vertragsende" xs={4}>
              <Typography variant="body2" key={`contractEnd${account.id}`}>
                {dayjs(account?.contractEnd).format("DD.MM.YYYY")}
              </Typography>
            </DataColumn>
          )
        )}
        <DataColumn xs={4} label="Vertragstyp">
          <Typography variant="body2" key={`type${account.id}`}>
            {!!account?.type ? translateType(account.type, true) : "-"}
          </Typography>
        </DataColumn>
        <DataColumn xs={4} label="Tarif">
          <TariffBadge account={account} />
        </DataColumn>
        <DataColumn xs={4} label="Nutzungsrate">
          <Typography variant="body2" key={`nutzungsrate${account.id}`}>
            {account.usageRate || "-"}
          </Typography>
        </DataColumn>
        {/* <DataColumn xs={4} label="Pin">
          <Typography variant="body2" key={`pin${account.id}`}>
            {account.pin || "-"}
          </Typography>
        </DataColumn> */}
        <DataColumn xs={4} label="Helpline Inland">
          <Typography variant="body2" key={`helpline${account.id}`}>
            {account.helpline || "-"}
          </Typography>
        </DataColumn>
        <DataColumn xs={4} label="App-Kennwort">
          <Typography variant="body2" key={`chatPin${account.id}`}>
            {account.chatRegistrationPin || "-"}
          </Typography>
        </DataColumn>      
      </DataRow>
    </Grid>
  );
};
export default AccountCard;
