import React, { FC } from "react";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";

import { TextField } from "formik-material-ui";
import { Field } from "formik";

import PhoneTypeSelect from "../../molecules/PhoneTypeSelect";
import PhoneCountrySelect from "../../molecules/PhoneCountrySelect";

const PhoneField: FC<{ namePrefix: string }> = ({ namePrefix }) => {
  const name = namePrefix ? `${namePrefix}.` : "";

  return (
    <Box mb={3} width="100%">
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <PhoneTypeSelect name={`${name}type`} />
        </Grid>
        <Grid item xs={3}>
          <PhoneCountrySelect name={`${name}countryCode`} />
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth margin="normal">
            <Field name={`${name}areaCode`} label="Vorwahl" component={TextField} />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth margin="normal">
            <Field name={`${name}number`} label="Nummer" component={TextField} />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};
export default PhoneField;
