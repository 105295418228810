import Grid from "@material-ui/core/Grid";
import React, { FC } from "react";
import { PersonQuery } from "../../../generated/graphql";
import { NamePathMap } from "../../molecules/Breadcrumb";
import BusinessHeader from "../../molecules/BusinessHeader";
import PersonHeader from "../../molecules/PersonHeader";
import ActivityStream from "../../organisms/ActivityStream";
import BusinessActivityStream from "../../organisms/BusinessActivityStream";
import PersonAppointmentStream from "../../organisms/ClientAppointmentStream";
import useStyles from "./styles";

type TPersonLayout = {
  contractExpirationDate?: string;
  language?: string;
  contactId?: number;
  counselor?: boolean;
  mapList: NamePathMap[];
  pathId?: number | null;
  person?: PersonQuery["person"];
  personIds?: number[];
  serviceId?: number;
  topicId?: number;
};

const PersonLayout: FC<TPersonLayout> = ({
  contractExpirationDate,
  language,
  children,
  contactId,
  counselor,
  mapList,
  pathId,
  person,
  personIds,
  serviceId,
  topicId,
}) => {
  const classes = useStyles();
  return (
    <>
      {!counselor && person ? (
        <PersonHeader
          person={person}
          mapList={mapList}
          topicId={topicId}
          serviceId={serviceId}
          contractExpirationDate={contractExpirationDate}
          language={language}
        />
      ) : (
        <BusinessHeader mapList={mapList} contractExpirationDate={contractExpirationDate} pathId={pathId} person={person} />
      )}
      <div className={classes.content}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Grid container spacing={4}>
              <Grid item xs={12} className={classes.contentLeft}>
                {person && person.id && (
                  <PersonAppointmentStream personId={person.id}></PersonAppointmentStream>
                )}
                {person && person.id && (
                  <ActivityStream
                    contactId={contactId}
                    personId={person.id}
                    topicId={topicId}
                    serviceId={serviceId}
                  />
                )}
                {!!personIds && !!personIds?.length && (
                  <BusinessActivityStream personIds={personIds} />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} className={classes.rightGridColumn}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <div className={classes.rightGridColumnWrapper}>{children}</div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PersonLayout;
