import React, { useContext, FC } from "react";

import { Formik } from "formik";
import dayjs from "dayjs";

import { useMutation } from "@apollo/client";

import { CreateAppointmentDocument, User, Person } from "../../../../generated/graphql";

import AppointmentDialogForm from "./form";
import { PersonContext } from "../../../App";
import validationSchema from "./validationSchema";
import { TAvatarUser } from "../../UserAvatar";

interface IAdd {
  allowEditUsers: boolean;
  person?: Person;
  currentTime: dayjs.Dayjs;
  minEndsAt: dayjs.Dayjs;
  minStartsAt: dayjs.Dayjs;
  onClose: () => void;
  users: Array<TAvatarUser | User>;
}

const Add: FC<IAdd> = ({
  allowEditUsers,
  person,
  currentTime,
  minEndsAt,
  minStartsAt,
  onClose,
  users,
}) => {
  const { filterUsers } = useContext(PersonContext);

  const userIds = users.map(user => user.id);
  if (filterUsers) {
    filterUsers.forEach(user => {
      if (user.id && (!user.absentUntil || currentTime.isAfter(user.absentUntil))) {
        if (!userIds.some(id => id === user.id)) {
          userIds.push(user.id);
        }
      }
    });
  }

  const initialValues = {
    isAppointment: !!person,
    note: "",
    title: "",
    personId: person?.id ?? -1,
    topicId: -1,
    serviceId: -1,
    date: currentTime,
    from: currentTime,
    to: currentTime.clone().add(15, "minute"), // this number here
    userIds,
  };

  const [createAppointment] = useMutation(CreateAppointmentDocument);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        let from = values.date.clone().hour(values.from.hour());
        from = from.minute(values.from.minute());

        let to = values.date.clone().hour(values.to.hour());
        to = to.minute(values.to.minute());

        const response = await createAppointment({
          variables: {
            appointmentInput: {
              ...values,
              from,
              to,
            },
          },
        });

        setSubmitting(false);

        if (response.data.createAppointment != null) {
          onClose();
        } else {
          setFieldError("from", "Ungültig");
          setFieldError("to", "Ungültig");
        }
      }}
    >
      {props => {
        return (
          <AppointmentDialogForm
            {...props}
            allowEditUsers={allowEditUsers}
            person={person}
            users={users}
            minStartsAt={minStartsAt}
            minEndsAt={minEndsAt}
          />
        );
      }}
    </Formik>
  );
};
export default Add;
