import Chip from "@material-ui/core/Chip";
import { createStyles, withStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const StyledChip = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontSize: 12,
      height: 24,
      left: 46,
      letterSpacing: "0.05em",
      minWidth: 148,
      position: "relative" as "relative",
    },
    label: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  }),
)(Chip);
