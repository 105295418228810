import React from "react";

import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack/";
import dayjs from "dayjs";
import { pathOr } from "ramda";
import MuLink from "@material-ui/core/Link";
import Notification from "../../molecules/Notification";
import Check from "@material-ui/icons/CheckCircle";

import { Appointment, Person, FinishTaskDocument } from "../../../generated/graphql";
import Avatar from "../../atoms/Avatar";
import { UserAvatarFromPerson } from "../UserAvatar";
import { InboxComponent } from "../InboxComponent";
import InboxGroup, { InboxHeadline } from "../InboxGroup";

import { InboxWrapper } from "./components";
import unassignedAvatar from "../../assets/unassignedAvatar.svg";
import useStyles from "../../assets/inboxStyles";

type TItem = { appointment: Appointment; isAdmin?: boolean };
const Item = ({ appointment, isAdmin }: TItem) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [finishTask] = useMutation(FinishTaskDocument);
  const person = pathOr(undefined, ["person"], appointment) as Person;
  const users = appointment?.users;
  // This should be an error, though prevent from crashing
  const author = (users && users[0]?.person) || undefined;

  const revert = (taskId: number) => finishTask({ variables: { taskId, revert: true } });

  const finish = () => {
    if (appointment.task && appointment.task.id) {
      const taskId = appointment.task.id;
      finishTask({ variables: { taskId } }).then(() => {
        enqueueSnackbar("", {
          content: (key: string) => (
            <Notification
              id={key}
              message={
                <>
                  Der Termin wurde erledigt.{" "}
                  <MuLink
                    onClick={() => revert(taskId)}
                    className={classes.notificationLink}
                    underline="always"
                    variant="h4"
                  >
                    Rückgängig machen
                  </MuLink>
                </>
              }
            />
          ),
        });
      });
    }
  };

  return (
    <InboxComponent
      isAppointment
      title={{
        name: appointment.title!,
      }}
      dates={{
        start: appointment.from,
        end: appointment.to,
      }}
      leading={
        !!author ? (
          <UserAvatarFromPerson person={author} />
        ) : (
          <Avatar src={unassignedAvatar} widthfactor={5} />
        )
      }
      overdue={dayjs().isAfter(appointment.from)}
      actions={
        <div className={classes.actionWrapper}>
          {!isAdmin && <Check className={classes.actionItem} onClick={finish} />}
        </div>
      }
      note={appointment.note}
      context={{
        person: person,
        topic: appointment.topic,
        service: appointment.service,
      }}
    />
  );
};

type TAppointmentList = {
  appointments: Appointment[];
  isAdmin?: boolean;
};
const AppointmentList = ({ appointments, isAdmin }: TAppointmentList) => {
  const pastAppointments: Appointment[] = [];
  const todayAppointments: Appointment[] = [];
  const futureAppointments: Appointment[] = [];
  appointments.forEach(appointment => {
    if (dayjs().isAfter(appointment.from)) {
      pastAppointments.push(appointment);
      return;
    }
    if (dayjs().isSame(appointment.from, "day")) {
      todayAppointments.push(appointment);
      return;
    }
    if (dayjs().isBefore(appointment.from)) {
      futureAppointments.push(appointment);
      return;
    }
  });

  return (
    <>
      {!!pastAppointments.length ? (
        <>
          <InboxGroup>
            <InboxWrapper>
              <InboxHeadline>Frühere Termine</InboxHeadline>
            </InboxWrapper>
            {pastAppointments.map((appointment: Appointment) => (
              <Item
                appointment={appointment}
                isAdmin={isAdmin}
                key={`${appointment.id}-appointment-${appointment.from}-${appointment.to}`}
              />
            ))}
          </InboxGroup>
        </>
      ) : (
        <></>
      )}
      {!!todayAppointments.length ? (
        <>
          <InboxGroup>
            <InboxWrapper>
              <InboxHeadline>Heutige Termine</InboxHeadline>
            </InboxWrapper>
            {todayAppointments.map((appointment: Appointment) => (
              <Item
                appointment={appointment}
                isAdmin={isAdmin}
                key={`${appointment.id}-appointment-${appointment.from}-${appointment.to}`}
              />
            ))}
          </InboxGroup>
        </>
      ) : (
        <></>
      )}
      {!!futureAppointments.length ? (
        <>
          <InboxGroup>
            <InboxWrapper>
              <InboxHeadline>Zukünftige Termine</InboxHeadline>
            </InboxWrapper>
            {futureAppointments.map((appointment: Appointment) => (
              <Item
                appointment={appointment}
                isAdmin={isAdmin}
                key={`${appointment.id}-appointment-${appointment.from}-${appointment.to}`}
              />
            ))}
          </InboxGroup>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default AppointmentList;
