import React, { useState, useRef } from "react";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";

import { Person, CounselingMandate } from "../../../generated/graphql";
import TextLink from "../../atoms/TextLink";

import useStyles from "./MandateSelect.styles";

export default function MandateSelect({
  defaultMandate,
  mandates,
  setMandate,
}: {
  defaultMandate?: string;
  mandates: CounselingMandate[];
  setMandate?: (person?: Person) => void;
}) {
  const classes = useStyles();
  const anchor = useRef<HTMLElement>(null);
  const [name, setName] = useState(defaultMandate);
  const [open, setOpen] = useState(false);
  function toggleMenu() {
    setOpen(!open);
  }
  function handleClose(event: any) {
    if (anchor && anchor.current && anchor.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }
  function selectMandate(m?: CounselingMandate) {
    setMandate && setMandate(m?.service?.client?.person || undefined);
    setName(m?.service?.client?.person?.name || undefined);
    toggleMenu();
  }

  return setMandate ? (
    <>
      {!!name && "zum Beratungsmandat "}
      <TextLink onClick={toggleMenu} inputRef={anchor}>
        {!name ? "ohne Beratungsmandat" : name}
      </TextLink>
      <Popper open={open} anchorEl={anchor.current} transition>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList className={classes.menuList}>
                  <MenuItem key="new-contact" onClick={() => selectMandate()}>
                    Ohne Beratungsmandat
                  </MenuItem>
                  {mandates.map(m => (
                    <MenuItem
                      key={`business-memo-mandate-${m.id!}`}
                      onClick={() => selectMandate(m)}
                    >
                      {m.service?.client?.person?.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  ) : (
    <>{defaultMandate || "ohne Beratungsmandat"}</>
  );
}
