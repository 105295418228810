import React, { useState, FC } from "react";
import { useHistory } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Loader from "../../atoms/Loading";
import DataColumn from "../../molecules/DataColumn";
import DataRow from "../../molecules/DataRow";
import BusinessContactData from "../../molecules/BusinessContactData";
import AccountEditDialog from "../../forms/AccountEditDialog";

import { useAccountSubscription } from "../../../hooks/useAccountSubscription";
import { SERVICE_REGION_TRANSLATIONS } from "../../../i18n/translations";
import PrimaryButton from "../../atoms/PrimaryButton";
import IllustratedErrorMessage from "../IllustratedErrorMessage";
import errorIllustration from "../../assets/errorIllustration.svg";

const AccountDetails: FC<{ id: number }> = ({ id }) => {
  const [open, setOpen] = useState(false);
  const { goBack } = useHistory();
  const { account, loading, error } = useAccountSubscription(id);

  const toggle = () => setOpen(!open);

  if (loading) {
    return <Loader />;
  }

  if (error || !account) {
    return (
      <IllustratedErrorMessage
        messages={["Der Account konnte nicht geladen werden."]}
        illustration={errorIllustration}
        illustrationAltText="Elli rutscht auf einer Banane aus"
      >
        <PrimaryButton onClick={goBack}>Zurück</PrimaryButton>
      </IllustratedErrorMessage>
    );
  }

  return (
    <Grid container>
      <DataRow title="Stammdaten" editTrigger={toggle}>
        <DataColumn label="Account">
          <Typography variant="body2">{account?.name || "-"}</Typography>
        </DataColumn>
        <DataColumn label="Übergeordneter Account">
          <Typography variant="body2">{account?.parent?.name || "-"}</Typography>
        </DataColumn>
        <DataColumn label="Branche">
          <Typography variant="body2">{account.industry?.name || "-"}</Typography>
        </DataColumn>
        <DataColumn label="Service-Region">
          <Typography variant="body2">
            {SERVICE_REGION_TRANSLATIONS[account.serviceRegion]}
          </Typography>
        </DataColumn>
        <DataColumn label="Mitarbeiter">
          <Typography variant="body2">{account?.employeesCount || "-"}</Typography>
        </DataColumn>
        <DataColumn label="Führungskräfte">
          <Typography variant="body2">{account?.executivesCount || "-"}</Typography>
        </DataColumn>
      </DataRow>
      {!!account.business && <BusinessContactData business={account.business} />}

      <AccountEditDialog open={open} toggle={toggle} account={account} />
    </Grid>
  );
};
export default AccountDetails;
