import React, { useState } from "react";

import { useParams, useLocation, useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

import {
  usePersonQuery,
  useCreatePersonMemoMutation,
  PersonActivity,
} from "../../../generated/graphql";
import Loader from "../../atoms/Loading";
import { fetchPolicy } from "../../../utils/constants";
import MemoReportFormStep1 from "../../forms/MemoReportFormStep1";

import useStyles from "./styles";

const PersonMemoCreation = () => {
  const classes = useStyles();
  const { personId } = useParams<{ personId: string }>();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const { state }: { state: { activity: PersonActivity } } = useLocation();
  const { data, loading } = usePersonQuery({
    variables: { id: parseInt(personId, 10) },
    fetchPolicy,
  });
  const [createPersonMemo] = useCreatePersonMemoMutation();
  const { replace } = useHistory();

  const person = data?.person;

  if (loading) {
    return <Loader />;
  }

  if (!person) {
    return <div>Person konnte nicht geladen werden</div>;
  }

  return (
    <Grid container className={classes.content}>
      <Grid item xs={2} xl={3} />
      <Grid item xs={8} xl={6}>
        {/* <BusinessMemoCreateForm activity={activity} refetch={refetch} /> */}
        <MemoReportFormStep1
          isCreating
          onSubmit={async (values, { setSubmitting }) => {
            if (!requestInProgress) {
              setRequestInProgress(true)
              const { data } = state?.activity?.id ?
              await createPersonMemo({
                variables: {
                  personId: person.id!,
                  memo: {
                    personActivity: {
                      activityType: values.type,
                      end: values.end,
                      start: values.start,
                      id: state.activity.id,
                    },
                  },
                },
              })
              :
              await createPersonMemo({
                variables: {
                  personId: person.id!,
                  memo: {
                    personActivity: {
                      activityType: values.type,
                      end: values.end,
                      start: values.start,
                    },
                  },
                },
              });

              


              setRequestInProgress(false)

              setSubmitting(false);

              if (data?.createPersonMemo?.id) {
                replace({
                  pathname: `/person/${person.id}/memo/${data?.createPersonMemo?.id}`,
                  state,
                });
              }
            }
          }}
          person={person}
          personActivity={state?.activity}
        />
      </Grid>
      <Grid item xs={2} xl={3} />
    </Grid>
  );
};

export default PersonMemoCreation;
