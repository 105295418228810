import React, { ReactNode } from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Formik, Form } from "formik";

import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import DefaultDialog from "../../molecules/DefaultDialog";
import DefaultDialogContent from "../../molecules/DefaultDialogContent";
import TeamSelect from "../../forms/TeamSelectField/TeamSelectField";
import { UserSelectFieldContainer } from "../../forms/UserSelectField";
import { Team } from "../../../generated/graphql";

interface IAssignModal {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: { team: Team | -1; userIds: number | -1 }) => void;
  showSuccessModal?: boolean;
  successModal?: ReactNode;
}
const AssignModal = ({ open, onClose, onSubmit, showSuccessModal, successModal }: IAssignModal) => {
  return (
    <DefaultDialog open={open}>
      <DefaultDialogContent>
        <Formik initialValues={{ team: -1, userIds: -1 }} onSubmit={onSubmit}>
          {({ values, handleChange }) => {
            if (showSuccessModal) {
              return successModal;
            }

            return (
              <Form style={{ width: "100%" }}>
                <Box mb={3} textAlign="center">
                  <Typography variant="h1">Weiterleiten an</Typography>
                </Box>
                <TeamSelect handleChange={handleChange} disabled={values.userIds !== -1} />
                <UserSelectFieldContainer disabled={values.team !== -1} />
                <Box display="flex" mt={4} justifyContent="center">
                  <SecondaryButton onClick={onClose}>Abbrechen</SecondaryButton>
                  <Box margin={1} />
                  <PrimaryButton type="submit">OK</PrimaryButton>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </DefaultDialogContent>
    </DefaultDialog>
  );
};

export default AssignModal;
