import makeStyles from "@material-ui/styles/makeStyles/makeStyles";

export default makeStyles(() => {
  return {
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
  };
});
