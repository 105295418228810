export const GOOGLE_CALL_CONTENT_TYPE = "GOOGLE_CALL_CONTENT_TYPE";
export const LIFEWORKS_CALL_CONTENT_TYPE = "LIFEWORKS_CALL_CONTENT_TYPE";
export const NONE_CALL_CONTENT_TYPE = "NONE_CALL_CONTENT_TYPE";

export enum CallContentTypes {
  NONE_CALL_CONTENT_TYPE,
  GOOGLE_CALL_CONTENT_TYPE,
  LIFEWORKS_CALL_CONTENT_TYPE,
}
export default interface ICallContentType {
  type: CallContentTypes;
  hrBusiness: string;
  informationSource: string;
}
