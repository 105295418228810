import React, { FC } from "react";
import Create from "@material-ui/icons/Create";
import Grid, { GridItemsAlignment, GridJustification } from "@material-ui/core/Grid";

import useStyles from "./styles";
import Card from "../Card";

type TDataRow = {
  title: string;
  editTrigger?: () => void;
  defaultIcon?: boolean;
  alignItems?: GridItemsAlignment;
  justify?: GridJustification;
};

const DataRow: FC<TDataRow> = ({
  title,
  editTrigger,
  children,
  defaultIcon,
  alignItems,
  justify,
}) => {
  const classes = useStyles();

  return (
    <Card
      title={title}
      onClick={editTrigger}
      actionIcon={!defaultIcon && <Create className={classes.createIcon} />}
    >
      <Grid container spacing={2} alignItems={alignItems} justify={justify}>
        {children}
      </Grid>
    </Card>
  );
};

export default DataRow;
