import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    personCard: {
      marginBottom: theme.spacing(2),
      width: "calc(100% - 260px)",
      "&.noNote": {
        width: "calc(100% - 150px)",
      },
    },
    memoContainer: {
      background: '#e1e7e8',
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
    },

    isTop: {
      position: "relative",
      zIndex: 10
    },

    isNotTop: {
      position: "relative",
      zIndex: 1
    }
  };
});

export default useStyles;
