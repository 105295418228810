import React, { FC, Fragment, useState, useRef, useEffect, memo } from "react";

import Maybe from "graphql/tsutils/Maybe";
import { useDebounce } from "use-debounce";
import TextField from "@material-ui/core/TextField";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";

import { Topic } from "../../../generated/graphql";
import useStyles from "./styles";

export type ITopicSelectOrCreateProps = {
  allowCreate?: boolean;
  topics: Maybe<Maybe<{ __typename?: "Topic" | undefined } & Pick<Topic, "id" | "name">>[]>;
  topic?: Topic | null;
  selectAction: (topic: Topic) => void;
  tabIndex?: number;
};

const TopicSelectOrCreate: FC<ITopicSelectOrCreateProps> = memo(
  ({ selectAction, topics, allowCreate = true, children, tabIndex, topic }) => {
    const [open, setOpen] = useState(false);
    const [createMode, setCreateMode] = useState(false);
    const [topicName, setTopicName] = useState("");
    const anchor = useRef<HTMLElement>(null);
    const classes = useStyles();

    function toggleMenu() {
      setOpen(!open);
    }

    function handleClose(event: any) {
      if (anchor && anchor.current && anchor.current.contains(event.target)) {
        return;
      }
      setOpen(false);
    }

    function saveAndClose(event: any, topic: Topic) {
      selectAction(topic);
      handleClose(event);
    }

    function createTopic(event: any) {
      const {
        target: { value },
      } = event;
      const newTopic = { name: value };
      const existing = topics ? topics.find(t => !!t && t.name === value) : newTopic;
      if (allowCreate || existing) {
        setTopicName(value);
        selectAction(existing || newTopic);
      }
    }

    function toggleCreation(event: any) {
      setCreateMode(true);
      setTopicName("");
      selectAction({ name: "" });
      handleClose(event);
    }

    const [topicDebounce] = useDebounce(topic, 500);

    useEffect(() => {
      if (topicDebounce) {
        setTopicName((topic && topic.name) || "");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topicDebounce]);

    return (
      <Fragment>
        <span>
          Thema{" "}
          <TextField
            className={classes.textField}
            data-testid="topic"
            inputProps={{ className: classes.input }}
            inputRef={anchor}
            onBlur={createMode ? () => setCreateMode(false) : () => undefined}
            onChange={createTopic}
            onClick={!createMode ? toggleMenu : () => undefined}
            tabIndex={tabIndex || 0}
            value={topicName}
          />
        </span>

        <Popper open={open} anchorEl={anchor.current} transition>
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    {allowCreate && topics && topics.length > 0 && (
                      <MenuItem key="new" onClick={toggleCreation}>
                        Neues Thema
                      </MenuItem>
                    )}
                    {topics &&
                      topics.map(
                        (existing, idx) =>
                          existing && (
                            <MenuItem key={idx} onClick={event => saveAndClose(event, existing)}>
                              {existing.name}
                            </MenuItem>
                          ),
                      )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Fragment>
    );
  },
);

export default TopicSelectOrCreate;
