import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    clickable: ({ onBackground }: { onBackground: boolean }) => {
      return {
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        color: theme.palette.primary.main,
        cursor: "pointer",

        "&:first-child": {
          borderTop: `1px solid ${theme.palette.grey[400]}`,
        },
        "&:hover": {
          color: theme.palette.primary.dark,
          backgroundColor: onBackground ? theme.palette.common.white : "#E7F9FF",
          "&>*": {
            color: theme.palette.primary.dark,
          },
        },
      };
    },
  });
});

export default useStyles;
