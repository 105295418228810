import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import StarRate from "@material-ui/icons/StarRate";
import React, { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Person } from "../../../generated/graphql";
import ClientAvatar from "../../atoms/ClientAvatar";
import Flag from "../../atoms/Flag";
import Divider from "./Divider";
import useStyles from "./styles";

export type NamePathMap = {
  path: string;
  name: ReactNode;
  chip?: ReactNode;
};

type TBreadcrumbItem = {
  nameMap: NamePathMap;
  isLink: boolean;
  hideDivider?: boolean;
};

const BreadcrumbItem = ({ nameMap, isLink, hideDivider }: TBreadcrumbItem) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {!hideDivider && <Divider />}
      {isLink ? (
        <>
          <Link
            underline="always"
            component={RouterLink}
            to={nameMap.path}
            variant="h3"
            color="primary"
          >
            {nameMap.name}
          </Link>
          {nameMap.chip && nameMap.chip}
        </>
      ) : (
        <Typography variant="h3" color="textPrimary">
          {nameMap.chip ? (
            <>
              {nameMap.name}
              {nameMap.chip}
            </>
          ) : (
            nameMap.name
          )}
        </Typography>
      )}
    </Box>
  );
};

type TPersonCrumb = {
  person: Pick<
    Person,
    | "id"
    | "mentalState"
    | "name"
    | "alias"
    | "salut"
    | "redFlag"
    | "vip"
    | "yellowFlag"
    | "avatarKeys"
    | "avatar"
  >;
  isLink: boolean;
  chipForPerson?: ReactNode;
};

const PersonCrumb = ({ person, isLink, chipForPerson }: TPersonCrumb) => {
  const classes = useStyles();
  let name = person.name || "NN";
  name = name === "Unbekannt" ? person.alias || name : name;
  const salut = person.salut;
  const red = person.redFlag || false;
  const yellow = person.yellowFlag || false;
  const vip = person.vip || false;
  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center">
        <ClientAvatar person={person} customClass={classes.avatar} red={red} yellow={yellow} />
        {isLink && person.id ? (
          <Link
            underline="always"
            component={RouterLink}
            to={`/person/${person.id}`}
            variant="h3"
            color="primary"
          >
            {`${salut ? `${salut} ` : ""}${name}`}
          </Link>
        ) : (
          <Typography variant="h3" color="textPrimary">{`${
            salut ? `${salut} ` : ""
          }${name}`}</Typography>
        )}
        {(red || yellow) && (
          <Box ml={1.5} paddingTop="3px">
            <Flag red={red} yellow={yellow} />
          </Box>
        )}
        {vip && (
          <Box ml={1.5} paddingTop="3px">
            <StarRate htmlColor="#5AA6E2"></StarRate>
          </Box>
        )}
      </Box>
      {chipForPerson}
    </>
  );
};

type TBreadcrumb = {
  mapList: NamePathMap[];
  person?: Pick<
    Person,
    | "id"
    | "mentalState"
    | "name"
    | "alias"
    | "salut"
    | "redFlag"
    | "vip"
    | "yellowFlag"
    | "avatarKeys"
    | "avatar"
  >;
  chipForPerson?: ReactNode;
};

const Breadcrumb = ({ mapList, person, chipForPerson }: TBreadcrumb) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {person && (
        <PersonCrumb person={person} isLink={!!mapList.length} chipForPerson={chipForPerson} />
      )}
      {mapList.map((nameMap: NamePathMap, key: number) => {
        return (
          <BreadcrumbItem
            key={`breadcrumb-${nameMap.path}-${key}`}
            nameMap={nameMap}
            isLink={key + 1 < mapList.length}
            hideDivider={key === 0 && !person}
          />
        );
      })}
    </Box>
  );
};

export default Breadcrumb;
