import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import { Checkbox, FormControlLabel, Link, Theme, Typography, withStyles } from "@material-ui/core";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import { useHistory, useParams } from "react-router";
import { KeyboardDatePicker } from "@material-ui/pickers";
import SecondaryButton from "../../atoms/SecondaryButton";
import PrimaryButton from "../../atoms/PrimaryButton";
import useStyles from "./styles";

dayjs.extend(LocalizedFormat);

export default function QualtricsQuestionnaire() {
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const { push } = useHistory();
  const { goBack } = useHistory();

  const [isOpen, setIsOpen] = useState(false);

  const [personId, setPersonId] = useState("-1");

  const onChangePerson = (pId?: number) => {
    setPersonId(pId!.toString());
  };

  // if (loading) {
  //   return <Loading />;
  // }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box marginTop={6}>
          <Typography variant="h1" className={classes.subHeadline}>
            Jetzt Qualtrics-Umfrage starten
            </Typography>
            </Box>
        </Grid>
      </Grid>

      <Box marginTop={6}>
        <Box>
        <Typography variant="h4" className={classes.subHeadline}>
          Du möchtest noch etwas ändern? Dann gehe auf “Zurück”, ansonsten schließt du dein
          Gesprächsprotokoll hier ab.
          </Typography>
        </Box>
        {/* <Box>
          Der Vermerk ist temporär und wird mit dem Speichern des Gesprächprotokolls automatisch
          gelöscht. Deshalb müssen Informationen aus dem Vermerk ins Protokoll übertragen werden.
        </Box> */}
      </Box>
      <Box display={'flex'} justifyContent={'center'}>
      <FormControlLabel
        control={
          <Checkbox
            checked={true}
            // onChange={toggle}
          />
        }
        label="Qualtrics-Umfrage durchgeführt"
      />
      </Box>
      <Box display={'flex'} justifyContent={'center'} mt={4}>
      <SecondaryButton onClick={() => {
        window.open('https://google.com', "_blank", "noreferrer");
      }}>Qualtrics-Umfrage starten</SecondaryButton>
      </Box>
      

      <div>
        <div className={classes.clientSelectorContainer}>
          <div className={classes.clientSelector}>
            <Box marginLeft={2}>
              <Link
                variant="h4"
                underline="always"
                color="primary"
                onClick={() => {                  
                  goBack();
                }}
              >
                Zurück zum Umfragetyp
              </Link>              
            </Box>

            <SecondaryButton
              onClick={async () => {                
                push(`/person/${id}`);
              }}
            >
              Cancel
            </SecondaryButton>

            <PrimaryButton
              onClick={async () => {
                alert();
                //push(`/person/${id}/qualtrics-documentation?t=2`);
              }}
            >
              Finish
            </PrimaryButton>
          </div>
        </div>
      </div>
    </>
  );
}

export const StyledKeyboardDatePicker = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
}))(KeyboardDatePicker);
