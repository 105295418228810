import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    buttonText: {
      textTransform: "initial",
      textDecoration: "underline",
    },
    marginLeft: {
      marginLeft: "6px",
    },
    phoneMenuItem: {
      height: "auto",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "& > .MuiTouchRipple-root": {
        display: "none",
      },
    },
    phoneMenuItemIcon: {
      marginRight: theme.spacing(1),
    },
  };
});

export default useStyles;
