import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { useParams } from "react-router-dom";
import { useBreadCrumbInfoByAccountIdQuery } from "../../../generated/graphql";
import { useBusinessSubscription } from "../../../hooks/useBusinessSubscription";
import Loader from "../../atoms/Loading";
import AccountStatusBadge from "../../molecules/AccountStatusBadge";
import Tab from "../../molecules/Tab";
import BusinessCard from "../../organisms/BusinessCard";
import MandateList from "../../organisms/MandateList";
import PersonLayout from "../../templates/PersonLayout";

export default function BusinessObjectPage() {
  const { id } = useParams<{ id: string }>();
  const { businessObject, loading } = useBusinessSubscription(parseInt(id!));
  const accountId = businessObject?.accountId;
  const { data: breadcrumbData } = useBreadCrumbInfoByAccountIdQuery({
    skip: !accountId,
    variables: {
      id: accountId!,
    },
  });
  if (loading) {
    return <Loader />;
  }

  if (!businessObject) {
    return <>Berater nicht gefunden</>;
  }

  const persons = businessObject.persons || [];
  const personIds = persons.map(p => p!.id!);
  const name = businessObject.name || "";
  const mapList = [
    {
      name: name,
      path: "",
      chip: (
        <AccountStatusBadge
          contractEnd={breadcrumbData?.breadCrumbInfoByAccountId?.contractEnd}
          lang={breadcrumbData?.breadCrumbInfoByAccountId?.language || ""}
        />
      ),
    },
  ];

  return (
    <PersonLayout
      mapList={mapList}
      counselor
      personIds={personIds}
      contractExpirationDate={breadcrumbData?.breadCrumbInfoByAccountId?.contractEnd}
    >
      <Box bgcolor="#EFE8DD" width={1} py={2} marginBottom={4}>
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex" ml={3}>
              <Tab type="permContactCalendar" target={`/business/${id}/contacts`}>
                Mitarbeiter
              </Tab>
              <Tab type="insertDriveFile" target={`/business/${id}/documents`}>
                Dokumente
              </Tab>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <BusinessCard business={businessObject} />
      {businessObject.category?.name === "Externe Beratung" && (
        <MandateList business={businessObject} name={name} />
      )}
    </PersonLayout>
  );
}
