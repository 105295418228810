import React, { useState } from "react";
import { useParams } from "react-router-dom";

import {
  BusinessObject,
  PersonsByBusinessQuery,
  useBreadCrumbInfoByAccountIdQuery,
} from "../../../generated/graphql";

import Loader from "../../atoms/Loading";
import PersonLayout from "../../templates/PersonLayout";
import DataRow from "../../molecules/DataRow";
import DataColumn from "../../molecules/DataColumn";
import AddressItem from "../../atoms/Address";
import { useBusinessSubscription } from "../../../hooks/useBusinessSubscription";
import { usePersonsByBusinessSubscription } from "../../../hooks/usePersonsByBusinessSubscription";
import { isValidAddress } from "../../../utils/getAddressDistance";
import BusinessContactPersonDialog from "../../forms/BusinessContactPersonDialog";
import AccountStatusBadge from "../../molecules/AccountStatusBadge";

export default function BusinessContactPersonDetails() {
  const { id, contactId } = useParams<{ id: string; contactId: string }>();

  const { businessObject, loading: loadBusiness } = useBusinessSubscription(parseInt(id, 10));
  const { persons, loading: loadingPersons } = usePersonsByBusinessSubscription(parseInt(id!, 10));
  const accountId = businessObject?.accountId;
  const { data: breadcrumbData } = useBreadCrumbInfoByAccountIdQuery({
    skip: !accountId,
    variables: {
      id: accountId!,
    },
  });

  if (loadingPersons || loadBusiness) {
    return <Loader></Loader>;
  }
  const person = persons.find(p => p!.id === parseInt(contactId, 10));

  if (!person || !businessObject) {
    return <></>;
  }

  return (
    <PersonContactDetailsView
      contactId={parseInt(contactId, 10)}
      person={person}
      businessObject={businessObject}
      contractExpirationDate={breadcrumbData?.breadCrumbInfoByAccountId?.contractEnd}
      language={breadcrumbData?.breadCrumbInfoByAccountId?.language || ""}
    />
  );
}

type TPersonContactDetailsView = {
  contactId: number;
  person: PersonsByBusinessQuery["personsByBusiness"][0];
  businessObject: BusinessObject;
  contractExpirationDate?: string;
  language?: string;
};
const PersonContactDetailsView = ({
  contactId,
  person,
  businessObject,
  contractExpirationDate,
  language,
}: TPersonContactDetailsView) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const mapList = businessObject.accountId
    ? [
        { name: "Accounts", path: "/account" },
        {
          name: businessObject.name || "",
          path: `/account/${businessObject.accountId!}`,
          chip: (
            <AccountStatusBadge
              contractEnd={contractExpirationDate}
              lang={language}
            />
          ),
        },
      ]
    : [
        {
          name: businessObject.name || "",
          path: `/business/${businessObject.id}`,
        },
      ];
  const address = person.addresses && person.addresses[0];
  const phone = person.phones && person.phones[0];
  const email = person.emails && person.emails[0];

  return (
    <PersonLayout
      counselor
      contractExpirationDate={contractExpirationDate}
      person={person}
      mapList={[
        ...mapList,
        {
          name: "Mitarbeiter",
          path: `/business/${businessObject.id!}/contacts`,
        },
        {
          name: person.name,
          path: "",
        },
      ]}
    >
      <DataRow title="Persönliche Daten" editTrigger={toggle}>
        <DataColumn label="Akademischer Titel" xs={4}>
          {person.title || "-"}
        </DataColumn>
        {address && isValidAddress(address) && (
          <DataColumn label="Adresse" xs={4}>
            <AddressItem address={address}></AddressItem>
          </DataColumn>
        )}
        <DataColumn label="Telefonnummer" xs={4}>
          {phone?.readableNumber || "-"}
        </DataColumn>
        <DataColumn label="E-Mail-Adresse" xs={4}>
          {email?.email || "-"}
        </DataColumn>
        <DataColumn label="Notiz" xs={4}>
          {person.note || "-"}
        </DataColumn>
      </DataRow>
      <BusinessContactPersonDialog
        businessId={businessObject.id!}
        toggle={toggle}
        open={open}
        person={person}
      />
    </PersonLayout>
  );
};
