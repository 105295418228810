const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST || "X_BACKEND_HOST";
const FRONTEND_HOST = process.env.REACT_APP_FRONTEND_HOST || "X_FRONTEND_HOST";
const USE_HTTPS = process.env.NODE_ENV === "production";

const chatWebSocket = process.env.REACT_APP_CHAT_WEBSOCKET;

const chatVersion = process.env.REACT_APP_CHAT_VERSION;

const inboxString = process.env.REACT_APP_INBOXES;


const inboxes = inboxString
  ? inboxString.split(",")
  : [
      "f2f@otheb.de",
      "f2fnetzwerk@otheb.de",
      "lifeservices@otheb.de",
      "mitarbeiterberatung-huk@otheb.de",
      "mitarbeiterberatung-vrk@otheb.de",
      "mut@otheb.de",
      "wpo@employee-assistance.de",
    ];
    
// const inboxesWpo = ["wpo@employee-assistance.de"];
const inboxesWpo = process.env.WPO_MAIL_ADDRESS || "dev@otheb.de";
const wpoIncomingEmail = process.env.WPO_INCOMING_EMAIL || "dev@otheb.de";
const wpoPersonId = process.env.WPO_PERSON_ID || -1;

const config = {
  inboxes,
  inboxesWpo,
  wpoPersonId,
  wpoIncomingEmail,
  chatVersion,
  chatWebSocket,
  scopes: "openid profile email",
  clientId: "725155231231-5ct6kn2nn6og37dpt9s4kvl9pggndpkr.apps.googleusercontent.com",
  authUrl: `${USE_HTTPS ? "https" : "http"}://${BACKEND_HOST}/tokensignin/`,
  backendUrl: `${USE_HTTPS ? "https" : "http"}://${BACKEND_HOST}/graphql/`,
  backendWsUrl: `${USE_HTTPS ? "wss" : "ws"}://${BACKEND_HOST}/ws`,
  frontendUrl: `${USE_HTTPS ? "https" : "http"}://${FRONTEND_HOST}`,
};

export default config;
