import React, { SyntheticEvent, Fragment } from "react";

import { ApolloError } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { GoogleLogout } from "react-google-login";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Lock from "@material-ui/icons/LockRounded";
import Settings from "@material-ui/icons/Settings";
import Phone from "@material-ui/icons/AddIcCall";
import Box from "@material-ui/core/Box";

import { MeQuery } from "../../../generated/graphql";

import { ReactComponent as TeamIcon } from "../../assets/groupIcons.svg";
import config from "../../../config";
import auth from "../../../services/authService";
import useStyles from "./styles";

type TAccountMenu = {
  anchorEl?: null | HTMLElement;
  isMenuOpen: boolean;
  handleMenuClose: (event: SyntheticEvent<{}, Event>) => void;
  openExtensionsDialog: () => void;
  openTeamsDialog: () => void;
  profile?: MeQuery["me"];
  teamsError?: ApolloError;
};

const AccountMenu = ({
  anchorEl,
  isMenuOpen,
  handleMenuClose,
  profile,
  openExtensionsDialog,
  openTeamsDialog,
  teamsError,
}: TAccountMenu) => {
  const { push } = useHistory();
  const classes = useStyles();

  const settings = (event: SyntheticEvent<{}, Event>) => {
    if (profile) push(`/person/${profile?.id}/details`);
    handleMenuClose(event);
  };

  const extensions = (event: SyntheticEvent<{}, Event>) => {
    if (profile) openExtensionsDialog();
    handleMenuClose(event);
  };

  const teams = (event: SyntheticEvent<{}, Event>) => {
    if (profile) openTeamsDialog();
    handleMenuClose(event);
  };

  return (
    <Menu
      keepMounted
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        {profile && profile.avatar ? (
          <Fragment>
            <Avatar
              color="inherit"
              className={classes.avatar}
              src={profile.picture || profile.avatar}
            />
            {profile.name}
          </Fragment>
        ) : (
          <AccountCircle />
        )}
      </MenuItem>
      {profile?.user?.primeDN && (
        <Box px={3} py={1}>
          <Typography variant="body2">Nebenstelle: {profile?.user.primeDN}</Typography>
        </Box>
      )}
      {!["/Administratoren", "/Account Management"].includes(profile?.role ?? "") ? (            
        <MenuItem onClick={teams} disabled={Boolean(teamsError)}>
          <TeamIcon className={classes.avatar} />
          Teams
        </MenuItem>
      ) : null}      

      <MenuItem onClick={extensions}>
        <Phone className={classes.avatar} />
        Nebenstelle
      </MenuItem>
      <MenuItem onClick={settings}>
        <Settings className={classes.avatar} />
        Einstellungen
      </MenuItem>
      <GoogleLogout
        clientId={config.clientId}
        onLogoutSuccess={auth.googleLogoutSuccess}
        onFailure={console.log}
        render={renderprops => (
          <MenuItem onClick={renderprops.onClick}>
            <Lock color="inherit" className={classes.avatar} />
            Abmelden
          </MenuItem>
        )}
      />
    </Menu>
  );
};
export default AccountMenu;
