import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { Hour, Maybe } from "../../../../generated/graphql";

import { groupHours, formatGroupedHours } from "../../../../utils/hoursHelper";
import DataColumn from "../../../molecules/DataColumn";

function getFormattedHours(hours: Maybe<Hour>[]) {
  const groupedHours = groupHours(hours);

  groupedHours.sort((a, b) => {
    const minA = Math.min.apply(Math, a.days);
    const minb = Math.min.apply(Math, b.days);

    if (minA > minb) {
      return 1;
    } else if (minA < minb) {
      return -1;
    } else {
      return 0;
    }
  });

  const formattedGroupedHours = formatGroupedHours(groupedHours);

  return formattedGroupedHours.map(group => {
    return (
      <Box key={`address-label-${group}`}>
        <Typography gutterBottom variant="body2">
          {group}
        </Typography>
      </Box>
    );
  });
}

interface IHoursField {
  // setFieldValue: any;
  hours: Hour[];
}

const HoursField = ({ hours }: IHoursField) => {
  let hoursLabel: any = "Noch keine Öffnungzeiten gespeichert";

  if (!!hours) {
    const formattedHours = getFormattedHours(hours);

    if (formattedHours.length > 0) {
      hoursLabel = formattedHours;
    }
  }

  return <DataColumn xs={12}>{hoursLabel}</DataColumn>;
};

export default HoursField;
