import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    content: {
      paddingTop: theme.spacing(12),
    },
    contentLeft: {
      position: "relative",
      minHeight: theme.spacing(12),
    },
    rightGridColumn: {
      position: "relative" as "relative",
      "&:before": {
        backgroundColor: theme.palette.grey[400],
        content: '""',
        height: "100%",
        left: 0,
        position: "absolute" as "absolute",
        top: theme.spacing(3),
        width: 1,
      },
    },
    rightGridColumnWrapper: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
    tabWrapper: {
      paddingTop: 8,
    },
    tab: {
      color: theme.palette.primary.main,
      display: "flex",
      marginBottom: theme.spacing(6),
      marginRight: theme.spacing(6),
      "&>p": {
        marginTop: 5,
      },
      "&>svg": {
        marginRight: 15,
        height: theme.spacing(2),
        width: theme.spacing(2),
      },
    },
    tabText: {
      borderBottom: "solid 1px",
      color: theme.palette.primary.main,
      cursor: "pointer",
      lineHeight: 1,
      marginBottom: "1px",
      "&:hover": {
        borderBottom: "solid 2px",
        marginBottom: "0",
      },
    },
  };
});

export default useStyles;
