import React, { createContext, ReactNode, useContext, useRef } from "react";
import dayjs from "dayjs";
import IdleTimer from "react-idle-timer";

import { useMeQuery, MeQuery } from "../../generated/graphql";
import auth from "../../services/authService";
import ExtensionDialog from "../../components/organisms/ExtensionDialog";
import Loader from "../../components/atoms/Loading";

import rules from "./rules";

const AuthorizationContext = createContext<{
  isAuthorized: (action: string) => boolean;
  me?: MeQuery["me"];
}>({ isAuthorized: (action: string) => true });

export default function AuthorizationProvider({ children }: { children: ReactNode }) {
  if (!auth.isAuthenticated()) {
    return <>{children}</>;
  }
  return <AuthenticatedAuthorizationProvider>{children}</AuthenticatedAuthorizationProvider>;
}

function AuthenticatedAuthorizationProvider({ children }: { children: ReactNode }) {
  const { data, loading } = useMeQuery({ fetchPolicy: "cache-first" });

  const idleTimer = useRef<IdleTimer | null>();
  const blocked = useRef<boolean>(false);
  const me = data?.me;

  const isAuthorized = (action: string) => {
    // isAuthorized is allways called after an auth.isAuthenticated()
    if (action === "/") {
      return true;
    }
    if (!me || !me.role) {
      return false;
    }
    // @ts-ignore
    const allowedRules = me.role in rules ? rules[me.role] : rules[""];
    return allowedRules.includes(action);
  };

  function refreshCallback() {
    blocked.current = false;
  }

  function onAction(e: Event) {
    if (auth.expiresAt) {
      const expires = dayjs(auth.expiresAt);
      const diff = expires.diff(dayjs(), "minute");
      if (diff <= 45 && !blocked.current) {
        // refreshSession google session every 15 minutes while active
        blocked.current = true;
        auth.doRefreshAuth(refreshCallback);
      }
    }
  }

  function onActive() {}

  function onIdle(e: Event) {
    auth.doLogout("/session-end");
  }

  return (
    <AuthorizationContext.Provider value={{ isAuthorized, me }}>
      {me?.id && <ExtensionDialog personId={me.id} />}
      <IdleTimer
        ref={ref => (idleTimer.current = ref)}
        element={document}
        timeout={1000 * 60 * 60 * 14} // 14 hours
        onActive={onActive}
        onAction={onAction}
        onIdle={onIdle}
      />
      {loading ? <Loader /> : children}
    </AuthorizationContext.Provider>
  );
}

/**
 * import useAuthorization to file where you need to check auth on some actions
 */

export function useAuthorization() {
  const context = useContext(AuthorizationContext);
  if (context === undefined) {
    throw new Error("useCountDispatch must be used within a AuthorizationProvider");
  }
  return context;
}
