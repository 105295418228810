import gql from "graphql-tag";

export const fragments = {
  gMailMessage: gql`
    fragment gMailMessage on GMailMessage {
      __typename
      id
      incoming
      historyId
      messageId
      threadId
      body
      date
      attachments {
        attachmentId
        filename
        mimeType
      }
      payload {
        from
        to
        subject
        returnPath
      }
    }
  `,
};

export const getGMailThreads = gql`
  query getGMailThreads {
    getGMailThreads {
      id
      incoming
      historyId
      messageId
      threadId
      body
      date
      attachments {
        attachmentId
        filename
        mimeType
      }
      payload {
        from
        to
        subject
        returnPath
      }
      personActivity {
        id
        activityType
        start
        end
        user {
          id
          person {
            id
            avatar
            avatarKeys
            picture
          }
        }
      }
      gMailInbox {
        id
        email
      }
      user {
        id
        person {
          id
          name
          avatar
          avatarKeys
          picture
          mentalState
          temporary
          emails {
            email
          }
        }
      }
      businessObject {
        id
        name
        email {
          id
          email
        }
      }
      person {
        id
        name
        avatar
        avatarKeys
        mentalState
        temporary
        emails {
          email
        }
      }
    }
  }
`;

export const getOneGMailThread = gql`
  query getOneGMailThread($threadId: String!) {
    getOneGMailThread(threadId: $threadId) {
      topic {
        id
        name
      }
      personActivity {
        __typename
        id
        activityType
        start
        end
        user {
          id
          person {
            id
            avatar
            avatarKeys
            picture
          }
        }
      }
      emails {
        id
        incoming
        historyId
        messageId
        threadId
        body
        date
        attachments {
          attachmentId
          filename
          mimeType
        }
        payload {
          from
          to
          subject
          returnPath
        }
        gMailInbox {
          id
          email
        }
        user {
          id
          person {
            id
            name
            avatar
            avatarKeys
            picture
            mentalState
            temporary
            emails {
              email
            }
          }
        }
        businessObject {
          id
          name
          email {
            id
            email
          }
        }
        person {
          id
          name
          avatar
          avatarKeys
          mentalState
          temporary
          emails {
            email
          }
        }
        personActivity {
          id
          activityType
          start
          end
          user {
            id
            person {
              id
              avatar
              avatarKeys
              picture
            }
          }
        }
      }
    }
  }
`;

export const getOneThreadByActivity = gql`
  query getOneThreadByActivity($personActivityId: Int!) {
    getOneThreadByActivity(personActivityId: $personActivityId) {
      id
      incoming
      historyId
      messageId
      threadId
      body
      date
      attachments {
        attachmentId
        filename
        mimeType
      }
      payload {
        from
        to
        subject
        returnPath
      }
      user {
        id
        person {
          id
          name
          avatar
          avatarKeys
          picture
          mentalState
          temporary
          emails {
            email
          }
        }
      }
      businessObject {
        id
        name
        email {
          id
          email
        }
      }
      person {
        id
        name
        avatar
        avatarKeys
        mentalState
        temporary
      }
      personActivity {
        id
        activityType
        start
        end
      }
    }
  }
`;

export const getAttachmentData = gql`
  query getAttachmentData($id: String!, $messageId: String!) {
    getAttachmentData(id: $id, messageId: $messageId)
  }
`;
