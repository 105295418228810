import React, { FC } from "react";
import PhoneInTalk from "@material-ui/icons/PhoneInTalk";
import CallEndIcon from "@material-ui/icons/CallEnd";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { PersonActivity } from "../../../generated/graphql";

const CallStatusComponent: FC<{ activity: PersonActivity }> = ({ activity }) => {
  const number = activity.call?.readableNumber || "";
  const name =
    activity.serviceResearchObject?.businessObject?.name ||
    activity.serviceResearchObject?.contactPerson?.name ||
    "";
  const active = activity.isActive ?? true;
  return (
    <Box display="flex">
      <Box marginRight={1} display="flex" justifyContent="center" alignItems="center">
        {active ? (
          <PhoneInTalk color={"secondary"} fontSize="large"></PhoneInTalk>
        ) : (
          <CallEndIcon fontSize="large"></CallEndIcon>
        )}
      </Box>
      <Box>
        <Typography variant="h4" color={active ? "secondary" : "inherit"}>
          {active ? "Du telefonierst mit:" : "Telefonat beendet:"}
        </Typography>
        <Typography variant="body2">
          {name}
          {number.length && `${name.length ? `, ${number}` : number}`}
        </Typography>
      </Box>
    </Box>
  );
};

export default CallStatusComponent;
