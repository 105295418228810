import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { Field } from "formik";
import { Select } from "formik-material-ui";
import React from "react";
import { useDepartmentsQuery } from "../../../generated/graphql";
import { TabIndex } from "../../../types";
import { fetchPolicy } from "../../../utils/constants";



type TDepartmentSelect = {
  accountId: number;
};

const DepartmentSelect = ({ accountId, tabIndex = 0 }: TDepartmentSelect & TabIndex) => {
  const { data } = useDepartmentsQuery({ variables: { accountId }, fetchPolicy });

  const departments = data?.departments || [];

  return (
    <FormControl fullWidth margin="normal">
      {!departments.length ? (
        <TextField label="Standort" disabled defaultValue="Hauptstandort" />
      ) : (
        <>
          <InputLabel htmlFor="clientType">Standort</InputLabel>
          <Field name="departmentId" label="Standort" component={Select} tabIndex={tabIndex}>
            {departments.map(dep => (
              <MenuItem value={dep!.id || ""} key={`department${dep?.id}`}>
                {dep!.name}
              </MenuItem>
            ))}
          </Field>
        </>
      )}
    </FormControl>
  );
};
export default DepartmentSelect;
