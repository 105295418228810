import { ServiceResearchObjectStatus } from "../generated/graphql";

export default function researchStatusHelper(status?: ServiceResearchObjectStatus | null) {
  switch (status) {
    case ServiceResearchObjectStatus.open:
      return "Offen";
    case ServiceResearchObjectStatus.suitable:
      return "Geeignet";
    case ServiceResearchObjectStatus.limited:
      return "Bedingt Geeignet";
    case ServiceResearchObjectStatus.unsuitable:
      return "Ungeeignet";
    default:
      return "";
  }
}
