import React, { Fragment, FC } from "react";
import { Formik } from "formik";
import Typography from "@material-ui/core/Typography";
import {
  BusinessObject,
  Memo,
  useUpdateMemoMutation,
  CounselingMandate,
} from "../../../generated/graphql";
import BusinessMemoForm from "../BusinessMemoForm";
import useStyles from "../reportStyles";

export interface IBusinessMemoCreateFormProps {
  memo: Memo;
  customCallBack: () => void;
  business: BusinessObject;
  mandates: CounselingMandate[];
}

const BusinessMemoCreateForm: FC<IBusinessMemoCreateFormProps> = ({
  business,
  memo,
  customCallBack,
  mandates,
}) => {
  const classes = useStyles();
  const [updateMemo] = useUpdateMemoMutation();

  return (
    <Fragment>
      <Typography variant="h1" className={classes.headline}>
        Erstelle eine neue Gesprächsnotiz zu {business.name!}.
      </Typography>
      <Formik
        initialValues={{
          start: memo.personActivity?.start || "",
          end: memo.personActivity?.end || "",
          type: memo.personActivity?.activityType || "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          updateMemo({
            variables: {
              memo: {
                id: memo.id,
                personActivity: {
                  id: memo.personActivity && memo.personActivity.id,
                  activityType: values.type,
                  end: values.end,
                  start: values.start,
                },
              },
            },
          }).then(() => {
            customCallBack();
            setSubmitting(false);
          });
        }}
      >
        {formProps => (
          <BusinessMemoForm
            {...formProps}
            business={business}
            defaultContact={memo.contact || undefined}
            defaultMandate={
              memo.personActivity?.refPersonId
                ? mandates.find(
                    m => m.service?.client?.person?.id === memo.personActivity?.refPersonId,
                  )?.service?.client?.person?.name || undefined
                : undefined
            }
            mandates={mandates}
          />
        )}
      </Formik>
    </Fragment>
  );
};
export default BusinessMemoCreateForm;
