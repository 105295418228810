import React from "react";

import { FormikValues, Form } from "formik";

import Box from "@material-ui/core/Box";

import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import WizardNavigation from "../../molecules/WizardNavigation";

export type TStepRisk = {
  backAction: () => void;
  saveInput: (params: any) => any;
  setFieldValue: (field: string, value: any) => void;
  values: FormikValues;
};

const StepRisk = ({ backAction, setFieldValue }: TStepRisk) => {
  return (
    <Form>
      <Box display="flex" justifyContent="center" mt={6}>
        <PrimaryButton
          onClick={() => {
            setFieldValue("risk", false);
          }}
          type="submit"
        >
          Nein
        </PrimaryButton>
        <Box mx={1} />
        <PrimaryButton
          onClick={() => {
            setFieldValue("risk", true);
          }}
          type="submit"
        >
          Ja
        </PrimaryButton>
      </Box>
      <WizardNavigation>
        <SecondaryButton onClick={backAction}>Zurück</SecondaryButton>
      </WizardNavigation>
    </Form>
  );
};

export default StepRisk;
