import React from "react";

import dayjs from "dayjs";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import Markup from "../../atoms/Markup";
import { User } from "../../../generated/graphql";
import { getUserName } from "../../../utils/userNameHelper";
import Flag from "../../atoms/Flag";

type TCaseManagementRisk = {
  lastUpdate: string;
  risk: {
    flag: "redFlag" | "yellowFlag" | "whiteFlag" | "unflagged" | null;
    riskAssessment: string;
  };
  user: User;
};

const CaseManagementRisk = ({ lastUpdate, risk, user }: TCaseManagementRisk) => {
  const flag = {
    redFlag: "Rote Flagge",
    yellowFlag: "Gelbe Flagge",
    whiteFlag: "Weiße Flagge",
    unflagged: "Entflaggt",
  }[risk.flag as "redFlag" | "yellowFlag" | "whiteFlag" | "unflagged"];

  return (
    <Box display="flex" mb={2}>
      <Box mr={1}>
        <Box
          height={40}
          width={40}
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={-1}
        >
          {/* TODO: refactor me! */}
          <Flag
            red={risk.flag === "redFlag"}
            yellow={risk.flag === "yellowFlag"}
            white={risk.flag === "whiteFlag"}
            unflagged={risk.flag === "unflagged" || !risk.flag}
          />
        </Box>
      </Box>
      <Box>
        <Typography style={{ marginBottom: "4px" }} variant="h4">
          {flag || "Ohne Flagge"}
        </Typography>
        {!!risk.riskAssessment && risk.riskAssessment.length > 2 && (
          <Markup value={risk.riskAssessment || ""}></Markup>
        )}
        <Typography style={{ color: "#979797", marginTop: "4px" }} variant="body2">
          {dayjs(lastUpdate).format("DD.MM.YYYY H:mm:ss")}, {getUserName(user!.person)}
        </Typography>
      </Box>
    </Box>
  );
};
export default CaseManagementRisk;
