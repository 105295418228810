import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    content: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    },
    message: {
      marginBottom: theme.spacing(2),
    },
  };
});

export default useStyles;
