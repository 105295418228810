import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    incomleteFile: {
      opacity: 0.3,
    },
    documentArea: {
      minHeight: 72,
      display: "flex",
      width: "100%",
    },
    borderArea: {
      width: "100%",
      position: "relative",
      display: "flex",
      flexDirection: "column",

      "&:after": {
        background: theme.palette.grey[400],
        bottom: 0,
        content: '""',
        height: 1,
        left: 0,
        position: "absolute",
        width: "100%",
      },
      "&:before": {
        background: theme.palette.grey[400],
        content: '""',
        height: 1,
        left: 0,
        position: "absolute",
        top: 0,
        width: "100%",
      },
    },
    column: {
      alignItems: "center",
      display: "flex",
      position: "relative",
      padding: theme.spacing(2, 1),
      "&>a": {
        alignItems: "center",
        display: "flex",
        overflow: "hidden",
        textAlign: "left",
      },
    },
    onelineEllipsis: {
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap" as "nowrap",
    },
  };
});

export default useStyles;
