import React from "react";
import Typography from "@material-ui/core/Typography";
import ResearchStatusIcon from "../ResearchStatusIcon";

import { ServiceResearchObjectStatus } from "../../../generated/graphql";
import helper from "../../../utils/researchStatusHelper";
import useStyles from "./styles";

type TResearchStatusIconLabel = {
  className?: string;
  status?: ServiceResearchObjectStatus | null;
};
const ResearchStatusIconLabel = ({ status, className }: TResearchStatusIconLabel) => {
  const classes = useStyles();

  return status ? (
    <Typography className={`${classes.iconLabel} ${className}`} variant="h3">
      <ResearchStatusIcon status={status}></ResearchStatusIcon>
      {helper(status)}
    </Typography>
  ) : (
    <></>
  );
};

export default ResearchStatusIconLabel;
