import React, { FC } from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { PropTypes } from "@material-ui/core";

import { Select } from "formik-material-ui";
import { Field } from "formik";
import { AccountType } from "../../../generated/graphql";
import { SOURCE_OF_INFORMATION_OPTIONS } from "../../pages/PersonDetails/Other";

const informationSources = SOURCE_OF_INFORMATION_OPTIONS[AccountType.lifeWorks];

const InformationSourceSelect: FC<{ margin?: PropTypes.Margin; fullWidth?: boolean }> = ({
  fullWidth = true,
  margin = "normal",
}) => {
  return (
    <FormControl fullWidth={fullWidth} margin={margin}>
      <InputLabel htmlFor="informationSource">Source of Information</InputLabel>
      <Field name="informationSource" label="Source of Information" component={Select}>
        {
          informationSources.map((informationSource) => {
            return (
              <MenuItem value={informationSource.value}>{informationSource.label}</MenuItem>
            )
          })
        }        
      </Field>
    </FormControl>
  );
};

export default InformationSourceSelect;
