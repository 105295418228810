import React, { useState } from "react";
import { Formik, FormikProps, FormikValues, Field } from "formik";
import { TextField } from "formik-material-ui";
import PrimaryButton from "../../atoms/PrimaryButton";
import SlateRTE, { initialFormValue } from "../../molecules/SlateRTE";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@material-ui/core/Box";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Collapse from "@material-ui/core/Collapse";
import useLocalStorage, { LocalStorage } from "../../../utils/localStorageHelper";
import useStyles from "../formStyles";

import {
  useUpdateServiceResearchObjectMutation,
  ServiceResearchObjectStatus,
  PersonActivity,
} from "../../../generated/graphql";

type TResearchCallForm = {
  activity: PersonActivity;
  active: boolean;
  cancel: () => void;
};

function ResearchCallForm({ activity, active, cancel }: TResearchCallForm) {
  const [update] = useUpdateServiceResearchObjectMutation();
  const research: any = activity.serviceResearchObject;
  const { getItem, removeItem } = useLocalStorage(LocalStorage.researchCall, String(research.id));
  const submit = (values: any) => {
    return update({
      variables: {
        serviceResearchObjectInput: {
          note: values.note,
          statusChangeReason: values.statusChangeReason || "",
          status: values.status,
        },
        id: research.id,
      },
    });
  };
  return (
    <Formik
      initialValues={{
        note: getItem(`note`) ? getItem(`note`) : research.note || initialFormValue,
        statusChangeReason: getItem(`statusChangeReason`)
          ? getItem(`statusChangeReason`)
          : research.statusChangeReason || "",
        status: getItem(`status`)
          ? getItem(`status`)
          : research.status || ServiceResearchObjectStatus.open,
      }}
      onSubmit={(values, { setSubmitting }) => {
        submit(values).then(() => {
          setSubmitting(false);
          removeItem(`note`);
          removeItem(`statusChangeReason`);
          removeItem(`status`);
          removeItem(`status-changed`);
          cancel();
        });
      }}
    >
      {(props: FormikProps<FormikValues>) => (
        <FormComp {...props} cancel={cancel} active={active} id={research.id} />
      )}
    </Formik>
  );
}

function FormComp({
  values,
  setFieldValue,
  handleSubmit,
  id,
}: {
  active: any;
  cancel: () => void;
  id: any;
} & FormikProps<FormikValues>) {
  const classes = useStyles();
  const { setItem, getItem } = useLocalStorage(LocalStorage.researchCall, String(id));
  const [changed, setChanged] = useState(!!getItem("status-changed"));
  const handleChange = (name: string, value: string) => {
    setFieldValue(name, value);
    // using localStorage should be good
    // call dialog is only visible to one user and otherwise we loose input
    setItem(name, value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Field>
        {({ field }: any) => (
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="status"
              name="status"
              value={values.status}
              onChange={(e: any, value: string) => {
                handleChange("status", value);
                setChanged(true);
                setItem("status-changed", "true");
              }}
              row
              tabIndex={1}
            >
              <FormControlLabel
                value={ServiceResearchObjectStatus.open}
                control={<Radio />}
                label="Offen"
                classes={{ root: classes.radioRoot }}
              />
              <FormControlLabel
                value={ServiceResearchObjectStatus.suitable}
                control={<Radio />}
                label="Geeignet"
                classes={{ root: classes.radioRoot }}
              />
              <FormControlLabel
                value={ServiceResearchObjectStatus.limited}
                control={<Radio />}
                label="Bedingt geeignet"
                classes={{ root: classes.radioRoot }}
              />
              <FormControlLabel
                value={ServiceResearchObjectStatus.unsuitable}
                control={<Radio />}
                label="Ungeeignet"
                classes={{ root: classes.radioRoot }}
              />
            </RadioGroup>
          </FormControl>
        )}
      </Field>
      <Collapse in={changed}>
        <FormControl className={classes.formControl} margin="normal" fullWidth>
          <Field
            name="statusChangeReason"
            component={TextField}
            label="Begründung"
            tabIndex={2}
            inputProps={{
              onChange: (event: any) => {
                handleChange("statusChangeReason", event.target.value);
              },
            }}
          />
        </FormControl>
      </Collapse>
      <SlateRTE
        value={values.note}
        small
        label="Meine Notizen"
        handleChange={(value: string) => handleChange("note", value)}
        tabIndex={3}
      />
      <Box alignItems="center" justifyContent="center" display="flex" marginTop={12} width={1}>
        <PrimaryButton type="submit">Ok</PrimaryButton>
      </Box>
    </form>
  );
}

export default ResearchCallForm;
