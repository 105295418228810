import React, { FC, useContext } from "react";

import Typography from "@material-ui/core/Typography";
import useStyles from "../styles";
import {
  ServiceStatus,
  ServiceWizardStatus,
  ServiceType,
  PersonActivity,
} from "../../../../generated/graphql";
import { ActivitiesStreamContext } from "..";

const getInternalConsultTitle = ({
  status,
  wizardStatus,
}: {
  status: ServiceStatus;
  wizardStatus: ServiceWizardStatus;
}) => {
  switch (wizardStatus) {
    case ServiceWizardStatus.incomplete:
      return `Interne Beratung angelegt aber noch nicht fertiggestellt`;
    case ServiceWizardStatus.complete:
    default:
      switch (status) {
        case ServiceStatus.counseling:
          return "Interne strukturierte Beratung geplant, Beratung läuft.";
        case ServiceStatus.finished:
          return "Interne strukturierte Beratung erfolgreich durchgeführt und abgeschlossen.";
        case ServiceStatus.started:
        case ServiceStatus.research:
        default:
          return `Interne strukturierte Beratung erstellt`;
      }
  }
};

const getExternalConsultTitle = ({
  status,
  wizardStatus,
}: {
  status: ServiceStatus;
  wizardStatus: ServiceWizardStatus;
}) => {
  // return `Externe Beratung angelegt`;
  switch (wizardStatus) {
    case ServiceWizardStatus.incomplete:
      return `Externe Beratung angelegt aber noch nicht fertiggestellt`;
    case ServiceWizardStatus.complete:
    default:
      switch (status) {
        case ServiceStatus.started:
          return "Externe Beratung angelegt aber noch nicht fertiggestellt";
        case ServiceStatus.counseling:
          return "In Beratung";
        case ServiceStatus.finished:
          return "Beratung abgeschlossen";
        case ServiceStatus.research:
        default:
          return `Externe Beratung geplant`;
      }
  }
};

const getServiceTitle = ({
  name,
  status,
  wizardStatus,
}: {
  name: string;
  status: ServiceStatus;
  wizardStatus: ServiceWizardStatus;
}) => {
  switch (wizardStatus) {
    case ServiceWizardStatus.incomplete:
      return `Service ${name} angelegt aber noch nicht fertiggestellt`;
    case ServiceWizardStatus.complete:
    default:
      switch (status) {
        case ServiceStatus.ready:
          return `Service ${name} ist bereit zur Rückmeldung`;
        case ServiceStatus.finished:
          return `Service ${name} wurde rückgemeldet`;
        case ServiceStatus.research:
          return `Service ${name} wird recherchiert`;
        default:
          return `Service ${name} gebucht`;
      }
  }
};

const getTitle = ({
  name,
  status,
  type,
  wizardStatus,
}: {
  name: string;
  status: ServiceStatus;
  type: ServiceType;
  wizardStatus: ServiceWizardStatus;
}) => {
  switch (type) {
    case ServiceType.internalConsult:
      return getInternalConsultTitle({ status, wizardStatus });
    case ServiceType.externalConsult:
      return getExternalConsultTitle({ status, wizardStatus });
    default:
      return getServiceTitle({ name, status, wizardStatus });
  }
};

type TServiceBubble = {
  activity: PersonActivity;
  isLink?: boolean;
};

export const ServiceBubble: FC<TServiceBubble> = ({ activity, isLink }) => {
  const { context } = useContext(ActivitiesStreamContext);
  const classes = useStyles();
  const service = activity.service!;
  const topic = activity.topic!;

  const name = service.serviceMeta?.name;
  const type = service.serviceMeta?.type;
  const status = service.status;
  const wizardStatus = service.wizardStatus;
  const topicName = topic.name;

  if (!status || !type || !wizardStatus || !name) {
    // This is an error and should be display as what it is!
    return <Typography variant="h4">Error</Typography>;
  }

  const title = getTitle({ name, status, type, wizardStatus });
  // Due to refactoring: https://medium.com/@calebmer/when-to-use-graphql-non-null-fields-4059337f6fc8
  let personName = activity?.person?.name;

  if (!personName) {
    personName = "Unbekannt (Fehler)";
  }  
  return (
    <>
      <Typography className={`${isLink ? classes.clickableText : ''}`} variant="body2">
        Service
      </Typography>
      <Typography variant="h4">{title}</Typography>
      <Typography variant="body2">
        {context !== "person" && `${personName}, `} {topicName}
      </Typography>
    </>
  );
};

export default ServiceBubble;
