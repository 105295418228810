import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { orderBy } from "lodash";
import React from "react";
import { useParams } from "react-router-dom";
import {
  useGetOneThreadByActivityQuery,
  usePersonInteractionsQuery,
  useReportActivityQuery,
} from "../../../generated/graphql";
import usePersonSubscription from "../../../hooks/usePersonSubscription";
import { fetchPolicy } from "../../../utils/constants";
import Loader from "../../atoms/Loading";
import AccountStatusBadge from "../../molecules/AccountStatusBadge";
import ActivityInfo from "../../molecules/ActivityInfo";
import PersonHeader from "../../molecules/PersonHeader";
import EmailThreadMessages from "../../organisms/EmailThreadMessages";
import MemoDetails from "../../organisms/MemoDetails";
import ReportDetails from "../../organisms/ReportDetails";
import { ActivityHeader, Pagination } from "./components";

const Activity = () => {
  const { topicId, id, personId } = useParams<{ id: string; topicId: string; personId: string }>();
  const activitiesQuery = usePersonInteractionsQuery({
    variables: {
      personId: parseInt(personId, 10),
      topicId: parseInt(topicId, 10),
    },
    fetchPolicy,
  });

  const { data, loading } = useReportActivityQuery({
    variables: {
      id: parseInt(id, 10),
      loadReportData: true,
    },
    fetchPolicy,
  });

  const { person: personData, loading: personLoading } = usePersonSubscription(
    parseInt(personId, 10),
  );

  if (loading || personLoading) return <Loader />;

  const activity = data?.personActivity;
  const person = activity?.person;
  const topic = activity?.topic;

  if (!activity || !person) {
    return <></>;
  }
  
  const name = !!activity?.memo ? "Gesprächsnotiz" : "Gesprächsprotokoll";
  const mapList = [
    ...(topicId && topic
      ? [
          {
            path: `/person/${personId}/topics/${topicId}`,
            name: topic.name || `Thema ${topicId}`,
            chip: <AccountStatusBadge contractEnd={personData?.client?.account?.contractEnd} />,
          },
        ]
      : []),
    { path: "", name },
  ];
  const list = (activitiesQuery?.data?.personInteractions || []).filter(p => !!p?.doneAt);

  return (
    <>
      <PersonHeader person={person} mapList={mapList}  contractExpirationDate={personData?.client?.account?.contractEnd}/>
      <Box mt={20} mb={11}>
        <Grid container>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <ActivityHeader person={person} activity={activity} topic={topic || undefined} />
            {!!activity.memo && <MemoDetails memo={activity.memo} />}
            {!!activity.conversationReport && (
              <ReportDetails person={person} report={activity.conversationReport} />
            )}
            {// Due to refactoring: https://medium.com/@calebmer/when-to-use-graphql-non-null-fields-4059337f6fc8
            activity.activityType && (
              <ActivityHistory
                activityType={activity.activityType}
                personActivityId={activity.id}
              />
            )}
            {activity && <ActivityInfo activity={activity} />}
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Box>
      <Pagination list={list} name={name} />
    </>
  );
};

type TActivityHistory = {
  activityType: string;
  personActivityId: number;
};

const ActivityHistory = ({ activityType, personActivityId }: TActivityHistory) => {
  switch (activityType) {
    case "mail":
    case "wpoVerweisung":
      return <ActivityHistoryMail personActivityId={personActivityId} />;
    default:
      return null;
  }
};

type TActivityHistoryMail = {
  personActivityId: number;
};

const ActivityHistoryMail = ({ personActivityId }: TActivityHistoryMail) => {
  const { data, loading, error } = useGetOneThreadByActivityQuery({
    variables: {
      personActivityId,
    },
  });

  if (error) {
    console.error(error);
  }
  if (loading || error || !data) {
    return <></>;
  }

  return (
    <>
      <Box mt={5}>
        <Typography variant="h3">E-Mail</Typography>
        <Box borderTop={1} borderColor="grey.400"></Box>
      </Box>
      <EmailThreadMessages emails={orderBy(data.getOneThreadByActivity || [], "date")} />
    </>
  );
};

export default Activity;
