import React, { FC } from "react";

import { path } from "ramda";
import { Transition } from "react-transition-group";
import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import dayjs from "dayjs";

import { isCurrentMonth } from "../../../utils/dateHelper";
import { MeQuery, PersonActivity } from "../../../generated/graphql";
import MentalIcon from "../../atoms/MentalIcon";

import useStyles from "./styles";
import Bubble from "./Bubble";
import ActivityAvatar from "./ActivityAvatar";
import "./styles.css";

export const isServiceLogType = (activityType: string): boolean => {
  return activityType.indexOf("service") !== -1;
};

type TActivityItem = {
  meData?: MeQuery["me"];
  delay: number;
  activity: PersonActivity;
  personId?: number | undefined;
  withClient?: boolean;
  topicId?: number;
};
const ActivityItem: FC<TActivityItem> = ({
  activity,
  delay,
  meData,
  personId,
  withClient,
  topicId,
}) => {
  const classes = useStyles();

  const timeTag = (date: string) => {
    return dayjs(date).format("H:mm");
    if (isCurrentMonth(dayjs(date))) {
      return dayjs(date).format("H:mm");
    }
    return dayjs(date).format("D. MMM");
  };
  return (
    <Transition timeout={150 * (delay + 1)} in appear>
      {state => (
        <ListItem
          alignItems="center"
          className={`activityItem activityItem-${state} ${classes.activityItem}`}
        >
          <Box className={classes.timeTag}>
            {activity.conversationReport && activity.conversationReport.mentalState ? (
              <MentalIcon type={activity.conversationReport.mentalState} />
            ) : (
              <div className={classes.grow} />
            )}
            {timeTag(activity.lastUpdate)}
          </Box>
          <ListItemAvatar>
            <ActivityAvatar activity={activity} />
          </ListItemAvatar>
          <Bubble
            meData={meData}
            activity={activity}
            personId={personId || path(["personId"], activity)}
            clientBubble={!withClient}
            topicId={topicId}
          />
        </ListItem>
      )}
    </Transition>
  );
};

export default ActivityItem;
