import React, { FC } from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { PropTypes } from "@material-ui/core";

import { Select } from "formik-material-ui";
import { Field } from "formik";

import { Gender } from "../../../generated/graphql";

const GenderSelect: FC<{ margin?: PropTypes.Margin; fullWidth?: boolean }> = ({
  fullWidth = true,
  margin = "normal",
}) => {
  return (
    <FormControl fullWidth={fullWidth} margin={margin}>
      <InputLabel htmlFor="gender">Geschlecht</InputLabel>
      <Field name="gender" label="Geschlecht" component={Select}>
        <MenuItem value={Gender.unknown}>{translateGender(Gender.unknown)}</MenuItem>
        <MenuItem value={Gender.female}>{translateGender(Gender.female)}</MenuItem>
        <MenuItem value={Gender.male}>{translateGender(Gender.male)}</MenuItem>
        <MenuItem value={Gender.divers}>{translateGender(Gender.divers)}</MenuItem>
      </Field>
    </FormControl>
  );
};

export const translateGender = (gender: Gender) => {
  switch (gender) {
    case Gender.unknown:
      return "unbekannt";
    case Gender.divers:
      return "divers";
    case Gender.male:
      return "männlich";
    case Gender.female:
      return "weiblich";
    default:
      return "unbekannt";
  }
};

export default GenderSelect;
