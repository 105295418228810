import React, { FC } from "react";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import Avatar from "../../atoms/Avatar";
import { User } from "../../../generated/graphql";
import { getUserName } from "../../../utils/userNameHelper";

const Header: FC<{ user: User }> = ({ user }) => {
  return (
    <Grid container>
      <Grid item xs={3} />
      <Grid item xs={6}>
        <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
          <Box mr={2}>
            <Avatar widthfactor={5} src={user.person!.picture || user.person!.avatar || ""}>
              {user.person!.avatarKeys}
            </Avatar>
          </Box>
          <Typography variant="h1" align="center">
            Inbox von {getUserName(user.person)}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={3} />
    </Grid>
  );
};

export default Header;
