import React, { useState } from "react";

import Async from "react-select/async";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import NoSsr from "@material-ui/core/NoSsr";
import Box from "@material-ui/core/Box";

import { AccountsQuery, Account, AccountsDocument } from "../../../../generated/graphql";
import { PersonTypes } from "../../../atoms/PersonTypeIcon";

import useStyles, { CustomComponents, OptionValue } from "../components";
import useDebouncedSearch from "../hooks";

const formatData = (name: string, data?: AccountsQuery) => {
  return data?.accounts?.map(formatAccount) || [];
};

export const formatAccount = (account?: Partial<Account> | null): OptionValue => {
  if (!account) {
    // @ts-ignore
    return {};
  }

  return {
    type: PersonTypes.account,
    label: `${account!.name}`,
    name: `${account!.name}`,
    path: `/account/${account!.id}`,
    value: account!.id,
  };
};

type TAccountSearch = {
  account?: Account | null;
  autoFocus?: boolean;
  onClick: (selection: any) => void;
  onCreateOption?: (inputValue: string) => void;
  placeholder?: string;
};

const AccountSearch = ({ autoFocus = true, onClick, account }: TAccountSearch) => {
  const [currentData, setCurrentData] = useState<AccountsQuery>();
  const classes = useStyles();
  const loadOptions = useDebouncedSearch(
    undefined,
    300,
    {
      query: AccountsDocument,
    },
    formatData,
    data => setCurrentData(data),
  );

  const [value, setValue] = useState<OptionValue | null>(formatAccount(account));

  return (
    <Box position="relative">
      <NoSsr>
        <Async
          // Force component to rerender if value changes
          key={String(value)}
          escapeClearsValue
          autoFocus={autoFocus}
          classes={classes}
          components={{
            ...CustomComponents,
            DropdownIndicator: null,
            ClearIndicator: null,
          }}
          isClearable
          loadOptions={loadOptions}
          // @ts-ignore
          onChange={selection => {
            if (selection) {
              setValue(selection);

              const selected = currentData?.accounts?.find(a => a?.id === selection.value);
              onClick(selected);
            }
          }}
          placeholder="Suche"
          value={!!value?.label ? value : undefined}
        />
      </NoSsr>
      {!!value?.label && (
        <Box
          position="absolute"
          right="0"
          top="2px"
          onClick={() => {
            setValue(null);
            onClick(-1);
          }}
        >
          <IconButton aria-label="delete" size="small">
            <CloseIcon fontSize="small" color="primary" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default AccountSearch;
