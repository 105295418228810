import React, { useEffect, useContext, FC } from "react";
import { useHistory, useParams } from "react-router";
import dayjs from "dayjs";

import Box from "@material-ui/core/Box";

import { PersonActivity } from "../../../generated/graphql";
import { ChatContext } from "../../../containers/ChatListener";
import { ChatServerMessage } from "../../../containers/ChatListener/ChatMessageListener";
import Counter from "../../atoms/Counter";

import Bubble from "./Bubble";
import useStyles from "./styles";

const ActiveChatBubble: FC<{ activity: PersonActivity }> = ({ activity }) => {
  const classes = useStyles();
  const { chatId } = useParams<{ chatId: string }>();
  const { push } = useHistory();
  const { chats, dispatch, token } = useContext(ChatContext);

  const chatUserId = activity.chat?.chatUserId!;
  const isActiveChat = !!chatId && parseInt(chatId) === chatUserId;

  const chat = chats.get(chatUserId);

  useEffect(() => {
    if (token) {
      dispatch({
        type: "connectChat",
        token: token,
        activity,
        id: chatUserId,
      });
    }

    return () => {
      dispatch({
        type: "disconnectChat",
        id: chatUserId,
      });
    };
  }, [activity, chatUserId, dispatch, token]);

  useEffect(() => {
    if (chat) {
      chat.connection.onmessage = message => {
        const messageData: ChatServerMessage = JSON.parse(message.data);
        // Currently we do not have a better indication for this
        if (messageData.b === "Ein Otheb-Mitarbeiter ist bereits in dem Chatraum") {
          return dispatch({
            type: "hideChat",
            id: chatUserId,
          });
        }

        switch (messageData.t) {
          case "t":
            return dispatch({
              type: "setTyping",
              id: chatUserId,
            });
          case "c":
            return dispatch({
              type: "addChatMessage",
              id: chatUserId,
              isActiveChat,
              message: {
                isClient: true,
                text: messageData.b,
                uid: messageData.uid,
                time: dayjs(),
              },
            });
          case "h":
            dispatch({
              type: "addChatMessageBulk",
              id: chatUserId,
              isActiveChat: true,
              messages: messageData.messages.map(message => {
                return {
                  isClient: !message.o,
                  text: message.b,
                  time: dayjs(message.d),
                  uid: message.uid,
                };
              }),
            });
        }
      };
    }
  }, [chat, chatUserId, dispatch, isActiveChat]);

  useEffect(() => {
    if (chat && isActiveChat) {
      dispatch({
        type: "resetCounter",
        id: chatUserId,
      });
    }
  }, [chat, chatUserId, dispatch, isActiveChat]);

  // Avoid showing the chat bubble in two different tabs, since it is technically not possible to
  // have two active websocket connection
  // if (!chat || chat.history.length < 1) {
  if (!chat || chat.hide) {
    return null;
  }

  // Due to refactoring: https://medium.com/@calebmer/when-to-use-graphql-non-null-fields-4059337f6fc8
  const name = activity.person?.name || activity.person?.alias || "Unbekannt (Fehler)";

  return (
    <div className={classes.activeChatBubble}>
      {chat && chat.lastTyping && (
        <span key={`${chat.activity!.id}-${chat.lastTyping}`} className={classes.typing}>
          <svg viewBox="0 0 4 4" xmlns="http://www.w3.org/2000/svg">
            <circle cx="2" cy="2" r="2" />
          </svg>
          <svg viewBox="0 0 4 4" xmlns="http://www.w3.org/2000/svg">
            <circle cx="2" cy="2" r="2" />
          </svg>
          <svg viewBox="0 0 4 4" xmlns="http://www.w3.org/2000/svg">
            <circle cx="2" cy="2" r="2" />
          </svg>
        </span>
      )}
      <Box>
        <Bubble
          key={`activity:${activity.id}`}
          // @ts-ignore
          type={activity.activityType}
          onClick={() => {
            // Due to refactoring: https://medium.com/@calebmer/when-to-use-graphql-non-null-fields-4059337f6fc8
            if (activity.person?.id) {
              push(`/person/${activity.person.id}/chat/${chatUserId}`);
            } else {
              push("/");
            }
          }}
          text={name!}
        />
      </Box>
      {chat && !isActiveChat && chat.numNewMessages > 0 && (
        <Counter
          id={`${chat.activity!.id}-${chat.numNewMessages}`}
          value={chat.numNewMessages}
          right={-10}
          top={-8}
        />
      )}
    </div>
  );
};
export default ActiveChatBubble;
