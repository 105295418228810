import classnames from "classnames";
import React from "react";
import { useHistory, useParams } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

import { User, useUserQuery } from "../../../generated/graphql";

import { fetchPolicy } from "../../../utils/constants";
import { getUserName } from "../../../utils/userNameHelper";
import Loader from "../../atoms/Loading";
import PrimaryButton from "../../atoms/PrimaryButton";
import BreadCrumb from "../../molecules/Breadcrumb";
import IllustratedErrorMessage from "../../organisms/IllustratedErrorMessage";
import List from "../../organisms/InboxLists";
import SubHeader from "../../organisms/SubHeader";
import useStyles from "../inboxStyles";

import { useCurrentTasksSubscription } from "../../../hooks/useCurrentTasksSubscription";
import { useMyAppointmentsSubscription } from "../../../hooks/useMyAppointmentsSubscription";
import Header from "./Header";

import errorIllustration from "../../assets/errorIllustration.svg";
import ClientList from "../../organisms/ClientList";

const Inbox = () => {
  const { userId } = useParams<{ userId: string }>();
  const classes = useStyles();
  const { goBack } = useHistory();

  const {
    appointments,
    loading: loadingAppointments,
    error: errorAppointments,
  } = useMyAppointmentsSubscription({
    userId: parseInt(userId, 10),
  });

  const { data: userData, loading: loadingUser, error: errorUser } = useUserQuery({
    variables: { id: parseInt(userId, 10) },
    fetchPolicy,
  });

  const { tasks, loading: loadingTasks, error: errorTasks } = useCurrentTasksSubscription(
    {
      userId: parseInt(userId, 10),
      withSnoozed: true,
    },
    t => !!t && !!t.author && !!t.author.id,
  );

  const user = userData?.user as User;

  if (loadingAppointments || loadingUser || loadingTasks) {
    return <Loader />;
  }

  if (errorAppointments || errorUser || errorTasks) {
    console.error({ errorAppointments, errorUser, errorTasks });

    return (
      <IllustratedErrorMessage
        messages={["Die Inbox konnte nicht geladen werden."]}
        illustration={errorIllustration}
        illustrationAltText="Elli rutscht auf einer Banane aus"
      >
        <PrimaryButton onClick={goBack}>Zurück</PrimaryButton>
      </IllustratedErrorMessage>
    );
  }

  return (
    <>
      <SubHeader>
        <BreadCrumb
          mapList={[
            { name: "Inbox", path: "/" },
            { name: `Inbox von ${getUserName(user.person)}`, path: "" },
          ]}
        />
      </SubHeader>
      <Grid container className={classnames(classes.content, classes.contentBox)}>
        <Header user={user} />
        <Grid container>
          <Grid item xs={12}>
            <List.AppointmentList appointments={appointments} />
            <List.TaskList tasks={tasks} showPlaceholder={!tasks.length && !appointments.length} />
            {/* <List.ClientList userId={userId} /> */}
            <Grid container>
              <Grid item xs={12}>
                <ClientList.GenericCMClients userId={user.id}/>
                <ClientList.GenericStcClients userId={user.id} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Inbox;
