import React, { useState } from "react";
import dayjs, { Dayjs } from "dayjs";

import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";

import useStyles from "./styles";
import TimeDialog from "../TimeDialog";
import { THourMethodsType } from "../DaysTimeSelector/DaysTimeSelector";
import uuidv4 from "../../../utils/uuid";
import IHour from "../../../interfaces/IHour";

type THourEntry = {
  day: number;
  defaultClose: Dayjs;
  defaultOpen: Dayjs;
  hour: IHour;
  methods: THourMethodsType;
  showAddIcon: boolean;
  showRemoveIcon: boolean;
};

const HourEntry = ({
  day,
  defaultClose,
  defaultOpen,
  hour,
  methods,
  showAddIcon,
  showRemoveIcon,
}: THourEntry) => {
  const classes = useStyles();
  const [showOpen, setShowOpen] = useState(false);
  const [showClose, setShowClose] = useState(false);

  const handleUpdateOpen = (date: any) =>
    //@ts-ignore
    methods.update({ ...hour, open: date }, hour[`${methods.identifier}`]);

  const handleUpdateClose = (date: any) =>
    //@ts-ignore
    methods.update({ ...hour, close: date }, hour[`${methods.identifier}`]);

  const createHour = () => {
    methods.create({
      day,
      open: dayjs(hour.close).add(1, "hour"),
      close: dayjs(hour.close).add(4, "hour"),
      ...(methods.identifier === "uuid" ? { uuid: uuidv4() } : []),
    });
  };

  const renderRemoveIcon = () => {
    return (
      <IconButton
        aria-label="Remove hour"
        className={classes.removeHourButton}
        size="small"
        //@ts-ignore
        onClick={() => methods.delete(hour[`${methods.identifier}`])}
      >
        <ClearIcon fontSize="inherit" className={classes.clearIcon} />
      </IconButton>
    );
  };

  const equalsDefault =
    dayjs(hour.open).isSame(defaultOpen) && dayjs(hour.close).isSame(defaultClose);

  return (
    <Box
      alignItems="center"
      display="flex"
      className={classes.hourEntry}
      flex={1}
      paddingBottom={0.5}
      paddingRight={5}
      paddingTop={0.5}
      position="relative"
    >
      <Typography variant="body2">
        {equalsDefault && (
          <>
            {showRemoveIcon && renderRemoveIcon()}
            <Link variant="h4" underline="always" color="primary" onClick={() => setShowOpen(true)}>
              abweichenden Zeitraum eintragen
            </Link>
          </>
        )}
        {!equalsDefault && (
          <>
            {showRemoveIcon && renderRemoveIcon()}
            <Link variant="h4" underline="always" color="primary" onClick={() => setShowOpen(true)}>
              {hour.open ? dayjs(hour.open).format("H:mm") : defaultOpen.format("H:mm")}
            </Link>{" "}
            bis{" "}
            <Link
              variant="h4"
              underline="always"
              color="primary"
              onClick={() => setShowClose(true)}
            >
              {hour.close ? dayjs(hour.close).format("H:mm") : defaultClose.format("H:mm")}
            </Link>{" "}
            Uhr
          </>
        )}
      </Typography>
      {showAddIcon && (
        <IconButton
          aria-label="Add hour"
          className={classes.addHourButton}
          color="primary"
          size="small"
          onClick={() => createHour()}
        >
          <AddIcon />
        </IconButton>
      )}
      <TimeDialog
        show={showOpen}
        handleClose={() => {
          setShowOpen(false);
          setShowClose(true);
        }}
        handleOpen={() => setShowOpen(true)}
        handleChange={handleUpdateOpen}
        handleDone={handleUpdateOpen}
        value={hour && hour.open ? dayjs(hour.open) : defaultOpen}
        label="Von"
      />
      <TimeDialog
        show={showClose}
        handleClose={() => setShowClose(false)}
        handleOpen={() => setShowClose(true)}
        handleChange={handleUpdateClose}
        handleDone={handleUpdateClose}
        value={hour && hour.close ? dayjs(hour.close) : defaultOpen}
        label="Bis"
      />
    </Box>
  );
};

export default HourEntry;
