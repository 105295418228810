import React, { useReducer, FC, useEffect } from "react";

import pathOr from "ramda/src/pathOr";
import dayjs from "dayjs";
import path from "ramda/src/path";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import { PapershiftShift, User, PapershiftWorkingArea } from "../../../generated/graphql";
import Loader from "../../atoms/Loading";
import Breadcrumb from "../../molecules/Breadcrumb";
import SubHeader from "../../organisms/SubHeader";

import Filter from "./components/Filter";
import ShiftResultsContainer from "./components/ShiftResultsContainer";
import { reducer } from "./reducer";
import { isRegularShift } from "./helper";

import { usePapershiftShiftsSubscription } from "../../../hooks/usePapershiftShiftsSubscription";

const Appointment = () => {
  const variables = {
    startsAt: dayjs()
      .startOf("day")
      .toISOString(),
    endsAt: dayjs()
      .endOf("day")
      .add(28, "day")
      .toISOString(),
  };

  const { data, loading, error } = usePapershiftShiftsSubscription(variables);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Typography variant="body2">{error.message}</Typography>;
  }

  let shifts: PapershiftShift[] | undefined = pathOr(undefined, ["papershiftShifts"], data || {});

  if (!shifts) {
    return <Typography variant="body2">Es konnten keine Schichten geladen werden</Typography>;
  }

  const users: Map<number, User> = new Map();
  const allUsers: Map<number, User> = new Map();

  /**
   *  Multiple users can be assigned to one shift. Since we show shifts based on users, split those
   *  here. This makes the following logic easier. While itering over the shifts also collect users
   *  to create a filterable map.
   */
  const splitShiftWithMultipleUsers = (carry: PapershiftShift[], shift: PapershiftShift) => {
    if (shift.users) {
      shift.users.forEach(user => {
        if (user && user.id) {
          users.set(user.id, user);
          allUsers.set(user.id, user);
        }
      });
    }

    if (shift.users && shift.users.length > 1) {
      const splitted = shift.users.map(user => {
        if (!!user?.absentUntil) {
          if (dayjs(shift.endsAt).isBefore(dayjs(user.absentUntil))) {
            return {};
          }
        }
        return { ...shift, users: [user] };
      });
      return [...carry, ...splitted];
    } else {
      const user: User | undefined = path(["users", 0], shift);
      if (!!user?.absentUntil) {
        if (dayjs(shift.endsAt).isBefore(dayjs(user.absentUntil))) {
          return carry;
        }
      }
      return [...carry, shift];
    }
  };
    
  const init: PapershiftShift[] = [];

  shifts = shifts.reduce(splitShiftWithMultipleUsers, init).filter(isRegularShift);

  return <AppointmentView shifts={shifts} users={users} allUsers={allUsers} />;
};

export default Appointment;

/**
 *
 */
interface AppointmentViewProps {
  shifts: PapershiftShift[];
  users: Map<number, User>;
  allUsers: Map<number, User>;
}

const AppointmentView: FC<AppointmentViewProps> = ({ shifts, users, allUsers }) => {
  const initialState = {
    users: new Map<number, User>(),
    allUsers: new Map<number, User>(),
    workingAreas: new Map<number, PapershiftWorkingArea>(),
    externalUsers: new Map<number, User>(),
    selectedWorkingAreaId: undefined,
  };

  // Find way to get state updates directly without the need of the two useStates below
  const [filter, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: "updateAllUsers", users: allUsers });
  }, [allUsers]);

  const addExternalUser = (user: User) => {
    dispatch({ type: "addExternalUser", user: user, id: user.id });
  };

  const removeExternalUser = (user: User) => {
    dispatch({ type: "removeExternalUser", user: user, id: user.id });
  };

  const toggleWorkingArea = (workingArea: PapershiftWorkingArea, selected: boolean) => {
    if (selected) {
      dispatch({ type: "deselectWorkingArea", id: workingArea.id });
    } else {
      dispatch({ type: "selectWorkingArea", workingArea, id: workingArea.id });
    }
  };

  return (
    <Box>
      <SubHeader>
        <Breadcrumb
          mapList={[
            { name: "Tagesschichten", path: "/shift/1" },
            { name: "Termin finden", path: "" },
          ]}
        />
      </SubHeader>
      <Box paddingTop={12}>
        <Grid container spacing={2}>
          <Grid item xs={3} xl={2}>
            <Filter
              addExternalUser={addExternalUser}
              removeExternalUser={removeExternalUser}
              toggleWorkingArea={toggleWorkingArea}
              filter={filter}
              //   users={users}
            />
          </Grid>
          <Grid item xs={9} xl={10}>
            <ShiftResultsContainer filter={filter} shifts={shifts} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
