import React, { FC } from "react";

import { useMutation } from "@apollo/client";
import { Formik, Form } from "formik";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { FormControl } from "@material-ui/core";

import {
  UpdateServiceAttributeDocument,
  ServiceAttribute,
  CounselingMandate,
  useUpdateMandateMutation,
} from "../../../generated/graphql";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import SlateRTE, { initialFormValue } from "../../molecules/SlateRTE";

const STCForm: FC<{
  serviceAttributes: ServiceAttribute[];
  onClose: () => void;
  mandate?: CounselingMandate;
}> = ({ onClose, serviceAttributes, mandate }) => {
  const [updateServiceAttribute] = useMutation(UpdateServiceAttributeDocument);
  const [updateMandate] = useUpdateMandateMutation();
  const initialValues = {} as { [key: string]: string | number | null | undefined };
  const serviceAttributesMap = new Map();
  serviceAttributes.forEach(serviceAttribute => {
    serviceAttributesMap.set(serviceAttribute.serviceMetaAttribute!.name, serviceAttribute);
    initialValues[serviceAttribute!.serviceMetaAttribute!.name!] = serviceAttribute.value;
  });
  if (mandate) {
    initialValues.sessionsAppointed =
      mandate.sessionsAppointed === undefined
        ? mandate?.service?.client?.account?.freeSessions || 0
        : mandate.sessionsAppointed;
    initialValues.sessionsCompleted = mandate.sessionsCompleted;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        const promises = [...serviceAttributesMap.entries()]
          // Only submit what has been changed
          .filter(([label, serviceAttribute]) => {
            return serviceAttribute.value !== values[label];
          })
          .map(([label, serviceAttribute]) => {
            return updateServiceAttribute({
              variables: {
                serviceAttribute: {
                  id: serviceAttribute.id,
                  serviceId: serviceAttribute.serviceId,
                  value: String(values[label]),
                },
              },
            });
          });
        if (!!mandate && !!mandate.id) {
          promises.push(
            updateMandate({
              variables: {
                id: mandate.id,
                input: {
                  sessionsAppointed:
                    (values.sessionsAppointed as number) ||
                    mandate.service?.client?.account?.freeSessions ||
                    1,
                  sessionsCompleted: (values.sessionsCompleted as number) || 0,
                },
              },
            }),
          );
        }

        await Promise.all(promises);

        setSubmitting(false);
        onClose();
      }}
    >
      {({ values, isSubmitting, setFieldValue }) => {
        const mandateThreshold = !!mandate ? 2 : 0;
        const fields = Object.keys(values).map((key, idx) => {
          if (key === "sessionsAppointed" || key === "sessionsCompleted") return <></>;
          const field = serviceAttributesMap.get(key);
          if (field.serviceMetaAttribute.type === "number") {
            return (
              <Box mt={6} pb={2} key={`field-${key}`}>
                <FormControl fullWidth>
                  <Field
                    tabIndex={idx + 1 + mandateThreshold}
                    name={key}
                    component={TextField}
                    type="number"
                    label={field.serviceMetaAttribute.label}
                    autoFocus={idx === mandateThreshold}
                  />
                </FormControl>
              </Box>
            );
          }

          return (
            <SlateRTE
              key={`field-${key}`}
              // @ts-ignore
              value={values[key] || initialFormValue}
              handleChange={(value: string) => {
                setFieldValue(key, value);
              }}
              label={field.serviceMetaAttribute.label}
              tabIndex={idx + 1 + mandateThreshold}
              autoFocus={idx === mandateThreshold}
            />
          );
        });

        return (
          <Form style={{ width: "100%" }}>
            {mandate && (
              <Box mt={6} pb={2}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Field
                        tabIndex={1}
                        name="sessionsCompleted"
                        component={TextField}
                        type="number"
                        label="Durchgeführte Sitzungen"
                        autoFocus
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Field
                        tabIndex={2}
                        name="sessionsAppointed"
                        component={TextField}
                        type="number"
                        label="Vereinbarte Sitzungen"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            )}
            {fields}
            <Box display="flex" justifyContent="center" mt={3}>
              <SecondaryButton onClick={onClose} tabIndex={fields.length + 2}>
                Abbrechen
              </SecondaryButton>
              <Box mx={1} />
              <PrimaryButton type="submit" disabled={isSubmitting} tabIndex={fields.length + 1}>
                {!isSubmitting && "OK"}
                {isSubmitting && (
                  <Box ml={3} display="flex" alignItems="center">
                    <CircularProgress size={24} />
                  </Box>
                )}
              </PrimaryButton>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default STCForm;
