import { useEffect } from "react";

import {
  useCurrentTasksQuery,
  CurrentTasksSubscriptionDocument,
  CurrentTasksSubscriptionSubscription,
  CurrentTasksQueryVariables,
  Maybe,
  Task,
} from "../generated/graphql";

import { fetchPolicy } from "../utils/constants";

export const useCurrentTasksSubscription = (
  variables: CurrentTasksQueryVariables = {},
  filterFunction: (t: Maybe<Task>) => boolean = t => !!t,
) => {
  const { data, subscribeToMore, loading, error } = useCurrentTasksQuery({
    variables,
    fetchPolicy,
  });

  useEffect(
    () =>
      subscribeToMore<CurrentTasksSubscriptionSubscription>({
        document: CurrentTasksSubscriptionDocument,
        variables: { ...variables },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data || !prev) return prev;

          const currentTasks = subscriptionData?.data?.currentTasksSubscription?.filter(
            // @ts-ignore
            filterFunction,
          );
          return Object.assign({}, prev, {
            currentTasks,
          });
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variables],
  );

  return { tasks: (data?.currentTasks || []) as Task[], loading, error };
};
