import { useEffect } from "react";
import {
  BusinessUnitsSubscriptionDocument,
  BusinessUnitsSubscriptionSubscription,
  useGetBusinessUnitsByAccountQuery,
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

export const useBusinessUnitsSubscription = (accountId: number) => {
  const { data, loading, error, subscribeToMore } = useGetBusinessUnitsByAccountQuery({
    variables: { accountId },
    fetchPolicy,
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore<BusinessUnitsSubscriptionSubscription>({
      document: BusinessUnitsSubscriptionDocument,
      variables: { accountId },
      updateQuery: (_prev, { subscriptionData }) => {
        return {
          getBusinessUnitsByAccount: subscriptionData.data.businessUnitsSubscription,
        };
      },
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  return { businessUnits: data?.getBusinessUnitsByAccount || [], loading, error };
};
