import React, { ReactNode, useState } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import MuLink from "@material-ui/core/Link";

import { Account, Person, User } from "../../../generated/graphql";

import ClientAvatar from "../../atoms/ClientAvatar";
import Avatar from "../../atoms/Avatar";
import UnassignedUserButton from "../../atoms/UnassignedUserButton";
import UserDialog from "../../organisms/UserDialog";

type TActivityItem = {
  account?: Pick<Account, "name"> | null;
  avatar?: {
    keys?: string | null;
    src?: string | null;
  };
  info: ReactNode;
  person?: Person | null;
  title: ReactNode;
  user?: User | null;
};

const ActivityItem = ({ avatar, account, person, title, user, info }: TActivityItem) => {
  const [open, setOpen] = useState(false);

  return (
    <Box py={2} px={1} display="flex" alignItems="center" minHeight={72}>
      {user && <UserDialog open={open} onClose={() => setOpen(false)} user={user} />}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
          <Box display="flex" alignItems="center">
            {/* User assigned and avatar image */}
            {avatar && (avatar.src || avatar.keys) && (
              <Box ml={2} mr={3}>
                <Avatar onClick={() => setOpen(true)} src={avatar.src || ""} widthfactor={5}>
                  {avatar.keys}
                </Avatar>
              </Box>
            )}
            {/* User assigned and no avatar image */}
            {avatar && !avatar.src && !avatar.keys && (
              <Box ml={2} mr={3}>
                <UnassignedUserButton />
              </Box>
            )}
            {/* no User assigned === show nothing */}
            {!avatar && <Box width={16} />}
            {person && (
              <ClientAvatar
                person={person}
                red={person.flag === "redFlag"}
                yellow={person.flag === "yellowFlag"}
                white={person.flag === "whiteFlag"}
                tiny
              />
            )}
            <Box mx={1} />
            {person && (
              <MuLink
                component={Link}
                to={`/person/${person.id}`}
                color="primary"
                underline="always"
                style={{ display: "inline-block" }}
                variant="h4"
              >
                {person.name}
                {!!account?.name && `, ${account?.name}`}
              </MuLink>
            )}
          </Box>
        </Grid>
        <Grid item xs={4}>
          {title}
        </Grid>
        <Grid item xs={4}>
          {info}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ActivityItem;
