import { useEffect } from "react";

import dayjs from "dayjs";
import unionBy from "lodash/unionBy";

import {
  useAppointmentsByUsersQuery,
  AppointmentSubscriptionSubscription,
  AppointmentSubscriptionDocument,
  PapershiftShift,
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

export const useAppointmentSubscription = ({
  shift,
  userIds,
}: {
  userIds: number[];
  shift: PapershiftShift;
}) => {
  const variables = {
    userIds,
    startsAt: dayjs(shift.startsAt)
      .startOf("day")
      .toISOString(),
    endsAt: dayjs(shift.endsAt)
      .endOf("day")
      .toISOString(),
  };

  const { data, error, loading, refetch, subscribeToMore } = useAppointmentsByUsersQuery({
    variables,
    fetchPolicy,
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore<AppointmentSubscriptionSubscription>({
      document: AppointmentSubscriptionDocument,
      variables,
      updateQuery: (prev, { subscriptionData }) => {
        if (
          !subscriptionData.data ||
          !subscriptionData.data.appointmentSubscription ||
          !prev ||
          !prev.appointmentsByUsers
        ) {
          return prev;
        }

        if (!!subscriptionData.data.appointmentSubscription.deletedAt) {
          return Object.assign({}, prev, {
            appointmentsByUsers: [
              ...prev.appointmentsByUsers.filter(apppointment => {
                if (!apppointment || !subscriptionData.data.appointmentSubscription) {
                  return false;
                }

                return apppointment.id !== subscriptionData.data.appointmentSubscription.id;
              }),
            ],
          });
        } else {
          // TODO(Gerret): subscriptionData.data.appointmentSubscription.data.date is
          // undefined/null !? #175033537
          return Object.assign({}, prev, {
            appointmentsByUsers: unionBy(
              [subscriptionData.data.appointmentSubscription],
              prev.appointmentsByUsers,
              "id",
            ),
          });
        }
      },
    });

    return unsubscribe;
    // Somehow the variable object changes too often. Similarly, the array of userIds changes,
    // though the elements in it do not change. When testing, however, this seems to work fine now.
    // If changes are made here, intensively test creating, deleting, editing appointments from the
    // 'shift calendar'-page as well as 'find appointments'-page across multiple users and all
    // possible variations!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables.startsAt, variables.endsAt]);

  return { data, error, loading, refetch, variables };
};
