import React from "react";

import Typography from "@material-ui/core/Typography";

import TypeIcon from "../../atoms/TypeIcon";

import useStyles from "./styles";

export enum ActivityInteractionType {
  callIn = "callIn",
  callOut = "callOut",
  call = "call",
  chat = "chat",
  mail = "mail",
  service = "service",
}

type TBubble = {
  active?: boolean;
  onClick?: () => void;
  type: ActivityInteractionType;
  text: string;
};
const Bubble = ({ active = true, onClick, type, text }: TBubble) => {
  const classes = useStyles();

  return (
    <div className={classes.activity} onClick={onClick}>
      <TypeIcon type={type} active={active} />
      <Typography variant="h4" color="secondary" style={{ whiteSpace: "nowrap" }}>
        {text}
      </Typography>
    </div>
  );
};

export default Bubble;
