import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => {
  return {
    badge: {
      marginLeft: theme.spacing(2),
      height: "24px",
    },
    badgeInactive: {
      marginLeft: theme.spacing(2),
      height: "24px",
      "& .MuiChip-label": {
        textDecorationLine: "underline",
        textDecorationColor: theme.palette.secondary.main,
      },
    },
    languageIcon: {
      width: "32px",
      height: "16px",
      marginLeft: theme.spacing(2),
    },
    breadCrumbInfoContainer: {
      display: "inline-flex",
      alignItems: "center",
    },
  };
});

export default useStyles;
