import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    activityItem: {
      paddingBottom: theme.spacing(0.5),
      paddingTop: theme.spacing(0.5),
    },
    avatar: {
      margin: "0 16px",
      borderRadius: 20,
      width: 40,
      height: 40,
    },
    noBorder: {
      border: "none",
    },
    root: {
      width: 920,
      "&:before": {
        backgroundColor: theme.palette.primary.main,
        content: '""',
        height: "100%",
        left: 136,
        position: "absolute" as "absolute",
        top: 0,
        width: 1,
      },
    },
    past: {
      "&:before": {
        left: 135,
        width: 3,
      },
    },
    timeTag: {
      flexShrink: 0,
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      width: 85,
      textAlign: "right",
    },
    grow: {
      flexGrow: 1,
    },
    headerTextWrapper: {
      alignItems: "center",
      display: "flex",
      paddingTop: theme.spacing(2),
      "&:svg": {
        fill: theme.palette.primary.dark,
      },
    },
    headerText: {
      marginRight: theme.spacing(1),
    },
    primaryText: {
      padding: theme.spacing(1, 0),
    },
    tagText: {
      alignItems: "center",
      display: "flex",
      paddingBottom: theme.spacing(2),
      "&>svg": {
        height: theme.spacing(2),
        width: theme.spacing(2),
      },
    },
    trash: {
      marginTop: theme.spacing(1),
      cursor: "pointer",
    },
    typeIcon: {
      color: theme.palette.primary.main,
    },
    activeText: {
      color: theme.palette.secondary.main,
    },
    listItemRedesign: {
      backgroundColor: "#EFE8DD",
      borderRadius: theme.spacing(0.5),
      display: "flex",
      flexGrow: 1,
      marginLeft: 10,
      minHeight: 56,
      position: "relative" as "relative",
      transition: theme.transitions.create(["color", "background-color"], {
        duration: theme.transitions.duration.standard,
        easing: theme.transitions.easing.easeInOut,
      }),
      "&:before": {
        borderBottom: `16px solid transparent`,
        borderLeft: `0px solid transparent`,
        borderRight: `16px solid #EFE8DD`,
        borderTop: `16px solid transparent`,
        bottom: 0,
        content: '""',
        height: 0,
        left: -12,
        margin: "auto 0",
        position: "absolute" as "absolute",
        top: 0,
        transition: theme.transitions.create("border-right-color", {
          duration: theme.transitions.duration.standard,
          easing: theme.transitions.easing.easeInOut,
        }),
        width: 0,
      },
    },
    clickable: {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.palette.common.white,
        cursor: "pointer",
        "&:before": {
          borderRight: "16px solid #fff",
        },
      },
    },
    clickableText: {
      color: theme.palette.primary.main,
      cursor: "pointer",
      textDecoration: "underline",
    },
    active: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.secondary.main,
      "&:before": {
        borderRightColor: theme.palette.background.paper,
      },
      "&:hover": {
        backgroundColor: "rgba(255, 255,255, 0.6)",
        "&:before": {
          borderRightColor: "rgba(255, 255,255, 0.6)",
        },
      },
    },

    reportSummary: {
      maxHeight: 24 * 4, // show 4 max lines
      overflow: "hidden",
    },
  };
});

export default useStyles;
