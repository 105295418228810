import React from "react";

import SentimentDissatisfiedOutlined from "@material-ui/icons/SentimentDissatisfiedOutlined";
import SentimentSatisfiedOutlined from "@material-ui/icons/SentimentSatisfiedOutlined";
import SentimentVerySatisfiedOutlined from "@material-ui/icons/SentimentVerySatisfiedOutlined";
import SentimentVeryDissatisfiedOutlined from "@material-ui/icons/SentimentVeryDissatisfiedOutlined";
import MoodOutlined from "@material-ui/icons/MoodOutlined";

import { MentalState } from "../../../generated/graphql";

import useStyles from "./styles";

interface IProps {
  classes?: string;
  size?: "medium" | "inherit" | "large" | "small";
}

const mentalIconMap: { [index: string]: Function } = {
  [MentalState.euphoric]: ({ classes, size }: IProps) => (
    <SentimentVerySatisfiedOutlined className={classes} fontSize={size} />
  ),
  [MentalState.happy]: ({ classes, size }: IProps) => (
    <MoodOutlined className={classes} fontSize={size} />
  ),
  [MentalState.neutral]: ({ classes, size }: IProps) => (
    <SentimentSatisfiedOutlined className={classes} fontSize={size} />
  ),
  [MentalState.depressed]: ({ classes, size }: IProps) => (
    <SentimentDissatisfiedOutlined className={classes} fontSize={size} />
  ),
  [MentalState.critical]: ({ classes, size }: IProps) => (
    <SentimentVeryDissatisfiedOutlined className={classes} fontSize={size} />
  ),
};

type TMentalIcon = { type: MentalState; className?: string; size?: string };

const MentalIcon = ({ className, size, type }: TMentalIcon) => {
  const classes = useStyles();

  if (mentalIconMap[type]) {
    return mentalIconMap[type]({
      classes: className || classes.emotionImage,
      size: size || "medium",
    });
  }
};

export default MentalIcon;
