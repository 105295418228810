import { Theme } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";


export const LegendWrapper = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flex: "1",    
    margin: theme.spacing(2, 0),
    justifyContent: "center"
  },
}))(Box);

export const LegendEntry = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    "&>p": {
      marginTop: "3px",
      fontSize: "12px",
    },
  },
}))(Box);
