import React, { FC, useEffect, useContext } from "react";

import { Maybe, Chat, Team } from "../../generated/graphql";

import { ChatContext } from ".";

const isOthebUser = (client: any) => !!client?.partner_id;
const isClient = (client: any) => !!client?.user_id;
const isMemo = (memo: any) => !!memo?.id;

const splitChatUsers = (messageData: any) => {
  const users = messageData?.clients?.filter(isOthebUser);
  const clients = messageData?.clients?.filter(isClient);
  const memos = messageData?.memo?.filter(isMemo);

  return { users, clients, memos };
};

const isInMyGroup = (
  chatUserId: any,
  teams: Maybe<Team>[],
  mayChat: boolean,
  activeChats?: Maybe<Chat>[] | null,
) => {
  return true
  // if (!mayChat) {
  //   return false;
  // }
  // const chat = activeChats?.find(activeChat => {
  //   return activeChat?.chatUserId === chatUserId;
  // });

  // if (!chat) {
  //   return false;
  // }

  // if (!chat && mayChat) {
  //   return true;
  // }

  // if (!chat.team) {
  //   return true;
  // }

  // return teams.includes(chat.team);
};

const isAssignedToUser = (chatUserId: any, activeChats?: Maybe<Chat>[] | null) => {
  return activeChats?.some(activeChat => {
    return !!activeChat?.user && activeChat?.chatUserId === chatUserId;
  });
};

const othebUserPresent = (chatUserId: any, partners: any) => {
  return partners?.some((partner: any) => parseInt(partner.partner_id) === chatUserId);
};

const filterClients = (
  chatUserId: any,
  users: any,
  teams: Maybe<Team>[],
  mayChat: boolean,
  activeChats?: Maybe<Chat>[] | null,
) => {
  if (!mayChat) {
    return false;
  }
  return (
    isInMyGroup(chatUserId, teams, mayChat, activeChats) &&
    !isAssignedToUser(chatUserId, activeChats) &&
    !othebUserPresent(chatUserId, users)
  );
  //
};

export type ObrowserServerMessage =
  | { t: "s"; user_id: number; time: string }
  | {
      t: "u";
      clients: { partner_id: string }[];
      memo: { account: string; alias: string; client_id: number; id: number; latest: string }[];
    };

export type ChatServerMessage =
  | { t: "t"; b: string }
  | { t: "c"; b: string; uid: number }
  | {
      t: "h";
      b: string;
      messages: {
        uid: number;
        b: string;
        a: boolean;
        d: string;
        o: boolean;
        r: boolean;
        context: false;
      }[];
    };

type TChatMessageListener = {
  teams: Maybe<Team>[];
  mayChat: boolean;
  activeChats?: Maybe<Chat>[] | null;
  connection: WebSocket;
};

const ChatMessageListener: FC<TChatMessageListener> = ({
  activeChats,
  children,
  connection,
  teams,
  mayChat,
}) => {
  const { dispatch } = useContext(ChatContext);

  useEffect(() => {
    if (connection) {
      connection.onmessage = message => {
        const messageData: ObrowserServerMessage = JSON.parse(message.data);

        switch (messageData.t) {
          case "s":
            return dispatch({
              type: "updateClient",
              id: messageData.user_id,
              latest: messageData.time,
            });
          case "u":
            let { users, clients, memos } = splitChatUsers(messageData);
            // Filter out active chats, so no other user is shown the current chat
            return dispatch({
              type: "setClients",
              clients: clients.filter((c: any) =>
                filterClients(c.id, users, teams, mayChat, activeChats),
              ),
              memos: memos.filter((c: any) =>
                filterClients(c.id, users, teams, mayChat, activeChats),
              ),
            });
          default:
            break;
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection, activeChats]);

  return <>{children}</>;
};

export default ChatMessageListener;
