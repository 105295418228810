import React, { FormEvent, FC } from "react";

import { FormikProps, FormikValues } from "formik";

import useAutoSave from "../../../hooks/useAutoSave";
import PrimaryButton from "../../atoms/PrimaryButton";
import SlateRTE, { initialFormValue } from "../../molecules/SlateRTE";
import SecondaryButton from "../../atoms/SecondaryButton";
import WizardNavigation from "../../molecules/WizardNavigation";
import useStyles from "../reportStyles";

export interface IFormProps {
  saveInput: (params: any) => any;
  backAction: () => void;
  allowContactSave: boolean;
}

const Step1: FC<FormikProps<FormikValues> & IFormProps> = ({
  backAction,
  handleSubmit,
  isSubmitting,
  saveInput,
  setFieldValue,
  values,
}) => {
  const timer = useAutoSave({ note: values.note }, saveInput);
  function handleTextChange(value: string) {
    setFieldValue("note", value);
  }

  const submit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    handleSubmit(event);
  };

  const classes = useStyles();
  return (
    <form onSubmit={submit} className={classes.content}>
      <SlateRTE
        value={values.note || initialFormValue}
        label="Gesprächsnotiz"
        handleChange={handleTextChange}
        tabIndex={1}
      />
      <WizardNavigation>
        <SecondaryButton onClick={() => backAction()} tabIndex={3}>
          Zurück
        </SecondaryButton>
        <PrimaryButton type="submit" disabled={isSubmitting} tabIndex={2}>
          Weiter
        </PrimaryButton>
      </WizardNavigation>
    </form>
  );
};

export default Step1;
