import React, { FC } from "react";

import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import Avatar from "../../../../atoms/Avatar";

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const EmergencyContact: FC = () => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" marginRight={4}>
      <Avatar widthfactor={5} className={classes.avatar}>
        SOS
      </Avatar>
      <Box width="12px" />
      <Typography color="textPrimary" variant="body2">0174 3359180</Typography>
    </Box>
  );
};
export default EmergencyContact;
