import Chip from "@material-ui/core/Chip";
import dayjs from "dayjs";
import React from "react";
import useContractStatus from "../../../hooks/useContractStatus";
import useStyles from "./styles";
import en from "./en.png";
import it from "./it.png";
import fr from "./fr.png";
import es from "./es.png";

const AccountStatusBadge = ({ contractEnd, isText, lang }: any) => {
  const classes = useStyles();
  const alertDate = dayjs().add(3, "month");
  const isContractExpired = useContractStatus(contractEnd);
  const languageStatusBadge = (lang: string) => {
    if (lang) {
      if (lang !== "de") {
        let imageToShow = null;
        switch (lang) {
          case "en":
            imageToShow = en;
            break;
          case "fr":
            imageToShow = fr;
            break;
          case "it":
            imageToShow = it;
            break;
          case "es":
            imageToShow = es;
            break;
          default:
            imageToShow = en;
            break;
        }
        return <img className={classes.languageIcon} src={imageToShow} alt="" />;
      }
    }

    return null;
  };

  if (contractEnd) {
    if (isContractExpired) {
      return isText ? (
        <>({`Vertrag beendet`})</>
      ) : (
        <div className={classes.breadCrumbInfoContainer}>
          {languageStatusBadge(lang)}
          <Chip
            className={classes.badgeInactive}
            label={`Vertrag beendet`}
            clickable={false}
            color="secondary"
          />
        </div>
      );
    }

    if (dayjs(contractEnd).isBefore(alertDate)) {
      return isText ? (
        <>({`Vertragsende: ${dayjs(contractEnd).format("DD.MM.YY")}`})</>
      ) : (
        <div className={classes.breadCrumbInfoContainer}>
          {languageStatusBadge(lang)}
          <Chip
            className={classes.badge}
            label={`Vertragsende: ${dayjs(contractEnd).format("DD.MM.YY")}`}
            clickable={false}
            color="primary"
          />
        </div>
      );
    }
  }
  return <div className={classes.breadCrumbInfoContainer}>{languageStatusBadge(lang)}</div>;
};

export default AccountStatusBadge;
