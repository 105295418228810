import dayjs from "dayjs";
import React, { useState } from "react";

import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { TextField } from "formik-material-ui";

import { Field, Formik, FormikProps, FormikValues } from "formik";
import pathOr from "ramda/src/pathOr";

import {
    FamilyStatus,
    Gender,
    Person, PersonUpdateInput, useUpdatePersonMutation
} from "../../../generated/graphql";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import DataColumn from "../../molecules/DataColumn";
import DataRow from "../../molecules/DataRow";
import DefaultDialog from "../../molecules/DefaultDialog";
import DialogContent from "../../molecules/DefaultDialogContent";

import ClientAge from "../../forms/ClientAge";
import useStyles from "../../forms/formStyles";
import FamilyStatusSelect, { translateFamilyStatus } from "../../molecules/FamilyStatusSelect";
import GenderSelect, { translateGender } from "../../molecules/GenderSelect";

type IMasterData = { person: Person };

const MasterData = ({ person }: IMasterData) => {
  const [open, setOpen] = useState(false);
  const [personUpdate] = useUpdatePersonMutation();

  const handleSubmit = async (values: PersonUpdateInput) => {
    return personUpdate({
      variables: {
        person: {
          ...values,
          // defaults to empty string if field is cleared
          initialAge: values.initialAge || null,
        },
      },
    });
  };

  return (
    <>
      <DataRow title="Stammdaten" editTrigger={() => setOpen(true)}>
        <DataColumn label="Geschlecht">
          <Typography variant="body2">
            {translateGender(person.gender || Gender.unknown)}
          </Typography>
        </DataColumn>
        <DataColumn label="Vorname">
          <Typography variant="body2">{person.firstName || "-"}</Typography>
        </DataColumn>
        <DataColumn label="Nachname">
          <Typography variant="body2">{person.lastName || "-"}</Typography>
        </DataColumn>
        <DataColumn label="Alias">
          <Typography variant="body2">{person.alias || "-"}</Typography>
        </DataColumn>
        <DataColumn label="Akademischer Titel">
          <Typography variant="body2">{person.title || "-"}</Typography>
        </DataColumn>
        <DataColumn label="PIN">
          <Typography variant="body2">
            {pathOr("-", ["client", "account", "chatRegistrationPin"], person)}
          </Typography>
        </DataColumn>
        <DataColumn label="Alter">
          <Typography variant="body2">
            {person.age || "-"} Jahre
            {person.age && person.ageGuessed
              ? " (geschätzt)"
              : `, ${!person.birthDate ? "" : dayjs(person.birthDate).format("DD.MM.YYYY")}`}
          </Typography>
        </DataColumn>
        <DataColumn label="Beziehungsstatus">
          <Typography variant="body2">
            {translateFamilyStatus(person.familyStatus || FamilyStatus.unknown)}
          </Typography>
        </DataColumn>
        <DataColumn label="Auffälligkeiten">
          <Typography variant="body2">{person.abnormalities || "-"}</Typography>
        </DataColumn>
      </DataRow>

      <DefaultDialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <Formik
            enableReinitialize
            onSubmit={async (values, { setSubmitting }) => {
              await handleSubmit(values);
              setSubmitting(false);
              setOpen(false);
            }}
            initialValues={{
              id: person.id,
              abnormalities: person.abnormalities || "",
              firstName: person.firstName,
              lastName: person.lastName,
              alias: person.alias,
              gender: person.gender,
              familyStatus: person.familyStatus,
              initialAge: person.age,
              ageGuessed: person.ageGuessed,
              title: person.title,
              birthDate: person.birthDate || dayjs().subtract(person.age || 0, "year"),
            }}
          >
            {props => <MasterDataForm {...props} cancel={() => setOpen(false)} />}
          </Formik>
        </DialogContent>
      </DefaultDialog>
    </>
  );
};

interface MasterDataFormProps {
  cancel: () => void;
}

const MasterDataForm = (props: MasterDataFormProps & FormikProps<FormikValues>) => {
  const classes = useStyles();
  const { cancel, handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1" align="center" style={{ marginBottom: "8px" }}>
            Stammdaten
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field name="firstName" label="Vorname" component={TextField} tabIndex={1} autoFocus />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field name="lastName" label="Nachname" component={TextField} tabIndex={2} />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field name="title" component={TextField} label="Akademischer Titel" tabIndex={3} />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <GenderSelect />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <Field name="alias" label="Alias" component={TextField} tabIndex={5} />
          </FormControl>
        </Grid>
      </Grid>
      <Box paddingY={3} width="100%">
        <Grid container spacing={2} alignItems="flex-end">
          <ClientAge
            setFieldValue={props.setFieldValue}
            errors={props.errors}
            validateForm={props.validateForm}
            values={props.values}
          />
        </Grid>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FamilyStatusSelect tabIndex={8} />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field
              name="abnormalities"
              component={TextField}
              label="Auffälligkeiten"
              tabIndex={9}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Box marginTop={4} display="flex" justifyContent="center" flexDirection="row-reverse">
        <PrimaryButton type="submit" tabIndex={0}>
          OK
        </PrimaryButton>
        <Box mx={1}></Box>
        <SecondaryButton tabIndex={0} onClick={cancel}>
          Abbrechen
        </SecondaryButton>
      </Box>
    </form>
  );
};

export default MasterData;
