import React from "react";
import { useHistory } from "react-router-dom";

import PrimaryButton from "../../atoms/PrimaryButton";
import errorIllustration from "../../assets/errorIllustration.svg";
import IllustratedErrorMessage from "../../organisms/IllustratedErrorMessage";

export default function ErrorFallback({ error }: { error: Error | CustomError }) {
  const { goBack } = useHistory();

  console.error({ error });

  return (
    <IllustratedErrorMessage
      messages={[
        error instanceof CustomError ? error.humanReadableMessage : "Ein Fehler ist aufgetreten",
      ]}
      illustration={errorIllustration}
      illustrationAltText="Elli rutscht auf einer Banane aus"
    >
      <PrimaryButton onClick={goBack}>Zurück</PrimaryButton>
    </IllustratedErrorMessage>
  );
}

export class CustomError extends Error {
  // custom error class to throw specific errors from within our app
  constructor(humanReadableMessage: string, error: Error) {
    super(error.message);

    this.name = error.name;
    this.stack = error.stack;
    this.humanReadableMessage = humanReadableMessage;
  }

  humanReadableMessage: string;
}
