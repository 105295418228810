import Alert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { BusinessUnit, useBreadCrumbInfoByAccountIdQuery } from "../../../generated/graphql";
import { useAccountSubscription } from "../../../hooks/useAccountSubscription";
import { useBusinessUnitsSubscription } from "../../../hooks/useBusinessUnitsSubscription";
import errorIllustration from "../../assets/errorIllustration.svg";
import Loader from "../../atoms/Loading";
import PrimaryButton from "../../atoms/PrimaryButton";
import BusinessUnitDialog from "../../forms/BusinessUnitDialog";
import AccountStatusBadge from "../../molecules/AccountStatusBadge";
import ClickableItemList from "../../organisms/ClickableItemList";
import IllustratedErrorMessage from "../../organisms/IllustratedErrorMessage";
import PersonLayout from "../../templates/PersonLayout";

const AccountBusinessUnits = () => {
  const { id } = useParams<{ id: string }>();
  const { goBack } = useHistory();

  const accountId = parseInt(id, 10);

  const [open, setOpen] = useState(false);
  const [currentBusinessUnit, setCurrentBusinessUnit] = useState<
    Pick<BusinessUnit, "id" | "name"> | undefined
  >();

  const toggle = () => {
    if (open) {
      setCurrentBusinessUnit(undefined);
    }

    setOpen(!open);
  };

  const { account, loading: loadingAccount, error: accountError } = useAccountSubscription(
    accountId,
    !id,
  );
  const { data: breadcrumbData } = useBreadCrumbInfoByAccountIdQuery({
    skip: !accountId,
    variables: {
      id: accountId!,
    },
  });

  const {
    businessUnits,
    loading: loadingBusinessUnits,
    error: businessUnitsError,
  } = useBusinessUnitsSubscription(accountId);

  if (loadingAccount || loadingBusinessUnits) {
    return <Loader />;
  }

  if (accountError || !account) {
    return (
      <IllustratedErrorMessage
        messages={["Der Account konnte nicht geladen werden."]}
        illustration={errorIllustration}
        illustrationAltText="Elli rutscht auf einer Banane aus"
      >
        <PrimaryButton onClick={goBack}>Zurück</PrimaryButton>
      </IllustratedErrorMessage>
    );
  }

  const navigationPath = [
    {
      name: "Accounts",
      path: "/account",
    },
    {
      name: account.name || "",
      path: `/account/${account.id}`,
      chip: (
        <AccountStatusBadge
          contractEnd={breadcrumbData?.breadCrumbInfoByAccountId?.contractEnd}
          lang={breadcrumbData?.breadCrumbInfoByAccountId?.language || ""}
        />
      ),
    },
    {
      name: "Business-Units",
      path: ``,
    },
  ];

  const personIds = account.business?.persons?.map(p => p!.id!) || [];
  const pathId = account.business?.id;

  if (businessUnitsError) {
    return (
      <PersonLayout
        counselor
        personIds={personIds}
        mapList={navigationPath}
        contractExpirationDate={account.contractEnd}
        pathId={pathId}
      >
        <Alert severity="error">Business-Units konnten nicht geladen werden</Alert>
      </PersonLayout>
    );
  }

  return (
    <PersonLayout
      counselor
      personIds={personIds}
      mapList={navigationPath}
      contractExpirationDate={account.contractEnd}
      pathId={pathId}
    >
      <ClickableItemList
        title="Business-Units"
        btnLabel="Business-Unit anlegen"
        onClick={toggle}
        items={businessUnits.map(businessUnit => ({
          key: `business-unit-${businessUnit.id}`,
          title: businessUnit.name,
          onClick: () => {
            setCurrentBusinessUnit(businessUnit);
            setOpen(true);
          },
        }))}
      />

      <BusinessUnitDialog
        businessUnit={currentBusinessUnit}
        accountId={accountId}
        open={open}
        toggle={toggle}
      />
    </PersonLayout>
  );
};

export default AccountBusinessUnits;
