import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    content: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    },
    contentBox: {
      marginTop: theme.spacing(20),
    },
    greeting: {
      marginBottom: theme.spacing(2),
    },
    greetingLink: {
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    clickable: () => {
      return {
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        color: theme.palette.primary.main,
        cursor: "pointer",

        "&:first-child": {
          borderTop: `1px solid ${theme.palette.grey[400]}`,
        },
        "&:hover": {
          color: theme.palette.primary.dark,
          backgroundColor: "#E7F9FF",
          "&>*": {
            color: theme.palette.primary.dark,
          },
        },
      };
    },
  };
});

export default useStyles;
