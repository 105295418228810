import React, { useEffect, useState } from "react";

import makeStyles from "@material-ui/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { Select } from "formik-material-ui";
import { Field } from "formik";

import { Service, useServicesQuery } from "../../../generated/graphql";

const useStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
  },
}));

interface ServiceSelectContainerProps {
  onChangeTopic: (topicId: number) => void;
  topicId: number;
}

const ServiceSelectContainer = ({ onChangeTopic, topicId }: ServiceSelectContainerProps) => {
  const [services, setServices] = useState<Service[] | null | undefined>(undefined);

  const { data } = useServicesQuery({
    variables: {
      topicId,
    },
  });

  useEffect(() => {
    onChangeTopic(topicId);
    // Do no change dependencies cause of warnings, this results in infinite renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicId]);

  useEffect(() => {
    setServices(data?.services);
  }, [data]);

  return <ServiceSelect services={services} />;
};

interface ServiceSelectProps {
  services?: Service[] | null;
}

const ServiceSelect = ({ services }: ServiceSelectProps) => {
  const classes = useStyles();

  return (
    <FormControl
      className={classes.formControl}
      margin="normal"
      disabled={!services || !services.length}
    >
      <InputLabel style={{ position: "absolute" }} htmlFor="Service">
        Service
      </InputLabel>

      <Field
        name="serviceId"
        label="Service"
        component={Select}
        disabled={!services || !services.length}
      >
        {services &&
          !!services.length &&
          services.map(service => {
            return (
              <MenuItem key={String(service.id)} value={service.id || -1}>
                {service.serviceMeta?.name}
              </MenuItem>
            );
          })}
      </Field>
    </FormControl>
  );
};

export default ServiceSelectContainer;
