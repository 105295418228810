import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  normalWeight: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  icon: {
    width: 115,
    height: "auto",
  },
}));
