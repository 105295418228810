import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { usePersonActivityQuery } from "../../../generated/graphql";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PrimaryButton from "../../atoms/PrimaryButton";
import DialogContent from "../../molecules/DefaultDialogContent";
import Dialog from "../../molecules/DefaultDialog";
import Loading from "../../atoms/Loading";
import useStyles from "./styles";

import elliDeskIcon from "../../assets/elliDeskIcon.svg";

interface ICallDialogCompleted {
  activityId: number;
  handleClose: () => void;
  open: boolean;
  handleNotifyClose: () => void;
}

const CallDialogCompleted: FC<ICallDialogCompleted> = props => {
  const classes = useStyles();
  return (
    <Dialog
      open={props.open}
      onClose={props.handleNotifyClose}
      PaperProps={{ classes: { root: classes.dialog } }}
    >
      <DialogContent>{props.open && <Content {...props} />}</DialogContent>
    </Dialog>
  );
};

const Content: FC<ICallDialogCompleted> = props => {
  const classes = useStyles();

  const { push } = useHistory();

  const { data, loading } = usePersonActivityQuery({
    variables: { id: props.activityId },
    fetchPolicy: "no-cache",
  });

  const activity = data?.personActivity;
  const call = activity?.call;
  const person = activity?.person;
  const business = person?.business;
  const memo = activity?.memo;
  const report = activity?.conversationReport;

  return loading || !activity ? (
    <Loading />
  ) : (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        width={1}
      >
        <Typography variant="h1" align="center">
          Du hast dein Telefonat mit <br />
          {person?.name} ({call && call.readableNumber}) beendet.
        </Typography>
      </Box>
      <Box my={5}>
        <img className={classes.elliDesk} src={elliDeskIcon} alt="Gespräch erfolgreich beendet" />
      </Box>
      <div>
        <PrimaryButton
          onClick={() => {
            const computedPathname = business
              ? `/business/${business!.id!}/memo`
              : `/person/${person!.id!}/report`;

            props.handleClose();
            push({
              pathname: computedPathname,
              state: { activity },
            });
          }}
        >
          Gesprächsprotokoll oder Notiz anlegen
        </PrimaryButton>
      </div>
    </>
  );
};

export default CallDialogCompleted;
