import { useEffect } from "react";

import {
  useServicesByTypeQuery,
  ServiceType,
  ServiceStatus,
  ServicesByTypeSubscriptionDocument,
  ServicesByTypeSubscriptionSubscription,
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

const useServicesByTypeSubscription = ({
  type,
  status,
  statusNot,
}: {
  status?: ServiceStatus[];
  statusNot?: ServiceStatus[];
  type: ServiceType;
}) => {
  const { data, loading, subscribeToMore } = useServicesByTypeQuery({
    variables: {
      type,
      status,
      statusNot,
    },
    fetchPolicy,
  });

  useEffect(() => {
    const subscribe = subscribeToMore<ServicesByTypeSubscriptionSubscription>({
      document: ServicesByTypeSubscriptionDocument,
      variables: {
        type,
        status,
        statusNot,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) {
          return prev;
        }

        return { servicesByType: subscriptionData.data.servicesByTypeSubscription };
      },
    });

    return subscribe;
  });

  return { data, loading };
};

export default useServicesByTypeSubscription;
