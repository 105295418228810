import React, { useState } from "react";

import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import { Field } from "formik";
import { TextField } from "formik-material-ui";

import DialogForm from "../DialogForm";
import { BusinessObject, useUpdateBusinessObjectMutation } from "../../../generated/graphql";
import BusinessCategorySelect from "../../fields/BusinessCategorySelect";

type TBusinessEditDialog = {
  business: BusinessObject;
  open: boolean;
  toggle: () => void;
};

const BusinessEditDialog = ({ business, open, toggle }: TBusinessEditDialog) => {
  const [update] = useUpdateBusinessObjectMutation();
  const [category, setCategory] = useState({
    value: business?.category?.id || "",
    label: business?.category?.name || "",
  });
  return (
    <DialogForm
      open={open}
      initialValues={{
        name: business.name || "",
        businessCategoryId: business.category?.id || "",
      }}
      onClose={toggle}
      title="Stammdaten bearbeiten"
      onSubmit={values => {
        return update({
          variables: {
            id: business.id!,
            input: {
              name: values.name,
              businessCategoryId: values.businessCategoryId || null,
            },
          },
        }).then(() => {
          toggle();
        });
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl margin="normal" fullWidth>
            <Field name="name" component={TextField} label="Name" />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <BusinessCategorySelect businessCategory={category} setBusinessCategory={setCategory} />
        </Grid>
      </Grid>
    </DialogForm>
  );
};

export default BusinessEditDialog;
