import React, { ChangeEvent, useState } from "react";
import classnames from "classnames";
import { useSnackbar } from "notistack/";
import dayjs from "dayjs";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import MuLink from "@material-ui/core/Link";
import WatchLater from "@material-ui/icons/WatchLater";
import GroupAdd from "@material-ui/icons/GroupAdd";
import RemoveCircle from "@material-ui/icons/RemoveCircle";

import AssignmentReturn from "@material-ui/icons/AssignmentReturn";
import Check from "@material-ui/icons/CheckCircle";
import { DateTimePicker } from "@material-ui/pickers";
import Notification from "../../molecules/Notification";

import {
  Task,
  Team,
  useAssignTaskMutation,
  useAssignTaskToTeamMutation,
  useFinishTaskMutation,
  useSnoozeTaskMutation,
  useUnassignTaskMutation,
} from "../../../generated/graphql";
import { allowManualFinish, isMine } from "../../../utils/taskHelpers";
import DefaultDialog from "../../molecules/DefaultDialog";
import DefaultDialogContent from "../../molecules/DefaultDialogContent";
import AssignUserDialog from "../AssignUserDialog";
import { useAuthorization } from "../../../providers/AuthorizationProvider";
import useStyles from "../../assets/inboxStyles";
import TeamsDialog from "../TeamsDialog/TeamsDialog";
import AssignTeamDialog from "../AssignTeamDialog";
import TeamSelect from "../../forms/TeamSelectField/TeamSelectField";

export const InboxWrapper = ({
  children,
  isHeadline = false,
}: {
  children: any;
  isHeadline?: boolean;
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classnames({
        [classes.task]: !isHeadline,
      })}
    >
      {children}
    </Grid>
  );
};

export const TaskActions = ({ task, mayFinish = false }: { task: Task; mayFinish?: boolean }) => {
  const { id, author } = task;
  const { isAuthorized, me } = useAuthorization();
  const [finishTask] = useFinishTaskMutation();
  const [snoozeTask] = useSnoozeTaskMutation();
  const [unassignTask] = useUnassignTaskMutation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState(dayjs());
  const [isOpen, setIsOpen] = useState(false);

  const unassign = () => (task.id ? unassignTask({ variables: { taskId: task.id } }) : undefined);
  const revert = (taskId: number) => finishTask({ variables: { taskId, revert: true } });
  const finish = () => {
    if (id) {
      finishTask({ variables: { taskId: id } }).then(() => {
        enqueueSnackbar("", {
          content: (key: string) => (
            <Notification
              id={key}
              message={
                <>
                  Die Aufgabe wurde erledigt.{" "}
                  <MuLink
                    onClick={() => revert(id)}
                    className={classes.notificationLink}
                    underline="always"
                  >
                    Rückgängig machen
                  </MuLink>
                </>
              }
            />
          ),
        });
      });
    }
  };
  return (
    <div className={classes.actionWrapper}>
      <div title="Snooze" className={classes.actionItem}>
        <WatchLater onClick={() => setIsOpen(true)} />
      </div>
      {id && author && (isAuthorized("assignTask") || isMine(task, me)) && (
        <div title="Aufgabe abgeben" className={classes.actionItem}>
          <AssignmentReturn onClick={unassign} />
        </div>
      )}
      {id && isAuthorized("assignTask") && <AssignTask taskId={id} />}

      <DateTimePicker
        ampm={false}
        format="yyyy-mm-dd hh:ii"
        disablePast
        InputProps={{ className: classes.input }}
        label=""
        onChange={date => date && handleDateChange(date)}
        onAccept={snoozeUntil =>
          snoozeUntil &&
          id &&
          snoozeTask({
            variables: {
              taskId: id,
              snoozeUntil: snoozeUntil.second(0).millisecond(0),
            },
          }).then(() =>
            enqueueSnackbar("", {
              content: (key: string) => (
                <Notification
                  id={key}
                  message={`Die Aufgabe wird dir am ${dayjs(snoozeUntil).format(
                    "DD.MM.YYYY",
                  )} um ${dayjs(snoozeUntil).format("H:mm")} Uhr wieder vorgelegt.`}
                />
              ),
            }),
          )
        }
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        open={isOpen}
        value={selectedDate}
        variant="dialog"
      />
      {mayFinish && allowManualFinish(task) && (
        <div className={classes.actionItem} title="Aufgabe abschließen">
          <Check onClick={finish} />
        </div>
      )}
    </div>
  );
};

const AssignTask = ({ taskId }: { taskId: number }) => {
  const [open, setOpen] = useState(false);
  const [teamOpen, setTeamOpen] = useState(false);

  const classes = useStyles();
  const [assignTask] = useAssignTaskMutation();
  const [assignTaskToTeam] = useAssignTaskToTeamMutation();

  const handleClick = async (userId?: number) => {
    // We do not allow this currently (needed for resetting caseManager only so far)
    if (userId !== undefined) {
      await assignTask({ variables: { taskId, userId } });
    }
    setOpen(false);
  };

  const handleTeamChange = async (targetTeam?: Team) => {
    if (targetTeam !== undefined) {
      //console.log(targetTeam)
      await assignTaskToTeam({ variables: { taskId, targetTeam } });      
    }
    setTeamOpen(false);
  };

  return (
    <>
      <Box color="primary" height={24} title="Aufgabe zuweisen">
        <svg
          onClick={() => setOpen(true)}
          className={classes.actionItem}
          color="inherit"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 19.5C4 16.1625 10.6625 14.5 14 14.5C17.3375 14.5 24 16.1625 24 19.5V22H4V19.5Z"
            fill="currentColor"
          />
          <path
            d="M17.5355 10.5355C16.5979 11.4732 15.3261 12 14 12C12.6739 12 11.4021 11.4732 10.4645 10.5355C9.52678 9.59785 9 8.32608 9 7C9 5.67392 9.52678 4.40215 10.4645 3.46447C11.4021 2.52678 12.6739 2 14 2C15.3261 2 16.5979 2.52678 17.5355 3.46447C18.4732 4.40215 19 5.67392 19 7C19 8.32608 18.4732 9.59785 17.5355 10.5355Z"
            fill="currentColor"
          />
          <path d="M8 11.5L3.55556 16V13.3H0V9.7H3.55556V7L8 11.5Z" fill="currentColor" />
        </svg>
        <DefaultDialog open={open} onClose={() => setOpen(false)}>
          <DefaultDialogContent>
            <AssignUserDialog
              onSubmit={handleClick}
              headline="Wem möchtest du die Aufgabe zuweisen?"
            />
          </DefaultDialogContent>
        </DefaultDialog>
      </Box>

      <Box color="primary" height={24} title="Aufgabe zuweisen">      
        <div title="Aufgabe zuweisen" className={classes.actionItem}>
          <GroupAdd onClick={() => setTeamOpen(true)} />                    
        </div>
        <DefaultDialog open={teamOpen} onClose={() => setTeamOpen(false)}>
          <DefaultDialogContent>
            <AssignTeamDialog handleChange={handleTeamChange} />
          </DefaultDialogContent>
        </DefaultDialog>
      </Box>

      {/* <Box color="textSecondary" height={24} title="Send back to pool">      
        <div title="Send back to pool" style={{color: 'red'}} className={classes.actionItem}>
        <RemoveCircle />
        </div>
        <DefaultDialog open={teamOpen} onClose={() => setTeamOpen(false)}>
          <DefaultDialogContent>
            <AssignTeamDialog handleChange={handleTeamChange} />
          </DefaultDialogContent>
        </DefaultDialog>
      </Box>       */}
    </>
  );
};