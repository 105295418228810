import { useEffect } from "react";
import {
  PersonAppointmentsQueryVariables,
  usePersonAppointmentsQuery,
  PersonAppointmentsSubscriptionSubscription,
  PersonAppointmentsSubscriptionDocument,
  Appointment,
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

export const usePersonAppointmentsSubscription = (variables: PersonAppointmentsQueryVariables) => {
  const { data, loading, subscribeToMore } = usePersonAppointmentsQuery({ variables, fetchPolicy });

  useEffect(() => {
    const unsubscribe = subscribeToMore<PersonAppointmentsSubscriptionSubscription>({
      document: PersonAppointmentsSubscriptionDocument,
      variables: {
        ...variables,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !prev) return prev;
        return Object.assign({}, prev, {
          personAppointments: [...subscriptionData.data.personAppointmentsSubscription],
        });
      },
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables]);

  return { appointments: (data?.personAppointments || []) as Appointment[], loading };
};
