import React, { createContext, FC, useContext } from "react";

import { useParams } from "react-router-dom";
import { path } from "ramda";
import { orderBy } from "lodash";

import {
  GMailInbox,
  GMailMessage,
  Topic,
  BusinessObject,
  PersonQuery,
} from "../../generated/graphql";
import useGetGMailThreadSubscription from "../../hooks/useGetGMailThreadSubscription";

type TEmailThreadProvider = {
  emails?: GMailMessage[] | null;
  inbox?: GMailInbox | null;
  thread?: {
    threadId?: string;
    subject?: string;
  };
  personActivity?: any | null;
  context: {
    topic?: Topic | null;
    person?: PersonQuery["person"] | null;
    businessObject?: BusinessObject | null;
  };
};

export const EmailThreadContext = createContext<TEmailThreadProvider | undefined>(undefined);

const EmailThreadProvider: FC<{
  context: {
    person?: PersonQuery["person"] | null;
    businessObject?: BusinessObject | null;
  };
}> = ({ children, context }) => {
  const { threadId } = useParams<{ threadId: string }>();
  const { getOneGMailThread } = useGetGMailThreadSubscription(threadId);

  const inbox: GMailInbox | undefined = path(["emails", 0, "gMailInbox"], getOneGMailThread || {});

  const sortedEmails = orderBy(getOneGMailThread?.emails, "date") as GMailMessage[];

  let subject = "";
  if (sortedEmails) {
    subject = `Re: ${sortedEmails[0]?.payload?.subject || ""}`;
  }

  return (
    <EmailThreadContext.Provider
      value={{
        inbox,
        emails: sortedEmails,
        personActivity: getOneGMailThread?.personActivity,
        thread: {
          threadId,
          subject,
        },
        context: {
          ...context,
          topic: getOneGMailThread?.topic,
        },
      }}
    >
      {children}
    </EmailThreadContext.Provider>
  );
};

export const useEmailThreadContext = () => {
  const context = useContext(EmailThreadContext);
  if (context === undefined) {
    throw new Error("useEmailThreadContext must be used within a EmailThreadContext");
  }
  return context;
};

export default EmailThreadProvider;
