import React, { FC } from "react";
import classNames from "classnames";
import Box from "@material-ui/core/Box";

import { useCurrentTaskCountSubscription } from "../../../hooks/useCurrentTaskCountSubscription";
import { useMyAppointmentsSubscription } from "../../../hooks/useMyAppointmentsSubscription";

const TaskCounter: FC<{ activeElli: boolean }> = ({ activeElli }) => {
  const { tasks, loading, error } = useCurrentTaskCountSubscription({});

  const { appointments } = useMyAppointmentsSubscription();

  if (!!error) return <></>;
  const count = appointments.length + tasks.length;
  return (
    <div
      className={classNames("counter", {
        "counter-big": activeElli,
        "counter-border": !loading && count > 0,
      })}
    >
      {!loading && count > 0 && <Counter count={count} />}
    </div>
  );
};

const Counter = ({ count }: { count: number }) => {
  const counter = count < 100 ? count : "99+";
  return <Box lineHeight="16px">{counter}</Box>;
};

export default TaskCounter;
