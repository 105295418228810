import React, { useEffect } from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { useAssignPersonMutation } from "../../../../generated/graphql";

import PrimaryButton from "../../../atoms/PrimaryButton";
import SecondaryButton from "../../../atoms/SecondaryButton";
import { useHistory } from "react-router";
import { usePersonCreateContext } from "../provider";
import { STEPS } from "../reducer";
import usePersonSubscription from "../../../../hooks/usePersonSubscription";
import AccountStatusBadge from "../../../molecules/AccountStatusBadge";
import useContractStatus from "../../../../hooks/useContractStatus";
import useStyles from "./styles";

interface IPersonAssignMatch {
  handleClose: () => void;
}

const PersonAssignMatch = ({ handleClose }: IPersonAssignMatch) => {
  const { personActivity, setStep } = usePersonCreateContext();
  const { push } = useHistory();
  const [assignPerson] = useAssignPersonMutation();
  const { person } = usePersonSubscription(personActivity?.matches?.[0]?.id);
  const classes = useStyles();
  const accountContractEnd = person?.client?.account?.contractEnd;
  const isContractExpired = useContractStatus(accountContractEnd);

  let match: any;
  if (personActivity && personActivity.matches && personActivity.matches.length === 1) {
    match = personActivity.matches[0];
  }

  useEffect(() => {
    if (!match?.id) {
      setStep(STEPS.ASSIGN_OR_CREATE);
    } else {
      setStep(STEPS.ASSIGN_MATCH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match?.id]);

  if (!match) {
    return null;
  }

  const { id, name, age, ageGuessed } = match;

  if (!id) {
    throw Error();
  }

  // TODO: Determine correctly!
  const isPast = false;

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        width={1}
        className={`${isContractExpired ? classes.deactivated : ""}`}
      >
        <Typography variant="h1" align="center">
          {isPast ? "War" : "Ist"} das<br />
          <b>{name}</b>
          <b>{age ? ` - ${age} Jahre${ageGuessed ? " (geschätzt)" : ""}` : " "}</b><br />
          am Telefon?
        </Typography>
        {isContractExpired && (
          <Box align="center" my={1}>
            <b>Account:</b> {person?.client?.account?.name || "ohne Account"}<br />
            <Box align="center" mt={1}>
              <AccountStatusBadge contractEnd={accountContractEnd} />
            </Box>
          </Box>
        )}
      </Box>
      <Box display="flex" mt={5}>
        <SecondaryButton onClick={() => setStep(STEPS.ASSIGN_OR_CREATE)}>
          Nein, andere Person zuweisen
        </SecondaryButton>
        {!isContractExpired && (
          <PrimaryButton
            style={{ marginLeft: "1em" }}
            onClick={async () => {
              await assignPerson({
                variables: {
                  id: personActivity.id,
                  personId: id,
                },
              });

              handleClose();
              push(`/person/${id}`);
            }}
          >
            Ja, zuweisen
          </PrimaryButton>
        )}
      </Box>
    </>
  );
};

export default PersonAssignMatch;
