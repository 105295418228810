import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  boxItem: {
    backgroundColor: "#EFE8DD",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0, 3, 0),
    "&:first-child": {
      paddingTop: theme.spacing(10),
    },
    position: "relative",
  },
  chip: {
    margin: theme.spacing(1, 0),
    "&.outlined": {
      border: "1px solid",
    },
  },
  styledLink: {
    display: "inline-flex",
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(2),
  },
  dialogRoot: {
    padding: theme.spacing(6, 11),
    textAlign: "center",
  },
  success: {
    height: 120,
    margin: theme.spacing(9, 0, 5),
    width: 98,
  },
  successHeading: {
    marginBottom: theme.spacing(15),
  },
  secondaryBtn: {
    marginRight: theme.spacing(3),
  },
  reqs: {
    marginBottom: theme.spacing(8),
  },
}));

export default useStyles;
