import React, { FC } from "react";
import Loader from "../../atoms/Loading";
import List from "./List";

import { Client, useGetStcClientsQuery } from "../../../generated/graphql";

const GenericStcClients: FC<{userId: number}> = ({ userId }) => {
  const { data, loading } = useGetStcClientsQuery({
    variables: {
      userId: userId
    }
  });

  if (loading && !data?.getSTCClients?.length) {
    return <Loader />;
  }
  return (
    <List
      title="Klienten in interner Beratung"
      clients={(data?.getSTCClients || []) as Client[]}
      emptyMessage="Du hast momentan keine Klienten in einer internen Beratung"
    />
  );
};

export default GenericStcClients;
