import React, { useState } from "react";

import Room from "@material-ui/icons/Room";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";

import { Address } from "../../../../generated/graphql";

import AddressDialog from "./AddressDialog";

type AddressFieldProps = {
  address: Address;
  tabIndex?: number;
};

export default function AddressField({ address, tabIndex = 0 }: AddressFieldProps) {
  const [addressDialogIsOpen, toggleAddressDialog] = useState(false);

  const addressLabel =
    address.street && address.zip && address.city
      ? `${address.street}, ${address.zip} ${address.city}`
      : "Adresse";

  return (
    <>
      <Box marginRight={1} height={24}>
        <Room color="primary" />
      </Box>
      <Link
        onClick={() => toggleAddressDialog(!addressDialogIsOpen)}
        tabIndex={tabIndex}
        variant="h4"
        underline="always"
      >
        {addressLabel}
      </Link>
      <AddressDialog open={addressDialogIsOpen} toggle={toggleAddressDialog} />
    </>
  );
}
