import React from "react";

const NoUserSelectedTeaser = () => {
  return (
    <svg
      style={{ verticalAlign: "top" }}
      width="171"
      height="172"
      viewBox="0 0 171 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.354 24.4774C141.655 24.4774 134.459 18.7779 129.306 18.7779C127.406 18.7779 125.642 26.9437 124.556 27.3272C123.471 27.7107 121.869 21.6871 121.078 23.9563C120.333 26.0942 119.593 29.4343 118.857 33.9767C109.357 25.4273 114.107 48.2255 120.131 44.3229C123.081 54.7727 129.663 59.9976 139.877 59.9976C152.462 59.9976 160.653 51.9553 164.453 35.8707C172.053 39.6762 169.203 14.9782 162.204 25.1778C161.803 21.1174 160.653 18.0341 158.754 15.9281C157.505 14.5443 155.562 19.0872 154.954 21.6276C154.68 22.7691 145.677 16.1542 144.505 16.878C142.525 18.1004 149.051 24.4774 147.354 24.4774Z"
        stroke="#025879"
        strokeLinejoin="round"
      />
      <path
        d="M128.863 42.165C129.822 48.8086 133.144 52.1305 138.828 52.1305C144.512 52.1305 149.571 48.2958 154.004 40.6265"
        stroke="#025879"
        strokeLinecap="round"
      />
      <path
        d="M132.534 33.9008C133.584 33.9008 134.434 33.0502 134.434 32.0009C134.434 30.9517 133.584 30.1011 132.534 30.1011C131.485 30.1011 130.635 30.9517 130.635 32.0009C130.635 33.0502 131.485 33.9008 132.534 33.9008Z"
        fill="#025879"
      />
      <path
        d="M147.675 31.8031C148.724 31.8031 149.575 30.9525 149.575 29.9033C149.575 28.854 148.724 28.0034 147.675 28.0034C146.626 28.0034 145.775 28.854 145.775 29.9033C145.775 30.9525 146.626 31.8031 147.675 31.8031Z"
        fill="#025879"
      />
      <path
        d="M121.707 23.5276C120.658 23.0031 113.819 25.2405 114.177 23.8073C114.416 22.8518 116.514 20.9286 120.471 18.0378C115.925 16.6391 114.352 15.2405 115.751 13.8418C117.849 11.7438 122.045 14.8908 125.192 12.2683C128.339 9.64584 133.86 4.40086 140.154 2.82736C146.448 1.25387 155.088 0.204872 155.088 1.77837C155.088 3.35186 153.054 5.80147 153.054 7.37884C153.054 8.95621 166.604 10.316 167.303 14.0283C167.572 15.4564 166.353 18.0378 158.754 15.9282"
        stroke="#00577A"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M136.905 166.669C136.905 168.272 135.6 170.766 130.256 170.766C124.911 170.766 118.897 169.292 118.897 166.669C118.897 164.047 125.506 163.097 130.256 163.167C135.005 163.237 136.905 165.067 136.905 166.669Z"
        stroke="#025879"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M153.054 169.816C158.888 170.865 164.004 168.767 164.004 166.145C164.004 163.522 149.936 162.601 147.221 164.571C144.505 166.542 147.221 168.767 153.054 169.816Z"
        stroke="#025879"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.306 160.375C133.106 160.375 135.58 160.724 136.731 161.424C138.456 162.473 138.828 123.66 142.5 119.988C144.948 117.541 146.171 131.352 146.171 161.424C150.018 161.773 152.465 161.424 153.514 160.375C155.088 158.801 156.137 134.674 156.137 127.331C156.137 122.436 155.263 115.967 153.514 107.925H130.437C127.29 118.764 126.913 136.248 129.306 160.375Z"
        fill="#CCCCCC"
        stroke="#025879"
      />
      <path
        d="M119.807 76.7231C113.513 80.9191 107.458 90.0221 108.408 93.7635C109.041 96.2578 114.985 100.803 126.24 107.4C127.289 112.121 125.25 118.619 117.907 117.57C110.564 116.521 120.261 107.4 125.506 107.4"
        stroke="#025879"
      />
      <path
        d="M160.857 80.6514C164.704 85.197 166.627 93.7638 166.627 106.352C161.711 117.129 161.35 121.819 165.546 120.42C171.84 118.322 171.347 111.597 166.627 106.352"
        stroke="#025879"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127.814 110.548C134.807 109.499 140.227 109.324 144.073 110.023C147.92 110.722 151.591 110.373 155.088 108.974L153.514 79.0777L157.71 84.3227C161.557 83.2737 164.878 80.6512 167.676 76.4552C162.78 69.8116 158.584 65.6156 155.088 63.8673C149.843 61.2448 144.073 64.3918 140.926 64.3918C137.779 64.3918 136.73 62.2938 132.01 63.3428C127.289 64.3918 118.373 68.5878 117.324 72.7838C116.275 76.9797 119.422 84.3227 120.995 84.3227C122.044 84.3227 124.842 82.3996 129.387 78.5532L127.814 110.548Z"
        fill="#A6C4CE"
        stroke="#025879"
        strokeLinejoin="round"
      />
      <path
        d="M163.503 32.0768C164.136 33.3433 164.77 33.3433 165.403 32.0768C166.036 30.8102 166.036 29.227 165.403 27.3271"
        stroke="#025879"
        strokeLinecap="round"
      />
      <path
        d="M120.189 40.3317C119.881 41.7138 119.266 41.867 118.345 40.7913C117.425 39.7156 117.042 38.1794 117.196 36.1828"
        stroke="#025879"
        strokeLinecap="round"
      />
      <path
        d="M62.2634 70.7036C70.5242 88.5729 79.6396 99.2368 89.6095 102.695C93.8444 97.6868 96.5379 96.3482 97.6899 98.6796C99.4181 102.177 94.2337 102.695 102.01 102.695C109.787 102.695 104.602 110.919 99.4181 110.919C94.2337 110.919 91.6415 107.067 89.6095 102.695"
        stroke="#025879"
      />
      <path
        d="M44.7124 70.7036C36.4516 88.5729 27.3362 99.2368 17.3663 102.695C13.1314 97.6868 10.438 96.3482 9.28589 98.6796C7.55777 102.177 12.7421 102.695 4.96558 102.695C-2.81098 102.695 2.3734 110.919 7.55777 110.919C12.7421 110.919 15.3343 107.067 17.3663 102.695"
        stroke="#025879"
      />
      <path
        d="M77.3374 32.2363C78.7652 35.1397 79.5732 38.5443 79.5732 42.434C79.5732 57.1109 64.8463 62.2911 55.6419 62.2911C46.4376 62.2911 30.7902 57.1109 30.7902 42.434C30.7902 39.6121 31.2155 37.0456 31.9941 34.7405"
        stroke="#025879"
      />
      <path
        d="M46.9009 126.111C44.985 134.2 44.985 144.888 46.9009 158.177C49.4554 159.332 50.4133 161.065 49.7747 163.376C48.8167 166.843 44.027 170.309 40.1953 170.309C36.3635 170.309 33.4897 164.243 37.3215 160.777C39.876 158.466 43.0691 157.599 46.9009 158.177"
        stroke="#025879"
      />
      <path
        d="M62.7893 126.111C63.5645 134.362 63.5645 145.853 62.7893 160.586C59.649 161.175 58.3745 162.648 58.9658 165.006C59.8527 168.541 68.7218 170.309 72.2694 170.309C74.2075 170.309 79.3437 170.351 80.2516 168.541C82.0254 165.006 74.0584 161.253 71.3825 160.586C69.0174 159.996 66.153 159.996 62.7893 160.586"
        stroke="#025879"
      />
      <path
        d="M65.7609 65.4585C62.8819 65.6306 61.1334 66.0256 60.5154 66.6436C57.8927 69.2663 54.0813 71.8891 52.618 71.8891C51.2357 71.8891 48.3676 69.138 46.5274 66.6436C45.9446 65.8535 44.1961 65.4585 41.2819 65.4585C44.7789 80.6316 37.2604 123.662 28.2265 134.408C39.0672 131.423 47.8 130.527 54.4248 131.721C61.0497 132.915 68.5779 133.512 77.0096 133.512C67.1791 95.9271 63.4296 73.2425 65.7609 65.4585Z"
        fill="#BDBDBD"
        stroke="#025879"
        strokeLinejoin="round"
      />
      <path
        d="M44.5459 43.0383C45.5437 43.0383 46.3526 42.2468 46.3526 41.2704C46.3526 40.294 45.5437 39.5024 44.5459 39.5024C43.548 39.5024 42.7391 40.294 42.7391 41.2704C42.7391 42.2468 43.548 43.0383 44.5459 43.0383Z"
        fill="#025879"
      />
      <path
        d="M60.8068 43.0383C61.8046 43.0383 62.6136 42.2468 62.6136 41.2704C62.6136 40.294 61.8046 39.5024 60.8068 39.5024C59.8089 39.5024 59 40.294 59 41.2704C59 42.2468 59.8089 43.0383 60.8068 43.0383Z"
        fill="#025879"
      />
      <path
        d="M39.1836 22.2326C35.6866 17.6925 31.6651 14.9148 26.4196 17.481C16.8987 22.1388 22.9226 37.0233 23.7969 41.4648C24.6711 45.9062 23.7969 56.5657 17.6771 63.672C25.5454 60.711 30.9657 59.4756 34.4627 54.2301C30.9657 50.3834 29.7418 44.9631 30.7909 37.9691C31.84 30.9751 34.6375 25.7296 39.1836 22.2326Z"
        fill="#D2E1E6"
        stroke="#025879"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.083 22.1623C72.58 17.7886 76.6015 15.3897 81.847 17.0061C90.7296 19.7432 85.344 35.8319 84.4697 40.1105C83.5955 44.3891 84.4697 54.6578 90.5895 61.5035C81.847 61.5035 75.7272 57.4157 74.853 54.5633C78.6997 50.7973 79.9236 45.2425 78.5248 37.8988C77.126 30.5551 73.9788 25.3096 69.083 22.1623Z"
        fill="#D2E1E6"
        stroke="#025879"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.4196 28.0383C45.3515 36.6199 36.3004 38.6222 30.2664 34.0454C34.4902 23.7476 42.3345 18.5986 53.7991 18.5986C65.2638 18.5986 72.9812 23.1447 76.9513 32.2369C73.9343 34.5253 67.4237 33.1258 57.4196 28.0383Z"
        fill="#D2E1E6"
        stroke="#025879"
        strokeLinejoin="round"
      />
      <path
        d="M38.0172 48.147C44.2223 54.0401 49.8068 56.9866 54.7709 56.9866C59.735 56.9866 64.3888 54.924 68.7324 50.7989"
        stroke="#025879"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default NoUserSelectedTeaser;
