import React, { FC } from "react";

import Dialog from "../../molecules/DefaultDialog";
import DialogContent from "../../molecules/DefaultDialogContent";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";

import ResearchCallForm from "../../forms/ResearchCallForm";
import MinimizeIcon from "../../assets/MinimizeIcon";
import CallStatusComponent from "../../molecules/CallStatusComponent";
import Loader from "../../atoms/Loading";

import { PersonActivity, usePersonResearchActivityQuery } from "../../../generated/graphql";
import useStyles from "./styles";

interface ResearchCallDialogProps {
  activityId: number;
  isOpen: boolean;
  toggle: (next: boolean) => void;
}

const ResearchCallDialog: FC<ResearchCallDialogProps> = props => {
  return <Dialog open={props.isOpen}>{props.isOpen && <Content {...props} />}</Dialog>;
};

const Content: FC<ResearchCallDialogProps> = ({ activityId, toggle }) => {
  const classes = useStyles();
  const { data, loading } = usePersonResearchActivityQuery({
    variables: { id: activityId },
  });

  const activity = data?.personActivity as PersonActivity;

  const active = Boolean(activity?.isActive);
  return (
    <DialogContent>
      {loading && !activity ? (
        <Loader />
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" width={1}>
            {activity && <CallStatusComponent activity={activity} />}
            {active && (
              <IconButton
                onClick={() => toggle(false)}
                aria-label="Minimieren"
                className={classes.minimize}
              >
                <MinimizeIcon></MinimizeIcon>
              </IconButton>
            )}
          </Box>
          <Box mt={8} width={1}>
            <ResearchCallForm
              activity={activity}
              active={active}
              cancel={() => toggle(false)}
            ></ResearchCallForm>
          </Box>
        </>
      )}
    </DialogContent>
  );
};
export default ResearchCallDialog;
