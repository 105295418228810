import Typography from "@material-ui/core/Typography";
import React, { FC, useContext } from "react";
import { ActivitiesStreamContext } from "../.";
import { PersonActivity } from "../../../../generated/graphql";
import useStyles from "../styles";

type TCMCheckBubble = {
  activity: PersonActivity;
  isLink?: boolean;
};

export const CMCheckBubble: FC<TCMCheckBubble> = ({ activity, isLink }) => {
  const { context } = useContext(ActivitiesStreamContext);
  // Due to refactoring: https://medium.com/@calebmer/when-to-use-graphql-non-null-fields-4059337f6fc8
  let name = activity?.person?.name;
  const classes = useStyles();
  if (!name) {
    name = "Unbekannt (Fehler)";
  }

  return (
    <>
      <Typography className={`${isLink ? classes.clickableText : ""}`} variant="body2">
        Systemmeldung
      </Typography>
      <Typography variant="h4">
        CM Review {context !== "person" && `bei ${name}`} durchgeführt
      </Typography>
    </>
  );
};

export default CMCheckBubble;
