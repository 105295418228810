import { ServiceStatus, ServiceType, Service, Maybe } from "../generated/graphql";
import dayjs from "dayjs";

export const statusLabel = (status: ServiceStatus) => {
  // TODO!: Unify ServiceStatus translations somehwere
  return {
    [ServiceStatus.research]: "in Recherche",
    [ServiceStatus.ready]: "Rückmeldungfertig",
    [ServiceStatus.finished]: "abgeschlossen",
    [ServiceStatus.started]: "in Planung",
    [ServiceStatus.counseling]: "in Beratung",
  }[status];
};

export const serviceTarget = (service: Pick<Service, "status" | "serviceMeta" | "id">) => {
  const type = service.serviceMeta?.type;
  switch (type) {
    case ServiceType.researchBlank:
    case ServiceType.research:
      return `/service/${service.id}`;
    case ServiceType.internalConsult:
      return `/${type}/${service.id}`;
    case ServiceType.externalConsult:
      if (service.status === ServiceStatus.counseling || service.status === ServiceStatus.finished)
        return `/${type}/${service.id}`;
      return `/service/${service.id}`;
    default:
      return;
  }
};

export const sortByLastUpdate = (
  a: Maybe<Pick<Service, "lastActivity">>,
  b: Maybe<Pick<Service, "lastActivity">>,
) => {
  if (!a) {
    return -1;
  }

  if (!b) {
    return 1;
  }

  if (dayjs(a.lastActivity).isAfter(b.lastActivity)) {
    return -1;
  }

  if (dayjs(b.lastActivity).isAfter(a.lastActivity)) {
    return 1;
  }

  return 0;
};

export const sortByDueDate = (
  a: Maybe<Pick<Service, "duedate">>,
  b: Maybe<Pick<Service, "duedate">>,
) => {
  if (!a) {
    return -1;
  }

  if (!b) {
    return 1;
  }

  if (a.duedate && !b.duedate) {
    return -1;
  }

  if (b.duedate && !a.duedate) {
    return 1;
  }

  if (a.duedate && b.duedate) {
    if (dayjs(a.duedate).isAfter(b.duedate)) {
      return -1;
    }

    if (dayjs(b.duedate).isAfter(a.duedate)) {
      return 1;
    }
  }

  return 0;
};
