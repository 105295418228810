import { useEffect } from "react";
import {
  AllServicesQueryVariables,
  useAllServicesQuery,
  ServicesSubscriptionSubscription,
  ServicesSubscriptionDocument,
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

export const useAllServicesSubscription = (variables: AllServicesQueryVariables) => {
  const { data, loading, subscribeToMore, error } = useAllServicesQuery({
    variables,
    fetchPolicy,
  });

  useEffect(() => {
    const subscribe = subscribeToMore<ServicesSubscriptionSubscription>({
      variables,
      document: ServicesSubscriptionDocument,
      // @ts-ignore
      updateQuery: (prev, { subscriptionData }) => {
        const services = subscriptionData?.data?.servicesSubscription;

        if (!services) {
          return prev;
        }

        return { services };
      },
    });

    return subscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables]);

  return { data, loading, error };
};
