import React, { FC } from "react";
import classnames from "classnames";
import { Field, FormikProps, FormikValues } from "formik";
import { TextField } from "formik-material-ui";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import useStyles from "../formStyles";

interface FormProps {
  cancel: () => void;
}

const Form: FC<FormProps & FormikProps<FormikValues>> = ({ cancel, handleSubmit }) => {
  const classes = useStyles();
  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <FormControl className={classnames(classes.formControl)} margin="normal" fullWidth>
        <Field name="descriptor" component={TextField} label="Bezeichnung" tabIndex={1} />
      </FormControl>
      <Box display="flex" justifyContent="center" mt={4}>
        <SecondaryButton onClick={cancel}>Abbrechen</SecondaryButton>
        <Box mx={1} />
        <PrimaryButton type="submit">OK</PrimaryButton>
      </Box>
    </form>
  );
};

export default Form;
