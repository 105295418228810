import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

// uses figma illustration Frage
const QuestionIllustration = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 83 120">
      <path
        d="M48 42.584c6.338 13.709 13.33 21.89 20.98 24.543 3.248-3.842 5.315-4.869 6.199-3.08 1.325 2.683-2.652 3.08 3.314 3.08s1.989 6.31-1.989 6.31c-3.977 0-5.966-2.956-7.525-6.31M34.535 42.584c-6.337 13.709-13.33 21.89-20.98 24.543-3.248-3.842-5.314-4.869-6.198-3.08-1.326 2.683 2.651 3.08-3.315 3.08-5.966 0-1.988 6.31 1.989 6.31 3.977 0 5.966-2.956 7.525-6.31M59.565 13.073c1.095 2.227 1.715 4.84 1.715 7.823 0 11.26-11.298 15.234-18.36 15.234-7.061 0-19.066-3.974-19.066-15.234 0-2.165.327-4.134.924-5.902M36.214 85.092c-1.47 6.205-1.47 14.405 0 24.6 1.96.886 2.695 2.216 2.205 3.989-.735 2.659-4.41 5.319-7.35 5.319-2.94 0-5.144-4.654-2.204-7.314 1.96-1.773 4.41-2.437 7.349-1.994M48.403 85.092c.595 6.33.595 15.145 0 26.448-2.409.452-3.387 1.582-2.933 3.391.68 2.712 7.485 4.069 10.206 4.069 1.487 0 5.428.032 6.124-1.357 1.361-2.712-4.751-5.591-6.804-6.103-1.815-.452-4.012-.452-6.593 0"
        stroke="#025879"
        fill="none"
      />
      <path
        d="M50.683 38.56c-2.209.132-3.55.435-4.024.91-2.012 2.011-4.936 4.023-6.059 4.023-1.06 0-3.26-2.11-4.672-4.024-.448-.606-1.789-.909-4.025-.909 2.683 11.64-3.085 44.653-10.016 52.897 8.317-2.29 15.017-2.977 20.1-2.061 5.082.916 10.857 1.374 17.326 1.374-7.542-28.835-10.418-46.238-8.63-52.21z"
        fill="#BDBDBD"
        stroke="#025879"
        strokeLinejoin="round"
      />
      <path
        d="M34.407 21.36c.766 0 1.386-.608 1.386-1.357s-.62-1.356-1.386-1.356c-.765 0-1.386.607-1.386 1.356 0 .75.62 1.357 1.386 1.357zM46.882 21.36c.766 0 1.387-.608 1.387-1.357s-.621-1.356-1.387-1.356c-.765 0-1.386.607-1.386 1.356 0 .75.62 1.357 1.386 1.357z"
        fill="#025879"
      />
      <path
        d="M30.294 5.398C27.61 1.915 24.526-.216 20.5 1.753c-7.304 3.573-2.683 14.992-2.012 18.4.67 3.407 0 11.585-4.695 17.037 6.037-2.272 10.195-3.22 12.878-7.244-2.683-2.951-3.622-7.11-2.817-12.475.805-5.366 2.95-9.39 6.439-12.073zM53.232 5.344C55.915 1.989 59 .148 63.024 1.388c6.815 2.1 2.683 14.443 2.012 17.726-.67 3.282 0 11.16 4.695 16.412-6.707 0-11.402-3.136-12.072-5.325 2.95-2.889 3.89-7.15 2.816-12.784-1.073-5.634-3.487-9.658-7.243-12.073z"
        fill="#D2E1E6"
        stroke="#025879"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.284 9.852c-9.259 6.583-16.202 8.12-20.832 4.608 3.24-7.9 9.259-11.85 18.054-11.85 8.796 0 14.716 3.488 17.762 10.463-2.314 1.755-7.31.682-14.984-3.221z"
        fill="#D2E1E6"
        stroke="#025879"
        strokeLinejoin="round"
      />
      <path
        d="M29.399 25.28c4.76 4.52 9.045 6.78 12.853 6.78 3.809 0 7.379-1.582 10.711-4.746"
        fill="none"
        stroke="#025879"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default QuestionIllustration;
