import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(4),
    marginTop: -theme.spacing(2),
  },
  chip: {
    backgroundColor: "white",
    margin: theme.spacing(0, 3),
  },
  chipLabel: {
    color: theme.palette.primary.main,
    fontSize: "16px",
  },
  filterLabel: {
    fontSize: "16px",
    margin: `${theme.spacing(0, 3)}  !important`,
  },
}));
