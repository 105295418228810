import React from "react";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";

import BuildIcon from "@material-ui/icons/Build";

// import { useAccountsQuery } from "../../../generated/graphql";
import Loader from "../../atoms/Loading";
import SubHeader from "../../organisms/SubHeader";
import Breadcrumb from "../../molecules/Breadcrumb";
import PrimaryButton from "../../atoms/PrimaryButton";
import IllustratedErrorMessage from "../../organisms/IllustratedErrorMessage";

import errorIllustration from "../../assets/errorIllustration.svg";

const Accounts = () => {
  // const { loading, error } = useAccountsQuery();
  const { loading, error } = { loading: false, error: undefined }; // remove me!
  const { goBack } = useHistory();

  if (loading) {
    return <Loader />;
  }

  if (error) {
    console.error(error);

    return (
      <SubHeader>
        <Breadcrumb mapList={[{ name: "Accounts", path: "" }]} />
        <IllustratedErrorMessage
          messages={["Der Account konnte nicht geladen werden."]}
          illustration={errorIllustration}
          illustrationAltText="Elli rutscht auf einer Banane aus"
        >
          <PrimaryButton onClick={goBack}>Zurück</PrimaryButton>
        </IllustratedErrorMessage>
      </SubHeader>
    );
  }

  return (
    <>
      <SubHeader>
        <Breadcrumb mapList={[{ name: "Accounts", path: "" }]} />
      </SubHeader>

      <Box mt={24} justifyContent="center" display="flex">
        <div>
          under constuction <BuildIcon />
          <br />
          <br />
          <PrimaryButton onClick={goBack}>Zurück</PrimaryButton>
        </div>
      </Box>
    </>
  );
};

export default Accounts;
