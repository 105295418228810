import history from "./history";
import config from "../config";
import localStorageHelper, { LocalStorage } from "../utils/localStorageHelper";

// eslint-ignore
const { setItem, getItem, removeItem } = localStorageHelper(LocalStorage.session);
export class Auth {
  accessToken = getItem("accessToken");
  expiresAt = getItem("expiresAt") ? parseInt(getItem("expiresAt"), 10) : null;
  tokenRenewalTimeout;
  userProfile;
  idToken = getItem("idToken");

  auth2 = null;

  init = () => {
    if (this.auth2) {
      return this.auth2;
    }
    if (window.gapi && window.gapi.auth2) {
      this.auth2 = window.gapi.auth2.getAuthInstance();
      return window.gapi.auth2.getAuthInstance();
    }
    return null;
  };

  googleLoginSuccess = (response, doRedirect = true, callback = null) => {
    if (response.tokenObj) {
      // Set isLoggedIn flag in localStorage
      setItem("isLoggedIn", "true");
      setItem("showExtensionModal", "true");
      // Set the time that the access token will expire at
      this.accessToken = response.accessToken;
      this.idToken = response.tokenObj.id_token;
      this.expiresAt = response.tokenObj.expires_at;
      const xhr = new XMLHttpRequest();
      xhr.open(
        "GET",
        `${config.authUrl}?token=${response.tokenObj.id_token}&access_token=${response.accessToken}`,
      );
      xhr.onload = function() {
        if (xhr.responseText === "ok") {
          setItem("accessToken", response.accessToken);
          setItem("idToken", response.tokenObj.id_token);
          setItem("expiresAt", response.tokenObj.expires_at);
          const historyBack = getItem("historyBack");
          if (doRedirect) {
            if (historyBack) {
              history.push(historyBack);
              removeItem("historyBack");
            } else {
              history.push("/");
            }
            if (callback) {
              callback();
            }
          }
        }
      };
      xhr.send();
    }
  };

  doLogout = (target = null, callback = null) => {
    if (target) {
      setItem("historyBack", history.location.pathname);
    }
    const auth2 = this.init();
    // yes we enforce a logout
    if (auth2 != null) {
      auth2.signOut().then(
        auth2.disconnect().then(
          response => this.googleLogoutSuccess(target, callback),
          err => this.googleLogoutSuccess(target, callback),
        ),
        err => this.googleLogoutSuccess(target, callback),
      );
    } else {
      this.googleLogoutSuccess(target, callback);
    }
  };

  doRefreshAuth = callback => {
    const auth2 = this.init();
    if (auth2 != null) {
      auth2.currentUser
        .get()
        .reloadAuthResponse()
        .then(
          resp => this.refreshSuccess(resp, callback),
          err => {
            if (callback) {
              callback();
            }
            console.error(err);
          },
        );
    } else if (callback) {
      callback();
    }
  };

  log = err => console.error(err);

  refreshSuccess = (response, callback = null) => {
    this.accessToken = response.accessToken;
    this.expiresAt = response.expires_at;
    this.idToken = response.id_token;

    const xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      `${config.authUrl}?token=${response.id_token}&access_token=${response.access_token}`,
    );
    xhr.onload = function() {
      if (xhr.responseText === "ok") {
        setItem("accessToken", response.access_token);
        setItem("idToken", response.id_token);
        setItem("expiresAt", response.expires_at);
        if (callback) callback();
      }
    };
    xhr.send();
  };

  googleLogoutSuccess = (target = null, callback = null) => {
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;
    this.userProfile = null;
    removeItem("isLoggedIn");
    removeItem("accessToken");
    removeItem("expiresAt");
    removeItem("idToken");
    history.replace(target || "/required-login");
    if (callback) {
      callback();
    }
  };

  googleLoginError = error => {
    console.log(error);
    alert(`Error: ${error.error}. Check the console for further details.`);
  };

  getAccessToken = () => {
    return this.accessToken;
  };

  getIdToken = () => {
    return this.idToken;
  };

  isAuthenticated = () => {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = this.expiresAt;
    return new Date().getTime() < expiresAt;
  };
}

const auth = new Auth();

export default auth;
