import { useEffect } from "react";

import {
  useGetDriveDocumentsQuery,
  GetDriveDocumentsSubscriptionDocument,
  GetDriveDocumentsSubscriptionSubscription,
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

export const useGetDriveDocumentsSubscription = (personIds: number[]) => {
  const { data, loading, subscribeToMore } = useGetDriveDocumentsQuery({
    variables: {
      personIds,
    },
    fetchPolicy,
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore<GetDriveDocumentsSubscriptionSubscription>({
      document: GetDriveDocumentsSubscriptionDocument,
      variables: {
        personIds,
      },
      updateQuery: (prev, { subscriptionData: { data } }) => {
        if (!data || !prev) {
          return prev;
        }

        return {
          ...prev,
          ...{ getDriveDocuments: data.getDriveDocumentsSubscription },
        };
      },
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personIds]);

  return { getDriveDocuments: data?.getDriveDocuments, loading };
};
