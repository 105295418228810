import React from "react";

import { FormikErrors } from "formik";

import { Theme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/styles/withStyles/withStyles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { Person } from "../../../generated/graphql";

import AsyncSearch from "../../molecules/AsyncSearch";
import TopicSelectContainer from "../TopicSelectField";
import ServiceSelectContainer from "../ServiceSelectField";
import dayjs from "dayjs";
dayjs.extend(LocalizedFormat);

export const StyledKeyboardDatePicker = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
}))(KeyboardDatePicker);

type TTopicServiceSelectField = {
  dueDate?: dayjs.Dayjs | null;
  hasDueDate?: boolean;
  errors: FormikErrors<{ personId: boolean }>;
  onChangePerson: (personId?: number) => void;
  person?: Person | null;
  personId: number;
  topicId: number;
  serviceId: number;
  setFieldValue: (field: string, value: any) => void;
  submittedOnce: boolean;
};
const TopicServiceSelectField = ({
  dueDate,
  hasDueDate = false,
  errors,
  onChangePerson,
  person,
  personId,
  topicId,
  serviceId,
  setFieldValue,
  submittedOnce,
}: TTopicServiceSelectField) => {
  return (
    <Box>
      <Box display="flex">
        <Box width={!!hasDueDate ? "50%" : "100%"} padding="32px 8px 0">
          <InputLabel error={submittedOnce && !!errors.personId} htmlFor="person">
            Person
          </InputLabel>
          <AsyncSearch.PersonSearch onClick={onChangePerson} person={person} autoFocus={false} />
          {submittedOnce && !!errors.personId && (
            <Typography color="error" variant="caption">
              {errors.personId}
            </Typography>
          )}
        </Box>
        {hasDueDate && (
          <Box width="50%" padding="32px 8px 0">
            <MuiPickersUtilsProvider utils={DayjsUtils} locale="de">
              <StyledKeyboardDatePicker
                minDate={dayjs()}
                name="dueDate"
                format="DD.MM.YYYY"
                value={dueDate}
                onChange={value => {
                  setFieldValue("dueDate", value || dayjs());
                }}
                label="Fälligkeit"
                cancelLabel="Abbrechen"
                okLabel="OK"
              />
            </MuiPickersUtilsProvider>
          </Box>
        )}
      </Box>
      <Box display="flex">
        <Box width="50%" padding="8px 8px 0">
          <TopicSelectContainer
            personId={personId}
            onChangePerson={() => {
              if (submittedOnce) {
                setFieldValue("serviceId", -1);
                setFieldValue("topicId", -1);
              }
            }}
          />
        </Box>
        <Box width="50%" padding="8px 8px 0">
          <ServiceSelectContainer
            topicId={personId > -1 ? topicId : -1}
            onChangeTopic={() => {
              if (submittedOnce) {
                setFieldValue("serviceId", -1);
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default TopicServiceSelectField;
