import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  submission: {
    marginTop: theme.spacing(10),
  },
  formControl: {
    width: "100%",
    minWidth: theme.spacing(30),
  },
  form: {
    minWidth: theme.spacing(40),
    width: "100%",
    field: {
      marginTop: 0,
    },
  },
  formGroup: {
    flexDirection: "row",
  },
  icon: { marginRight: theme.spacing(1) },
  leftSpacing: { marginLeft: theme.spacing(2) },
}));
