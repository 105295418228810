import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import DialogForm from "../DialogForm";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import { Field, FieldProps } from "formik";
import { TextField } from "formik-material-ui";
import { AccountType, AccountTariff, useCreateAccountMutation } from "../../../generated/graphql";
import AccountParentSelect from "../../molecules/AccountParentSelect";
import AccountTariffSelect from "../../molecules/AccountTariffSelect";
import AccountTypeSelect from "../../molecules/AccountTypeSelect";
import SlateRTE, { initialFormValue } from "../../molecules/SlateRTE";

type TAccountCreateDialog = {
  open: boolean;
  toggle: () => void;
};

const AccountCreateDialog = ({ open, toggle }: TAccountCreateDialog) => {
  const { push } = useHistory();
  const [createAccount] = useCreateAccountMutation();
  const [parent, setParent] = useState();
  return (
    <DialogForm
      open={open}
      initialValues={{
        name: "",
        tariff: AccountTariff.premium,
        type: AccountType.eap,
        parentId: "",
        memo: "" || initialFormValue,
      }}
      onClose={toggle}
      title="Account anlegen"
      onSubmit={values => {
        return createAccount({
          variables: {
            accountInput: {
              ...values,
              parentId: values.parentId || null,
            },
          },
        }).then(({ data }) => {
          const id = data?.createAccount?.id;
          if (id) {
            push(`/account/${id}`);
            toggle();
          }
        });
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl margin="normal" fullWidth>
            <Field name="name" component={TextField} label="Account" />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <AccountParentSelect parent={parent} setParent={setParent}></AccountParentSelect>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AccountTypeSelect />
        </Grid>
        <Grid item xs={6}>
          <AccountTariffSelect />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field
            name="memo"
            render={({ form: { setFieldValue, values } }: FieldProps) => {
              return (
                <SlateRTE
                  value={values.memo}
                  handleChange={input => setFieldValue("memo", input)}
                  label="Internes Memo"
                  tabIndex={5}
                />
              );
            }}
          ></Field>
        </Grid>
      </Grid>
    </DialogForm>
  );
};

export default AccountCreateDialog;
