import React from "react";
import { FormikProps, FormikValues } from "formik";
import Typography from "@material-ui/core/Typography";
import PrimaryButton from "../../atoms/PrimaryButton";
import { Person } from "../../../generated/graphql";
import useStyles from "../reportStyles";
import WizardNavigation from "../../molecules/WizardNavigation";
import Box from "@material-ui/core/Box";
import SecondaryButton from "../../atoms/SecondaryButton";

export interface IFormProps {
  person: Person;
  backAction: () => void;
}

export default function StepAskForReview({
  backAction,
  handleSubmit,
  submitForm,
  setFieldValue,
  isSubmitting,
  person,
}: FormikProps<FormikValues> & IFormProps) {
  const classes = useStyles();
  const skip = () => {
    setFieldValue("skipReview", true);
    setTimeout(() => {
      submitForm();
    }, 100);
  };
  const proceed = () => {
    setFieldValue("skipReview", false);
    setTimeout(() => {
      submitForm();
    }, 100);
  };
  return (
    <form onSubmit={handleSubmit} className={classes.content}>
      <Typography variant="h1" className={classes.subHeadline}>
        Wir haben schon historische Daten von {person.name},<br /> möchtest du prüfen, ob sich an
        den Informationen etwas geändert hat?
      </Typography>
      <Box mt={6} display="flex">
        <Box mx={1}>
          <PrimaryButton disabled={isSubmitting} tabIndex={1} onClick={proceed}>
            Ja
          </PrimaryButton>
        </Box>
        <Box mx={1}>
          <PrimaryButton disabled={isSubmitting} tabIndex={1} onClick={skip}>
            Nein
          </PrimaryButton>
        </Box>
      </Box>
      <WizardNavigation>
        <SecondaryButton onClick={() => backAction()} tabIndex={2}>
          Zurück
        </SecondaryButton>
      </WizardNavigation>
    </form>
  );
}
