import React, { useEffect, useRef, useState, FC, useMemo } from "react";
import { Theme } from "@material-ui/core";

import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";

import DefaultDialog from "../../molecules/DefaultDialog";
import DefaultDialogContent from "../../molecules/DefaultDialogContent";
import useLocalStorage, { LocalStorage } from "../../../utils/localStorageHelper";
import { useUpdateExtensionMutation } from "../../../generated/graphql";
import { useUserFromPersonSubscription } from "../../../hooks/useUserSubscription";
import PrimaryButton from "../../atoms/PrimaryButton";

type TExtension = {
  personId: number;
};

const ExtensionDialog: FC<TExtension> = ({ personId }) => {
  const { getItem, setItem } = useLocalStorage(LocalStorage.session);
  const [open, setOpen] = useState(false);
  const [extension, setExtension] = useState<[string, string, string]>(["", "", ""]);
  const { user, loading } = useUserFromPersonSubscription(personId);
  const userId = user?.id;
  const userExtensions = useMemo(
    () =>
      user?.hotDesks?.map(ht => ht?.extension?.primeDN?.split("", 3) as [string, string, string]) ||
      [],
    [user?.hotDesks],
  );

  const showExtensionModal = getItem("showExtensionModal");

  useEffect(() => {
    if (!loading && showExtensionModal !== "false" && userId && !userExtensions.length) {
      setItem("showExtensionModal", "false");
      setOpen(true);
    }
  }, [showExtensionModal, userId, userExtensions, loading, setItem]);

  const [updateExtension] = useUpdateExtensionMutation();

  const submit = async (value: [string, string, string]) => {
    if (user?.id) {
      await updateExtension({
        variables: {
          id: user?.id,
          extension: value.join(""),
        },
      });

      setOpen(false);
    }
  };

  return (
    <DefaultDialog open={open}>
      <DefaultDialogContent>
        <Typography variant="h1">Deine heutige Nebenstelle</Typography>
        <Box my={2}>
          <ExtensionForm extension={extension} setExtension={setExtension} callback={submit} />
        </Box>
      </DefaultDialogContent>
    </DefaultDialog>
  );
};

type TExtensionDialogExtended = {
  open: boolean;
  close: () => void;
  personId: number;
};
export const ExtensionDialogExtended: FC<TExtensionDialogExtended> = ({
  open,
  personId,
  close,
}) => {
  const { user } = useUserFromPersonSubscription(personId);
  const [addIndex, setAddIndex] = useState(-1);
  const [newExtensions, setNewExtentions] = useState<[string, string, string][]>([]);
  const userExtensions = user?.hotDesks?.map(
    ht => ht?.extension?.primeDN?.split("", 3) as [string, string, string],
  );

  const addNew = () => {
    setAddIndex(addIndex + 1);
    const nextExtensions = [...newExtensions, ["", "", ""] as [string, string, string]];
    setNewExtentions(nextExtensions);
  };

  const setExtension = (values: [string, string, string], index: number) => {
    const extensions = newExtensions;
    extensions[index] = values;
    setNewExtentions(extensions);
  };

  const [updateExtension] = useUpdateExtensionMutation();

  const submit = async () => {
    newExtensions.forEach(async value => {
      const extension = value.join("");
      if (user?.id && extension.length === 3) {
        await updateExtension({
          variables: {
            id: user?.id,
            extension,
          },
        });
      }
    });

    close();
  };

  return (
    <DefaultDialog open={open} onClose={close}>
      <DefaultDialogContent>
        <Typography variant="h1">Deine heutige Nebenstelle</Typography>
        {userExtensions?.map((ext, index) => (
          <ExtensionForm
            key={`extisting-extension-${index}`}
            extension={ext}
            setExtension={() => {}}
            readOnly
          />
        ))}
        {newExtensions.map((extension, index) => (
          <ExtensionForm
            key={`extisting-new-${index}`}
            extension={extension}
            setExtension={values => setExtension(values, index)}
          />
        ))}
        <Box my={4}>
          <Link underline="always" variant="h4" color="primary" onClick={addNew}>
            weitere Nebenstelle angeben
          </Link>
        </Box>
        <PrimaryButton onClick={submit}>Ok</PrimaryButton>
      </DefaultDialogContent>
    </DefaultDialog>
  );
};

type TExtensionForm = {
  callback?: (values: any) => void;
  extension: [string, string, string];
  setExtension: (values: [string, string, string]) => void;
  readOnly?: boolean;
};

const ExtensionForm: FC<TExtensionForm> = ({ extension, setExtension, callback, readOnly }) => {
  const secondRef = useRef<HTMLInputElement>(null);
  const thirdRef = useRef<HTMLInputElement>(null);
  return (
    <Box display="flex" mt={3}>
      <SingleDigitInputField
        readOnly={readOnly}
        values={extension}
        index={0}
        onChange={values => {
          secondRef?.current?.focus();
          setExtension(values);
        }}
      />
      <SingleDigitInputField
        readOnly={readOnly}
        inputRef={secondRef}
        values={extension}
        index={1}
        onChange={values => {
          thirdRef?.current?.focus();
          setExtension(values);
        }}
      />
      <SingleDigitInputField
        readOnly={readOnly}
        inputRef={thirdRef}
        values={extension}
        index={2}
        onChange={values => {
          setExtension(values);
          if (values.join("").length === 3 && callback) {
            callback(values);
          }
        }}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      margin: "0 8px",
    },
    input: {
      ...theme.typography.h2,
      textAlign: "center",
    },
  };
});

type TSingleDigitInputField = {
  values: [string, string, string];
  index: number;
  onChange: (values: [string, string, string]) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
  readOnly?: boolean;
};

const SingleDigitInputField: FC<TSingleDigitInputField> = ({
  values,
  index,
  inputRef,
  onChange,
  readOnly,
}) => {
  const classes = useStyles();

  return readOnly ? (
    <TextField
      autoFocus={!inputRef}
      disabled={readOnly}
      value={values[index]}
      className={classes.root}
      inputRef={inputRef}
      inputProps={{ maxLength: 1, size: 1, className: classes.input }}
    />
  ) : (
    <TextField
      autoFocus={!inputRef}
      onChange={({ target: { value } }) => {
        if (value.length > 0) {
          values.splice(index, 1, value);
          onChange(values);
        }
      }}
      className={classes.root}
      inputRef={inputRef}
      inputProps={{ maxLength: 1, size: 1, className: classes.input }}
    />
  );
};

export default ExtensionDialog;
