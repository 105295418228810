import React, { useState } from "react";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import NoSsr from "@material-ui/core/NoSsr";
import CloseIcon from "@material-ui/icons/Close";
import Async from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";

import { ClientSearchDocument, ClientSearchQuery, Person } from "../../../../generated/graphql";
import { PersonTypes } from "../../../atoms/PersonTypeIcon";


import useStyles, { CustomComponents, OptionValue } from "../components";
import { Link } from "@material-ui/core";
import { useDebouncedSearchForCallDialog } from "../hooks";
import { useMeQuery } from "../../../../generated/graphql";

const formatData = (name: string, data?: ClientSearchQuery) => {
  const results = data?.clientSearch;

  if (!results)
    return [];
  return results.persons?.map(formatPerson) || [];
};

type TPersonSearchClient = {
  __typename?: "Person";
  id: number;
  name: string;
  age?: number | null;
  ageGuessed?: boolean | null;
  businesses?: Array<{
    __typename?: "BusinessObject";
    id?: number | null;
    name?: string | null;
  } | null> | null;
  client?: {
    __typename?: "Client";
    id?: number | null;
    account?: {
      __typename?: "Account";
      id?: number | null;
      name?: string | null;
      contractEnd?: any | null;
    } | null;
  } | null;
};

export const formatPerson = (person?: TPersonSearchClient | null): OptionValue => {
  if (!person) {
    // @ts-ignore
    return {};
  }

  let path = `/person/${person.id}`;
  if (!!person.client) {
    let label = person.name;
    if (person.age) {
      label = `${label}, ${person.ageGuessed ? "ca. " : ""}${person.age} J`;
    }
    return {
      type: PersonTypes.client,
      label: `${label}, ${person.client!.account?.name || "ohne Account"}`,
      name: `${person.name}`,
      path,
      value: person.id,
      expirationDate: person.client?.account?.contractEnd,
    };
  }

  let label = person.name || "";
  let type = PersonTypes.person;

  if (person.businesses) {
    const business = person.businesses[0];
    if (business) {
      type = PersonTypes.businessContact;
      label = `${label} (${business.name})`;
      path = `/business/${business.id}/contacts/${person.id}`;
    }
  }

  if (person.age) {
    label = `${label}, ${person.ageGuessed ? "ca. " : ""}${person.age} J`;
  }

  return {
    type,
    label,
    path,
    name: `${person.name}`,
    value: person.id,
  };
};

type TPersonSearch = {
  autoFocus?: boolean;
  onClick: (selection: any) => void;
  onCreateOption?: (inputValue: string) => void;
  person?: Person | null;
  placeholder?: string;
  dataHandler?: (data: any) => void;
  previewAction?: (data: any) => void;
};

const ClientForCallDialogSearch = ({
  autoFocus = true,
  onClick,
  person,
  onCreateOption,
  placeholder,
  dataHandler,
  previewAction
}: TPersonSearch) => {
  const classes = useStyles();
  const [value, setValue] = useState<OptionValue | null>(formatPerson(person));
  const { data: meData } = useMeQuery({ fetchPolicy: "cache-first" });

  const loadOptions = useDebouncedSearchForCallDialog(
    meData?.me?.user?.id,
    300,
    {
      query: ClientSearchDocument,
    },
    formatData,
    dataHandler
  );

  // @ts-ignore
  const formatOptionLabel = ({ value, label }) => {
    const isNew = label.includes('neu anlegen')
    return (
      <div onClick={(e: any) => {
        e.stopPropagation()
        e.preventDefault()
      }} style={{
        display: 'inline-flex',
        flexDirection: 'row',
        width: '600px',
      }}>
        <div style={{
          flex: 1,
        }}>{label}</div>
        <div style={{
          flex: 1,
          textAlign: 'end'
        }}>
          {!isNew &&
            (<div style={{
              display: 'inline-flex',
              flexDirection: 'row'
            }}>
              <Box marginLeft={2}>
                <Link
                  variant="h4"
                  underline="always"
                  color="primary"
                  onClick={(e: any) => {
                    if (value && previewAction) {
                      previewAction(value)
                    }
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                >
                  Vorschau
                </Link>
              </Box>
              <Box marginLeft={2}>
                <Link
                  variant="h4"
                  underline="always"
                  color="primary"
                  onClick={(e: any) => {
                    if (value) {
                      onClick(value)
                    }
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                >
                  Zuweisen
                </Link>
              </Box>
            </div>)
          }
        </div>
      </div>
    )
  };


  return (
    <Box position="relative">
      <NoSsr>
        {!!onCreateOption && (
          <AsyncCreatableSelect
            autoFocus
            classes={classes}
            components={{
              ...CustomComponents,
              DropdownIndicator: null,
              ClearIndicator: null,
            }}
            createOptionPosition={"first"}
            formatCreateLabel={(inputValue: string) => `“${inputValue}” neu anlegen`}
            formatOptionLabel={formatOptionLabel}
            loadOptions={loadOptions}
            onChange={(selection: any) => {
              if (selection) {
                // @ts-ignore
                //----------setValue(selection);
                // @ts-ignore
                //----------onClick(selection.value);                
              }
            }}
            onCreateOption={onCreateOption}
            placeholder={placeholder}
          />
        )}
        {!onCreateOption && (
          <Async
            // Force component to rerender if value changes
            key={String(value)}
            escapeClearsValue
            autoFocus={autoFocus}
            classes={classes}
            components={{
              ...CustomComponents,
              DropdownIndicator: null,
              ClearIndicator: null,
            }}
            isClearable
            loadOptions={loadOptions}
            // @ts-ignore
            onChange={selection => {
              if (selection) {
                // @ts-ignore
                setValue(selection);
                // @ts-ignore
                onClick(selection.value);
              }
            }}
            placeholder="Suche"
            value={!!value?.label ? value : undefined}
          />
        )}
      </NoSsr>
      {!!value?.label && (
        <Box
          position="absolute"
          right="0"
          top="2px"
          onClick={() => {
            setValue(null);
            onClick(-1);
          }}
        >
          <IconButton aria-label="delete" size="small">
            <CloseIcon fontSize="small" color="primary" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default ClientForCallDialogSearch;
