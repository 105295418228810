import Typography from "@material-ui/core/Typography";
import React, { FC } from "react";
import { PersonActivity, Topic } from "../../../../generated/graphql";
import useStyles from "../styles";

type TMandateBubble = {
  activity: PersonActivity;
  topic: Topic;
  isLink?: boolean;
};

export const MandateBubble: FC<TMandateBubble> = ({ activity, topic, isLink }) => {
  const topicName = topic.name;
  const classes = useStyles();
  return (
    <>
      <Typography variant="body2" className={`${isLink ? classes.clickableText : ""}`}>
        Service
      </Typography>
      <Typography variant="h4">{activity.description}</Typography>
      <Typography variant="body2">{topicName}</Typography>
    </>
  );
};

export default MandateBubble;
