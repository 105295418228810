import React, { MutableRefObject, useState } from "react";

import Box from "@material-ui/core/Box";
import MuLink from "@material-ui/core/Link";

import { useAuthorization } from "../../../providers/AuthorizationProvider";
import { EmailMessageContainer } from "../../molecules/EmailMessage";

import WriteAnswerInput from "./WriteAnswerInput";
import { TAvatarUser } from "../UserAvatar";

type TWriteAnswer = {
  endRef: MutableRefObject<any>;
  initialWriteMode?: boolean;
  onAbort?: () => any;
  signature?: string;
  onSubmit: (message: string) => Promise<any>;
};

const WriteAnswer = ({
  onAbort,
  onSubmit,
  endRef,
  signature,
  initialWriteMode = false,
}: TWriteAnswer) => {
  const { me } = useAuthorization();

  const [writeMode, setWriteMode] = useState(initialWriteMode);

  if (!me?.user) {
    return null;
  }

  const user: TAvatarUser = {
    id: me?.user?.id,
    papershiftWorkingAreas: me?.papershiftWorkingAreas,
    person: me,
  };

  return (
    <EmailMessageContainer dense={!writeMode} isClient={false} user={user}>
      {!writeMode ? (
        <Box textAlign="center">
          <MuLink
            color="primary"
            onClick={() => setWriteMode(true)}
            title="Antworten"
            underline="always"
            variant="h4"
          >
            Antworten
          </MuLink>
        </Box>
      ) : (
        <WriteAnswerInput
          onAbort={() => {
            !!onAbort ? onAbort() : setWriteMode(false);
          }}
          endRef={endRef}
          onSubmit={onSubmit}
          signature={signature}
        />
      )}
    </EmailMessageContainer>
  );
};

export default WriteAnswer;
