import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { FormControl } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { Field } from "formik";

import {
  Person,
  useGetDriveMetaDataQuery,
  useCreateDriveDocumentMutation,
} from "../../../generated/graphql";

import DialogForm from "../../forms/DialogForm";

const getFileId = (url: string) => {
  // matches e.g. https://drive.google.com/open?id=1N4qXK1Ow7ZAADojaoqK7M99uIObM0ZMrlVCyASDprpk
  let regex = /(?<=https:\/\/drive.google.com\/open\?id=)(.*)/;
  let hint = "Bitte kopiere den Link unter 'Link zum Freigeben abrufen'.";

  let match = url.match(regex);
  if (match === null) {
    // matches e.g.
    // - https://docs.google.com/document/d/1RWMK46lB2g6IpcTcSmEqoXS29Jsf8HFNkJD2ipJioMA/edit?usp=sharing
    // - https://drive.google.com/file/d/1YZUDPZ-zJgPmSX211UA-t182CqhIKK1w/view?usp=sharing
    regex = /(?<=google.com\/.+?(?<=d\/))(.+?(?=\/))/;
    match = url.match(regex);
    hint = "Bitte stelle sicher, dass du den korrekten Link kopiert hast.";
  }

  const fileId = !!match ? match[0] : null;

  const schema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .min(1, "Bitte gebe einen Titel an."),
    link: Yup.string()
      .trim()
      .matches(regex, hint)
      .required(hint),
  });

  return { schema, fileId, regex };
};

type TDocumentDialog = { open: boolean; onClose: () => void; person: Person };

export const DocumentDialog = ({ open, person, onClose }: TDocumentDialog) => {
  const [validationSchema, setValidationSchema] = useState<
    | Yup.ObjectSchema<
        Yup.Shape<
          object,
          {
            link: string;
            title: string;
          }
        >
      >
    | undefined
  >();
  const [initialValues, setInitialValues] = useState({
    fileId: "",
    title: "",
    link: "",
  });
  const { data } = useGetDriveMetaDataQuery({
    skip: initialValues.fileId === "",
    variables: {
      fileId: initialValues.fileId,
    },
  });
  const [createDriveDocument] = useCreateDriveDocumentMutation();
  const title = data?.getDriveMetaData;

  useEffect(() => {
    const read = async () => {
      try {
        const clipboard = await navigator.clipboard.readText();

        const { fileId, schema } = getFileId(clipboard);

        setValidationSchema(schema);

        if (fileId === null) {
          setInitialValues({
            title: "",
            link: "",
            fileId: "",
          });
          return;
        }

        setInitialValues({
          title: initialValues.title || "",
          link: clipboard,
          fileId,
        });
      } catch (e) {
        // clipboard not yet available
        setInitialValues({
          title: "",
          link: "",
          fileId: "",
        });
      }
    };

    if (!!navigator.clipboard) {
      read();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (!!title && title !== "") {
      setInitialValues({
        title,
        link: initialValues.link,
        fileId: initialValues.fileId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  return (
    <>
      <DialogForm
        isInitialValid={!!initialValues.link.length && !!initialValues.title.length}
        initialValues={initialValues}
        validationSchema={validationSchema}
        title="Dokument ablegen"
        open={open}
        onClose={() => {
          setInitialValues({
            fileId: "",
            title: "",
            link: "",
          });
          onClose();
        }}
        onSubmit={async (values: any, { setErrors }) => {
          const { fileId } = getFileId(values.link);

          const result = await createDriveDocument({
            variables: {
              createDocumentInput: {
                fileId,
                link: values.link,
                title: values.title,
                personId: person.id,
              },
            },
          });

          if (!result.data?.createDriveDocument) {
            setErrors({
              link: "Das Dokument existiert nicht",
            });
          } else {
            setInitialValues({
              fileId: "",
              title: "",
              link: "",
            });
            onClose();
          }
        }}
      >
        <FormControl fullWidth margin="normal">
          <Field label="Bezeichnung" name="title" component={TextField} tabIndex={1}></Field>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <Field label="Link" name="link" component={TextField} tabIndex={2}></Field>
        </FormControl>
      </DialogForm>
    </>
  );
};
export default DocumentDialog;
