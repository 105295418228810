import Link from "@material-ui/core/Link/Link";
import Typography from "@material-ui/core/Typography";
import React, { FC } from "react";
import { PersonActivity } from "../../../../generated/graphql";
import useStyles from "../styles";


type TDocumentBubble = {
  activity: PersonActivity;
  isLink?: boolean;
};

export const DocumentBubble: FC<TDocumentBubble> = ({ activity, isLink }) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="body2" className={`${isLink ? classes.clickableText : ""}`}>
        Dokument
      </Typography>
      {// Due to refactoring: https://medium.com/@calebmer/when-to-use-graphql-non-null-fields-4059337f6fc8
      activity.description && (
        <Link
          href={activity.description}
          target="_blanck"
          color="primary"
          underline="always"
          key={`activity.description`}
          variant="h4"
        >
          {activity.subtitle}
        </Link>
      )}
    </>
  );
};

export default DocumentBubble;
