import React, { useState } from "react";
import omit from "lodash/omit";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { pathOr } from "ramda";
import { Formik } from "formik";

import { ReadOnlyEditor } from "../../molecules/SlateRTE";

import AddressItem from "../../atoms/Address";
import DataColumn from "../../molecules/DataColumn";
import DataRow from "../../molecules/DataRow";
import Dialog from "../../molecules/DefaultDialog";
import DialogContent from "../../molecules/DefaultDialogContent";
import ElementContactDataForm from "../../forms/ElementContactDataForm";
import ElementBaseDataForm from "../../forms/ElementBaseDataForm";
import ElementNotesForm from "../../forms/ElementNotesForm";
import ElementInfoForm from "../../forms/ElementInfoForm";
import ElementStatusForm from "../../forms/ElementStatusForm";
import Markup from "../../atoms/Markup";
import StatusRow from "../../molecules/StatusRow";
import {
  ServiceResearchObject,
  Hour,
  useUpdateServiceResearchObjectMutation,
  Person,
  Address,
} from "../../../generated/graphql";
import { initialValuesHelper } from "../../../utils/initialValueHelper";
import stripTypenames from "../../../utils/stripTypenames";
import validation from "./validation";

import Hours from "./HoursField";
import HoursForm from "./HoursField/form";

interface IResearchElement {
  researchObject: ServiceResearchObject;
}

const ResearchElement = ({ researchObject }: IResearchElement) => {
  const [update] = useUpdateServiceResearchObjectMutation();

  const [hasSubmitError, setHasSubmitError] = useState(false);

  const [currentModal, setCurrentModal] = useState<string | null>(null);
  // TODO refactor me
  // warning be aware that not all values from research Object are submittable
  // and object.key = "" is falsey!!
  // use omit to delete keys from object
  const prepareValues = (values: ServiceResearchObject) => {
    let useValues = omit(values, ["businessObject.accountId", "service"]);

    if (useValues.businessObject?.phones?.length) {
      const phones = useValues.businessObject.phones.map(p => {
        return omit(p, ["readableNumber"]);
      });
      useValues.businessObject.phones = phones;
    }

    if (values.businessObject?.address && useValues.businessObject?.address) {
      useValues.businessObject.address = omit(values.businessObject.address, ["lat", "lon"]);
    }

    let contacts = (researchObject.businessObject?.persons as Person[]) || [];
    if (useValues.businessObject && useValues.businessObject.persons) {
      contacts = [...useValues.businessObject.persons] as Person[];
      useValues = omit(useValues, "businessObject.persons");
    }
    let contactPerson = researchObject.contactPerson || { temporary: true };
    const contactName =
      useValues.contactPerson && useValues.contactPerson.name && useValues.contactPerson.name.length
        ? useValues.contactPerson.name
        : false;
    if (!contactName) {
      return {
        ...useValues,
        contactPerson: {
          // @ts-ignore TOOD: Refactor!!!
          id: contactPerson.id,
          // @ts-ignore TOOD: Refactor!!!
          firstName: contactPerson.firstName,
          // @ts-ignore TOOD: Refactor!!!
          lastName: contactPerson.lastName,
          temporary: contactPerson.temporary,
        },
      };
    }
    if (useValues.contactPerson && useValues.contactPerson.id) {
      // remove generated name from submit
      return omit(useValues, [
        "contactPerson.salut",
        "contactPerson.name",
        "contactPerson.avatarKeys",
      ]);
    }

    const existingContact = contacts.find(p => !!p && !!p.name && p.name === contactName);
    contactPerson = existingContact || contactPerson;
    if (!existingContact) {
      const indexOfLastSpace = contactName.lastIndexOf(" ");
      const lastName =
        indexOfLastSpace === -1 ? contactName : contactName.substring(indexOfLastSpace).trim();
      const firstName = indexOfLastSpace === -1 ? "" : contactName.substring(0, indexOfLastSpace);
      const nameSearch = contacts.find(
        p =>
          !!p &&
          !!p.firstName &&
          !!p.lastName &&
          p.lastName === lastName &&
          p.firstName === firstName,
      );

      // @ts-ignore TOOD: Refactor!!!
      contactPerson = nameSearch ? nameSearch : { firstName, lastName };
    }
    // @ts-ignore TOOD: Refactor!!!
    contactPerson = omit(contactPerson, ["salut", "name", "avatarKeys"]);
    // @ts-ignore TOOD: Refactor!!!
    return { ...useValues, contactPerson };
  };

  const valueOrPlaceholder = (value: string) => (value.length ? value : "-");
  const name = researchObject.businessObject?.name || "-";
  const contact = researchObject.contactPerson?.name || "-";
  const notes = researchObject.note || "-";
  const info = researchObject.businessObject?.info || "-";
  const phone: string = valueOrPlaceholder(
    pathOr("-", ["businessObject", "phones", "0", "readableNumber"], researchObject),
  );
  const site = researchObject.businessObject?.url || "-";
  const email: string = valueOrPlaceholder(
    pathOr("-", ["businessObject", "email", "email"], researchObject),
  );

  const contacts = (researchObject.businessObject?.persons as Person[]) || [];
  const hours = (researchObject.businessObject?.hours || []) as Hour[];

  const address: Address | null = pathOr(null, ["businessObject", "address"], researchObject);

  async function handleSubmit(values: ServiceResearchObject) {
    const cleanValues = prepareValues(values);
    await update({
      variables: {
        serviceResearchObjectInput: stripTypenames(cleanValues, "__typename"),
        id: String(researchObject!.id!),
      },
    });
  }

  const currentValidation = () => {
    const val = validation.find((o: any) => o.name === currentModal);
    if (!val) {
      return null;
    }
    return val.schema;
  };

  return (
    <Grid item xs={7} xl={6} key={`serviceResearchObject-${researchObject.id}`}>
      <Box marginBottom={11} width={1}>
        <Grid container>
          <DataColumn xs={12}>
            <StatusRow
              researchObject={researchObject}
              toggle={() => setCurrentModal("Status")}
              key={`status${researchObject.id}`}
            ></StatusRow>
          </DataColumn>
          <DataRow title="Stammdaten" editTrigger={() => setCurrentModal("Stammdaten")}>
            <DataColumn label="Name">
              <Typography variant="body2" key={`name${researchObject.id}`}>
                {name}
              </Typography>
            </DataColumn>
            <DataColumn label="Ansprechpartner">
              <Typography variant="body2" key={`contact${researchObject.id}`}>
                {contact}
              </Typography>
            </DataColumn>
            <DataColumn label="Adresse">{address && <AddressItem address={address} />}</DataColumn>
          </DataRow>
          <DataRow title="Kontaktdaten" editTrigger={() => setCurrentModal("Kontaktdaten")}>
            <DataColumn label="Telefonnummer">
              <Typography variant="body2" key={`phone${researchObject.id}`}>
                {phone}
              </Typography>
            </DataColumn>
            <DataColumn label="E-Mail-Adresse">
              <Typography variant="body2" key={`email${researchObject.id}`}>
                {email}
              </Typography>
            </DataColumn>
            <DataColumn label="Webseite">
              {site.length > 1 ? (
                <Link
                  href={site}
                  target="_blanck"
                  color="primary"
                  underline="always"
                  key={`site${researchObject.id}`}
                  variant="h4"
                >
                  {site}
                </Link>
              ) : (
                site
              )}
            </DataColumn>
          </DataRow>
          <DataRow title="Öffnungszeiten" editTrigger={() => setCurrentModal("Öffnungszeiten")}>
            <Hours hours={hours} key={`hours${researchObject.id}`} />
          </DataRow>
          <DataRow title="Sonstiges" editTrigger={() => setCurrentModal("Sonstiges")}>
            <DataColumn xs={12}>
              <Markup value={info} key={`info${researchObject.id}`} />
            </DataColumn>
          </DataRow>
          <DataRow title="Meine Notizen" editTrigger={() => setCurrentModal("Meine Notizen")}>
            <DataColumn xs={12}>
              <ReadOnlyEditor value={notes} key={`note${researchObject.id}`} />
            </DataColumn>
          </DataRow>
        </Grid>
      </Box>
      <Dialog
        open={!!currentModal}
        onClose={() => {
          setHasSubmitError(false);
          setCurrentModal(null);
        }}
      >
        <DialogContent>
          <Box mb={3} display="flex" justifyContent="center">
            <Typography variant="h1">{currentModal}</Typography>
          </Box>
          <Formik
            onSubmit={async (values, { setSubmitting }) => {
              setHasSubmitError(false);
              setSubmitting(true);

              try {
                await handleSubmit(values);

                setCurrentModal(null);
              } catch (error) {
                console.error(error);
                setHasSubmitError(true);
              }

              setSubmitting(false);
            }}
            initialValues={initialValuesHelper(researchObject) as ServiceResearchObject}
            validationSchema={currentValidation()}
          >
            {props => {
              switch (currentModal) {
                case "Stammdaten":
                  return (
                    <ElementBaseDataForm
                      {...props}
                      cancel={() => setCurrentModal(null)}
                      contacts={contacts}
                    />
                  );
                case "Kontaktdaten":
                  return <ElementContactDataForm {...props} cancel={() => setCurrentModal(null)} />;
                case "Meine Notizen":
                  return <ElementNotesForm {...props} cancel={() => setCurrentModal(null)} />;
                case "Sonstiges":
                  return <ElementInfoForm {...props} cancel={() => setCurrentModal(null)} />;
                case "Öffnungszeiten":
                  return (
                    <HoursForm
                      {...props}
                      hours={hours}
                      autoSave={handleSubmit}
                      cancel={() => setCurrentModal(null)}
                    />
                  );
                case "Status":
                  return (
                    <ElementStatusForm
                      {...props}
                      hasSubmitError={hasSubmitError}
                      cancel={() => {
                        setHasSubmitError(false);
                        setCurrentModal(null);
                      }}
                    />
                  );
                default:
                  return <div></div>;
              }
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default ResearchElement;
