import React from "react";
import { Field, FormikProps, FormikValues } from "formik";
import { TextField } from "formik-material-ui";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import PrimaryButton from "../../atoms/PrimaryButton";
import { radiusDefaults } from "../../../utils/constants";
import WizardNavigation from "../../molecules/WizardNavigation";
import SecondaryButton from "../../atoms/SecondaryButton";
import useStyles from "./styles";

interface TAddressRadiusForm {
  cancel: (show: boolean) => void;
  showCancel: boolean;
  backAction: () => void;
}

export const AddressRadiusForm = ({
  handleSubmit,
  backAction,
  isSubmitting,
  cancel,
  values,
  setFieldValue,
  showCancel,
  isValid,
}: TAddressRadiusForm & FormikProps<FormikValues>) => {
  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <Field component={TextField} label="Straße und Hausnummer" name="street" tabIndex={1} />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormControl fullWidth margin="normal">
            <Field component={TextField} label="Postleitzahl" name="zip" tabIndex={2} />
          </FormControl>
        </Grid>
        <Grid item xs={9}>
          <FormControl fullWidth margin="normal">
            <Field component={TextField} label="Stadt" name="city" tabIndex={3} />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl component="fieldset" fullWidth margin="normal">
            <RadioGroup
              aria-label="radius"
              name="radius"
              value={values.radius}
              className={classes.radioGroup}
              onChange={e => {
                setFieldValue("radius", parseInt(e.target.value));
              }}
            >
              {radiusDefaults.map(value => {
                if (value === 10000) {
                  return (
                    <FormControlLabel
                      tabIndex={4}
                      key="noRadius"
                      value={10000}
                      control={<Radio />}
                      label="Ohne Einschränkung"
                      labelPlacement="top"
                    />
                  );
                }
                return (
                  <FormControlLabel
                    tabIndex={4}
                    key={value}
                    value={value}
                    control={<Radio />}
                    label={`${value}km`}
                    labelPlacement="top"
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <WizardNavigation>
        {!showCancel && (
          <SecondaryButton onClick={() => backAction()} tabIndex={6}>
            Zurück
          </SecondaryButton>
        )}
        {showCancel && (
          <SecondaryButton onClick={() => cancel(false)} tabIndex={5}>
            Zur Auswahl
          </SecondaryButton>
        )}
        <PrimaryButton type="submit" disabled={isSubmitting || !isValid}>
          Anlegen
        </PrimaryButton>
      </WizardNavigation>
    </form>
  );
};

export default AddressRadiusForm;
