import { CircularProgress, FormControl, InputAdornment } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import MuLink from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import dayjs from "dayjs";
import { Field, Formik, FormikActions, FormikValues, yupToFormErrors } from "formik";
import { TextField } from "formik-material-ui";
import React, { FC, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router";
import {
  ClientType,
  Gender,
  useAddClientMutation,
  useFinishWpoAssignmentProcessMutation,
  usePersonQuery,
} from "../../../generated/graphql";
import { useEmailThreadContext } from "../../../providers/Email";
import Loader from "../../atoms/Loading";
import PrimaryButton from "../../atoms/PrimaryButton";
import AsyncSearch from "../../molecules/AsyncSearch";
import { useTransferWpoClientContext } from "../TransferWpoClientDialog/provider";
import { STEPS } from "../TransferWpoClientDialog/reducer";
import illustration from "./arbeiten.svg";
import Form from "./form";
import validationSchema from "./validation";
import { useQuery } from "@apollo/client";
import { clientByUcmsId } from "../../../queries/client";
import SecondaryButton from "../../atoms/SecondaryButton";
import useStyles from "./styles";
import * as Yup from "yup";
// import { useStyles } from "./styles";

const AssignClientModal = () => {
  const { step } = useTransferWpoClientContext();
  switch (step) {
    case STEPS.UCMS:
      return <UcmsSearchFrom />;
    case STEPS.INIT:
      return <TransferWpoClientInit />;
    case STEPS.DETAILS:
      return <TransferWpoClientDetails />;
    case STEPS.ANONYMOUS:
      return <></>;
    case STEPS.CONFIRM:
      return <TransferWpoClientConfirm />;
  }
};

const UcmsSearchFrom: FC = () => {
  const styles = useStyles();
  const { setStep, setUcmsId, setClientId, setPersonId } = useTransferWpoClientContext();
  const [ucmsIdFromEmail, setUcmsIdFromEmail] = useState("");
  const [selectedUcmsId, setSelectedUcmsId] = useState("");
  const { emails } = useEmailThreadContext();
  const { data, loading, error } = useQuery(clientByUcmsId, {
    variables: { ucmsId: +selectedUcmsId },
    skip: !selectedUcmsId,
  });
  useEffect(() => {
    const tryToExtractUcmsIdFromEmail = () => {
      if (emails?.length) {
        const email = emails[0];
        const result = email.body?.match(/#\d{5,10}/g);
        if (result?.length == 1) {
          setUcmsIdFromEmail(result.toString().replace(/\D/g, ""));
        }
      }
    };

    tryToExtractUcmsIdFromEmail();
  }, []);

  useEffect(() => {
    if (error) {
      setUcmsId(+selectedUcmsId);
      setStep(STEPS.INIT);
    }
  }, [error]);

  return (
    <>
      <Box mb={3} textAlign="center">
        <Typography variant="h1">WPO Vorgang zuweisen</Typography>
      </Box>
      <Typography variant="body2" align="center">
        Bitte zuerst die Case ID eingeben.
      </Typography>
      <Formik
        initialValues={{ ucmsId: ucmsIdFromEmail }}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({ ucmsId: Yup.number().typeError('Es sind nur Zahleneingaben erlaubt.') })}
        onSubmit={(values, { setSubmitting }) => {
          setSelectedUcmsId(values.ucmsId);
          setSubmitting(false);
        }}
      >
        {form => (
          <>
            <Box width={1} marginBottom={2} marginTop={6}>
              <form onSubmit={form.handleSubmit} className={styles.ucmsForm}>
                <FormControl margin="normal" fullWidth>
                  <Field
                    name="ucmsId"
                    component={TextField}
                    label="Case ID"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">#</InputAdornment>,
                    }}
                  />
                </FormControl>
                <SecondaryButton type="submit">Klienten suchen</SecondaryButton>
              </form>
            </Box>
            {loading && <Loader />}
            {data && form.values.ucmsId === selectedUcmsId && (
              <Box mb={3} textAlign="left" width={1}>
                <Box display="flex" marginBottom={4} flexDirection="column">
                  <Typography variant="body2">
                    Die Case ID ist für folgenden Klienten hinterlegt:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {data.clientByUcmsId.person?.firstName} {data.clientByUcmsId.person?.lastName} (
                    {data.clientByUcmsId.account?.name || "-"})
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <SecondaryButton
                    type="button"
                    onClick={() => {
                      setUcmsId(+selectedUcmsId);
                      setStep(STEPS.INIT);
                    }}
                  >
                    Einen anderen Klienten auswählen/erstellen
                  </SecondaryButton>
                  <PrimaryButton
                    style={{ marginLeft: "1rem" }}
                    onClick={() => {
                      setUcmsId(+selectedUcmsId);
                      setClientId(data.clientByUcmsId?.id);
                      setPersonId(data.clientByUcmsId?.person?.id);
                      setStep(STEPS.CONFIRM);
                    }}
                  >
                    Mit diesem Klienten fortfahren
                  </PrimaryButton>
                </Box>
              </Box>
            )}
          </>
        )}
      </Formik>
    </>
  );
};

const TransferWpoClientInit: FC = () => {
  const { setStep, setPersonId, ucmsId, setPersonName } = useTransferWpoClientContext();
  return (
    <>
      <Box mb={3} textAlign="center">
        <Typography variant="h1">WPO Vorgang zuweisen</Typography>
      </Box>
      <Typography variant="body2" align="center">
        Wähle jetzt eine Person aus der Vorschlagsliste, oder benutze die Suche.
      </Typography>
      <Typography variant="body2" align="center">
        Du bist dir nicht sicher? Dann nutze die Vorschau der Klienten-Detailseite zur
        Identifikation.
      </Typography>
      <Typography variant="body2" color="secondary" align="center">
        (Case ID: {ucmsId})
      </Typography>
      <Box width={1} marginBottom={2} marginTop={6}>
        <AsyncSearch.PersonWithClientSearch
          placeholder="Suche nach Vorname Nachname"
          onClick={value => {
            setPersonId(value);
            setStep(STEPS.CONFIRM);
          }}
          onCreateOption={async (name: string) => {
            setPersonName(name);
            setStep(STEPS.DETAILS);
          }} // uncomment this to enable placeholder text
        />
      </Box>
      {/* <Box mb={3} textAlign="left" width={1}>
        <Typography variant="body2">
          Die Person möchte anonym bleiben.{" "}
          <MuLink
            color="primary"
            component="button"
            title={""}
            underline="always"
            variant="body2"
            onClick={() => {              
              setStep(STEPS.DETAILS);
            }}
          >
            Erzeuge hier ein Alias.
          </MuLink>
        </Typography>
      </Box> */}
    </>
  );
};

const TransferWpoClientDetails = () => {
  const [accountId, setAccountId] = useState("10");
  const [addClient] = useAddClientMutation();
  const { setStep, setPersonId, personName } = useTransferWpoClientContext();

  function handleAccountChange(option: any) {
    setAccountId(option);
  }

  const [lastName, ...rest] = personName.split(" ").reverse();
  const firstName = rest.reverse().join(" ");

  const onSubmit = async (values: FormikValues, { setSubmitting }: FormikActions<FormikValues>) => {
    const {
      ageGuessed,
      initialAge,
      birthDate,
      countryCode,
      areaCode,
      number,
      account,
      clientType,
      ...person
    } = values;
    const phones = areaCode && number ? [{ areaCode, number, countryCode }] : [];
    try {
      const { data } = await addClient({
        variables: {
          client: {
            accountId: parseInt(account.value, 10),
            clientType,
            ucmsId: parseInt(values.ucmsId, 10),
            person: {
              alias: person.alias,
              firstName: person.firstName,
              lastName: person.lastName,
              gender: person.gender,
              phones,
              ageGuessed,
              initialAge,
              birthDate,
            },
          },
        },
      });
      if (data?.addClient?.person?.id) {
        setPersonId(data?.addClient?.person?.id);
        setStep(STEPS.CONFIRM);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <Formik
      initialValues={{
        firstName: firstName,
        lastName: lastName,
        //countryCode: "49",
        areaCode: "49",
        clientType: ClientType.unknown,
        number: "",
        account: { label: "Bitte auswählen", value: null },
        gender: Gender.unknown,
        ageGuessed: true,
        initialAge: "",
        alias: "",
        birthDate: dayjs(),
        ucmsId: "",
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {props => {
        return (
          <Form
            {...props}
            handleAccountChange={handleAccountChange}
            accountId={accountId}
            accounts={[]}
          />
        );
      }}
    </Formik>
  );
};

const TransferWpoClientConfirm: FC = () => {
  const {
    createTask,
    automaticConfirmation,
    setAutomaticConfirmation,
    setCreateTask,
    personId,
    onClose,
    ucmsId,
  } = useTransferWpoClientContext();
  const [messageId, setMessageId] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const { emails } = useEmailThreadContext();
  const { push } = useHistory();
  useEffect(() => {
    if (emails && emails.length > 0) {
      const email = emails[0];
      setMessageId(email.messageId || "");
    }
  }, []);

  const [finishAssignmentProcess] = useFinishWpoAssignmentProcessMutation();
  const { data, loading } = usePersonQuery({
    variables: { id: 1 },
  });
  if (!1 || loading) {
    return <Loader />;
  }

  return (
    <>
      <Box mb={3} textAlign="center">
        <Typography variant="h1">
          Du hast die WPO E-Mail {data?.person.firstName} {data?.person.lastName} zugewiesen.
        </Typography>
      </Box>
      <img src={illustration} alt="" />

      <Box marginTop={4} maxWidth={500} width={1} display="flex" justifyContent="flex-start">
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={automaticConfirmation}
                onChange={() => setAutomaticConfirmation(!automaticConfirmation)}
                name="checkedA"
              />
            }
            label="automatisch eine Bestätigungs-E-Mail an WPO verschicken"
          />
        </FormGroup>
      </Box>
      <Box maxWidth={500} width={1} display="flex" justifyContent="flex-start">
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={createTask}
                onChange={() => setCreateTask(!createTask)}
                name="checkedA"
              />
            }
            label="eine Aufgabe erstellen, damit der Klient kontaktiert wird"
          />
        </FormGroup>
      </Box>
      <Box display="flex" justifyContent="center" mt={3}>
        <PrimaryButton
          onClick={async () => {
            setIsDisabled(true)
            await finishAssignmentProcess({
              variables: {
                personId,
                messageId,
                ucmsId,
                shouldCreateTask: createTask,
                shouldSendWpoEmail: automaticConfirmation,
              },
            }).then(() => {                            
            }).finally(() => {              
                const redirectUrl = `/person/${personId}/email/${messageId}`                
                push(redirectUrl);  
                onClose();
              
            })
          }}
          type="submit"
          disabled={isDisabled}
          tabIndex={10}
        >
          {!false && "OK"}
          {false && (
            <Box ml={3} display="flex" alignItems="center">
              <CircularProgress size={24} />
            </Box>
          )}
        </PrimaryButton>
      </Box>
    </>
  );
};

export default AssignClientModal;
