import React from "react";
import { FormikProps, FormikValues } from "formik";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import ArrowForward from "@material-ui/icons/ArrowForwardIos";
import PrimaryButton from "../../atoms/PrimaryButton";
import { Person, Service } from "../../../generated/graphql";
import useStyles from "../reportStyles";
import WizardNavigation from "../../molecules/WizardNavigation";
import SecondaryButton from "../../atoms/SecondaryButton";

export interface IFormProps {
  person: Person;
  reportId: number;
  saveInput: (params: any) => any;
  services: Service[];
  backAction: () => void;
}

export default function StepCheckServices({
  backAction,
  handleSubmit,
  isSubmitting,
  person,
  reportId,
  saveInput,
  services,
  values,
}: FormikProps<FormikValues> & IFormProps) {
  const { push } = useHistory();
  const classes = useStyles();
  const handleClick = (service: any) => {
    localStorage.setItem("activeReport", `${reportId}`);
    saveInput({ connectedServices: [...values.connectedServices, service.id] });
    push(`/person/${person.id}/service/${service.id}/setup`);
  };
  return (
    <form onSubmit={handleSubmit} className={classes.content}>
      <div className={classes.list}>
        {services &&
          services.length &&
          services.map(
            (service: Service, idx: number) =>
              service && (
                <div
                  key={`reviewable-service-list-${idx}`}
                  onClick={() => handleClick(service)}
                  className={classes.listItem}
                  tabIndex={idx + 1}
                >
                  <Typography variant="body2">
                    Service {service.serviceMeta && service.serviceMeta.name} bearbeiten
                  </Typography>
                  <ArrowForward color="primary"></ArrowForward>
                </div>
              ),
          )}
      </div>
      <WizardNavigation>
        <SecondaryButton onClick={() => backAction()} tabIndex={services ? services.length + 2 : 2}>
          Zurück
        </SecondaryButton>
        <PrimaryButton
          type="submit"
          disabled={isSubmitting}
          tabIndex={services ? services.length + 1 : 1}
        >
          Alles korrekt, ohne Bearbeitung fortfahren.
        </PrimaryButton>
      </WizardNavigation>
    </form>
  );
}
