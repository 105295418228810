import React from "react";
import { useMutation } from "@apollo/client";
import { TextField } from "formik-material-ui";
import Box from "@material-ui/core/Box";
import {
  Service,
  Person,
  CreateServiceAttributeDocument,
  UpdateServiceAttributeDocument,
  ServiceAttribute,
} from "../../../../generated/graphql";

import { Formik, Field } from "formik";
import useStyles from "../styles";
import PrimaryButton from "../../../atoms/PrimaryButton";
import { pathOr } from "ramda";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import WizardNavigation from "../../../molecules/WizardNavigation";
import SecondaryButton from "../../../atoms/SecondaryButton";

interface Props {
  backAction: () => void;
  service: Service;
  person: Person;
  serviceMetaAttribute: any;
  setStep: (step: number) => void;
  step: number;
  update: () => void;
}

export default function NumberSelector({
  serviceMetaAttribute,
  service,
  setStep,
  step,
  update,
  backAction,
}: Props) {
  const classes = useStyles();
  const [createServiceAttribute] = useMutation(CreateServiceAttributeDocument);
  const [updateServiceAttribute] = useMutation(UpdateServiceAttributeDocument);
  const next = () => {
    setStep(step + 1);
  };
  let attributes: ServiceAttribute[] = pathOr([], ["serviceAttributes"], service);
  const attribute = attributes.find(
    attr => attr.serviceMetaAttributeId === serviceMetaAttribute.id,
  );

  const initialValue = serviceMetaAttribute?.type === "currency" ? 15 : undefined;
  const initialValues = {
    value: !!attribute && attribute.value ? parseFloat(attribute.value) : initialValue,
  };

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            if (!!attribute) {
              updateServiceAttribute({
                variables: {
                  serviceAttribute: {
                    value: `${values.value}`,
                    id: attribute.id,
                    serviceId: service.id,
                  },
                },
              }).then(() => {
                setSubmitting(false);
                try {
                  update();
                } catch (error) {
                  console.log(error);
                }
              });
            } else {
              createServiceAttribute({
                variables: {
                  serviceAttribute: {
                    serviceMetaAttributeId: serviceMetaAttribute.id,
                    value: `${values.value}`,
                    serviceId: service.id,
                  },
                },
              }).then(() => {
                setSubmitting(false);
                try {
                  update();
                } catch (error) {
                  console.log(error);
                }
              });
            }
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} onBlur={handleSubmit}>
              <FormControl className={classes.formControl}>
                <Field
                  name="value"
                  component={TextField}
                  onKeyPress={(event: any) => {
                    // TOOD: Better to support 'float' and 'int' fields in services?
                    // Allow only integers for 'referenceNumber'
                    if (
                      attribute?.serviceMetaAttribute?.name === "referenceNumber" &&
                      ["e", ",", ".", "-"].some(key => key === event.key)
                    ) {
                      event.preventDefault();
                    }
                  }}
                  InputProps={{
                    endAdornment:
                      serviceMetaAttribute.type === "currency" ? (
                        <InputAdornment position="end">€</InputAdornment>
                      ) : null,
                  }}
                  type="number"
                  label={serviceMetaAttribute.label}
                  tabIndex={1}
                />
              </FormControl>
            </form>
          )}
        </Formik>
      </Box>
      <WizardNavigation>
        <SecondaryButton onClick={() => backAction()} tabIndex={3}>
          Zurück
        </SecondaryButton>
        <PrimaryButton
          disabled={serviceMetaAttribute.required && !attribute}
          onClick={next}
          tabIndex={2}
        >
          Weiter
        </PrimaryButton>
      </WizardNavigation>
    </>
  );
}
