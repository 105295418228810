import React from "react";
import { Typography, Grid, Box } from "@material-ui/core";

import PrimaryButton from "../../atoms/PrimaryButton";
import DataColumn from "../DataColumn";
import DataRow from "../DataRow";

interface IItemListHeader {
  title: string;
  btnLabel?: string;
  countLabel?: string;
  onClick?: () => void;
  count?: number;
}

const ItemListHeader = ({ title, btnLabel, countLabel, onClick, count }: IItemListHeader) => {
  return (
    <Box mb={3}>
      <DataRow title={title} defaultIcon justify="space-between">
        <DataColumn label={countLabel}>
          {count !== undefined ? <Typography variant="body2">{count}</Typography> : null}
        </DataColumn>

        <Grid item>
          {btnLabel !== undefined && onClick !== undefined ? (
            <Box mt={1} mb={2}>
              <PrimaryButton onClick={onClick}>{btnLabel}</PrimaryButton>
            </Box>
          ) : null}
        </Grid>
      </DataRow>
    </Box>
  );
};

export default ItemListHeader;
