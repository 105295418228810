import { useEffect } from "react";
import {
  useActiveChatsQuery,
  ActiveChatsSubscriptionSubscription,
  ActiveChatsSubscriptionDocument,
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

export const useActiveChatsSubscription = () => {
  const { data, loading, subscribeToMore } = useActiveChatsQuery({
    fetchPolicy,
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore<ActiveChatsSubscriptionSubscription>({
      document: ActiveChatsSubscriptionDocument,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) {
          return prev;
        }

        return { activeChats: subscriptionData.data.activeChatsSubscription };
      },
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, loading };
};
