import React, { FC } from "react";

import dayjs from "dayjs";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { Appointment, User, Person } from "../../../../generated/graphql";

import Add from "./add";
import Edit from "./edit";
import { TAvatarUser } from "../../UserAvatar";

interface IAppointmentDialogEdit {
  allowEditUsers: boolean;
  appointment?: Appointment;
  person?: Person;
  currentTime: dayjs.Dayjs;
  minEndsAt: dayjs.Dayjs;
  minStartsAt: dayjs.Dayjs;
  onClose: () => void;
  refetch: () => void;
  users: Array<User | TAvatarUser>;
}

const AppointmentDialogEdit: FC<IAppointmentDialogEdit> = ({
  allowEditUsers,
  appointment,
  person,
  currentTime,
  minEndsAt,
  minStartsAt,
  onClose,
  refetch,
  users,
}) => {
  return (
    <Box width="100%">
      <Box>
        <Typography variant="h1" align="center">
          Termin {!!appointment ? "bearbeiten" : "anlegen"}
        </Typography>
      </Box>
      {!appointment && (
        <Add
          allowEditUsers={allowEditUsers}
          person={person}
          currentTime={currentTime}
          minEndsAt={minEndsAt}
          minStartsAt={minStartsAt}
          onClose={onClose}
          users={users}
        />
      )}
      {appointment && (
        <Edit
          allowEditUsers={allowEditUsers}
          appointment={appointment}
          minEndsAt={minEndsAt}
          minStartsAt={minStartsAt}
          onClose={onClose}
          refetch={refetch}
          users={users}
        />
      )}
    </Box>
  );
};
export default AppointmentDialogEdit;
