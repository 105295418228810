import React from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Phone } from "@material-ui/icons";

import { BusinessObject, Person } from "../../../generated/graphql";
import ContactIcon from "../../assets/ContactIcon";
import ClickBox from "../../atoms/ClickBox";
import CallBox from "../CallBox";
import Loader from "../../atoms/Loading";
import { usePersonsByBusinessSubscription } from "../../../hooks/usePersonsByBusinessSubscription";
import { orderBy } from "lodash";
import { useHistory } from "react-router";

type TContactPersonCard = { 
  person: Person,
  business? : BusinessObject
 };

const ContactPersonCard = ({ person, business }: TContactPersonCard) => {
  const { push } = useHistory();
  let url: any = null;
  if(business) {    
    url = `/business/${business.id}/contacts`;
  }
  //const { persons, loading: loadingPersons } = usePersonsByBusinessSubscription(parseInt(id!, 10));
  // const { persons, loading: loadingPersons } = usePersonsByBusinessSubscription(5);
  // if (loadingPersons) {
  //   return <></>;
  // }
  // const filteredSet = persons.filter(p => !p.temporary);
  return (
    <Grid container component={Box} mb={2}>
      <Grid item xs={6}>
        <ClickBox
          handleClick={() => {
            if(url) {
              push(url);
            }
          }}
          icon={
            <Box mr={2}>
              <Phone color="primary" />
            </Box>
          }
        >
          <Box width="100%" height={52} display="flex" alignItems="center" my={1}>
            <Box marginLeft={2} marginRight={4} height={39}>
              <ContactIcon />
            </Box>
            <Box>
              <Typography variant="h4" color="textSecondary" gutterBottom>
                Ansprechpartner
              </Typography>
              <Typography variant="body2">{person.name}</Typography>
            </Box>
          </Box>
          {/* <Box>
            {filteredSet &&
              orderBy(filteredSet, ["lastName", "firstName", "name"], "asc").map(contact => {
                return <CallBox contact={contact} />;
              })}
          </Box> */}
        </ClickBox>
      </Grid>
    </Grid>
  );
};

export default ContactPersonCard;
