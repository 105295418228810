import { useEffect } from "react";
import dayjs from "dayjs";
import unionBy from "lodash/unionBy";
import { fetchPolicy } from "../utils/constants";
import {
  TasksQueryVariables,
  useTasksQuery,
  Task,
  TaskSubscriptionSubscription,
  TaskSubscriptionDocument,
} from "../generated/graphql";

export const useTasksSubscription = (variables: TasksQueryVariables) => {
  const { data, loading, subscribeToMore } = useTasksQuery({ variables, fetchPolicy });

  useEffect(() => {
    const unsubscribe = subscribeToMore<TaskSubscriptionSubscription>({
      document: TaskSubscriptionDocument,
      updateQuery: (prev, { subscriptionData }) => {
        const taskSubscription = subscriptionData?.data?.taskSubscription;
        if (!taskSubscription || !prev) return prev;

        let showTask = !taskSubscription.finishedAt;

        if (variables.duedate) {
          showTask =
            showTask &&
            (!taskSubscription.dueDate ||
              dayjs(taskSubscription.dueDate).isBefore(dayjs(variables.duedate)));
        }

        if (variables.pool) {
          showTask = showTask && !taskSubscription.author;
        }

        if (taskSubscription.snoozeUntil) {
          showTask = variables.withSnoozed
            ? showTask
            : showTask && dayjs(taskSubscription.snoozeUntil).isBefore(dayjs());
        }

        if (!showTask) {
          return Object.assign({}, prev, {
            // @ts-ignore
            tasks: [...prev.tasks.filter((task: Task) => task.id !== taskSubscription.id)],
          });
        } else {
          return Object.assign({}, prev, {
            tasks: unionBy([taskSubscription], prev.tasks, "id"),
          });
        }
      },
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables]);

  return { tasks: (data?.tasks || []) as Task[], loading };
};
