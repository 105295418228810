import React, { useEffect, useReducer, useState } from "react";

import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import relativeTime from "dayjs/plugin/relativeTime";

import {
  Account,
  useUpdatePersonMutation,
  useSetAnonymousPersonMutation,
  useAssignPersonMutation,
} from "../../../generated/graphql";
import Loading from "../../atoms/Loading";
import DefaultDialogContent from "../../molecules/DefaultDialogContent";
import DefaultDialog from "../../molecules/DefaultDialog";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";

import { PersonCreateContext, usePersonCreateContext } from "./provider";
import {
  STEPS,
  reducer,
  initialState,
  SET_STEP,
  TYPES,
  SET_TYPE,
  SET_ACCOUNT,
  SET_NAME,
  RESET_ACCOUNT,
  SET_ASSISTANCE_ID,
} from "./reducer";
import useStyles from "./styles";
import AssistanceAccountAssign from "./AssistanceAccountAssign";
import AccountConfirm from "./AccountConfirm";
import PersonAssignMatch from "./PersonAssignMatch";
import PersonAssignNoMatch from "./PersonAssignNoMatch";
import AccountAssign from "./AccountAssign";
import Counterpart from "./Counterpart";
import usePersonActivitySubscription from "../../../hooks/usePersonActivitySubscription";
import BusinessAssign from "./BusinessAssign";
import PersonPreview from "../../pages/PersonPreview";
import { Link } from "@material-ui/core";

dayjs.extend(relativeTime);

interface ICallDialog {
  activityId?: number;
  open: boolean;
  handleClose: () => void;
  pushTarget?: (personId: number) => string;
}

const CallDialog = ({ activityId, handleClose, open, pushTarget }: ICallDialog) => {
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showPersonPreview, setShowPersonPreview] = useState(false);
  const [previewPersonId, setPreviewPersonId] = useState("");
  const [resultData, setResultData] = useState([]);
  const [isPreviousHidden, setIsPreviousHidden] = useState(false);
  const [isNextHidden, setIsNextHidden] = useState(false);
  const { loading, personActivity, refetch } = usePersonActivitySubscription(activityId);

  const [assignPerson] = useAssignPersonMutation();
  const { push } = useHistory();


  useEffect(() => {
    if (previewPersonId != '') {
      setShowPersonPreview(true)
    }
  }, [previewPersonId]);

  // useEffect(() => {
  //   setIsNextHidden()
  // }, [resultData]);

  if (loading) {
    return (
      <DefaultDialog
        disableEscapeKeyDown
        open={open}
        maxWidth="md"
        // fullWidth
        PaperProps={{ classes: { root: classes.dialog } }}
      >
        <DefaultDialogContent>
          <Loading />
        </DefaultDialogContent>
      </DefaultDialog>
    );
  }

  if (!personActivity) {
    return null;
  }

  return (
    <DefaultDialog
      disableEscapeKeyDown
      open={open}
      maxWidth={showPersonPreview ? "xl" : "md"}
      // fullWidth
      PaperProps={{ classes: { root: classes.dialog } }}
    >
      <PersonCreateContext.Provider
        value={{
          ...state,
          personActivity,
          refetch,
          pushTarget,
          setStep: (step: STEPS) => {
            dispatch({ type: SET_STEP, payload: step });
          },
          setType: (type: TYPES) => {
            dispatch({ type: SET_TYPE, payload: type });
          },
          setAccount: (account: Account) => {
            dispatch({ type: SET_ACCOUNT, payload: account });
          },
          setName: (name: string) => {
            dispatch({ type: SET_NAME, payload: name });
          },
          setAssistanceId: (assistanceId: string) => {
            dispatch({ type: SET_ASSISTANCE_ID, payload: assistanceId });
          },
          resetAccount: () => {
            dispatch({ type: RESET_ACCOUNT });
          },
        }}
      >
        {!showPersonPreview &&
          <CallDialogContent handleClose={handleClose} open={open} pushTarget={pushTarget} dataHandler={setResultData}
            previewAction={setPreviewPersonId} />
        }
        {showPersonPreview ?
          <div>
            <div className={classes.previewOverlay}>
              <div className={classes.personPreviewContainer}>
                <PersonPreview id={previewPersonId} />
              </div>
            </div>            
            <div className={classes.clientSelectorContainer}>
              <div className={classes.clientSelector}>
                <Box marginLeft={2}>
                  <Link
                    variant="h4"
                    underline="always"
                    color="primary"
                    onClick={() => {
                      setShowPersonPreview(false)
                      setPreviewPersonId("")
                    }}
                  >
                    Zurück zum Anrufdialog
                  </Link>
                </Box>

                <Box className={isPreviousHidden ? classes.disabledLink : classes.enabledLink} marginLeft={2}>
                  <Link
                    variant="h4"
                    underline="always"
                    color="primary"
                    onClick={() => {
                      if (previewPersonId != '') {
                        const personIds = resultData.map((e: any) => e.id)
                        const targetIndex = personIds.indexOf(previewPersonId)
                        if (targetIndex !== -1 && targetIndex > 0) {
                          setPreviewPersonId(personIds[targetIndex - 1])
                          setIsNextHidden(false)
                          if (targetIndex === 1) {
                            setIsPreviousHidden(true)
                          }
                        } else {
                          setIsPreviousHidden(true)
                        }
                      }
                    }}
                  >
                    Vorherige Vorschau
                  </Link>
                </Box>

                <PrimaryButton onClick={async () => {
                  if (previewPersonId != '') {
                    const personId = parseInt(previewPersonId, 10)
                    await assignPerson({
                      variables: {
                        id: personActivity.id,
                        personId
                      },
                    });
                    handleClose();
                    if (pushTarget) {
                      const pathname = pushTarget(personId);
                      push({ pathname, state: { activity: personActivity } });
                    } else {
                      push(`/person/${personId}?cd=1`);
                    }
                  }//afterSubmit(personId, true);                  
                }}>
                  Klient zuweisen
                </PrimaryButton>

                <Box className={isNextHidden ? classes.disabledLink : classes.enabledLink} marginLeft={2}>
                  <Link
                    variant="h4"
                    underline="always"
                    color="primary"
                    onClick={() => {
                      if (previewPersonId != '') {
                        const personIds = resultData.map((e: any) => e.id)
                        const targetIndex = personIds.indexOf(previewPersonId)
                        if (targetIndex !== -1 && targetIndex + 1 < personIds.length) {
                          setPreviewPersonId(personIds[targetIndex + 1])
                          setIsPreviousHidden(false)
                          if (targetIndex + 1 === personIds.length - 1) {
                            setIsNextHidden(true)
                          }
                        } else {
                          setIsNextHidden(true)
                        }
                      }
                    }}
                  >
                    Nächste Vorschau
                  </Link>
                </Box>

              </div>
            </div>
          </div>
          : null}
      </PersonCreateContext.Provider>
    </DefaultDialog >
  );
};

interface ICallDialogContent {
  open: boolean;
  handleClose: (personId?: number) => void;
  pushTarget?: (personId: number) => string;
  dataHandler?: (data: any) => void;
  previewAction?: (data: any) => void;
}

const CallDialogContent = ({ handleClose, dataHandler, previewAction }: ICallDialogContent) => {
  const { personActivity, step } = usePersonCreateContext();

  if (!personActivity) {
    throw Error();
  }

  return (
    <DefaultDialogContent>
      <Box width={1} mb={4}>
        <Counterpart
          personActivity={personActivity}
          handleClose={handleClose}
          showCancel={[STEPS.ASSIGN_MATCH, STEPS.ASSIGN_OR_CREATE].includes(step)}
        />
      </Box>
      <CallDialogStep handleClose={handleClose} dataHandler={dataHandler} previewAction={previewAction} />
    </DefaultDialogContent>
  );
};

interface updatePerson {
  personId: number;
  accountId?: number | null;
  assistanceId?: string;
  firstName?: string;
  lastName?: string;
}

type ICallDialogStep = {
  handleClose: () => void;
  dataHandler?: (data: any) => void;
  previewAction?: (data: any) => void;
};

const CallDialogStep = ({ handleClose, dataHandler, previewAction }: ICallDialogStep) => {
  const { pushTarget, personActivity, step, refetch, setStep, setName } = usePersonCreateContext();
  const [updatePerson] = useUpdatePersonMutation();
  const [setAnonymousPerson] = useSetAnonymousPersonMutation();
  const { push } = useHistory();

  const update = async (
    { personId, accountId, assistanceId, firstName, lastName }: updatePerson,
    redirect = true,
  ) => {
    await updatePerson({
      variables: {
        person: {
          id: personId,
          temporary: false,
          firstName,
          lastName,
        },
        accountId,
        assistanceId,
      },
    });

    afterSubmit(personId, redirect);
  };

  const afterSubmit = (personId: number, redirect: boolean) => {
    if (redirect) {
      handleClose();
      if (pushTarget) {
        const pathname = pushTarget(personId);
        push({ pathname, state: { activity: personActivity } });
      } else {
        push(`/person/${personId}?cd=1`);
      }
    }
  };

  switch (step) {
    case STEPS.ASSIGN_MATCH:
      return <PersonAssignMatch handleClose={handleClose} />;
    case STEPS.ASSIGN_OR_CREATE:
      return (
        <PersonAssignNoMatch
          dataHandler={dataHandler}
          afterSubmit={afterSubmit}
          previewAction={previewAction}
          setPersonAsAnonymous={async ({ personId }) => {
            const response = await setAnonymousPerson({
              variables: { personId, personActivityId: personActivity.id },
            });

            // @ts-ignore
            personId = response.data?.setAnonymousPerson?.id;

            if (personId) {
              await refetch();
              setStep(STEPS.TYPE);
              setName(response.data?.setAnonymousPerson?.alias || "");
            }
          }}
          updatePerson={update}
        />
      );
    case STEPS.TYPE:
      return <PersonType />;
    case STEPS.ACCOUNT:
      return <AccountAssign personId={personActivity.person?.id} />;
    case STEPS.ASSISTANCE:
      return <AssistanceAccountAssign />;
    case STEPS.BUSINESS:
      return <BusinessAssign handleClose={handleClose} />;
    case STEPS.ACCOUNT_CONFIRM:
      return <AccountConfirm updatePerson={update} />;
  }
};

export default CallDialog;

// Unify with PersonCreate!
const PersonType = () => {
  const { setType } = usePersonCreateContext();

  return (
    <Box>
      <Typography variant="h1" align="center">
        Um was für einen Kontakt handelt es sich?
      </Typography>
      <Box display="flex" mt={5}>
        <PrimaryButton onClick={() => setType(TYPES.CLIENT)}>Klient</PrimaryButton>
        <Box mx={1} />
        <SecondaryButton onClick={() => setType(TYPES.PERSON)}>Privatperson</SecondaryButton>
        <Box mx={1} />
        <SecondaryButton onClick={() => setType(TYPES.BUSINESS)}>Geschäftskontakt</SecondaryButton>
      </Box>
    </Box>
  );
};

