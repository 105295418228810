import React from "react";

import classnames from "classnames";

import useStyles from "./styles";

export enum ServiceTypes {
  Angebote = "angebote",
  Arzt = "arzt",
  Babysitter = "babysitter",
  Fachanwalt = "fachanwalt",
  Finanzen = "finanzen",
  Hausaufgabenhilfe = "hausaufgabenhilfe",
  Haushaltshilfe = "haushaltshilfe",
  Hebamme = "hebamme",
  Immokaufen = "immokaufen",
  Immomiete = "immomieten",
  Kindergarten = "kindergarten",
  Kurse = "kurse",
  Pflege = "pflege",
  PflegeStationaer = "pflegeStat",
  Tagesmutter = "tagesmutter",
  Umzugshilfe = "umzugshilfe",
  Verein1 = "verein1",
  Verein2 = "verein2",
  Versicherung = "versicherung",
  Psychotherapie = "psychotherapie",
  Paarberatung = "paarberatung",
  ChildCare = "childCare",
  DomesticAid = "domesticAid",
  InternalConsult = "internalConsult",
  ExternalConsult = "externalConsult",
  CatInfo = "Allgemeiner Informationsservice",
  CatHealth = "Gesundheitslotse",
  CatChild = "Child & Elder Care",
  CatExpert = "Experten Service",
}

type TServiceMetaIconProps = {
  name?: string | null | undefined;
  big?: boolean;
  className?: string;
};

const ServiceMetaIcon = (props: TServiceMetaIconProps) => {
  switch (props.name) {
    case ServiceTypes.Angebote:
      return <Icon {...props} svg="serviceAngebote" />;
    case ServiceTypes.Arzt:
    case ServiceTypes.CatHealth:
      return <Icon {...props} svg="serviceArzt" />;
    case ServiceTypes.Babysitter:
      return <Icon {...props} svg="serviceBabysitter" />;
    case ServiceTypes.Fachanwalt:
    case ServiceTypes.CatExpert:
      return <Icon {...props} svg="serviceFachanwalt" />;
    case ServiceTypes.Finanzen:
      return <Icon {...props} svg="serviceFinanzen" />;
    case ServiceTypes.Hausaufgabenhilfe:
      return <Icon {...props} svg="serviceHausaufgabenhilfe" />;
    case ServiceTypes.Haushaltshilfe:
      return <Icon {...props} svg="serviceHaushaltshilfe" />;
    case ServiceTypes.Hebamme:
      return <Icon {...props} svg="serviceHebamme" />;
    case ServiceTypes.Immokaufen:
      return <Icon {...props} svg="serviceImmobilieKaufen" />;
    case ServiceTypes.Immomiete:
      return <Icon {...props} svg="serviceImmobilieMieten" />;
    case ServiceTypes.Kindergarten:
      return <Icon {...props} svg="serviceKinderbetreuung" />;
    case ServiceTypes.Kurse:
      return <Icon {...props} svg="serviceKurse" />;
    case ServiceTypes.PflegeStationaer:
      return <Icon {...props} svg="servicePflegeStationaer" />;
    case ServiceTypes.Pflege:
      return <Icon {...props} svg="servicePflegeAmbulant" />;
    case ServiceTypes.Tagesmutter:
      return <Icon {...props} svg="serviceTagesmutter" />;
    case ServiceTypes.Umzugshilfe:
      return <Icon {...props} svg="serviceUmzugshilfe" />;
    case ServiceTypes.Verein1:
      return <Icon {...props} svg="serviceVerein1" />;
    case ServiceTypes.Verein2:
      return <Icon {...props} svg="serviceVerein1" />;
    case ServiceTypes.Versicherung:
      return <Icon {...props} svg="serviceVersicherungen" />;
    case ServiceTypes.ChildCare:
    case ServiceTypes.CatChild:
      return <Icon {...props} svg="serviceKinderbetreuung" />;
    case ServiceTypes.DomesticAid:
      return <Icon {...props} svg="serviceHaushaltshilfe" />;
    case ServiceTypes.InternalConsult:
      return <Icon {...props} svg="serviceSTC" />;
    case ServiceTypes.Paarberatung:
    case ServiceTypes.Psychotherapie:
    case ServiceTypes.ExternalConsult:
      return <Icon {...props} svg="servicef2f" />;
    case ServiceTypes.CatInfo:
    default:
      return <Icon {...props} svg="serviceDefault" />;
  }
};

type TIcon = { big?: boolean; className?: string; svg: string };

const Icon = ({ big, className, svg }: TIcon) => {
  const classes = useStyles();
  const icon = require(`./icons/${svg}.svg`);
  return (
    <img
      src={icon.default}
      alt="illustration"
      className={classnames(className, classes.root, { [classes.big]: big })}
    />
  );
};

export default ServiceMetaIcon;
