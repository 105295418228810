import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  radioGroup: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: theme.spacing(1),
    width: "100%",
    "&>:first-child": {
      marginLeft: 0,
    },
    "&>:last-child": {
      marginRight: 0,
    },
  },
}));
