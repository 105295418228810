import React from "react";
import FormControl from "@material-ui/core/FormControl";
import { Field, FieldProps } from "formik";
import { TextField } from "formik-material-ui";
import { useAccountsQuery, Account } from "../../../generated/graphql";
import { fetchPolicy } from "../../../utils/constants";

import Autocomplete from "../Autocomplete";

type TAccountParentSelect = {
  parent: any;
  setParent: (p: any) => void;
};

const AccountParentSelect = ({ parent, setParent }: TAccountParentSelect) => {
  const { data } = useAccountsQuery({ fetchPolicy });
  const accounts = (data?.accounts || []) as Account[];
  return (
    <FormControl margin="normal" fullWidth>
      <Field
        name="parentId"
        component={TextField}
        render={({ field, form: { setFieldValue, errors, touched } }: FieldProps) => {
          return (
            <Autocomplete
              hasError={!!errors[field.name] && !!touched[field.name]}
              error={!!errors[field.name] && !!touched[field.name] ? errors[field.name] : undefined}
              label="Übergeordneter Account"
              placeholder="Bitte ausfüllen"
              handleChange={(o: any) => {
                setFieldValue(field.name, o.value);
                setParent(o);
              }}
              value={parent}
              suggestions={
                accounts
                  ? [
                      { value: "", label: "-" },
                      ...accounts.map((account: Account) => {
                        if (account) {
                          return {
                            value: account.id,
                            label: account.name,
                          };
                        }
                        return null;
                      }),
                    ]
                  : [{ value: "", label: "-" }]
              }
            />
          );
        }}
      />
    </FormControl>
  );
};
export default AccountParentSelect;
