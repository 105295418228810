import React, { useRef, useState } from "react";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import SubHeader from "../../organisms/SubHeader";
import BreadCrumb from "../../molecules/Breadcrumb";
import PrimaryButton from "../../atoms/PrimaryButton";
import WizardNavigation from "../../molecules/WizardNavigation";
import SecondaryButton from "../../atoms/SecondaryButton";
import EmailThreadMessages from "../../organisms/EmailThreadMessages";
import BusinessHeader from "../../molecules/BusinessHeader";
import PersonHeader from "../../molecules/PersonHeader";
import { useEmailThreadContext } from "../../../providers/Email";

import EmailThreadHeader from "./EmailThreadHeader";
import EmailThreadAnswer from "./EmailThreadAnswer";
import config from "../../../config";

type TEmailThread = {
  actions: {
    backAction: () => void;
    assignAction?: () => void;
    assignClientAction?: () => void;
    finishAction?: () => void;
  };
};

const senderToMatch = config.wpoIncomingEmail;


export const EmailThread = ({ actions }: TEmailThread) => {
  const [writeMode, setWriteMode] = useState(false);
  const { backAction, assignAction, finishAction, assignClientAction } = actions;
  const {
    emails,
    inbox,
    context: { businessObject, person },
    personActivity,
  } = useEmailThreadContext();
  const ref = useRef<any>(null);

  if (!emails || emails.length < 1 || !inbox) {
    return null;
  }

  const mapList =
    businessObject || person
      ? [{ name: "E-Mail", path: "" }]
      : [
          { name: "Inbox", path: "/emails" },
          { name: "E-Mail", path: "" },
        ];

  const isWpoEmail = () => {
    // check if the email is from wpo by checking the first mail address
    if (emails.length) {
      const email = emails[0];
      if (email.payload?.from?.toUpperCase().includes(senderToMatch.toUpperCase())) {
        return true;
      }
    }

    return false;
  };

  return (
    <>
      <SubHeader>
        {person && <PersonHeader person={person} mapList={mapList} />}
        {businessObject && <BusinessHeader mapList={mapList} />}
        {!person && !businessObject && <BreadCrumb mapList={mapList} />}
      </SubHeader>
      <Box pt={14} pb={8}>
        <Grid container>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <EmailThreadHeader
              inbox={inbox}
              activityId={personActivity?.id}
              person={person || emails[0]?.person}
              businessObject={businessObject}
            />
            <EmailThreadMessages emails={emails} person={person} />
            {(person || businessObject) && (
              <Box width={1}>
                <EmailThreadAnswer endRef={ref} writeMode={writeMode} setWriteMode={setWriteMode} />
              </Box>
            )}
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Box>
      <div ref={ref} />
      <WizardNavigation>
        <SecondaryButton onClick={backAction}>Zurück</SecondaryButton>
        {isWpoEmail() && (
          <SecondaryButton onClick={assignClientAction} disabled={writeMode}>
            WPO Klient zuweisen
          </SecondaryButton>
        )}
        {(person || businessObject) && assignAction && (
          <PrimaryButton onClick={assignAction} disabled={writeMode}>
            Zuweisen
          </PrimaryButton>
        )}
        {(person || businessObject) && finishAction && (
          <PrimaryButton onClick={finishAction} disabled={writeMode}>
            Abschließen
          </PrimaryButton>
        )}
      </WizardNavigation>
    </>
  );
};

export default EmailThread;
