import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    emotionImage: {
      fill: theme.palette.primary.main,
    },
  };
});

export default useStyles;
