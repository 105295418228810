import { useEffect } from "react";
import {
  useDepartmentsQuery,
  DepartmentsSubscriptionDocument,
  DepartmentsSubscriptionSubscription,
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

export const useDepartmentsSubscription = (accountId: number) => {
  const { data, loading, subscribeToMore } = useDepartmentsQuery({
    variables: { accountId },
    fetchPolicy,
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore<DepartmentsSubscriptionSubscription>({
      document: DepartmentsSubscriptionDocument,
      variables: { accountId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !prev) return prev;
        return {
          ...prev,
          ...{ departments: subscriptionData.data.departmentsSubscription },
        };
      },
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  return { departments: data?.departments || [], loading };
};
