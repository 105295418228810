import React, { useState } from "react";

import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { Field } from "formik";
import { Select, TextField } from "formik-material-ui";

import { Account, useUpdateAccountMutation } from "../../../generated/graphql";
import { SERVICE_REGION_TRANSLATIONS } from "../../../i18n/translations";
import AccountParentSelect from "../../molecules/AccountParentSelect";
import IndustrySelect from "../../molecules/IndustrySelect";
import DialogForm from "../DialogForm";

type TAccountEditDialog = {
  account: Account;
  open: boolean;
  toggle: () => void;
};

const AccountEditDialog = ({ account, open, toggle }: TAccountEditDialog) => {
  const [updateAccount] = useUpdateAccountMutation();

  const [parent, setParent] = useState({
    value: account?.parent?.id || "",
    label: account?.parent?.name || "",
  });
  const [industry, setIndustry] = useState({
    value: account?.industry?.id || "",
    label: account?.industry?.name || "",
  });

  return (
    <DialogForm
      open={open}
      initialValues={{
        name: account.name || "",
        parentId: account.parent?.id || "",
        industryId: account.industry?.id || "",
        executivesCount: account.executivesCount || 0,
        employeesCount: account.employeesCount || 0,
        serviceRegion: account.serviceRegion,
      }}
      onClose={toggle}
      title="Stammdaten bearbeiten"
      onSubmit={values => {
        return updateAccount({
          variables: {
            id: account.id!,
            input: {
              ...values,
              industryId: values.industryId || null,
              parentId: values.parentId || null,
            },
          },
        }).then(() => {
          toggle();
        });
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl margin="normal" fullWidth>
            <Field name="name" component={TextField} label="Account" />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <AccountParentSelect parent={parent} setParent={setParent}></AccountParentSelect>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <IndustrySelect industry={industry} setIndustry={setIndustry} />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="serviceRegion">Service-Region</InputLabel>
            <Field name="serviceRegion" component={Select}>
              {Object.entries(SERVICE_REGION_TRANSLATIONS).map(([serviceRegion, label]) => {
                return (
                  <MenuItem key={serviceRegion} value={serviceRegion}>
                    {label}
                  </MenuItem>
                );
              })}
            </Field>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl margin="normal" fullWidth>
            <Field name="employeesCount" component={TextField} label="Mitarbeiter" type="number" />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl margin="normal" fullWidth>
            <Field
              name="executivesCount"
              component={TextField}
              label="Führungskräfte"
              type="number"
            />
          </FormControl>
        </Grid>
      </Grid>
    </DialogForm>
  );
};

export default AccountEditDialog;
