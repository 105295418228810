import {
  AccountType,
  AccountTariff,
  AccountReporting,
  AccountUsageScope,
} from "../generated/graphql";
import upperFirst from "lodash/upperFirst";

export function translateType(type: AccountType, detail: boolean = false) {
  switch (type) {
    case AccountType.eap:
      return "EAP";
    case AccountType.chesnut:
    case AccountType.morneauShepell:
    case AccountType.lifeWorks:
      return detail ? "LifeWorks" : "International";
    case AccountType.wpo:
      return detail ? "WPO" : "International";
    case AccountType.international:
      return "International";
    case AccountType.test:
      return "Testaccount";
    case AccountType.assistance:
      return "Assistance";
    default:
      return "EAP";
  }
}

export function translateTariff(tariff: AccountTariff) {
  switch (tariff) {
    case AccountTariff.light:
      return `Geringnutzer`;
    case AccountTariff.basic:
      return `Basisnutzer`;
    default:
    case AccountTariff.premium:
      return `Intensivnutzer`;
  }
}

export function translateReporting(reporting: AccountReporting) {
  return upperFirst(reporting);
}

export function translateUsageScope(scope: AccountUsageScope) {
  return scope === AccountUsageScope.employeeOnly ? "Mitarbeiter" : "Mitarbeiter und Angehörige";
}
