import { Box, Grid, Typography } from "@material-ui/core";
import MuLink from "@material-ui/core/Link";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import classnames from "classnames";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import { ChatClient } from "../../../containers/ChatListener/reducer";
import { usePersonByChatAliasQuery } from "../../../generated/graphql";
import useStyles from "../../assets/inboxStyles";
import unassignedAvatar from "../../assets/unassignedAvatar.svg";
import Avatar from "../../atoms/Avatar";
import { InboxWrapper, TimeText } from "../../organisms/InboxComponent/components";
import { ChatActions } from "./chatItemActions";

export type TChatItem = {
  client: ChatClient;
};

const ChatItem = ({ client }: TChatItem) => {
  const classes = useStyles();
  const { data } = usePersonByChatAliasQuery({
    variables: {
      chatAlias: client.alias,
    },
  });

  if (!data) {
    return null;
  }

  return (
    <InboxWrapper>
      <Grid item xs={12} md={4} className={classes.taskContentColumn}>
        <Box mr={3}>
          <Avatar src={unassignedAvatar} widthfactor={5} />
        </Box>
        <ChatBubbleOutlineIcon color="primary" />
        <Box ml={2} display="flex" alignItems="start" flexDirection="column" overflow="hidden">
          <Typography variant="body2">{client.alias}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={2} className={classes.taskContentColumn}>
        <Box mr={2} color="grey.400" width="24px" height="24px"></Box>
        <TimeText start={client.latest?.toString() || dayjs().toISOString()} text="" />
      </Grid>
      <Grid item xs={12} md={3} className={classes.taskContentColumn}>
        <Box paddingX={2}>
          <Box>
            <MuLink
              to={`/person/${data?.personByChatAlias?.id}`}
              component={Link}
              className={classnames([classes.onelineEllipsis])}
              title={data.personByChatAlias?.name}
              color="primary"
              underline="always"
              variant="h4"
            >
              {data.personByChatAlias?.name}
            </MuLink>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={3} className={classes.taskContentColumn}>
        <ChatActions chat={client} mayFinish={true} />
      </Grid>
    </InboxWrapper>
  );
};

export default ChatItem;
