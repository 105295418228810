import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    menuList: {
      maxHeight: "calc(100vh - 192px)",
      margin: theme.spacing(14, 0, 10),
      overflow: "auto",
    },
  };
});

export default useStyles;
