import React, { useState, FC } from "react";

import { useMutation } from "@apollo/client";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import dayjs from "dayjs";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import DynamicFeed from "@material-ui/icons/DynamicFeed";
import Internal from "@material-ui/icons/EmojiFoodBeverage";
import External from "@material-ui/icons/Weekend";
import Flag from "@material-ui/icons/Flag";
import Services from "@material-ui/icons/Widgets";
import TrendingUp from "@material-ui/icons/TrendingUp";
import Event from "@material-ui/icons/Event";
import IconButton from "@material-ui/core/IconButton";
import AccountBalance from "@material-ui/icons/AccountBalance";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuBook from "@material-ui/icons/MenuBook";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Person from "@material-ui/icons/Person";
import PhoneCallback from "@material-ui/icons/PhoneCallback";
import { useHistory } from "react-router-dom";

import { PersonContext, client } from "../../App";
import { CreateCallDocument, PapershiftShiftsDocument } from "../../../generated/graphql";
import { useAuthorization } from "../../../providers/AuthorizationProvider";
import auth from "../../../services/authService";
import PersonCreateDialog from "../../organisms/PersonCreateDialog";
import AccountCreateDialog from "../../forms/AccountCreateDialog";
import TaskIcon from "../../atoms/TaskIcon";

import TaskDialog from "../TaskDialog";

import useStyles from "./styles";
import { Gavel } from "@material-ui/icons";
import { AddComment } from "@material-ui/icons";


interface IMenu {
  open: boolean;
  handleDrawerToggle: () => void;
}

const Menu = (props: IMenu) => {
  const { isAuthorized } = useAuthorization();
  const [showDialog, setShowDialog] = useState(false);
  const [showAccountDialog, setShowAccountDialog] = useState(false);
  const { push } = useHistory();

  const toggleAccountDialog = () => {
    const nextState = !showAccountDialog;
    setShowAccountDialog(nextState);
    if (nextState) {
      props.handleDrawerToggle();
    }
  };

  const openDialog = () => {
    setShowDialog(true);
    props.handleDrawerToggle();
  };

  const linkTo = (target: string) => {
    push(target);
    props.handleDrawerToggle();
  };

  const filterLabels = [
    {
      title: "Externe Beratungsmandate",
      path: "/activities/external",
      rule: "/activities/external",
      icon: <External />,
    },
    {
      title: "Interne strukturierte Beratungen",
      path: "/activities/internal",
      rule: "/activities/internal",
      icon: <Internal />,
    },
    {
      title: "Alle geflaggten Klienten",
      path: "/activities/flags",
      rule: "/activities/flags",
      icon: <Flag />,
    },
    {
      title: "Alle LM Services",
      path: "/activities/services",
      rule: "/activities/services",
      icon: <Services />,
    },
    {
      title: "Verwaltung",
      path: "/inbox/pool",
      rule: "/inbox/:filter(overdue|pool)",
      icon: <MenuBook />,
    },
  ];

  const [triggerCall] = useMutation(CreateCallDocument);

  const classes = useStyles();

  return (
    <>
      <Drawer
        className={classes.drawer}
        onClose={props.handleDrawerToggle}
        anchor="left"
        open={props.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={props.handleDrawerToggle}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        {auth.isAuthenticated() && (
          <List>
            <ListItem
              button
              className={classes.drawerListItem}
              onClick={() => linkTo("/activities")}
            >
              <ListItemIcon>
                <DynamicFeed />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: "body2" }} primary="Ellis Tag" />
            </ListItem>
            <PersonContext.Consumer>
              {context => {
                return (
                  <ListItem
                    button
                    className={classes.drawerListItem}
                    onMouseOver={() => {
                      const startsAt = dayjs().startOf("day");
                      const endsAt = dayjs().endOf("day");

                      client.query({
                        query: PapershiftShiftsDocument,
                        variables: {
                          startsAt: startsAt.toISOString(),
                          endsAt: endsAt.toISOString(),
                        },
                      });
                    }}
                    onClick={() => {
                      if (!!context.person && !!context.person.id) {
                        context.setPerson(undefined);
                      }
                      linkTo("/shift/1");
                    }}
                  >
                    <ListItemIcon>
                      <Event />
                    </ListItemIcon>
                    <ListItemText
                      primary="Kalender"
                      primaryTypographyProps={{ variant: "body2" }}
                    />
                  </ListItem>
                );
              }}
            </PersonContext.Consumer>
            <AddTask />
            <Divider />
            {filterLabels.map(filter => {
              return isAuthorized(filter.rule) ? (
                <ListItem
                  key={filter.rule}
                  button
                  className={classes.drawerListItem}
                  onClick={() => linkTo(filter.path)}
                >
                  <ListItemIcon>{filter.icon}</ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ variant: "body2" }}
                    primary={filter.title}
                  />
                </ListItem>
              ) : (
                <></>
              );
            })}
            <Divider />
            <ListItem button className={classes.drawerListItem} onClick={openDialog}>
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ variant: "body2" }}
                primary="Kontakt anlegen"
              />
            </ListItem>
            {isAuthorized("createAccount") && (
              <ListItem button className={classes.drawerListItem} onClick={toggleAccountDialog}>
                <ListItemIcon>
                  <AccountBalance />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ variant: "body2" }}
                  primary="Account anlegen"
                />
              </ListItem>
            )}
            <Divider />
            {process.env.NODE_ENV !== "production" && (
              <>
                <ListItem
                  button
                  className={classes.drawerListItem}
                  onClick={() =>
                    triggerCall({
                      variables: {
                        random: false,
                      },
                    }).then(() => props.handleDrawerToggle())
                  }
                >
                  <ListItemIcon>
                    <PhoneCallback />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ variant: "body2" }}
                    primary="Eingehender Anruf 100% match"
                  />
                </ListItem>
                <ListItem
                  button
                  className={classes.drawerListItem}
                  onClick={() =>
                    triggerCall({
                      variables: {
                        random: true,
                      },
                    }).then(() => props.handleDrawerToggle())
                  }
                >
                  <ListItemIcon>
                    <PhoneCallback />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ variant: "body2" }}
                    primary="Eingehender Anruf 0% match"
                  />
                </ListItem>
              </>
            )}
            {isAuthorized("/statistics") && (
              <ListItem
                button
                className={classes.drawerListItem}
                onClick={() => linkTo("/statistics")}
              >
                <ListItemIcon>
                  <TrendingUp />
                </ListItemIcon>
                <ListItemText primary="Statistik" primaryTypographyProps={{ variant: "body2" }} />
              </ListItem>
            )}
            <ListItem
              button
              className={classes.drawerListItem}
              onClick={() => linkTo("/complaints")}
            >
              <ListItemIcon>
                <Gavel />
              </ListItemIcon>
              <ListItemText primary="Beschwerden" primaryTypographyProps={{ variant: "body2" }} />
            </ListItem>            
          </List>
        )}
      </Drawer>
      <PersonCreateDialog
        open={showDialog}
        onClose={(path, error) => {
          setShowDialog(false);
          if (error) {
            console.log({ error });
          } else if (path) {
            push(path);
          }
        }}
      />
      <AccountCreateDialog open={showAccountDialog} toggle={toggleAccountDialog} />
    </>
  );
};

const AddTask: FC = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <>
      <ListItem
        button
        className={classes.drawerListItem}
        onClick={() => {
          setOpen(true);
        }}
      >
        <ListItemIcon>
          <TaskIcon fill="rgba(0, 0, 0, 0.54)" />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ variant: "body2" }} primary="Neue Aufgabe" />
      </ListItem>
      <TaskDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};

export default Menu;
