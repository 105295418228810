import React, { FC } from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const StyledSvgIcon = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "transparent",
      height: "82px",
      position: "absolute",
      top: "-10px",
      width: "84px",
    },
  }),
)(SvgIcon);

const PostIt: FC = () => {
  return (
    <StyledSvgIcon viewBox="0 0 92 90">
      <g filter="url(#filter0_d)">
        <path
          fill="#F1EEAE"
          d="M4.113 2.749L83.068.068l1.968 57.967c.61 17.99 2.711 20.92 2.711 20.92l-79.954 2.68S6.115 77.5 6.115 61.715c0-15.785-2.002-58.966-2.002-58.966z"
        />
      </g>
      <path
        fill="#000"
        d="M49.096 50.03l-2.755.029c-.41.017-.717.035-.922.054l-.642.056c-.487.05-.916.083-1.287.099-.946.04-1.434-.3-1.465-1.023a.931.931 0 01.404-.838c.294-.208.69-.322 1.188-.344l1.141-.048-.406-9.542c-.025-.586-.164-.873-.418-.862-.117.005-.301.047-.552.126a3.105 3.105 0 01-.83.138 1.243 1.243 0 01-.832-.273c-.253-.194-.387-.467-.402-.818-.031-.742.478-1.189 1.527-1.34.65-.087 1.179-.198 1.584-.332.414-.135.817-.21 1.207-.227.732-.032 1.118.421 1.157 1.358l.497 11.664 1.156-.05c1.23-.051 1.86.308 1.893 1.078.012.274-.1.523-.334.748-.226.215-.529.33-.91.346zm-2.034-20.235c.04.946-.334 1.578-1.122 1.895-.221.088-.537.14-.946.158-.41.017-.793-.128-1.148-.435-.355-.308-.544-.72-.566-1.237-.022-.517.136-.954.472-1.31.347-.357.808-.548 1.383-.573.586-.024 1.046.112 1.382.41.344.29.526.653.544 1.092z"
      />
      <path
        stroke="#000"
        strokeWidth="1.5"
        d="M37.613 22.5c-8.5 2.5-17.5 11.5-15 22.5s10.5 17.5 21.5 19c11.89 1.621 21-7 24-13 3.325-6.649 1.5-18.346-3.5-24.5-6.5-8-14.5-10-19-10s-19 1-25 15.5"
      />
      <defs>
        <filter
          id="filter0_d"
          width="91.747"
          height="89.707"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </StyledSvgIcon>
  );
};
export default PostIt;
