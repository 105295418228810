import { Box, CircularProgress, Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import { Field, FieldProps, FormikProps, FormikValues } from "formik";
import { TextField } from "formik-material-ui";
import Maybe from "graphql/tsutils/Maybe";
import React, { FC } from "react";
import { Account } from "../../../generated/graphql";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import AccountSelect from "../../fields/AccountSelect";
import ClientTypeSelect from "../../fields/ClientTypeSelect";
import ClientAge from "../../forms/ClientAge";
import GenderSelect from "../../molecules/GenderSelect";
import { useTransferWpoClientContext } from "../TransferWpoClientDialog/provider";
import { STEPS } from "../TransferWpoClientDialog/reducer";
const useStyles = makeStyles({
  formControl: {
    minWidth: 336,
  },
});

type AccountType = Maybe<{ __typename?: "Account" | undefined } & Pick<Account, "id" | "name">>;

interface TClientForm {
  accounts?: Array<AccountType>;
  accountId?: any;
  handleAccountChange: (option: any) => void;
}

const ClientForm: FC<TClientForm & FormikProps<FormikValues>> = ({
  handleSubmit,
  isSubmitting,
}) => {
  const classes = useStyles();
  const { setStep } = useTransferWpoClientContext();
  return (
    <form onSubmit={handleSubmit}>
      <Box mb={3} textAlign="center">
        <Typography variant="h1">Neuen Klienten anlegen</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth className={classes.formControl} margin="normal">
            <Field name="firstName" component={TextField} label="Vorname" />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth className={classes.formControl} margin="normal">
            <Field name="lastName" component={TextField} label="Nachname" />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <GenderSelect />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth className={classes.formControl} margin="normal">
            <Field name="alias" component={TextField} label="Alias" />
          </FormControl>
        </Grid>
        <Field
          render={({ field, form, form: { setFieldValue } }: FieldProps) => {
            return (
              <ClientAge
                setFieldValue={setFieldValue}
                errors={{}}
                validateForm={() => {}}
                values={{
                  initialAge: form.values.initialAge,
                  ageGuessed: form.values.ageGuessed,
                  birthDate: form.values.birthDate,
                }}
              />
            );
          }}
        />
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field component={TextField} name="areaCode" label="Vorwahl*" />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field label="Telefonnummer*" name="number" component={TextField} />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Field
            render={({
              field,
              form,
              form: { setFieldValue, setTouched, touched, errors },
            }: FieldProps) => {
              return (
                <AccountSelect
                  formError={!!errors.account && !!touched.account}
                  onBlur={() => {
                    setTouched({ ...touched, account: true });
                  }}
                  onChange={value => {
                    setFieldValue("account", value);
                  }}
                  value={form.values.account || { value: null }}
                />
              );
            }}
          ></Field>
        </Grid>
        <Grid item xs={6}>
          <ClientTypeSelect />
        </Grid>
        {/* <Grid item xs={6}>
          <FormControl className={classes.formControl} margin="normal">
            <Field label="Fallnummer" component={TextField} name="ucmsId" />
          </FormControl>
        </Grid> */}
      </Grid>
      <Box display="flex" justifyContent="center" mt={3}>
        <SecondaryButton onClick={() => setStep(STEPS.INIT)} tabIndex={0}>
          Abbrechen
        </SecondaryButton>
        <Box mx={1} />
        <PrimaryButton type="submit" disabled={isSubmitting} tabIndex={10}>
          {!isSubmitting && "OK"}
          {isSubmitting && (
            <Box ml={3} display="flex" alignItems="center">
              <CircularProgress size={24} />
            </Box>
          )}
        </PrimaryButton>
      </Box>
    </form>
  );
};
export default ClientForm;
