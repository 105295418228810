import { Field } from "formik";
import { withStyles, Theme } from "@material-ui/core";

export const StyledField = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(2),
    "& .MuiFormControl-root": {},
    "& .MuiInput-underline.Mui-disabled:before": {
      border: "none",
    },
    "& label.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.87)",
    },
    "& input.Mui-disabled": {
      cursor: "text",
      color: `${theme.palette.primary.main}`,
    },
  },
}))(Field);
