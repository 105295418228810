import React, { FC } from "react";
import { Theme } from "@material-ui/core";
import DialogContent, { DialogContentProps } from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(6, 11, 4),
    "&:first-child": {
      // dialog without title
      paddingTop: theme.spacing(6),
    },
  },
}));

const DefaultDialogContent: FC<DialogContentProps> = props => {
  const classes = useStyles();
  return <DialogContent {...props} classes={{ root: classes.root }} />;
};
export default DefaultDialogContent;
