import React from "react";
import sortBy from "lodash/sortBy";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { useUsersQuery, User, Team } from "../../../generated/graphql";
import ClickableListItem from "../../atoms/ClickableListItem";
import Loader from "../../atoms/Loading";
import SecondaryButton from "../../atoms/SecondaryButton";
import { getUserName } from "../../../utils/userNameHelper";
import UserDetails from "../../molecules/UserDetails";

type TAssignUserDialog = {
  allowReset?: boolean;
  headline: string;
  onClose?: () => void;
  onSubmit: (userId?: number) => void;
  user?: User | null;
  teams?: Team[];
};

const AssignUserDialogForService = ({
  allowReset = false,
  headline,
  onClose,
  onSubmit,
  user,
  teams,
}: TAssignUserDialog) => {
  const { data, loading } = useUsersQuery({ variables: { teams } });

  if (loading) {
    return <Loader />;
  }

  let users = data?.users as User[];
  //to remove developers
  //later should be added to admin panel for dynamic filtering
  const devUserIds = [97, 107, 103, 47, 60, 56, 63];
  users = users.filter(e => !devUserIds.includes(e.id));
  

  if (!users || users.length < 1) {
    return <>Error</>;
  }

  return (
    <>
      <Box mb={5} width={1} textAlign="center">
        <Typography variant="h1">{headline}</Typography>
      </Box>
      <Box width={1}>
        {user && (
          <Box mb={8} width={1} display="flex" alignItems="flex-start">
            <UserDetails user={user} />
            {allowReset && (
              <SecondaryButton onClick={() => onSubmit()}>Zurücksetzen</SecondaryButton>
            )}
          </Box>
        )}
        <Box overflow="scroll">
          {sortBy(users, (user: User) => getUserName(user?.person)).map((user: User) => {
            if (!user) {
              return null;
            }

            return <UserListItem key={String(user.id)} user={user} onSubmit={onSubmit} />;
          })}
        </Box>
        {onClose && (
          <Box mt={3} textAlign="center">
            <SecondaryButton onClick={onClose}>Abbrechen</SecondaryButton>
          </Box>
        )}
      </Box>
    </>
  );
};

const UserListItem = ({ onSubmit, user }: { onSubmit: (userId: number) => void; user: User }) => {
  if (!user.person) {
    return null;
  }

  return (
    <ClickableListItem
      onBackground={false}
      onClick={async () => {
        onSubmit(user.id!);
      }}
    >
      <Box flex={1}>{getUserName(user!.person)}</Box>      
    </ClickableListItem>
  );
};

export default AssignUserDialogForService;
