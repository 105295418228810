import React, { ReactNode } from "react";
import Select from "react-select";

import MuiTextField from "@material-ui/core/TextField";
import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import NoSsr from "@material-ui/core/NoSsr";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import useStyles from "./styles";

function NoOptionsMessage(props: any) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      variant="body2"
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }: any) {
  return <div ref={inputRef} {...props} />;
}

function Control(props: any) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props: any) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props: any) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      variant="body2"
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props: any) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      variant="body2"
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props: any) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props: any) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  DropdownIndicator: null,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

type TAutoComplete = {
  error: any;
  handleChange: (option: any) => void;
  hasError: boolean;
  id?: string;
  label?: ReactNode;
  name?: string;
  onBlur?: () => void;
  disabled?: boolean;
  placeholder?: string;
  suggestions: Array<any>;
  value: any | undefined;
  loading?: boolean;
};

const AutoComplete = ({
  error,
  handleChange,
  hasError,
  id,
  label,
  name,
  onBlur,
  placeholder,
  suggestions,
  disabled = false,
  value,
  loading,
}: TAutoComplete) => {
  const classes = useStyles();

  return (
    <NoSsr>
      <Select
        classes={classes}
        isDisabled={disabled}
        components={components}
        id={id}
        menuPosition="fixed"
        name={name}
        onChange={handleChange}
        onBlur={onBlur}
        isLoading={loading}
        options={suggestions}
        placeholder={placeholder}
        textFieldProps={{
          label,
          InputLabelProps: {
            shrink: true,
            error: hasError,
          },
          error: hasError,
        }}
        value={value}
      />
      {hasError && <FormHelperText error>{error}</FormHelperText>}
    </NoSsr>
  );
};

/**
 * The account select is very laggy when using the above variant.
 */
type TMuiAutoCompleteWrapper = {
  error?: boolean;
  id: string;
  label: string;
  getOptionSelected: (option: any) => boolean;
  noOptionsText: string;
  onBlur: () => void;
  onChange: (value: string) => void;
  options: any[];
  value: any;
};

export const MuiAutoCompleteWrapper = ({
  error = false,
  getOptionSelected,
  id,
  label,
  noOptionsText,
  onBlur,
  onChange,
  options,
  value,
}: TMuiAutoCompleteWrapper) => {
  return (
    <MuiAutocomplete
      id={id}
      getOptionSelected={getOptionSelected}
      options={options}
      getOptionLabel={option => {
        return option.label || option.name || "";
      }}
      onBlur={onBlur}
      onChange={(event, value) => {
        onChange(value);
      }}
      renderInput={params => {
        return <MuiTextField {...params} label={label} error={error} />;
      }}
      noOptionsText={noOptionsText}
      value={value}
    />
  );
};

export default AutoComplete;
