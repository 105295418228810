import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    csv: {
      "&>a": {
        fontWeight: theme.typography.fontWeightMedium,
        "&:not(:last-child):after": {
          content: '","',
        },
        paddingRight: theme.spacing(0.5),
      },
    },
    expand: {
      cursor: "pointer",
      "&:hover": {
        background: "white",
      },
    },
    group: {
      "&>div": {
        borderTop: "solid #BDBDBD 1px",
        "&:last-child": {
          borderBottom: "solid #BDBDBD 1px",
        },
      },
    },
  };
});

export default useStyles;
