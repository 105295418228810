import React from "react";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";

interface IIllustratedErrorMessage {
  messages: string[];
  illustration: string; // image src `import stop from "../../assets/stop.svg";`
  illustrationAltText: string;
}

const IllustratedErrorMessage: React.FC<IIllustratedErrorMessage> = props => {
  const classes = useStyles();

  return (
    <Grid container className={classes.content}>
      <Box mt={20} textAlign="center">
        <Typography className={classes.message} variant="h1">
          {props.messages.map((msg, idx) => (
            <React.Fragment key={`msg-${idx}`}>
              {msg}
              <br />
            </React.Fragment>
          ))}
        </Typography>
        <Box my={5}>
          <img src={props.illustration} alt={props.illustrationAltText} />
        </Box>
        {props.children}
      </Box>
    </Grid>
  );
};

export default IllustratedErrorMessage;
