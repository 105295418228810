import React, { useState, Fragment } from "react";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

import { Formik } from "formik";
import Typography from "@material-ui/core/Typography";
import {
  BusinessObject,
  Person,
  PersonActivity,
  useCreateBusinessMemoMutation,
  CounselingMandate,
} from "../../../generated/graphql";
import BusinessMemoForm from "../BusinessMemoForm";
import useStyles from "../reportStyles";

export type TBusinessMemoCreateForm = {
  business: BusinessObject;
  fallBackPerson: Person;
  mandates: CounselingMandate[];
  activity?: PersonActivity;
  refetch: () => void;
};

const BusinessMemoCreateForm = ({
  business,
  fallBackPerson,
  activity,
  refetch,
  mandates,
}: TBusinessMemoCreateForm) => {
  const classes = useStyles();
  const { replace } = useHistory();
  const [createBusinessMemo] = useCreateBusinessMemoMutation();
  const [contact, setContact] = useState<Person | { name: string; id?: never } | undefined>(
    fallBackPerson,
  );
  const [mandate, setMandate] = useState<Person | undefined>();
  return (
    <Fragment>
      <Typography variant="h1" className={classes.headline}>
        Erstelle eine neue Gesprächsnotiz zu {business.name!}.
      </Typography>
      <Formik
        initialValues={{
          start: activity ? activity.start : dayjs().subtract(45, "minute"),
          end: activity?.end || dayjs(),
          type: activity ? activity.activityType : "callIn",
        }}
        onSubmit={(values, { setSubmitting }) => {
          createBusinessMemo({
            variables: {
              businessId: business.id!,
              memo: {
                personId: contact?.id || fallBackPerson.id!,
                contact:
                  contact?.name && contact?.name !== "Unbekannt" ? contact?.name : business.name!,
                saveContact: true,
                personActivity: {
                  id: activity && activity.id!,
                  activityType: values.type,
                  end: values.end,
                  start: values.start,
                  ...(mandate ? { refPersonId: mandate.id } : []),
                },
              },
            },
          }).then(({ data: { createBusinessMemo } }: any) => {
            setSubmitting(false);
            refetch();
            if (createBusinessMemo) {
              replace({
                pathname: `/business/${business.id}/memo/${createBusinessMemo.id}`,
                state: { activity },
              });
            }
          });
        }}
      >
        {formProps => (
          <BusinessMemoForm
            {...formProps}
            business={business}
            setContact={setContact}
            setMandate={setMandate}
            mandates={mandates}
          />
        )}
      </Formik>
    </Fragment>
  );
};
export default BusinessMemoCreateForm;
