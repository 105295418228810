import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    link: {
      borderBottom: "solid 1px",
      color: theme.palette.primary.main,
      cursor: "pointer",
      lineHeight: 1,
      marginBottom: 1,
      "&:hover": {
        borderBottom: "solid 2px",
        marginBottom: 0,
        textDecoration: "none",
      },
    },
  };
});

export default useStyles;
