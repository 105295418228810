import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  deactivated: {
    backgroundImage: `linear-gradient(110deg, #fff 25%, #f8f6f1 25%, #f8f6f1 50%, #fff 50%, #fff 75%, #f8f6f1 75%, #f8f6f1 100%)`,
    backgroundSize: " 21.28px 58.48px",
    border: "1px solid #f2680a",
    padding: "10px",
    borderRadius: "4px",
  },
}));

export default useStyles;