import React, { FormEvent } from "react";
import { FormikProps, FormikValues } from "formik";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import PrimaryButton from "../../atoms/PrimaryButton";
import { Person } from "../../../generated/graphql";
import WizardNavigation from "../../molecules/WizardNavigation";
import SecondaryButton from "../../atoms/SecondaryButton";
import useStyles from "../reportStyles";
import mapping from "../../../utils/lifeworksServiceMapping";

export interface IFormProps {
  person: Person;
  saveInput: (params: any) => any;
  backAction: () => void;
}

export default function StepLifeworksService({
  backAction,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  values,
}: FormikProps<FormikValues> & IFormProps) {
  const submit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSubmit(event);
  };

  const classes = useStyles();

  const incomplete =
    values.lifeworksService === "" ||
    values.lifeworksServiceIssue === "" ||
    values.lifeworksServiceSubIssue === "";

  const services = Object.entries(mapping).map(([value, service]) => ({
    value,
    label: service.label,
  }));

  const issues = Object.entries(mapping[values.lifeworksService]?.issues || {}).map(
    ([value, issue]) => ({
      value,
      label: issue.label,
    }),
  );

  const subIssues = Object.entries(
    mapping[values.lifeworksService]?.issues[values.lifeworksServiceIssue]?.subIssues || {},
  ).map(([value, subIssue]) => ({
    value,
    label: subIssue.label,
  }));

  const selectSubFields = (issue: string, subIssue: string = "") => {
    setFieldValue("lifeworksServiceIssue", issue);
    setFieldValue("lifeworksServiceSubIssue", subIssue);
  };

  return (
    <form onSubmit={submit} className={classes.content}>
      <Box maxWidth={300} width="100%">
        <FormControl fullWidth margin="normal">
          <InputLabel style={{ position: "absolute" }} htmlFor="lifeworksService">
            Service
          </InputLabel>
          <Select
            name="lifeworksService"
            value={values.lifeworksService}
            onChange={e => {
              e.preventDefault();

              const value: string = e.target.value as string; // TODO? can we get type better here?
              setFieldValue("lifeworksService", value);

              // pre-select following fields
              let newIssue = "";
              let newSubIssue = "";

              const issuesOfSelection = Object.keys(mapping[value].issues);

              if (issuesOfSelection.length === 1) {
                newIssue = issuesOfSelection[0];

                const subIssuesOfSelection = Object.keys(mapping[value].issues[newIssue].subIssues);
                if (subIssuesOfSelection.length === 1) {
                  newSubIssue = subIssuesOfSelection[0];
                }
              }

              selectSubFields(newIssue, newSubIssue);
            }}
          >
            {services.map(service => (
              <MenuItem key={service.value} value={service.value}>
                {service.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel style={{ position: "absolute" }} htmlFor="lifeworksServiceIssue">
            Issue
          </InputLabel>
          <Select
            name="lifeworksServiceIssue"
            disabled={values.lifeworksService === ""}
            value={values.lifeworksServiceIssue}
            onChange={e => {
              e.preventDefault();
              const value = e.target.value as string; // TODO? can we get type better here?

              const subIssuesOfSelection = Object.keys(
                mapping[values.lifeworksService].issues[value].subIssues,
              );

              selectSubFields(
                value,
                subIssuesOfSelection.length === 1 ? subIssuesOfSelection[0] : "",
              );
            }}
          >
            {issues.map(issue => (
              <MenuItem key={issue.value} value={issue.value}>
                {issue.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel style={{ position: "absolute" }} htmlFor="lifeworksServiceSubIssue">
            Sub issue
          </InputLabel>
          <Select
            name="lifeworksServiceSubIssue"
            disabled={values.lifeworksServiceIssue === ""}
            value={values.lifeworksServiceSubIssue}
            onChange={e => {
              e.preventDefault();
              setFieldValue("lifeworksServiceSubIssue", e.target.value);
            }}
          >
            {subIssues.map(subIssue => (
              <MenuItem key={subIssue.value} value={subIssue.value}>
                {subIssue.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <WizardNavigation>
        <SecondaryButton onClick={() => backAction()} tabIndex={3}>
          Zurück
        </SecondaryButton>
        <PrimaryButton type="submit" disabled={isSubmitting || incomplete} tabIndex={2}>
          Weiter
        </PrimaryButton>
      </WizardNavigation>
    </form>
  );
}
