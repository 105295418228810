import React from "react";

import IconButton from "@material-ui/core/IconButton";
import SentimentDissatisfiedOutlined from "@material-ui/icons/SentimentDissatisfiedOutlined";
import SentimentSatisfiedOutlined from "@material-ui/icons/SentimentSatisfiedOutlined";
import SentimentVerySatisfiedOutlined from "@material-ui/icons/SentimentVerySatisfiedOutlined";
import SentimentVeryDissatisfiedOutlined from "@material-ui/icons/SentimentVeryDissatisfiedOutlined";
import MoodOutlined from "@material-ui/icons/MoodOutlined";
import useStyles from "./styles";
import { MentalState } from "../../../generated/graphql";

type TMoodSelect = {
  value: MentalState;
  setValue: (x: MentalState) => void;
};

const MoodSelect = ({ value, setValue }: TMoodSelect) => {
  const classes = useStyles();
  return (
    <div>
      <IconButton
        className={`${classes.icon} ${value === MentalState.euphoric && classes.active}`}
        onClick={() => setValue(MentalState.euphoric)}
      >
        <SentimentVerySatisfiedOutlined />
      </IconButton>
      <IconButton
        className={`${classes.icon} ${value === MentalState.happy && classes.active}`}
        onClick={() => setValue(MentalState.happy)}
      >
        <MoodOutlined />
      </IconButton>
      <IconButton
        className={`${classes.icon} ${value === MentalState.neutral && classes.active}`}
        onClick={() => setValue(MentalState.neutral)}
      >
        <SentimentSatisfiedOutlined />
      </IconButton>
      <IconButton
        className={`${classes.icon} ${value === MentalState.depressed && classes.active}`}
        onClick={() => setValue(MentalState.depressed)}
      >
        <SentimentDissatisfiedOutlined />
      </IconButton>
      <IconButton
        className={`${classes.icon} ${value === MentalState.critical && classes.active}`}
        onClick={() => setValue(MentalState.critical)}
      >
        <SentimentVeryDissatisfiedOutlined />
      </IconButton>
    </div>
  );
};
export default MoodSelect;
