import React, { Fragment, ReactText } from "react";
import Typography from "@material-ui/core/Typography";
import { Formik, FormikProps, FormikValues } from "formik";
import { useUpdateMemoMutation, MemoState, Memo, BusinessObject } from "../../../generated/graphql";
import Step1 from "./step1";
import FinalStep from "../ReportFinalStep";

import useStyles from "../reportStyles";

export type TBusinessMemoWizard = {
  backAction: () => void;
  business: BusinessObject;
  memo: Memo;
  step: number;
  setStep: (step: number) => void;
  refetch: () => void;
};

const memoMap = [MemoState.created, MemoState.created, MemoState.finished];

const BusinessMemoWizard = ({
  business,
  backAction,
  memo,
  step,
  setStep,
  refetch,
}: TBusinessMemoWizard) => {
  const [updateMemo] = useUpdateMemoMutation();
  const classes = useStyles();
  const headline = () => {
    return ["", `Fasse kurz das Gespräch zusammen.`][step];
  };

  const getInitialValues = () => {
    return {
      note: memo.note || "",
      contact: memo.contact || "",
    };
  };

  const getSubmitValues = (values: {
    [key: string]: string | number | null | undefined | ReactText | boolean | any[];
  }) => {
    let submitValues = {};
    const validKeys = Object.keys(getInitialValues());
    validKeys.forEach(key => {
      if (values[key]) {
        submitValues = { ...submitValues, [key]: values[key] };
      }
    });
    if (values["status"]) {
      submitValues = { ...submitValues, status: values["status"] };
    }
    return submitValues;
  };

  const renderForm = (callback: (p: any) => any, formProps: FormikProps<FormikValues>) => {
    switch (step) {
      case 1:
        return (
          <Step1
            {...formProps}
            saveInput={callback}
            backAction={backAction}
            allowContactSave={false}
          />
        );
      case 2:
        // finished state
        return <FinalStep business={business} backAction={backAction} />;
      default:
        return <div />;
    }
  };

  function autoSave(values: { [key: string]: string | number | null | undefined | ReactText }) {
    return updateMemo({
      variables: {
        memo: {
          id: memo.id,
          ...getSubmitValues(values),
        },
      },
    });
  }

  return (
    <Fragment>
      <Typography variant="h1" className={classes.headline}>
        {headline()}
      </Typography>

      <Formik
        initialValues={getInitialValues()}
        onSubmit={(values, { setSubmitting }) => {
          const newStep = step + 1;
          setStep(newStep);
          return updateMemo({
            variables: {
              memo: {
                id: memo.id,
                status: memoMap[newStep % 3],
                ...getSubmitValues(values),
              },
            },
          }).then(() => {
            // refetch();
            setSubmitting(false);
          });
        }}
      >
        {formProps => renderForm(autoSave, formProps)}
      </Formik>
    </Fragment>
  );
};
export default BusinessMemoWizard;
