import React, { FC } from "react";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";

import { TextField } from "formik-material-ui";
import { Field } from "formik";

import AddressTypeSelect from "../../molecules/AddressTypeSelect";

const AddressField: FC<{ namePrefix: string }> = ({ namePrefix }) => {
  const name = namePrefix ? `${namePrefix}.` : "";

  return (
    <Box mb={3} width="100%">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AddressTypeSelect name={`${name}type`} />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field name={`${name}street`} label="Straße, Hausnummer" component={TextField} />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field name={`${name}zip`} label="PLZ" component={TextField} />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Field name={`${name}city`} label="Ort" component={TextField} />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AddressField;
