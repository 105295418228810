export const radiusDefaults = [1, 2, 3, 5, 10, 20, 30, 50, 10000]; // using 10000 for no real radius
export const weekDays = [
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag",
  "Sonntag",
];

export const TAKE = 20;

export const fetchPolicy = "cache-and-network";
