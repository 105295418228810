import path from "ramda/src/path";

import { PapershiftShift, User } from "../../../../generated/graphql";

// Sorting functions
export const sortByWorkingArea = (shiftA: PapershiftShift, shiftB: PapershiftShift) => {
  if (
    !shiftA.papershiftWorkingArea ||
    !shiftB.papershiftWorkingArea ||
    !shiftA.papershiftWorkingArea.id ||
    !shiftB.papershiftWorkingArea.id
  ) {
    return 0;
  }

  if (shiftA.papershiftWorkingArea.id > shiftB.papershiftWorkingArea.id) {
    return 1;
  }

  if (shiftA.papershiftWorkingArea.id < shiftB.papershiftWorkingArea.id) {
    return -1;
  }

  return 0;
};

export const sortShiftsByUserAlias = (shiftA: PapershiftShift, shiftB: PapershiftShift) => {
  const userAAlias = path<string>(["users", 0, "person", "alias"], shiftA);
  const userBAlias = path<string>(["users", 0, "person", "alias"], shiftB);

  if (!userAAlias || !userBAlias) {
    return 0;
  }

  if (userAAlias.toLowerCase() > userBAlias.toLowerCase()) {
    return 1;
  }

  if (userAAlias.toLowerCase() < userBAlias.toLowerCase()) {
    return -1;
  }

  return 0;
};

export const sortUsersByAlias = (userA: User, userB: User) => {
  if (!userA.person || !userA.person.alias || !userB.person || !userB.person.alias) {
    return 0;
  }

  if (userA.person.alias.toLowerCase() > userB.person.alias.toLowerCase()) {
    return 1;
  }

  if (userA.person.alias.toLowerCase() < userB.person.alias.toLowerCase()) {
    return -1;
  }

  return 0;
};
