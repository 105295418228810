import React from "react";
import { useLocation, useParams } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Loader from "../../atoms/Loading";
import CounselingServiceCreationForm from "../../forms/CounselingServiceCreateForm";
import ServiceReset from "../../organisms/ServiceReset";
import { usePersonQuery, ServiceType } from "../../../generated/graphql";

type TCounselingCreation = { type: ServiceType };
const CounselingCreation = ({ type }: TCounselingCreation) => {
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation();
  const { data, loading } = usePersonQuery({
    variables: { id: parseInt(id, 10) },
  });
  // @ts-ignore
  const topicId = state?.topicId! ? parseInt(state?.topicId!, 10) : undefined;

  const createdConsultingId = localStorage.getItem("createdConsultingId");
  const createdServiceName = {
    [ServiceType.research]: "",
    [ServiceType.researchBlank]: "",
    [ServiceType.externalConsult]: "Externe Beratung",
    [ServiceType.internalConsult]: "Interne strukturierte Beratung",
  }[type];
  const person = data ? data.person : null;

  return loading && !person ? (
    <Loader />
  ) : (
    person && (
      <Grid container component={Box} mt={20}>
        {createdConsultingId ? (
          <>
            <Grid item xs={1} />
            <Grid item xs={10}>
              <ServiceReset
                name={createdServiceName}
                id={createdConsultingId}
                person={person}
                type={type}
              />
            </Grid>
            <Grid item xs={1} />
          </>
        ) : (
          <>
            <Grid item xs={2} xl={3} />
            <Grid item xs={8} xl={6}>
              <CounselingServiceCreationForm person={person} topicId={topicId} type={type} />
            </Grid>
            <Grid item xs={2} xl={3} />
          </>
        )}
      </Grid>
    )
  );
};

export default CounselingCreation;
