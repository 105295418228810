import React from "react";
import { Formik } from "formik";
import { initialValuesHelper } from "../../../utils/initialValueHelper";

import { Service, ServiceResearchObject } from "../../../generated/graphql";
import ResearchObjectBusinessForm from "../../forms/ResearchObjectBusinessForm";
import ServiceResearchObjectValidation from "./validation";

interface IServiceResearchLeadCreationForm {
  service: Service;
  serviceResearchObject: { [key: string]: string | any[] | any };
  onSubmit: (values: ServiceResearchObject) => void;
}

const ServiceResearchLeadCreationForm = ({
  service,
  serviceResearchObject,
  onSubmit,
}: IServiceResearchLeadCreationForm) => {
  delete serviceResearchObject.service;

  return (
    <Formik
      enableReinitialize
      validationSchema={ServiceResearchObjectValidation}
      initialValues={initialValuesHelper(serviceResearchObject) as ServiceResearchObject}
      onSubmit={onSubmit}
    >
      {props => <ResearchObjectBusinessForm {...props} service={service} />}
    </Formik>
  );
};
export default ServiceResearchLeadCreationForm;
