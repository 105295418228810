import React from "react";
import FormControl from "@material-ui/core/FormControl";
import { Field, FieldProps } from "formik";
import { TextField } from "formik-material-ui";
import { useIndustriesQuery, Industry } from "../../../generated/graphql";
import { fetchPolicy } from "../../../utils/constants";

import Autocomplete from "../Autocomplete";

type TIndustrySelect = {
  industry: any;
  setIndustry: (p: any) => void;
};
const IndustrySelect = ({ industry, setIndustry }: TIndustrySelect) => {
  const { data } = useIndustriesQuery({ fetchPolicy });
  const industries = (data?.industries || []) as Industry[];
  return (
    <FormControl margin="normal" fullWidth>
      <Field
        name="industryId"
        component={TextField}
        render={({ field, form: { setFieldValue, errors, touched } }: FieldProps) => {
          return (
            <Autocomplete
              hasError={!!errors[field.name] && !!touched[field.name]}
              error={!!errors[field.name] && !!touched[field.name] ? errors[field.name] : undefined}
              label="Branche"
              placeholder="Bitte ausfüllen"
              handleChange={(o: any) => {
                setFieldValue(field.name, o.value);
                setIndustry(o);
              }}
              value={industry}
              suggestions={
                industries
                  ? [
                      { value: "", label: "-" },
                      ...industries.map((industry: Industry) => {
                        if (industry) {
                          return {
                            value: industry.id,
                            label: industry.name,
                          };
                        }
                        return null;
                      }),
                    ]
                  : [{ value: "", label: "-" }]
              }
            />
          );
        }}
      />
    </FormControl>
  );
};

export default IndustrySelect;
