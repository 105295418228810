import React from "react";
import { Link as RouterLink } from "react-router-dom";

import Link from "@material-ui/core/Link/Link";

import { Person, Topic, Service } from "../../../generated/graphql";

type TTopicOrServiceLink = {
  person: Person;
  topic?: Topic | null;
  service?: Service | null;
};

const TopicOrServiceLink = ({ person, topic, service }: TTopicOrServiceLink) => {
  let target = `/person/${person.id}`;
  if (topic && topic.id) {
    target += `/topics/${topic.id}`;
  }
  if (service && service.id) {
    target = `/service/${service.id}`;
  }

  return (
    <Link component={RouterLink} to={target} underline="always" variant="h4">
      {topic?.name || ""}
      {service && `, ${service?.serviceMeta?.name}`}
    </Link>
  );
};

export default TopicOrServiceLink;
