import React, { FC } from "react";

import IconButton from "@material-ui/core/IconButton";

import useStyles from "./styles";

type TCustomIconButton = {
  onClick: () => void;
};

const CustomIconButton: FC<TCustomIconButton> = ({ children, onClick }) => {
  const classes = useStyles();

  return (
    <IconButton classes={{ root: classes.iconButton }} onClick={onClick}>
      {children}
    </IconButton>
  );
};

export default CustomIconButton;
