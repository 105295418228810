import * as Yup from "yup";
import dayjs from "dayjs";

const dateErrorMessage = "Startzeitpunkt darf nicht nach dem Endzeitpunkt liegen.";
const outsideOfShiftErrorMessage =
  "Der Termin liegt außerhalb der Schicht von mindestens einem Teilnehmer";

export const customHackyDateValidation = (
  setFieldError: (field: "from" | "to", message: string) => void,
  from: dayjs.Dayjs,
  to: dayjs.Dayjs,
) => {
  if (!to.isAfter(from)) {
    setFieldError("to", dateErrorMessage);
  } else {
    setFieldError("to", "");
  }
  if (!from.isBefore(to)) {
    setFieldError("from", dateErrorMessage);
  } else {
    setFieldError("from", "");
  }
};

export const customHackyDateValidationForShiftBoundary = (
  setFieldError: (field: "from" | "to", message: string) => void,
  selectedTime: dayjs.Dayjs,
  shiftStarts: dayjs.Dayjs,
  shiftEnds: dayjs.Dayjs,
) => {
  if (selectedTime.isAfter(shiftEnds) || selectedTime.isBefore(shiftStarts)) {
    setFieldError("to", outsideOfShiftErrorMessage);
    setFieldError("from", outsideOfShiftErrorMessage);
  } else {
    setFieldError("to", "");
    setFieldError("from", "");
  }
};

const validationSchema = Yup.object().shape({
  isAppointment: Yup.boolean(),
  userIds: Yup.array().min(1),
  from: Yup.date().max(Yup.ref("to"), dateErrorMessage),
  to: Yup.date().min(Yup.ref("from"), dateErrorMessage),
  title: Yup.string()
    .min(1)
    .required("Bitte ausfüllen"),
  personId: Yup.number()
    .nullable()
    .when("isAppointment", {
      is: true,
      then: Yup.number()
        .min(1, "Bitte auswählen")
        .required("Bitte auswählen"),
      otherwise: Yup.number(),
    }),
});

export default validationSchema;
