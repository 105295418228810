import React from "react";

import IconButton from "@material-ui/core/IconButton";
import Calendar from "@material-ui/icons/Event";

type TCalendarButton = {
  color?: "primary" | "inherit";
  className?: string;
  onClick?: () => void;
  onMouseOver?: () => void;
};

const CalendarButton = ({ color, className, onClick, onMouseOver }: TCalendarButton) => {
  return (
    <IconButton onClick={onClick} onMouseOver={onMouseOver} color="inherit" className={className}>
      <Calendar color={color} />
    </IconButton>
  );
};

export default CalendarButton;
