import React, { FC } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import DefaultDialog from "../../molecules/DefaultDialog";
import DefaultDialogContent from "../../molecules/DefaultDialogContent";

import PrimaryButton from "../../atoms/PrimaryButton";
import { ReadOnlyEditor } from "../../molecules/SlateRTE";

interface ResearchHintDialogProps {
  isOpen: boolean;
  text: string;
  toggle: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResearchHintDialog: FC<ResearchHintDialogProps> = ({ isOpen, toggle, text }) => {
  return (
    <DefaultDialog maxWidth="md" open={isOpen} onClose={() => toggle(false)}>
      <DefaultDialogContent>
        <Typography variant="h1" align="center">
          Leitfaden
        </Typography>
        <ReadOnlyEditor value={text} />
        <Box marginTop={4} justifyContent="center" display="flex" flexDirection="row-reverse">
          <PrimaryButton tabIndex={0} onClick={() => toggle(false)}>
            Schließen
          </PrimaryButton>
        </Box>
      </DefaultDialogContent>
    </DefaultDialog>
  );
};

export default ResearchHintDialog;
