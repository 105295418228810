import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    taskLine: {            
    },
    dueDate: {
      position: "absolute",
      top: 0,
      right: 0,
      background: "#ffdbdb",
      padding: "0 8px 0 8px",
      borderRadius: "2px",
      borderBottom: "2px solid #ff8e8e",
    },
    topicBox: {
      border: "1px solid #dbdbdb",
      borderRadius: "5px",
      background: "#e3e3e3",
      display: "flex",
      alignContent: "center",
      alignItems: "center",
      height: "fit-content",
      maxWidth: "fit-content",
    },
    topic: {
      opacity: 0.7,
    },
    expand: {
      cursor: "pointer",
      "&:hover": {
        background: "white",
      },
    },
    group: {
      "&>div": {
        borderTop: "solid #BDBDBD 1px",
        "&:last-child": {
          borderBottom: "solid #BDBDBD 1px",
        },
      },
    },
  };
});

export default useStyles;
