import { useEffect } from "react";
import {
  useMyCmClientsQuery,
  MyCmClientsSubscriptionSubscription,
  MyCmClientsSubscriptionDocument,
  Client,
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

export const useMyCMClientsSubscription = () => {
  const { data, loading, subscribeToMore } = useMyCmClientsQuery({ fetchPolicy });

  useEffect(() => {
    const unsubscribe = subscribeToMore<MyCmClientsSubscriptionSubscription>({
      document: MyCmClientsSubscriptionDocument,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !prev) return prev;
        return { myCMClients: subscriptionData.data.myCMClientsSubscription };
      },
    });

    return unsubscribe;
  });
  return { clients: (data?.myCMClients || []) as Client[], loading };
};
