import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    flexGrow: 1,
    marginTop: theme.spacing(8),
    padding: theme.spacing(0, 3, 1),
  },
}));

export default useStyles;
