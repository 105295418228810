import { Team } from "../generated/graphql";

export default function getTeamName(team?: Team | null): string {
  switch (team) {
    case Team.accountManagement:
      return "Account Management";
    case Team.serviceManagement:
      return "People & Culture";
      //return "Service Management";
    case Team.administrators:
      return "Administrator";
    case Team.lifeManagement:
      return "Life Services";
      //return "Life Management";
    case Team.caseManagement:
      return "Case Management";
    case Team.clinicalAdministration:
      return "Clinical Administration";
    case Team.counseling1stLevel:
      return "Counseling 1st Level";
    case Team.counseling2ndLevel:
      return "Counseling 2nd Level";
    case Team.counselingTC:
      return "Counseling TC";
    case Team.digitalServices:
      return "Digital Services";
    default:
      return "Andere";
  }
}
