import { Account } from "../../../generated/graphql";

export const SET_STEP = "SET_STEP";
export const SET_TYPE = "SET_TYPE";
export const SET_ACCOUNT = "SET_ACCOUNT";
export const SET_NAME = "SET_NAME";
export const SET_ASSISTANCE_ID = "SET_ASSISTANCE_ID";
export const RESET_ACCOUNT = "RESET_ACCOUNT";

export enum STEPS {
  ASSIGN_MATCH,
  ASSIGN_OR_CREATE,
  TYPE,
  ACCOUNT,
  ASSISTANCE,
  BUSINESS,
  ACCOUNT_CONFIRM,
}

export enum TYPES {
  UNDEFINED,
  CLIENT,
  PERSON,
  BUSINESS,
}

export type Action =
  | { type: typeof SET_TYPE; payload: TYPES }
  | { type: typeof SET_STEP; payload: STEPS }
  | { type: typeof SET_NAME; payload: string }
  | { type: typeof SET_ACCOUNT; payload: Account }
  | { type: typeof SET_ASSISTANCE_ID; payload: string }
  | { type: typeof RESET_ACCOUNT };

export type State = {
  step: STEPS;
  type: TYPES;
  name?: string;
  account?: Account;
  assistanceId?: string;
};

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case SET_STEP:
      return { ...state, step: action.payload };
    case SET_NAME:
      return { ...state, name: action.payload, step: STEPS.TYPE };
    case SET_TYPE:
      let { step } = state;
      // Determine type specific next step
      if (action.payload === TYPES.CLIENT) {
        step = STEPS.ACCOUNT;
      } else if (action.payload === TYPES.PERSON) {
        step = STEPS.ACCOUNT_CONFIRM;
      } else if (action.payload === TYPES.BUSINESS) {
        step = STEPS.BUSINESS;
      }

      return { ...state, type: action.payload, step };
    case SET_ACCOUNT:
      return {
        ...state,
        account: action.payload,
        step: action.payload.isAssistance ? STEPS.ASSISTANCE : STEPS.ACCOUNT_CONFIRM,
      };
    case SET_ASSISTANCE_ID:
      return {
        ...state,
        assistanceId: action.payload,
        step: STEPS.ACCOUNT_CONFIRM,
      };
    case RESET_ACCOUNT:
      return {
        ...state,
        account: undefined,
        step: !!state.account ? STEPS.ACCOUNT : STEPS.TYPE,
      };
    default:
      return { ...state };
  }
};

export const initialState: State = {
  step: STEPS.ASSIGN_MATCH,
  type: TYPES.UNDEFINED,
  account: undefined,
  assistanceId: undefined,
  name: undefined,
};
