import React from "react";
import { Dayjs } from "dayjs";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import { TimePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

type TTimeDialog = {
  handleChange: (date: any) => void;
  handleClose: () => void;
  handleDone: (date: MaterialUiPickersDate) => void;
  handleOpen: () => void;
  label: string;
  show: boolean;
  value: Dayjs;
};

const TimeDialog = ({
  handleChange,
  handleClose,
  handleDone,
  handleOpen,
  label,
  show,
  value,
}: TTimeDialog) => {
  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle>{label}</DialogTitle>
      <TimePicker
        ampm={false}
        cancelLabel="Abbrechen"
        okLabel="OK"
        onAccept={date => date && handleDone(date)}
        onChange={date => date && handleChange(date)}
        onClose={handleClose}
        onOpen={handleOpen}
        open
        value={value}
      />
    </Dialog>
  );
};
export default TimeDialog;
