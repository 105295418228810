import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import classnames from "classnames";
import React, { FC, useEffect, useState } from "react";
import {
  AccountType,
  PersonQuery,
  useBreadCrumbInfoByPersonIdQuery,
  useGetCaseManagementReviewQuery,
  useGetMemoForClientByPersonIdQuery,
} from "../../../generated/graphql";
import useScrollTop from "../../../hooks/useScrollTop";
import { useAuthorization } from "../../../providers/AuthorizationProvider";
import { fetchPolicy } from "../../../utils/constants";
import Loading from "../../atoms/Loading";
import Markup from "../../atoms/Markup";
import PersonalCard from "../../molecules/PersonalCard";
import PersonNote from "../../molecules/PersonNote";
import Tab from "../../molecules/Tab";
import PersonLayout from "../../templates/PersonLayout";
import CaseManagementContainer from "../CaseManagement";
import TopicList from "../TopicList";
import useStyles from "./styles";
import helperIllu1 from "../../assets/qualtric_start.svg";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import { useHistory, useLocation, useParams } from "react-router";

type TPersonDashboard = { person: PersonQuery["person"]; id: number };

type TQualtricsDocumentation = {
  personId: number;
  onClose?: () => void;
};

const QualtricsDocumentation: FC<TQualtricsDocumentation> = props => {
  const { push } = useHistory();
  return (
    <div
      style={{
        //backgroundColor: 'transparent',
        position: "fixed",
        right: "40px",
        top: "calc(100vh - 100px)",
        zIndex: 10,
        background: "#F9E9E2",
        padding: "8px",
        borderRadius: "5px",
      }}
    >
      <Box>
        <StupidLittleHelper type={true} />
      </Box>
    </div>
  );
};

function StupidLittleHelper({ type }: { type: boolean }) {
  return type ? (
    <div
      style={{
        //backgroundColor: 'transparent',
        position: "fixed",
        right: "40px",
        top: "calc(100vh - 240px)",
        zIndex: 10,
        background: "#F9E9E2",
        padding: "8px",
        borderRadius: "5px",
      }}
    >
      <Box maxWidth={260}>
        <Typography variant="h4">Qualtrics-Dokumentation?</Typography>
      </Box>
      <Box mt={1}>
        <object type="image/svg+xml" data={helperIllu1}></object>
      </Box>
    </div>
  ) : (
    <></>
  );
}

const PersonDashboard = ({ person, id }: TPersonDashboard) => {
  const { search } = useLocation();
  const parameters = new URLSearchParams(search);
  const isFromCallDialog = parameters.get("cd");
  const { isAuthorized } = useAuthorization();
  const [isTop, setIsTop] = useState(true);
  const classes = useStyles();
  const [isQualtrics, setIsQualtrics] = useState(false);
  const [showQualtrics, setShowQualtrics] = useState(false);
  useScrollTop();
  const { data } = useGetCaseManagementReviewQuery({
    variables: {
      personId: person.id!,
    },
    fetchPolicy,
  });

  const personId = person?.id;
  const { data: breadcrumbData, loading: loadingBreadcrumb } = useBreadCrumbInfoByPersonIdQuery({
    skip: !personId,
    variables: {
      id: personId!,
    },
  });

  useEffect(() => {
    setShowQualtrics(isQualtrics);
  }, [isQualtrics]);

  const { data: memo, loading: loadingMemo } = useGetMemoForClientByPersonIdQuery({
    skip: id !== null ? false : true,
    variables: { id: personId },
  });

  if (loadingMemo || loadingBreadcrumb) {
    return <Loading />;
  }

  const clientId = person.client?.id;

  if (person.client?.account) {
    if (person.client.account.type === AccountType.lifeWorks && !isQualtrics && isFromCallDialog) {
      setTimeout(() => {
        setIsQualtrics(true);
      }, 1000);
    }
  }

  return (
    <PersonLayout
      person={person}
      contractExpirationDate={breadcrumbData?.breadCrumbInfoByPersonId?.contractEnd}
      language={breadcrumbData?.breadCrumbInfoByPersonId?.language || ""}
      mapList={[]}
    >
      <>
        <Box bgcolor="#EFE8DD" width={1} py={2} marginBottom={4}>
          <Grid container>
            <Grid item xs={9}>
              <Box display="flex" ml={3} alignItems="center">
                <Tab type="permContactCalendar" target={`/person/${id}/contacts`}>
                  Adressbuch
                </Tab>
                <Tab type="insertDriveFile" target={`/person/${id}/documents`}>
                  Dokumente
                </Tab>
                {person.client && isAuthorized("/person/:id/caseManagement") && (
                  <Tab
                    type="assignment"
                    target={`/person/${id}/caseManagement`}
                    active={!!data?.getCaseManagementReview?.id}
                  >
                    Case Management
                  </Tab>
                )}
                {/* {clientId && (
                  <Tab
                    type="gavel"
                    target={`/person/${id}/client/${clientId}/complaints`}
                  >
                    Beschwerden
                  </Tab>
                )}           */}
                {/* <Box mb={6} p={3} style={{ background: "#F9F3E9CC" }} key={1}>
                  {showQualtrics && (
                    <QualtricsDocumentation
                      onClose={() => {
                        setShowQualtrics(false);
                      }}
                      personId={personId}
                    />
                  )}
                </Box> */}
              </Box>
            </Grid>
            <Grid
              className={isTop ? classes.isTop : classes.isNotTop}
              item
              xs={3}
              onClick={e => {
                if (!isTop) {
                  setIsTop(!isTop);
                }
              }}
            >
              <PersonNote person={person} />
            </Grid>
            {/* <Grid item xs={9}>
              <Box display="flex" ml={3} mt={2} alignItems="center">
              {clientId && (
                  <Tab
                    type="gavel"
                    target={`/person/${id}/client/${clientId}/complaints`}
                  >
                    Beschwerden
                  </Tab>
                )}
              </Box>
            </Grid> */}
          </Grid>
        </Box>
        {memo && memo?.getMemoForClientByPersonId && (
          <Box
            className={!isTop ? classes.isTop : classes.isNotTop}
            marginBottom={8}
            onClick={e => {
              if (isTop) {
                setIsTop(!isTop);
              }
            }}
          >
            <Box className={classes.memoContainer}>
              <Markup value={memo.getMemoForClientByPersonId!} key={`info${personId}`} />
            </Box>
          </Box>
        )}
        <div
          className={classnames(classes.personCard, {
            noNote: !person.note || person.note.length === 0,
          })}
        >
          <PersonalCard person={person} />
        </div>
        <CaseManagementContainer person={person} />

        {person.client && <TopicList client={person.client} name={person.name || ""} />}
      </>
    </PersonLayout>
  );
};

export default PersonDashboard;
