import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme: Theme) => ({
  root: {
    color: "transparent",
    height: 49,
    width: "auto",
  },
  big: {
    height: 70,
  },
}));
