import React, { FC } from "react";

import Box from "@material-ui/core/Box";
import MuLink from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

import { CaseManagementReview, Maybe, Topic } from "../../../generated/graphql";

import Flag from "../../atoms/Flag";
import Loading from "../../atoms/Loading";
import ActivityItem from "../../molecules/ActivitiesItem";

import { useGetCaseManagementReviewsSubscription } from "../../../hooks/useGetCaseManagementReviewsSubscription";

import { Chip, Grid } from "@material-ui/core";
import empty from "../../assets/empty.svg";
import useStyles from "./styles";

const sortByLastActivity = (
  a: Maybe<Pick<CaseManagementReview, "lastUpdate">>,
  b: Maybe<Pick<CaseManagementReview, "lastUpdate">>,
) => {
  if (dayjs(a?.lastUpdate).isAfter(b?.lastUpdate)) {
    return -1;
  }
  if (dayjs(b?.lastUpdate).isAfter(a?.lastUpdate)) {
    return 1;
  }

  return 0;
};

const ActivitiesFlags: FC = () => {
  const classes = useStyles();
  const { getCaseManagementReviews, loading } = useGetCaseManagementReviewsSubscription({
    includeValues: ["whiteFlag", "yellowFlag", "redFlag"],
    clientReviewAttributeId: 10,
  });

  if (loading) {
    return <Loading />;
  }

  const init = new Map<string, any[]>([
    ["redFlag", []],
    ["yellowFlag", []],
    ["whiteFlag", []],
    // ["unflagged", []],
  ]);

  const reviewGroups = getCaseManagementReviews
    ?.slice()
    ?.sort(sortByLastActivity)
    ?.reduce((carry, caseManagementReview) => {
      const caseManagementReviewAttributes = caseManagementReview?.caseManagementReviewAttributes;

      if (!caseManagementReviewAttributes?.length || caseManagementReviewAttributes.length > 1) {
        console.error({ error: caseManagementReview });
        return carry;
      }

      const flag = caseManagementReviewAttributes[0];

      carry.get(flag!.attributeValue!)!.push({
        avatar: {
          src:
            caseManagementReview?.caseManager?.user?.person?.picture ||
            caseManagementReview?.caseManager?.user?.person?.avatar,
          keys: caseManagementReview?.caseManager?.user?.person?.avatarKeys,
        },
        user: caseManagementReview?.caseManager?.user,
        account: caseManagementReview?.client?.account,
        person: caseManagementReview?.client?.person,
        topics: caseManagementReview?.client?.topics,
        info: {
          lastFlagUpdate: flag?.lastUpdate,
          lastReview: caseManagementReview?.lastUpdate,
        },
      });

      return carry;
    }, init);

  const groups =
    reviewGroups &&
    [...reviewGroups]
      .map(([title, reviews]) => {
        if (!reviews || reviews.length < 1) {
          return null;
        }

        return (
          <Box key={`${title}`} pb={2}>
            <Box mt={3} mb={1} display="flex" alignItems="center">
              <Box p={title === "whiteFlag" ? 0 : 1} display="flex" alignItems="center">
                <Flag
                  red={title === "redFlag"}
                  yellow={title === "yellowFlag"}
                  white={title === "whiteFlag"}
                />
              </Box>
              <Typography variant="h3">
                {
                  {
                    redFlag: "Rote Flagge",
                    yellowFlag: "Gelbe Flagge",
                    whiteFlag: "Weiße Flagge",
                  }[title as "redFlag" | "yellowFlag" | "whiteFlag"]
                }
              </Typography>
            </Box>
            <Box className={classes.group}>
              {reviews.map((review: any) => {
                const title = review.topics.map((topic: Topic) => {
                  return (
                    <MuLink
                      component={Link}
                      to={`/person/${review.person.id}/topics/${topic.id}`}
                      color="primary"
                      underline="always"
                      key={`topic-${topic.id}`}
                      variant="h4"
                    >
                      {topic.name}
                    </MuLink>
                  );
                });
                return (
                  <ActivityItem
                    key={`review-${review.person.id}`}
                    account={review.account}
                    avatar={review.avatar}
                    user={review.user}
                    person={review.person}
                    title={<Box className={classes.csv}>{title}</Box>}
                    info={
                      <Box display="flex">
                        <Box flex={1}>
                          <Typography variant="body2">
                            Aktuelle Flagge seit:{" "}
                            {dayjs(review.info.lastFlagUpdate).format("DD.MM.YYYY")}
                          </Typography>
                        </Box>
                        <Box mx={1.5} />
                        <Box flex={1}>
                          <Typography variant="body2">
                            Letzte Review: {dayjs(review.info.lastReview).format("DD.MM.YYYY")}
                          </Typography>
                        </Box>
                      </Box>
                    }
                  />
                );
              })}
            </Box>
          </Box>
        );
      })
      .filter(_ => _ !== null);

  return (
    <>
      <Grid container className={`${classes.container}`}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.headerContainer}
        >
          <Grid item xs={4}>
            <MuLink
              className={`${classes.filterLabel}`}
              component={Link}
              to={`/activities/flags/caseManager`}
              color="primary"
              underline="always"
              key={`flags`}
              variant="h3"
              style={{ marginLeft: "16px" }}
            >
              Case Manager
            </MuLink>
            <Chip
              key={"Flaggen"}
              label={"Flaggen"}
              color={"default"}
              classes={{ root: classes.chip, label: classes.chipLabel }}
            />
          </Grid>
          <Grid xs={4} container alignItems="center" justifyContent="center">
            <h3>Flaggen Übersicht</h3>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </Grid>

      {!groups || groups.length < 1 ? (
        <Box mt={10} textAlign="center">
          <img src={empty} alt="Es gibt derzeit keine geflaggten Klienten" />
          <Box my={2} />
          <Typography variant="body2">Es gibt derzeit keine geflaggten Klienten</Typography>
        </Box>
      ) : (
        groups
      )}
    </>
  );
};

export default ActivitiesFlags;
