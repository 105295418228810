import React from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { Select } from "formik-material-ui";
import { Field } from "formik";

import { RelationshipType } from "../../../generated/graphql";
import { TabIndex } from "../../../types";

type TRelationTypeSelect = { name: string } & TabIndex;
const RelationTypeSelect = ({ name, tabIndex = 0 }: TRelationTypeSelect) => {
  const options = [];
  for (const [relationType, relationValue] of RelationshipTypeMap) {
    options.push(
      <MenuItem key={relationType} value={relationType}>
        {relationValue}
      </MenuItem>,
    );
  }

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel htmlFor={name}>Beziehung</InputLabel>
      <Field name={name} label="Beziehung" component={Select} tabIndex={tabIndex}>
        {options}
      </Field>
    </FormControl>
  );
};

const RelationshipTypeMap = new Map<RelationshipType, string>([
  [RelationshipType.unknown, "Unbekannt"],
  [RelationshipType.wife, "Ehefrau"],
  [RelationshipType.husband, "Ehemann"],
  [RelationshipType.partner, "Partner"],
  [RelationshipType.expartner, "Ex-Partner"],
  [RelationshipType.friend, "Freund"],
  [RelationshipType.mother, "Mutter"],
  [RelationshipType.father, "Vater"],
  [RelationshipType.daughter, "Tochter"],
  [RelationshipType.son, "Sohn"],
  [RelationshipType.grandmother, "Großmutter"],
  [RelationshipType.grandfather, "Großvater"],
  [RelationshipType.aunt, "Tante"],
  [RelationshipType.uncle, "Onkel"],
  [RelationshipType.other, "Sonstiges"],
]);

export default RelationTypeSelect;
