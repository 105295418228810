import React from "react";

import { useParams } from "react-router";

import CaseManagementWizard from "../../forms/CaseManagementWizard";

const CaseManagementReview = () => {
  const { id } = useParams<{ id: string }>();

  return <CaseManagementWizard personId={parseInt(id)} />;
};

export default CaseManagementReview;
