import React from "react";

import classnames from "classnames";
import groupBy from "lodash/groupBy";
import { useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

import { useGetGMailThreadsQuery } from "../../../generated/graphql";
import { fetchPolicy } from "../../../utils/constants";

import useStyles from "../inboxStyles";
import InboxLabelFilter from "../../molecules/InboxLabelFilter";

import EmailInboxView from "./EmailInboxView";
import IllustratedErrorMessage from "../../organisms/IllustratedErrorMessage";
import PrimaryButton from "../../atoms/PrimaryButton";
import errorIllustration from "../../assets/errorIllustration.svg";
import Loading from "../../atoms/Loading";

const useGetGMailThreadsSubscription = () => {
  const { data, loading, error } = useGetGMailThreadsQuery({
    pollInterval: 10000,
    fetchPolicy,
  });

  return { data, loading, error };
};

const EmailInbox = () => {
  const classes = useStyles();
  const { goBack } = useHistory();

  const { data, loading, error } = useGetGMailThreadsSubscription();

  if (loading) {
    return <Loading />;
  }

  if (!data?.getGMailThreads || error) {
    return (
      <IllustratedErrorMessage
        messages={["Die E-Mails konnten nicht geladen werden."]}
        illustration={errorIllustration}
        illustrationAltText="Elli rutscht auf einer Banane aus"
      >
        <PrimaryButton onClick={goBack}>Zurück</PrimaryButton>
      </IllustratedErrorMessage>
    );
  }

  let threaded = groupBy(data.getGMailThreads, "threadId");

  let threads = Object.keys(threaded)?.map((threadId: any) => {
    return threaded[threadId];
  });

  if (!threads) {
    return null;
  }

  // If sorting troubles:
  // threads = threads?.sort((a: Maybe<GMailMessage>[], b: Maybe<GMailMessage>[]) => {
  //   const emailA = dayjs(sortBy(a, "date", "DESC")[0].payload.date);
  //   const emailB = dayjs(sortBy(b, "date", "DESC")[0].payload.date);

  //   if (emailA.isBefore(emailB)) {
  //     return 1;
  //   }

  //   if (emailA.isAfter(emailB)) {
  //     return -1;
  //   }

  //   return 0;
  // });

  return (
    <Grid container className={classnames(classes.content, classes.contentBox)}>
      <InboxLabelFilter />
      <EmailInboxView threads={threads} />
    </Grid>
  );
};

export default EmailInbox;
