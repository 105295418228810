import { useEffect } from "react";
import {
  usePersonsByBusinessQuery,
  PersonsByBusinessSubscriptionDocument,
  PersonsByBusinessSubscriptionSubscription,
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

export const usePersonsByBusinessSubscription = (businessId: number) => {
  const { data, loading, subscribeToMore, error } = usePersonsByBusinessQuery({
    variables: { businessId },
    fetchPolicy,
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore<PersonsByBusinessSubscriptionSubscription>({
      document: PersonsByBusinessSubscriptionDocument,
      variables: { businessId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !prev) return prev;
        return {
          ...prev,
          ...{ personsByBusiness: subscriptionData.data.personsByBusinessSubscription },
        };
      },
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId]);

  return { persons: data?.personsByBusiness || [], loading, error };
};
