import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  Maybe,
  Person,
  Service,
  usePersonQuery,
  useServiceQuery,
  useUpdateServiceMutation,
  ServiceWizardStatus,
  ServiceStatus,
  ServiceType,
} from "../../../generated/graphql";
import { fetchPolicy } from "../../../utils/constants";
import Loader from "../../atoms/Loading";
import ServiceWizard from "../../forms/ServiceWizard";

interface UseService {
  service: Maybe<Service>;
  loading: boolean;
  refetchService: () => void;
}

const useService = (serviceId: string): UseService => {
  const { data, loading, refetch } = useServiceQuery({
    variables: { id: parseInt(serviceId, 10) },
    fetchPolicy: "no-cache",
  });
  const service = data ? data.service : null;
  return { service, loading, refetchService: refetch };
};

interface UsePerson {
  person: Maybe<Person>;
  loadingPerson: boolean;
  refetchPerson: () => void;
}
const usePerson = (personId: string): UsePerson => {
  const { data, loading, refetch } = usePersonQuery({
    variables: { id: parseInt(personId, 10) },
    fetchPolicy,
  });
  const person = data?.person || null;
  return { person, loadingPerson: loading, refetchPerson: refetch };
};

export default function CounselingSetup() {
  const { push, goBack } = useHistory();
  const { id, serviceId } = useParams<{ id: string; serviceId: string }>();

  const { person, loadingPerson, refetchPerson } = usePerson(id);
  const { service, loading, refetchService } = useService(serviceId);

  const [updateService, { data }] = useUpdateServiceMutation();
  localStorage.setItem("createdConsultingId", serviceId);
  const updateStep = data?.updateService?.wizardStep || service?.wizardStep || 0;
  const [step, setStateStep] = useState<number>(updateStep);

  if (person === null || service === null) return <Loader />;

  const setStep = (wizardStep: number) => {
    const attributes = service?.serviceMeta?.serviceMetaAttributes || [];
    const status = service.status;
    const serviceData = {
      status,
      wizardStep,
      wizardStatus: service.wizardStatus,
    };
    if (wizardStep >= attributes.length) {
      localStorage.removeItem("createdConsultingId");
      serviceData.wizardStatus = ServiceWizardStatus.complete;
      serviceData.status =
        service.serviceMeta?.type === ServiceType.internalConsult
          ? ServiceStatus.started
          : ServiceStatus.research;
    }

    updateService({
      variables: {
        id: service!.id!,
        service: {
          ...serviceData,
        },
      },
    }).then(() => {
      refetchService();
      setStateStep(wizardStep);
    });
  };
  const update = () => {
    if (person && refetchPerson) {
      refetchPerson();
    }
    if (service && refetchService) {
      refetchService();
    }
  };
  const stepToUse =
    step === 0 && service.wizardStep && service.wizardStep > step ? service.wizardStep : step;

  const handleBack = () => setStep(stepToUse - 1);

  const backAction = () => {
    if (stepToUse === 0) {
      // todo go back to topic selection
      if (service && service.serviceMeta && service.serviceMeta.type) {
        return push(`/person/${id}/${service.serviceMeta.type}`);
      }
      return goBack();
    }
    return handleBack();
  };
  const serviceName = service.serviceMeta?.name || "Interne strukturierte Beratung";
  const personName = person?.name || "";
  return (
    <ServiceWizard
      mainHeadline={`${serviceName} für ${personName} erstellen`}
      update={update}
      person={person}
      service={service}
      step={stepToUse}
      setStep={setStep}
      backAction={backAction}
      isReloading={loadingPerson || loading}
    />
  );
}
