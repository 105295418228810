import React, { FC } from "react";
import { useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";

import DataRow from "../../molecules/DataRow";
import AddressItem from "../../atoms/Address";
import FormattedHours from "../../atoms/FormattedHours";
import Markup from "../../atoms/Markup";
import DataColumn from "../../molecules/DataColumn";
import ContactPersonCard from "../../molecules/ContactPersonCard";
import { Account, BusinessObject } from "../../../generated/graphql";
import TariffBadge from "../../molecules/TariffBadge";

const BusinessCard: FC<{ business: BusinessObject }> = ({ business }) => {
  const { push } = useHistory();

  const hours = business?.hours || [];
  const address = business?.address || null;

  return (
    <Grid container>
      <DataRow
        defaultIcon
        title="Allgemeine Informationen"
        editTrigger={() => push(`/business/${business.id}/details`)}
      >       

        <DataColumn xs={4} label="Adresse">
          {address && <AddressItem address={address} />}
        </DataColumn>

        <DataColumn label="Kategorie" xs={4}>
          <Typography variant="body2" key={`category${business.id}`}>
            {business?.category?.name || "-"}
          </Typography>
        </DataColumn>

        <Grid item xs={4} />

        <DataColumn xs={4} label="Telefonnummer">
          <Typography variant="body2" key={`phone${business.id}`}>
            {business?.phones?.[0]?.readableNumber || "-"}
          </Typography>
        </DataColumn>

        <DataColumn xs={4} label="E-Mail-Adresse">
          <Typography variant="body2" key={`email${business.id}`}>
            {business?.email?.email || "-"}
          </Typography>
        </DataColumn>

        <DataColumn xs={4} label="Webseite">
          {!!business?.url ? (
            <Link
              href={business?.url || ""}
              target="_blanck"
              color="primary"
              underline="always"
              key={`site${business.id}`}
              variant="h4"
            >
              {business?.url || ""}
            </Link>
          ) : (
            <Typography variant="body2" key={`webseite${business.id}`}>
              -
            </Typography>
          )}
        </DataColumn>

        <DataColumn xs={4} label="Account">
          {business?.accountId &&
            <Box pr={1}>
              <TariffBadge account={{
                id: business.accountId
              } as Account} fetch />
            </Box>
          }
          {!business?.accountId &&
          <Box pr={1}>-</Box>
          }
        </DataColumn>

        <DataColumn xs={12} label="Sonstiges">
          <Markup value={business?.info || "-"} key={`info${business.id}`} />
        </DataColumn>
      </DataRow>

      {!!business.persons?.filter(p => !!p && !p.temporary).length && (
        <ContactPersonCard 
        person={business.persons.filter(p => !!p && !p.temporary)[0]!} 
        business={business}
        />
      )}

      {!!hours.length && (
        <DataRow title="Öffnungszeiten" defaultIcon>
          <Grid item xs={12}>
            <Box marginBottom={4}>
              <FormattedHours hours={hours} key={`hours${business.id}`} />
            </Box>
          </Grid>
        </DataRow>
      )}
    </Grid>
  );
};

export default BusinessCard;
