import React, { FC } from "react";
import { Field, FormikProps, FormikValues } from "formik";
import { TextField } from "formik-material-ui";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import PhoneCountrySelect from "../../molecules/PhoneCountrySelect";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";

import useStyles from "../formStyles";

interface FormProps {
  cancel: () => void;
}

const Form: FC<FormProps & FormikProps<FormikValues>> = ({ cancel, handleSubmit }) => {
  const classes = useStyles();
  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <PhoneCountrySelect name="businessObject.phones[0].countryCode" />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <Field name="businessObject.phones[0].areaCode" label="Vorwahl" component={TextField} />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <Field name="businessObject.phones[0].number" label="Nummer" component={TextField} />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <Field name="businessObject.email.email" label="E-Mail-Adresse" component={TextField} />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <Field name="businessObject.url" label="Webseite" component={TextField} />
          </FormControl>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center" mt={4}>
        <SecondaryButton onClick={cancel}>Abbrechen</SecondaryButton>
        <Box mx={1} />
        <PrimaryButton type="submit">OK</PrimaryButton>
      </Box>
    </form>
  );
};

export default Form;
