import React, { useState } from "react";
import { MutationFunctionOptions, useMutation } from "@apollo/client";
import { ExecutionResult } from "graphql";
import dayjs, { Dayjs } from "dayjs";
import { Hour, UpdateHourDocument } from "../../../../../generated/graphql";

import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";

import TimeDialog from "../TimeDialog";
import useStyles from "./styles";

interface HourEntryProps {
  createHour: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<ExecutionResult<any>>;
  day: number;
  defaultClose: Dayjs;
  defaultOpen: Dayjs;
  deleteHour: (id: number) => Promise<ExecutionResult<any>>;
  hour: Hour;
  serviceId?: number | null;
  showAddIcon: boolean;
  showRemoveIcon: boolean;
  update: () => void;
}

export default function HourEntry({
  createHour,
  day,
  defaultClose,
  defaultOpen,
  deleteHour,
  hour,
  serviceId,
  showAddIcon,
  showRemoveIcon,
  update,
}: HourEntryProps) {
  const classes = useStyles();
  const [showOpen, setShowOpen] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [updateHour] = useMutation(UpdateHourDocument);

  const handleUpdateClose = (date: any) => {
    updateHour({
      variables: {
        hour: {
          id: hour.id,
          close: date,
        },
      },
    }).then(() => {
      update();
    });
  };

  const handleUpdateOpen = (date: any) => {
    updateHour({
      variables: {
        hour: {
          id: hour.id,
          open: date,
        },
      },
    }).then(() => {
      update();
    });
  };

  const _createHour = () => {
    createHour({
      variables: {
        hour: {
          day,
          open: dayjs(hour.close).add(1, "hour"),
          close: dayjs(hour.close).add(4, "hour"),
          serviceId,
        },
      },
    }).then(update);
  };

  const renderRemoveIcon = () => {
    return (
      <IconButton
        aria-label="Remove hour"
        className={classes.removeHourButton}
        size="small"
        onClick={() => hour.id && deleteHour(hour.id).then(update)}
      >
        <ClearIcon fontSize="inherit" className={classes.clearIcon} />
      </IconButton>
    );
  };

  const equalsDefault =
    dayjs(hour.open).isSame(defaultOpen) && dayjs(hour.close).isSame(defaultClose);

  return (
    <Box
      alignItems="center"
      display="flex"
      className={classes.hourEntry}
      flex={1}
      paddingBottom={0.5}
      paddingRight={5}
      paddingTop={0.5}
      position="relative"
    >
      <Typography variant="body2">
        {equalsDefault && (
          <>
            {showRemoveIcon && renderRemoveIcon()}
            <Link variant="h4" underline="always" color="primary" onClick={() => setShowOpen(true)}>
              abweichenden Zeitraum eintragen
            </Link>
          </>
        )}
        {!equalsDefault && (
          <>
            {showRemoveIcon && renderRemoveIcon()}
            <Link variant="h4" underline="always" color="primary" onClick={() => setShowOpen(true)}>
              {hour.open ? dayjs(hour.open).format("H:mm") : defaultOpen.format("H:mm")}
            </Link>{" "}
            bis{" "}
            <Link
              variant="h4"
              underline="always"
              color="primary"
              onClick={() => setShowClose(true)}
            >
              {hour.close ? dayjs(hour.close).format("H:mm") : defaultClose.format("H:mm")}
            </Link>{" "}
            Uhr
          </>
        )}
      </Typography>
      {showAddIcon && (
        <IconButton
          aria-label="Add hour"
          className={classes.addHourButton}
          color="primary"
          size="small"
          onClick={() => _createHour()}
        >
          <AddIcon />
        </IconButton>
      )}
      <TimeDialog
        show={showOpen}
        handleClose={() => {
          setShowOpen(false);
          setShowClose(true);
        }}
        handleOpen={() => setShowOpen(true)}
        handleChange={handleUpdateOpen}
        handleDone={handleUpdateOpen}
        value={hour && hour.open ? dayjs(hour.open) : defaultOpen}
        label="Von"
      />
      <TimeDialog
        show={showClose}
        handleClose={() => setShowClose(false)}
        handleOpen={() => setShowClose(true)}
        handleChange={handleUpdateClose}
        handleDone={handleUpdateClose}
        value={hour && hour.close ? dayjs(hour.close) : defaultOpen}
        label="Bis"
      />
    </Box>
  );
}
