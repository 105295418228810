import React, { FC } from "react";

import Link from "@material-ui/core/Link";

import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import Avatar from "../../../../atoms/Avatar";

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const PADContact: FC = () => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" marginRight={4}>
      <Avatar widthfactor={5} className={classes.avatar}>
        PAD
      </Avatar>
      <Box width="12px" />
      <Link
        underline="none"
        target="_blank"
        href="https://calendar.google.com/calendar/embed?src=c_jqft3eeavhoc90on1a5uluvc7g%40group.calendar.google.com&ctz=Europe%2FBerlin"
        variant="body2"
      >
        Kalender anzeigen
      </Link>
    </Box>
  );
};
export default PADContact;
