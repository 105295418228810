import { Box, Grid, Typography } from "@material-ui/core";
import MuLink from "@material-ui/core/Link";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import classnames from "classnames";
import { data } from "cypress/types/jquery";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import { Chat } from "../../../generated/graphql";
import useStyles from "../../assets/inboxStyles";

import { InboxWrapper, TimeText } from "../../organisms/InboxComponent/components";
import { UserAvatarFromPerson } from "../../organisms/UserAvatar";
import { ChatActions } from "./chatItemActions";
import { InProgressChatActions } from "./inProgressChatActions";

export type TInProgressChatItem = {
  client: Chat;
};

const InProgressChatItem = ({ client }: TInProgressChatItem) => {
  const classes = useStyles();

  return (
    <InboxWrapper>
      <Grid item xs={12} md={4} className={classes.taskContentColumn}>
        <Box mr={3}>
          <UserAvatarFromPerson person={client.user?.person!} />
        </Box>
        <ChatBubbleOutlineIcon color="primary" />
        <Box ml={2} display="flex" alignItems="start" flexDirection="column" overflow="hidden">
          <Typography variant="body2">{client.personActivity?.person?.alias}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={2} className={classes.taskContentColumn}>
        <Box mr={2} color="grey.400" width="24px" height="24px"></Box>
        <TimeText
          start={client.personActivity?.lastUpdate?.toString() || dayjs().toISOString()}
          text=""
        />
      </Grid>
      <Grid item xs={12} md={3} className={classes.taskContentColumn}>
        <Box paddingX={2}>
          <Box>
            <MuLink
              to={`/person/${client.personActivity?.person?.id}`}
              component={Link}
              className={classnames([classes.onelineEllipsis])}
              title={client.personActivity?.person.alias || ""}
              color="primary"
              underline="always"
              variant="h4"
            >
              {client.personActivity?.person.alias}
            </MuLink>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={3} className={classes.taskContentColumn}>
        <InProgressChatActions chat={client} mayFinish={true} />
      </Grid>
    </InboxWrapper>
  );
};

export default InProgressChatItem;
