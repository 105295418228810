import React, { createContext, Dispatch, FC, useEffect, useReducer } from "react";

import { useGetTokenQuery, Chat } from "../../generated/graphql";
import { useAuthorization } from "../../providers/AuthorizationProvider";
import { fetchPolicy } from "../../utils/constants";

import { reducer, Action, ChatClient, ChatConnection } from "./reducer";
import ChatMessageListener from "./ChatMessageListener";
import { useActiveChatsSubscription } from "../../hooks/useActiveChatsSubscription";

// TODO move to providers
export const ChatContext = createContext<{
  dispatch: Dispatch<Action>;
  obrowser: {
    connection?: WebSocket;
    clients?: Map<number, ChatClient>;
    memos?: Map<number, ChatClient>;
  };
  chats: Map<number, ChatConnection>;
  activeChats: Chat[];
  token?: string | null;
}>({
  dispatch: () => {},
  chats: new Map(),
  obrowser: {},
  activeChats: [],
});

const obrowserInitial = {
  connection: undefined,
  clients: new Map(),
};

type TChatListener = {
  mayChat: boolean;
};

export const ChatListener: FC<TChatListener> = ({ children, mayChat }) => {
  const { data, loading } = useActiveChatsSubscription();
  const { data: tokenData, loading: tokenLoading } = useGetTokenQuery({ fetchPolicy });
  const { me } = useAuthorization();

  const [state, dispatch] = useReducer(reducer, {
    chats: new Map(),
    obrowser: obrowserInitial,
  });

  const token = tokenData?.getToken;

  useEffect(() => {
    if (!loading && !tokenLoading && token) {
      dispatch({
        type: "connectGlobal",
        token: token,
      });
    }

    return () =>
      dispatch({
        type: "disconnectGlobal",
      });
  }, [loading, tokenLoading, token]);

  const connection = state.obrowser.connection;
  const activeChats = data?.activeChats as Chat[];

  if (!connection || !me || !token) {
    return <>{children}</>;
  }

  const teams = me.user?.teams ?? [];

  return (
    <ChatContext.Provider value={{ ...state, dispatch, activeChats, token: token }}>
      <ChatMessageListener
        activeChats={activeChats}
        connection={connection}
        mayChat={mayChat}
        teams={teams}
      >
        {children}
      </ChatMessageListener>
    </ChatContext.Provider>
  );
};

export default ChatListener;
