import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    display: "flex",
    padding: 0,
    height: 32,
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap" as "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: "absolute" as "absolute",
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: "static" as "static",
    zIndex: theme.zIndex.modal + 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
}));

export default useStyles;
