import React, { Dispatch, SetStateAction } from "react";
import dayjs from "dayjs";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import DayjsUtils from "@date-io/dayjs";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Button from "../../atoms/PrimaryButton";
import { StyledKeyboardDatePickerFitsGrid } from "../../atoms/StyledFormComponents";
import { AccountStatisticsByTypeQueryVariables } from "../../../generated/graphql";
import { QueryLazyOptions } from "@apollo/client";
import AccountTypeSelect from "../../molecules/AccountTypeSelect";

const validation = Yup.object().shape({
  type: Yup.string().required("Bitte ausfüllen"),
});

export default function StatisticsByTypeForm({
  query,
  setQuery,
  disabled = false,
}: {
  query: (options?: QueryLazyOptions<AccountStatisticsByTypeQueryVariables> | undefined) => void;
  setQuery: Dispatch<SetStateAction<string | undefined>>;
  disabled: boolean;
}) {
  return (
    <Formik
      initialValues={{
        type: "",
        start: dayjs().subtract(1, "year"),
        end: dayjs(),
      }}
      onSubmit={(values, { setSubmitting }) => {
        setQuery(undefined);
        query({
          variables: {
            type: values.type,
            start: values.start,
            end: values.end,
          },
        });

        setQuery("type");
        setSubmitting(false);
      }}
      validationSchema={validation}
    >
      {props => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AccountTypeSelect tabIndex={0} disabled={disabled} forStatistics />
            </Grid>
            <MuiPickersUtilsProvider utils={DayjsUtils} locale="de">
              <Grid item xs={6}>
                <StyledKeyboardDatePickerFitsGrid
                  name="date"
                  format="DD.MM.YYYY"
                  value={props.values.start}
                  onChange={value => {
                    props.setFieldValue("start", value || dayjs());
                  }}
                  disableFuture
                  label="Ab Datum"
                  cancelLabel="Abbrechen"
                  okLabel="OK"
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={6}>
                <StyledKeyboardDatePickerFitsGrid
                  disableFuture
                  name="date"
                  format="DD.MM.YYYY"
                  value={props.values.end}
                  onChange={value => {
                    props.setFieldValue("end", value || dayjs());
                  }}
                  label="Bis Datum"
                  cancelLabel="Abbrechen"
                  okLabel="OK"
                  disabled={disabled}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" mt={4}>
                <Button type="submit" disabled={!props.isValid || props.isSubmitting || disabled}>
                  Statistik erstellen
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
