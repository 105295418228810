import React, { FormEvent, FC } from "react";
import { FormikProps, FormikValues, Field } from "formik";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import useAutoSave from "../../../hooks/useAutoSave";
import PrimaryButton from "../../atoms/PrimaryButton";
import { Person } from "../../../generated/graphql";
import SlateRTE, { initialFormValue } from "../../molecules/SlateRTE";
import SecondaryButton from "../../atoms/SecondaryButton";
import WizardNavigation from "../../molecules/WizardNavigation";
import useStyles from "../reportStyles";

export interface IFormProps {
  person: Person;
  saveInput: (params: any) => any;
  backAction: () => void;
  allowContactSave: boolean;
}

const Step1: FC<FormikProps<FormikValues> & IFormProps> = ({
  person,
  backAction,
  handleSubmit,
  handleChange,
  isSubmitting,
  saveInput,
  setFieldValue,
  allowContactSave,
  values,
}) => {
  const timer = useAutoSave({ note: values.note }, saveInput);
  function handleTextChange(value: string) {
    setFieldValue("note", value);
  }

  const submit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    handleSubmit(event);
  };

  const classes = useStyles();
  return (
    <form onSubmit={submit} className={classes.content}>
      <SlateRTE
        value={values.note || initialFormValue}
        label="Gesprächsnotiz"
        handleChange={handleTextChange}
        tabIndex={1}
      />
      {allowContactSave && (
        <Box width={1} mt={6}>
          <Typography variant="h3" gutterBottom>{`Möchtest du „${
            values.contact
          }” in das Adressbuch von ${person.name!} speichern?`}</Typography>
          <FormControl key="saveContact" margin="normal" className={classes.checkboxInput}>
            <FormControlLabel
              control={
                <Field
                  label="Im Adressbuch speichern"
                  id="saveContact"
                  onChange={handleChange}
                  name="saveContact"
                  value={!!values.saveContact}
                  component={Checkbox}
                  tabIndex={2}
                  type="checkbox"
                />
              }
              classes={{ label: classes.checkboxLabel }}
              label="Im Adressbuch speichern"
            />
          </FormControl>
        </Box>
      )}
      <WizardNavigation>
        <PrimaryButton type="submit" disabled={isSubmitting} tabIndex={2}>
          Weiter
        </PrimaryButton>
      </WizardNavigation>
    </form>
  );
};
export default Step1;
