import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => {
  return {
    teaserPageGridClassName: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
    },
    placeholderIcon: {
      marginTop: theme.spacing(8),
    },
  };
});
