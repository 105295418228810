import React, { useState } from "react";

import { Link, useHistory } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import MuLink from "@material-ui/core/Link";

import {
  GMailInbox,
  Person,
  BusinessObject,
  useCancelGMailMessageMutation,
} from "../../../generated/graphql";

import emailIllustration from "../../assets/emailIllustration.svg";
import PrimaryButton from "../../atoms/PrimaryButton";
import PersonName from "../../molecules/PersonName";
import { useEmailThreadContext } from "../../../providers/Email";

import CallDialog from "../../organisms/CallDialog";
import SecondaryButton from "../../atoms/SecondaryButton";
import DefaultDialog from "../../molecules/DefaultDialog";
import DefaultDialogContent from "../../molecules/DefaultDialogContent";

type TEmailThreadHeader = {
  activityId?: number;
  inbox: GMailInbox;
  person?: Person | null;
  businessObject?: BusinessObject | null;
};

const EmailThreadHeader = ({ activityId, inbox, businessObject, person }: TEmailThreadHeader) => {
  const {
    thread,
    context: { topic },
  } = useEmailThreadContext();

  const [open, setOpen] = useState(false);
  const [openSpam, setOpenSpam] = useState(false);

  const [cancelMail] = useCancelGMailMessageMutation();
  const { push } = useHistory();

  const cancelMailAction = async () => {
    if (!thread?.threadId) return;
    await cancelMail({ variables: { threadId: thread.threadId as string } });
    setOpenSpam(false);
    push("/emails");
  };

  if (!person && !businessObject) {
    return null;
  }

  return (
    <Box textAlign="center">
      <img src={emailIllustration} alt="" />
      <Box>
        <Box mt={4} mb={2} display="flex" justifyContent="center">
          <Typography variant="h2">Eingehende E-Mail von</Typography>
          <Box mx={0.5} />
          {person && <PersonName person={person} variant="h1" />}
          {businessObject && (
            <MuLink
              color="primary"
              component={Link}
              title={businessObject.name!}
              to={`/business/${businessObject.id}`}
              underline="always"
              variant="h1"
            >
              {businessObject.name}
            </MuLink>
          )}
        </Box>
        {topic && person && (
          <Box mt={2} mb={2} display="flex" justifyContent="center">
            <Typography variant="h2">Zum Thema</Typography>
            <Box mx={0.5} />
            <MuLink
              color="primary"
              component={Link}
              title={topic!.name!}
              to={`/person/${person.id}/topics/${topic?.id}`}
              underline="always"
              variant="h1"
            >
              {topic?.name}
            </MuLink>
          </Box>
        )}
      </Box>
      {person && person.temporary && activityId && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box mx={1}>
            <SecondaryButton onClick={() => setOpenSpam(true)}>ungültig</SecondaryButton>
          </Box>
          <Box mx={1}>
            <PrimaryButton onClick={() => setOpen(true)}>Person zuweisen</PrimaryButton>
          </Box>
        </Box>
      )}
      <DefaultDialog open={openSpam} onClose={() => setOpenSpam(false)}>
        <DefaultDialogContent>
          <Typography variant="h1">Ungültig/SPAM?</Typography>
          <Box mt={3}>
            <Typography variant="body1">
              Möchtest du die E-Mail wirklich als SPAM markieren und löschen?
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center" mt={5}>
            <Box mx={1}>
              <SecondaryButton onClick={() => setOpenSpam(false)}>Abbrechen</SecondaryButton>
            </Box>
            <Box mx={1}>
              <PrimaryButton onClick={cancelMailAction}>Unwideruflich löschen</PrimaryButton>
            </Box>
          </Box>
        </DefaultDialogContent>
      </DefaultDialog>

      <CallDialog
        activityId={activityId}
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
        pushTarget={personId => {
          // The personId might be different from our person.id from the context above!
          return `/person/${personId}/email/${thread?.threadId}`;
        }}
      />
      <Box mt={2} />

      <Typography variant="body2">Mail an: {inbox?.email?.split("@")[0]}</Typography>
    </Box>
  );
};

export default EmailThreadHeader;
