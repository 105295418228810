import { useEffect } from "react";

import {
  useGetCaseManagementReviewsQuery,
  CaseManagementReviewsSubscriptionSubscription,
  CaseManagementReviewsSubscriptionDocument,
} from "../generated/graphql";
import { fetchPolicy } from "../utils/constants";

export const useGetCaseManagementReviewsSubscription = ({
  clientReviewAttributeId,
  includeValues,
}: {
  clientReviewAttributeId: number;
  includeValues: ("whiteFlag" | "yellowFlag" | "redFlag" | "unflagged")[];
}) => {
  const { data, loading, subscribeToMore } = useGetCaseManagementReviewsQuery({
    variables: {
      includeValues,
      clientReviewAttributeId,
    },
    fetchPolicy,
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore<CaseManagementReviewsSubscriptionSubscription>({
      document: CaseManagementReviewsSubscriptionDocument,
      variables: {
        includeValues,
        clientReviewAttributeId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !prev) return prev;

        return {
          ...prev,
          ...{ getCaseManagementReviews: subscriptionData.data.caseManagementReviewsSubscription },
        };
      },
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientReviewAttributeId]);

  return { getCaseManagementReviews: data?.getCaseManagementReviews, loading };
};
