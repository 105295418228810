import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    selectAllCheckbox: {
      color: "white",
      padding: theme.spacing(0, 0, 0, 1),
    },
  };
});

export default useStyles;
