import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import sortBy from "lodash/sortBy";
import includes from "lodash/includes";
import uniq from "lodash/uniq";
import dayjs from "dayjs";

import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import { useAuthorization } from "../../../../providers/AuthorizationProvider";
import { addressString } from "../../../../utils/addressHelper";

import {
  Service,
  ServiceAttribute,
  ServiceAddress,
  ServiceMetaAttributeType,
  ServiceStatus,
  ServiceType,
  ServiceClient,
  ServiceMetaAttribute,
  Hour,
  Task,
  Maybe,
  TaskType,
  ServiceResearchObject,
  ServiceResearchObjectStatus,
} from "../../../../generated/graphql";

import DialogContent from "../../../molecules/DefaultDialogContent";
import Dialog from "../../../molecules/DefaultDialog";
import Info from "../../../molecules/Info";
import Label from "../../../atoms/Label";
import PrimaryButton from "../../../atoms/PrimaryButton";
import SecondaryButton from "../../../atoms/SecondaryButton";
import ResearchHintDialog from "../../../organisms/ResearchHintDialog";
import Success from "../../../assets/SuccessIllustration";
import Question from "../../../assets/QuestionIllustration";
import UserAvatar from "../../../organisms/UserAvatar";
import PostIt from "./PostIt";
import useStyles from "./styles";

interface TRequirements {
  service: Service;
  serviceResearchObjects: ServiceResearchObject[];
  changeServiceStatus: () => void;
  revertServiceStatus?: () => void;
}

const Requirements = ({
  service,
  changeServiceStatus,
  revertServiceStatus,
  serviceResearchObjects,
}: TRequirements) => {
  const classes = useStyles();
  const { isAuthorized } = useAuthorization();
  const { push } = useHistory();

  const [open, setOpen] = useState(false);
  const [openRevert, setOpenRevert] = useState(false);
  const requirements = getSortedRequirements({ service, changeServiceStatus });
  const [researchHintDialogOpen, toggleResearchHintDialog] = useState(false);
  const type = service?.serviceMeta?.type || ServiceType.research;
  const status = service?.status || "";
  const dueDate = requirements.find((req: any) => req.name === "duedate");
  const researchHint = service.serviceMeta?.researchHint || "";
  return (
    <Grid container spacing={0} justify="flex-end" className={classes.reqs}>
      <Grid item xs={12} xl={9} className={classes.boxItem}>
        <PostIt></PostIt>
        <Grid item xs={12}>
          <StatusChip status={status} className={classes.chip} />
        </Grid>
        {dueDate && dueDate.value && (
          <Grid item xs={12}>
            <Chip
              variant="outlined"
              className={`${classes.chip} outlined`}
              label={`Fälligkeit: ${dueDate.value}`}
            />
          </Grid>
        )}
        <Info
          xs={12}
          md={12}
          xl={12}
          markup={false}
          title={"Referenznummer"}
          value={`${400000000 + service!.id!}`}
        ></Info>
        {requirements
          .filter((req: any) => req.name !== "duedate" && req.value && req.value.length)
          .map((requirement: any) => {
            let { label, name, value, type: rtype } = requirement;

            if (name === "days") {
              value = [
                "Montag",
                "Dienstag",
                "Mittwoch",
                "Donnerstag",
                "Freitag",
                "Samstag",
                "Sonntag",
              ]
                .filter(day => value.split(",").some((_: string) => _.trim() === day))
                .join(", ");
            }

            return (
              <Info
                key={name}
                xs={12}
                md={12}
                xl={12}
                markup={rtype === ServiceMetaAttributeType.text}
                title={label}
                value={value}
              ></Info>
            );
          })}
      </Grid>
      {service?.tasks && <ServiceStatusUpdates tasks={service.tasks} />}
      {isAuthorized("finishService") && (
        <Grid item xs={12} xl={9} className={classes.boxItem}>
          <Info
            key="status"
            xs={12}
            md={12}
            xl={12}
            title="Status"
            value={getStatusInfoText(status, type, serviceResearchObjects)}
          ></Info>
          {status !== ServiceStatus.finished && (
            <StatusToggleButton
              status={status}
              handleClick={() => setOpen(true)}
              type={type}
              serviceResearchObjects={serviceResearchObjects}
            />
          )}
          {(status === ServiceStatus.finished || status === ServiceStatus.ready) && (
            <StatusToggleButton
              status={status}
              handleClick={() => setOpenRevert(true)}
              type={type}
              serviceResearchObjects={serviceResearchObjects}
              revert
            />
          )}
        </Grid>
      )}
      {researchHint !== "" && (
        <Grid item xs={12} xl={9}>
          <Box mt={2}>
            <Link
              variant="h4"
              underline="always"
              color="primary"
              onClick={() => toggleResearchHintDialog(!researchHintDialogOpen)}
            >
              Leitfaden öffnen
            </Link>
          </Box>
          <ResearchHintDialog
            isOpen={researchHintDialogOpen}
            toggle={toggleResearchHintDialog}
            text={researchHint}
          />
        </Grid>
      )}
      {type !== ServiceType.researchBlank && (
        <Grid item xs={12} xl={9}>
          <Box mt={2}>
            <Link
              variant="h4"
              underline="always"
              color="primary"
              onClick={() => push(`/service/${service.id}/research/suggestionList`)}
            >
              Kontaktdatenbank durchsuchen
            </Link>
          </Box>
        </Grid>
      )}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
        aria-labelledby="form-dialog-title"
      >
        <StatusDialogContent
          handleCancel={() => setOpen(false)}
          handleConfirm={changeServiceStatus}
          status={status}
          type={type}
        />
      </Dialog>
      {revertServiceStatus && (
        <Dialog
          open={openRevert}
          onClose={() => setOpenRevert(false)}
          maxWidth="md"
          fullWidth
          aria-labelledby="form-dialog-title-revert"
        >
          <StatusDialogContent
            handleCancel={() => setOpenRevert(false)}
            handleConfirm={revertServiceStatus}
            status={status}
            type={type}
            revert
          />
        </Dialog>
      )}
    </Grid>
  );
};

type TServiceStatusUpdates = { tasks: Maybe<Task>[] };

const ServiceStatusUpdates = ({ tasks }: TServiceStatusUpdates) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} xl={9} className={classes.boxItem}>
      {sortBy(tasks, ["createdAt", "finishedAt"]).map((task: Task) => {
        let title;
        let dateInfo;

        if (!task?.user || !task?.taskType) {
          return null;
        }

        switch (task.taskType) {
          case TaskType.createService:
            dateInfo = !task?.finishedAt ? "seit " : "";
            title = `Service ${!task?.finishedAt ? "wird " : ""}angelegt`;
            break;
          case TaskType.researchService:
            dateInfo = !task?.finishedAt ? "seit " : "";
            title = `Service ${!task?.finishedAt ? "wird " : ""}recherchiert`;
            break;
          case TaskType.feedbackService:
            dateInfo = !task?.finishedAt ? "seit " : "";
            title = `Service ${!task?.finishedAt ? "wird " : ""} zurückgemeldet`;
            break;
          case TaskType.externalConsultResearch:
            dateInfo = "am ";
            title = "In Beratung übergeben";
            break;
        }

        return (
          <Box my={2} key={task.taskType}>
            <Label>{title}</Label>
            <Box display="flex" alignItems="center" mt={1}>
              <UserAvatar user={task.user} />
              <Box mx={1} />
              <Typography variant="body2">
                {dateInfo}
                {dayjs(task.finishedAt || task.created).format("DD.MM.YYYY")}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Grid>
  );
};
type TStatusToggleButton = {
  status: string;
  type: string;
  handleClick: () => void;
  revert?: boolean;
  serviceResearchObjects: ServiceResearchObject[];
};
const StatusToggleButton = ({
  status,
  type,
  handleClick,
  revert,
  serviceResearchObjects,
}: TStatusToggleButton) => {
  let title;
  let disabled = false;

  switch (status) {
    case ServiceStatus.research:
      if (type === ServiceType.research || type === ServiceType.researchBlank) {
        title = "Service rückmeldefertig";
        break;
      }
      disabled = !serviceResearchObjects.some(
        serviceResearchObject =>
          serviceResearchObject.status === ServiceResearchObjectStatus.suitable,
      );
      title = "In Beratung geben";
      break;
    case ServiceStatus.ready:
      title = "Service abschließen";
      break;
  }
  if (revert) {
    return (
      <Box mb={2} width={240}>
        <SecondaryButton onClick={handleClick} fullWidth>
          Status zurücksetzen
        </SecondaryButton>
      </Box>
    );
  }
  if (title) {
    return (
      <Box mb={2} width={240}>
        <PrimaryButton onClick={handleClick} disabled={disabled} fullWidth>
          {title}
        </PrimaryButton>
      </Box>
    );
  }
  return <></>;
};
type TStatusDialogContent = {
  revert?: boolean;
  status: string;
  type: string;
  handleConfirm: () => void;
  handleCancel: () => void;
};
const StatusDialogContent = ({
  status,
  handleConfirm,
  handleCancel,
  type,
  revert,
}: TStatusDialogContent) => {
  const classes = useStyles();
  const confirm = () => {
    handleConfirm();
    setTimeout(() => handleCancel(), 200);
  };
  const question = (): string => {
    if (revert) {
      return "Möchtest du den Service zurücksetzen?";
    }
    switch (status) {
      case ServiceStatus.research:
        if (type === ServiceType.research || type === ServiceType.researchBlank) {
          return "Möchtest du den Service rückmeldefertig melden?";
        }
        return "Möchtest du den Service in die Beratung übergeben?";
      case ServiceStatus.ready:
      default:
        return "Möchtest du den Service abschließen?";
    }
  };
  const action = (): string => {
    if (revert) {
      return "Service zurücksetzen";
    }
    switch (status) {
      case ServiceStatus.research:
        if (type === ServiceType.research || type === ServiceType.researchBlank) {
          return "Service rückmeldefertig melden";
        }
        return "Service in Beratung geben";
      case ServiceStatus.ready:
      default:
        return "Service abschließen";
    }
  };

  return (
    <DialogContent classes={{ root: classes.dialogRoot }}>
      {revert ? <Question className={classes.success} /> : <Success className={classes.success} />}
      <Typography variant="h1" className={classes.successHeading}>
        {question()}
      </Typography>
      <Box>
        <SecondaryButton onClick={handleCancel} className={classes.secondaryBtn}>
          Abbrechen
        </SecondaryButton>
        <PrimaryButton onClick={confirm}>{action()}</PrimaryButton>
      </Box>
    </DialogContent>
  );
};

type TStatusChip = { status: string; className: any };
const StatusChip = ({ status, className }: TStatusChip) => {
  let title;
  // TODO!: Unify ServiceStatus translations somehwere
  switch (status) {
    case ServiceStatus.research:
      title = "in Recherche";
      break;
    case ServiceStatus.ready:
      title = "rückmeldefertig";
      break;
    case ServiceStatus.finished:
      title = "abgeschlossen";
      break;
  }
  if (title) {
    return <Chip className={className} color="primary" label={title} />;
  }
  return <></>;
};

const getStatusInfoText = (
  status: string,
  type: string,
  serviceResearchObjects: ServiceResearchObject[],
) => {
  let intoText = "";

  switch (status) {
    case ServiceStatus.research:
      if (type === ServiceType.research || type === ServiceType.researchBlank) {
        intoText =
          "Sobald du deine Arbeit abgeschlossen hast, kannst du den Status auf rückmeldefertig setzen.";
      } else {
        intoText = !serviceResearchObjects.some(
          serviceResearchObject =>
            serviceResearchObject.status === ServiceResearchObjectStatus.suitable,
        )
          ? "Sobald du einen Vertragspartner gefunden hast, kannst du den Service in die Beratung übergeben"
          : "Prima, du kannst den Service jetzt in Beratung übergeben";
      }
      break;
    case ServiceStatus.counseling:
      intoText = "Der Service ist an einen Berater übergeben worden.";
      break;
    case ServiceStatus.finished:
      intoText = "Der Service ist abgeschlossen worden.";
      break;
    case ServiceStatus.ready:
      intoText = "Sobald die Ergebnisse rückgemeldet wurden, kannst du diesen Service abschließen.";
      break;
  }
  return intoText;
};

type GetSortedRequirementsProps = Pick<TRequirements, "service" | "changeServiceStatus">;

const getSortedRequirements = (props: GetSortedRequirementsProps) => {
  const serviceMetaAttributes = (props.service.serviceMeta?.serviceMetaAttributes ||
    []) as ServiceMetaAttribute[];
  const serviceAttributes = (props.service.serviceAttributes || []) as ServiceAttribute[];

  const getHours = (props: GetSortedRequirementsProps, sma: ServiceMetaAttribute) => {
    const serviceHours = [...(props?.service?.hours || [])] as Hour[];

    const weekDays = [
      "Montag",
      "Dienstag",
      "Mittwoch",
      "Donnerstag",
      "Freitag",
      "Samstag",
      "Sonntag",
    ];

    serviceHours.sort((a: Hour, b: Hour) => a.day! - b.day!);

    const hours = serviceHours.map((hours: Hour) => {
      return {
        day: weekDays[hours.day!],
        open: dayjs(hours.open).format("H:mm"),
        close: dayjs(hours.close).format("H:mm"),
      };
    });

    return {
      label: sma.label,
      name: sma.name,
      value: hours.map((h: any) => {
        return (
          <span key={h.day + h.open + h.close}>
            {h.day}: {h.open} - {h.close} Uhr
            <br />
          </span>
        );
      }),
    };
  };

  function getAddress(props: GetSortedRequirementsProps, sma: ServiceMetaAttribute) {
    const serviceAddresses = [...(props.service.serviceAddresses || [])] as ServiceAddress[];
    let value = "";
    const serviceAddress = serviceAddresses.length ? serviceAddresses[0] : null;
    if (!!serviceAddress) {
      value = `${
        serviceAddress.radius !== 10000 ? `${serviceAddress.radius}km` : "Uneingeschränkt"
      } von ${addressString(serviceAddress.address!)}`;
    }

    return {
      label: sma.label,
      name: sma.name,
      value,
    };
  }

  function currencyFormatter(num: number | string) {
    return (
      parseFloat(String(num))
        .toFixed(2) // always show two decimal digits
        .replace(".", ",") // replace decimal point character with ,
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
    );
  }

  function getAttribute(props: GetSortedRequirementsProps, sma: ServiceMetaAttribute) {
    const serviceAttributes = [...(props.service.serviceAttributes || [])] as ServiceAttribute[];
    const filteredSet = serviceAttributes.filter(sa => sa.serviceMetaAttributeId === sma.id);
    const getValues = (sas: ServiceAttribute[], type?: string | null) => {
      const first = sas[0];
      if (type === ServiceMetaAttributeType.date && first) {
        return dayjs(first.value!).format("DD.MM.YYYY");
      }
      if (type === ServiceMetaAttributeType.currency && first) {
        return currencyFormatter(first.value!);
      }
      if (type === ServiceMetaAttributeType.text && first) {
        return first.value;
      }
      return uniq(sas.map(sa => sa.value)).join(", ");
    };
    if (filteredSet.length) {
      return {
        label: sma.label,
        name: sma.name,
        value: getValues(filteredSet, sma.type),
        type: sma.type,
      };
    }
  }

  function getClient(props: GetSortedRequirementsProps, sma: ServiceMetaAttribute) {
    const serviceClients = [...(props?.service?.serviceClients || [])] as ServiceClient[];
    const smaScs = serviceClients.filter(sc => sc.serviceMetaAttributeId === sma.id);
    return {
      label: sma.label,
      name: sma.name,
      // value: "",
      value: smaScs.map(c => {
        const personName = c?.client?.person?.name || "";
        const personAge = c?.client?.person?.age || "?";
        return (
          <span key={`serviceClient-service-${c.id!}`}>
            {personName}, {personAge} Jahre
            <br />
          </span>
        );
      }),
    };
  }

  const requirements = sortBy(serviceMetaAttributes, ["sortIdx"]).reduce(
    (acc: any[], sma: ServiceMetaAttribute) => {
      if (
        !includes(
          [
            ServiceMetaAttributeType.person,
            ServiceMetaAttributeType.address,
            ServiceMetaAttributeType.hours,
          ],
          sma.type,
        ) &&
        !serviceAttributes.filter(sa => sa.serviceMetaAttributeId === sma.id).length
      ) {
        return acc;
      }
      switch (sma.type) {
        case ServiceMetaAttributeType.person:
          acc.push(getClient(props, sma));
          break;
        case ServiceMetaAttributeType.address:
          acc.push(getAddress(props, sma));
          break;
        case ServiceMetaAttributeType.hours:
          acc.push(getHours(props, sma));
          break;
        default:
          acc.push(getAttribute(props, sma));
          break;
      }
      return acc;
    },
    [],
  );
  return requirements;
};

export default Requirements;
