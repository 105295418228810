import React, { FC } from "react";

import Loader from "../../atoms/Loading";
import List from "./List";
import { Client, useGetCmClientsQuery } from "../../../generated/graphql";

const GenericCMClients: FC<{userId: number}> = ({ userId }) => {
  const { data, loading } = useGetCmClientsQuery({
    variables: {
      userId: userId
    }
  });

  if (loading && !data?.getCMClients?.length) {
    return <Loader />;
  }
  return (
    <List
      title="Meine Klienten"
      clients={(data?.getCMClients || []) as Client[]}
      emptyMessage="Du betreust momentan keine Klienten im Case Management"
      isCaseManagement
    />
  );
};
export default GenericCMClients;
