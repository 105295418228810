import React, { FC } from "react";

import Link from "@material-ui/core/Link";

import useStyles from "./styles";

type TTextLink = {
  className?: string;
  inputRef?: any;
  disabled?: boolean;
  onClick?: (ev: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  text?: string;
  download?: string;
  href?: string;
};

const TextLink: FC<TTextLink> = ({ inputRef, onClick, className, disabled, text, children }) => {
  const classes = useStyles();

  return (
    <Link
      innerRef={inputRef}
      onClick={onClick}
      className={`${classes.link} ${className}`}
      style={{ opacity: disabled ? 0.5 : 1 }}
    >
      {text || children}
    </Link>
  );
};

export const DownloadTextLink: FC<TTextLink> = ({
  inputRef,
  href,
  onClick,
  download,
  className,
  disabled,
  text,
  children,
}) => {
  const classes = useStyles();

  return (
    <Link
      innerRef={inputRef}
      download={download}
      onClick={onClick}
      href={href}
      className={`${classes.link} ${className}`}
      style={{ opacity: disabled ? 0.5 : 1 }}
    >
      {text || children}
    </Link>
  );
};

export default TextLink;
