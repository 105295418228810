import pathOr from "ramda/src/pathOr";
import dayjs from "dayjs";

import { PapershiftShift, User } from "../../../generated/graphql";

const hasOneUser = (shift: PapershiftShift) => {
  const user: User | undefined = pathOr(undefined, ["users", 0], shift);

  return !!user && !!user.id && shift.users && shift.users.length === 1;
};

const isNightshift = (shift: PapershiftShift) => {
  return dayjs(shift.endsAt).hour() < dayjs(shift.startsAt).hour();
};

/**
 *  To correctly display a shift, the following properties must be fulfilled:
 *  - Should be a day shift, since the calendar only shows shifts between 06:00 and 22:00
 *  - Has exactly one user (shifts with multiple users should have been split before)
 */
export const isRegularShift = (shift: PapershiftShift) => !isNightshift(shift) && hasOneUser(shift);
