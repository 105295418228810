import React, { FC } from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";

export const InboxGroup: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.inboxGroup}>
      {children}
    </Grid>
  );
};

export const InboxHeadline: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.taskTitleColumn}>
      <Typography variant="h3" align="left">
        {children}
      </Typography>
    </Grid>
  );
};

export default InboxGroup;
