import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PrimaryButton from "../../atoms/PrimaryButton";
import { Person, BusinessObject } from "../../../generated/graphql";
import useStyles from "../reportStyles";
import WizardNavigation from "../../molecules/WizardNavigation";
import SecondaryButton from "../../atoms/SecondaryButton";

import reportFinishedIcon from "../../assets/reportFinishedIcon.svg";

export interface IFormProps {
  person?: Person;
  business?: BusinessObject;
  backAction: () => void;
}

const ReportFinalStep: FC<IFormProps> = ({ person, backAction, business }) => {
  const classes = useStyles();
  const { push } = useHistory();
  const personOverviewUrl = person?.id ? `/person/${person.id}` : "/";
  const businessUrl = business?.id
    ? `${business.accountId ? `/account/${business.accountId}` : `/business/${business.id}`}`
    : undefined;
  const url = businessUrl || personOverviewUrl;
  return (
    <div className={classes.content}>
      <img src={reportFinishedIcon} alt="Gesprächsprotokoll erfolgreich abgeschlossen" />
      <Typography variant="h1" className={classes.finishedHeadline}>
        Gespeichert! Vielen Dank für deine tolle Arbeit!
      </Typography>
      <Box textAlign="center" mt={4}>
        <Typography variant="h3">
          Du möchtest noch etwas ändern? Dann gehe auf "Zurück", ansonsten schließt du dein
          Gesprächsprotokoll hier ab.
        </Typography>
      </Box>
      <div>
        {(businessUrl || personOverviewUrl) && (
          <PrimaryButton
            className={`${classes.button} ${classes.withRightMargin}`}
            onClick={() => push(url)}
            tabIndex={1}
          >
            Zu {business?.name || person?.name}
          </PrimaryButton>
        )}
        <PrimaryButton className={`${classes.button} ${classes.withRightMargin}`} onClick={() => push("/emails")} tabIndex={2}>
          Zurück zu den E-Mails
        </PrimaryButton>
        <PrimaryButton className={classes.button} onClick={() => push("/")} tabIndex={2}>
          Zu meiner Inbox
        </PrimaryButton>
      </div>
      <WizardNavigation>
        <SecondaryButton onClick={backAction} tabIndex={3}>
          Zurück
        </SecondaryButton>
      </WizardNavigation>
    </div>
  );
};
export default ReportFinalStep;
