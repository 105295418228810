import React, { FC } from "react";

import Box from "@material-ui/core/Box";

import useStyles from "./styles";

export const Label: FC = ({ children }) => {
  const classes = useStyles();

  return (
    // https://material-ui.com/de/system/typography/
    <Box fontWeight="fontWeightMedium" className={classes.label}>
      {children}
    </Box>
  );
};

export default Label;
