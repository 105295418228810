import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    tinyChip: {
      fontSize: 12,
      height: 20,
      marginLeft: theme.spacing(1),
      lineHeight: 1,
      letterSpacing: "0.05em",
      cursor: "pointer",
    },
    chip: {
      fontSize: 12,
      lineHeight: 1,
      height: 24,
      padding: theme.spacing(0, 2),
      letterSpacing: "0.05em",
      cursor: "pointer",
    },
    chipLabel: {
      padding: theme.spacing(0),
    },
    iconButton: {
      padding: 0,
    },
    clickable: {
      color: theme.palette.primary.main,
      cursor: "pointer",
      textDecoration: "underline",
    },
  };
});

export default useStyles;
