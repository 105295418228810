import React, { useState, FC } from "react";
import dayjs from "dayjs";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import DataColumn from "../../molecules/DataColumn";
import DataRow from "../../molecules/DataRow";
import AccountModuleList from "../../molecules/AccountModuleList";
import TariffBadge from "../../molecules/TariffBadge";
import AccountContractDialog from "../../forms/AccountContractDialog";
import AccountModulesDialog from "../../forms/AccountModulesDialog";

import {
  translateType,
  translateReporting,
  translateUsageScope,
} from "../../../utils/accountHelper";
import {
  Account,
  AccountUsageScope,
  AccountReporting,
  AccountType,
} from "../../../generated/graphql";

const AccountContractDetails: FC<{ account: Account }> = ({ account }) => {
  const [open, setOpen] = useState(false);
  const [modules, setModules] = useState(false);
  const toggleModules = () => setModules(!modules);
  const toggle = () => setOpen(!open);

  if (!account) {
    return <>Der Account konnte nicht gefunden werden</>;
  }
  return (
    <>
      <Grid container>
        <DataRow title="Vertragsdaten" editTrigger={toggle}>
          <DataColumn xs={4} label="Vertragstyp">
            <Typography variant="body2" key={`type${account.id}`}>
              {!!account?.type ? translateType(account.type, true) : "-"}
            </Typography>
          </DataColumn>
          <DataColumn label="Externe Accountkennung" xs={4}>
            <Typography variant="body2" key={`externalRef${account.id}`}>
              {account?.externalRef || "-"}
            </Typography>
          </DataColumn>
          {account?.type === AccountType.lifeWorks && (
            <DataColumn label="Lifeworks Accountkennung" xs={4}>
              <Typography variant="body2" key={`lifeworksId${account.id}`}>
                {account?.lifeworksId || "-"}
              </Typography>
            </DataColumn>
          )}
          <DataColumn xs={4} label="Tarif">
            <TariffBadge account={account} />
          </DataColumn>
          <DataColumn label="Vertragsbeginn" xs={4}>
            <Typography variant="body2" key={`contractStart${account.id}`}>
              {!!account?.contractStart ? dayjs(account?.contractStart).format("DD.MM.YYYY") : "-"}
            </Typography>
          </DataColumn>
          <DataColumn label="Vertragsende" xs={4}>
            <Typography variant="body2" key={`contractEnd${account.id}`}>
              {!!account?.contractEnd ? dayjs(account?.contractEnd).format("DD.MM.YYYY") : "-"}
            </Typography>
          </DataColumn>
          <DataColumn xs={4} label="Nutzungsrate">
            <Typography variant="body2" key={`nutzungsrate${account.id}`}>
              {account.usageRate || "-"}
            </Typography>
          </DataColumn>
          {/* <DataColumn xs={4} label="Pin">
            <Typography variant="body2" key={`pin${account.id}`}>
              {account.pin || "-"}
            </Typography>
          </DataColumn> */}
          <DataColumn xs={4} label="App-Kennwort">
            <Typography variant="body2" key={`chatPin${account.id}`}>
              {account.chatRegistrationPin || "-"}
            </Typography>
          </DataColumn>
          <DataColumn xs={4} label="Helpline 1">
            <Typography variant="body2" key={`helpline${account.id}`}>
              {account.helpline || "-"}
            </Typography>
          </DataColumn>
          <DataColumn xs={4} label="Helpline 2">
            <Typography variant="body2" key={`helpline${account.id}`}>
              {account.helpline2 || "-"}
            </Typography>
          </DataColumn>
          <DataColumn xs={4} label="Telefonnr. Ausland">
            <Typography variant="body2" key={`helpline${account.id}`}>
              {account.helplineInternational || "-"}
            </Typography>
          </DataColumn>
          <DataColumn xs={4} label="Reporting">
            <Typography variant="body2" key={`helpline${account.id}`}>
              {translateReporting(account?.reporting || AccountReporting.basic)}
            </Typography>
          </DataColumn>
          {/* //TODO
          {(account?.type === AccountType.lifeWorks || account?.type === AccountType.eap || account?.type === AccountType.assistance) && (
            <DataColumn label="Accountnummer" xs={4}>
              <Typography variant="body2" key={`lifeworksId${account.id}`}>
                {account?.lifeworksId || "-"}
              </Typography>
            </DataColumn>
          )} */}
        </DataRow>
      </Grid>
      <Grid container component={Box} mt={3}>
        <DataRow title="Vertragsmodule" editTrigger={toggleModules}>
          <DataColumn xs={4} label="Life-Management">
            <AccountModuleList account={account} small />
          </DataColumn>
          <DataColumn xs={4} label="Externe Beratungen">
            <Typography variant="body2" key={`sessions${account.id}`}>
              {account?.freeSessions || "keine"} Sitzung
              {account?.freeSessions && account?.freeSessions !== 1 ? "en" : ""}
            </Typography>
          </DataColumn>
          <DataColumn xs={4} label="Interne Beratung (STC)">
            <Typography variant="body2" key={`telephone-session${account.id}`}>
              {account?.nFreeSTCs || "keine"} Sitzung
              {account?.nFreeSTCs && account?.nFreeSTCs !== 1 ? "en" : ""}
            </Typography>
          </DataColumn>
          {/* <DataColumn xs={4} label="Reporting">
            <Typography variant="body2">
              {translateReporting(account?.reporting || AccountReporting.basic)}
            </Typography>
          </DataColumn> */}
          <DataColumn xs={4} label="Zugangsberechtigung">
            <Typography variant="body2">
              {translateUsageScope(account?.usageScope || AccountUsageScope.employeeOnly)}
            </Typography>
          </DataColumn>
          <DataColumn xs={4} label="Führungskräftecoaching">
            <Typography variant="body2">{!account.coaching ? "nicht " : ""}gebucht</Typography>
          </DataColumn>
          <DataColumn xs={4} label="Service-Zeiten">
            <Typography variant="body2">
              {account.serviceTimes}
            </Typography>
          </DataColumn>
          <DataColumn xs={12} label="Besonderheiten/Einschränkungen">
            <Typography variant="body2">
              {account.remarks}
            </Typography>
          </DataColumn>
        </DataRow>
      </Grid>
      <AccountContractDialog
        account={account}
        open={open}
        toggle={toggle}
        key={`contract-modal-${account.id}`}
      />
      <AccountModulesDialog
        account={account}
        open={modules}
        toggle={toggleModules}
        key={`modules-modal-${account.id}`}
      />
    </>
  );
};
export default AccountContractDetails;
