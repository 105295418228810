import Box from "@material-ui/core/Box";
import dayjs from "dayjs";
import React, { useState } from "react";
import { User, Team, useUpdateCaseManagerByUserIdMutation } from "../../../generated/graphql";
import Avatar from "../../atoms/Avatar";
import CustomIconButton from "../../atoms/CustomIconButton";
import UnassignedUserButton from "../../atoms/UnassignedUserButton";
import DefaultDialog from "../../molecules/DefaultDialog";
import DefaultDialogContent from "../../molecules/DefaultDialogContent";
import AssignUserDialogForService from "../AssignUserDialogForService";

function CaseManagementAssignmentDialog({
  caseManager,
  clientId,
  counselingUser,
  serviceId,
  updatedAt,
}: {
  caseManager?: User | null;
  clientId: number;
  counselingUser: User | null;
  serviceId: number;
  updatedAt?: dayjs.Dayjs;
}) {
  const [open, setOpen] = useState(false);
  const [updateCaseManager] = useUpdateCaseManagerByUserIdMutation();

  const avatar = !counselingUser ? (
    <UnassignedUserButton setOpen={() => setOpen(true)} />
  ) : (
    <CustomIconButton onClick={() => setOpen(true)}>
      <Avatar
        src={counselingUser.person?.picture || counselingUser.person?.avatar || ""}
        widthfactor={5}        
      >
        {counselingUser.person?.avatarKeys}
      </Avatar>
    </CustomIconButton>
  );

  return (
    <Box display="flex">
      <Box display="flex" alignItems="center"
      style={{
        position: 'absolute',
        top: '130px'
      }}>
        <Box width={16} />
        {avatar}
      </Box>
      <DefaultDialog open={open} onClose={() => setOpen(false)}>
        <DefaultDialogContent>
          <AssignUserDialogForService
            allowReset={false}
            onClose={() => setOpen(false)}
            user={counselingUser}
            onSubmit={async userId => {
              console.log(counselingUser);
              if (!counselingUser) {
                await updateCaseManager({
                  variables: {
                    serviceId,
                    newCaseManagerId: userId,
                    oldCaseManagerId: null,
                  },
                });
              } else {
                await updateCaseManager({
                  variables: {
                    serviceId,
                    newCaseManagerId: userId,
                    oldCaseManagerId: caseManager?.id,
                  },
                });
              }
              window.location.reload();
              setOpen(false);
            }}
            headline={
              !!counselingUser ? "Zuständigen Berater wechseln" : "Zuständigen Berater zuweisen"
            }
            teams={[Team.counselingTC]}
          />
        </DefaultDialogContent>
      </DefaultDialog>
    </Box>
  );
}

export default CaseManagementAssignmentDialog;
