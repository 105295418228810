import React from "react";

import { FormikProps, FormikValues, Form } from "formik";
import { useHistory } from "react-router-dom";
import DayjsUtils from "@date-io/dayjs";
import { TimePicker, DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import Maybe from "graphql/tsutils/Maybe";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import PrimaryButton from "../../atoms/PrimaryButton";
import PersonTextualSelect from "../../molecules/PersonTextualSelect";
import TopicSelectOrCreate from "../../molecules/TopicSelectOrCreate";
import WizardNavigation from "../../molecules/WizardNavigation";
import { Person, Topic } from "../../../generated/graphql";
import SecondaryButton from "../../atoms/SecondaryButton";
import { ActivityInteractionType } from "../../organisms/Interactions/Bubble";
import useStyles from "../reportStyles";

export interface IReportForm {
  defaultContact?: string | null;
  isCreating?: boolean;
  person: Person;
  setContact?: (person?: Person | { name: string; id?: never }) => void;
  setTopic: (topic: Topic) => void;
  topic: Maybe<Topic>;
}

const ReportForm = ({
  defaultContact,
  isCreating = false,
  isSubmitting,
  person,
  setContact,
  setFieldValue,
  setTopic,
  submitForm,
  topic,
  values,
}: FormikProps<FormikValues> & IReportForm) => {
  const { goBack } = useHistory();

  const handleTypeChange = (event: any) => {
    setFieldValue("type", event.target.value);
  };
  const handleStartChange = (value: MaterialUiPickersDate) => {
    setFieldValue("start", value);
  };
  const handleEndChange = (value: MaterialUiPickersDate) => {
    setFieldValue("end", value);
  };

  const classes = useStyles();
  const topics = person.client?.topics || [];

  return (
    <Form className={classes.content}>
      <Typography variant="h2" className={classes.dataText} gutterBottom>
        <Select
          value={values.type}
          classes={{ select: classes.select }}
          inputProps={{ className: classes.input }}
          IconComponent="span"
          onChange={handleTypeChange}
          tabIndex={0}
        >
          <MenuItem value="callIn">Eingehendes Telefonat</MenuItem>
          <MenuItem value="callOut">Ausgehendes Telefonat</MenuItem>
          <MenuItem value="chat">Chat</MenuItem>
          <MenuItem value="mail">E-Mail</MenuItem>
          <MenuItem value="wpoVerweisung">E-Mail (wpo)</MenuItem>
        </Select>{" "}
        mit{" "}
        <PersonTextualSelect
          defaultContact={defaultContact}
          person={person}
          setContact={setContact}
          tabIndex={1}
        />
      </Typography>
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <Typography variant="h2" className={classes.dataText} gutterBottom>
          am{" "}
          <DatePicker
            cancelLabel="Abbrechen"
            className={classes.datePicker}
            disableFuture
            format="Do MMM"
            InputProps={{ inputProps: { className: classes.timeinput } }}
            key="startDate"
            onChange={handleStartChange}
            tabIndex={2}
            value={values.start}
          />{" "}
          <TimePicker
            ampm={false}
            cancelLabel="Abbrechen"
            className={classes.timePicker}
            data-testid="startTime"
            InputProps={{ inputProps: { className: classes.timeinput } }}
            key="startTime"
            onChange={handleStartChange}
            tabIndex={3}
            value={values.start}
          />
          {" bis "}
          <DatePicker
            cancelLabel="Abbrechen"
            className={classes.datePicker}
            data-testid="endDate"
            disableFuture
            format="Do MMM"
            InputProps={{ inputProps: { className: classes.timeinput } }}
            key="endDate"
            onChange={handleEndChange}
            tabIndex={4}
            value={values.end}
          />{" "}
          <TimePicker
            ampm={false}
            cancelLabel="Abbrechen"
            className={classes.timePicker}
            data-testid="endTime"
            InputProps={{ inputProps: { className: classes.timeinput } }}
            key="endTime"
            onChange={handleEndChange}
            tabIndex={3}
            value={values.end}
          />
          Uhr
        </Typography>
      </MuiPickersUtilsProvider>
      {person.client && (
        <Typography variant="h2" className={classes.dataText} gutterBottom>
          zum{" "}
          <TopicSelectOrCreate
            // @ts-ignore
            topics={topics}
            selectAction={setTopic}
            topic={topic}
            tabIndex={5}
          />
        </Typography>
      )}
      <WizardNavigation>
        <SecondaryButton onClick={goBack} tabIndex={0}>
          Abbrechen
        </SecondaryButton>
        <ReportFormMemo
          isCreating={isCreating}
          isClient={!!person?.client}
          isSubmitting={isSubmitting}
          topic={topic}
          setFieldValue={setFieldValue}
          values={values}
        />
        <ReportFormNext
          isCreating={isCreating}
          isClient={!!person?.client}
          isSubmitting={isSubmitting}
          topic={topic}
          setFieldValue={setFieldValue}
        />
      </WizardNavigation>
    </Form>
  );
};

export default ReportForm;

type TReportFormNext = {
  isCreating: boolean;
  isClient: boolean;
  isSubmitting: boolean;
  setFieldValue: (key: string, value: any) => void;
  topic?: Topic | null;
};

const ReportFormNext = ({
  isCreating,
  isClient,
  isSubmitting,
  topic,
  setFieldValue,
}: TReportFormNext) => {
  let disabled = isSubmitting || missingTopic(topic);
  let onClick = () => {
    setFieldValue("isReport", true);
  };

  return (
    <PrimaryButton onClick={onClick} disabled={disabled} tabIndex={0} type="submit">
      Gesprächsprotokoll erstellen
    </PrimaryButton>
  );
};

type TReportFormMemo = {
  isCreating: boolean;
  isClient: boolean;
  isSubmitting: boolean;
  setFieldValue: (key: string, value: any) => void;
  topic?: Topic | null;
  values: FormikValues;
};

const ReportFormMemo = ({
  isCreating,
  isClient,
  isSubmitting,
  topic,
  setFieldValue,
  values,
}: TReportFormMemo) => {
  let disabled = isSubmitting || missingTopic(topic) || disableSubmitMemo(values);
  let onClick = () => {
    setFieldValue("isReport", false);
  };

  return (
    <PrimaryButton onClick={onClick} disabled={disabled} tabIndex={0} type="submit">
      Gesprächsnotiz erstellen
    </PrimaryButton>
  );
};

const missingTopic = (topic: Maybe<Topic>) => {
  const topicName = topic?.name;

  return !topicName || /^(\s|\t|\n|\r)+$/.test(topicName);
};

const disableSubmitMemo = (
  { end, start, type }: FormikValues,
) => {
  switch (type) {
    case ActivityInteractionType.chat:
      return false;
    case ActivityInteractionType.mail:
      return false;
    case ActivityInteractionType.call:
    case ActivityInteractionType.callIn:
    case ActivityInteractionType.callOut:
      if (!end || !start) {
        return true;
      }
      return end.diff(start, "minutes") > 3;
  }
};
