import React, { Fragment } from "react";
import orderBy from "lodash/orderBy";
import List from "@material-ui/core/List";
import AppointmentItem from "./item";
import { path } from "ramda";
import classnames from "classnames";
import "./styles.css";
import useStyles from "./styles";
import { Appointment } from "../../../generated/graphql";

import { TAKE } from "../../../utils/constants";

const smallTake = Math.floor(TAKE / 10);
type TAppointmentsList = {
  appointments: Appointment[];
  past?: boolean;
  withClient?: boolean;
};
const AppointmentsList = ({ appointments, past, withClient }: TAppointmentsList) => {
  const classes = useStyles();

  return (
    <List disablePadding className={classnames(classes.root, { [classes.past]: past })}>
      {orderBy(appointments, "from", "desc").map((appointment: Appointment, index: number) => {
        return (
          <Fragment key={`fragment-${appointment.id}`}>
            <AppointmentItem
              withClient={withClient}
              key={`${appointment.id}`}
              delay={smallTake - (index % smallTake)}
              appointment={appointment}
              personId={path(["person", "id"], appointment)}
            />
          </Fragment>
        );
      })}
    </List>
  );
};

export default AppointmentsList;
