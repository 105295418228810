import React, { FC, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Typography, Box, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import config from "../../../config";

import PersonHeader from "../../molecules/PersonHeader";
import Loader from "../../atoms/Loading";
import emailIllustration from "../../assets/emailIllustration.svg";
import SlateRTE, { initialFormValue, serializeHtml } from "../../molecules/SlateRTE";
import SecondaryButton from "../../atoms/SecondaryButton";
import PrimaryButton from "../../atoms/PrimaryButton";
import { EmailMessageContainer } from "../../molecules/EmailMessage";
import { MailClassificationType, useBreadCrumbInfoByAccountIdQuery, useCreateGMailMutation } from "../../../generated/graphql";
import { useAuthorization } from "../../../providers/AuthorizationProvider";
import useBusinessSubscription from "../../../hooks/useBusinessSubscription";
import { usePersonsByBusinessSubscription } from "../../../hooks/usePersonsByBusinessSubscription";
import IllustratedErrorMessage from "../../organisms/IllustratedErrorMessage";
import errorIllustration from "../../assets/errorIllustration.svg";
import AccountStatusBadge from "../../molecules/AccountStatusBadge";

const BusinessEmailSendNew: FC = () => {
  const { me } = useAuthorization();
  const { goBack } = useHistory();
  const { id, contactId } = useParams<{ contactId: string; id: string }>();

  const { businessObject, loading: loadBusiness, error: businessError } = useBusinessSubscription(
    parseInt(id, 10),
  );
  const accountId = businessObject?.accountId;
  const { data: breadcrumbData } = useBreadCrumbInfoByAccountIdQuery({
    skip: !accountId,
    variables: {
      id: accountId!,
    },
  });
  const {
    persons,
    loading: loadingPersons,
    error: personsError,
  } = usePersonsByBusinessSubscription(parseInt(id!, 10));

  const [createGMail] = useCreateGMailMutation();

  const [fromAddress, setFromAddress] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState(initialFormValue);
  const [submitting, setSubmitting] = useState(false);

  if (loadingPersons || loadBusiness) {
    return <Loader></Loader>;
  }
  const person = persons.find(p => p!.id === parseInt(contactId, 10));

  if (!person || !businessObject || !me || !me || !me.user || businessError || personsError) {
    if (businessError || personsError) {
      console.error(businessError || personsError);
    }

    return (
      <IllustratedErrorMessage
        messages={["Fehler beim Laden der Daten"]}
        illustration={errorIllustration}
        illustrationAltText="Elli rutscht auf einer Banane aus"
      >
        <PrimaryButton onClick={goBack}>Zurück</PrimaryButton>
      </IllustratedErrorMessage>
    );
  }
  const user = {
    id: me.user.id,
    papershiftWorkingAreas: me.papershiftWorkingAreas,
    person: me,
  };

  const email = person.emails?.length ? person.emails[0] : null;

  if (!email) {
    return null;
  }

  const onSubmit = async () => {
    setSubmitting(true);

    await createGMail({
      variables: {
        classification: MailClassificationType.service,
        sender: fromAddress,
        personId: person.id,
        message: serializeHtml(JSON.parse(body)),
        subject,
        topicId: null,
      },
    });
    setSubmitting(false);
    goBack();
  };

  const fromAddresses = config.inboxes as string[];

  const mapList = businessObject.accountId
    ? [
        { name: "Accounts", path: "/account" },
        {
          name: businessObject.name || "",
          path: `/account/${businessObject.accountId!}`,          
        },
      ]
    : [
        {
          name: businessObject.name || "",
          path: `/business/${businessObject.id}`,
        },
      ];

  return (
    <>
      <PersonHeader
        person={person}
        contractExpirationDate={breadcrumbData?.breadCrumbInfoByAccountId?.contractEnd}
        language={breadcrumbData?.breadCrumbInfoByAccountId?.language || ""}
        mapList={[
          ...mapList,
          {
            name: "Mitarbeiter",
            path: `/business/${businessObject.id!}/contacts`,
          },
          {
            name: person.name,
            path: `/business/${businessObject.id!}/contacts/${person.id}`,
          },
          {
            name: "E-Mail",
            path: ``,
          },
        ]}
      />

      <Grid container style={{ marginTop: "192px" }}>
        <Grid item xs={3}></Grid>

        <Grid
          item
          container
          xs={6}
          direction="column"
          alignItems="center"
          style={{ display: "flex" }}
        >
          <img src={emailIllustration} alt="" />
          <Box mt={4} py="3px">
            <Typography variant="h2">E-Mail an {person.name}</Typography>
          </Box>
          <Box my={1} />
          <Typography variant="body2">E-Mail: {email.email}</Typography>
        </Grid>

        <Grid item xs={3}></Grid>
      </Grid>

      <Grid container>
        <Grid item xs={3}></Grid>

        <Grid item xs={6}>
          <EmailMessageContainer isClient={false} user={user}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Absender</InputLabel>
              <Select value={fromAddress}>
                {fromAddresses.map(name => (
                  <MenuItem key={name} value={name} onClick={() => setFromAddress(name)}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              inputProps={{
                maxLength: 256,
              }}
              fullWidth
              value={subject}
              onChange={event => {
                setSubject(event.target.value);
              }}
              label="Betreff"
              margin="normal"
            />
            <SlateRTE label="Nachricht" value={body} handleChange={setBody} />
          </EmailMessageContainer>

          <Box display="flex" justifyContent="center" mt={4}>
            <SecondaryButton onClick={goBack}>Abbrechen</SecondaryButton>
            <Box mx={1} />
            <PrimaryButton
              disabled={
                submitting ||
                !JSON.parse(body)[0]["children"][0]["text"].trim().length ||
                !fromAddress ||
                !subject
              }
              onClick={onSubmit}
            >
              Absenden
            </PrimaryButton>
          </Box>
        </Grid>

        <Grid item xs={3}></Grid>
      </Grid>
    </>
  );
};

export default BusinessEmailSendNew;
