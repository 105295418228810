import React from "react";

const SmsIllustration = () => {
  return (
    <svg
      width="107"
      height="80"
      viewBox="0 0 107 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.8758 61.4639L23.8439 61.469L23.813 61.4782C19.1923 62.8497 17.2511 65.0491 14.8155 67.8086L14.7247 67.9115C12.2737 70.6879 9.2292 74.0962 2.29384 78.0266C1.90673 78.2459 1.43702 77.8558 1.63399 77.4084C4.3153 71.3178 6.53539 65.3726 7.42936 58.4658C9.78593 40.2591 6.91421 15.6408 5.743 6.96947C5.54628 5.51298 6.57806 4.20485 8.01698 4.10227C20.7538 3.19418 68.8783 0.195463 103.395 3.92532C104.924 4.09049 105.949 5.62624 105.601 7.18781C103.761 15.4379 103.565 25.784 103.975 35.1523C104.386 44.5289 105.407 52.9633 106.019 57.4057C106.224 58.8883 105.122 60.229 103.636 60.3009C102.394 60.361 100.835 60.4345 99.0043 60.5165L99.0042 60.5165L98.9936 60.5171C98.993 60.5171 98.9923 60.5171 98.9916 60.5171C98.3754 60.5117 74.8343 60.3077 67.0749 60.3016C63.1444 60.2985 54.1576 60.2985 45.254 60.4438C36.3703 60.5888 27.5129 60.8789 23.8758 61.4639Z"
        fill="white"
        stroke="#025879"
      />
      <path
        d="M19.9629 16.8023C28.7465 14.4023 67.8733 19.2016 81.448 16.8023"
        stroke="#025879"
        strokeLinecap="round"
      />
      <path
        d="M20.7612 31.469C29.5448 29.069 88.6344 31.469 93.4254 31.469"
        stroke="#025879"
        strokeLinecap="round"
      />
      <path
        d="M20.7612 44.7997C43.9179 42.3999 55.8956 46.3996 68.6717 44.7997"
        stroke="#025879"
        strokeLinecap="round"
      />
      <path
        d="M91.0299 44.8008C91.0299 43.1034 90.3568 41.4755 89.1589 40.2753C87.9609 39.0751 86.336 38.4008 84.6418 38.4008C82.9476 38.4008 81.3228 39.0751 80.1248 40.2753C78.9268 41.4755 78.2538 43.1034 78.2538 44.8008C78.2538 46.4982 78.9268 48.126 80.1248 49.3263C81.3228 50.5265 82.9476 51.2008 84.6418 51.2008C86.336 51.2008 87.9609 50.5265 89.1589 49.3263C90.3568 48.126 91.0299 46.4982 91.0299 44.8008ZM92.6269 44.8008C92.6269 46.9225 91.7856 48.9573 90.2881 50.4576C88.7906 51.9579 86.7596 52.8008 84.6418 52.8008C83.5932 52.8008 82.5548 52.5939 81.5861 52.1918C80.6173 51.7898 79.737 51.2005 78.9955 50.4576C77.498 48.9573 76.6567 46.9225 76.6567 44.8008C76.6567 42.6791 77.498 40.6442 78.9955 39.1439C80.493 37.6436 82.524 36.8008 84.6418 36.8008C85.6904 36.8008 86.7288 37.0077 87.6976 37.4097C88.6664 37.8118 89.5466 38.4011 90.2881 39.1439C91.0296 39.8868 91.6178 40.7687 92.0191 41.7393C92.4203 42.7099 92.6269 43.7502 92.6269 44.8008ZM83.0448 42.8008C83.0448 43.4408 82.4858 44.0008 81.847 44.0008C81.2082 44.0008 80.6493 43.4408 80.6493 42.8008C80.6493 42.1608 81.2082 41.6008 81.847 41.6008C82.4858 41.6008 83.0448 42.1608 83.0448 42.8008ZM88.6343 42.8008C88.6343 43.4408 88.0754 44.0008 87.4366 44.0008C86.7978 44.0008 86.2388 43.4408 86.2388 42.8008C86.2388 42.1608 86.7978 41.6008 87.4366 41.6008C88.0754 41.6008 88.6343 42.1608 88.6343 42.8008ZM84.6418 48.9848C83.2444 48.9848 82.0147 48.4008 81.2961 47.5368L82.4299 46.4008C82.7893 46.9768 83.6437 47.3848 84.6418 47.3848C85.6399 47.3848 86.4943 46.9768 86.8537 46.4008L87.9876 47.5368C87.2689 48.4008 86.0392 48.9848 84.6418 48.9848Z"
        fill="#F2680A"
      />
    </svg>
  );
};
export default SmsIllustration;
