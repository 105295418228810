import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      padding: theme.spacing(12, 0, 4),
    },
    content: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
    },
  };
});

export default useStyles;
