import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import {
  Phone,
  useMakeClientCallMutation,
  PersonFragment,
  Person,
  useUpdatePersonExtrasMutation,
} from "../../../generated/graphql";
import { useAuthorization } from "../../../providers/AuthorizationProvider";
import DefaultDialog from "../../molecules/DefaultDialog";
import DialogContent from "../../molecules/DefaultDialogContent";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import ICallContentType, { CallContentTypes } from "../../../interfaces/ICallContentType";
import TextField from "@material-ui/core/TextField";
import { Grid, InputLabel, MenuItem, withWidth } from "@material-ui/core";

type TOutgoingCall = {
  open: boolean;
  close: () => void;
  phone?: Phone;
  callContentType?: ICallContentType;
  person: PersonFragment | Person;
};

const informationSources = [
  "Co-Worker",
  "Family Member",
  "Human Ressources",
  "Informatio Booth",
  "Internal Communication",
  "My EAP Mobile Application",
  "Orientation",
  "Peer Support Team / Wellness Bureau",
  "Promotional Literature",
  "Supervision / Manager",
  "Trauma Debriefing",
  "Union Representative",
  "Web Intake",
  "Website Viewing",
  "Wellness Seminar",
  "Worksite Seminar",
  "Worksite Health Services",
  "Declined",
];
const OutgoingCall = ({ open, close, phone, person, callContentType }: TOutgoingCall) => {
  const { me } = useAuthorization();
  const [submitting, setSubmitting] = useState(false);
  const [hrBusiness, setHrBusiness] = useState(callContentType?.hrBusiness);
  const [informationSource, setInformationSource] = useState(callContentType?.informationSource);

  const [updatePersonExtras] = useUpdatePersonExtrasMutation();

  const [primeDN, setPrimeDN] = useState(me?.user?.primeDN || "");
  const [call] = useMakeClientCallMutation();

  const updateExtras = () => {
    updatePersonExtras({
      variables: {
        personId: person.id,
        personExtraInput: {
          //informationSource: informationSource || "",
          hrBusiness: hrBusiness || "",
        },
      },
    });
  };
  const doCall = async () => {
    updateExtras();
    if (!person.id || !phone?.id || submitting) {
      return;
    }
    setSubmitting(true);
    try {
      await call({
        variables: { personId: person!.id, phoneId: phone!.id, primeDN: parseInt(primeDN) },
      });
      setSubmitting(false);
      close();
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  return !phone ? (
    <></>
  ) : (
    <DefaultDialog
      open={open}
      onClose={() => {
        updateExtras();
        close();
      }}
    >
      <DialogContent>
        <Box width={1}>
          <Typography variant="body2">
            Nebenstelle{" "}
            {me?.user?.hotDesks?.length === 1 ? (
              me?.user?.primeDN
            ) : (
              <Select
                value={primeDN}
                native
                onChange={e => {
                  if (typeof e.target.value === "string") setPrimeDN(e.target.value);
                }}
              >
                {me?.user?.hotDesks?.map(ht => (
                  <option value={ht!.extension!.primeDN || ""} key={ht!.extension!.primeDN || ""}>
                    {ht?.extension?.primeDN}
                  </option>
                ))}
              </Select>
            )}
          </Typography>
        </Box>
        <Box mt={4} textAlign="center" width="100%">
          {/* {callContentType?.type === CallContentTypes.GOOGLE_CALL_CONTENT_TYPE && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  minRows={1}
                  multiline
                  inputProps={{
                    maxLength: 256,
                  }}
                  fullWidth
                  label="HR Business"
                  value={hrBusiness}
                  onChange={event => {
                    setHrBusiness(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  minRows={1}
                  multiline
                  inputProps={{
                    maxLength: 256,
                  }}
                  fullWidth
                  label="Source"
                  value={informationSource}
                  onChange={event => {
                    setInformationSource(event.target.value);
                  }}
                />
              </Grid>
            </Grid>
          )} */}
          {/* {callContentType?.type === CallContentTypes.LIFEWORKS_CALL_CONTENT_TYPE && (
            <Grid container spacing={2} xs={6} >              
              <Grid item xs={12}>
              <InputLabel htmlFor="info_source">Source of Information</InputLabel>
                <Select name="info_source" value={informationSource} fullWidth>
                  {informationSources.map(source => (
                    <MenuItem
                      key={source}
                      value={source}
                      onClick={() => {
                        setInformationSource(source);
                      }}
                    >
                      {source}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          )} */}
        </Box>
        <Box mt={13} mb={phone.messagingAllowed ? 15 : 3} textAlign="center">
          <Typography variant="h1">
            {person.name &&
              `${person.name}, ${
                phone && phone.readableNumber
                  ? phone.readableNumber
                  : `${phone.countryCode} ${phone.areaCode} ${phone.number}`
              } anrufen`}
          </Typography>
        </Box>
        {!phone.messagingAllowed && (
          <Box mb={8} textAlign="center">
            <Typography variant="h4" color="error">
              Keine Nachrichten
            </Typography>
          </Box>
        )}
        <Box alignItems="center" justifyContent="center" display="flex">
          <Box marginRight={3}>
            <SecondaryButton
              onClick={() => {
                updateExtras();
                close();
              }}
            >
              Abbrechen
            </SecondaryButton>
          </Box>
          <Box>
            <PrimaryButton disabled={submitting} onClick={doCall}>
              Jetzt anrufen
            </PrimaryButton>
          </Box>
        </Box>
      </DialogContent>
    </DefaultDialog>
  );
};

export default OutgoingCall;
