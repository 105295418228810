import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    borderTop: `1px solid ${theme.palette.primary.main}`,
    marginTop: theme.spacing(1),
    padding: theme.spacing(1, 0),
  },
  field: padded => ({
    marginTop: padded ? theme.spacing(6) : 0,
    width: "100%",
    "&:focus": {
      outline: 0,
    },
    "&.Mui-error": {
      "&>.MuiInputLabel-formControl": {
        color: theme.palette.error.main,
      },
      "& .MuiIconButton-label": {
        color: theme.palette.error.main,
      },
    },
  }),
  smallField: {
    marginTop: theme.spacing(3),
  },
  customLabel: {
    fontSize: 26,
  },
  createIcon: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    height: "0.8em",
    position: "absolute" as "absolute",
    right: 0,
    top: 0,
    width: "0.8em",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  readOnly: {
    "&>p": {
      margin: 0,
    },
    "&>strong,&>b": {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  enabled: {
    color: theme.palette.secondary.main,
  },
  editor: {
    fontSize: "14px",
    padding: theme.spacing(2.5, 0, 0),
    "&>p, &>*>p, >p": {
      margin: 0,
    },
    "&>strong,&>b,&>*>strong": {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  customLabelRoot: {
    ...theme.typography.body2,
    cursor: "pointer",
    display: "block",
    transformOrigin: "top left",
  },
  customLabelRootSmall: {
    ...theme.typography.body2,
    cursor: "pointer",
    display: "block",
    transformOrigin: "top left",
  },
}));
