import React from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import dayjs from "dayjs";
import { useHistory, useParams } from "react-router-dom";

export default function ShiftHeader() {
  const { page } = useParams<{ page: string }>();
  const { push } = useHistory();

  const pageNo = parseInt(page, 10);

  const date = dayjs().add(pageNo - 1, "day");

  const notisFirstColumnPage = pageNo > 1;
  const notisLastColumnPage = pageNo < 28;

  return (
    <Box display="flex" alignItems={"center"} flex={1}>
      <Grid container>
        <Grid item xs={12}>
          <Box alignItems="center" justifyContent="center" display="flex">
            {notisFirstColumnPage && (
              <Box marginRight={2}>
                <Link
                  variant="h4"
                  underline="always"
                  color="primary"
                  onClick={() => push(`/shift/${parseInt(page) - 1}`)}
                >
                  vorherige
                </Link>
              </Box>
            )}{" "}
            <Typography color="textPrimary" variant="h3" align="center">
              Tagesschicht vom {date.format("dd, D.M.YYYY")}
            </Typography>
            {notisLastColumnPage && (
              <Box marginLeft={2}>
                <Link
                  variant="h4"
                  underline="always"
                  color="primary"
                  onClick={() => push(`/shift/${parseInt(page) + 1}`)}
                >
                  nächste
                </Link>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
