import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    inboxGroup: {
      marginTop: theme.spacing(3),
      "&:first-child": {
        marginTop: 0,
      },
    },
    taskTitleColumn: {
      alignItems: "center",
      display: "flex",
      marginTop: theme.spacing(3),
      padding: theme.spacing(0.5, 0),
      borderBottom: `solid ${theme.palette.grey[400]} 1px`,
    },
  };
});

export default useStyles;
