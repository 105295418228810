import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    boxShadow: theme.shadows[0],
    fontWeight: theme.typography.fontWeightMedium,
    minWidth: theme.spacing(20),
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    textTransform: "none" as "none",
  },
}));

export default useStyles;
