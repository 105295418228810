import React from "react";
import { useHistory } from "react-router";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import PrimaryButton from "../../atoms/PrimaryButton";
import Loading from "../../atoms/Loading";
import DefaultDialog from "../../molecules/DefaultDialog";
import DefaultDialogContent from "../../molecules/DefaultDialogContent";

import { ActivityInteractionType } from "../../organisms/Interactions/Bubble";
import { usePersonActivityQuery, PersonActivity } from "../../../generated/graphql";

import elliDeskIcon from "../../assets/elliDeskIcon.svg";

// Unify with CallDialog
interface IInteractionCompletedDialog {
  activity: PersonActivity;
  open: boolean;
  handleClose: () => void;
  handleNotifyClose: () => void;
}

const InteractionCompletedDialog = ({
  activity,
  open,
  handleClose,
  handleNotifyClose,
}: IInteractionCompletedDialog) => {
  const { push } = useHistory();
  const { data, loading } = usePersonActivityQuery({
    // Due to refactoring: https://medium.com/@calebmer/when-to-use-graphql-non-null-fields-4059337f6fc8
    variables: { id: activity?.id || -1 },
    skip: !activity?.id,
  });

  // Due to refactoring: https://medium.com/@calebmer/when-to-use-graphql-non-null-fields-4059337f6fc8
  let name = activity?.person?.name;

  if (!name) {
    name = "Unbekannt (Fehler)";
  }

  let headline;
  switch (activity.activityType) {
    case ActivityInteractionType.chat:
      headline = (
        <Typography variant="h1" align="center">
          Du hast den Chat mit {name} beendet. Schreibe jetzt ein Gesprächsprotokoll.
        </Typography>
      );
      break;
    case ActivityInteractionType.call:
      headline = (
        <Typography variant="h1" align="center">
          Du hast dein Telefonat mit <br /> {name} beendet
        </Typography>
      ); // (${call && call.readableNumber })
      break;
  }

  if (loading) {
    return <Loading />;
  }

  const report = data?.personActivity?.conversationReport;

  return (
    <DefaultDialog open={open} onClose={handleNotifyClose}>
      <DefaultDialogContent>
        {headline}
        <Box marginY={8}>
          <img alt="Gespräch erfolgreich beendet" style={{ width: "160px" }} src={elliDeskIcon} />
        </Box>
        <PrimaryButton
          type="submit"
          onClick={() => {
            handleClose();
            // Due to refactoring: https://medium.com/@calebmer/when-to-use-graphql-non-null-fields-4059337f6fc8
            if (activity?.person?.id) {
              push({
                pathname: `/person/${activity.person.id}/report${
                  report && report.id ? `/${report.id}` : ""
                }`,
                state: { activity },
              });
            } else {
              push({
                pathname: "/",
              });
            }
          }}
        >
          Gesprächsprotokoll schreiben
        </PrimaryButton>
      </DefaultDialogContent>
    </DefaultDialog>
  );
};
export default InteractionCompletedDialog;
