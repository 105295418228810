import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { useParams } from "react-router-dom";
import {
  useBreadCrumbInfoByPersonIdQuery,
  useGetMemoForClientByPersonIdQuery,
} from "../../../generated/graphql";
import usePersonSubscription from "../../../hooks/usePersonSubscription";
import Loading from "../../atoms/Loading";
import ErrorFallback from "../../molecules/ErrorFallback/ErrorFallback";
import PersonHeader from "../../molecules/PersonHeader";
import Account from "./Account";
import Contact from "./Contact/index";
import MasterData from "./MasterData";
import Other from "./Other";
import useStyles from "../../forms/formStyles";
import Markup from "../../atoms/Markup";

export default function PersonDetails() {
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();

  const { person, loading, error } = usePersonSubscription(parseInt(id, 10));
  const personId = person?.id;
  const { data: breadcrumbData } = useBreadCrumbInfoByPersonIdQuery({
    skip: !personId,
    variables: {
      id: personId!,
    },
  });
  const { data: memo, loading: loadingMemo } = useGetMemoForClientByPersonIdQuery({
    skip: id !== null ? false : true,
    variables: { id: parseInt(id, 10) },
  });

  if (loading) {
    return <Loading />;
  }

  if (error || !person) {
    if (error) {
      console.error(error);
    }
    return <ErrorFallback error={new Error("Person wurde nicht gefunden")} />;
  }

  return (
    <>
      <PersonHeader
        person={person}
        contractExpirationDate={breadcrumbData?.breadCrumbInfoByPersonId?.contractEnd}
        language={breadcrumbData?.breadCrumbInfoByPersonId?.language || ""}
        mapList={[
          {
            name: "Persönliche Daten",
            path: "",
          },
        ]}
      ></PersonHeader>
      <Box marginTop={24}>
        <Grid container spacing={2}>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            {memo && (memo?.getMemoForClientByPersonId) && (            
              <Box marginBottom={8}>
                <Box className={classes.memoContainer}>
                  <Markup
                    value={memo.getMemoForClientByPersonId!}
                    key={`info${personId}`}
                  />
                </Box>
              </Box>
            )}
            <MasterData person={person} />
            <Contact person={person} />
            <Account
              person={person}
              memo={memo?.getMemoForClientByPersonId! || ""}
            />
            <Other person={person} />
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Box>
    </>
  );
}
