import React, { Fragment, ReactText } from "react";

import { Formik, FormikProps, FormikValues } from "formik";

import Typography from "@material-ui/core/Typography";

import {
  useUpdateConversationReportMutation,
  Person,
  Service,
  ConversationReport,
  StatisticGroupTypes,
  ReportState,
  AccountType,
} from "../../../generated/graphql";

import useStyles from "../reportStyles";
import { getPrevNext } from "../../../utils/reportHelper";
import { initialFormValue } from "../../molecules/SlateRTE";

import Issue from "./stepIssue";
import Agreement from "./stepAgreement";
import Stats from "./stepStats";
import Mental from "./stepMental";
import StepCounselingOrLM from "./stepCounselingOrLM";
import Summary from "./stepSummary";
import CMCheck from "./stepCMCheck";
import StepRisk from "./stepRisk";
import StepRiskInfo from "./stepRiskInfo";
import ReviewSwitch from "./stepAskForReview";
import CheckServices from "./stepCheckServices";
import FinalStep from "../ReportFinalStep";
import StepLifeworksService from "./stepLifeworksService";

export type TReportWizard = {
  backAction: () => void;
  person: Person;
  report: ConversationReport;
  step: ReportState;
};

export default function ReportWizard({ person, backAction, report, step }: TReportWizard) {
  const classes = useStyles();
  const [updateConversationReport] = useUpdateConversationReportMutation();
  const services = (report.services || []) as Service[];
  const headline = () => {
    return {
      [ReportState.created]: "",
      [ReportState.issued]: `OK, was war das Anliegen von ${person.name}?`,
      [ReportState.workStats]: "Gibt es arbeitsplatzbezogene Gründe für die Kontaktaufnahme?",
      [ReportState.privateStats]: "Gibt es persönliche Gründe für die Kontaktaufnahme?",
      [ReportState.agreed]: "Habt Ihr etwas vereinbart?",
      [ReportState.mentalized]: `Wie war die psychische Verfassung von ${person.name}?`,
      [ReportState.summarized]: "Bitte fasse nun den Gesprächsverlauf zusammen",
      [ReportState.counselingOrLm]: "Was war der Schwerpunkt des Gesprächs?",
      [ReportState.risk]: "Hast du ein Risiko identifiziert?",
      [ReportState.riskInfo]: "Wie schätzt du das Risiko ein?",
      [ReportState.cmnotified]: "Hier hast du die Möglichkeit eine CM-Prüfung zu veranlassen.",
      [ReportState.reportDone]: "Alles dokumentiert, vielen Dank!",
      [ReportState.startReview]: "",
      [ReportState.inReview]: "",
      [ReportState.inServiceReview]: `${person.name} hat ${
        services.length !== 1 ? services.length : "einen"
      } aktive${services.length === 1 ? "n" : ""} Service${
        services.length === 1 ? "" : "s"
      }, möchtest du hier Ergänzungen oder Änderungen vornehmen?`,
      [ReportState.finished]: "",
      [ReportState.serviceSelected]: "Bitte wähle das Thema des Gesprächs aus",
    }[step];
  };

  const getInitialValues = () => {
    return {
      agreement: report.agreement || initialFormValue,
      lifeworksServiceSubIssue: report.lifeworksServiceSubIssue || "",
      lifeworksServiceIssue: report.lifeworksServiceIssue || "",
      lifeworksService: report.lifeworksService || "",
      risk: report.risk || false,
      riskInfo: report.riskInfo || initialFormValue,
      issue: report.issue || initialFormValue,
      mentalDescription: report.mentalDescription || initialFormValue,
      reportType: report.reportType || initialFormValue,
      mentalState: report.mentalState || initialFormValue,
      summary: report.summary || initialFormValue,
      cmCheckMessage: report.cmCheckMessage || initialFormValue,
      connectedServices: report.connectedServices
        ? report.connectedServices.map(s => s && s.id)
        : [],
    };
  };

  const getSubmitValues = (values: {
    [key: string]: string | number | null | undefined | ReactText | any[] | boolean;
  }) => {
    let submitValues = {};
    const validKeys = Object.keys(getInitialValues());

    validKeys.forEach(key => {
      if (values[key]) {
        submitValues = { ...submitValues, [key]: values[key] };
      }
    });

    if (values["status"]) {
      submitValues = { ...submitValues, status: values["status"] };
    }

    submitValues = { ...submitValues, risk: values["risk"] };

    return submitValues;
  };

  const renderForm = (callback: (p: any) => any, formProps: FormikProps<FormikValues>) => {
    switch (step) {
      case ReportState.issued:
        return (
          <Issue {...formProps} person={person} saveInput={callback} backAction={backAction} />
        );
      case ReportState.workStats:
        return (
          <Stats
            {...formProps}
            backAction={backAction}
            type={StatisticGroupTypes.work}
            report={report}
          />
        );
      case ReportState.privateStats:
        return (
          <Stats
            {...formProps}
            backAction={backAction}
            type={StatisticGroupTypes.private}
            report={report}
          />
        );
      case ReportState.agreed:
        return (
          <Agreement {...formProps} person={person} saveInput={callback} backAction={backAction} />
        );
      case ReportState.mentalized:
        return (
          <Mental {...formProps} person={person} saveInput={callback} backAction={backAction} />
        );
      case ReportState.summarized:
        return (
          <Summary {...formProps} person={person} saveInput={callback} backAction={backAction} />
        );
      case ReportState.counselingOrLm:
        return (
          <StepCounselingOrLM
            backAction={backAction}
            saveInput={callback}
            setFieldValue={formProps.setFieldValue}
            values={formProps.values}
          />
        );
      case ReportState.risk:
        return (
          <StepRisk
            backAction={backAction}
            saveInput={callback}
            setFieldValue={formProps.setFieldValue}
            values={formProps.values}
          />
        );
      case ReportState.riskInfo:
        return (
          <StepRiskInfo
            backAction={backAction}
            saveInput={callback}
            setFieldValue={formProps.setFieldValue}
            values={formProps.values}
          />
        );
      case ReportState.cmnotified:
        return (
          <CMCheck {...formProps} person={person} backAction={backAction} saveInput={callback} />
        );
      case ReportState.reportDone:
        return <ReviewSwitch {...formProps} person={person} backAction={backAction} />;
      case ReportState.inServiceReview:
        return (
          <CheckServices
            {...formProps}
            person={person}
            services={services}
            reportId={report.id}
            saveInput={callback}
            backAction={backAction}
          />
        );
      case ReportState.finished:
        return <FinalStep person={person} backAction={backAction} />;

      case ReportState.serviceSelected:
        return (
          <StepLifeworksService
            {...formProps}
            person={person}
            saveInput={callback}
            backAction={backAction}
          />
        );
      default:
        return <div />;
    }
  };

  function autoSave(values: { [key: string]: string | number | null | undefined | ReactText }) {
    return updateConversationReport({
      variables: {
        report: {
          id: report.id,
          ...getSubmitValues(values),
        },
      },
    });
  }

  return (
    <Fragment>
      <Typography variant="h1" className={classes.headline}>
        {headline()}
      </Typography>

      <Formik
        initialValues={{ ...getInitialValues(), skipReview: false }}
        onSubmit={async (values, { setSubmitting }) => {
          const newStep = getPrevNext(step, {
            hasServices: report?.services && report.services.length > 0 ? true : false,
            needsReview: !person.client!.reviewRequired!,
            hasRisk: values.risk,
            doesReview: !!report?.activeClientReviewAttributeGroup || !values.skipReview,
            accountType: person?.client?.account?.type || AccountType.eap,
            next: true,
          });

          await updateConversationReport({
            variables: {
              report: {
                id: report.id,
                status: newStep,
                ...getSubmitValues(values),
              },
            },
          });

          setSubmitting(false);
        }}
      >
        {formProps => {
          return renderForm(autoSave, formProps);
        }}
      </Formik>
    </Fragment>
  );
}
